import styled from '@emotion/styled';

import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';



export const Wrapper = styled(AccountContent, { label: 'Wrapper' })`

    display: flex;

    flex-flow: column nowrap;

    gap: 32px;

    padding: 16px 6px 32px 16px;

`;

