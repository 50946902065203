import {
    validateAmountRequire,
    validateAmountPattern,
    validateMinAmountNew,
    validateMaxAmountNew,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { observable, computed, action, makeObservable } from 'mobx';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';

export type ErrorMessageType = 'daily-max-deposit-limit' | 'monthly-max-deposit-limit' | 'empty' | 'unknown-error';

const minAmount = new Amount('1');
export class DepositLimitState {
    @observable public isSwitched: boolean = false;
    @observable public errorMessage: ErrorMessageType | null = null;
    @observable public refDailyInput: HTMLInputElement | null = null;
    @observable public refWeeklyInput: HTMLInputElement | null = null;
    @observable public refMonthlyInput: HTMLInputElement | null = null;

    public dailyValueState: FormInputState<string, Amount>;

    public weeklyValueState: FormInputState<string, Amount>;

    public monthlyValueState: FormInputState<string, Amount>;

    public constructor(
        private readonly language: LanguagesState,
        public readonly configComponents: ConfigComponents,
        public readonly account: AccountState
    ) {
        makeObservable(this);

        this.dailyValueState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(
                validateMinAmountNew(
                    minAmount,
                    this.language.getTranslation(
                        'signup-journey.deposit-limit-error.input.min-amount',
                        'Please enter higher value than {currency}{minAmount}',
                        { minAmount: minAmount.value, currency: this.account.usersState.moneySymbol }
                    )
                )
            )
            .map(
                validateMaxAmountNew(
                    this.dailyMaxDepositLimit() ?? this.monthlyMaxDepositLimit,
                    this.language.getTranslation(
                        'signup-journey.deposit-limit-error.input.max-amount',
                        'Please enter smaller value than {currency}{maxAmount}',
                        {
                            maxAmount: this.dailyMaxDepositLimit()?.value ?? '',
                            currency: this.account.usersState.moneySymbol,
                        }
                    )
                )
            );

        this.weeklyValueState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(
                validateMinAmountNew(
                    minAmount,
                    this.language.getTranslation(
                        'signup-journey.deposit-limit-error.input.min-amount',
                        'Please enter higher value than {currency}{minAmount}',
                        { minAmount: minAmount.value, currency: this.account.usersState.moneySymbol }
                    )
                )
            )
            .map(
                validateMaxAmountNew(
                    this.monthlyMaxDepositLimit,
                    this.language.getTranslation(
                        'signup-journey.deposit-limit-error.input.max-amount',
                        'Please enter smaller value than {currency}{maxAmount}',
                        {
                            maxAmount: this.monthlyMaxDepositLimit?.value ?? '',
                            currency: this.account.usersState.moneySymbol,
                        }
                    )
                )
            );

        this.monthlyValueState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(
                validateMinAmountNew(
                    minAmount,
                    this.language.getTranslation(
                        'signup-journey.deposit-limit-error.input.min-amount',
                        'Please enter higher value than {currency}{minAmount}',
                        { minAmount: minAmount.value, currency: this.account.usersState.moneySymbol }
                    )
                )
            )
            .map(
                validateMaxAmountNew(
                    this.monthlyMaxDepositLimit,
                    this.language.getTranslation(
                        'signup-journey.deposit-limit-error.input.max-amount',
                        'Please enter smaller value than {currency}{maxAmount}',
                        {
                            maxAmount: this.monthlyMaxDepositLimit?.value ?? '',
                            currency: this.account.usersState.moneySymbol,
                        }
                    )
                )
            );
    }

    @computed public get monthlyMaxDepositLimit(): Amount | null {
        const depositLimits = this.account.usersState.depositLimitsData.valueReady;
        const monthlyMaxDepositLimit = depositLimits?.monthly?.maxDepositLimit ?? null;

        if (monthlyMaxDepositLimit === null) {
            return null;
        }

        return this.configComponents.precision.newFromOld(monthlyMaxDepositLimit);
    }

    public dailyMaxDepositLimit = (): Amount | null => {
        const depositLimits = this.account.usersState.depositLimitsData.valueReady;
        const dailyMaxDepositLimit = depositLimits?.daily?.maxDepositLimit ?? null;

        if (dailyMaxDepositLimit === null) {
            return null;
        }

        return this.configComponents.precision.newFromOld(dailyMaxDepositLimit);
    };

    @computed public get maxLifetimeNetDepositLimit(): number | null {
        return this.account.usersState.depositLimitsData.valueReady?.maxLifetimeNetDepositLimit.active ?? null;
    }

    @computed public get isDailyError(): boolean {
        return this.dailyValueState.result.value.type === 'error';
    }

    @computed public get isWeeklyError(): boolean {
        return this.weeklyValueState.result.value.type === 'error';
    }

    @computed public get isMonthlyError(): boolean {
        return this.monthlyValueState.result.value.type === 'error';
    }

    @computed public get isAllInputsEmpty(): boolean {
        return this.isDailyError && this.isWeeklyError && this.isMonthlyError;
    }

    @computed public get isAnyInputError(): boolean {
        return (
            (this.monthlyMaxDepositLimit?.isLessThan(new Amount(this.dailyValueState.value)) ?? false) ||
            (this.monthlyMaxDepositLimit?.isLessThan(new Amount(this.weeklyValueState.value)) ?? false) ||
            (this.monthlyMaxDepositLimit?.isLessThan(new Amount(this.monthlyValueState.value)) ?? false)
        );
    }

    public setDailyValue = (): void => {
        if (!this.isDailyError) {
            this.dailyValueState.setValue(this.dailyValueState.value);
        }
        return;
    };

    public setWeeklyValue = (): void => {
        if (!this.isWeeklyError) {
            this.weeklyValueState.setValue(this.weeklyValueState.value);
        }
        return;
    };

    public setMonthlyValue = (): void => {
        if (!this.isMonthlyError) {
            this.monthlyValueState.setValue(this.monthlyValueState.value);
        }
        return;
    };

    public onChangeDaily = (): void => {
        this.dailyValueState.setValue(this.dailyValueState.value.replace(/[^0-9\.]/g, ''));
        this.errorMessage = null;
    };

    public onChangeWeekly = (): void => {
        this.weeklyValueState.setValue(this.weeklyValueState.value.replace(/[^0-9\.]/g, ''));
        this.errorMessage = null;
    };

    public onChangeMonthly = (): void => {
        this.monthlyValueState.setValue(this.monthlyValueState.value.replace(/[^0-9\.]/g, ''));
        this.errorMessage = null;
    };

    @action public onSubmitDepositLimitsForm = async (): Promise<void> => {
        try {
            if (this.isAllInputsEmpty) {
                this.errorMessage = 'empty';
                return undefined;
            }
            const dailyMaxDepositLimit = this.dailyMaxDepositLimit();
            const daily =
                this.dailyValueState.result.value.type === 'ok' ? this.dailyValueState.result.value.data : undefined;
            const weekly =
                this.weeklyValueState.result.value.type === 'ok' ? this.weeklyValueState.result.value.data : undefined;
            const monthly =
                this.monthlyValueState.result.value.type === 'ok'
                    ? this.monthlyValueState.result.value.data
                    : undefined;
            if (dailyMaxDepositLimit !== null && daily?.isGreaterThan(dailyMaxDepositLimit) === true) {
                this.errorMessage = 'daily-max-deposit-limit';
                return;
            }

            if (this.monthlyMaxDepositLimit !== null && monthly?.isGreaterThan(this.monthlyMaxDepositLimit) === true) {
                this.errorMessage = 'monthly-max-deposit-limit';
                return;
            }

            await this.account.account?.onUpdateTopUpLimits(
                daily?.value === undefined ? null : daily,
                weekly?.value === undefined ? null : weekly,
                monthly?.value === undefined ? null : monthly
            );
        } catch (e) {
            this.errorMessage = 'unknown-error';
        }
    };

    @action public onSwitch = (): void => {
        this.isSwitched = !this.isSwitched;
        this.errorMessage = null;
        this.dailyValueState.reset();
        this.weeklyValueState.reset();
        this.monthlyValueState.reset();
    };

    public handleClickDaily = (): void => {
        if (this.isDailyError) {
            this.dailyValueState.reset();
        }
    };

    public handleClickWeekly = (): void => {
        if (this.isWeeklyError) {
            this.weeklyValueState.reset();
        }
    };
    public handleClickMonthly = (): void => {
        if (this.isMonthlyError) {
            this.monthlyValueState.reset();
        }
    };

    @action public setDailyInputRef = (node: HTMLInputElement | null): void => {
        this.refDailyInput = node;
    };

    @action public setWeeklyInputRef = (node: HTMLInputElement | null): void => {
        this.refWeeklyInput = node;
    };

    @action public setMonthlyInputRef = (node: HTMLInputElement | null): void => {
        this.refMonthlyInput = node;
    };
}
