import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SelectionRabItemMenuItem } from './SelectionRabCorrectScoreMenuItem.style';
import { slug } from 'src/utils/deburr';
import { useAsObservableSource } from 'mobx-react-lite';

interface SelectionRabPropsType {
    isActive: boolean;
    name: string;
    changeActiveMenu: (activeMenu: string) => void;
}

class State {
    public constructor(
        private readonly props: SelectionRabPropsType
    ) {
    }

    public changeMenu = (): void => {
        const slugName = slug(this.props.name);
        this.props.changeActiveMenu(slugName);
    }
}

export const SelectionRabNestedMenuItem = observer('SelectionRabNestedMenuItem', (propsIn: SelectionRabPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { name, isActive } = props;
    const [ state ] = React.useState(() => new State(props));

    return (
        <SelectionRabItemMenuItem onClick={state.changeMenu} isActive={isActive}>
            {name}
        </SelectionRabItemMenuItem>
    );
});
