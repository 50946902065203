import { action, computed, observable, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { apiCommon } from 'src/api/ApiCommon';
import { PromoNotificationsModelType } from 'src/api/config/cms_new/promo_notifications/get_promo_notifications_active';
import { LiveNotificationsModelType } from 'src/api/config/cms_new/liveNotificationsActive/getLiveNotificationsActive';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { DateTime } from 'src_common/utils/time/time';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { sortByNumberField, sortReverseByNumberField } from 'src_common/utils/sport/sort';

export type PromoNotificationsModelForView = Omit<PromoNotificationsModelType, 'universe' | 'is_active'>;

export type LiveNotificationLocalStorageObjectType = {
    timesVisited: number;
    id: string;
};

interface NotificationStorageType {
    timesVisited: number;
}

type LiveNotificationShowType = {
    show: boolean;
};

const NotificationIdsIO = t.interface({
    idsList: t.array(t.number),
});

const isNotificationIds = createGuard(NotificationIdsIO);

const convertToNumber = (value: string | null | undefined): number | null => {
    if (value === null || value === undefined) {
        return null;
    }

    const valueNumber = parseInt(value, 10);
    if (isNaN(valueNumber)) {
        return null;
    }

    return valueNumber;
};

export class NotificationsState {
    private readonly notificationsActiveResource: Resource<Array<PromoNotificationsModelType>>;
    private readonly liveNotificationsActive: Resource<Array<LiveNotificationsModelType>>;
    @observable public hideAll: boolean = false;
    @observable public liveNotificationVisibilityMap: Map<string, LiveNotificationShowType> = new Map();

    public constructor(
        public readonly localStorageState: LocalStorageState,
        public readonly router: StarRouter,
        private readonly getUserId: () => string | null,
        private readonly sdkCustomer: SdkCustomer,
        private readonly doesGameExist: (gameId: number) => boolean
    ) {
        makeObservable(this);

        this.notificationsActiveResource = new Resource(async () => {
            return await apiCommon.get_promo_notifications_active.run({});
        });

        this.liveNotificationsActive = new Resource(async (): Promise<Array<LiveNotificationsModelType>> => {
            const responseApi = await apiCommon.getLiveNotificationsActive.run({});

            const response = [];

            for (const item of responseApi) {
                const cta_event = convertToNumber(item.cta_event);

                response.push({
                    ...item,
                    cta_event: this.sdkCustomer.models.id.getEventIdOption(cta_event),
                });
            }

            this.handleDisplayFrequency(response);

            return response;
        });
    }

    @computed private get userId(): string | null {
        return this.getUserId();
    }

    @action public addItemToLiveNotificationVisibilityArray = (id: string, item: LiveNotificationShowType): void => {
        this.liveNotificationVisibilityMap.set(id, { show: item.show });
    };

    @computed public get areAllNotificationsClosed(): boolean {
        for (const [_key, value] of this.liveNotificationVisibilityMap.entries()) {
            if (value.show === true)
                return false;
        }
        return true;
    }

    @action public handleCloseLiveNotification = (itemId: string): void => {

        const foundNotification = this.liveNotificationVisibilityMap.get(itemId);
        if (foundNotification !== undefined) {
            this.liveNotificationVisibilityMap.set(itemId, { show: false });
        }
    };

    private hadleAddingToLocalStorage = (
        notification: LiveNotificationsModelType,
        ids: Array<number>,
        isUsersListFilled: boolean,
        isAuthorized: boolean,
        userId: string | undefined | null): string | null => {
        if (isAuthorized) {
            if (isUsersListFilled) {
                const isUserIncludedInList = userId !== undefined && userId !== null && ids.includes(parseInt(userId, 10));
                if (isUserIncludedInList) {
                    return notification.id.toString();
                }
            } else {
                return notification.id.toString();
            }
        } else {
            if (isNotificationIds(notification.users_ids) && notification.users_ids.idsList.length === 0) {
                return notification.id.toString();
            }
            return null;
        }
        return null;
    };

    private handleDisplayFrequency = (data: Array<LiveNotificationsModelType>): void => {
        const valuesFromLocalStorage = this.notificationFromStorage;
        const notifiactionsToPush = new Map();
        for (const notification of data) {
            const storageElement = valuesFromLocalStorage.get(notification.id.toString());
            const usersList = isNotificationIds(notification.users_ids) ? notification.users_ids.idsList : [];
            const isUsersListFilled = isNotificationIds(notification.users_ids) && notification.users_ids.idsList.length > 0;

            if (storageElement === undefined) {
                const id = this.hadleAddingToLocalStorage(notification, usersList, isUsersListFilled, this.sdkCustomer.session.isAuthorized, this.userId);
                if (id !== null) {
                    notifiactionsToPush.set(notification.id.toString(), { timesVisited: 0 });
                }
            }
        }
        valuesFromLocalStorage.forEach((value, key) => notifiactionsToPush.set(key, value));
        this.setValuesToLocalStorage(notifiactionsToPush);
    };

    public redirectToEvent = (id: EventId): void => {
        this.router.redirectToEvent(id);
        return;
    };

    @computed public get notifications(): Array<PromoNotificationsModelType> {
        const notifications = this.notificationsActiveResource.get();
        if (notifications.type === 'ready') {
            return notifications.value;
        }

        return [];
    }

    @computed public get notifications_right_promo_filter_date(): Array<PromoNotificationsModelForView> {
        return this.notifications.filter(elem => {
            const now = new Date().getTime();
            const from = new Date(elem.date_from).getTime();
            const to = new Date(elem.date_to).getTime();

            if (isNaN(from) === false && isNaN(to) === false && from < now && now < to) {
                return true;
            }

            return false;
        });
    }

    @computed public get notifications_right_promo_sorted(): Array<PromoNotificationsModelForView> {
        return this.notifications_right_promo_filter_date.sort((v1: PromoNotificationsModelForView, v2: PromoNotificationsModelForView) => sortByNumberField(v1.display_order, v2.display_order));
    }

    @computed public get liveNotificationsList(): Array<LiveNotificationsModelType> {

        const notifications = this.liveNotificationsActive.get();
        if (notifications.type === 'ready') {
            return notifications.value.filter(elem => elem.is_active).sort((v1: LiveNotificationsModelType, v2: LiveNotificationsModelType) => sortReverseByNumberField(v1.display_order, v2.display_order));
        }

        return [];
    }

    public isNotificationValid = (notification: LiveNotificationsModelType): boolean => {

        const date_from = notification.date_from ?? null;
        const date_to = notification.date_to ?? null;

        if (date_from === null || date_to === null) {
            return false;
        }

        const dateTo = DateTime.from(date_to);

        if (dateTo === null) {
            return false;
        }

        const isCurrentDateAfter = DateTime.current().isAfter(date_from);
        const isCurrentDateBefore = DateTime.current().isBefore(dateTo);

        const liveNotifications = this.notificationFromStorage;
        const currentNotification = liveNotifications.get(notification.id.toString());
        const timesVisited = currentNotification === undefined ? 0 : currentNotification.timesVisited;

        const isDateValid = isCurrentDateAfter && isCurrentDateBefore;
        const isUsageValid = notification.visibility_option === 'multiple' || timesVisited < parseInt(notification.visibility_option ?? '', 10);

        return isUsageValid && isDateValid && currentNotification !== undefined;
    };

    private filterNotifications = (notification: LiveNotificationsModelType): boolean => {
        const userId = this.userId;
        if (this.sdkCustomer.session.isAuthorized === true) {
            if (userId === null) {
                return false;
            }
            if (isNotificationIds(notification.users_ids) && notification.users_ids.idsList.length > 0) {
                const ids = notification.users_ids.idsList;
                const userId = this.userId;
                if (userId !== null && ids.includes(parseInt(userId, 10))) {
                    return true;
                }
                return false;
            }
            return true;
        } else if (isNotificationIds(notification.users_ids) && notification.users_ids.idsList.length > 0) {
            return false;
        }
        return notification.is_show_for_all_users;
    };

    @computed public get liveNotificationsListForMessagesBox(): Array<LiveNotificationsModelType> {
        return this.liveNotificationsList
            .filter(elem => this.filterNotifications(elem))
            .map(elem => {
                return {
                    id: elem.id,
                    type: 'liveNotifications',
                    content: elem.title,
                    cta_html_link: elem.cta_html_link,
                    cta_game: elem.cta_game,
                    cta_event: elem.cta_event,
                    cta_text: elem.cta_text,
                    message_text: elem.message_text,
                    date_from: elem.date_from,
                    date_to: elem.date_to,
                    visibility_option: elem.visibility_option,
                    users_ids: elem.users_ids,
                    display_order: elem.display_order,
                    is_show_for_all_users: elem.is_show_for_all_users,
                    img_url: elem.img_url,
                    is_active: elem.is_active,
                    title: elem.title,
                    universe: elem.universe,
                };
            }).filter(elem => this.isNotificationValid(elem)).sort((a, b) => a.display_order > b.display_order ? -1 : 1);
    }

    @computed public get messagesBox(): Array<LiveNotificationsModelType> {
        if (this.hideAll) {
            return [];
        }
        return this.liveNotificationsListForMessagesBox.filter(elem => this.isGameTypeNotificationValid(elem));
    }

    private isGameTypeNotificationValid = (notification: LiveNotificationsModelType): boolean => {
        const ctaGameLink = notification.cta_game ?? null;
        if (ctaGameLink === '' || ctaGameLink === 'none' || ctaGameLink === null) {
            return true;
        }
        const id = parseInt(ctaGameLink, 10);
        return this.doesGameExist(id);
    };

    @action public onHideAll = (): void => {
        this.hideAll = true;
    };

    @computed public get notificationFromStorage(): Map<string, NotificationStorageType> {
        const notifiactionMap = new Map();
        const liveNotifications = this.localStorageState.liveNotifications.getValue();
        for (const notification of liveNotifications) {
            notifiactionMap.set(notification.id, { timesVisited: notification.timesVisited });
        }
        return notifiactionMap;
    }

    public setValuesToLocalStorage = (notifications: Map<string, NotificationStorageType>): void => {
        const localStorageArray: Array<LiveNotificationLocalStorageObjectType> = [];
        notifications.forEach((value: NotificationStorageType, key: string) => {
            const timesVisited = value.timesVisited ?? 0;
            localStorageArray.push({ id: key, timesVisited: timesVisited });
        });
        this.localStorageState.liveNotifications.setValue([...localStorageArray]);
    };

    @action public onCloseOne = (id: string): void => {
        const liveNotifications = this.notificationFromStorage;
        const foundNotificationIndex = liveNotifications.get(id);
        if (foundNotificationIndex !== undefined) {
            liveNotifications.set(id, { timesVisited: foundNotificationIndex.timesVisited === 0 ? 1 : foundNotificationIndex.timesVisited + 1 });
            this.handleCloseLiveNotification(id);
        }
        this.setValuesToLocalStorage(liveNotifications);
    };
}
