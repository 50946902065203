import React from 'react';
import { HomePageCarouselState } from 'src/domains/layouts/state/cmsState/homePageCarouselState/HomePageCarouselState';
import { observer } from 'src/utils/mobx-react';
import { ScrollLeft, ScrollRight, ChevronIcon } from './HomePageCarouselPromos.style';

interface PropsTypes {
    carouselPromoState: HomePageCarouselState
}

export const ChevronsWrapper = observer('ChevronsWrapper', ({ carouselPromoState }: PropsTypes) => {
    const { dotsList, currentSlideGroupId, setNextSlide, setPreviousSlide } = carouselPromoState;
    const isActiveLeft = currentSlideGroupId > 0;
    const isActiveRight = currentSlideGroupId < (dotsList.length - 1);

    return (
        <>
            {isActiveLeft ? (
                <ScrollLeft>
                    <ChevronIcon position='left' onClick={setPreviousSlide}/>
                </ScrollLeft>
            ) : null}
            {isActiveRight ? (
                <ScrollRight>
                    <ChevronIcon position='right' onClick={setNextSlide}/>
                </ScrollRight>
            ) : null}
        </>
    );
});
