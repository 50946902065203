import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { TraderChatWebsocketData } from 'src/domains/players/state/traderChat/TraderChatWebsocketData';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';

export class ConnectWrapperTraderChatWebsocketData {

    public constructor(
        private readonly userId: number,
        private readonly websocketV1: WebsocketV1) {
    }

    public connect(self: MobxValue<TraderChatWebsocketData>): (data: unknown) => void {

        const value = self.getValue();

        const callback = (data: unknown): void => {
            // @ts-expect-error
            value.applyData(data);
        };

        value.fetchInitialData()
            .then(()=>{
                const websocketMessageId = `${this.userId}:Chat`;
                this.websocketV1.subscribeOldVersionToDelete(websocketMessageId, callback);
            }).catch((error) => {
                console.error(error);
            });

        return callback;
    }

    public dispose(fn: (data: unknown) => void): void {
        const websocketMessageId = `${this.userId}:Chat`;
        this.websocketV1.unsubscribe(websocketMessageId, fn);
    }
}
