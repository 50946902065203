import React from 'react';
import {
    LinkName,
    QuickHeaderButton,
    UserIconWrapper,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { action, makeObservable } from 'mobx';
import { useCommon } from 'src/domains/common/Common';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';

class State {
    public constructor(
        private readonly starRouter: StarRouter,
        private readonly handleGtmParameter: (route: string) => void
    ) {
        makeObservable(this);
    }

    @action public handleClick = (): void => {
        this.handleGtmParameter('account-summary');
        this.starRouter.redirectToAccountSummary();
    };
}

export const LoginButton = observer('LoginButton', () => {
    const common = useCommon();
    const { appLayoutsState } = useAppStateContext();
    const { sdkCustomer, starRouter } = appLayoutsState;
    const localStorageState = LocalStorageState.get(common);

    const [state] = React.useState(() => new State(starRouter, appLayoutsState.callbacks.onRoutingAccountChange));
    const isUserAuthorized = sdkCustomer.session.isAuthorized;

    const linkParam = isUserAuthorized ? { account: 'summary' } : { account: 'login' };
    const showBalanceInHeader = localStorageState.balanceInHeader.getValue();

    const playableBalanceNow = appLayoutsState.walletResource().valueReady?.playableBalance;
    const playableBalance = playableBalanceNow === undefined ? new Amount('0') : new Amount(playableBalanceNow);
    const balance = sdkCustomer.money(playableBalance);

    const showHeader = isUserAuthorized ? (
        showBalanceInHeader ? (
            <span>{balance}</span>
        ) : (
            <I18n
                langKey='quick-header.account.label'
                defaultText='Account'
            />
        )
    ) : (
        <I18n
            langKey='quick-header.log-in.label'
            defaultText='Log In'
        />
    );

    const active = starRouter.accountParam;
    const activeLink = linkParam.account === active;

    const handleClick = isUserAuthorized ? state.handleClick : starRouter.redirectToLogin;
    const dataTest = isUserAuthorized ? 'account-navigation-login-link-authorized' : 'account-navigation-login-link';

    return (
        <QuickHeaderButton
            data-test={dataTest}
            isActive={activeLink}
            onClick={handleClick}
        >
            <UserIconWrapper />
            <LinkName>{showHeader}</LinkName>
        </QuickHeaderButton>
    );
});
