import { DateInputState } from 'src/domains/players/webview/components/Account/common/dateRangeForm/dateInput/DateInput.state';
import { DateTime } from 'src_common/utils/time/time';

export class DateRangeFormState {
    public readonly from: DateInputState;
    public readonly to: DateInputState;

    public constructor(from: DateTime, to: DateTime) {
        this.from = new DateInputState(from);
        this.to = new DateInputState(to);
    }
};
