import { computed, observable, makeObservable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import {
    validatePassword,
    validatePasswordsEqual,
    validateRequire,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { AccountState } from 'src/domains/players/shared/Types';

interface PasswordFormToSendType {
    oldPassword: string;
    newPassword: string;
}

export class ChangePasswordProcedureState {
    @observable public isValidOldPassword: boolean = true;
    @observable public isSuccessChangePassword: boolean = false;
    @observable public isLoading: boolean = false;

    public readonly oldPasswordState: FormInputState<string, string>;

    public readonly newPasswordState: FormInputState<string, string>;

    public readonly confirmPasswordState: FormInputState<string, string>;

    public readonly form: FormModel<PasswordFormToSendType>;

    public constructor(
        private readonly accountState: AccountState,
        private readonly translation: LanguagesState
    ) {
        makeObservable(this);

        this.oldPasswordState = FormInputState.new('')
            .map(validateRequire)
            .map((value) => {
                if (this.isValidOldPassword === false) {
                    return Result.createError(
                        this.translation.getTranslation('errors.invalid-password', 'Provided password is invalid.')
                    );
                }
                return Result.createOk(value);
            });

        this.newPasswordState = FormInputState.new('').map(validateRequire).map(validatePassword);

        this.confirmPasswordState = FormInputState.new('')
            .map(validateRequire)
            .map((value) => {
                const validateIsTheSamePassords = validatePasswordsEqual({
                    password: this.newPasswordState.value,
                    confirm: value,
                });

                return validateIsTheSamePassords;
            });

        this.form = FormModel.group({
            oldPassword: this.oldPasswordState,
            newPassword: this.newPasswordState,
            confirmPassword: this.confirmPasswordState,
        }).map((item) => {
            return Result.createOk({
                oldPassword: item.oldPassword,
                newPassword: item.newPassword,
            });
        });
    }

    public setValidOldPassword = (isValid: boolean): void => {
        this.isValidOldPassword = isValid;
    };

    public setSuccessChangePassword = (isSuccess: boolean): void => {
        this.isSuccessChangePassword = isSuccess;
    };

    public setIsLoading = (isLoading: boolean): void => {
        this.isLoading = isLoading;
    };

    public onSubmit = async (): Promise<void> => {
        this.form.setAsVisited();
        this.setValidOldPassword(true);
        this.setSuccessChangePassword(false);

        this.setIsLoading(true);
        const result = this.form.result;

        if (result.value.type === 'ok') {
            const account = this.accountState.account;

            if (account === null) {
                return;
            }

            const response = await account.onChangePassword(result.value.data);

            if (response.responseStatus === 'error') {
                this.setValidOldPassword(false);
                this.setIsLoading(false);
                return;
            }

            this.setSuccessChangePassword(true);
            this.form.reset();
        }
        this.setIsLoading(false);
        return;
    };

    @computed public get isButtonDisabled(): boolean {
        return (
            this.oldPasswordState.value === '' ||
            this.newPasswordState.value === '' ||
            this.confirmPasswordState.value === ''
        );
    }
}
