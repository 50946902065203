import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const EachWayBox = withConfig(theme => styled('div', { label: 'EachWayBox' })`
    background-color: ${theme.star.eachWayBox.bgColor};
    flex: 0 0 66px;
    height: 100%;
    padding: 12px 8px;
    width: 66px;
`);

export const CombinationName = withConfig(theme => styled('div', { label: 'CombinationName' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.3;
    margin: 0 4px 0 0;
    padding-bottom: 10px;
    text-align: left;
`);

export const InputWrapper = withConfig(theme => styled('div', { label: 'InputWrapper' })`
    background-color: ${theme.star.betslipGeneral.bgColorSecondary};
    display: flex;
    height: 40px;
`);

export const ReturnsWrapper = styled('div', { label: 'ReturnsWrapper' })`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
`;

export const ReturnWrapper = withConfig(theme => styled('div', { label: 'ReturnWrapper' })`
    color: ${theme.star.betslipSelectionList.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    text-align: left;
    display: flex;
    align-items: center;
    line-height: 1.4;
`);

export const ReturnValue = withConfig(theme => styled('span', { label: 'ReturnValue' })`
    color: ${theme.star.betslipSelectionList.txtColorTertiary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    margin-left: 4px;
`);
