import { computed, makeObservable } from 'mobx';
import { SportHeaderPropsType } from 'src/domains/layouts/webview/components/sportHeader/SportHeader';
import { SportModelType, SportsConfigState } from 'src/domains/sportsbook/state/sportsConfigState/sportsConfigState';

export class CustomizedSportHeaderState {
    public constructor(
        private readonly sportsConfigState: SportsConfigState,
        private readonly props: SportHeaderPropsType
    ) {
        makeObservable(this);
    }

    @computed public get isLoading(): boolean {
        return this.sportsConfigState.sportsConfigActiveResourceData === 'loading';
    }

    @computed public get getSportDetails(): SportModelType | null {
        const sportResourceData = this.sportsConfigState.sportsConfigActiveResourceData;

        if (sportResourceData === 'loading') {
            return null;
        }

        for (const sport of sportResourceData) {
            if (sport.sport_slug === this.props.sportId) {
                return sport;
            }
        }

        return null;
    }

    @computed public get isCustomImgDesktop(): boolean {
        const customImage = this.getSportDetails?.header_img_big;
        return customImage !== undefined && customImage !== '';
    }

    @computed public get isCustomImgTablet(): boolean {
        const customImage = this.getSportDetails?.header_img_medium;
        return customImage !== undefined && customImage !== '';
    }

    @computed public get isCustomImgMobile(): boolean {
        const customImage = this.getSportDetails?.header_img_small;
        return customImage !== undefined && customImage !== '';
    }

    @computed public get isActiveHeaderImage(): boolean {
        if (this.isLoading === true) {
            return false;
        }
        return this.getSportDetails?.is_active_header_image ?? true;
    }

    @computed public get headerImgDesktop(): string | null {
        if (this.isActiveHeaderImage === true) {
            const customImage = this.getSportDetails?.header_img_big ?? '';
            const defaultImage = this.props.options.desktopImage ?? '';

            if (customImage === '') {
                return defaultImage === '' ? null : defaultImage;
            }

            return customImage;
        }

        return null;
    }

    @computed public get headerImgTablet(): string | null {
        if (this.isActiveHeaderImage === true) {
            const customImage = this.getSportDetails?.header_img_medium ?? '';
            const defaultImage = this.props.options.tabletImage ?? '';

            if (customImage === '') {
                return defaultImage === '' ? null : defaultImage;
            }

            return customImage;
        }

        return null;
    }

    @computed public get headerImgMobile(): string | null {
        if (this.isActiveHeaderImage === true) {
            const customImage = this.getSportDetails?.header_img_small ?? '';
            const defaultImage = this.props.options.mobileImage ?? '';

            if (customImage === '') {
                return defaultImage === '' ? null : defaultImage;
            }

            return customImage;
        }

        return null;
    }
}
