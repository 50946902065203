import { action, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { getOffset } from 'src/domains/layouts/utils/GetOffset';
import { GenderTitleState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/titleSection/TitleSection.state';

export class TitleSectionBoxState {
    public constructor(
        private readonly genderTitleState: GenderTitleState,
        private readonly title: 'ms' | 'mr' | '',
        private readonly asideElement: (() => HTMLElement | null) | undefined,
        private readonly onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    ) {
        makeObservable(this);
    }

    @action private handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        const innerWidth = getWindowInnerWidth();
        const headerHeight = 30;
        const getAsideElement = this.asideElement;
        const child = event.currentTarget;

        if (getAsideElement !== undefined && innerWidth !== null && innerWidth <= 768) {
            const asideElement = getAsideElement();

            if (asideElement !== null) {
                const offset = getOffset(asideElement, child);

                asideElement.scrollTo({ top: offset - headerHeight });
            }
        }

        if (this.onClick !== undefined) {
            this.onClick(event);
        }
    };

    public onClickBox = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        this.genderTitleState.titleChooseState.setValue(this.title);
        this.handleClick(event);
    };
}
