import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const validateRequireCheckbox = (value: 'yes' | 'no' | ''): Result<'yes' | 'no'> => {
    if (value === '') {
        return Result.createError(getErrorByCode('ERROR_REQUIRED_FIELD'));
    }

    return Result.createOk(value);
};
