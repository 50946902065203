import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { NavigationPanel } from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { useAppStateContext } from 'src/appState/AppState';

export const NavigationSidebarSectionRecentlyViewed = observer('NavigationSidebarSectionRecentlyViewed', (): React.ReactElement | null => {
    const { appLayoutsState } = useAppStateContext();
    const { recentlyVisitedLocalStorage, configComponents } = appLayoutsState;
    const { mapRecentlyVisitedToLinks } = recentlyVisitedLocalStorage;
    const { config } = configComponents;
    const { navigationRecentlyViewed } = config;

    if (navigationRecentlyViewed === false) {
        return null;
    }

    return (
        <NavigationPanel
            title={<I18n langKey='left-menu.recently-viewed.title' defaultText='Recently viewed' />}
            titleDataTest='recently-viewed'
            links={mapRecentlyVisitedToLinks}
            typeTag='sport'
        />
    );
});

