import React, { useEffect, useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { RaceCard } from 'src/domains/sportsbook/webview/components/raceCard/NewRaceCard';
import { AlternativeEvent } from 'src/domains/sportsbook/webview/modules/AlternativeEvent';
import { RaceCardSection } from './RaceCardPage.style';
import { useAppStateContext } from 'src/appState/AppState';
import {
    RacecardMeetingsRouteType,
    RacecardRaceRouteType,
} from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsType {
    sport: 'horseracing' | 'greyhoundracing';
    currentView: RacecardMeetingsRouteType | RacecardRaceRouteType;
    accountView: RightHandSideViewType | null;
    isSearchActive: boolean;
    racecardBuildIds: Array<number>;
    racecardCollection: 'next-off' | 'race-meetings' | number | null;
    racecardSelected: number | null;
}

const useIsInViewport = (ref: HTMLElement | null): boolean => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const options = {
        threshold: 1.0,
    };

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry !== undefined) {
                setIsIntersecting(entry.isIntersecting);
            }
        }, options);

        if (ref !== null) {
            observer.observe(ref);
        }

        return (): void => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
};

export const RaceCardPage = observer('RaceCardPage', (props: PropsType) => {
    const {
        appSportsBookState: { alternativeEventState, models, streamingState },
        appLayoutsState: { starRouter },
    } = useAppStateContext();

    const { sport, currentView, accountView, isSearchActive, racecardBuildIds, racecardCollection, racecardSelected } =
        props;
    const isNotificationsList = accountView?.account === 'terms-and-conditions-promos';

    const eventId = models.id.getEventIdOption(currentView.selected);
    const { isAlternativeEvent, getAlternativeEventItemById } = alternativeEventState.get(eventId);
    const alternativeEventItem = isAlternativeEvent && eventId !== null ? getAlternativeEventItemById(eventId) : null;
    const showHeroView = isAlternativeEvent && alternativeEventItem?.eventHasEnded === false;

    const isVisible = useIsInViewport(streamingState.streamWrapperRef.ref);

    return (
        <RaceCardSection>
            <LayoutSkeleton
                accountView={accountView}
                currentView={currentView}
                isSearchActive={isSearchActive}
                navigationType='global'
                streamRmgWrapperIsVisible={isVisible}
                isStreamOpen={streamingState.isStreamOpen}
                isAccountOpen={starRouter.isAccountParam}
            >
                {showHeroView && eventId !== null ? (
                    <AlternativeEvent currentView={currentView} />
                ) : (
                    <div className='sport-racing'>
                        <RaceCard
                            sport={sport}
                            racecardBuildIds={racecardBuildIds}
                            racecardCollection={racecardCollection}
                            racecardSelected={racecardSelected}
                            isNotificationsList={isNotificationsList}
                            streamRmgWrapperIsVisible={isVisible}
                        />
                    </div>
                )}
            </LayoutSkeleton>
        </RaceCardSection>
    );
});
