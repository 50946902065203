import React from 'react';
import { BetDetailsWrapper, BetId } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet.style';
import { observer } from 'src/utils/mobx-react';
import { BetReturnsLabel } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetReturnsLabel';
import { BetPlacedDate } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetPlacedDate';
import { BetSingleTypeStakeOdd } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetSingleTypeStakeOdd';
import { BetSingleLegResult } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetSingleLegResult';
import { BetEachWayInfo } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetEachWayInfo';
import { BetRule4DeductionInfo } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetRule4DeductionInfo';
import { BetSPPriceInfo } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetSPPriceInfo';
import { BetLegTitle } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetLegTitle';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';


interface BetPropsTypes {
    bet: BetHistoryType,
}

export const BetSingleDetails = observer('BetSingleDetails', (props: BetPropsTypes) => {
    const { bet } = props;

    const leg = bet.legs[0];
    if (leg === undefined) {
        return null;
    }

    return (
        <BetDetailsWrapper>
            <BetLegTitle leg={leg} isSingle={true}/>

            <BetSingleTypeStakeOdd bet={bet} />
            <BetSPPriceInfo leg={leg} />
            <BetRule4DeductionInfo leg={leg}/>
            <BetEachWayInfo bet={bet} leg={leg}/>

            <BetReturnsLabel bet={bet}/>
            <BetSingleLegResult bet={bet} leg={leg}/>

            <BetPlacedDate bet={bet}/>
            <BetId data-test='bet-id'>#{bet.id}</BetId>
        </BetDetailsWrapper>
    );
});

