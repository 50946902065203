import { ConfirmationBoxState } from 'src/domains/players/state/ConfirmationBoxState';
import { computed, observable, action, makeObservable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { SelectedOption } from 'src/domains/players/webview/components/form/selectNew/SelectNew';
import { DateTime } from 'src_common/utils/time/time';

export class RealityCheckState {
    @observable public infoMessage: string = '';

    public realityCheckInput: FormInputState<string, string>;

    public constructor(
        public readonly account: AccountState,
        public readonly language: LanguagesState,
        public readonly confirmationBox: ConfirmationBoxState,
    ) {
        makeObservable(this);
        this.language = language;
        this.confirmationBox = confirmationBox;

        this.realityCheckInput = FormInputState.new('').map(validateRequire);
    }

    @computed public get selectedDuration(): string {
        return this.realityCheckOptions.find(item => item.value === this.realityCheckInput.value)?.label ?? '';
    }

    @computed public get realityCheckOptions(): SelectedOption[] {

        return [
            { label: this.language.getTranslation('account.reality-check.30', '30 Minutes'), value: '30' },
            { label: this.language.getTranslation('account.reality-check.60', '1 Hour'), value: '60' },
            { label: this.language.getTranslation('account.reality-check.90', '90 Minutes'), value: '90' },
            { label: this.language.getTranslation('account.reality-check.120', '2 Hours'), value: '120' },
            { label: this.language.getTranslation('account.reality-check.180', '3 Hours'), value: '180' },
            { label: this.language.getTranslation('account.reality-check.300', '5 Hours'), value: '300' },
        ];
    }

    @computed public get activeDuration(): number | undefined {
        return this.account.usersState.realityCheckData.valueReady?.active?.duration ?? undefined;
    }

    @computed public get activeDurationLabel(): string {
        return this.realityCheckOptions.find(item => item.value === this.activeDuration?.toString())?.label ?? '';
    }

    @computed public get pendingDuration(): string | undefined {
        return this.account.usersState.realityCheckData.valueReady?.pending?.duration?.toString() ?? undefined;
    }

    @computed public get pendingDurationLabel(): string {
        return this.realityCheckOptions.find(item => item.value === this.pendingDuration)?.label ?? '';
    }

    @computed public get pendingActivableAt(): string | undefined {
        const activableAt = this.account.usersState.realityCheckData.valueReady?.pending?.activableAt ?? undefined;

        if (activableAt !== undefined) {
            return DateTime.from(activableAt)?.format('DD/MM/YYYY HH:mm:ss');
        }
        return undefined;
    }

    @action public onSubmitRealityCheckForm = async (): Promise<void> => {
        this.infoMessage = '';

        if (this.realityCheckInput.result.value.type === 'error') {
            return;
        }

        try {

            await this.account.usersState.setRealityCheck({
                status: this.activeDuration === undefined ? 'active' : 'pending',
                requestBody:{
                    duration: parseInt(this.realityCheckInput.result.value.data, 10)
                }
            });

            this.infoMessage = 'success';
        } catch (e) {
            this.infoMessage = 'error';
            console.error(e);
        }
    };

    public handleSelectChange = (): void => {
        this.infoMessage = '';
    };
}
