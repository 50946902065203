import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { RealityCheckState } from './RealityCheck.state';
import { useAppStateContext } from 'src/appState/AppState';
import {
    RealityCheckButton,
    RealityCheckFormWrapper,
    RealityCheckParagraph,
    RealityCheckStatus,
    RealityCheckStatusContainer,
    RealityCheckStatusHeader,
    SectionHeader,
} from './RealityCheck.style';
import { observer } from 'src/utils/mobx-react';
import { SelectNew } from 'src/domains/players/webview/components/form/selectNew/SelectNew';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const displayInfoMessage = (message: string): JSX.Element | string => {
    if (message === 'error') {
        return (
            <Messages
                type='error'
                marginTop='8px'
                message={
                    <I18n
                        langKey='account.reality-check.error-message'
                        defaultText='Sorry, we seem to have a problem. Please try again.'
                    />
                }
            />
        );
    }

    if (message === 'success') {
        return (
            <Messages
                marginTop='8px'
                type='success'
                message={
                    <I18n
                        langKey='account.reality-check.success-message'
                        defaultText='Your reality check set successfully.'
                    />
                }
            />
        );
    }

    return '';
};

export const RealityCheck = observer('RealityCheck', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState, configComponents } = appLayoutsState;
    const { accountState, confirmationBox } = appPlayersState;
    const [state] = useState(() => new RealityCheckState(accountState, languagesState, confirmationBox));
    const title = <I18n langKey='common.confirmation-box.confirm' defaultText='Confirm' />;
    const text = (
        <I18n
            langKey='account.reality-check.confirmation'
            defaultText='Are you sure that you want to set reality check for {duration}?'
            params={{ duration: state.selectedDuration.toLowerCase() }}
        />
    );

    const showConfirmationModal = (): void => {
        state.realityCheckInput.setAsVisited();
        if (state.realityCheckInput.result.value.type === 'error') {
            return;
        }
        confirmationBox.showConfirmation(title, text, state.onSubmitRealityCheckForm, () => {});
    };

    return (
        <RealityCheckFormWrapper isMessage={state.infoMessage} data-test='reality-check'>
            <SectionHeader data-test='reality-check-header'>
                <I18n langKey='account.reality-check.title' defaultText='Reality Check' />
            </SectionHeader>
            <RealityCheckParagraph data-test='reality-check-paragraph'>
                <I18n
                    langKey='account.reality-check.description'
                    defaultText='A reality check is a notification that interrupts your session to discretely remind you how long you’ve been playing for.'
                />
            </RealityCheckParagraph>
            <RealityCheckStatusHeader data-test='reality-check-status-header'>
                <I18n langKey='account.reality-check.status.title' defaultText='Status:' />
            </RealityCheckStatusHeader>
            <RealityCheckStatusContainer>
                <RealityCheckStatus data-test='reality-check-active-label'>
                    <span>
                        <I18n langKey='account.reality-check.active.label' defaultText='Active:' />{' '}
                    </span>
                    <span>
                        {state.activeDuration === undefined ? (
                            <I18n langKey='account.reality-check.none.label' defaultText='None' />
                        ) : (
                            state.activeDurationLabel
                        )}
                    </span>
                </RealityCheckStatus>
                {state.pendingDuration === undefined ? null : (
                    <RealityCheckStatus data-test='reality-check-pending-label'>
                        <span>
                            <I18n langKey='account.reality-check.pending.label' defaultText='Pending:' />{' '}
                        </span>
                        <span>{`${state.pendingDurationLabel} at ${state.pendingActivableAt ?? ''}`}</span>
                    </RealityCheckStatus>
                )}
            </RealityCheckStatusContainer>
            {configComponents.config.userCanChangeRealityCheck === true ? (
                <>
                    <SelectNew
                        dataTest='reality-check-select'
                        options={state.realityCheckOptions}
                        inputState={state.realityCheckInput}
                        placeholder={languagesState.getTranslation(
                            'account.reality-check.duration.label',
                            'Choose reality check frequency'
                        )}
                    />
                    <RealityCheckButton
                        type='submit'
                        size='medium'
                        onClick={showConfirmationModal}
                        dataTest='reality-check-save-button'
                    >
                        <I18n langKey='account.reality-check.submit.label' defaultText='Save' />
                    </RealityCheckButton>
                </>
            ) : null}
            {state.infoMessage !== '' && displayInfoMessage(state.infoMessage)}
        </RealityCheckFormWrapper>
    );
});
