import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

interface PasswordParamType {
    password: string;
    confirm: string;
}

export const validatePasswordsEqual = (value: PasswordParamType): Result<string> => {
    if (value.confirm !== value.password) {
        return Result.createError(getErrorByCode('ERROR_CONFIRM_PASSWORD'));
    }
    return Result.createOk(value.confirm);
};
