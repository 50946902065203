import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { RealexTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab.state';
import { KycWarning } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/kycWarning/KycWarning';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import { RingFencedFundsMessage } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/ringFencedFundsWrapper/RingFencedFundsWrapper';
import {
    PayButton,
    InfoContainer,
    InfoWarningIconWrapper,
    ErrorLink,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab.style';
import {
    CardInfo,
    CashBalance,
    CashBalanceWrapper,
    DepositTabWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/DepositProcedure.style';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';

interface PropsType {
    state: RealexTabState;
}

interface LimitReachedErrorMessage {
    message: string;
    languagesState: LanguagesState;
}

export const LimitReachedErrorMessage = observer(
    'LimitReachedErrorMessage',
    ({ languagesState, message }: LimitReachedErrorMessage) => (
        <div>
            {languagesState.translateTokens(message, () => {
                return (
                    <ErrorLink
                        to={{ account: 'limits' }}
                        dataTest='error'
                    >
                        here
                    </ErrorLink>
                );
            })}
        </div>
    )
);

export const RealexTab = observer('RealexTab', ({ state }: PropsType): JSX.Element => {
    const {
        config: { showKycStatus },
        appPlayersState: { usersState },
        appLayoutsState: { languagesState },
    } = useAppStateContext();

    return (
        <DepositTabWrapper>
            <CashBalanceWrapper>
                <I18n
                    langKey='account.top-up.cash-balance-label'
                    defaultText='Cash balance'
                />
                <CashBalance>{state.showBalance}</CashBalance>
            </CashBalanceWrapper>

            <Input
                type='text'
                maxLength={10}
                placeholder='0.00'
                currency={usersState.moneySymbol}
                label={
                    <I18n
                        langKey='account.top-up.description-label'
                        defaultText='Deposit amount'
                    />
                }
                state={state.depositAmount}
                onChange={state.onChange}
                onBlur={state.setAmount}
                colorTheme='light'
                inputHasBackground={false}
                dataTest='deposit-amount-input'
            />

            <PaymentAdditions
                key='payment-additions'
                currency={usersState.moneySymbol}
                value={state.depositAmount.value}
                onChange={state.handleAdditionsChange}
            />

            {state.errors.length > 0
                ? state.errors.map((error) =>
                      error.type === 'limit-reached' ? (
                          <Messages
                              type='error'
                              marginTop='8px'
                              key={error.type}
                              message={
                                  <LimitReachedErrorMessage
                                      message={error.message}
                                      languagesState={languagesState}
                                  />
                              }
                          />
                      ) : (
                          <Messages
                              type='error'
                              marginTop='8px'
                              key={error.type}
                              message={error.message}
                          />
                      )
                  )
                : null}

            {state.hasRingFencedFunds === false ? (
                <RingFencedFundsMessage
                    isMoreShown={state.showMoreRingFenceFlag}
                    toggleShowMore={state.toggleShowMore}
                />
            ) : null}
            <PayButton
                size='large'
                disabled={state.isButtonDisabled || state.areMissingFieldsForBillingInfoRealex()}
                onClick={state.submitDepositForm}
                type='submit'
                dataTest='deposit-pay-debit-card'
            >
                <I18n
                    langKey='account.top-up.pay-card-button'
                    defaultText='Pay using debit card'
                />
            </PayButton>

            <CardInfo data-test='deposit-card-info'>
                <I18n
                    langKey='account.top-up.top-upcard-info'
                    defaultText='Credit cards are no longer accepted'
                />
            </CardInfo>
            {showKycStatus === true ? <KycWarning isSignup={false} /> : null}
            <InfoContainer>
                <InfoWarningIconWrapper />
                New cards will be stored for future deposits and withdraw processing.
            </InfoContainer>
        </DepositTabWrapper>
    );
});
