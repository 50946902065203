import styled from '@emotion/styled';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';
import { SerializedStyles, css } from '@emotion/react';

interface BackgroundContainerPropsType {
    bgImage?: string;
}

const renderBgImage = (bgImage: string | undefined, theme: EmotionTheme): SerializedStyles => {
    if (bgImage === undefined ) {
        return css``;
    }

    return css`
        &::before {
            ${positionElementFull};
            background-image: url(${bgImage}), ${theme.star.scoreboards.bgColorTertiary};
            background-position: top center;
            background-size: 100% auto;
            content: '';
            position: absolute;
            z-index: ${theme.star.zIndexGlobal.below};
        }
    `;
};

/* Background container for Scoreboard */
export const BackgroundContainer = withConfig(theme => styled('div', { label: 'BackgroundContainer' })<BackgroundContainerPropsType>`
    background-color: ${theme.star.scoreboards.bgColor};
    height: auto;
    margin: auto;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.base};
    ${({ bgImage }): SerializedStyles => renderBgImage(bgImage, theme)};
`);

interface SportHeaderContainerPropsType {
    bgImage?: string;
    isActiveSportHeaderImage?: boolean;
}

const renderBgSportImage = (bgImage: string | undefined, isActiveSportHeaderImage: boolean | undefined, theme: EmotionTheme): SerializedStyles => {
    if (isActiveSportHeaderImage === false || bgImage === undefined) {
        return css`
            background-color: ${theme.star.headings.sportBanner.bgColor};
        `;
    }

    return css`
        background-color: ${theme.star.headings.sportBanner.bgColorSecondary};
        &::before {
            ${positionElementFull};
            background-image: url(${bgImage}), ${theme.star.headings.sportBanner.bgColorTertiary};
            background-position: top center;
            background-size: 100% auto;
            content: '';
            position: absolute;
            z-index: ${theme.star.zIndexGlobal.below};
        }
    `;
};

/* Background container for Sport header */
export const SportHeaderContainer = withConfig(theme => styled('div', { label: 'SportHeaderContainer' })<SportHeaderContainerPropsType>`
    height: auto;
    margin: auto;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.base};
    ${({ bgImage, isActiveSportHeaderImage }): SerializedStyles => renderBgSportImage(bgImage, isActiveSportHeaderImage, theme)};
`);
