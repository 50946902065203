import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';
import { DateTime } from 'src_common/utils/time/time';

export const BetPlacedDate = observer<{ bet: BetHistoryType }>('BetPlacedDate', ({ bet }) => {
    return <div data-test='bet-placed-date'>
        {DateTime.from(bet.placedAt)?.format('DD MMM YYYY | HH:mm')}
    </div>;
});
