import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { BetLegsStake } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetLegsStake';
import { renderBetTypeName } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/betUtils';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface BetPropsTypes {
    bet: BetHistoryType;
}

export const BetSingleTypeStakeOdd = observer('BetSingleTypeStakeOdd', ({ bet }: BetPropsTypes) => {
    const appState = useAppStateContext();
    const { appLayoutsState, appPlayersState } = appState;
    const { usersState } = appPlayersState;
    const { configComponents } = appLayoutsState;

    return (
        <div>
            {`${renderBetTypeName(bet.type, appState)} ${usersState.money(configComponents.precision.newFromAnything(bet.stakePerLine))} `}
            <I18n langKey='bets.bet-history.stake-label' defaultText='stake' />{' '}
            <BetLegsStake bet={bet} />
        </div>
    );
});

