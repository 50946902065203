import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Circle, PathArea, PathLine, Text } from './LineChart.style';

interface DataItemType {
    x: number,
    y: number,
    label: string,
}

interface PropsType {
    d3: (typeof import('d3')),
    data: Array<DataItemType>,
    showLabels: boolean,
    circle: number,
    xScale: (value: number) => string | number | undefined,
    yScale: (value: number) => string | number | undefined,
}

export const LineChartSeries = observer('LineChartSeries', (props: PropsType): JSX.Element => {
    const { d3 } = props;
    const { showLabels, circle, data, xScale, yScale } = props;

    //@ts-expect-error
    const path = d3.svg.line()
        .x((d: DataItemType) => xScale(d.x))
        .y((d: DataItemType) => yScale(d.y));

    //@ts-expect-error
    const area = d3.svg.area()
        .x((d: DataItemType) => xScale(d.x))
        .y0(() => yScale(0))
        .y1((d: DataItemType) => yScale(d.y));

    return (
        <>
            <PathArea d={area(data)} className='area'/>
            <PathLine d={path(data)} className='line'/>
            {
                data.map((v, i) => [
                    <Circle
                        data-test='circle'
                        key={i}
                        r={circle}
                        cx={xScale(v.x)}
                        cy={yScale(v.y)}
                    />,
                    showLabels ? (
                        <Text
                            data-test='price'
                            className='label'
                            key={`label-${  i}`}
                            x={xScale(v.x)}
                            y='95%'
                        >
                            { v.label }
                        </Text>
                    ) : void 0
                ])
            }
        </>
    );
});
