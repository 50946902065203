import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { decodeBetsFromLocalStorage, BetsFromLocalStorageType } from 'src/domains/sportsbook/shared/Types';

export const localStorageBetSlipBets = (withAutorun: boolean, key: string): LocalStorageItemState<BetsFromLocalStorageType> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decodeBetsFromLocalStorage,
        defaultValue: { legs: [], combinations: [] }
    });
};
