import React, { PropsWithChildren } from 'react';
import { observer } from 'src/utils/mobx-react';
import { AccordionState } from 'src/domains/sportsbook/webview/components/racing/Accordion.state';
import { AccordionHeader, ChevronAccordionWrapper, MeetingSectionHR, TitleStyle } from 'src/domains/sportsbook/webview/components/racing/Accordion.style';

interface PropsType extends PropsWithChildren<unknown> {
    state: AccordionState,
    title: React.ReactNode,
}

export const Accordion = observer<PropsType>('Accordion', (props) => {

    const { title, children, state } = props;
    const { isOpened } = state;

    return <>
        <AccordionHeader onClick={state.toggle}>
            <ChevronAccordionWrapper position={isOpened ? 'down' : 'right'} />
            <TitleStyle>{title}</TitleStyle>
        </AccordionHeader>
        {isOpened ? children : null}

        {isOpened ? null : <MeetingSectionHR />}
    </>;
});
