import React from 'react';
import { RaceSummaryHorseRacing } from './raceSummarySport/RaceSummaryHorseRacing';
import { RaceSummaryGreyhoundRacing } from './raceSummarySport/RaceSummaryGreyhoundRacing';
import { observer } from 'src/utils/mobx-react';
import { HorseRacingSortOrderType } from 'src/domains/sportsbook/webview/components/raceCard/RaceCompetition.state';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';


interface PropsType {
    sortOrder: HorseRacingSortOrderType;
    marketId: MarketId;
}

export const RaceSummaryCast = observer('RaceSummaryCast', (props: PropsType) => {
    const { marketId, sortOrder } = props;

    const marketModel = marketId.getModel();

    if (marketModel === null) {
        return null;
    }

    const raceModel = marketModel.getEvent();
    if (raceModel === null) {
        return null;
    }

    const sport = raceModel.sport;

    const allHorseRaces = sport === 'horseracing';
    const allGreyhoundRaces = sport === 'greyhoundracing';

    if ( allHorseRaces ) {
        return (
            <RaceSummaryHorseRacing
                sortOrder={sortOrder}
                type='cast'
                marketId={marketId}
            />
        );
    } else if ( allGreyhoundRaces ) {
        return (
            <RaceSummaryGreyhoundRacing
                type='cast'
                marketId={marketId}
            />
        );
    }

    return null;
});
