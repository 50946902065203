import { validatePhone } from 'src/domains/players/webview/components/ValidatorsNew';
import { computed, makeObservable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { SelectState } from 'src/domains/players/webview/components/form/select/Select.state';
import { SelectOptionType } from 'src/domains/players/webview/components/form/select/Select';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';

export class PhoneNumberState {

    public readonly prefix: SelectState;
    public readonly phoneNumber: FormInputState<string,string>;

    public refPhone: HTMLInputElement | null;

    public constructor(
        public readonly language: LanguagesState,
        public readonly prefixValue?: SelectOptionType,
        public readonly initialPhoneNumber?: string
    ){
        makeObservable(this);
        this.refPhone = null;
        this.prefix = new SelectState(prefixValue, true);
        this.phoneNumber = FormInputState.new(initialPhoneNumber ?? '').map(validatePhone(this.language.getTranslation));
    }

    @computed public get refPhoneSelection(): number | null {
        if (this.refPhone !== null) {
            return this.refPhone.selectionStart;
        }
        return null;
    }

    public setInputPhoneRef = (node: HTMLInputElement | null): void => {
        this.refPhone = node;
    };
}
