import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { OccupiedSeatsWrapper, OccupiedSeatsIcon } from './OccupiedSeats.style';

interface OccupiedSeatsPropsType {
    largeVersion: boolean;
    currentSeats: number | null;
    maxSeats: number | null;
}

export const OccupiedSeats = observer('OccupiedSeats', (props: OccupiedSeatsPropsType) => {
    const { largeVersion } = props;

    if (props.currentSeats === null) {
        return null;
    }

    const out: Array<number> = [props.currentSeats];
    if (props.maxSeats !== null) {
        out.push(props.maxSeats);
    }
    const label = out.join('/');

    return (
        <OccupiedSeatsWrapper largeVersion={largeVersion}>
            <OccupiedSeatsIcon largeVersion={largeVersion} /> {label}
        </OccupiedSeatsWrapper>
    );
});
