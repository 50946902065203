import * as React from 'react';
import { EventRowMobxWrapper, EventGroupHeader, SpecialEventsTableMarketsLoader, EventListWrapper } from 'src/domains/sportsbook/shared/Components';
import { observer } from 'src/utils/mobx-react';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { GroupState } from './EventListState';
import { SpecialSportCompetitionType, SpecialSportSportType, SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { AppState, useAppStateContext } from 'src/appState/AppState';


const renderCompetition = (appState: AppState, competitionGroup: CompetitionType): Array<React.ReactNode> => {
    const events = competitionGroup.eventsArray;
    const out = [];

    if (events !== null && events.length > 0) {
        for (const event of events) {

            out.push((
                <EventRowMobxWrapper
                    key={`event_${event.id}`}
                    smallEvent={event}
                    competitionId={appState.appSportsBookState.models.id.getCompetitionId(competitionGroup.id)}
                />
            ));
        }
    }

    return out;
};

interface CompetitionListPropsType {
    competition: CompetitionType;
    order: number;
    sportName: string;
}

const CompetitionList = observer('CompetitionList', ({ competition, order, sportName }: CompetitionListPropsType) => {
    const appState = useAppStateContext();
    const [groupState] = React.useState(() => new GroupState(order));

    return (
        <>
            <EventGroupHeader
                key={`group_${competition.id}`}
                selections={[]}
                label={competition.name}
                lightTheme={true}
                open={groupState.isOpen}
                onToggle={groupState.setIsOpen}
                sportName={sportName}
                competitionId={appState.appSportsBookState.models.id.getCompetitionId(competition.id)}
                dataTest='competition-header'
            />
            {groupState.isOpen ? renderCompetition(appState, competition) : null}
        </>
    );
});
interface SportsListPropsType {
    sport: {
        id: string;
        name: string;
    };
    sportOrder: number;
    competitions: Array<CompetitionType | undefined>;
}

const SportsList = observer('SportsList', ({ sport, sportOrder, competitions }: SportsListPropsType ) => {
    const [groupState] = React.useState(() => new GroupState(sportOrder));

    return (
        <>
            <EventGroupHeader
                key={`group_${sport.id}`}
                selections={[]}
                label={sport.name}
                open={groupState.isOpen}
                onToggle={groupState.setIsOpen}
                competitionId={null}
                sportName={sport.name}
                dataTest='sport-header'
            />
            {groupState.isOpen && competitions.filter(c => c?.sport === sport.id).map((competition, order) => {
                if (competition !== undefined) {
                    return (
                        <CompetitionList key={order} order={order} competition={competition} sportName={sport.name} />
                    );
                }
            })
            }
        </>
    );
});

const renderSportGroup = (sport: { id: string; name: string }, sportOrder: number, outSportEventsArray: Array<CompetitionType | undefined>): null | React.ReactNode => {
    const orderToGroup = sportOrder - 1;
    if (sport.id !== 'all') {
        const filteredCompetitions = outSportEventsArray.filter(c => c?.sport === sport.id);
        if (filteredCompetitions.length > 0) {
            return <SportsList key={sportOrder} sportOrder={orderToGroup} sport={sport} competitions={outSportEventsArray} />;
        }

    }
    return null;
};
interface CompetitionType {
    name: string;
    id: number;
    eventsArray: Array<EventListGroupEventItemType> | null;
    sport: string | undefined;
}

interface SpecialEventsType {
    specialSportsState: SpecialSportsState;
}
interface EventsListType {
    availableSports: Array<SpecialSportSportType>;
    competitions: Array<SpecialSportCompetitionType | undefined>;
}

const EventsList = observer('EventsList', ({ availableSports, competitions }: EventsListType) => {
    const isLoaded = availableSports.filter(sport => sport.id !== 'all').length > 0;
    return (
        <>
            <EventListWrapper data-test='special-sport-event-list-wrapper'>
                {availableSports.map(
                    (item, order) => renderSportGroup(item, order, competitions))}
            </EventListWrapper>
            {isLoaded ? null : <SpecialEventsTableMarketsLoader />}
        </>
    );
});

export const SpecialEventsList = observer('SpecialEventsList', ({ specialSportsState }: SpecialEventsType) => {
    const filters_coupon = specialSportsState.filter_coupon;
    const availableSports = specialSportsState.availableSports;
    const competitions = specialSportsState.competitions;
    return (
        <EventsList key={filters_coupon} availableSports={availableSports} competitions={competitions} />
    );
});

