import React from 'react';
import { groupBy, sortBy } from 'lodash';
import { MarketGroup } from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroups';
import { observer } from 'src/utils/mobx-react';
import { EventMarketItemType } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

const GROUPS: Record<string, string> = {
    'first-goalscorer': 'goalscorer',
    'anytime-goalscorer': 'goalscorer',
    'hattrick-goalscorer': 'goalscorer',
};

const UNGROUPED: Array<string> = [
    'race-to',
    'custom-outrights-market',
    'custom-match-market',
    'custom-market',
    'custom-two-participant-market',
];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const groupMakrets = (markets: Array<EventMarketItemType>) => {
    return groupBy(markets, (market) => {
        let marketDisplayTemplate = market.tags['display-template']; // eventTagModel.getMarketTag(market.id, 'display-template');
        const marketGrouping = market.tags['group-template']; // eventTagModel.getMarketTag(market.id, 'group-template');
        // let marketDisplayTemplate = market.displayTemplate;
        // const marketGrouping = market.groupTemplate;
        const templateId = market.templateId;

        marketDisplayTemplate =
            marketDisplayTemplate !== undefined && marketDisplayTemplate !== '-' ? `${marketDisplayTemplate}-` : '';
        const groupTemplate = GROUPS[templateId] ?? null;

        if (groupTemplate !== null || marketGrouping === 'yes') {
            if (groupTemplate !== null) {
                return groupTemplate;
            }
            return marketDisplayTemplate + templateId;
        } else if (UNGROUPED.includes(templateId)) {
            return `${marketDisplayTemplate + templateId}-ungrouped-${market.id}`;
        } else {
            return `${marketDisplayTemplate}${market.id}`;
        }
    });
};

interface PropsTypes {
    eventId: EventId;
    markets: Array<EventMarketItemType>;
}

export const EventMarkets = observer('EventMarkets', (props: PropsTypes) => {
    const { eventId } = props;
    const markets = groupMakrets(props.markets);

    return (
        <div>
            {sortBy(
                Object.keys(markets),
                //@ts-expect-error
                (id) => markets[id][0].displayOrder,
                //@ts-expect-error
                (id) => markets[id][0].name
            ).map((type, index) => {
                const marketsByType = markets[type];
                const marketType = type.includes('-ungrouped-') ? type.split('-ungrouped-')[0] : type;
                if (marketsByType === undefined || marketType === undefined) {
                    return null;
                }
                return (
                    <MarketGroup
                        key={index.toString()}
                        order={index}
                        type={marketType}
                        markets={marketsByType}
                        eventId={eventId}
                    />
                );
            })}
        </div>
    );
});
