import React, { useState } from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { MatchBetsGreyhound } from './raceSummarySport/MatchBetsGreyhound';
import { observer } from 'src/utils/mobx-react';
import { HeadersGreyhoundRacing } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/HeadersGreyhoundRacing';
import { RaceSummaryRowMarkets, RaceSummaryRowMarketsConfig } from './templates/rowMarkets/RaceSummaryRowMarkets';
import {
    RowMarketsState,
    RowMarketsStateStrategy, SHORT_LIST_COUNT
} from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummaryRowMarkets.state';
import { computed, observable, makeObservable } from 'mobx';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionList } from './templates/rowMarkets/RaceSummerRowMarkets.style';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export class RaceSummaryMatchBetsState implements RowMarketsStateStrategy {
    @observable public isShownMore: boolean = false;

    public constructor(public readonly eventId: EventId) {
        makeObservable(this);
    }

    @computed public get markets(): Array<MarketModel> {
        const currentEvent = this.eventId.getEventModel();
        if (currentEvent === null) {
            return [];
        }
        const out = currentEvent.marketMatchBet.sort((mA, mB) => mA.displayOrder < mB.displayOrder ? -1 : 1);
        if (out.length < SHORT_LIST_COUNT) {
            return out;
        }

        return this.isShownMore ? out : out.slice(0, SHORT_LIST_COUNT);
    }
}

const viewConfig: RaceSummaryRowMarketsConfig = {
    displayShowMoreButton: true,
    toggleMarkets: true,
    withTableHeader: false
};

export interface RaceSummaryMatchBetsPropsTypes {
    eventId: EventId;
}

export const RaceSummaryMatchBets = observer('RaceSummaryMatchBets', (propsIn: RaceSummaryMatchBetsPropsTypes) => {
    const props = useAsObservableSource(propsIn);
    const { eventId } = props;

    const [state] = useState(() => new RowMarketsState(new RaceSummaryMatchBetsState(props.eventId)));

    const currentEvent = eventId.getEventModel();
    if (currentEvent === null) {
        return null;
    }

    const matchBetsHeaderRowType = currentEvent.marketMatchBet[0];

    const MatchBetMarketHeader = (): JSX.Element | null => {
        if (matchBetsHeaderRowType !== undefined) {
            return (
                <SelectionList className='race-summary__list'>
                    <HeadersGreyhoundRacing
                        type='' race={currentEvent}
                        market={matchBetsHeaderRowType}
                    />
                </SelectionList>
            );
        }
        return null;
    };

    const sport = currentEvent.sport;
    const allHorseRaces = sport === 'horseracing';
    const allGreyhoundRaces = sport === 'greyhoundracing';

    if ( allHorseRaces ) {
        return <RaceSummaryRowMarkets eventId={props.eventId} state={state} config={viewConfig}/>;
    } else if ( allGreyhoundRaces ) {
        return (
            <>
                { MatchBetMarketHeader() }
                {
                    currentEvent.marketMatchBet.map(el =>
                        <MatchBetsGreyhound
                            key={el.marketName}
                            selections={el.selections}
                            matchBetTitle={el.name}
                            marketId={el.id2}
                        />
                    )
                }
            </>

        );
    }
    return null;
});
