import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { renderTimer } from 'src/domains/sportsbook/lifespan/LifespanWallet/LifeSpanWalletTimer';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { assertNever } from 'src_common/common/assertNever';
import { LifeSpanState, WalletType } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { AppState, useAppStateContext } from 'src/appState/AppState';
import { BetSlipState } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { jsonParse } from 'src_common/common/jsonParse';
import {
    LifeSpanContainer,
    LifeSpanHeader,
    LifeSpanHeaderCloseIconWrapper,
    LifeSpanHeaderIcon,
    LifeSpanHeaderTableColumn,
    LifeSpanHeaderTitle,
    LifeSpanListTableColumn,
    LifeSpanTable,
    LifeSpanTableHeader,
    LifeSpanTableList,
    LifeSpanTableListItem,
    LifeSpanWrapper,
    LifeSpanTableBoostInfo,
    LifeSpanTableListContainer,
    LifeSpanEmptyWalletMessage,
} from 'src/domains/sportsbook/lifespan/LifespanWallet/LifespanWallet.style';

export const WalletPromotionLink = t.union([
    t.interface({
        type: t.literal('sport'),
        id: t.string,
    }),
    t.interface({
        type: t.literal('event'),
        id: t.number,
    }),
    t.interface({
        type: t.literal('racecard'),
        collection: t.number,
        selected: t.number,
        sport: t.string,
    }),
    t.interface({
        type: t.literal('competition'),
        competitionId: t.string,
        sportId: t.string,
    }),
    t.interface({
        type: t.literal('market'),
        eventId: t.string,
        marketId: t.string,
    }),
    t.interface({
        type: t.literal('selection'),
        selectionId: t.string,
        marketId: t.string,
        eventId: t.string,
    }),
    t.interface({
        type: t.literal('racing'),
        eventId: t.string,
        competitionId: t.string,
        racing: t.string,
    }),
]);

const isWalletPromotionLinkAvailable = createGuard(WalletPromotionLink);

const getPromotionLink = (
    lifeSpanState: LifeSpanState,
    routeInfo: string | undefined,
    starRouter: StarRouter,
    modelState: ModelsState,
    betSlipState: BetSlipState
): (() => void) => {
    if (routeInfo === undefined) {
        return (): void => {
            lifeSpanState.toggleWalletOpen();
            starRouter.redirectToHomepage();
        };
    }

    const routeInfoParsed = jsonParse(routeInfo);

    if (routeInfoParsed.type === 'json' && isWalletPromotionLinkAvailable(routeInfoParsed.json)) {
        const routeInfoJson = routeInfoParsed.json;

        switch (routeInfoJson.type) {
            case 'sport':
                return (): void => {
                    lifeSpanState.toggleWalletOpen();
                    starRouter.redirectToSportPage(routeInfoJson.id);
                };

            case 'event':
                return (): void => {
                    lifeSpanState.toggleWalletOpen();
                    starRouter.redirectToEvent(modelState.id.getEventId(routeInfoJson.id));
                };
            case 'racecard':
                const selected = routeInfoJson.selected;
                return (): void => {
                    lifeSpanState.toggleWalletOpen();
                    starRouter.redirectToEvent(modelState.id.getEventId(selected));
                };
            case 'competition':
                return (): void => {
                    lifeSpanState.toggleWalletOpen();
                    starRouter.redirectToCompetitionPage(
                        parseInt(routeInfoJson.competitionId, 10),
                        routeInfoJson.sportId
                    );
                };
            case 'market':
                return (): void => {
                    lifeSpanState.toggleWalletOpen();
                    starRouter.redirectToEvent(modelState.id.getEventId(parseInt(routeInfoJson.eventId, 10)));
                };
            case 'selection':
                const newSelectionId = modelState.id.getSelectionId(
                    parseInt(routeInfoJson.eventId, 10),
                    parseInt(routeInfoJson.marketId, 10),
                    parseInt(routeInfoJson.selectionId, 10)
                );
                const selectionModel = newSelectionId.getModel();

                if (selectionModel === null) {
                    return (): void => {
                        console.warn('Selection model is not loaded');
                    };
                }

                return (): void => {
                    lifeSpanState.toggleWalletOpen();
                    betSlipState.legsState.betslipData.onAddSimpleBet(newSelectionId); //Number(routeInfoJson.selectionId));
                };
            case 'racing':
                return (): void => {
                    const { eventId } = routeInfoJson;

                    lifeSpanState.toggleWalletOpen();
                    starRouter.redirectToEvent(modelState.id.getEventId(parseInt(eventId, 10)));
                };
            default:
                return assertNever('Failed to redirect', routeInfoJson);
        }
    } else {
        return (): void => {
            console.error('Failed to redirect');
        };
    }
};

const renderWalletItems = (userRewardsMessageGrouped: WalletType[], appState: AppState): JSX.Element => {
    const { appLayoutsState, appSportsBookState } = appState;
    const { lifeSpanState, starRouter } = appLayoutsState;

    const isScroll = userRewardsMessageGrouped.length >= 5;

    if (userRewardsMessageGrouped.length === 0) {
        return <LifeSpanEmptyWalletMessage>You don't have any rewards.</LifeSpanEmptyWalletMessage>;
    }

    return (
        <LifeSpanTableList isScroll={isScroll}>
            {userRewardsMessageGrouped.map((reward) => {
                const { name, boosts, expiry } = reward;
                const time = renderTimer(expiry);
                const callbackOnClick = getPromotionLink(
                    lifeSpanState,
                    reward.routeInfo,
                    starRouter,
                    appSportsBookState.models,
                    appSportsBookState.betSlipState
                );

                return (
                    <LifeSpanTableListItem
                        key={reward.id}
                        onClick={callbackOnClick}
                    >
                        <LifeSpanListTableColumn place='first'>
                            <LifeSpanTableBoostInfo>{boosts}</LifeSpanTableBoostInfo>
                        </LifeSpanListTableColumn>
                        <LifeSpanListTableColumn place='second'>{name}</LifeSpanListTableColumn>
                        <LifeSpanListTableColumn place='third'>{time}</LifeSpanListTableColumn>
                    </LifeSpanTableListItem>
                );
            })}
        </LifeSpanTableList>
    );
};

const renderWalletHeader = (userRewardsMessageGrouped: WalletType[]): JSX.Element | null => {
    if (userRewardsMessageGrouped.length === 0) {
        return null;
    }

    return (
        <LifeSpanTableHeader>
            <LifeSpanHeaderTableColumn place='first'>Boost</LifeSpanHeaderTableColumn>
            <LifeSpanHeaderTableColumn place='second'>Name</LifeSpanHeaderTableColumn>
            <LifeSpanHeaderTableColumn place='third'>Expires</LifeSpanHeaderTableColumn>
        </LifeSpanTableHeader>
    );
};

export const LifeSpanWallet = observer('LifeSpanWallet', () => {
    const appState = useAppStateContext();
    const { appLayoutsState } = appState;
    const { lifeSpanState } = appLayoutsState;
    const { userRewardsMessageGrouped } = lifeSpanState;

    return (
        <LifeSpanContainer>
            <LifeSpanWrapper>
                <LifeSpanHeader>
                    <LifeSpanHeaderIcon />

                    <LifeSpanHeaderTitle>Available Boost</LifeSpanHeaderTitle>

                    <LifeSpanHeaderCloseIconWrapper onClick={lifeSpanState.toggleWalletOpen} />
                </LifeSpanHeader>

                <LifeSpanTable>
                    {renderWalletHeader(userRewardsMessageGrouped)}

                    <LifeSpanTableListContainer>
                        {renderWalletItems(userRewardsMessageGrouped, appState)}
                    </LifeSpanTableListContainer>
                </LifeSpanTable>
            </LifeSpanWrapper>
        </LifeSpanContainer>
    );
});
