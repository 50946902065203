import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Wrapper } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/AlternativeEvent.style';
import { Header } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/header/Header';
import { PromoBanner } from './AlternativeEvent/promoBanner/PromoBanner';
import { AlternativeEventDetails } from './AlternativeEvent/alternativeEventDetails/AlternativeEventDetails';
import { AlternativeMainContent } from './AlternativeEvent/alternativeMainContent/AlternativeMainContent';
import { AlternativeNavigation } from './AlternativeEvent/alternativeNavigation/AlternativeNavigation';
import { AlternativeMarketFilters } from './AlternativeEvent/alternativeMarketFilters/AlternativeMarketFilters';
import { EventRouteType, RacecardMeetingsRouteType, RacecardRaceRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { useAppStateContext } from 'src/appState/AppState';

interface AlternativeEventPropsType {
    currentView: EventRouteType | RacecardMeetingsRouteType | RacecardRaceRouteType;
}

export const AlternativeEvent = observer('AlternativeEvent', (props: AlternativeEventPropsType) => {
    const appState = useAppStateContext();
    const models = appState.appSportsBookState.models;
    const eventId = props.currentView.name === 'event' ? models.id.getEventId(props.currentView.id) : models.id.getEventIdOption(props.currentView.selected);

    return (
        <Wrapper>
            <AlternativeNavigation currentView={props.currentView} />
            <Header eventId={eventId} />
            <PromoBanner eventId={eventId} />
            <AlternativeEventDetails eventId={eventId} />
            <AlternativeMarketFilters eventId={eventId} />
            <AlternativeMainContent eventId={eventId}/>
        </Wrapper>
    );
});
