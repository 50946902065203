import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { ActiveHomePageCarouselType } from 'src/api/config/cms/getActiveHomePageCarousel';
import { HomePageCarouselItemState } from './HomePageCarouselItem.state';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import PlaceholderImage from 'src/domains/layouts/webview/components/homePageCarouselPromos/assets/default_hpc_image.webp';
import {
    HomePageCarouselBettingItem,
    CardBodyTitle,
    CardBodySubTitle,
    SelectionPromoWrapper,
    SelectionText,
    SelectionPrice,
    SelectionsWrapper,
    CardBettingWrapper,
    CardFooterWrapper,
    CardFooterText,
    TcApplied,
    LazyImageItem,
} from './HomePageCarouselPromos.style';

interface ItemTypeBettingType {
    promo: ActiveHomePageCarouselType;
}

export const ItemTypeBetting = observer('ItemTypeBetting', ({ promo }: ItemTypeBettingType) => {
    const { appCasinoState, appLayoutsState, appSportsBookState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const { betSlipState } = appSportsBookState;

    const [state] = useState(() => new HomePageCarouselItemState(promo, starRouter, appCasinoState));
    const { selectionDetails, isShowTC, isTooLongTerms, onApplyTC } = state;

    if (
        promo.market_id === null ||
        promo.market_id === undefined ||
        promo.event_id === null ||
        promo.event_id === undefined
    ) {
        return null;
    }

    const { img_url, bg_color, title, subtitle, tc_text, market_id } = promo;
    const tcText = tc_text ?? '';
    const imageUrl = img_url ?? null;

    return (
        <HomePageCarouselBettingItem
            bgColor={bg_color ?? undefined}
            data-test={`promo-carousel-item-${promo.title}`}
        >
            {imageUrl === null ? (
                <LazyImageItem
                    imgBase={{ src: PlaceholderImage }}
                    imgFit='contain'
                    alt=''
                    placeholder={true}
                />
            ) : (
                <LazyImageItem
                    imgBase={{ src: imageUrl }}
                    imgFit='contain'
                    alt={`Promo item background: ${title}`}
                    placeholder={true}
                />
            )}

            <CardBettingWrapper onClick={state.handleRedirect}>
                <CardBodyTitle data-test='promo-carousel-title'>{title}</CardBodyTitle>
                <CardBodySubTitle data-test='promo-carousel-subtitle'>{subtitle}</CardBodySubTitle>
            </CardBettingWrapper>

            <SelectionsWrapper isOneElem={selectionDetails.length === 1}>
                {selectionDetails.map((selection) => {
                    const selectionModel = market_id.getSelectionId(selection.id).getModel();
                    if (selectionModel === null) {
                        return null;
                    }

                    const selectionForView = selectionModel.forView();

                    if (selectionForView === null) {
                        return null;
                    }
                    const selectionInBetSlip = betSlipState.ids.getSingleId(selectionModel.id2, 0).getModel();
                    const isSelected = selectionInBetSlip.legId !== null;

                    return (
                        <SelectionPromoWrapper
                            key={selection.id}
                            isSelected={isSelected}
                            isSuspended={selectionForView.suspended}
                            onClick={(): void => betSlipState.legsState.betslipData.onAddSimpleBet(selectionModel.id2)}
                            itemsInRow={selectionDetails.length}
                            data-test={`promo-carousel-selection-${selection.label}`}
                        >
                            {selectionDetails.length > 1 ? <SelectionText>{selection.label}</SelectionText> : null}
                            <SelectionPrice
                                isOneElem={selectionDetails.length === 1}
                                isSuspended={selectionForView.suspended}
                                isSelected={isSelected}
                            >
                                {selectionForView.displayPrice}
                            </SelectionPrice>
                        </SelectionPromoWrapper>
                    );
                })}
            </SelectionsWrapper>
            {tcText === '' ? null : (
                <CardFooterWrapper>
                    {isShowTC === true || isTooLongTerms === false ? (
                        <CardFooterText
                            dangerouslySetInnerHTML={{ __html: tcText }}
                            data-test='promo-carousel-tc'
                        />
                    ) : (
                        <TcApplied
                            onClick={onApplyTC}
                            data-test='promo-carousel-tc'
                        >
                            <I18n
                                langKey='promos.terms-and-conditions-apply'
                                defaultText='Terms and Conditions Apply'
                            />
                        </TcApplied>
                    )}
                </CardFooterWrapper>
            )}
        </HomePageCarouselBettingItem>
    );
});
