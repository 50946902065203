import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppState } from 'src/appState/AppState';
import { TableRow, TableCol, TableWrapper, GroupBody, GroupWrapper, Label, Value, ValueWrapper, ValueBox, JsonWrapper } from 'src/domains/layouts/webview/components/debugPanel/DebugPanel.style';
import { CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { RouletteNumberType } from 'src/domains/casino/utils/types';

interface PropsTypes {
    appState: AppState;
    gameModel: CasinoGameModelExt;
}

const formatRuletteNumbers = (rouletteNumbers: Array<RouletteNumberType> | null): string => {
    if (rouletteNumbers === null) {
        return '';
    }

    const results = rouletteNumbers.map(n => n.result);    
    return results.toString();
};

export const RTLGameDetails = observer('RTLGameDetails', ({ appState, gameModel }: PropsTypes) => {
    const rtlGameModel = appState.appCasinoState.realTimeLobbySocket.getModel(gameModel.id)?.values ?? null;

    if (rtlGameModel === null) {
        return null;
    }

    return (
        <GroupWrapper>
            <ValueBox>
                <ValueWrapper>
                    <Label>Game id</Label>
                    <Value>{gameModel.id}</Value>
                </ValueWrapper>
                <ValueWrapper>
                    <Label>Provider</Label>
                    <Value>{gameModel.provider}</Value>
                </ValueWrapper>
                <ValueWrapper>
                    <Label>Launch game id</Label>
                    <Value>{gameModel.launchGameId}</Value>
                </ValueWrapper>
            </ValueBox>

            <GroupBody>
                <TableWrapper>
                    <TableRow isHeadline={true}>
                        <TableCol>Dealer name</TableCol>
                        <TableCol>Table name</TableCol>
                        <TableCol>Has bet limits</TableCol>
                        <TableCol>Min bet</TableCol>
                        <TableCol>Max bet</TableCol>
                        <TableCol>Current seats</TableCol>
                        <TableCol>Max seats</TableCol>
                        <TableCol>Roulette numbers</TableCol>
                    </TableRow>

                    <TableRow>
                        <TableCol>{rtlGameModel.dealerName}</TableCol>
                        <TableCol>{rtlGameModel.tableName}</TableCol>
                        <TableCol>{rtlGameModel.hasBetLimits ? 'y' : 'n'}</TableCol>
                        <TableCol>{rtlGameModel.minBet.value}</TableCol>
                        <TableCol>{rtlGameModel.maxBet.value}</TableCol>
                        <TableCol>{rtlGameModel.currentSeats}</TableCol>
                        <TableCol>{rtlGameModel.maxSeats}</TableCol>
                        <TableCol>
                            <JsonWrapper>{ formatRuletteNumbers(rtlGameModel.rouletteNumbers) }</JsonWrapper>
                        </TableCol>
                    </TableRow>
                </TableWrapper>
            </GroupBody>
        </GroupWrapper>
    );
});
