import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';


export const SrgReopenWrapper = withConfig(theme => styled('div', { label: 'SrgReopenWrapper' })`
    align-items: center;
    background-color: ${theme.star.srgBanner.bgColor};
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.srgBanner};
    @media ${theme.star.mediaQuery.desktop} {
        padding: 4px;
        position: relative;
    }
`);

export const SrgReopenContainer = styled('div', { label: 'SrgReopenContainer' })`
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    margin: 0 10px;
`;

export const SrgReopenBoxHeader = withConfig(theme => styled('p', { label: 'SrgReopenBoxHeader' })`
    color: ${theme.star.srgBanner.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0;
`);


export const SrgReopenText = withConfig(theme => styled('p', { label: 'SrgReopenText' })`
    color: ${theme.star.srgBanner.txtColorSecondary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 0;
    text-align: left;
`);

export const SrgReopenButton = withConfig(theme => styled(Button, { label: 'SrgReopenButton' })`
    align-self: flex-start;
    margin-left: 8px;
    position: relative;
    text-transform: capitalize;

    @media ${theme.star.mediaQuery.tablet} {
        align-self: center;
    }
    @media ${theme.star.mediaQuery.desktop} {
        padding: 10px 12px;
    }
`);

