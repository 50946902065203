import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    ConfirmBtnWrapper,
    ErrorMessageWrapper,
    VerifyWithSMSBtnWrapper,
    VerifyWithSMSForm,
    VerifyWithSmsWrapper,
    SmsMsgWrapper,
} from './VerifyWithSMS.style';
import { useAppStateContext } from 'src/appState/AppState';
import { VerifyWithSMSState } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/VerifyWithSMS.state';
import { SMSCode } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/SMSCode';
import { observer } from 'src/utils/mobx-react';
import {
    VerifyInformationStyle,
    SendAgainStyle,
} from 'src/domains/players/webview/components/Account/verifyAccountTab/VerifyAccountTab.style';
import { VerifyWithEmailSendAgain } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithEmail/VerifyWithEmail.style';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const preventDefault = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
};

export const VerifyWithSMS = observer('VerifyWithSMS', () => {
    const { appLayoutsState, appPlayersState, trpcClient } = useAppStateContext();
    const { configComponents, languagesState, starRouter } = appLayoutsState;
    const { loginState, usersState } = appPlayersState;
    const { config } = configComponents;
    const verificationCodes = ['cell-0', 'cell-1', 'cell-2', 'cell-3'];
    const [smsVerifyState] = useState(
        () =>
            new VerifyWithSMSState(
                languagesState,
                verificationCodes,
                starRouter,
                loginState,
                config,
                usersState,
                trpcClient
            )
    );

    if (!smsVerifyState.isEmailSent) {
        return (
            <VerifyWithSMSBtnWrapper
                size='large'
                onClick={smsVerifyState.handleClickSendSMS}
            >
                <I18n
                    langKey='account.verify-account.through-sms.submit.label'
                    defaultText='Verify with sms'
                />
            </VerifyWithSMSBtnWrapper>
        );
    }

    return (
        <VerifyWithSmsWrapper className='verify-account-with-sms'>
            {smsVerifyState.isFormVisible && (
                <VerifyInformationStyle>
                    <p>
                        <I18n
                            langKey='account.verify-account.through-sms.description'
                            defaultText='Please check your text messages. We’ve sent you a SMS with a 4 digit code to verify your account.'
                        />
                    </p>
                    <VerifyWithEmailSendAgain>
                        <I18n
                            langKey='account.verify-account.resend.description'
                            defaultText='Haven’t got it?'
                        />
                        <SendAgainStyle
                            onClick={smsVerifyState.handleClickSendSMS}
                            href='#'
                        >
                            <I18n
                                langKey='account.verify-account-sms.resend.button'
                                defaultText='SEND AGAIN'
                            />
                        </SendAgainStyle>
                    </VerifyWithEmailSendAgain>
                </VerifyInformationStyle>
            )}

            <SmsMsgWrapper>
                <I18n
                    langKey='account.verify-account.through-sms.verification-code.title'
                    defaultText='VERIFICATION CODE'
                />
            </SmsMsgWrapper>

            {smsVerifyState.errorMessage === null ? null : (
                <ErrorMessageWrapper>
                    <Messages
                        key='error-message'
                        marginBottom='16px'
                        message={smsVerifyState.errorMessage}
                        type='error'
                    />
                </ErrorMessageWrapper>
            )}

            {smsVerifyState.isFormVisible && (
                <VerifyWithSMSForm
                    id='verify-account-with-sms'
                    onSubmit={preventDefault}
                >
                    <SMSCode inputs={smsVerifyState.inputs} />
                    <ConfirmBtnWrapper
                        type='submit'
                        disabled={!smsVerifyState.isFormValid}
                        size='large'
                        onClick={smsVerifyState.send}
                        key='submit-btn'
                    >
                        <I18n
                            langKey='account.verify-account.through-sms.verification-code.submit.label'
                            defaultText='Confirm'
                        />
                    </ConfirmBtnWrapper>
                </VerifyWithSMSForm>
            )}
        </VerifyWithSmsWrapper>
    );
});
