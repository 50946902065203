import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { WatchLiveButtonRaceCard } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails.style';
import { RaceStreamButtonState } from './RaceStreamButton.state';
import { observer } from 'src/utils/mobx-react';

interface PropTypes {
    race: EventModel;
}

export const RenderStreamButton = observer('RenderStreamButton', ({ race }: PropTypes): JSX.Element | null => {
    const { appLayoutsState, appPlayersState, appSportsBookState, sdkCustomer } = useAppStateContext();
    const {
        languagesState,
        configComponents: { config },
    } = appLayoutsState;
    const { accountState } = appPlayersState;
    const { betsState, streamingState } = appSportsBookState;
    const [state] = useState(
        () =>
            new RaceStreamButtonState(
                race,
                streamingState,
                languagesState,
                accountState,
                betsState,
                sdkCustomer,
                config.shouldPlaceBetToWatchStream
            )
    );

    if (state.stream === null) {
        return null;
    }

    return (
        <WatchLiveButtonRaceCard
            isDisabled={state.isDisabled}
            onClick={state.onClickHandler}
            streamOn={streamingState.openEventId === race.id}
        >
            {state.buttonText}
        </WatchLiveButtonRaceCard>
    );
});
