import { Amount } from 'src_common/common/amount/Amount';
import { trimZerosFromRight } from 'src_common/common/amount/amountUtils';
import { z } from 'zod';

export const CurrencyCodeZod = z.enum([
    'GBP',
    'EUR',
    'XTS',
    'CAD',
    'NZD',
    'USD',
    'BTC',
    'BRL',
    'PLN',
    'RON'
]);

export type CurrencyType = z.infer<typeof CurrencyCodeZod>;

export const isCurrencyCode = (value: unknown): value is CurrencyType =>
    CurrencyCodeZod.safeParse(value).success;

export type CurrencySymbolType = '£' | '€' | 'X' | '$' | '₿' | 'R$' | 'C$' | 'NZ$' | 'PLN' | 'RON';

const symbols: Record<CurrencyType, CurrencySymbolType> = {
    GBP: '£',
    EUR: '€',
    XTS: 'X',
    CAD: 'C$',
    NZD: 'NZ$',
    USD: '$',
    BTC: '₿',
    BRL: 'R$',
    PLN: 'PLN',
    RON: 'RON'
};

export const moneySymbol = (currency: CurrencyType): CurrencySymbolType => {
    return symbols[currency];
};

const formatCommaForLeft = (units: string): string => {
    const result = [];
    let group = [];

    const chars = units.split('').reverse();
    const max = chars.length;

    for (let i = 0; i < max; i++) {
        const char = chars[i];

        if (char === undefined) {
            throw Error('Char expected');
        }

        group.push(char);

        if (group.length === 3) {
            result.push(group.reverse().join(''));
            group = [];
        }
    }

    if (group.length > 0) {
        result.push(group.reverse().join(''));
    }

    return result.reverse().join(',');
};

export const formatCommalForBigUnits = (value: string): string => {
    const chunks = value.split('.');

    const units = chunks.shift();

    if (units === undefined) {
        return formatCommaForLeft(value);
    }

    chunks.unshift(formatCommaForLeft(units));
    return chunks.join('.');
};

/**
* @deprecated - please use Amount.format()
*/
export const formatAmountWithCurrency = (
    currency: CurrencyType,
    amount: Amount | null | undefined | 'n/a',
    withoutZeros?: boolean,
    withoutCurrencySymbol?: boolean,
    withoutRedundantDecimals?: boolean
): string => {
    if (amount === null || amount === undefined || amount === 'n/a') {
        return 'n/a';
    }

    //Need check instanceof because the function is use in jsx files. Can be removed after refactor all js/jsx
    if ((amount instanceof Amount) === false) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`amount must be instance of Amount class. Current amount: ${amount} is type of ${typeof amount}`);
    }

    const minusSymbol = amount.isLessThanZero() ? '-' : '';
    const currencySymbol: string = withoutCurrencySymbol === true ? '' : symbols[currency];
    const value = amount.toFixed(2).replace('-', '');

    if (currency === 'BTC') {
        const cryptoValue = amount.isLessThanZero() ? amount.toFixed(8).replace('-', '') : amount.toFixed(8);
        return minusSymbol + currencySymbol + cryptoValue;
    } else if (withoutZeros !== true) {
        const valueChunks = value.split('.');

        if (valueChunks.length > 2) {
            throw Error('Incorrect format');
        }

        const left = valueChunks[0];

        if (left === undefined) {
            throw Error('Incorrect format');
        }

        const right = (valueChunks[1] ?? '').padEnd(2, '0');

        /* TODO: Clean commented code below
       or use it instead of current implementation after decision
       with displaying numbers with decimals .00

    ** const formattedRight = right === '00' ? '' : `.${right}`; **
    */
        const formattedRight = withoutRedundantDecimals === true && right === '00' ? '' : `.${right}`;
        return `${minusSymbol}${currencySymbol}${formatCommalForBigUnits(left)}${formattedRight}`;
    }


    return trimZerosFromRight(minusSymbol + currencySymbol + formatCommalForBigUnits(value));
};

export type formatAmountByCurrencyConfig = {
    withoutZeros?: boolean;
    withoutCurrencySymbol?: boolean;
    /** @description cuts off money decimals if it's .00, e.g. €2.00 => €2 */
    withoutRedundantDecimals?: boolean;
}

const defaultConfig: formatAmountByCurrencyConfig = {
    withoutZeros: false,
    withoutCurrencySymbol: false,
    withoutRedundantDecimals: false
};

export const formatAmountByCurrency = (currency: CurrencyType, amount: Amount | null | undefined | 'n/a', config?: formatAmountByCurrencyConfig): string => {
    config = {
        ...defaultConfig,
        ...config
    };
    return formatAmountWithCurrency(currency, amount, config.withoutZeros, config.withoutCurrencySymbol, config.withoutRedundantDecimals);
};
