import * as React from 'react';

import { LoaderTxt, LoadingSpinnerWrapper, LoaderSpinner } from 'src/domains/layouts/webview/components/loader/LoaderSpinner.style';

import { observer } from 'src/utils/mobx-react';



interface LoadingSpinnerPropsType {

    children?: React.ReactNode | JSX.Element;

    className?: string;

    size?: number;

    version?: 'light' | 'dark';

}



export const Loader = observer(

    'LoadingSpinner',

    ({ className, children, version, size }: LoadingSpinnerPropsType): JSX.Element => {

        if (children !== undefined) {

            return (

                <LoadingSpinnerWrapper

                    className={className}

                    version={version}

                >

                    <LoaderSpinner

                        size={size}

                        version={version}

                    />

                    <LoaderTxt size={size}>{children}</LoaderTxt>

                </LoadingSpinnerWrapper>

            );

        }



        return (

            <LoadingSpinnerWrapper

                className={className}

                version={version}

            >

                <LoaderSpinner

                    size={size}

                    version={version}

                />

            </LoadingSpinnerWrapper>

        );

    }

);

