import { computed, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getContactUsElements';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { TrpcClient } from 'src/appState/TrpcClient';

export class ContactUsState {
    private readonly footerContactUsResource: Resource<Response200Type>;

    public constructor(
        private readonly config: ConfigType,
        trpcClient: TrpcClient
    ) {
        makeObservable(this);
        this.footerContactUsResource = new Resource(async (): Promise<Response200Type> => {
            return await trpcClient.client.cms.getFooterContactUs.query();
        });
    }

    public get data(): Response200Type | null {
        const data = this.footerContactUsResource.get();

        if (data.type === 'ready' && data.value.length > 0) {
            return data.value.sort((a, b) => (a.display_order > b.display_order ? 1 : -1));
        }

        return this.config.contactUsTemporaryDefaultMock.sort((a, b) => (a.display_order > b.display_order ? 1 : -1));
        // return null;
    }

    @computed public get isDataReady(): boolean {
        return this.data !== null;
    }
}
