import React, { ReactElement, useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { makeObservable, action, observable } from 'src_common/common/mobx-wrapper';

type LazyLoadWrapperProps = {
    children: ReactElement;
}

interface RefObserverType {
    ref: HTMLDivElement;
    observer: IntersectionObserver;
}

class LazyLoadWrapperState {
    @observable public isVisible: boolean;
    @observable private refObserver: RefObserverType | null = null;

    public constructor() {
        makeObservable(this);
        this.isVisible = false;
    }

    @action private setVisible = (): void => {
        this.isVisible = true;
    };

    @action public setRef = (ref: HTMLDivElement | null): void => {
        if (this.refObserver !== null) {
            this.refObserver.observer.unobserve(this.refObserver.ref);
            this.refObserver = null;
        }

        if (ref !== null) {
            const observer = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting === true) {
                        this.setVisible();
                    }
                }
            }, { rootMargin: '50px' });

            observer.observe(ref);

            this.refObserver = {
                ref,
                observer
            };
        }
    };
}

export const LazyLoadWrapper = observer('LazyLoadWrapper', (props: LazyLoadWrapperProps): JSX.Element | null => {
    const [state] = useState(new LazyLoadWrapperState());

    return (
        <div ref={state.setRef}>
            {
                state.isVisible ? (
                    <React.Suspense>
                        {props.children}
                    </React.Suspense>
                ) : null
            }
        </div>
    );
});
