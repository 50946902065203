import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    TransactionDetailsWrapper,
    DepositReceiptDashedBorder,
    ParticularDetail,
    ParticularValue,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/transactionReceipt/TransactionReceipt.style';
import { useAppStateContext } from 'src/appState/AppState';
import { computed, makeObservable } from 'mobx';
import { UsersState } from 'src/domains/players/state/UsersState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { DateTime } from 'src_common/utils/time/time';
import { TransactionCallbackType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';

interface TransactionReceiptTypes {
    transactionCallback: TransactionCallbackType;
    viewType: 'success' | 'failure';
}

class TransactionReceiptState {
    public constructor(
        private readonly configComponents: ConfigComponents,
        private readonly usersState: UsersState,
        private readonly transactionCallback: () => TransactionCallbackType
    ) {
        makeObservable(this);
    }

    @computed public get depositAmount(): string | undefined {
        const amount = this.transactionCallback().data.amount;
        if (amount === undefined) {
            return undefined;
        }
        return this.usersState.money(this.configComponents.precision.newFromAnything(amount));
    }

    @computed public get datecreatedAt(): string | undefined {
        return DateTime.from(this.transactionCallback().data.createdAt)?.format('D/MM/YYYY');
    }

    @computed public get timecreatedAt(): string | undefined {
        return DateTime.from(this.transactionCallback().data.createdAt)?.format('H:mm:ss');
    }
}

export const TransactionReceipt = observer('TransactionReceipt', ({ transactionCallback }: TransactionReceiptTypes) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents } = appLayoutsState;

    const [state] = useState(
        () => new TransactionReceiptState(configComponents, appPlayersState.usersState, () => transactionCallback)
    );
    const { transactionId } = transactionCallback;
    const { authCode, orderId, paymentMethod } = transactionCallback.data;
    return (
        <TransactionDetailsWrapper>
            <DepositReceiptDashedBorder data-test='deposit-receipt-dashed-border' />

            {transactionId === undefined ? undefined : (
                <ParticularDetail data-test='transaction-id'>
                    <I18n
                        langKey='account.top-up.success.transaction-id'
                        defaultText='Transaction ID'
                    />
                    <ParticularValue>{transactionId}</ParticularValue>
                </ParticularDetail>
            )}

            {orderId === undefined ? undefined : (
                <ParticularDetail data-test='transaction-reference'>
                    <I18n
                        langKey='account.top-up.success.transaction-reference'
                        defaultText='Transaction reference'
                    />
                    <ParticularValue>{orderId}</ParticularValue>
                </ParticularDetail>
            )}

            {state.depositAmount === undefined ? undefined : (
                <ParticularDetail data-test='transaction-deposit-amount'>
                    <I18n
                        langKey='account.top-up.success.deposit-amount'
                        defaultText='Deposit Amount'
                    />
                    <ParticularValue>{state.depositAmount}</ParticularValue>
                </ParticularDetail>
            )}

            {paymentMethod === undefined ? undefined : (
                <ParticularDetail data-test='transaction-payment-method'>
                    <I18n
                        langKey='account.top-up.success.payment-method'
                        defaultText='Payment Method'
                    />
                    <ParticularValue>****{paymentMethod.substring(paymentMethod.length - 4)}</ParticularValue>
                </ParticularDetail>
            )}

            {authCode === undefined ? undefined : (
                <ParticularDetail data-test='transaction-bank-authorization-code'>
                    <I18n
                        langKey='account.top-up.success.bank-authorization-code'
                        defaultText='Bank Authorization Code'
                    />
                    <ParticularValue>{authCode}</ParticularValue>
                </ParticularDetail>
            )}

            <ParticularDetail data-test='transaction-date'>
                <I18n
                    langKey='account.top-up.success.transaction-date'
                    defaultText='Transaction Date'
                />
                <ParticularValue>{state.datecreatedAt}</ParticularValue>
            </ParticularDetail>

            <ParticularDetail data-test='transaction-time'>
                <I18n
                    langKey='account.top-up.success.transaction-time'
                    defaultText='Transaction Time'
                />
                <ParticularValue>{state.timecreatedAt}</ParticularValue>
            </ParticularDetail>
        </TransactionDetailsWrapper>
    );
});
