import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    NavigationPanel,
    LinkItemLinkType,
} from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { computed, makeObservable } from 'mobx';
import { AppCasinoState, ALL_GAMES, CollectionType } from 'src/domains/casino/shared/Types';
import { useAppStateContext } from 'src/appState/AppState';

class State {
    public constructor(
        private readonly appCasinoState: AppCasinoState,
        private readonly collectionType: CollectionType
    ) {
        makeObservable(this);
    }

    @computed public get casinocategories(): Array<LinkItemLinkType> {
        const out: Array<LinkItemLinkType> = [];
        const collection = this.collectionType === 'casino' ? 'casino' : 'live-casino';

        out.push({
            key: collection,
            to: {
                name: collection,
                tabId: ALL_GAMES,
            },
            label: (
                <I18n
                    langKey='left-menu.casino.all-games.label'
                    defaultText='All Games'
                />
            ),
        });

        for (const category of this.appCasinoState.gameStoreExt.getCollection(this.collectionType)
            .activeCategoriesVisibleInNavigation) {
            out.push({
                key: category.name,
                to: {
                    name: collection,
                    tabId: category.id.toString(),
                },
                label: category.name,
            });
        }

        return out;
    }
}

interface NavigationSidebarSectionCasinoPropsType {
    navigationType: CollectionType;
}

export const NavigationSidebarSectionCasino = observer(
    'NavigationSidebarSectionCasino',
    (props: NavigationSidebarSectionCasinoPropsType): React.ReactElement => {
        const { appCasinoState } = useAppStateContext();
        const [state] = React.useState(() => new State(appCasinoState, props.navigationType));
        const casinocategories = state.casinocategories;

        return (
            <NavigationPanel
                title={
                    <I18n
                        langKey='left-menu.casino.categories.title'
                        defaultText='Categories'
                    />
                }
                titleDataTest='categories'
                links={casinocategories}
            />
        );
    }
);
