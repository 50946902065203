import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const PromoCodeWrapper = styled('div', { label: 'PromoCodeWrapper' })`
    margin-top: 11px;
`;

export const PromoCodeHeader = withConfig(theme => styled('span', { label: 'PromoCodeHeader' })`
    align-items: center;
    color: ${theme.star.signUp.txtColorSecondary};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    margin-bottom: 0;
`);

export const ChevronWrapper = styled(ChevronIcon, { label: 'ChevronWrapper' })`
    fill: currentcolor;
    width: 12px;
    margin-right: 4px;
`;

export const PromoCodeInputWrapper = styled('div', { label: 'PromoCodeInputWrapper' })`
    margin: -10px 0 5px 0;
`;
