import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetHistoryLegType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

export const BetRule4DeductionInfo = observer<{ leg: BetHistoryLegType }>(
    'BetRule4DeductionInfo',
    ({ leg }): JSX.Element | null => {
        const deduction = leg.result?.r4Deductions ?? null;

        if (deduction !== null) {
            return (
                <div>
                    <I18n
                        langKey='bets.list.bet.rule4'
                        defaultText='Rule 4 Deduction - {deduction}p'
                        params={{ deduction }}
                    />
                </div>
            );
        }

        return null;
    }
);
