import { importAmd } from './require_amd';
import { Logger } from 'src/domains/common/Logger';

type CallbackType = (data: string) => void;

export interface SocketIO {
    on: (event: string, callback: CallbackType) => void;
    off: (event: string, callback: CallbackType) => void;
    io: {
        connect: () => void;
        disconnect: () => void;
    };
    emit: (eventName: string, data: unknown, callback?: (data: unknown) => void) => void;
}

const getSocketIO = async (websocket_host: string): Promise<() => SocketIO> => {
    // tslint:disable-next-line
    if (typeof window === 'undefined') {
        return new Promise(() => {});
    }

    const io = await importAmd(`${websocket_host}/socket.io/socket.io.js`);

    if (typeof io !== 'function') {
        Logger.captureError('Import socket.io error', 'internal');
        return new Promise(() => {});
    }
    return (): SocketIO => {
        const socket = io( websocket_host, { transports: [ 'websocket', 'polling' ] } );
        return socket;
    };
};

export class SocketIOCache {
    private data: Map<string, Promise<() => SocketIO>>;

    public constructor() {
        this.data = new Map();
    }

    public getByHost = (websocket_host: string): Promise<() => SocketIO> => {
        const item = this.data.get(websocket_host);

        if (item !== undefined) {
            return item;
        }

        const newItem = getSocketIO(websocket_host);
        this.data.set(websocket_host, newItem);
        return newItem;
    };
}
