import { slug } from 'src/utils/deburr';
import DEFAULT from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/default-nba-team.png';

import cle from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/cle.png';
import chi from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/chi.png';
import cha from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/cha.png';
import bos from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/bos.png';
import bkn from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/bkn.png';
import atl from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/atl.png';
import lac from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/lac.png';
import ind from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/ind.png';
import hou from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/hou.png';
import gsw from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/gsw.png';
import det from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/det.png';
import den from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/den.png';
import dal from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/dal.png';
import nyk from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/nyk.png';
import nop from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/nop.png';
import min from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/min.png';
import mil from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/mil.png';
import mia from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/mia.png';
import mem from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/mem.png';
import lal from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/lal.png';
import por from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/por.png';
import sas from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/sas.png';
import was from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/was.png';
import okc from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/okc.png';
import sac from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/sac.png';
import phi from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/phi.png';
import orl from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/orl.png';
import uta from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/uta.png';
import tor from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/tor.png';
import phx from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/assets/logos/phx.png';

export const getTeamShirt = (name: string | null): string => {
    if (name !== null) {
        const slugName = slug(name);
        switch (slugName) {
            case 'cleveland-cavaliers':
                return cle;
            case 'chicago-bulls':
                return chi;
            case 'charlotte-hornets':
                return cha;
            case 'boston-celtics':
                return bos;
            case 'brooklyn-nets':
                return bkn;
            case 'atlanta-hawks':
                return atl;
            case 'los-angeles-clippers':
                return lac;
            case 'indiana-pacers':
                return ind;
            case 'houston-rockets':
                return hou;
            case 'golden-state-warriors':
                return gsw;
            case 'detroit-pistons':
                return det;
            case 'denver-nuggets':
                return den;
            case 'dallas-mavericks':
                return dal;
            case 'new-york-knicks':
                return nyk;
            case 'new-orleans-pelicans':
                return nop;
            case 'minnesota-timberwolves':
                return min;
            case 'milwaukee-bucks':
                return mil;
            case 'miami-heat':
                return mia;
            case 'memphis-grizzlies':
                return mem;
            case 'los-angeles-lakers':
                return lal;
            case 'portland-trail-blazers':
                return por;
            case 'san-antonio-spurs':
                return sas;
            case 'washington-wizards':
                return was;
            case 'oklahoma-city-thunder':
                return okc;
            case 'sacramento-kings':
                return sac;
            case 'philadelphia-76ers':
                return phi;
            case 'orlando-magic':
                return orl;
            case 'utah-jazz':
                return uta;
            case 'toronto-raptors':
                return tor;
            case 'phoenix-suns':
                return phx;
        }
    }

    return DEFAULT;

};
