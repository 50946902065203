import styled from '@emotion/styled';



export const VerifyAccountWithSmsCells = styled('div', { label: 'VerifyAccountWithSmsCells' })`

    align-items: center;

    display: flex;

    flex-flow: row nowrap;

    justify-content: center;

`;

