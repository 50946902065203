import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';

// prettier-ignore
export const CountryCodeIO = t.union([
    t.literal('AZ'), t.literal('AZ'), t.literal('NF'), t.literal('PW'), t.literal('HN'),
    t.literal('CY'), t.literal('GI'), t.literal('AI'), t.literal('LC'), t.literal('UG'),
    t.literal('LS'), t.literal('FM'), t.literal('PS'), t.literal('LI'), t.literal('MA'),
    t.literal('KI'), t.literal('TM'), t.literal('MO'), t.literal('MM'), t.literal('TZ'),
    t.literal('CR'), t.literal('LV'), t.literal('CW'), t.literal('MX'), t.literal('ME'),
    t.literal('PY'), t.literal('TF'), t.literal('ID'), t.literal('IR'), t.literal('SY'),
    t.literal('BF'), t.literal('BM'), t.literal('VU'), t.literal('GH'), t.literal('JP'),
    t.literal('TH'), t.literal('CX'), t.literal('GU'), t.literal('GP'), t.literal('NI'),
    t.literal('NP'), t.literal('CN'), t.literal('BG'), t.literal('MR'), t.literal('AR'),
    t.literal('PG'), t.literal('BQ'), t.literal('IE'), t.literal('EE'), t.literal('ZW'),
    t.literal('UY'), t.literal('RU'), t.literal('CG'), t.literal('TR'), t.literal('BO'),
    t.literal('QA'), t.literal('MP'), t.literal('GM'), t.literal('BB'), t.literal('AW'),
    t.literal('GQ'), t.literal('ST'), t.literal('SX'), t.literal('LA'), t.literal('DJ'),
    t.literal('AO'), t.literal('KN'), t.literal('EH'), t.literal('BT'), t.literal('GT'),
    t.literal('TN'), t.literal('AS'), t.literal('TL'), t.literal('CF'), t.literal('SD'),
    t.literal('BS'), t.literal('LR'), t.literal('TO'), t.literal('IM'), t.literal('PH'),
    t.literal('BY'), t.literal('AX'), t.literal('MC'), t.literal('SR'), t.literal('UM'),
    t.literal('PF'), t.literal('AQ'), t.literal('LB'), t.literal('EG'), t.literal('TJ'),
    t.literal('MU'), t.literal('SO'), t.literal('VC'), t.literal('NU'), t.literal('SC'),
    t.literal('GD'), t.literal('AL'), t.literal('HR'), t.literal('GG'), t.literal('ML'),
    t.literal('SN'), t.literal('PK'), t.literal('SJ'), t.literal('BJ'), t.literal('KY'),
    t.literal('SS'), t.literal('EC'), t.literal('BN'), t.literal('MV'), t.literal('BA'),
    t.literal('CK'), t.literal('FI'), t.literal('IO'), t.literal('MH'), t.literal('TK'),
    t.literal('NZ'), t.literal('NE'), t.literal('KZ'), t.literal('ES'), t.literal('KW'),
    t.literal('BI'), t.literal('HM'), t.literal('FK'), t.literal('GF'), t.literal('BD'),
    t.literal('PR'), t.literal('UZ'), t.literal('TC'), t.literal('MS'), t.literal('IS'),
    t.literal('GW'), t.literal('TG'), t.literal('KG'), t.literal('MG'), t.literal('WS'),
    t.literal('NL'), t.literal('CD'), t.literal('BV'), t.literal('GR'), t.literal('PA'),
    t.literal('SZ'), t.literal('FO'), t.literal('VI'), t.literal('TW'), t.literal('MW'),
    t.literal('JO'), t.literal('PL'), t.literal('GA'), t.literal('TT'), t.literal('DE'),
    t.literal('KP'), t.literal('CV'), t.literal('KR'), t.literal('GS'), t.literal('DO'),
    t.literal('WF'), t.literal('TD'), t.literal('SG'), t.literal('CH'), t.literal('HK'),
    t.literal('BZ'), t.literal('RO'), t.literal('AD'), t.literal('ZM'), t.literal('IL'),
    t.literal('BL'), t.literal('DK'), t.literal('PN'), t.literal('GB'), t.literal('VA'),
    t.literal('TV'), t.literal('SL'), t.literal('BE'), t.literal('HT'), t.literal('MN'),
    t.literal('VE'), t.literal('CC'), t.literal('GN'), t.literal('IT'), t.literal('CU'),
    t.literal('VG'), t.literal('HU'), t.literal('FR'), t.literal('CO'), t.literal('SA'),
    t.literal('CZ'), t.literal('UA'), t.literal('US'), t.literal('DZ'), t.literal('MF'),
    t.literal('ZA'), t.literal('JM'), t.literal('SI'), t.literal('DM'), t.literal('PT'),
    t.literal('NA'), t.literal('GE'), t.literal('NR'), t.literal('RE'), t.literal('PM'),
    t.literal('KE'), t.literal('NC'), t.literal('GL'), t.literal('MQ'), t.literal('AE'),
    t.literal('PE'), t.literal('MZ'), t.literal('NG'), t.literal('LY'), t.literal('CA'),
    t.literal('MY'), t.literal('SB'), t.literal('KM'), t.literal('MT'), t.literal('ET'),
    t.literal('AM'), t.literal('FJ'), t.literal('VN'), t.literal('SM'), t.literal('JE'),
    t.literal('NO'), t.literal('OM'), t.literal('KH'), t.literal('SV'), t.literal('AU'),
    t.literal('AF'), t.literal('SH'), t.literal('YT'), t.literal('ER'), t.literal('RS'),
    t.literal('BR'), t.literal('LK'), t.literal('RW'), t.literal('YE'), t.literal('BH'),
    t.literal('GY'), t.literal('SE'), t.literal('LU'), t.literal('SK'), t.literal('IQ'),
    t.literal('AG'), t.literal('MD'), t.literal('CM'), t.literal('MK'), t.literal('IN'),
    t.literal('CL'), t.literal('CI'), t.literal('LT'), t.literal('BW'), t.literal('AT'),
    t.literal('XK'),
]);

export type CountryCodeType = t.TypeOf<typeof CountryCodeIO>;

export const isCountryCodeType = createGuard(CountryCodeIO);

export type CountryNameType = Record<CountryCodeType, string>;
// prettier-ignore
export const countryName: CountryNameType = {
    GB: 'United Kingdom',
    IE: 'Ireland',
    AL: 'Albania',
    AD: 'Andorre',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AT: 'Austria',
    AW: 'Aruba',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BB: 'Barbade',
    BY: 'Belarus',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CM: 'Cameroon',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CL: 'Chile',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo (Dem. Rep.)',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    ME: 'Crna Gora',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CI: 'Côte D\'Ivoire',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    TL: 'East Timor',
    EG: 'Egypt',
    SV: 'El Salvador',
    ER: 'Eritrea',
    EE: 'Estonia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GT: 'Guatemala',
    GG: 'Guernsey and Alderney',
    GN: 'Guinea',
    GY: 'Guyana',
    HM: 'Heard and McDonald Islands',
    HN: 'Honduras',
    IS: 'Iceland',
    IQ: 'Iraq',
    IM: 'Isle of Man',
    IT: 'Italy',
    JM: 'Jamaica',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MV: 'Maldives',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MR: 'Mauritania',
    MU: 'Mauritius',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands Antilles',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NO: 'Norway',
    OM: 'Oman',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PN: 'Pitcairn',
    QA: 'Qatar',
    RO: 'Romania',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SX: 'Sint Maarten',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    ST: 'São Tomé and Príncipe',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UY: 'Uruguay',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Wenezuela',
    EH: 'Western Sahara',
    ZM: 'Zambia',
    AX: 'Åland Island',
    XK: 'Kosovo',
    NF: 'Norfolk Island',
    CY: 'Cyprus',
    TM: 'Turkmenistan',
    MM: 'Myanmar',
    LV: 'Latvia',
    TF: 'French Southern Territories',
    ID: 'Indonesia',
    IR: 'Iran',
    SY: 'Syrian Arab Republic',
    JP: 'Japan',
    CX: 'Christmas Island',
    GU: 'Guam',
    GP: 'Guadeloupe',
    CN: 'China',
    ZW: 'Zimbabwe',
    RU: 'Russian Federation',
    TR: 'Turkey',
    MP: 'Northern Mariana Islands',
    GQ: 'Equatorial Guinea',
    DJ: 'Djibouti',
    AO: 'Angola',
    AS: 'American Samoa',
    CF: 'Central African Republic',
    SD: 'Sudan',
    PH: 'Philippines',
    UM: 'United States Minor Outlying Islands',
    PF: 'French Polynesia',
    SO: 'Somalia',
    ML: 'Mali',
    PK: 'Pakistan',
    SS: 'South Sudan',
    EC: 'Ecuador',
    FI: 'Finland',
    GF: 'French Guiana',
    BD: 'Bangladesh',
    PR: 'Puerto Rico',
    UZ: 'Uzbekistan',
    GW: 'Guinea-Bissau',
    GR: 'Greece',
    VI: 'Virgin Islands (U.S.)',
    PL: 'Poland',
    DE: 'Germany',
    KP: 'Korea (Democratic People\'s Republic of)',
    KR: 'Korea, Republic of',
    WF: 'Wallis and Futuna',
    TD: 'Chad',
    SG: 'Singapore',
    CH: 'Switzerland',
    HK: 'Hong Kong',
    BZ: 'Belize',
    IL: 'Israel',
    DK: 'Denmark',
    BE: 'Belgium',
    HT: 'Haiti',
    CC: 'Cocos (Keeling) Islands',
    HU: 'Hungary',
    FR: 'France',
    CZ: 'Czechia',
    UA: 'Ukraine',
    US: 'United States of America',
    DZ: 'Algeria',
    PT: 'Portugal',
    RE: 'Réunion',
    PM: 'Saint Pierre and Miquelon',
    NC: 'New Caledonia',
    GL: 'Greenland',
    MQ: 'Martinique',
    AE: 'United Arab Emirates',
    LY: 'Libya',
    CA: 'Canada',
    MY: 'Malaysia',
    ET: 'Ethiopia',
    VN: 'Viet Nam',
    KH: 'Cambodia',
    AU: 'Australia',
    AF: 'Afghanistan',
    YT: 'Mayotte',
    RS: 'Serbia',
    BR: 'Brazil',
    RW: 'Rwanda',
    YE: 'Yemen',
    SK: 'Slovakia',
    IN: 'India',
};

export type CountryEntryType = { id: CountryCodeType; name: string };
export type CountriesType = Array<CountryEntryType>;

export const countries = Object.entries(countryName).reduce<CountriesType>((prev, [id, name]) => {
    if (isCountryCodeType(id)) {
        return [...prev, { id, name }];
    }
    return prev;
}, []);

