import { BoostIcon } from 'src/domains/layouts/shared/assets/icons/BoostIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

interface ABTestButtonOneWrapper {
    isBoosted?: boolean;
}

const renderBoostedWrapper = (isBoosted: boolean | undefined, theme: EmotionTheme): SerializedStyles => {
    if (isBoosted === true) {
        return css`
            color: ${theme.star.lifeSpan.txtColorTertiary};
            font-weight: ${theme.star.fontWeight.bold};
            background-color: ${theme.star.lifeSpan.bgColorQuaternary};
            pointer-events: none;
        `;
    }

    return css`
        font-weight: ${theme.star.fontWeight.regular};
        background-color: ${theme.star.lifeSpan.bgColorQuinary};
        color: ${theme.star.lifeSpan.txtColorQuaternary};
    `;
};

export const ABTestButtonOneWrapper = withConfig(theme => styled(Button, { label: 'ABTestButtonOneWrapper' })<ABTestButtonOneWrapper>`
    ${({ isBoosted }): SerializedStyles => renderBoostedWrapper(isBoosted, theme)}
    align-items: center;
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    height: 48px;
    justify-content: center;
    line-height: 1.4285;
    margin-top: 8px;
    width: 100%;
    color: ${theme.star.lifeSpanBoostButton.txtColor};
`);

export const TextWrapper = withConfig(theme => styled('span', { label: 'TextWrapper' })`
    color: ${theme.star.betslipSelectionList.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.xRegular};
`);

export const BoostWrapperIconAB = styled(BoostIcon, { label: 'BoostWrapperIconAB' })`
    margin-right: 4px;
    display: flex;
    align-items: center;
    fill: currentcolor;
`;

export const PotentialReturnMessage = withConfig(theme => styled('div', { label: 'PotentialReturnMessage' })`
    color: ${theme.star.betslipSelectionList.txtColor};
    display: flex;
    flex-direction: column;
`);

export const ApplyBoostWrapper = withConfig(theme => styled('div', { label: 'ApplyBoostWrapper' })`
    display: flex;
    flex-direction: row;
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    align-items: center;
`);

export const PotentialReturnWrapper = withConfig(theme => styled('div', { label: 'PotentialReturnWrapper' })`
    font-size: ${theme.star.fontSize.xSmall};
`);
