import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { setHeaderAppearance, EventHeaderType } from 'src/domains/sportsbook/webview/components/eventGroupHeader/EventsGroupHeader.style';
import { withConfig } from 'src/withConfig';

export const EventHeaderGroup = withConfig(theme => styled('header', { label: 'EventHeaderGroup' })<EventHeaderType>`
    ${({ small, headerStyle }): SerializedStyles => setHeaderAppearance(theme, small, headerStyle)};
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    min-height: 24px;
    overflow: hidden;
    position: relative;
    @media ${theme.star.mediaQuery.tablet} {
        height: 28px;
    }
`);

export const EventHeaderGroupName = withConfig(theme => styled('h4', { label: 'EventHeaderGroupName' })`
    color: currentcolor;
    flex: 1 1 0;
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    margin: 0;
    overflow: hidden;
    padding: 4px 8px 2px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
`);

export const EventHeaderGroupSelection = withConfig(theme => styled('div', { label: 'EventHeaderGroupSelection' })`
    align-items: center;
    color: ${theme.star.headings.competition.txtColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    flex: 0 0 50px;
    height: 100%;
    justify-content: center;
    text-align: center;
    @media ${theme.star.mediaQuery.desktop} {
        flex-basis: 160px;
    }
`);

export const EventHeaderGroupSelectionName = withConfig(theme => styled('span', { label: 'EventHeaderGroupSelectionName' })`
    display: none;
    @media ${theme.star.mediaQuery.desktop} {
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
`);

export const ChevronIconStyle = styled(ChevronIcon, { label: 'ChevronIconStyle' })`
    fill: currentcolor;
    flex: 0 0 12px;
    margin: 0 8px;
    width: 12px;
`;
