import * as React from 'react';
import { CarouselDots, CarouselDot } from 'src/domains/layouts/webview/components/popups/MarketingNotificationsPopup/Bullets/Bullets.style';
import { observer } from 'src/utils/mobx-react';

interface BulletsPropsType {
    bulletsId: Array<string>;
    currentQuestionId: string;
    bulletsPosition?: 'left' | 'center' | undefined;
    setCurrentId: (id: string) => void;
    className?: string;
}

export const Bullets = observer('Bullets', ({ bulletsId, currentQuestionId, bulletsPosition, setCurrentId, className }: BulletsPropsType): JSX.Element => {
    const list = [];

    for (const bulletId of bulletsId) {
        const activeDot = currentQuestionId === bulletId;

        const onClick = (): void => {
            setCurrentId(bulletId);
        };

        list.push(<CarouselDot key={bulletId} indexOfActive={activeDot} onClick={onClick} />);
    }

    return (
        <CarouselDots bulletsPosition={bulletsPosition} className={className} >
            { list }
        </CarouselDots>
    );
});
