import { Value } from './Value';

type OnDispose = () => void;
export type OnConnect = () => OnDispose;

export interface ConnectType<T, S> {
    connect(self: MobxValue<T>): S,
    dispose(subscription: S, self: MobxValue<T>): void,
}

interface MobxValueParams<T, S> {
    initValue: T,
    connect?: ConnectType<T, S>,
}

/**
 * @deprecated - Please use the newest version: src_common/common/mobx-utils/Value
 */
export class MobxValue<T> {
    private value: Value<T>;

    private constructor(params: MobxValueParams<T, unknown>) {
        
        const connect = params.connect;
        
        if (connect === undefined) {
            this.value = new Value(params.initValue);
        } else {
            this.value = new Value(params.initValue, () => {
                const subscription = connect.connect(this);

                return (): void => {
                    connect.dispose(subscription, this);
                };
            });
        }
        
    }

    public static create<T, S>(params: MobxValueParams<T, S>): MobxValue<T> {
        return new MobxValue(params);
    }

    public setValue(value: T): void {
        this.value.setValue(value);
    }

    public getValue(): T {
        return this.value.getValue();
    }

    public isObserved(): boolean {
        return this.value.isObserved();
    }
}
