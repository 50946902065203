import styled from '@emotion/styled';
import { ChatIcon } from 'src/domains/layouts/shared/assets/icons/ChatIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { styleNavIcon } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { withConfig } from 'src/withConfig';

export const TraderChatWrapper = styled('div', { label: 'TraderChatWrapper' })`
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 13px;
    position: relative;
`;

export const TraderChatButtonFloatingWrapper = withConfig(theme => styled(Button, { label: 'TraderChatButtonFloatingWrapper' })`
    border-radius: 50%;
    bottom: 104px;
    height: 48px;
    overflow: hidden;
    padding: 0;
    position: fixed;
    right: 16px;
    text-transform: none;
    width: 48px;
    z-index: ${theme.star.zIndexGlobal.above};
    @media ${theme.star.mediaQuery.desktop} {
        bottom: 16px;
        right: 296px;
    }
`);

export const TraderChatWrapperFloating = styled(TraderChatWrapper, { label: 'TraderChatWrapperFloating' })`
    padding: 0;
`;

export const TraderChatWrapperTxt = styled('span', { label: 'TraderChatWrapperTxt' })`
    display: block;
    line-height: 1.24;
    position: relative;
`;

export const ChatIconWrapper = styled(ChatIcon, { label: 'ChatIconWrapper' })`
    margin-right: 5px;
    ${styleNavIcon()};
`;

export const ChatIconWrapperFloating = styled(ChatIcon, { label: 'ChatIconWrapperFloating' })`
    ${styleNavIcon()};
`;

export const TraderChatCounter = withConfig(theme => styled('span', { label: 'TraderChatCounter' })`
    align-items: center;
    background-color: ${theme.star.counter.chat.bgColor};
    border-radius: 50%;
    color: ${theme.star.counter.chat.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    height: 16px;
    justify-content: center;
    min-width: 16px;
    position: absolute;
    right: 6px;
    top: -9px;
`);
