import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const VerifyWithEmailBtnWrapper = styled(Button, { label: 'VerifyWithEmailBtnWrapper' })`
    margin: 16px 20px;
    width: calc(100% - 40px);
`;

export const VerifyWithEmailSendAgain = withConfig(theme => styled('p', { label: 'VerifyWithEmailSendAgain' })`
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    margin: 16px 0 8px 0;
    font-size: ${theme.star.fontSize.small};
    justify-content: space-between;
    line-height: 1.3334;
`);
