import { observable, makeObservable } from 'mobx';
import { AppState } from 'src/appState/AppState';

export class YouDecideState {
    public readonly appState: AppState;
    @observable public isConfirmationOpened: boolean = false;

    public constructor(appState: AppState) {
        makeObservable(this);
        this.appState = appState;
    }

    public onAddToBetslipInner = (): void => {
        this.appState.appSportsBookState.rab.addToBetslip();

        this.isConfirmationOpened = true;

        setTimeout(() => {
            this.isConfirmationOpened = false;
        }, 3000);
    };
}
