import { PromiseBox } from 'src_common/common/mobx-utils/PromiseBox';

export class LogContext {
    public constructor(
        private label: string,
    ) {}

    public error = (message: string, data?: unknown): void => {
        if (data === undefined) {
            console.error(`Socket ${this.label} ==> ${message}`);
        } else {
            console.error(`Socket ${this.label} ==> ${message}`, data);
        }
    };

    public warn = (message: string, data?: unknown): void => {
        if (data === undefined) {
            console.warn(`Socket ${this.label} ==> ${message}`);
        } else {
            console.warn(`Socket ${this.label} ==> ${message}`, data);
        }
    };

    public info = (message: string, data?: unknown): void => {
        if (data === undefined) {
            console.info(`Socket ${this.label} ==> ${message}`);
        } else {
            console.info(`Socket ${this.label} ==> ${message}`, data);
        }
    };
}

export interface WebSocketApi {
    close: () => void;
    send: (message: string) => void;
}

interface OpenSocketResult {
    socket: Promise<WebSocketApi | null>;
    done: Promise<void>;
}

export const connect = (
    log: LogContext,
    host: string,
    timeout: number,
    sendMessageCallback: (data: string) => void,
): OpenSocketResult => {
    const result = new PromiseBox<WebSocketApi | null>();
    const done = new PromiseBox<void>();
    const socket = new WebSocket(host);
    let isClose: boolean = false;

    log.info('starting ...');

    const closeSocket = (): void => {
        if (isClose) {
            return;
        }

        log.info('close');

        isClose = true;
        result.resolve(null);
        done.resolve();
        socket.close();
    };

    const socketContext: WebSocketApi = {
        close: closeSocket,
        send: (message: string) => {
            if (isClose) {
                return;
            }
            socket.send(message);
        }
    };

    setTimeout(() => {
        if (result.isFulfilled() === false) {
            log.error(`timeout (${timeout}ms)`);
            closeSocket();
        }
    }, timeout);

    socket.addEventListener('open', (): void => {
        log.info('open');
        result.resolve(socketContext);
    });

    socket.addEventListener('error', (error: Event): void => {
        log.warn('error', error);
        closeSocket();
    });

    socket.addEventListener('close', closeSocket);
    
    socket.addEventListener('message', (event: MessageEvent): void => {
        if (isClose) {
            return;
        }

        const dataRaw = event.data;

        if (typeof dataRaw === 'string') {
            sendMessageCallback(dataRaw);
            return;
        }

        log.error('onMessage - expected string', dataRaw);
    });

    return {
        socket: result.promise,
        done: done.promise
    };
};
