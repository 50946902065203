import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Header, HeaderMainLinks, UnreadCount } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header';
import {
    NavigationSidebar,
    SidebarCloseIconWrapper,
    SidebarSlideIconWrapper,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/NavigationSidebar';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { MessageBarWrapper } from 'src/domains/layouts/webview/components/MessageBarWrapper';
import {
    ContentWrapper,
    ContentWrapperChildren,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/LayoutSkeleton.style';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { CollectionType } from 'src/domains/casino/shared/Types';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

export { Header, HeaderMainLinks, SidebarCloseIconWrapper, UnreadCount, SidebarSlideIconWrapper, NavigationSidebar };

interface PropsType {
    navigation?: boolean;
    children: React.ReactChild;
    currentView: RouteViewType;
    isSearchActive: boolean;
    navigationType: CollectionType | 'global';
    accountView: RightHandSideViewType | null;
    streamRmgWrapperIsVisible?: boolean;
    isStreamOpen?: boolean;
    isAccountOpen?: boolean;
}

const renderContent = (
    navigation: boolean,
    children: React.ReactChild,
    currentView: RouteViewType,
    isSearchActive: boolean,
    navigationType: CollectionType | 'global',
    streamRmgWrapperIsVisible: boolean | undefined,
    isStreamOpen: boolean | undefined,
    isAccountOpen: boolean | undefined
): React.ReactNode => {
    if (navigation) {
        return (
            <ContentWrapper>
                <NavigationSidebar
                    currentView={currentView}
                    isSearchActive={isSearchActive}
                    navigationType={navigationType}
                />
                <ContentWrapperChildren
                    streamRmgWrapperIsVisible={streamRmgWrapperIsVisible}
                    isStreamOpen={isStreamOpen}
                    isAccountOpen={isAccountOpen}
                >
                    {children}
                </ContentWrapperChildren>
            </ContentWrapper>
        );
    }

    const widthInner = getWindowInnerWidth();
    const navSidebar =
        widthInner !== null && widthInner < 1200 ? (
            <NavigationSidebar
                currentView={currentView}
                isSearchActive={isSearchActive}
                navigationType={navigationType}
            />
        ) : null;

    return (
        <>
            {navSidebar}
            {children}
        </>
    );
};

export const LayoutSkeleton = observer('LayoutSkeleton', (props: PropsType) => {
    const {
        navigation,
        children,
        isSearchActive,
        navigationType,
        currentView,
        accountView,
        streamRmgWrapperIsVisible,
        isStreamOpen,
        isAccountOpen,
    } = props;

    return (
        <>
            <Header
                accountView={accountView}
                currentView={currentView}
            />
            <MessageBarWrapper />
            {renderContent(
                navigation ?? true,
                children,
                currentView,
                isSearchActive,
                navigationType,
                streamRmgWrapperIsVisible,
                isStreamOpen,
                isAccountOpen
            )}
        </>
    );
});
