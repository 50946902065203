import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { RacesSliderState } from 'src/domains/sportsbook/shared/Types';
import { GreyhoundRacingIconWrapper, HorseRacingIconWrapper, NextOffToggleWrapper, NextOffRadioWrapper } from './NextOffToggle/NextOffToggle.style';

interface RadioButtonPropsType {
    icon: JSX.Element;
    handleClick: () => void;
    isSelected: boolean;
}

const NextOffRadioButton = observer('NextOffRadioButton', (props: RadioButtonPropsType) => {
    const { handleClick, isSelected, icon } = props;

    return (
        <NextOffRadioWrapper
            onClick={handleClick}
            isSelected={isSelected}
        >
            { icon }
        </NextOffRadioWrapper>
    );
});


interface NextOffTogglePropsType {
    state: RacesSliderState;
}

export const NextOffToggle = observer('NextOffToggle', (props: NextOffTogglePropsType) => {
    const { setHorseRacingNextOff, setGreyhoundRacingNextOff, isSelected } = props.state;

    return (
        <NextOffToggleWrapper>
            <NextOffRadioButton
                icon={<HorseRacingIconWrapper />}
                handleClick={setHorseRacingNextOff}
                isSelected={isSelected('horseracing')}
            />
            <NextOffRadioButton
                icon={<GreyhoundRacingIconWrapper />}
                handleClick={setGreyhoundRacingNextOff}
                isSelected={isSelected('greyhoundracing')}
            />
        </NextOffToggleWrapper>
    );
});
