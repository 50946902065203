import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface WarningIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const WarningIcon = observer(
    'WarningIcon',
    ({ className, onClick }: WarningIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
                data-test='warning-icon'
            >
                <path
                    d='M23.892 22.029L12.757 1.235A.872.872 0 0012 .8a.867.867 0 00-.755.435L.112 22.03a.842.842 0 00.006.85c.156.264.44.425.748.425h22.271a.868.868 0 00.748-.425.847.847 0 00.007-.85v-.001zm-10.64-1.226h-2.5v-2.5h2.5v2.5zm0-4.375h-2.5V8.3h2.5v8.128z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
