import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import styled from '@emotion/styled';
import { AppStoreLogo } from 'src/domains/layouts/shared/assets/logotypes/apps/AppStoreLogo';
import { css, SerializedStyles } from '@emotion/react';
import { GooglePlayLogo } from 'src/domains/layouts/shared/assets/logotypes/apps/GooglePlayLogo';
import { withConfig } from 'src/withConfig';

export const FooterList = styled('nav', { label: 'FooterList' })`
    display: flex;
    flex-flow: column nowrap;
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
`;

export const StoreAppLinks = withConfig(theme => styled(Link, { label: 'StoreAppLinks' })`
    align-items: center;
    align-self: flex-start;
    color: ${theme.star.footerMain.txtColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    text-decoration: none;
    transition: color .2s ease;
    &:hover {
        color: ${theme.star.footerMain.txtColorQuinary};
    }
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
`);

const setIconStore = (): SerializedStyles => {
    return css`
        display: block;
        margin-right: 8px;
    `;
};

export const AppStoreIconWrapper = styled(AppStoreLogo, { label: 'AppStoreIconWrapper' })`
    ${setIconStore};
`;

export const GooglePlayIconWrapper = styled(GooglePlayLogo, { label: 'GooglePlayIconWrapper' })`
    ${setIconStore};
`;
