//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    requestBody: {
        from?: null | undefined | string;
        page: number;
        perPage: number;
        statusOpen?: null | undefined | boolean;
        to?: null | undefined | string;
    };
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/customer/all-bets -> post -> ${code}`);
    };
};

export const Response200IO = t.interface({
    results: t.array(t.interface({
        cashOut: t.boolean,
        channel: t.string,
        comment: t.union([t.null, t.undefined, t.string]),
        currency: t.string,
        eachWay: t.boolean,
        id: t.number,
        ip: t.union([t.null, t.undefined, t.string]),
        legs: t.array(t.interface({
            competition: t.union([t.null, t.undefined, t.interface({
                id: t.string,
                name: t.string,
            })]),
            eachWayTerms: t.union([t.null, t.undefined, t.interface({
                places: t.number,
                reduction: t.interface({
                    den: t.number,
                    num: t.number,
                }),
            })]),
            event: t.interface({
                eventId: t.union([t.null, t.undefined, t.number]),
                id: t.union([t.null, t.undefined, t.string]),
                name: t.string,
                url: t.union([t.null, t.undefined, t.string]),
            }),
            id: t.string,
            inPlay: t.boolean,
            market: t.union([t.null, t.undefined, t.interface({
                id: t.union([t.null, t.undefined, t.number]),
                name: t.union([t.null, t.undefined, t.string]),
                url: t.union([t.null, t.undefined, t.string]),
            })]),
            price: t.union([t.null, t.undefined, t.interface({
                d: t.union([t.null, t.undefined, t.number]),
                f: t.union([t.null, t.undefined, t.string]),
            })]),
            priceType: t.string,
            result: t.union([t.null, t.undefined, t.interface({
                place: t.union([t.null, t.undefined, t.number]),
                r4Deductions: t.union([t.null, t.undefined, t.number]),
                type: t.string,
            })]),
            selection: t.union([t.null, t.undefined, t.interface({
                id: t.union([t.null, t.undefined, t.number]),
                name: t.string,
            })]),
            selections: t.union([t.null, t.undefined, t.array(t.interface({
                market: t.interface({
                    name: t.string,
                }),
                selection: t.interface({
                    name: t.string,
                }),
            }))]),
            spPrice: t.union([t.null, t.undefined, t.interface({
                d: t.union([t.null, t.undefined, t.number]),
                f: t.union([t.null, t.undefined, t.string]),
            })]),
            sport: t.union([t.null, t.undefined, t.interface({
                id: t.string,
                name: t.union([t.null, t.undefined, t.string]),
            })]),
            type: t.string,
        })),
        maxBet: t.union([t.null, t.undefined, t.number]),
        numLines: t.number,
        operatorStakePerLine: t.number,
        operatorTotalStake: t.number,
        payout: t.union([t.null, t.undefined, t.number]),
        placedAt: t.string,
        potentialReturns: t.union([t.null, t.undefined, t.number]),
        stakeFactor: t.number,
        stakePerLine: t.number,
        status: t.string,
        tax: t.union([t.null, t.undefined, t.number]),
        totalStake: t.number,
        transaction: t.interface({
            tags: t.interface({
                bonuses: t.array(t.interface({
                    amount: t.number,
                    id: t.number,
                    type: t.union([t.null, t.undefined, t.string]),
                })),
                freebetCredits: t.array(t.interface({
                    amount: t.union([t.null, t.undefined, t.number]),
                    id: t.union([t.null, t.undefined, t.number]),
                })),
                selections: t.union([t.null, t.undefined, t.array(t.number)]),
            }),
        }),
        type: t.string,
    })),
});
export type Response200Type = {
    results: Array<{
        cashOut: boolean;
        channel: string;
        comment?: null | undefined | string;
        currency: string;
        eachWay: boolean;
        id: number;
        ip?: null | undefined | string;
        legs: Array<{
            competition?: null | undefined | {
                id: string;
                name: string;
            };
            eachWayTerms?: null | undefined | {
                places: number;
                reduction: {
                    den: number;
                    num: number;
                };
            };
            event: {
                eventId?: null | undefined | number;
                id?: null | undefined | string;
                name: string;
                url?: null | undefined | string;
            };
            id: string;
            inPlay: boolean;
            market?: null | undefined | {
                id?: null | undefined | number;
                name?: null | undefined | string;
                url?: null | undefined | string;
            };
            price?: null | undefined | {
                d?: null | undefined | number;
                f?: null | undefined | string;
            };
            priceType: string;
            result?: null | undefined | {
                place?: null | undefined | number;
                r4Deductions?: null | undefined | number;
                type: string;
            };
            selection?: null | undefined | {
                id?: null | undefined | number;
                name: string;
            };
            selections?: null | undefined | Array<{
                market: {
                    name: string;
                };
                selection: {
                    name: string;
                };
            }>;
            spPrice?: null | undefined | {
                d?: null | undefined | number;
                f?: null | undefined | string;
            };
            sport?: null | undefined | {
                id: string;
                name?: null | undefined | string;
            };
            type: string;
        }>;
        maxBet?: null | undefined | number;
        numLines: number;
        operatorStakePerLine: number;
        operatorTotalStake: number;
        payout?: null | undefined | number;
        placedAt: string;
        potentialReturns?: null | undefined | number;
        stakeFactor: number;
        stakePerLine: number;
        status: string;
        tax?: null | undefined | number;
        totalStake: number;
        transaction: {
            tags: {
                bonuses: Array<{
                    amount: number;
                    id: number;
                    type?: null | undefined | string;
                }>;
                freebetCredits: Array<{
                    amount?: null | undefined | number;
                    id?: null | undefined | number;
                }>;
                selections?: null | undefined | Array<number>;
            };
        };
        type: string;
    }>;
};
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiProxyCustomerAllBetsRequest"
 */
export const openapi_proxy_customer_all_bets = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/customer/all-bets${query}`;
    
    const method = 'POST';
    const paramsFetch = {
        url,
        body: params.requestBody,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiProxyCustomerAllBetsParamsType = ParamsType;

export type OpenapiProxyCustomerAllBetsResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiProxyCustomerAllBetsResponse200Type = Response200Type;

export const openapiProxyCustomerAllBetsRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiProxyCustomerAllBetsResponseType> => {
    const response = await openapi_proxy_customer_all_bets(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiProxyCustomerAllBetsRequest - unhandled response ${response.status}`);
};

