import * as React from 'react';
import { AddressesListWrapper, AddressesListItem, AddressesItemChoice } from 'src/domains/players/webview/components/Account/personalDetailsTab/changeAddressProcedure/addressesList/AddressesList.style';

const hash = address => JSON.stringify(address);

class AddressesListItemNew extends React.PureComponent {
    onClick = (e) => {
        e.preventDefault();
        const { item, onChoose } = this.props;
        onChoose(item);
    }

    render() {
        const { item } = this.props;

        return (
            <AddressesListItem>
                <AddressesItemChoice onClick={this.onClick}>
                    { item.Text }
                </AddressesItemChoice>
            </AddressesListItem>
        );
    }
}

class AddressesListNew extends React.PureComponent {
    render() {
        const { addresses, onChoose } = this.props;

        return (
            <AddressesListWrapper
            >
                {addresses.map(address => (
                    <AddressesListItemNew
                        key={hash(address)}
                        onChoose={onChoose}
                        item={address}
                    />
                ))}
            </AddressesListWrapper>
        );
    }
}

export default AddressesListNew;
