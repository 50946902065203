import { createWebApiDriverItem } from 'src_common/browser/apiUtils';
import { accountValidateEmail } from 'src/api/config/accounts/accountValidateEmailData';
import { rabGetPrice } from 'src/api/config/eventsRab/rabGetPrice';
import { getReferredBetSlip } from 'src/api/config/betting/getReferredBetSlip';
import { cashOutsData } from 'src/api/config/betting/cashOutsData';
import { retrieveCashOut } from 'src/api/config/betting/retrieveCashOut';
import { getCheckBetForEvent } from 'src/api/config/streaming/checkBetForEvent';
import { withdrawalsList } from 'src/api/config/accounts/accountsWithdrawalsList';
import { cancelWithdrawal } from 'src/api/config/accounts/accountsCancelWithdrawal';
import { navigationHeaderLinksActive } from './config/cms/navigationHeaderActiveLinks';
import { get_promo_notifications_active } from './config/cms_new/promo_notifications/get_promo_notifications_active';
import { get_hero_widget_active } from './config/cms_new/hero_widget/get_hero_widget_active';
import { getLiveNotificationsActive } from './config/cms_new/liveNotificationsActive/getLiveNotificationsActive';
import { getUserFavouritesSport } from './config/cms/getUserFavouritesSport';
import { postUserFavouritesSport } from './config/cms/postUserFavouritesSport';
import { getActiveQuickLinkTray } from './config/cms/getActiveQuickLinkTray';
import { get_promo_codes } from 'src/api/config/promo_codes/get_promo_codes';
import { getFooterContactUsElements } from 'src/api/config/cms_new/footer/getFooterContactUsElements';
import { put_update_promo_code } from 'src/api/config/promo_codes/put_update_promo_code';
import { getActiveHomePageCarousel } from './config/cms/getActiveHomePageCarousel';
import { createAccount } from 'src/api/config/accounts/createAccount';

//Temporarily has to remain due to login problems for betzone and rhino operators
import { loginRequest } from 'src/api/config/accounts/login';
import { ping } from './config/session/ping';
import { accountBasicData } from 'src/api/config/accounts/accountBasicData';
import { getHeadlineCarouselActive } from './config/cms_new/headlineCarouselActive/getHeadlineCarouselActive';
import { get_weather } from './config/weather/get_weather';
import { getActiveWeatherLocation } from 'src/api/config/cms_new/weather_data/getActiveWeatherLocation';
import { getActivePromotedSportsElement } from './config/cms_new/getActivePromotedSports/getActivePromotedSports';
import { getActiveHomePageCarouselV2 } from './config/cms/getActiveHomePageCarouselV2';

/**
 * @deprecated - Please start using the new session.call mechanism
 */
export const configCommon = {
    rabGetPrice: createWebApiDriverItem(rabGetPrice),
    accountValidateEmail: createWebApiDriverItem(accountValidateEmail),
    createAccount: createWebApiDriverItem(createAccount),
    getReferredBetSlip: createWebApiDriverItem(getReferredBetSlip),
    cashOutsData: createWebApiDriverItem(cashOutsData),
    retrieveCashOut: createWebApiDriverItem(retrieveCashOut),
    checkBetForEvent: createWebApiDriverItem(getCheckBetForEvent),
    withdrawalsList: createWebApiDriverItem(withdrawalsList),
    cancelWithdrawal: createWebApiDriverItem(cancelWithdrawal),
    navigationHeaderLinksActive: createWebApiDriverItem(navigationHeaderLinksActive),
    get_promo_notifications_active: createWebApiDriverItem(get_promo_notifications_active),
    get_hero_widget_active: createWebApiDriverItem(get_hero_widget_active),
    getLiveNotificationsActive: createWebApiDriverItem(getLiveNotificationsActive),
    getUserFavouritesSport: createWebApiDriverItem(getUserFavouritesSport),
    postUserFavouritesSport: createWebApiDriverItem(postUserFavouritesSport),

    getActiveQuickLinkTray: createWebApiDriverItem(getActiveQuickLinkTray),
    get_promo_codes: createWebApiDriverItem(get_promo_codes),
    getFooterContactUs: createWebApiDriverItem(getFooterContactUsElements),
    put_update_promo_code: createWebApiDriverItem(put_update_promo_code),
    getActiveHomePageCarousel: createWebApiDriverItem(getActiveHomePageCarousel),
    getActiveHomePageCarouselV2: createWebApiDriverItem(getActiveHomePageCarouselV2),

    //Temporarily has to remain due to login problems for betzone and rhino operators
    login: createWebApiDriverItem(loginRequest),
    accountBasicData: createWebApiDriverItem(accountBasicData),
    ping: createWebApiDriverItem(ping),

    getHeadlineCarouselActive: createWebApiDriverItem(getHeadlineCarouselActive),

    getWeather: createWebApiDriverItem(get_weather),
    getWeatherLocation: createWebApiDriverItem(getActiveWeatherLocation),

    getActivePromotedSportsElement: createWebApiDriverItem(getActivePromotedSportsElement),
};

/**
 * @deprecated - Please start using the new session.call mechanism
 */
export const apiCommon = configCommon;
