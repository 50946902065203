import { makeObservable } from 'src_common/common/mobx-wrapper';
import { observable, computed, action } from 'mobx';
import { ConfigType, LanguagesAvailableType } from 'src/domains/layouts/config/features/types';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { ExternalApiEmmiters } from 'src/domains/layouts/state/externalApi/ExternalApiEmmiters';

const convertCodeToName = (id: string, list: LanguagesAvailableType): string => {
    for (const lang of list) {
        if (lang.langCode === id) {
            return lang.langName;
        }
        continue;
    }
    return id;
};

export class LanguageSwitcherState {
    @observable public isListExpanded: boolean = false;

    public constructor(
        private readonly config: ConfigType,
        private readonly languagesState: LanguagesState,
        private readonly externalApiEmittersInner: ExternalApiEmmiters,
        private readonly isMobileLogout: () => boolean
    ) {
        makeObservable(this);
    }

    @computed public get activeLanguage(): string | null {
        return this.languagesState.userLang;
    }

    @computed public get currentLanguageName(): string | null {
        if (this.activeLanguage !== null) {
            return convertCodeToName(this.activeLanguage, this.alternativeLanguages);
        }
        return null;
    }

    @computed public get alternativeLanguages(): LanguagesAvailableType {
        return this.config.languagesAvailable;
    }

    @action public toggleLanguageList = (ref: HTMLElement | null): void => {
        this.isListExpanded = !this.isListExpanded;
        if (this.isListExpanded && ref !== null) {
            setTimeout(() => {
                ref.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        }
    };

    @action public onChangeLanguage = (lang: string): void => {
        this.isListExpanded = false;
        this.languagesState.setUserLang(lang);
        if (this.isMobileLogout() === false) {
            window.location.reload();
        } else {
            this.externalApiEmittersInner.emitEventLanguageClick(lang);
        }
    };
}
