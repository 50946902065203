import React from 'react';
import {
    BogMessageIndicator,
    BogMessageWrapper,
} from 'src/domains/layouts/webview/components/Messages/BogBanner.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';

interface BogBannerPropsType {
    message: React.ReactNode | string;
}

export const BogBanner = observer('BogBanner', ({ message }: BogBannerPropsType) => {
    return (
        <BogMessageWrapper data-test='bog-indicator-msg'>
            <BogMessageIndicator size='small'>
                <I18n langKey='events.bog' defaultText='BOG' />
            </BogMessageIndicator>

            {message}
        </BogMessageWrapper>
    );
});
