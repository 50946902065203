import { action, observable, computed, makeObservable } from 'mobx';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { RabPostBodyItemType } from 'src/domains/sportsbook/betting/state/rabState/Types';
import { RabMarketsModel } from 'src_common/common/websocket2/models/RabMarketsModel';
import { PriceResponseItem } from 'src/domains/sportsbook/betting/state/rabState/rabItemState/PriceResponseItem';
import { getRABErrorMessage } from 'src/api/config/eventsRab/rabGetPrice';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export interface RabStatusType {
    price: null | number;
    priceString: null | string;
    conflictingBets: boolean;
    conflictingBetsMessage?: string;
    suspendedBets: boolean;
}

class SelectValue {
    @observable private select: boolean = false;

    public constructor() {
        makeObservable(this);
    }

    public has(): boolean {
        return this.select;
    }

    @action public add(): void {
        this.select = true;
    }

    @action public remove(): void {
        this.select = false;
    }
}

class SelectSelection {
    private readonly data: MobxMapAutoNew<string, SelectValue> = new MobxMapAutoNew(() => new SelectValue());

    public has(selection: string): boolean {
        return this.data.get(selection).has();
    }

    public add(selection: string): void {
        this.data.get(selection).add();
    }

    public remove(selection: string): void {
        this.data.get(selection).remove();
    }
}

export class SelectMarket {
    private readonly eventId: EventId;
    private readonly getMarkets: () => RabMarketsModel | null;
    @observable.ref private currentPrice: PriceResponseItem;

    private readonly data: MobxMapAutoNew<string, SelectSelection> = new MobxMapAutoNew(() => new SelectSelection());

    public constructor(
        private readonly languagesState: LanguagesState,
        eventId: EventId,
        getMarkets: () => RabMarketsModel | null
    ) {
        makeObservable(this);
        this.eventId = eventId;
        this.getMarkets = getMarkets;

        this.currentPrice = new PriceResponseItem(this.eventId, () => this.postBody);
    }

    public acceptChanges(): void {
        this.price.acceptChanges();
    }

    public get selectionChanged(): boolean {
        return this.price.selectionChanged;
    }

    public get selectionChangedDown(): boolean {
        return this.price.selectionChangedDown;
    }

    @computed public get price(): PriceResponseItem {
        return this.currentPrice;
    }

    public addSelection(market: string, selection: string): void {
        this.data.get(market).add(selection);
        this.currentPrice.reset();
    }

    public removeSelection(market: string, selection: string): void {
        this.data.get(market).remove(selection);
        this.currentPrice.reset();
    }

    public hasSelect(market: string, selection: string): boolean {
        return this.data.get(market).has(selection);
    }

    @computed public get postBody(): Array<RabPostBodyItemType> {
        const markets = this.getMarkets();

        if (markets === null) {
            return [];
        }

        const out: Array<RabPostBodyItemType> = [];

        for (const marketItem of markets.markets) {
            const { selections, ...marketItemRest } = marketItem;

            for (const selectionItem of selections) {
                const selectionPayload = {
                    name: selectionItem.name,
                    selectionType: {
                        id: selectionItem.selectionType.id ?? undefined,
                        name: selectionItem.selectionType.name ?? undefined,
                        team: selectionItem.selectionType.team ?? undefined,
                        away: selectionItem.selectionType.away ?? undefined,
                        home: selectionItem.selectionType.home ?? undefined,
                        ou: selectionItem.selectionType.ou ?? undefined,
                        yn: selectionItem.selectionType.yn ?? undefined,
                        oy: selectionItem.selectionType.oy ?? undefined,
                        value: selectionItem.selectionType.value ?? undefined,
                    },
                };
                if (this.hasSelect(marketItem.name, selectionItem.name)) {
                    out.push({
                        market: marketItemRest,
                        selection: selectionPayload,
                    });
                }
            }
        }

        return out;
    }

    @computed public get rabStatus(): RabStatusType {
        const currentPriceResponse = this.price.price;

        if (currentPriceResponse === null) {
            return {
                price: null,
                priceString: null,
                conflictingBets: false,
                suspendedBets: false,
            };
        } else if (currentPriceResponse.type === 'error') {
            return {
                price: null,
                priceString: null,
                conflictingBets: true,
                suspendedBets: false,
                conflictingBetsMessage: getRABErrorMessage(this.languagesState, currentPriceResponse.data),
            };
        } else if (currentPriceResponse.type === 'suspended') {
            return {
                price: null,
                priceString: null,
                conflictingBets: false,
                suspendedBets: true,
            };
        } else if (currentPriceResponse.data.price > 0) {
            return {
                price: currentPriceResponse.data.price,
                priceString: currentPriceResponse.data.price.toFixed(2),
                conflictingBets: false,
                suspendedBets: false,
            };
        }

        return {
            price: null,
            priceString: null,
            conflictingBets: false,
            suspendedBets: false,
        };
    }

    public dump(): void {
        this.currentPrice.dump();
    }
}
