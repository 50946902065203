import * as t from 'io-ts';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

const decodeIO = t.array(t.string);

const decode = buildValidator('localStorageVisitedLandingPromotionsList.decodeIO', decodeIO, true);

export const localStorageVisitedLandingPromotionsList = (withAutorun: boolean, key: string): LocalStorageItemState<string[]> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: []
    });
};
