import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { vdom } from 'src/domains/App';
import { initIndex } from 'src/index.init';
import { AppState } from './appState/AppState';
import { registerNetworRequest, whenIdle } from 'src_common/utils/Idle';

const createJsx = async (): Promise<[React.ReactElement | null, AppState]> => {
    const [common, appState] = await initIndex();
    return [vdom(common, appState), appState];
};

const fakeRoot = ReactDOM.createRoot(document.createElement('div'));

// eslint-disable-next-line @typescript-eslint/no-floating-promises
createJsx().then(([app, appState]) => {
    //@ts-expect-error
    window.$appState = appState;

    fakeRoot.render(app);

    const unregister = registerNetworRequest();

    setTimeout(() => {
        unregister();
    }, 500);

    whenIdle(async () => {
        const root = document.getElementById('root');

        if (root === null) {
            console.error('Missing root');
        } else {
            createRoot(root).render(app);
        }
        fakeRoot.unmount();
    });
});
