import React, { useState } from 'react';
// eslint-disable-next-line
import { useAsObservableSource } from 'mobx-react-lite';
import { observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { calculateAmericanOdds } from 'src_common/common/calculateAmericanOdds';
import { assertNever } from 'src_common/common/assertNever';
import { LineChart } from 'src/domains/sportsbook/webview/components/priceHistory/lineChart/LineChart';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PriceType {
    d: number;
    f: string;
}

const priceToString = (price: PriceType, oddsFormat: 'f' | 'd' | 'a'): string => {
    switch (oddsFormat) {
        case 'f':
            return price.f;
        case 'd':
            return price.d.toString();
        case 'a':
            return calculateAmericanOdds(price.d);
        default:
            return assertNever('priceToString', oddsFormat);
    }
};

interface PropsType {
    selectionId: SelectionId;
    nrPricesToShow?: number | false;
}

class State {
    public readonly props: PropsType;
    private isInit: boolean = false;
    @observable.ref public d3Inner: typeof import('d3') | null = null;

    public constructor(props: PropsType) {
        makeObservable(this);
        this.props = props;
    }

    public get d3(): typeof import('d3') | null {
        if (this.isInit === false) {
            this.isInit = true;

            (async (): Promise<void> => {
                this.d3Inner = await import('d3');
            })().catch((err) => {
                console.error(err);
            });
        }
        return this.d3Inner;
    }
}

export const PriceHistory = observer('PriceHistory', (propsIn: PropsType): JSX.Element | null => {
    const props = useAsObservableSource(propsIn);
    const [state] = useState(() => new State(props));
    const { selectionId, nrPricesToShow } = props;

    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const isTablet = breakpointsState.tablet.isBiggerOrEq;
    const oddsFormat = appSportsBookState.getOddsFormat();
    const nrPricesToShowDesktop = 4;

    const selectionModel = selectionId.getModel();

    if (selectionModel === null) {
        return null;
    }

    const priceHistory = selectionModel.priceHistory;

    if (priceHistory.length < 1) {
        return null;
    }

    if (priceHistory.length === 1) {
        const priceHistoryFirstEl = priceHistory[0];
        if (priceHistoryFirstEl !== undefined) {
            return <span data-test='price-history'>{priceToString(priceHistoryFirstEl.p, oddsFormat)}</span>;
        }
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const historyToDisplay = priceHistory.concat([]).slice(0, nrPricesToShow || nrPricesToShowDesktop);
    historyToDisplay.reverse();

    const values = historyToDisplay.map((price, key) => ({
        x: key,
        y: price.p.d,
        label: priceToString(price.p, oddsFormat),
    }));

    const d3 = state.d3;
    const isTabletWidth = isTablet === null ? 76 : 160;
    const isTabletHeight = isTablet === null ? 47 : 55;

    if (d3 === null) {
        return null;
    }

    return (
        <LineChart
            data={[{ values: values }]}
            circle={6.5}
            margin={15}
            width={isTabletWidth}
            height={isTabletHeight}
            d3={d3}
        />
    );
});
