import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, makeObservable } from 'mobx';
import { useAppStateContext } from 'src/appState/AppState';
import { CenteredVerifyHeadline, HomeButtonWrapper, VerifyInfoSuspendedCentered } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/ProfileVerification.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SignUpState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUp.state';
import {
    VerificationFailedWrapper,
    EmailLink,
    WarningTriangle,
    WarningTriangleIconWrapper,
    HighlightedTxt
} from './VerificationFailed.style';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

class VerificationFailedPageState {

    public constructor(public readonly signUpState: SignUpState, public readonly router: StarRouter) {
        makeObservable(this);
    }

    @action public onHomeClick = (): void => {
        this.signUpState.rebuildState();
        this.router.redirectToHomepage();
    };
}

interface PropsType {
    signUpState: SignUpState;
}
export const VerificationFailedDetailedPage = observer('VerificationFailedDetailedPage', ({ signUpState }: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, starRouter } = appLayoutsState;
    const { config } = configComponents;
    const [state] = useState(() => new VerificationFailedPageState(signUpState, starRouter));
    const { signUpFooterMail } = config;

    return (
        <VerificationFailedWrapper>
            <WarningTriangle>
                <WarningTriangleIconWrapper />
            </WarningTriangle>
            <CenteredVerifyHeadline data-test='centered-verify-headline'>
                <I18n
                    langKey='signup-journey.verification-failed-detailed.headline'
                    defaultText='Sorry, some of the details you entered could not be verified'
                />
            </CenteredVerifyHeadline>
            <VerifyInfoSuspendedCentered data-test='verify-info-suspended-centered'>
                <I18n
                    langKey='signup-journey.verification-failed-detailed.info1'
                    defaultText='Your account has been automatically suspended'
                />
            </VerifyInfoSuspendedCentered>
            <VerifyInfoSuspendedCentered data-test='verify-info-suspended-centered'>
                <I18n
                    langKey='signup-journey.verification-failed-detailed.info2'
                    defaultText='To re-activate your account please email copies of both documents listed below to'
                />
                <EmailLink href={`mailto: ${signUpFooterMail}`}> {signUpFooterMail}</EmailLink>
            </VerifyInfoSuspendedCentered>
            <VerifyInfoSuspendedCentered data-test='verify-info-suspended-centered'>
                <HighlightedTxt>
                    <I18n
                        langKey='signup-journey.verification-failed-detailed.info3.1'
                        defaultText='ID Documentation: '
                    />
                </HighlightedTxt>
                <I18n
                    langKey='signup-journey.verification-failed-detailed.info3.2'
                    defaultText='Drivers license or Passport'
                />
            </VerifyInfoSuspendedCentered>
            <VerifyInfoSuspendedCentered data-test='verify-info-suspended-centered'>
                <HighlightedTxt>
                    <I18n
                        langKey='signup-journey.verification-failed-detailed.info4.1'
                        defaultText='Address Verification: '
                    />
                </HighlightedTxt>
                <I18n
                    langKey='signup-journey.verification-failed-detailed.info4.2'
                    defaultText='A bank statement, utility bill or payslip addressed to you that is no more than 3 months old.'
                />
            </VerifyInfoSuspendedCentered>
            <HomeButtonWrapper size='large' onClick={state.onHomeClick} dataTest='home-button'>
                <I18n
                    langKey='signup-journey.verification-failed.home-btn'
                    defaultText='Home'
                />
            </HomeButtonWrapper>
        </VerificationFailedWrapper>
    );
});
