import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAsObservableSource } from 'mobx-react-lite';
import { Dot, DotsWrapper } from './Dots.style';

interface DotType {
    id: number;
}

interface DotsPropsTypes {
    banners: Array<DotType>,
    currentSlide: number,
    slideWithDot: (i: number) => void,
    smallDotIndex: number,
    position: 'static' | 'absolute';
    marginTop?: number;
    isHomepage: boolean;
}

export const Dots = observer('Dots', (propsIn: DotsPropsTypes) => {
    const props = useAsObservableSource(propsIn);
    const { banners, smallDotIndex, currentSlide, slideWithDot, position, marginTop } = props;
    return (
        <DotsWrapper displayOnMobile={true} position={position} marginTop={marginTop} >
            {banners.map((banner, index) => {
                const activeIndexDiff = currentSlide - index;
                const indexActiveDiff = index - currentSlide;
                return (
                    <Dot
                        isHomePage={props.isHomepage}
                        key={banner.id}
                        isActive={currentSlide === index}
                        onClick={(): void => slideWithDot(index)}
                        isSmall={activeIndexDiff=== smallDotIndex || indexActiveDiff === smallDotIndex}
                        isSmallest={activeIndexDiff > smallDotIndex || indexActiveDiff > smallDotIndex}
                    />
                );})}
        </DotsWrapper>
    );
});
