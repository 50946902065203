import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { computed, observable, makeObservable } from 'mobx';
import { AccountModel, ChangePhoneParamsType } from 'src/domains/players/state/accountState/AccountModel';
import { PhoneNumberState } from 'src/domains/players/webview/components/SignUp';
import { ConfigType } from 'src/domains/layouts/config/features/types';

export interface MobilePhoneDataType {
    country: string;
    number: string;
    prefix: string;
}

export class ChangePhoneState {
    @observable public isLoading: boolean;
    @observable public isSuccessChangeNumber: boolean;

    public readonly phoneNumberState: PhoneNumberState;

    public constructor(
        public readonly account: AccountModel,
        public readonly initialPhoneNumberData: MobilePhoneDataType | null,
        public readonly language: LanguagesState,
        public readonly config: ConfigType
    ) {
        makeObservable(this);
        this.isLoading = false;
        this.isSuccessChangeNumber = false;

        const initialPhonePrefix = this.transformInitialPrefix();
        this.phoneNumberState = new PhoneNumberState(
            this.language,
            initialPhonePrefix,
            this.initialPhoneNumberData?.number
        );
    }

    public transformInitialPrefix = (): Record<string, string> | undefined => {
        if (this.initialPhoneNumberData?.prefix === undefined) {
            return undefined;
        }

        return { [this.initialPhoneNumberData.country]: `+${this.initialPhoneNumberData.prefix}` };
    };

    public onSubmit = async (): Promise<void> => {
        this.isLoading = true;
        this.isSuccessChangeNumber = false;

        const prefix = this.phoneNumberState.prefix;
        const phoneNumber = this.phoneNumberState.phoneNumber.result.value;

        if (
            phoneNumber.type === 'error' ||
            prefix.inputState.result.value.type === 'error' ||
            prefix.currentValueFormatted === undefined
        ) {
            return;
        }

        const prefixFormated = prefix.currentValueFormatted.value.replace('+', '');

        const formatedData: ChangePhoneParamsType = {
            prefixFullData: {
                label: prefixFormated,
                value: `${prefixFormated},${prefix.currentValueFormatted.key}`,
                id: prefix.currentValueFormatted.key,
            },
            number: phoneNumber.data,
        };

        await this.account.onChangePhoneNumber(formatedData);
        this.isSuccessChangeNumber = true;
        this.isLoading = false;
    };

    @computed public get isButtonDisabled(): boolean {
        return (
            this.phoneNumberState.phoneNumber.result.value.type === 'error' ||
            this.phoneNumberState.prefix.inputState.result.value.type === 'error'
        );
    }
}
