import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { observable, makeObservable } from 'mobx';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ListItem } from './SelectionRabItem';
import { MarketRabView } from 'src/domains/sportsbook/betting/state/rabState/Types';
import {
    MoreLessGroup,
    LinkMoreLessWrapper,
} from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/outrightMarket/OutrightMarket.style';
import {
    SelectionGroupWrapper,
    SelectionRabList,
    MarketGroup,
    MarketGroupHeader,
    SelectionsGroup,
    RabHeaderWrapper,
    ChevronIconWrapper,
    ChevronIconMoreWrapper,
} from './SelectionRab.style';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

interface SelectionRabPropsType {
    rabId: BetslipRabId;
    rabMarket: MarketRabView;
    order: number;
}

class State {
    @observable public collapsed: boolean = false;
    @observable public moreOpen: boolean = false;

    public constructor(order: number) {
        makeObservable(this);
        this.collapsed = order > 4;
    }

    public toggleCollapsed = (): void => {
        if (this.collapsed) {
            this.collapsed = false;
            this.moreOpen = false;
        } else {
            this.collapsed = true;
        }
    };

    public onClickMore = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        this.moreOpen = true;
    };

    public onClickLess = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        this.moreOpen = false;
    };
}

const renderMore = (state: State): React.ReactNode => {
    return (
        <MoreLessGroup>
            <LinkMoreLessWrapper onClick={state.onClickMore}>
                <I18n langKey='events.request-a-bet-selection.more' defaultText='More' />
                <ChevronIconMoreWrapper position='down' />
            </LinkMoreLessWrapper>
        </MoreLessGroup>
    );
};

const renderLess = (state: State): React.ReactNode => {
    return (
        <MoreLessGroup>
            <LinkMoreLessWrapper onClick={state.onClickLess}>
                <I18n langKey='events.request-a-bet-selection.less' defaultText='Less' />
                <ChevronIconMoreWrapper position='up' />
            </LinkMoreLessWrapper>
        </MoreLessGroup>
    );
};

const renderMoreLess = (state: State): React.ReactNode => {
    const { moreOpen } = state;
    if (moreOpen) {
        return renderLess(state);
    }

    return renderMore(state);
};

export const SelectionRab = observer('SelectionRab', (props: SelectionRabPropsType) => {
    const { rabMarket, rabId, order } = props;
    const [state] = React.useState(() => new State(order));
    const { collapsed, moreOpen } = state;

    const selectionsMoreLess = moreOpen ? rabMarket.selections : rabMarket.selections.slice(0, 4);

    return (
        <MarketGroup data-test-rab-market-name={rabMarket.name} data-test-collapsed={collapsed}>
            <RabHeaderWrapper onClick={state.toggleCollapsed}>
                <ChevronIconWrapper position={collapsed ? 'right' : 'down'} />
                <MarketGroupHeader isCollapsed={collapsed}>{rabMarket.name}</MarketGroupHeader>
            </RabHeaderWrapper>

            {collapsed ? null : (
                <SelectionGroupWrapper>
                    <SelectionsGroup>
                        <SelectionRabList columns={rabMarket.viewDetails.selectionDisplayType}>
                            {selectionsMoreLess.map((selection) => {
                                return (
                                    <ListItem
                                        key={`${rabMarket.name} ${selection.name}`}
                                        rabId={rabId}
                                        marketName={rabMarket.name}
                                        name={selection.name}
                                        isSelect={selection.isSelect}
                                        displayName={true}
                                    />
                                );
                            })}
                        </SelectionRabList>
                    </SelectionsGroup>

                    {rabMarket.selections.length > 4 ? renderMoreLess(state) : null}
                </SelectionGroupWrapper>
            )}
        </MarketGroup>
    );
});
