import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    DateRangeFormBtnWrapper,
    DateRangeFormTitle,
    DatePartInputRange,
    DateInputsRangeWrapper,
    DateRangeResultWrapper,
    DateRangeResultText,
    DateRangeResultUpdate,
    DateRangeResultTextBold,
    DateRangeFormContainer,
    DataRageRowWrapper,
} from './DateRangeForm.style';
import { DateRangeFormState } from './DateRangeForm.state';
import { action, computed, observable, makeObservable } from 'mobx';
import { DatePartInputState, DateInputStatus } from 'src/domains/players/webview/components/form/DateInput';
import { RangeType, StatusType } from 'src/domains/players/webview/components/Account';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { DateTime } from 'src_common/utils/time/time';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface DateRangeFormPropsTypes {
    label: JSX.Element;
    onSubmit: (dateFrom: string, dateTo: string) => void;
    state: DateRangeFormState;
    status: StatusType;
}

interface DateFormType {
    day: number;
    month: number;
    year: number;
}

interface DateRangeFormMomentType {
    from: FormModel<DateTime>;
    to: FormModel<DateTime>;
}

interface DateRangeErrorType {
    from: string | null;
    to: string | null;
}

const mapDatesValues = (
    languagesState: LanguagesState,
    dateModelForm: FormModel<DateFormType>,
    dateFrom: boolean,
    yearFrom: DatePartInputState,
    monthFrom: DatePartInputState,
    dayFrom: DatePartInputState
): FormModel<DateTime> => {
    return dateModelForm.map((value: DateFormType) => {
        const day = value.day;
        const month = value.month;
        const year = value.year;

        const date = DateTime.strictFormattedYearMonthDay(year, month, day, 'YYYY-M-D');

        const actualYear = DateTime.current().getYear();
        const actualDate = DateTime.current().format('YYYY-M-D');
        const dateStart = DateTime.strictFormattedYearMonthDay(2017, 10, 17, 'YYYY-M-D');

        const dayFromInput = parseInt(dayFrom.inputState.value, 10);
        const monthFromInput = parseInt(monthFrom.inputState.value, 10);
        const yearFromInput = parseInt(yearFrom.inputState.value, 10);

        if (dateFrom === true) {
            if (date.isBefore(dateStart)) {
                return Result.createError(languagesState.getTranslation('errors.date', 'Please enter a valid date.'));
            }
        } else {
            const dateFrom = DateTime.strictFormattedYearMonthDay(
                yearFromInput,
                monthFromInput,
                dayFromInput,
                'YYYY-M-D'
            );
            if (date.isBefore(dateFrom)) {
                return Result.createError(
                    languagesState.getTranslation('errors.range-date', 'This date must be after the ‘from’ date.')
                );
            }
        }

        if (day > 31 || month > 12 || year > actualYear || year.toString().length !== 4) {
            return Result.createError(languagesState.getTranslation('errors.date', 'Please enter a valid date.'));
        }

        if (date.isValid() === false) {
            return Result.createError(languagesState.getTranslation('errors.date', 'Please enter a valid date.'));
        }

        if (date.isAfter(actualDate)) {
            return Result.createError(languagesState.getTranslation('errors.date', 'Please enter a valid date.'));
        }

        return Result.createOk(date);
    });
};

class DateInputState {
    @observable public isSelectedDate = false;

    @observable public message: string | null = null;
    @observable public refDay: HTMLInputElement | null;
    @observable public refMonth: HTMLInputElement | null;
    @observable public refYear: HTMLInputElement | null;

    @observable public refDayTo: HTMLInputElement | null;
    @observable public refMonthTo: HTMLInputElement | null;
    @observable public refYearTo: HTMLInputElement | null;

    public readonly day: DatePartInputState;
    public readonly month: DatePartInputState;
    public readonly year: DatePartInputState;

    public readonly dayTo: DatePartInputState;
    public readonly monthTo: DatePartInputState;
    public readonly yearTo: DatePartInputState;

    public readonly dateFromMoment: FormModel<DateTime>;
    public readonly dateModelFrom: FormModel<DateFormType>;
    public readonly dateInputStatus: DateInputStatus;

    public readonly dateToMoment: FormModel<DateTime>;
    public readonly dateModelTo: FormModel<DateFormType>;
    public readonly dateInputToStatus: DateInputStatus;

    public readonly dateRangeModel: DateRangeFormMomentType;

    private onSubmit: (dateFrom: string, dateTo: string) => void;

    public constructor(
        private readonly languagesState: LanguagesState,
        onSubmit: (dateFrom: string, dateTo: string) => void
    ) {
        makeObservable(this);
        this.onSubmit = onSubmit;
        this.day = new DatePartInputState('');
        this.month = new DatePartInputState('');
        this.year = new DatePartInputState('');

        this.dayTo = new DatePartInputState('');
        this.monthTo = new DatePartInputState('');
        this.yearTo = new DatePartInputState('');

        this.refDay = null;
        this.refMonth = null;
        this.refYear = null;

        this.refDayTo = null;
        this.refMonthTo = null;
        this.refYearTo = null;

        this.dateModelFrom = FormModel.group({
            day: this.day.inputState,
            month: this.month.inputState,
            year: this.year.inputState,
        });

        this.dateFromMoment = mapDatesValues(
            this.languagesState,
            this.dateModelFrom,
            true,
            this.year,
            this.month,
            this.day
        );
        this.dateInputStatus = new DateInputStatus(this.dateFromMoment);

        this.dateModelTo = FormModel.group({
            day: this.dayTo.inputState,
            month: this.monthTo.inputState,
            year: this.yearTo.inputState,
        });

        this.dateToMoment = mapDatesValues(
            this.languagesState,
            this.dateModelTo,
            false,
            this.year,
            this.month,
            this.day
        );
        this.dateInputToStatus = new DateInputStatus(this.dateToMoment);

        this.dateRangeModel = {
            from: this.dateFromMoment,
            to: this.dateToMoment,
        };
    }

    public toggleSelected = (): void => {
        this.isSelectedDate = !this.isSelectedDate;
    };

    public onFocusDay = (): void => {
        this.setZeroValue();
    };

    public onFocusMonth = (): void => {
        this.setZeroValue();
    };

    public onFocusYear = (): void => {
        this.setZeroValue();
    };

    public setInputDayRef = (node: HTMLInputElement | null): void => {
        this.refDay = node;
    };

    public setInputMonthRef = (node: HTMLInputElement | null): void => {
        this.refMonth = node;
    };

    public setInputYearRef = (node: HTMLInputElement | null): void => {
        this.refYear = node;
    };

    public setInputDayToRef = (node: HTMLInputElement | null): void => {
        this.refDayTo = node;
    };

    public setInputMonthToRef = (node: HTMLInputElement | null): void => {
        this.refMonthTo = node;
    };

    public setInputYearToRef = (node: HTMLInputElement | null): void => {
        this.refYearTo = node;
    };

    public setZeroValue = (): void => {
        if (this.day.inputState.value.length === 1) {
            this.day.inputState.setValue(`0${this.day.inputState.value}`);
        }
        if (this.month.inputState.value.length === 1) {
            this.month.inputState.setValue(`0${this.month.inputState.value}`);
        }
    };

    public onChangeDayInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currLength = event.currentTarget.value.length;
        if (currLength === 2) {
            this.refMonth?.focus();
        }
    };

    public onChangeMonthInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currLength = event.currentTarget.value.length;
        if (currLength === 2) {
            this.refYear?.focus();
        }
    };

    public onChangeYearInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currLength = event.currentTarget.value.length;
        if (currLength === 4) {
            this.refDayTo?.focus();
        }
    };

    public onChangeDayToInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currLength = event.currentTarget.value.length;
        if (currLength === 2) {
            this.refMonthTo?.focus();
        }
    };

    public onChangeMonthToInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currLength = event.currentTarget.value.length;
        if (currLength === 2) {
            this.refYearTo?.focus();
        }
    };

    @computed public get datesFormated(): RangeType {
        return {
            from: `${this.year.inputState.value}-${this.month.inputState.value}-${this.day.inputState.value}`,
            to: `${this.yearTo.inputState.value}-${this.monthTo.inputState.value}-${this.dayTo.inputState.value}`,
        };
    }

    @computed public get errorMessage(): DateRangeErrorType {
        return {
            from: this.dateRangeModel.from.result.errors()?.[0] ?? null,
            to: this.dateRangeModel.to.result.errors()?.[0] ?? null,
        };
    }

    @computed public get isButtonDisabled(): boolean {
        return (
            this.dateRangeModel.from.result.errors()?.[0] === undefined &&
            this.dateRangeModel.to.result.errors()?.[0] === undefined &&
            this.dateRangeModel.from.isVisited() === true &&
            this.dateRangeModel.to.isVisited() === true
        );
    }

    @action public submitForm = (): void => {
        this.onSubmit(this.datesFormated.from, this.datesFormated.to);
        this.toggleSelected();
    };
}

export const DateRangeForm = observer('DateRangeForm', ({ status, onSubmit }: DateRangeFormPropsTypes) => {
    const {
        appLayoutsState: { languagesState },
    } = useAppStateContext();

    const submitLabel = <I18n langKey='common.date-range.submit.label' defaultText='Update' />;

    const [dateState] = useState(() => new DateInputState(languagesState, onSubmit));

    const { type: statusTypeFrom } = dateState.dateInputStatus;
    const { type: statusType } = dateState.dateInputToStatus;

    return (
        <DateRangeFormContainer>
            {dateState.isSelectedDate === true ? (
                <DateRangeResultWrapper>
                    <DateRangeResultText>
                        <I18n langKey='common.date-range.result-text.from' defaultText='Displaying transactions from' />
                        <DateRangeResultTextBold>
                            {dateState.day.inputState.value}/{dateState.month.inputState.value}/
                            {dateState.year.inputState.value}
                        </DateRangeResultTextBold>
                        <I18n langKey='common.date-range.result-text.to' defaultText='to' />
                        <DateRangeResultTextBold>
                            {dateState.dayTo.inputState.value}/{dateState.monthTo.inputState.value}/
                            {dateState.yearTo.inputState.value}
                        </DateRangeResultTextBold>
                    </DateRangeResultText>
                    <DateRangeResultUpdate onClick={dateState.toggleSelected}>
                        <I18n langKey='common.date-range.result-text.update' defaultText='Update' />
                    </DateRangeResultUpdate>
                </DateRangeResultWrapper>
            ) : (
                <div>
                    <DataRageRowWrapper>
                        <DateRangeFormTitle>
                            <I18n langKey='common.date-range.from.label' defaultText='From' />
                        </DateRangeFormTitle>
                        <DateInputsRangeWrapper isError={statusTypeFrom === 'error'}>
                            <DatePartInputRange
                                name='dayFrom'
                                placeholder='DD'
                                maxLength={2}
                                pattern='0?[1-9]|1[0-9]|2[0-9]|3[01]'
                                state={dateState.day}
                                inputRef={dateState.setInputDayRef}
                                onClick={dateState.onFocusDay}
                                onChangeInput={dateState.onChangeDayInput}
                                inputMode='numeric'
                            />
                            <DatePartInputRange
                                name='monthFrom'
                                placeholder='MM'
                                maxLength={2}
                                pattern='0?[1-9]|1[012]'
                                state={dateState.month}
                                inputRef={dateState.setInputMonthRef}
                                onClick={dateState.onFocusMonth}
                                onChangeInput={dateState.onChangeMonthInput}
                                inputMode='numeric'
                            />
                            <DatePartInputRange
                                name='yearFrom'
                                placeholder='YYYY'
                                maxLength={4}
                                pattern='(19|20)[0-9]{2}'
                                state={dateState.year}
                                inputRef={dateState.setInputYearRef}
                                onClick={dateState.onFocusYear}
                                onChangeInput={dateState.onChangeYearInput}
                                inputMode='numeric'
                            />
                        </DateInputsRangeWrapper>
                        {dateState.errorMessage.from === null ? null : (
                            <Messages type='error' marginBottom='16px' message={dateState.errorMessage.from} />
                        )}
                    </DataRageRowWrapper>

                    <DataRageRowWrapper>
                        <DateRangeFormTitle>
                            <I18n langKey='common.date-range.to.label' defaultText='To' />
                        </DateRangeFormTitle>
                        <DateInputsRangeWrapper isError={statusType === 'error'}>
                            <DatePartInputRange
                                name='dayTo'
                                placeholder='DD'
                                maxLength={2}
                                pattern='0?[1-9]|1[0-9]|2[0-9]|3[01]'
                                state={dateState.dayTo}
                                inputRef={dateState.setInputDayToRef}
                                onClick={dateState.onFocusDay}
                                onChangeInput={dateState.onChangeDayToInput}
                                inputMode='numeric'
                            />
                            <DatePartInputRange
                                name='monthTo'
                                placeholder='MM'
                                maxLength={2}
                                pattern='0?[1-9]|1[012]'
                                state={dateState.monthTo}
                                inputRef={dateState.setInputMonthToRef}
                                onClick={dateState.onFocusMonth}
                                onChangeInput={dateState.onChangeMonthToInput}
                                inputMode='numeric'
                            />
                            <DatePartInputRange
                                name='yearTo'
                                placeholder='YYYY'
                                maxLength={4}
                                pattern='(19|20)[0-9]{2}'
                                state={dateState.yearTo}
                                inputRef={dateState.setInputYearToRef}
                                onClick={dateState.onFocusYear}
                                inputMode='numeric'
                            />
                        </DateInputsRangeWrapper>
                        {dateState.errorMessage.to === null ? null : (
                            <Messages type='error' marginBottom='16px' message={dateState.errorMessage.to} />
                        )}
                    </DataRageRowWrapper>
                </div>
            )}
            {status === 'loading' || dateState.isSelectedDate === false ? (
                <DateRangeFormBtnWrapper
                    disabled={!dateState.isButtonDisabled || statusType === 'error' || statusTypeFrom === 'error'}
                    isLoading={status === 'loading'}
                    type='submit'
                    onClick={dateState.submitForm}
                    size='medium'
                >
                    {submitLabel}
                </DateRangeFormBtnWrapper>
            ) : null}
        </DateRangeFormContainer>
    );
});
