import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    MatchTime,
    Result,
    Score,
    Team,
    TeamImg,
    TeamName,
    TeamsWrapper,
} from './AmericanSportMatchLead.style';
import { AmericanSportMatchLeadStateStrategy } from './AmericanSportMatchLeadStateStrategy';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PropsType {
    state: AmericanSportMatchLeadStateStrategy;
}

export const AmericanSportLiveScore = observer<PropsType>('AmericanSportLiveScore', ({ state }) => {
    const homeName = state.homeTeamName;
    const awayName = state.awayTeamName;

    return (
        <>
            <MatchTime isPreMatch={false}>
                <I18n langKey='competition.american-sport.pre-match.in-play' defaultText='In-Play' />
            </MatchTime>

            <TeamsWrapper>
                <Team isHome={true}>
                    <TeamName>{homeName}</TeamName>
                    <TeamImg decoding='async' src={state.homeTeamLogo} alt={`${homeName} logo`} />
                </Team>

                <Result>
                    <Score>{state.homeScore}</Score>
                    <Score>{state.awayScore}</Score>
                </Result>

                <Team isHome={false}>
                    <TeamName>{awayName}</TeamName>
                    <TeamImg decoding='async' src={state.awayTeamLogo} alt={`${awayName} logo`} />
                </Team>
            </TeamsWrapper>
        </>
    );
});
