import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LastResultsWrapper, LastResultNumber } from './LastResults.style';
import { RouletteNumberType } from 'src/domains/casino/utils/types';

interface LastResultsPropsType {
    largeVersion: boolean;
    rouletteNumbers: Array<RouletteNumberType> | null;
}

export const LastResults = observer('LastResults', (props: LastResultsPropsType) => {
    const { largeVersion, rouletteNumbers } = props;

    if (rouletteNumbers === null || rouletteNumbers.length === 0) {
        return null;
    }

    const out: Array<React.ReactElement> = [];

    for (const [index, item] of rouletteNumbers.entries()) {
        out.push(
            <LastResultNumber
                key={index}
                type={item.color}
                largeVersion={largeVersion}
                emphasized={item.emphasized}
            >
                {item.result}
            </LastResultNumber>
        );
    }

    return (
        <LastResultsWrapper>
            { out }
        </LastResultsWrapper>
    );
});
