import { computed, makeObservable } from 'mobx';
import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { TransactionItemType } from 'src/api/config/accounts/accountsWithdrawalsList';

export class PendingTransactionsState {
    public constructor(
        private readonly accountState: AccountState,
        private readonly withdrawalsListState: WithdrawalsListState,
    ) {
        makeObservable(this);
    }

    @computed public get isCancelWithdrawal(): boolean {
        return this.accountState.account?.cancelWithdrawal ?? false;
    }

    @computed public get isEmptyTransactionList(): boolean {
        return this.withdrawalsListState.withdrawals.length === 0;
    }

    @computed public get withdrawalsListSorted(): Array<TransactionItemType> {
        return this.withdrawalsListState.withdrawals.sort((a, b) => {
            const timeA = new Date(a.tdate).getTime();
            const timeB = new Date(b.tdate).getTime();
            return timeA === timeB ? 0 : (timeA > timeB ? -1 : 1);
        });
    }

    @computed public get withdrawalsList(): Array<TransactionItemType> {
        return this.withdrawalsListState.withdrawals.map(withdrawal => {
            return {
                ...withdrawal,
            };
        });
    }
}
