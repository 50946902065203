import React from 'react';
import { CasinoNavigationWrapper, CategoryLoader, SearchbarLoader } from 'src/domains/layouts/shared/loaders';
import { observer } from 'src/utils/mobx-react';

interface CasinoNavigationLoaderPropsType {
    hideCategories: boolean;
}

export const CasinoNavigationLoader = observer('CasinoNavigationLoader', (props: CasinoNavigationLoaderPropsType) => {
    const categories = [];

    if (props.hideCategories !== true) {
        for (let i = 0; i < 6; i++) {
            categories.push(<CategoryLoader key={i} />);
        }
    }

    return (
        <CasinoNavigationWrapper>
            <SearchbarLoader />
            {categories}
        </CasinoNavigationWrapper>
    );
});
