import { action, computed, observable, makeObservable } from 'mobx';
import { BetResponseType } from 'src/domains/sportsbook/shared/Types';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { Session } from 'src_common/sdk/session';
import { UpdateBetslipMessageAvailableType, UserRewardsMessageAvailableType } from './LifespanSocketConnectionState';
import { BetsBoostTypes, LifeSpanSocketState, SelectionBoostState } from './LifespanSocketState';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';

interface WalletItemType {
    active: boolean;
    category: string;
    description: string;
    expiry: number;
    id: string;
    rewardType: string;
    routeInfo: string | undefined;
}

export interface WalletType {
    name: string;
    boosts: number;
    expiry: number;
    id: string | undefined;
    items: WalletItemType[];
    routeInfo: string | undefined;
}

const groupWalletItems = (list: Array<WalletItemType>): Array<WalletType> => {
    const group: Map<string, Array<WalletItemType>> = new Map();
    const resultArray = [];

    for (const item of list) {
        group.set(
            item.description,
            list.filter((listItem) => listItem.description === item.description)
        );
    }

    for (const [name, value] of group) {
        const eaarliestExpiry = Math.min(...value.map((item) => item.expiry));
        const getPromotionUrl = value[0]?.routeInfo;

        resultArray.push({
            name,
            boosts: value.length,
            expiry: eaarliestExpiry,
            items: value,
            id: value[0]?.id,
            routeInfo: getPromotionUrl,
        });
    }

    return resultArray;
};

interface LifeSpanStateCallbacksType {
    /**
     * @deprecated - the reference to this method must be removed
     */
    getSelection: (selectionId: number) => SelectionModel | null;
    getCurrency: () => CurrencyType;
}
export class LifeSpanState {
    public readonly lifeSpanSocketState: LifeSpanSocketState;
    @observable public lifeSpanWallet: boolean;

    public constructor(
        session: Session,
        public readonly configComponents: ConfigComponents,
        private readonly callbacks: LifeSpanStateCallbacksType,
        googleTagManager: GoogleTagManagerState
    ) {
        makeObservable(this);
        this.lifeSpanSocketState = new LifeSpanSocketState(session, configComponents, googleTagManager);
        this.lifeSpanWallet = false;
    }

    @computed public get userRewardsMessage(): Array<UserRewardsMessageAvailableType> {
        return this.lifeSpanSocketState.userRewardsMessage;
    }

    @computed public get userRewardsMessageGrouped(): Array<WalletType> {
        return groupWalletItems(this.userRewardsMessage);
    }

    @action public isSelectionsHasBoost = (bets: BetResponseType[]): void => {
        const out: BetsBoostTypes[] = [];
        const userCurrency = this.callbacks.getCurrency();

        for (const bet of bets) {
            const selectionModel = this.callbacks.getSelection(parseInt(bet.id, 10));
            if (selectionModel !== null) {
                const eventModel = selectionModel.getEvent();
                if (eventModel !== null) {
                    const { sport, id, timeSettingsStartTimeUnixMs } = eventModel;
                    const selectionPrice = this.configComponents.precision.newFromAnything(bet.stakePerLine);

                    out.push({
                        reference: selectionModel.id.toString(),
                        sport: sport,
                        competition: eventModel.competition.toString(),
                        event: id.toString(),
                        market: bet.legs[0]?.market?.id.toString(),
                        selection: selectionModel.id.toString(),
                        decimalOdds: selectionModel.price?.d,
                        amount: parseInt(selectionPrice.value, 10),
                        currency: userCurrency,
                        startTime: timeSettingsStartTimeUnixMs,
                    });
                }
            }
        }

        this.lifeSpanSocketState.isSelectionsHasBoost(out);
    };

    @action public activeSelectionBoost = (selectionWithBoost: UpdateBetslipMessageAvailableType): void => {
        this.lifeSpanSocketState.activeBoost(selectionWithBoost);
    };

    @action public deactivateSelectionBoost = (selectionWithBoost: UpdateBetslipMessageAvailableType): void => {
        this.lifeSpanSocketState.deactivationBoost(selectionWithBoost);
    };

    @action public toggleWalletOpen = (): void => {
        this.lifeSpanWallet = !this.lifeSpanWallet;
    };

    @computed public get isWalletOpen(): boolean {
        return this.lifeSpanWallet;
    }

    @action public selectionsWithAvailableBoosts(legId: string): SelectionBoostState | null {
        return this.lifeSpanSocketState.selectionsWithAvailableBoosts(legId);
    }
}
