import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const YouDecideExitWrapper = withConfig(theme => styled('div', { label: 'YouDecideExitWrapper' })`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
`);

export const RabCloseIconWrapper = styled(CloseIcon, { label: 'RabCloseIconWrapper' })`
    fill: currentcolor;
    margin: -2px 14px 0 8px;
    width: 20px;
`;

