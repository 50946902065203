import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import styled from '@emotion/styled';
interface PropsType {
    className?: string;
    onClick?: () => void;
}
const SvgElement = styled('svg', { label: 'SvgElement' }) <PropsType>`
    height: auto;
`;

export const CashOutIcon = observer('CashOutIcon', ({ className, onClick }: PropsType): JSX.Element => {
    return (
        <SvgElement
            fill='#fff'
            onClick={onClick}
            className={className}
            xmlns='http://www.w3.org/2000/svg'
            width='24px'
            height='24px'
            viewBox='0 0 24 24'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M12 1a11 11 0 0111 11c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zM7.74 7.76c-.8-.014-1.59.172-2.3.54a3.995 3.995 0 00-1.61 1.51A4.121 4.121 0 003.25 12c-.01.772.199 1.53.6 2.19a3.995 3.995 0 001.61 1.51c.71.368 1.5.554 2.3.54a5.046 5.046 0 002-.39 4.105 4.105 0 001.49-1.14l-1.47-1.33a2.47 2.47 0 01-3.11.66 2.012 2.012 0 01-.8-.82A2.526 2.526 0 015.58 12c0-.41.1-.816.29-1.18.181-.345.46-.63.8-.82a2.47 2.47 0 013.11.62l1.45-1.33a4.105 4.105 0 00-1.49-1.14 5.046 5.046 0 00-2-.39zm10.8.54a5.275 5.275 0 00-4.65 0 4.1 4.1 0 00-2.21 3.7 4.1 4.1 0 002.21 3.7c1.467.72 3.183.72 4.65 0a4.1 4.1 0 002.21-3.7 4.1 4.1 0 00-2.21-3.7zM15.1 10a2.292 2.292 0 012.23 0c.332.183.609.453.8.78.196.376.296.796.29 1.22.004.425-.099.845-.3 1.22a2.102 2.102 0 01-.8.82 2.292 2.292 0 01-2.23 0 2.102 2.102 0 01-.8-.82A2.526 2.526 0 0114 12c.004-.412.107-.817.3-1.18.186-.342.462-.626.8-.82z'
            />
        </SvgElement>
    );
});
