import { SocketController } from 'src_common/common/mobx-utils/Socket/SocketController';
import { SocketMap } from 'src_common/common/mobx-utils/Socket/SocketMap';
import { assertNever } from 'src_common/common/assertNever';
import { JackpotsListModel } from './JackpotsListModel';
import { JackpotsListType, isJackpotsListModel } from './JackpotsDetailsModels';

export class JackpotsListDetails {
    private readonly socketController: SocketController;
    private readonly socketMap: SocketMap<undefined, JackpotsListType, JackpotsListModel>;

    public constructor(host: string, isBrowser: boolean) {
        this.socketController = new SocketController(
            'Jackpots - JackpotsListDetails',
            host,
            isBrowser,
            10000,
            50
        );

        this.socketMap = new SocketMap(
            this.socketController,
            null,
            null,
            JackpotsListModel.create
        );

        this.socketController.onData((data) => {
            if (data.type === 'open') {
                this.socketMap.onNewSocket(data.socket);
                return;
            }

            if (data.type === 'message') {
                const json = JSON.parse(data.message);
                if (isJackpotsListModel(json)) {
                    this.socketMap.onMessage(undefined, json);
                } else {
                    console.error('socket, model JackpotsListType: message decoding error', json);
                }
                return;
            }

            if (data.type === 'close') {
                return;
            }

            return assertNever('socketController data.type', data);
        });
    }

    public getModel(): JackpotsListModel | null {
        return this.socketMap.getById(undefined);
    }
}
