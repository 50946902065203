import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { AccountNavigationLink } from 'src/domains/layouts/webview/components/accountNavigationLink/AccountNavigationLink';
import { withConfig } from 'src/withConfig';

export const LoginTabWrapper = styled('div', { label: 'LoginTabWrapper' })`
    flex-flow: column nowrap;
    display: flex;
    height: 100%;
`;

export const LoginForm = styled('form', { label: 'LoginForm' })`
    padding: 16px;
    margin: 0;
`;

export const LoginInput = styled(Input, { label: 'LoginInput' })`
    margin: 0 0 16px 0;
`;

export const LoginMessage = withConfig(theme => styled('h3', { label: 'LoginMessage' })`
    font-size: ${theme.star.fontSize.regular};
    text-transform: uppercase;
    margin-top: 0;
`);

export const ForgotPasswordLink = withConfig(theme => styled(AccountNavigationLink, { label: 'ForgotPasswordLink' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    color: ${theme.star.accountDrawer.txtColorSecondary};
    line-height: 1.43;
    text-decoration: none;
`);

export const LoginBtnWrapper = styled(Button, { label: 'LoginBtnWrapper' })`
    display: block;
    margin: 16px 0 16px 0;
    width: 100%;
`;

export const AlignBottomWrapper = styled('div', { label: 'AlignBottomWrapper' })`
    margin: auto 0 0 0;
`;

export const SignUpHelper = withConfig(theme => styled('div', { label: 'SignUpHelper' })`
    text-align: center;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const SignUpLink = withConfig(theme => styled(AccountNavigationLink, { label: 'SignUpLink' })`
    color: ${theme.star.accountDrawer.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
`);

export const RememberMeWrapper = styled('div', { label: 'RememberMeWrapper' })`
    display: flex;
    justify-content: space-between;
`;
