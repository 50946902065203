import { observable, makeObservable } from 'mobx';

const launchIntoFullscreen = async (element: HTMLElement): Promise<void> => {
    console.info('LaunchIntoFullscreen', element);

    // tslint:disable-next-line
    if (typeof element.requestFullscreen === 'function') {
        console.info('LaunchIntoFullscreen mode 1');
        await element.requestFullscreen();
        return;
    }

    //@ts-expect-error
    if (typeof element.mozRequestFullScreen === 'function') {
        console.info('LaunchIntoFullscreen mode 2');
        //@ts-expect-error
        element.mozRequestFullScreen();
        return;
    }

    //@ts-expect-error
    if (typeof element.webkitRequestFullscreen === 'function') {
        console.info('LaunchIntoFullscreen mode 3');
        //@ts-expect-error
        element.webkitRequestFullscreen();
        return;
    }

    console.error('LaunchIntoFullscreen fail', element);
};

const exitFullscreen = async (): Promise<void>  => {
    // tslint:disable-next-line
    if (typeof document.exitFullscreen === 'function') {
        console.info('ExitFullscreen mode 1');
        await document.exitFullscreen();
        return;
    }

    //@ts-expect-error
    if (typeof document.mozCancelFullScreen === 'function') {
        console.info('ExitFullscreen mode 2');
        //@ts-expect-error
        document.mozCancelFullScreen();
        return;
    }

    //@ts-expect-error
    if (typeof document.webkitExitFullscreen === 'function') {
        console.info('ExitFullscreen mode 3');
        //@ts-expect-error
        document.webkitExitFullscreen();
        return;
    }

    console.error('ExitFullscreen fail');
};

const runPromise = (toRun: () => Promise<void>): void => {
    toRun().catch((error) => {
        console.error(error);
    });
};

export class FullScreenState {
    @observable public isFullScreen: boolean = false;
    private element: HTMLElement | null = null;

    public toggleFullscreen = (): void => {
        if (this.isFullScreen) {
            runPromise(exitFullscreen);
        } else {
            const element = this.element;
            if (element === null) {
                return;
            }
            runPromise((): Promise<void> => launchIntoFullscreen(element));
        }

        this.isFullScreen = !this.isFullScreen;
    };

    public clearFullScreen = (): void => {
        this.isFullScreen = false;
        this.element = null;
    };

    public setRef = (ref: HTMLElement | null): void => {
        this.element = ref;
    }

    public constructor() {
        makeObservable(this);
    }
}
