import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ChevronIconWrapper, PaginationWrapper, PaginationButton, DotItem, DotsList } from './Pagination.style';
import { TransactionHistoryCollection } from 'src/domains/players/webview/components/Account/transactionHistoryTab/TransactionHistoryCollection';
import { TransactionListLoaderWrapper } from 'src/domains/players/webview/components/Account/transactionsList/TransactionsList.style';
import { useAppStateContext } from 'src/appState/AppState';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';

const renderDots = (totalPages: number, activePage: number): JSX.Element[] => {
    const dots = [];

    for (let i = 0; i < totalPages; i++){
        const isActive = i === activePage;
        const bigDot = ((activePage - 1) === i) || (activePage + 1) === i || isActive === true;
        const smallDot = ((activePage - 2) === i) || (activePage + 2) === i || isActive === true;

        dots.push (
            <DotItem
                isActive={isActive}
                key={i}
                bigDot={bigDot}
                smallDot={smallDot}
            />
        );
    }

    return dots;
};

const renderPrev = (breakpointsState: BreakpointsState, collection: TransactionHistoryCollection): React.ReactElement | null => {
    if (breakpointsState.tablet.isBiggerOrEq === true) {
        return (
            <PaginationButton onClick={collection.prevPage} isHidden={collection.isFirstPage}>
                <ChevronIconWrapper position='left'/>
            </PaginationButton>
        );
    }

    return null;
};

const renderNext = (breakpointsState: BreakpointsState, collection: TransactionHistoryCollection): React.ReactElement | null => {
    if (breakpointsState.tablet.isBiggerOrEq === true) {
        return (
            <PaginationButton onClick={collection.nextPage} isHidden={collection.isLastPage}>
                <ChevronIconWrapper position='right'/>
            </PaginationButton>    
        );
    }

    return null;
};

interface PaginationPropsTypes {
    collection: TransactionHistoryCollection;
}

export const Pagination = observer('Pagination', (props: PaginationPropsTypes): JSX.Element | null => {
    const appState = useAppStateContext();

    const { collection } = props;
    const { breakpointsState } = appState.appLayoutsState;

    if (collection.isNetDeposit) {
        return null;
    }

    if (collection.isFirstPage === false && collection.hasNextPageLoading) {
        return (
            <PaginationWrapper>
                <TransactionListLoaderWrapper
                    size={14}
                >
                </TransactionListLoaderWrapper>
            </PaginationWrapper>
        );
    }
                
    if (collection.hasPrevPage || collection.hasNextPage) {
        return (
            <PaginationWrapper>
                { renderPrev(breakpointsState, collection) }

                <DotsList>
                    {renderDots(collection.totalPages, collection.activePage)}
                </DotsList>

                { renderNext(breakpointsState, collection) }
            </PaginationWrapper>
        );
    }

    return null;
});
