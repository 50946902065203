import * as t from 'io-ts';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { buildModelValidator, buildApiItemDefault } from 'src/api/utils/modelUtils';
import {
    decodeNumberOrNull,
    decodeBoolean,
    decodeString,
    decodeStringOrNull,
    decodeStringArr,
    decodeNumber,
    decodeBooleanOrNull,
    buildOptionalDecoderModel,
    decodeBooleanOrUndefined } from 'src/api/utils/commonModelValidators';

const decodePhone = buildValidator('Phone', t.interface({
    country: t.string,
    number: t.string,
    prefix: t.string,
}));

const modelConfig = {
    chatEnabled: buildApiItemDefault(decodeBoolean, false),
    realityCheckFrequency: buildApiItemDefault(decodeNumberOrNull, null),
    addressLine1: buildApiItemDefault(decodeStringOrNull, ''),
    dailyDepositLimitRequestDate: buildApiItemDefault(decodeStringOrNull, null),
    city: buildApiItemDefault(decodeStringOrNull, ''),
    weeklyDepositLimit: buildApiItemDefault(decodeNumberOrNull, null),
    securityNotes: buildApiItemDefault(decodeStringOrNull, null),
    dailyDepositLimit: buildApiItemDefault(decodeNumberOrNull, null),
    playBreakExpiry: buildApiItemDefault(decodeStringOrNull, null),
    requestedDailyDepositLimit: buildApiItemDefault(decodeNumberOrNull, null),
    stakeFactorInPlaySports: buildApiItemDefault(buildValidator('', t.array(t.unknown)), []),
    statusDescription: buildApiItemDefault(decodeStringOrNull, null),
    selfExclusionDuration: buildApiItemDefault(decodeNumberOrNull, null),
    printedStatement: buildApiItemDefault(decodeBoolean, false),
    commission: buildApiItemDefault(decodeBoolean, false),
    postCode: buildApiItemDefault(decodeStringOrNull, ''),
    email: buildApiItemDefault(decodeString, ''),
    realityCheckFrequencyDateSet: buildApiItemDefault(decodeStringOrNull, null),
    dailyDepositLimitDateSet: buildApiItemDefault(decodeStringOrNull, null),
    telebettingId: buildApiItemDefault(decodeStringOrNull, null),
    requestedRealityCheckFrequency: buildApiItemDefault(decodeNumberOrNull, null),
    bankAccountSortCode: buildApiItemDefault(decodeStringOrNull, null),
    creditLimit: buildApiItemDefault(decodeNumber, 0),
    telebettingPassword: buildApiItemDefault(decodeStringOrNull, null),
    monthlyDepositLimit: buildApiItemDefault(decodeNumberOrNull, null),
    surname: buildApiItemDefault(decodeStringOrNull, ''),
    watchlist: buildApiItemDefault(decodeBoolean, false),
    contactPreferences: buildApiItemDefault(decodeStringArr, []),
    country: buildApiItemDefault(decodeString, ''),
    mobilePhone: buildApiItemDefault(buildOptionalDecoderModel(decodePhone), null),
    creditAccount: buildApiItemDefault(decodeBoolean, false),
    betReferralEnabled: buildApiItemDefault(decodeBoolean, false),
    weeklyDepositLimitDateSet: buildApiItemDefault(decodeStringOrNull, null),
    county: buildApiItemDefault(decodeStringOrNull, null),
    realityCheckFrequencyRequestDate: buildApiItemDefault(decodeStringOrNull, null),
    stakeFactorInPlay: buildApiItemDefault(decodeNumber, 0),
    kycStatus: buildApiItemDefault(decodeString, ''),
    kycRequestExpireDate: buildApiItemDefault(decodeStringOrNull, ''),
    firstName: buildApiItemDefault(decodeStringOrNull, ''),
    gamStopVerification: buildApiItemDefault(decodeString, ''),
    playBreakDuration: buildApiItemDefault(decodeNumberOrNull, null),
    birthDate: buildApiItemDefault(decodeString, ''),
    cashoutEnabled: buildApiItemDefault(decodeBoolean, false),
    tradingNotes: buildApiItemDefault(decodeStringOrNull, null),
    bpEnabled: buildApiItemDefault(decodeBoolean, false),
    language: buildApiItemDefault(decodeStringOrNull, null),
    weeklyDepositLimitRequestDate: buildApiItemDefault(decodeStringOrNull, null),
    reactivationRequestedDate: buildApiItemDefault(decodeStringOrNull, null),
    monthlyDepositLimitRequestDate: buildApiItemDefault(decodeStringOrNull, null),
    selfExclusionDate: buildApiItemDefault(decodeStringOrNull, null),
    marketing: buildApiItemDefault(decodeBooleanOrNull, null),
    roles: buildApiItemDefault(decodeStringArr, []),
    landlinePhone: buildApiItemDefault(buildOptionalDecoderModel(decodePhone), null),
    selfExclusionExpiry: buildApiItemDefault(decodeStringOrNull, null),
    creditPaymentTerms: buildApiItemDefault(decodeString, ''),
    amlStatus: buildApiItemDefault(decodeString, ''),
    status: buildApiItemDefault(decodeString, ''),
    requestedMonthlyDepositLimit: buildApiItemDefault(decodeNumberOrNull, null),
    addressLine2: buildApiItemDefault(decodeStringOrNull, null),
    requestedWeeklyDepositLimit: buildApiItemDefault(decodeNumberOrNull, null),
    passwordResetToken: buildApiItemDefault(
        buildValidator('', t.union([
            t.interface({
                sourceIp: t.string,
                token: t.string,
                validFrom: t.number,
                validTo: t.number,
                sentVia: t.string,
                attempts: t.number
            }),
            t.null
        ])),
        null
    ),
    cancelWithdrawal: buildApiItemDefault(decodeBooleanOrNull, false),
    stakeFactorSports: buildApiItemDefault(buildValidator('', t.array(t.unknown)), []),
    quickBetReferralEnabled: buildApiItemDefault(decodeBoolean, false),
    currency: buildApiItemDefault(decodeString, ''),
    referrer: buildApiItemDefault(decodeStringOrNull, null),
    mailingEnabled: buildApiItemDefault(decodeBoolean, false),
    title: buildApiItemDefault(decodeStringOrNull, null),
    bankAccountNumber: buildApiItemDefault(decodeStringOrNull, null),
    type: buildApiItemDefault(decodeString, ''),
    oddsFormat: buildApiItemDefault(decodeString, ''),
    failedLoginAttempts: buildApiItemDefault(decodeNumber, 0),
    terms: buildApiItemDefault(
        buildValidator('', t.union([ t.boolean, t.null ])),
        null
    ),
    playBreakRequestDate: buildApiItemDefault(decodeStringOrNull, null),
    statementFrequency: buildApiItemDefault(decodeString, ''),
    monthlyDepositLimitDateSet: buildApiItemDefault(decodeStringOrNull, null),
    ringFencedFunds: buildApiItemDefault(decodeBoolean, false),
    incomeaccess: buildApiItemDefault(decodeStringOrNull, null),
    stakeFactor: buildApiItemDefault(decodeNumber, 0),
    antePostRules: buildApiItemDefault(decodeBoolean, false),
    coolingOffPeriodHours: buildApiItemDefault(decodeNumberOrNull, null),
    amlWatchlist: buildApiItemDefault(decodeBoolean, false),
    id: buildApiItemDefault(decodeNumber, 0),
    universe: buildApiItemDefault(decodeString, ''),
    name: buildApiItemDefault(decodeString, ''),
    url: buildApiItemDefault(decodeString, ''),
    passwordResetTokenUrl: buildApiItemDefault(decodeString, ''),
    resetPasswordUrl: buildApiItemDefault(decodeString, ''),
    walletUrl: buildApiItemDefault(decodeString, ''),
    transactionUrl: buildApiItemDefault(decodeString, ''),
    assetFlowsUrl: buildApiItemDefault(decodeString, ''),
    statementsUrl: buildApiItemDefault(decodeString, ''),
    revision: buildApiItemDefault(decodeNumber, 0),
    tartanClub: buildApiItemDefault(decodeBooleanOrUndefined, false),
    marketingQuestionsAnswers: buildApiItemDefault(buildValidator('', t.union([t.record(t.string, t.string), t.null, t.undefined])), null),
    ageVerification: buildApiItemDefault(decodeString, ''),
    pushNotificationsEnabled: buildApiItemDefault(decodeBooleanOrNull, false),
};

export const decodeAccountBasicDataModel = buildModelValidator('Accounts full data', modelConfig);

export type AccountBasicDataModelType = ReturnType<typeof decodeAccountBasicDataModel>;
