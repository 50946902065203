import styled from '@emotion/styled';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';


export const NoConnectionWrapper = withConfig(theme => styled('div', { label: 'NoConnectionWrapper' })`
    ${positionElementFull};
    align-content: center;
    background: ${theme.star.utilityPage.bgColor};
    color: ${theme.star.utilityPage.txtColor};
    display: grid;
    justify-content: center;
    position: fixed;
    text-align: center;
    z-index: 12;
    animation: fadeIn .3s ease  1 forwards;
    padding: 20px;
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
`);

export const WarningIconWrapper = withConfig(theme => styled(WarningIcon, { label: 'WarningIconWrapper' })`
    fill: ${theme.star.utilityPage.txtColorSecondary};
    margin: 0 auto;
    width: 56px;
`);

export const InfoHeader = withConfig(theme => styled('h1', { label: 'InfoHeader' })`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 24px 0 0;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.medium};
        line-height: 1.3333;
    }
`);

export const InfoSubHeader = withConfig(theme => styled('p', { label: 'InfoSubHeader' })`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4285;
    margin: 0;
`);
