import { computed, makeObservable } from 'mobx';
import React, { useState } from 'react';
import { HeadlineCarouselState } from 'src/domains/layouts/state/cmsState/headlineCarouselState/HeadlineCarouselState';
import { observer } from 'src/utils/mobx-react';
import { Slider, SliderType } from 'src/domains/layouts/webview/components/common/slider/Slider';
import { HeadlineCarouselSlide } from './HeadlineCarouselSlide';
import { HeadlineCarouselWrapper } from 'src/domains/layouts/webview/components/headlineCarousel/HeadlineCarouselSlide.style';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { HeadlineCarouselActiveModelType } from 'src/api/config/cms_new/headlineCarouselActive/getHeadlineCarouselActive';
import { DateTime } from 'src_common/utils/time/time';

class State {
    public readonly reactiveWrapper: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);
    public constructor(
        private readonly headlineCarousel: HeadlineCarouselState,
    ) {
        makeObservable(this);
    }
    @computed public get elementRefHeight(): number {
        const elementRef = this.reactiveWrapper.ref;
        return elementRef?.clientHeight ?? 0;
    }

    @computed public get sortedCarouselsItem(): Array<HeadlineCarouselActiveModelType> {
        return this.headlineCarousel.headlineCarouselData.filter(headline => DateTime.current().isBetween(headline.date_from, headline.date_to)).sort((a,b) => a.display_order - b.display_order );
    }

    @computed public get sliderData(): Array<SliderType> {
        return this.sortedCarouselsItem.map(headlineCarouselItem => {
            return {
                id: headlineCarouselItem.id.toString(),
                component: <HeadlineCarouselSlide headlineCarouselItem={headlineCarouselItem}/>
            };
        });
    }
}

interface HeadlineCarouselType {
    route: 'casino' | 'live-casino' | 'virtuals';
}

export const HeadlineCarousel = observer('HeadlineCarousel', ({ route }: HeadlineCarouselType): JSX.Element | null => {
    const [headlineCarouselState] = useState(() => new HeadlineCarouselState( route ));
    const [state] = useState(() => new State(headlineCarouselState));

    if (state.sliderData.length > 0) {
        return (
            <HeadlineCarouselWrapper computedHeight={state.elementRefHeight}>
                <div ref={state.reactiveWrapper.setRef}>
                    <Slider banners={state.sliderData} isHomepage={false}/>
                </div>
            </HeadlineCarouselWrapper>
        );
    }

    return null;
});
