import { action, observable, makeObservable } from 'mobx';
import { SignUpParamsType } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/SignUpParams';
import { SignUpMainSteps } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/createAccount/SignupMainSteps';

export class SignupTertiaryState {
    public signupType = 'SignupTertiary';
    @observable public isLoading: boolean = false;

    public currentView: SignUpMainSteps;

    public constructor(public readonly params: SignUpParamsType) {
        makeObservable(this);
        this.currentView = new SignUpMainSteps(params, this.setLoading, this.toggleAside);
    }

    @action public rebuildState = (): void => {
        this.currentView = new SignUpMainSteps(this.params, this.setLoading, this.toggleAside);
        this.params.googleTagManager.renderNewUuid();
    };

    @action public setLoading = (isLoading: boolean): void => {
        this.isLoading = isLoading;
    };

    @action public toggleAside = async (): Promise<void> => {
        this.rebuildState();
        this.params.router.closeAccount();

        if (this.isLoading) {
            await this.params.accountState.handleLogout();
        }
    };
}
