import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { UniverseComponent } from 'src/domains/common/universes';
import { MobileFooterLinksType } from 'src/domains/layouts/config/features/types';
import {
    BingoIconWrapper,
    CashOutIconWrapper,
    CasinoIconWrapper,
    FootballIconWrapper,
    HomeIconWrapper,
    HorseracingIconWrapper,
    InPlayIconWrapper,
    LiveCasinoIconWrapper,
    LoudSpeakerIconWrapper,
    SportsListIconWrapper,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/mobileFooter/MobileFooterIcons.style';

const footerIconsAll: Record<MobileFooterLinksType, React.ReactElement> = {
    bingo: <BingoIconWrapper />,
    'cash-out': <CashOutIconWrapper />,
    casino: <CasinoIconWrapper />,
    'football-european': <FootballIconWrapper />,
    football: <FootballIconWrapper />,
    home: <HomeIconWrapper />,
    'in-play': <InPlayIconWrapper />,
    'live-casino': <LiveCasinoIconWrapper />,
    promos: <LoudSpeakerIconWrapper />,
    racing: <HorseracingIconWrapper />,
    'sports-list': <SportsListIconWrapper />,
    sportsbook: <FootballIconWrapper />,
};

interface FooterItemsIconPropsType {
    slug: MobileFooterLinksType;
}

export const FooterItemsIcon = observer('FooterItemsIcon', ({ slug }: FooterItemsIconPropsType): JSX.Element => {
    return (
        <UniverseComponent>
            {(universeVersion): React.ReactElement => {
                const overwrittenIcon = universeVersion.footerMobileIcons(slug);

                if (overwrittenIcon !== null) {
                    return overwrittenIcon;
                }

                const regularIcon = footerIconsAll[slug];

                return regularIcon;
            }}
        </UniverseComponent>
    );
});
