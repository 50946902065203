import * as React from 'react';
import { computed, observable, makeObservable, action } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    SelfExclusionParagraph,
    SelfExclusionExpiryDate,
    SectionHeader,
    SelfExclusionProcedureButton,
} from 'src/domains/players/webview/components/Account/limitsTab/selfExclusionProcedure/SelfExclusionProcedure.style';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { ConfirmationBoxState } from 'src/domains/players/state/ConfirmationBoxState';
import { MessageBoxState } from 'src/domains/players/state/MessageBoxState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { SelectNew, SelectedOption } from 'src/domains/players/webview/components/form/selectNew/SelectNew';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateExpectNumber, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { DateTime } from 'src_common/utils/time/time';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

class SelfExclusionState {
    @observable private infoMessage = '';
    @observable private selfExclusionDate: string | null | undefined = '';

    public selfExclusionInput: FormInputState<string, number>;

    public constructor(
        private readonly language: LanguagesState,
        private readonly confirmationBox: ConfirmationBoxState,
        private readonly accountState: AccountState,
        private readonly messageBox: MessageBoxState,
        private readonly router: StarRouter
    ) {
        makeObservable(this);

        this.selfExclusionInput = FormInputState.new('').map(validateRequire).map(validateExpectNumber);
    }

    @computed public get status(): 'loading' | 'error' | 'ready' | undefined {
        const dataModel = this.accountState.usersState.basicData.get();
        return dataModel.type;
    }

    public renderSelfExclusionDate(): JSX.Element | undefined {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (this.selfExclusionDate) {
            return (
                <div>
                    <I18n langKey='account.self-exclusion.expiry-date.prefix' defaultText='Expiry date:' />{' '}
                    <SelfExclusionExpiryDate>{this.selfExclusionDate}</SelfExclusionExpiryDate>
                </div>
            );
        }

        return undefined;
    }

    @action public onChange = (): void => {
        if (this.selfExclusionInput.result.value.type === 'error') {
            return;
        }

        const selfExclusionInputValue = this.selfExclusionInput.result.value.data;
        const dateWithDuration = DateTime.current().addMonths(selfExclusionInputValue).format('DD/MM/YYYY');
        this.selfExclusionDate = dateWithDuration;
    };

    @computed public get selfExclusionOptions(): SelectedOption[] {
        return [
            { label: this.language.getTranslation('account.self-exclusion.6', '6 Months'), value: '6' },
            { label: this.language.getTranslation('account.self-exclusion.12', '1 Year'), value: '12' },
            { label: this.language.getTranslation('account.self-exclusion.24', '2 Years'), value: '24' },
            { label: this.language.getTranslation('account.self-exclusion.36', '3 Years'), value: '36' },
            { label: this.language.getTranslation('account.self-exclusion.48', '4 Years'), value: '48' },
            { label: this.language.getTranslation('account.self-exclusion.60', '5 Years'), value: '60' },
        ];
    }

    public showPopup = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.selfExclusionInput.setAsVisited();
        if (this.selfExclusionInput.result.value.type === 'error') {
            return;
        }

        this.confirmationBox.showConfirmation(
            <I18n langKey='common.confirmation-box.title' defaultText='Confirm' />,
            <I18n
                langKey='account.selfExclusion.confirmation'
                defaultText='Are you sure that you want to suspend your account for {defaultTextInfo} months?'
                params={{ defaultTextInfo: this.selfExclusionInput.result.value.data }}
            />,
            this.onSubmit,
            () => {}
        );
    };

    private onSubmit = async (): Promise<void> => {
        if (this.accountState.account === null || this.selfExclusionInput.result.value.type === 'error') {
            return;
        }
        try {
            await this.accountState.account.onChangeSelfExclusion(this.selfExclusionInput.result.value.data);
            this.messageBox.runActionNotLogged();
            this.router.redirectToHomepage();
        } catch (error) {
            // temporary solution, BE send 401 "invalid_token"
            this.messageBox.runActionNotLogged();
            this.router.redirectToHomepage();
            this.infoMessage = 'error';
        }
    };

    public renderMessage = (): JSX.Element | string => {
        if (this.infoMessage === 'error') {
            return (
                <Messages
                    marginBottom='8px'
                    type='error'
                    message={
                        <I18n
                            langKey='account.self-exclusion.error-message-new'
                            defaultText='Sorry, we seem to have a problem. Please try again.'
                        />
                    }
                />
            );
        }

        return '';
    };
}
export const SelfExclusionProcedure = observer('SelfExclusionProcedure', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState, starRouter } = appLayoutsState;
    const { accountState, confirmationBox, messageBox } = appPlayersState;
    const [state] = React.useState(
        () => new SelfExclusionState(languagesState, confirmationBox, accountState, messageBox, starRouter)
    );

    if (state.status === 'loading') {
        return <I18n langKey='account.self-exclusion.loading' defaultText='Loading...' />;
    }
    return (
        <form onSubmit={state.showPopup} data-test='self-exclusion-procedure'>
            <SectionHeader data-test='self-exclusion-header'>
                <I18n langKey='account.self-exclusion.title' defaultText='Self-Exclusion' />
            </SectionHeader>

            <SelfExclusionParagraph data-test='self-exclusion-paragraph'>
                <I18n
                    langKey='account.self-exclusion.description'
                    defaultText='You may wish to exclude yourself from accessing the site completely. Once set you will be logged out immediately, any remaining balance be refunded to you and you will not be able login or change or cancel the exclusion period. Your account will remain inactive beyond this period unless you contact customer services.'
                />
            </SelfExclusionParagraph>

            <SelectNew
                dataTest='self-exclusion-select'
                options={state.selfExclusionOptions}
                inputState={state.selfExclusionInput}
                onChange={state.onChange}
                placeholder={languagesState.getTranslation(
                    'account.self-exclusion-select.label',
                    'Choose self-exclusion period'
                )}
            />

            {state.renderMessage()}

            {state.renderSelfExclusionDate()}

            <div className='buttons'>
                <SelfExclusionProcedureButton type='submit' size='medium' dataTest='self-exclusion-save-button'>
                    <I18n langKey='account.selfExclusion.submit.label' defaultText='Save' />
                </SelfExclusionProcedureButton>
            </div>
        </form>
    );
});
