import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { SwitchIcon } from 'src/domains/layouts/shared/assets/icons/SwitchIcon';
import { withConfig } from 'src/withConfig';

type PriceInputTypes = 'up' | 'down' | null;

const oddsColors = (theme: EmotionTheme, priceDirection: PriceInputTypes): SerializedStyles => {
    if (priceDirection === 'up') {
        return css`
            background: ${theme.star.odds.bgColor};
        `;
    }

    if (priceDirection === 'down') {
        return css`
            background: ${theme.star.odds.bgColorSecondary};
        `;
    }

    return css`
        background: ${theme.star.odds.bgColorQuaternary};
    `;
};

interface PriceInputPropsTypes {
    priceDirection: PriceInputTypes;
}

export const PriceInputWrapper = withConfig(theme => styled('div', { label: 'PriceInputWrapper' })<PriceInputPropsTypes>`
    ${({ priceDirection }): SerializedStyles => oddsColors(theme, priceDirection)};
    align-items: center;
    color: ${theme.star.odds.txtColor};
    cursor: pointer;
    display: flex;
    flex: 0 0 66px;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    height: 100%;
    justify-content: center;
`);

export const Option = withConfig(theme => styled('span', { label: 'Option' })`
    color: ${theme.star.odds.txtColor};
    line-height: 1.3334;
`);

export const SwitchIconWrapper = withConfig(theme => styled(SwitchIcon, { label: 'SwitchIconWrapper' })`
    fill: ${theme.star.betslipGeneral.iconColorSwitch};
    margin-left: 4px;
    pointer-events: none;
    width: 12px;
`);
