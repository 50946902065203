import { action, observable, makeObservable } from 'mobx';
import { createGuard } from 'src_common/common/createGuard';
import { jsonParse } from 'src_common/common/jsonParse';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { Session } from 'src_common/sdk/session';
import { BetsBoostTypes, MyBetsBetHistoryState, SelectionBoostState } from './LifespanSocketState';
import { SocketConnectionController, SocketEventType } from './SocketConnection';
import * as t from 'io-ts';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';

const UserRewardsMessageAvailableIO = t.interface({
    active: t.boolean,
    id: t.string,
    category: t.string,
    expiry: t.number,
    rewardType: t.string,
    description: t.string,
    routeInfo: t.union([t.string, t.undefined]),
});

const UpdateBetslipAvailableIO = t.interface({
    amount: t.number,
    competition: t.string,
    currency: t.union([t.string, t.undefined]),
    decimalOdds: t.union([t.number, t.undefined]),
    event: t.string,
    market: t.string,
    reference: t.string,
    reward: t.union([t.interface({
        boostValue: t.number,
        currency: t.union([t.string, t.undefined]),
        id: t.string,
        active: t.union([t.boolean, t.undefined]),
        maxBoostedStake: t.interface({
            EUR: t.number,
            GBP: t.number,
        }),
        rewardType: t.string,
        value: t.number,
    }), t.undefined]),
    selection: t.string,
    sport: t.string,
    startTime: t.number,
});

const BetInBetsHistoryIO = t.interface({
    betId: t.string,
    campaignId: t.string,
    result: t.union([t.union([t.literal('reward-won'), t.literal('reward-lost'), t.literal('active')]), t.undefined]),
    reward: t.interface({
        currency: t.union([t.union([t.literal('EUR'), t.literal('XTS'), t.literal('GBP')]), t.undefined]),
        type: t.union([t.string, t.undefined]),
        value: t.union([t.number, t.undefined]),
    })
});

const isUserRewardsMessageAvailable = createGuard(UserRewardsMessageAvailableIO);
const isUpdateBetslipMessageAvailable = createGuard(UpdateBetslipAvailableIO);

const UserRewardsMessageIO = t.interface({
    message: t.literal('user-rewards'),
    data: t.interface({
        available: t.array(UserRewardsMessageAvailableIO)
    })
});

const UpdateBetslipMessageIO = t.interface({
    message: t.literal('update-betslip'),
    betslip: t.array(UpdateBetslipAvailableIO),
});

const ActivateRewardIO = t.interface({
    message: t.literal('activate-reward'),
    response: t.interface({
        data: t.unknown,
        success: t.boolean,
    })
});

const DeactivateRewardIO = t.interface({
    type: t.literal('deactivate-reward'),
    payload: t.interface({
        campaignId: t.string,
        reference: t.string,
    })
});

const ActivateRewardCloseIO = t.interface({
    id: t.string,
    category: t.string,
    expiry: t.number,
    description: t.string,
    rewardType: t.string,
});

const isActiveRewardClose = createGuard(ActivateRewardCloseIO);

const MyBetsBetHistoryIO = t.interface({
    type: t.literal('check-bets-list'),
    payload: t.interface({
        bets: t.array(BetInBetsHistoryIO)
    })
});

export type UserRewardsMessageAvailableType = t.TypeOf<typeof UserRewardsMessageAvailableIO>;
export type UpdateBetslipMessageAvailableType = t.TypeOf<typeof UpdateBetslipAvailableIO>;
export type BetInBetHistoryType = t.TypeOf<typeof BetInBetsHistoryIO>;

const AnotherMessageIO = t.interface({
    message: t.literal('another'),
    data: t.interface({})
});

const KnownMessageIO = t.union([UserRewardsMessageIO, AnotherMessageIO, UpdateBetslipMessageIO, ActivateRewardIO]);

const KnownMessageV1IO = t.union([MyBetsBetHistoryIO, DeactivateRewardIO]);

const isKnownMessage = createGuard(KnownMessageIO);
const isKnownMessageV1 = createGuard(KnownMessageV1IO);

type MessageTo = unknown

class SelectionButtonState {
    public isLoading: boolean;

    public constructor() {
        this.isLoading = false;
    }
}

export class LifeSpanSocketConnectionState {
    public readonly socketController: SocketConnectionController<MessageTo>;
    @observable.ref public userRewardsMessage: Array<UserRewardsMessageAvailableType> = [];
    @observable public rewardValue: number = 0;
    public rewardValueForBetslipReceipt: number = 0;

    public readonly availableSelections: MobxMapAutoNew<string, SelectionBoostState>;
    public readonly myBetsBetHistory: MobxMapAutoNew<string, MyBetsBetHistoryState>;
    public readonly selectionButton: MobxMapAutoNew<string, SelectionButtonState>;
    
    public constructor(
        private readonly session: Session, 
        socketController: SocketConnectionController<MessageTo>, 
        private readonly googleTagManager: GoogleTagManagerState,
    ) {
        makeObservable(this);
        this.socketController = socketController;
        this.availableSelections = new MobxMapAutoNew(() => new SelectionBoostState());
        this.myBetsBetHistory = new MobxMapAutoNew((betId: string) => new MyBetsBetHistoryState(betId, this.socketController));
        this.selectionButton = new MobxMapAutoNew(() => new SelectionButtonState());
    }

    public onSocketMessageCallback = (event: SocketEventType<MessageTo>): void => {
        const token = this.session.currentJwt;
        
        if (event.type === 'socket') {
            this.socketController.send({
                'action': 'session',
                'data': {
                    'token': token,
                    'format': 'pbe'
                }
            });
            return;
        }

        const messageJson = jsonParse(event.message);

        if (messageJson.type === 'json') {
            this.processMessage(messageJson.json);
        } else {
            console.log('-----> message text', messageJson.text);
        }
    };

    public isSelectionsHasBoost = (bets: BetsBoostTypes[]): void => {
        this.sendMessage({
            'action':'activity',
            'data':{
                'activityType':'update-betslip',
                'betslip':bets
            }
        });
        for (const bet of bets) {
            const selectionButtonState = this.selectionButton.get(bet.reference);
            selectionButtonState.isLoading = true;
        }
    };

    public activeBoost = (selectionWithBoost: UpdateBetslipMessageAvailableType): void => {
        const { reference, reward, startTime } = selectionWithBoost;

        if (reward !== undefined) {
            this.sendMessage({
                'action':'activity',
                'data':{
                    'activityType':'activate-reward',
                    'rewardId':reward.id,
                    'reference':reference,
                    'startTime': startTime
                }
            });
            const selectionButtonState = this.selectionButton.get(reference);
            selectionButtonState.isLoading = true;
        }
    };

    public messageWithEmptyBetslip = (): void => {
        this.sendMessage({
            'action':'activity',
            'data':{
                'activityType':'update-betslip',
                'betslip':[]
            }
        });
    };

    public deactivationBoost = (selectionWithBoost: UpdateBetslipMessageAvailableType): void => {
        const { reward, reference } = selectionWithBoost;
        
        if (reward !== undefined) {
            this.sendMessage({
                'action':'activity',
                'data':{
                    'activityType':'deactivate-reward',
                    'campaignId':reward.id,
                }
            });

            const selectionButtonState = this.selectionButton.get(reference);
            selectionButtonState.isLoading = true;
        }
    };

    public isMyBetIsBoosted = (betId: string): void => {
        this.sendMessage({
            'action':'bets',
            'data':{
                'bets':[betId]
            }
        });
    };

    @action private processMessage = (socketMessage: unknown): void => {
        if (isKnownMessage(socketMessage)) {

            if (socketMessage.message === 'user-rewards') {
                const decodedAvailable = [];

                for (const available of socketMessage.data.available) {
                    if (isUserRewardsMessageAvailable(available)) {
                        // send message to dataLayer
                        this.googleTagManager.lifespanBoostAvailableTag(available.id);

                        decodedAvailable.push(available);
                    } else {
                        console.error('Can not decode user wallet - ', available);
                    }
                }

                this.userRewardsMessage = decodedAvailable;
            }
            
            if (socketMessage.message === 'update-betslip') {
                this.rewardValue = 0;
                this.rewardValueForBetslipReceipt = 0;
                for (const selection of socketMessage.betslip) {
                    if (isUpdateBetslipMessageAvailable(selection)) {
                        
                        const selectionState = this.availableSelections.get(selection.reference);
                        selectionState.selectionInfo = selection;

                        const selectionBoosted = this.availableSelections.get(selectionState.selectionInfo.reference);
                        const { selectionInfo } = selectionBoosted;

                        if (selectionInfo !== null) {
                            if (selectionState.selectionInfo.reward?.active === true) {
                                if (selectionInfo.reward?.active === true) {    
                                    this.rewardValue = this.rewardValue + selectionInfo.reward.value * 100;
                                    this.rewardValueForBetslipReceipt = this.rewardValueForBetslipReceipt + selectionInfo.reward.value * 100;
                                }
                            } 
                        }
                        const selectionButtonState = this.selectionButton.get(selection.reference);
                        selectionButtonState.isLoading = false;
                    } else {
                        console.error('Can not decode user betslip - ', selection);
                    }
                }
            }

            if (socketMessage.message === 'activate-reward') {
                if (isActiveRewardClose(socketMessage.response.data)) {
                    this.rewardValue = 0;
                    this.rewardValueForBetslipReceipt = 0;
                }
            }
        }

        if (isKnownMessageV1(socketMessage)) {
            if (socketMessage.type === 'check-bets-list') {
                const response = socketMessage.payload;

                for (const item of response.bets) {
                    const boostedBet = this.myBetsBetHistory.get(item.betId);
                    boostedBet.setValue(item);
                }
            }

            if (socketMessage.type === 'deactivate-reward') {
                this.updateRewardValue(socketMessage.payload.reference);
                const selectionButtonState = this.selectionButton.get(socketMessage.payload.reference);
                selectionButtonState.isLoading = true;
            }
        }
    };

    @action public sendMessage = (message: unknown): void => {
        this.socketController.send(message);
    };

    @action public updateRewardValue = (reference: string): void => {
        const selectionBoosted = this.availableSelections.get(reference);
        const { selectionInfo } = selectionBoosted;

        if (selectionInfo !== null && selectionInfo.reward !== undefined) {
            this.rewardValue = this.rewardValue - selectionInfo.reward.value * 100;
            this.rewardValueForBetslipReceipt = this.rewardValueForBetslipReceipt - selectionInfo.reward.value * 100;
        }
    };
}
