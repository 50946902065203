import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { PendingWithdrawals, WithdrawProcedureWrapper } from 'src/domains/players/webview/components/Account';
import { action, observable, makeObservable } from 'mobx';
import { WithdrawFailuresType } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawJourney';
import { useAppStateContext } from 'src/appState/AppState';
import { WithdrawPraxisProviderProcedure } from './WithdrawPraxisProviderProcedure';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';
import { WithdrawSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess';
import { WithdrawPraxisProviderState } from './WithdrawPraxisProvider.state';
import { PraxisIframeStyle } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/praxisProvider/PraxisTab.style';

export type WithdrawPraxisProviderStepsType = {
    readonly type: 'set-card';
} | {
    readonly type: 'failure-view';
    readonly failureType: WithdrawFailuresType;
} | {
    type: 'withdraw-pending';
} | {
    type: 'iframe';
    url: string;
}

export class WithdrawPraxisProviderSteps {

    @observable.ref public step: WithdrawPraxisProviderStepsType;

    public constructor() {
        makeObservable(this);
        this.step = {
            type: 'set-card',
        };
    }


    @action public redirectToSetCard = (): void => {
        this.step = {
            type: 'set-card',
        };
    };

    @action public redirectToIframe = (iframeUrl: string): void => {
        this.step = {
            type: 'iframe',
            url: iframeUrl
        };
    };

    @action public redirectToFailureView = (failureType: WithdrawFailuresType): void => {
        this.step = {
            type: 'failure-view',
            failureType
        };
    };

    @action public redirectToPending = (): void => {
        this.step = {
            type: 'withdraw-pending',
        };
    };
}

export const WithdrawPraxisProvider = observer('WithdrawPraxisProvider', () => {
    const { appLayoutsState, appPlayersState, trpcClient } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const { accountState } = appPlayersState;

    const [state] = useState(() => new WithdrawPraxisProviderState(configComponents, accountState, trpcClient));

    switch (state.currentStep.type) {
        case 'set-card':
            return (
                <>
                    <WithdrawProcedureWrapper
                        data-test='praxis-withdraw-procedure-wrapper'>
                        <WithdrawPraxisProviderProcedure state={state} />
                    </WithdrawProcedureWrapper>
                    <PendingWithdrawals />
                </>
            );
        case 'iframe':
            return <PraxisIframeStyle isSignup={false} src={state.currentStep.url} dataTest='praxis-iframe'/>;
        case 'failure-view':
            return <WithdrawIssue failureType={state.currentStep.failureType} />;
        case 'withdraw-pending':
            return <WithdrawSuccess />;
    }
});
