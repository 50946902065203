import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { ApiResponseWrapper } from 'src_common/common/mobx-utils/ApiResponseWrapper';
import { MobxMap } from 'src_common/common/mobx-utils/MobxMap';
import {
    OpenapiProxyCustomerAllBetsParamsType,
    openapiProxyCustomerAllBetsRequest,
    OpenapiProxyCustomerAllBetsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_all_bets';
import { Session } from 'src_common/sdk/session';

export type BetListType =
    OpenapiProxyCustomerAllBetsResponse200Type['results'] extends Array<infer Model> ? Model : never;
export type BetLegType = BetListType['legs'] extends Array<infer Model> ? Model : never;
export type BetsListType = Array<BetListType>;

export class BetsListState {
    private readonly betsList: MobxMap<number, ApiResponseWrapper<OpenapiProxyCustomerAllBetsResponse200Type | null>>;
    private readonly betsListOpen: MobxMap<
        number,
        ApiResponseWrapper<OpenapiProxyCustomerAllBetsResponse200Type | null>
    >;

    public constructor(
        websocketV1: WebsocketV1,
        private readonly session: Session
    ) {
        this.betsList = new MobxMap(
            async (userId: number): Promise<ApiResponseWrapper<OpenapiProxyCustomerAllBetsResponse200Type | null>> => {
                return new ApiResponseWrapper(
                    'BetsList',
                    null,
                    async (): Promise<OpenapiProxyCustomerAllBetsResponse200Type> => {
                        const resp = await this.postAllBets({
                            requestBody: {
                                perPage: 500,
                                from: undefined,
                                to: undefined,
                                statusOpen: undefined,
                                page: 1,
                            },
                        });

                        return resp;
                    },
                    (refresh) => {
                        const callback = (): void => {
                            refresh();
                            setTimeout(refresh, 5000);
                        };

                        callback();

                        const betsTopic = `${userId}:Bets`;
                        const walletTopic = `${userId}:Wallet`;

                        window.addEventListener('online', callback);
                        websocketV1.subscribe(betsTopic, callback);
                        websocketV1.subscribe(walletTopic, callback);

                        return (): void => {
                            window.removeEventListener('online', callback);
                            websocketV1.unsubscribe(betsTopic, callback);
                            websocketV1.unsubscribe(walletTopic, callback);
                        };
                    }
                );
            }
        );

        this.betsListOpen = new MobxMap(
            async (userId: number): Promise<ApiResponseWrapper<OpenapiProxyCustomerAllBetsResponse200Type | null>> => {
                return new ApiResponseWrapper(
                    'BetsListOpen',
                    null,
                    async (): Promise<OpenapiProxyCustomerAllBetsResponse200Type> => {
                        const resp = await this.postAllBets({
                            requestBody: {
                                perPage: 500,
                                from: undefined,
                                to: undefined,
                                statusOpen: true,
                                page: 1,
                            },
                        });

                        return resp;
                    },
                    (refresh) => {
                        const callback = (): void => {
                            refresh();
                            setTimeout(refresh, 5000);
                        };

                        callback();

                        const betsTopic = `${userId}:Bets`;
                        const walletTopic = `${userId}:Wallet`;

                        window.addEventListener('online', callback);
                        websocketV1.subscribe(betsTopic, callback);
                        websocketV1.subscribe(walletTopic, callback);

                        return (): void => {
                            window.removeEventListener('online', callback);
                            websocketV1.unsubscribe(betsTopic, callback);
                            websocketV1.unsubscribe(walletTopic, callback);
                        };
                    }
                );
            }
        );
    }
    public postAllBets(
        params: OpenapiProxyCustomerAllBetsParamsType
    ): Promise<OpenapiProxyCustomerAllBetsResponse200Type> {
        return this.session.call(openapiProxyCustomerAllBetsRequest, params);
    }

    public get list(): OpenapiProxyCustomerAllBetsResponse200Type | null {
        if (this.session.userId !== null) {
            const betsListResource = this.betsList.get(this.session.userId).get();

            if (betsListResource.type === 'ready') {
                return betsListResource.value.data;
            }
        }
        return null;
    }

    public get listOpen(): OpenapiProxyCustomerAllBetsResponse200Type | null {
        if (this.session.userId !== null) {
            const betsListOpenResource = this.betsListOpen.get(this.session.userId).get();

            if (betsListOpenResource.type === 'ready') {
                return betsListOpenResource.value.data;
            }
        }
        return null;
    }
}
