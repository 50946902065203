import { computed, makeObservable } from 'mobx';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { COLUMNS } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/SelectionGroupState';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';

interface ComponentProps {
    selection: SelectionModel;
    eventId: EventId;
    marketId: MarketId;
    isExpanded: boolean;
    itemsIn: Array<SelectionModel>;
    selectionSize?: number | null;
}
export class SelectionTemplateRowState {
    public constructor(
        private readonly componentProps: ComponentProps,
        private readonly breakpointsState?: BreakpointsState,
    ) {
        makeObservable(this);
    }

    @computed private get eventId(): EventId {
        return this.componentProps.eventId;
    }

    @computed private get marketId(): MarketId {
        return this.componentProps.marketId;
    }

    @computed private get isExpanded(): boolean {
        return this.componentProps.isExpanded;
    }

    @computed private get itemsIn(): Array<SelectionModel> {
        return this.componentProps.itemsIn;
    }

    @computed private get selectionSize(): number | null {
        return this.componentProps.selectionSize ?? null;
    }

    @computed private get selection(): SelectionModel {
        return this.componentProps.selection;
    }

    @computed public get eventModel(): EventModel | null {
        return this.eventId.getEventModel();
    }

    @computed private get displayOrder(): string | null {
        return this.selection.marketSelectionOrdering ?? null;
    }

    @computed public get order(): string {
        if (this.displayOrder !== null && this.displayOrder !== '-') {
            return this.displayOrder;
        }

        return 'by-creation';
    }

    @computed private get items(): Array<SelectionModel> {
        const items = this.itemsIn.filter(elem => elem.display);
        return sortSelectionsByCriteria(items, this.order);
    }

    @computed private get eventMarket(): MarketModel | null {
        return this.marketId.getModel();
    }

    @computed public get displayTemplate(): string | undefined {
        return this.selection.marketDisplayTemplate;
    }

    @computed private get isMobile(): boolean {
        return this.breakpointsState?.tablet.isBiggerOrEq === false;
    }

    @computed public get columnsCount(): number {
        if (this.isMobile === true) {
            return 1;
        } else {
            if (this.displayTemplate !== undefined) {
                return COLUMNS[this.displayTemplate] ?? 1;
            }
        }
        return 1;
    }

    @computed public get limitRowToDisplay(): number {
        if (this.isMobile === true) {
            return 8;
        } else {
            return 4;
        }
    }

    @computed private get columnsRowsCount(): number {
        if (this.eventMarket !== null && this.isExpanded && this.selectionSize !== null) {
            return this.selectionSize;
        } else {
            return this.limitRowToDisplay * this.columnsCount;
        }
    }

    @computed public get divider(): number | null {
        if (this.displayTemplate === 'three-column') {
            return 3;
        }

        if (this.displayTemplate === 'two-column') {
            return 2;
        }

        return null;
    }

    @computed public get selectionsToRender(): Array<SelectionModel> {
        if (this.isExpanded) {
            return this.items;
        }

        return this.items.slice(0, this.columnsRowsCount);
    }
};
