import { action, computed, observable, makeObservable } from 'mobx';
import { groupBy } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/groupBy';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';

const SELECTION_ORDER: Record<string, Array<string>> = {
    'race-to': [ 'A', 'N', 'H' ],
    'both-teams-to-score': [ 'N', 'Y' ],
    'away-to-win-to-nil': [ 'N', 'Y' ],
    'home-to-win-to-nil': [ 'N', 'Y' ],
    'home-clean-sheet': [ 'N', 'Y' ],
    'away-clean-sheet': [ 'N', 'Y' ],
    'red-card-in-match': [ 'N', 'Y' ],
    'overtime': [ 'N', 'Y' ],
    'will-runs-be-scored-by-first-ball': [ 'N', 'Y' ],
    'first-over-runs-odd-even': [ 'E', 'O' ],
    'double-chance': [ 'H-A', 'A-D', 'H-D' ],
    'time-of-1st-goal-over-under': [ '0-30', 'NG', '31-90' ],
    'time-of-2nd-goal-over-under': [ '0-50', 'NG', '51-90' ],
    'time-of-3rd-goal-over-under': [ '0-70', 'NG', '71-90' ],
    'fight-end': [ 'D', 'KO', 'PNTS' ],
    'total-frames': [ 'U', 'Ext', 'O' ],
    'default': [ 'A', 'D', 'H' ]
};

const MULTIPLES = [
    'correct-score',
    'half-time-full-time',
    'half-time-correct-score',
    'match-result-and-both-to-score',
    'match-result-and-goals-over-under',
    /^set-correct-score-[1-9]$/
];

const ONE_COLUMN = [
    'match-goals',
    /^time-of-[1-9][a-z]{2}-goal$/
];

export const COLUMNS: Record<string, number> = {
    'one-column': 1,
    'two-column': 2,
    'three-column': 3
};

export class SelectionGroupState {
    private readonly models: ModelsState;
    @observable public isExpanded = false;
    @observable public selectionsProps:Record<string, SelectionModel[]> = {};

    public constructor(models: ModelsState, selections: Record<string, SelectionModel[]>) {
        makeObservable(this);
        this.models = models;
        this.selectionsProps = selections;
    }


    public test = (market: string): ((id: string | RegExp) => boolean) => {
        return (id: string | RegExp): boolean => {
            if (id instanceof RegExp) {
                return id.test(market);
            } else {
                return id === market;
            }
        };
    };

    public getTemplateId = (selection: SelectionModel): string => {
        const marketTemplateId = selection.templateMarketId;
        // Get market display template and if is 1-3 column then create a single group
        const marketDisplayTemplate = selection.marketDisplayTemplate;
        // Use selection template when no identifier was found
        let templateId = selection.identifier !==  undefined ?  selection.identifier : selection.templateId;
        // Create selections groups based on what display template was specified
        // otherwise fallback to default behavior

        if (templateId === undefined) {
            return '';
        }

        if (marketDisplayTemplate === undefined) {
            return templateId;
        }

        if (marketDisplayTemplate && Object.keys(COLUMNS).includes(marketDisplayTemplate)) {
            return 'single';
        }


        if (marketDisplayTemplate && marketDisplayTemplate !== '-' ) {
            return templateId.charAt(0);
        }

        if (marketDisplayTemplate === '-') {
            return 'single';
        }

        if (templateId === 'T') {
            templateId = 'D';
        }

        if (marketTemplateId === undefined) {
            return templateId;
        }

        if (marketTemplateId && MULTIPLES.some(this.test(marketTemplateId))) {
            return templateId.charAt(0);
        } else if (marketTemplateId && ONE_COLUMN.some(this.test(marketTemplateId))) {
            return 'a';
        } else {
            return templateId;
        }
    };

    public getOrder = (selection: SelectionModel): number=> {
        const marketTemplateId = selection.templateMarketId;
        const defaultValue = SELECTION_ORDER['default'] || [ 'A', 'D', 'H' ];

        const list = marketTemplateId !== undefined ? SELECTION_ORDER[marketTemplateId] : null;
        const resultList = list || defaultValue;

        return resultList.indexOf(this.getTemplateId(selection));
    };

    public groupSelections = (selections: Array<SelectionModel>): Array<Array<SelectionModel>>=> {
        let abc;

        abc = selections.filter( (sel:SelectionModel) => !(
            sel.marketDisplayTemplate !== undefined && sel.marketDisplayTemplate.includes('/home-away/') &&
                    sel.identifier !== undefined &&
                    sel.identifier.match(/D/gi)
        ));

        abc = abc.sort((firstSelection: SelectionModel, secondSelection: SelectionModel): number => {
            if (firstSelection.displayOrder !== undefined && secondSelection.displayOrder !== undefined) {
                return firstSelection.displayOrder - secondSelection.displayOrder;
            } else if (firstSelection.displayOrder === undefined ) {
                return -1;
            }
            return 1;
        }
        );

        abc = abc.sort((firstSelection: SelectionModel, secondSelection: SelectionModel): number => {
            if (firstSelection.marketDisplayOrder !== undefined && secondSelection.marketDisplayOrder !== undefined) {
                return secondSelection.marketDisplayOrder - firstSelection.marketDisplayOrder;
            } else if (firstSelection.marketDisplayOrder === undefined) {
                return 1;
            }
            return -1;
        });

        abc = abc.sort((firstSelection: SelectionModel, secondSelection: SelectionModel): number => {
            return this.getOrder(secondSelection) - this.getOrder(firstSelection);
        });

        const flattenedArray = abc.flat();

        return groupBy(flattenedArray, this.getTemplateId);
    };

    public filterSelections = (list: Array<SelectionModel | null>): Array<SelectionModel> => {
        const out: Array<SelectionModel> = [];
        for (const item of list) {
            if (item !== null) {
                out.push(item);
            }
        }
        return out;
    };

    public selectionsByPropsNew = (modelsState:ModelsState, selections:Record<string, SelectionModel[]>): Array<SelectionModel> | undefined =>  {
        const selectionsList: Record<number, Array<SelectionModel>> = {};

        for (const key of Object.keys(selections)) {
            const selectionByKey = selections[key];

            if (selectionByKey !== undefined) {
                const selectionModels = selectionByKey.map((selection:SelectionModel | null) => {
                    const selectionById = selection !== null ? modelsState.getSelectionAndLoad(selection.marketId, selection.id) : null;
                    if (selectionById !== null) {
                        return selectionById;
                    }
                    return null;
                });


                const filteredSelections: Array<SelectionModel> = this.filterSelections(selectionModels);

                selectionsList[Number(key)] = filteredSelections;
            }

        }
        return Object.values(selectionsList)[0];
    };

    @action public toggleExpand = (e:React.MouseEvent): void => {
        e.preventDefault();
        this.isExpanded = !this.isExpanded;
    }

    @action public setSelectionsProps = (selections: Record<string, SelectionModel[]>): void => {
        this.selectionsProps = selections;
    }

    @computed public get newAllSelectionProps(): Array<SelectionModel> | null {
        const selectionByProps = this.selectionsByPropsNew(this.models, this.selectionsProps);
        return selectionByProps !== undefined ? selectionByProps : null;
    }
    @computed public get limitDisplayBasedOnColumns(): number | undefined {
        const selectionValues = this.newAllSelectionProps !== null ? Object.values(this.newAllSelectionProps) : [];
        const firstSelectionValue = selectionValues[0];
        const firstSelection = firstSelectionValue?.marketDisplayTemplate;

        if (firstSelectionValue !== undefined && firstSelection !== undefined) {

            const limitDisplayBasedOnColumnsObject: Record<string, number> = {
                'two-column': selectionValues.length / 2,
                'three-column': selectionValues.length / 3
            };
            return limitDisplayBasedOnColumnsObject[firstSelection] !== undefined ? limitDisplayBasedOnColumnsObject[firstSelection] : selectionValues.length;

        }
    }


    @computed public get firstSelectionKey(): string | undefined {
        const selectionKeys = this.newAllSelectionProps !== null ? Object.keys(this.newAllSelectionProps) : undefined;
        return selectionKeys !== undefined ? selectionKeys[0] : undefined;
    };

    @computed public get biggestGroup(): number {
        const selections = this.selectionsByPropsNew(this.models, this.selectionsProps);
        const newAllSelectionsProps =  selections !== undefined ? this.groupSelections(selections) : null;;
        if (this.firstSelectionKey === 'single' && this.limitDisplayBasedOnColumns !== undefined) {
            return this.limitDisplayBasedOnColumns;
        }
        return newAllSelectionsProps !== null ? Object.values(newAllSelectionsProps).reduce((biggest: number, items:SelectionModel[]) => biggest < items.length ? items.length : biggest, 0) : -1;

    }

    @computed public get newAllSelectionsProps(): Array<Array<SelectionModel>> | null {
        const selections = this.selectionsByPropsNew(this.models, this.selectionsProps);
        return selections !== undefined ? this.groupSelections(selections) : null;
    }

    @computed public get selectionSize(): number | null {
        const selectionByProps = this.selectionsByPropsNew(this.models, this.selectionsProps);
        const selectionSize = selectionByProps !== undefined ? selectionByProps.length : null;
        return selectionSize;
    }

}



