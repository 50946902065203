import { Session } from 'src_common/sdk/session';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { GameModalState } from 'src/domains/casino/state/AppCasino/game-modal/GameModal.state';
import { openapiCmsCustomerGetFavouriteGamesRequest } from 'src/domains/casino/api/generated/cms/openapi_cms_customer_get_favourite_games';
import { FavouriteGamesResponseType, RecentGamesResponseType } from './typesExt';
import { openapiCmsCustomerGetRecentGamesRequest } from 'src/domains/casino/api/generated/cms/openapi_cms_customer_get_recent_games';
import { RedirectState } from 'src/domains/casino/state/AppCasino/Redirect.state';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';

export class CasinoContextModelExt {
    private readonly favouriteGamesInner: Resource<FavouriteGamesResponseType | null>;
    private readonly favouriteGamesInnerEmpty: Resource<FavouriteGamesResponseType | null>;

    private readonly userRecentGamesResourceInner: Resource<RecentGamesResponseType | null>;
    private readonly userRecentGamesResourceInnerEmpty: Resource<RecentGamesResponseType | null>;

    public constructor(
        public readonly session: Session,
        public readonly redirectState: RedirectState,
        public readonly config: ConfigState,
        public readonly gameModalState: () => GameModalState
    ) {
        this.favouriteGamesInner = new Resource(async (): Promise<FavouriteGamesResponseType | null> => {
            return this.session.call(openapiCmsCustomerGetFavouriteGamesRequest, {});
        });
        this.favouriteGamesInnerEmpty = new Resource(async (): Promise<FavouriteGamesResponseType | null> => null);

        this.userRecentGamesResourceInner = new Resource(async (): Promise<RecentGamesResponseType | null> => {
            return this.session.call(openapiCmsCustomerGetRecentGamesRequest, {});
        });

        this.userRecentGamesResourceInnerEmpty = new Resource(
            async (): Promise<RecentGamesResponseType | null> => null
        );
    }

    /**
     * @deprecated - please use redirectState.goToLoginForm
     */
    public goToLoginForm = (): boolean => {
        return this.redirectState.goToLoginForm();
    };

    public get favouriteGames(): Resource<FavouriteGamesResponseType | null> {
        if (this.redirectState.getIsUserAutorized()) {
            return this.favouriteGamesInner;
        }

        return this.favouriteGamesInnerEmpty;
    }

    public get userRecentGamesResource(): Resource<RecentGamesResponseType | null> {
        if (this.redirectState.getIsUserAutorized()) {
            return this.userRecentGamesResourceInner;
        }

        return this.userRecentGamesResourceInnerEmpty;
    }
}
