import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';

export const sortByNumberField = (a: number, b: number): number => {
    if (b > a) {
        return 1;
    }
    if (b < a) {
        return -1;
    }
    return 0;
};

export const sortReverseByNumberField = (a: number, b: number): number => {
    return a - b;
};

const parseNumber = (value: string | undefined | null): number | null => {
    if (value === null || value === undefined) {
        return null;
    }
    const valueNumber = parseInt(value, 10);
    if (isNaN(valueNumber)) {
        return null;
    }
    return valueNumber;
};

export const price = (x: SelectionModel): number => {
    const shouldGroupByUnnamedFavourite: boolean = x.templateId === 'unnamed-favourite' && x.templateMarketId === 'race-winner';
    //TODO to remove while new trading will be realised
    if (x.resultType === 'void') {
        return 100000;
    }

    if (x.templateId === 'NG') {
        return 99999;
    }

    if (shouldGroupByUnnamedFavourite) {
        return 99999;
    }

    return x.price?.d ?? 99998;
};

const NoPriceMattersCriteriaIO = t.union([
    t.literal('by-active-display'),
    t.literal('by-price'),
    t.literal('reverse-by-price')
]);

export const isNoPriceMattersCriteria = createGuard(NoPriceMattersCriteriaIO);

function processSortedSelection(criteria: CriteriaType, selection: SelectionModel, accumulator: SelectionModel[], noPrice: SelectionModel[], unnamed: SelectionModel[], voided: SelectionModel[]): void {
    const shouldGroupByNoPrice: boolean = selection.price === undefined && isNoPriceMattersCriteria(criteria);
    const shouldGroupByUnnamedFavourite: boolean = selection.templateId === 'unnamed-favourite' && selection.templateMarketId === 'race-winner';

    if (selection.resultType === 'void') {
        voided.push(selection);
    } else if (shouldGroupByUnnamedFavourite) {
        unnamed.push(selection);
    } else if (shouldGroupByNoPrice) {
        noPrice.push(selection);
    } else {
        accumulator.push(selection);
    }
}

function processSortedSelections(sorted: SelectionModel[], criteria: CriteriaType): SelectionModel[] {
    const activeSelections: SelectionModel[] = [];
    const noPriceSelections: SelectionModel[] = [];
    const unnamedSelections: SelectionModel[] = [];
    const voidSelections: SelectionModel[] = [];

    const disabledSelections: SelectionModel[] = [];
    const disabledNoPriceSelections: SelectionModel[] = [];
    const disabledUnnamedSelections: SelectionModel[] = [];
    const disabledVoidSelections: SelectionModel[] = [];

    for (const selection of sorted) {
        if (!selection.active && !selection.display && criteria !== '-') {
            processSortedSelection(criteria, selection, disabledSelections, disabledNoPriceSelections, disabledUnnamedSelections, disabledVoidSelections);
        } else {
            processSortedSelection(criteria, selection, activeSelections, noPriceSelections, unnamedSelections, voidSelections);
        }
    }

    const activeAndDisplaySelections = [
        ...activeSelections,
        ...noPriceSelections,
        ...unnamedSelections,
        ...voidSelections,
    ];

    const notActiveAndNotDisplay = [
        ...disabledSelections,
        ...disabledNoPriceSelections,
        ...disabledUnnamedSelections,
        ...disabledVoidSelections,
    ];

    return [
        ...activeAndDisplaySelections,
        ...notActiveAndNotDisplay
    ];
}

const CriteriaIO = t.union([
    t.literal('-'),
    t.literal('by-creation'),
    t.literal('by-active-display'),
    t.literal('by-price'),
    t.literal('reverse-by-price'),
    t.literal('by-display-order'),
    t.literal('by-selection-name'),
    t.literal('reverse-by-selection-name'),
    t.literal('by-tag'),
    t.literal('by-place'),
    t.literal('goalscorer'),
]);

export type CriteriaType = t.TypeOf<typeof CriteriaIO>;

export const isCriteria = createGuard(CriteriaIO);

export function sortSelectionsByCriteria(selections: SelectionModel[], criteria: string | undefined): Array<SelectionModel> {
    if (criteria === undefined) {
        return selections;
    }

    if (!(isCriteria(criteria))) {
        console.warn(`sortSelectionsByCriteria - unknown criteria ${criteria}`);
        return selections;
    }
    const SELECTIONS_SORTING = {
        '-': function (prev: SelectionModel, next: SelectionModel): number {
            const orderPrev = prev.displayOrder ?? 0;
            const orderNext = next.displayOrder ?? 0;
            const placePrev = parseNumber(prev.metaDataNumber) ?? 0;
            const placeNext = parseNumber(next.metaDataNumber) ?? 0;

            return (orderPrev * orderNext > 0) ? //all greater than 0
                SELECTIONS_SORTING['by-display-order'](prev, next) :
                (placePrev * placeNext > 0) ? SELECTIONS_SORTING['by-place'](prev, next) :
                    SELECTIONS_SORTING['by-creation'](prev, next);
        },
        'by-active-display': function (prev: SelectionModel, next: SelectionModel): number {
            return SELECTIONS_SORTING['by-selection-name'](prev, next);
        },
        'by-price': function (prev: SelectionModel, next: SelectionModel): number {
            const pricePrev = price(prev);
            const priceNext = price(next);

            return pricePrev === priceNext ?
                SELECTIONS_SORTING['by-selection-name'](prev, next) :
                (pricePrev < priceNext ? -1 : 1);
        },
        'reverse-by-price': function (prev: SelectionModel, next: SelectionModel): number {
            const pricePrev = price(prev);
            const priceNext = price(next);

            return pricePrev === priceNext ?
                SELECTIONS_SORTING['by-selection-name'](prev, next) :
                (pricePrev < priceNext ? 1 : -1);
        },
        'by-display-order': function (prev: SelectionModel, next: SelectionModel): number {
            return (prev.displayOrder ?? 0) - (next.displayOrder ?? 0);
        },
        'by-selection-name': function (prev: SelectionModel, next: SelectionModel): number {
            return prev.name.trim() > next.name.trim() ? 1 : -1;
        },
        'reverse-by-selection-name': function (prev: SelectionModel, next: SelectionModel): number {
            return prev.name.trim() > next.name.trim() ? -1 : 1;
        },
        'by-tag': (prev: SelectionModel, next: SelectionModel): number => {
            const tagNamePrev = prev.identifier;
            const tagNameNext = next.identifier;

            // Undefined tags or tags without a correct identifier should be placed at the bottom
            // and sorted by the selection name
            const selectionIdentifierPrev = tagNamePrev === undefined || tagNamePrev === '-' ? 99997 : tagNamePrev;
            const selectionIdentifierNext = tagNameNext === undefined || tagNameNext === '-' ? 99997 : tagNameNext;

            return selectionIdentifierPrev === selectionIdentifierNext ?
                SELECTIONS_SORTING['by-selection-name'](prev, next) :
                selectionIdentifierPrev > selectionIdentifierNext ? 1 : -1;
        },
        'by-creation': (prev: SelectionModel, next: SelectionModel): number => {
            return prev.id - next.id;
        },
        'goalscorer': (): number => {
            return 1;
        },
        'by-place': (prev: SelectionModel, next: SelectionModel): number => {
            const prevPlaceNumber = parseNumber(prev.metaDataNumber) ?? 0;
            const nextPlaceNumberNil = parseNumber(next.metaDataNumber) ?? 0;

            return prevPlaceNumber - nextPlaceNumberNil;
        }
    };

    const sorted = selections.sort(SELECTIONS_SORTING[criteria]);

    return processSortedSelections(sorted, criteria);
}
