import * as t from 'io-ts';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { LiveNotificationLocalStorageObjectType } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

const decodeIO = t.array(t.interface({
    id: t.string,
    timesVisited: t.number,
}));

const decode = buildValidator('localStorageLiveNotifications.decodeIO', decodeIO, true);

export const localStorageLiveNotifications = (withAutorun: boolean, key: string): LocalStorageItemState<Array<LiveNotificationLocalStorageObjectType>> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: []
    });
};
