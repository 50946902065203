import { z } from 'zod';

export type TransactionCallbackRealexInput = z.infer<typeof InputSchema>;

export const InputSchema = z.object({
    transactionId: z.number(),
});

const Statuses = z.union([z.literal('failed'), z.literal('rejected'), z.literal('paid'), z.string()]);

export const SuccessResponseSchema = z.object({
    status: Statuses,
    amount: z.number(),
    currency: z.string(),
    orderId: z.string(),
    createdAt: z.string(),
    authCode: z.string(),
    paymentMethod: z.string(),
});

type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    error: z.object({
        code: z.string().optional().nullable(),
        message: z.string().optional().nullable(),
    }),
});

export const TrpcErrorResponseSchema = z.object({
    code: z.string().optional().nullable(),
    message: z.string().optional().nullable(),
});

type TErrorResponse = z.infer<typeof TrpcErrorResponseSchema>;

export type TResponseType =
    | { responseStatus: 'success'; response: TSuccessResponse }
    | { responseStatus: 'error'; data: TErrorResponse };
