import { countries } from 'src/domains/layouts/config/countries';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { DateInputState } from 'src/domains/players/webview/components/form/DateInput';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { observable, action, makeObservable } from 'mobx';
import { PhoneNumberState } from 'src/domains/players/webview/components/SignUp/signupParts/phoneNumber/PhoneNumber.state';
import { SelectState } from 'src/domains/players/webview/components/form/select/Select.state';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { SendCpfResponse } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupWelcome/SignupWelcome.state';
import { DateTime } from 'src_common/utils/time/time';
import { OffersContactPreferencesState, PromoCodesState, toLowerCase } from 'src/domains/players/webview/components/SignUp';
import { EmailAndPasswordState } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/createAccount/EmailAndPassword.state';
import { createAccountPrimary } from 'src_server/trpc/types';
import { convertArrayOfStringForSelectComponent } from 'src/domains/players/webview/components/form/select/Select';
import { TermsAndConditionsCheckboxState } from 'src/domains/players/webview/components/SignUp/signupParts/termsAndConditions/TermsAndConditionsCheckbox.state';

export interface YourDetailsFormType {
    firstName: string;
    lastName: string;
    currency: string;
    dateOfBirth: DateTime;
    prefix: string;
    phoneNumber: string;
    promoCode?: string;
    affiliateId?: string;
    contactPreferences: createAccountPrimary.CreateAccountInput['contactPreferences'];
}
export class YourDetailsState {
    @observable public isShownFullAddressForm: boolean = false;
    @observable public loadingAddresses: boolean = false;
    @observable public loadingCreateAccountReq: boolean = false;
    @observable public wasTouched: boolean | null = null;
    @observable public validateOnSubmit: boolean = false;
    @observable public isPromoCode = false;

    public emailAndPasswordState: EmailAndPasswordState;
    public termsAndConditionsCheckboxState: TermsAndConditionsCheckboxState;

    public readonly cpfNumberState: FormInputState<string,string>;
    public readonly firstNameState: FormInputState<string,string>;
    public readonly lastNameState: FormInputState<string,string>;
    public readonly offersSettingsState: OffersContactPreferencesState;
    public readonly currency: SelectState;
    public readonly dateOfBirth: DateInputState;
    public readonly phoneNumber: PhoneNumberState;
    public promoCodesState: PromoCodesState;
    public readonly affiliateIdModel: FormInputState<string,string | undefined>;
    public readonly promoCodeTextState: FormInputState<string,string>;


    public readonly yourDetailsFormModel: FormModel<YourDetailsFormType>;

    public constructor(
        public readonly customKeyboard: CustomKeyboardState,
        public readonly language: LanguagesState,
        private readonly config: ConfigComponents,
        private readonly cpfResponseFormModel: () => FormModel<SendCpfResponse> | undefined,
    ) {
        makeObservable(this);
        this.emailAndPasswordState = new EmailAndPasswordState(language);

        this.cpfNumberState = FormInputState.new('').map(validateRequire);
        this.firstNameState = FormInputState.new('').map(validateRequire);
        this.lastNameState = FormInputState.new('').map(validateRequire);
        this.offersSettingsState = new OffersContactPreferencesState(config.config.contactPreferences);

        this.promoCodesState = new PromoCodesState();
        this.termsAndConditionsCheckboxState = new TermsAndConditionsCheckboxState(this.config.config);

        this.phoneNumber = new PhoneNumberState(this.language, this.config.config.prefixAndPhoneNumberDefault);

        const defaultCurrency =this.config.config.currencyDefaultSelectedInSignup ? convertArrayOfStringForSelectComponent(this.config.config.currencySignUpList)[0]: undefined;
        this.currency = new SelectState(defaultCurrency, true);

        this.dateOfBirth = new DateInputState(this.customKeyboard);

        this.promoCodeTextState = FormInputState.new('').map(toLowerCase).map((value: string): Result<string> => {
            if (this.promoCodesState.promoCodes.type === 'ready') {
                const isPromoCodeValid = this.promoCodesState.checkIfCodeExistsAndIsValid(value);

                if (isPromoCodeValid === false) {
                    const message = this.language.getTranslation('sign-up.create-account.welcome.promo-code.error', 'This promo code is not valid');
                    return Result.createError(message);
                }

            }

            return Result.createOk(value);
        });

        this.affiliateIdModel = this.promoCodeTextState
            .map((): Result<string | undefined> => {
                if (this.promoCodesState.promoCodes.type === 'ready') {
                    const isAffiliateIdValid = this.promoCodesState.onIfAffiliateIdExists(this.promoCodeTextState.value);

                    return Result.createOk(isAffiliateIdValid);
                }
                return Result.createOk(undefined);
            });

        this.yourDetailsFormModel = FormModel.group({
            firstName: this.firstNameState,
            lastName: this.lastNameState,
            dateOfBirth: this.dateOfBirth.dateModel,
            currency: this.currency.inputState,
            phoneNumber: this.phoneNumber.phoneNumber,
            prefix: this.phoneNumber.prefix.inputState,
            promoCode: this.promoCodeTextState,
            affiliateId: this.affiliateIdModel,
            contactPreferences: this.offersSettingsState.formatFormModel
        });
    };

    @action public setPromoCode = (): void => {
        this.isPromoCode = !this.isPromoCode;
    };


    public formattedCountries = (): Array<Record<string, string>> => {
        const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));
        const sortedCountriesFormatted = sortedCountries.map(country => ({ [country.id]: country.name }));
        return sortedCountriesFormatted;
    };

    @action public handleWasTouched = (): void => {
        this.wasTouched = true;
    };

    @action public setCpfResponse = (): void => {
        const cpfResponseFormModel = this.cpfResponseFormModel();
        if (cpfResponseFormModel?.result.value.type === 'ok') {
            this.cpfNumberState.setValue(cpfResponseFormModel.result.value.data.cpf);
            this.firstNameState.setValue(cpfResponseFormModel.result.value.data.firstName);
            this.lastNameState.setValue(cpfResponseFormModel.result.value.data.surname);
            this.dateOfBirth.setInitialValues(cpfResponseFormModel.result.value.data.birthDate);
        }
    };
}
