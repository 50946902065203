interface PopupBaseProperties {
    // defines z-index for overlay
    overlayLevel: number;
    // defines if overlay should be visible
    overlayVisible: boolean;
    // defines if right panel should be attached to right edge while opening
    rightPanelStickyToRight: boolean;
}

export class MarketingNotificationsPopup implements PopupBaseProperties {
    protected nominal: null = null;
    public readonly type: 'marketingNotificationsPopup' = 'marketingNotificationsPopup' as const;
    public readonly overlayLevel: number = 0;
    public readonly overlayVisible: boolean = true;
    public readonly rightPanelStickyToRight: boolean = false;
}

export class GameModalPopup implements PopupBaseProperties {
    protected nominal: null = null;
    public readonly type: 'gameModal' = 'gameModal' as const;
    public readonly overlayVisible: boolean = true;
    public readonly rightPanelStickyToRight: boolean = true;

    public constructor(
        private readonly getIsDepositOpened: () => boolean,
        private readonly isMini: boolean,
    ) { }

    public get overlayLevel(): number {
        if (this.isMini) {
            if (this.getIsDepositOpened()) {
                return -1;
            }
            return 11;
        }
        return this.getIsDepositOpened() ? -1 : 12;
    }
}

export class MultipleSlotsPopup implements PopupBaseProperties {
    protected nominal: null = null;
    public readonly type: 'multipleSlotsPopup' = 'multipleSlotsPopup' as const;
    public readonly overlayLevel: number = 11;
    public readonly overlayVisible: boolean = true;
    public readonly rightPanelStickyToRight: boolean = false;
}
