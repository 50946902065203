import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { observer } from 'src/utils/mobx-react';
import { ScrollBarThumbWrapper, ScrollBarTrackWrapper } from './CustomScrollbar.style';

interface PropsTypes {
    className?: string,
    children?: React.ReactNode
}

export const CustomScrollbar = observer('AccountPanel',(props: PropsTypes) => {
    return (
        <Scrollbars
            key='custom-scrollbar'
            autoHide
            hideTracksWhenNotNeeded
            className={props.className}
            //eslint-disable-next-line
            renderTrackHorizontal={(props: PropsTypes) => <ScrollBarTrackWrapper position='horizontal' {...props} />}
            //eslint-disable-next-line
            renderTrackVertical={(props: PropsTypes) => <ScrollBarTrackWrapper position='vertical' {...props} />}
            //eslint-disable-next-line
            renderThumbHorizontal={(props: PropsTypes) => <ScrollBarThumbWrapper {...props} />}
            //eslint-disable-next-line
            renderThumbVertical={(props: PropsTypes) => <ScrollBarThumbWrapper {...props} />}
            //eslint-disable-next-line
            renderView={(props: PropsTypes) => <div {...props} className='scrollbar__view'/>}>
            { props.children }
        </Scrollbars>
    );
});
