import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { MarketingNotificationsState } from 'src/domains/players/shared/Types';
import {
    BulletsNav,
    ButtonWrapper,
    Description,
    Header,
    Label,
    PopupContent,
    QuestionLabel,
    QuestionsSelect,
    SkipButton,
} from './MarketingNotificationsPopupView.style';
import { useAppStateContext } from 'src/appState/AppState';
import { AppPlayersState } from 'src/domains/players/state/AppPlayers.state';
import { QuestionModel } from 'src/domains/players/state/MarketingQuestionsState';
import { MarketingQuestionsPopupWrapper } from 'src/domains/layouts/webview/components/popups/PopupContainer.style';

interface MarketingQuestionSelectPropsType {
    question: QuestionModel;
}

const MarketingQuestionSelect = observer('MarketingQuestionSelect', (props: MarketingQuestionSelectPropsType) => {
    const { question } = props;

    return (
        <>
            <QuestionLabel>{question.name ?? ''}</QuestionLabel>
            <QuestionsSelect
                name='question-options'
                placeholder={<I18n langKey='marketing-questions-popup.select.label' defaultText='Please select' />}
                hasEmptyOption={true}
                options={question.selectOptions}
                showIndicator={!question.hasAnswer}
                disabled={question.hasAnswer}
                state={question.selectState}
            />
        </>
    );
});

interface MarketingQuestionFormPropsType {
    appPlayerState: AppPlayersState;
    marketingNotificationsState: MarketingNotificationsState;
    removePopupAndDismissQuestions: () => void;
}

const MarketingQuestionForm = observer('MarketingQuestionForm', (props: MarketingQuestionFormPropsType) => {
    const { marketingNotificationsState, removePopupAndDismissQuestions } = props;

    const currentQuestion = marketingNotificationsState.currentQuestion;

    if (currentQuestion === undefined) {
        return null;
    }

    const onClick = (): void => {
        const saveExecuted = marketingNotificationsState.handleClick();

        if (saveExecuted) {
            removePopupAndDismissQuestions();
        }
    };

    return (
        <>
            <MarketingQuestionSelect question={currentQuestion} key={currentQuestion.id} />
            <ButtonWrapper type='button' size='large' onClick={onClick}>
                <Label>
                    {marketingNotificationsState.isLastQuestion() ? (
                        <I18n langKey='marketing-questions-popup.button.save' defaultText='Save' />
                    ) : (
                        <I18n langKey='marketing-questions-popup.button.next' defaultText='Next' />
                    )}
                </Label>
            </ButtonWrapper>
        </>
    );
});

export const MarketingNotificationsPopupView = observer('MarketingNotificationsPopupView', (): JSX.Element | null => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { popupState } = appLayoutsState;

    const { availableQuestions, setCurrentQuestionId } = appPlayersState.marketingNotificationsState;
    const currentQuestionId = appPlayersState.marketingNotificationsState.currentQuestionId;

    const removePopupAndDismissQuestions = (): void => {
        popupState.hide();
        appPlayersState.marketingNotificationsState.dismissAllQuestions();
    };

    if (availableQuestions.length === 0) {
        return null;
    }

    const isOnlyOneQuestionAvailable = availableQuestions.length === 1;

    if (currentQuestionId === null) {
        return null;
    }
    return (
        <MarketingQuestionsPopupWrapper>
            <SkipButton onClick={removePopupAndDismissQuestions}>
                <I18n langKey='marketing-questions-popup.skip' defaultText='Skip' />
            </SkipButton>
            <PopupContent>
                <Header>
                    <I18n langKey='marketing-questions-popup.header' defaultText='Get personalised offers' />
                </Header>
                <Description>
                    {isOnlyOneQuestionAvailable ? (
                        <I18n
                            langKey='marketing-questions-popup.description.single'
                            defaultText='We have some offers for you based on your preferences. You can answer this in your settings at any time.'
                        />
                    ) : (
                        <I18n
                            langKey='marketing-questions-popup.description.multiple'
                            defaultText='We have some offers for you based on your preferences, answer a couple of quick questions to get them. You can answer these in your settings at any time.'
                        />
                    )}
                </Description>
                <MarketingQuestionForm
                    appPlayerState={appPlayersState}
                    marketingNotificationsState={appPlayersState.marketingNotificationsState}
                    removePopupAndDismissQuestions={removePopupAndDismissQuestions}
                />
                <BulletsNav
                    bulletsId={availableQuestions.map(question => question.id)}
                    currentQuestionId={currentQuestionId}
                    bulletsPosition='center'
                    setCurrentId={setCurrentQuestionId}
                />
            </PopupContent>
        </MarketingQuestionsPopupWrapper>
    );
});
