import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const TermsConditionsWrapper = withConfig(theme => styled('aside', { label: 'TermsConditionsWrapper' })`
    background-color: ${theme.star.promoSidebarSection.bgColor};
    display: flex;
    flex-flow: column nowrap;
    max-width: 414px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`);

export const Header = withConfig(theme => styled('div', { label: 'Header' })`
    min-height: 48px;
    color: ${theme.star.promoSidebarSection.txtColor};
    background-color: ${theme.star.promoSidebarSection.bgColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    text-transform: none;
`);

export const TermsContent = withConfig(theme => styled('div', { label: 'TermsContent' })`
    color: ${theme.star.promoSidebarSection.txtColor};
    padding: 16px;
`);
