import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })<{isSearchBarDesktopOpened?: boolean}>`
    height: auto;
`;

interface SearchIconPropsType {
    className?: string;
    onClick?: () => void;
    isSearchBarDesktopOpened?: boolean;
}

export const SearchIcon = observer('SearchIcon', ({ className, onClick, isSearchBarDesktopOpened }: SearchIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
            isSearchBarDesktopOpened={isSearchBarDesktopOpened}
        >
            <path
                clipRule='evenodd'
                d='M17.07 14.406c.86-1.352 1.364-2.956 1.364-4.68C18.434 4.907 14.532 1 9.72 1 4.902 1 1 4.907 1 9.726c0 4.818 3.902 8.725 8.714 8.725 1.747 0 3.374-.516 4.738-1.398l.395-.275L21.07 23 23 21.035l-6.216-6.222.286-.407zM14.601 4.85c1.3 1.3 2.017 3.03 2.017 4.87 0 1.839-.716 3.57-2.017 4.87-1.3 1.3-3.03 2.016-4.87 2.016-1.839 0-3.569-.716-4.87-2.016-1.3-1.3-2.016-3.031-2.016-4.87 0-1.84.716-3.57 2.016-4.87 1.301-1.3 3.031-2.017 4.87-2.017 1.84 0 3.57.716 4.87 2.017z'
                fill='fill'
                fillRule='evenodd'
            />
        </SvgElement>
    );
});


