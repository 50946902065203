import { parseNumberAmount, trimZerosFromRight } from './amountUtils';


export const convertNumberToString = (base: number, value: number): string => {
    if (Math.floor(value) !== value) {
        throw Error(`amountConvertNumberToString: integer was expected value=${value}`);
    }

    const isNegative = Math.sign(value) === -1;

    const convertedPositiveValue = Math.abs(value);

    const basePow = Math.pow(10, base);
    const smallUnits = convertedPositiveValue % basePow;
    const units = Math.floor(convertedPositiveValue / basePow);

    const sign = `${isNegative ? '-' : ''}`;

    if (smallUnits === 0) {
        return `${sign}${units}`;
    }
    
    return trimZerosFromRight(`${sign}${units}.${smallUnits.toString().padStart(base, '0')}`);
};


export const convertStringToNumber = (base: number, value: string): number => {

    const chunks = value.split('.');

    if (chunks.length > 2) {
        throw Error(`too many dots in amount: ${value}`);
    }

    const [units, smallUnits] = chunks;

    if (units === undefined) {
        throw Error('incorrect amount format');
    }

    const basePow = Math.pow(10, base);

    if (smallUnits === undefined) {
        return basePow * parseNumberAmount(units);
    }
    
    const smallUnitsNumber = parseNumberAmount(smallUnits.padEnd(base, '0'));

    if (smallUnitsNumber >= basePow) {
        throw Error(`the number of small units is too high: ${value}`);
    }

    const isNegative = units.includes('-');

    const parsedUnits = parseNumberAmount(units);

    const result = basePow * Math.abs(parsedUnits) + smallUnitsNumber;

    return isNegative ? -result : result;
};
