import React from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import {
    YouDecideItem,
    YouDecideItemSelection,
    YouDecideItemMarket,
    DeleteItemIconTrashWrapper,
} from './YouDecide.style';
import { observer } from 'src/utils/mobx-react';
import { RabItemState } from 'src/domains/sportsbook/betting/state/rabState/RabItemState';

export interface ItemType {
    market: string;
    selection: string;
}

interface RabItemPropsType {
    conflicting: boolean;
    item: ItemType;
    rabBasket: RabItemState | null;
}

export const RabItem = observer('RabItem', (propsIn: RabItemPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { item, conflicting, rabBasket } = props;

    const removeSelection = (): void => {
        if (rabBasket !== null) {
            rabBasket.removeSelection(item.market, item.selection);
        }
    };

    return (
        <YouDecideItem className='x-YouDecideItem' key={item.selection} isConflicting={conflicting} data-test='you-decide-item'>
            <div>
                <YouDecideItemSelection className='x-YouDecideItemSelection' data-test='selection-name'>{item.selection}</YouDecideItemSelection>
                <YouDecideItemMarket data-test='market-name'>{item.market}</YouDecideItemMarket>
            </div>

            <DeleteItemIconTrashWrapper onClick={removeSelection} dataTest='remove-item' />
        </YouDecideItem>
    );
});
