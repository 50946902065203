import React from 'react';

import { EventMarkets } from 'src/domains/sportsbook/webview/components/eventMarkets/EventMarkets';
import { observer } from 'src/utils/mobx-react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsTypes {
    eventId: EventId;
    filterMarketsByPopularTag: boolean;
}

function popularMarketsFilter(market:MarketModel): boolean {
    return market.websiteNotPopular;
}

function renderContent(
    markets:Array<MarketModel>,
    actualEvent: EventModel,
): React.ReactNode {
    if (markets.length === 0) {
        return null;
    }

    const eventMarkets = actualEvent.eventMarkets ?? [];

    return (
        <EventMarkets
            markets={eventMarkets}
            eventId={actualEvent.id2}
        />
    );
}

export const EventSummary = observer('EventSummary', (props:PropsTypes) => {
    const { eventId, filterMarketsByPopularTag } = props;

    const actualEvent = eventId.getEventModel();

    if (actualEvent === null) {
        return null;
    }

    const markets = filterMarketsByPopularTag
        ? actualEvent.markets.filter(popularMarketsFilter)
        : actualEvent.allMarketsForEventPage;

    return (
        <div className='event-summary'>
            {renderContent(markets, actualEvent)}
        </div>
    );
});
