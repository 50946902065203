import React, { Suspense } from 'react';
import { observer } from 'src/utils/mobx-react';
import { ssrReactLazy } from 'src_common/utils/Idle';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

const ScoreboardImgArena = ssrReactLazy(
    async () =>
        (await import('src/domains/sportsbook/webview/components/scoreboards/imgArena/ScoreboardImgArena'))
            .ScoreboardImgArena
);

interface ImgArenaPropsType {
    sport: string;
    eventFeedGroupId: string | undefined | null;
    eventId: number;
}

export const ImgArena = observer(
    'ImgArena',
    ({ eventFeedGroupId, sport, eventId }: ImgArenaPropsType): JSX.Element | null => {
        const common = useCommon();
        const config = ConfigComponents.get(common).config;
        const hideScoreboard = config.scoreboard.imgArena === null;
        const feedGroupId = eventFeedGroupId ?? 'none';
        const feedGroupIdParse = parseInt(feedGroupId, 10);

        if (hideScoreboard === true || isNaN(feedGroupIdParse)) {
            return null;
        }

        if (sport === 'golf') {
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    <ScoreboardImgArena
                        feedGroupId={feedGroupId}
                        eventId={eventId}
                    />
                </Suspense>
            );
        }

        return null;
    }
);
