import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { KeyboardSingleButton, IconDeleteWrapper } from './CustomKeyboardNew.style';
import { KeyboardButtonType, charFromKeyboardButton } from './config';

interface PropsTypes {
    value: KeyboardButtonType;
    handleOnClick: (value: KeyboardButtonType) => void;
}

export const SingleKeyButton = observer('SingleKeyButton', (props: PropsTypes) => {
    const handleOnClick = (): void => {
        const { handleOnClick, value } = props;
        handleOnClick(value);
    };

    const renderValue = (value: KeyboardButtonType): string | JSX.Element => {
        if (value.type === 'delete') {
            return (
                // Temporary because of no design - no svg file
                <IconDeleteWrapper />
            );
        }
        return charFromKeyboardButton(value);
    };

    const { value } = props;

    return (
        <KeyboardSingleButton
            isSpecial={value.type === 'delete' || value.type === 'dot'}
            onClick={handleOnClick}
            data-test={`keyboard-button-${charFromKeyboardButton(value)}`}
        >
            {renderValue(value)}
        </KeyboardSingleButton>
    );
});
