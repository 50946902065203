import { EventsCollectionQueryType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { WebsocketId } from './id/WebsocketId';
import { ModelsEventStateNew } from './ModelsEventStateNew/ModelsEventStateNew';

const splitBy = (text: Array<string>, separator: string): Array<string> => {
    const result: Array<Array<string>> = [];

    for (const word of text) {
        result.push(word.split(separator));
    }

    const empty: Array<string> = [];
    return empty.concat(...result);
};

const splitByList = (searchText: string, split: Array<string>): Array<string> => {
    let result = [searchText];

    for (const splitItem of split) {
        result = splitBy(result, splitItem);
    }

    return result;
};

const findMax = (chunks: Array<string>): string => {
    let max = '';

    for (const chunk of chunks) {
        if (max.length < chunk.length) {
            max = chunk;
        }
    }

    return max;
};

export const splitToChunks = (searchText: string): [string, Array<string>] => {
    const chunks = splitByList(searchText, [' ', '/', '-', ':'])
        .map(item => item.trim().toLocaleLowerCase())
        .filter(item => item !== '')
    ;

    const first = findMax(chunks);

    if (first === undefined || first.length < 3) {
        return ['', []];
    }

    return [first, chunks];
};

export const search = (websicketId: WebsocketId, modelsEvent: ModelsEventStateNew, query: EventsCollectionQueryType): EventsCollectionQueryModel | null => {
    const q = query.q;
    
    if (q === undefined) {
        return modelsEvent.getEventQuery(query);
    }

    if (q.length < 2) {
        return EventsCollectionQueryModel.createEmpty(() => websicketId);
    }

    const [searchTextFirst, chunks] = splitToChunks(q.toLocaleLowerCase());

    const newQuery = {
        ...query,
        q: searchTextFirst,
    };

    const list = modelsEvent.getEventQuery(newQuery);
    if (list === null) {
        return null;
    }
    
    return list.filterBySportAndEvent((_sport: string, event): boolean => {
        for (const chunkItem of chunks) {
            if (event.name.toLocaleLowerCase().includes(chunkItem) === false) {
                return false;
            }
        }

        return true;
    });
};
