import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { RaceBoxSelection, ShowMoreArrowWrapper } from 'src/domains/sportsbook/shared/Components';
import {
    HeaderWithMovieWrapper,
    EventWrapper,
    EventEachWay,
    SpecialEventsHeaderMaxbetText,
    ShowMoreWrapper,
    EventName,
    EventInfo,
    ListWrapper,
    RaceBox,
} from './HomepageHeroWidget.style';
import emptyHrSilk_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import emptyHrSilk from 'src/domains/sportsbook/webview/components/racesSlider/assets/empty-hr-silk.svg';
import emptyGHSilk from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import { HeroWidgetModelType } from 'src/api/config/cms_new/hero_widget/get_hero_widget_active';
import { HeroWidgetItemState } from './HeroWidgetItem.state';
import { HeroWidgetBanner } from './HeroWidgetBanner';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsTypes {
    heroWidgetItem: HeroWidgetModelType;
    sport: 'horseracing' | 'greyhoundracing';
}

export const HeroWidgetRacing = observer(
    'HeroWidgetRacing',
    ({ heroWidgetItem, sport }: PropsTypes): JSX.Element | null => {
        const { appLayoutsState } = useAppStateContext();
        const { starRouter, configComponents, breakpointsState } = appLayoutsState;

        const [heroWidgetItemState] = useState(() => new HeroWidgetItemState(starRouter, heroWidgetItem));
        const { headerImages, promoImages, filteredAndSortDefaultSelections, marketModel, handleRedirectToRacePage } =
            heroWidgetItemState;
        const { is_active_promo, title, subtitle, id, tc_text, tc_title } = heroWidgetItem;

        const isDesktop = breakpointsState.desktop.isBiggerOrEq;

        const defaultHRSilk = configComponents.config.shouldDisplaySilkWithoutQuestionMark
            ? emptyHrSilk
            : emptyHrSilk_old;
        const defaultSilk = sport === 'greyhoundracing' ? emptyGHSilk : defaultHRSilk;

        if (marketModel !== null) {
            return (
                <React.Fragment key={marketModel.id}>
                    <>
                        <HeaderWithMovieWrapper banners={headerImages} data-test='widget-banner'>
                            <EventWrapper>
                                <EventName data-test='hero-widget-title'>{title}</EventName>
                                <EventInfo data-test='hero-widget-subtitle'>{subtitle}</EventInfo>
                                {isDesktop === false ? (
                                    <EventEachWay>
                                        {marketModel.eachWayOffered &&
                                        marketModel.eachWayTermsPlaces !== undefined &&
                                        marketModel.eachWayTermsReduction !== undefined
                                            ? `Each way terms: ${marketModel.eachWayTermsReduction} for ${marketModel.eachWayTermsPlaces} Places`
                                            : null}
                                    </EventEachWay>
                                ) : null}
                            </EventWrapper>
                            <SpecialEventsHeaderMaxbetText>
                                {isDesktop === true ? (
                                    <EventEachWay>
                                        {marketModel.eachWayOffered &&
                                        marketModel.eachWayTermsPlaces !== undefined &&
                                        marketModel.eachWayTermsReduction !== undefined
                                            ? `Each way terms: ${marketModel.eachWayTermsReduction} for ${marketModel.eachWayTermsPlaces} Places`
                                            : null}
                                    </EventEachWay>
                                ) : null}
                            </SpecialEventsHeaderMaxbetText>
                        </HeaderWithMovieWrapper>

                        {
                            <RaceBox>
                                <ListWrapper autoHeightItem>
                                    {filteredAndSortDefaultSelections.map((selection) => (
                                        <li key={selection.id}>
                                            <RaceBoxSelection
                                                extendSelection={false}
                                                moreInfo={true}
                                                defaultSilk={defaultSilk}
                                                selectionId={selection.id2}
                                                specialEvent={true}
                                                truncateRacerName={false}
                                            />
                                        </li>
                                    ))}
                                </ListWrapper>
                                <ShowMoreWrapper onClick={handleRedirectToRacePage} data-test='show-more'>
                                    <I18n langKey='events.racebox.view.label' defaultText='View racecard' />
                                    <ShowMoreArrowWrapper position='right' />
                                </ShowMoreWrapper>
                            </RaceBox>
                        }
                    </>
                    {is_active_promo ? (
                        <HeroWidgetBanner
                            withMargin={false}
                            banners={promoImages}
                            slug={`${id}-hero-promo`}
                            content={tc_text ?? ''}
                            title={tc_title ?? ''}
                        />
                    ) : null}
                </React.Fragment>
            );
        }

        return null;
    }
);
