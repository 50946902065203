import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ChangeEmailProcedureState } from './ChangeEmailProcedure.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { UpdateButton } from 'src/domains/players/webview/components/Account/personalDetailsTab/PersonalDetailsTab.styles';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const ChangeEmailProcedure = observer('ChangeEmailProcedure', () => {
    const { appLayoutsState, appPlayersState, trpcClient } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { usersState } = appPlayersState;
    const [state] = React.useState(() => new ChangeEmailProcedureState(usersState, trpcClient));

    return (
        <form>
            <Input
                name='email-old'
                label={
                    <I18n
                        langKey='account.email-procedure.old-email.label'
                        defaultText='E-mail address'
                    />
                }
                placeholder={languagesState.getTranslation(
                    'account.email-procedure.old-email.placeholder',
                    '*Old e-mail address'
                )}
                state={state.oldEmailState}
                colorTheme='light'
                dataTest='old-email-input'
                autocomplete='new-password'
            />
            <Input
                name='email-new'
                label={
                    <I18n
                        langKey='account.email-procedure.new-email.label'
                        defaultText='*New e-mail address'
                    />
                }
                placeholder={languagesState.getTranslation(
                    'account.email-procedure.new-email.placeholder',
                    '*New e-mail address'
                )}
                state={state.newEmailState}
                colorTheme='light'
                dataTest='new-email-input'
                autocomplete='new-password'
            />
            <Input
                name='email-confirm'
                label={
                    <I18n
                        langKey='account.email-procedure.confirm-email.label'
                        defaultText='*Confirm new e-mail address'
                    />
                }
                placeholder={languagesState.getTranslation(
                    'account.email-procedure.confirm-email.placeholder',
                    '*Confirm new e-mail address'
                )}
                state={state.confirmEmailState}
                colorTheme='light'
                dataTest='confirm-email-input'
                autocomplete='new-password'
            />

            {state.setNewEmailErrorMessage === null ? null : (
                <Messages
                    type='error'
                    marginBottom='8px'
                    message={state.setNewEmailErrorMessage}
                />
            )}

            {state.newEmailAlreadyExistsErrorMessage === null ? null : (
                <Messages
                    type='error'
                    marginBottom='8px'
                    message={state.newEmailAlreadyExistsErrorMessage}
                />
            )}

            {state.isShowSuccessMessage ? (
                <Messages
                    type='success'
                    marginTop='8px'
                    message={
                        <I18n
                            langKey='account.update-email.success-message'
                            defaultText='You have successfully changed your e-mail address.'
                        />
                    }
                />
            ) : null}

            <UpdateButton
                type='button'
                size='large'
                onClick={state.update}
                dataTest='update-email-button'
                disabled={state.isButtonDisabled}
            >
                <I18n
                    langKey='account.email-procedure.submit.label'
                    defaultText='Update Email'
                />
            </UpdateButton>
        </form>
    );
});
