import React from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import EMPTY_SILK from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import { PriceHistory } from 'src/domains/sportsbook/webview/components/priceHistory/PriceHistory';
import { Trap } from 'src/domains/sportsbook/webview/components/trap/Trap';
import { SelectionRunner, TrapListItem } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow.style';
import { useAppStateContext } from 'src/appState/AppState';
import { PriceHistoryCell, RunnerCell, RaceSummaryRow, SilkCell } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { SpInProgress, WinInProgress } from 'src/domains/sportsbook/webview/components/selectionRaceCard/SelectionRaceCard.style';
import { MatchBetSelection } from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummerRowMarkets.style';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    className?: string;
    tags?: React.ReactNode;
    runner?: React.ReactNode;
    sp?: React.ReactNode;
    winner?: React.ReactNode;
    selectionId: SelectionId;
    isUnnamedFavourite: boolean;
    nrPricesToShow: number | false;
    antePost: boolean;
    trapChallengeTag: string | undefined;
    isNonRunner?: boolean;
    isRaceInProgress?: boolean;
    isRAceFinished?: boolean;
    dataTest?: string;
}

export const RaceSummaryRowGreyhoundRacing = observer('RaceSummaryRowGreyhoundRacing', (propsIn: PropsType): JSX.Element => {
    const props = useAsObservableSource(propsIn);
    const { className, isUnnamedFavourite, tags, runner, sp, winner, selectionId, antePost, nrPricesToShow, trapChallengeTag, isNonRunner, isRaceInProgress, dataTest } = props;

    const { appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const { config } = configComponents;

    const selectionModel = selectionId.getModel();
    const matchBets = selectionModel?.templateMarketId === 'match-bets';
    const trapChallenge = trapChallengeTag === 'yes';

    if (isUnnamedFavourite) {
        return (
            <RaceSummaryRow className={className} isNonRunner={isNonRunner} data-test={dataTest}>
                <RunnerCell>{ runner }</RunnerCell>
                { isRaceInProgress === true ? <SpInProgress /> : sp }
                { tags }
            </RaceSummaryRow>
        );
    } else if (antePost) {
        return (
            <RaceSummaryRow className={className} isNonRunner={isNonRunner} tags={tags} isAlternativeTag={config.isAlternativeRacingBoost} data-test={dataTest}>
                <RunnerCell>{ runner }</RunnerCell>
                { isRaceInProgress === true ? <WinInProgress /> : winner }
                { tags }
            </RaceSummaryRow>
        );
    } else if (matchBets) {
        return (
            <MatchBetSelection className='x-MatchBetSelection'>
                <SilkCell>
                    <Trap
                        number={selectionModel.metaDataNumber}
                        placeholder={EMPTY_SILK}
                        selectionId={selectionId}
                    />
                </SilkCell>
                <SelectionRunner>{ runner }</SelectionRunner>
                { isRaceInProgress === true ? <WinInProgress /> : winner }
                { tags }
            </MatchBetSelection>
        );
    } else if (trapChallenge) {
        return (
            <TrapListItem className={className}>
                { isRaceInProgress === true ? <WinInProgress /> : winner }
            </TrapListItem>
        );
    } else {
        return (
            <RaceSummaryRow className={className} isNonRunner={isNonRunner} tags={tags} isAlternativeTag={config.isAlternativeRacingBoost} data-test={dataTest}>
                <SilkCell>
                    <Trap
                        number={selectionModel === null ? null : selectionModel.metaDataNumber}
                        placeholder={EMPTY_SILK}
                        selectionId={selectionId}
                    />
                </SilkCell>
                <RunnerCell>
                    { runner }
                </RunnerCell>

                <PriceHistoryCell className='race-summary__selection__price-history' isRaceInProgress={isRaceInProgress}>
                    <PriceHistory nrPricesToShow={nrPricesToShow} selectionId={selectionId} />
                </PriceHistoryCell>

                { isRaceInProgress === true ? <WinInProgress /> : winner }
                { isRaceInProgress === true ? <SpInProgress /> : sp }

                { tags }
            </RaceSummaryRow>
        );
    }
});
