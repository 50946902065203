import { action, computed, makeObservable, observable } from 'mobx';
import { CasinoGameModelExt } from './CasinoGameModelExt';
import Fuse from 'fuse.js';

export class CasinoSearchExt {
    @observable private searchInput: string = '';
    @observable private searchOpen: boolean = false;
    @observable private dropdownOpen: boolean = false;

    public constructor() {
        makeObservable(this);
    }

    public isActive(): boolean {
        return this.searchInput.length > 2;
    }

    public filter(list: Array<CasinoGameModelExt>): Array<CasinoGameModelExt> {
        const fuseOptions: Fuse.IFuseOptions<CasinoGameModelExt> = {
            findAllMatches: true,
            minMatchCharLength: 3,
            threshold: 0.5,
            ignoreLocation: true,
            includeScore: true,
            ignoreFieldNorm: true,
            keys: [
                {
                    name: 'name',
                    weight: 1
                },
                {
                    name: 'studioName',
                    weight: 0.8
                },
                {
                    name: 'provider',
                    weight: 0.8
                },
                {
                    name: 'id',
                    weight: 0.6
                },
            ]
        };

        const fuse = new Fuse(list, fuseOptions);
        const fuseResult = fuse.search(this.searchInput);
        const filteredList = fuseResult.map(result => result.item);

        return filteredList;
    }

    // For serarch input
    @action public setSearch = (value: string): void => {
        this.searchInput = value;
    };

    // For serarch input
    public get searchValue(): string {
        return this.searchInput;
    }

    @action public setIsSearchOpen = (isOpen: boolean): void => {
        this.searchOpen = isOpen;
    };

    @action public toggleIsSearchOpen = (): void => {
        this.searchOpen = !this.searchOpen;
    };

    @computed public get isSearchOpen(): boolean {
        return this.searchOpen;
    }

    @action public setIsDropdownOpen = (isOpen: boolean): void => {
        this.dropdownOpen = isOpen;
    };

    @computed public get isDropdownOpen(): boolean {
        return this.dropdownOpen;
    }
}
