import React, { useState } from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { MeetingsList } from 'src/domains/sportsbook/webview/components/meetingsList/MeetingsList';
import { FiltersState } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { getEventsList, SportMeetingsListState, SportType } from './SportMeetingsList.state';
import { MeetingsAzSubheader, SportMeetingsFiltersWrapper, SportMeetingsListWrapper } from 'src/domains/sportsbook/webview/components/containers/sportMeetingsList/SportMeetingsList.style';
import { MeetingsChevron } from 'src/domains/sportsbook/webview/components/meetingsAntePostAndSpecials/MeetingsGroup.style';
import { SportMeetingsListFilterStrategy } from './SportMeetingsListFilterStrategy';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { HorseRacingSelectedNew } from 'src/domains/sportsbook/webview/components/racing/NewRacingPage.state';

export interface SportMeetingsListPropsTypes {
    state: SportMeetingsListState;
    isBuilding: boolean;
    isLoading: boolean;
    selected: HorseRacingSelectedNew;
    sport: SportType;
    nameList?: string;
    onChoose?: (eventId: EventId) => void;
}

const SportMeetingsListByCountry = observer('SportMeetingsListByCountry', (propsIn: SportMeetingsListPropsTypes) => {
    const { appSportsBookState } = useAppStateContext();
    const props = useAsObservableSource(propsIn);
    const { state, sport, isBuilding, isLoading, selected, onChoose } = props;
    const eventsList = getEventsList(appSportsBookState, sport, isBuilding);

    if (eventsList === null) {
        return null;
    }

    return <>
        <>
            <MeetingsAzSubheader onClick={state.handleClickUkAndIreland}>
                <MeetingsChevron position={state.isOpenUkAndIreland ? 'down' : 'right'} />
                <I18n langKey='events.sport-meetings-list.country.uk-ireland' defaultText='UK And Ireland' />
            </MeetingsAzSubheader>
            <MeetingsList
                key='meetings-list'
                events={state.filterEventsComputedUkIreland}
                onChoose={onChoose}
                selected={selected}
                isBuilding={isBuilding}
                isLoading={isLoading}
                isOpen={state.isOpenUkAndIreland}
            />
        </>

        <>
            <MeetingsAzSubheader onClick={state.handleClickInternational}>
                <MeetingsChevron position={state.isOpenInternational ? 'down' : 'right'} />
                <I18n langKey='events.sport-meetings-list.country.international' defaultText='International' />
            </MeetingsAzSubheader>
            <MeetingsList
                key='meetings-list'
                events={state.filterEventsComputedInternational}
                onChoose={onChoose}
                selected={selected}
                isBuilding={isBuilding}
                isLoading={isLoading}
                isOpen={state.isOpenInternational}
            />
        </>
    </>;
});

const SportMeetingsListDefault = observer('SportMeetingsListDefault', (propsIn: SportMeetingsListPropsTypes) => {
    const { appSportsBookState } = useAppStateContext();
    const props = useAsObservableSource(propsIn);
    const { state, sport, isBuilding, isLoading, selected, onChoose } = props;
    const eventsList = getEventsList(appSportsBookState, sport, isBuilding);
    if (eventsList === null) {
        return null;
    }

    return (
        <MeetingsList
            key='meetings-list'
            events={state.filterEventsComputed}
            onChoose={onChoose}
            selected={selected}
            isLoading={isLoading}
            isBuilding={isBuilding}
        />
    );
});

export interface PropsTypes {
    sport: SportType;
    selected: HorseRacingSelectedNew;
    isBuilding: boolean;
    isLoading: boolean;
    shared: true;
    onChoose?: (eventId: EventId) => void;
}

export const SportMeetingsList = observer('SportMeetingsList', (propsIn: PropsTypes) => {
    const { appSportsBookState, config } = useAppStateContext();
    const props = useAsObservableSource(propsIn);
    const { sport, isBuilding, isLoading, selected, onChoose } = props;
    const { sportHorseracingMeetingsSplitByCountry, sportGreyhoundMeetingsSplitByCountry, filtersVersion } = config;

    const [state] = useState(() => new SportMeetingsListState(props, appSportsBookState));
    const [filtersState] = useState(() => new FiltersState(new SportMeetingsListFilterStrategy(state, appSportsBookState.language.getTranslation)));

    return (
        <SportMeetingsListWrapper>
            <SportMeetingsFiltersWrapper>
                <FiltersScrollableWrapper>
                    <Filters state={filtersState} skin={filtersVersion.football} />
                </FiltersScrollableWrapper>
            </SportMeetingsFiltersWrapper>
            {
                sport === 'horseracing' ? (
                    sportHorseracingMeetingsSplitByCountry ?
                        <SportMeetingsListByCountry
                            state={state}
                            isBuilding={isBuilding}
                            isLoading={isLoading}
                            selected={selected}
                            onChoose={onChoose}
                            sport={sport}
                        /> :
                        <SportMeetingsListDefault
                            state={state}
                            isBuilding={isBuilding}
                            isLoading={isLoading}
                            selected={selected}
                            onChoose={onChoose}
                            sport={sport}
                        />
                ) : sportGreyhoundMeetingsSplitByCountry ?
                    <SportMeetingsListByCountry
                        state={state}
                        isBuilding={isBuilding}
                        isLoading={isLoading}
                        selected={selected}
                        onChoose={onChoose}
                        sport={sport}
                    /> :
                    <SportMeetingsListDefault
                        state={state}
                        isBuilding={isBuilding}
                        isLoading={isLoading}
                        selected={selected}
                        onChoose={onChoose}
                        sport={sport}
                    />

            }
        </SportMeetingsListWrapper>
    );
});
