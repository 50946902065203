import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { PromoBannerAlternativeEvent, PromoBannerAlternativeEventWrapper } from './PromoBanner.style';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    eventId: EventId | null;
}
export const PromoBanner = observer('PromoBanner-Alternative-Event', (props: PropsType) => {
    const { appSportsBookState: { alternativeEventState } } = useAppStateContext();
    const { getAlternativeEventItemById, eventId } = alternativeEventState.get(props.eventId);

    if (eventId === null) {
        return null;
    }

    const eventItem = getAlternativeEventItemById(eventId);
    const { isActivePromo, showTermsAndConditions } = eventItem;

    if (isActivePromo === true) {
        return (
            <PromoBannerAlternativeEventWrapper>
                <PromoBannerAlternativeEvent
                    banners={eventItem.bgPromoImages}
                    onClick={showTermsAndConditions}
                />
            </PromoBannerAlternativeEventWrapper>
        );
    }

    return null;
});
