import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ResetPasswordForm } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordForm';
import { ResetPasswordRhsRouteType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsType {
    currentRoute: ResetPasswordRhsRouteType;
}

export const ResetPasswordProcedure = observer('ResetPasswordProcedure', (props: PropsType) => {
    const { currentRoute } = props;

    return <ResetPasswordForm currentRoute={currentRoute} />;
});
