import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface RewardsIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const RewardsIcon = observer(
    'RewardsIcon',
    ({ className, onClick }: RewardsIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                height='24'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
                fill='#020d16'
            >
                <path
                    d='M8.775 12.91h1.874L8.376 3.817h2.602l.78 5.455h1.818l.779-5.455h2.601l-2.272 9.091h1.874l2.445-9.779A.915.915 0 0 0 18.12 2H7.211a.912.912 0 0 0-.88 1.13l2.444 9.78Z'
                    fill='fill'
                    transform='matrix(1.022 0 0 1 -.939 0)'
                />
                <path
                    d='M12.667 19.762 16.376 22l-.982-4.221 3.275-2.838-4.317-.371-1.685-3.98-1.686 3.98-4.316.37 3.274 2.839L8.957 22l3.71-2.238Z'
                    fill='fill'
                    transform='matrix(1.022 0 0 1 -.939 0)'
                />
            </SvgElement>
        );
    }
);
