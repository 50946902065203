import React from 'react';
import { GameLoader, GamesWrapper } from 'src/domains/layouts/shared/loaders';
import { observer } from 'src/utils/mobx-react';

export const CasinoGamesLoader = observer('CasinoGamesLoader', () => {
    const games = [];

    for (let i = 0; i < 16; i++) {
        games.push(<GameLoader key={i} />);
    }

    return (
        <GamesWrapper>
            {games}
        </GamesWrapper>
    );
});
