import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { PopupOverlayDefault } from 'src/domains/players/webview/ui/Popup';
import { PofPopupButton, PofPopupContainer, PofPopupDescription, PofPopupEmailLink, PofPopupHeader, PofPopupSubHeader } from './PofPopups.style';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface LanguagePropsTypes {
    languagesState: LanguagesState;
    accountHelperMail: string;
}

const HelpInformation = observer('HelpInformation', ({ languagesState, accountHelperMail }: LanguagePropsTypes) => {
    return languagesState.translateTokens(
        languagesState.getTranslation(
            'pof.popup.help-inforamtion',
            'Please do not hesitate to contact the Compliance Department at [accountHelperMail] should you have any questions or concerns.'
        ),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'accountHelperMail') {
                return (
                    <PofPopupEmailLink href={`mailto:${accountHelperMail}`}>
                        {accountHelperMail}
                    </PofPopupEmailLink>
                );
            }
        }
    );
});

interface PofPopupPropsType {
    subHeaderText: React.ReactNode;
    descriptionText: React.ReactNode | null;
    onButtonClick: () => void;
}

export const PofPopup = observer('PofPopup', ({ subHeaderText, descriptionText, onButtonClick }:PofPopupPropsType): JSX.Element | null => {

    const {
        appLayoutsState: {
            languagesState,
            configComponents: { config: { accountHelperMail } }
        }
    } = useAppStateContext();

    return (
        <PopupOverlayDefault active={true}>
            <PofPopupContainer data-test='pof-popup'>
                <PofPopupHeader data-test='pof-popup-header'>
                    <I18n langKey='pof.popup.header' defaultText='Verification Required' />
                </PofPopupHeader>
                <PofPopupSubHeader data-test='pof-popup-subheader'>
                    {subHeaderText}
                </PofPopupSubHeader>
                {descriptionText === null ? null :(
                    <PofPopupDescription data-test='pof-popup-description'>
                        {descriptionText}
                    </PofPopupDescription>
                )}
                <PofPopupDescription data-test='pof-popup-info'>
                    <HelpInformation languagesState={languagesState} accountHelperMail={accountHelperMail}/>
                </PofPopupDescription>
                <PofPopupButton dataTest='pof-popup-submit' size='large' onClick={onButtonClick}>
                    <I18n langKey='pof.popup.button-text' defaultText='Ok thanks' />
                </PofPopupButton>
            </PofPopupContainer>
        </PopupOverlayDefault>
    );
});

