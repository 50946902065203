import { UsersState } from 'src/domains/players/state/UsersState';
import { action, computed, observable, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { StarLoginState } from 'src/domains/players/state/starLoginState/StarLoginState';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { FormModel } from 'src_common/common/mobx-utils/Form2/FormModel';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { TrpcClient } from 'src/appState/TrpcClient';

export class InputStateAndModel {
    public readonly id: string;
    public readonly state: FormInputState<string, string>;

    public constructor(id: string, state: FormInputState<string, string>) {
        this.id = id;
        this.state = state;
    }
}

export class VerifyWithSMSState {
    @observable public isEmailSent: boolean = false;
    @observable public isFormVisible: boolean = true;
    @observable public errorMessage: string | null = null;

    private readonly language: LanguagesState;
    private readonly router: StarRouter;
    private readonly logInState: StarLoginState;
    private readonly config: ConfigType;
    private readonly usersState: UsersState;

    private readonly inputsIds: string[];
    public readonly inputs: InputStateAndModel[];

    public formModel: FormModel<Record<string, string>>;

    public constructor(
        language: LanguagesState,
        inputsIds: string[],
        router: StarRouter,
        logInState: StarLoginState,
        config: ConfigType,
        usersState: UsersState,
        private readonly trpcClient: TrpcClient
    ) {
        makeObservable(this);
        this.language = language;
        this.inputsIds = inputsIds;
        this.inputs = [];
        this.router = router;
        this.logInState = logInState;
        this.config = config;
        this.usersState = usersState;

        this.inputsIds.forEach((id) => {
            const state = FormInputState.new('').map(validateRequire);
            const input = new InputStateAndModel(id, state);
            this.inputs.push(input);
        });

        const inputsFormModelMap = this.inputs.reduce<{ [key: string]: FormInputState<string, string> }>(
            (map, input) => {
                map[input.id] = input.state;
                return map;
            },
            {}
        );
        this.formModel = FormModel.group(inputsFormModelMap);
    }

    @action public setEmailSent = (value: boolean): void => {
        this.isEmailSent = value;
    };

    @action public setFormVisibility = (value: boolean): void => {
        this.isFormVisible = value;
    };

    @action public sendSMS = async (): Promise<void> => {
        const email = this.logInState.emailToVerifyAccount;
        if (email === null) {
            const errorMessage = getErrorByCode('ERROR_INVALID_EMAIL');
            this.setErrorMsg(errorMessage);
            throw new Error(errorMessage);
        }
        this.setEmailSent(true);
        await this.usersState.requestResetPassword({ requestBody: { email, sendViaSMS: true, verify: false } });
    };

    @computed public get isFormValid(): boolean {
        return this.formModel.result.value.type === 'ok';
    }

    @action public send = async (): Promise<void> => {
        const result = this.formModel.result;
        this.formModel.setAsVisited();
        if (result.value.type === 'ok') {
            const tokenValue = this.inputsIds
                .map((inputId) => (result.value.type === 'ok' ? result.value.data[inputId] : ''))
                .join('');
            const email = this.logInState.emailToVerifyAccount;

            try {
                if (email === null || tokenValue === '') {
                    throw new Error(
                        this.language.getTranslation(
                            'account.verify.internal-error',
                            'Something went wrong, please try to login again or contact us.'
                        )
                    );
                }

                const response = await this.trpcClient.client.accounts.accountRequestPasswordResetValidate.mutate({
                    username: email,
                    tokenValue: tokenValue,
                    receivedVia: 'sms',
                });

                switch (response.responseStatus) {
                    case 'success':
                        const { valid, attempts } = response.data.bodyJson;
                        if (!valid) {
                            if (attempts === 0) {
                                this.setFormVisibility(false);
                                throw new Error(
                                    this.language.getTranslation(
                                        'account.verify.attempts-limit-reached-error',
                                        'We have been unable to verify your account via SMS. Please get in touch with customer services or email {email}',
                                        { email: this.config.accountHelperMail }
                                    )
                                );
                            }
                            if (attempts > 0) {
                                throw new Error(
                                    this.language.getTranslation(
                                        'account.verify.incorrect-code-error',
                                        'Incorrect code - {attempts} attempts left',
                                        { attempts }
                                    )
                                );
                            }
                        }
                        break;
                    case 'error':
                        throw new Error(response.data.message ?? 'Unknown error');
                    default:
                        throw new Error(getErrorByCode('ERROR_UNKNOWN'));
                }

                this.router.redirectToResetPassword(tokenValue, email, null);
            } catch (error) {
                if (error instanceof Error) {
                    this.setErrorMsg(error.message);
                }
                this.resetInputs();
                throw error;
            }
        } else {
            console.log(result);
            this.setErrorMsg(result.errors()[0] ?? '');
        }
    };

    @action public setErrorMsg = (loginErrorMsg: string): void => {
        this.errorMessage = loginErrorMsg;
    };

    @action public resetInputs = (): void => {
        this.formModel.reset();
    };

    public handleClickSendSMS = async (event: React.SyntheticEvent | undefined): Promise<void> => {
        event?.preventDefault();
        await this.sendSMS();
    };
}
