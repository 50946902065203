import * as React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { StarRouter, ToType } from 'src/domains/layouts/state/router/StarRouter';
import { observer } from 'src/utils/mobx-react';
import { NewLinkWrapper } from './NewLink.style';
import { assertNever } from 'src_common/common/assertNever';

const getUrl = (starRouter: StarRouter, to: ToType | undefined): string => {
    if (to === undefined) {
        return '';
    }

    if (typeof to === 'string') {
        return to;
    }

    return starRouter.buildUrlTo(to);
};

export interface LinkPropsType {
    //TODO - It would be good to remove the undefined from the props "to"
    to?: ToType;
    children?: React.ReactNode;
    className?: string;
    size?: 'large' | 'medium' | 'small' | 'xs';
    isButton?: boolean;
    onClick?: () => void;
    onMouseEnter?: (e: React.SyntheticEvent) => void;
    dataTest?: string;
}

interface LinkAttrType {
    target: string | undefined;
    rel: string | undefined;
}

const getLinkAttr = (starRouter: StarRouter, href: string): LinkAttrType => {
    const result = starRouter.isLinkExternal(href);

    if (result.type === 'parseError') {
        return {
            target: undefined,
            rel: undefined,
        };
    }

    if (result.type === 'onserver') {
        return {
            target: undefined,
            rel: undefined,
        };
    }

    if (result.type === 'external') {
        return {
            target: '_blank',
            rel: 'noopener',
        };
    }

    if (result.type === 'internal') {
        return {
            target: '_self',
            rel: undefined,
        };
    }

    return assertNever('getLinkAttr', result);
};

export const Link = observer('Link', (props: LinkPropsType) => {
    const { children, to, className, isButton, size, dataTest, onClick } = props;
    const { appLayoutsState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const href = getUrl(starRouter, to);
    const linkAttr = getLinkAttr(starRouter, href);

    const redirect = (e: React.MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        if (onClick !== undefined) {
            onClick();
        }

        starRouter.handleUrlRedirection(href);
    };

    return (
        <NewLinkWrapper
            isButton={isButton}
            className={className}
            size={size}
            href={href}
            data-test={dataTest === undefined ? null : `${dataTest}-link`}
            onClick={redirect}
            target={linkAttr.target}
            rel={linkAttr.rel}
        >
            {children}
        </NewLinkWrapper>
    );
});
