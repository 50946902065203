import { deburr as _ } from 'lodash';

const NOT_STANDARD_SLUG = /([^abcdefghijklmnopqrstuvwxyz0123456789]+)/g;

export function deburr(str: string): string {
    str = _(str);

    return str;
}

export const slug = (str: string): string => {
    str = String(str).toLowerCase();

    return str.replace(NOT_STANDARD_SLUG, '-');
};

