import React from 'react';
import { Bet } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { CaptionWrapper, Label, List } from './BetsList.style';
import { BetsListMessage } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/BetsListMessage';
import { OpenapiProxyCustomerBonusesResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_bonuses';
import { OpenapiProxyCustomerAllBetsResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_all_bets';
import { StatusType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface BetsListPropsTypes {
    betsResult: OpenapiProxyCustomerAllBetsResponse200Type | null;
    bonuses: OpenapiProxyCustomerBonusesResponse200Type;
    status: StatusType;
}

export const BetsList = observer('BetsList', ({ betsResult, bonuses, status }: BetsListPropsTypes) => {

    if (betsResult === null || status === 'loading' || betsResult?.results?.length < 1) {
        return (
            <React.Fragment key='no-bets'>
                <BetsListMessage status={status} betsListLength={0} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment key='bets-list'>
            <CaptionWrapper>
                <Label><I18n langKey='bets.bet.label' defaultText='Bet' /></Label>
                <Label><I18n langKey='bets.stake.label' defaultText='Stake' /></Label>
                <Label><I18n langKey='bets.return.label' defaultText='Return' /></Label>
            </CaptionWrapper>

            <List>
                {
                    betsResult.results.map((bet) =>
                        <Bet
                            key={bet.id}
                            bet={bet}
                            bonuses={bonuses}
                        />
                    )
                }
            </List>
        </React.Fragment>
    );
});
