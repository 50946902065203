import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const TitleSectionWrapper = styled('div', { label: 'TitleSectionWrapper' })`
    margin-top: 16px;
`;

export const LabelTextWrapper = withConfig(theme => styled('span', { label: 'LabelTextWrapper' })`
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    color: ${theme.star.signUp.txtColor};
    margin-bottom: 6px;
    line-height: 1;
`);

export const TitleChooseBoxWrapper = styled('div', { label: 'TitleChooseBoxWrapper' })`
    display: flex;
    justify-content: space-between;
`;
