import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { VerifyWithSMSInput } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/VerifyWithSMS.style';
import { InputStateAndModel } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/VerifyWithSMS.state';
import { SMSCodeState } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/SMSCode.state';
import { SMSInputState } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/SMSInput.state';

interface SMSInputPropsType {
    state: SMSCodeState,
    input: InputStateAndModel,
    idx: number,
}

export const SMSInput = observer('SMSInput', ({ state, idx, input }: SMSInputPropsType) => {

    const [ itemState ] = useState(() => new SMSInputState(state, idx));

    return (
        <VerifyWithSMSInput
            key={input.id}
            maxLength={1}
            name={input.id}
            type='text'
            placeholder='-'
            state={input.state}
            onInput={itemState.onInput}
            ref={state.addInputRef(idx)}
        />
    );
});
