import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string;
}

const SvgElement = styled('svg', { label: 'SvgElement' })<PropsType>`
    margin: 4px;
    width: auto;
`;

export const IconCheckMark = ({ className }: PropsType): JSX.Element => (
    <SvgElement className={className} fill='#fff' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32'>
        <path fillRule='evenodd' d='M11.377 27.228c-.37.365-.896.67-1.359.67-.463 0-.988-.32-1.374-.686L0 18.677l2.748-2.713 7.286 7.194L29.299 4 32 6.759 11.377 27.228z' />
    </SvgElement>
);
