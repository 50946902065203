import React, { useState } from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import { SelectState } from './Select.state';
import {
    SelectWrapper,
    SelectLabel,
    SingleOption,
    OptionsWrapper,
    SelectInput,
    SingleOptionPropsType
} from './Select.style';
import { useClickOutside } from 'src/domains/players/hooks/useClickOutside';
import { observable, action, makeObservable, computed } from 'mobx';

type SingleOptionType = React.FunctionComponent<SingleOptionPropsType>;
type LabelType = React.FunctionComponent<{}>

export interface SelectOptionType {
    [key: string]: string;
}

export const convertArrayOfStringForSelectComponent = (arrayOfString: string[]): SelectOptionType[]=> {
    return arrayOfString.map(item => ({ item }));
};

interface SelectPropsTypes {
    options: Array<SelectOptionType>;
    state: SelectState;
    isDisabled?: boolean;
    onChange?: () => void;
    labelText?: string | JSX.Element;
    hideLabel?: boolean;
    placeholder?: string;
    /** default true */
    closeOnChoose?: boolean;
    isSearchable?: boolean;
    customSingleOption?: SingleOptionType;
    customLabel?: LabelType;
    dataTest?: string;
    hasPrefixes?: boolean;
    colorTheme?: 'light' | 'dark';
    onBlur?: () => void;
    changeToInput?: boolean;
}

interface RenderOptionElementProps {
    option: SelectOptionType;
    selectedOption: SelectOptionType | undefined;
    index: number;
    onhandleClick: () => void;
}
class SelectInnerState {

    public refs: RefInterface;
    @observable public currChar: string = '';
    @observable public currIndex: number = 0;

    public constructor(private readonly props: SelectPropsTypes) {
        makeObservable(this);
        this.refs = this.props.options.reduce<RefInterface>((acc, value) => {
            const convertedValue = this.convertOptionToValue(value);

            if (convertedValue === undefined) {
                return acc;
            }

            acc[convertedValue] = React.createRef<HTMLButtonElement>();

            return acc;

        }, {});
    }


    @action public manageSearch = (char: string, filteredArray: string[]): void => {
        if (this.currChar === char && filteredArray[this.currIndex + 1] !== undefined) {
            this.currIndex += 1;
            return;
        }

        if (this.currChar === char) {
            this.currIndex = 0;
            return;
        }

        this.currChar = char;
        this.currIndex = 0;
    };


    public getFilteredArray = (char: string): string[] => {
        return this.props.options.map(option => this.convertOptionToValue(option) ?? '').filter((option) => {
            return option?.[0]?.toLowerCase() === char?.toLowerCase();
        });
    };


    @action public handleSearch = (e: React.SyntheticEvent<HTMLInputElement, Event>): void => {
        if (this.props.isSearchable !== true) {
            return;
        }

        this.resetHoverStyleForAllOption();

        this.props.state.handleOpen();

        const value = e.currentTarget.value;

        const char = this.getChar(value);

        this.props.state.inputState.setValue(this.props.state.currentValue);

        const filteredArray = this.getFilteredArray(char);

        this.manageSearch(char, filteredArray);

        const currValue = filteredArray[this.currIndex];

        if (currValue === undefined) {
            return;
        }

        setTimeout(() => {
            this.setHoverStyleForOption(currValue);

            this.refs[currValue]?.current?.scrollIntoView(
                { behavior: 'auto', block: 'nearest', inline: 'start' }
            );
        });

    };

    private getChar = (str: string): string => {
        return this.props.state.currentValue.split('').reduce((acc, char) => {
            const replaced = acc.replace(char, '') ?? '';

            return replaced;
        }, str);
    };

    private resetHoverStyleForAllOption = (): void => {
        Object.keys(this.refs).forEach(refKey => {
            this.refs[refKey]?.current?.setAttribute('aria-selected', 'false');
        });
    };

    private setHoverStyleForOption = (currValue: string): void => {
        this.refs[currValue]?.current?.setAttribute('aria-selected', 'true');
    };

    public onChangeHandler = (option: SelectOptionType): void => {
        this.props.state.value = option;
        this.resetHoverStyleForAllOption();

        if (this.props.state.currentValueFormatted !== undefined) {
            this.props.state.setCurrentValue(option);
        }

        if (this.props.onChange !== undefined) {
            this.props.onChange();
        }

        if (this.props.closeOnChoose !== false || this.props.isSearchable !== false) {
            this.props.state.close();
        }
    };

    public convertOptionToValue = (option: SelectOptionType): string | undefined => {
        const value = Object.values(option)[0];

        if (value?.[0] === '+') {
            return value.substring(1, value.length);
        }

        return value;
    };

    public getRefFunction = (option: SelectOptionType): React.RefObject<HTMLButtonElement> | undefined => {
        return this.refs[this.convertOptionToValue(option) ?? ''] ?? undefined;
    };

    @computed public get viewForOneOption(): boolean {
        return this.props.changeToInput === true && this.props.options.length === 1;
    }
}

interface RefInterface {
    [key: string]: React.RefObject<HTMLButtonElement> | null;
}

interface RenderOptionsPropsIn {
    isOpen: boolean;
    hideLabel: boolean | undefined;
    colorTheme: 'light' | 'dark' | undefined;
    options: SelectOptionType[];
    selectedOption: SelectOptionType | undefined;
    getRefFunction: (option: SelectOptionType) => React.RefObject<HTMLButtonElement> | undefined;
    handleClick: (option: SelectOptionType) => void;
}

const RenderOptionElement = React.forwardRef(({ option, index, selectedOption, onhandleClick }: RenderOptionElementProps, ref: React.ForwardedRef<HTMLButtonElement> | undefined): JSX.Element => {

    const key = Object.keys(option)[0];
    //@ts-expect-error
    const optionText = option[key];
    //@ts-expect-error
    const isSelected = selectedOption !== undefined && selectedOption[key] === option[key];

    return <SingleOption
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        key={`${optionText}-${index}`}
        ref={ref}
        aria-selected={isSelected}
        role='option'
        isSelected={isSelected}
        onClick={onhandleClick}
        type='button'
    >
        {optionText}
    </SingleOption>;
});

const RenderOptions = observer('RenderOptions', (propsIn: RenderOptionsPropsIn): JSX.Element => {
    const props = useAsObservableSource(propsIn);
    const { isOpen, colorTheme, hideLabel, options, selectedOption, getRefFunction, handleClick } = props;

    return (
        <OptionsWrapper
            isOpen={isOpen}
            colorTheme={colorTheme} hideLabel={hideLabel}>
            {options.map((option, index: number) => {
                const objProp = Object.entries(option)[0] ?? ['', ''];
                return (<RenderOptionElement key={objProp[0] + objProp[1]} option={option} index={index} ref={getRefFunction(option)} onhandleClick={(): void => handleClick(option)} selectedOption={selectedOption} />);
            })}
        </OptionsWrapper>
    );
});



export const Select = observer('Select', (propsIn: SelectPropsTypes): JSX.Element => {
    const props = useAsObservableSource(propsIn);
    const { options, labelText, isDisabled, state, placeholder, customLabel,
        hideLabel, dataTest, hasPrefixes, colorTheme, isSearchable, onBlur } = props;
    const [innerState] = useState(() => new SelectInnerState(props));
    //TODO: change to mobX clickOutside
    const [setWrapperRef] = useClickOutside({ callback: state.handleClickOutside });



    const renderInput = (): JSX.Element => {

        const isReadOnly = isSearchable === true ? false : true;

        return (
            <SelectInput
                state={state.inputState}
                onBlur={state.handleBlur}
                placeholder={placeholder}
                autocomplete='off'
                isReadOnly={isReadOnly}
                dataTest={dataTest}
                onChange={innerState.handleSearch}
                hasPrefixes={hasPrefixes}
                colorTheme={colorTheme}
                isError={state.inputState.result.value.type === 'error'}
                type={innerState.viewForOneOption ? 'single-option-dropdown' : 'dropdown'}
                viewForOneOption={innerState.viewForOneOption}
            />
        );
    };

    const renderLabel = (): JSX.Element | null => {
        if (hideLabel === true) {
            return null;
        }
        const Label = customLabel === undefined ? SelectLabel : customLabel;
        return <Label data-test={`${dataTest === undefined ? '' : `${dataTest}-`}label`}>{labelText}</Label>;
    };

    const onBlurHandler = (): void => {
        if (onBlur !== undefined && state.isOpen === false) {
            onBlur();
        }
    };

    const isSelectDisabled = isDisabled === undefined ? false : isDisabled;

    return (
        <SelectWrapper
            onBlur={onBlurHandler}
            isDisabled={isSelectDisabled}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            aria-label={`${labelText} Select dropdown`}
            ref={setWrapperRef}
        >
            <div
                onClick={isSelectDisabled ? undefined : state.handleClick}
            >
                {renderLabel()}
                {renderInput()}
            </div>
            <RenderOptions
                isOpen={state.isOpen}
                hideLabel={hideLabel}
                colorTheme={colorTheme}
                options={options}
                selectedOption={state.value}
                getRefFunction={innerState.getRefFunction}
                handleClick={innerState.onChangeHandler}
            />
        </SelectWrapper>
    );
});
