import styled from '@emotion/styled';

export const LoadingPageWrapper = styled('div', { label: 'LoadingPageWrapper' })`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > a > svg {
        width: 400px;
    }
`;
