import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { buildBtnBackgroundColor, buildBtnDisabled, buildBtnLoading, buildBtnSize, buildSpinner } from 'src/domains/layouts/webview/components/common/button/utils';
import { ButtonVersionType } from 'src/domains/layouts/webview/components/common/button/Button';
import { Spinner } from 'src/domains/layouts/shared/assets/icons/Spinner';
import { withConfig } from 'src/withConfig';

interface ButtonUniversalWrapperType {
    className?: string;
    disabled?: boolean;
    isLoading?: boolean;
    size: 'large' | 'medium' | 'small' | 'xs';
    version?: ButtonVersionType;
}

export const ButtonUniversalWrapper = withConfig(theme => styled('button', { label: 'ButtonUniversalWrapper' })<ButtonUniversalWrapperType>`
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0;
    position: relative;
    text-decoration: none;
    z-index: ${theme.star.zIndexGlobal.base};
    border-radius: 0;
    &::before {
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .2s ease opacity;
        z-index: ${theme.star.zIndexGlobal.below};
    }
    &:hover {
        &::before {
            opacity: 1;
        }
    }
    &:active,
    &:focus {
        outline: none;
    }
    ${({ disabled }): SerializedStyles | null => buildBtnDisabled(disabled)};
    ${({ isLoading }): SerializedStyles | null => buildBtnLoading(isLoading)};
    ${({ size }): SerializedStyles => buildBtnSize(theme, size)};
    ${({ version='primary' }): SerializedStyles => buildBtnBackgroundColor(theme, version)};
`);

type ButtonSpinnerWrapperType = Pick<ButtonUniversalWrapperType, 'size'>;

export const ButtonSpinnerWrapper = styled(Spinner, { label: 'ButtonSpinnerWrapper' })<ButtonSpinnerWrapperType>`
    display: block;
    margin: 2px auto;
    ${({ size }): SerializedStyles => buildSpinner(size)};
`;
