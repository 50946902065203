import { computed, action, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { getSavedCards } from 'src_server/trpc/types';
import { PaymentProvidersType } from 'src/domains/layouts/config/features/types';

export class PaymentMethodDropdownState {
    public readonly selectedTopUpOption: FormInputState<string, string>;
    public readonly savedPaymentMethodResource: Resource<getSavedCards.TResponseType>;

    public constructor(
        private readonly trpcClient: TrpcClient,
        private readonly usePaymentFormForUniverse: PaymentProvidersType
    ) {
        makeObservable(this);
        this.selectedTopUpOption = FormInputState.new('');
        this.savedPaymentMethodResource = new Resource(async (): Promise<getSavedCards.TResponseType> => {
            return await this.trpcClient.client.payments.getSavedCard.query({
                provider: this.usePaymentFormForUniverse.type,
            });
        });
    }

    @computed public get savedPaymentMethods(): getSavedCards.TSuccessResponse | null {
        const resource = this.savedPaymentMethodResource.getReady();
        if (resource === null || resource.responseStatus === 'error') {
            return null;
        }

        return resource.response;
    }

    @computed public get paymentMethodsValue(): string {
        const selectedPaymentMethod = this.selectedPaymentMethod;
        if (selectedPaymentMethod === undefined) {
            return 'none';
        }

        return selectedPaymentMethod.id;
    }

    @computed public get selectedPaymentMethod(): getSavedCards.SavedCardInterface | undefined {
        if (this.savedPaymentMethods === null) {
            return undefined;
        }

        if (this.selectedTopUpOption.value === '') {
            const ArrLength = this.savedPaymentMethods.length;
            const lastSavedPaymentMethod = this.savedPaymentMethods[ArrLength - 1];

            if (lastSavedPaymentMethod !== undefined) {
                return lastSavedPaymentMethod;
            }
        }

        const accountSavedPaymentMethods = this.savedPaymentMethods;
        for (const paymentMethod of accountSavedPaymentMethods) {
            if (paymentMethod.id === this.selectedTopUpOption.value) {
                return paymentMethod;
            }
        }

        return undefined;
    }

    @action public handlePaymentSelectChange = (value: string): void => {
        this.selectedTopUpOption.setValue(value);
        this.selectedTopUpOption.setAsVisited();
    };

    @computed public get isNewCard(): boolean {
        return this.paymentMethodsValue === 'none';
    }

    @computed public get areSavedPaymentMethods(): boolean {
        return this.savedPaymentMethods !== null && this.savedPaymentMethods.length > 0 ? true : false;
    }
}
