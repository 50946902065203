import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StyledSeeMoreButton } from './SeeMoreButton.style';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';

interface SeeMoreButtonPropsType {
    appCasinoState: AppCasinoState;
}

export const SeeMoreButton = observer('SeeMoreButton', (props: SeeMoreButtonPropsType) => {
    const { appCasinoState } = props;
    const { breakpointsState, miniGamesListState } = appCasinoState;
    const { toggleExtendGamesList, isGamesListExtended } = miniGamesListState;

    const isDesktop = breakpointsState.desktop.isBiggerOrEq === true;

    if (isDesktop === false) {
        return null;
    }

    return (
        <StyledSeeMoreButton
            onClick={toggleExtendGamesList}
            data-test='mini-games-see-more-button'
        >
            {isGamesListExtended ? (
                <I18n
                    langKey='casino.mini-games.see-less-button'
                    defaultText='Show Less'
                />
            ) : (
                <I18n
                    langKey='casino.mini-games.see-more-button'
                    defaultText='See More'
                />
            )}
        </StyledSeeMoreButton>
    );
});
