import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { Amount } from 'src_common/common/amount/Amount';
import { StakeInputState2 } from './stakeInputState/StakeInputState2';

export class AddToAllState {
    public readonly stakeInput: StakeInputState2;

    private readonly getLegsIds: () => Array<BetslipSingleId>;

    public constructor(getLegsIds: () => Array<BetslipSingleId>, decimalLength: number) {
        this.getLegsIds = getLegsIds;
        this.stakeInput = new StakeInputState2(decimalLength, this.handleOnChange);
    }

    public handleOnChange = (data: Amount): void => {
        const legIds = this.getLegsIds();

        for (const legId of legIds) {
            const stakeInput = legId.getModel().stakeInput;
            if (stakeInput === null) {
                console.error('AddToAllState -> handleOnChange -> ignore change');
            } else {
                stakeInput.changeStake(data);
            }
        }
    };
}
