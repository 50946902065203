import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetslipSelection } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection';
import { AllStakesWrapper } from './BetslipSinglesSection.style';
import { BetslipAddToAllLegs } from 'src/domains/sportsbook/betting/ui/betSlip/betslipAddToAllLegs/BetslipAddToAllLegs';
import {
    ChevronIconWrapper,
    HeaderWrapper,
    Label,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslip/Betslip.style';
import { SelectionsList } from 'src/domains/sportsbook/betting/ui/betSlip/betslipMainContent/BetslipMainContent.style';
import { useAppStateContext } from 'src/appState/AppState';

export const BetslipSinglesSection = observer('BetslipSinglesSection', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { legsIds2, basicBetSlipState } = betSlipState;
    const { isSinglesOpenForView, onToggleSinglesOpen } = basicBetSlipState;

    return (
        <SelectionsList data-test='betslip-singles-section'>
            {legsIds2.length > 0 ? (
                <HeaderWrapper onClick={onToggleSinglesOpen}>
                    <ChevronIconWrapper position={isSinglesOpenForView ? 'down' : 'right'} />
                    <Label data-test='betslip-section-header'>
                        <I18n langKey='betslip.header.singles' defaultText='SINGLES' />
                    </Label>
                </HeaderWrapper>
            ) : null}
            {legsIds2.map((legId) => {
                return <BetslipSelection key={legId.reactKey} id={legId} isHidden={!isSinglesOpenForView} />;
            })}
            {legsIds2.length > 1 ? (
                <AllStakesWrapper data-test='all-stakes'>
                    <BetslipAddToAllLegs />
                </AllStakesWrapper>
            ) : (
                void 0
            )}
        </SelectionsList>
    );
});
