import { observable, action, computed, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { PromoCodesState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/stepsStructure/createAccount/promoCodesState/PromoCodes.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import {
    checkPassword,
    validateEmail,
    validateEmailRaw,
    validatePassword,
    validateRequire,
} from 'src/domains/players/webview/components/ValidatorsNew';
import {
    emailCheckMap,
    toLowerCase,
} from 'src/domains/players/webview/components/SignUp/signupParts/emailValidation/EmailValidation';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';

export interface WelcomeStepFormType {
    email: string;
    password: string;
    promoCode: string;
    affiliateId?: string;
}

export class SignUpWelcomeState {
    @observable public isPromoCode: boolean;
    @observable public isShowPassword = false;

    private readonly emailCheckMap: MobxMapAutoNew<string, Resource<boolean>>;

    public promoCodesState: PromoCodesState;
    public readonly emailState: FormInputState<string, string>;
    public readonly passwordState: FormInputState<string, string>;
    public readonly promoCodeTextState: FormInputState<string, string>;
    public readonly affiliateIdModel: FormInputState<string, string | undefined>;
    public readonly welcomeStepFormModel: FormModel<WelcomeStepFormType>;

    public constructor(
        private readonly customKeyboard: CustomKeyboardState,
        private readonly moveToNextStage: () => void,
        private readonly language: LanguagesState,
        private readonly localStorageState: LocalStorageState,
        initialUserData?: WelcomeStepFormType
    ) {
        makeObservable(this);
        this.emailCheckMap = emailCheckMap();
        this.promoCodesState = new PromoCodesState();
        const initialEmail = initialUserData?.email === undefined ? '' : initialUserData.email;
        const initialPassword = initialUserData?.password === undefined ? '' : initialUserData.password;
        const initialPromoCode = this.promoCodeFromLocalStorage ?? '';
        this.isPromoCode = this.promoCodeFromLocalStorage === null ? false : true;
        this.emailState = FormInputState.new(initialEmail)
            .map(validateRequire)
            .map((value: string): Result<string> => {
                const valueTrim = value.trim();
                return Result.createOk(valueTrim);
            })
            .map(validateEmail)
            .map((value: string): Result<string> => {
                const isExistResult = this.emailCheckMap.get(value).get();

                if (isExistResult.type === 'ready') {
                    const isExist = isExistResult.value;

                    if (isExist) {
                        return Result.createError(getErrorByCode('ERROR_ALREADY_EXISTS'));
                    }
                }

                return Result.createOk(value);
            });

        this.passwordState = FormInputState.new(initialPassword).map(validatePassword);

        this.promoCodeTextState = FormInputState.new(initialPromoCode)
            .map(toLowerCase)
            .map((value: string): Result<string> => {
                if (this.promoCodesState.promoCodes.type === 'ready') {
                    const isPromoCodeValid = this.promoCodesState.checkIfCodeExistsAndIsValid(value);

                    if (isPromoCodeValid === false) {
                        const message = this.language.getTranslation(
                            'sign-up.create-account.welcome.promo-code.error',
                            'This promo code is not valid'
                        );
                        return Result.createError(message);
                    }
                }

                return Result.createOk(value);
            });

        this.affiliateIdModel = this.promoCodeTextState.map((): Result<string | undefined> => {
            if (this.promoCodesState.promoCodes.type === 'ready') {
                const isAffiliateIdValid = this.promoCodesState.onIfAffiliateIdExists(this.promoCodeTextState.value);

                return Result.createOk(isAffiliateIdValid);
            }
            return Result.createOk(undefined);
        });

        this.welcomeStepFormModel = FormModel.group({
            email: this.emailState,
            password: this.passwordState,
            promoCode: this.promoCodeTextState,
            affiliateId: this.affiliateIdModel,
        });
    }

    @action public promoCodeTextChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.value;
        const valueFromStorage = this.localStorageState.promoCode.getValue();
        if (value === '' && valueFromStorage !== null) {
            this.localStorageState.promoCode.setValue(null);
        }
    };

    @computed public get promoCodeFromLocalStorage(): string | null {
        return this.localStorageState.promoCode.getValue();
    }

    @computed public get isCorrectEmail(): boolean {
        const email = this.emailState.value;
        const emailItem = this.emailCheckMap.get(this.emailState.value).get();

        if (emailItem.type === 'ready') {
            const isExist = emailItem.value;
            if (isExist) {
                return false;
            }

            const isEmailOk = validateEmailRaw(email);
            return isEmailOk;
        }

        return false;
    }

    @computed public get isCorrectPassword(): boolean {
        const password = this.passwordState.value;

        return checkPassword(password);
    }

    @computed public get stepIsValid(): boolean {
        return (
            this.emailState.errorsForView.length === 0 &&
            this.passwordState.errorsForView.length === 0 &&
            this.emailState.value !== '' &&
            this.passwordState.value !== ''
        );
    }

    @computed public get promoCodeValue(): string {
        const isPromoCodeValid = this.promoCodesState.checkIfCodeExistsAndIsValid(this.promoCodeTextState.value);

        if (isPromoCodeValid) {
            return this.promoCodeTextState.value;
        }

        return '';
    }

    @action public setPromoCode = (v: boolean): void => {
        this.isPromoCode = v;
    };

    @action public showPassword = (): void => {
        this.isShowPassword = !this.isShowPassword;
    };

    public onMoveToYourDetails = (): void => {
        this.customKeyboard.scrollAsideToTop();
        this.welcomeStepFormModel.setAsVisited();

        if (this.welcomeStepFormModel.result.value.type === 'error') {
            return;
        }
        this.moveToNextStage();
    };
}
