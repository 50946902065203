import styled from '@emotion/styled';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const ResponsibleGamblingHeader = withConfig(theme => styled('h3', { label: 'ResponsibleGamblingHeader' })`
    margin: 0 !important;
    padding: 0 !important;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    text-transform: uppercase;
`);

export const MainDescription = withConfig(theme => styled('p', { label: 'MainDescription' })`
    margin: 8px 0 0;
    font-size: ${theme.star.fontSize.xRegular} !important;
    line-height: 1.43 !important;
`);

export const Description = withConfig(theme => styled('p', { label: 'Description' })`
    margin: 6px 0 8px;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.33 !important;
    &:only-of-type {
        margin: 6px 0 0;
    }
`);

export const SectionWrapper = styled('article', { label: 'SectionWrapper' })`
    margin: 20px 0 0;
`;

export const HeadlineWrapper = styled('div', { label: 'HeadlineWrapper' })`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -1px;
`;

export const HeadlineIconWrapper = styled('div', { label: 'HeadlineIconWrapper' })`
    padding-right: 8px;
`;

export const SectionHeadline = withConfig(theme => styled('h4', { label: 'SectionHeadline' })`
    display: inline-block;
    margin: 0 5px 0 0;
    font-size: ${theme.star.fontSize.small} !important;
    font-weight: ${theme.star.fontWeight.bold} !important;
    line-height: 1.3334;
`);

export const InfoIconWrapper = withConfig(theme => styled(InfoIcon, { label: 'InfoIconWrapper' })`
    position: relative;
    width: 16px;
    top: 3px;
    fill: ${theme.star.signUp.bgColorQuaternary};
    cursor: pointer;
`);

export const NextButtonWrapper = styled(Button, { label: 'NextButtonWrapper' })`
    width: 100%;
    margin: 24px 0 8px 0 !important;
`;
