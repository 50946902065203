import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { LoadingWrapper } from 'src/domains/layouts/shared/loaders';
import { SrgReopenContainer, SrgReopenButton, SrgReopenText, SrgReopenWrapper, SrgReopenBoxHeader } from 'src/domains/layouts/webview/components/srgReopenBox/SrgReopenBox.style';
import { SrgReopenBoxState } from 'src/domains/layouts/webview/components/srgReopenBox/SrgReopenBox.state';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';

export const SrgReopenBox = observer('SrgReopenBox', () => {
    const { appPlayersState: { usersState: { toggleSrgModal } } } = useAppStateContext();
    const [ srgReopenBoxState ] = useState(() => new SrgReopenBoxState());

    return (
        <LoadingWrapper computedHeight={srgReopenBoxState.boxHeight}>
            <SrgReopenWrapper ref={srgReopenBoxState.setRef}>
                <InfoIcon/>
                <SrgReopenContainer>
                    <SrgReopenBoxHeader>
                        <I18n langKey='srg-reopen.box.header' defaultText='Customer Interaction Questionnaire' />
                    </SrgReopenBoxHeader>
                    <SrgReopenText>
                        <I18n langKey='srg-reopen.box.message' defaultText='Please complete the remaining questions in order to activate your account.' />
                    </SrgReopenText>
                </SrgReopenContainer>

                <SrgReopenButton
                    onClick={toggleSrgModal}
                    size='small'
                    dataTest='srg-reopen-button'
                >
                    <I18n langKey='srg-reopen.box.button' defaultText='Open Questionnaire' />
                </SrgReopenButton>
            </SrgReopenWrapper>
        </LoadingWrapper>
    );
});
