import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';
import { withConfig } from 'src/withConfig';

interface OverlayItemPropsType {
    isVisible: boolean;
    zIndex?: number;
}

const OverlayElem = withConfig(theme => styled('div', { label: 'OverlayElem' })<OverlayItemPropsType>`
    background: ${theme.star.overlay.bgColorSecondary};
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: ${({ isVisible }): string => isVisible ? '1' : '0'};
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .3s ease;
    visibility: ${({ isVisible }): string => isVisible ? 'visible' : 'hidden'};
    z-index: ${({ zIndex }): number => zIndex === undefined ? 2: zIndex};
`);

interface OverlayPropsTypes {
    zIndex: number;
    isVisible: boolean;
    onClick: () => void;
}

export const Overlay = observer('Overlay', ({ isVisible, onClick, zIndex }: OverlayPropsTypes) => {
    return (
        <OverlayElem onClick={onClick} isVisible={isVisible} zIndex={zIndex} />
    );
});
