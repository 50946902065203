import * as React from 'react';

import { Wrapper } from './SpecialSportPage.style';

import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { SportHeaderStar } from 'src/domains/layouts/webview/components/sportHeader/SportHeader';
import { EventsTable } from './filters/EventsTable';
import { useAppStateContext } from 'src/appState/AppState';
import { SportSpecialRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsTypes {
    currentView: SportSpecialRouteType;
    accountView: RightHandSideViewType | null;
    isSearchActive: boolean;
}

export const SpecialSportPage = observer('SpecialSportPage', ({ currentView, accountView, isSearchActive }: PropsTypes) => {
    const { appSportsBookState } = useAppStateContext();
    const slug = currentView.id;
    const specialSportState = appSportsBookState.specialSportsMap.get(slug);

    const HEADER_DEFAULT = {
        desktopImage: '',
        mobileImage: '',
        tabletImage: '',
    };
    return (
        <section>
            <LayoutSkeleton currentView={currentView} accountView={accountView} isSearchActive={isSearchActive} navigationType='global' >
                <Wrapper>
                    <SportHeaderStar options={HEADER_DEFAULT} isSpecialEvent={true} sportId={specialSportState?.sportId ?? ''} isCompetitionPage={false} competitionId={null}/>
                    <EventsTable specialSportState={specialSportState}/>
                </Wrapper>
            </LayoutSkeleton>
        </section>
    );
});
