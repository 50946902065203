import { z } from 'zod';

const StreamUrlResponseSchema = z.object({
    url: z.string(),
});

export type StreamUrlResponseType = z.infer<typeof StreamUrlResponseSchema>;

const StreamMetaDataSchema = z.object({
    bitrateLevel: z.string(),
    mediaFormat: z.string(),
    url: z.string(),
});

const StreamUrlsResponseSchema = z.object({
    streams: z.array(StreamMetaDataSchema).nullable(),
});

export type StreamUrlsResponseType = z.infer<typeof StreamUrlsResponseSchema>;

export const RmgMetadataResponseSchema = z.object({
    fixtureId: z.string(),
    assetId: z.string(),
    accessToken: z.string(),
    outletAuthKey: z.string(),
    accountId: z.number(),
});

export type RmgStreamMetadataResponseType = z.infer<typeof RmgMetadataResponseSchema>;
