/* eslint-disable */
import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { NavigationLinkItemType } from 'src/api/config/cms/navigationHeaderActiveLinks';
import { LinksScrollableWrapper } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/linksScrollableWrapper/LinksScrollableWrapper';
import { HeaderLinksType } from 'src/domains/layouts/config/features/types';
import {
    HeaderMainLinkWrapper,
    HeaderStyledLink,
    VirtualSportsIconWrapper,
    CherryIconWrapper,
    FootballIconWrapper,
    InPlayIconWrapper,
    LiveCasinoIconWrapper,
    HeaderStyledStaticLink,
    SpecialsIconWrapper,
    HeaderStyledStaticOnClick,
    CasinoLucky6IconWrapper,
    StatsIconWrapper,
    BingoIconWrapper,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/headerMainLinks/HeaderMainLinks.style';
import { useAppStateContext } from 'src/appState/AppState';
import { isMainViewRouteEq, RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

interface HeaderItemType {
    icon: (isActive?: boolean) => JSX.Element;
    label: string | React.ReactNode;
    onSidebar?: boolean;
    isHidden?: boolean;
    displayOrder?: number | null;
    action:
        | {
              type: 'redirect';
              to: RouteViewType | undefined;
              isActive?: (route: string) => boolean;
          }
        | {
              type: 'redirect-outside';
              href: string;
              dataTest: string;
          }
        | {
              type: 'onclick';
              onClick: () => void;
              dataTest: string;
          };
}

interface PropsType {
    className?: string;
    onSidebar?: boolean;
    currentView: RouteViewType;
    languageWidth: number;
}

export const HeaderMainLinks = observer('HeaderMainLinks', (props: PropsType) => {
    const { appLayoutsState, appPlayersState, appCasinoState } = useAppStateContext();
    const { breakpointsState, configComponents, featureState } = appLayoutsState;
    const { config } = configComponents;

    const getSpecialEventsHeaders = (): Array<HeaderItemType> => {
        const { specialEvents } = appPlayersState;
        const { validatedLinks, getParams } = specialEvents.headerNavigationLinks;

        return validatedLinks.map((link: NavigationLinkItemType) => {
            const isDirectLink = link.action_template === 'direct-link';
            const linkUrl = link.cta_url;
            const redirectTo = getParams(link);

            if (isDirectLink && linkUrl !== '' && linkUrl !== undefined && linkUrl !== null) {
                return {
                    icon: (): JSX.Element => <SpecialsIconWrapper source={link.img_url} />,
                    label: link.title,
                    onSidebar: true,
                    displayOrder: link.display_order,
                    action: {
                        type: 'redirect-outside',
                        href: linkUrl,
                        dataTest: 'header-special-link',
                    },
                };
            } else {
                const link2: HeaderItemType = {
                    icon: (): JSX.Element =>
                        link.sport === 'premier-league' ? (
                            //until LT-944 is not fixed
                            <StatsIconWrapper />
                        ) : (
                            <SpecialsIconWrapper source={link.img_url} />
                        ),
                    label: link.title,
                    onSidebar: true,
                    displayOrder: link.display_order,
                    action: {
                        type: 'redirect',
                        to: redirectTo,
                    },
                };

                return link2;
            }
        });
    };

    const findHeaderLink = (headerItem: HeaderLinksType): HeaderItemType => {
        const { gameModalState } = appCasinoState;
        switch (headerItem) {
            case 'sports': {
                return {
                    icon: (): JSX.Element => <FootballIconWrapper />,
                    label: (
                        <I18n
                            langKey='header.sports.label'
                            defaultText='sports'
                        />
                    ),
                    onSidebar: true,
                    action: {
                        type: 'redirect',
                        to: { name: 'homepage' },
                        isActive: (route: string): boolean =>
                            ['event', 'sport', 'competition', 'racecard', 'sport-special'].includes(route),
                    },
                };
            }
            case 'in-play': {
                return {
                    icon: (): JSX.Element => <InPlayIconWrapper />,
                    label: (
                        <I18n
                            langKey='header.in-play.label'
                            defaultText='in play'
                        />
                    ),
                    onSidebar: true,
                    action: {
                        type: 'redirect',
                        to: { name: 'inplay' },
                    },
                };
            }
            case 'virtuals': {
                return {
                    icon: (): JSX.Element => <VirtualSportsIconWrapper />,
                    label: (
                        <I18n
                            langKey='header.virtual-sports.label'
                            defaultText='virtuals'
                        />
                    ),
                    onSidebar: true,
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'virtuals',
                        },
                    },
                };
            }
            case 'casino': {
                return {
                    icon: (): JSX.Element => <CherryIconWrapper />,
                    label: (
                        <I18n
                            langKey='header.casino.label'
                            defaultText='casino'
                        />
                    ),
                    onSidebar: true,
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'casino',
                            tabId: null,
                            game: null,
                        },
                    },
                };
            }
            case 'live-casino': {
                return {
                    icon: (): JSX.Element => <LiveCasinoIconWrapper />,
                    label: (
                        <I18n
                            langKey='header.live-casino.label'
                            defaultText='Live casino'
                        />
                    ),
                    onSidebar: true,
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'live-casino',
                            tabId: null,
                        },
                    },
                };
            }
            case 'lucky6': {
                return {
                    icon: (): JSX.Element => <CasinoLucky6IconWrapper />,
                    label: (
                        <I18n
                            langKey='header.lucky6.label'
                            defaultText='Lucky6'
                        />
                    ),
                    onSidebar: true,
                    action: {
                        type: 'onclick',
                        onClick: (): void => {
                            gameModalState.startLucky6Game();
                        },
                        dataTest: 'lucky6',
                    },
                };
            }
            case 'bingo': {
                return {
                    icon: (): JSX.Element => <BingoIconWrapper />,
                    label: (
                        <I18n
                            langKey='header.bingo.label'
                            defaultText='Bingo'
                        />
                    ),
                    onSidebar: true,
                    action: {
                        type: 'redirect',
                        to: {
                            name: 'casino',
                            game: 'bingo',
                        },
                    },
                };
            }
        }
    };

    const getHeaderItemsDefault = (headerItems: Array<HeaderLinksType>): Array<HeaderItemType> => {
        const { navigationMobileLinks } = config;
        const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;
        const specialEventsArray = getSpecialEventsHeaders();
        const links: Array<HeaderItemType> = [];
        const headerLinks = (): void => {
            headerItems.map((headerItem) => {
                links.push(findHeaderLink(headerItem));
            });
        };
        const specialLinks = (): void => {
            specialEventsArray.map((specialLink: HeaderItemType) => {
                if (specialLink.displayOrder !== undefined && specialLink.displayOrder !== null) {
                    links.splice(specialLink.displayOrder - 1, 0, specialLink);
                } else {
                    links.push(specialLink);
                }
            });
        };

        if (isLargeDesktop === true || navigationMobileLinks === 'header-links') {
            headerLinks();
            specialLinks();
        } else {
            specialLinks();
        }

        return links;
    };

    const getHeadersConfig = (): HeaderLinksType[] => {
        const { headerLinks, casinoPublishedCollections } = config;
        const headerItems = headerLinks.filter((item) => {
            if (item === 'casino' || item === 'live-casino' || item === 'virtuals' || item === 'bingo') {
                if (featureState.allowCasinoInIOSWrapperNew === false) {
                    return false;
                }
                return casinoPublishedCollections.includes(item);
            }
            return true;
        });
        return headerItems;
    };

    const getHeaderItems = (): Array<HeaderItemType> => {
        return getHeaderItemsDefault(getHeadersConfig());
    };

    const isActive = (link: HeaderItemType, currentView: RouteViewType): boolean => {
        if (link.action.type === 'onclick' || link.action.type === 'redirect-outside') {
            return false;
        }

        if (link.action.to?.name === 'casino' && link.action.to.game === 'bingo') {
            return false;
        }

        const thirdCase: boolean = link.action.isActive !== undefined && link.action.isActive(currentView.name);
        if (thirdCase) {
            return true;
        }

        const to = link.action.to;
        if (to === undefined) {
            return false;
        }

        return isMainViewRouteEq(to, currentView);
    };

    const fireGtmTag = (route: string | undefined): undefined => {
        const { googleTagManager } = appLayoutsState;

        googleTagManager.categoryViewTag(route);
        return undefined;
    };

    const { onSidebar, currentView } = props;
    const { navigationMobileLinks } = config;
    const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;

    if (isLargeDesktop === null) {
        return null;
    }

    const content = (
        <HeaderMainLinkWrapper
            isLargeDesktop={isLargeDesktop === true}
            isSpecialLink={navigationMobileLinks === 'special-links'}
        >
            {getHeaderItems().map((item: HeaderItemType, idx: number) => {
                if ((onSidebar === true && item.onSidebar === false) || item.isHidden === true) {
                    return;
                }

                if (item.action.type === 'onclick') {
                    const onItemClick = item.action.onClick;
                    const dataTestAttr = `${item.action.dataTest}-link`;
                    return (
                        <HeaderStyledStaticOnClick
                            onClick={(): void => {
                                onItemClick();
                            }}
                            data-test={dataTestAttr}
                            key={idx}
                        >
                            {item.icon(isActive(item, currentView))}
                            {item.label}
                        </HeaderStyledStaticOnClick>
                    );
                } else if (item.action.type === 'redirect-outside') {
                    const { href } = item.action;
                    return (
                        <HeaderStyledStaticLink
                            onClick={(): void => {
                                fireGtmTag(href);
                            }}
                            dataTest={`${item.action.dataTest}`}
                            key={idx}
                            to={href}
                        >
                            {item.icon(isActive(item, currentView))}
                            {item.label}
                        </HeaderStyledStaticLink>
                    );
                } else {
                    const to = item.action.to;
                    const actionRoute = to?.name;
                    const dataTest = actionRoute === 'casino' && to.game === 'bingo' ? 'bingo' : actionRoute;

                    return (
                        <HeaderStyledLink
                            onClick={(): void => {
                                fireGtmTag(actionRoute);
                            }}
                            key={idx}
                            to={to}
                            isActive={isActive(item, currentView)}
                            dataTest={dataTest}
                        >
                            {item.icon(isActive(item, currentView))}
                            {item.label}
                        </HeaderStyledLink>
                    );
                }
            })}
        </HeaderMainLinkWrapper>
    );
    return isLargeDesktop === true ? (
        <LinksScrollableWrapper languageWidth={props.languageWidth}>{content}</LinksScrollableWrapper>
    ) : (
        content
    );
});
