import * as React from 'react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import { IframeStandard, ScoreBoardWrapper } from 'src/domains/sportsbook/webview/components/scoreboards/standard/ScoreboardStandard.style';

interface PropType {
    eventId: string,
}

const getTennislUrl = ({ eventId }: PropType): string => {
    return `//scoreboards.sportingsolutions.com/tennis_responsive.html#event_id=${eventId}&customer=star`;
};

interface PropsType {
    event: EventModel,
}

export const TennisScoreboard = observer('TennisScoreboard', ({ event }: PropsType) => {
    const { feedGroupId } = event;

    if (feedGroupId !== null && feedGroupId !== undefined) {
        const url = getTennislUrl({
            eventId: feedGroupId,
        });

        return (
            <ScoreBoardWrapper>
                <IframeStandard src={url} key={feedGroupId} />
            </ScoreBoardWrapper>
        );
    }

    return null;
});
