
class Amd {
    private defineBuffor: Array<unknown> = [];
    private results: Map<string, Promise<unknown>>;

    public constructor() {
        this.results = new Map();
    }

    public define = (
        deps: Array<unknown>,
        moduleRoot: unknown
    ): void => {
        if (deps.length > 0) {
            console.error('AMD define dependencies, the module was expected to have no dependencies');
        } else {
            if (typeof moduleRoot === 'function') {
                this.defineBuffor.push(moduleRoot());
            } else {
                console.error('AMD define - Expected function');
            }
        }
    }

    public importAmd = (path: string): Promise<unknown> => {
        this.defineBuffor = [];

        const result = this.results.get(path);

        if (result !== undefined) {
            return result;
        }

        const newResult = new Promise((resolve, reject) => {
            let isLoading = true;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = path;

            const callback = (): void => {
                if (isLoading === false) {
                    return;
                }

                isLoading = false;

                const result = this.defineBuffor.pop();
                if (this.defineBuffor.length !== 0) {
                    console.error('AMD import - the define function was expected to be called once');
                }

                resolve(result);

                this.defineBuffor = [];
            };

            const error = (): void => {
                reject();
            };

            script.addEventListener('load', callback);
            script.onload = callback;
            script.addEventListener('error', error);
            script.onerror = error;

            document.body.appendChild(script);    
        });

        this.results.set(path, newResult);
        return newResult;
    }
}

const amd = new Amd();

// tslint:disable-next-line
if (typeof window === 'undefined') {
    //server
} else {
    //@ts-expect-error
    window.define = (
        deps: Array<unknown>,
        moduleRoot: unknown
    ): void => {
        amd.define(deps, moduleRoot);
    };

    //@ts-expect-error
    window.define.amd = {};
}

export const importAmd = (path: string): Promise<unknown> => {
    return amd.importAmd(path);
};

