import { AccountBasicDataType } from 'src/domains/players/state/UsersState';
import { DateTime } from 'src_common/utils/time/time';
import { z } from 'zod';

const isParsedError = (value: string | undefined | null): boolean => {
    if (value === undefined || value === null || value.trim() === '') {
        return true;
    }

    return false;
};

interface PraxisValidationDepositToValidateResponse {
    customer: {
        first_name: string;
        last_name: string;
        email: string;
        country: string;
        phone: string;
        address: string;
        city: string;
        zip: string;
        dob: string;
    };
    currency: string;
}

interface PraxisValidationDepositErrorResponse {
    type: 'error';
    fieldsRequired: string[];
}
export interface PraxisValidationDepositSuccessResponse {
    type: 'ok';
    data: PraxisValidationDepositToValidateResponse;
}

const billingInfoFields = z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    country_code: z.string(),
    prefix: z.string(),
    number: z.string(),
    country: z.string(),
    address: z.string(),
    city: z.string(),
    zip_code: z.string(),
    dob: z.string(),
    currency: z.string(),
});

export type ValidateBillingInfoFieldsPraxis = z.infer<typeof billingInfoFields>;

export const isValidBillingIfoFieldsPraxis = (value: unknown): value is ValidateBillingInfoFieldsPraxis =>
    billingInfoFields.safeParse(value).success;


export const praxisValidationDeposit = (basicData: Omit<AccountBasicDataType, 'oddsFormat'> | null): PraxisValidationDepositErrorResponse | PraxisValidationDepositSuccessResponse | undefined => {

    if (basicData !== null) {
        const dataToValid = {
            first_name: basicData.firstName,
            last_name: basicData.surname,
            email: basicData.email,
            country_code: basicData.country,
            prefix: basicData.mobilePhone?.prefix ?? null,
            number: basicData.mobilePhone?.number ?? null,
            country: basicData.mobilePhone?.country ?? null,
            address: basicData.addressLine1,
            city: basicData.city,
            zip_code: basicData.postCode,
            dob: DateTime.from(basicData.birthDate)?.format('DD/MM/YYYY'),
            currency: basicData.currency
        };
        if (isValidBillingIfoFieldsPraxis(dataToValid)) {
            return {
                type: 'ok',
                data: {
                    customer: {
                        first_name: dataToValid.first_name,
                        last_name: dataToValid.last_name,
                        email: dataToValid.email,
                        //Remove all special characters from prefix
                        phone: dataToValid.prefix.replace(/\D/g, '') + dataToValid.number,
                        country: dataToValid.country_code,
                        zip: dataToValid.zip_code,
                        address: dataToValid.address,
                        city: dataToValid.city,
                        dob: dataToValid.dob,
                    },
                    currency: dataToValid.currency
                }
            };
        }

        const fieldsRequired: Array<string> = [];
        if (isParsedError(dataToValid['first_name'])) {
            fieldsRequired.push('first_name');
        }
        if (isParsedError(dataToValid['last_name'])) {
            fieldsRequired.push('last_name');
        }
        if (isParsedError(dataToValid['email'])) {
            fieldsRequired.push('email');
        }
        if (isParsedError(dataToValid['country_code'])) {
            fieldsRequired.push('country_code');
        }
        if (isParsedError(dataToValid['prefix'])) {
            fieldsRequired.push('prefix');
        }
        if (isParsedError(dataToValid['number'])) {
            fieldsRequired.push('number');
        }
        if (isParsedError(dataToValid['country'])) {
            fieldsRequired.push('country');
        }
        if (isParsedError(dataToValid['address'])) {
            fieldsRequired.push('address');
        }
        if (isParsedError(dataToValid['city'])) {
            fieldsRequired.push('city');
        }
        if (isParsedError(dataToValid['zip_code'])) {
            fieldsRequired.push('zip_code');
        }
        if (isParsedError(dataToValid['dob'])) {
            fieldsRequired.push('dob');
        }
        if (isParsedError(dataToValid['currency'])) {
            fieldsRequired.push('currency');
        }

        return {
            type: 'error',
            fieldsRequired,
        };
    };
    return undefined;
};
