import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { DepositFailure } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { SwiftyGlobalTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalTab.state';
import { SwiftyGlobalTab } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalTab';
import { SwiftyGlobalIframeStyle } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalJourney.style';
import { LoadingSpinner } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';

interface PropsType {
    isSignup: boolean;
};

export const SwiftyGlobalJourney = observer('SwiftyGlobalJourney', ({ isSignup }: PropsType): JSX.Element => {
    const { config: { accountHelperMail },
        appPlayersState: { usersState, accountState },
        appLayoutsState: { languagesState }
    } = useAppStateContext();

    const [state] = useState(() => new SwiftyGlobalTabState(accountState, usersState,languagesState, isSignup));

    const renderContent = (): JSX.Element => {
        switch (state.depositInitiateState.type) {
            case 'loading':
                return <LoadingSpinner/>;

            case 'error':
                return <SwiftyGlobalTab
                    errorMessage={state.depositInitiateState.error}
                />;
            case 'serverIssue':
                return <DepositFailure
                    issueType='serverIssue'
                    accountHelperMail={accountHelperMail}
                    isSignup={isSignup}
                />;
            case 'ok':
                return <SwiftyGlobalIframeStyle
                    src={state.depositInitiateState.iframeUrl}
                    dataTest='swifty-global-iframe-style'
                />;
        }
    };

    return (
        <>
            {renderContent()}
        </>
    );
});
