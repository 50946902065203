import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StakeInputMobx } from 'src/domains/sportsbook/betting/ui/betSlip/stakeInputMobx/StakeInputMobx';
import { AddTooAllWrapper, AddTooAllTitle, AddTooAllInputWrapper } from './BetslipAddToAllLegs.style';
import { useAppStateContext } from 'src/appState/AppState';

export const BetslipAddToAllLegs = observer('BetslipAddToAllLegs', (): React.ReactElement => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { ids } = betSlipState;

    const setRef = betSlipState.betslipData.setRef(betSlipState.ids.getAll());

    return (
        <AddTooAllWrapper ref={setRef}>
            <AddTooAllTitle>
                <I18n
                    langKey='betslip.add-to-all-legs.title'
                    defaultText='All Singles Stakes'
                />
            </AddTooAllTitle>
            <AddTooAllInputWrapper>
                <StakeInputMobx selectionId={ids.getAll()} />
            </AddTooAllInputWrapper>
        </AddTooAllWrapper>
    );
});
