import { computed, makeObservable } from 'mobx';
import { LegType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipTypes';
import { PossibleBetsRequestState } from 'src/domains/sportsbook/betting/betSlipState/possibleBetsState/PossibleBetsState';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { TypeId } from 'src_common/common/websocket2/type';
import { Amount } from 'src_common/common/amount/Amount';
import { BetslipStateLegsType } from './LegStateType';
import { BetslipData } from 'src/domains/sportsbook/betting/betSlipState/BetslipData';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

export class LegsState {
    public constructor(
        private readonly possibleBetsRequestState: PossibleBetsRequestState,
        public readonly lifeSpanState: LifeSpanState,
        private readonly sdkCustomer: SdkCustomer,
        public betslipData: BetslipData
    ) {
        makeObservable(this);
    }

    public getLegById = (legId: BetslipSingleId): LegType | null => {
        const legIdOld = legId.id.toOldId();
        return this.parsedLegsMap.get(legIdOld) ?? null;
    };

    @computed private get beforeRequestParsedLegs(): Array<LegType> {
        const preparedLegs: Array<LegType> = [];
        for (const leg of Array.from(this.betslipData.getMapCorePrepareLegsValue().values())) {
            const selectionId = leg.selectionId.toOldId();
            const selectionModel = this.sdkCustomer.models.getSelection(selectionId);
            if (selectionModel !== null) {
                preparedLegs.push({
                    eachWay: leg.eachWay,
                    errors: leg.errors,
                    eventId: selectionModel.eventId,
                    freebetCredits: null,
                    freebetRemarks: null,
                    marketId: TypeId.getMarketId(selectionModel.marketId),
                    maxStake: '0', //leg.maxStake,
                    potentialReturns: /*leg.potentialReturns ??*/ '0',
                    potentialReturnsAt: /*leg.potentialReturnsEw ??*/ '0',
                    potentialReturnsEw: /*leg.potentialReturnsEw ??*/ '0',
                    price: selectionModel.price ?? null,
                    priceType: leg.priceType,
                    related: false,
                    selectionId: selectionModel.id,
                    index: selectionModel.id,
                    stakePerLine: leg.stakePerLine.currentStakeValue.value,
                    timestamp: 0,
                    numLines: 1,
                    totalStake: null,
                    uuid: selectionModel.uuid,
                });
            }
        }

        return preparedLegs;
    }

    @computed private get parsedLegs(): Array<LegType> {
        return this.beforeRequestParsedLegs.map((leg) => {
            const responseLeg =
                leg.selectionId === null
                    ? undefined
                    : this.possibleBetsRequestState.legsPossibleBetsResponseMap.get(leg.selectionId);

            if (responseLeg === undefined) {
                return {
                    ...leg,
                };
            } else {
                return {
                    ...leg,
                    ...responseLeg,
                };
            }
        });
    }

    @computed.struct public get coreLegsPossibleBetsRequest(): Array<BetslipStateLegsType> {
        return Array.from(this.betslipData.getMapCorePrepareLegsValue().values());
    }

    @computed private get parsedLegsMap(): Map<number, LegType> {
        const legsMap: Map<number, LegType> = new Map();
        for (const leg of this.parsedLegs) {
            if (leg.selectionId !== null) {
                legsMap.set(leg.selectionId, leg);
            }
        }

        return legsMap;
    }

    @computed public get forPotentialReturn(): Amount {
        let totalPotentialReturn: Amount = new Amount('0');
        const potentialReturnLifeSpan = this.lifeSpanState.lifeSpanSocketState.rewardValue;

        for (const leg of this.possibleBetsRequestState.parsedLegsPossibleBetsResponse) {
            if (
                leg.potentialReturns !== null &&
                leg.stakePerLine !== undefined &&
                leg.stakePerLine !== null &&
                new Amount(leg.stakePerLine).isGreaterThanZero()
            ) {
                totalPotentialReturn = totalPotentialReturn.add(new Amount(leg.potentialReturns));
            }
        }

        return totalPotentialReturn.add(potentialReturnLifeSpan);
    }

    @computed public get forPlaceBet(): Array<LegType> {
        const forPlaceBet: Array<LegType> = [];
        for (const leg of this.possibleBetsRequestState.parsedLegsPossibleBetsResponse) {
            if (leg.stakePerLine !== null) {
                forPlaceBet.push(leg);
            }
        }

        return forPlaceBet;
    }

    @computed public get isError(): boolean {
        return this.possibleBetsRequestState.parsedLegsPossibleBetsResponse.some((elem) => {
            return elem.errors.length > 0;
        });
    }

    @computed public get isAnyLegSP(): boolean {
        return this.possibleBetsRequestState.parsedLegsPossibleBetsResponse.some((elem) => {
            return elem.priceType === 'sp';
        });
    }
}
