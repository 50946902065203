import { computed, makeObservable } from 'mobx';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventRow6PackState } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/EventRow6Pack.state';
import { getTeamShirt } from './NHLTeamLogos';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export const getMarketNameNHL = (
    getTranslation: (key: string, defaultText: string) => string
): [string, string, string] => {
    return [
        getTranslation('market.nhl.moneyline', 'Money Line'),
        getTranslation('market.nhl.spread', 'Spread'),
        getTranslation('market.nhl.totalgoals', 'Total Goals (Inc OT)'),
    ];
};

export class NHLEventRow6PackState implements EventRow6PackState {
    public constructor(
        public readonly eventId: EventId,
        public readonly language: LanguagesState
    ) {
        makeObservable(this);
    }

    @computed public get event(): EventModel | null {
        return this.eventId.getEventModel();
    }

    // --------------------

    public get firstMarketName(): string {
        const marketsNamesNHL = getMarketNameNHL(this.language.getTranslation);
        return marketsNamesNHL[0] ?? '-';
    }

    public get secondMarketName(): string {
        const marketsNamesNHL = getMarketNameNHL(this.language.getTranslation);
        return marketsNamesNHL[1] ?? '-';
    }

    public get thirdMarketName(): string {
        const marketsNamesNHL = getMarketNameNHL(this.language.getTranslation);
        return marketsNamesNHL[2] ?? '-';
    }

    // --------------------

    @computed public get firstMarket(): MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const moneyLine = event.markets.filter((market) => market.newTemplateId === 'match-winner');
        return moneyLine[0];
    }

    @computed public get secondMarket(): MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const spreads = event.markets.filter(
            (market) => market.name.startsWith('Handicap') || market.name.startsWith('Spread')
        );
        return spreads.length === 1 ? spreads[0] : spreads[1];
    }

    @computed public get thirdMarket(): MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }
        const totalPoints = event.markets.filter((market) => market.name.startsWith('Goals U/O'));
        return totalPoints.length === 1 ? totalPoints[0] : totalPoints[1];
    }

    // --------------------

    public getSelectionName = (market: MarketModel | undefined, selection: SelectionModel): string => {
        if (market?.name.startsWith('Goals U/O') === true) {
            return selection.name.substring(0, selection.name.length - 9);
        }
        return selection.name.split(' ').slice(-1).join(' ');
    };

    // --------------------

    public getParticipantLogo = (name: string | null): string => {
        return getTeamShirt(name);
    };
}
