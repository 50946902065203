export class SMSCodeState {
    private readonly inputsRefs: Map<number, HTMLInputElement>;

    public constructor() {
        this.inputsRefs = new Map();
    }

    public addInputRef = (idx: number) => (ref: HTMLInputElement | null): void => {
        if (ref === null) {
            this.inputsRefs.delete(idx);
        } else {
            this.inputsRefs.set(idx, ref);
        }
    }

    public getNextInputRef = (idx: number): HTMLInputElement | null => {
        const nextIdx = idx + 1;
        return this.inputsRefs.get(nextIdx) ?? null;
    }
}
