import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    RhsMenuOverlay,
    RhsMenuSlide,
    RhsMenuSlider,
    RhsMenuWrapper,
} from 'src/domains/players/webview/components/Account/rhsMenu/RhsMenu.style';
import { useAppStateContext } from 'src/appState/AppState';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { AccountTabsBuilderState } from 'src/domains/players/webview/components/Account/accountDrawer/AccountTabsBuilder.state';
import { Tab } from 'src/domains/players/webview/components/Account/accountParts/Tab';

interface RenderTabPropsType {
    tab: RightHandSideViewType | null;
}

const RenderTab = observer('RenderTab', (props: RenderTabPropsType) => {
    const { appLayoutsState, appCasinoState, appPlayersState } = useAppStateContext();
    const { configComponents, sdkCustomer, languagesState } = appLayoutsState;
    const { session } = sdkCustomer;

    const [state] = useState(
        new AccountTabsBuilderState(session, configComponents, languagesState, appCasinoState, appPlayersState)
    );

    const { tab } = props;
    const data = state.getTabData(tab);

    if (data === null) {
        return null;
    }

    return (
        <Tab
            tabName={tab}
            showHeader={true}
            tabData={data}
            tabDataForHeader={data}
        />
    );
});

interface RhsMenuPropsType {
    topOffset: number;
}

export const RhsMenu = observer('RhsMenu', ({ topOffset }: RhsMenuPropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { accountDrawerNew, starRouter } = appLayoutsState;
    const { active, tabLeft, tabRight } = accountDrawerNew;

    const isRhsMenuVisible = active !== null;

    return (
        <>
            <RhsMenuOverlay
                isVisible={isRhsMenuVisible}
                onClick={starRouter.closeAccount}
            />

            <RhsMenuWrapper
                isVisible={isRhsMenuVisible}
                topOffset={topOffset}
            >
                <RhsMenuSlider tabForView={active}>
                    <RhsMenuSlide>
                        <RenderTab tab={tabLeft} />
                    </RhsMenuSlide>

                    <RhsMenuSlide>
                        <RenderTab tab={tabRight} />
                    </RhsMenuSlide>
                </RhsMenuSlider>
            </RhsMenuWrapper>
        </>
    );
});
