import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { CasinoSection } from './CasinoPage.style';
import { MainContent } from 'src/domains/layouts/webview/modules/layoutSkeleton/LayoutSkeleton.style';
import { CasinoRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { CasinoCollection } from 'src/domains/casino/webview/CasinoGames';

interface CasinoPagePropsType {
    appCasinoState: AppCasinoState;
    currentView: CasinoRouteType;
    isSearchActive: boolean;
    accountView: RightHandSideViewType | null;
}

export const CasinoPage = observer('CasinoPage', (props: CasinoPagePropsType) => {
    const { appCasinoState, currentView, isSearchActive, accountView } = props;

    return (
        <CasinoSection>
            <LayoutSkeleton
                navigation={false}
                currentView={currentView}
                accountView={accountView}
                isSearchActive={isSearchActive}
                navigationType='casino'
            >
                <MainContent>
                    <CasinoCollection
                        appCasinoState={appCasinoState}
                        collection='casino'
                        tabId={currentView.tabId ?? null}
                    />
                </MainContent>
            </LayoutSkeleton>
        </CasinoSection>
    );
});
