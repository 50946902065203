import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const NotificationsPageStyle = withConfig(theme => styled('section', { label: 'NotificationsPageStyle' })`
    padding-bottom: 55px;
    &::before {
        background-color: ${theme.star.notifications.notificationsPage.bgColor};
        bottom: 0;
        content: '';
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: ${theme.star.zIndexGlobal.below};
    }
`);

export const NotificationsListWrapper = styled('div', { label: 'NotificationsListWrapper' })`
    padding: 0 10px 10px;
    margin-top: 10px;
`;

export const NotificationsWrapper = styled('div', { label: 'NotificationsWrapper' })`
    padding: 10px;
`;
