import { observable, computed, action, makeObservable } from 'mobx';
import { PossibleBetsRequestState } from './possibleBetsState/PossibleBetsState';
import { PlayableBalanceAmountsType, BetSlipUserDataType } from './BetSlipSheredTypes';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { ErrorRawType } from 'src/domains/sportsbook/betting/betting/getPossibleBetsTypes';
import { QuickBetState } from './quickBetState/QuickBetState';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import {
    BetslipSingleId,
    BetslipCombinationId,
    BetslipRabId,
    BetslipAllSinglesId,
} from 'src/domains/sportsbook/betting/models/BetslipIdModels';

export class BasicBetSlipState {
    private readonly possibleBetsRequestState: PossibleBetsRequestState;
    private readonly quickBetState: QuickBetState;
    private readonly getAccountData: () => BetSlipUserDataType;

    @observable private focusedSelectionIdInner:
        | BetslipSingleId
        | BetslipCombinationId
        | BetslipRabId
        | BetslipAllSinglesId
        | null = null;
    @observable private isSinglesOpen: boolean = true;
    @observable private isMultiplesOpen: boolean = true;

    public constructor(
        possibleBetsRequestState: PossibleBetsRequestState,
        quickBetState: QuickBetState,
        getAccountData: () => BetSlipUserDataType
    ) {
        makeObservable(this);
        this.quickBetState = quickBetState;
        this.possibleBetsRequestState = possibleBetsRequestState;
        this.getAccountData = getAccountData;
    }

    @action public onFocusSelection = (
        focusedSelectionIdInner: BetslipSingleId | BetslipCombinationId | BetslipRabId | BetslipAllSinglesId
    ): void => {
        this.focusedSelectionIdInner = focusedSelectionIdInner;
    };

    @action public onHideKeyboard = (): void => {
        this.focusedSelectionIdInner = null;
    };

    @action public onCloseQuickBet = (): void => {
        if (this.quickBetState.isVisibleQuickBet) {
            this.onHideKeyboard();
        }
        this.quickBetState.onUpdateQuickBet(false);
    };

    @action public onToggleSinglesOpen = (): void => {
        this.isSinglesOpen = !this.isSinglesOpen;
    };

    @action public onToggleMultiplesOpen = (): void => {
        this.isMultiplesOpen = !this.isMultiplesOpen;
    };

    @computed public get errors(): Array<ErrorRawType> {
        return this.possibleBetsRequestState.errors.filter((error) => {
            const isMinimum =
                error.resource === 'Wallet' && error.field === 'playableBalance' && error.code === 'minimum';
            if (isMinimum) {
                return false;
            }
            return true;
        }); //resource: "Wallet", code: "minimum", field: "playableBalance"
    }

    @computed public get betSlipPlayableBalanceAmounts(): PlayableBalanceAmountsType | null {
        return this.possibleBetsRequestState.playableBalanceAmounts ?? null;
    }

    @computed public get placeBetStatus(): string | null {
        return 'status';
    }

    @computed public get betSlipSinglesOnly(): boolean {
        return this.possibleBetsRequestState.isSinglesOnly;
    }

    @computed public get isShowQuickBet(): boolean {
        return this.quickBetState.isShowQuickBetInner;
    }

    @computed public get channel(): ChannelType {
        if (this.quickBetState.isVisibleQuickBet) {
            return 'quickbet';
        }

        const innerWidth = getWindowInnerWidth();

        if (innerWidth !== null) {
            const maxResolutionForKeyBoard = innerWidth < 1024;
            if (maxResolutionForKeyBoard) {
                return 'mobile';
            }
        }

        return 'desktop';
    }

    @computed public get related(): boolean {
        return this.possibleBetsRequestState.isRelated;
    }

    @computed public get relatedOnAdd(): boolean {
        return this.possibleBetsRequestState.isRelatedOnAdd;
    }

    @computed public get focusedSelectionId():
        | BetslipSingleId
        | BetslipCombinationId
        | BetslipRabId
        | BetslipAllSinglesId
        | null {
        return this.focusedSelectionIdInner;
    }

    @computed public get isVisibleKeyBoard(): boolean {
        const innerWidth = getWindowInnerWidth();

        if (innerWidth !== null) {
            const maxResolutionForKeyBoard = innerWidth < 1024;
            return maxResolutionForKeyBoard || this.quickBetState.isVisibleQuickBet;
        }
        return false;
    }

    @computed public get isSinglesOpenForView(): boolean {
        return this.isSinglesOpen;
    }

    @computed public get isMultiplesOpenForView(): boolean {
        return this.isMultiplesOpen;
    }

    @computed public get userData(): BetSlipUserDataType {
        const accountData = this.getAccountData();
        return accountData;
    }
}
