import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';


export const isValidCpf = (cpf: string): boolean => {
    if (/[^\d]+/.test(cpf)) {
        return false;
    }

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false;
    }
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let rest = sum % 11;
    const digit1 = (rest < 2) ? 0 : (11 - rest);
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rest = sum % 11;
    const digit2 = (rest < 2) ? 0 : (11 - rest);
    return (digit1 === parseInt(cpf.charAt(9)) && digit2 === parseInt(cpf.charAt(10)));
};

export const removeDotsAndHyphens = (value: string): string => {
    // Use case: remove signs: '-' and '.'
    return value.replace(/[-.]/g, '');
};

/**
 * validator for Brazilian id number (CPF)
 */
export const validateCpf = (value: string, languagesState?: LanguagesState): Result<string> => {
    const valueSanitized = removeDotsAndHyphens(value);

    if (!isValidCpf(valueSanitized)) {
        const errorMessage = languagesState === undefined ? 'Enter a valid CPF Number' : languagesState.getTranslation('errors.cpf.id', 'Enter a valid CPF Number');
        return Result.createError(errorMessage);
    }

    return Result.createOk(valueSanitized);
};
