import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SingleBetItemState } from 'src/domains/sportsbook/betting/betSlipState/betsState/SingleBetItemState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { PotentialReturns, ReturnsSpan, FreeBetIconWrapper, HelpIconWrapper } from 'src/domains/sportsbook/betting/bettingTab/cashout/selection/Selection.style';
import { BetsFilter } from 'src/domains/sportsbook/betting/state/BetsState';
import { AmountTitle, BetSummaryWrapper } from './BetSummary.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';

interface PropsTypes {
    betItem: SingleBetItemState;
}

export const BetSummary = observer('BetSummary', ({ betItem }: PropsTypes) => {
    const { freeBetCredits, payout, potentialReturns, totalStake, status, legs, isAnyBP, bogBonus, isAllTypeVoid } = betItem;
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { sdkCustomer, starRouter } = appLayoutsState;
    const { getIsBetSP, selectedFilter } = appSportsBookState.betsState;

    const isFreeBet = freeBetCredits.length > 0;
    const getPayoutFowView = (): Amount | null => {
        if (isAnyBP && (selectedFilter === BetsFilter.SETTLED || selectedFilter === BetsFilter.ALL) && bogBonus !== null && !isAllTypeVoid && payout !== null) {
            const newPayout = payout;
            const newBogAmount = bogBonus.amount;

            return newPayout.add(newBogAmount);
        }   
        return payout;
    };

    const potentialReturnValue = (): string => {
        if (getIsBetSP(legs)) {
            return 'n/a';
        } else {
            return potentialReturns === null ? 'n/a' : sdkCustomer.money(potentialReturns);
        }
    };

    return (
        <BetSummaryWrapper>
            <div data-test='bet-summary-stake'>
                <AmountTitle>
                    {
                        isFreeBet
                            ? <I18n langKey='bets.selection.free-bet-stake.label' defaultText='Free Bet Stake:' />
                            : <I18n langKey='bets.selection.stake.label' defaultText='Stake:' />
                    }
                </AmountTitle>

                {isFreeBet ? <FreeBetIconWrapper /> : null}

                <ReturnsSpan>
                    {totalStake === null ? null : sdkCustomer.money(totalStake)}
                </ReturnsSpan>
            </div>

            <div data-test='bet-summary-returns'>
                {
                    status === 'settled' || status === 'cancelled' ? (
                        <>
                            <AmountTitle>
                                <I18n langKey='bets.selection.return.label' defaultText='Return:' />
                            </AmountTitle>
                            <PotentialReturns >
                                {sdkCustomer.money(getPayoutFowView())}
                            </PotentialReturns>
                        </>
                    ) : (
                        <>
                            <AmountTitle>
                                <I18n langKey='bets.selection.potential-returns.label' defaultText='Returns:' />
                                {isFreeBet ? <HelpIconWrapper onClick={starRouter.redirectToFreeBetCreditsInfo} /> : null}
                            </AmountTitle>
                            <PotentialReturns>
                                {potentialReturnValue()}
                            </PotentialReturns>
                        </>
                    )
                }
            </div>
        </BetSummaryWrapper>
    );
});

