import styled from '@emotion/styled';

import { ThumbsIcon } from 'src/domains/layouts/shared/assets/icons/ThumbsIcon';

import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

import { withConfig } from 'src/withConfig';



export const CashOutBtnWrapper = styled(Button, { label: 'CashOutBtnWrapper' })`

    display: block;

    margin: 10px auto 0 !important;

    padding: 15px 10px;

    width: 100%;

`;



export const CashOutSuccessMessage = withConfig(theme => styled('div', { label: 'CashOutSuccessMessage' })`

    background-color: ${theme.star.statuses.success.bgColor};

    color: ${theme.star.statuses.success.txtColor};

    font-size: ${theme.star.fontSize.xSmall};

    line-height: 1.3334;

    padding: 5px 5px 5px 22px;

    position: relative;

`);



export const CashOutThumbsUpIconWrapper = styled(ThumbsIcon, { label: 'CashOutThumbsUpIconWrapper' })`

    fill: currentcolor;

    width: 12px;

    position: absolute;

    top: 5px;

    left: 5px;

`;

