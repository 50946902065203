import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { MessageItem } from 'src/domains/players/webview/components/Account/traderChat/components/Message.style';
import { DotsAnimation } from 'src/domains/players/webview/components/Account/traderChat/components/TypingMessage.style';
import { observer } from 'src/utils/mobx-react';

export const TypingMessage = observer('TypingMessage',() => {
    const { appPlayersState } = useAppStateContext();
    const { isTraderTyping } = appPlayersState.traderChatState;

    if (isTraderTyping) {
        return (
            <MessageItem senderType='staff'>
                <DotsAnimation />
            </MessageItem>
        );
    }

    return null;
});
