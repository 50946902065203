export const europeanEliteTemplate = [
    'Scotland Premier League',
    'England Premier League',
    'Spain Primera Division',
    'Italy Serie A',
    'Germany Bundesliga 1',
    'France Ligue 1',
    'Netherlands Eredivisie',
    'UEFA Champions League',
    'Europa League',
    'UEFA Europa League',
];

export const europeanEliteTemplateMcBookie = [
    'Spain Primera Division',
    'Italy Serie A',
    'France Ligue 1',
    'Germany Bundesliga 1',
    'Portugal Primeira Liga',
    'Netherlands Eredivisie',
    'UEFA Europa League',
    'UEFA Champions League',
];

export const scotlandRegex = [/scotland/, /scottish/];

export const walesTemplateId = [
    'Cymru Premier League',
    'Cymru North',
    'Cymru South',
    'Ardal South East League',
    'Ardal South West League',
    'Ardal North West League',
    'Wales League Cup',
    'Wales Cymru North',
    'Wales Cymru South',
    'Wales League Division 1',
    'Wales Ardal League South East',
    'Wales Ardal League South West',
    'Wales Ardal League North East',
    'Wales Ardal League North West',
    'Wales Ardal League Cup North',
    'Wales Ardal League Cup South',
    'Wales FA Cup',
    'Wales Cup',
    'Wales League Cup',
    'Wales Premier League Play-off',
    'FAW Trophy',
    'FAW Welsh Cup',
];

export const welshFootballTeamTemplate = new Set([
    'Cardiff City',
    'Swansea City',
    'Wrexham',
    'Newport County',
    'Merthyr Town',
]);
