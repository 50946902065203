import React, { useState } from 'react';
import { sortBy, groupBy, reduce } from 'lodash';
import { observable, makeObservable } from 'mobx';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import {
    MoreLessGroup,
    LinkMoreLessWrapper,
    DetailsEachWay,
    SelectionsGroup,
    DetailsEachWayItem,
    SelectionList,
} from './OutrightMarket.style';
import {
    MarketHeaderContent,
    ChevronIconWrapper,
    MarketHeaderTitle,
} from 'src/domains/sportsbook/webview/components/marketHeader/MarketHeader.style';
import {
    SelectionsGroupLiItem,
    SelectionsGroupName,
} from 'src/domains/sportsbook/webview/components/selectionsGroup/SelectionGroup.style';
import {
    MarketGroupWrapper,
    MarketSeparator,
} from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroup.style';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { cut } from './cut';
import { price } from 'src_common/utils/sport/sort';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

const TRUNCATE_ROWS_LIMIT: Record<string, number> = {
    'custom-outrights-market': 4,
};

const HEADER_INFO = (market: MarketModel): JSX.Element | undefined => {
    if (market.eachWay !== undefined && market.eachWay.offered) {
        const terms = market.eachWay.terms[0];
        if (terms !== undefined) {
            return (
                <DetailsEachWay>
                    <DetailsEachWayItem>
                        <I18n langKey='events.outright-market.each-way-terms.label' defaultText='Each Way' />
                    </DetailsEachWayItem>

                    <DetailsEachWayItem>{terms.reduction}</DetailsEachWayItem>

                    <DetailsEachWayItem>
                        <I18n
                            langKey='events.outright-market.each-way-terms.places'
                            defaultText='{places} Places'
                            params={{ places: terms.places }}
                        />
                    </DetailsEachWayItem>
                </DetailsEachWay>
            );
        }

        return undefined;
    }
};

function getToggleButton(opened: boolean, left: number, onClick: () => void): JSX.Element {
    return (
        <MoreLessGroup>
            <LinkMoreLessWrapper onClick={onClick}>
                {opened ? (
                    <>
                        <I18n langKey='events.request-a-bet-selection.more' defaultText='More' /> ({left})
                    </>
                ) : (
                    <I18n langKey='events.request-a-bet-selection.less' defaultText='Less' />
                )}

                <ChevronIconWrapper position={opened ? 'down' : 'up'} />
            </LinkMoreLessWrapper>
        </MoreLessGroup>
    );
}

function getHeaderInfo(market: MarketModel): JSX.Element | undefined {
    return HEADER_INFO(market) ?? undefined;
}

function groupSelections(selections: Record<string, SelectionModel[]>): Record<string, SelectionModel[]> {
    const selectionListNewFirst = Object.values(selections)[0];
    if (selectionListNewFirst === undefined) {
        return {};
    }
    const selectionListNew: SelectionModel[] = sortBy(selectionListNewFirst, price);

    //bug is in here but don't know if we want to fix it
    return groupBy(selectionListNew, (_selection) => {
        return undefined;
        // return selectionListNew.indexOf(selection) % columns;
    });
}

interface PropsType {
    eventId: EventId;
    markets: Array<MarketId>;
    selections: Record<number, SelectionModel[]>;
    isOpen: boolean;
    onClick(): void;
}

class OutrightMarketState {
    @observable public truncate: boolean = true;

    public constructor() {
        makeObservable(this);
    }

    public toggleTruncate = (): void => {
        this.truncate = !this.truncate;
    };
}

const getColumns = (selections: Record<number, SelectionModel[]>): Record<string, Array<SelectionModel>> => {
    return groupSelections(selections);
};

const market = (markets: Array<MarketId>): MarketModel | null => {
    const marketId = markets[0];
    if (marketId !== undefined) {
        return marketId.getModel();
    }
    return null;
};

export const OutrightMarket = observer('OutrightMarket', (props: PropsType) => {
    const [state] = useState(() => new OutrightMarketState());
    const { truncate, toggleTruncate } = state;

    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { configComponents, languagesState } = appLayoutsState;
    const { config } = configComponents;

    const marketModel = market(props.markets);
    if (marketModel === null) {
        return null;
    }

    const columns = getColumns(props.selections);
    const { isOpen, onClick, eventId } = props;
    const isRAB = appSportsBookState.rab.isBetBuilderOpen;
    const rabAvailable = appSportsBookState.rab.isAvailableForEvent(eventId);
    const templateId = marketModel.newTemplateId;
    const numOfSelections = reduce(Object.values(columns), (sum, column) => sum + column.length, 0);
    const rowsLimit = TRUNCATE_ROWS_LIMIT[templateId];

    const left =
        rowsLimit !== undefined && rowsLimit * Object.values(columns).length < numOfSelections
            ? numOfSelections - Object.values(columns).length * rowsLimit
            : undefined;
    const isOpened = rowsLimit === undefined ? true : truncate;
    const truncateButton = left === undefined ? undefined : getToggleButton(isOpened, left, toggleTruncate);

    return (
        <>
            <MarketGroupWrapper className='market-group--outright'>
                <MarketHeaderContent onClick={onClick}>
                    <ChevronIconWrapper position={isOpen ? 'down' : 'right'} />

                    <MarketHeaderTitle>
                        <span>
                            {isRAB === true && rabAvailable === true ? `#${config.rabHeaderTitle} - ` : ''}
                            {languagesState.getMarketTranslationName(marketModel)}
                        </span>
                    </MarketHeaderTitle>
                    {getHeaderInfo(marketModel)}
                </MarketHeaderContent>

                {isOpen ? (
                    <>
                        <SelectionsGroup className='selections-group'>
                            {Object.keys(columns).map((column) => (
                                <SelectionList key={column}>
                                    {cut(columns[column] ?? [], truncate ? rowsLimit ?? 0 : 0).map((selection) => (
                                        <SelectionsGroupLiItem key={selection.id}>
                                            <SelectionsGroupName
                                                title={languagesState.getSelectionTranslationName(selection)}
                                            >
                                                {languagesState.getSelectionTranslationName(selection)}
                                            </SelectionsGroupName>

                                            <Selection
                                                borderBottom={true}
                                                borderLeft={true}
                                                borderRight={true}
                                                selectionId={selection.id2}
                                            />
                                        </SelectionsGroupLiItem>
                                    ))}
                                </SelectionList>
                            ))}
                        </SelectionsGroup>
                        {truncateButton}
                    </>
                ) : null}
            </MarketGroupWrapper>

            {isOpen ? null : <MarketSeparator />}
        </>
    );
});
