import React, { useState } from 'react';
import { SingleKeyButton } from './SingleKeyButton';
import { action, makeObservable } from 'mobx';
import { BetSlipState } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { KEYBOARD_KEYS, KeyboardButtonType, keyFromKeyboardButton } from './config';
import { assertNever } from 'src_common/common/assertNever';
import {
    CustomNumericKeyboard,
    KeyboardDigitWrapper,
    KeyboardActionPanel,
    CustomNumericQuickBetKeyboardWrapper,
    KeyboardBtnIconWrapper,
} from './CustomKeyboardNew.style';

class KeyboardButtonsState {
    private readonly value: KeyboardButtonType;
    private readonly betSlipState: BetSlipState;

    public constructor(value: KeyboardButtonType, betSlipState: BetSlipState) {
        makeObservable(this);
        this.value = value;
        this.betSlipState = betSlipState;
    }

    @action public onClickButton = (): void => {
        const { focusedSelectionId } = this.betSlipState.basicBetSlipState;

        if (focusedSelectionId === null) {
            return;
        }
        const model = focusedSelectionId.getModel();

        if (model !== null) {
            const stakeInput = model.stakeInput;

            if (stakeInput === null) {
                console.error('CustomKeyboard -> onClickButton -> ignore -> stakeInput is null');
            } else {
                switch (this.value.type) {
                    case 'delete':
                        stakeInput.onKeybordDelete();
                        return;
                    case 'dot':
                        stakeInput.onKeybordDot();
                        return;
                    case 'digital':
                        stakeInput.onKeybordNumberKey(this.value.keyCode);
                        return;
                    default:
                        return assertNever('value.type', this.value);
                }
            }
        }
    };
}

const KeyboardButton = observer('KeyboardButton', ({ value }: { value: KeyboardButtonType }) => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const [localState] = useState(() => new KeyboardButtonsState(value, betSlipState));

    return (
        <SingleKeyButton
            value={value}
            handleOnClick={localState.onClickButton}
        />
    );
});

export const KeyBoard = observer('KeyBoard', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { onHideKeyboard } = betSlipState.basicBetSlipState;

    return (
        <>
            <KeyboardActionPanel>
                <KeyboardBtnIconWrapper onClick={onHideKeyboard} />
            </KeyboardActionPanel>

            <KeyboardDigitWrapper
                key='full-keyboard-digits'
                data-test='keyboard-wrapper'
            >
                {KEYBOARD_KEYS.map((elem) => {
                    return (
                        <KeyboardButton
                            key={keyFromKeyboardButton(elem)}
                            value={elem}
                        />
                    );
                })}
            </KeyboardDigitWrapper>
        </>
    );
});

export const CustomKeyboard = observer('CustomKeyboard', ({ isQuickBet }: { isQuickBet: boolean }) => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { focusedSelectionId, isVisibleKeyBoard } = betSlipState.basicBetSlipState;

    if (focusedSelectionId !== null && isVisibleKeyBoard) {
        if (isQuickBet) {
            return (
                <CustomNumericQuickBetKeyboardWrapper>
                    <KeyBoard />
                </CustomNumericQuickBetKeyboardWrapper>
            );
        }
        return (
            <CustomNumericKeyboard>
                <KeyBoard />
            </CustomNumericKeyboard>
        );
    }

    return null;
});
