import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { withConfig } from 'src/withConfig';

interface CheckBoxType {
    isChecked: boolean;
    isDisabled?: boolean;
    isReversed?: boolean;
}

export const InputCheckBox = styled('input', { label: 'InputCheckBox' })`
    display: none;
`;

type CheckBoxLabelType = Omit<CheckBoxType, 'isChecked'>;

export const CheckBoxLabel = styled('label', { label: 'CheckBoxLabel' })<CheckBoxLabelType>`
    flex-flow: ${({ isReversed }): string => isReversed === true ? 'row-reverse nowrap' : 'row nowrap'};
    align-items: center;
    cursor: ${({ isDisabled }): string => isDisabled === true ? 'not-allowed' : 'pointer'};
    display: flex;
    justify-content: space-between;
    position: relative;
    &:active {
        ${({ isDisabled }): string => isDisabled === true ? 'pointer-events: none' : ''};
    }
`;

const checkBoxWrapperColors = (theme: EmotionTheme, isChecked: boolean, isDisabled?: boolean):SerializedStyles => {
    if (isDisabled === true) {
        return css`
            background: ${theme.star.checkbox.light.borderColor};
            border-color: ${theme.star.checkbox.light.borderColor};
        `;
    }

    if (isChecked === true) {
        return css`
            background: ${theme.star.checkbox.light.bgColorSecondary};
            border-color: ${theme.star.checkbox.light.borderColorSecondary};
        `;
    }

    return css`
        background: transparent;
        border-color: ${theme.star.checkbox.light.borderColor};
    `;
};


export const IconWrapper = withConfig(theme => styled('span', { label: 'IconWrapper' })<CheckBoxType>`
    ${({ isChecked, isDisabled }): SerializedStyles => checkBoxWrapperColors(theme, isChecked, isDisabled)};
    align-items: center;
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    display: flex;
    flex: 0 0 16px;
    height: 16px;
    justify-content: center;
    width: 16px;
`);

interface CheckMarkWrapperType {
    isChecked: boolean;
}

export const CheckMarkWrapper = withConfig(theme => styled(CheckSingleIcon, { label: 'CheckMarkWrapper' })<CheckMarkWrapperType>`
    ${({ isChecked }): string => isChecked ? 'transform: scale(1)' : 'transform: scale(0)'};
    fill: ${theme.star.checkbox.bgColor};
    transition: transform .2s ease;
    width: 10px;
`);
