import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SocketGameDetails, SocketGameList } from 'src/domains/casino/shared/Types';
import { GroupBody, GroupHeader, GroupWrapper, JsonWrapper } from 'src/domains/layouts/webview/components/debugPanel/DebugPanel.style';

interface RenderGamePropsType {
    tableId: string;
    socketGameDetails: SocketGameDetails;
}

const RenderGame = observer('RenderGame', (props: RenderGamePropsType) => {
    const { tableId, socketGameDetails } = props;

    const model = socketGameDetails.getModel(tableId);

    if (model === null) {
        return (
            <div>
                tableId={tableId} - Loading ...
            </div>
        );
    }

    return (
        <GroupWrapper>
            <GroupHeader>TableId: {tableId}</GroupHeader>
            <GroupBody>{JSON.stringify(model.getData())}</GroupBody>
        </GroupWrapper>
    );
});


const renderListDetails = (list: Array<string>, socketGameDetails: SocketGameDetails): Array<React.ReactElement> => {
    const out = [];

    for (const tableId of list) {
        out.push(
            <RenderGame key={tableId} tableId={tableId} socketGameDetails={socketGameDetails} />
        );
    }

    return out;
};

interface PropsType {
    label: string;
    socketGameList: SocketGameList;
    socketGameDetails: SocketGameDetails;
}

export const RTLPragmaticDebugDetails = observer('RTLPragmaticDebugDetails', (props: PropsType): React.ReactElement => {
    const { socketGameList, socketGameDetails } = props;

    const list = socketGameList.list;

    if (list === null) {
        return <div>Loading ...</div>;
    }

    const label = `Tables list, host: ${socketGameList.host} casinoId: ${socketGameList.casinoId}`;

    return (
        <div>
            <GroupWrapper>
                <GroupHeader>{label}</GroupHeader>

                <GroupBody>
                    <JsonWrapper>
                        { JSON.stringify(list)}
                    </JsonWrapper>
                </GroupBody>
            </GroupWrapper>
            { renderListDetails(list, socketGameDetails) }
        </div>
    );    
});
