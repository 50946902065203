import * as React from 'react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import { IframeStandardDesktop, ScoreBoardWrapper } from 'src/domains/sportsbook/webview/components/scoreboards/standard/ScoreboardStandard.style';
import { UniverseVersion } from 'src/domains/common/UniverseVersion';
import { UniverseType } from 'src_common/common/universe';

interface UrlFootbalParams {
    eventId: string,
    modeId: string,
    universe: UniverseType,
}

const getFootballUrl = (params: UrlFootbalParams): string => {
    const { eventId, modeId, universe } = params;
    return `//scoreboards.sportingsolutions.com/football.html#event_id=${eventId}&mode=${modeId}&customer=${universe}&feed=extended`;
};

interface PropsType {
    event: EventModel,
}

export const FootballScoreboard = observer('FootballScoreboard', ({ event }: PropsType) => {
    const { feedGroupId } = event;

    if (feedGroupId !== null && feedGroupId !== undefined) {
        const modeId = 'desktop';

        return (
            <UniverseVersion>
                {(universe: UniverseType): React.ReactElement => {
                    const url = getFootballUrl({
                        eventId: feedGroupId,
                        modeId,
                        universe
                    });

                    return (
                        <ScoreBoardWrapper>
                            <IframeStandardDesktop src={url} key={feedGroupId} />
                        </ScoreBoardWrapper>
                    );
                }}
            </UniverseVersion>
        );
    }

    return null;
});
