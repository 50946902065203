import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface PriceBoostIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const PriceBoostIcon = observer('PriceBoostIcon', ({ className, onClick }: PriceBoostIconPropsType): JSX.Element => (
    <SvgElement
        className={className}
        fill='#072d4f' 
        height='24'
        onClick={onClick}
        viewBox='0 0 24 24'
        width='24'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fill='fill'
            fillRule='evenodd'
            d='m3.64 17.96 1.66 1.72 7.03.27 3.12-5.4 2.83 1.65-.25-12.27-1.85-1.06L5.22 8.66l2.84 1.63-4.42 7.67ZM7.42 8.8 9.4 9.93l-4.3 7.41 6.05.04 2.79-4.82 1.99 1.15-.23-9.36-8.3 4.44Z' clipRule='evenodd'/>
    </SvgElement>
));
