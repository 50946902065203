import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const TermsWrapper = withConfig(theme => styled('div', { label: 'TermsWrapper' })`
    display: flex;
    justify-content: flex-end;
    margin: 0;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    padding: 0 8px 0 8px;
    white-space: nowrap;
`);

export const SingleTermsWrapper = styled('span', { label: 'SingleTermsWrapper' })`
    margin-right: 5px;
`;

export const MarketHeaderTitle = withConfig(theme => styled('h4', { label: 'MarketHeaderTitle' })`
    display: flex;
    align-items: center;
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0;
    max-width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        max-width: calc(100% - 68px);
    }
`);

export const MarketHeaderContent = withConfig(theme => styled('header', { label: 'MarketHeaderContent' })`
    align-items: center;
    background: ${theme.star.headings.market.bgColor};
    color: ${theme.star.headings.market.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    min-height: 24px;
    @media ${theme.star.mediaQuery.tablet} {
        height: 28px;
    }
`);

export const ChevronIconWrapper = styled(ChevronIcon, { label: 'ChevronIconWrapper' })`
    fill: currentcolor;
    flex: 0 0 12px;
    margin: 0 8px;
    width: 12px;
`;
