import { z } from 'zod';

export const InputSchema = z.object({
    provider: z.string(),
});

export const SavedCard = z.object({
    expires: z.string().nullable().optional(),
    id: z.string(),
    lastUsedAt: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    number: z.string(),
    provider: z.string(),
    providerReference: z.string().nullable().optional(),
    subtype: z.string().nullable().optional(),
    type: z.string(),
});

export type SavedCardInterface = z.infer<typeof SavedCard>;

export const SuccessResponseSchema = z.array(SavedCard);

export type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    error: z.object({
        code: z.string().optional().nullable(),
        message: z.string().optional().nullable(),
    }),
});

export const TrpcErrorResponseSchema = z.object({
    message: z.string().optional().nullable(),
});

type TErrorResponse = z.infer<typeof TrpcErrorResponseSchema>;

export type TResponseType =
    | { responseStatus: 'success'; response: TSuccessResponse }
    | { responseStatus: 'error'; data: TErrorResponse };
