import React from 'react';
import { UniverseComponent } from 'src/domains/common/universes';
import { observer } from 'src/utils/mobx-react';

export const SignUpIcons = observer('SignUpIcon', (): JSX.Element => {
    return (
        <UniverseComponent>
            {(universeModule): React.ReactElement => <universeModule.signUpIcon />}
        </UniverseComponent>
    );
});
