import bgAustralianRules from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgAustralianRules.png';
import bgBadminton from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgBadminton.png';
import bgBoxing from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgBoxing.png';
import bgCricket from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgCricket.png';
import bgCycling from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgCycling.png';
import bgDarts from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgDarts.png';
import bgESports from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgESports.png';
import bgFootball from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgFootball.png';
import bgFormulaOne from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgFormulaOne.png';
import bgGaelicFootball from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgGaelicFootball.png';
import bgGaelicHurling from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgGaelicHurling.png';
import bgGolf from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgGolf.png';
import bgGreyhoundRacing from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgGreyhoundRacing.png';
import bgHandball from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgHandball.png';
import bgHorseRacing from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgHorseRacing.png';
import bgMixedMartialArts from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgMixedMartialArts.png';
import bgMLB from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgMLB.png';
import bgMotorSport from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgMotorSport.png';
import bgNBA from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgNBA.png';
import bgNFL from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgNFL.png';
import bgNHL from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgNHL.png';
import bgOlympicsSummer from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgOlympicsSummer.png';
import bgOther from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgOther.png';
import bgPolitics from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgPolitics.png';
import bgRugbyLeague from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgRugbyLeague.png';
import bgRugbyUnion from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgRugbyUnion.png';
import bgSnooker from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgSnooker.png';
import bgTableTennis from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgTableTennis.png';
import bgTennisDoubles from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgTennisDoubles.png';
import bgTennis from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgTennis.png';
import bgVolleyball from 'src/domains/layouts/shared/assets/backgrounds/sports/desktop/bgVolleyball.png';

import bgAustralianRulesMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgAustralianRulesMobile.png';
import bgBadmintonMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgBadmintonMobile.png';
import bgBoxingMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgBoxingMobile.png';
import bgCricketMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgCricketMobile.png';
import bgCyclingMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgCyclingMobile.png';
import bgDartsMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgDartsMobile.png';
import bgESportsMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgESportsMobile.png';
import bgFootballMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgFootballMobile.png';
import bgFormulaOneMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgFormulaOneMobile.png';
import bgGaelicFootballMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgGaelicFootballMobile.png';
import bgGaelicHurlingMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgGaelicHurlingMobile.png';
import bgGolfMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgGolfMobile.png';
import bgGreyhoundRacingMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgGreyhoundRacingMobile.png';
import bgHandballMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgHandballMobile.png';
import bgHorseRacingMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgHorseRacingMobile.png';
import bgMixedMartialArtsMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgMixedMartialArtsMobile.png';
import bgMLBMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgMLBMobile.png';
import bgMotorSportMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgMotorSportMobile.png';
import bgNBAMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgNBAMobile.png';
import bgNFLMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgNFLMobile.png';
import bgNHLMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgNHLMobile.png';
import bgOlympicsSummerMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgOlympicsSummerMobile.png';
import bgOtherMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgOtherMobile.png';
import bgPoliticsMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgPoliticsMobile.png';
import bgRugbyLeagueMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgRugbyLeagueMobile.png';
import bgRugbyUnionMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgRugbyUnionMobile.png';
import bgSnookerMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgSnookerMobile.png';
import bgTableTennisMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgTableTennisMobile.png';
import bgTennisDoublesMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgTennisDoublesMobile.png';
import bgTennisMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgTennisMobile.png';
import bgVolleyballMobile from 'src/domains/layouts/shared/assets/backgrounds/sports/mobile/bgVolleyballMobile.png';

const HEADER_DEFAULT = {
    desktopImage: bgFootball,
    tabletImage: bgFootball,
    mobileImage: bgFootballMobile,
    color: '#121524',
};

export interface HeaderOptionItemType {
    desktopImage: string | null | undefined;
    tabletImage: string | null | undefined;
    mobileImage: string | null | undefined;
}

const getHeaderOptions = (): Record<string, HeaderOptionItemType> => {
    const hasBackgroundImage = true;

    return {
        americanfootball: {
            desktopImage: hasBackgroundImage ? bgNFL : null,
            tabletImage: hasBackgroundImage ? bgNFL : null,
            mobileImage: hasBackgroundImage ? bgNFLMobile : null,
        },
        australianrules: {
            desktopImage: hasBackgroundImage ? bgAustralianRules : null,
            tabletImage: hasBackgroundImage ? bgAustralianRules : null,
            mobileImage: hasBackgroundImage ? bgAustralianRulesMobile : null,
        },
        baseball: {
            desktopImage: hasBackgroundImage ? bgMLB : null,
            tabletImage: hasBackgroundImage ? bgMLB : null,
            mobileImage: hasBackgroundImage ? bgMLBMobile : null,
        },
        basketball: {
            desktopImage: hasBackgroundImage ? bgNBA : null,
            tabletImage: hasBackgroundImage ? bgNBA : null,
            mobileImage: hasBackgroundImage ? bgNBAMobile : null,
        },
        boxing: {
            desktopImage: hasBackgroundImage ? bgBoxing : null,
            tabletImage: hasBackgroundImage ? bgBoxing : null,
            mobileImage: hasBackgroundImage ? bgBoxingMobile : null,
        },
        cricket: {
            desktopImage: hasBackgroundImage ? bgCricket : null,
            tabletImage: hasBackgroundImage ? bgCricket : null,
            mobileImage: hasBackgroundImage ? bgCricketMobile : null,
        },
        darts: {
            desktopImage: hasBackgroundImage ? bgDarts : null,
            tabletImage: hasBackgroundImage ? bgDarts : null,
            mobileImage: hasBackgroundImage ? bgDartsMobile : null,
        },
        football: {
            desktopImage: hasBackgroundImage ? bgFootball : null,
            tabletImage: hasBackgroundImage ? bgFootball : null,
            mobileImage: hasBackgroundImage ? bgFootballMobile : null,
        },
        gaelicfootball: {
            desktopImage: hasBackgroundImage ? bgGaelicFootball : null,
            tabletImage: hasBackgroundImage ? bgGaelicFootball : null,
            mobileImage: hasBackgroundImage ? bgGaelicFootballMobile : null,
        },
        gaelichurling: {
            desktopImage: hasBackgroundImage ? bgGaelicHurling : null,
            tabletImage: hasBackgroundImage ? bgGaelicHurling : null,
            mobileImage: hasBackgroundImage ? bgGaelicHurlingMobile : null,
        },
        golf: {
            desktopImage: hasBackgroundImage ? bgGolf : null,
            tabletImage: hasBackgroundImage ? bgGolf : null,
            mobileImage: hasBackgroundImage ? bgGolfMobile : null,
        },
        handball: {
            desktopImage: hasBackgroundImage ? bgHandball : null,
            tabletImage: hasBackgroundImage ? bgHandball : null,
            mobileImage: hasBackgroundImage ? bgHandballMobile : null,
        },
        icehockey: {
            desktopImage: hasBackgroundImage ? bgNHL : null,
            tabletImage: hasBackgroundImage ? bgNHL : null,
            mobileImage: hasBackgroundImage ? bgNHLMobile : null,
        },
        motorsport: {
            desktopImage: hasBackgroundImage ? bgMotorSport : null,
            tabletImage: hasBackgroundImage ? bgMotorSport : null,
            mobileImage: hasBackgroundImage ? bgMotorSportMobile : null,
        },
        rugbyleague: {
            desktopImage: hasBackgroundImage ? bgRugbyLeague : null,
            tabletImage: hasBackgroundImage ? bgRugbyLeague : null,
            mobileImage: hasBackgroundImage ? bgRugbyLeagueMobile : null,
        },
        rugbyunion: {
            desktopImage: hasBackgroundImage ? bgRugbyUnion : null,
            tabletImage: hasBackgroundImage ? bgRugbyUnion : null,
            mobileImage: hasBackgroundImage ? bgRugbyUnionMobile : null,
        },
        snooker: {
            desktopImage: hasBackgroundImage ? bgSnooker : null,
            tabletImage: hasBackgroundImage ? bgSnooker : null,
            mobileImage: hasBackgroundImage ? bgSnookerMobile : null,
        },
        tennis: {
            desktopImage: hasBackgroundImage ? bgTennis : null,
            tabletImage: hasBackgroundImage ? bgTennis : null,
            mobileImage: hasBackgroundImage ? bgTennisMobile : null,
        },
        formulaone: {
            desktopImage: hasBackgroundImage ? bgFormulaOne : null,
            tabletImage: hasBackgroundImage ? bgFormulaOne : null,
            mobileImage: hasBackgroundImage ? bgFormulaOneMobile : null,
        },
        greyhoundracing: {
            desktopImage: hasBackgroundImage ? bgGreyhoundRacing : null,
            tabletImage: hasBackgroundImage ? bgGreyhoundRacing : null,
            mobileImage: hasBackgroundImage ? bgGreyhoundRacingMobile : null,
        },
        horseracing: {
            desktopImage: hasBackgroundImage ? bgHorseRacing : null,
            tabletImage: hasBackgroundImage ? bgHorseRacing : null,
            mobileImage: hasBackgroundImage ? bgHorseRacingMobile : null,
        },
        tabletennis: {
            desktopImage: hasBackgroundImage ? bgTableTennis : null,
            tabletImage: hasBackgroundImage ? bgTableTennis : null,
            mobileImage: hasBackgroundImage ? bgTableTennisMobile : null,
        },
        tennisdoubles: {
            desktopImage: hasBackgroundImage ? bgTennisDoubles : null,
            tabletImage: hasBackgroundImage ? bgTennisDoubles : null,
            mobileImage: hasBackgroundImage ? bgTennisDoublesMobile : null,
        },
        badminton: {
            desktopImage: hasBackgroundImage ? bgBadminton : null,
            tabletImage: hasBackgroundImage ? bgBadminton : null,
            mobileImage: hasBackgroundImage ? bgBadmintonMobile : null,
        },
        cycling: {
            desktopImage: hasBackgroundImage ? bgCycling : null,
            tabletImage: hasBackgroundImage ? bgCycling : null,
            mobileImage: hasBackgroundImage ? bgCyclingMobile : null,
        },
        politics: {
            desktopImage: hasBackgroundImage ? bgPolitics : null,
            tabletImage: hasBackgroundImage ? bgPolitics : null,
            mobileImage: hasBackgroundImage ? bgPoliticsMobile : null,
        },
        tvspecials: {
            desktopImage: hasBackgroundImage ? bgOther : null,
            tabletImage: hasBackgroundImage ? bgOther : null,
            mobileImage: hasBackgroundImage ? bgOtherMobile : null,
        },
        volleyball: {
            desktopImage: hasBackgroundImage ? bgVolleyball : null,
            tabletImage: hasBackgroundImage ? bgVolleyball : null,
            mobileImage: hasBackgroundImage ? bgVolleyballMobile : null,
        },
        esports: {
            desktopImage: hasBackgroundImage ? bgESports : null,
            tabletImage: hasBackgroundImage ? bgESports : null,
            mobileImage: hasBackgroundImage ? bgESportsMobile : null,
        },
        mixedmartialarts: {
            desktopImage: hasBackgroundImage ? bgMixedMartialArts : null,
            tabletImage: hasBackgroundImage ? bgMixedMartialArts : null,
            mobileImage: hasBackgroundImage ? bgMixedMartialArtsMobile : null,
        },
        olympics: {
            desktopImage: hasBackgroundImage ? bgOlympicsSummer : null,
            tabletImage: hasBackgroundImage ? bgOlympicsSummer : null,
            mobileImage: hasBackgroundImage ? bgOlympicsSummerMobile : null,
        },
    };
};

function getDefaultHeaderOptions(): HeaderOptionItemType {
    return HEADER_DEFAULT;
}

export { getHeaderOptions, getDefaultHeaderOptions };
