import { observable, action, computed, makeObservable } from 'mobx';
import { ReferencePointType } from 'src/domains/layouts/state/cmsState/homePageCarouselState/HomePageCarouselState';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';

type directionType = 'left' | 'right';
const getCurrentTime = (): number => new Date().getTime();

const createCurrentTime = (): MobxValue<number> => {
    return MobxValue.create({
        initValue: getCurrentTime(),
        connect: {
            connect: (self: MobxValue<number>): NodeJS.Timeout => {
                self.setValue(getCurrentTime());

                const timer = setInterval(() => {
                    self.setValue(getCurrentTime());
                }, 6000);

                return timer;
            },
            dispose: (timer: NodeJS.Timeout): void => {
                clearInterval(timer);
            }
        }
    });
};


export class SliderState {
    @observable private touchStartX: number;
    @observable private touchEndX: number;

    @observable.ref private currentTime: MobxValue<number>;
    @observable.ref private referencePoint: ReferencePointType;
    
    public constructor(private readonly itemsLength: number ) {
        makeObservable(this);
        this.touchStartX = 0;
        this.touchEndX = 0;
        this.referencePoint = {
            time: getCurrentTime(),
            slide: 0
        };
        this.currentTime = createCurrentTime();
    }

    @computed public get crt(): number {
        return Math.round(Math.abs((this.currentTime.getValue() - this.referencePoint.time) / 6000)) ;
    }

    @computed public get currentSlide(): number {
        return (this.referencePoint.slide +this.crt) % this.itemsLength;
    }

    @action public swipeStart = (touchX: number): void => {
        this.touchStartX = touchX;
    };

    @action public swipeEnd = (touchX: number, goLeftAvailable: boolean, goRightAvailable: boolean): void => {
        this.touchEndX = touchX;
        if (this.touchEndX > this.touchStartX && goLeftAvailable) this.slideInDirection('left');
        if (this.touchEndX < this.touchStartX && goRightAvailable) this.slideInDirection('right');
    };


    @action public setCurrentSlide = (newSlide: number): void => {
        this.currentTime = createCurrentTime();
        this.referencePoint = {
            time: getCurrentTime(),
            slide: newSlide
        };
    };

    @action public slideWithDot = (dotIndex: number): void => {
        const indexOffset = dotIndex - this.currentSlide;
        this.setCurrentSlide(this.currentSlide + indexOffset);
    };

    @action public slideInDirection = (direction: directionType): void => {
        const indexOffset = direction === 'left' ? -1 : 1;
        this.setCurrentSlide(this.currentSlide + indexOffset);
    };

}
