import { CasinoTableModel } from 'src/domains/casino/state/AppCasino/casino-sockets/real-time-lobby-pragmatic-socket/CasinoTableModel';
import { RealTimeLobbyGameModel } from 'src/domains/casino/state/AppCasino/casino-sockets/real-time-lobby-socket/RealTimeLobbyGameModel';
import { RouletteColorType, RouletteNumberType } from 'src/domains/casino/utils/types';
import { Amount } from 'src_common/common/amount/Amount';

export class GameDetailsCommonModel {
    private gameDetails: CasinoTableModel | RealTimeLobbyGameModel;

    public constructor(gameDetails: CasinoTableModel | RealTimeLobbyGameModel) {
        this.gameDetails = gameDetails;
    }

    public get tableName(): string | null {
        if (this.gameDetails instanceof CasinoTableModel) {
            return this.gameDetails.tableName ?? null;
        }
        return null; // tableName should be taken from gameModel.name
    }

    public get dealerName(): string {
        if (this.gameDetails instanceof CasinoTableModel) {
            return this.gameDetails.dealerName;
        }
        return this.gameDetails.dealerName;
    }

    public get hasBetLimits(): boolean {
        if (this.gameDetails instanceof CasinoTableModel) {
            return true;
        }
        return this.gameDetails.hasBetLimits;
    }

    public get minBet(): Amount {
        if (this.gameDetails instanceof CasinoTableModel) {
            return this.gameDetails.tableLimits.minBet;
        }
        return this.gameDetails.minBet;
    }

    public get maxBet(): Amount {
        if (this.gameDetails instanceof CasinoTableModel) {
            return this.gameDetails.tableLimits.maxBet;
        }
        return this.gameDetails.maxBet;
    }

    public get currentSeats(): number | null {
        if (this.gameDetails instanceof CasinoTableModel) {
            return this.gameDetails.availableSeats.current ?? null;
        }
        return this.gameDetails.seatsTaken;
    }

    public get maxSeats(): number | null {
        if (this.gameDetails instanceof CasinoTableModel) {
            return this.gameDetails.availableSeats.max ?? null;
        }
        return this.gameDetails.seatsTotal;
    }

    private getRouletteColor = (rouletteNumber: string): RouletteColorType => {
        const rouletteColors = {
            red: ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'],
            black: ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'],
            green: ['0', '00'],
        };
    
        for (const [key, numbers] of Object.entries(rouletteColors)) {
            const includesNumber = numbers.includes(rouletteNumber);
    
            if (includesNumber === true) {
                if (key === 'red') {
                    return 'red';
                }
                if (key === 'black') {
                    return 'black';
                }
                if (key === 'green') {
                    return 'green';
                }

                console.warn(`getRouletteColor - could not match ${key} color`);
            };
        }
        return 'black';
    };

    public get rouletteNumbers(): Array<RouletteNumberType> | null {
        if (this.gameDetails instanceof CasinoTableModel) {
            return this.gameDetails.last5Result ?? null;
        }

        const last5Numbers = this.gameDetails.last5Numbers;

        if (last5Numbers === null) {
            return null;
        }

        const rouletteNumbers: Array<RouletteNumberType> = last5Numbers.map(number => ({
            result: number,
            emphasized: null,
            color: this.getRouletteColor(number),
        }));

        return rouletteNumbers;
    }

}
