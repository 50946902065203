import React, { ReactElement } from 'react';
import { BetLeg } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet.style';
import { observer } from 'src/utils/mobx-react';
import { BetCombinedLegResult } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetCombinedLegResult';
import { BetLegTitle } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetLegTitle';
import { BetEachWayInfo } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetEachWayInfo';
import { BetRule4DeductionInfo } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetRule4DeductionInfo';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface PropsType {
    bet: BetHistoryType,
}

export const BetLegs = observer<PropsType>('BetLegs', ({ bet }): ReactElement | null => {

    return (
        <>
            {
                bet.legs.map(leg => (
                    <BetLeg key={leg.id} isSingle={false}>
                        <BetLegTitle leg={leg} isSingle={false} />
                        <BetCombinedLegResult leg={leg} />
                        <BetRule4DeductionInfo leg={leg} />
                        <BetEachWayInfo bet={bet} leg={leg} />
                    </BetLeg>
                ))
            }
        </>
    );
});

