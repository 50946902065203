import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { CarouselEventNavigationCompetition } from './CarouselEventNavigationCompetition';
import { CarouselEventNavigationEvent } from './CarouselEventNavigationEvent';
import { CarouselEventAutoMap } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigationState';
import { EventNavigationDropDownWrapper } from './CarouselSelection.style';
import { CompetitionRouteType, EventRouteType, SportRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

interface PropsType {
    currentView: SportRouteType | CompetitionRouteType | EventRouteType;
}

export const CarouselEventNavigation = observer('CarouselEventNavigation', (props: PropsType) => {
    const appState = useAppStateContext();
    const [stateMap] = React.useState(() => new CarouselEventAutoMap(appState));
    const state = stateMap.get(props.currentView);

    return (
        <EventNavigationDropDownWrapper>
            <CarouselEventNavigationCompetition state={state} />
            <CarouselEventNavigationEvent state={state} isActiveCompetition={state.competitionState.active} />
        </EventNavigationDropDownWrapper>
    );
});
