import { EventsCollectionQueryType, EventsCollectionQueryFilterMarketType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { DateTime } from 'src_common/utils/time/time';

export const getQueryListInPlay = (): EventsCollectionQueryType => ({
    state: 'open',
    'market.display': true,
    'timeSettings.tradedInPlay': 'true',
    sportNot: ['horseracing', 'greyhoundracing'],
    time: 'in-play',
});

export const getQueryListUpcoming = (): EventsCollectionQueryType => ({
    state: 'open',
    'market.display': true,
    'timeSettings.tradedInPlay': undefined,
    sportNot: ['horseracing', 'greyhoundracing'],
    time: 'upcoming',
});

export const getQueryListNextOffRacing = (sport: 'horseracing' | 'greyhoundracing'): EventsCollectionQueryType => {
    return {
        sport: [sport],
        started: false,
        time: 'next-off',
        'market.display': true,
        state: 'open',
    };
};

export const getQueryListRacingForWeek = (sport: 'horseracing' | 'greyhoundracing'): EventsCollectionQueryType => {
    const from = DateTime.current().startOfDays().toISOString();
    const to = DateTime.current().addWeeks(1).startOfDays().toISOString();

    return {
        sport: [sport],
        'startTime[from]': from,
        'startTime[to]': to,
    };
};

export const getQueryListNextOffRacingWithStarted = (sport: 'horseracing' | 'greyhoundracing'): EventsCollectionQueryType => {
    return {
        sport: [sport],
        time: 'next-off',
        'market.display': true,
        state: 'open',
    };
};

export const getQueryListForSport = (sport: string, filterMarket?: EventsCollectionQueryFilterMarketType): EventsCollectionQueryType => ({
    'market.display': true,
    state: 'open',
    sport: [sport],
    filterMarket
});

export const getQueryListForSportWithMonthRange = (
    sport: string,
    filterMarket?: EventsCollectionQueryFilterMarketType
): EventsCollectionQueryType => {
    const from = DateTime.current().startOfDays().toISOString();
    const to = DateTime.current().addMonths(1).startOfDays().toISOString();

    return {
        'market.display': true,
        state: 'open',
        sport: [sport],
        filterMarket,
        'startTime[from]': from,
        'startTime[to]': to,
    };
};

export const getQueryListForEventsByTemplate = (templateId: string): EventsCollectionQueryType => ({
    'market.display': true,
    state: 'open',
    filterMarket: {
        templateId
    }
});

export const getQueryListAntePost = (sport: string): EventsCollectionQueryType => {
    const today = DateTime.current().startOfDays().toISOString();

    return ({
        'state': 'open',
        'market.display': true,
        // 'market.ante-post': 'yes',
        'startTime[from]': today,
        sport: [sport],
    });
};

export const getQueryListHorseracing = (): EventsCollectionQueryType => {
    const today = DateTime.current().startOfDays().toISOString();

    return ({
        'state': 'open',
        'market.display': true,
        'startTime[from]': today,
        sport: ['horseracing'],
    });
};

export const getListFromSearch = (searchText:string): EventsCollectionQueryType => ({
    'market.display': true,
    q: searchText,
    state: 'open',
    time: 'search-time'
});

export const getQueryListWithCompetitions = (competitionIds: Array<number>): EventsCollectionQueryType => ({
    'market.display': true,
    competitionsIds: competitionIds,
    state: 'open',
});
