import React, { useEffect } from 'react';
import { MeetingsEvent } from './MeetingsEvent';
import { observable, makeObservable } from 'mobx';
import { MeetingsRowEvents } from 'src/domains/sportsbook/webview/components/meetingsRow/MeetingsRow.style';
import { observer } from 'src/utils/mobx-react';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { HorseRacingSelectedNew } from 'src/domains/sportsbook/webview/components/racing/NewRacingPage.state';

interface PropsTypes {
    events: Array<EventId>;
    selected: HorseRacingSelectedNew;
    isBuilding: boolean;
    isOpen?: boolean;
    onChoose?: (eventId: EventId) => void;
    accountHasBP?: boolean;
}

class State {
    @observable public ref: HTMLElement | null = null;

    public setRef = (ref: HTMLElement | null): void => {
        this.ref = ref;
    };

    public onScroll = (): void => {
        if (this.ref !== null) {
            // scroll left so it flash and show that scroll exist on small screens
            this.ref.scrollLeft = 1;
        }
    };

    public constructor() {
        makeObservable(this);
    }
}

const MeetingsRow = observer('MeetingsRow',(props: PropsTypes) => {
    const [state] = React.useState(() => new State());
    const { events, selected, isBuilding, isOpen, onChoose } = props;
    const isOpened = isOpen === undefined ? true : isOpen;

    useEffect(state.onScroll, []);

    return (
        <MeetingsRowEvents ref={state.setRef} isOpen={isOpened}>
            {
                events.map((eventId: EventId) => {
                    return (
                        <MeetingsEvent
                            key={eventId.toOldId()}
                            eventId={eventId}
                            selected={selected.isSelected(eventId) /*[eventId] ?? false*/}
                            isBuilding={isBuilding}
                            onChoose={onChoose}
                            data-test-event-id={eventId.toOldId()}
                        />
                    );
                })
            }
        </MeetingsRowEvents>
    );
});

// eslint-disable-next-line import/no-default-export
export default MeetingsRow;
