import * as Sentry from '@sentry/react';

export class Logger {
    public static captureError(
        msg: string,
        domain: 'Sportsbook' | 'internal' | 'Players',
        context?: Record<string, unknown>
    ): void {
        Sentry.withScope((scope) => {
            scope.setLevel('error');
            Sentry.captureException(msg, {
                tags: {
                    Domain: domain,
                },
                contexts: {
                    'Extra data': context,
                },
            });
        });
    }
}
