import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { EventLoader } from 'src/domains/sportsbook/webview/components/loaders/EventLoader';
import { FiltersLoader, SecondaryHeaderLoader } from 'src/domains/layouts/shared/loaders';

export const EventsTableSportLoader = observer('EventsTableSportLoader', (): JSX.Element => {
    const threeEventRows = [];
    const twoEventRows = [];

    for (let i = 0; i < 3; i++) {
        threeEventRows.push(<EventLoader key={i} />);
    }

    for (let i = 0; i < 2; i++) {
        twoEventRows.push(<EventLoader key={i} />);
    }

    return (
        <>
            <SecondaryHeaderLoader size='sportsFilters' />
            <FiltersLoader />
            <SecondaryHeaderLoader size='small' />
            {threeEventRows}
            <SecondaryHeaderLoader size='small' />
            {threeEventRows}
            <SecondaryHeaderLoader size='small' />
            {twoEventRows}
            <SecondaryHeaderLoader size='small' />
            {twoEventRows}
        </>
    );
});
