import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { RaceSportType, RacesSliderState } from './RacesSlider.state';
import { RacingFilters } from 'src/domains/sportsbook/webview/components/racingFilters/RacingFilters';
import { NextOffToggle } from 'src/domains/layouts/webview/components/NextOffToggle';
import { RacesSliderList } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSliderList';
import {
    Header,
    RacesSliderSection,
} from './RacesSlider.style';
import { RacesSliderListState } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSliderList.state';

interface PropsType {
    racesSliderState: RacesSliderState,
    isRacingToggle: boolean,
    mainPage: boolean,
    sport: RaceSportType,
    racesSliderListState: RacesSliderListState,
}

export const RacesSlider = observer('RacesSlider', (props: PropsType) => {
    const { config } = useAppStateContext();
    const { racesSliderState, isRacingToggle, sport, racesSliderListState } = props;
    const eventsFilterState = racesSliderState.eventsCollection.eventsFilterState;
    const listKey = racesSliderState.eventsCollection.listKey;

    const eventsForView = props.racesSliderState.eventsCollection.eventsForView;
    return (
        <RacesSliderSection className='races-slider'>
            {eventsForView === 'loading' ?
                null :
                <Header isRacingToggle={isRacingToggle}>
                    {
                        config.racingNextOffToggle && isRacingToggle
                            ? <NextOffToggle state={racesSliderState} />
                            : null
                    }
                    <RacingFilters key={sport} eventsFilter={eventsFilterState} />
                </Header> }
            <RacesSliderList key={listKey} racesSliderListState={racesSliderListState} sport={sport} events={eventsForView} />
        </RacesSliderSection>
    );
});
