import { AppState } from 'src/appState/AppState';

interface BetsConfigTypes {
    [key: string]: string;
}

export const getBetsTranslation = (appState: AppState, type: string): string | undefined => {
    const betsConfig: BetsConfigTypes = {
        SGL: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.sgl', 'Single'),
        RAB: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.rab', 'YouDecide'),
        TTW: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttw', 'Tote Win'),
        TTP: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttp', 'Tote Place'),
        DBL: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.dbl', 'Double'),
        FC: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.fc', 'Forecast'),
        RFC: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.rfc', 'Reverse Forecast'),
        SSA: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ssa', 'Single Stakes About'),
        TTE: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.tte', 'Exacta'),
        TTRE: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttre', 'Reverse Exacta'),
        TBL: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.tbl', 'Treble'),
        '2F3': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f3', 'Doubles from 3'),
        TRX: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.trx', 'Trixie'),
        PAT: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.pat', 'Patent'),
        RR: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.rr', 'Round Robin'),
        TC: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.tc', 'Tricast'),
        CFC: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.cfc', 'Combination Forecast'),
        CTC: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ctc', 'Combination Tricast'),
        TTT: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttt', 'Trifecta'),
        TTCE: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttce', 'Combination Exacta'),
        TTCT: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttct', 'Combination Trifecta'),
        '4F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.4f', '4 fold'),
        '2F4': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f4', 'Doubles from 4'),
        '3F4': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f4', 'Trebles from 4'),
        YNK: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ynk', 'Yankee'),
        YAN: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.yan', 'Yankee'),
        L15: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.l15', 'Lucky 15'),
        FLG: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.flg', 'Flag'),
        TTQ: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttq', 'Quadpot'),
        '5F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.5f', '5 fold'),
        '2F5': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f5', 'Doubles from 5'),
        '3F5': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f5', 'Trebles from 5'),
        '4F5': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.4f5', '4 folds from 5'),
        CAN: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.can', 'Canadian'),
        L31: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.l31', 'Lucky 31'),
        SFLG: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.sflg', 'Super Flag'),
        '6F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.6f', '6 fold'),
        '2F6': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f6', 'Doubles from 6'),
        '3F6': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f6', 'Trebles from 6'),
        '4F6': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.4f6', '4 folds from 6'),
        '5F6': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.5f6', '5 folds from 6'),
        HNZ: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.hnz', 'Heinz'),
        L63: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.l63', 'Lucky 63'),
        TTPP: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttpp', 'Placepot'),
        TTJP: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.ttjp', 'Jackpot'),
        TTS6: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.tts6', 'Scoop 6'),
        '7F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.7f', '7 fold'),
        '2F7': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f7', 'Doubles from 7'),
        '3F7': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f7', 'Trebles from 7'),
        '4F7': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.4f7', '4 folds from 7'),
        '5F7': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.5f7', '5 folds from 7'),
        '6F7': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.6f7', '6 folds from 7'),
        SHNZ: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.shnz', 'Super Heinz'),
        '8F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.8f', '8 fold'),
        '2F8': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f8', 'Doubles from 8'),
        '3F8': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f8', 'Trebles from 8'),
        '4F8': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.4f8', '4 folds from 8'),
        '5F8': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.5f8', '5 folds from 8'),
        '6F8': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.6f8', '6 folds from 8'),
        '7F8': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.7f8', '7 folds from 8'),
        GOL: appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.gol', 'Goliath'),
        '9F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.9f', '9 fold'),
        '2F9': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f9', 'Doubles from 9'),
        '3F9': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f9', 'Trebles from 9'),
        '8F9': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.8f9', '8 folds from 9'),
        '10F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.10f', '10 fold'),
        '2F10': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f10', 'Doubles from 10'),
        '3F10': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f10', 'Trebles from 10'),
        '9F10': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.9f10', '9 folds from 10'),
        '11F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.11f', '11 fold'),
        '2F11': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f11', 'Doubles from 11'),
        '3F11': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f11', 'Trebles from 11'),
        '10F11': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.10f11', '10 folds from 11'),
        '12F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.12f', '12 fold'),
        '2F12': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f12', 'Doubles from 12'),
        '3F12': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f12', 'Trebles from 12'),
        '11F12': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.11f12', '11 folds from 12'),
        '13F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.13f', '13 fold'),
        '2F13': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f13', 'Doubles from 13'),
        '3F13': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f13', 'Trebles from 13'),
        '12F13': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.12f13', '12 folds from 13'),
        '14F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.14f', '14 fold'),
        '2F14': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f14', 'Doubles from 14'),
        '3F14': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f14', 'Trebles from 14'),
        '13F14': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.13f14', '13 folds from 14'),
        '15F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.15f', '15 fold'),
        '2F15': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f15', 'Doubles from 15'),
        '3F15': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f15', 'Trebles from 15'),
        '14F15': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.14f15', '14 folds from 15'),
        '16F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.16f', '16 fold'),
        '2F16': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f16', 'Doubles from 16'),
        '3F16': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f16', 'Trebles from 16'),
        '15F16': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.15f16', '15 folds from 16'),
        '17F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.17f', '17 fold'),
        '2F17': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f17', 'Doubles from 17'),
        '3F17': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f17', 'Trebles from 17'),
        '16F17': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.16f17', '16 folds from 17'),
        '18F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.18f', '18 fold'),
        '2F18': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f18', 'Doubles from 18'),
        '3F18': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f18', 'Trebles from 18'),
        '17F18': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.17f18', '17 folds from 18'),
        '19F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.19f', '19 fold'),
        '2F19': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f19', 'Doubles from 19'),
        '3F19': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f19', 'Trebles from 19'),
        '18F19': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.18f19', '18 folds from 19'),
        '20F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.20f', '20 fold'),
        '2F20': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f20', 'Doubles from 20'),
        '3F20': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f20', 'Trebles from 20'),
        '19F20': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.19f20', '19 folds from 20'),
        '21F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.21f', '21 fold'),
        '2F21': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f21', 'Doubles from 21'),
        '3F21': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f21', 'Trebles from 21'),
        '20F21': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.20f21', '20 folds from 21'),
        '22F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.22f', '22 fold'),
        '2F22': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f22', 'Doubles from 22'),
        '3F22': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f22', 'Trebles from 22'),
        '21F22': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.21f22', '21 folds from 22'),
        '23F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.23f', '23 fold'),
        '2F23': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f23', 'Doubles from 23'),
        '3F23': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f23', 'Trebles from 23'),
        '22F23': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.22f23', '22 folds from 23'),
        '24F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.24f', '24 fold'),
        '2F24': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f24', 'Doubles from 24'),
        '3F24': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f24', 'Trebles from 24'),
        '23F24': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.23f24', '23 folds from 24'),
        '25F': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.25f', '25 fold'),
        '2F25': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.2f25', 'Doubles from 25'),
        '3F25': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.3f25', 'Trebles from 25'),
        '24F25': appState.appLayoutsState.languagesState.getTranslation('bets.bet-type.24f25', '24 folds from 25')
    };

    if (type === 'RAB') {
        return appState.config.rabHeaderTitle;
    }

    return betsConfig[type];
};
