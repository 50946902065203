import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { PaymentMethodListWrapper } from './PaymentMethods.style';
import { FooterHeader } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { UniverseComponent } from 'src/domains/common/universes';

export const PaymentMethod = observer('PaymentMethod', (): JSX.Element | null => {
    return (
        <>
            <FooterHeader data-test='footer-payment-methods'>
                <I18n langKey='footer.payment.methods' defaultText='Payment Methods' />
            </FooterHeader>
            <PaymentMethodListWrapper>
                <UniverseComponent>
                    {(universeComponent): React.ReactElement => <universeComponent.paymentLogotypes />}
                </UniverseComponent>
            </PaymentMethodListWrapper>
        </>
    );
});
