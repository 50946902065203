import React, { ReactElement } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { OpenapiProxyCustomerBonusesResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_bonuses';
import { useAppStateContext } from 'src/appState/AppState';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';
import { TypeFromArray } from 'src_common/common/type_from_array';
import { BogBanner } from 'src/domains/layouts/webview/components/Messages/BogBanner';

type BonusTypes = TypeFromArray<BetHistoryType['transaction']['tags']['bonuses']>;

interface PropsType {
    betBonusTypes: Array<BonusTypes>;
    bonuses: OpenapiProxyCustomerBonusesResponse200Type;
}

export const BogBonusMessage = observer<PropsType>('BogBonusMessage', ({ betBonusTypes, bonuses }): ReactElement => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;

    return (
        <>
            {betBonusTypes.map((bonus) => {
                const bonusType = bonus.type ?? null;
                const bonusName = bonusType === null ? null : bonuses.filter((b) => b.type === bonusType).pop()?.name; // bonuses.get(bonus.get('type'));
                const bonusAmount = configComponents.precision.newFromAnything(bonus.amount);
                const price = ` bonus ${appPlayersState.usersState.money(bonusAmount)}`;
                const bonusNameForView = bonusName ?? bonusType ?? '';

                if (bonusType === 'best-odds-guaranteed') {
                    return (
                        <BogBanner
                            key={bonus.id}
                            message={
                                <I18n
                                    langKey='betslip.selection-bog'
                                    defaultText='You won an extra {amount} with Best Odds Guaranteed'
                                    params={{ amount: appPlayersState.usersState.money(bonusAmount) }}
                                />
                            }
                        />
                    );
                }
                return <div key={bonus.id}>{bonusNameForView + price}</div>;
            })}
        </>
    );
});
