import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersState } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { MarketFiltersStrategy } from './MarketFiltersStrategy.state';
import { useAppStateContext } from 'src/appState/AppState';
import { MarketFiltersWrapper } from 'src/domains/sportsbook/webview/components/marketFilters/MarketFilters.style';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    sport: string;
    eventId: EventId;
}

export const MarketFilters = observer('MarketFilters', ({ sport, eventId }: PropsType) => {
    const { appSportsBookState, config } = useAppStateContext();
    const marketFiltersForSport = appSportsBookState.getMarketFiltersBySport(sport, eventId);
    const [filtersState] = useState(() => new FiltersState(new MarketFiltersStrategy(marketFiltersForSport)));

    if (marketFiltersForSport.isMarketFiltersAvailableForSport === false) {
        return null;
    }

    return (
        <MarketFiltersWrapper>
            <FiltersScrollableWrapper>
                <Filters state={filtersState} skin={config.filtersVersion.football} />
            </FiltersScrollableWrapper>
        </MarketFiltersWrapper>
    );
});
