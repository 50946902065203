
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { withConfig } from 'src/withConfig';

const Wrapper = withConfig(theme => styled('div', { label: 'Wrapper' })`
    padding: 10px;
    text-align: center;
    font-size: ${theme.star.fontSize.small};
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.3);
`);

export const RabSuspendedBetsMessage = observer('RabSuspendedBetsMessage', () => {
    return (
        <Wrapper>
            <I18n
                langKey='betslip.warning-message.rab-suspended-bets'
                defaultText='Some or all of your selections are no longer available. Please resolve to continue.'
            />
        </Wrapper>
    );
});

