import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { keyframes } from '@emotion/react';
import { withConfig } from 'src/withConfig';

export const YouDecideMobileWrapper = withConfig(theme => styled('div', { label: 'YouDecideMobileWrapper' })`
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    width: 100vw;
    z-index: ${theme.star.zIndexGlobal.footer};
    @media ${theme.star.mediaQuery.desktop} {
        display: none;
    }
`);

const overlaySlideUp = keyframes`
    0% { opacity: 0; }
    100% { opacity: .5; }
`;

export const OpacityLayer = withConfig(theme => styled('div', { label: 'OpacityLayer' })`
    animation: .2s ${overlaySlideUp} ease 1 forwards;
    background-color: rgb(0, 0, 0);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${theme.star.zIndexGlobal.below};
`);

export const YouDecideMobileContent = withConfig(theme => styled('div', { label: 'YouDecideMobileContent' })`
    background-color: ${theme.star.rabSelectionList.bgColor};
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
`);

export const YouDecideMobileTab = withConfig(theme => styled('div', { label: 'YouDecideMobileTab' })`
    align-items: center;
    background: ${theme.star.rabSelectionList.bgColorQuinary};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    height: 48px;
    justify-content: space-between;
    padding: 8px 20px 8px 8px;
`);

export const YouDecideMobileTabShow = withConfig(theme => styled('div', { label: 'YouDecideMobileTabShow' })`
    align-items: center;
    align-self: stretch;
    color: ${theme.star.rabSelectionList.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
`);

interface ItemsPropsType {
    isOpened: boolean;
}

export const YouDecideMobileList = styled('ul', { label: 'YouDecideMobileList' })<ItemsPropsType>`
    display: ${({ isOpened }): string => isOpened ? 'grid' : 'none'};
    grid-gap: 4px;
    gap: 4px;
    margin: 4px 0;
    max-height: 200px;
    overflow-y: auto;
    padding: 0 8px;
    width: 100vw;
`;

export const RabSelectionChevronIconWrapper = styled(ChevronIcon, { label: 'RabSelectionChevronIconWrapper' })`
    fill: currentcolor;
    margin-right: 4px;
    width: 16px;
`;
