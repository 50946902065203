import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PopupOverlayProps {
    active: boolean;
}

export const PopupOverlayDefault = withConfig(theme => styled('div', { label: 'PopupOverlayDefault' })<PopupOverlayProps>`
    align-items: center;
    background: ${theme.star.sidebar.bgColorSecondary};
    display: ${({ active }): string => active ? 'flex' : 'none'};
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const PopupTitle = withConfig(theme => styled('header', { label: 'PopupTitle' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    padding: 15px 15px 10px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
`);

interface PopupContentProps {
    maxWidth?: string;
}

export const PopupContent = withConfig(theme => styled('div', { label: 'PopupContent' })<PopupContentProps>`
    background: ${theme.star.popup.bgColor};
    border-radius: 2px;
    box-shadow: 0 0 15px 10px ${theme.star.popup.bgColorQuinary};
    color: ${theme.star.popup.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    max-height: 100%;
    max-width: ${({ maxWidth }): string => maxWidth === undefined ? '100%' : maxWidth};
    position: relative;
    min-width: 300px;
`);

export const PopupBody = styled('div', { label: 'PopupBody' })`
    padding: 0 15px 15px;
    position: relative;
    text-align: left;
`;

export const PopupClose = withConfig(theme => styled('span', { label: 'PopupClose' })`
    background: ${theme.star.popup.bgColorSenary};
    border-radius: 50%;
    color: ${theme.star.popup.txtColor};
    cursor: pointer;
    font-size: ${theme.star.fontSize.regular};
    height: 20px;
    line-height: 0.75;
    padding-top: 4px;
    position: absolute;
    right: 15px;
    text-align: center;
    text-decoration: none;
    top: 15px;
    width: 20px;
    &:hover {
        background: ${theme.star.popup.bgColorSeptenary};
        color: ${theme.star.popup.txtColorTertiary};
        text-decoration: none;
    }
`);
