import { observable, action, computed, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { getOffset } from 'src/domains/layouts/utils/GetOffset';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';

interface EventHandlersType {
    onClick?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onInput?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

interface InputFlagsTypes {
    asideElement: (() => HTMLElement | null) | undefined;
}

export class InputState {
    @observable public isFocus: boolean = false;

    public constructor(
        private readonly inputState: FormInputState<string,unknown>,
        private readonly eventHandlers: EventHandlersType,
        private readonly inputFlags: InputFlagsTypes,
    ) {
        makeObservable(this);
    }

    @action public handleClick = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        if (this.eventHandlers.onClick !== undefined) {
            this.eventHandlers.onClick(event);
        }
    };

    @action public handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        const headerHeight = 110;
        const innerWidth = getWindowInnerWidth() ?? 0;
        const getAsideElement = this.inputFlags.asideElement;
        const child = event.target;

        if (getAsideElement !== undefined && innerWidth <= 768) {
            const asideElement = getAsideElement();

            if (asideElement !== null) {
                const offset = getOffset(asideElement, child);

                asideElement.scrollTo({ top: offset - headerHeight });
            }
        }

        if (this.eventHandlers.onFocus !== undefined) {
            this.eventHandlers.onFocus(event);
        }
    };

    @action public handleBlur = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        this.isFocus = false;
        this.inputState.setAsVisited();
        if (this.eventHandlers.onBlur !== undefined)
            this.eventHandlers.onBlur(event);
    };

    @action public handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value;

        this.inputState.setValue(value);
        if (this.eventHandlers.onChange !== undefined)
            this.eventHandlers.onChange(event);
    };

    @action public handleInput = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        if (this.eventHandlers.onInput !== undefined)
            this.eventHandlers.onInput(event);
    };

    @computed public get value(): string {
        return this.inputState.value;
    }

    @computed public get isEmpty(): boolean {
        return this.inputState.value.length === 0;
    }

    @computed public get errorMessage(): string | null {
        return this.inputState.errorsForView?.[0] ?? null;
    }
};
