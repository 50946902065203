import React, { useState } from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import {
    EventHeaderGroup,
    EventHeaderGroupSelection,
    EventHeaderGroupName,
    ChevronIconStyle,
    Hr
} from './EventsGroupHeader.style';
import { SportsEventHeaderGroupSelectionName } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventGroupHeader.style';

interface StatePropsType {
    onToggle: () => void,
};

class State {
    private readonly props: StatePropsType;

    public constructor(props: StatePropsType) {
        this.props = props;
    }

    public onToggle = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        this.props.onToggle();
    }
}

interface EventGroupHeaderPropsType {
    isOutrightEvent: boolean,
    label: string,
    selections: Array<string>,
    small: boolean,
    open: boolean,
    onToggle: () => void,
    headerStyle: 'sportLevel' | 'competitionLevel'
};

export const EventGroupHeader = observer('EventGroupHeader', (propsIn: EventGroupHeaderPropsType) => {
    const props = useAsObservableSource(propsIn);
    const [state] = useState(() => new State(props));
    const { label, small, open, selections, isOutrightEvent, headerStyle } = props;
    const setClassName = small ? 'event-group-header--small' : '';
    const dataTest = small ? 'header-competitionLevel' : 'header-sportLevel';
    return (
        <>
            <EventHeaderGroup headerStyle={headerStyle} small={small} data-test={dataTest} className={`event-group-header ${setClassName}`} onClick={state.onToggle}>
                <ChevronIconStyle position={open ? 'down' : 'right'} />
                <EventHeaderGroupName data-test='header-name' className='event-group-header__name'>{label}</EventHeaderGroupName>
                {
                    !isOutrightEvent && selections.map(
                        (name, key) => (
                            <EventHeaderGroupSelection key={key}>
                                <SportsEventHeaderGroupSelectionName>
                                    {name.length > 0 && name.substr(0, 1).toUpperCase()}
                                </SportsEventHeaderGroupSelectionName>

                                <SportsEventHeaderGroupSelectionName>
                                    {name}
                                </SportsEventHeaderGroupSelectionName>
                            </EventHeaderGroupSelection>
                        )
                    )
                }
            </EventHeaderGroup>

            <Hr isOpen={open} small={small} headerStyle={headerStyle} />
        </>
    );
});
