import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

/* 1 - You can delete this if z-index will be deleted in Selection component */
export const MoreLessGroup = withConfig(theme => styled('div', { label: 'MoreLessGroup' })`
    background-color: ${theme.star.heroEvents.bgColorTertiary};
    border-top: solid 1px ${theme.star.heroEvents.borderColorTertiary};
    padding: 8px 10px 7px;
    text-align: center;
    position: relative;
    margin-top: -1px;
    width: calc(100% - 3px);
    cursor: pointer;
`);

export const LinkMoreLessWrapper = withConfig(theme => styled('div', { label: 'LinkMoreLessWrapper' })`
    background-color: transparent;
    border: 0;
    color: ${theme.star.heroSingleMarket.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
    display: inline-block;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4;
    padding: 0 18px 2px 10px;
    position: relative;
    text-decoration: none;
`);

export const ChevronIconStyle = styled(ChevronIcon, { label: 'ChevronIconStyle' })`
    fill: currentcolor;
    flex: 0 0 12px;
    margin: 0 8px;
    width: 12px;
`;
