import React from 'react';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import { useAppStateContext } from 'src/appState/AppState';
import { PlaceCell, RunnerCell, SilkCell, RaceSummaryRow } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { observer } from 'src/utils/mobx-react';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    className?: string;
    place?: React.ReactNode;
    runner?: React.ReactNode;
    oneCast?: React.ReactNode;
    twoCast?: React.ReactNode;
    threeCast?: React.ReactNode;
    anyCast?: React.ReactNode;
    selectionId: SelectionId;
    isUnnamedFavourite: boolean;
    isNonRunner?: boolean;
    dataTest?: string;
}

export const RaceSummaryCastRowHorseRacing = observer('RaceSummaryCastRowHorseRacing', (props: PropsType) => {
    const { className, isUnnamedFavourite, place, runner, oneCast, twoCast, threeCast, anyCast, selectionId, isNonRunner, dataTest } = props;

    const { appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const { config } = configComponents;
    const selectionModel = selectionId.getModel();

    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    const silkUrl = selectionModel.metaDataSilkUrl;

    if (isUnnamedFavourite) {
        return null;
    } else {
        return (
            <RaceSummaryRow className={className} isNonRunner={isNonRunner} data-test={dataTest}>
                <PlaceCell className='cast'>{ place }</PlaceCell>
                <SilkCell className='cast'>
                    <LazyImage
                        src={silkUrl}
                        placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                        alt={selectionModel.metaDataSilk}
                    />
                </SilkCell>
                <RunnerCell>{ runner }</RunnerCell>

                { oneCast }
                { twoCast }
                { marketModel.tricastsOffered && threeCast }
                { anyCast }
            </RaceSummaryRow>
        );
    }
});
