import styled from '@emotion/styled';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { withConfig } from 'src/withConfig';

export const Wrapper = styled(AccountContent, { label: 'Wrapper' })`
    padding: 0 16px;
`;

export const SectionWrapper = styled('div', { label: 'SectionWrapper' })`
    padding: 16px 0;
`;

export const MarketingSectionWrapper = styled('div', { label: 'MarketingSectionWrapper' })`
    padding-top: 28px;
`;

export const Header = withConfig(theme => styled('h2', { label: 'Header' })`
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
`);
