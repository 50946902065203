import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const GridBodyWrapper = withConfig(theme => styled('div', { label: 'GridBodyWrapper' })`
    align-content: start;
    background-color: ${theme.star.casino.casinoMini.bgColor};
    display: grid;
    flex: 1 1 0%;
    gap: 4px;
    grid-template-columns: repeat(2, 1fr);
    height: calc(100% - 40px);
    overflow: auto;
    padding-bottom: 48px;
    scrollbar-color: ${theme.star.scrollableLinksHeader.bgColorSecondary} ${theme.star.scrollableLinksHeader.bgColor};
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 1px;
        background-color: ${theme.star.scrollableLinksHeader.bgColor};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${theme.star.scrollableLinksHeader.bgColorSecondary};
        border-radius: 10px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        max-height: 425px;
        margin: 0 10px;
        padding-bottom: 0;
    }
`);
