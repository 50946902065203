import styled from '@emotion/styled';



export const TrapImg = styled('img', { label: 'TrapImg' })`

    height: auto;

    width: 100%;

`;

