import styled from '@emotion/styled'; 
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons/FreeBetIcon';
import { HelpIcon } from 'src/domains/layouts/shared/assets/icons/HelpIcon';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const FreeBetIconWrapper = styled(FreeBetIcon, { label: 'FreeBetIconWrapper' })`
    width: 14px;
    fill: currentcolor;
    margin: 0 5px 0 0;
`;

export const HelpIconWrapper = styled(HelpIcon, { label: 'HelpIconWrapper' })`
    width: 15px;
    fill: currentcolor;
    vertical-align: top;
    margin: 0 0 0 3px;
    cursor: pointer;
`;

interface IndicatorStatusType {
    winingBet: boolean;
    hasStatus: boolean;
}

export const BetElementList = withConfig(theme => styled('li', { label: 'BetElementList' })<IndicatorStatusType>`
    border-left-color: ${({ winingBet, hasStatus }): string => hasStatus ? winingBet === true ? theme.star.betslipSummary.borderColor : theme.star.betslipSummary.borderColorSecondary : 'transparent' };
    border-style: solid;
    border-width: ${({ winingBet, hasStatus }): string => hasStatus ? winingBet === true ? '0 0 0 4px' : '0 0 0 4px' : '0'};
    color: ${theme.star.betslipGeneral.txtColor};
    margin: 10px 0;
    padding: ${({ hasStatus }): string => hasStatus === true ? '8px 0 8px 8px;' : '8px 0'};
    position: relative;
    &::after {
        background: ${theme.star.betslipSummary.bgColor};
        content: '';
        height: 1px;
        left: 0;
        margin-top: 4px;
        position: absolute;
        right: 0;
        top: 100%;
    }
    &:last-of-type {
        &::after {
            content: none;
        }
    }
`);

export const EachWayTermsList = withConfig(theme => styled('ul', { label: 'EachWayTermsList' })`
    font-size: ${theme.star.fontSize.xSmall};
    width: 100%;
`);

export const EachWayTermsListElement = styled('li', { label: 'EachWayTermsListElement' })`
    display: inline-block;
    margin-right: 5px;
    &:last-of-type {
        margin-right: 0;
    }
`;

export const BetSelectionHeader = withConfig(theme => styled('div', { label: 'BetSelectionHeader' })`
    color: ${theme.star.betslipGeneral.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0;
    opacity: 1;
    padding-right: 10px;
    position: relative;
    text-transform: capitalize;
`);

export const RabItemsWrapper = styled('div', { label: 'RabItemsWrapper' })`
    margin-bottom: 10px;
`;

export const BetType = styled('div', { label: 'BetType' })`
    margin-left: 16px;
`;

export const RabItemsWrapperSelection = withConfig(theme => styled('span', { label: 'RabItemsWrapperSelection' })`
    display: block;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1;
`);

export const RabItemsWrapperMarket = withConfig(theme => styled(RabItemsWrapperSelection, { label: 'RabItemsWrapperMarket' })`
    font-size: ${theme.star.fontSize.xSmall};
    margin-top: 5px;
    opacity: 0.6;
`);

export const BetSelectionHeaderEventName = withConfig(theme => styled(RabItemsWrapperMarket, { label: 'BetSelectionHeaderEventName' })`
    font-weight: ${theme.star.fontWeight.regular};
    margin: 0 0 8px 0;
    padding-left: 16px;
`);

export const PotentialReturns = withConfig(theme => styled('span', { label: 'PotentialReturns' })`
    color: ${theme.star.betslipGeneral.txtColorTertiary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const ReturnsSpan = withConfig(theme => styled('span', { label: 'ReturnsSpan' })`
    color: ${theme.star.betslipGeneral.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const ArrowBetSelectionWrapper = styled(ChevronIcon, { label: 'ArrowBetSelectionWrapper' })`
    fill: currentcolor;
    left: 0;
    position: absolute;
    top: 6px;
    width: 12px;
`;

export const LegPrice = withConfig(theme => styled('span', { label: 'LegPrice' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.67;
    font-weight: ${theme.star.fontWeight.bold};
`);
