import { z } from 'zod';

export const InputSchema = z.object({
    body: z.object({ cpf: z.string(),
    }),
    isTest: z.boolean().optional().nullable(),
});

export type VerifyCpfInput = z.infer<typeof InputSchema>

export const SuccessResponseSchema = z.object({
    cpf: z.string(),
    hashedFirstName: z.string(),
    hashedSurname: z.string(),
    birthDate: z.string(),
});

type SuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    error: z.object({
        code: z.string(),
        message: z.string()
    })
});

type ErrorResponse = z.infer<typeof ErrorResponseSchema>;

export type TResponseType =
    { responseStatus: 'success'; data: SuccessResponse } |
    { responseStatus: 'error'; data: ErrorResponse }
