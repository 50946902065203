import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { InfoIconWrapper, IssueWithdrawContentContainer, IssueWithdrawInfo, IssueWithdrawTitle, IssueWithdrawTitleSecondary } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue.style';
import { WithdrawFailuresType } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawJourney';

const ServerIssue = (
    <>
        <InfoIconWrapper />

        <IssueWithdrawContentContainer>
            <IssueWithdrawTitleSecondary>
                <I18n langKey='account.withdraw.server-issue.title' defaultText='Payments are unavailable at this time' />
            </IssueWithdrawTitleSecondary>
            <IssueWithdrawInfo>
                <I18n langKey='account.withdraw.server-issue.info' defaultText='We are working with our providers to rectify the issue and apologise for any inconvenience caused. For more information contact customer support' />
            </IssueWithdrawInfo>
        </IssueWithdrawContentContainer>

    </>
);

const NoPaymentMethods = (
    <>
        <InfoIconWrapper />

        <IssueWithdrawContentContainer>
            <IssueWithdrawTitle>
                <I18n langKey='account.withdraw.no-payment-methods.title' defaultText="You don't have any payment methods yet" />
            </IssueWithdrawTitle>
            <IssueWithdrawInfo>
                <I18n langKey='account.withdraw.no-payment-methods.info' defaultText='You can withdraw money only to a card which was used to deposit.' />
            </IssueWithdrawInfo>
        </IssueWithdrawContentContainer>

    </>
);

interface WithdrawIssuePropsType {
    failureType: WithdrawFailuresType;
}

export const WithdrawIssue = observer('WithdrawIssue', ({ failureType }: WithdrawIssuePropsType) => {

    switch (failureType) {
        case 'noPaymentMethods':
            return NoPaymentMethods;
        case 'serverIssue':
            return ServerIssue;
    }
});
