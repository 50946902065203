import styled from '@emotion/styled';
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons/FreeBetIcon';
import { HelpIcon } from 'src/domains/layouts/shared/assets/icons/HelpIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { css, SerializedStyles } from '@emotion/react';
import { withConfig } from 'src/withConfig';

interface PropsType {
    betslipNew?: boolean;
    hasTwoButtons?: boolean;
    isFreeBetBtn?: boolean;
    isQuickBet?: boolean;
    visibleKeyboard?: boolean;
}

export const WarningIconWrapper = styled(WarningIcon, { label: 'WarningIconWrapper' })`
    fill: currentcolor;
    width: 18px;
`;

export const InfoIconWrapper = styled(InfoIcon, { label: 'InfoIconWrapper' })`
    fill: currentcolor;
    width: 14px;
`;

export const FreeBetIconWrapper = styled(FreeBetIcon, { label: 'FreeBetIconWrapper' })`
    fill: currentcolor;
    align-self: center;
    width: 14px;
`;

export const HelpIconWrapper = styled(HelpIcon, { label: 'HelpIconWrapper' })`
    fill: currentcolor;
    cursor: pointer;
    width: 15px;
`;

type ButtonsWrapperType = Pick<PropsType, 'hasTwoButtons'>;

export const ButtonsWrapper = styled('div', { label: 'ButtonsWrapper' })<ButtonsWrapperType>`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ hasTwoButtons }): string => hasTwoButtons === true ? '4px' : '0'};
`;

export const BulbWrapper = styled('div', { label: 'BulbWrapper' })`
    left: 0;
    margin: 12px -8px 0 -8px;
    position: sticky;
    right: 0;
    width: calc(100% + 16px);
`;

type BulbContainerType = Pick<PropsType, 'isQuickBet'>;

export const BulbContainer = withConfig(theme => styled('div', { label: 'BulbContainer' })<BulbContainerType>`
    background-color: ${theme.star.betSlipTooltip.bgColor};
    color: ${theme.star.betSlipTooltip.txtColor};
    padding: ${({ isQuickBet }): string => isQuickBet === true ? '4px 10px' : '5px 10px'};
    &::before {
        border-color: transparent transparent ${theme.star.betSlipTooltip.borderColor} transparent;
        border-style: solid;
        border-width: 10px;
        content: '';
        position: absolute;
        right: 23%;
        top: -19px;
    }
    &::after {
        clear: both;
    }
`);

type ButtonWrapperType = Pick<PropsType, 'betslipNew'>;

export const ButtonWrapper = styled('div', { label: 'ButtonWrapper' })<ButtonWrapperType>`
    ${({ betslipNew }): string => betslipNew === true ? '' : 'padding: 0 0 8px 0'};
`;

type PayoutRulesWrapperType = Pick<PropsType, 'isQuickBet'>;

export const PayoutRulesWrapper = withConfig(theme => styled('div', { label: 'PayoutRulesWrapper' })<PayoutRulesWrapperType>`
    color: ${theme.star.betslipGeneral.txtColor};
    font-size: 9px;
    display: flex;
    padding: 0 8px;
    line-height: 1.8;
    white-space: nowrap;

    ${({ isQuickBet }): string => isQuickBet === true ? `
        padding: 2px;
        font-size: 9px;
        line-height: 1.3;
        margin: 5px 0;
    ` : ''}
`);

export const MoreInfo = withConfig(theme => styled('div', { label: 'MoreInfo' })`
    color: ${theme.star.betSlipTooltip.bgColor};
    cursor: pointer;
`);

const renderButtons = (visibleKeyboard?: boolean): SerializedStyles => {
    if (visibleKeyboard === true) {
        return css`
            bottom: 201px;
        `;
    }

    return css `
        bottom: 0;
        position: relative;
    `;
};

type BetButtonsWrapperType = Pick<PropsType, 'visibleKeyboard'>;

export const BetButtonsWrapper = withConfig(theme => styled('div', { label: 'BetButtonsWrapper' })<BetButtonsWrapperType>`
    ${({ visibleKeyboard }): SerializedStyles => renderButtons(visibleKeyboard)};
    background: ${theme.star.betslipGeneral.bgColor};
    width: 100%;
    left: 0;
    right: 0;
    padding: 8px 0;
    @media ${theme.star.mediaQuery.desktop} {
        position: relative;
        padding: 0 0 8px 0;
    }
`);

export const BulbHeader = withConfig(theme => styled('h4', { label: 'BulbHeader' })`
    font-size: ${theme.star.fontSize.xRegular};
    margin: 0 !important;
    font-weight: ${theme.star.fontWeight.bold};
    display: flex;
    align-items: center;
    justify-content: space-between;
`);

export const AmountWrapper = styled('div', { label: 'AmountWrapper' })`
    display: flex;
    justify-content: center;
`;

export const Amount = styled('div', { label: 'Amount' })`
    line-height: 1.8;
    margin-left: 3px;
`;

export const BulbInfoMessage = withConfig(theme => styled('div', { label: 'BulbInfoMessage' })`
    font-size: ${theme.star.fontSize.small};
    display: flex;
    margin-top: -3px;
`);

export const MessageInfo = styled('div', { label: 'MessageInfo' })`
    margin-left: 5px;
    padding-top: 4px;
    line-height: initial;
`;

export const BetNowWrapper = styled('div', { label: 'BetNowWrapper' })`
    text-transform: uppercase;
`;

export const SpanTop = withConfig(theme => styled('span', { label: 'SpanTop' })`
    display: block;
    font-size: ${theme.star.fontSize.small};
`);

export const SpanBottom = styled('span', { label: 'SpanBottom' })`
    display: block;
`;

type EnterStakeButtonSpanType = Pick<PropsType, 'isFreeBetBtn'>;

export const EnterStakeButtonSpan = withConfig(theme => styled('span', { label: 'EnterStakeButtonSpan' })<EnterStakeButtonSpanType>`
    display: block;
    font-size: ${({ isFreeBetBtn }): string => isFreeBetBtn === true ? theme.star.fontSize.small : theme.star.fontSize.regular};
    padding: 0 12px;
`);


