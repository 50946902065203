import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { groupEvents, MeetingsState, getCustomMarketNew } from './MeetingsState';
import { MeetingsRaceCard } from './MeetingsRaceCard';
import { MeetingsEventsList } from './MeetingsGroup';
import { MeetingsInfo, LoaderWrapper } from 'src/domains/sportsbook/webview/components/meetingsAntePostAndSpecials/Meetings.style';
import { useAppStateContext } from 'src/appState/AppState';
import { SportRacesTypeParamType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropTypes {
    eventId: EventId | null;
    sport: 'horseracing' | 'greyhoundracing';
    filterType: SportRacesTypeParamType | null;
    shared: boolean;
    isLoading: boolean;
    handleScroll?: () => void;
}

export const MeetingsAntePost = observer('MeetingsAntePost', (props: PropTypes) => {
    const { eventId, isLoading } = props;

    const { appSportsBookState, appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;

    const [state] = React.useState(() => new MeetingsState(appSportsBookState, () => props.sport));
    const routeType = props.filterType;
    const isDarkUniverse = configComponents.config.isDarkUniverse;

    const eventsAntePost = state.collectionEventsAntePost;
    const events = groupEvents(eventsAntePost);
    const event = eventId === null ? null : eventId.getEventModel();;
    
    const competitionModel = event === null ? null : event.competition2.getModel();
    const competitionName = competitionModel?.name ?? null;

    if (isLoading) {
        return (
            <MeetingsInfo>
                <LoaderWrapper
                    size={14}
                    version={isDarkUniverse ? 'light' : 'dark'}
                >
                    <I18n langKey='events.ante-post.loading' defaultText='Loading races...' />
                </LoaderWrapper>
            </MeetingsInfo>
        );
    } else if (eventsAntePost.length === 0) {
        return (
            <MeetingsInfo>
                <I18n langKey='events.ante-post.empty' defaultText='There are no races available at the moment. Please check again later.' />
            </MeetingsInfo>
        );
    } else if (event !== null && 'ante-post' === routeType) {
        const markets = getCustomMarketNew(event);
        return (
            <MeetingsRaceCard
                event={event}
                competitionName={competitionName}
                markets={markets}
                section='ante-post'
                isAntePost={true}
            />
        );
    } else {
        return (
            <MeetingsEventsList
                events={events}
                section='ante-post'
                handleScroll={props.handleScroll}
            />
        );
    }
});
