import styled from '@emotion/styled'; 
import { withConfig } from 'src/withConfig';

export const StakeInputWrapper = withConfig(theme => styled('div', { label: 'StakeInputWrapper' })`
    align-items: center;
    background-color: ${theme.star.form.dark.bgColor};
    display: flex;
    flex: 1 1 0%;
    height: 40px;
    justify-content: space-between;
    padding: 0 10px;
`);

export const CurrencyStyle = withConfig(theme => styled('div', { label: 'CurrencyStyle' })`
    color: ${theme.star.form.bgColorTertiary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const StakeInputStyle = withConfig(theme => styled('input', { label: 'StakeInputStyle' })`
    background-color: ${theme.star.form.dark.bgColor};
    border: 0;
    color: ${theme.star.betslipGeneral.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    height: 100%;
    line-height: 1;
    min-width: 50px;
    text-align: right;
    width: 100%;
    &::placeholder {
        color: ${theme.star.form.txtColorSecondary};
    }
`);
