import { HelpIcon } from 'src/domains/layouts/shared/assets/icons/HelpIcon';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const TransactionHelpIconWrapper = withConfig(theme => styled(HelpIcon, { label: 'TransactionHelpIconWrapper' })`

    cursor: help;

    display: inline-block;

    fill: ${theme.star.transactionHistory.bgColorTertiary};

    margin-left: 5px;

    position: relative;

    top: 1px;

    width: 14px;

`);



export const TransactionProfit = withConfig(theme => styled('span', { label: 'TransactionProfit' })<{ isNegative: boolean }>`

    color: ${({ isNegative }): string => isNegative === true ? theme.star.transactionHistory.txtColorSecondary : theme.star.transactionHistory.txtColor};

    width: 90px;

    font-weight: ${theme.star.fontWeight.bold};

    display: flex;

    align-items: center;

`);



export const TransactionBalance = withConfig(theme => styled('span', { label: 'TransactionBalance' })`

    width: 85px;

    font-weight: ${theme.star.fontWeight.bold};

    display: flex;

    align-items: center;

`);



interface TransactionNetDepositCurrency {

    isNegative?: boolean;

}



export const TransactionNetDepositCurrency = withConfig(theme => styled('span', { label: 'TransactionNetDepositCurrency' })<TransactionNetDepositCurrency>`

    color: ${({ isNegative }): string => isNegative === true ? theme.star.transactionHistory.txtColorSecondary : theme.star.transactionHistory.txtColor};

    width: 33.333%;

`);



export const TransactionId = withConfig(theme => styled('div', { label: 'TransactionId' })`

    color: ${theme.star.transactionHistory.txtColorQuinary};

    font-size: ${theme.star.fontSize.xSmall};

    line-height: 1.4;

`);

