import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { slug } from 'src/utils/deburr';
import { SelectionNewVersion } from 'src/domains/sportsbook/webview/components/Selection';
import { EventSummaryConnect } from 'src/domains/sportsbook/webview/components/containers/eventSummary/EventSummary';
import {
    EventRowMeta,
    EventRowNameLink,
    EventRowWrapper,
} from 'src/domains/sportsbook/webview/components/eventRow/EventRow.style';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import {
    EventRowHeader,
    EventRowMarkets,
    EventRowSelections,
    EventRowSelectionsElement,
    EventRowMetaTime,
} from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventRowMobx.style';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { getMarketForDisplay } from 'src/domains/sportsbook/webview/components/sportEventsListNew/getEventAndMarket';
import { Score } from 'src/domains/sportsbook/webview/components/events/row/Score';
import { Participant } from 'src/domains/sportsbook/webview/components/events/row/Participant';
import { EventStatusAndDate } from 'src/domains/sportsbook/webview/components/events/row/StatusAndDate';
import { AppState, useAppStateContext } from 'src/appState/AppState';
import { EventsListState } from 'src/domains/sportsbook/state/eventsListState/EventsListState';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';

const getMarket = (
    appState: AppState,
    smallEvent: EventListGroupEventItemType,
    eventsListState: EventsListState
): MarketModel | null => {
    const eventModel = smallEvent.id2.getEventModel();

    if (eventModel === null) {
        return null;
    }

    const marketModel = getMarketForDisplay(appState, eventModel, eventsListState); //: MarketModel | 'loading' | null => {

    //market: MarketModel | null
    if (marketModel === null || marketModel === 'loading') {
        return null;
    }

    return marketModel;
};

interface EventRowMobxRenderSelectionsPropsType {
    smallEvent: EventListGroupEventItemType;
    eventsListState: EventsListState;
}

const EventRowMobxRenderSelections = observer(
    'EventRowMobxRenderSelections',
    (props: EventRowMobxRenderSelectionsPropsType): JSX.Element | null => {
        const { smallEvent, eventsListState } = props;
        const appState = useAppStateContext();

        const market = getMarket(appState, smallEvent, eventsListState);

        if (market === null) {
            return null;
        }

        const selections = eventsListState.lastSelections;

        return selections.length > 0 ? (
            <EventRowSelections>
                {selections.map((id) => {
                    const selection = market.selections.find((s) => {
                        const selectionIdentifier = s.selectionIdentifiers;

                        return market.displayed && s.display && selectionIdentifier === id;
                    });

                    return (
                        <EventRowSelectionsElement key={id}>
                            <SelectionNewVersion
                                selectionId={selection?.id2}
                                sp={false}
                                borderLeft={true}
                                borderRight={true}
                            />
                        </EventRowSelectionsElement>
                    );
                })}
            </EventRowSelections>
        ) : null;
    }
);

interface EventRowMobxPropsType {
    smallEvent: EventListGroupEventItemType;
    competitionId: CompetitionId | null;
}

const EventRowMobx = observer('EventRowMobx', (props: EventRowMobxPropsType) => {
    const { smallEvent, competitionId } = props;

    const { appSportsBookState } = useAppStateContext();
    const eventsListState = appSportsBookState.eventsListState.get(competitionId);
    const isOutrights = smallEvent.template === 'outrights';

    const filterMarketsByPopularTag = false;

    return isOutrights ? (
        <EventRowWrapper>
            <EventRowHeader>
                <EventRowNameLink
                    to={{ name: 'event', id: smallEvent.id, slug: slug(smallEvent.name) }}
                    dataTest='EventRowNameLink'
                >
                    <Participant smallEvent={smallEvent} />

                    <EventRowMeta>
                        <EventStatusAndDate dateFormat='onlyToday' smallEvent={smallEvent} />
                    </EventRowMeta>
                </EventRowNameLink>

                <Score smallEvent={smallEvent} />
            </EventRowHeader>

            {isOutrights ? null : (
                <EventRowMobxRenderSelections smallEvent={smallEvent} eventsListState={eventsListState} />
            )}

            <EventRowMarkets>
                <EventSummaryConnect
                    id={appSportsBookState.models.id.getEventId(smallEvent.id)}
                    filterMarketsByPopularTag={filterMarketsByPopularTag}
                />
            </EventRowMarkets>
        </EventRowWrapper>
    ) : (
        <EventRowWrapper>
            <EventRowHeader>
                <EventRowNameLink
                    to={{ name: 'event', id: smallEvent.id, slug: slug(smallEvent.name) }}
                    dataTest='EventRowNameLink'
                >
                    <Participant smallEvent={smallEvent} />

                    <EventRowMeta>
                        <EventRowMetaTime>
                            <EventStatusAndDate dateFormat='onlyToday' smallEvent={smallEvent} />
                        </EventRowMetaTime>
                    </EventRowMeta>
                </EventRowNameLink>

                <Score smallEvent={smallEvent} />
            </EventRowHeader>

            {isOutrights ? null : (
                <EventRowMobxRenderSelections smallEvent={smallEvent} eventsListState={eventsListState} />
            )}
        </EventRowWrapper>
    );
});

interface EventRowMobxWrapperPropsType {
    smallEvent: EventListGroupEventItemType;
    competitionId: CompetitionId | null;
}

export const EventRowMobxWrapper = observer('EventRowMobxWrapper', (props: EventRowMobxWrapperPropsType) => {
    const { smallEvent, competitionId } = props;

    return <EventRowMobx smallEvent={smallEvent} competitionId={competitionId} />;
});
