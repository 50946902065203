const getHostFromUrl = (url: string): URL | null => {
    try {
        return new URL(url);
    } catch {
        return null;
    }
};

const parseURLBase = (baseHost: string | null, url: string): URL | null => {
    if (url.startsWith('/')) {
        const defaultHost = baseHost ?? 'example.com';
        const result3 = getHostFromUrl(`http://${defaultHost}${url}`);
        if (result3 !== null) {
            return result3;
        }
    }

    const result1 = getHostFromUrl(url);
    if (result1 !== null) {
        return result1;
    }

    const result2 = getHostFromUrl(`http://${url}`);
    if (result2 !== null) {
        return result2;
    }

    return null;
};

class UrlParsedType {
    private constructor(
        public readonly protocol: string,
        public readonly host: string,
        public readonly pathname: string
    ) {}

    public static parseURL(baseHost: string | null, url: string): UrlParsedType | null {
        const result = parseURLBase(baseHost, url);

        if (result === null) {
            return null;
        }

        const chunks = result.pathname.split('/').filter((item) => item.trim() !== '');

        return new UrlParsedType(result.protocol, result.host, `/${chunks.join('/')}${result.search}`);
    }

    public toUrl(): string {
        return `${this.protocol}//${this.host}${this.pathname === '/' ? '' : this.pathname}`;
    }
}

export const hostEqual = (host1: string, host2: string): boolean => {
    const removeWww = (host: string): string => {
        if (host.startsWith('www.')) {
            return host.substring(4);
        }

        return host;
    };

    return removeWww(host1) === removeWww(host2);
};

export type IsLinkExternalBaseResult =
    | {
          type: 'onserver';
      }
    | {
          type: 'external';
          url: string;
      }
    | {
          type: 'internal';
          path: string;
      }
    | {
          type: 'parseError';
          message: string;
      };

const calculateInternal = (outsideAllowedRoutes: Array<string>, parsedUrl: UrlParsedType): IsLinkExternalBaseResult => {
    if (outsideAllowedRoutes.includes(parsedUrl.pathname)) {
        return {
            type: 'external',
            url: parsedUrl.toUrl(),
        };
    }

    return {
        type: 'internal',
        path: parsedUrl.pathname,
    };
};

export const isLinkExternalBase = (
    baseHost: string | null,
    outsideAllowedRoutes: Array<string>,
    toIn: string
): IsLinkExternalBaseResult => {
    const parsedUrl = UrlParsedType.parseURL(baseHost, toIn);
    if (parsedUrl === null) {
        return {
            type: 'parseError',
            message: `perse error url - ${toIn}`,
        };
    }

    if (parsedUrl.protocol === 'mailto:' || parsedUrl.protocol === 'tel:') {
        return {
            type: 'external',
            url: toIn,
        };
    }

    if (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') {
        if (baseHost === null) {
            if (toIn[0] === '/') {
                return calculateInternal(outsideAllowedRoutes, parsedUrl);
            }

            return {
                type: 'onserver',
            };
        }

        if (hostEqual(baseHost, parsedUrl.host)) {
            return calculateInternal(outsideAllowedRoutes, parsedUrl);
        } else {
            return {
                type: 'external',
                url: parsedUrl.toUrl(),
            };
        }
    }

    return {
        type: 'external',
        url: parsedUrl.toUrl(),
    };
};
