import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { Panel } from 'src/domains/layouts/webview/components/navigationPanel/Panel';
import { LanguageSwitcher } from 'src/domains/layouts/webview/components/languageSwitcher/LanguageSwitcher';
import { computed, makeObservable, observable } from 'mobx';

class State {
    @observable public ref: HTMLElement | null = null;

    public setRef = (ref: HTMLElement | null): void => {
        this.ref = ref;
    };

    public constructor() {
        makeObservable(this);
    }

    @computed public get refWidth(): number {
        return this.ref?.clientWidth ?? 0;
    }
}

export const NavigationSidebarLanguage = observer('NavigationSidebarLanguage', (): React.ReactElement | null => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, breakpointsState } = appLayoutsState;
    const [state] = React.useState(() => new State());

    const { config } = configComponents;
    const { languagesAvailable } = config;
    const hasLanguages = languagesAvailable.length > 1;

    if (hasLanguages === false || breakpointsState.desktop.isBiggerOrEq === true) {
        return null;
    }

    return (
        <Panel title='Language' titleDataTest='language'>
            <LanguageSwitcher setRef={state.setRef} isSidebar reference={state.ref} />
        </Panel>
    );
});
