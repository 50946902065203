import React from 'react';
import { computed, observable, action, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import {
    TPWrapper,
    AmountInputDescription,
    TopUpLimitsInput,
} from 'src/domains/players/webview/components/Account/limitsTab/topUpLimitsProcedure/TopUpLimitsProcedure.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { DepositLimitType } from 'src/domains/players/webview/components/DepositLimitPopup';
import { UsersState } from 'src/domains/players/state/UsersState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Amount } from 'src_common/common/amount/Amount';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { useAppStateContext } from 'src/appState/AppState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { DateTime } from 'src_common/utils/time/time';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export class TopUpLimitsProcedureItemState {
    @observable public refAmountInput: HTMLInputElement | null;

    public amount: FormInputState<string, Amount | undefined>;

    public constructor(
        private readonly language: LanguagesState,
        private readonly configComponents: ConfigComponents,
        public readonly usersState: UsersState,
        private readonly clearInfoMessage: () => void,
        public readonly getLimits: () => DepositLimitType | undefined | null
    ) {
        makeObservable(this);
        this.refAmountInput = null;

        this.amount = this.inputState();
    }

    public setAmountInputRef = (node: HTMLInputElement | null): void => {
        this.refAmountInput = node;
    };

    @action public onChangeInput = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        const newValue = event.currentTarget.value;
        this.clearInfoMessage();
        this.amount.setValue(newValue);
    };

    @action public resetValue = (): void => {
        this?.amount?.reset();
    };

    @computed public get maxDepositLimit(): Amount | undefined {
        const maxDepositLimit = this.getLimits()?.maxDepositLimit ?? undefined;
        if (maxDepositLimit === undefined) {
            return undefined;
        }

        return this.configComponents.precision.newFromOld(maxDepositLimit);
    }

    @computed public get inputDepositLimitType(): string {
        return this.getLimits()?.active?.type ?? '';
    }

    @computed public get getInputState(): FormInputState<string, Amount | undefined> {
        const noPreviousData = this.isLoading === false && this.amount.value === '' && this.refAmountInput === null;

        if (noPreviousData) {
            this.amount = this.inputState();
        }

        return this.amount;
    }

    private inputState(): FormInputState<string, Amount | undefined> {
        const activeValue = this.getLimits()?.active?.amount;
        const convertedActiveValue =
            activeValue === undefined ? '' : this.configComponents.precision.newFromOld(activeValue).value;

        return FormInputState.new(convertedActiveValue).map((value: string): Result<undefined | Amount> => {
            if (value === '') {
                return Result.createOk(undefined);
            }
            try {
                const amount = new Amount(value);

                if (this.maxDepositLimit !== undefined && amount.isGreaterThan(this.maxDepositLimit)) {
                    return Result.createError(
                        this.language.getTranslation(
                            'account.top-up-limits-error-message.input.max-limit',
                            'Your maximum {inputDepositLimitType} limit is {currency}{maxAmount}, Please enter a lower value',
                            {
                                maxAmount: this.maxDepositLimit.value,
                                currency: this.usersState.moneySymbol,
                                inputDepositLimitType: this.inputDepositLimitType,
                            }
                        )
                    );
                }

                return Result.createOk(amount);
            } catch (e) {
                return Result.createError(getErrorByCode('ERROR_DECIMAL'));
            }
        });
    }

    @computed public get renderValueFromTime(): string | undefined {
        const alreadyUsed = this.getLimits()?.active?.used;
        if (alreadyUsed !== undefined) {
            return this.configComponents.precision.newFromOld(alreadyUsed).value;
        }

        return alreadyUsed;
    }

    @computed public get isLoading(): boolean {
        return this.getLimits() === undefined;
    }

    @computed public get isError(): boolean {
        return this.getInputState.result.value.type === 'error' || this.getInputState.value === '';
    }

    @computed public get renderTime(): string | undefined {
        const periodEndDate = this.getLimits()?.active?.periodEndDate;

        if (periodEndDate === undefined) {
            return periodEndDate;
        }

        return DateTime.from(periodEndDate)?.format('DD-MM-YYYY');
    }
}

const pendingMessage = (
    configComponents: ConfigComponents,
    usersState: UsersState,
    amount: number,
    lastUpdateDate: string
): JSX.Element | undefined => {
    const until = DateTime.from(lastUpdateDate)?.addDays(1).format('HH:mm DD-MM-YYYY');

    if (amount === 0 || until === undefined) {
        return undefined;
    }

    const next = usersState.money(configComponents.precision.newFromAnything(amount));

    return (
        <I18n
            langKey='account.top-up-limits.pending-new-limit'
            defaultText='New limit of {next} will be available at {until}'
            params={{ next, until }}
        />
    );
};

const renderPending = (
    configComponents: ConfigComponents,
    usersState: UsersState,
    limits: DepositLimitType | null
): React.ReactElement | null => {
    if (limits === null) {
        return null;
    }

    const pending = limits.pending ?? null;

    if (pending === null) {
        return null;
    }

    const active = limits.active ?? null;

    if (active === null) {
        return null;
    }

    const message = pendingMessage(configComponents, usersState, pending.amount, pending.lastUpdateDate);

    return <Messages type='success' message={message} dataTest='limit-set-message' />;
};

interface PropsType {
    state: TopUpLimitsProcedureItemState;
    label: React.ReactElement;
}

export const TopUpLimitsProcedureItem = observer('TopUpLimitsItem', (props: PropsType) => {
    const { state, label } = props;

    const { appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const usersState = state.usersState;

    return (
        <>
            <TopUpLimitsInput
                type='text'
                maxLength={10}
                label={label}
                currency={usersState.moneySymbol}
                placeholder='Not set'
                state={state.getInputState}
                inputRef={state.setAmountInputRef}
                onChange={state.onChangeInput}
                colorTheme='light'
                onBlur={state.onChangeInput}
            />

            {renderPending(configComponents, usersState, state.getLimits() ?? null)}

            <TPWrapper>
                <AmountInputDescription>
                    <I18n langKey='account.top-up-limits.used-so-far.label' defaultText='Used so Far ' />
                    <span>{`${usersState.moneySymbol}${state.renderValueFromTime ?? 0}`}</span>
                </AmountInputDescription>
                {state.renderTime !== undefined && (
                    <AmountInputDescription>
                        <I18n langKey='account.top-up-limits.resets.label' defaultText='Resets on ' />
                        <span>{state.renderTime}</span>
                    </AmountInputDescription>
                )}
            </TPWrapper>
        </>
    );
});
