import { streamButtonBarDesktop } from 'src/domains/sportsbook/shared/Components';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface UIPropsType {
    bannersHeight: number;
    streamHeight: number;
    isStreamFloating: boolean;
    isLayoutHasAside: boolean;
}

export const headerHeight = 48;

const setAppWrapperPosition = (bannersHeight: number, streamHeight: number, isStreamFloating: boolean): string => {
    const fixedStreamHeight = streamHeight - 1; // Remove 1px hap between stream and main header.

    if (streamHeight > 0) {
        if (isStreamFloating) {
            return `
                padding-top: ${bannersHeight + headerHeight}px;
            `;
        }

        return `
            padding-top: ${bannersHeight + headerHeight + fixedStreamHeight + streamButtonBarDesktop}px;
        `;
    }

    return `
        padding-top: ${bannersHeight + headerHeight}px;
    `;
};

const setMainWrapper = (isLayoutHasAside: boolean): string => {
    if (isLayoutHasAside) {
        return `
            width: calc(100% - 280px);
        `;
    }

    return `
        width: 100%;
    `;
};

export const AppWrapper = withConfig(theme => styled('div', { label: 'AppWrapper' }) <UIPropsType>`
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    min-height: 100%;
    ${({ bannersHeight, streamHeight, isStreamFloating }): string => setAppWrapperPosition(bannersHeight, streamHeight, isStreamFloating)};

    @media ${theme.star.mediaQuery.desktop} {
        padding: ${({ bannersHeight }): string => `${bannersHeight}px`} 0 0 0;
        ${({ isLayoutHasAside }: UIPropsType): string => setMainWrapper(isLayoutHasAside)};
    }
`);
