import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Popup } from 'src/domains/players/webview/ui/Popup';
import { observer } from 'src/utils/mobx-react';
import { ConfirmationBoxButtonsWrapper, ConfirmationBoxBtnWrapper, GamstopText } from 'src/domains/players/webview/ui/ConfirmationsBoxWrapper/ConfirmationBox/ConfirmationBox.style';

interface PropsType {
    message: string | JSX.Element;
    title?: JSX.Element;
    onConfirm: () => void;
    onReject: (err: unknown) => void,
}

export const ConfirmationBox = observer('ConfirmationBox', (props: PropsType) => {
    const { message, title } = props;
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { getTranslation, translateTokens } = languagesState;

    function confirm(e: React.SyntheticEvent | undefined): void {
        const { onConfirm } = props;

        if (e !== undefined) {
            e.preventDefault();
        }

        onConfirm();
    }

    function reject(e: React.SyntheticEvent | undefined): void {
        const { onReject } = props;

        if (e !== undefined) {
            e.preventDefault();
        }

        onReject(new Error('no-error'));
    }

    return (
        <Popup
            title={title === undefined ? <I18n langKey='common.confirmation-box.title' defaultText='Confirm' /> : title}
            priority={10100}
            key='confirmation-box'
            overlayClose={true}
            maxWidth='790px'
            onClose={reject}
        >
            <span data-test='message'>{message}</span>
            <ConfirmationBoxButtonsWrapper>
                <ConfirmationBoxBtnWrapper onClick={confirm} size='xs' dataTest='button-confirm'>
                    <I18n langKey='common.confirmation-box.accept.label' defaultText='Confirm' />
                </ConfirmationBoxBtnWrapper>

                <ConfirmationBoxBtnWrapper onClick={reject} size='xs' dataTest='button-cancel'>
                    <I18n langKey='common.confirmation-box.cancel.label' defaultText='Cancel' />
                </ConfirmationBoxBtnWrapper>
            </ConfirmationBoxButtonsWrapper>

            <GamstopText>
                {
                    translateTokens(
                        getTranslation('account.self-exclusion.gamstop-link', 'To self-exclude from participating online gambling companies licensed in Great Britain go to [Link].'),
                        () => (
                            <a href='https://www.gamstop.co.uk' rel='noopener noreferrer' target='_blank'>www.gamstop.co.uk</a>
                        )
                    )
                }
            </GamstopText>
        </Popup>
    );
});
