import React, { useRef, useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { BetCommentIcon, BetCommentTooltip, BetCommentWrapper, BetCommentTooltipWrapper } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetComment.style';

interface PropsType {
    message: string;
}

export const BetComment = observer<PropsType>('BetComment', ({ message }) => {
    const [isOpened, setIsOpened] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    return <BetCommentWrapper ref={elementRef}>
        <BetCommentIcon onClick={(): void => setIsOpened(!isOpened)} />
        {
            isOpened === true
                ? <BetCommentTooltipWrapper verticalOffset={elementRef.current?.offsetLeft ?? 0}>
                    <BetCommentTooltip>{ message }</BetCommentTooltip>
                </BetCommentTooltipWrapper>
                : null
        }
    </BetCommentWrapper>;
});
