import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    CompetitionType,
    EventType,
} from 'src/domains/layouts/webview/components/promotedSports/PromotedEventsState.state';
import { observer } from 'src/utils/mobx-react';
import { EventGroupHeader } from 'src/domains/sportsbook/webview/components/eventGroupHeader/EventGroupHeader';
import { EventRowMobxWrapper } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventRowMobx';
import { EventRow } from 'src/domains/sportsbook/webview/components/eventRow/EventRow';
import { EventRowNFL } from 'src/domains/sportsbook/shared/Components';
import { EventRowMLB } from 'src/domains/sportsbook/webview/components/eventsList/eventRowMLB/EventRowMLB';
import { EventRowNBA } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/EventRowNBA';
import { EventRowNHL } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNHL/EventRowNHL';
import { makeObservable, observable } from 'mobx';
import {
    getMarketAndSelections,
    PossibleMarketAndSelectionInListType,
} from 'src/domains/sportsbook/state/eventsListState/eventsGroup/getMarketAndSelections';
import { SELECTIONS_LABELS } from 'src/domains/sportsbook/state/eventsListState/eventsGroup/EventsGroupView';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { EventGroupHeaderMLB } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderMLB';
import { EventGroupHeaderNBA } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderNBA';
import { EventGroupHeaderNFL } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderNFL';
import { EventGroupHeaderNHL } from 'src/domains/sportsbook/webview/components/eventsList/EventGroupHeaderNHL';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';

interface CompetitionDataPropsTypes {
    competition: CompetitionType;
    sportSlug: string;
}
const checkCompetition = (competitionName: string, competionArray: Array<string>): boolean => {
    const chunks = competitionName.toLowerCase().split(' ');
    for (const item of competionArray) {
        if (chunks.includes(item.toLowerCase())) {
            return true;
        }
    }
    return false;
};

const renderCompetitionView = (
    competitionId: CompetitionId,
    competitionName: string,
    event: EventListGroupEventItemType,
    sportSlug: string,
    possibleMarketAndSelectionInList: PossibleMarketAndSelectionInListType
): JSX.Element => {
    const mlbOrMajorCompetition = checkCompetition(competitionName, ['MLB', 'Major']);
    const id = event.id2;
    const template = event.template;

    if (mlbOrMajorCompetition === true && template.startsWith('two-participant')) {
        return <EventRowMLB key={`event_${id.toOldId()}`} eventId={id} smallEvent={event} />;
    } else if (checkCompetition(competitionName, ['NHL']) === true && template.startsWith('two-participant')) {
        return <EventRowNHL key={`event_${id.toOldId()}`} eventId={id} smallEvent={event} />;
    } else if (checkCompetition(competitionName, ['NBA']) === true && template.startsWith('two-participant')) {
        return <EventRowNBA key={`event_${id.toOldId()}`} eventId={id} smallEvent={event} />;
    } else if (competitionName === 'US NFL' || competitionName === 'NFL Matches') {
        return <EventRowNFL key={`event_${id.toOldId()}`} eventId={id} smallEvent={event} />;
    } else if (sportSlug === 'football') {
        return <EventRowMobxWrapper smallEvent={event} competitionId={competitionId} />;
    } else {
        return (
            <EventRow
                key={`event_${event.id}`}
                sport={sportSlug}
                smallEvent={event}
                possibleMarketAndSelectionInList={possibleMarketAndSelectionInList}
                filterMarketsByPopularTag={false}
            />
        );
    }
};

const getSmallEvents = (events_list: EventType[]): Array<EventListGroupEventItemType> => {
    const result = [];
    for (const event of events_list) {
        const model = event.event_id.getEventModel();
        if (model !== null) {
            result.push(model.convertToSmallEvent());
        }
    }

    return result;
};
interface HeaderType {
    competition: CompetitionType;
    isOpenedCompetition: boolean;
    sportSlug: string;
    setIsOpened: () => void;
}

const Header = observer(
    'Header',
    ({ competition, isOpenedCompetition, sportSlug, setIsOpened }: HeaderType): JSX.Element => {
        const { appLayoutsState } = useAppStateContext();
        const { languagesState } = appLayoutsState;

        const competitionName = competition.competition_id.getModel()?.name ?? '';
        const smallEventsArray = getSmallEvents(competition.events_list);
        const possibleMarketAndSelectionInList = getMarketAndSelections(smallEventsArray);
        const selectionsLabels = SELECTIONS_LABELS(languagesState.getTranslation);
        const selections = possibleMarketAndSelectionInList.headers.map(
            (x) => selectionsLabels[`${sportSlug}:${x}`] ?? selectionsLabels[x] ?? x
        );
        const mlbOrMajorCompetition = checkCompetition(competitionName, ['MLB', 'Major']);
        const areEventsTwoParticipantsOnly = getSmallEvents(competition.events_list).every((smallEvent) =>
            smallEvent.template.startsWith('two-participant')
        );

        if (mlbOrMajorCompetition === true && areEventsTwoParticipantsOnly === true) {
            return (
                <EventGroupHeaderMLB
                    key={`sport_competition_${competition.competition_id.toOldId()}`}
                    label={competitionName}
                    onToggle={setIsOpened}
                    small={true}
                    open={isOpenedCompetition}
                    headerStyle='competitionLevel'
                />
            );
        } else if (checkCompetition(competitionName, ['NHL']) === true && areEventsTwoParticipantsOnly === true) {
            return (
                <EventGroupHeaderNHL
                    key={`sport_competition_${competition.competition_id.toOldId()}`}
                    label={competitionName}
                    onToggle={setIsOpened}
                    small={true}
                    open={isOpenedCompetition}
                    headerStyle='competitionLevel'
                />
            );
        } else if (checkCompetition(competitionName, ['NBA']) === true && areEventsTwoParticipantsOnly === true) {
            return (
                <EventGroupHeaderNBA
                    key={`sport_competition_${competition.competition_id.toOldId()}`}
                    label={competitionName}
                    onToggle={setIsOpened}
                    small={true}
                    open={isOpenedCompetition}
                    headerStyle='competitionLevel'
                />
            );
        } else if (competitionName === 'US NFL' || competitionName === 'NFL Matches') {
            return (
                <EventGroupHeaderNFL
                    key={`sport_competition_${competition.competition_id.toOldId()}`}
                    label={competitionName}
                    onToggle={setIsOpened}
                    small={true}
                    open={isOpenedCompetition}
                    headerStyle='competitionLevel'
                />
            );
        } else {
            return (
                <EventGroupHeader
                    selections={selections}
                    small={true}
                    isOutrightEvent={false}
                    label={competitionName}
                    headerStyle='competitionLevel'
                    open={isOpenedCompetition}
                    onToggle={setIsOpened}
                />
            );
        }
    }
);

class State {
    @observable public isOpenedCompetition: boolean = true;

    public constructor() {
        makeObservable(this);
    }

    public setIsOpened = (): void => {
        this.isOpenedCompetition = !this.isOpenedCompetition;
    };
}

export const CompetitionData = observer('CompetitionData', ({ competition, sportSlug }: CompetitionDataPropsTypes) => {
    const { appLayoutsState } = useAppStateContext();
    const { promotedEventsState } = appLayoutsState;
    const [state] = useState(() => new State());

    const competitionName = competition.competition_id.getModel()?.name ?? '';
    const smallEventsArray = getSmallEvents(competition.events_list);
    const possibleMarketAndSelectionInList = getMarketAndSelections(smallEventsArray);
    const eventsSorted = competition.events_list.sort((a, b) => a.display_order - b.display_order);
    const eventModels = eventsSorted
        .map((event) => event.event_id.getEventModel())
        .filter(
            (eventModel): eventModel is EventModel =>
                eventModel !== null &&
                promotedEventsState.isEventToShow(eventModel) &&
                eventModel.allMarketsForEventPage.length > 0
        );

    return eventModels.length > 0 ? (
        <React.Fragment key={competition.id}>
            <Header
                competition={competition}
                sportSlug={sportSlug}
                isOpenedCompetition={state.isOpenedCompetition}
                setIsOpened={state.setIsOpened}
            />
            {state.isOpenedCompetition ? (
                <>
                    {eventModels.map((eventModel) => {
                        const smallEvent = eventModel.convertToSmallEvent();

                        const properComponent = renderCompetitionView(
                            competition.competition_id,
                            competitionName,
                            smallEvent,
                            sportSlug,
                            possibleMarketAndSelectionInList
                        );
                        return properComponent;
                    })}
                </>
            ) : null}
        </React.Fragment>
    ) : null;
});
