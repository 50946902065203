import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { NavigationPanel } from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { PromotedEventsLink } from './NavigationSidebarPromotedEvents';

export const RenderPopularSportsAndPromotedEvents = observer('RenderPopularSports', (): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { popularSportState, configComponents } = appLayoutsState;

    const navigationPopular = configComponents.config.navigationPopular;
    if (navigationPopular === null) {
        return <PromotedEventsLink />; // NOTE: even when navigationPopular is null, we still want to display promoted events
    }

    const out = popularSportState.generateNavigationList(navigationPopular);
    return (
        <NavigationPanel
            title={<I18n langKey='left-menu.popular.title' defaultText='Popular' />}
            titleDataTest='popular'
            links={out}
            typeTag='sport'
        />
    );
});
