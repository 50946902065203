import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';
import { GameContentWrapper, IframeContainer, GameIframe } from './BingoContent.style';
import { BingoMiniGames } from './bingo-mini-games/BingoMiniGames';

interface BingoContentPropsType {
    appCasinoState: AppCasinoState;
    url: string;
    gameDataState: GameDataState;
}

export const BingoContent = observer('BingoContent', (props: BingoContentPropsType): ReactElement => {
    const { appCasinoState } = props;
    const { onIframeMessage, setIframeRef } = appCasinoState.gameModalState;

    return (
        <GameContentWrapper data-test='game-modal-success-bingo'>
            <IframeContainer>
                <GameIframe
                    ref={setIframeRef}
                    title={`${props.gameDataState.gameType ?? 'no'}-game-modal`}
                    src={props.url}
                    onMessage={onIframeMessage}
                    dataTest='game-iframe-bingo'
                />
            </IframeContainer>
            <BingoMiniGames appCasinoState={appCasinoState} />
        </GameContentWrapper>
    );
});
