import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface MyBetsSectionPropsType {
    isMiniCasino: boolean;
}

export const MyBetsSection = withConfig(theme => styled('ul', { label: 'MyBetsSection' })<MyBetsSectionPropsType>`
    @media ${theme.star.mediaQuery.desktop} {
        margin-bottom: ${({ isMiniCasino }): string => isMiniCasino ? '20px' : '0'};
    }
`);

export const PlaceBetInfo = withConfig(theme => styled('div', { label: 'PlaceBetInfo' })`
    color: ${theme.star.betslipGeneral.txtColor};
`);

export const BetsFiltersWrapper = styled(Filters, { label: 'BetsFiltersWrapper' })`
    padding-bottom: 10px;
`;

export const EmptyInformation = withConfig(theme => styled('p', { label: 'EmptyInformation' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.7;
    margin: 10px 0 5px;
`);
