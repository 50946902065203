import { observable, action, computed, makeObservable } from 'mobx';

export class SelectState {
    private readonly getDefaultValue: () => string | undefined;
    @observable private value: string | undefined;
    @observable public isFocus: boolean = false;
    @observable public isError: boolean = false;

    public constructor(getDefaultValue: () => string | undefined) {
        makeObservable(this);
        this.getDefaultValue = getDefaultValue;
    }

    @action public handleFocus = (): void => {
        this.isFocus = true;
        this.isError = false;
    };

    @action public handleBlur = (): void => {
        this.isFocus = false;
    };

    @computed public get currentValue(): string | undefined {
        if (this.value !== undefined) {
            return this.value;
        }

        return this.getDefaultValue();
    }

    public setValue(value: string): void {
        this.value = value;
    }

    public reset(): void {
        this.value = undefined;
    }

    @computed public get isSelected(): boolean {
        const value = this.currentValue;

        if (value === undefined) {
            return false;
        }
        return value.length !== 0;
    }
}
