import styled from '@emotion/styled';
import Select from 'react-select';
import { paymentDropDown } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/paymentMethodDropdown/paymentDropDown.style';
import { withConfig } from 'src/withConfig';

export const SelectWrapper = withConfig(theme => styled(Select, { label: 'SelectWrapper' })`
    /* External library payment custom drop down */
    ${paymentDropDown(theme)}
`);

interface SelectInputContainerPropsTypes {
    hasPrefixes?: boolean;
}

export const SelectInputContainer = styled('div', { label: 'SelectInputContainer' })<SelectInputContainerPropsTypes>`
    margin: 24px 0 0;
`;

export const SelectInputLabel = withConfig(theme => styled('label', { label: 'SelectInputLabel' })`
    color: ${theme.star.form.light.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    margin-top: 16px;
`);
