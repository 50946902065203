import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface EyeClosedIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const EyeClosedIcon = observer(
    'EyeClosedIcon',
    ({ className, onClick }: EyeClosedIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M11.872 8.05h.154c.887 0 1.654.324 2.302.972.649.649.973 1.416.973 2.303v.205l-3.429-3.48zm-4.708.87c-.41.819-.614 1.62-.614 2.405 0 1.501.537 2.789 1.612 3.864 1.075 1.074 2.362 1.612 3.864 1.612.784 0 1.586-.205 2.405-.614l-1.689-1.69c-.273.07-.512.103-.716.103-.887 0-1.655-.324-2.303-.972-.648-.648-.972-1.416-.972-2.303 0-.205.034-.443.102-.716L7.164 8.92zM1.126 2.882L2.507 1.5l19.344 19.343-1.382 1.382c-.17-.17-.708-.7-1.612-1.587a333.269 333.269 0 01-2.072-2.046c-1.467.614-3.054.92-4.76.92-2.695 0-5.134-.75-7.317-2.25C2.525 15.76.955 13.781 0 11.324.853 9.21 2.217 7.402 4.094 5.901c-.41-.41-.981-.99-1.714-1.74L1.126 2.88v.002zm10.9 2.968c-.683 0-1.348.136-1.996.41L7.676 3.904c1.33-.512 2.78-.767 4.35-.767 2.695 0 5.125.75 7.292 2.251A12.655 12.655 0 0124 11.325a13.343 13.343 0 01-3.736 5.169l-3.172-3.173c.273-.648.41-1.314.41-1.996 0-1.5-.538-2.789-1.613-3.863-1.074-1.075-2.362-1.612-3.863-1.612z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
