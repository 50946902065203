import { action, observable } from 'mobx';
import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { makeObservable } from 'src_common/common/mobx-wrapper';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { assertNever } from 'src_common/common/assertNever';
import { UpdateBetslipMessageAvailableType } from 'src/domains/layouts/state/lifespanState/LifespanSocketConnectionState';
import { SelectionBoostState } from 'src/domains/layouts/state/lifespanState/LifespanSocketState';
import { LifeSpanBoostButtonWrapper } from './LifeSpanBoostButtonNew.style';
import {
    BoostWrapperIcon,
    LifeSpanBoostButtonClickedWrapper,
    LifeSpanBoostButtonCloseIcon,
} from './LifeSpanBoostButton.style';
import {
    SpinnerDark,
    SpinnerLight,
} from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner.style';
import {
    ABTestButtonOneWrapper,
    ApplyBoostWrapper,
    BoostWrapperIconAB,
    PotentialReturnMessage,
    PotentialReturnWrapper,
    TextWrapper,
} from './ABTestButtonOne.style';
import {
    ABTestButtonTwo,
    ABTestButtonTwoWrapper,
    ApplyBoostWrapperTwo,
    BoostAvailableWrapper,
    BoostWrapperIconABTwo,
    PotentialReturnElement,
    PotentialReturnWrapperTwo,
    TriangleUp,
} from './ABTestButtonTwo.style';

const renderText = (selectionReturn: string, potentialReturn: Amount | null): string => {
    if ((potentialReturn !== null && potentialReturn.isEqualWith(new Amount('0'))) || selectionReturn === 'n/a') {
        return 'Boost potential returns';
    }
    return `Boost returns to ${selectionReturn}`;
};

const renderTextOne = (selectionReturn: string, potentialReturn: Amount | null): string | JSX.Element => {
    if ((potentialReturn !== null && potentialReturn.isEqualWith(new Amount('0'))) || selectionReturn === 'n/a') {
        return (
            <>
                <BoostWrapperIconAB />
                <TextWrapper>Apply Boost</TextWrapper>
            </>
        );
    }
    return (
        <PotentialReturnMessage>
            <ApplyBoostWrapper>
                <BoostWrapperIconAB />
                Apply Boost
            </ApplyBoostWrapper>
            <PotentialReturnWrapper>Potential return: {selectionReturn}</PotentialReturnWrapper>
        </PotentialReturnMessage>
    );
};

const renderTextTwo = (selectionReturn: string, potentialReturn: Amount | null): string | JSX.Element => {
    if ((potentialReturn !== null && potentialReturn.isEqualWith(new Amount('0'))) || selectionReturn === 'n/a') {
        return (
            <BoostAvailableWrapper>
                <BoostWrapperIconABTwo />
                <TextWrapper>Boost Available</TextWrapper>
            </BoostAvailableWrapper>
        );
    }
    return (
        <PotentialReturnElement>
            <BoostWrapperIconABTwo />
            <PotentialReturnMessage>
                <ApplyBoostWrapperTwo>Boost Available</ApplyBoostWrapperTwo>
                <PotentialReturnWrapperTwo>Potential Returns: {selectionReturn}</PotentialReturnWrapperTwo>
            </PotentialReturnMessage>
        </PotentialReturnElement>
    );
};

interface LifeSpanBoostButtonType {
    selectionBoost: SelectionBoostState;
    activateBoost: (selectionInfo: UpdateBetslipMessageAvailableType) => void;
    deactivateBoost: (selectionInfo: UpdateBetslipMessageAvailableType) => void;
    potentialReturn: Amount | null;
    selectionId: SelectionId;
    stakePerLine: Amount | null;
}

class LifeSpanBoostButtonState {
    @observable public isBoosted: boolean = false;
    @observable public isDisabled: boolean = false;
    @observable public isLoading: boolean = false;

    public constructor() {
        makeObservable(this);
    }

    @action public setDisabled = (value: boolean): void => {
        this.isDisabled = value;
    };
}

const renderReturnWithBoost = (
    potentialReturn: Amount | null,
    boostValue: number | undefined,
    localState: LifeSpanBoostButtonState,
    configComponents: ConfigComponents
): Amount => {
    if (boostValue === 0) {
        localState.setDisabled(true);
    }

    if (potentialReturn !== null && boostValue !== undefined && boostValue !== 0) {
        return potentialReturn.add(configComponents.precision.newFromAnything(boostValue.toString()));
    }

    return new Amount('0');
};

export const LifeSpanBoostButton = observer('LifeSpanBoostButton', (props: LifeSpanBoostButtonType): JSX.Element => {
    const [localState] = useState(() => new LifeSpanBoostButtonState());
    const { appLayoutsState, appSportsBookState } = useAppStateContext();

    const { betSlipState } = appSportsBookState;
    const { isPlacingBet } = betSlipState.betSlipSummaryState;
    const { possibleBetsRequestState, referralState } = betSlipState;

    const { sdkCustomer, lifeSpanState, configComponents } = appLayoutsState;

    const { selectionBoost, activateBoost, deactivateBoost, potentialReturn, selectionId, stakePerLine } = props;
    const returnWithBoost = renderReturnWithBoost(
        potentialReturn,
        selectionBoost.selectionInfo?.reward?.value,
        localState,
        configComponents
    );
    const selectionReturn = sdkCustomer.money(returnWithBoost);

    const isLoading = lifeSpanState.lifeSpanSocketState.buttonIsLoading(selectionId.rawId);

    const buttonIsLoading =
        (isPlacingBet && !referralState.isBetSlipOffer) ||
        possibleBetsRequestState.isLoading ||
        isLoading === true ||
        localState.isLoading;

    const currency = selectionBoost.selectionInfo?.currency === 'EUR' ? 'EUR' : 'GBP';
    const maxBoostedStake = selectionBoost.selectionInfo?.reward?.maxBoostedStake ?? null;
    const maxStake = maxBoostedStake === null ? null : maxBoostedStake[currency];

    const stakeValue = stakePerLine;
    const maxStakeValue = maxStake === null ? null : new Amount(maxStake.toString());

    const isDisableByMaxStake =
        stakeValue === null || maxStakeValue === null ? false : stakeValue.isGreaterThan(maxStakeValue);

    const buttonIsDisabled =
        potentialReturn?.isEqualWith(new Amount('0')) === true ||
        returnWithBoost.isEqualWith(new Amount('0')) === true ||
        isDisableByMaxStake === true;

    // @ts-expect-error THIS VARIABLE WILL BE DELETED AFTER THE END OF AB TESTING
    const ABTestingGlobalVariable: undefined | 'variantOne' | 'variantTwo' = window.lifespanBoostButton;

    const triggerActivateBoost = (): void => {
        localState.setDisabled(false);
        localState.isLoading = true;
        if (selectionBoost.selectionInfo !== null) {
            localState.isLoading = false;
            activateBoost(selectionBoost.selectionInfo);
        }
    };

    const triggerDeactivateBoost = (): void => {
        localState.setDisabled(true);
        localState.isLoading = true;
        if (selectionBoost.selectionInfo !== null) {
            deactivateBoost(selectionBoost.selectionInfo);
            localState.isLoading = false;
        }
    };

    if (selectionBoost.selectionInfo?.reward?.active === true) {
        return (
            <>
                <TriangleUp />
                <LifeSpanBoostButtonClickedWrapper
                    isDisabled={
                        ((isPlacingBet || possibleBetsRequestState.isLoading) && !referralState.isBetSlipOffer) ||
                        localState.isDisabled === true
                    }
                >
                    {localState.isLoading === true || isLoading ? (
                        <SpinnerLight type='basic' />
                    ) : (
                        <>
                            <BoostWrapperIcon />
                            Boost applied to selection
                            <LifeSpanBoostButtonCloseIcon onClick={triggerDeactivateBoost} />
                        </>
                    )}
                </LifeSpanBoostButtonClickedWrapper>
            </>
        );
    }

    if (ABTestingGlobalVariable !== undefined) {
        if (isLoading === true || possibleBetsRequestState.isLoading || localState.isLoading === true) {
            return (
                <LifeSpanBoostButtonWrapper size='large'>
                    <SpinnerDark type='basic' />
                </LifeSpanBoostButtonWrapper>
            );
        }
        switch (ABTestingGlobalVariable) {
            case 'variantOne':
                return (
                    <ABTestButtonOneWrapper
                        size='large'
                        isLoading={buttonIsLoading}
                        disabled={buttonIsDisabled}
                        onClick={triggerActivateBoost}
                    >
                        {renderTextOne(selectionReturn, potentialReturn)}
                    </ABTestButtonOneWrapper>
                );
            case 'variantTwo':
                return (
                    <LifeSpanBoostButtonWrapper
                        size='large'
                        isLoading={buttonIsLoading}
                        disabled={buttonIsDisabled}
                        onClick={triggerActivateBoost}
                    >
                        <BoostWrapperIcon />
                        <span>{renderText(selectionReturn, potentialReturn)}</span>
                    </LifeSpanBoostButtonWrapper>
                );
            default:
                return assertNever('ABTestingGlobalVariable', ABTestingGlobalVariable);
        }
    }

    return (
        <>
            <TriangleUp />
            <ABTestButtonTwoWrapper>
                {renderTextTwo(selectionReturn, potentialReturn)}
                <ABTestButtonTwo
                    size='xs'
                    isLoading={buttonIsLoading}
                    disabled={buttonIsDisabled}
                    onClick={triggerActivateBoost}
                >
                    Apply
                </ABTestButtonTwo>
            </ABTestButtonTwoWrapper>
        </>
    );
});
