import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { calculateRaceStatus } from 'src/domains/sportsbook/utils/filterRacesWithFinishState';
import {
    MeetingsEventLink,
    RacingPostIconWrapper,
    TimeData,
} from 'src/domains/sportsbook/webview/components/meetingsList/MeetingsList.style';
import {
    FakeCheckboxCheckIcon,
    FakeCheckboxWrapper,
} from 'src/domains/sportsbook/webview/components/meetingsRow/MettingsEvent.style';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { DateTime } from 'src_common/utils/time/time';

interface PropsTypes {
    eventId: EventId;
    selected: boolean;
    isBuilding: boolean;
    onChoose?: (eventId: EventId) => void;
}

export const MeetingsEvent = observer('MeetingsEvent', (props: PropsTypes) => {
    const { eventId, selected, isBuilding } = props;
    const { appLayoutsState } = useAppStateContext();
    const { buildRacecardCheckboxColorMode } = appLayoutsState.configComponents.config;

    const onChoose = (): void => {
        const { isBuilding, onChoose, eventId } = props;
        const event = eventId.getEventModel();

        if (event !== null) {
            const isInProgress = calculateRaceStatus(event.timeSettingsTimeline, event.state) === 'RaceStatusInProgress';
            if (isBuilding && !isInProgress) {
                if (onChoose !== undefined) {
                    onChoose(eventId);
                }
            }
        }

    };

    const event = eventId.getEventModel();

    if (event === null) {
        return null;
    }

    const eventMarket = event.marketRaceWinnerAll[0] ?? null;
    if (eventMarket === null) {
        return null;
    }

    const startTime = DateTime.from(event.timeSettingsStartTime);
    const eventSportName = event.sport;
    const raceStatus = calculateRaceStatus(event.timeSettingsTimeline, event.state);

    const renderIconOrCheckbox = (): JSX.Element | null => {
        if (isBuilding && event.active) {
            return (
                <FakeCheckboxWrapper mode={buildRacecardCheckboxColorMode} isSelected={selected === true}>
                    {selected === true ? <FakeCheckboxCheckIcon /> : null}
                </FakeCheckboxWrapper>
            );
        }
        if (raceStatus === 'RaceStatusFinished') {
            return <RacingPostIconWrapper />;
        }
        return null;
    };

    return event.antePost ? null : (
        <MeetingsEventLink
            to={isBuilding ? undefined : {
                name: 'racecard',
                collection: event.competition,
                sport: eventSportName,
                selected: event.id,
            }}
            onClick={onChoose}
            isInProgress={raceStatus === 'RaceStatusInProgress'}
            isFinished={raceStatus === 'RaceStatusFinished'}
        >
            {renderIconOrCheckbox()}
            <TimeData data-test-event-id={event.id}>{startTime?.format('HH:mm')}</TimeData>
        </MeetingsEventLink>
    );
});
