import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetComment } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetComment';
import { BetSingleLegResultWrapper } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet.style';
import {
    BetHistoryType,
    BetHistoryLegType,
} from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

export const BetSingleLegResult = observer<{ bet: BetHistoryType; leg: BetHistoryLegType }>(
    'BetSingleLegResult',
    ({ bet, leg }): JSX.Element => {
        const resultStatus = leg.result?.type ?? null;
        const betStatus = bet.cashOut ? 'Cashed Out' : bet.status;
        const status = resultStatus ?? betStatus;
        const betComment = bet.comment ?? null;

        return (
            <BetSingleLegResultWrapper data-test='bet-result'>
                <I18n
                    langKey='bets.list.bet.status'
                    defaultText='Status - {status}'
                    params={{ status }}
                />
                {betComment === null ? null : <BetComment message={betComment} />}
            </BetSingleLegResultWrapper>
        );
    }
);
