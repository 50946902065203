import * as React from 'react';

interface PropsType {
    className?: string;
    src?: string;
    // target?: string,
    title?: string;
    onMessage?: (data: string) => void;
    allowFullScreen?: true;
    dataTest?: string;
}

export class Iframe extends React.Component<PropsType> {
    public ref: HTMLIFrameElement | null = null;

    private onMessage = (event: MessageEvent): void => {
        if (this.ref !== null && this.props.onMessage !== undefined) {
            if (this.ref.contentWindow === event.source) {
                if (typeof event.data === 'string') {
                    this.props.onMessage(event.data);
                } else if (event.data.name !== undefined && typeof event.data.name === 'string') {
                    this.props.onMessage(event.data.name);
                } else if (event.data.gcmevent !== undefined && typeof event.data.gcmevent === 'string') {
                    this.props.onMessage(event.data.gcmevent);
                }
                if (event.data.eventType !== undefined) {
                    switch (event.data.eventType) {
                        case 'onCloseIframeSuccess':
                            this.props.onMessage('success');
                            break;
                        case 'onCloseIframeFail':
                            this.props.onMessage('serverIssue');
                            break;
                    }
                }
                if (event.data.rgMessage === 'gprg_Listening') {
                    this.props.onMessage('gprg_Listening');
                }
                if (event.data.rgMessage === 'gprg_UserAction' && typeof event.data.payload.action === 'string') {
                    const userAction: string = event.data.payload.action;
                    this.props.onMessage(`gprg_UserAction_${userAction}`);
                }
            }
        }
    };

    public componentDidMount(): void {
        window.addEventListener('message', this.onMessage);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('message', this.onMessage);
    }

    private setRef = (ref: HTMLIFrameElement | null): void => {
        this.ref = ref;
    };

    public render(): JSX.Element {
        const { className, src, title, allowFullScreen, dataTest } = this.props;

        return (
            <iframe
                className={className}
                src={src}
                ref={this.setRef}
                title={title}
                allowFullScreen={allowFullScreen}
                data-test={dataTest}
            />
        );
    }
}
