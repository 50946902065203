import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { CheckboxWrapper, CheckboxInput, CheckboxLabel, CheckMarkWrapper, CheckboxTxtWrapper } from './Checkbox.style';
import { CheckboxState } from './CheckboxState';

interface CheckboxTypes {
    children: string | JSX.Element;
    isDisabled?: boolean;
    /** @default 'right' */
    labelSide?: 'left' | 'right';
    size?: string;
    state: FormInputState<boolean,boolean>;
    mode?: 'light' | 'dark';
    dataTest?: string;
}

export const Checkbox = observer('Checkbox', ({
    children,
    isDisabled,
    labelSide = 'right',
    size,
    state,
    mode= 'light',
    dataTest
}: CheckboxTypes) => {
    const [checkboxState] = React.useState(() => new CheckboxState(state));

    return (
        <CheckboxLabel>
            {
                labelSide === 'left' ?
                    <CheckboxTxtWrapper labelSide={labelSide}>
                        {children}
                    </CheckboxTxtWrapper> :
                    null
            }

            <CheckboxInput
                type='checkbox'
                checked={checkboxState.value}
                disabled={isDisabled}
                onChange={checkboxState.handleChange}
            />
            <CheckboxWrapper
                data-test={dataTest}
                disabled={isDisabled}
                isChecked={checkboxState.value}
                size={size}
                mode={mode}
            >
                <CheckMarkWrapper
                    isChecked={checkboxState.value}
                />
            </CheckboxWrapper>

            {
                labelSide === 'right' ?
                    <CheckboxTxtWrapper labelSide={labelSide} data-test='checkbox-txt-wrapper'>
                        {children}
                    </CheckboxTxtWrapper> :
                    null
            }

        </CheckboxLabel>
    );
});
