import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { sortSelections, sortGreyhoundsSelections } from 'src/domains/layouts/utils/sortSelections';
import { distance as formatDistance } from 'src/domains/sportsbook/utils/format';
import { QuickPickColumn } from './QuickPickColumn';
import { QuickPickName } from './QuickPickName';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { computed, observable, makeObservable } from 'mobx';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { BogIndicatorRaceWrapper } from 'src/domains/sportsbook/webview/components/bogIndicator/BogIndicatorRace';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import {
    RaceHeaderDetailsName,
    RaceHeaderWrapper,
    RaceHeaderDetails,
    RaceHeaderListEwTerms,
    RaceHeaderListItem,
    RaceHeaderListMeta,
    RaceHeaderListItemMeta,
    RaceHeaderListItemSeparatorMeta,
    RaceHeaderDetailsDistance,
    QuickPickStyle, QuickPickInfo, QuickPickContent, LoaderWrapperQuickPick, QuickPickTableWrapper, QuickPickHeader
} from 'src/domains/sportsbook/webview/components/quickPick/QuickPick.style';
import { EventId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

type SportNameType = 'horseracing' | 'greyhoundracing';

interface SelectItem {
    event: EventModel;
    market: MarketModel;
}

interface ListEventsProps {
    event: EventModel;
    market: MarketModel;
}

type SortFuncType = (data: Array<SelectionModel>) => Array<SelectionModel>;

const emptySort = (data: Array<SelectionModel>): Array<SelectionModel> => {
    return data;
};

const getSortForSport = (sport: string): SortFuncType => {
    if (sport === 'horseracing') {
        return sortSelections;
    }

    if (sport === 'greyhoundracing') {
        return sortGreyhoundsSelections;
    }

    return emptySort;
};

const getSelectItem = (event: EventModel | null): SelectItem | null => {
    if (event === null) {
        return null;
    }

    const market = event.marketRaceWinner[0] ?? null;
    if (market !== null) {
        return {
            event,
            market
        };
    }

    return null;
};

const NoRacesText = observer('NoRacesText', () => (
    <QuickPickInfo>
        <I18n langKey='events.quick-pick.empty' defaultText='There are no races available at the moment. Please check again later.' />
    </QuickPickInfo>
));

function buildEventsList(events: Array<EventModel>): ListEventsProps[] {
    const out: Array<ListEventsProps> = [];

    for (const event of events) {
        const selectItem = getSelectItem(event);
        if (selectItem !== null) {
            out.push(selectItem);
        }
    }

    const outNew = out.filter((e) => e.event.display); //&& e.event.active

    return sortBy(outNew, e => e.event.timeSettingsStartTime);
}


interface MetaPropsType {
    race: EventModel;
}

const MetaQuickPick = observer('MetaQuickPick', (props: MetaPropsType) => {
    const { race } = props;

    const goingInfo = race.feedDataGoing;
    const courseType = race.feedCourseType;
    const handicapCond = race.feedHandicap === 'Y';
    const distance = formatDistance(race.feedDistance);

    return (
        <RaceHeaderListMeta>
            {handicapCond ?
                <RaceHeaderListItemMeta>
                    <I18n langKey='events.handicap.label' defaultText='Handicap' />
                </RaceHeaderListItemMeta> : null
            }
            {courseType === undefined ? null : <RaceHeaderListItemMeta>{courseType}</RaceHeaderListItemMeta>}
            <RaceHeaderListItemMeta>{distance}</RaceHeaderListItemMeta>
            {courseType !== undefined || handicapCond ? <RaceHeaderListItemSeparatorMeta>&nbsp;</RaceHeaderListItemSeparatorMeta> : null}
            {goingInfo === undefined ? null : <RaceHeaderListItemMeta>{goingInfo}</RaceHeaderListItemMeta>}
        </RaceHeaderListMeta>
    );
});

interface EWThermsPropsType {
    market: MarketModel;
}

const EWTerms = observer('EWTerms', (props: EWThermsPropsType) => {
    const { market } = props;

    if (market.eachWayOffered) {
        const reduction = market.eachWayTermsReduction;
        const places = market.eachWayTermsPlaces;

        return (
            <RaceHeaderListEwTerms>
                <RaceHeaderListItem>
                    <I18n langKey='events.quick-pick.each-way-terms.label' defaultText='EW:' />
                </RaceHeaderListItem>
                <RaceHeaderListItem>{reduction}</RaceHeaderListItem>
                <RaceHeaderListItem>
                    <I18n langKey='events.quick-pick.each-way-terms.for' defaultText='for' />
                </RaceHeaderListItem>
                {places === undefined ? null :(
                    <RaceHeaderListItem>
                        <I18n langKey='events.quick-pick.each-way-terms.places' defaultText='{places} Places' params={{ places: places }} />
                    </RaceHeaderListItem>
                )
                }
            </RaceHeaderListEwTerms>
        );
    }

    return null;
});

class SelectItemState {
    @observable private selectItem: EventId | null = null;

    public setSelect = (eventId: EventId): void => {
        this.selectItem = eventId;
    };

    public constructor() {
        makeObservable(this);
    }

    public getSelectEventId(events: Array<SelectItem>): EventId | null {
        for (const event of events) {
            if (event.event.id2 === this.selectItem) {
                return this.selectItem;
            }
        }

        const firstElement = events[0];
        if (firstElement !== undefined) {
            return firstElement.event.id2;
        }

        return null;
    }

    public getEventBySelectedId(): SelectItem | null {
        const event = this.selectItem === null ? null : this.selectItem.getEventModel();

        if (event !== null) {
            const market = event.marketRaceWinner[0] ?? null;

            if (market !== null) {
                return ({
                    event,
                    market: market
                });
            }
        }

        return null;
    }
}

interface PropsType {
    sport: SportNameType;
    shared: boolean;
    label: JSX.Element | string;
    isLoading: boolean;
}

export class QuickPickState {
    public constructor(
        private readonly eventsCollectionState: EventsCollectionState,
        private readonly sport: SportNameType,

    ) {
        makeObservable(this);
        this.eventsCollectionState = eventsCollectionState;
        this.sport = sport;
    }

    private getEventsList = (sport: SportNameType): EventsCollectionList => this.eventsCollectionState.getListNextOffRacingWithoutFinishState(sport);

    @computed public get collectionEvents(): Array<EventModel> {
        const out: Array<EventModel> = [];
        const eventsList = this.getEventsList(this.sport);

        for (const id of eventsList.ids2) {
            const event = id.getEventModel();

            if (event !== null) {
                out.push(event);
            }
        }

        return out;
    }
}

export const QuickPick = observer('QuickPick', (props: PropsType) => {
    const [hoverSelect, setHoverSelect] = useState<SelectionId | null>(null);
    const [selectItem] = useState(() => new SelectItemState());

    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { eventsCollection } = appSportsBookState;
    const { sdkCustomer, configComponents } = appLayoutsState;
    const { isDarkUniverse } = configComponents.config;

    const [quickPickState] = useState(() => new QuickPickState(eventsCollection, props.sport));

    const events = quickPickState.collectionEvents ?? null;

    const eventsList = buildEventsList(events);
    const selectId = selectItem.getSelectEventId(eventsList);

    if (props.isLoading) {
        return (
            <QuickPickInfo>
                <LoaderWrapperQuickPick
                    size={14}
                    version={isDarkUniverse ? 'light' : 'dark'}
                >
                    <I18n langKey='events.quick-pick.loading' defaultText='Loading races...' />
                </LoaderWrapperQuickPick>
            </QuickPickInfo>
        );
    } else {
        if (events.length === 0) {
            return (
                <NoRacesText />
            );
        }

        const selectEvent = selectId === null ? null : getSelectItem(selectId.getEventModel());

        if (selectEvent === null) {
            return (
                <NoRacesText />
            );
        }

        const { event, market } = selectEvent;

        const bogValue = sdkCustomer.basicData.valueReady?.bpEnabled ?? false;

        const bogcard = bogValue === true && market.bp === true ? <BogIndicatorRaceWrapper /> : null;

        const { name, sport } = event;

        return (
            <QuickPickStyle className='quick-pick'>
                <div>
                    <QuickPickHeader>
                        <RaceHeaderWrapper className='race-header'>
                            <RaceHeaderDetails className='race-header--details'>
                                <RaceHeaderDetailsName>
                                    {name}
                                </RaceHeaderDetailsName>

                                {bogcard}

                                <RaceHeaderDetailsDistance className='race-header--details--distance'>
                                    <MetaQuickPick race={event} />
                                    <EWTerms market={market} />
                                </RaceHeaderDetailsDistance>
                            </RaceHeaderDetails>
                        </RaceHeaderWrapper>
                    </QuickPickHeader>

                    <div key='win-only'>
                        <QuickPickContent>
                            <QuickPickName
                                race={event}
                                market={market}
                                sort={getSortForSport(sport)}
                                sport={sport}
                                hoveredSelection={hoverSelect}
                            />
                            <QuickPickTableWrapper>
                                {
                                    eventsList.map(({ event, market }) => (
                                        <QuickPickColumn
                                            key={event.id}
                                            event={event}
                                            market={event.marketRaceWinner.filter((m: MarketModel) => m.id === market.id)}
                                            selected={selectId === event.id2}
                                            onHoverSelection={setHoverSelect}
                                            onSelect={selectItem.setSelect}
                                            sort={getSortForSport(sport)}
                                        />
                                    ))
                                }
                            </QuickPickTableWrapper>
                        </QuickPickContent>
                    </div>
                </div>
            </QuickPickStyle>
        );
    }
});


