import { isNewDesign } from 'src/utils/new-design';

export const styleConfig = {
    color: {
        black: '#000000',
        gray: '#707070',
        white: '#ffffff',
        orange: '#FF6710',
    },
    font: {
        family: isNewDesign() ? "'Open Sans', Arial, sans-serif" : 'Montserrat, Arial, sans-serif',
        weight: '600',
    },
    width: {
        mobile: '457px',
        tablet: '841px',
        desktop: '1381px',
    },
    height: {
        smallMobile: '567px',
    },
};
