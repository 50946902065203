import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { UniverseComponent } from 'src/domains/common/universes';

export const HeaderLogo = observer('HeaderLogo', () => {
    const { appCasinoState } = useAppStateContext();
    const { gameModalState } = appCasinoState;

    return (
        <UniverseComponent>
            {(universeVersion): React.ReactElement => (
                <universeVersion.headerLink onClick={gameModalState.closeGameAndRedirectToHomePage} dataTest='operator-logo'>
                    <universeVersion.headerLogo />
                </universeVersion.headerLink>
            )}
        </UniverseComponent>
    );
});
