import { computed, makeObservable } from 'mobx';
import { ModernizrDetectType } from 'src/domains/layouts/state/environmentState/modernizr/ModernizrType';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { EnvVariables } from 'src/domains/common/contextStore/EnvVariables';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { ThemeColorsStarType } from 'src/domains/common/universes';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';
import { Value } from 'src_common/common/mobx-utils/Value';

type CasinoAppVersionModelType = {
    app_version: string;
    is_casino_active: boolean;
    universe: string;
};

const isWindowIosVersion = createGuard(
    t.interface({
        device: t.union([t.string, t.null, t.undefined]),
        deviceVersion: t.union([t.string, t.null, t.undefined]),
    })
);

interface DeviceInfoType {
    device: string;
    deviceVersion: string;
}

const iosVersionConnect: Value<DeviceInfoType | null> = new Value<DeviceInfoType | null>(null, (setValue) => {
    const timer = setInterval((): void => {
        // tslint:disable-next-line
        if (typeof window !== 'undefined') {
            if (isWindowIosVersion(window)) {
                const deviceVersion = window.deviceVersion ?? null;
                const device = window.device ?? null;
                if (deviceVersion !== null && device !== null) {
                    const deviceInfo: DeviceInfoType = {
                        device,
                        deviceVersion,
                    };
                    setValue(deviceInfo);
                }
            }
        }
    }, 1000);

    return () => {
        clearInterval(timer);
    };
});

export class EmotionTheme {
    public readonly star: ThemeColorsStarType;

    public constructor(colorConfig: ThemeColorsStarType) {
        this.star = colorConfig;
    }
}

export class EnvironmentState {
    public readonly envVariables: EnvVariables;
    public readonly websiteBaseUrl: string;
    public readonly emotionTheme: EmotionTheme;
    public readonly modernizrDetect: ModernizrDetectType | null;
    public readonly isBrowser: boolean;
    public readonly casinoMobileAppVersions: Resource<Array<CasinoAppVersionModelType>>;

    public static get = AutoWeakMap.create((common: Common) => new EnvironmentState(common));

    private constructor(common: Common) {
        makeObservable(this);
        const { envVariables, modernizrDetect, websiteBaseUrl, isBrowser, themeColors } = common;

        const colorConfig = themeColors;

        this.envVariables = envVariables;
        this.websiteBaseUrl = websiteBaseUrl;
        this.emotionTheme = new EmotionTheme(colorConfig);
        this.modernizrDetect = modernizrDetect;
        this.isBrowser = isBrowser;

        this.casinoMobileAppVersions = new Resource(async (): Promise<Array<CasinoAppVersionModelType>> => {
            const result = await common.trpcClient.client.cms.getMobileCasinoVersion.query();

            if (result.status === 200) {
                return result.body;
            }

            throw Error(`Expected response 200, received http code ${result.status}`);
        });
    }

    public isAppIOSWrapper(): boolean {
        //iOS-app
        return (this.envVariables.mobileWrapperType ?? '').toLowerCase().includes('ios-app');
    }

    private isAppAndroidWrapper(): boolean {
        // android-app
        return (this.envVariables.mobileWrapperType ?? '').toLowerCase().includes('android-app');
    }

    public isMobileApp(): boolean {
        return this.isAppIOSWrapper() || this.isAppAndroidWrapper();
    }

    @computed public get windowDeviceVersion(): string | null {
        const deviceInfo = iosVersionConnect.getValue();

        return deviceInfo?.deviceVersion ?? null;
    }

    @computed public get windowDevice(): string | null {
        const deviceInfo = iosVersionConnect.getValue();

        return deviceInfo?.device ?? null;
    }

    public get casinoMobileAppVersionsValue(): Array<CasinoAppVersionModelType> {
        const versions = this.casinoMobileAppVersions.get();
        if (versions.type === 'ready') {
            return versions.value;
        }
        return [];
    }

    @computed public get isAppIOSDeviceWrapper(): boolean {
        const device = this.windowDevice ?? this.envVariables.mobileWrapperType ?? '';

        return device.toLowerCase().includes('ios-app');
    }

    public get mobileAppVersion(): string | null {
        return this.envVariables.mobileWrapperVersion ?? this.windowDeviceVersion;
    }
}
