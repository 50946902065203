import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    EventRowLoader,
    EventsLoader,
    FirstLineLoader,
    SecondLineLoader,
    ThirdLineLoader,
    SelectionLoader,
    SelectionsWrapper,
    Wrapper
} from 'src/domains/layouts/shared/loaders';

export const EventLoader = observer('EventLoader', () => {
    const selections = [];

    for (let i = 0; i < 3; i++) {
        selections.push(<SelectionLoader key={i} />);
    }
    return (
        <Wrapper>
            <EventsLoader>
                <EventRowLoader>
                    <FirstLineLoader />
                    <SecondLineLoader />
                    <ThirdLineLoader />
                </EventRowLoader>

            </EventsLoader>
            <SelectionsWrapper>
                {selections}
            </SelectionsWrapper>
        </Wrapper>
    );
});
