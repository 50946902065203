import { action, computed, observable, makeObservable } from 'mobx';

export class NebetDefaultCompetitionState {
    @observable private competitionDefault: string | null = null;

    @action public setDefaultCompetitionFilter = (competitionName: string | null): void => {
        this.competitionDefault = competitionName;
    };

    public constructor() {
        makeObservable(this);
    }

    @computed public get getCompetitionDefault(): string | null {
        return this.competitionDefault;
    }
}
