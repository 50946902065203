import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersState } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { Filter } from 'src/domains/layouts/webview/components/filters/Filters.style';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';

interface FiltersPropsType {
    states: FiltersState[];
    separators?: React.ReactNode[];
    skin?: FiltersSkin;
}

export const MultipleFilters = observer<FiltersPropsType>('MultipleFilters', (props) => {
    const { states, separators = [], skin = FiltersSkin.PRIMARY } = props;

    return (
        <>
            {states
                .map((state, stateIndex) => {
                    const filters: React.ReactNode[] = state.filters.map((filter) => {
                        const { id, key, label } = filter;
                        const isActive = state.isFilterActive(id);
                        const isDisabled = state.isFilterDisabled(id);
                        const title = typeof label === 'string' ? label : undefined;

                        return (
                            <Filter
                                key={`${state.id}_${key}`}
                                title={title}
                                disabled={isDisabled}
                                skin={skin}
                                onClick={state.bindSetActiveFilterId(id)}
                                isActive={isActive}
                                isDisabled={isDisabled}
                                data-test-filter-key={key}
                            >
                                {label}
                            </Filter>
                        );
                    });

                    if (stateIndex > 0) {
                        filters.unshift(separators[stateIndex - 1] ?? null);
                    }
                    return filters;
                })
                .flat()}
        </>
    );
});
