import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventHeaderGroup, EventHeaderGroupName, EventHeaderGroupSelection, EventHeaderGroupSelectionName } from 'src/domains/sportsbook/shared/Components';
import { getMarketNameNFL } from 'src/domains/layouts/shared/translations/markets';
import { observer } from 'src/utils/mobx-react';
import { CompetitionName } from './HomepageWidgetNFLHeader/HomepageWidgetNFLHeader.style';

export const HomepageWidgetNFLHeader = observer('HomepageWidgetNFLHeader', (): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const marketsNamesNFL = getMarketNameNFL(languagesState.getTranslation);

    return (
        <EventHeaderGroup headerStyle='sportLevel' small={true}>
            <EventHeaderGroupName>
                <CompetitionName>NFL</CompetitionName>
            </EventHeaderGroupName>

            {marketsNamesNFL.map((marketName) => {
                return (
                    <EventHeaderGroupSelection key={marketName}>
                        <EventHeaderGroupSelectionName>
                            {marketName}
                        </EventHeaderGroupSelectionName>
                    </EventHeaderGroupSelection>
                );
            })}
        </EventHeaderGroup>
    );
});
