import React from 'react';
import { BettingTab, LifeSpanWallet } from 'src/domains/sportsbook/shared/Components';
import { CustomScrollbar } from 'src/domains/players/webview/components/Account/accountPanel/customScrollbar/CustomScrollbar';
import {
    AccountContentWrapperDesktop,
    ScrollbarWrapper,
} from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { AccountNavigation } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation';
import { observer } from 'src/utils/mobx-react';
import { MiniGames } from 'src/domains/casino/shared/Components';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { useAppStateContext } from 'src/appState/AppState';
import { NotificationsList } from 'src/domains/layouts/webview/components/notificationsList/NotificationsList';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { TwitterWidget } from 'src/domains/layouts/webview/components/twitterWIdget/TwitterWidget';

interface PropsTypes {
    currentView: RouteViewType | null;
    isNotificationsList: boolean;
}

export const BetsPanel = observer('BetsPanel', (props: PropsTypes): JSX.Element => {
    const isCasino = props.currentView?.name === 'casino';
    const isVirtuals = props.currentView?.name === 'virtuals';
    const isLiveCasino = props.currentView?.name === 'live-casino';

    const isAnyCasinoPage = isCasino || isVirtuals || isLiveCasino;

    const { appLayoutsState, appCasinoState, appSportsBookState } = useAppStateContext();
    const { configComponents, featureState, lifeSpanState } = appLayoutsState;
    const { casinoMiniGames, socialWidgets } = configComponents.config;
    const { betWidgetsState } = appSportsBookState;
    const { isIndicatorVisible } = betWidgetsState;
    const isAllowCasinoInIOSWrapper = featureState.allowCasinoInIOSWrapperNew;

    const isTwitterWidgetNotVisible = isAnyCasinoPage || isIndicatorVisible;
    const widthInner = getWindowInnerWidth();

    return (
        <AccountContentWrapperDesktop>
            <AccountNavigation currentView={props.currentView} />
            <ScrollbarWrapper>
                {widthInner !== null && widthInner >= 1024 && lifeSpanState.isWalletOpen === true ? (
                    <LifeSpanWallet />
                ) : null}

                <CustomScrollbar>
                    {isCasino === false && isVirtuals === false ? (
                        <>
                            <BettingTab key='betting-tab' />
                            {casinoMiniGames && isAllowCasinoInIOSWrapper === true ? (
                                <MiniGames appCasinoState={appCasinoState} />
                            ) : null}
                        </>
                    ) : null}

                    {socialWidgets.twitter === null ? null : (
                        <TwitterWidget
                            isWidgetNotVisible={isTwitterWidgetNotVisible}
                            socialWidgetData={socialWidgets.twitter}
                        />
                    )}

                    <NotificationsList key='notifications-list' isNotificationsList={props.isNotificationsList} />
                </CustomScrollbar>
            </ScrollbarWrapper>
        </AccountContentWrapperDesktop>
    );
});
