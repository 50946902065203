import React from 'react';
import { LinkWrapper } from './ContactUs.style';
import { getContactElements } from './ContactUs.utils';
import { observer } from 'src/utils/mobx-react';

type ContactUsElementProps = {
    element: {
        contactType: string;
        isActive: boolean;
        contactValue: string;
        contactLabel: null | undefined | string;
    };
};

export const ContactUsElement = observer(
    'ContactUsElement',
    ({ element }: ContactUsElementProps): JSX.Element | null => {
        const { contactType, contactLabel, contactValue, isActive } = element;

        if (!isActive) {
            return null;
        }
        const { Icon, LabelValueText, contactValueTo } = getContactElements(contactType, contactLabel, contactValue);

        return (
            <LinkWrapper
                key={contactType}
                to={contactValueTo}
                dataTest={`${contactType}-contact-us`}
            >
                {Icon}
                {LabelValueText}
            </LinkWrapper>
        );
    }
);
