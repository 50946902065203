import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { DateTime } from 'src_common/utils/time/time';

export function isToday(time: string): boolean {
    const today = DateTime.from(time)?.startOfDays();
    if (today === undefined) {
        console.error('Today value is undefined. Time: ', time);
        return false;
    }
    return DateTime.current().startOfDays().isSameDay(today);
}

export function isTomorrow(time: string): boolean {
    const tomorrow = DateTime.from(time)?.startOfDays();
    if (tomorrow === undefined) {
        console.error('Tomorrow value is undefined. Time: ', time);
        return false;
    }
    return DateTime.current().startOfDays().addDays(1).isSameDay(tomorrow);
}

export function getSimpleDate(time: string, language: LanguagesState): string {
    const currentTime = DateTime.from(time);
    if (currentTime === null) {
        return '';
    }
    return (isToday(time) ? language.getTranslation('events.time-options.today', 'Today') : currentTime.format('DD MMM')) + currentTime.format(' HH:mm');
}

export function getSimpleDateTodayTomorrow(time: string, language: LanguagesState): string {
    const currentTime = DateTime.from(time);

    if (currentTime === null) {
        return '';
    }

    return (
        isToday(time) === true
            ? language.getTranslation('events.time-options.today', 'Today')
            : isTomorrow(time) === true
                ? language.getTranslation('events.time-options.tomorrow', 'Tomorrow')
                : currentTime.format('DD MMM')
    ) + currentTime.format(' HH:mm');
}
