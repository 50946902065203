import * as React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { SelectionGroupState } from './SelectionGroupState';
import { observer } from 'src/utils/mobx-react';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { SelectionGroupWrap, SelectionsContainer, SectionWrapper } from './SelectionGroup.style';
import { SelectionRow } from './selectionRow/SelectionRow';
import { SelectionRowTemplate } from './selectionTemplateRow/SelectionTemplateRow';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsTypes {
    eventId: EventId;
    marketIdMain: MarketId;
    selections: Record<string, SelectionModel[]>;
}

export const SelectionGroup = observer('SelectionGroup',({ marketIdMain, eventId, selections }: PropsTypes) => {
    const { appSportsBookState } = useAppStateContext();
    const { models } = appSportsBookState;
    const [state] = React.useState(() => new SelectionGroupState(models, selections));

    const limitColumnsToDisplay = 4;
    const sectionClass = ` ${ state.newAllSelectionsProps !== null && Object.keys(state.newAllSelectionsProps).length > 3 ? 'selections-group--multiple' : ''}`;

    return (
        <SelectionGroupWrap>
            <SectionWrapper className={sectionClass}>
                <SelectionsContainer>
                    {
                        state.newAllSelectionsProps !== null && Object.entries(state.newAllSelectionsProps).map(([key, itemsIn]: [string, SelectionModel[]]) => {
                            const selection = itemsIn[0];

                            if (selection === undefined) {
                                console.error('fatal - selection should not be null');
                                return null;
                            }

                            if (state.selectionSize !== null && state.selectionSize > 0 && state.selectionSize < limitColumnsToDisplay) {
                                return (
                                    <SelectionRow
                                        key={key} 
                                        selection={selection} 
                                        eventId={eventId} 
                                        marketId={marketIdMain} 
                                        isExpanded={state.isExpanded} 
                                        itemsIn={itemsIn} 
                                    />
                                );
                            }

                            else {
                                // Force [n] columns if it's only one template
                                return (
                                    <SelectionRowTemplate
                                        key={key}
                                        selection={selection}
                                        eventId={eventId}
                                        marketId={marketIdMain}
                                        isExpanded={state.isExpanded}
                                        itemsIn={itemsIn}
                                        biggestGroup={state.biggestGroup}
                                        toggleExpand={state.toggleExpand}
                                    />
                                );
                            }
                        })
                    }

                </SelectionsContainer>
            </SectionWrapper>
        </SelectionGroupWrap>
    );
});
