import React from 'react';
import { PromoSignUpStep } from 'src/domains/players/webview/components/Account/promoSidebar/promoSignUpStep/PromoSignUpStep';
import { PromoLogin } from 'src/domains/players/webview/components/Account/promoSidebar/promoLoginPage/PromoLogin';
import { LandingPage } from 'src/domains/players/state/specialEventsState/LandingPage';
import { SidebarContentDirectLink } from '.';

interface SidebarContentPropsType {
    isUserAuthorized: boolean;
    isHTMLBanner: boolean;
    ctaText: string;
    actionTemplate?: string;
    promotionSlug: string;
    lpState: LandingPage;
    onClickDirectLinkHandle: () => void;
}
export const SidebarContent = ({
    isUserAuthorized,
    isHTMLBanner,
    ctaText,
    actionTemplate,
    promotionSlug,
    lpState,
    onClickDirectLinkHandle,
}: SidebarContentPropsType): JSX.Element | null => {
    if (actionTemplate === 'signup') {
        return (
            <PromoSignUpStep
                promotionSlug={promotionSlug}
                lpState={lpState}
            />
        );
    }

    if (!isUserAuthorized && actionTemplate === 'login') {
        return <PromoLogin promotionSlug={promotionSlug} />;
    }

    if (actionTemplate === 'direct-link') {
        return (
            <SidebarContentDirectLink
                onClickDirectLinkHandle={onClickDirectLinkHandle}
                isHTMLBanner={isHTMLBanner}
                ctaText={ctaText}
            />
        );
    }

    return null;
};
