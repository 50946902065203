import React from 'react';
import {
    BetslipIconWrapper,
    CounterQuickHeader,
    LinkName,
    QuickHeaderButton,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

interface BetsButtonType {
    currentView: RouteViewType;
    accountView: string | null;
}

export const BetsButton = observer('BetsButton', ({ currentView, accountView }: BetsButtonType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { betSlipState, rab } = appSportsBookState;
    const { starRouter } = appLayoutsState;

    const { activeBets } = rab;
    const { legsIds2 } = betSlipState;

    const isCasino = currentView.name === 'casino';
    const isLiveCasino = currentView.name === 'live-casino';
    const isBetting = accountView === 'betting';

    if (isBetting && (isCasino || isLiveCasino)) {
        return null;
    }

    const handleClick = (): void => {
        starRouter.redirectToBetslip();
    };

    const bets = legsIds2.length + activeBets.length;
    const isBetCounter = bets > 0 ? <CounterQuickHeader>{bets}</CounterQuickHeader> : null;

    return (
        <QuickHeaderButton
            data-test='account-navigation-bets-link'
            isActive={isBetting}
            onClick={handleClick}
        >
            <BetslipIconWrapper />
            <LinkName>
                <I18n
                    langKey='quick-header.betslip.label'
                    defaultText='Bets'
                />
            </LinkName>
            {isBetCounter}
        </QuickHeaderButton>
    );
});
