import styled from '@emotion/styled';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { withConfig } from 'src/withConfig';

export const PhoneNumberWrapper = styled('div', { label: 'PhoneNumberWrapper' })`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
`;

export const LabelTextWrapper = withConfig(theme => styled('span', { label: 'LabelTextWrapper' })`
    flex: 1 0 100%;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    color: currentcolor;
    line-height: 1;
`);

export const PrefixWrapper = styled('div', { label: 'PrefixWrapper' })`
    flex: 0 0 92px;
`;

export const Phone = styled(Input, { label: 'Phone' })`
    flex: 1 1 calc(100% - 97px);
    margin: 5px 0 0 5px;
`;
