import React, { useState } from 'react';
import { HeadlineCarouselActiveModelType } from 'src/api/config/cms_new/headlineCarouselActive/getHeadlineCarouselActive';
import { observer } from 'src/utils/mobx-react';
import { HeadlineCarouselSlideState, RedirectTypeEnum } from './HeadlineCarouselSlide.state';
import { useAppStateContext } from 'src/appState/AppState';
import { ActionButton, ButtonWrapper, DefaultImage, HeadlineCarouselSlideContent, HeadlineCarouselSlideWrapper, HeadlineCarouselSubTitle, HeadlineCarouselText, HeadlineCarouselTitle, TermsText, TermsWrapper } from './HeadlineCarouselSlide.style';
import { deviceSize } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PropsType {
    headlineCarouselItem: HeadlineCarouselActiveModelType
}

export const HeadlineCarouselSlide = observer('HeadlineCarouselSlide', ({ headlineCarouselItem }: PropsType): JSX.Element | null => {
    const { appCasinoState, appLayoutsState } = useAppStateContext();
    const { starRouter, breakpointsState } = appLayoutsState;

    const [state] = useState(() => new HeadlineCarouselSlideState(headlineCarouselItem, starRouter, appCasinoState));

    const { actionTemplate, bgImagesUrl } = state;
    const {
        title,
        subtitle,
        bg_color,
        btn_title,
        btn_color,
        tc_text
    } = headlineCarouselItem;

    const btnTitle = btn_title ?? '';
    const btnColor = btn_color ?? '';
    const hasRedirection = actionTemplate !== null && actionTemplate !== RedirectTypeEnum.REDIRECT_NONE; 
    const terms = tc_text ?? '';
    const isDesktop = breakpointsState.desktop.isBiggerOrEq;
    return (
        <HeadlineCarouselSlideWrapper bgImagesUrl={bgImagesUrl} hasRedirection={hasRedirection} bgColor={bg_color ?? null} >
            <picture>
                <source media={`(min-width: ${deviceSize.tablet}px)`} srcSet={bgImagesUrl.desktopImg} />
                <source media={`(min-width: ${deviceSize.mobile}px)`} srcSet={bgImagesUrl.tabletImg} />
                <DefaultImage src={bgImagesUrl.mobileImg} alt={title} />
            </picture>
            <HeadlineCarouselSlideContent>
                <HeadlineCarouselText onClick={state.handleRedirect}>
                    <div>
                        <HeadlineCarouselTitle>{title}</HeadlineCarouselTitle>
                        <HeadlineCarouselSubTitle>{subtitle}</HeadlineCarouselSubTitle>
                    </div>
                    {btn_title === '' ? null
                        : <ButtonWrapper>
                            <ActionButton color={btnColor} size={isDesktop === true? 'medium' :'small'}>{btnTitle}</ActionButton>
                        </ButtonWrapper>
                    }
                </HeadlineCarouselText>
                {terms === '' ? null
                    : <TermsWrapper onClick={state.onApplyTC}>
                        <TermsText>
                            <I18n langKey='lk.terms-and-conditions-promo' defaultText=' *New Customers only 18+ UK and Irish Resident only ' />  
                        </TermsText>
                    </TermsWrapper>}
            </HeadlineCarouselSlideContent>
          
        </HeadlineCarouselSlideWrapper >
    );
});
