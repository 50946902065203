
export const CASINO_MINI_GAMES_NAME = 'Casino Mini Games';

export const HOME = 'home';
export const ALL_GAMES = 'all_games';
export const FAVOURITE_GAMES = 'favourite_games';

export type GameType = 'casino' | 'virtuals' | 'live-casino' | 'lucky6' | 'bingo' | 'bingo-mini' | undefined;

export type TabIdType = {
    type: 'home';
} | {
    type: 'all_games';
} | {
    type: 'favourite_games';
} | {
    type: 'supplier_games';
    id: string;
} | {
    type: 'numeric';
    id: number;
};

export interface SupplierType {
    id: string;
    name: string;
}

// LiveCasinoSocket
export type RouletteNumberEmphasizedType = null | 'hot' | 'cold';

export interface AvailableSeatsType {
    current: number | undefined;
    max: number | null;
}

export type RouletteColorType = 'black' | 'red' | 'green';

export interface RouletteNumberType {
    color: RouletteColorType;
    emphasized: RouletteNumberEmphasizedType;
    result: string;
}
