import { Input } from 'src/domains/players/webview/components/form/input/Input';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const InputsWrapper = styled('div', { label: 'InputsWrapper' })`
    position: relative;
    display: flex;
    margin-top: 10px;
`;

export const SingleInput = styled(Input, { label: 'SingleInput' })`
    flex: 0 1 33.33%;
    margin: 0 6px 0 0;
    &:last-of-type {
        margin: 0;
    }
`;

interface FormNotificationPropsType {
    status: 'error' | 'success';
}

const setNotification = (theme: EmotionTheme, status: 'error' | 'success'): string => {
    if (status === 'error') {
        return `
            background-color: ${theme.star.statuses.error.bgColor};
            color: ${theme.star.statuses.error.txtColor};
        `;
    }

    return `
        background-color: ${theme.star.statuses.success.bgColor};
        color: ${theme.star.statuses.success.txtColor};
    `;
};


export const FormNotification = withConfig(theme => styled('div', { label: 'FormNotification' })<FormNotificationPropsType>`
    ${({ status }): string => setNotification(theme, status)};
    border-radius: 0;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3333;
    margin: 5px 0;
    padding: 4px 8px;
    text-align: left;
`);
