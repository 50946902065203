import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { computed, observable, makeObservable } from 'mobx';
import { useAsObservableSource } from 'mobx-react-lite';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { GoalScorerMarketWrapper, GoalScorerMarketListWrapper, GoalScorerMarketListItem, MarketGroupCaptionDesktop, CaptionGroupDesktop, CaptionDesktop } from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalScorerMarket.style';
import { GoalscorerName } from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { MARKET_NAMES } from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup';
import { ExpandButton } from 'src/domains/sportsbook/webview/components/expandButton/ExpandButton';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

// Home | Away | NoGoalScorer
type IdentifierType = 'H' | 'A' | 'NG';

interface PropTypes {
    selections: Record<number, SelectionModel[]>;
    eventId: EventId;
}

interface GoalScorer {
    name: string;
    first: SelectionId | null;
    anytime: SelectionId | null;
    hatTrick: SelectionId | null;
    identifier: IdentifierType;
}

const findIdentifier = (identifiers: string | undefined): IdentifierType => {
    if (identifiers === 'H') {
        return 'H';
    }
    if (identifiers === 'NG') {
        return 'NG';
    }
    return 'A';
};


class State {
    @observable public isExpanded = false;

    public constructor(
        private readonly props: PropTypes,
    ) {
        makeObservable(this);
    }

    @computed public get marketAndSelections(): SelectionModel[] {
        const list: SelectionModel[] = [];

        for (const market of Object.values(this.props.selections)) {
            list.push(...market);
        }

        return list;
    }

    @computed public get noGoalScore(): GoalScorer | null {
        const noScorer = this.marketAndSelections.find(elem => {
            return findIdentifier(elem.selectionIdentifiers) === 'NG';
        }) ?? null;

        if (noScorer !== null) {
            return {
                name: noScorer.name,
                first: noScorer.id2,
                anytime: null,
                hatTrick: null,
                identifier: 'NG'
            };
        }

        return null;
    }

    @computed public get allGoalScorerList(): Record<string, GoalScorer> {
        const selectionGroup: Record<string, GoalScorer> = {};
        for (const selection of this.marketAndSelections) {
            const slug = selection.name.toLocaleLowerCase().split(' ').join('-');
            if (selectionGroup[slug] === undefined) {
                selectionGroup[slug] = {
                    name: selection.name,
                    first: null,
                    anytime: null,
                    hatTrick: null,
                    identifier: findIdentifier(selection.selectionIdentifiers)
                };
            }
            const elem = selectionGroup[slug];
            if (elem !== undefined) {
                if (selection.templateMarketId === 'first-goalscorer') {
                    selectionGroup[slug] = {
                        ...elem,
                        first: selection.id2
                    };
                }
                if (selection.templateMarketId === 'anytime-goalscorer') {
                    selectionGroup[slug] = {
                        ...elem,
                        anytime: selection.id2
                    };
                }
                if (selection.templateMarketId === 'hattrick-goalscorer') {
                    selectionGroup[slug] = {
                        ...elem,
                        hatTrick: selection.id2
                    };
                }
            }
        };
        return selectionGroup;
    }


    @computed public get goalScorerListHome(): Array<GoalScorer> {
        return Object.values(this.allGoalScorerList).filter(goalScorer => goalScorer.identifier === 'H');
    }

    @computed public get goalScorerListAway(): Array<GoalScorer> {
        return Object.values(this.allGoalScorerList).filter(goalScorer => goalScorer.identifier === 'A');
    }

    public toggleExpand = (e: React.MouseEvent): void => {
        e.preventDefault();
        this.isExpanded = !this.isExpanded;
    };

    @computed public get isCanBeExpanded(): boolean {
        const homeLength = this.goalScorerListHome.length;
        const awayLength = this.goalScorerListAway.length;
        const noGoalScoreLength = this.noGoalScore === null ? 0 : 1;
        const length = noGoalScoreLength + (homeLength > awayLength ? homeLength : awayLength);

        return length > 6;
    }
}

interface GoalScorerMarketListItemWrapperPropsType {
    goalScorer: GoalScorer;
}

const GoalScorerMarketListItemWrapper = observer('GoalScorerMarketListItemWrapper', (props: GoalScorerMarketListItemWrapperPropsType): JSX.Element | null => {
    const { name, first, anytime, hatTrick } = props.goalScorer;

    return (
        <GoalScorerMarketListItem key={name}>
            <GoalscorerName templateId=''>{name === 'No goalscorer' ? <I18n langKey='selection.football.match.no-goalscorer' defaultText='No goalscorer' /> : name}</GoalscorerName>
            <Selection
                borderBottom={true}
                borderLeft={true}
                borderRight={true}
                selectionId={first ?? undefined}
            />
            <Selection
                borderBottom={true}
                borderLeft={true}
                borderRight={true}
                selectionId={anytime ?? undefined}
            />
            <Selection
                borderBottom={true}
                borderLeft={true}
                selectionId={hatTrick ?? undefined}
            />
        </GoalScorerMarketListItem>
    );
});

interface GoalScorerMarketListPropsType {
    eventId: EventId;
    goalScorerList: Array<GoalScorer>;
    isExpanded: boolean;
}

const GoalScorerMarketList = observer(
    'GoalScorerMarketList',
    (props: GoalScorerMarketListPropsType): JSX.Element | null => {
        const { goalScorerList, eventId, isExpanded } = props;
        const event = eventId.getEventModel();

        //TODO - do we really need this protection
        if (event === null) {
            return null;
        }

        return (
            <GoalScorerMarketListWrapper>
                {goalScorerList.length > 0 ? (
                    <MarketGroupCaptionDesktop>
                        <CaptionDesktop isFirst={true}>
                            <I18n langKey='events.goalscorer-market.player' defaultText='Player' />
                        </CaptionDesktop>

                        <CaptionGroupDesktop>
                            {Object.keys(MARKET_NAMES).map((templateId: string) => {
                                const marketName = MARKET_NAMES[templateId];
                                return <CaptionDesktop key={templateId}>{marketName}</CaptionDesktop>;
                            })}
                        </CaptionGroupDesktop>
                    </MarketGroupCaptionDesktop>
                ) : null}

                {goalScorerList
                    .slice(0, isExpanded === true ? goalScorerList.length : 6)
                    .map((elem) => {
                        return <GoalScorerMarketListItemWrapper key={elem.name} goalScorer={elem} />;
                    })}
            </GoalScorerMarketListWrapper>
        );
    }
);

export const GoalScorerMarket = observer('GoalScorerMarket', (propsInner: PropTypes) => {
    const props = useAsObservableSource(propsInner);
    const [stateToday] = useState(() => new State(props));

    return (
        <>
            <GoalScorerMarketWrapper>
                <GoalScorerMarketList goalScorerList={stateToday.goalScorerListHome} eventId={props.eventId} isExpanded={stateToday.isExpanded} />
                <GoalScorerMarketList goalScorerList={stateToday.goalScorerListAway} eventId={props.eventId} isExpanded={stateToday.isExpanded} />
            </GoalScorerMarketWrapper>


            {stateToday.noGoalScore !== null && (stateToday.isExpanded || stateToday.isCanBeExpanded === false)
                ? <GoalScorerMarketListItemWrapper goalScorer={stateToday.noGoalScore} />
                : null
            }

            {
                stateToday.isCanBeExpanded ?
                    <ExpandButton
                        isExpanded={stateToday.isExpanded}
                        toggleExpand={stateToday.toggleExpand}
                    />
                    : null
            }
        </>
    );
});
