import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';

export const FreshDeskIframe = observer('FreshDeskIframe',(): JSX.Element | null => {
    const appState = useAppStateContext();
    const { appLayoutsState } = appState;
    const { configComponents } = appLayoutsState;

    const freshDeskConfig = configComponents.config.freshDeskChat;

    React.useEffect(() => {
        if (freshDeskConfig !== null) {
            setTimeout(() => {
                if (document.getElementById('Freshchat-js-sdk') !== null) {
                    return;
                }

                const chatParams = document.createElement('script');
                chatParams.type = 'text/javascript';
                chatParams.innerHTML = `
                    window.fcSettings = {
                        token: "${freshDeskConfig.token}",
                        host: "${freshDeskConfig.host}",
                        widgetUuid: "${freshDeskConfig.widgetUuid}"
                    };
                `;
        
                const chatScript = document.createElement('script');
                chatScript.id = 'Freshchat-js-sdk';
                chatScript.type = 'text/javascript';
                chatScript.async = true;
                chatScript.src = `${freshDeskConfig.host}/js/widget.js`;
                
                document.body.appendChild(chatScript);
                document.body.appendChild(chatParams);
    
                return () => {
                    const existingScript = document.getElementById('Freshchat-js-sdk');
    
                    if (existingScript !== null) {
                        document.body.removeChild(existingScript);
                    }
                };
            }, 0);
        }
    }, []);

    return null;
});
