import * as t from 'io-ts';
import { z } from 'zod';

/* UNIVERSE TYPE */
export const UniverseIO = t.union([
    //star template
    t.literal('oddsbet'),
    t.literal('summitbet'),
    t.literal('swiftyglobal'),
]);

/* UNIVERSE TYPE ZOD*/
export const UniverseZod = z.union([
    z.literal('oddsbet'),
    z.literal('summitbet'),
    z.literal('swiftyglobal'),
]);

export type UniverseType = t.TypeOf<typeof UniverseIO>;

export const getAllUniverse = (): Array<UniverseType> => {
    return [
        'oddsbet',
        'summitbet',
        'swiftyglobal',
    ];
};

/**
 * @deprecated - Please use the getAllUniverse function
 */
export const getAllUniverseStr = (): Array<string> => {
    return getAllUniverse();
};
