function checkCookieIsCorrect(cookie: string, isFirst: boolean): boolean {
    if (isFirst) {
        return cookie.split('=').length === 3;
    }
    return cookie.split('=').length === 2;
}

export function checkCookieWithMultiValues(values: Array<string>): boolean {
    let isAllCorrect = true;
    values.forEach((value, index) => {
        if (checkCookieIsCorrect(value, index === 0) === false) {
            isAllCorrect = false;
        }
    });
    return isAllCorrect;
}

export const getCookie = (name: string): string | null => {
    for (const cookie of document.cookie.split(';')) {
        const cookieValues = cookie.trim().split('&');
        const cookieChunk = cookie.trim().split('=');
        const cookieName = cookieChunk[0];
        const cookieValue = cookieChunk[1];
        if (cookieName === undefined || cookieValue === undefined) {
            console.warn(`getCookie: Broken cookie part => ${cookie}`);
            continue;
        }

        if (cookieValues.length > 1 && checkCookieWithMultiValues(cookieValues) === false) {
            console.warn(`getCookie: Incorrect number of cookieChunk => ${cookieChunk.length} in ${cookie}`);
            continue;
        }

        if (cookieName === name) {
            return cookieValue;
        }
    }

    return null;
};

export const setCookie = (name: string, value: string, days: number | undefined | null, sameSite?: 'Lax' | 'Strict' | 'None'): void => {
    const date = new Date();
    let expires = '';
    const valueCheck = value ?? '';

    if (days !== null && days !== undefined) {
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    if (sameSite === undefined) {
        document.cookie = `${name}=${valueCheck}${expires}; path=/`;
    } else {

        document.cookie = `${name}=${valueCheck}${expires}; path=/; SameSite=${sameSite}; Secure`;
    }

};
