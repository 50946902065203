import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const VirtualsPageMainWrapper = withConfig(theme => styled('div', { label: 'VirtualsPageMainWrapper' })`

    flex: 1 1 0%;

    display: flex;

    flex-flow: column nowrap;

    background-color: ${theme.star.casino.bgColor};

`);



