import React from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import { HighLightedTextWrapper, TextEmphasised } from 'src/domains/sportsbook/webview/components/highlightedText/HighlightedText.style';

interface PropsType {
    name: string;
    query: string;
}

const setCorrectQuery = (query: string, name: string): string => {
    const index = name.toLowerCase().indexOf(query.toLowerCase());
    return name.slice(index, index+query.length);
};

const areEqual = (query: string, part: string): boolean => {
    return query.toUpperCase() === part.toUpperCase();
};

export const HighlightedText = observer('HighlightedText', (propsIn: PropsType) => {
    const props = useAsObservableSource(propsIn);
    const { query, name } = props;

    const correctQuery = setCorrectQuery(query, name);
    const splitParts = name.split(correctQuery);
    const parts: string[] = [];

    splitParts.forEach((part, i) => {
        if (i === splitParts.length - 1) {
            return parts.push(part);
        }
        return parts.push(part, correctQuery);
    });

    if (query.length > 0) {
        return (
            <HighLightedTextWrapper>
                {
                    parts.map((part, idx) => {
                        return (
                            areEqual(query, part) ? <TextEmphasised key={idx}>{part}</TextEmphasised> : <span key={idx}>{part}</span>
                        );
                    }
                    )
                }
            </HighLightedTextWrapper>
        );
    }
    return null;

});
