import { z } from 'zod';
import { CountryCodeType } from 'src/domains/layouts/config/countries';
import { ContactPreferenceEnum } from 'src_server/trpc/types/createAccountPrimary';
import { CurrencyCodeZod } from 'src_common/common/amount/website-money/currency';

export const InputSchema = z.object({
    body: z.object({
        cpf: z.string(),
        email: z.string(),
        password: z.string(),
        birthDate: z.string(), //Date '2000-12-24'
        currency: CurrencyCodeZod,
        contactPreferences: z.array(z.nativeEnum(ContactPreferenceEnum)).optional().nullable(),//Array<ContactPreference>;
        postCode: z.string().optional().nullable(), //max 10,
        addressLine2: z.string().optional().nullable(), //max 100
        language: z.string().optional().nullable(),
        mobilePhone: z.object({
            country: z.string(), //CountryCodesTypes,
            prefix: z.string(),
            number: z.string()
        }),
        referrer: z.string().optional().nullable(),
        incomeaccess: z.string().optional().nullable(),
        promoID: z.string().optional().nullable(),
    }),
    isTest: z.boolean().optional().nullable()
});

export type CreateAccountLatamInput = z.infer<typeof InputSchema> & {
    country: CountryCodeType;
    mobilePhone: {
      country: CountryCodeType;
      prefix: string;
      number: string;
    };
  };

export const SuccessResponseSchema = z.object({
    token: z.string()
});

type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    code: z.string().optional().nullable(),
    debugDetails: z.string().optional().nullable(),
    details: z.string().optional().nullable(),
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            debugDetails: z.string().optional().nullable(),
            field: z.string().optional().nullable(),
            pointer: z.string().optional().nullable(),
            resource: z.string().optional().nullable(),
        })
    ),
    message: z.string().optional().nullable(),
});


type TErrorResponse = z.infer<typeof ErrorResponseSchema>;

export type TResponseType =
    { responseStatus: 'success'; data: TSuccessResponse } |
    { responseStatus: 'error'; data: TErrorResponse }
