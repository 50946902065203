import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const PaginationWrapper = styled('div', { label: 'PaginationWrapper' })`
    margin: 17px auto;
    display: flex;
    justify-content: center;
`;

interface PaginationButtonTypes {
    isHidden: boolean;
}

export const PaginationButton = withConfig(theme => styled('button', { label: 'PaginationButton' })<PaginationButtonTypes>`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    box-shadow: 0 0 4px 0 ${theme.star.pagination.bgColor};
    padding: 0;
    cursor: pointer;
    background-color: ${theme.star.pagination.bgColorSecondary};
    border: 0;
    margin: 0 5px;
    visibility: ${({ isHidden }): string => isHidden === true ? 'hidden' : 'visible'};
`);

export const ChevronIconWrapper = styled(ChevronIcon, { label: 'ChevronIconWrapper' })`
    width: 12px;
`;

interface DotsTypes {
    isActive: boolean;
    bigDot: boolean;
    smallDot: boolean;
}

const renderDots = (bigDot: boolean, smallDot: boolean): string => {
    if (bigDot === true) {
        return `
            width: 8px;
            height: 8px;
        `;
    }

    if (smallDot === true) {
        return `
            width: 4px;
            height: 4px;
        `;
    }

    return `
        width: 2px;
        height: 2px;
    `;
};

export const DotItem = withConfig(theme => styled('li', { label: 'DotItem' })<DotsTypes>`
    ${({ bigDot, smallDot }): string => renderDots(bigDot, smallDot)};
    border-radius: 50%;
    background-color: ${({ isActive }): string => isActive === true ? theme.star.pagination.bgColorTertiary : theme.star.pagination.bgColor};
    margin: auto 4px auto 0;
    flex-shrink: 0;
    transition: height 0.3s linear, width 0.3s linear;
`);

export const DotsList = styled('ul', { label: 'DotsList' })`
    display: flex;
    width: 64px;
    overflow-x: hidden;
    position: relative;
    justify-content: center;
    margin: 0 22px;
`;
