import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import {
    InfoWrapper,
    TermsButton,
    InfoContentWrapper,
    CloseButton,
    StyledCloseIcon,
    InfoContentTitle,
    InfoContent,
    CheckboxWrapper,
} from './TermsAndConditions.style';
import { Checkbox } from 'src/domains/layouts/webview/components/form/checkbox/Checkbox';
import { TermsAndConditionsCheckboxState } from 'src/domains/players/webview/components/SignUp/signupParts/termsAndConditions/TermsAndConditionsCheckbox.state';

interface PropsType {
    termsAndConditionsCheckboxState: TermsAndConditionsCheckboxState;
    signUpCheckboxTC: boolean;
}

export const TermsAndConditions = observer(
    'TermsAndConditions',
    ({ termsAndConditionsCheckboxState, signUpCheckboxTC }: PropsType) => {
        const { appLayoutsState, appPlayersState } = useAppStateContext();
        const { languagesState, starRouter } = appLayoutsState;
        const { cmsDrawerPagesState } = appPlayersState;
        const isValidPage =
            cmsDrawerPagesState.pageType === 'terms-and-conditions' ||
            cmsDrawerPagesState.pageType === 'privacy-policy';

        const renderText = (): JSX.Element => {
            return languagesState.translateTokens(
                languagesState.getTranslation(
                    'signup.terms-and-conditions',
                    'By clicking the ‘Agree and Join’ button below you confirm that you are 18 or over and you accept the [Button1 {button1Text="Terms & Conditions"}] and [Button2 {button2Text="Privacy Policy"}]'
                ),

                (singleParam: LanguageTokenType) => {
                    if (singleParam.tag === 'Button1') {
                        const text = singleParam.params.button1Text ?? '';
                        return (
                            <TermsButton
                                type='button'
                                onClick={cmsDrawerPagesState.showTermsAndConditionsPage}
                                data-test='terms-and-conditions-button'
                            >
                                {text}
                            </TermsButton>
                        );
                    }

                    if (singleParam.tag === 'Button2') {
                        const text = singleParam.params.button2Text ?? '';
                        return (
                            <TermsButton
                                type='button'
                                onClick={cmsDrawerPagesState.showPrivacyPolicyPage}
                                data-test='privacy-policy-button'
                            >
                                {text}
                            </TermsButton>
                        );
                    }
                }
            );
        };

        const renderCheckbox = (): JSX.Element => {
            const TermsAndConditions = languagesState.translateTokens(
                languagesState.getTranslation(
                    'signup.terms-and-conditions.checkbox',
                    'By checking this box you confirm that you are 18+ and you accept our [Button1 {button1Text="Terms & Conditions"}]'
                ),

                (singleParam: LanguageTokenType) => {
                    if (singleParam.tag === 'Button1') {
                        const text = singleParam.params.button1Text ?? '';
                        return (
                            <TermsButton
                                type='button'
                                onClick={starRouter.redirectToTermsAndConditions}
                            >
                                {text}
                            </TermsButton>
                        );
                    }
                }
            );
            const PrivacyPolicy = languagesState.translateTokens(
                languagesState.getTranslation(
                    'signup.terms-and-conditions.privacy-policy.checkbox',
                    'By checking this box you are accepting our [Button2 {button2Text="Privacy Policy"}]'
                ),
                (singleParam: LanguageTokenType) => {
                    if (singleParam.tag === 'Button2') {
                        const text = singleParam.params.button2Text ?? '';
                        return (
                            <TermsButton
                                type='button'
                                onClick={cmsDrawerPagesState.showPrivacyPolicyPage}
                            >
                                {text}
                            </TermsButton>
                        );
                    }
                }
            );
            return (
                <>
                    <CheckboxWrapper>
                        <Checkbox
                            dataTest='terms-and-conditions-checkbox'
                            mode='dark'
                            state={termsAndConditionsCheckboxState.termsAndConditions}
                        >
                            {TermsAndConditions}
                        </Checkbox>
                    </CheckboxWrapper>
                    <Checkbox
                        dataTest='privacy-policy-checkbox'
                        mode='dark'
                        state={termsAndConditionsCheckboxState.privacyPolicy}
                    >
                        {PrivacyPolicy}
                    </Checkbox>
                </>
            );
        };

        return (
            <InfoWrapper
                data-test='info-wrapper'
                signUpCheckboxTC={signUpCheckboxTC}
            >
                {signUpCheckboxTC ? renderCheckbox() : renderText()}
                {cmsDrawerPagesState.displayPage &&
                isValidPage &&
                cmsDrawerPagesState.title !== null &&
                cmsDrawerPagesState.content !== null ? (
                    <InfoContentWrapper>
                        <InfoContentTitle>
                            <div dangerouslySetInnerHTML={{ __html: cmsDrawerPagesState.title }} />
                            <CloseButton
                                type='button'
                                onClick={cmsDrawerPagesState.closePage}
                            >
                                <StyledCloseIcon />
                            </CloseButton>
                        </InfoContentTitle>
                        <InfoContent dangerouslySetInnerHTML={{ __html: cmsDrawerPagesState.content }} />
                    </InfoContentWrapper>
                ) : null}
            </InfoWrapper>
        );
    }
);
