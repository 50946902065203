import { Amount } from 'src_common/common/amount/Amount';

const tryDigits = (value: string): boolean => {
    for (const char of value) {
        if ('0' <= char && char <= '9') {
            //ok
        } else {
            return false;
        }
    }

    return true;
};

export const normalizeAmount = (valueInput: string): string => {
    const value = valueInput.replaceAll(',', '.').replaceAll(';', '.');
    if (value[0] === '.') {
        return `0${value}`;
    } else {
        return value;
    }
};

export const tryStringToAmount = (value: string, precision: number): Amount | null => {
    const [chunk1, chunk2, ...rest] = normalizeAmount(value).split('.');

    if (rest.length > 0) {
        return null;
    }

    if (chunk1 !== undefined && chunk2 !== undefined) {
        // case: 'xxxx.xxxx' (chunk1, chunk2)
        if (tryDigits(chunk1) && tryDigits(chunk2) && chunk2.length <= precision) {
            return new Amount(`${chunk1}.${chunk2}`);
        }
        return null;
    }

    if (chunk1 !== undefined && chunk2 === undefined) {
        // case: 'xxxx' (chunk1)
        if (tryDigits(chunk1)) {
            return new Amount(chunk1);
        }
        return null;
    }

    return null;
};
