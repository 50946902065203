import { action, computed, observable, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { apiCommon } from 'src/api/ApiCommon';
import { decodePromoCodeObjects } from './commonCmsGuard';
import { PromoCodeModelType } from 'src/api/config/promo_codes/get_promo_codes';
import { PromoCodesType } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/stepsStructure/createAccount/promoCodesState/commonCmsGuard';
import { DateTime } from 'src_common/utils/time/time';

interface PromoCodeLoading {
    type: 'loading';
}

interface PromoCodeReady {
    type: 'ready';
    value: Array<PromoCodesType>;
}
interface PromoCodeError {
    type: 'error';
    value: string;
}

type PromoCodeContentResponse = PromoCodeLoading | PromoCodeReady | PromoCodeError;

export class PromoCodesState {
    @observable public readonly promoCodesResource: Resource<Array<PromoCodeModelType>>;

    public constructor() {
        makeObservable(this);

        this.promoCodesResource = new Resource(async (): Promise<Array<PromoCodeModelType>> => {
            const response = await apiCommon.get_promo_codes.run({});
            return response;
        });
    }

    public checkIfCodeExistsAndIsValid = (codeFromSignup: string): boolean => {
        if (codeFromSignup === ''){
            return true;
        }

        for (const promoCode of this.promoCodeList) {
            for (const singleCodeObject of promoCode.promo_code) {
                if (singleCodeObject.code.toLowerCase() === codeFromSignup.toLowerCase()) {
                    const isCurrentDateAfter = DateTime.current().isAfter(promoCode.date_from);
                    const isCurrentDateBefore = DateTime.current().isBefore(promoCode.date_to);

                    const isDateValid = isCurrentDateAfter && isCurrentDateBefore;
                    const isUsageValid = (promoCode.number_of_uses === null || promoCode.number_of_uses === undefined) || singleCodeObject.used < promoCode.number_of_uses;

                    if (isUsageValid && isDateValid) {
                        return true;
                    }
                    return false;
                }

            }
        }
        return false;
    };

    public onIfAffiliateIdExists = (codeFromSignup: string): string | undefined => {
        if (codeFromSignup === ''){
            return undefined;
        }


        for (const promoCode of this.promoCodeList) {
            for (const singleCodeObject of promoCode.promo_code) {
                if (singleCodeObject.code.toLowerCase() === codeFromSignup.toLowerCase()) {
                    return promoCode.affiliate_id ?? undefined;
                }
            }
        }
        return undefined;
    };

    @action public updatePromoCode = async (codeFromSignup: string): Promise<number | Error | null> => {
        let response: number | Error | null ;
        for (const promoCode of this.promoCodeList) {
            for (const singleCodeObject of promoCode.promo_code) {
                if (singleCodeObject.code.toLowerCase() === codeFromSignup.toLowerCase()) {
                    const foundObject = singleCodeObject;
                    try {
                        const properCode = promoCode.promo_code.findIndex(c => c.code.toLowerCase() === codeFromSignup.toLowerCase());
                        const promoCodeCopy = promoCode;
                        if (properCode !== -1) {
                            promoCodeCopy.promo_code[properCode] = { code: foundObject.code, used: foundObject.used + 1 };
                        }
                        response = await apiCommon.put_update_promo_code.run({ data: promoCodeCopy, id: promoCode.id });
                    } catch (error) {
                        console.error(error);
                        return null;
                    }
                    return response;
                }

            }

        }
        return null;
    };

    @computed public get promoCodes(): PromoCodeContentResponse {
        const promoCode = this.promoCodesResource.get();
        if (promoCode.type === 'ready') {

            const promoCodeObject = decodePromoCodeObjects(promoCode.value);

            return {
                type: 'ready',
                value: promoCodeObject
            };

        }
        if (promoCode.type === 'loading') {
            return {
                type: 'loading'
            };
        }

        return {
            type: 'error',
            value: 'Something went wrong with fetching promo codes info'
        };
    }

    @computed public get promoCodeList(): Array<PromoCodesType> {
        if (this.promoCodes.type === 'ready') {
            return this.promoCodes.value;
        }
        return [];
    }


    public static createForContext(): PromoCodesState {
        return new PromoCodesState( );
    }
}
