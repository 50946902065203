import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { computed, makeObservable } from 'mobx';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';
export class FeatureState {
    public static get = AutoWeakMap.create((common: Common) => new FeatureState(common));

    private constructor(private readonly common: Common) {
        makeObservable(this);
    }

    public get rabFeature(): boolean {
        return ConfigComponents.get(this.common).config.rab;
    }

    @computed public get hasFirstLoginInMobileAppDetection(): boolean {
        return ConfigComponents.get(this.common).config.detectLoginInMobileApp;
    }

    @computed public get iosAllowCasinoNew(): boolean | null {
        const env = EnvironmentState.get(this.common);
        const { mobileAppVersion } = env;

        const appVersion = mobileAppVersion ?? '';

        if (env.casinoMobileAppVersionsValue.length === 0) {
            return null;
        }

        const mobileAppVersionItem =
            env.casinoMobileAppVersionsValue.find((elem) => elem.app_version === appVersion) ?? null;
        if (env.isMobileApp()) {
            if (mobileAppVersionItem === null) {
                console.error('Mobile version not found in BO', mobileAppVersion);
            } else if (mobileAppVersionItem.is_casino_active === false) {
                console.error('Mobile version is inactive in BO admin section');
            }
        }

        if (mobileAppVersionItem === null) {
            return true;
        }

        return mobileAppVersionItem.is_casino_active;
    }

    @computed public get allowCasinoInIOSWrapperNew(): boolean {
        const env = EnvironmentState.get(this.common);

        if (env.isAppIOSDeviceWrapper) {
            return this.iosAllowCasinoNew ?? false;
        }

        return true;
    }
}
