import React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string,
}

const SvgElement = styled('svg', { label: 'SvgElement' })<PropsType>`
    height: auto;
`;

export const Logo18Plus = ({ className }: PropsType): JSX.Element => (
    <SvgElement
        className={className}
        fill='#e83737'
        height={24}
        viewBox='0 0 24 24'
        width={24}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            clipRule='evenodd'
            d='M12 24a12 12 0 1 1 0-24 12 12 0 0 1 0 24Zm0-1.2a10.8 10.8 0 1 0 0-21.6 10.8 10.8 0 0 0 0 21.6ZM8.34 8.98h-2.2v.92h1.15v4.55h1.05V8.98Zm5.08 1.39c0-.44-.16-.8-.5-1.05a2.15 2.15 0 0 0-1.35-.39c-.56 0-1 .13-1.34.38a1.26 1.26 0 0 0-.28 1.72c.14.2.34.37.6.5-.34.11-.6.3-.8.55-.2.24-.29.53-.29.85 0 .32.09.6.26.83.17.23.42.41.74.54.32.13.7.2 1.12.2.43 0 .8-.07 1.12-.2.32-.13.57-.3.74-.55.17-.24.26-.52.26-.83 0-.32-.1-.6-.28-.85a1.64 1.64 0 0 0-.76-.55 1.23 1.23 0 0 0 .76-1.15Zm-2.8.05c0-.23.08-.41.25-.54.17-.13.4-.2.7-.2.31 0 .54.07.71.2.17.13.25.3.25.54 0 .24-.08.42-.25.55-.17.13-.4.2-.7.2-.3 0-.54-.07-.7-.2a.65.65 0 0 1-.26-.55Zm-.17 2.4c0-.27.1-.48.3-.62.19-.15.47-.22.82-.22.36 0 .64.07.84.22.2.15.3.36.3.62 0 .27-.1.48-.3.63-.2.15-.48.22-.83.22-.36 0-.64-.07-.84-.22a.75.75 0 0 1-.3-.64Zm7.25-.56v-.7h-1.36V10.2h-.66v1.36h-1.35v.7h1.35v1.34h.66v-1.34h1.36Z'
            fillRule='evenodd'
            fill='fill'
        />
    </SvgElement>
);
