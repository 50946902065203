import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    SportsEventHeaderGroupName,
    SportsEventHeaderGroupSelection,
    SportsEventHeaderGroupSelectionName,
    SportsEventHeaderGroup,
    ChevronIconWrapper
} from './EventGroupHeader.style';
import { useAppStateContext } from 'src/appState/AppState';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    label: string;
    selections: Array<string>;
    open: boolean;
    lightTheme?: boolean;
    onToggle: () => void;
    competitionId: CompetitionId | null;
    sportName: string | null;
    dataTest?: string;
}

export const EventGroupHeader = observer('EventGroupHeader', (props: PropsType) => {
    const { appSportsBookState } = useAppStateContext();

    const eventsListState = appSportsBookState.eventsListState.get(props.competitionId);
    const toggle = (e: React.MouseEvent): void => {
        e.preventDefault();
        const { onToggle } = props;
        onToggle();
    };

    
    const isOutrightsMarket = eventsListState.isOutrightsMarket();
    const { label, selections, open, lightTheme, dataTest } = props;

    return (
        <SportsEventHeaderGroup
            lightTheme={isOutrightsMarket || lightTheme}
            className='event-group-header'
            onClick={toggle}
            isOutrights={isOutrightsMarket}
        >
            <ChevronIconWrapper position={open ? 'down' : 'right'} />
            <SportsEventHeaderGroupName className='event-group-header__name' data-test={dataTest}>{label}</SportsEventHeaderGroupName>
            {open ?
                !isOutrightsMarket && selections.map((name, key) => (
                    <SportsEventHeaderGroupSelection key={key}>
                        <SportsEventHeaderGroupSelectionName>
                            {name.length > 0 ? name.substr(0, 1).toUpperCase() : ''}
                        </SportsEventHeaderGroupSelectionName>

                        <SportsEventHeaderGroupSelectionName>{name}</SportsEventHeaderGroupSelectionName>
                    </SportsEventHeaderGroupSelection>
                )) : null}
        </SportsEventHeaderGroup>
    );
});
