/* eslint-disable @typescript-eslint/no-floating-promises */
// @ts-nocheck
import { action, computed, makeObservable, observable } from 'mobx';
// eslint-disable-next-line no-restricted-imports
import { apiCommon } from 'src/api/ApiCommon';
import { TransactionItemType } from 'src/api/config/accounts/accountsWithdrawalsList';
import { Resource } from 'src_common/common/mobx-utils/Resource';

export class WithdrawalsListState {
    private readonly withdrawalsResource: Resource<Array<TransactionItemType>>;

    public constructor() {
        makeObservable(this);
        this.withdrawalsResource = new Resource(async (): Promise<Array<TransactionItemType>> => {
            return await apiCommon.withdrawalsList.run({});
        });
    }

    @observable public loading = new Set();

    @computed public get withdrawals(): Array<TransactionItemType> {
        const result = this.withdrawalsResource.get();
        if (result.type === 'ready') {
            return result.value;
        }
        return [];
    }

    @action public cancelWithdrawal = async (id: number): Promise<void> => {
        this.loading.add(id);
        try {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            const response = await apiCommon.cancelWithdrawal.run({ id });
        } catch (ex) {
            // exception
        }

        this.loading.delete(id);
    };

    public refresh(): void {
        this.withdrawalsResource.refresh();
    }
}
