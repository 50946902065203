import * as t from 'io-ts';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

export type PofPopupLocalStorageObjectType = {
    isPofFailedModalAlreadyShowed: boolean;
    isPofRequestedModalAlreadyShowed: boolean;
    isPofisPofRequestedWithoutCRModalAlreadyShowed: boolean;
};

const decodeIO = t.type({
    isPofFailedModalAlreadyShowed: t.boolean,
    isPofRequestedModalAlreadyShowed: t.boolean,
    isPofisPofRequestedWithoutCRModalAlreadyShowed: t.boolean
});

const decode = buildValidator('localStoragePofPopups.decodeIO', decodeIO, true);

export const localStoragePofPopup = (withAutorun: boolean, key: string): LocalStorageItemState<PofPopupLocalStorageObjectType> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: {
            isPofFailedModalAlreadyShowed: false,
            isPofRequestedModalAlreadyShowed: false,
            isPofisPofRequestedWithoutCRModalAlreadyShowed: false
        }
    });
};
