import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    ResetPasswordFormWrapper,
    SetNewPasswordBtnWrapper,
} from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordForm.style';
import { ResetPasswordFormState } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordForm.state';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { ResetPasswordRhsRouteType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { ResetPasswordSuccessfullyChange } from './ResetPasswordSuccessfullyChange';
import { AlignBottomWrapper } from 'src/domains/players/webview/components/Account/forgotPasswordTab/ForgotPasswordTab.style';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import { AccountHelperContainer } from 'src/domains/players/webview/components/Account/forgotPasswordTab/resetPasswordSent/ResetPasswordSent.style';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    currentRoute: ResetPasswordRhsRouteType;
}

export const ResetPasswordForm = observer('ResetPasswordForm', (props: PropsType) => {
    const { currentRoute } = props;
    const { appLayoutsState, appPlayersState, common } = useAppStateContext();
    const { starRouter, configComponents } = appLayoutsState;
    const { accountState } = appPlayersState;

    const [resetPasswordState] = useState(
        () => new ResetPasswordFormState(starRouter, configComponents, true, false, accountState)
    );

    const errors = (
        <>
            {resetPasswordState.errors.map((message, i) => (
                <span key={i}>{message}</span>
            ))}
        </>
    );

    const { universe } = common.envVariables;

    if (resetPasswordState.isSuccess) {
        return <ResetPasswordSuccessfullyChange />;
    }

    return (
        <AccountHelperContainer>
            <ResetPasswordFormWrapper
                onSubmit={(e): void => {
                    e.preventDefault();
                }}
            >
                <Input
                    name='password'
                    type={resetPasswordState.isShowPassword ? 'text' : 'password'}
                    label={
                        <I18n
                            langKey='account.reset-password.new-password.label'
                            defaultText='New password'
                        />
                    }
                    state={resetPasswordState.newPasswordState}
                    isShowPassword={resetPasswordState.isShowPassword}
                    showPassword={resetPasswordState.setShowPassword}
                    showPasswordFlag={true}
                    showSuccessIcon={resetPasswordState.newPasswordState.result.value.type === 'ok'}
                    autocomplete='off'
                    colorTheme={universe === 'oddsbet' ? 'dark' : 'light'}
                    dataTest='new-password-input'
                />
                <Input
                    name='confirm'
                    type={resetPasswordState.isShowConfirmPassword ? 'text' : 'password'}
                    label={
                        <I18n
                            langKey='account.reset-password.confirm-password.label'
                            defaultText='Confirm password'
                        />
                    }
                    state={resetPasswordState.confirmPasswordState}
                    isShowPassword={resetPasswordState.isShowConfirmPassword}
                    showPassword={resetPasswordState.setShowConfirmPassword}
                    showSuccessIcon={resetPasswordState.confirmPasswordState.result.value.type === 'ok'}
                    showPasswordFlag={true}
                    autocomplete='off'
                    colorTheme={universe === 'oddsbet' ? 'dark' : 'light'}
                    dataTest='confirm-password-input'
                />
                {resetPasswordState.errors.length > 0 ? (
                    <Messages
                        type='error'
                        marginBottom='16px'
                        message={errors}
                    />
                ) : null}
                <SetNewPasswordBtnWrapper
                    disabled={resetPasswordState.isFormValid === false}
                    onClick={(): Promise<void> => resetPasswordState.onSubmit(currentRoute)}
                    size='large'
                    type='submit'
                    isError={resetPasswordState.isConfirmPassError}
                    dataTest='set-new-password-button'
                >
                    <I18n
                        langKey='account.reset-password.submit.label'
                        defaultText='Set new password'
                    />
                </SetNewPasswordBtnWrapper>
            </ResetPasswordFormWrapper>
            <AlignBottomWrapper>
                <AccountHelperInfo />
            </AlignBottomWrapper>
        </AccountHelperContainer>
    );
});
