import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { OptionType, checkOptionType } from './common';
import { SelectWrapper } from './CarouselSelection.style';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { components, OptionProps } from 'react-select';
import { SelectCheckIconWrapper, ChevronFullIconWrapper } from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';

interface PropsTypes {
    options: Array<OptionType>;
    placeholder: string;
    active: string | null;
    type: SelectionType;
}

type SelectionType = 'event' | 'competition';

class State {
    public constructor(
        private readonly starRouter: StarRouter,
        private readonly type: SelectionType,
        private readonly models: ModelsState,
    ){}

    public onChange = (selectedOption: unknown ): void => {
        if (checkOptionType(selectedOption)) {
            const id = parseInt(selectedOption.value, 10);

            if (this.type === 'event') {
                this.starRouter.redirectToEvent(this.models.id.getEventId(id));
            } else {
                if (selectedOption.sportId === undefined) {
                    console.error('CarouselSelection onChange error');
                    return;
                }
                this.starRouter.redirectToCompetitionPage(id, selectedOption.sportId);
            }
        }
        if (selectedOption === undefined || selectedOption === null) {
            console.error('CarouselSelection onChange error');
            return;
        }
    };
}

export const CarouselSelection = observer('CarouselSelection', (props: PropsTypes) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { languagesState, starRouter } = appLayoutsState;
    const [state] = React.useState(() => new State(starRouter, props.type, appSportsBookState.models));

    const renderOption = (props: OptionProps): JSX.Element | null => {
        const isSelected = props.isSelected;
        return (
            <div data-test={props.label}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <components.Option {...props}>
                    {props.label} {isSelected === true ? <SelectCheckIconWrapper /> : null}
                </components.Option>
            </div>
        );
    };
    
    const { options, placeholder, active } = props;
    const noResultsFound = languagesState.getTranslation('events.navigation.competition.no-results-found', 'No results found.');

    const activeElement = (): OptionType | null => {
        const filtered = options.find(option => active === option.value) ?? null;
        return filtered;
    };

    return (
        <SelectWrapper
            className='custom-dropdown-select'
            classNamePrefix='react-select'
            noOptionsMessage={(): string => noResultsFound}
            onChange={state.onChange}
            options={options}
            placeholder={placeholder}
            value={activeElement()}
            isSearchable={false}

            components={{
                Option: renderOption,
                DropdownIndicator: (): JSX.Element => <ChevronFullIconWrapper position='down' />,
            }}
        />

    );
});
