import React from 'react';

import { AssigneeInfo } from 'src/domains/players/webview/components/Account/traderChat/components/AssigneeInfo';

import { ChatNightModeMessage } from 'src/domains/players/webview/components/Account/traderChat/components/ChatNightModeMessage';

import { observer } from 'src/utils/mobx-react';



export const NotificationBar = observer('NotificationBar', () => {

    return (

        <>

            <AssigneeInfo />

            <ChatNightModeMessage />

        </>

    );

});

