import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import {
    PendingTransactionWrapper,
    PendingWithdrawalsHeader,
    PendingWithdrawalsHeaderItem,
    PendingWithdrawalsList,
    PendingWithdrawalsRow,
    PendingWithdrawalsRowElem,
    AmountStyle,
    PendingWithdrawalsDateWrapper,
    PendingTitleWrapper,
    PendingTitle,
    PendingName,
    PendingCount,
    PendingSubTitle,
    PendingButton,
} from './PendingTransactions.style';
import { observer } from 'src/utils/mobx-react';
import { PendingTransactionsState } from './PendingTransactions.state';
import { DateTime } from 'src_common/utils/time/time';

export const PendingWithdrawals = observer('PendingWithdrawals', () => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;

    const [state] = useState(
        () => new PendingTransactionsState(appPlayersState.accountState, appPlayersState.withdrawalsListState)
    );

    return (
        <PendingTransactionWrapper>
            <PendingTitleWrapper>
                <PendingTitle>
                    <PendingName>
                        <I18n
                            langKey='account.pending-transactions.title-header.label'
                            defaultText='Pending withdrawals'
                        />
                    </PendingName>
                    <PendingCount>{state.withdrawalsList.length}</PendingCount>
                </PendingTitle>
                <PendingSubTitle>
                    <I18n
                        langKey='account.pending-transactions.subtitle-header.label'
                        defaultText='Withdrawals can take up to 3 - 5 working days'
                    />
                </PendingSubTitle>
            </PendingTitleWrapper>
            {state.isEmptyTransactionList ? null : (
                <>
                    <PendingWithdrawalsHeader>
                        <PendingWithdrawalsHeaderItem>
                            <I18n
                                langKey='account.pending-transactions.date-time.label'
                                defaultText='Date | Time'
                            />
                        </PendingWithdrawalsHeaderItem>

                        <PendingWithdrawalsHeaderItem>
                            <I18n
                                langKey='account.pending-transactions.withdrawn-to.label'
                                defaultText='Withdrawn to'
                            />
                        </PendingWithdrawalsHeaderItem>

                        <PendingWithdrawalsHeaderItem>
                            <I18n
                                langKey='account.pending-transactions.status.label'
                                defaultText='Status'
                            />
                        </PendingWithdrawalsHeaderItem>

                        <PendingWithdrawalsHeaderItem>
                            <I18n
                                langKey='account.pending-transactions.amount.label'
                                defaultText='Amount'
                            />
                        </PendingWithdrawalsHeaderItem>
                    </PendingWithdrawalsHeader>

                    <PendingWithdrawalsList>
                        {state.withdrawalsList.map((withdrawal) => {
                            const { tdate, cardNumber, id, amount } = withdrawal;
                            const newAmount = configComponents.precision.newFromAnything(amount);

                            return (
                                <PendingWithdrawalsRow key={id}>
                                    <PendingWithdrawalsRowElem data-test='pending-withdrawals-row-date'>
                                        <PendingWithdrawalsDateWrapper>
                                            <div>{DateTime.from(tdate)?.format('DD MMM YYYY')}</div>
                                            <div>{DateTime.from(tdate)?.format('HH:mm')}</div>
                                        </PendingWithdrawalsDateWrapper>
                                    </PendingWithdrawalsRowElem>

                                    <PendingWithdrawalsRowElem data-test='pending-withdrawals-row-card-number'>
                                        **** {cardNumber}
                                    </PendingWithdrawalsRowElem>

                                    <PendingWithdrawalsRowElem data-test='pending-withdrawals-row-status'>
                                        <PendingButton
                                            isLoading={appPlayersState.withdrawalsListState.loading.has(id)}
                                            onClick={(): Promise<void> =>
                                                appPlayersState.withdrawalsListState.cancelWithdrawal(id)
                                            }
                                            size='small'
                                            dataTest={`button-cancel-${id}`}
                                        >
                                            <I18n
                                                langKey='account.top-up.popups.agree-cancel-popup.cancel-btn'
                                                defaultText='Cancel'
                                            />
                                        </PendingButton>
                                    </PendingWithdrawalsRowElem>

                                    <PendingWithdrawalsRowElem data-test='pending-withdrawals-row-amount'>
                                        <AmountStyle>{appPlayersState.usersState.money(newAmount)}</AmountStyle>
                                    </PendingWithdrawalsRowElem>
                                </PendingWithdrawalsRow>
                            );
                        })}
                    </PendingWithdrawalsList>
                </>
            )}
        </PendingTransactionWrapper>
    );
});
