import { MethodType, ParamsFetchType, GenerateUrlApiType, ResponseType } from 'src_common/browser/apiUtils';
import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import { openapi_website_cms_getHeroWidgetActive, decodeResponse200, Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getHeroWidgetActive';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

export type HeroWidgetModelType = {
    date_from: string;
    date_to: string;
    display_order: number;
    event_id: EventId;
    header_bg_color?: null | undefined | string;
    header_img_big: string;
    header_img_medium: string;
    header_img_small: string;
    id: number;
    is_active_promo: boolean;
    is_active_widget: boolean;
    market_id: MarketId;
    promo_bg_color?: null | undefined | string;
    promo_img_big: string;
    promo_img_medium: string;
    promo_img_small: string;
    subtitle?: null | undefined | string;
    tc_text?: null | undefined | string;
    tc_title?: null | undefined | string;
    title: string;
    universe: string;
};

const decode = (status: number, data: ResponseType): Response200Type => {
    if (status === 200 && data.type === 'json') {
        const list = decodeResponse200(data.json);
        return list;
    }

    throw new Error(`unhandled response ${status} - ${data.type}`);
};


export const get_hero_widget_active = {
    browser: {
        params: (): ParamsFetchType<void> => {
            return {
                type: MethodType.GET,
                url: '/api-web/get_hero_widget_active',
            };
        },
        decode: decode
    },
    express: {
        method: MethodType.GET,
        urlBrowser: '/api-web/get_hero_widget_active',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<void>): Promise<GenerateUrlApiType> => {

        const response = await openapi_website_cms_getHeroWidgetActive(params.API_URL, params.API_TIMEOUT, params.jwtToken, {
            universe: params.API_UNIVERSE
        });

        return {
            passToBackend: false,
            status: response.status,
            responseBody: response.body
        };
    }
};
