import * as t from 'io-ts';
import { AppState } from 'src/appState/AppState';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

const TabIdIO = t.union([
    t.literal('main'),
    t.literal('translations'),
    t.literal('casino'),
    t.literal('event'),
    t.literal('config'),
    t.literal('tags'),
]);

export type TabIdType = t.TypeOf<typeof TabIdIO>;

export const SelectTabIdIO = t.union([
    t.literal('main'),
    t.literal('translations'),
    t.literal('casino'),
    t.literal('event'),
    t.literal('config'),
    t.literal('tags'),
    t.null,
]);

export type SelectTabIdType = t.TypeOf<typeof SelectTabIdIO>;

export interface TabItemType {
    id: TabIdType;
    name: string;
    body: (appState: AppState, currentView?: RouteViewType) => React.ReactElement;
}

export const DebugPanelSizeIO = t.union([
    t.null,
    t.interface({
        left: t.number,
        top: t.number,
        width: t.number,
        height: t.number,
    })
]);

export type DebugPanelSizeType = t.TypeOf<typeof DebugPanelSizeIO>;
