import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, makeObservable } from 'mobx';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import {
    BalanceDepositEmphasised,
    BalanceDepositWrapper,
} from 'src/domains/sportsbook/betting/ui/betSlip/errorAndWarningMessages/PlayableBalanceMessage.style';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

class State {
    private readonly requiredToBetAmount: Amount | null;
    private readonly router: StarRouter;
    private readonly onRoutingAccountChange: (route: string) => void;

    public constructor(
        private readonly configComponents: ConfigComponents,
        requiredToBetAmount: Amount | null,
        router: StarRouter,
        onRoutingAccountChange: (route: string) => void
    ) {
        makeObservable(this);
        this.requiredToBetAmount = requiredToBetAmount;
        this.router = router;
        this.onRoutingAccountChange = onRoutingAccountChange;
    }

    @action public onTopUp = (): void => {
        if (this.requiredToBetAmount !== null) {
            this.onRoutingAccountChange('top-up');
            const amount = this.requiredToBetAmount;
            this.router.redirectToTopUp(this.configComponents.precision.valueOldFormat(amount));
        }
    };
}

export const PlayableBalanceMessage = observer('PlayableBalanceMessage', () => {
    const appState = useAppStateContext();
    const { appLayoutsState, appSportsBookState } = appState;

    const { betSlipState, onAccountGTMRoutingChange } = appSportsBookState;
    const { requiredToBetAmount } = betSlipState.betSlipSummaryState;

    const { starRouter, sdkCustomer, configComponents, languagesState } = appLayoutsState;
    const { hasTopUpEnabled } = configComponents.config;

    const [state] = React.useState(
        () => new State(configComponents, requiredToBetAmount, starRouter, onAccountGTMRoutingChange)
    );

    const renderText = (): JSX.Element => {
        return languagesState.translateTokens(
            languagesState.getTranslation(
                'betslip.balance-warning-message.description',
                'You need [Amount] more to place this bet'
            ),

            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'Amount') {
                    const amount = sdkCustomer.money(requiredToBetAmount);
                    return <strong>{amount}</strong>;
                }
            }
        );
    };

    if (hasTopUpEnabled) {
        return (
            <BalanceDepositWrapper
                key='Wallet:minimum'
                onClick={state.onTopUp}
                isFakeButton={true}
            >
                {renderText()}
                <BalanceDepositEmphasised data-test='click-here-deposit-link'>
                    <I18n
                        langKey='betslip.balance-warning-message.label'
                        defaultText='Click here to deposit'
                    />
                </BalanceDepositEmphasised>
            </BalanceDepositWrapper>
        );
    }

    return (
        <BalanceDepositWrapper
            isFakeButton={false}
            key='Wallet:minimum'
        >
            {renderText()}
        </BalanceDepositWrapper>
    );
});
