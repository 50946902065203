import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { SrgQuestionnaireContainer, SrgQuestionnaireOverlay, SrgQuestionnaireCloseIcon } from 'src/domains/players/state/srgQuestionnaire/SrgQuestionnaire.style';
import { SrgQuestionnaireState } from './SrgQuestionnaireState';
import { useAppStateContext } from 'src/appState/AppState';
import { SrgFooterDescription } from 'src/domains/players/state/srgQuestionnaire/SrgFooterDescription';
import { SrgContent } from 'src/domains/players/state/srgQuestionnaire/srgContent/SrgContent';
import { SrgVerdict } from 'src/domains/players/state/srgQuestionnaire/SrgVerdict';

export const SrgQuestionnaire = observer('SrgQuestionnaire', (): JSX.Element | null => {
    const {
        appPlayersState: { usersState },
        appLayoutsState: {
            languagesState,
            configComponents: { config: { operatorFullName, accountHelperMail }
            }
        }
    } = useAppStateContext();

    const [state] = useState(() => new SrgQuestionnaireState(usersState));

    if (state.showSrgForm && usersState.isSrgModalOpen) {
        return (
            <SrgQuestionnaireOverlay data-test='srg-questionnaire'>
                <SrgQuestionnaireContainer>
                    {state.srgPassed === null ? (
                        <SrgQuestionnaireCloseIcon
                            onClick={usersState.toggleSrgModal}
                        />
                    ): null }
                    {state.srgPassed === null ?
                        <SrgContent
                            state={state}
                            languagesState={languagesState}
                            userName={state.userBasicData?.name}
                            operatorFullName={operatorFullName}
                            questionSet={state.questionSet}
                        /> :
                        <SrgVerdict
                            operatorFullName={operatorFullName}
                            srgPassed={state.srgPassed}
                            languagesState={languagesState}
                            handleCloseModal={usersState.toggleSrgModal}
                        />
                    }
                    <SrgFooterDescription
                        isGreetingPart={state.currentSrgStep === 0}
                        languagesState={languagesState}
                        accountHelperMail={accountHelperMail}
                        showMoreDescription={state.showMoreDescription}
                        toggleDescription={state.toggleDescription}
                        showToggleInfo={state.showToggleInfo}
                    />
                </SrgQuestionnaireContainer>
            </SrgQuestionnaireOverlay>
        );
    }

    return null;
});
