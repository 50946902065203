import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SeeMoreButton } from './see-more-button/SeeMoreButton';
import { MiniGame } from './mini-game/MiniGame';
import { GridBodyWrapper } from './GridBody.style';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';

interface GridBodyPropsType {
    appCasinoState: AppCasinoState;
}

export const GridBody = observer('GridBody', (props: GridBodyPropsType) => {
    const { appCasinoState } = props;
    const { miniGamesToDisplay } = appCasinoState.miniGamesListState;

    return (
        <>
            <GridBodyWrapper>
                {
                    miniGamesToDisplay.map(game => (
                        <MiniGame
                            appCasinoState={appCasinoState}
                            key={game.id}
                            gameId={game.id}
                        />
                    ))
                }
            </GridBodyWrapper>
            <SeeMoreButton appCasinoState={appCasinoState} />
        </>
    );
});
