import React, { useEffect, useState } from 'react';

import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

import { RaceCard } from 'src/domains/sportsbook/webview/components/raceCard/NewRaceCard';
import { MatchCard } from 'src/domains/sportsbook/webview/components/matchCard/MatchCard';

import { useAppStateContext } from 'src/appState/AppState';

import { observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import {
    EventCardWrapper,
    EventCardInfoWrapper,
} from 'src/domains/sportsbook/webview/components/eventCard/EventCard.style';
import { EventsTableMarketsLoader } from 'src/domains/sportsbook/webview/components/loaders/EventsTableMarketsLoader';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
interface PropsTypes {
    id: EventId;
    isNotificationsList: boolean;
}

const renderContent = (props: PropsTypes, isFooter: boolean): JSX.Element | undefined => {
    const { id, isNotificationsList } = props;

    const event = id.getEventModel();
    const sport = event?.sport;

    if (event === null) {
        return <EventsTableMarketsLoader />;
    }

    if (event.display === false) {
        return (
            <EventCardInfoWrapper>
                <I18n langKey='events.card.hidden' defaultText='This event is not available.' />
            </EventCardInfoWrapper>
        );
    }

    const templateId = event.template;

    switch (templateId) {
        case 'race':
            if (sport === 'horseracing' || sport === 'greyhoundracing') {
                return (
                    <RaceCard
                        sport={sport}
                        racecardBuildIds={[]}
                        racecardCollection={null}
                        racecardSelected={null}
                        isNotificationsList={isNotificationsList}
                        streamRmgWrapperIsVisible={false}
                    />
                );
            } else {
                console.error('Wrong sport name');
            }
            break;
        case 'match':
            return <MatchCard eventId={id} isFooter={isFooter} />;
        default:
            return <MatchCard eventId={id} isFooter={isFooter} />;
    }
};

class State {
    @observable public isFooter: boolean;

    public constructor() {
        makeObservable(this);
        this.isFooter = false;
    }

    public setVisible(isIntersecting: boolean): void {
        this.isFooter = isIntersecting;
    }
}

export const EventCard = observer('EventCard', (props: PropsTypes) => {
    const appState = useAppStateContext();
    const [state] = useState(() => new State());

    const content = renderContent(props, state.isFooter);

    useEffect(() => {
        const footer = document.querySelector('footer');

        if (footer === null) {
            return (): void => {};
        }

        const options = {
            root: null,
            threshold: 0,
            rootMargin: '0px',
        };

        const observer = new IntersectionObserver((entries) => {
            for (const item of entries) {
                state.setVisible(item.isIntersecting);
            }
        }, options);

        observer.observe(footer);

        return (): void => {
            observer.disconnect();
            appState.appSportsBookState.rab.closeRab();
        };
    }, []);

    return <EventCardWrapper className='event-card'>{content}</EventCardWrapper>;
});
