import styled from '@emotion/styled';



export const TableInfoBottom = styled('div', { label: 'TableInfoBottom' })`

    position: absolute;

    bottom: 0;

    left: 0;

    right: 0;

`;

