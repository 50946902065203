import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { HeaderLogo } from './header-logo/HeaderLogo';
import {
    HeaderWrapper,
    ButtonsWrapper,
    InFullScreenIcon,
    OutFullScreenOutIcon,
    ResizeButton,
    CloseGameIcon,
} from './ModalHeader.style';

interface PropsType {
    appCasinoState: AppCasinoState;
    gameDataState: GameDataState | null;
}

export const ModalHeader = observer('ModalHeader', (props: PropsType): React.ReactElement | null => {
    const { appCasinoState } = props;
    const { isFullScreen, toggleFullScreen, closeGame, showResizeButton } = appCasinoState.gameModalState;

    return (
        <HeaderWrapper data-test='game-modal-header'>
            <HeaderLogo />

            <ButtonsWrapper>
                {props.gameDataState?.isDemo !== true && (
                    <Button
                        onClick={appCasinoState.redirectState.redirectToDepositPage}
                        size='medium'
                        dataTest='game-modal-deposit-button'
                    >
                        <I18n langKey='casino.games.launch.deposit' defaultText='Deposit' />
                    </Button>
                )}
                {showResizeButton ? (
                    <ResizeButton
                        onClick={toggleFullScreen}
                        className={isFullScreen ? 'fullscreen' : ''}
                        data-test='game-modal-resize-button'
                    >
                        {isFullScreen ? <InFullScreenIcon /> : <OutFullScreenOutIcon />}
                    </ResizeButton>
                ) : null}
                {props.gameDataState?.isMini === false ? <CloseGameIcon onClick={closeGame} /> : null}
            </ButtonsWrapper>
        </HeaderWrapper>
    );
});
