import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const SinglesOnlyMessage = observer('SinglesOnlyMessage', () => {
    return (
        <Messages
            type='info'
            marginTop='10px'
            message={
                <I18n
                    langKey='betslip.warning-message.singles-only'
                    defaultText='One of your selections can be placed only as Single Only'
                />
            }
        />
    );
});
