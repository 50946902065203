import * as React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import {
    AppStoreIconWrapper,
    FooterList,
    GooglePlayIconWrapper,
    StoreAppLinks,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/getTheApp/GetTheApp.style';
import { FooterHeader } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

export const GetTheApp = observer('GetTheApp', (): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { env, configComponents } = appLayoutsState;
    const {
        appDownloadLink: { appStoreLink, googlePlayLink },
    } = configComponents.config;

    if (appStoreLink === null && googlePlayLink === null) {
        return null;
    }

    if (env.isMobileApp() === true) {
        return null;
    }

    return (
        <>
            <FooterHeader>
                <I18n
                    langKey='footer.get-app'
                    defaultText='Get the App'
                />
            </FooterHeader>

            <FooterList>
                {appStoreLink === null ? null : (
                    <StoreAppLinks to={appStoreLink}>
                        <AppStoreIconWrapper />
                    </StoreAppLinks>
                )}

                {googlePlayLink === null ? null : (
                    <StoreAppLinks to={googlePlayLink}>
                        <GooglePlayIconWrapper />
                    </StoreAppLinks>
                )}
            </FooterList>
        </>
    );
});
