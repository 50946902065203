import React, { useEffect, useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { HeaderWrapper, FormWrapper } from './ContactPreferencesProcedure.style';
import { action, computed, observable, makeObservable } from 'mobx';
import { OffersContactPreferencesState, OffersSettings } from 'src/domains/players/webview/components/SignUp';
import { AppPlayersState } from 'src/domains/players/state/AppPlayers.state';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

class ContactPreferencesProcedureState {
    @observable public infoMessage: 'success' | 'error' | '' = '';
    @observable public isProcessingRequest = false;

    public constructor(
        private readonly appPlayersState: AppPlayersState,
        public readonly offersContactState: OffersContactPreferencesState
    ) {
        makeObservable(this);
    }

    @computed public get status(): 'loading' | 'error' | 'ready' | undefined {
        const dataModel = this.appPlayersState.usersState.basicData.get();
        return dataModel?.type;
    }

    public updateIfChanged(): void {
        const dataModel = this.appPlayersState.usersState.basicData.get();
        if (dataModel.type === 'ready') {
            const { contactPreferences } = dataModel.value;
            this.offersContactState.emailState.setValue(contactPreferences.includes('email') ? 'yes' : 'no');
            this.offersContactState.smsState.setValue(contactPreferences.includes('sms') ? 'yes' : 'no');
            this.offersContactState.phoneState.setValue(contactPreferences.includes('phone') ? 'yes' : 'no');
            this.offersContactState.whatsappState.setValue(contactPreferences.includes('whatsApp') ? 'yes' : 'no');
        }
    }

    public renderMessage = (): JSX.Element | string => {
        if (this.infoMessage === 'error') {
            return (
                <Messages
                    type='error'
                    marginTop='8px'
                    message={
                        <I18n
                            langKey='account.contact-preferences.error-message'
                            defaultText='Sorry, we seem to have a problem. Please try again.'
                        />
                    }
                />
            );
        } else if (this.infoMessage === 'success') {
            return (
                <Messages
                    type='success'
                    marginTop='8px'
                    message={
                        <I18n
                            langKey='account.contact-preferences.success-message'
                            defaultText='Contact preferences have been successfully changed.'
                        />
                    }
                />
            );
        }

        return '';
    };

    @action public onSubmit = (): void => {
        if (this.appPlayersState.accountState.account === null) {
            return;
        }
        const contactPreferences = this.offersContactState.formatFormModel.result;
        if (contactPreferences.value.type === 'ok') {
            this.isProcessingRequest = true;
            this.infoMessage = '';
            this.appPlayersState.accountState.account
                .onChangeContactPreferences(contactPreferences.value.data)
                .then(() => {
                    this.infoMessage = 'success';
                    this.isProcessingRequest = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.infoMessage = 'error';
                });
        }
    };
}

export const ContactPreferencesProcedure = observer('ContactPreferencesProcedure', () => {
    const {
        appPlayersState,
        config: { contactPreferences },
    } = useAppStateContext();
    const [offersContactState] = useState(() => new OffersContactPreferencesState(contactPreferences));
    const [state] = useState(() => new ContactPreferencesProcedureState(appPlayersState, offersContactState));

    useEffect(() => {
        state.updateIfChanged();
    }, [state.status]);

    if (state.status === 'loading') {
        return <I18n langKey='account.contact-preferences.loading' defaultText='Loading...' />;
    }

    return (
        <FormWrapper>
            <HeaderWrapper data-test='offers-header'>
                <I18n
                    langKey='account.contact-preferences.contact-subtitle.label'
                    defaultText='How would you like to receive offers?'
                />
            </HeaderWrapper>

            <OffersSettings
                groupState={state.offersContactState}
                skin='light'
                onChange={state.onSubmit}
                isProcessingRequest={state.isProcessingRequest}
            />

            {state.renderMessage()}
        </FormWrapper>
    );
});
