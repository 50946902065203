//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/customer/account-basic-data -> get -> ${code}`);
    };
};

export const Response200IO = t.interface({
    addressLine1: t.union([t.null, t.undefined, t.string]),
    addressLine2: t.union([t.null, t.undefined, t.string]),
    ageVerification: t.string,
    antePostRules: t.boolean,
    bankAccountNumber: t.union([t.null, t.undefined, t.string]),
    bankAccountSortCode: t.union([t.null, t.undefined, t.string]),
    betReferralEnabled: t.boolean,
    birthDate: t.string,
    bogLimit: t.union([t.null, t.undefined, t.number]),
    bpEnabled: t.boolean,
    cancelWithdrawal: t.union([t.null, t.undefined, t.boolean]),
    cashoutEnabled: t.boolean,
    chatEnabled: t.boolean,
    city: t.union([t.null, t.undefined, t.string]),
    closureReasonId: t.union([t.null, t.undefined, t.number]),
    commission: t.boolean,
    complianceRestriction: t.union([t.null, t.undefined, t.interface({
        eventTime: t.string,
        pendingSuspensionTime: t.string,
        source: t.string,
    })]),
    contactPreferences: t.array(t.string),
    coolingOffPeriodHours: t.union([t.null, t.undefined, t.number]),
    country: t.union([t.null, t.undefined, t.string]),
    countryClub: t.union([t.null, t.undefined, t.boolean]),
    county: t.union([t.null, t.undefined, t.string]),
    cpf: t.union([t.null, t.undefined, t.string]),
    creditAccount: t.boolean,
    creditLimit: t.number,
    creditPaymentTerms: t.string,
    currency: t.string,
    dailyDepositLimit: t.union([t.null, t.undefined, t.number]),
    dailyDepositLimitDateSet: t.union([t.null, t.undefined, t.string]),
    dailyDepositLimitRequestDate: t.union([t.null, t.undefined, t.string]),
    dragonCircle: t.union([t.null, t.undefined, t.boolean]),
    email: t.union([t.null, t.undefined, t.string]),
    externalId: t.union([t.null, t.undefined, t.string]),
    failedLoginAttempts: t.number,
    firstName: t.union([t.null, t.undefined, t.string]),
    fullName: t.union([t.null, t.undefined, t.string]),
    gamStopVerification: t.string,
    id: t.number,
    incomeaccess: t.union([t.null, t.undefined, t.string]),
    isHVC: t.union([t.null, t.undefined, t.boolean]),
    kycRequestExpireDate: t.union([t.null, t.undefined, t.string]),
    kycStatus: t.string,
    kycWatchlist: t.union([t.null, t.undefined, t.interface({
        pep: t.boolean,
        reviewDate: t.union([t.null, t.undefined, t.string]),
        sanctions: t.boolean,
        validationDate: t.union([t.null, t.undefined, t.string]),
    })]),
    landlinePhone: t.union([t.null, t.undefined, t.interface({
        country: t.string,
        number: t.string,
        prefix: t.string,
    })]),
    language: t.union([t.null, t.undefined, t.string]),
    mailingEnabled: t.boolean,
    marketing: t.union([t.null, t.undefined, t.boolean]),
    marketingQuestionsAnswers: t.union([t.null, t.undefined, t.record(t.string, t.string)]),
    maxDailyDepositLimit: t.union([t.null, t.undefined, t.number]),
    maxLifetimeNetDepositLimit: t.union([t.null, t.undefined, t.number]),
    maxMonthlyDepositLimit: t.union([t.null, t.undefined, t.number]),
    maxWeeklyDepositLimit: t.union([t.null, t.undefined, t.number]),
    mobilePhone: t.union([t.null, t.undefined, t.interface({
        country: t.string,
        number: t.string,
        prefix: t.string,
    })]),
    monthlyDepositLimit: t.union([t.null, t.undefined, t.number]),
    monthlyDepositLimitDateSet: t.union([t.null, t.undefined, t.string]),
    monthlyDepositLimitRequestDate: t.union([t.null, t.undefined, t.string]),
    name: t.string,
    nextReviewDate: t.union([t.null, t.undefined, t.string]),
    oddsFormat: t.string,
    personalId: t.union([t.null, t.undefined, t.string]),
    playBreakDuration: t.union([t.null, t.undefined, t.number]),
    playBreakExpiry: t.union([t.null, t.undefined, t.string]),
    playBreakRequestDate: t.union([t.null, t.undefined, t.string]),
    pofStatus: t.union([t.null, t.undefined, t.union([t.literal('not-checked'), t.literal('requested'), t.literal('passed'), t.literal('failed')])]),
    postCode: t.union([t.null, t.undefined, t.string]),
    printedStatement: t.boolean,
    promoBanned: t.union([t.null, t.undefined, t.boolean]),
    promoID: t.union([t.null, t.undefined, t.string]),
    pushNotificationsEnabled: t.union([t.null, t.undefined, t.boolean]),
    quickBetReferralEnabled: t.boolean,
    reactivationRequestedDate: t.union([t.null, t.undefined, t.string]),
    realityCheckFrequency: t.union([t.null, t.undefined, t.number]),
    realityCheckFrequencyDateSet: t.union([t.null, t.undefined, t.string]),
    realityCheckFrequencyRequestDate: t.union([t.null, t.undefined, t.string]),
    referrer: t.union([t.null, t.undefined, t.string]),
    requestedDailyDepositLimit: t.union([t.null, t.undefined, t.number]),
    requestedMonthlyDepositLimit: t.union([t.null, t.undefined, t.number]),
    requestedRealityCheckFrequency: t.union([t.null, t.undefined, t.number]),
    requestedWeeklyDepositLimit: t.union([t.null, t.undefined, t.number]),
    reviewNetLosses: t.union([t.null, t.undefined, t.number]),
    reviewTotalDeposits: t.union([t.null, t.undefined, t.number]),
    rewardZone: t.union([t.null, t.undefined, t.boolean]),
    ringFencedFunds: t.boolean,
    roles: t.array(t.string),
    securityNotes: t.union([t.null, t.undefined, t.string]),
    selfExclusionDate: t.union([t.null, t.undefined, t.string]),
    selfExclusionDuration: t.union([t.null, t.undefined, t.number]),
    selfExclusionExpiry: t.union([t.null, t.undefined, t.string]),
    spOnlySports: t.union([t.null, t.undefined, t.array(t.string)]),
    srgStatus: t.union([t.null, t.undefined, t.union([t.literal('not-started'), t.literal('requested'), t.literal('passed'), t.literal('failed')])]),
    stakeFactor: t.number,
    stakeFactorInPlay: t.number,
    statementFrequency: t.string,
    status: t.string,
    statusDescription: t.union([t.null, t.undefined, t.string]),
    surname: t.union([t.null, t.undefined, t.string]),
    tartanClub: t.union([t.null, t.undefined, t.boolean]),
    telebettingId: t.union([t.null, t.undefined, t.string]),
    telebettingPassword: t.union([t.null, t.undefined, t.string]),
    terms: t.union([t.null, t.undefined, t.boolean]),
    title: t.union([t.null, t.undefined, t.string]),
    tradingNotes: t.union([t.null, t.undefined, t.string]),
    type: t.string,
    universe: t.string,
    watchlist: t.boolean,
    weeklyDepositLimit: t.union([t.null, t.undefined, t.number]),
    weeklyDepositLimitDateSet: t.union([t.null, t.undefined, t.string]),
    weeklyDepositLimitRequestDate: t.union([t.null, t.undefined, t.string]),
});
export type Response200Type = {
    addressLine1?: null | undefined | string;
    addressLine2?: null | undefined | string;
    ageVerification: string;
    antePostRules: boolean;
    bankAccountNumber?: null | undefined | string;
    bankAccountSortCode?: null | undefined | string;
    betReferralEnabled: boolean;
    birthDate: string;
    bogLimit?: null | undefined | number;
    bpEnabled: boolean;
    cancelWithdrawal?: null | undefined | boolean;
    cashoutEnabled: boolean;
    chatEnabled: boolean;
    city?: null | undefined | string;
    closureReasonId?: null | undefined | number;
    commission: boolean;
    complianceRestriction?: null | undefined | {
        eventTime: string;
        pendingSuspensionTime: string;
        source: string;
    };
    contactPreferences: Array<string>;
    coolingOffPeriodHours?: null | undefined | number;
    country?: null | undefined | string;
    countryClub?: null | undefined | boolean;
    county?: null | undefined | string;
    cpf?: null | undefined | string;
    creditAccount: boolean;
    creditLimit: number;
    creditPaymentTerms: string;
    currency: string;
    dailyDepositLimit?: null | undefined | number;
    dailyDepositLimitDateSet?: null | undefined | string;
    dailyDepositLimitRequestDate?: null | undefined | string;
    dragonCircle?: null | undefined | boolean;
    email?: null | undefined | string;
    externalId?: null | undefined | string;
    failedLoginAttempts: number;
    firstName?: null | undefined | string;
    fullName?: null | undefined | string;
    gamStopVerification: string;
    id: number;
    incomeaccess?: null | undefined | string;
    isHVC?: null | undefined | boolean;
    kycRequestExpireDate?: null | undefined | string;
    kycStatus: string;
    kycWatchlist?: null | undefined | {
        pep: boolean;
        reviewDate?: null | undefined | string;
        sanctions: boolean;
        validationDate?: null | undefined | string;
    };
    landlinePhone?: null | undefined | {
        country: string;
        number: string;
        prefix: string;
    };
    language?: null | undefined | string;
    mailingEnabled: boolean;
    marketing?: null | undefined | boolean;
    marketingQuestionsAnswers?: null | undefined | Record<string, string>;
    maxDailyDepositLimit?: null | undefined | number;
    maxLifetimeNetDepositLimit?: null | undefined | number;
    maxMonthlyDepositLimit?: null | undefined | number;
    maxWeeklyDepositLimit?: null | undefined | number;
    mobilePhone?: null | undefined | {
        country: string;
        number: string;
        prefix: string;
    };
    monthlyDepositLimit?: null | undefined | number;
    monthlyDepositLimitDateSet?: null | undefined | string;
    monthlyDepositLimitRequestDate?: null | undefined | string;
    name: string;
    nextReviewDate?: null | undefined | string;
    oddsFormat: string;
    personalId?: null | undefined | string;
    playBreakDuration?: null | undefined | number;
    playBreakExpiry?: null | undefined | string;
    playBreakRequestDate?: null | undefined | string;
    pofStatus?: null | undefined | 'not-checked' | 'requested' | 'passed' | 'failed';
    postCode?: null | undefined | string;
    printedStatement: boolean;
    promoBanned?: null | undefined | boolean;
    promoID?: null | undefined | string;
    pushNotificationsEnabled?: null | undefined | boolean;
    quickBetReferralEnabled: boolean;
    reactivationRequestedDate?: null | undefined | string;
    realityCheckFrequency?: null | undefined | number;
    realityCheckFrequencyDateSet?: null | undefined | string;
    realityCheckFrequencyRequestDate?: null | undefined | string;
    referrer?: null | undefined | string;
    requestedDailyDepositLimit?: null | undefined | number;
    requestedMonthlyDepositLimit?: null | undefined | number;
    requestedRealityCheckFrequency?: null | undefined | number;
    requestedWeeklyDepositLimit?: null | undefined | number;
    reviewNetLosses?: null | undefined | number;
    reviewTotalDeposits?: null | undefined | number;
    rewardZone?: null | undefined | boolean;
    ringFencedFunds: boolean;
    roles: Array<string>;
    securityNotes?: null | undefined | string;
    selfExclusionDate?: null | undefined | string;
    selfExclusionDuration?: null | undefined | number;
    selfExclusionExpiry?: null | undefined | string;
    spOnlySports?: null | undefined | Array<string>;
    srgStatus?: null | undefined | 'not-started' | 'requested' | 'passed' | 'failed';
    stakeFactor: number;
    stakeFactorInPlay: number;
    statementFrequency: string;
    status: string;
    statusDescription?: null | undefined | string;
    surname?: null | undefined | string;
    tartanClub?: null | undefined | boolean;
    telebettingId?: null | undefined | string;
    telebettingPassword?: null | undefined | string;
    terms?: null | undefined | boolean;
    title?: null | undefined | string;
    tradingNotes?: null | undefined | string;
    type: string;
    universe: string;
    watchlist: boolean;
    weeklyDepositLimit?: null | undefined | number;
    weeklyDepositLimitDateSet?: null | undefined | string;
    weeklyDepositLimitRequestDate?: null | undefined | string;
};
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiProxyAccountBasicDataRequest"
 */
export const openapi_proxy_account_basic_data = async (api_url: string, api_timeout: number, backendToken: string, _params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/customer/account-basic-data${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiProxyAccountBasicDataParamsType = ParamsType;

export type OpenapiProxyAccountBasicDataResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiProxyAccountBasicDataResponse200Type = Response200Type;

export const openapiProxyAccountBasicDataRequest = async (api_url: string, api_timeout: number, backendToken: string, _params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiProxyAccountBasicDataResponseType> => {
    const response = await openapi_proxy_account_basic_data(api_url, api_timeout, backendToken, _params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiProxyAccountBasicDataRequest - unhandled response ${response.status}`);
};

