import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const TransactionDetailsWrapper = styled('div', { label: 'TransactionDetailsWrapper' })`
    margin: 0 16px;
`;

export const DepositReceiptDashedBorder = withConfig(theme => styled('span', { label: 'DepositReceiptDashedBorder' })`
    background-image: linear-gradient(to right, ${theme.star.depositPaymentMethods.bgColor} 50%, rgba(255, 255, 255, 0) 0%);
    background-position: center;
    background-repeat: repeat-x;
    background-size: 16px 2px;
    display: inline-block;
    height: 2px;
    margin: 24px 0 4px -5px;
    width: 100%;
`);

export const ParticularDetail = withConfig(theme => styled('div', { label: 'ParticularDetail' })`
    align-items: center;
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    height: 30px;
    justify-content: space-between;
    line-height: 1.2;
    text-align: left;
    @media ${theme.star.mediaQuery.xMobile} {
        font-size: ${theme.star.fontSize.small};
    }
`);

export const ParticularValue = withConfig(theme => styled('p', { label: 'ParticularValue' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    text-align: right;
    @media ${theme.star.mediaQuery.xMobile} {
        font-size: ${theme.star.fontSize.small};
    }
`);
