export const iterateByLegsRefferal = <T extends { selectionId: number | null }>(
    list: Array<T>
): Array<[T & { selectionId: number }, number]> => {
    const prev: Map<number, number> = new Map();
    const result: Array<[T & { selectionId: number }, number]> = [];

    for (const item of list) {
        const selectionId = item.selectionId;

        if (selectionId !== null) {
            const nextIndex = prev.get(selectionId) ?? 0;
            const newItem = {
                ...item,
                selectionId,
            };
            result.push([newItem, nextIndex]);
            prev.set(selectionId, nextIndex + 1);
        }
    }

    return result;
};
