import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import {
    NotificationImg,
    HtmlWrapper,
    HtmlOverlayHrefMask,
    HtmlOverlayMask,
    HtmlIframeWrapper,
    VideoWrapper,
    VideoIframeWrapper,
} from './Notification.style';
import { NotificationMediaState } from './NotificationMedia.state';
import { PromoNotificationsModelForView } from 'src/domains/layouts/state/notificationsState/NotificationsState';

interface PropsTypes {
    notification: PromoNotificationsModelForView;
}

export const NotificationMedia = observer('NotificationMedia', ({ notification }: PropsTypes) => {
    const appState = useAppStateContext();
    const { appLayoutsState, appCasinoState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const isMobile = appLayoutsState.breakpointsState.desktop.isBiggerOrEq === false;
    const [state] = React.useState(
        () => new NotificationMediaState(starRouter, notification, appCasinoState, appState.appSportsBookState.models)
    );

    const { cta_url, img_url, html_url, video_url, title, media_type, action_template } = notification;
    const video_url_for_view = video_url ?? '';
    const img_url_for_view = img_url ?? '';
    const html_url_or_view = html_url ?? '';

    const handleMatchUrl = (): void => {
        if (cta_url === null || cta_url === undefined) return;
        if (cta_url.includes('account=') && isMobile) {
            starRouter.redirectToHomepage();
        }
        setTimeout(() => {
            starRouter.handleUrlRedirection(cta_url);
        }, 0);
    };

    if (media_type === 'video' && video_url_for_view !== '') {
        return (
            <VideoWrapper>
                <VideoIframeWrapper
                    title={title}
                    src={video_url_for_view}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    width='100%'
                    height='100%'
                />
            </VideoWrapper>
        );
    }

    if (media_type === 'static' && img_url_for_view !== '') {
        if (action_template === 'direct-link') {
            return (
                <div onClick={handleMatchUrl}>
                    <NotificationImg
                        alt=''
                        imgBase={{
                            src: img_url_for_view,
                            optimizeOptions: {
                                width: 260,
                                quality: 75,
                            },
                        }}
                        placeholder={false}
                    />
                </div>
            );
        }

        return (
            <div onClick={state.handleOnClick}>
                <NotificationImg
                    alt=''
                    imgBase={{
                        src: img_url_for_view,
                        optimizeOptions: {
                            width: 260,
                            quality: 75,
                        },
                    }}
                    placeholder={false}
                />
            </div>
        );
    }

    if (media_type === 'html' && html_url_or_view !== '') {
        if (action_template === 'direct-link') {
            return (
                <HtmlWrapper>
                    <HtmlIframeWrapper
                        title={title}
                        src={html_url_or_view}
                        frameBorder='0'
                        width='100%'
                        height='100%'
                    />
                    <HtmlOverlayHrefMask
                        target='_blank'
                        href={cta_url ?? ''}
                    />
                </HtmlWrapper>
            );
        }

        return (
            <HtmlWrapper onClick={state.handleOnClick}>
                <HtmlIframeWrapper
                    title={title}
                    src={html_url_or_view}
                    frameBorder='0'
                    width='100%'
                    height='100%'
                />
                <HtmlOverlayMask />
            </HtmlWrapper>
        );
    }

    return null;
});
