import { action, computed, observable, makeObservable } from 'mobx';
import { 
    CustomSelectionType,
    FieldType
} from './commonCmsGuard';
import { MarketId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

export interface MaskModelType {
    url: string;
    color: string;
}

 interface DetailsListType {
     label: string;
     value: string | number;
 }

export class AlternativeCustomSelectionState {

    @observable public isShowDetails: boolean = false;

    public constructor(
        private readonly marketId: MarketId,
        private readonly selectionId: SelectionId,
        private readonly getCustomSelectionByMarketAndSelectionId: (marketId: MarketId, selectionId: SelectionId) => CustomSelectionType | null,
    ) {
        makeObservable(this);
    }

    @computed public get customSelectionRaw(): CustomSelectionType | null {
        return this.getCustomSelectionByMarketAndSelectionId(this.marketId, this.selectionId);
    }

    @computed public get starValue(): number {
        return this.customSelectionRaw?.star_value ?? 0;
    }

    @computed public get selectionType(): string {
        return this.customSelectionRaw?.selection_type ?? '';
    }

    @computed public get fields(): Array<FieldType> {
        return this.customSelectionRaw?.fields ?? [];
    }

    @computed public get name(): string {
        const selectionModel = this.selectionId.getModel();
        return selectionModel?.name ?? '';
    }

    @computed public get description(): string {
        return this.customSelectionRaw?.description ?? '';
    }

    @computed public get mask(): MaskModelType | null {
        const selectionModel = this.selectionId.getModel();
        const feedSilk = selectionModel?.metaDataSilkUrl ?? null;
        const color = this.customSelectionRaw?.color ?? '';
        const imageUrl = this.customSelectionRaw?.image ?? '';

        return {
            url: feedSilk ?? imageUrl,
            color: color
        };
    }

    @computed public get detailsListForView(): Array<DetailsListType> {
        return this.fields.filter(field => field.value.length > 0);
    }

    @computed public get isShouldShowDetails(): boolean {
        return this.detailsListForView.length > 0;
    }

    @action public handleToggleShowDetails = (): void => {
        if (this.isShouldShowDetails) {
            this.isShowDetails = !this.isShowDetails;
        }
    };
};
