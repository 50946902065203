import { keyframes } from '@emotion/react';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



const dotsAnimation = keyframes`

    0% {

        opacity: 0.4;

    }

    50% {

        opacity: 1;

    }

    100% {

        opacity: 0.4;

    }

`;



export const DotsAnimation = withConfig(theme => styled('div', { label: 'DotsAnimation' })`

    animation: 1.5s ${dotsAnimation} 0.5s infinite ease;

    background: ${theme.star.traderChat.messages.bgColor};

    border-radius: 50%;

    height: 8px;

    margin: auto;

    opacity: 0.4;

    position: relative;

    width: 8px;

    &::after,

    &::before {

        animation: ${dotsAnimation} 1.5s infinite ease;

        background: ${theme.star.traderChat.messages.bgColor};

        border-radius: 50%;

        content: '';

        height: 8px;

        position: absolute;

        width: 8px;

    }

    &::before {

        margin-right: 2px;

        right: 100%;

        animation-delay: 0s;

    }

    &::after {

        left: 100%;

        margin-left: 2px;

        animation-delay: 1s;

    }

`);

