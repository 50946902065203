import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Wrapper, Description, CloseIconWrapper } from './MarketingNotificationsBanner.style';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { useAppStateContext } from 'src/appState/AppState';

export const MarketingNotificationsBanner = observer('MarketingNotificationsBanner', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const { availableQuestions, dismissQuestion } = appPlayersState.marketingNotificationsState;

    if (availableQuestions.length === 0) {
        return null;
    }

    const question = availableQuestions[0];

    if (question === undefined) {
        return null;
    }

    const handleRedirection = (): void => {
        starRouter.redirectToPreferences();
    };

    const handleClose = (): void => {
        dismissQuestion(question.id);
    };

    return (
        <Wrapper data-test='marketing-notifications-banner'>
            <Description data-test='description'>
                {question.name ?? ''}
            </Description>
            <Button
                type='button'
                size='xs'
                onClick={handleRedirection}
                dataTest='select-button'
            >
                <I18n langKey='promotion.desktop-select' defaultText='Select' />
            </Button>
            <CloseIconWrapper
                onClick={handleClose}
            />
        </Wrapper>
    );
});
