import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { SelectWrapper, SelectInputContainer, SelectInputLabel } from './PaymentMethodDropdown.style';
import { ChevronIconWrapper } from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';
import { components, OptionProps } from 'react-select';
import { OptionType, checkOptionType } from 'src/domains/layouts/webview/components/dropdown/dropdown.types';
import { useAppStateContext } from 'src/appState/AppState';
import { getSavedCards } from 'src_server/trpc/types';

export class PaymentMethodDropdownStateInner {
    public constructor(private readonly onChangeCB: (value: string) => void) {}

    public onChange = (value: string): void => {
        this.onChangeCB(value);
    };
}

export interface DropDownPropsType {
    allowAddingMethod?: boolean;
    label: string | JSX.Element;
    options: getSavedCards.SavedCardInterface[] | null;
    value: string;
    onChangeCB: (value: string) => void;
}

export const PaymentMethodDropdown = observer('PaymentMethodDropdown', (props: DropDownPropsType) => {
    const { allowAddingMethod = false, label, options, value, onChangeCB } = props;

    const [state] = useState(() => new PaymentMethodDropdownStateInner(onChangeCB));
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;

    const onChange = (selectedOption: unknown): void => {
        if (checkOptionType(selectedOption)) {
            state.onChange(selectedOption.value);
            return;
        }
        console.error('Payment Methods Dropdown - payment method onChange error');
        return;
    };

    const allOptionsRender = (): OptionType[] => {
        if (options === null) {
            return [];
        }

        const optionsParsed = options.map((v) => {
            return {
                value: v.id,
                label: `**** **** **** ${v.number.substr(-4)}`,
            };
        });

        if (allowAddingMethod) {
            optionsParsed.unshift({
                label: languagesState.getTranslation('account.payment-method-dropdown.new.label', 'New payment method'),
                value: 'none',
            });
            return optionsParsed;
        }

        return optionsParsed;
    };

    const activeElement = (): OptionType | null => {
        const active = allOptionsRender().find((option) => value === option.value) ?? null;
        return active;
    };

    return (
        <SelectInputContainer>
            <SelectInputLabel data-test='select-input-label'>{label}</SelectInputLabel>

            <SelectWrapper
                value={activeElement()}
                options={allOptionsRender()}
                className='custom-dropdown-select'
                classNamePrefix='react-select'
                onChange={onChange}
                components={{
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    Option: (props: OptionProps): JSX.Element => (
                        <components.Option {...props}>{props.label}</components.Option>
                    ),
                    DropdownIndicator: (): JSX.Element => <ChevronIconWrapper position='down' />,
                }}
                isSearchable={false}
            />
        </SelectInputContainer>
    );
});
