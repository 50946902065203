import React from 'react';
import { InputStateAndModel } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/VerifyWithSMS.state';
import { SMSCodeState } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/SMSCode.state';
import { SMSInput } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/SMSInput';
import { observer } from 'src/utils/mobx-react';
import { VerifyAccountWithSmsCells } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/SMSCode.style';

interface SMSCodePropsType {
    inputs: InputStateAndModel[]
}

export const SMSCode = observer('SMSCode', ({ inputs }: SMSCodePropsType) => {
    const [state] = React.useState(() => new SMSCodeState());

    return <VerifyAccountWithSmsCells key='verification-code'>
        {
            inputs.map((input, idx) => {
                return (
                    <SMSInput
                        key={idx}
                        idx={idx}
                        input={input}
                        state={state}
                    />
                );
            })
        }
    </VerifyAccountWithSmsCells>;
});
