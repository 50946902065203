import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { HeaderWrapper, HeaderImageWrapper } from './Header.style';
import { StreamComponent } from './StreamComponent';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    eventId: EventId | null;
}
export const Header = observer('Header-Alternative-Event', (props: PropsType) => {
    const { appSportsBookState: { alternativeEventState } } = useAppStateContext();
    const { getAlternativeEventItemById, eventId } = alternativeEventState.get(props.eventId);
    const eventItem = eventId === null ? null : getAlternativeEventItemById(eventId);

    if (eventId === null || eventItem === null) {
        return null;
    }

    const { isStreamUrlAvailable, bgHeaderImages, bgHeaderUnderStreamImages, isShowHeaderWithoutStream, bgHeaderHeightImages } = eventItem;

    if ( isStreamUrlAvailable === true) {
        return (
            <HeaderWrapper data-test='picture' banners={bgHeaderUnderStreamImages}>
                <StreamComponent eventId={eventId}/>
            </HeaderWrapper>
        );
    }
    if (isShowHeaderWithoutStream) {
        return (
            <HeaderImageWrapper banners={bgHeaderImages} heights={bgHeaderHeightImages}/>
        );
    }

    return null;
});
