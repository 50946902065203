import * as React from 'react';
import { ConfirmationBox } from './ConfirmationsBoxWrapper/ConfirmationBox';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';

export const ConfirmationsBoxWrapper = observer('ConfirmationsBoxWrapper', () => {
    const { appPlayersState: { confirmationBox: { confirmationForView } } } = useAppStateContext();

    if (confirmationForView === null) {
        return null;
    }

    const { title, message, onConfirm, onReject } = confirmationForView;

    return (
        <ConfirmationBox
            message={message}
            title={title}
            onConfirm={onConfirm}
            onReject={onReject}
        />
    );
});
