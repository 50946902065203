import { CalendarEndIcon } from 'src/domains/layouts/shared/assets/icons/CalendarEnd';
import { StopWatchIcon } from 'src/domains/layouts/shared/assets/icons/StopWatchIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const OfferTimer = withConfig(theme => styled('div', { label: 'OfferTimer' })`
    align-items: flex-start;
    align-self: flex-start;
    background-color: ${theme.star.statuses.error.bgColor};
    border-radius: 4px;
    color: ${theme.star.statuses.error.txtColor};
    display: flex;
    margin-bottom: 10px;
    padding: 8px;
`);

export const RejectLink = withConfig(theme => styled('a', { label: 'RejectLink' })`
    color: ${theme.star.betslipGeneral.txtColor};
`);

export const BetSlipOfferTimerContent = withConfig(theme => styled('div', { label: 'BetSlipOfferTimerContent' })`
    line-height: 1.3334;
    font-size: ${theme.star.fontSize.small};
`);

export const WarningIconWrapper = styled(WarningIcon, { label: 'WarningIconWrapper' })`
    fill: currentcolor;
    margin-right: 10px;
    flex: 0 0 32px;
`;

export const CalendarEndIconWrapper = styled(CalendarEndIcon, { label: 'CalendarEndIconWrapper' })`
    fill: currentcolor;
    margin-right: 10px;
    flex: 0 0 32px;
`;

export const StopWatchIconWrapper = styled(StopWatchIcon, { label: 'StopWatchIconWrapper' })`
    fill: currentcolor;
    margin-right: 10px;
    flex: 0 0 32px;
`;
