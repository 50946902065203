import { CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { CategoryType, CollectionType } from 'src/domains/casino/state/AppCasino/store/game-store/typesExt';
import { computed, makeObservable } from 'mobx';
import { GameStoreExt } from './store/game-store/GameStoreExt';
import { RedirectState } from './Redirect.state';

interface CasinoHomeCategoryType {
    gameModels: Array<CasinoGameModelExt>;
    categoryModel?: CategoryType;
    redirect?: () => void;
}

const NEW_GAMES_NAME = 'New';
const POPULAR_GAMES_NAME = 'Popular';
const TRENDING_GAMES_NAME = 'Trending';
const TABLE_GAMES_NAME = 'Table Games';

export class HomeCollections {
    public constructor(
        private readonly gameStoreExt: GameStoreExt,
        private readonly redirectState: RedirectState
    )
    {
        makeObservable(this);
    }

    private getHomeCollection(collection: CollectionType, categoryName: string, redirect: (id: number | string) => void): CasinoHomeCategoryType {
        const categoryModel = this.gameStoreExt.getCollection(collection).getCategoryByName(categoryName);
        const gameModels = this.gameStoreExt.getCollection(collection).filterByCategoryName(categoryName).games;

        if (categoryModel === null) {
            return {
                gameModels: []
            };
        }

        return {
            gameModels: gameModels,
            categoryModel: categoryModel,
            redirect: (): void => redirect(categoryModel.id)
        };
    };

    @computed public get casinoPopularGames(): CasinoHomeCategoryType {
        return this.getHomeCollection('casino', POPULAR_GAMES_NAME, this.redirectState.redirectToCasinoTab);
    }

    @computed public get casinoNewGames(): CasinoHomeCategoryType {
        return this.getHomeCollection('casino', NEW_GAMES_NAME, this.redirectState.redirectToCasinoTab);
    }

    @computed public get casinoTrendingGames(): CasinoHomeCategoryType {
        return this.getHomeCollection('casino', TRENDING_GAMES_NAME, this.redirectState.redirectToCasinoTab);
    }

    @computed public get casinoTableGames(): CasinoHomeCategoryType {
        return this.getHomeCollection('casino', TABLE_GAMES_NAME, this.redirectState.redirectToCasinoTab);
    }

    @computed public get liveCasinoPopularGames(): CasinoHomeCategoryType {
        return this.getHomeCollection('live-casino', POPULAR_GAMES_NAME, this.redirectState.redirectToLiveCasinoTab);
    }

    @computed public get liveCasinoNewGames(): CasinoHomeCategoryType {
        return this.getHomeCollection('live-casino', NEW_GAMES_NAME, this.redirectState.redirectToLiveCasinoTab);
    }

    private getHomeCustomCategoryCollection(collection: CollectionType, redirect: (id: number | string) => void): Array<CasinoHomeCategoryType> {
        const categoryModels = this.gameStoreExt.getCollection(collection).customCategoriesVisibleInAllGames;
        const output = [];

        for (const categoryModel of categoryModels) {
            const gameModels = this.gameStoreExt.getCollection(collection).filterByCategoryId(categoryModel.id).games;

            output.push({
                gameModels: gameModels,
                categoryModel: categoryModel,
                redirect: (): void => redirect(categoryModel.id)
            });
        }

        return output;
    }

    @computed public get casinoCustomGames(): Array<CasinoHomeCategoryType> {
        return this.getHomeCustomCategoryCollection('casino', this.redirectState.redirectToCasinoTab);
    }

    @computed public get liveCasinoCustomGames(): Array<CasinoHomeCategoryType> {
        return this.getHomeCustomCategoryCollection('live-casino', this.redirectState.redirectToLiveCasinoTab);
    }

    @computed public get recentGameModels(): Array<CasinoGameModelExt> {
        const recentGamesIds = this.gameStoreExt.contextModel.userRecentGamesResource.getReady()?.game_ids ?? [];
        const gameModels = [];

        for (const gameId of recentGamesIds) {
            const gameModel = this.gameStoreExt.getGameModel(gameId);

            if (gameModel !== null) {
                gameModels.push(gameModel);
            }
        }

        return gameModels;
    }

    @computed public get homepageCasinoPopularGames(): CasinoHomeCategoryType {
        return this.getHomeCollection('casino', POPULAR_GAMES_NAME, this.redirectState.redirectToCasinoPage);
    }

    @computed public get homepageVirtualGames(): CasinoHomeCategoryType {
        const gameModels = this.gameStoreExt.getCollection('virtuals').filterByVisibleInAllGames().games;

        return {
            gameModels: gameModels,
            redirect: this.redirectState.redirectToVirtualsPage
        };
    }
}
