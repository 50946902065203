import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ZenetPayTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/zenetPayProvider/ZenetPayTab.state';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import {
    DepositContainer,
    PayButton,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/zenetPayProvider/ZenetPayTab.style';
import {
    CashBalance,
    CashBalanceWrapper,
    DepositInputLabel,
    DepositTabWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/DepositProcedure.style';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { PaymentProviderImages } from './assets/PaymentProviderImages';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    state: ZenetPayTabState;
}

export const ZenetPayTab = observer('ZenetPayTab', ({ state }: PropsType): JSX.Element => {
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();

    return (
        <DepositTabWrapper>
            <CashBalanceWrapper>
                <I18n langKey='account.top-up.cash-balance-label' defaultText='Cash balance' />
                <CashBalance>{state.showBalance}</CashBalance>
            </CashBalanceWrapper>

            <DepositContainer>
                <Input
                    type='text'
                    maxLength={10}
                    placeholder='0.00'
                    currency={usersState.moneySymbol}
                    label={
                        <DepositInputLabel langKey='account.top-up.description-label' defaultText='Deposit amount' />
                    }
                    state={state.depositAmount}
                    onChange={state.onChange}
                    onBlur={state.setAmount}
                    colorTheme='light'
                    inputHasBackground={false}
                    dataTest='deposit-amount-input'
                />

                <PaymentAdditions
                    key='payment-additions'
                    currency={usersState.moneySymbol}
                    value={state.depositAmount.value}
                    options={['50', '100', '250', '500']}
                    onChange={state.handleAdditionsChange}
                />

                {state.errorMessage.length > 0
                    ? state.errorMessage.map((errorString) => (
                          <Messages
                              type='error'
                              marginTop='8px'
                              marginBottom='0'
                              key={errorString}
                              message={errorString}
                          />
                      ))
                    : null}

                <PayButton
                    size='large'
                    disabled={state.isButtonDisabled || state.areMissingFieldsForBillingInfoZenetPay()}
                    onClick={state.submitDepositForm}
                    type='submit'
                    dataTest='generate-qr-code-button'
                >
                    <I18n langKey='account.top-up.zenet-pay.pay-card-button' defaultText='Next' />
                </PayButton>
                <PaymentProviderImages />
            </DepositContainer>
        </DepositTabWrapper>
    );
});
