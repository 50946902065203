import React, { useState } from 'react';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import {
    HeaderCloseIcon,
    TitleWrapper,
    HeaderWrapper,
    ArrowBackIcon,
} from 'src/domains/players/webview/components/Account/accountDrawerHeader/AccountDrawerHeader.style';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { UniverseComponent } from 'src/domains/common/universes';

export const TraderChatTitleHeader = observer('TraderChatTitleHeader', () => (
    <UniverseComponent>
        {(universeVersion): React.ReactElement => <universeVersion.traderChatHeaderLabel />}
    </UniverseComponent>
));

interface PropTypes {
    title: React.ReactNode | null | string;
    hasClose: boolean;
    hasBack: boolean;
    parent?: string;
    icon?: () => JSX.Element;
    isSmallerVersion?: boolean;
    isAuthorized?: boolean;
}

class AccountDrawerHeaderState {
    public constructor(
        private readonly parent: string | null = null,
        private readonly router: StarRouter
    ) {}

    public redirectToParent = (): void => {
        if (this.parent !== null) {
            if (this.parent === 'summary') {
                this.router.redirectToAccountSummary();
            }
            if (this.parent === 'login') {
                this.router.redirectToLogin();
            }
            if (this.parent === 'reset-password') {
                const { token, email, isVerify } = this.router.resetPasswordParams;
                this.router.redirectToResetPassword(token ?? '', email ?? '', isVerify);
            }
        }
    };
}

export const AccountDrawerHeader = observer('AccountDrawerHeader', (props: PropTypes): React.ReactElement => {
    const { title, hasClose, hasBack, parent, isSmallerVersion, icon } = props;
    const { appLayoutsState } = useAppStateContext();
    const { starRouter } = appLayoutsState;

    const [state] = useState(() => new AccountDrawerHeaderState(parent ?? null, starRouter));

    let backView = null;
    let iconView = null;
    let titleView = null;
    let closeView = null;

    if (hasBack === true && parent !== undefined) {
        backView = <ArrowBackIcon position='left' onClick={state.redirectToParent} />;
    }

    if (hasBack === true && parent === 'home') {
        backView = <ArrowBackIcon position='left' onClick={starRouter.closeAccount} />;
    }

    if (hasBack === true && parent === 'login') {
        backView = <ArrowBackIcon position='left' onClick={state.redirectToParent} />;
    }

    if (icon !== undefined) {
        iconView = <>{icon()}</>;
    }

    if (hasClose === true) {
        closeView = <HeaderCloseIcon onClick={starRouter.closeAccount} />;
    }

    if (title !== null) {
        // TODO: data-test='title-header' same as in signupParts/termsAndConditions/TermsPage.tsx - should be changed to be more specific
        titleView = (
            <TitleWrapper
                key='title'
                hasIcon={iconView !== null || backView !== null}
                data-test='title-header'
                isSmallerVersion={isSmallerVersion}
            >
                {title}
            </TitleWrapper>
        );
    }

    return (
        <HeaderWrapper>
            {backView}
            {iconView}
            {titleView}
            {closeView}
        </HeaderWrapper>
    );
});
