import { computed, makeObservable } from 'mobx';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventRow6PackState } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/EventRow6Pack.state';
import { getTeamLogo } from './NFLTeamLogos';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { getMarketNameNFL } from 'src/domains/layouts/shared/translations/markets';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export class NFLEventRow6PackState implements EventRow6PackState {

    public constructor(
        public readonly eventId: EventId,
        public readonly language: LanguagesState,
    ) {
        makeObservable(this);
    }

    @computed public get event():EventModel | null {
        return this.eventId.getEventModel();
    }

    // --------------------

    public get firstMarketName():string {
        const marketsNamesNFL = getMarketNameNFL(this.language.getTranslation);
        return marketsNamesNFL[0] ?? '-';
    }

    public get secondMarketName():string {
        const marketsNamesNFL = getMarketNameNFL(this.language.getTranslation);
        return marketsNamesNFL[1] ?? '-';
    }

    public get thirdMarketName():string {
        const marketsNamesNFL = getMarketNameNFL(this.language.getTranslation);
        return marketsNamesNFL[2] ?? '-';
    }

    // --------------------

    @computed public get firstMarket():MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const moneyLine = event.markets.filter(x => x.templateId === 'match-winner');
        return moneyLine[0];
    }

    @computed public get secondMarket():MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const spreads = event.markets.filter(x => x.templateId === 'two-way-handicap');
        return (
            spreads.length === 1 ? spreads[0] : spreads[1]
        );
    }

    @computed public get thirdMarket():MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const totalPoints = event.markets.filter(x => x.templateId === 'total-points-over-under');
        return (
            totalPoints.length === 1 ? totalPoints[0] : totalPoints[1]
        );
    }

    // --------------------

    public getSelectionName = (market:MarketModel | undefined, selection: SelectionModel): string => {
        if (market?.name.includes('Total') === true) {
            return selection.name;
        }
        return selection.name.split(' ').slice(-1).join(' ');
    };

    // --------------------

    public getParticipantLogo = (name: string | null): string => {
        return getTeamLogo(name);
    };

}
