export function parseQueryString(qs: string): Record<string, string | true> {
    const result = {};

    if (qs !== '') {
        //@ts-expect-error
        qs = qs.split('&');

        for (let part of qs) {
            //@ts-expect-error
            part = part.split('=');
            //@ts-expect-error
            result[decodeURIComponent(part.shift())] = part.length > 0 ? decodeURIComponent(part.join('=')) : true;
        }
    }

    return result;
}

export function buildQueryString(data: Record<string, string | number | boolean | undefined | true | null>): string {
    /* eslint-disable-next-line */
    if (!data) {
        return '';
    }

    return Object.keys(data)
        .filter(key => data[key] != null)
        //@ts-expect-error
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
}

export interface ParseResult {
    url: string;
    path: string;
    query: Record<string, string>;
}

export function parse(url: string): ParseResult {
    const result = {
        url
    };

    //@ts-expect-error
    url = url.split('?');
    //@ts-expect-error
    result.path = url.shift();
    //@ts-expect-error
    result.query = parseQueryString(url.join('?'));

    //@ts-expect-error
    return result;
}
