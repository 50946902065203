import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface BulletsPositionPropsType {
    bulletsPosition?: 'left' | 'center' | undefined;
}

const bulletsPositionType = ( bulletsPosition?: 'left' | 'center' | undefined ): string => {
    if (bulletsPosition !== undefined) {
        if (bulletsPosition === 'left') {
            return `
                left: 18px;
                margin: 0;
                text-align: left;
            `;
        }
        if (bulletsPosition === 'center') {
            return `
                left: 0;
                right: 0;
                margin: 0 0 0 -16px;
                text-align: center;
            `;
        }
    }
    return `
        left: 18px;
        margin: 0;
        text-align: left;`
    ;
};


export const CarouselDots = withConfig(theme => styled('div', { label: 'CarouselDots' })<BulletsPositionPropsType>`
    position: absolute;
    bottom: 15px;
    ${({ bulletsPosition }): string => bulletsPositionType(bulletsPosition)};
    @media ${theme.star.mediaQuery.desktop} {
        display: none;
    }
`);

interface CarouselDotPropsType {
    indexOfActive: boolean;
};

export const CarouselDot = withConfig(theme => styled('div', { label: 'CarouselDot' })<CarouselDotPropsType>`
    display: inline-block;
    background-color: ${({ indexOfActive }): string => indexOfActive ? `${theme.star.notifications.marketing.bgColor}` : `${theme.star.notifications.marketing.bgColorSecondary}` };
    cursor: pointer;
    padding: 0;
    width: 8px;
    height: 8px;
    margin-right: 4px;
    border-radius: 50%;
    &:last-child {
        margin-right: 0;
    }
`);
