import styled from '@emotion/styled';

interface PropsType {
    maxWidth?: string;
}

export const PaymentMethodListWrapper = styled('ul', { label: 'PaymentMethodListWrapper' })<PropsType>`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 18px 0 0;
    max-width: 136px;
`;
