import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { InfoHeader, InfoSubHeader, NoConnectionWrapper, WarningIconWrapper } from './NoConnection/NoConnection.style';

export const NoConnection = (): JSX.Element => (
    <NoConnectionWrapper>
        <WarningIconWrapper />;
        <InfoHeader>
            <I18n
                langKey='layouts.page-no-connection.header'
                defaultText='There is no internet'
            />
        </InfoHeader>
        <InfoSubHeader>
            <I18n
                langKey='layouts.page-no-connection.sub-header'
                defaultText='Please check your connection and try again.'
            />
        </InfoSubHeader>
    </NoConnectionWrapper>
);
