import { Resource } from 'src_common/common/mobx-utils/Resource';
import { SportsList, SportLinkType } from 'src/domains/layouts/state/sportsList/SportsList';
import { apiCommon } from 'src/api/ApiCommon';
import { computed, observable, makeObservable } from 'mobx';

const isEqual = (a: Set<string>, b: Set<string>): boolean => {
    if (a.size !== b.size) {
        return false;
    }

    for (const item of a) {
        if (b.has(item) === false) {
            return false;
        }
    }

    return true;
};
export class NavigationFavourites {

    private isSync: boolean = false;

    private readonly sportsList: SportsList;
    private readonly favourites: Resource<Array<string>>;
    @observable.ref private optimistic: Record<string, boolean>;

    public constructor(sportsList: SportsList) {
        makeObservable(this);
        this.sportsList = sportsList;
        this.favourites = new Resource(() => apiCommon.getUserFavouritesSport.run({}));
        this.optimistic = {};
    }

    @computed private get favouritesSet(): Set<string> {
        const favouritesSport = this.favourites.get();

        if (favouritesSport.type === 'ready') {
            return new Set(favouritesSport.value);
        }

        return new Set();
        
    }

    @computed private get favouritesWithOptimistic(): Set<string> {
        const out: Set<string> = new Set(this.favouritesSet);

        for (const [sport, isAdd] of Object.entries(this.optimistic)) {
            if (isAdd) {
                out.add(sport);
            } else {
                out.delete(sport);
            }
        }

        return out;
    }

    public setFavourites(sport: string, isFavourite: boolean): void {
        this.optimistic = {
            ...this.optimistic,
            [sport]: isFavourite
        };

        this.sync().catch((err) => {
            console.error(err);
        });
    }

    private async sync(): Promise<void> {
        if (this.isSync === true) {
            return;
        }

        if (isEqual(this.favouritesSet, this.favouritesWithOptimistic)) {
            return;
        }

        this.isSync = true;

        try {
            const sports = Array.from(this.favouritesWithOptimistic);

            await apiCommon.postUserFavouritesSport.run({
                sport: sports
            });

            await this.favourites.refresh();

            this.isSync = false;
        } catch (err) {
            console.error(err);

            this.isSync = false;
            return;
        }

        await this.sync();
    }

    @computed public get favouritesListView(): Array<SportLinkType> {
        const favouritesWithOptimistic = this.favouritesWithOptimistic;
        return this.sportsList.sportsLinks.filter((item) => favouritesWithOptimistic.has(item.to.id));
    }

    @computed public get restListView(): Array<SportLinkType> {
        const favouritesWithOptimistic = this.favouritesWithOptimistic;
        return this.sportsList.sportsLinks.filter((item) => favouritesWithOptimistic.has(item.to.id) === false);
    }
}

