import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const validateConfirmEmail = (newEmail: FormInputState<string,string>) => (value: string): Result<string> => {
    if (newEmail.value.trim().toLowerCase() !== value) {

        return Result.createError(getErrorByCode('ERROR_CONFIRM_EMAIL'));
    }
    return Result.createOk(value);
};
