import * as React from 'react';

import { ScoreGroup, ScoreHome, ScoreAway } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/Score6Pack.style';

import { observer } from 'src/utils/mobx-react';

import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';



interface Score6PackPropsType {

    smallEvent: EventListGroupEventItemType;

}



export const Score6Pack = observer('Score6Pack', (props: Score6PackPropsType) => {

    const { smallEvent } = props;

    const eventModel = smallEvent.id2.getEventModel();



    if (eventModel === null) {

        return null;

    }



    const scoreHome = eventModel.timeSettingsStarted ? eventModel.scoreHome : null;

    const scoreAway = eventModel.timeSettingsStarted ? eventModel.scoreAway : null;



    if (scoreHome !== null && scoreAway !== null) {

        return (

            <ScoreGroup>

                <ScoreHome>{scoreHome}</ScoreHome>

                <ScoreAway>{scoreAway}</ScoreAway>

            </ScoreGroup>

        );

    }



    return null;

});

