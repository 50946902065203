import { computed, makeObservable } from 'mobx';
import {
    FiltersStateStrategy,
    FilterType
} from 'src/domains/layouts/webview/components/filters/Filters.state';

interface Props {
    marketFiltersForView: Array<{ id: string; key: string; label: string }>
    activeMarketFilter: string
    setActiveFilter: (filter: string) => void
}

export class AlternativeMarketFiltersState implements FiltersStateStrategy {

    public constructor(
        private readonly props: Props,
    ) {
        makeObservable(this);
    }

    @computed public get filters(): FilterType[] {
        return this.props.marketFiltersForView;
    }


    public getActiveFilterId(): string | number | null {
        return this.props.activeMarketFilter
            ?? this.filters[0]?.id
            ?? null;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (id !== null && typeof id !== 'number') {
            this.props.setActiveFilter(id);
        } else {
            console.error('Selected filter can not be `null` or `number`');
        }
    }

}
