import styled from '@emotion/styled'; 
import { RewardsIcon } from 'src/domains/layouts/shared/assets/icons/RewardsIcon';
import { withConfig } from 'src/withConfig';

interface RewardsButtonPropsType {
    isOpen?: boolean;
}

export const RewardsButton = withConfig(theme => styled('button', { label: 'RewardsButton' })<RewardsButtonPropsType>`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${theme.star.quickHeaderMain.txtColor};
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    margin: 0;
    min-width: 48px;
    padding-top: 5px;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.above};

    &::after, &::before {
        ${({ isOpen }): string => isOpen === true ? 'content: ""' : 'content: none'};
        border-style: solid;
        bottom: -9px;
        height: 0;
        position: absolute;
        width: 0;
    }

    &::after {
        border-width: 0 10px 8px 10px;
        border-color: transparent transparent ${theme.star.lifeSpan.borderColor} transparent;
    }

    &::before {
        border-width: 0 8px 6px 8px;
        border-color: transparent transparent ${theme.star.lifeSpan.bgColor} transparent;
        z-index: ${theme.star.zIndexGlobal.above};
    }
`);

export const RewardsIconWrapper = styled(RewardsIcon, { label: 'RewardsIconWrapper' })`
    fill: currentcolor;
`;
