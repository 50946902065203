import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { GameStatus } from 'src/domains/casino/webview/CasinoGames/game-modal/modal-body/modal-success/game-content/game-status/GameStatus';
import { ModalError } from 'src/domains/casino/webview/CasinoGames/game-modal/modal-body/modal-error/ModalError';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';
import {
    GameViewDesktopContainer,
    GameViewMobileContainer,
    PreviewHeader,
    ClosePreviewIcon,
    ExpandIconWrapper,
    GamePreviewTitle,
    LaunchMiniGameBody,
    LaunchMiniGameBodyMobile,
    LaunchMiniGameBodyIframe,
} from './MiniGameModal.style';

interface MiniGameModalType {
    appCasinoState: AppCasinoState;
    gameDataState: GameDataState;
}

export const MiniGameModal = observer('MiniGameModal', (props: MiniGameModalType) => {
    // TODO - move this to the main modal if is fullscreen
    const { appCasinoState, gameDataState } = props;
    const { breakpointsState } = appCasinoState;

    const { closeGame, toggleFullScreen } = appCasinoState.gameModalState;
    const desktopVersion = breakpointsState.desktop.isBiggerOrEq;

    if (gameDataState.session.status !== 'ready') {
        return null;
    }

    const sessionData = gameDataState.session.value;

    if (sessionData.status !== 200) {
        return (
            <ModalError
                appCasinoState={appCasinoState}
                message={sessionData.payload}
            />
        );
    }

    if (desktopVersion === true) {
        return (
            <GameViewDesktopContainer>
                <PreviewHeader>
                    <GamePreviewTitle>{gameDataState.name}</GamePreviewTitle>
                    <ClosePreviewIcon onClick={closeGame} />
                    <ExpandIconWrapper onClick={toggleFullScreen} />
                </PreviewHeader>
                <LaunchMiniGameBody>
                    <LaunchMiniGameBodyIframe
                        src={sessionData.payload}
                        data-test='game-iframe-mini'
                    />
                </LaunchMiniGameBody>
                <GameStatus
                    appCasinoState={appCasinoState}
                    gameDataState={gameDataState}
                />
            </GameViewDesktopContainer>
        );
    }

    return (
        <GameViewMobileContainer>
            <PreviewHeader>
                <GamePreviewTitle>{gameDataState.name}</GamePreviewTitle>
                <ClosePreviewIcon onClick={closeGame} />
                <ExpandIconWrapper onClick={toggleFullScreen} />
            </PreviewHeader>
            <LaunchMiniGameBodyMobile>
                <LaunchMiniGameBodyIframe
                    src={sessionData.payload}
                    data-test='game-iframe-mini'
                />
            </LaunchMiniGameBodyMobile>
            <GameStatus
                appCasinoState={appCasinoState}
                gameDataState={gameDataState}
            />
        </GameViewMobileContainer>
    );
});
