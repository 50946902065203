import React, { PropsWithChildren, useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersWrapper, GradientOverlay } from './Filters.style';
import { ScrollWrapper, ScrollLeftButton, ScrollRightButton, ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';
import { ScrollState } from 'src/domains/sportsbook/shared/Types';
import { useAppStateContext } from 'src/appState/AppState';

interface Props extends PropsWithChildren<unknown> {
    casinoGradientOverlay?: boolean;
    scrollState?: ScrollState;
    customHeight?: number;
}

export const FiltersScrollableWrapper = observer<Props>('FiltersScrollableWrapper', (props) => {
    const { children = false, casinoGradientOverlay = false, customHeight } = props;
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const [ scrollState ] = useState(() => new ScrollState());

    const isTabletAtLeast = breakpointsState.tablet.isBiggerOrEq;
    const isLeftButtonVisible = isTabletAtLeast === true && scrollState.scrollLeftArrow === true;
    const isRightButtonVisible = isTabletAtLeast === true && scrollState.scrollRightArrow === true;

    return (
        <FiltersWrapper data-test='filters'>
            {
                isLeftButtonVisible === true
                    ? <>
                        {casinoGradientOverlay === true && <GradientOverlay />}

                        <ScrollLeftButton
                            onClick={scrollState.goToLeft}
                        >
                            <ButtonChevronIcon position='left' />
                        </ScrollLeftButton>
                    </> : null
            }

            <ScrollWrapper customHeight={customHeight} ref={scrollState.setRef}>
                { children }
            </ScrollWrapper>

            {
                isRightButtonVisible === true
                    ? (
                        <ScrollRightButton
                            onClick={scrollState.goToRight}
                        >
                            <ButtonChevronIcon position='right' />
                        </ScrollRightButton>
                    ) : null
            }
        </FiltersWrapper>
    );

});
