import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { PopupOverlay } from 'src/domains/layouts/webview/components/popups/PopupContainer.style';
import { withConfig } from 'src/withConfig';

export const RealityCheckPopupOverlay = styled(PopupOverlay, { label: 'RealityCheckPopupOverlay' })``;

export const RealityCheckPopupWrapper = withConfig(theme => styled('div', { label: 'RealityCheckPopupWrapper' })`
    background: ${theme.star.popup.bgColor};
    color: ${theme.star.popup.txtColor};
    padding: 24px;
    width: 288px;
    text-align: center;
`);

export const PopupHeader = withConfig(theme => styled('h2', { label: 'PopupHeader' })`
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.5;
    margin: 0;
`);

export const PopupContent = withConfig(theme => styled('article', { label: 'PopupContent' })`
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.5;
    margin: 0;
`);

export const TimerDescription = withConfig(theme => styled('p', { label: 'TimerDescription' })`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4286;
    margin: 4px 0 16px;
`);

export const Description = withConfig(theme => styled('p', { label: 'Description' })`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 16px 0;
`);

export const ButtonWrapper = styled(Button, { label: 'ButtonWrapper' })`
    display: block;
    width: 100%;
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
`;

export const FakeLink = withConfig(theme => styled('span', { label: 'FakeLink' })`
    color: ${theme.star.popup.txtColorQuaternary};
    cursor: pointer;
    display: inline-block;
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
`);
