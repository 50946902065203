import * as React from 'react';
import { TabIdType, TabItemType } from './MainViewTypes';
import { MainViewTab } from './MainViewTab';
import { observer } from 'src/utils/mobx-react';
import { AppState } from 'src/appState/AppState';
import { MainViewMain, MainViewTranslations, FeaturesFlagTab, MainViewCasino, CurrentEvent, TagStatistics } from './Tabs';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { TabWrapper } from './DebugPanel.style';

const Tabs: Array<TabItemType> = [{
    id: 'main',
    name: 'Main',
    body: (appState: AppState): React.ReactElement => <MainViewMain appState={appState} />,
}, {
    id: 'translations',
    name: 'Translations',
    body: (appState: AppState): React.ReactElement => <MainViewTranslations appState={appState} />,
},{
    id: 'config',
    name: 'Website Config',
    body: (appState: AppState): React.ReactElement => <FeaturesFlagTab appState={appState} />
},{
    id: 'casino',
    name: 'Casino',
    body: (appState: AppState): React.ReactElement => <MainViewCasino appState={appState} />,
},{
    id: 'event',
    name: 'Current Event',
    body: (appState: AppState, currentView: RouteViewType | undefined): React.ReactElement => <CurrentEvent appState={appState} currentView={currentView} />,
}, {
    id: 'tags',
    name: 'Tag statistics',
    body: (appState: AppState): React.ReactElement => <TagStatistics appState={appState} />,
}];

const getTabBody = (id: TabIdType | null, appState: AppState, currentView: RouteViewType): React.ReactElement => {
    for (const item of Tabs) {
        if (item.id === id) {
            return item.body(appState, currentView);
        }
    }

    return <MainViewTranslations appState={appState} />;
};

interface PropsType {
    appState: AppState;
    currentView: RouteViewType;
}

export const MainView = observer('MainView', ({ appState, currentView }: PropsType) => {
    const activeTab = appState.appLayoutsState.debugPanel?.activeTab;
    const body = getTabBody(activeTab, appState, currentView);

    const out: Array<React.ReactNode> = [];

    for (const tabItem of Tabs) {
        out.push(
            <MainViewTab
                key={tabItem.id}
                tabId={tabItem.id}
                name={tabItem.name}
                appState={appState}
            />
        );
    }

    return (
        <>
            <TabWrapper>
                {out}
            </TabWrapper>
            {body}
        </>
    );
});
