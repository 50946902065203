import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventsUpcoming } from 'src/domains/sportsbook/webview/components/containers/eventsUpcoming/EventsUpcoming';
import { InPlay } from 'src/domains/sportsbook/webview/components/containers/eventsInPlay/EventsInPlay';
import { observer } from 'src/utils/mobx-react';

interface PropsTypes {
    showPriceLegend: boolean,
    headerStyleLevel: 'sportLevel' | 'competitionLevel'
}
export const EventsInPlayOrUpcoming = observer('EventsInPlayOrUpcoming', ({ showPriceLegend, headerStyleLevel }: PropsTypes) => {
    const { appSportsBookState, config } = useAppStateContext();
    const isLoading = appSportsBookState.eventsCollection.listInPlay.isLoading;

    if (isLoading){
        return <InPlay showPriceLegend={showPriceLegend} isLoading={true} headerStyleLevel={headerStyleLevel}/>;
    }
    else if (appSportsBookState.eventsCollection.listInPlay.isEmpty === true) {
        if (config.startingSoonEvents === false) {
            return <EventsUpcoming showPriceLegend={showPriceLegend} isLoading={false} headerStyleLevel={headerStyleLevel}/>;
        }
    }
    return <InPlay showPriceLegend={showPriceLegend} isLoading={false} headerStyleLevel={headerStyleLevel}/>;
});
