import React from 'react';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsType {
    to?: RouteViewType | RightHandSideViewType | string;
    children?: React.ReactNode;
    className?: string;
    size?: 'large' | 'medium' | 'small' | 'xs';
    isButton?: boolean;
    onClickCallback?: () => void;
    onMouseEnter?: (e: React.SyntheticEvent) => void;
    dataTest?: string;
    isActiveLifespan?: boolean;
    routeNameForGtm: string | null;
}

export const AccountNavigationLink = observer('AccountNavigationLink', (props: PropsType) => {
    const {
        appLayoutsState: { callbacks },
    } = useAppStateContext();

    const handleOnClickWithGTM = (): void => {
        if (props.onClickCallback !== undefined) {
            props.onClickCallback();
        }

        if (props.routeNameForGtm === null) {
            return;
        }

        callbacks.onRoutingAccountChange(props.routeNameForGtm);
    };

    return (
        <Link
            className={props.className}
            dataTest={props.dataTest}
            isButton={props.isButton}
            onClick={handleOnClickWithGTM}
            onMouseEnter={props.onMouseEnter}
            size={props.size}
            to={props.to}
        >
            {props.children}
        </Link>
    );
});
