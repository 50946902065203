import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

interface GameplayDetailsWrapperPropsType {
    largeVersion: boolean;
}

export const GameplayDetailsWrapper = withConfig(theme => styled('div', { label: 'GameplayDetailsWrapper' })<GameplayDetailsWrapperPropsType>`
    backdrop-filter: blur(2px);
    background-color: ${theme.star.casino.liveCasino.bgColorSecondary};
    display: flex;
    flex-direction: column;
    height: ${({ largeVersion }): string => (largeVersion ? '60px' : '40px')};
    justify-content: center;
`);

interface GameplayDetailsLabelLinePropsType {
    bold?: boolean;
    largeVersion: boolean;
    center?: boolean;
}

const calculateLineFontSize = (theme: EmotionTheme, largeVersion: boolean, bold?: boolean): SerializedStyles => {
    if (largeVersion) {
        return css`
            font-size: ${theme.star.fontSize.xRegular};
            line-height: 1.43;
        `;
    }

    if (bold === true) {
        return css`
            font-size: ${theme.star.fontSize.small};
            line-height: 1.3334;
        `;
    }

    return css`
        font-size: ${theme.star.fontSize.xSmall};
        line-height: 1.4;
    `;
};

const calculateLineFontWeight = (theme: EmotionTheme, bold?: boolean): SerializedStyles => {
    if (bold === true) {
        return css`
            font-weight: ${theme.star.fontWeight.bold};
        `;
    }

    return css`
        font-weight: ${theme.star.fontWeight.medium};
    `;
};

export const GameplayDetailsLabelLine = withConfig(theme => styled('div', { label: 'GameplayDetailsLabelLine' })<GameplayDetailsLabelLinePropsType>`
    ${({ largeVersion, bold }): SerializedStyles => calculateLineFontSize(theme, largeVersion, bold)}
    ${({ bold }): SerializedStyles => calculateLineFontWeight(theme, bold)}
    padding: 0 8px;
    text-align: left;
    color: ${theme.star.casino.txtColor};
    ${({ center }): string => {
        if (center === true) {
            return `
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            `;
        }

        return '';
    }}
`);

export const ShortDealerName = styled('span', { label: 'ShortDealerName' })`
    width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
