import React from 'react';

import { InPlay as EventsInPlay } from 'src/domains/sportsbook/webview/components/containers/eventsInPlay/EventsInPlay';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

import { Sport } from './InPlayPage.style';
import { EventsUpcoming } from 'src/domains/sportsbook/webview/components/containers/eventsUpcoming/EventsUpcoming';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { InPlayRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { PromotedEvents } from 'src/domains/sportsbook/webview/components/containers/promotedEvents/PromotedEvents';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

//TODO: I have not removed it can be useful
//const NoEvents = observer(() => (
//    <div className="no-events">
//        <header className="no-events__header">
//            <img src={INPLAY_NO_EVENTS} className="header__icon"/>
//            <h1 className="header__title">
//                <I18n langKey="layouts.in-play.empty.header" defaultText="SORRY!" />
//            </h1>
//            <div className="header__text">
//                <p>
//                    <I18n langKey="layouts.in-play.empty.description.line-1" defaultText="There are no in-play events happening right now. Please check back later." />
//                </p>
//                <p>
//                    <I18n langKey="layouts.in-play.empty.description.line-2" defaultText="In the meantime check out up and coming events below!" />
//                </p>
//            </div>
//        </header>
//        <EventsOnLater />
//    </div>
//));

interface EventsInPlayContentPropsTypes {
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
}

const EventsInPlayContent = observer('EventsInPlayContent', ({ headerStyleLevel }: EventsInPlayContentPropsTypes) => {
    const { appSportsBookState: { eventsCollection }, appLayoutsState: { promotedEventsState } } = useAppStateContext();
    const inPlayCollection = eventsCollection.listInPlay;
    const inPlayIsLoading = inPlayCollection.isLoading;
    const noEvents = (inPlayIsLoading === false) && inPlayCollection.total === 0;

    if (noEvents) {
        return (
            <>
                <EventsUpcoming showPriceLegend={true} isLoading={false} headerStyleLevel={headerStyleLevel} />
                <PromotedEvents promotedEventsState={promotedEventsState} />
            </>
        );
    };


    return <EventsInPlay showPriceLegend={true} isLoading={inPlayIsLoading} headerStyleLevel={headerStyleLevel} />;
});

interface PropsTypes {
    currentView: InPlayRouteType;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isSearchActive: boolean;
    accountView: RightHandSideViewType | null;
}

export const InPlayLayout = observer('InPlayLayout', ({ currentView, accountView, headerStyleLevel, isSearchActive }: PropsTypes) => {
    return (
        <Sport>
            <LayoutSkeleton currentView={currentView} accountView={accountView} isSearchActive={isSearchActive} navigationType='global' >
                <div className='in-play-page'>
                    <EventsInPlayContent headerStyleLevel={headerStyleLevel}/>
                </div>
            </LayoutSkeleton>
        </Sport>
    );
});
