import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface FullScreenOutIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const FullScreenOutIcon = observer('FullScreenOutIcon', ({ className, onClick }: FullScreenOutIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M7.857 13.357l-4.03 4.168L1 14.185V23h8.782l-3.341-2.861 4.166-4.032-2.75-2.75zM14.217 1l3.342 2.861-4.166 4.032 2.75 2.75 4.03-4.168L23 9.815V1h-8.782z'
                fill='fill'
                fillRule='evenodd'
            />
        </SvgElement>
    );
});
