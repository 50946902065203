import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, makeObservable } from 'mobx';
import { TabIdType } from './MainViewTypes';
import { AppState } from 'src/appState/AppState';
import { TabLabel } from './DebugPanel.style';

class MainViewTabState {
    public constructor(public readonly appState: AppState, public readonly tabId: TabIdType) {
        makeObservable(this);
    }

    @action public changeTab = (): void => {
        this.appState.appLayoutsState.debugPanel.changeTab(this.tabId);
    };
}

interface MainViewTabPropsType {
    tabId: TabIdType;
    name: string;
    appState: AppState;
}

export const MainViewTab = observer('MainViewTab', (props: MainViewTabPropsType) => {
    const { tabId, name, appState } = props;
    const [ state ] = React.useState(() => new MainViewTabState(appState, tabId));
    return (
        <TabLabel
            active={appState.appLayoutsState.debugPanel.activeTab === tabId}
            onClick={state.changeTab}
        >
            { name }
        </TabLabel>
    );
});
