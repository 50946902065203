import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import styled from '@emotion/styled';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import { withConfig } from 'src/withConfig';

export const Wrapper = withConfig(theme => styled('div', { label: 'Wrapper' })`
    background-color: ${theme.star.layoutGlobal.bgColor};
`);

export const SectionWrapper = styled('div', { label: 'SectionWrapper' })`
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0%;
`;

export const PaddingWrapper = withConfig(theme => styled('div', { label: 'PaddingWrapper' })`
    padding: 16px;
    @media ${theme.star.mediaQuery.tablet} {
        padding: 0; 
    }
`);

export const PremierLeagueIframeWrapper = withConfig(theme => styled('div', { label: 'PremierLeagueIframeWrapper' })`
    overflow: hidden;
    padding-top: 270%;
    position: relative;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        padding-top: 130%;  
    }
    @media ${theme.star.mediaQuery.desktop} {
        padding-top: 100%;  
    }
`);

export const PremierLeagueIframe = styled(Iframe, { label: 'PremierLeagueIframe' })`
    ${positionElementFull};
    border: 0;
    height: 100%;
    position: absolute;
    width: 100%;
`;
