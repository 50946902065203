import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { SingleBetItemState } from 'src/domains/sportsbook/betting/betSlipState/betsState/SingleBetItemState';
import { BetsFilter } from 'src/domains/sportsbook/betting/state/BetsState';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsTypes {
    betItem: SingleBetItemState,
}

export const SettledCashOut = observer('SettledCashOut', ({ betItem }: PropsTypes) => {
    const { appSportsBookState: { betsState } } = useAppStateContext();
    const { selectedFilter } = betsState;

    if (selectedFilter === BetsFilter.SETTLED && betItem.cashOut) {
        return (
            <div>
                <I18n langKey='bets.selection.cashed-out-already.label' defaultText='Cashed out' />
            </div>
        );
    }

    return null;
});

