import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const MainFooter = withConfig(theme => styled('footer', { label: 'MainFooter' })`
    background-color: ${theme.star.footerMain.bgColor};
    margin-top: auto;
    overflow: hidden;
    padding: 40px 16px 92px;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        padding: 40px;
    }
`);

export const MainWrapper = withConfig(theme => styled('div', { label: 'MainWrapper' })`
    margin: 0 auto;
    max-width: 784px;
    @media ${theme.star.mediaQuery.tablet} {
        margin: 0;
    }
`);

export const MainContentWrapper = withConfig(theme => styled('div', { label: 'MainContentWrapper' })`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media ${theme.star.mediaQuery.tablet} {
        grid-template-columns: repeat(4, auto);
        justify-content: space-between;
    }
`);

export const FooterColumnMobile = withConfig(theme => styled('div', { label: 'FooterColumnMobile' })`
    @media ${theme.star.mediaQuery.tablet} {
        display: contents;
    }
`);

export const FooterNavigation = withConfig(theme => styled('section', { label: 'FooterNavigation' })`
    margin-bottom: 24px;
    padding-right: 6px;
    &:last-of-type {
        padding-right: 0;
    }
    @media ${theme.star.mediaQuery.tablet} {
        justify-self: start;
        padding-right: 0;
        &:nth-of-type(1),
        &:nth-of-type(3) {
            order: -1;
        }
    }
`);

export const FooterHeader = withConfig(theme => styled('h3', { label: 'FooterHeader' })`
    color: ${theme.star.footerMain.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
    margin: 0 0 16px;
`);
