import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { HorseInfoWrapper, RaceBoxWrapper } from 'src/domains/sportsbook/webview/components/raceBoxSelection/RaceBoxSelectionLoader.style';
import { EventsLoader, FirstLineRacingLoader, SecondLineRacingLoader, SelectionLoader, SilkLoader } from 'src/domains/layouts/shared/loaders';

export const RaceBoxSelectionLoader = observer('RaceBoxSelectionLoader', () => {
    return (
        <RaceBoxWrapper>
            <HorseInfoWrapper>
                <SilkLoader />

                <EventsLoader>
                    <FirstLineRacingLoader />
                    <SecondLineRacingLoader />
                </EventsLoader>
            </HorseInfoWrapper>

            <SelectionLoader />
        </RaceBoxWrapper>
    );
});
