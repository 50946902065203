import { BoostIcon } from 'src/domains/layouts/shared/assets/icons/BoostIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

interface PropsTypes {
    betHistory: boolean | undefined;
}

const renderWrapperStyle = (theme: EmotionTheme, betHistory: boolean | undefined): SerializedStyles => {
    if (betHistory === true) {
        return css`
            padding: 10px 12px;
            /* config should be form lifeSpanMyBetsBoostedMessage */
            background-color: ${theme.star.betHistory.bgColor};
            color: ${theme.star.betHistory.txtColorQuinary};
        `;
    }
    return css`
        padding: 4px;
        background-color: ${theme.star.lifeSpanMyBetsBoostedMessage.bgColor};
        color: ${theme.star.lifeSpanMyBetsBoostedMessage.txtColor};
    `;
};

export const SelectionBoostedMessageWrapper = withConfig(theme => styled('div', { label: 'SelectionBoostedMessageWrapper' })<PropsTypes>`
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: ${theme.star.fontSize.small};
    ${({ betHistory }): SerializedStyles => renderWrapperStyle(theme, betHistory)};
`);

export const BoostWrapperIcon = withConfig(theme => styled(BoostIcon, { label: 'BoostWrapperIcon' })<PropsTypes>`
    margin-right: 8px;
    color: ${({ betHistory }): string => betHistory === true ? 'inherit' : theme.star.lifeSpanMyBetsBoostedMessage.bgColorSecondary};
    fill: currentcolor;
`);

export const SelectionBoostedValue = withConfig(theme => styled('span', { label: 'SelectionBoostedValue' })<PropsTypes>`
    font-weight: ${({ betHistory }): string => betHistory === true ? theme.star.fontWeight.regular : theme.star.fontWeight.bold};
    margin-left: 4px;
`);
