import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAsObservableSource } from 'mobx-react-lite';
import { Switch } from 'src/domains/layouts/webview/components/Switch';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Select } from 'src/domains/players/webview/components/form/select/Select';
import { RealityCheckState } from './RealityCheck.state';
import { SelectWrapper } from './RealityCheck.style';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { PopupInfo, SignupPopupState } from 'src/domains/players/state/SignupPopupState';
import {
    SectionWrapper,
    Description,
    HeadlineWrapper,
    HeadlineIconWrapper,
    SectionHeadline,
    InfoIconWrapper
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/ResponsibleGambling.style';
import { useAppStateContext } from 'src/appState/AppState';

interface RealityCheckFormPropsType {
    state: RealityCheckState;
}

class RealityCheckPopupState {
    public constructor(private readonly signupPopupState: SignupPopupState, public readonly language: LanguagesState) { }

    public renderRealityCheckPopup = (): void => {
        const popUpTitle = this.language.getTranslation(
            'signup-journey.popups.realitycheck-popup-title',
            'Reality check'
        );
        const popUpText = this.language.getTranslation(
            'signup-journey.popups.realitycheck-popup-text',
            'A reality check is a notification that interrupts your session to discretely remind you how long you’ve been playing for.'
        );
        const popup = new PopupInfo(popUpTitle, false, popUpText);
        this.signupPopupState.show(popup);
    };
}

export const RealityCheck = observer('RealityCheck', (propsIn: RealityCheckFormPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { state } = props;
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { signupPopupState } = appPlayersState;
    const [popupState] = React.useState(() => new RealityCheckPopupState(signupPopupState, languagesState));
    const placeholder = languagesState.getTranslation('signup-journey.reality-check.duration.placeholder', 'Choose a frequency');

    return (
        <SectionWrapper data-test='reality-check'>
            <HeadlineWrapper>
                <HeadlineIconWrapper>
                    <SectionHeadline data-test='section-headline'>
                        <I18n
                            langKey='signup-journey.reality-check.title'
                            defaultText='Turn on reality check'
                        />
                    </SectionHeadline>
                    <InfoIconWrapper onClick={popupState.renderRealityCheckPopup} />
                </HeadlineIconWrapper>
                <Switch switched={state.isSwitched} onChange={state.onSwitch} version='secondary' />
            </HeadlineWrapper>
            <Description data-test='description'>
                <I18n
                    langKey='signup-journey.reality-check.description'
                    defaultText='Set a time limit for how long you are active.'
                />
            </Description>
            {
                state.isSwitched ?
                    <SelectWrapper>
                        <Select
                            labelText={<I18n
                                langKey='signup-journey.reality-check.duration.label'
                                defaultText='Time period'
                            />}
                            placeholder={placeholder}
                            options={state.realityCheckOptions}
                            state={state.selectedDuration}
                            isDisabled={false}
                            onChange={state.onChange}
                            dataTest='sign-up-select-reality-check'
                        />
                    </SelectWrapper>
                    : null
            }
        </SectionWrapper>
    );
});
