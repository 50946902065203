import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { CarouselEventNavigation } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigation';
import { useAppStateContext } from 'src/appState/AppState';
import { BackButton, BackButtonWrapper, EventNavigation } from 'src/domains/layouts/webview/components/eventNavigation/CarouselSelection.style';
import { EventRouteType, RacecardMeetingsRouteType, RacecardRaceRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';

const getId = (models: ModelsState, currentView: EventRouteType | RacecardMeetingsRouteType | RacecardRaceRouteType): EventId | null => {
    if (currentView.name === 'event') {
        return models.id.getEventId(currentView.id);
    }
    if (currentView.name === 'racecard') {
        return models.id.getEventIdOption(currentView.selected);
    }
    return null;
};

interface AlternativeNavigationPropsType {
    currentView: EventRouteType | RacecardMeetingsRouteType | RacecardRaceRouteType;
}

export const AlternativeNavigation = observer('AlternativeEvent', (props: AlternativeNavigationPropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const { alternativeEventState, models } = appSportsBookState;

    const id = getId(models, props.currentView);
    const { eventId } = alternativeEventState.get(id);

    if (eventId === null || id === null) {
        return null;
    }

    const actualEvent = id.getEventModel();


    const eventModel = eventId.getEventModel();

    if (eventModel === null) {
        return null;
    }

    const { sport } = eventModel;

    if (sport === 'greyhoundracing' || sport === 'horseracing' || props.currentView.name === 'racecard') {
        return null;
    }

    const handleRedirectToCompetitionPage = (): void => {
        if (actualEvent !== null) {
            starRouter.redirectToCompetitionPage(actualEvent.competition, actualEvent.sport);
        }
    };

    return (
        <EventNavigation>
            <BackButtonWrapper onClick={handleRedirectToCompetitionPage}>
                <BackButton position='left' />
            </BackButtonWrapper>
            <CarouselEventNavigation currentView={props.currentView} />
        </EventNavigation>
    );
});
