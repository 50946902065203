import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StakeInputMobx } from 'src/domains/sportsbook/betting/ui/betSlip/stakeInputMobx/StakeInputMobx';
import { Checkbox } from 'src/domains/sportsbook/betting/ui/checkbox/CheckBox';
import { InfoMessageDeprecated } from 'src/domains/layouts/webview/components/Messages';
import { Amount } from 'src_common/common/amount/Amount';
import { BetslipCombinationId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import {
    EachWayBoxLabel,
    TitleWrapper,
    BetSlipError,
    BetSlipSelectionWrapper,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection.styles';
import {
    ReturnWrapper,
    CombinationName,
    InputWrapper,
    ReturnValue,
    EachWayBox,
    ReturnsWrapper,
} from './BetslipCombination.style';

interface PropsType {
    selectionId: BetslipCombinationId;
    isHidden?: boolean;
}

export const BetslipCombination = observer('BetslipCombination', (props: PropsType) => {
    const { appLayoutsState, appSportsBookState, sdkCustomer } = useAppStateContext();
    const { selectionId, isHidden } = props;
    const { legsState, betSlipSummaryState, referralState, betslipData } = appSportsBookState.betSlipState;
    const combinationId = selectionId;
    const combinationElem = combinationId.getModel();

    if (combinationElem === null) {
        return null;
    }

    const { combination, eachWay, errors, ewOffered, handleChangeEachWay, name, numLines, returns, stakePerLine } =
        combinationElem;

    const potentialReturns = (): React.ReactElement | null => {
        const { isAnyLegSP } = legsState;
        const { betTooHighValue } = betSlipSummaryState;
        const isStake = stakePerLine !== null && stakePerLine.isGreaterThanZero();

        return (
            <ReturnsWrapper>
                <ReturnWrapper data-test='returns'>
                    <I18n
                        langKey='betslip.star-return'
                        defaultText='Returns:'
                    />
                    <ReturnValue>
                        {combination !== null &&
                        combination.totalStake !== null &&
                        new Amount(combination.totalStake).isEqualWith(new Amount('0')) &&
                        !isAnyLegSP
                            ? sdkCustomer.money(new Amount('0'))
                            : isAnyLegSP || (combination !== null && combinationId.isCastCombination())
                              ? sdkCustomer.money(null)
                              : sdkCustomer.money(returns)}{' '}
                    </ReturnValue>
                </ReturnWrapper>

                {betTooHighValue !== null && isStake ? (
                    <ReturnWrapper>
                        <I18n
                            langKey='betslip.star-max-bet'
                            defaultText='Max. bet:'
                        />
                        <ReturnValue>{sdkCustomer.money(betTooHighValue)}</ReturnValue>
                    </ReturnWrapper>
                ) : null}
            </ReturnsWrapper>
        );
    };

    const labelWrapper = (
        <EachWayBoxLabel>
            <I18n
                langKey='betslip.combination.each-way.label'
                defaultText='E/W'
            />
        </EachWayBoxLabel>
    );

    const { translationsStore } = appLayoutsState;

    return (
        <BetSlipSelectionWrapper
            ref={betslipData.setRef(selectionId)}
            isHidden={isHidden}
            data-test-combinations-selection-name={name}
        >
            <TitleWrapper>
                <CombinationName data-test='combination-name'>
                    <>
                        {translationsStore.getBetslipTranslation(name)} {numLines === null ? null : ` x ${numLines}`}
                    </>
                </CombinationName>
            </TitleWrapper>

            <InputWrapper>
                <StakeInputMobx selectionId={combinationId} />
                {((ewOffered !== null && ewOffered) || (eachWay !== null && eachWay)) &&
                !combinationId.isCastCombination() ? (
                    <EachWayBox data-test='ew-checkbox'>
                        <Checkbox
                            label={labelWrapper}
                            isChecked={eachWay !== null && eachWay}
                            isDisabled={referralState.isReferred}
                            handleOnChange={handleChangeEachWay}
                        />
                    </EachWayBox>
                ) : null}
            </InputWrapper>
            {potentialReturns()}
            {errors.length > 0 ? (
                <BetSlipError>
                    {errors.map((error): JSX.Element | null => {
                        if (error.code === 'singles-only') {
                            return null;
                        }
                        return (
                            <InfoMessageDeprecated
                                message={error}
                                hasIcon={false}
                                key={`${error.code}-${error.resource}`}
                            />
                        );
                    })}
                </BetSlipError>
            ) : null}
        </BetSlipSelectionWrapper>
    );
});
