import * as React from 'react';
import { ChangeAddressProcedureNewForm } from 'src/domains/players/webview/components/Account/personalDetailsTab/changeAddressProcedure/ChangeAddressProcedureNewForm';
import { useAppStateContext } from 'src/appState/AppState';
import { ChangePasswordProcedure } from 'src/domains/players/webview/components/Account/personalDetailsTab/changePasswordProcedure/ChangePasswordProcedure';
import { observer } from 'src/utils/mobx-react';
import { ChangeEmailProcedure } from 'src/domains/players/webview/components/Account/personalDetailsTab/changeEmailProcedure/ChangeEmailProcedure';
import { ChangePhoneProcedure } from 'src/domains/players/webview/components/Account/personalDetailsTab/changePhoneProcedure/ChangePhoneProcedure';
import { PersonalDetailsTabWrapper } from 'src/domains/players/webview/components/Account/personalDetailsTab/PersonalDetailsTab.styles';

export const PersonalDetailsTab = observer('PersonalDetailsTab', () => {
    const { appPlayersState, config } = useAppStateContext();
    const { account } = appPlayersState.accountState;

    if (account === null) {
        return null;
    }

    const basicUserData = account.basicDataReady;

    const { hasUserAddressChangeEnabled, hasUserContactNumberChangeEnabled, hasUserEmailChangeEnabled } = config;

    const changeEmailProcedure = hasUserEmailChangeEnabled ? <ChangeEmailProcedure /> : null;

    const changeContactNumberProcedure =
        hasUserContactNumberChangeEnabled && basicUserData !== null ? (
            <ChangePhoneProcedure
                account={account}
                initialPhoneNumberData={basicUserData.mobilePhone ?? null}
            />
        ) : null;

    const changeAddressProcedure =
        hasUserAddressChangeEnabled && basicUserData !== null ? (
            <ChangeAddressProcedureNewForm initialData={basicUserData} />
        ) : null;

    return (
        <PersonalDetailsTabWrapper>
            {changeEmailProcedure}
            {changeContactNumberProcedure}
            {changeAddressProcedure}
            <ChangePasswordProcedure />
        </PersonalDetailsTabWrapper>
    );
});
