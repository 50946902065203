import styled from '@emotion/styled';
import { GridIcon } from 'src/domains/layouts/shared/assets/icons/GridIcon';
import { withConfig } from 'src/withConfig';

export const NavigationWrapper = withConfig(theme => styled('div', { label: 'NavigationWrapper' })`
    display: flex;
    padding: 12px 15px;
    flex-wrap: nowrap;
    overflow-x: auto;
    flex-shrink: 0;
    background-color: ${theme.star.casino.virtualsGamesBar.bgColorSecondary};
    @media ${theme.star.mediaQuery.tablet} {
        flex-wrap: wrap;
        overflow-x: hidden;
    }
`);

interface GameButtonPropsType {
    isActive?: boolean;
}

export const GameButton = withConfig(theme => styled('button', { label: 'GameButton' }) <GameButtonPropsType>`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    border: 1px solid ${theme.star.filtersColors.primary.borderColor};
    color: ${({ isActive }): string =>
        isActive === true ? theme.star.casino.virtualsGamesBar.txtColor : theme.star.casino.virtualsGamesBar.txtColorSecondary};
    margin: 0 4px 4px 0;
    padding: 9px 12px;
    line-height: 1;
    background-color: ${({ isActive }): string =>
        isActive === true ? theme.star.casino.virtualsGamesBar.bgColor : 'transparent'};
    cursor: pointer;
    white-space: nowrap;
    &:last-of-type {
        margin-right: 0;
    }
    &:hover,
    &:focus {
        outline: none;
        background-color: ${theme.star.casino.virtualsGamesBar.bgColor};
        color: ${theme.star.casino.virtualsGamesBar.txtColor};
    }
`);

export const AllGamesButton = withConfig(theme => styled(GameButton, { label: 'AllGamesButton' })`
    position: relative;
    padding-left: 28px;
    &:hover svg {
        fill: ${theme.star.casino.virtualsGamesBar.txtColor};
    }
`);

export const GridIconWrapper = withConfig(theme => styled(GridIcon, { label: 'GridIconWrapper' })`
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 12px;
    fill: ${theme.star.casino.virtualsGamesBar.bgColor};
`);

