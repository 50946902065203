import { action, observable, makeObservable } from 'mobx';
import { TrpcClient } from 'src/appState/TrpcClient';
import {
    GetChatDataResponseType,
    GetChatsSuccessResponseType,
    ChatMessageType,
    ChatSenderType,
} from 'src_server/trpc/types/chat';

interface SocketDataType {
    body: ChatMessageType;
}

export class TraderChatWebsocketData {
    @observable.ref public assignee: ChatSenderType | null = null;
    @observable.ref public messages: ChatMessageType[] = [];
    @observable public isTraderTyping: boolean = false;

    private traderTypingTimeout: NodeJS.Timeout | null = null;

    public constructor(private readonly trpc: TrpcClient) {
        makeObservable(this);
    }

    @action private stopTraderTyping = (): void => {
        if (this.traderTypingTimeout !== null) {
            clearTimeout(this.traderTypingTimeout);
        }
        this.traderTypingTimeout = setTimeout(() => {
            this.isTraderTyping = false;
        }, 5000);
    };

    @action public applyData = (data: SocketDataType): void => {
        const message = data.body;

        if (this.messages.some((item) => item.id === message.id)) {
            return;
        }

        const type = message.type;
        const senderType = message.sender.type;

        if (type === 'typing' && senderType === 'staff') {
            this.isTraderTyping = true;
            this.stopTraderTyping();
            return;
        } else {
            this.isTraderTyping = false;
        }

        const messages = [...this.messages, message];

        if (type === 'assigned') {
            this.assignee = data.body.sender;
            this.messages = messages;
        }

        if (type === 'unassigned' || type === 'ended') {
            this.assignee = null;
            this.messages = messages;
        }

        if (type === 'standard') {
            this.messages = messages;
        }
    };

    @action public fetchInitialData = async (): Promise<void> => {
        const chatData = await this.getChatData();
        const templates = await this.getChatTemplates();

        if (chatData?.responseStatus === 'success') {
            this.messages = [...chatData.data.messages, ...templates].reverse();

            this.setAssigneeFromChatData(chatData.data);
        } else {
            this.messages = templates.reverse();
        }
    };

    private getChatTemplates = async (): Promise<ChatMessageType[]> => {
        let messageTemplates: ChatMessageType[] = [];
        try {
            const result = await this.trpc.client.chatRouter.getChatTemplates.query();
            if (result.responseStatus === 'success') {
                const messages = result.data.templates.messageTemplates;
                messageTemplates = messages
                    .map(
                        (msg, index): ChatMessageType => ({
                            id: String(index),
                            type: 'standard',
                            content: { text: msg },
                            sender: {
                                name: 'system',
                                type: 'system',
                                id: 0,
                            },
                            sentAt: '',
                            tags: [],
                            chatId: undefined,
                        })
                    )
                    .reverse();
                return messageTemplates;
            }
        } catch (error) {
            return [];
        }
        return [];
    };

    private getChatData = async (): Promise<GetChatDataResponseType | null> => {
        return await this.trpc.client.chatRouter.getChatData.query();
    };

    private setAssigneeFromChatData = (chatData: GetChatsSuccessResponseType): void => {
        const participants = chatData.participants;
        const assignee = participants.find((p) => p.role === 'assignee');

        if (assignee !== undefined) {
            this.assignee = assignee.account;
        }
    };
}
