import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface SocialFacebookIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const SocialFacebookIcon = observer('SocialFacebookIcon', ({ className, onClick }: SocialFacebookIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M18.4.173v3.808h-2.18c-.797 0-1.334.173-1.612.519-.277.346-.416.865-.416 1.558v2.726h4.07l-.543 4.269h-3.527V24h-4.25V13.053H6.4v-4.27h3.542V5.64c0-1.788.481-3.175 1.444-4.16C12.35.492 13.632 0 15.233 0c1.361 0 2.417.058 3.167.173z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
