import { useAsObservableSource } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ActiveQuickLinkTrayType } from 'src/api/config/cms/getActiveQuickLinkTray';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { observer } from 'src/utils/mobx-react';
import { SportName, NavSportWrapper, ImageWrapper, LinkWrapper } from './QuickLinkTray.style';
import { useAppStateContext } from 'src/appState/AppState';
import { AppCasinoState } from 'src/domains/casino/shared/Types';

interface QuickLinkTrayItemType {
    link: ActiveQuickLinkTrayType;
}

class State {
    public constructor(
        private readonly props: QuickLinkTrayItemType,
        private readonly router: StarRouter,
        private readonly appCasinoState: AppCasinoState,
    ) { }

    public handleRedirect = (): void => {
        const { redirect_option, event_id, competition_id, sport, casino_game_id } = this.props.link;
        const event_id_view = event_id ?? null;
        const competition_id_view = competition_id ?? null;
        const casino_game_id_view = casino_game_id ?? null;
        const sport_view = sport ?? '';

        if (redirect_option === 'sport' && sport_view !== '') {
            this.router.redirectToSportPage(sport_view);
            return;
        }

        if (redirect_option === 'event-id' && event_id_view !== null) {
            this.router.redirectToEvent(event_id_view);
            return;
        }

        if (redirect_option === 'competition-id' && competition_id_view !== null && sport_view !== '') {
            this.router.redirectToCompetitionPage(competition_id_view, sport_view);
            return;
        }

        if (redirect_option === 'casino-game-id' && casino_game_id_view !== null) {
            this.router.redirectToCasinoPage();
            this.appCasinoState.gameStoreExt.getGameModel(casino_game_id_view)?.startGame();
        }
    };
}

export const QuickLinkTrayItem = observer('QuickLinkTrayItem', (propsInner: QuickLinkTrayItemType) => {
    const props = useAsObservableSource(propsInner);
    const { appCasinoState, appLayoutsState } = useAppStateContext();
    const { starRouter } = appLayoutsState;

    const [state] = useState(() => new State(props, starRouter, appCasinoState));
    const { link } = props;
    const { redirect_option, direct_url } = link;

    const direct_url_view = direct_url ?? '';

    const handleMatchUrl = (): void => {
        if (direct_url_view === '') return;
        starRouter.handleUrlRedirection(direct_url_view);
    };


    if (redirect_option === 'direct-link') {
        if (direct_url_view !== '') {
            return (
                <LinkWrapper onClick={handleMatchUrl} data-test='quick-link-tray-item'>
                    <div>
                        <ImageWrapper url={link.icon} data-test='quick-link-tray-image'/>
                        <SportName data-test='quick-link-tray-label'>{link.label}</SportName>
                    </div>
                </LinkWrapper>
            );
        }
        return null;
    }

    return (
        <NavSportWrapper key={link.id} onClick={state.handleRedirect} data-test='quick-link-tray-item'>
            <div>
                <ImageWrapper url={link.icon} data-test='quick-link-tray-image'/>
                <SportName data-test='quick-link-tray-label'>{link.label}</SportName>
            </div>
        </NavSportWrapper>
    );
});
