import { computed, makeObservable } from 'mobx';
import { Value } from 'src_common/common/mobx-utils/Value';
import { CasinoTableType, TableLimitsType } from './SocketGameDetailsModels';
import { AvailableSeatsType, RouletteColorType, RouletteNumberEmphasizedType, RouletteNumberType } from 'src/domains/casino/utils/types';
import { GameDetailsCommonModel } from 'src/domains/casino/state/AppCasino/casino-sockets/real-time-lobby-socket/GameDetailsCommonModel';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';

export class CasinoTableModel {
    private constructor(
        private readonly data: Value<CasinoTableType>,
        private readonly config: ConfigState
    ) {
        makeObservable(this);
    }

    public static create(data: Value<CasinoTableType>, config: ConfigState): CasinoTableModel {
        return new CasinoTableModel(data, config);
    }

    public getData(): CasinoTableType {
        return this.data.getValue();
    }

    public get tableName(): string {
        const data = this.data.getValue();

        return data.tableName;
    }

    public get tableId(): string {
        return this.data.getValue().tableId;
    }

    public get dealerName(): string {
        return this.data.getValue().dealer.name;
    }

    public get tableImage(): string {
        return this.data.getValue().tableImage;
    }

    public get tableLimits(): TableLimitsType {
        const tableLimits = this.data.getValue().tableLimits;

        const tableValues = {
            maxBet: this.config.precision.newFromPragmaticNumber(tableLimits.maxBet),
            maxPlayers: tableLimits.maxPlayers,
            minBet: this.config.precision.newFromPragmaticNumber(tableLimits.minBet),
        };
        return tableValues;
    }

    private getEmphasized(isHot: boolean, isCold: boolean): RouletteNumberEmphasizedType {
        if (isHot) {
            return 'hot';
        }

        if (isCold) {
            return 'cold';
        }

        return null;
    }

    private convertColor(color: string): RouletteColorType {
        const colorLowerCase = color.toLowerCase();

        if (colorLowerCase.includes('red')) {
            return 'red';
        }

        if (colorLowerCase.includes('green')) {
            return 'green';
        }

        return 'black';
    }

    private get lastResult(): Array<RouletteNumberType> {
        const data = this.data.getValue();

        const list = data.last20Results ?? [];
        const hotNumbers = data.hotNumbers ?? [];
        const coldNumbers = data.coldNumbers ?? [];

        const getColor = (color: string | null | undefined): RouletteColorType => {
            if (data.tableType === 'ROULETTE') {
                if (color !== undefined && color !== null) {
                    return this.convertColor(color);
                }
            }
            return 'black';
        };

        if (data.tableType === 'MEGAWHEEL' || data.tableType === 'ROULETTE') {
            const itemsList = [];
            for (const item of list) {
                const result = item.result;
                if (result !== undefined && result !== null) {
                    const color = getColor(item.color);

                    const isHot = hotNumbers.includes(result);
                    const isCold = coldNumbers.includes(result);
                    const emphasized = this.getEmphasized(isHot, isCold);

                    itemsList.push({
                        color,
                        emphasized,
                        result: result.toString(),
                    });
                }
            }
            return itemsList;
        }
        return [];
    }


    @computed.struct public get last5Result(): Array<RouletteNumberType> {
        return this.lastResult.slice(0, 5);
    }

    @computed public get availableSeats(): AvailableSeatsType {
        const data = this.data.getValue();

        if (data.availableSeats !== undefined) {
            if (data.tableType === 'BLACKJACK') {
                return {
                    current: data.availableSeats,
                    max: 7
                };
            }
        }

        return {
            current: data.availableSeats,
            max: null
        };
    }

    @computed public get values(): GameDetailsCommonModel {
        return new GameDetailsCommonModel(this);
    }
}
