import { Value } from './Value';

class NextId {
    private counter: number = 1;

    public get(): number {
        const currentId = this.counter;
        this.counter++;
        return currentId;
    }
}

type RefreshFnType = () => void;
type UnsubscribeFnType = () => void;

type CreateSubscription = (refresh: RefreshFnType) => UnsubscribeFnType;

export class ApiResponseWrapper<T> {
    private readonly label: string | null;
    private readonly nextId: NextId;
    private currentRequestId: number;
    private readonly getData: () => Promise<T>;

    private readonly dataInner: Value<T>;

    public constructor(label: string | null, initValue: T, getData: () => Promise<T>, createSubscription: CreateSubscription) {
        this.label = label;
        this.nextId = new NextId();
        this.currentRequestId = 0;
        this.getData = getData;
        this.dataInner = new Value(initValue, (_setValue) => {
            if (this.label !== null) {
                console.info(`${this.label} - connect`);
            }
            const unsubscribe = createSubscription(this.refresh);


            return (): void => {
                if (this.label !== null) {
                    console.info(`${this.label} - dispose`);
                }
                unsubscribe();
            };
        });
    }

    private getDataWithRequestId = async (): Promise<[number, T]> => {
        const requestId = this.nextId.get();

        const resp = await this.getData();
        return [requestId, resp];
    };

    public refresh = (): void => {
        if (this.label !== null) {
            console.info(`${this.label} - refresh`);
        }

        (async (): Promise<void> => {
            const [requestId, data] = await this.getDataWithRequestId();

            if (requestId > this.currentRequestId) {
                this.currentRequestId = requestId;
                this.dataInner.setValue(data);
            }
        })().catch((error) => {
            console.error(error);
        });
    };

    public get data(): T {
        return this.dataInner.getValue();
    }
}
