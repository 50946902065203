import { CountryCodeType } from 'src/domains/layouts/config/countries';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';

export const countryCurrency: Record<CountryCodeType, CurrencyType | undefined> = {
    GB: undefined,
    IE: undefined,
    AL: undefined,
    AD: undefined,
    AI: undefined,
    AQ: undefined,
    AG: undefined,
    AR: undefined,
    AM: undefined,
    AT: undefined,
    AW: undefined,
    AZ: undefined,
    BS: undefined,
    BH: undefined,
    BB: undefined,
    BY: undefined,
    BJ: undefined,
    BM: undefined,
    BT: undefined,
    BO: undefined,
    BQ: undefined,
    BA: undefined,
    BW: undefined,
    BV: undefined,
    IO: undefined,
    VG: undefined,
    BN: undefined,
    BG: undefined,
    BF: undefined,
    BI: undefined,
    CM: undefined,
    CV: undefined,
    KY: undefined,
    CL: undefined,
    CO: undefined,
    KM: undefined,
    CG: undefined,
    CD: undefined,
    CK: undefined,
    CR: undefined,
    ME: undefined,
    HR: undefined,
    CU: undefined,
    CW: undefined,
    CI: undefined,
    DM: undefined,
    DO: undefined,
    TL: undefined,
    EG: undefined,
    SV: undefined,
    ER: undefined,
    EE: undefined,
    FK: undefined,
    FO: undefined,
    FJ: undefined,
    GA: undefined,
    GM: undefined,
    GE: undefined,
    GH: undefined,
    GI: undefined,
    GD: undefined,
    GT: undefined,
    GG: undefined,
    GN: undefined,
    GY: undefined,
    HM: undefined,
    HN: undefined,
    IS: undefined,
    IQ: undefined,
    IM: undefined,
    IT: undefined,
    JM: undefined,
    JE: undefined,
    JO: undefined,
    KZ: undefined,
    KE: undefined,
    KI: undefined,
    KW: undefined,
    KG: undefined,
    LA: undefined,
    LB: undefined,
    LS: undefined,
    LR: undefined,
    LI: undefined,
    LT: undefined,
    LU: undefined,
    MO: undefined,
    MK: undefined,
    MG: undefined,
    MW: undefined,
    MV: undefined,
    MT: undefined,
    MH: undefined,
    MR: undefined,
    MU: undefined,
    MX: undefined,
    FM: undefined,
    MD: undefined,
    MC: undefined,
    MN: undefined,
    MS: undefined,
    MA: undefined,
    MZ: undefined,
    NA: undefined,
    NR: undefined,
    NP: undefined,
    NL: undefined,
    NZ: undefined,
    NI: undefined,
    NE: undefined,
    NG: undefined,
    NU: undefined,
    NO: undefined,
    OM: undefined,
    PW: undefined,
    PS: undefined,
    PA: undefined,
    PG: undefined,
    PY: undefined,
    PE: undefined,
    PN: undefined,
    QA: undefined,
    RO: undefined,
    BL: undefined,
    SH: undefined,
    KN: undefined,
    LC: undefined,
    MF: undefined,
    VC: undefined,
    WS: undefined,
    SM: undefined,
    SA: undefined,
    SN: undefined,
    SC: undefined,
    SL: undefined,
    SX: undefined,
    SI: undefined,
    SB: undefined,
    ZA: undefined,
    GS: undefined,
    ES: undefined,
    LK: undefined,
    SR: undefined,
    SJ: undefined,
    SZ: undefined,
    SE: undefined,
    ST: undefined,
    TW: undefined,
    TJ: undefined,
    TZ: undefined,
    TH: undefined,
    TG: undefined,
    TK: undefined,
    TO: undefined,
    TT: undefined,
    TN: undefined,
    TC: undefined,
    TV: undefined,
    UG: undefined,
    UY: undefined,
    VU: undefined,
    VA: undefined,
    VE: undefined,
    EH: undefined,
    ZM: undefined,
    AX: undefined,
    XK: undefined,
    NF: undefined,
    CY: undefined,
    TM: undefined,
    MM: undefined,
    LV: undefined,
    TF: undefined,
    ID: undefined,
    IR: undefined,
    SY: undefined,
    JP: undefined,
    CX: undefined,
    GU: undefined,
    GP: undefined,
    CN: undefined,
    ZW: undefined,
    RU: undefined,
    TR: undefined,
    MP: undefined,
    GQ: undefined,
    DJ: undefined,
    AO: undefined,
    AS: undefined,
    CF: undefined,
    SD: undefined,
    PH: undefined,
    UM: undefined,
    PF: undefined,
    SO: undefined,
    ML: undefined,
    PK: undefined,
    SS: undefined,
    EC: undefined,
    FI: undefined,
    GF: undefined,
    BD: undefined,
    PR: undefined,
    UZ: undefined,
    GW: undefined,
    GR: undefined,
    VI: undefined,
    PL: undefined,
    DE: undefined,
    KP: undefined,
    KR: undefined,
    WF: undefined,
    TD: undefined,
    SG: undefined,
    CH: undefined,
    HK: undefined,
    BZ: undefined,
    IL: undefined,
    DK: undefined,
    BE: undefined,
    HT: undefined,
    CC: undefined,
    HU: undefined,
    FR: undefined,
    CZ: undefined,
    UA: undefined,
    US: undefined,
    DZ: undefined,
    PT: undefined,
    RE: undefined,
    PM: undefined,
    NC: undefined,
    GL: undefined,
    MQ: undefined,
    AE: undefined,
    LY: undefined,
    CA: undefined,
    MY: undefined,
    ET: undefined,
    VN: undefined,
    KH: undefined,
    AU: undefined,
    AF: undefined,
    YT: undefined,
    RS: undefined,
    BR: undefined,
    RW: undefined,
    YE: undefined,
    SK: undefined,
    IN: undefined,
};
