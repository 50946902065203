import { JackpotsListDetails } from './JackpotsListDetails';
import { JackpotForGameType } from './JackpotsDetailsModels';
import { Amount } from 'src_common/common/amount/Amount';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

export class JackpotsSocket {
    private readonly data: JackpotsListDetails | null = null;

    public constructor(
        public readonly websocket_casino_jackpots_host: string | null,
        public readonly isBrowser: boolean,
        public readonly sdkCustomer: SdkCustomer
    ) {
        if (websocket_casino_jackpots_host === null) {
            console.warn('Casino jackpots host is not defined.');
            return;
        }
        this.data = new JackpotsListDetails(websocket_casino_jackpots_host, isBrowser);
    }

    public getModel(gameId: number): JackpotForGameType | null {
        if (this.data === null) {
            return null;
        }
        const jackpotsListModel = this.data.getModel();

        if (jackpotsListModel === null) {
            return null;
        }

        const list = jackpotsListModel.jackpotsList.jackpots;

        for (const jackpot of list) {
            if (jackpot.games.filter(jackpotGameId => jackpotGameId === gameId).length > 0) {
                const jackpotAmount = new Amount(jackpot.amount);
                const jackpotAmountToShow = this.sdkCustomer.money(jackpotAmount, true);

                return {
                    id: jackpot.id,
                    name: jackpot.name,
                    amount: jackpotAmountToShow
                };
            }
        }
        return null;
    }
}
