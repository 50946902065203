import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const QuickPickColumnHeaderName = withConfig(theme => styled('span', { label: 'QuickPickColumnHeaderName' })`

    display: block;

    font-size: ${theme.star.fontSize.xSmall};

    overflow: hidden;

    padding: 0 6px;

    text-overflow: ellipsis;

    white-space: nowrap;

    width: 100%;

`);



export const QuickPickColumnHeaderHour = withConfig(theme => styled('span', { label: 'QuickPickColumnHeaderHour' })`

    font-weight: ${theme.star.fontWeight.bold};

    font-size: ${theme.star.fontSize.xRegular};

`);



export const QuickPickCheckIconWrapper = withConfig(theme => styled(CheckSingleIcon, { label: 'QuickPickCheckIconWrapper' })`

    fill: ${theme.star.quickPick.bgColorSenary};

    position: absolute;

    right: 1px;

    top: 1px;

    width: 10px;

`);



export const SelectionNotRunning = withConfig(theme => styled('span', { label: 'SelectionNotRunning' })`

    align-items: center;

    background: ${theme.star.quickPick.bgColorQuaternary};

    color: ${theme.star.quickPick.txtColor};

`);

