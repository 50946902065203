import React, { useState } from 'react';
import { EventsTable } from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable';
import { useAppStateContext } from 'src/appState/AppState';
import { FilterKindType } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterType';
import { computed, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAsObservableSource } from 'mobx-react-lite';
import { EventsTableHeaderStyle } from 'src/domains/sportsbook/webview/components/eventsTable/EventsMainSectionWrapper';
import { AppSportsBookState } from 'src/domains/sportsbook/state/AppSportsBook.state';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';

const filters: Array<FilterKindType> = ['sport'];

class UpcomingState {
    public constructor(private readonly appSportsBookState: AppSportsBookState, private readonly loading: boolean) {
        makeObservable(this);
    }

    @computed public get isLoading(): boolean {
        return this.loading;
    }
    @computed public get eventsCollection(): EventsCollectionList {
        return this.appSportsBookState.eventsCollection.listInPlayOrFilteredUpcoming();
    }

    public get tableLabel(): string {
        return this.appSportsBookState.language.getTranslation('special-box.header.upcoming', 'Upcoming');
    }
};

interface PropsTypes {
    showPriceLegend: boolean,
    isLoading: boolean,
    headerStyleLevel: 'sportLevel' | 'competitionLevel'
}

export const EventsUpcoming = observer('EventsUpcoming', (propsIn: PropsTypes) => {
    const { appSportsBookState, config } = useAppStateContext();
    const props = useAsObservableSource(propsIn);
    const [upcomingState] = useState(() => new UpcomingState(appSportsBookState, props.isLoading));

    return (
        <EventsTable
            headerStyle={EventsTableHeaderStyle.SOLID}
            filterVersion={config.filtersVersion.eventsUpcoming}
            icon='in-play'
            className='events-table--in-play events-table--dark'
            title={upcomingState.tableLabel}
            showHeaderSport={true}
            allGroupsOpen={true}
            eventsCollection={upcomingState.eventsCollection}
            filters={filters}
            isTogglable={true}
            showPriceLegend={props.showPriceLegend}
            hideNoEventsMessage={true}
            isLoading={upcomingState.isLoading}
            headerStyleLevel={props.headerStyleLevel}
            isAmericanFootball={false}
        />
    );
});
