import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { SearchIcon } from 'src/domains/layouts/shared/assets/icons/SearchIcon';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { css, SerializedStyles } from '@emotion/react';
import { customFilterDropdown } from 'src/domains/casino/webview/CasinoGames/filters-dropdown/CustomFilterDropdown.style';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { FiltersSkinType } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { withConfig } from 'src/withConfig';
import Select from 'react-select';

interface PropsType {
    isActive: boolean;
    isSearchOpen: boolean;
    filterVersion: FiltersSkinType;
}

export const SearchBtn = withConfig(theme => styled(Button, { label: 'SearchBtn' })`
    background: ${theme.star.searchBar.searchBarCasino.bgColor};
    flex: 0 0 48px;
    height: 40px;
    padding: 10px 14px;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.above};
    &:hover {
        &::before {
            opacity: 0;
        }
    }
`);

const setSearchIcon = (theme: EmotionTheme): SerializedStyles => css`
    bottom: 0;
    display: block;
    fill: ${theme.star.casino.bgColorSecondary};
    left: 0;
    margin: auto;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.35s ease, transform 0.35s ease;
    width: 20px;
`;

type SearchIconWrapperPropsType = Pick<PropsType, 'isSearchOpen'>;

export const SearchIconWrapper = withConfig(theme => styled(SearchIcon, { label: 'SearchIconWrapper' })<SearchIconWrapperPropsType>`
    ${setSearchIcon(theme)};
    opacity: ${({ isSearchOpen }): number => isSearchOpen ? 0 : 1};
    visibility: ${({ isSearchOpen }): string => isSearchOpen ? 'hidden' : 'visible'};
`);

type ChevronIconWrapperPropsType = Pick<PropsType, 'isSearchOpen'>;

export const ChevronIconWrapper = withConfig(theme => styled(ChevronIcon, { label: 'ChevronIconWrapper' })<ChevronIconWrapperPropsType>`
    ${setSearchIcon(theme)};
    opacity: ${({ isSearchOpen }): number => isSearchOpen ? 1 : 0};
    visibility: ${({ isSearchOpen }): string => isSearchOpen ? 'visible' : 'hidden'};
`);

type SearchInputWrapperPropsType = Pick<PropsType, 'isSearchOpen'>;

export const SearchInputWrapper = withConfig(theme => styled('div', { label: 'SearchInputWrapper' })<SearchInputWrapperPropsType>`
    border-radius: 0;
    flex: 0 0 auto;
    height: 40px;
    margin-left: 0;
    max-width: 295px;
    overflow: hidden;
    position: relative;
    transition: margin 0.35s ease, width 0.35s ease, ${({ isSearchOpen }): string => isSearchOpen === false ? 'visibility 0s 0.35s ease' : 'visibility 0s 0s'};
    visibility: ${({ isSearchOpen }): string => isSearchOpen === false ? 'hidden' : 'initial'};
    width: ${({ isSearchOpen }): string => isSearchOpen === false ? '0' : 'calc(100% - 64px)'};
    @media ${theme.star.mediaQuery.tablet} {
        max-width: 320px;
        width: ${({ isSearchOpen }): string => isSearchOpen === false ? '0' : '320px'};
    }
`);

export const SearchInput = withConfig(theme => styled('input', { label: 'SearchInput' })`
    background: ${theme.star.searchBar.bgColorTertiary};
    border: solid 1px transparent;
    color: ${theme.star.searchBar.searchBarCasino.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    height: 100%;
    line-height: 1.43;
    padding-left: 12px;
    width: 100%;

    &:focus {
        border-color: ${theme.star.searchBar.searchBarCasino.borderColor};
    }

    &::-webkit-search-cancel-button {
        display: none;
    }
`);

export const SearchCloseButton = withConfig(theme => styled(CloseIcon, { label: 'SearchCloseButton' })`
    bottom: 0;
    cursor: pointer;
    fill: ${theme.star.searchBar.searchBarCasino.txtColor};
    left: 0;
    margin: auto 0 auto auto;
    padding: 12px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
`);

interface SearchAndFilterWrapperType {
    isDropdownOpened?: boolean;
    isMiniGamesListDisplayed: boolean;
    stickyDesktopHeader: boolean;
}

export const SearchAndFilterWrapper = withConfig(theme => styled('div', { label: 'SearchAndFilterWrapper' })<SearchAndFilterWrapperType>`
    align-items: flex-start;
    display: flex;
    overflow: hidden;
    padding: 16px 0;
    position: sticky;
    top: 48px;
    z-index: ${({ isMiniGamesListDisplayed }): number => isMiniGamesListDisplayed ? theme.star.zIndexGlobal.base : theme.star.zIndexGlobal.above};
    ${({ isDropdownOpened }): string => isDropdownOpened === true ? 'margin-bottom: -200px;' : ''}

    @media ${theme.star.mediaQuery.desktop} {
        top: ${({ stickyDesktopHeader }): string => stickyDesktopHeader ? '56px' : '0px'};
    }

    &::before {
        content: '';
        background: ${theme.star.casino.bgColor};
        height: 72px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`);

type FilterDropdownPropsType = Pick<PropsType, 'isActive' | 'filterVersion'>;

export const FilterDropdown = withConfig(theme => styled(Select, { label: 'FilterDropdown' })<FilterDropdownPropsType>`
    /* External library custom drop down */
    ${({ isActive, filterVersion }): SerializedStyles => customFilterDropdown(theme, isActive, filterVersion)}
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    height: 32px;
    margin: 0 auto 0 4px;
    position: fixed;
`);

export const CasinoFilterWrapper = styled('div', { label: 'CasinoFilterWrapper' })`
    overflow-x: hidden;
    padding: 4px 0 0 8px;
`;
