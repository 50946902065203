import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { getPlace, getRunner, NotRunningPriceView } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { RaceSummaryRowEachWayExtra } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/eachWayExtraSymmary/RaceSummaryRowEachWayExtra';
import { EachWayExtraSelectionCast, EachWayExtraRaceSummaryCaption, EachWayExtraRunnerCaption, EachWayExtraSelectionCaption, EachWayExtraPlaceCaption, EachWayExtraSilkCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/eachWayExtraSymmary/EachWayExtraSymmary.style';
import { observer } from 'src/utils/mobx-react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { computed, makeObservable } from 'mobx';
import { useAsObservableSource } from 'mobx-react-lite';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { ScrollRowWrapper } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll.style';
import { RaceSummaryTwoColumnsList } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll';
import { useAppStateContext } from 'src/appState/AppState';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';

function getSelectionByName(selectionName: string, marketModel: MarketModel): SelectionModel | null {
    for (const selection of marketModel.selections) {

        if (selection.name === selectionName) {
            return selection;
        }
    }

    return null;
};

function selectionsBuilder(selectionName: string, eachWayMarkets: MarketModel[], isNonRunnerRow: boolean, oddsType: 'f' | 'd'): Array<JSX.Element | null> | null {
    return eachWayMarkets.map(market => {
        const selectionInCurrentRow = getSelectionByName(selectionName, market);
        if (isNonRunnerRow === true) {
            return <NotRunningPriceView key={selectionInCurrentRow?.id2.toOldId()} eachWayExtra={true} />;
        }

        if (selectionInCurrentRow === null) {
            return null;
        }

        return <EachWayExtraSelectionCast
            key={selectionInCurrentRow.id}
            selectionId={selectionInCurrentRow.id2}
            title={oddsType === 'd' ? selectionInCurrentRow?.price?.d : selectionInCurrentRow?.price?.f}
            borderLeft={true}
            index={selectionInCurrentRow.id}
        />;
    });
};


type MarketsPlaceAndReductionType = {
    places: string;
    reduction: string;
    marketId: MarketId;
}

class EachWayExtraSummaryState {

    public constructor(private readonly eachWayExtraProps: EachWayExtraSummaryTypeProps) {
        makeObservable(this);
    }

    @computed public get sortedMarketsByTermPlaces(): Array<MarketModel> {
        const sortedMarkets: Array<MarketModel> = this.eachWayExtraProps.race.marketEachWayExtra.sort((marketA, marketB) => {
            const marketAPlaces = marketA.eachWayTermsPlaces;
            const marketBPlaces = marketB.eachWayTermsPlaces;

            if (marketAPlaces === undefined || marketBPlaces === undefined) {
                return 0;
            }

            return marketAPlaces - marketBPlaces;
        });


        return sortedMarkets;
    }

    @computed public get marketsPlaceAndReduction(): Array<MarketsPlaceAndReductionType> {
        const marketsSimpleData: Array<MarketsPlaceAndReductionType> = [];

        for (const market of this.sortedMarketsByTermPlaces) {
            const eachWay = market.eachWay;
            if (eachWay !== undefined) {
                const terms = eachWay.terms[0];

                if (terms !== undefined) {
                    const places = `${terms.places} Places`;
                    const reduction = `EW${terms.reduction}`;

                    const data = {
                        places,
                        reduction,
                        marketId: market.id2
                    };

                    marketsSimpleData.push(data);
                };
            };

        }

        return marketsSimpleData;
    }

    @computed public get eachWaySelections(): Array<SelectionModel> | null {
        const eachWayMarket = this.sortedMarketsByTermPlaces[0];

        if (eachWayMarket !== undefined) {
            return eachWayMarket.selections;
        }

        return null;
    }

    //HardCoded sorted by price - not support for OrderBy from market template.
    @computed public get selectionsByPrice(): Array<SelectionModel> | undefined {
        const selections = this.eachWaySelections?.sort((selectionA, selectionB) => {
            const priceA = selectionA.price?.d;
            const priceB = selectionB.price?.d;
            if (priceA === undefined || priceB === undefined) {
                return 0;
            }
            return priceA - priceB;
        });

        if (selections === undefined) {
            return selections;
        }
        const sortedViaNonRunner = [];
        const sortedWithRunner = [];
        for (const selection of selections) {
            const nonRunner = this.isNonRunnerInRow(selection.name);
            if (nonRunner === true) {
                sortedViaNonRunner.push(selection);
            } else {
                sortedWithRunner.push(selection);
            }
        }

        return [...sortedWithRunner, ...sortedViaNonRunner];
    }

    public isNonRunnerInRow = (selectionName: string): boolean => {
        const markets = this.sortedMarketsByTermPlaces;
        let isNonRunnerInRow = false;
        for (const market of markets) {
            const selectionInRow = getSelectionByName(selectionName, market);

            if (selectionInRow?.resultType === 'void') {
                isNonRunnerInRow = true;
            }
        }

        return isNonRunnerInRow;
    };
}

type EachWayExtraSummaryTypeProps = {
    race: EventModel;
}

export const EachWayExtraSummary = observer('EachWayExtraSummary', (propsIn: EachWayExtraSummaryTypeProps): JSX.Element | null => {
    const props = useAsObservableSource(propsIn);
    const { appSportsBookState } = useAppStateContext();
    const [state] = useState(() => new EachWayExtraSummaryState(props));
    const selectionsByPrice = state.selectionsByPrice;
    if (selectionsByPrice === undefined) {
        return null;
    }

    const firstSelection = selectionsByPrice[0];
    if (firstSelection === undefined) {
        return null;
    }

    const marketModel = firstSelection.getMarket();
    if (marketModel === null) {
        return null;
    }
    const oddsTypeShort = appSportsBookState.getOddsFormat();
    // const oddsType = appState.accountState.account?.basicDataReady?.oddsFormat !== undefined ? appState.accountState.account?.basicDataReady?.oddsFormat : 'fractal';


    const StaticList = (): JSX.Element => {
        return <>
            <EachWayExtraRaceSummaryCaption>
                <EachWayExtraPlaceCaption>#</EachWayExtraPlaceCaption>
                <EachWayExtraSilkCaption>
                    <I18n langKey='events.race-summary.silk.label' defaultText='Silk' />
                </EachWayExtraSilkCaption>
                <EachWayExtraRunnerCaption>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </EachWayExtraRunnerCaption>

            </EachWayExtraRaceSummaryCaption>

            {selectionsByPrice.map(selection => {
                return <RaceSummaryRowEachWayExtra
                    key={selection.id}
                    isNonRunner={state.isNonRunnerInRow(selection.name)}
                    selectionId={selection.id2}
                    runner={getRunner(selection, false, true, true)}
                    place={getPlace( selection)}
                />;
            })}
        </>;
    };

    const ScrollList = (): JSX.Element => {
        return <>
            <EachWayExtraRaceSummaryCaption>
                {state.marketsPlaceAndReduction.map(market => <EachWayExtraSelectionCaption key={market.marketId.toOldId()}>{market.places}<br />{market.reduction}</EachWayExtraSelectionCaption>)}
            </EachWayExtraRaceSummaryCaption>
            {selectionsByPrice.map(selection => <ScrollRowWrapper key={`price-row-${selection.id}`} isNonRunner={state.isNonRunnerInRow(selection.name)}>{selectionsBuilder(selection.name, state.sortedMarketsByTermPlaces, state.isNonRunnerInRow(selection.name), oddsTypeShort)}</ScrollRowWrapper>)}
        </>;
    };

    return <RaceSummaryTwoColumnsList staticListElements={StaticList()} scrollListElements={ScrollList()} columnCount={state.marketsPlaceAndReduction.length} />;
});
