import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const FiltersWrapper = withConfig(theme => styled('div', { label: 'FiltersWrapper' })`
    background-color: ${theme.star.headings.section.bgColor};
    padding: 8px 0 8px 8px;
`);

export const RaceCardChevronIconWrapper = styled(ChevronIcon, { label: 'RaceCardChevronIconWrapper' })`
    fill: currentcolor;
    flex: 0 0 12px;
    margin-left: 4px;
`;

export const AtLeastTabletView = withConfig(theme => styled('div', { label: 'AtLeastTabletView' }) <{ isAntePostReverse: boolean }>`
    display: none;
    @media ${theme.star.mediaQuery.tablet} {
        display: block;
        margin-bottom: ${({ isAntePostReverse }): string => isAntePostReverse ? '20px' : '0'};
    }
`);


export const MobileView = withConfig(theme => styled('div', { label: 'MobileView' })`
    display: block;
    @media ${theme.star.mediaQuery.tablet} {
        display: none;
    }
`);

export const BuildRaceCardButton = styled(Button, { label: 'BuildRaceCardButton' })`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
`;

export const ContentWrapper = styled('div', { label: 'ContentWrapper' }) <{ isAntePostReverse: boolean }>`
    display: flex;
    flex-direction: ${({ isAntePostReverse }): string => isAntePostReverse ? 'column-reverse' : 'column'};
`;
