import styled from '@emotion/styled';

import { setCasinoLayout } from 'src/domains/casino/webview/layouts/casinoPage/CasinoPage.style';

import { withConfig } from 'src/withConfig';



export const LiveCasinoPageMainWrapper = withConfig(theme => styled('div', { label: 'LiveCasinoPageMainWrapper' })`

    ${setCasinoLayout(theme)};

`);

