import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const TraderChatNotificationBar = withConfig(theme => styled('div', { label: 'TraderChatNotificationBar' })`

    background: ${theme.star.traderChat.notification.bgColor};

    color: ${theme.star.traderChat.notification.txtColor};

    flex: 0 0 64px;

    font-size: ${theme.star.fontSize.small};

    font-weight: ${theme.star.fontWeight.medium};

    line-height: 1.3334;

    padding: 16px 9px 16px 64px;

    position: relative;

`);

