import styled from '@emotion/styled';
import { ImagesType, ImagesHeightType } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeEventDetailsItemState';
import { HourGlassIcon } from 'src/domains/layouts/shared/assets/icons/HourGlassIcon';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { withConfig } from 'src/withConfig';

interface HeaderWrapperTypes {
    banners: ImagesType;
    heights?: ImagesHeightType;
}

export const HeaderWrapper = withConfig(theme => styled('div', { label: 'HeaderWrapper' })<HeaderWrapperTypes>`
    padding: 20px 10px;
    background-color: ${theme.star.heroEvents.bgColorSenary};
    background-image: url(${({ banners }): string => banners.mobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    @media ${theme.star.mediaQuery.xMobile} {
        background-image: url(${({ banners }): string => banners.tablet});
    }

    @media ${theme.star.mediaQuery.desktop} {
        background-image: url(${({ banners }): string => banners.desktop});
    }
`);

export const HeaderImageWrapper = withConfig(theme => styled(HeaderWrapper, { label: 'HeaderImageWrapper' })<HeaderWrapperTypes>`
    height: ${({ heights }): string => heights?.mobile ?? '200px'};

    @media ${theme.star.mediaQuery.xMobile} {
        height: ${({ heights }): string => heights?.tablet ?? '250px'};
    }

    @media ${theme.star.mediaQuery.desktop} {
        height: ${({ heights }): string => heights?.desktop ?? '300px'};
    }
`);

//------------------------ todo refactor ----------------

export const HeaderWithMovieDesc = withConfig(theme => styled('h1', { label: 'HeaderWithMovieDesc' })`
    color: ${theme.star.heroEvents.txtColor};
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    margin: 0 10px 15px;
    max-width: 260px;
    position: relative;
    text-transform: uppercase;

    @media screen and (min-width: 480px) {
        max-width: initial;
    }
    @media ${theme.star.mediaQuery.desktop} {
        margin-bottom: 58px;
    }
    &::after {
        background-color: ${theme.star.heroEvents.bgColor};
        top: 100%;
        content: '';
        height: 2px;
        left: 0;
        margin-top: 5px;
        position: absolute;
        width: 40px;
        @media ${theme.star.mediaQuery.desktop} {
            margin-top: 7px;
        }
    }
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.big};
        max-width: initial;
    }
`);

export const TimerHeader = withConfig(theme => styled('span', { label: 'TimerHeader' })`
    color: ${theme.star.heroEvents.txtColor};
    display: block;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    margin-bottom: 4px;
    text-align: center;
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.regular};
        margin-bottom: 12px;
    }
`);

export const TimeStyle = withConfig(theme => styled('span', { label: 'TimeStyle' })`
    color: ${theme.star.heroEvents.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    text-transform: lowercase;
`);

export const WatchLiveBtn = withConfig(theme => styled('div', { label: 'WatchLiveBtn' })`
    align-items: center;
    background-color: ${theme.star.heroEvents.bgColor};
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    display: flex;
    height: 88px;
    justify-content: center;
    margin: 0 auto 44px;
    position: relative;
    width: 88px;
    @media ${theme.star.mediaQuery.desktop} {
        height: 136px;
        margin-bottom: 72px;
        width: 136px;
    }
    &::before {
        border-color: transparent transparent transparent ${theme.star.heroEvents.borderColorSecondary};
        border-style: solid;
        border-width: 16.5px 0 16.5px 28px;
        content: '';
        height: 0;
        margin-left: 8px;
        width: 0;
        @media ${theme.star.mediaQuery.desktop} {
            border-width: 23px 0 23px 38px;
            margin-left: 14px;
        }
    }
`);

export const NotAllowStreamWrapper = withConfig(theme => styled('div', { label: 'NotAllowStreamWrapper' })`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    width: 300px;
    padding: 10px;
    background-color: ${theme.star.heroEvents.bgColor};
    border-radius: 16px;
    opacity: .95;
    @media ${theme.star.mediaQuery.tablet} {
        height: 72px;
        width: 480px;
        padding: 20px 15px;
    }
`);

export const InfoTextWrapper = withConfig(theme => styled('div', { label: 'InfoTextWrapper' })`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    font-weight: ${theme.star.fontWeight.medium};
    justify-content: space-between;
    height: 21px;
    color: ${theme.star.heroEvents.txtColorSecondary};
    margin-left: 10px;

    @media ${theme.star.mediaQuery.tablet} {
        height: 30px;
    }
`);

export const CrossButton = withConfig(theme => styled(CloseIcon, { label: 'CrossButton' })`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    cursor: pointer;
    fill: ${theme.star.heroEvents.bgColorSecondary};
    margin-right: 6px;
    margin-left: 10px;

    @media ${theme.star.mediaQuery.tablet} {
        margin-left: 20px;
        margin-right: 10px;
        width: 14px;
    }
`);

export const InfoLongText = withConfig(theme => styled('div', { label: 'InfoLongText' })`
    font-size: ${theme.star.fontSize.small};
    color: ${theme.star.heroEvents.txtColorSecondary};
    line-height: 1;
    font-weight: ${theme.star.fontWeight.bold};
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.regular};
    }
`);

export const InfoShortText = withConfig(theme => styled('div', { label: 'InfoShortText' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.regular};
    }
`);

export const BlueBoldText = withConfig(theme => styled('span', { label: 'BlueBoldText' })`
    color: ${theme.star.heroEvents.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    cursor: pointer;
`);

export const NotAllowStreamContainer = withConfig(theme => styled('div', { label: 'NotAllowStreamContainer' })`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 88px;
    margin: 72px auto 44px;
    @media ${theme.star.mediaQuery.tablet} {
        height: 136px;
        margin-bottom: 72px;
    }
`);

export const StartDateInfo = withConfig(theme => styled('div', { label: 'StartDateInfo' })`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    color: ${theme.star.heroEvents.txtColorSecondary};

    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.regular};
    }
`);

export const HourGlassWrapper = withConfig(theme => styled(HourGlassIcon, { label: 'HourGlassWrapper' })`
    width: 27px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 43px;
    }
`);

export const RaceStartWrapper = styled('div', { label: 'RaceStartWrapper' })<{isStreamUrlAvailable: boolean}>``;

export const WatchLiveBtnSmall = withConfig(theme => styled(WatchLiveBtn, { label: 'WatchLiveBtnSmall' })`
    width: 27px;
    height: 27px;
    margin: 0;
    background-color: ${theme.star.heroEvents.bgColorQuinary};
    @media ${theme.star.mediaQuery.tablet} {
        margin: 0;
        width: 43px;
        height: 43px;
    }
    &::before {
        border-color: transparent transparent transparent ${theme.star.heroEvents.borderColor};
        border-width: 4.5px 0 4.5px 7px;
        margin-left: 2px;
        @media ${theme.star.mediaQuery.tablet} {
            border-width: 7.5px 0 7.5px 12px;
            margin-left: 4px;
        }
    }
`);

export const StreamWrapper = styled('div', { label: 'StreamWrapper' })`
    display: flex;
    justify-content: center;
`;
