import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

import { Input } from 'src/domains/players/webview/components/form/input/Input';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';

import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';



export const WizardWrapper = styled('div', { label: 'WizardWrapper' })`

    padding: 0 16px;

`;



export const WizardForm = styled('form', { label: 'WizardForm' })``;



export const WizardFormWrapper = styled('fieldset', { label: 'WizardFormWrapper' })`

    border: 0;

    margin: 0;

    padding: 0;

`;



export const WizardFormTitle = withConfig(theme => styled('legend', { label: 'WizardFormTitle' })`

    font-size: ${theme.star.fontSize.regular};

    font-weight: ${theme.star.fontWeight.bold};

    line-height: 1.5;

    padding: 0;

    position: relative;

    text-transform: uppercase;

    top: 2px;

`);



export const AgreeButtonWrapper = styled(Button, { label: 'AgreeButtonWrapper' })`

    margin-top: 24px;

    width: 100%;

`;



export const SearchAddressButton = styled(Button, { label: 'SearchAddressButton' })`

    margin: 36px 0 0 3px;

    padding: 0 16px;

    flex: 1 1 82px;

    height: 40px;

`;



export const PostcodeWrapper = styled('div', { label: 'PostcodeWrapper' })`

    position: relative;

    display: flex;

    flex-wrap: wrap;

    align-items: flex-start;

`;



export const AddressesInputsWrapper = styled('div', { label: 'AddressesInputsWrapper' })`

    position: relative;

`;



export const AddressesListWrapper = withConfig(theme => styled('ul', { label: 'AddressesListWrapper' })`

    background-color: ${theme.star.form.dark.bgColor};

    left: 0;

    margin: 4px 0 0;

    max-height: 240px;

    overflow-y: auto;

    padding: 6px 0;

    position: absolute;

    top: 100%;

    width: 100%;

    z-index: ${theme.star.zIndexSelect.optionWrapper};

`);



export const AddressesListItem = withConfig(theme => styled('li', { label: 'AddressesListItem' })`

    color: ${theme.star.form.txtColor};

    cursor: pointer;

    font-size: ${theme.star.fontSize.xSmall};

    padding: 12px;

    line-height: 1.3334;

    &:hover {

        background-color: ${theme.star.signUp.yourDetails.bgColor};

    }

`);



export const AddressesListItemLast = withConfig(theme => styled(AddressesListItem, { label: 'AddressesListItemLast' })`

    border-top: 1px solid ${theme.star.form.light.borderColor};

    font-size: ${theme.star.fontSize.small};

    cursor: auto;

    margin-left: 12px;

    padding: 6px 12px 6px 0;

    &:hover {

        background-color: inherit;

    }

`);



export const EnterManually = withConfig(theme => styled('span', { label: 'EnterManually' })`

    color: ${theme.star.signUp.txtColorSecondary};

    cursor: pointer;

    display: block;

    font-size: ${theme.star.fontSize.xRegular};

    font-weight: ${theme.star.fontWeight.bold};

    line-height: 1.43;

    margin-top: 4px;

`);



export const EnterManuallyAddressList = withConfig(theme => styled(EnterManually, { label: 'EnterManuallyAddressList' })`

    font-size: ${theme.star.fontSize.small};

    color: ${theme.star.signUp.yourDetails.txtColor};

    line-height: 1.3334;

`);



export const ClearAddressForm = styled(EnterManually, { label: 'ClearAddressForm' })`

    line-height: 1;

    margin-top: 0;

    position: absolute;

    right: 0;

    top: 0;

`;



export const FlexInput = styled(Input, { label: 'FlexInput' })`

    flex: 1 1 calc(100% - 87px);

`;



export const ErrorMessageWrapper = styled(Messages, { label: 'ErrorMessageWrapper' })`

    flex: 1 1 0;

`;

