import { z } from 'zod';

export const DetailedErrorSchema = z.object({
    code: z.string().optional().nullable(),
    debugDetails: z.string().optional().nullable(),
    details: z.string().optional().nullable(),
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            debugDetails: z.string().optional().nullable(),
            field: z.string().optional().nullable(),
            pointer: z.string().optional().nullable(),
            resource: z.string().optional().nullable(),
        })
    ),
    message: z.string().optional().nullable(),
});

export type TDetailedErrorResponse = z.infer<typeof DetailedErrorSchema>;
