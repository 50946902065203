import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { BannerWrapper, BannerImage, TermsWrapper } from './CasinoBanners.style';
import { CasinoBannersState } from 'src/domains/casino/webview/CasinoGames/home-games/casino-banners/CasinoBanners.state';
import { AppCasinoState, BannerType } from 'src/domains/casino/state/AppCasino.state';
import { SingleBannerState } from './SingleBanner.state';

interface SingleBannerProps {
    appCasinoState: AppCasinoState;
    index: number;
    banner: BannerType;
    casinoBannersState: CasinoBannersState;
}

export enum RedirectTypeEnum {
    REDIRECT_URL = 'REDIRECT_URL',
    REDIRECT_GAME = 'REDIRECT_GAME',
}

export const SingleBanner = observer('SingleBanner', (props: SingleBannerProps) => {
    const { appCasinoState, banner, index, casinoBannersState } = props;
    const { activeBannerIndex } = casinoBannersState;
    const { title, terms_headline, image_desktop, image_tablet, image_mobile } = banner;

    const [singleBannerState] = useState(new SingleBannerState(appCasinoState, casinoBannersState, banner));
    const { showTerms, handleTouchStart, handleTouchEnd, handleRedirect, handleTerms } = singleBannerState;

    return (
        <BannerWrapper
            offset={index - activeBannerIndex}
            onTouchStart={(e): void => handleTouchStart(e)}
            onTouchEnd={(e): void => handleTouchEnd(e)}
        >
            <BannerImage
                onClick={handleRedirect}
                alt={title}
                imgBase={{ src: image_desktop }}
                imgDeviceSet={{
                    mobile: { src: image_mobile },
                    tablet: { src: image_tablet },
                }}
            />
            {showTerms && <TermsWrapper onClick={handleTerms}>{terms_headline}</TermsWrapper>}
        </BannerWrapper>
    );
});
