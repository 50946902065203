import styled from '@emotion/styled';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';

export const MiniGameWrapper = styled('div', { label: 'MiniGameWrapper' })`
    position: relative;
    width: 100%;
    &:hover > div {
        visibility: visible;
    }
`;

export const MiniGameImgWrapperLazy = styled(LazyLoadingImg, { label: 'MiniGameImgWrapperLazy' })`
    padding-top: 70%;
    position: relative;
    width: 100%;
`;
