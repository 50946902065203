import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { GameStatisticLabel, GameStatisticValue, GameStatisticWrapper } from './GameInfoStatistic.style';

interface GameInfoStatisticPropsType {
    label: string,
    value: number | string
};

export const GameInfoStatistic = observer('GameInfoStatistic', ({ label, value }: GameInfoStatisticPropsType): JSX.Element => {
    return (
        <GameStatisticWrapper>
            <GameStatisticValue>{value}</GameStatisticValue>
            <GameStatisticLabel>{label}</GameStatisticLabel>
        </GameStatisticWrapper>
    );
});
