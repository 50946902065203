import { ParsedPlaceBetType, PlaceBetBetsType } from 'src/domains/sportsbook/betting/betting/postPlaceBet';

export type ErrorMsgType =
    | 'BET-TOO-HIGH'
    | 'BET-TOO-LOW'
    | 'PAYOUT-TOO-HIGH'
    | 'MAX-BET-EXCEEDED'
    | 'INTERNAL_SERVER_ERROR_MESSAGE';
export interface ArrayBetsType {
    betId: number;
    type: string;
    totalStake: string;
    totalStakeCurrency: string;
    freebet: boolean;
    legs: Array<{
        marketId?: string | number;
        marketName?: string;
        selectionId?: string | number;
        selectionName?: string;
        betEventId?: string | number;
        betEventName?: string;
        price?: number;
        sportId?: number | string;
        sportName?: string;
    }>;
    isBoostVisible: boolean;
    isBoosted: boolean | undefined;
}

export const parsePlaceBetLegs = (legs: PlaceBetBetsType[number]['legs']): ArrayBetsType['legs'] =>
    legs.map((singleLeg) => {
        const { event, price } = singleLeg;
        const defaultLeg = {
            betEventName: event?.name,
            price: price?.d,
        };
        switch (singleLeg.type) {
            case 'standard': {
                const { market, selection, sport } = singleLeg;
                return {
                    ...defaultLeg,
                    marketId: market?.id,
                    marketName: market?.name,
                    selectionId: selection?.id,
                    selectionName: selection?.name,
                    sportName: sport.name,
                    sportId: sport.id,
                };
            }
            case 'empty': {
                const { selection, sport } = singleLeg;
                return {
                    ...defaultLeg,
                    selectionName: selection?.name,
                    sportId: sport?.id,
                };
            }
            case 'rab': {
                const { sport, event } = singleLeg;
                const betEventId =
                    event.eventId !== null && event.eventId !== undefined ? event.eventId.toString() : undefined;
                return {
                    ...defaultLeg,
                    betEventId: betEventId,
                    sportName: sport !== null && sport !== undefined && 'name' in sport ? sport.name : undefined,
                    sportId: sport?.id,
                };
            }
        }
    });

type ParsedPlaceBetErrorsType =
    | {
          type: 'redirect';
      }
    | {
          type: 'set-bet-to-high-value';
          messageType: ErrorMsgType;
          maxStakePerLine: number | null;
      }
    | {
          type: 'message';
          messageType: ErrorMsgType;
      };

export const parsePlaceBetErrors = (
    response: ParsedPlaceBetType,
    isShowQuickBet: boolean
): ParsedPlaceBetErrorsType[] => {
    const errors = response.status === 'error' ? response.data.errors : undefined;
    if (errors === undefined || errors === null || errors.length === 0) {
        return [];
    }
    const errorCodes: ParsedPlaceBetErrorsType[] = [];
    const firstError = errors[0] ?? null;
    const firstErrorCode = firstError === null ? null : firstError.code;
    if (firstErrorCode === 'bet-stake-too-high' && isShowQuickBet) {
        errorCodes.push({
            type: 'redirect',
        });
    }
    for (const error of errors) {
        if (firstErrorCode === 'bet-stake-too-high') {
            if (error.code === 'bet-stake-too-high') {
                const betToHighError: ParsedPlaceBetErrorsType = {
                    type: 'set-bet-to-high-value',
                    maxStakePerLine: null,
                    messageType: 'BET-TOO-HIGH',
                };
                if (error.details !== null && typeof error.details === 'object' && 'maxStakePerLine' in error.details) {
                    const maxStakePerLine = error.details.maxStakePerLine;
                    betToHighError.maxStakePerLine = typeof maxStakePerLine === 'number' ? maxStakePerLine : null;
                    if (maxStakePerLine === 0) {
                        betToHighError.messageType = 'MAX-BET-EXCEEDED';
                    }
                }
                errorCodes.push(betToHighError);
            }
            if (error.code === 'bet-exceeds-max-payout') {
                errorCodes.push({
                    type: 'message',
                    messageType: 'PAYOUT-TOO-HIGH',
                });
            }
        }
        if (error.code === 'minimum') {
            errorCodes.push({
                type: 'message',
                messageType: 'BET-TOO-LOW',
            });
        }
    }
    for (const errorKey in errors) {
        if (errorKey === 'potentialPayout') {
            errorCodes.push({
                type: 'message',
                messageType: 'PAYOUT-TOO-HIGH',
            });
        }
    }
    return errorCodes;
};
