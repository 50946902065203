import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, CasinoGameModelExt, GameWrapperType } from 'src/domains/casino/state/AppCasino.state';
import { GameImgWrapperLazy, GameWrapper } from './SingleGameExt.style';
import { JackpotBar } from './jackpot-bar/JackpotBar';
import { RealTimeLobby } from './real-time-lobby/RealTimeLobby';
import { ThumbnailOverlay } from './thumbnail-overlay/ThumbnailOverlay';

const getImageForWrapper = (
    gameModel: CasinoGameModelExt,
    wrapperType: GameWrapperType,
    isFirstTile: boolean | undefined
): string => {
    if (wrapperType === 'slider-grid' && isFirstTile === true) {
        return gameModel.imageSquare;
    }

    if (wrapperType === 'numeric-trending') {
        return gameModel.imageVertical;
    }

    return gameModel.imageHorizontal;
};
interface PropsType {
    appCasinoState: AppCasinoState;
    gameModel: CasinoGameModelExt;
    wrapperType?: GameWrapperType;
    showModalOnMobile?: boolean;
    showLikeAndInfo?: boolean;
    showRealTimeLobby?: boolean;
    isFirstTile?: boolean;
    showJackpotBar?: boolean;
}

export const SingleGameExt = observer('SingleGameExt', (props: PropsType) => {
    const { appCasinoState, gameModel, isFirstTile } = props;
    const config = appCasinoState.features.config;

    const thumbnailConfigSettings = config.gameThumbnailSettings[gameModel.collection];
    const wrapperType = props.wrapperType ?? thumbnailConfigSettings.wrapperType;
    const showModalOnMobile = props.showModalOnMobile ?? thumbnailConfigSettings.showModalOnMobile;
    const showLikeAndInfo = props.showLikeAndInfo ?? thumbnailConfigSettings.showLikeAndInfo;
    const showRealTimeLobby = props.showRealTimeLobby ?? thumbnailConfigSettings.showRealTimeLobby;
    const showJackpotBar = props.showJackpotBar ?? thumbnailConfigSettings.showJackpotBar;

    const imageToDisplay = getImageForWrapper(gameModel, wrapperType, isFirstTile);

    const showModal = (): void => {
        appCasinoState.casinoSidebarGameInfoState.showSidebar(gameModel.id);
        appCasinoState.redirectState.resetRealityCheckTime();
    };

    const handleOnMobileClick = (): void => {
        if (showModalOnMobile) {
            showModal();
        } else {
            gameModel.startGame();
        }
    };

    return (
        <GameWrapper wrapperType={wrapperType} title={gameModel.name} data-test-game-id={gameModel.id}>
            <GameImgWrapperLazy
                alt={gameModel.name}
                imgFit='fill'
                imgBase={{
                    src: imageToDisplay,
                    optimizeOptions: {
                        width: 340,
                        quality: 85,
                    },
                }}
                onClick={handleOnMobileClick}
                placeholder={true}
            />
            <JackpotBar
                appCasinoState={appCasinoState}
                gameModel={gameModel}
                isBigTile={isFirstTile ?? false}
                showJackpotBar={showJackpotBar}
            />
            <RealTimeLobby
                appCasinoState={appCasinoState}
                gameModel={gameModel}
                showRealTimeLobby={showRealTimeLobby}
            />
            <ThumbnailOverlay
                appCasinoState={appCasinoState}
                gameModel={gameModel}
                showLikeAndInfo={showLikeAndInfo}
                handleOnMobileClick={handleOnMobileClick}
                handleInfoClick={showModal}
            />
        </GameWrapper>
    );
});
