import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAsObservableSource } from 'mobx-react-lite';
import { useAppStateContext } from 'src/appState/AppState';
import MeetingsRow from 'src/domains/sportsbook/webview/components/meetingsRow/MeetingsRow';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MeetingsListState, RowType } from './MeetingsList.state';
import {
    LoaderWrapperMeetings,
    MeetingRowName,
    MeetingRowNameChevron,
    MeetingsListInfo,
    MeetingsListStyle,
    MeetingsRowWrapper,
    StreamIco
} from './MeetingsList.style';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { HorseRacingSelectedNew } from 'src/domains/sportsbook/webview/components/racing/NewRacingPage.state';

export interface MeetingsListPropsTypes {
    events: Array<EventModel> | null;
    className?: string;
    isBuilding: boolean;
    selected: HorseRacingSelectedNew;
    isOpen?: boolean;
    isLoading: boolean;
    onChoose?: (eventId: EventId) => void;
}

export const MeetingsList = observer('MeetingsList', (propsIn: MeetingsListPropsTypes) => {
    const props = useAsObservableSource(propsIn);
    const [state] = useState(() => new MeetingsListState(props));

    const { appSportsBookState, appLayoutsState } = useAppStateContext();
    const { breakpointsState, configComponents } = appLayoutsState;
    const { config } = configComponents;

    const isTabletOrBigger = breakpointsState.tablet.isBiggerOrEq;
    const isDarkUniverse = configComponents.config.isDarkUniverse;

    const renderContent = (): JSX.Element | Array<JSX.Element | null> => {
        const { selected, isBuilding } = props;
        let content;

        if (props.events === null || props.isLoading) {
            content = (
                <MeetingsListInfo>
                    <LoaderWrapperMeetings
                        size={14}
                        version={isDarkUniverse ? 'light' : 'dark'}
                    >
                        <I18n langKey='events.meetings-list.loading' defaultText='Loading races...' />
                    </LoaderWrapperMeetings>
                </MeetingsListInfo>
            );
        } else if (props.events.length === 0) {
            content = (
                <MeetingsListInfo>
                    <I18n langKey='events.meetings-list.empty' defaultText='There are no races available at the moment. Please check again later.' />
                </MeetingsListInfo>
            );
        } else {
            content = state.totalEventsByRow
                .map((row: RowType) => {
                    const competitionModel = row.competitionId.getModel();

                    let hasAnyStream = false;
                    row.events.forEach((event) => {
                        if (config.hasEventLiveStreaming && appSportsBookState.streamingState.hasStream(event.id)) {
                            hasAnyStream = true;
                        }
                    });

                    if (row.events.length === 0) {
                        return null;
                    }

                    const { isOpened } = state.isRowOpen.get(row.competitionId);

                    if (isTabletOrBigger === true) {
                        return (
                            <MeetingsRowWrapper key={row.competitionId.toOldId()}>
                                <MeetingRowName>
                                    {competitionModel === null ? '' : competitionModel.name}
                                    {config.hasEventLiveStreaming && hasAnyStream ? <StreamIco /> : null}
                                </MeetingRowName>
                                <MeetingsRow
                                    key={`${row.competitionId.toOldId()}-rowId`}
                                    events={row.events.map((item) => item.id2)}
                                    selected={selected}
                                    isBuilding={isBuilding}
                                    onChoose={props.onChoose}
                                />
                            </MeetingsRowWrapper>
                        );
                    }
                    return (
                        <MeetingsRowWrapper key={row.competitionId.toOldId()}>
                            <MeetingRowName onClick={(): void => state.handleClickRow(row.competitionId)}>
                                <MeetingRowNameChevron position={isOpened ? 'down' : 'right'} />
                                {competitionModel === null ? '' : competitionModel.name}
                                {config.hasEventLiveStreaming && hasAnyStream ? <StreamIco /> : null}
                            </MeetingRowName>
                            <MeetingsRow
                                key={`${row.competitionId.toOldId()}-rowId`}
                                events={row.events.map((item) => item.id2)}
                                selected={selected}
                                isBuilding={isBuilding}
                                isOpen={isOpened}
                                onChoose={props.onChoose}
                            />
                        </MeetingsRowWrapper>
                    );
                });
        }
        return content;
    };

    const isOpen = props.isOpen === undefined ? true : props.isOpen;

    return (
        <MeetingsListStyle isOpen={isOpen}>
            {renderContent()}
        </MeetingsListStyle>
    );
});
