import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface IconPropsType {
    className?: string;
    onClick?: () => void;
}

export const ItalianFlagIcon = observer(
    'ItalianFlagIcon',
    ({ className, onClick }: IconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='none'
                onClick={onClick}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g clipPath='url(#clip0_907_67)'>
                    <rect width='24' height='24' rx='12' fill='white' />
                    <path fillRule='evenodd' clipRule='evenodd' d='M-4.79999 0H6.40001V24H-4.79999V0Z' fill='#249F58' />
                    <path fillRule='evenodd' clipRule='evenodd' d='M17.6 0H28.8V24H17.6V0Z' fill='#F93939' />
                </g>
                <defs>
                    <clipPath id='clip0_907_67'>
                        <rect width='24' height='24' rx='12' fill='white' />
                    </clipPath>
                </defs>
            </SvgElement>
        );
    }
);
