import React from 'react';

import { EventSummary } from 'src/domains/sportsbook/webview/components/eventSummary/EventSummary';
import { observer } from 'src/utils/mobx-react';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsTypes { 
    id: EventId;
    filterMarketsByPopularTag: boolean;
}

export const EventSummaryConnect = observer('EventSummaryConnect', (props: PropsTypes) => {
    const eventId = props.id;

    const { filterMarketsByPopularTag } = props;

    return (
        <EventSummary
            eventId={eventId}
            filterMarketsByPopularTag={filterMarketsByPopularTag}
        />
    );
});
