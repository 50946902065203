import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const BetslipActualScore = withConfig(theme => styled('span', { label: 'BetslipActualScore' })`
    align-items: center;
    align-self: flex-start;
    background-color: ${theme.star.eventRow.bgColorTertiary};
    color: ${theme.star.eventRow.txtColorTertiary};
    display: inline-flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    height: 20px;
    justify-content: center;
    line-height: 1.3334;
    margin: 0 4px;
    padding: 2px 4px;
    white-space: nowrap;
`);
