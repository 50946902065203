import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MatchLead } from 'src/domains/sportsbook/webview/components/matchLead/MatchLead';
import { useAppStateContext } from 'src/appState/AppState';
import { MatchLeadNBA } from 'src/domains/sportsbook/webview/components/matchLead/matchLeadNBA/MatchLeadNBA';
import { MatchLeadMLB } from 'src/domains/sportsbook/webview/components/matchLead/matchLeadMLB/MatchLeadMLB';
// import { MatchLeadNHL } from 'src/domains/sportsbook/webview/components/matchLead/matchLeadNHL/MatchLeadNHL';

interface PropsType {
    event: EventModel | null;
}

export const MatchLeadWrapper = observer<PropsType>('MatchLeadWrapper', ({ event }) => {
    const { appLayoutsState } = useAppStateContext();
    const { scoreboard, liveTrackerForFootballOutrights } = appLayoutsState.configComponents.config;

    if (event === null) {
        return null;
    }

    const competition = event.competition2.getModel();

    if (
        liveTrackerForFootballOutrights === false &&
        event.template === 'outrights' &&
        competition?.getData().sport === 'football'
    ) {
        return null;
    }
    if (event.template.startsWith('two-participant')) {
        if (competition?.name.includes('Major League Baseball') === true) {
            return (
                <MatchLeadMLB
                    key={event.id}
                    event={event}
                />
            );
        }

        if (competition?.name.split(' ').includes('NHL') === true) {
            return null; // temporary hide until new implementation - LT-1795
            // return <MatchLeadNHL key={event.id} event={event} />;
        }

        if (competition?.name.split(' ').includes('NBA') === true) {
            if (scoreboard.liveTracker === null) {
                return (
                    <MatchLeadNBA
                        key={event.id}
                        event={event}
                    />
                );
            }

            for (const item of scoreboard.liveTracker) {
                if (item === 'basketball') {
                    return <MatchLead event={event} />;
                }
            }

            return (
                <MatchLeadNBA
                    key={event.id}
                    event={event}
                />
            );
        }
    }

    return <MatchLead event={event} />;
});
