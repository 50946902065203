import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { IssueWithdrawContentContainer, IssueWithdrawInfo, IssueWithdrawTitle } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue.style';
import { CheckMarkIconWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess.style';

const WithdrawalPending = (
    <>
        <CheckMarkIconWrapper />

        <IssueWithdrawContentContainer>
            <IssueWithdrawTitle>
                <I18n langKey='account.withdraw.pending.title' defaultText='Withdrawal Pending' />
            </IssueWithdrawTitle>
            <IssueWithdrawInfo>
                <I18n langKey='account.withdraw.pending.info' defaultText="We've received your withdrawal request. Your account will be updated as soon as your withdrawal has been successfully processed." />
            </IssueWithdrawInfo>
            <IssueWithdrawInfo>
                <I18n langKey='account.withdraw.pending.info-time' defaultText='Withdrawals can take up to 3-5 working days' />
            </IssueWithdrawInfo>
        </IssueWithdrawContentContainer>
    </>
);

export const WithdrawSuccess = observer('WithdrawSuccess', () => {
    return WithdrawalPending;
});
