import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    ChatIconWrapper,
    LinkName,
    QuickHeaderButton,
    UnreadCount,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

export const ChatLink = observer('ChatLink', () => {
    const common = useCommon();
    const { appPlayersState } = useAppStateContext();
    const config = ConfigComponents.get(common).config;
    const { traderChat } = config;
    const starRouter = StarRouter.get(common);

    const unreadMessagesCount = appPlayersState.traderChatState.unreadMessagesCount;
    const unreadCounter = unreadMessagesCount > 0 ? <UnreadCount>{unreadMessagesCount}</UnreadCount> : null;

    if (traderChat.isBubbleChatButton) {
        return null;
    }

    return (
        <QuickHeaderButton
            data-test='account-navigation-chat-link'
            isActive={false}
            onClick={starRouter.redirectToTraderChat}
        >
            <ChatIconWrapper />
            <LinkName>
                <I18n
                    langKey='quick-header.live-chat.label'
                    defaultText='Chat'
                />
            </LinkName>
            {unreadCounter}
        </QuickHeaderButton>
    );
});
