import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const Wrapper = styled('div', { label: 'Wrapper' })`
    padding: 0 10px;
    display: flex;
`;

export const NameContainer = styled('div', { label: 'NameContainer' })`
    display: flex;
    flex-flow: column nowrap;
    width: 144px;
`;

export const Label = withConfig(theme => styled('span', { label: 'Label' })`
    align-self: flex-start;
    background: ${theme.star.eventsGroupedFiltersColors.bgColor};
    border-radius: 3px;
    color: ${theme.star.eventsGroupedFiltersColors.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 2.6667;
    margin-bottom: 8px;
    padding: 0 5px 0 9px;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.base};
    &::before {
        background-color: ${theme.star.eventsGroupedFiltersColors.bgColor};
        border-radius: 3px;
        bottom: 0;
        content: '';
        height: 24px;
        margin: auto 0;
        position: absolute;
        right: -11px;
        top: 0;
        transform: rotate(45deg);
        width: 24px;
        z-index: ${theme.star.zIndexGlobal.below};
    }
`);

export const Right = styled('div', { label: 'Right' })`
    flex: 1 0 0%;
    overflow: hidden;
`;

export const FilterRowWrapper = styled('div', { label: 'FilterRowWrapper' })`
    margin-bottom: 8px;
`;
