import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    AccountNavLink,
    AccountNavText,
} from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { UserIconWrapper } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/accountLinkNav/AccountLinkNav.style';

export const LogInLinkNav = observer('LogInLinkNav', () => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const language = languagesState.userLang ?? 'en';

    return (
        <AccountNavLink
            dataTest='account-navigation-login'
            to={{ account: 'login', promo: null }}
            routeNameForGtm='login'
        >
            <UserIconWrapper />
            <AccountNavText lang={language}>
                <I18n
                    langKey='account.login'
                    defaultText='Login'
                />
            </AccountNavText>
        </AccountNavLink>
    );
});
