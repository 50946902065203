import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { HeaderMain } from './HeaderWrapperList.style';
import { useAppStateContext } from 'src/appState/AppState';

interface HeaderWrapperListPropsType {
    className?: string;
    children: React.ReactNode;
}

export const HeaderWrapperList = observer('HeaderWrapperList', (props: HeaderWrapperListPropsType) => {
    const { className, children } = props;
    const { appLayoutsState, appSportsBookState } = useAppStateContext();

    const { streamingState } = appSportsBookState;
    const streamBoxHeight = streamingState.streamBoxHeight;
    const streamFloating = streamingState.isStreamFloating;

    const { bannersBoxState, sdkCustomer } = appLayoutsState;
    const bannersHeight = bannersBoxState.boxHeight;
    const basicData = sdkCustomer.basicData.get();
    const isTartanClub = basicData.type === 'ready' ? basicData.value.tartanClub ?? false : false;

    return (
        <HeaderMain
            bannersHeight={bannersHeight}
            className={className}
            isFloating={streamFloating}
            isTartanClub={isTartanClub}
            streamBoxHeight={streamBoxHeight}
        >
            {children}
        </HeaderMain>
    );
});
