import styled from '@emotion/styled';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const InfoPageWrapper = styled('div', { label: 'InfoPageWrapper' })`
    animation: fadein 0.3s;
    margin: 0 16px 40px;
    padding-top: 80px;
    text-align: center;
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;

export const InfoIconBox = withConfig(theme => styled('div', { label: 'InfoIconBox' })`
    background: ${theme.star.topUp.bgColor};
    border-radius: 50%;
    display: inline-flex;
    margin-bottom: 32px;
    padding: 24px;
`);

export const IconInfo = styled(InfoIcon, { label: 'IconInfo' })`
    min-width: 15px;
    min-height: 15px;
`;

export const InformationParagraph = withConfig(theme => styled('p', { label: 'InformationParagraph' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.43;
    max-width: 290px;
    margin: 8px auto 0;
`);

export const LinkWrapper = withConfig(theme => styled(Link, { label: 'LinkWrapper' })`
    color: currentcolor;
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
    &:hover {
        color: ${theme.star.accountDrawer.txtColorSecondary};
    }
`);
