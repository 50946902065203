import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { CarouselMainState } from './homePageCarouselState/CarouselMainState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

export class CmsState {
    public readonly carouselMainState: CarouselMainState;

    public constructor(
        models: ModelsState,
        languagesState: LanguagesState,
        configComponents: ConfigComponents,
        trpcClient: TrpcClient
    ) {
        this.carouselMainState = new CarouselMainState(models, languagesState, configComponents, trpcClient);
    }
}
