import React from 'react';
import {
    SwitchView,
    CloseStream,
    StreamLoader,
    StreamButtonsWrapperATR,
    RacingStreamWrapperATR,
    StreamWrapperATR,
} from 'src/domains/sportsbook/webview/components/stream/RacingStream.style';
import { useAppStateContext } from 'src/appState/AppState';
import ReactPlayer from 'react-player';
import { Spinner } from 'src/domains/layouts/shared/assets/icons/Spinner';
import { observer } from 'src/utils/mobx-react';

export interface RacingStreamPropsTypes {
    isNotificationsList: boolean;
}

export const RacingStream = observer('RacingStream', ({ isNotificationsList }: RacingStreamPropsTypes) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { bannersBoxState, notificationsState } = appLayoutsState;
    const { streamingState } = appSportsBookState;

    if (streamingState.openEventId === null) {
        return null;
    }

    const streamEvent = streamingState.getModel(streamingState.openEventId);

    const providerName = streamingState.providerName;
    const streamUrl = streamEvent.streamUrl;
    const isOpen = streamingState.isStreamOpen;
    const boxHeight = streamingState.boxHeightHeightRmgRestrictions;

    const getStreamUrl = streamUrl?.type === 'ready' ? streamUrl.url : '';
    if (getStreamUrl === null) {
        return null;
    }

    if (streamUrl?.type === 'loading') {
        return (
            <StreamLoader>
                <Spinner />
            </StreamLoader>
        );
    }

    const renderButtons = (
        <StreamButtonsWrapperATR isFloating={streamingState.isStreamFloating} isOpen={isOpen} provider={providerName}>
            <SwitchView
                position='down'
                isFloating={streamingState.isStreamFloating}
                isOpen={isOpen}
                onClick={streamingState.switchFloatingStream}
                provider={providerName}
            />
            <CloseStream
                isFloating={streamingState.isStreamFloating}
                isOpen={isOpen}
                onClick={streamingState.closeStream}
                provider={providerName}
            />
        </StreamButtonsWrapperATR>
    );

    return (
        <RacingStreamWrapperATR isFloating={streamingState.isStreamFloating} isOpen={isOpen}>
            {streamingState.isStreamFloating ? '' : renderButtons}

            <StreamWrapperATR
                // isNotificationAvailable={notificationsState.notifications}
                isNotificationAvailable={isNotificationsList === true ? notificationsState.notifications : []}
                bannersHeight={bannersBoxState.boxHeight}
                height={boxHeight}
                isOpen={isOpen}
                isFloating={streamingState.isStreamFloating}
                ref={streamingState.setIframeRef}
            >
                {streamingState.isStreamFloating ? renderButtons : ''}

                <ReactPlayer
                    style={{ display: 'flex' }}
                    url={getStreamUrl}
                    width='100%'
                    height='100%'
                    playsinline={true}
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nofullscreen',
                            },
                        },
                    }}
                />
            </StreamWrapperATR>
        </RacingStreamWrapperATR>
    );
});
