import React, { ReactElement, useState } from 'react';
import {
    BingoIcon,
    BingoMiniGamesContainer,
    BodyContainer,
    CloseButton,
    ExpandContainer,
    GameImage,
    GameTile,
    GamesGridContainer,
    HeaderArrow,
    HeaderContainer,
    HeaderTitle,
} from './BingoMiniGames.style';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { BingoMiniGameModal } from './bingo-mini-game-modal/BingoMiniGameModal';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface BingoMiniGamesPropsType {
    appCasinoState: AppCasinoState;
}

export const BingoMiniGames = observer('BingoMiniGames', (props: BingoMiniGamesPropsType): ReactElement | null => {
    const { appCasinoState } = props;
    const { bingoMiniGameModel } = appCasinoState.gameModalState;
    const [extended, setExtended] = useState(false);
    const isModalEnabled = bingoMiniGameModel !== null;

    if (appCasinoState.miniGamesListState.casinoMiniGamesList.length === 0) {
        return null;
    }

    return (
        <BingoMiniGamesContainer extended={extended}>
            <HeaderContainer>
                <ExpandContainer onClick={(): void => setExtended(!extended)}>
                    <BingoIcon />
                    <HeaderTitle>
                        <I18n
                            langKey='casino.bingo-mini-header.placeholder'
                            defaultText='Mini games'
                        />
                    </HeaderTitle>
                    <HeaderArrow extended={extended} />
                </ExpandContainer>
                <CloseButton
                    isModalEnabled={isModalEnabled}
                    onClick={appCasinoState.gameModalState.closeBingoMiniGame}
                />
            </HeaderContainer>
            <BingoMiniGameModal
                appCasinoState={appCasinoState}
                gameDataState={appCasinoState.gameModalState.bingoMiniGameModel}
            />
            <BodyContainer isModalEnabled={isModalEnabled}>
                <GamesGridContainer>
                    {appCasinoState.miniGamesListState.casinoMiniGamesList.map((game) => (
                        <GameTile
                            key={game.id}
                            onClick={(): void => game.startGame({ mode: 'bingo-mini' })}
                        >
                            <GameImage
                                alt={game.name}
                                imgBase={{
                                    src: game.imageHorizontal,
                                    optimizeOptions: {
                                        width: 270,
                                        quality: 75,
                                    },
                                }}
                                placeholder={true}
                            />
                        </GameTile>
                    ))}
                </GamesGridContainer>
            </BodyContainer>
        </BingoMiniGamesContainer>
    );
});
