//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    collection_slug: string,
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/anonymous/casino/banners/{collection_slug} -> get -> ${code}`);
    };
};

const Response200IO = t.interface({
    banners: t.array(t.interface({
        date_end: t.string,
        date_start: t.string,
        id: t.number,
        image_desktop: t.string,
        image_mobile: t.string,
        image_tablet: t.string,
        published: t.boolean,
        redirect_game: t.union([t.null, t.undefined, t.number]),
        redirect_type: t.union([t.null, t.undefined, t.string]),
        redirect_url: t.union([t.null, t.undefined, t.string]),
        terms_full: t.string,
        terms_headline: t.string,
        title: t.string,
        universe: t.string,
    })),
});
export type Response200Type = {
    banners: Array<{
        date_end: string,
        date_start: string,
        id: number,
        image_desktop: string,
        image_mobile: string,
        image_tablet: string,
        published: boolean,
        redirect_game?: null | undefined | number,
        redirect_type?: null | undefined | string,
        redirect_url?: null | undefined | string,
        terms_full: string,
        terms_headline: string,
        title: string,
        universe: string,
    }>,
};
export const decodeResponse200 = createGuard(200, Response200IO);

const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiCmsAnonymousGetBannersRequest"
 */
export const openapi_cms_anonymous_get_banners = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/anonymous/casino/banners/${params.collection_slug}${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiCmsAnonymousGetBannersParamsType = ParamsType;

export type OpenapiCmsAnonymousGetBannersResponseType = {
    status: 200,
    body: Response200Type,
} | {
    status: 400,
    body: Response400Type,
} | {
    status: 401,
    body: Response401Type,
} | {
    status: 500,
    body: Response500Type,
};

export type OpenapiCmsAnonymousGetBannersResponse200Type = Response200Type;

export const openapiCmsAnonymousGetBannersRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiCmsAnonymousGetBannersResponseType> => {
    const response = await openapi_cms_anonymous_get_banners(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        throw Error(`Http status ${status} - json was expected`);
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiCmsAnonymousGetBannersRequest - unhandled response ${response.status}`);
};

