import { CountriesType, countries, countryName } from 'src/domains/layouts/config/countries';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { DateInputState } from 'src/domains/players/webview/components/form/DateInput';
import {
    GenderTitleState,
    GenderTitleType,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/titleSection/TitleSection.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { observable, action, makeObservable } from 'mobx';
import { OffersContactPreferencesState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/offersSettings/OffersSettingsState';
import { PhoneNumberState } from 'src/domains/players/webview/components/SignUp/signupParts/phoneNumber/PhoneNumber.state';
import { SelectState } from 'src/domains/players/webview/components/form/select/Select.state';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import {
    validateCity,
    validateFirstName,
    validateLastName,
    validateRequire,
    validateSpaces,
    validatePostCode,
    validateRequireIfWasTouched,
    validateAddress,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { DateTime } from 'src_common/utils/time/time';
import { GeolocalizationState } from 'src/domains/layouts/state/geolocalizationState/GeolocalizationState';
import { createAccountPrimary } from 'src_server/trpc/types';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';
import { TermsAndConditionsCheckboxState } from 'src/domains/players/webview/components/SignUp/signupParts/termsAndConditions/TermsAndConditionsCheckbox.state';
import { TrpcClient } from 'src/appState/TrpcClient';
import { findAddress, findAddressDetails } from 'src_server/trpc/types/accountModel';

const excludeCountries = (array: CountriesType, excludeList: Array<string>): CountriesType => {
    return array.filter((country) => !excludeList.includes(country.id));
};

export interface YourDetailsFormType {
    firstName: string;
    lastName: string;
    postcode: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    currency: string;
    contactPreferences: createAccountPrimary.CreateAccountInput['contactPreferences'];
    dateOfBirth: DateTime;
    prefix: string;
    phoneNumber: string;
}

interface AddressFormGroupType {
    postcode: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
}

export interface GenderFormModelType {
    genderTitle: GenderTitleType;
}
export class YourDetailsState {
    @observable public isShownFullAddressForm: boolean = false;
    @observable public loadingAddresses: boolean = false;
    @observable public loadingCreateAccountReq: boolean = false;
    @observable.ref public addresses: findAddress.SuccessResponse = [];
    @observable public wasTouched: boolean | null = null;
    @observable public validateOnSubmit: boolean = false;
    @observable public myAddressError: string | null = null;

    public termsAndConditionsCheckboxState: TermsAndConditionsCheckboxState;
    public readonly firstNameState: FormInputState<string, string>;
    public readonly lastNameState: FormInputState<string, string>;
    public readonly postcodeState: FormInputState<string, string>;
    public readonly addressLineOneState: FormInputState<string, string>;
    public readonly addressLineTwoState: FormInputState<string, string>;
    public readonly cityState: FormInputState<string, string>;
    public readonly country: SelectState;
    public readonly currency: SelectState;
    public readonly addressFormGroup: FormModel<AddressFormGroupType>;
    public readonly contactPreferences: OffersContactPreferencesState;
    public readonly dateOfBirth: DateInputState;
    public readonly genderTitle: GenderTitleState;
    public readonly phoneNumber: PhoneNumberState;

    public readonly yourDetailsFormModel: FormModel<YourDetailsFormType>;
    public readonly genderTitleForModel: FormModel<GenderFormModelType>;

    public constructor(
        public readonly customKeyboard: CustomKeyboardState,
        public readonly language: LanguagesState,
        private readonly config: ConfigComponents,
        private readonly geolocalization: GeolocalizationState,
        private readonly trpc: TrpcClient
    ) {
        makeObservable(this);
        this.firstNameState = FormInputState.new('').map(validateRequire).map(validateFirstName);
        this.lastNameState = FormInputState.new('').map(validateRequire).map(validateLastName);

        this.postcodeState = FormInputState.new('').map(validatePostCode(this.language.getTranslation));

        this.addressLineOneState = FormInputState.new('').map(validateRequire).map(validateAddress);
        this.addressLineTwoState = FormInputState.new('').map(validateAddress);
        this.cityState = FormInputState.new('').map(validateRequire).map(validateCity).map(validateSpaces);

        const defaultCountryConfig = this.config.config.countrySignUpDefault;
        const geolocalizationCountry = this.geolocalization.countryCode;

        const defaultCountryCode = geolocalizationCountry ?? defaultCountryConfig;

        const defaultCountryValue =
            defaultCountryCode === null ? undefined : { [defaultCountryCode]: countryName[defaultCountryCode] };

        this.country = new SelectState(defaultCountryValue);
        this.phoneNumber = new PhoneNumberState(this.language, this.config.config.prefixAndPhoneNumberDefault);

        if (config.config.zipCodeAndCountryCheck === true) {
            const validator = (value: string): Result<string> =>
                validateRequireIfWasTouched(value, this.wasTouched === true || this.validateOnSubmit);
            this.country.inputState = this.country.inputState.map(validator);
            this.phoneNumber.prefix.inputState = this.phoneNumber.prefix.inputState.map(validator);
        }

        const currencyGeolocalization = this.geolocalization.currencyCode;
        const defaultCurrencyValue =
            currencyGeolocalization === undefined ? this.config.config.currencyDefault : currencyGeolocalization;
        const defaultCurrency = this.config.config.currencyDefaultSelectedInSignup
            ? { currency: defaultCurrencyValue }
            : undefined;
        this.currency = new SelectState(defaultCurrency, true);
        this.contactPreferences = new OffersContactPreferencesState(this.config.config.contactPreferences);
        this.dateOfBirth = new DateInputState(this.customKeyboard);
        this.genderTitle = new GenderTitleState(this.config.config);
        this.termsAndConditionsCheckboxState = new TermsAndConditionsCheckboxState(this.config.config);

        this.yourDetailsFormModel = FormModel.group({
            firstName: this.firstNameState,
            lastName: this.lastNameState,
            postcode: this.postcodeState,
            addressLine1: this.addressLineOneState,
            addressLine2: this.addressLineTwoState,
            city: this.cityState,
            country: this.country.inputState,
            contactPreferences: this.contactPreferences.formatFormModel,
            dateOfBirth: this.dateOfBirth.dateModel,
            phoneNumber: this.phoneNumber.phoneNumber,
            prefix: this.phoneNumber.prefix.inputState,
            currency: this.currency.inputState,
        });
        this.genderTitleForModel = FormModel.group({
            genderTitle: this.genderTitle.titleChooseState,
        });

        this.addressFormGroup = FormModel.group({
            postcode: this.postcodeState,
            addressLine1: this.addressLineOneState,
            addressLine2: this.addressLineTwoState,
            city: this.cityState,
            country: this.country.inputState,
        });
    }

    @action public onSearchAddressHandler = async (): Promise<void> => {
        if (this.postcodeState.value.length > 0) {
            this.myAddressError = null;
            this.loadingAddresses = true;

            const listAllPromise = Promise.all([
                await this.trpc.client.accounts.findAddress.mutate({ Countries: 'GB', Text: this.postcodeState.value }),
                await this.trpc.client.accounts.findAddress.mutate({ Countries: 'IE', Text: this.postcodeState.value }),
            ]);

            const [addressesResponseGb, addressesResponseIe] = await listAllPromise;
            let allAddresses: findAddress.SuccessResponse = [];
            if (addressesResponseGb.responseStatus === 'success' && addressesResponseIe.responseStatus === 'success') {
                const addressesResponseGbData = addressesResponseGb.data;
                const addressesResponseIeData = addressesResponseIe.data;
                allAddresses = [...addressesResponseGbData, ...addressesResponseIeData];
            }
            if (addressesResponseGb.responseStatus === 'error' && addressesResponseIe.responseStatus === 'error') {
                this.loadingAddresses = false;
                this.myAddressError = 'Error, enter address manually';
            }

            if (allAddresses.length > 0) {
                this.addresses = allAddresses;
            }

            this.loadingAddresses = false;
        }
    };

    @action public showFullAddressFormHandler = (): void => {
        this.isShownFullAddressForm = true;
        this.myAddressError = null;
        this.clearAddresses();
    };

    @action private clearAddresses = (): void => {
        this.addresses = [];
    };

    @action public setAddressFromPostcode = async (address: findAddress.AddressItem): Promise<void> => {
        this.showFullAddressFormHandler();
        const postCode = address.Id.split('|').pop();
        if (postCode === undefined) {
            return;
        }

        const addressDetailsResponse = await this.trpc.client.accounts.findAddressDetails.mutate({
            addressId: address.Id,
        });

        let firstOfResponse: findAddressDetails.AddressDetailsItem | undefined;

        if (addressDetailsResponse.responseStatus === 'success') {
            firstOfResponse = addressDetailsResponse.data[0];
        } else {
            this.myAddressError = 'Error, enter address manually';
        }

        if (firstOfResponse !== undefined) {
            const countryFromPostcode = Object.entries(countryName).filter(
                (country) => country[0] === firstOfResponse.CountryIso2
            )[0];
            if (countryFromPostcode === undefined) {
                return;
            }
            this.addressLineOneState.setValue(firstOfResponse.Line1);
            this.addressLineOneState.setAsVisited();
            this.addressLineTwoState.setValue(firstOfResponse.Line2);
            this.addressLineTwoState.setAsVisited();
            this.cityState.setValue(firstOfResponse.City);
            this.cityState.setAsVisited();
            this.postcodeState.setValue(firstOfResponse.PostalCode);
            this.postcodeState.setAsVisited();
            const firstOfPostcode = countryFromPostcode[0];
            const secondOfPostcode = countryFromPostcode[1];

            this.country.setCurrentValue({ [firstOfPostcode]: secondOfPostcode });

            if (this.country.inputState.value.toLocaleLowerCase() === 'ireland') {
                const defIrelandCurrency: CurrencyType = 'EUR';
                this.currency.setCurrentValue({ currency: defIrelandCurrency });
            }
        }
    };

    public clearAddressForm = (): void => {
        this.addressFormGroup.reset();
        this.isShownFullAddressForm = false;
    };

    public formattedCountries = (): Array<Record<string, string>> => {
        const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));
        const countriesAfterExclude = excludeCountries(sortedCountries, this.config.config.excludedCountriesFromSignUp);
        const sortedCountriesFormatted = countriesAfterExclude.map((country) => ({ [country.id]: country.name }));
        return sortedCountriesFormatted;
    };

    @action public handleClickOutside = (clickOutside: boolean): void => {
        if (clickOutside === true) {
            this.clearAddresses();
        }
    };

    @action public handleWasTouched = (): void => {
        this.wasTouched = true;
    };
}
