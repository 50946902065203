import { assertNever } from 'src_common/common/assertNever';

export type KeyboardButtonType =
    | {
          type: 'delete';
      }
    | {
          type: 'dot';
      }
    | {
          type: 'digital';
          keyCode: number;
      };

export const keyFromKeyboardButton = (value: KeyboardButtonType): string => {
    switch (value.type) {
        case 'digital': {
            return `digital-${value.keyCode}`;
        }
        case 'dot': {
            return 'dot';
        }
        case 'delete': {
            return 'delete';
        }
    }
    return assertNever('', value);
};

export const charFromKeyboardButton = (value: KeyboardButtonType): string => {
    switch (value.type) {
        case 'digital': {
            return value.keyCode.toString();
        }
        case 'dot': {
            return '.';
        }
        case 'delete': {
            return 'DEL';
        }
    }
    return assertNever('', value);
};

export const KEYBOARD_KEYS: Array<KeyboardButtonType> = [
    {
        type: 'digital',
        keyCode: 1,
    },
    {
        type: 'digital',
        keyCode: 2,
    },
    {
        type: 'digital',
        keyCode: 3,
    },
    {
        type: 'digital',
        keyCode: 4,
    },
    {
        type: 'digital',
        keyCode: 5,
    },
    {
        type: 'digital',
        keyCode: 6,
    },
    {
        type: 'digital',
        keyCode: 7,
    },
    {
        type: 'digital',
        keyCode: 8,
    },
    {
        type: 'digital',
        keyCode: 9,
    },
    {
        type: 'dot',
    },
    {
        type: 'digital',
        keyCode: 0,
    },
    {
        type: 'delete',
    },
];
