import { computed, action, observable, makeObservable } from 'mobx';
import { slug } from 'src/utils/deburr';
import { LocalStorageItemState } from './LocalStorageItemState';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { SportModel } from 'src_common/common/websocket2/models/SportModel/SportModel';
import { EventsCollectionList } from 'src/domains/sportsbook/shared/Types';
import { GameSharedModel } from 'src/domains/casino/shared/Types';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';

interface RecentlyVisitedItem {
    key: string;
    label: string;
    to: RouteViewType;
}

export type RecentlyVisitedLocalStorageObjectType = {
    type: 'event' | 'sport' | 'casino';
    id: string;
};

interface RecentlyVisitedLocalStorageCallbacksType {
    getGameById: (gameId: number) => GameSharedModel | null;
    getSport: (sportId: string) => SportModel | null;
    getSportsAndEventsListAllowed: () => Map<string, number>;
    listOfSport: (sportId: string) => EventsCollectionList;
}

export class RecentlyVisitedLocalStorage {
    private readonly translationsStore: TranslationsStore;
    private readonly configComponents: ConfigComponents;

    @observable public recentlyVisited: LocalStorageItemState<Array<RecentlyVisitedLocalStorageObjectType>>;

    public constructor(
        localStorageItem: LocalStorageItemState<Array<RecentlyVisitedLocalStorageObjectType>>,
        translationsStore: TranslationsStore,
        configComponents: ConfigComponents,
        private readonly models: ModelsState,
        private readonly callbacks: RecentlyVisitedLocalStorageCallbacksType
    ) {
        makeObservable(this);
        this.recentlyVisited = localStorageItem;
        this.translationsStore = translationsStore;
        this.configComponents = configComponents;
    }

    private pushNewItem(type: 'event' | 'sport' | 'casino', id: string): void {
        const currentList = this.recentlyVisited.getValue();
        const currentListFiltered = currentList.filter(value => {
            const isEq = value.type === type && value.id === id;
            return !isEq;
        });

        currentListFiltered.push({
            type,
            id
        });

        this.recentlyVisited.setValue(currentListFiltered);
    }

    @action public setItem = (route: RouteViewType): void => {
        if (route.name === 'sport') {
            this.pushNewItem('sport', route.id);
        }

        if (route.name === 'casino') {
            //TODO - This part didn't work. To be verified with PO casino what about this code
            // if (route.tabId !== null) {
            //     this.pushNewItem('casino', route.tabId);
            // }
        }
    
        if (route.name === 'event') {
            this.pushNewItem('event', route.id.toString());
        }

        // filter localStorage and remove no active events
        this.recentlyVisited.setValue(this.recentlyVisited.getValue().filter(value => {
            if (value.type === 'event') {
                const eventIdNumber = parseInt(value.id, 10);
                const eventId = this.models.id.getEventId(eventIdNumber);

                const event = eventId.getEventModel();
                if (event !== null && event.active && event.display) {
                    return true;
                } else {
                    return false;
                }
            }

            if (route.name === 'sport' || route.name === 'casino') {
                return true;
            }

            return true;
        }));


        if (this.recentlyVisited.getValue().length > 10) {
            this.recentlyVisited.getValue().splice(0, this.recentlyVisited.getValue().length - 10);
        }
    };

    private linkParams({ type, id }: RecentlyVisitedLocalStorageObjectType): RecentlyVisitedItem | null {
        switch (type) {
            case 'sport': {
                const sportModel = this.callbacks.getSport(id);
                const eventsInSport = this.callbacks.listOfSport(id)?.ids.length ?? 0;

                if (sportModel !== null || eventsInSport !== 0) {
                    return {
                        key: `recently-sport-${id}`,
                        label: this.translationsStore.translateSport(id),
                        to: {
                            name: 'sport',
                            nameType: 'regular',
                            id,
                        }
                    };
                }
                return null;
            }
            case 'casino': {
                const gameId = parseInt(id);
                const game = this.callbacks.getGameById(gameId);

                if (game !== null) {
                    return {
                        key: `recently-casino-${id}`,
                        label: game.name,
                        to: {
                            name: 'casino',
                            tabId: id,
                        }
                    };
                }
                return null;
            }
            case 'event': {
                const eventIdNumber = parseInt(id, 10);

                if (isNaN(eventIdNumber)) {
                    return null;
                }

                const eventId = this.models.id.getEventId(eventIdNumber);

                const event = eventId.getEventModel();

                if (event !== null && event.active && event.display) {
                    return {
                        key: `recently-event-${id}`,
                        label: event.name,
                        to: {
                            name: 'event',
                            id: event.id,
                            slug: slug(event.name)
                        }
                    };
                }

                return null;
            }
            default:
                return null;
        }
    }

    @computed.struct public get mapRecentlyVisitedToLinks(): Array<RecentlyVisitedItem> {
        const out: Array<RecentlyVisitedItem> = [];
        const { hiddenSportsList } = this.configComponents.config;

        for (const recentlyVisited of this.recentlyVisited.getValue()) {
            if (recentlyVisited.type === 'sport' && hiddenSportsList.includes(recentlyVisited.id)) {
                continue;
            }

            const link = this.linkParams(recentlyVisited);

            const sportsAndEventsListAllowed = this.callbacks.getSportsAndEventsListAllowed();

            if (link !== null && sportsAndEventsListAllowed.has(recentlyVisited.id)) {
                out.push(link);
            }
        }
        return out.reverse();
    }
}
