import React, { useState } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { WithdrawProcedure } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawProcedure';
import { WithdrawFormState } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/WithdrawFormState';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';
import { PendingWithdrawals, WithdrawProcedureWrapper } from 'src/domains/players/webview/components/Account';
import { WithdrawSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export type WithdrawStepsType =
    | {
          type: 'set-card';
      }
    | {
          type: 'failure-view';
          readonly failureType: WithdrawFailuresType;
      }
    | {
          type: 'withdraw-pending';
      };

export type WithdrawFailuresType = 'serverIssue' | 'noPaymentMethods';

const defaultWithdrawStep: WithdrawStepsType = {
    type: 'set-card',
};

export class WithdrawSteps {
    @observable.ref public step: WithdrawStepsType;

    public constructor(public initialStep?: WithdrawStepsType) {
        makeObservable(this);
        this.step = initialStep ?? defaultWithdrawStep;
    }

    @action public redirectToSetCard = (): void => {
        this.step = {
            type: 'set-card',
        };
    };

    @action public redirectToFailureView = (failureType: WithdrawFailuresType): void => {
        this.step = {
            type: 'failure-view',
            failureType,
        };
    };

    @action public redirectToPending = (): void => {
        this.step = {
            type: 'withdraw-pending',
        };
    };
}

export const WithdrawJourney = observer('WithdrawJourney', (): JSX.Element => {
    const { appLayoutsState, appPlayersState, trpcClient } = useAppStateContext();
    const {
        googleTagManager,
        languagesState,
        configComponents: {
            config: { minWithdrawAmount, usePaymentFormForUniverse },
            precision,
        },
    } = appLayoutsState;
    const { usersState, withdrawalsListState } = appPlayersState;

    const [paymentMethodDropdownState] = useState(
        () => new PaymentMethodDropdownState(trpcClient, usePaymentFormForUniverse)
    );
    const [state] = React.useState(
        () =>
            new WithdrawFormState(
                usersState,
                languagesState,
                withdrawalsListState,
                googleTagManager,
                paymentMethodDropdownState,
                minWithdrawAmount,
                precision
            )
    );

    switch (state.currentStep.type) {
        case 'set-card':
            return (
                <>
                    <Messages
                        hasIcon={true}
                        type='info'
                        message={
                            <I18n
                                langKey='payment-method-info.label'
                                defaultText='From 14/04/2020 Credit Cards cannot be used to withdraw funds, please use Debit Cards.'
                            />
                        }
                    />
                    <WithdrawProcedureWrapper data-test='withdraw-procedure-wrapper'>
                        <WithdrawProcedure
                            state={state}
                            paymentMethodDropdownState={paymentMethodDropdownState}
                        />
                    </WithdrawProcedureWrapper>
                    <PendingWithdrawals />
                </>
            );
        case 'failure-view':
            return <WithdrawIssue failureType={state.currentStep.failureType} />;
        case 'withdraw-pending':
            return <WithdrawSuccess />;
    }
});
