import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    HamburgerLabel,
    LinkHamburgerMenu,
    HamburgerMenuIcon
} from './HamburgerMenu.style';

export const HamburgerMenu = observer('HamburgerMenu', () => {
    return (
        <LinkHamburgerMenu
            dataTest='mobile-hamburger-menu'
            to={{ account: 'navigation' }}
        >
            <HamburgerMenuIcon />
            <HamburgerLabel>
                <I18n langKey='common.hamburger-menu.label' defaultText='Hamburger Menu' />
            </HamburgerLabel>
        </LinkHamburgerMenu>
    );
});
