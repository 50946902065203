import { css, keyframes, SerializedStyles } from '@emotion/react';

import { LoadingSpinnerIcon } from 'src/domains/layouts/shared/assets/icons/LoadingSpinnerIcon';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



const circleRotate = keyframes`

    0% {

        transform: rotate(0);

    }

    100% {

        transform: rotate(360deg);

    }

`;



const styleLoader = (theme: EmotionTheme, version?: 'light' | 'dark'): SerializedStyles => {

    if (version === 'light') {

        return css`

            fill: ${theme.star.loader.bgColorSecondary};

        `;

    }

    if (version === 'dark') {

        return css`

            fill: ${theme.star.loader.bgColor};

        `;

    }



    return css`

        fill: currentcolor;

    `;

};



interface LoaderPropsType {

    version?: 'light' | 'dark';

    size?: number;

}



export const LoaderSpinner = withConfig(theme => styled(LoadingSpinnerIcon, { label: 'LoaderSpinner' })<LoaderPropsType>`

    ${({ version }): SerializedStyles => styleLoader(theme, version)};

    animation: ${circleRotate} .8s linear infinite;

    position: relative;

    transform: rotate3d(90deg);

    width: ${({ size }): string => size === undefined ? '24px' : `${size}px`};

`);



const styleLoaderWrapper = (theme: EmotionTheme, version?: 'light' | 'dark'): SerializedStyles => {

    if (version === 'light') {

        return css`

            color: ${theme.star.loader.bgColorSecondary};

        `;

    }



    if (version === 'dark') {

        return css`

            color: ${theme.star.loader.bgColor};

        `;

    }



    return css`

        color: currentcolor;

    `;

};



interface LoadingSpinnerWrapperPropsType {

    version?: 'light' | 'dark';

}



export const LoadingSpinnerWrapper = withConfig(theme => styled('div', { label: 'LoadingSpinnerWrapper' })<LoadingSpinnerWrapperPropsType>`

    ${({ version }): SerializedStyles => styleLoaderWrapper(theme, version)};

    display: flex;

`);



interface LoaderTxtPropsType {

    size?: number;

}



export const LoaderTxt = styled('span', { label: 'LoaderTxt' })<LoaderTxtPropsType>`

    color: currentcolor;

    font-size: ${({ size }): string => size === undefined ? '24px' : `${size}px`};

    margin-left: 5px;

`;

