import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { ErrorWrapper } from 'src/domains/players/webview/components/Account/traderChat/components/Error.style';
import { observer } from 'src/utils/mobx-react';

export const Error = observer('Error', () => {
    const { appPlayersState } = useAppStateContext();
    const { error } = appPlayersState.traderChatState;

    if (error === null) {
        return null;
    }

    return <ErrorWrapper>{error}</ErrorWrapper>;
});
