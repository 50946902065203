import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SimplePopupWrapper, SimplePopupHeader, CloseIconWrapper, ContentWrapper, ContentLink } from './PopupContainer.style';
import { PopupHelpAndContact } from 'src/domains/layouts/state/popupState/PopupState';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface HelpAndContactPopupPropsType {
    popup: PopupHelpAndContact
};

export const HelpAndContactPopup = observer('HelpAndContactPopup', (props: HelpAndContactPopupPropsType): JSX.Element => {
    const { appLayoutsState } = useAppStateContext();
    const { popupState } = appLayoutsState;
    const { popup } = props;

    return (
        <SimplePopupWrapper>
            <SimplePopupHeader>
                <I18n langKey='popup-state.header.help-support' defaultText='Help & Support' />
                <CloseIconWrapper onClick={popupState.hide}/>
            </SimplePopupHeader>
            <ContentWrapper>
                {popup.textPart1}
                <ContentLink href={`mailto:${popup.email}`}>
                    {popup.email}
                </ContentLink>
                {popup.textPart2}
            </ContentWrapper>
        </SimplePopupWrapper>
    );
});
