import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const RejectedByTraderMessage = observer('RejectedByTraderMessage', () => {
    return (
        <Messages
            type='info'
            message={
                <I18n langKey='betslip.offer.trader-rejected' defaultText='Your bet has been rejected by trader' />
            }
        />
    );
});
