import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface BetslipPropsType {
    className?: string;
    onClick?: () => void;
}

export const BetslipIconNew = observer('BetslipIconNew', ({ className, onClick }: BetslipPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#fff'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='m16 19 4 3V2H4v20l4-3 4 3 4-3Zm.5-12.25h-9v2.5h9v-2.5Zm-2 7.5h-7v-2.5h7v2.5Z'
                fill='fill'
                fillRule='evenodd'
            />
        </SvgElement>
    );
});
