import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SelectionView } from 'src/domains/sportsbook/betting/state/rabState/Types';
import {
    MarketGroup,
    SelectionsGroup,
} from 'src/domains/sportsbook/webview/components/selectionRab/SelectionRab.style';
import { SelectionRabCombineRow } from 'src/domains/sportsbook/webview/components/selectionRab/selectionRabCombined/selectionRabCombinedRow/SelectionRabCombinedRow';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
interface SelectionRabPropsType {
    rabId: BetslipRabId;
    moreOpen: boolean;
    teamHome: SelectionView[];
    teamAway: SelectionView[];
    firstMarketName: string;
    secondMarketName: string;
}

export const SelectionRabCombinedDesktop = observer('SelectionRabCombinedDesktop', (props: SelectionRabPropsType) => {
    const { rabId, moreOpen, teamHome, teamAway, firstMarketName, secondMarketName } = props;

    const teamHomeMoreLess = moreOpen ? teamHome : teamHome.slice(0, 6);
    const teamAwayMoreLess = moreOpen ? teamAway : teamAway.slice(0, 6);

    const teamHomeNames = teamHomeMoreLess.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
    const teamAwayNames = teamAwayMoreLess.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

    return (
        <MarketGroup>
            <SelectionsGroup>
                <SelectionRabCombineRow
                    selections={teamHome}
                    rabId={rabId}
                    selectionsName={teamHomeNames}
                    firstMarketName={firstMarketName}
                    secondMarketName={secondMarketName}
                />
                <SelectionRabCombineRow
                    selections={teamAway}
                    rabId={rabId}
                    selectionsName={teamAwayNames}
                    firstMarketName={firstMarketName}
                    secondMarketName={secondMarketName}
                />
            </SelectionsGroup>
        </MarketGroup>
    );
});
