import { Amount } from 'src_common/common/amount/Amount';
import { RealTimeLobbyGameType } from './RealTimeLobbyGameDetailsModels';
import { computed } from 'mobx';
import { GameDetailsCommonModel } from 'src/domains/casino/state/AppCasino/casino-sockets/real-time-lobby-socket/GameDetailsCommonModel';
import { Value } from 'src_common/common/mobx-utils/Value';

export class RealTimeLobbyGameModel {
    private constructor(
        private readonly data: Value<RealTimeLobbyGameType>,
    ) {
        // makeObservable(this);
    }

    public static create(data: Value<RealTimeLobbyGameType>): RealTimeLobbyGameModel {
        return new RealTimeLobbyGameModel(data);
    }

    public get gameId(): number {
        return this.data.getValue().game_id;
    }

    public get dealerName(): string {
        const dealerName = this.data.getValue().dealer_name;
        return dealerName === 'Default' || dealerName === 'Auto' ? '' : dealerName;
    }

    public get hasBetLimits(): boolean {
        return this.data.getValue().min_bet !== '' && this.data.getValue().max_bet !== '';
    }

    public get minBet(): Amount {
        return new Amount(this.data.getValue().min_bet);
    }

    public get maxBet(): Amount {
        return new Amount(this.data.getValue().max_bet);
    }

    public get seatsTotal(): number | null {
        return this.data.getValue().seats_total ?? null;
    }

    public get seatsTaken(): number | null {
        return this.data.getValue().seats_taken ?? null;
    }

    public get last5Numbers(): Array<string> | null {
        return this.data.getValue().last_numbers?.map(number => number.slice(0, 2)) ?? null;
    }

    @computed public get values(): GameDetailsCommonModel {
        return new GameDetailsCommonModel(this);
    }
}
