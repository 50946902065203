import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { WidgetBanner } from './HomepageHeroWidget.style';
import { ImagesTypes } from './HeroWidgetItem.state';
import { action, makeObservable } from 'mobx';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { useAppStateContext } from 'src/appState/AppState';

class HeroWidgetBannerState {
    public constructor(private readonly router: StarRouter, private readonly slug: string) {
        makeObservable(this);
    }

    @action public redirectToCustomStaticPage = (): void => {
        this.router.redirectToStaticResourceHeroWidget(this.slug);
    };
}

interface PropsTypes {
    withMargin: boolean;
    banners: ImagesTypes;
    slug: string;
    content: string;
    title: string;
}

export const HeroWidgetBanner = observer('HeroWidgetBanner', ({ withMargin, banners, slug, content }: PropsTypes): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const [promoHeroWidget] = useState(() => new HeroWidgetBannerState(starRouter, slug));

    return (
        <WidgetBanner
            withMargin={withMargin}
            banners={banners}
            iaContent={content !== ''}
            onClick={content === '' ? undefined : promoHeroWidget.redirectToCustomStaticPage}
            data-test='promo-banner'
        />
    );
});
