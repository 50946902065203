import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { useAppStateContext } from 'src/appState/AppState';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import {
    PriceHistoryCaption,
    RunnerCaption,
    RaceSummaryCaption,
    SilkCaption,
    SelectionCaption,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';

interface HeadersPropsType {
    type?: string;
    race: EventModel;
    market: MarketModel;
    isRaceInProgress?: boolean;
}

export const HeadersGreyhoundRacing = observer('HeadersGreyhoundRacing', (props: HeadersPropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;
    const { type, market, race, isRaceInProgress } = props;

    if (type === 'cast') {
        return (
            <RaceSummaryCaption>
                <SilkCaption className='cast' data-test='race-summary-header-silk'>
                    <I18n langKey='events.race-summary.silk.label' defaultText='Silk' />
                </SilkCaption>

                <RunnerCaption data-test='race-summary-header-runner'>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-1st'>
                    <I18n langKey='events.race-summary.1st.label' defaultText='1st' />
                </SelectionCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-2nd'>
                    <I18n langKey='events.race-summary.2nd.label' defaultText='2nd' />
                </SelectionCaption>

                {market.tricastsOffered ? (
                    <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-3rd'>
                        <I18n langKey='events.race-summary.3rd.label' defaultText='3rd' />
                    </SelectionCaption>
                ) : null}

                <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-anyorder'>
                    <I18n langKey='events.race-summary.any-order.label' defaultText='Any Order' />
                </SelectionCaption>
            </RaceSummaryCaption>
        );
    } else if (race.antePost) {
        return (
            <RaceSummaryCaption>
                <RunnerCaption>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>
                <SelectionCaption isRaceInProgress={isRaceInProgress}>
                    <I18n langKey='events.race-summary.price.label' defaultText='Price' />
                </SelectionCaption>
            </RaceSummaryCaption>
        );
    } else if (market?.templateId === 'match-bets') {
        return (
            <RaceSummaryCaption>
                <SilkCaption>
                    <I18n langKey='events.race-summary.trap.label' defaultText='Trap' />
                </SilkCaption>

                <RunnerCaption>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress}>Win</SelectionCaption>
            </RaceSummaryCaption>
        );
    } else {
        return (
            <RaceSummaryCaption>
                <SilkCaption>
                    <I18n langKey='events.race-summary.trap.label' defaultText='Trap' />
                </SilkCaption>

                <RunnerCaption>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>

                <PriceHistoryCaption
                    className='race-summary__selection__price-history'
                    isRaceInProgress={isRaceInProgress}
                >
                    <I18n langKey='events.race-summary.price-history.label' defaultText='Price history' />
                </PriceHistoryCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress}>
                    <I18n langKey='events.race-summary.price.label' defaultText='Price' />
                </SelectionCaption>

                {breakpointsState.tablet.isBiggerOrEq === true ? (
                    <SelectionCaption isRaceInProgress={isRaceInProgress}>SP</SelectionCaption>
                ) : null}
            </RaceSummaryCaption>
        );
    }
});
