import { GameDataState } from './AppCasino/game-modal/GameData.state';
import { PopupState } from 'src/domains/layouts/state/popupState/PopupState';
import { RedirectCasinoCallbackType } from 'src/domains/casino/utils/callbackTypes';
import { CasinoSidebarGameInfoState } from './AppCasino/CasinoSidebarGameInfo.state';
import { LiveCasinoSocket } from './AppCasino/casino-sockets/real-time-lobby-pragmatic-socket/LiveCasinoSocket';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { CasinoTableModel } from './AppCasino/casino-sockets/real-time-lobby-pragmatic-socket/CasinoTableModel';
import { SocketGameList } from './AppCasino/casino-sockets/real-time-lobby-pragmatic-socket/SocketGameList';
import { SocketGameDetails } from './AppCasino/casino-sockets/real-time-lobby-pragmatic-socket/SocketGameDetails';
import { GameStoreExt } from 'src/domains/casino/state/AppCasino/store/game-store/GameStoreExt';
import { CasinoContextModelExt } from 'src/domains/casino/state/AppCasino/store/game-store/CasinoContextModelExt';
import { CasinoGameModelExt } from './AppCasino/store/game-store/CasinoGameModelExt';
import { CasinoCollectionModelExt } from './AppCasino/store/game-store/CasinoCollectionModelExt';
import { GameSharedModel, FilterCategoryType, CollectionType } from './AppCasino/store/game-store/typesExt';
import { HomeCollections } from './AppCasino/HomeCollections.state';
import { CasinoNavigationState } from './AppCasino/CasinoNavigation.state';
import { RedirectState } from 'src/domains/casino/state/AppCasino/Redirect.state';
import { BannerStore } from 'src/domains/casino/state/AppCasino/store/banner-store/Banner.store';
import { GameModalState } from './AppCasino/game-modal/GameModal.state';
import { MiniGamesListState } from 'src/domains/casino/state/AppCasino/MiniGamesList.state';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { BannerType } from 'src/domains/casino/state/AppCasino/store/banner-store/types';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { CasinoPageState } from 'src/domains/casino/state/AppCasino/CasinoPage.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { CasinoSearchExt } from './AppCasino/store/game-store/CasinoSearchExt';
import { getAccountRoute, getCasinoRoute } from 'src/domains/casino/utils/utils';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { RealTimeLobbySocket } from './AppCasino/casino-sockets/real-time-lobby-socket/RealTimeLobbySocket';
import { ConfigState } from './AppCasino/ConfigState';
import { JackpotsSocket } from './AppCasino/casino-sockets/jackpot-socket/JackpotsSocket';
import { GameDetailsCommonModel } from 'src/domains/casino/state/AppCasino/casino-sockets/real-time-lobby-socket/GameDetailsCommonModel';

type GameWrapperType = 'slider' | 'slider-grid' | 'square' | 'rectangle' | 'numeric-trending';

export {
    CasinoTableModel,
    SocketGameList,
    SocketGameDetails,
    CasinoGameModelExt,
    CasinoCollectionModelExt,
    GameSharedModel,
    FilterCategoryType,
    CasinoPageState,
    CasinoNavigationState,
    GameWrapperType,
    GameDataState,
    BannerType,
    GameDetailsCommonModel,
    CollectionType,
};

export class AppCasinoState {
    public readonly contextModel: CasinoContextModelExt;
    public readonly gameStoreExt: GameStoreExt;
    public readonly gameModalState: GameModalState;
    public readonly bannerStore: BannerStore;
    public readonly configState: ConfigState;
    public readonly casinoSidebarGameInfoState: CasinoSidebarGameInfoState;
    public readonly liveCasinoSocket: LiveCasinoSocket;
    public readonly realTimeLobbySocket: RealTimeLobbySocket;
    public readonly jackpotsSocket: JackpotsSocket;
    public readonly redirectState: RedirectState;
    public readonly homeCollections: HomeCollections;
    public readonly miniGamesListState: MiniGamesListState;
    public readonly casinoPageState: AutoMap<CollectionType, AutoMap<string | null, CasinoPageState>>;

    public constructor(
        /**
         * @deprecated - Please use this.configState in appCasinoState
         */
        public readonly features: ConfigComponents,
        public readonly popupState: PopupState,
        public readonly breakpointsState: BreakpointsState,
        public readonly env: EnvironmentState,
        public readonly callbacks: RedirectCasinoCallbackType,
        public readonly sdkCustomer: SdkCustomer,
        public readonly starRouter: StarRouter,
        public readonly languagesState: LanguagesState
    ) {
        this.configState = new ConfigState(this.features);

        this.bannerStore = new BannerStore(this.sdkCustomer.session);

        this.redirectState = new RedirectState(
            this.starRouter,
            this.breakpointsState,
            this.configState,
            this.callbacks
        );

        this.contextModel = new CasinoContextModelExt(
            this.sdkCustomer.session,
            this.redirectState,
            this.configState,
            () => this.gameModalState
        );

        this.gameModalState = new GameModalState(
            this.popupState,
            this.configState,
            this.breakpointsState,
            this.sdkCustomer.session,
            this.redirectState,
            this.env
        );

        const search = new CasinoSearchExt();

        starRouter.onChangeCurrentView((prevCurrentView, nextCurrentView) => {
            const prevCasinoRoute = getCasinoRoute(prevCurrentView);
            const nextCasinoRoute = getCasinoRoute(nextCurrentView);
            const { hideAllCasinoGamesOnChristmas, casinoPublishedCollections } = this.configState.commonConfig;

            // redirect unpublished collection page to homepage
            if (
                (nextCurrentView?.name === 'casino' && !(nextCurrentView?.game === 'bingo')) ||
                nextCurrentView?.name === 'live-casino' ||
                nextCurrentView?.name === 'virtuals'
            ) {
                if (this.env.isBrowser && casinoPublishedCollections.includes(nextCurrentView.name) === false) {
                    const homepageUrl = `${location.protocol}//${location.host}`;
                    location.replace(homepageUrl);
                }
            }

            // reset search on route change
            if (prevCasinoRoute !== nextCasinoRoute) {
                search.setSearch('');
                search.setIsSearchOpen(false);
                search.setIsDropdownOpen(false);
            }

            // redirect casino to christmas page
            if (this.env.isBrowser && hideAllCasinoGamesOnChristmas) {
                if (
                    nextCasinoRoute === 'casino' ||
                    nextCasinoRoute === 'live-casino' ||
                    nextCasinoRoute === 'virtuals'
                ) {
                    location.replace('https://christmas.starsports.bet/');
                }
            }

            // launch bingo from url
            if (nextCurrentView?.name === 'casino' && nextCurrentView?.game === 'bingo') {
                if (casinoPublishedCollections.includes('bingo') === true) {
                    this.gameModalState.startBingoGame();
                } else if (this.env.isBrowser && casinoPublishedCollections.includes('bingo') === false) {
                    const homepageUrl = `${location.protocol}//${location.host}`;
                    location.replace(homepageUrl);
                }
            }
        });

        starRouter.onChangeAccountView((prevAccountView, nextAccountView) => {
            const prevAccountRoute = getAccountRoute(prevAccountView);
            const nextAccountRoute = getAccountRoute(nextAccountView);

            if (prevAccountRoute !== nextAccountRoute) {
                if (nextAccountRoute === 'deposit') {
                    this.gameModalState.onDepositOpen();
                }
                if (prevAccountRoute === 'deposit' && nextAccountRoute === 'other') {
                    this.gameModalState.onDepositClose();
                }
            }
        });

        this.gameStoreExt = new GameStoreExt(search, this.contextModel);

        this.homeCollections = new HomeCollections(this.gameStoreExt, this.redirectState);

        this.miniGamesListState = new MiniGamesListState(this.gameStoreExt, this.breakpointsState, this.starRouter);

        this.casinoSidebarGameInfoState = new CasinoSidebarGameInfoState(this.redirectState);

        this.liveCasinoSocket = new LiveCasinoSocket(
            this.configState,
            this.env,
            this.redirectState,
            this.env.isBrowser
        );

        this.realTimeLobbySocket = new RealTimeLobbySocket(
            this.env.envVariables.websocket_casino_rt_lobby_host,
            this.env.envVariables.universe,
            this.env.isBrowser
        );

        this.jackpotsSocket = new JackpotsSocket(
            this.env.envVariables.websocket_casino_jackpots_host,
            this.env.isBrowser,
            this.sdkCustomer
        );

        this.casinoPageState = new AutoMap(
            (collection: CollectionType) =>
                new AutoMap(
                    (tabId: string | null) =>
                        new CasinoPageState(
                            this.languagesState,
                            this.redirectState,
                            this.breakpointsState,
                            this.configState,
                            this.gameStoreExt.getCollection(collection),
                            collection,
                            tabId,
                            search
                        )
                )
        );
    }
}
