import { computed, action, observable, makeObservable } from 'mobx';

interface ConfirmationType {
    message: JSX.Element;
    title?: JSX.Element;
    onConfirm: () => void,
    onReject: (err: unknown) => void,
}

export class ConfirmationBoxState {
    @observable.ref private confirmation: ConfirmationType | null = null;

    constructor() {
        makeObservable(this);
    }

    @computed public get confirmationForView(): ConfirmationType | null {
        const confirmation = this.confirmation;

        if (confirmation === null) {
            return null;
        }

        return {
            message: confirmation.message,
            title: confirmation.title,
            onConfirm: this.onConfirm,
            onReject: this.onReject
        };
    }

    @action public showConfirmation(title: JSX.Element | undefined, message: JSX.Element, onConfirm: () => void, onReject: (err: unknown) => void): void {
        this.onReject(new Error('no-error'));

        this.confirmation = {
            title,
            message,
            onConfirm,
            onReject
        };
    }

    @action private onConfirm = (): void => {
        if (this.confirmation) {
            this.confirmation.onConfirm();
            this.confirmation = null;
        }
    }

    @action private onReject = (err:unknown): void => {
        if (this.confirmation) {
            this.confirmation.onReject(err);
            this.confirmation = null;
        }
    }
}
