import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { EventsTableHeaderStyle } from 'src/domains/sportsbook/webview/components/eventsTable/EventsMainSectionWrapper';
import { useAppStateContext } from 'src/appState/AppState';
import { PromotedEventsState, PromotedSportSingleType } from 'src/domains/layouts/webview/components/promotedSports/PromotedEventsState.state';
import { PromotedSportSectionWrapper } from 'src/domains/layouts/webview/components/promotedSports/PromotedSportSectionWrapper';
import { FiltersStyle } from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable.style';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { FilterRowWrapper } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterGroup.style';
import { Filter } from 'src/domains/layouts/webview/components/filters/Filters.style';
import { SectionHeader } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader';
import { MainSectionHR, MainSectionWrapper } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader.style';
import { UniverseComponent } from 'src/domains/common/universes';

interface PropsTypes {
    promotedEventsState: PromotedEventsState;
}

export const PromotedEvents = observer('PromotedEvents', ({ promotedEventsState }: PropsTypes) => {
    const { appSportsBookState: { models, language } } = useAppStateContext();
    const promotedSports = promotedEventsState.promotedSportsToShow;

    const titleJsx = (
        <UniverseComponent>
            {(universe): React.ReactElement => (
                <span>
                    {universe.promotedEventsSectionTitle(language)}
                </span>
            )}
        </UniverseComponent>
    );

    if (promotedSports.length > 0) {
        return (
            <>
                <MainSectionWrapper>
                    <SectionHeader
                        title={titleJsx}
                        isOpen={promotedEventsState.openEvents}
                        handleOnToggle={promotedEventsState.setOpenEvents}
                        dataTest='promoted-event-header'
                    />
                    {promotedEventsState.openEvents ? <>
                        <FiltersStyle
                            headerStyle={EventsTableHeaderStyle.SOLID}
                            isCompetitionPage={false}
                        >
                            <FilterRowWrapper>
                                <FiltersScrollableWrapper>
                                    {promotedEventsState.sportFiltersAll.map((sport) => {
                                        const sportName = models.getSport(sport.key)?.name ?? '';
                                        return (
                                            <Filter
                                                key={sport.key}
                                                title={sport.key === 'all' ? 'All Sports' : sportName}
                                                disabled={false}
                                                skin='primary'
                                                onClick={(): void => promotedEventsState.handleToggleIsSportFilterActive(sport.key)}
                                                isActive={promotedEventsState.isFilterActive(sport.key)}
                                                data-test={`promoted-event-filter-${sportName.toLowerCase().replaceAll(' ','')}`}
                                            >
                                                {sport.key === 'all' ? 'All Sports' : sportName }
                                            </Filter>
                                        );
                                    })}
                                </FiltersScrollableWrapper>
                            </FilterRowWrapper>
                        </FiltersStyle>

                        {promotedSports.map((sportData: PromotedSportSingleType) => (
                            <React.Fragment key={sportData.id}>
                                {promotedEventsState.sportFiltersAll.map((elem) => {
                                    if (elem.key === sportData.sport_id) {
                                        return <PromotedSportSectionWrapper
                                            key={sportData.id}
                                            sportSlug={sportData.sport_id}
                                            promotedEventsState={promotedEventsState}
                                            sportData={sportData}
                                        />;
                                    }
                                })}
                            </React.Fragment>
                        ))} </> : null}
                </ MainSectionWrapper>

                {promotedEventsState.openEvents ? null : <MainSectionHR />}
            </>
        );
    }

    return null;

});
