import { action, observable, makeObservable } from 'mobx';

export class LiveTrackerIframeState {
  @observable public isOpen: boolean = true;

  public constructor() {
      makeObservable(this);
  }

  @action public toggle = (): void => {
      this.isOpen = !this.isOpen;
  };
}
