import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const BetslipYouDecideWrapper = withConfig(theme => styled('div', { label: 'BetslipYouDecideWrapper' })`
    color: ${theme.star.betslipGeneral.txtColor};
    display: flex;
    flex-flow: column nowrap;
    position: relative;
`);

export const BetslipYouDecideLabel = withConfig(theme => styled('div', { label: 'BetslipYouDecideLabel' })`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: ${theme.star.fontWeight.bold};
    font-size: 14px;
    line-height: 1.14;
    text-transform: uppercase;
`);

export const ChevronIconWrapper = styled(ChevronIcon, { label: 'ChevronIconWrapper' })`
    fill: currentcolor;
    margin-right: 4px;
    width: 14px;
`;

export const CloseIcon = styled('span', { label: 'CloseIcon' })`
    cursor: pointer;
    font-size: 8px;
    position: absolute;
    right: 6px;
    top: 6px;
`;

export const BetslipYouDecideRabItemsWrapper = styled('div', { label: 'BetslipYouDecideRabItemsWrapper' })`
    position: relative;
`;

export const RabItems = withConfig(theme => styled('ul', { label: 'RabItems' })`
    font-size: 12px;
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1;
    padding: 0 28px 0 18px;
    position: relative;
    width: 100%;
`);

export const BetslipYouDecideEventSuspendedBets = withConfig(theme => styled('div', { label: 'BetslipYouDecideEventSuspendedBets' })`
    background-color: ${theme.star.statuses.error.bgColor};
    color: ${theme.star.statuses.error.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    padding: 5px;
`);

export const BetslipYouDecideEventReturn = withConfig(theme => styled('div', { label: 'BetslipYouDecideEventReturn' })`
    color: ${theme.star.betslipGeneral.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
`);

export const BetslipYouDecideInputWrapper = styled('div', { label: 'BetslipYouDecideInputWrapper' })`
    align-items: center;
    display: flex;
    height: 40px;
    margin: 8px 0;
    width: 100%;
`;

export const BetslipYouDecideReturn = withConfig(theme => styled('span', { label: 'BetslipYouDecideReturn' })`
    color: ${theme.star.betslipSelectionList.txtColorTertiary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
`);

export const BetslipYouDecideLabelEvent = withConfig(theme => styled('span', { label: 'BetslipYouDecideLabelEvent' })`
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    padding: 0 0 5px 18px;
    opacity: 0.6;
`);

export const RabItemsWrapper = styled('li', { label: 'RabItemsWrapper' })`
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const RabItemsWrapperSelection = withConfig(theme => styled('span', { label: 'RabItemsWrapperSelection' })`
    display: block;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const RabItemsWrapperMarket = withConfig(theme => styled(RabItemsWrapperSelection, { label: 'RabItemsWrapperMarket' })`
    font-size: ${theme.star.fontSize.xSmall};
    margin-top: 5px;
    opacity: 0.6;
`);

export const ReturnsStyle = withConfig(theme => styled('div', { label: 'ReturnsStyle' })`
    align-items: center;
    color: ${theme.star.betslipGeneral.txtColor};
    display: flex;
    flex-direction: row;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    padding-right: 7px;
    text-align: right;
`);

export const ReturnWrapper = styled('div', { label: 'ReturnWrapper' })`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
