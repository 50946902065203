import React, { ReactElement } from 'react';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';
import { GameIframe, ModalContainer } from './BingoMiniGameModal.style';
import { observer } from 'src/utils/mobx-react';
import { GameStatus } from 'src/domains/casino/webview/CasinoGames/game-modal/modal-body/modal-success/game-content/game-status/GameStatus';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface BingoMiniGameModalType {
    appCasinoState: AppCasinoState;
    gameDataState: GameDataState | null;
}

export const BingoMiniGameModal = observer(
    'BingoMiniGameModal',
    (props: BingoMiniGameModalType): ReactElement | null => {
        const { appCasinoState, gameDataState } = props;
        const { onBingoMiniGameIframeMessage, setBingoMiniGameIframeRef } = appCasinoState.gameModalState;

        if (gameDataState === null) {
            return null;
        }

        if (gameDataState?.session.status === 'loading') {
            return (
                <ModalContainer>
                    <Loader />
                </ModalContainer>
            );
        }
        if (gameDataState?.session.status === 'error') {
            return (
                <ModalContainer>
                    <I18n
                        langKey='casino.bingo-mini-error.placeholder'
                        defaultText='Server error occured. Try again later.'
                    />
                </ModalContainer>
            );
        }
        if (gameDataState?.session.status === 'ready' && gameDataState.session.value.status !== 200) {
            return (
                <ModalContainer>
                    <I18n
                        langKey='casino.bingo-mini-error.placeholder'
                        defaultText='Server error occured. Try again later.'
                    />
                </ModalContainer>
            );
        }

        return (
            <ModalContainer>
                <GameIframe
                    ref={setBingoMiniGameIframeRef}
                    title={`${gameDataState.gameType}-game-modal`}
                    src={gameDataState.session.value.payload}
                    onMessage={onBingoMiniGameIframeMessage}
                    dataTest='game-iframe-bingo-mini'
                />
                <GameStatus
                    appCasinoState={appCasinoState}
                    gameDataState={gameDataState}
                />
            </ModalContainer>
        );
    }
);
