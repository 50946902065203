import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    SrgQuestionnaireTitle,
    SrgVerdictParagraph,
    StaticLinkWrapper,
    SrgParagraph,
    SrgQuestionnaireButton,
    SrgParagraphSecondary,
    SrgQuestionnaireEmailOrPhoneLink,
} from './SrgQuestionnaire.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';

interface SrgVerdictPropsTypes {
    operatorFullName: string;
    srgPassed: boolean;
    languagesState: LanguagesState;
    handleCloseModal: () => void;
}

interface SrgVerdictFailedPropstypes {
    operatorFullName: string;
    languagesState: LanguagesState;
    handleCloseModal: () => void;
}

export const SrgVerdictFailed = observer(
    'SrgVerdictFailed',
    ({ operatorFullName, languagesState, handleCloseModal }: SrgVerdictFailedPropstypes): JSX.Element | null => {
        const HelpLineString = observer(
            'GamstopInformation',
            ({ languagesState }: { languagesState: LanguagesState }) => {
                const greetingString = languagesState.translateTokens(
                    languagesState.getTranslation(
                        'srg.verdict.fail.part4',
                        'Its confidential helpline is: [helpLine]. Non-UK residents can contact GamCare for details of International support organisations.'
                    ),

                    (singleParam: LanguageTokenType) => {
                        if (singleParam.tag === 'helpLine') {
                            return (
                                <SrgQuestionnaireEmailOrPhoneLink
                                    href='tel:0845 6000 133'
                                    isHighlited={true}
                                >
                                    0845 6000 133
                                </SrgQuestionnaireEmailOrPhoneLink>
                            );
                        }
                    }
                );

                return <SrgParagraph>{greetingString}</SrgParagraph>;
            }
        );

        return (
            <SrgVerdictParagraph data-test='srg-verdict-paragraph'>
                <SrgParagraph>
                    <I18n
                        langKey='srg.verdict.fail.part1'
                        defaultText='{operatorFullName} is committed to responsible gambling as a policy of customer care and social responsibility. Therefore, following your answers to our responsible gambling interaction, we have decided to restrict you from placing further deposits or bets on your account. Instead, you have 7 days to cash out any open bets and withdraw any available funds. Once this time has elapsed, your account will then be closed.'
                        params={{ operatorFullName }}
                    />
                </SrgParagraph>

                <SrgParagraph>
                    <I18n
                        langKey='srg.verdict.fail.part2'
                        defaultText='We would also like to make you aware of the below organisations available to you:'
                    />
                </SrgParagraph>

                <SrgParagraph>
                    <I18n
                        langKey='srg.verdict.fail.part3'
                        defaultText='GamCare: The leading authority providing counselling, advice, and practical help in addressing the social impact of gambling in the UK, can be visited at '
                    />
                    <StaticLinkWrapper
                        isUnderlined={true}
                        to='https://www.gamcare.org.uk'
                    >
                        www.gamcare.org.uk
                    </StaticLinkWrapper>
                </SrgParagraph>

                <SrgParagraph>
                    <HelpLineString languagesState={languagesState} />
                </SrgParagraph>

                <SrgParagraph>
                    <I18n
                        langKey='srg.verdict.fail.part5'
                        defaultText='Gamblers Anonymous: A fellowship of men and women who have joined together to do something about their gambling problem and help other compulsive gamblers do the same. There are regional fellowships around the world.'
                    />
                </SrgParagraph>

                <SrgParagraph>
                    <I18n
                        langKey='srg.verdict.fail.part6'
                        defaultText='The Gamblers Anonymous international service site is at '
                    />
                    <StaticLinkWrapper
                        isUnderlined={true}
                        to='https://www.gamblersanonymous.org.uk'
                    >
                        www.gamblersanonymous.org.uk
                    </StaticLinkWrapper>
                </SrgParagraph>

                <SrgParagraph>
                    <I18n
                        langKey='srg.verdict.fail.part7'
                        defaultText='Gamstop: Gamstop lets you put controls in place to restrict your online gambling activities. You will be prevented from using gambling websites and apps run by companies licensed in Great Britain, for a period of your choosing: '
                    />
                    <StaticLinkWrapper
                        isUnderlined={true}
                        to='https://www.gamstop.co.uk'
                    >
                        www.gamstop.co.uk
                    </StaticLinkWrapper>
                </SrgParagraph>

                <SrgQuestionnaireButton
                    size='large'
                    onClick={handleCloseModal}
                    dataTest='close-button'
                >
                    Ok, I understand
                </SrgQuestionnaireButton>
            </SrgVerdictParagraph>
        );
    }
);

export const SrgVerdict = observer(
    'SrgVerdict',
    ({
        operatorFullName,
        srgPassed = true,
        languagesState,
        handleCloseModal,
    }: SrgVerdictPropsTypes): JSX.Element | null => {
        return (
            <>
                <SrgQuestionnaireTitle data-test='srg-verdict-header'>
                    {srgPassed ? (
                        <I18n
                            langKey='srg.verdict.header.pass'
                            defaultText='Passed Customer Interaction Questionnaire'
                        />
                    ) : (
                        <I18n
                            langKey='srg.verdict.header.fail'
                            defaultText='Failed Customer Interaction Questionnaire'
                        />
                    )}
                </SrgQuestionnaireTitle>

                {srgPassed === true ? (
                    <SrgVerdictParagraph data-test='srg-verdict-paragraph'>
                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part1'
                                defaultText='Thank you for taking the time to complete our responsible gambling interaction.'
                            />
                        </SrgParagraph>

                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part2'
                                defaultText='For more information on how {operatorFullName} can help you, please follow this link:'
                                params={{ operatorFullName }}
                            />
                        </SrgParagraph>

                        <StaticLinkWrapper
                            isUnderlined={true}
                            to={{
                                account: 'static',
                                static: 'responsible-gambling',
                            }}
                        >
                            <I18n
                                langKey='srg.verdict.pass.part3'
                                defaultText='{operatorFullName} - Responsible Gambling'
                                params={{ operatorFullName }}
                            />
                        </StaticLinkWrapper>

                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part4'
                                defaultText='If you ever feel your gambling is not fun anymore, please contact us via live chat or email to discuss, and one of our team will be available.'
                            />
                        </SrgParagraph>

                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part5'
                                defaultText='Alternatively, visit one of the websites below for advice and help.'
                            />
                        </SrgParagraph>

                        <SrgParagraph>
                            <StaticLinkWrapper
                                isUnderlined={true}
                                to='https://www.gamcare.org.uk'
                            >
                                www.gamcare.org.uk
                            </StaticLinkWrapper>
                            <br />

                            <StaticLinkWrapper
                                isUnderlined={true}
                                to='https://www.gamblersanonymous.org.uk'
                            >
                                www.gamblersanonymous.org.uk
                            </StaticLinkWrapper>
                            <br />

                            <StaticLinkWrapper
                                isUnderlined={true}
                                to='https://www.gamstop.co.uk'
                            >
                                www.gamstop.co.uk
                            </StaticLinkWrapper>
                        </SrgParagraph>

                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part6'
                                defaultText='{operatorFullName} can help customers with potential gambling problems by offering them the following services:'
                                params={{ operatorFullName }}
                            />
                        </SrgParagraph>

                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part7'
                                defaultText='Deposit Limits: This gives the customer the ability to cap his/her spend over a period of time. Deposit limits can be set on a daily/weekly/monthly basis.'
                            />
                        </SrgParagraph>

                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part8'
                                defaultText='Player Breaks: This gives the customer the ability to take a short break from their gambling experience for a small period of time. Daily, weekly or monthly breaks are available to our customers.'
                            />
                        </SrgParagraph>

                        <SrgParagraphSecondary>
                            <I18n
                                langKey='srg.verdict.pass.part9'
                                defaultText='Reality Checks: This is a notification that interrupts your session to discreetly remind you how long you have been playing.'
                            />
                        </SrgParagraphSecondary>

                        <SrgParagraph>
                            <I18n
                                langKey='srg.verdict.pass.part10'
                                defaultText='Self-exclusion: This service allows customers to take a long term/permanent break from their gambling experience. Customers have no access to their betting account and we will not contact them with marketing offers or promotions. Self-exclusion breaks can last from six months to up to 5 years. We can extend the exclusion at the customer{apostrophe}s request.'
                                params={{ apostrophe: "'" }}
                            />
                        </SrgParagraph>

                        <SrgQuestionnaireButton
                            size='large'
                            onClick={handleCloseModal}
                            dataTest='close-button'
                        >
                            Ok, I understand
                        </SrgQuestionnaireButton>
                    </SrgVerdictParagraph>
                ) : (
                    <SrgVerdictFailed
                        operatorFullName={operatorFullName}
                        languagesState={languagesState}
                        handleCloseModal={handleCloseModal}
                    />
                )}
            </>
        );
    }
);
