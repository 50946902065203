import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';

export class ConfigState {
    public constructor(private readonly features: ConfigComponents) {}

    public get commonConfig(): ConfigType {
        return this.features.config;
    }

    public get precision(): AmountPrecision {
        return new AmountPrecision(this.commonConfig.decimalLength);
    }
}
