//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    universe: string;
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /website-cms/{universe}/promo-notifications/active -> get -> ${code}`);
    };
};

const Response200IO = t.array(t.interface({
    action_template: t.string,
    casino_game_id: t.union([t.null, t.undefined, t.number]),
    competition_id: t.union([t.null, t.undefined, t.number]),
    cta_text: t.string,
    cta_url: t.union([t.null, t.undefined, t.string]),
    date_from: t.string,
    date_to: t.string,
    display_order: t.number,
    event_id: t.union([t.null, t.undefined, t.number]),
    html_url: t.union([t.null, t.undefined, t.string]),
    id: t.number,
    img_url: t.union([t.null, t.undefined, t.string]),
    is_active: t.boolean,
    media_type: t.string,
    sport: t.union([t.null, t.undefined, t.string]),
    tc_text: t.string,
    title: t.string,
    universe: t.string,
    video_url: t.union([t.null, t.undefined, t.string]),
}));
export type Response200Type = Array<{
    action_template: string;
    casino_game_id?: null | undefined | number;
    competition_id?: null | undefined | number;
    cta_text: string;
    cta_url?: null | undefined | string;
    date_from: string;
    date_to: string;
    display_order: number;
    event_id?: null | undefined | number;
    html_url?: null | undefined | string;
    id: number;
    img_url?: null | undefined | string;
    is_active: boolean;
    media_type: string;
    sport?: null | undefined | string;
    tc_text: string;
    title: string;
    universe: string;
    video_url?: null | undefined | string;
}>;
export const decodeResponse200 = createGuard(200, Response200IO);

const Response500IO = t.unknown;
export type Response500Type = unknown;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiWebsiteCmsGetPromoNotificationsActiveRequest"
 */
export const openapi_website_cms_getPromoNotificationsActive = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/website-cms/${params.universe}/promo-notifications/active${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiWebsiteCmsGetPromoNotificationsActiveParamsType = ParamsType;

export type OpenapiWebsiteCmsGetPromoNotificationsActiveResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiWebsiteCmsGetPromoNotificationsActiveResponse200Type = Response200Type;

export const openapiWebsiteCmsGetPromoNotificationsActiveRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiWebsiteCmsGetPromoNotificationsActiveResponseType> => {
    const response = await openapi_website_cms_getPromoNotificationsActive(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiWebsiteCmsGetPromoNotificationsActiveRequest - unhandled response ${response.status}`);
};

