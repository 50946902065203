import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { InfoPageWrapper, InfoIconBox, IconInfo, InformationParagraph, LinkWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/temporaryInfo/InfoPage.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PropsType {
    infoPageType: 'withdrawal' | 'deposit';
    accountHelperMail: string;
}

export const InfoPage = observer('InfoPage', ({ infoPageType, accountHelperMail }: PropsType): JSX.Element => {
    return (
        <InfoPageWrapper>
            <InfoIconBox>
                <IconInfo />
            </InfoIconBox>
            <InformationParagraph data-test='information-paragraph'>
                {infoPageType === 'deposit' ? (
                    <>
                        <I18n langKey='account.deposit.info-page' defaultText='For deposit requests, please contact us at' />
                        <LinkWrapper to={`mailto:${accountHelperMail}`}>
                            <I18n langKey='account.deposit.info-page.email' defaultText=' {accountHelperMail}' params={{ accountHelperMail }} />
                        </LinkWrapper>
                    </>
                ): (
                    <>
                        <I18n langKey='account.withdrawal.info-page' defaultText='For withdrawal requests, please contact us at' />
                        <LinkWrapper to={`mailto:${accountHelperMail}`}>
                            <I18n langKey='account.withdrawal.info-page.email' defaultText=' {accountHelperMail}' params={{ accountHelperMail }} />
                        </LinkWrapper>
                    </>
                )}
            </InformationParagraph>
        </InfoPageWrapper>
    );
});
