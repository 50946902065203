import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { observer } from 'src/utils/mobx-react';

export const ReferredBetMessage = observer('ReferredBetMessage', () => {
    return (
        <Messages
            type='info'
            message={
                <I18n
                    langKey='betslip.referred-to-trader-message'
                    defaultText='Your bet has been referred to a trader - please wait as we review it'
                />
            }
        />
    );
});
