export const parseFloatValue = (value: string): number | null => {
    const valueNumber = Number(value);
    if (isNaN(valueNumber)) {
        return null;
    }

    return valueNumber;
};

interface SplitMarketNameForSortType {
    name: string,
    value: number,
}

export const splitMarketNameForSort = (name: string): SplitMarketNameForSortType => {

    const chunks = name.split(' ');
    const last = chunks.pop();

    if (last !== undefined) {
        const value = parseFloatValue(last);

        if (value !== null) {
            return {
                name: chunks.join(' '),
                value,
            };
        }
    }
    
    return {
        name,
        value: 0,
    };
};

