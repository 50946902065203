import React from 'react';
import { LinkWrapper } from './PromoSidebarPartials.style';

interface SidebarContentDirectLinkPropsType {
    isHTMLBanner: boolean;
    ctaText: string;
    onClickDirectLinkHandle: () => void;
}
export const SidebarContentDirectLink = ({
    isHTMLBanner,
    ctaText,
    onClickDirectLinkHandle,
}: SidebarContentDirectLinkPropsType): JSX.Element => (
    <LinkWrapper
        size='medium'
        onClick={onClickDirectLinkHandle}
        isHTMLBanner={isHTMLBanner}
        dataTest='landing-page-direct-link'
    >
        {ctaText === '' ? 'Continue' : ctaText}
    </LinkWrapper>
);
