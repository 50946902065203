import { groupBy, sortBy } from 'lodash';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { computed, makeObservable } from 'mobx';
import { AppSportsBookState } from 'src/domains/sportsbook/state/AppSportsBook.state';
import { DateTime } from 'src_common/utils/time/time';

export const getCustomMarketNew = (event: EventModel): Array<MarketModel> | null => {
    const isAntePostEvent = event?.antePost;

    const antePostMarkets = event?.markets.filter( (m: MarketModel) => {
        const templateId = m.templateId;
        const antePost = event.antePost;
        const oldMarketFiltering = isAntePostEvent && (
            templateId === 'custom-race-market' || templateId === 'custom-outrights-market'
        );

        // Get market with antepost tag 'yes' otherwise fallback to old behavior
        // event must be an antepost and the market template must be custom
        return antePost || oldMarketFiltering;
    });

    // Remove event without markets
    return antePostMarkets.length > 0 ? antePostMarkets : null;
};

export const groupEvents = (eventsProps: Array<EventModel>): Record<string, Array<EventModel>> => {
    eventsProps = eventsProps.filter( (e: EventModel | null) => e !== null && getCustomMarketNew(e));

    return groupBy(sortBy(eventsProps, x => x.timeSettingsStartTime), event => (
        DateTime.from(event.timeSettingsStartTime)?.startOfMonths().toISOString()
    ));
};

export const groupEventsSpecials = (eventsProps: Array<EventModel>): Record<string, Array<EventModel>> => {
    return groupBy(sortBy(eventsProps, x => x.timeSettingsStartTime), event => (
        DateTime.from(event.timeSettingsStartTime)?.startOfMonths().toISOString()
    ));
};
export class MeetingsState {
    private readonly sport: () => 'horseracing' | 'greyhoundracing';

    public constructor(private readonly appSportsBookState: AppSportsBookState, sport: () => 'horseracing' | 'greyhoundracing') {
        makeObservable(this);
        this.sport = sport;
    }

    @computed public get collectionEvents(): Array<EventModel> {
        const out: Array<EventModel> = [];
        const eventsList = this.appSportsBookState.eventsCollection.listHorseracingSpecials();
        for (const id of eventsList.ids2) {
            const event = id.getEventModel();

            if (event !== null) {
                out.push(event);
            }
        }

        return out;
    }

    @computed public get collectionEventsAntePost(): Array<EventModel> {
        const out: Array<EventModel> = [];
        const sport = this.sport();
        const eventsList = this.appSportsBookState.eventsCollection.listAntePostRacing(sport);

        for (const id of eventsList.ids2) {
            const event = id.getEventModel();

            if (event !== null) {
                out.push(event);
            }
        }

        return out;
    }
}
