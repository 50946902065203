import React, { useEffect } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetslipReceipt } from 'src/domains/sportsbook/betting/ui/betSlip/betslipReceipt/BetslipReceipt';
import { BetslipMainContent } from 'src/domains/sportsbook/betting/ui/betSlip/betslipMainContent/BetslipMainContent';
import { autorun } from 'mobx';
import { BetslipMainWrapper } from './Betslip.style';
import { useAppStateContext } from 'src/appState/AppState';
import { TextMessages } from 'src/domains/layouts/webview/components/Messages/TextMessages';

export const Betslip = observer('Betslip', () => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;

    useEffect(() => {
        const autoRunDispose = autorun(() => {
            const { legsIds2, referralState } = betSlipState;

            legsIds2.map((leg) => {
                const selectionModel = leg.id.getModel();
                if (selectionModel?.display === false && referralState.isReferred === false) {
                    betSlipState.legsState.betslipData.onRemoveLeg(leg);
                }
            });
        });

        return (): void => {
            autoRunDispose();
        };
    }, []);

    const { legsIds2, betSlipSummaryState } = betSlipState;
    const { betReceipt } = betSlipSummaryState;

    let content;

    const activeBets = appSportsBookState.rab.activeBets;

    if (legsIds2.length > 0 || activeBets.length > 0) {
        content = <BetslipMainContent />;
    } else {
        content = (
            <TextMessages
                type='info'
                marginTop='8px'
                message={
                    <I18n
                        langKey='betslip.waiting-for-selections'
                        defaultText='Empty betslip. Make some selections and they will show up here'
                    />
                }
            />
        );
    }

    if (betReceipt !== null) {
        content = <BetslipReceipt />;
    }

    const { configComponents } = appLayoutsState;

    return <BetslipMainWrapper isMiniCasino={configComponents.config.casinoMiniGames}>{content}</BetslipMainWrapper>;
});
