import { z } from 'zod';
import { CurrencyCodeZod } from 'src_common/common/amount/website-money/currency';

export const ContactPreferenceEnum = {
    EMAIL: 'email',
    SMS: 'sms',
    PHONE: 'phone',
    WHATSAPP: 'whatsApp',
} as const;

const titleType = z.union([z.literal('mr'), z.literal('ms'), z.literal('mrs'), z.literal('miss')]);

export const InputSchema = z.object({
    personalId: z.string().optional().nullable(),
    username: z.string().optional(),
    email: z.string(),
    password: z.string(),
    title: titleType.optional(),
    firstName: z.string(), //max50
    surname: z.string(), //max 50
    birthDate: z.string(), //Date '2000-12-24'
    country: z.string(), //CountryCodesTypes
    currency: CurrencyCodeZod.optional(),
    contactPreferences: z.array(z.nativeEnum(ContactPreferenceEnum)), //Array<ContactPreference>;
    postCode: z.string().optional().nullable(), //max 10,
    addressLine1: z.string(), //max 100
    addressLine2: z.string().optional().nullable(), //max 100
    city: z.string().optional().nullable(), //max 50
    language: z.string().optional().nullable(),
    mobilePhone: z.object({
        country: z.string(), //CountryCodesTypes,
        prefix: z.string(),
        number: z.string(),
    }),
    referrer: z.string().optional().nullable(),
    incomeaccess: z.string().optional().nullable(),
    promoID: z.string().optional().nullable(),
});

export type CreateAccountInput = z.infer<typeof InputSchema>;

export const SuccessResponseSchema = z.object({
    token: z.string(),
});

type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorInputResponseSchema = z.object({
    code: z.string(),
    debugDetails: z.string().optional().nullable(),
    details: z.string().optional().nullable(),
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            debugDetails: z.string().optional().nullable(),
            field: z.string().optional().nullable(),
            pointer: z.string().optional().nullable(),
            resource: z.string().optional().nullable(),
        })
    ),
    message: z.string().optional().nullable(),
});

type TErrorInputResponse = z.infer<typeof ErrorInputResponseSchema>;

export const ErrorApiResponseSchema = z.object({
    code: z.string(),
    message: z.string(),
});

type TErrorApiResponse = z.infer<typeof ErrorApiResponseSchema>;

export type TResponseType =
    | { responseStatus: 'success'; data: TSuccessResponse }
    | { responseStatus: 'error_input'; data: TErrorInputResponse }
    | { responseStatus: 'error_api'; data: TErrorApiResponse };

//----------------------------------------------------------------

export const AccountAgeVerificationEnum = {
    PASSED: 'passed',
    NOT_CHECKED: 'not checked',
    FAILED: 'failed',
    UNKNOWN: 'unknown',
} as const;

export const AccountStatusEnum = {
    ACTIVE: 'active',
    PENDING: 'pending',
    SUSPENDED: 'suspended',
    BLOCKED: 'blocked',
    CLOSED: 'closed',
} as const;
