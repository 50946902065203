import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    EventsLoader,
    SelectionLoader,
    SilkLoader,
    FirstLineRacecardLoader,
    SecondLineRacecardLoader,
    RacePositionLoader,
    PriceHistory,
    PriceHistoryWrapper,
    HorseInfoWrapper,
    RaceboxWrapper, WeightLoader, SelectionSPLoader
} from 'src/domains/layouts/shared/loaders';

export const RaceRow = observer('RaceRow', () => {
    return (
        <RaceboxWrapper>
            <HorseInfoWrapper>
                <RacePositionLoader />
                <SilkLoader />

                <EventsLoader>
                    <FirstLineRacecardLoader />
                    <SecondLineRacecardLoader />
                </EventsLoader>
            </HorseInfoWrapper>
            <PriceHistoryWrapper>
                <WeightLoader />
                <PriceHistory />
                <SelectionLoader />
                <SelectionSPLoader />
            </PriceHistoryWrapper>
        </RaceboxWrapper>
    );
}
);
