import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    StaticPageInnerWrapper,
    StaticPageWrapper,
    StaticPageInfoWrapper,
    StaticPageLoaderWrapper
} from 'src/domains/players/webview/components/Account/staticPage/StaticPage.styles';
import { StaticPageHeader } from 'src/domains/players/webview/components/Account/staticPage/StaticPageHeader';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsType {
    pageSlug: string;
}

export const StaticResourceHeroWidget = observer('StaticResourceHeroWidget', (props: PropsType): React.ReactElement | null => {
    const { pageSlug } = props;

    const { appLayoutsState } = useAppStateContext();
    const { heroWidgetState } = appLayoutsState;
    const heroWidgetResourceType = heroWidgetState.heroWidgetResource.get().type;

    if (heroWidgetResourceType === 'loading') {
        return (
            <StaticPageLoaderWrapper />
        );
    }

    if (heroWidgetResourceType === 'error') {
        return (
            <StaticPageWrapper>
                <StaticPageHeader title={<I18n langKey='account.static-page.error.title' defaultText='Error occurred' />} back={true} parent='summary' />
                <StaticPageInnerWrapper>
                    <StaticPageInfoWrapper>
                        <I18n
                            langKey='account.static-page.error.description'
                            defaultText="Sorry, we can't find page you are searching for."
                        />
                    </StaticPageInfoWrapper>
                </StaticPageInnerWrapper>
            </StaticPageWrapper>
        );
    }

    const actualHeroWidgetInfo = heroWidgetState.actualHeroWidgetInfo(pageSlug);

    if (actualHeroWidgetInfo === undefined) {
        return (
            <StaticPageWrapper>
                <StaticPageHeader title='' back={false} />
                <StaticPageInnerWrapper>
                    <div dangerouslySetInnerHTML={{ __html: '' ?? '' }} />
                </StaticPageInnerWrapper>
            </StaticPageWrapper>
        );
    }

    const { tc_title, tc_text } = actualHeroWidgetInfo;

    return (
        <StaticPageWrapper>
            <StaticPageHeader title={tc_title} back={true} parent='home' />
            <StaticPageInnerWrapper>
                <div dangerouslySetInnerHTML={{ __html: tc_text ?? '' }} data-test='hero-widget-static-page-content'/>
            </StaticPageInnerWrapper>
        </StaticPageWrapper>
    );
});
