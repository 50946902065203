import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { CollectionType } from 'src/domains/casino/types/types';
import { CasinoPromotions } from './CasinoPromotions';
import { CasinoNavigation } from './CasinoNavigation';
import { CasinoContent } from './CasinoContent';

interface CasinoCollectionPropsType {
    appCasinoState: AppCasinoState;
    collection: CollectionType;
    tabId: string | null;
}

export const CasinoCollection = observer('CasinoCollection', (props: CasinoCollectionPropsType): JSX.Element => {
    const { appCasinoState, collection, tabId } = props;

    const casinoPageState = appCasinoState.casinoPageState.get(collection).get(tabId);
    const hideCategories = collection === 'virtuals' ? true : false;

    return (
        <>
            <CasinoPromotions
                appCasinoState={appCasinoState}
                collection={collection}
            />
            <CasinoNavigation
                appCasinoState={appCasinoState}
                casinoPageState={casinoPageState}
                hideCategories={hideCategories}
            />
            <CasinoContent
                appCasinoState={appCasinoState}
                casinoPageState={casinoPageState}
                collection={collection}
            />
        </>
    );
});
