import { TouchEvent } from 'react';
import { AppCasinoState, BannerType } from 'src/domains/casino/state/AppCasino.state';
import { RedirectTypeEnum } from './SingleBanner';
import { CasinoBannersState } from 'src/domains/casino/webview/CasinoGames/home-games/casino-banners/CasinoBanners.state';

export class SingleBannerState {
    public constructor(
        private appCasinoState: AppCasinoState,
        private casinoBannersState: CasinoBannersState,
        private banner: BannerType,
    ) {}

    public get showTerms(): boolean {
        return !(this.casinoBannersState.hasOneBanner && this.banner.terms_headline === '');
    };

    public handleTouchStart = (e: TouchEvent<HTMLDivElement>): void => {
        if (e.changedTouches[0] !== undefined) this.casinoBannersState.swipeStart(e.changedTouches[0].screenX);
    };

    public handleTouchEnd = (e: TouchEvent<HTMLDivElement>): void => {
        if (e.changedTouches[0] !== undefined) this.casinoBannersState.swipeEnd(e.changedTouches[0].screenX, this.casinoBannersState.goLeftAvailable, this.casinoBannersState.goRightAvailable);
    };

    public handleRedirect = (): void => {
        const { redirect_game, redirect_type, redirect_url } = this.banner;
        const redirectGame = redirect_game ?? null;
        const redirectUrl = redirect_url ?? null;
    
        if (redirect_type === RedirectTypeEnum.REDIRECT_GAME && redirectGame !== null) {
            this.appCasinoState.gameStoreExt.getGameModel(redirectGame)?.startGame();
        };
        if (redirect_type === RedirectTypeEnum.REDIRECT_URL && redirectUrl !== null) {
            this.appCasinoState.redirectState.onHandleUrlRedirection(redirectUrl);
        };
    };

    public handleTerms = (): void => {
        this.appCasinoState.bannerStore.setSidebarTermsActiveBanner(this.banner.id);
        this.appCasinoState.redirectState.redirectToCasinoBannerTerms();
    };
}
