import { z } from 'zod';
import { TShortErrorResponse } from './error/shortError';

export const InputSchema = z.object({
    username: z.string(),
    password: z.string(),
    grant_type: z.string(),
});

export type SignInInput = z.infer<typeof InputSchema>;

export const SignInResponse = z.object({
    id: z.string(),
    access_token: z.string(),
    token_type: z.string(),
    data: z.object({}),
    universe: z.string(),
    accountId: z.number().nullable(),
    accountName: z.string(),
    accountType: z.string(),
    roles: z.array(z.string()),
    expires: z.string(), // You may want to use a more specific date type if needed
    createdAt: z.string(), // You may want to use a more specific date type if needed
    startsAt: z.string(), // You may want to use a more specific date type if needed
    deletedAt: z.string().nullable(),
    deletedBy: z.string().nullable(),
    reasonForDeletion: z.string().nullable(),
    channel: z.string().nullable(),
    ip: z.string(),
    country: z.string(),
    killable: z.boolean(),
    extendableToken: z.boolean(),
    concurrent: z.boolean(),
});

export type TSignInResponse = z.infer<typeof SignInResponse>;

export type TSignInType =
    { responseStatus: 'success'; data: TSignInResponse} |
    { responseStatus: 'error'; data: TShortErrorResponse }

