import React from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import { EventGroupHeader6Pack } from 'src/domains/sportsbook/webview/components/eventsList/eventGroupHeader6Pack/EventGroupHeader6Pack';
import { getMarketNameNBA } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/EventRowNBA.state';
import { useAppStateContext } from 'src/appState/AppState';

interface EventGroupHeaderPropsType {
    label: string,
    small: boolean,
    open: boolean,
    onToggle: () => void,
    headerStyle: 'sportLevel' | 'competitionLevel'
}

export const EventGroupHeaderNBA = observer('EventGroupHeaderNBA', (propsIn: EventGroupHeaderPropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const props = useAsObservableSource(propsIn);
    const { label, small, open, onToggle, headerStyle } = props;

    return (
        <EventGroupHeader6Pack
            label={label}
            small={small}
            open={open}
            onToggle={onToggle}
            marketNames={getMarketNameNBA(languagesState.getTranslation)}
            headerStyle={headerStyle}
        />
    );
});
