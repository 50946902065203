import { SelectionModel, PriceType } from './SelectionModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { computed, makeObservable } from 'src_common/common/mobx-wrapper';

import { SelectionAnimation } from './SelectionAnimation';
import { ModelBoxContext } from 'src_common/common/websocket2/common/ModelBoxContext';
import { calculateAmericanOdds } from 'src_common/common/calculateAmericanOdds';

interface ParamsType {
    selection: SelectionModel;
    sp?: boolean;
    started: boolean;
    tradedInPlay: boolean;
    isSPOnly: boolean;
}

interface StateReturnType {
    price: 'sp' | PriceType | undefined;
    suspended: boolean;
    selected: boolean;
}

const buildState = ({ selection, sp, started, tradedInPlay, isSPOnly }: ParamsType): StateReturnType => {
    let price: 'sp' | PriceType | undefined;
    let suspended = false;

    const isSP = selection.sp && started !== true;
    const isUnnamedFavourite = selection.templateId === 'unnamed-favourite';

    if (isUnnamedFavourite && isSP) {
        if (sp !== false) {
            price = 'sp';
        }
    } else if (sp === true) {
        if (isSP) {
            price = 'sp';
        }
    } else if (selection.price !== undefined && !isSPOnly && selection.activated) {
        price = selection.price;
    } else if (isSP) {
        price = 'sp';
    }

    if (sp === true && isSP) {
        //This comparison appears to be unintentional because the types 'false' and 'true' have no overlap.ts(2367)
        // suspended = started === true;
        suspended = false;
    } else {
        suspended = !isSPOnly && !isSP && (!selection.activated || (tradedInPlay === false && started === true));
    }

    return {
        price,
        suspended,
        selected: false,
    };
};

export class SelectionViewModel {
    public readonly modelBoxContext: ModelBoxContext;
    public readonly event: EventModel;
    public readonly market: MarketModel;
    public readonly selection: SelectionModel;
    public readonly sp: boolean | undefined;
    public readonly animation: SelectionAnimation;

    public constructor(
        modelBoxContext: ModelBoxContext,
        event: EventModel,
        market: MarketModel,
        selection: SelectionModel,
        sp: boolean | undefined,
        animation: SelectionAnimation
    ) {
        this.modelBoxContext = modelBoxContext;
        this.event = event;
        this.market = market;
        this.selection = selection;
        this.sp = sp;
        this.animation = animation;
        makeObservable(this);
    }

    @computed.struct public get forView(): StateReturnType {
        const marketTradedInPlay = this.market.tradedInPlay;
        const isSPOnly = this.market.spOnly;

        const started = this.event.timeSettingsStarted;
        const tradedInPlay = this.event.timeSettingsTradedInPlay && marketTradedInPlay;

        return buildState({
            selection: this.selection,
            started,
            tradedInPlay,
            isSPOnly,
            sp: this.sp,
        });
    }

    @computed.struct public get price(): 'sp' | PriceType | undefined {
        return this.forView.price;
    }

    @computed.struct public get suspended(): boolean {
        return this.forView.suspended;
    }

    @computed.struct public get selected(): boolean {
        return this.forView.selected;
    }

    @computed public get displayPrice(): string | number {
        const oddsFormat = this.modelBoxContext.modelsStateSocketConfig.oddsFormatShort;

        if (this.price === undefined) {
            return '-';
        }

        if (this.price === 'sp') {
            return 'SP';
        }

        switch (oddsFormat) {
            case 'a': {
                return calculateAmericanOdds(this.price.d);
            }
            case 'd': {
                return this.price.d;
            }
            case 'f': {
                return this.price.f;
            }
        }
    }

    @computed public get priceChange(): 'up' | 'down' | null {
        return this.animation.priceChange;
    }
}
