import { slug } from 'src/utils/deburr';
import DEFAULT from 'src/domains/sportsbook/webview/components/eventsList/eventRowMLB/assets/logos/default-mlb-team.png';

import ari from './assets/logos/ari.png';
import atl from './assets/logos/atl.png';
import bal from './assets/logos/bal.png';
import bos from './assets/logos/bos.png';
import chc from './assets/logos/chc.png';
import cin from './assets/logos/cin.png';
import cle from './assets/logos/cle.png';
import col from './assets/logos/col.png';
import cws from './assets/logos/cws.png';
import det from './assets/logos/det.png';
import hou from './assets/logos/hou.png';
import kc from './assets/logos/kc.png';
import laa from './assets/logos/laa.png';
import lad from './assets/logos/lad.png';
import mia from './assets/logos/mia.png';
import mil from './assets/logos/mil.png';
import min from './assets/logos/min.png';
import nym from './assets/logos/nym.png';
import nyy from './assets/logos/nyy.png';
import oak from './assets/logos/oak.png';
import phi from './assets/logos/phi.png';
import pit from './assets/logos/pit.png';
import sd from './assets/logos/sd.png';
import sea from './assets/logos/sea.png';
import sf from './assets/logos/sf.png';
import stl from './assets/logos/stl.png';
import tb from './assets/logos/tb.png';
import tex from './assets/logos/tex.png';
import tor from './assets/logos/tor.png';
import was from './assets/logos/was.png';

export const getTeamShirt = (name: string | null): string => {
    if (name !== null) {
        const slugName = slug(name);
        switch (slugName) {
            case 'arizona-diamondbacks':
                return ari;
            case 'atlanta-braves':
                return atl;
            case 'baltimore-orioles':
                return bal;
            case 'boston-red-sox':
                return bos;
            case 'chicago-cubs':
                return chc;
            case 'cincinnati-reds':
                return cin;
            case 'cleveland-indians':
                return cle;
            case 'colorado-rockies':
                return col;
            case 'chicago-white-sox':
                return cws;
            case 'detroit-tigers':
                return det;
            case 'houston-astros':
                return hou;
            case 'kansas-city-royals':
                return kc;
            case 'los-angeles-angels':
                return laa;
            case 'los-angeles-dodgers':
                return lad;
            case 'miami-marlins':
                return mia;
            case 'milwaukee-brewers':
                return mil;
            case 'minnesota-twins':
                return min;
            case 'new-york-mets':
                return nym;
            case 'new-york-yankees':
                return nyy;
            case 'oakland-athletics':
                return oak;
            case 'philadelphia-phillies':
                return phi;
            case 'pittsburgh-pirates':
                return pit;
            case 'san-diego-padres':
                return sd;
            case 'seattle-mariners':
                return sea;
            case 'san-francisco-giants':
                return sf;
            case 'st-louis-cardinals':
                return stl;
            case 'tampa-bay-rays':
                return tb;
            case 'texas-rangers':
                return tex;
            case 'toronto-blue-jays':
                return tor;
            case 'washington-nationals':
                return was;
        }
    }

    return DEFAULT;

};
