import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface DollarIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const DollarIcon = observer(
    'DollarIcon',
    ({ className, onClick }: DollarIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M10.6 8.257c0 .373.21.66.63.863.436.202 1.12.427 2.053.676 1.166.326 2.13.653 2.892.98.777.326 1.454.847 2.03 1.562.574.7.862 1.625.862 2.776 0 1.492-.482 2.682-1.446 3.568-.964.871-2.231 1.423-3.802 1.656V24H9.948v-3.732a15.482 15.482 0 01-3.22-.91A12.532 12.532 0 014 17.796l2.006-4.082c.824.716 1.858 1.33 3.102 1.843 1.244.497 2.286.746 3.125.746.404 0 .723-.07.957-.21.233-.155.35-.388.35-.7 0-.373-.226-.66-.677-.862-.435-.218-1.127-.444-2.076-.677-1.15-.295-2.115-.598-2.892-.91a5.263 5.263 0 01-1.982-1.492c-.56-.669-.84-1.563-.84-2.682 0-1.353.427-2.48 1.283-3.382.87-.918 2.068-1.524 3.592-1.82V0h3.871v3.499c.995.124 1.96.35 2.892.676a12.14 12.14 0 012.473 1.166l-1.96 4.128a17.867 17.867 0 00-2.962-1.352c-1.088-.39-1.974-.583-2.659-.583-.668 0-1.002.24-1.002.723H10.6z'
                    fill='fill'
                    fillRule='nonzero'
                />
            </SvgElement>
        );
    }
);
