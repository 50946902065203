import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';

import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';

import { deburr } from 'src/utils/deburr';

import { checkIsLetter, checkIsNumericCharacter, checkIsSpecialCharacter } from 'src_common/common/forms/validators/validatingFunctions';

import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';



const allowedSpecialChar = [

    '~', '`', '!', '@',

    '#', '$', '%', '^',

    '&', '*', '(', ')',

    '_', '-', '+', '=',

    '{', '[', '}', ']',

    '|', ':', ';', '?',

    '<', ',', '>', '.',

    '/', '\\', '\''

];



const matchChar = (char: string): boolean => {

    if (checkIsLetter(char)) {

        return true;

    }



    if (checkIsNumericCharacter(char)) {

        return true;

    }



    if (checkIsSpecialCharacter(allowedSpecialChar, char)) {

        return true;

    }



    return false;

};



const checkGroupOfChar = (password: string): boolean => {

    const validator = {

        isLetter: false,

        isNumeric: false,

        isSpecialCharacter: false,

    };



    for (const char of password) {

        if (checkIsLetter(char)) {

            validator.isLetter = true;

        }

        if (checkIsNumericCharacter(char)) {

            validator.isNumeric = true;

        }

        if (checkIsSpecialCharacter(allowedSpecialChar, char)) {

            validator.isSpecialCharacter = true;

        }

    }



    const { isLetter, isNumeric, isSpecialCharacter } = validator;



    return isLetter && isNumeric && isSpecialCharacter;

};



export const checkPassword = (password: string): boolean => {

    if (password.length < 8 || password.length > 20) {

        return false;

    }



    for (const char of password) {

        if (matchChar(char) === false) {

            return false;

        }

    }



    if (checkGroupOfChar(password) === false) {

        return false;

    }



    return true;

};



export const validatePassword = (value: string): Result<string> => {

    if (checkPassword(deburr(value))) {

        return Result.createOk(value);

    }



    const errorRequiredField = getErrorByCode('ERROR_PASSWORD');

    return Result.createError(errorRequiredField);

};



export const comparePassword = (value: string, mainPassword: string, languagesState: LanguagesState): Result<string> => {

    if (value === mainPassword) {

        return Result.createOk(value);



    }

    return Result.createError(

        languagesState.getTranslation('errors.second-password', 'Passwords are not the same.'));

};

