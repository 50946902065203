import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { RaceSummaryRow } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { Trap } from 'src/domains/sportsbook/webview/components/trap/Trap';
import EMPTY_SILK_GH from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import {
    TrapChallengeContainer,
    TrapHeaderList,
    TrapNumberSilk,
    CompetitionRow,
    CompetitionRowName,
    CompetitionRowTrapList
} from 'src/domains/sportsbook/webview/components/containers/competitionsTrapChallenge/CompetitionsTrapChallengeGroup.style';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';

interface CompetitionsTrapChallengeRowProps {
    event: EventModel;
}

const CompetitionsTrapChallengeRow = observer('CompetitionsTrapChallenge', ({ event }: CompetitionsTrapChallengeRowProps): React.ReactElement | null => {
    const firstMarket = event.markets[0];
    if (firstMarket === undefined) {
        return null;
    }

    const competitionModel = event.competition2.getModel();

    const type = undefined;
    const order = 'by-selection-name';
    const itemsForView = sortSelectionsByCriteria(firstMarket.selections, order);

    return (
        <CompetitionRow>
            <CompetitionRowName>
                {competitionModel === null ? null : competitionModel.name}
            </CompetitionRowName>
            <CompetitionRowTrapList>
                {
                    itemsForView.map((selection: SelectionModel) => (
                        <RaceSummaryRow
                            key={selection.id}
                            selectionId={selection.id2}
                            type={type}
                            displayTrainer={type === 'cast' ? false : true}
                        />
                    ))
                }
            </CompetitionRowTrapList>
        </CompetitionRow>
    );
});

interface PropTypes {
    events: Array<EventModel>;
}

export const CompetitionsTrapChallengeGroup = observer('CompetitionsTrapChallengeGroup', (props: PropTypes) => {
    const { events } = props;
    const TRAP_ARRAY = [1, 2, 3, 4, 5, 6];

    return (
        <TrapChallengeContainer>
            <TrapHeaderList>
                {
                    TRAP_ARRAY.map((item) => (
                        <TrapNumberSilk key={item}>
                            <span></span>
                            <Trap
                                number={item}
                                placeholder={EMPTY_SILK_GH}
                                selectionId={null}
                            />
                        </TrapNumberSilk>
                    ))
                }
            </TrapHeaderList>

            {
                events.map((event: EventModel) => (
                    <CompetitionsTrapChallengeRow
                        event={event}
                        key={event.id}
                    />
                ))
            }
        </TrapChallengeContainer>
    );
});

