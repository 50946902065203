import { computed, makeObservable } from 'mobx';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { DataTimeDuration } from 'src_common/utils/time/time';

class ConnectWrapperTimer {
    public connect(self: MobxValue<number>): NodeJS.Timeout {
        const timer = setInterval((): void => {
            const currentTimeDate = new Date();

            self.setValue(currentTimeDate.getTime());
        }, 1000);
        return timer;
    }

    public dispose(timer: NodeJS.Timeout): void {
        clearInterval(timer);
    }
}

const currentTime: MobxValue<number> = MobxValue.create({
    initValue: 0,
    connect: new ConnectWrapperTimer(),
});

export class CounterState {
    public constructor(
        private readonly eventId: EventId
    ) {
        makeObservable(this);
    }

    @computed public get nextRaceTime(): number | null {
        const eventModel = this.eventId.getEventModel();
        if (eventModel !== null) {
            const startTime = new Date(eventModel.timeSettingsStartTime);
            return startTime.getTime();
        }
        return null;
    }

    @computed public get momentHelperTime(): DataTimeDuration | null {
        const curTime = currentTime.getValue();
        if (this.nextRaceTime !== null && curTime > 0) {
            const diffInMilliseconds = Math.floor(this.nextRaceTime - curTime);

            return DataTimeDuration.from(diffInMilliseconds);
        }

        return null;
    }

    @computed public get day(): string | null {
        if (this.momentHelperTime === null) {
            return null;
        }
        const days = this.momentHelperTime.days();
        if (days <= 0) {
            return null;
        }
        if (days.toString().length === 1) {
            return `0${days.toString()}`;
        }

        return days.toString();
    }

    @computed public get hour(): string | null {
        if (this.momentHelperTime === null) {
            return null;
        }
        const hours = this.momentHelperTime.hours();
        if (hours <= 0 && this.day === null) {
            return null;
        }
        if (hours.toString().length === 1) {
            return `0${ hours.toString()}`;
        }

        return hours.toString();
    }

    @computed public get min(): string | null {
        if (this.momentHelperTime === null) {
            return null;
        }
        const minutes = this.momentHelperTime.minutes();
        if (minutes <= 0 && this.hour === null) {
            return null;
        }

        if (minutes.toString().length === 1) {
            return `0${ minutes.toString()}`;
        }

        return minutes.toString();
    }

    @computed public get sec(): string | null {
        if (this.momentHelperTime === null) {
            return null;
        }
        const seconds = this.momentHelperTime.seconds();
        if (seconds <= 0 && this.min === null) {
            return null;
        }
        if (seconds.toString().length === 1) {
            return `0${ seconds.toString()}`;
        }

        return seconds.toString();
    }

    @computed public get isEventStarted(): boolean {
        if (this.momentHelperTime === null) {
            return false;
        }
        const seconds = this.momentHelperTime.seconds();

        if (seconds <= 0 && this.min === null) {
            return true;
        }
        return false;
    }
}
