import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string;
}

const SvgElement = styled('svg', { label: 'SvgElement' }) <PropsType>`
    height: auto;
`;

export const WhatsappIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement
        className={className}
        width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'
        data-test='whatsapp-icon'
    >
        <mask
            id='a'
            width={28}
            height={28}
            x={2}
            y={2}
            maskUnits='userSpaceOnUse'
            style={{
                maskType: 'alpha',
            }}
        >
            <path
                fill='#1C2835'
                d='M29.334 8.733c-.015-.575-.06-1.149-.134-1.72-.1-.5-.265-.984-.493-1.44a4.88 4.88 0 0 0-.947-1.333 5.213 5.213 0 0 0-1.333-.947 5.708 5.708 0 0 0-1.44-.48 13.616 13.616 0 0 0-1.707-.146H8.734c-.576.015-1.15.06-1.72.133-.5.1-.984.266-1.44.493a4.88 4.88 0 0 0-1.334.947 5.213 5.213 0 0 0-.946 1.333c-.224.457-.385.941-.48 1.44a13.614 13.614 0 0 0-.147 1.707v14.547c.015.575.06 1.15.133 1.72.1.5.266.984.494 1.44.238.494.558.945.946 1.333.392.384.842.703 1.334.947a5.7 5.7 0 0 0 1.44.48c.565.084 1.135.133 1.706.146h14.547c.575-.015 1.15-.06 1.72-.133.5-.1.984-.265 1.44-.493a4.88 4.88 0 0 0 1.333-.947c.384-.392.703-.842.947-1.333.223-.457.385-.941.48-1.44.085-.565.134-1.135.147-1.707V8.733Zm-13.027 16.6a9.493 9.493 0 0 1-4.573-1.2l-5.067 1.334L8 20.507a9.48 9.48 0 0 1-1.333-4.774 9.574 9.574 0 1 1 9.64 9.6Zm0-17.506A8 8 0 0 0 9.574 20l.186.307-.8 2.92L12 22.4l.294.173a8 8 0 0 0 4 1.107 8 8 0 0 0 0-16l.013.147Zm4.667 11.36a2.427 2.427 0 0 1-1.614 1.133 3.106 3.106 0 0 1-1.493-.093c-.454-.142-.9-.311-1.333-.507a10.666 10.666 0 0 1-4.08-3.6 4.64 4.64 0 0 1-.974-2.467 2.668 2.668 0 0 1 .84-2 .869.869 0 0 1 .64-.293h.374c.146 0 .346 0 .533.413.187.414.68 1.654.747 1.774a.454.454 0 0 1 0 .413c-.06.145-.14.28-.24.4-.12.147-.254.32-.36.427-.107.106-.24.24-.107.48.363.612.812 1.169 1.333 1.653a6.665 6.665 0 0 0 1.92 1.187c.24.12.387.106.52 0 .134-.107.6-.694.76-.934.16-.24.32-.2.534-.12.213.08 1.4.654 1.64.774s.386.173.453.28a2.08 2.08 0 0 1-.093 1.04v.04Z'
            />
        </mask>
        <g mask='url(#a)'>
            <path fill='#fff' d='M0 0h32v32H0z' />
        </g>
    </SvgElement>
);
