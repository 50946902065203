import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { StreamWrapper } from './Header.style';

interface PropsType {
    streamUrl: string
}
export const Stream = observer('Stream', (({ streamUrl }: PropsType) => {
    return (
        <StreamWrapper>
            <iframe
                title='stream'
                src={streamUrl}
                height='400'
                width='600'
                frameBorder={0}
                scrolling='no'
                allowFullScreen={true}
            />
        </StreamWrapper>
    );
}));
