import React, { useRef, RefObject } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventMarkets } from 'src/domains/sportsbook/webview/components/eventMarkets/EventMarkets';
import { YouDecideHref } from 'src/domains/sportsbook/webview/components/matchCard/youDecideHeader/YouDecideHeaderHref';
import { SelectionRab } from 'src/domains/sportsbook/webview/components/selectionRab/SelectionRab';
import { SelectionRabCorrectScore } from 'src/domains/sportsbook/webview/components/selectionRab/selectionRabCorrectScore/SelectionRabCorrectScore';
import { YouDecide } from 'src/domains/sportsbook/webview/components/youDecide/YouDecide';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { MarketRabView } from 'src/domains/sportsbook/betting/state/rabState/Types';
import { MatchLeadWrapper } from 'src/domains/sportsbook/webview/components/matchLead/MatchLeadWrapper';
import { YouDecideHeader } from 'src/domains/sportsbook/webview/components/matchCard/youDecideHeader/YouDecideHeader';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { MarketFilters } from 'src/domains/sportsbook/webview/components/marketFilters/MarketFilters';
import { SelectionRabCombined } from 'src/domains/sportsbook/webview/components/selectionRab/selectionRabCombined/SelectionRabCombined';
import { EventsTableMarketsLoader } from 'src/domains/sportsbook/webview/components/loaders/EventsTableMarketsLoader';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import NFLlogo from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/Official_NFL_logo.webp';
import {
    EventsNFLLogoImage,
    EventsNFLLogoImageWrapper,
} from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable.style';
import {
    MatchCardSection,
    RabHeader,
    MatchCardContent,
    MatchCardContentWrapper,
    MatchCardContentMarkets,
    MatchCardContentMarketsRAB,
    MatchCardInfoWrapper,
} from './MatchCard.style';

const renderViewNormal = (
    marketsWrapperRef: RefObject<HTMLDivElement>,
    contentMarkets: React.ReactNode,
    getMarketsWrapperWidth: number | undefined,
    isYouDecideOpen: boolean,
    rabAvailable: boolean
): JSX.Element => {
    return (
        <MatchCardContentWrapper ref={marketsWrapperRef}>
            <MatchCardContentMarkets
                widthContent={getMarketsWrapperWidth}
                isYouDecideOpen={isYouDecideOpen}
                rabAvailable={rabAvailable}
            >
                {contentMarkets}
            </MatchCardContentMarkets>
        </MatchCardContentWrapper>
    );
};

const renderViewRab = (
    marketsWrapperRef: RefObject<HTMLDivElement>,
    contentMarkets: React.ReactNode,
    getMarketsWrapperWidth: number | undefined,
    isYouDecideOpen: boolean,
    rabAvailable: boolean,
    renderContentMarketsRAB: Array<JSX.Element>
): JSX.Element => {
    return (
        <MatchCardContentWrapper ref={marketsWrapperRef}>
            <MatchCardContentMarkets
                widthContent={getMarketsWrapperWidth}
                isYouDecideOpen={isYouDecideOpen}
                rabAvailable={rabAvailable}
            >
                {contentMarkets}
            </MatchCardContentMarkets>
            {rabAvailable ? (
                <MatchCardContentMarketsRAB
                    widthContent={getMarketsWrapperWidth}
                    isYouDecideOpen={isYouDecideOpen}
                    rabAvailable={rabAvailable}
                >
                    {renderContentMarketsRAB}
                </MatchCardContentMarketsRAB>
            ) : null}
        </MatchCardContentWrapper>
    );
};

interface MatchCardPropsType {
    eventId: EventId;
    isFooter: boolean;
}

export const MatchCard = observer('MatchCard', (props: MatchCardPropsType) => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const rabId = appSportsBookState.rab.getOrCreateRabBasket(props.eventId).id;

    const marketsWrapperRef = useRef<HTMLDivElement>(null);

    const reactiveWrapper: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);

    const elementRefHeight = (): number | null => {
        const elementRef = reactiveWrapper.ref;
        return elementRef?.clientHeight ?? null;
    };

    const onYouDecideClick = (): void => {
        appSportsBookState.rab.toggleOpenForEvent(props.eventId);
    };

    const getMarketsWrapperWidth = (): number | undefined => {
        const windowWidth = getWindowInnerWidth();

        const { desktop } = breakpointsState;

        if (windowWidth === null) {
            return undefined;
        }

        if (desktop.isBiggerOrEq === false) {
            return windowWidth;
        }
        return marketsWrapperRef.current?.getBoundingClientRect().width;
    };

    const renderRABBottomBetslip = (): JSX.Element | null => {
        const { isFooter } = props;

        return (
            <YouDecide
                setRef={reactiveWrapper}
                isFooterVisible={isFooter}
                rabId={rabId}
            />
        );
    };

    const renderRABHeader = (isYouDecideOpen: boolean): JSX.Element => {
        return (
            <>
                <YouDecideHeader />

                <YouDecideHref
                    onClick={onYouDecideClick}
                    isYouDecideOpen={isYouDecideOpen}
                />
            </>
        );
    };

    const renderRAB = (): JSX.Element => {
        const { isBetBuilderOpen: isYouDecideOpen } = appSportsBookState.rab;
        return <RabHeader isYouDecideOpen={isYouDecideOpen}>{renderRABHeader(isYouDecideOpen)}</RabHeader>;
    };

    const mapItem = (item: MarketRabView, index: number): JSX.Element | null => {
        if (item.viewDetails.selectionDisplayType === 'CorrectScore') {
            return (
                <SelectionRabCorrectScore
                    rabId={rabId}
                    rabMarket={item}
                    key={item.name}
                    order={index}
                />
            );
        }

        if (item.templateType === 'FirstNextGoalScorer') {
            return (
                <SelectionRabCombined
                    templateType={item.templateType}
                    rabId={rabId}
                    marketName='Player to score'
                    order={index}
                    firstMarketName='First/next goalscorer'
                    secondMarketName='Anytime scorer'
                />
            );
        }

        if (item.templateType === 'FirstNextCardedPlayer') {
            return (
                <SelectionRabCombined
                    templateType={item.templateType}
                    rabId={rabId}
                    marketName='Player to be carded'
                    order={index}
                    firstMarketName='First/next carded player'
                    secondMarketName='Anytime carded player'
                />
            );
        }

        if (item.templateType === 'AnytimeScorer' || item.templateType === 'AnytimeCardedPlayer') {
            return null;
        }

        return (
            <SelectionRab
                rabId={rabId}
                rabMarket={item}
                key={item.name}
                order={index}
            />
        );
    };

    const renderContentMarketsRAB = (): Array<JSX.Element> => {
        const marketsForView = rabId.getModel().marketsForView ?? [];

        const result: Array<JSX.Element> = [];

        for (const [index, item] of marketsForView.entries()) {
            const itemElement = mapItem(item, index);

            if (itemElement !== null) {
                result.push(itemElement);
            }
        }

        return result;
    };

    const contentMarkets = (): React.ReactNode => {
        const { eventId } = props;
        const actualEvent = eventId.getEventModel();
        if (actualEvent === null) {
            return (
                <MatchCardContent key='match-card-content'>
                    <EventsTableMarketsLoader />
                </MatchCardContent>
            );
        }

        const eventMarkets = appSportsBookState.getMarketFiltersBySport(actualEvent.sport, eventId).marketsForView;

        return (
            <MatchCardContent key='match-card-content'>
                {eventMarkets.length > 0 ? (
                    <EventMarkets
                        eventId={eventId}
                        markets={eventMarkets}
                    />
                ) : actualEvent.allMarketsLoading ? null : (
                    <MatchCardInfoWrapper>Missing markets</MatchCardInfoWrapper>
                )}
                {actualEvent.allMarketsLoading ? <EventsTableMarketsLoader /> : null}
            </MatchCardContent>
        );
    };

    const { isFooter, eventId } = props;
    const rabAvailable = appSportsBookState.rab.isAvailableForEvent(eventId);

    const event = eventId.getEventModel();

    const { isBetBuilderOpen: isYouDecideOpen } = appSportsBookState.rab;
    const isEventStarted = event?.timeSettingsStarted;

    if (event === null) {
        return null;
    }

    return (
        <MatchCardSection
            isFooterVisible={isFooter}
            rabBetslipHeight={elementRefHeight()}
        >
            <>
                <MatchLeadWrapper event={event} />
                {rabAvailable ? renderRAB() : null}
            </>

            {isYouDecideOpen === false ? (
                <MarketFilters
                    sport={event.sport}
                    eventId={eventId}
                />
            ) : null}

            {rabAvailable === true && isEventStarted === false
                ? renderViewRab(
                      marketsWrapperRef,
                      contentMarkets(),
                      getMarketsWrapperWidth(),
                      isYouDecideOpen,
                      rabAvailable,
                      renderContentMarketsRAB()
                  )
                : renderViewNormal(
                      marketsWrapperRef,
                      contentMarkets(),
                      getMarketsWrapperWidth(),
                      isYouDecideOpen,
                      rabAvailable
                  )}

            {isYouDecideOpen === true && rabAvailable === true && isEventStarted === false
                ? renderRABBottomBetslip()
                : null}

            {event?.sport === 'americanfootball' ? (
                <EventsNFLLogoImageWrapper>
                    <EventsNFLLogoImage
                        src={NFLlogo}
                        alt='Official NFL logo'
                    />
                </EventsNFLLogoImageWrapper>
            ) : null}
        </MatchCardSection>
    );
});
