import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const AccordionHeader = withConfig(theme => styled('div', { label: 'AccordionHeader' })`
    align-items: center;
    background-color: ${theme.star.headings.section.bgColor};
    cursor: pointer;
    display: flex;
    padding: 8px 0;
`);

export const ChevronAccordionWrapper = withConfig(theme => styled(ChevronIcon, { label: 'ChevronAccordionWrapper' })`
    fill: ${theme.star.headings.section.txtColor};
    height: 100%;
    margin: 0 4px 0 8px;
    width: 16px;
`);

export const TitleStyle = withConfig(theme => styled('div', { label: 'TitleStyle' })`
    color: ${theme.star.headings.section.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    text-transform: uppercase;
    user-select: none;
`);

export const MeetingSectionHR = withConfig(theme => styled('hr', { label: 'MeetingSectionHR' })`
    background-color: ${theme.star.headings.section.borderColor};
    border: 0;
    display: block;
    height: 1px;
    margin: 0;
`);
