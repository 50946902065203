import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppState } from 'src/appState/AppState';
import { TabLabel, TabWrapper } from 'src/domains/layouts/webview/components/debugPanel/DebugPanel.style';
import { action, computed, makeObservable, observable } from 'mobx';
import { assertNever } from 'src_common/common/assertNever';
import { RTLPragmatic } from './MainViewCasino/rtl-pragmatic/RTLPragmatic';
import { GamesInspector } from './MainViewCasino/games-inspector/GamesInspector';
import { RTLDebug } from './MainViewCasino/rtl/RTLDebug';
import { CollectionType } from 'src/domains/casino/state/AppCasino.state';

type CasinoTabId = 'games_inspector' | 'rtl' | 'rtl_pragmatic';
type CasinoTabType = {
    id: CasinoTabId;
    label: string;
};

class NavigationState {
    @observable private selectedTabId: CasinoTabId | null = null;
    @observable public selectedWebsiteCollection: CollectionType | null = null;
    @observable public selectedWebsiteTabId: string | null = null;

    public constructor(
        private readonly appState: AppState,
    ) {
        makeObservable(this);

        this.appState.appLayoutsState.starRouter.onChangeCurrentView((prevCurrentView, nextCurrentView) => {
            if (nextCurrentView?.name === 'casino' || nextCurrentView?.name === 'live-casino') {
                this.selectWebsiteCollection(nextCurrentView.name);
                this.selectWebsiteTabId(nextCurrentView.tabId ?? null);
            } else if (nextCurrentView?.name === 'virtuals') {
                this.selectWebsiteCollection(nextCurrentView.name);
                this.selectWebsiteTabId(null);
            } else {
                this.selectWebsiteCollection(null);
                this.selectWebsiteTabId(null);
            }
            console.log('debugPrevView: ', prevCurrentView, 'debugNextView: ', nextCurrentView);
        });
    }

    @computed public get tabList(): Array<CasinoTabType> {
        return [
            {
                id: 'games_inspector',
                label: 'Games Inspector',
            },
            {
                id: 'rtl',
                label: 'RTL',
            },
            {
                id: 'rtl_pragmatic',
                label: 'RTL Pragmatic',
            }
        ];
    }

    @computed private get firstTabId(): CasinoTabId | null {
        if (this.tabList[0] === undefined) {
            return null;
        }
        return this.tabList[0].id;
    }

    @computed public get activeTabId(): CasinoTabId | null {
        return this.selectedTabId ?? this.firstTabId;
    }

    @action public selectTab = (tabId: CasinoTabId): void => {
        this.selectedTabId = tabId;
    };

    @action public selectWebsiteCollection = (websiteCollection: CollectionType | null): void => {
        this.selectedWebsiteCollection = websiteCollection;
    };

    @action public selectWebsiteTabId = (websiteTabId: string | null): void => {
        this.selectedWebsiteTabId = websiteTabId;
    };
}

const renderTabContent = (navigationState: NavigationState, appState: AppState): React.ReactNode => {
    const { activeTabId } = navigationState;

    if (activeTabId === null) {
        return null;
    }

    if (activeTabId === 'games_inspector') {
        return <GamesInspector
            appState={appState}
            collection={navigationState.selectedWebsiteCollection}
            tabId={navigationState.selectedWebsiteTabId} />;
    }

    if (activeTabId === 'rtl') {
        return <RTLDebug
            appState={appState}
            collection={navigationState.selectedWebsiteCollection}
            tabId={navigationState.selectedWebsiteTabId} />;
    }

    if (activeTabId === 'rtl_pragmatic') {
        return <RTLPragmatic appState={appState}></RTLPragmatic>;
    }

    assertNever('Debug Panel Tab Id ', activeTabId);
};

interface CasinoTabPropsType {
    navigationState: NavigationState;
    id: CasinoTabId;
    label: string;
}

const CasinoTab = observer('CasinoTab', (props: CasinoTabPropsType) => {
    const handleTabClick = (): void => {
        props.navigationState.selectTab(props.id);
    };

    const isActive = props.navigationState.activeTabId === props.id;

    return (
        <TabLabel active={isActive} onClick={handleTabClick}>{props.label}</TabLabel>
    );
});

interface PropsTypes {
    appState: AppState;
}

export const MainViewCasino = observer('MainViewLiveCasino', (props: PropsTypes) => {
    const [navigationState] = React.useState(() => new NavigationState(props.appState));

    return (
        <>
            <TabWrapper>
                {navigationState.tabList.map((tab) =>
                    <CasinoTab
                        key={tab.id}
                        navigationState={navigationState}
                        id={tab.id}
                        label={tab.label}
                    ></CasinoTab>
                )}
            </TabWrapper>

            {renderTabContent(navigationState, props.appState)}
        </>
    );
});
