import * as t from 'io-ts';
import { SubscriptionResourceIdIO, SubscriptionResourceIdType } from './subscriptionId';

const MessageSubscriptionsIO = t.interface({
    type: t.literal('SubscriptionValue'),
    data: t.array(t.interface({
        id: SubscriptionResourceIdIO,
        active: t.boolean
    })),
});

type MessageSubscriptionsType = t.TypeOf<typeof MessageSubscriptionsIO>;

export const createMessageSubscriptions = (data: Array<{id: SubscriptionResourceIdType; active: boolean}>): MessageSubscriptionsType => ({
    type: 'SubscriptionValue',
    data,
});
