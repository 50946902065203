import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { RaceBoxSelection } from 'src/domains/sportsbook/webview/components/raceBoxSelection/RaceBoxSelection';
import { RaceBoxState } from './RaceBoxState';
import {
    HeaderRaceDistance,
    HeaderRaceName,
    HeaderRaceTime,
    StreamIco,
    ShowMoreLink,
    RaceBoxListElement,
    RaceBoxList,
    ShowMoreArrowWrapper,
    HeaderLinkWrapper,
} from './RaceBox.style';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';

export interface RaceBoxPropsType {
    race: EventModel;
    market: MarketModel;
    items: number;
    defaultSilk: string;
    sortSelections: (list: Array<SelectionModel>) => Array<SelectionModel>;
    moreInfo: boolean;
    extendSelection: boolean;
}

export const RaceBox = observer('RaceBox', (props: RaceBoxPropsType) => {
    const { appSportsBookState, config } = useAppStateContext();
    const [state] = useState(() => new RaceBoxState(props, config, appSportsBookState));
    const { defaultSilk, moreInfo, extendSelection } = props;

    return (
        <>
            <HeaderLinkWrapper dataTest='event' to={state.routingData} key='race-box__header-link'>
                <HeaderRaceName hasIcon={state.hasStream}>
                    {state.hasStream ? <StreamIco /> : null}
                    {state.name}
                </HeaderRaceName>

                <HeaderRaceTime displaySeparator={state.distance !== undefined}>{state.time}</HeaderRaceTime>

                <HeaderRaceDistance>{state.distance}</HeaderRaceDistance>
            </HeaderLinkWrapper>

            <RaceBoxList key='race-box-selections'>
                {state.itemsForView?.map((selection, index) => (
                    <RaceBoxListElement key={`${selection.id2.toOldId()}-${index}`}>
                        <RaceBoxSelection
                            extendSelection={extendSelection}
                            moreInfo={moreInfo}
                            defaultSilk={defaultSilk}
                            selectionId={selection.id2}
                        />
                    </RaceBoxListElement>
                ))}
                <RaceBoxListElement>
                    <ShowMoreLink to={state.routingData}>
                        <I18n langKey='events.racebox.view.label' defaultText='View racecard' />
                        <ShowMoreArrowWrapper position='right' />
                    </ShowMoreLink>
                </RaceBoxListElement>
            </RaceBoxList>
        </>
    );
});
