import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface IconPropsType {
    className?: string;
    onClick?: () => void;
}

export const PortugeseBrazilFlagIcon = observer(
    'PortugeseBrazilFlagIcon',
    ({ className, onClick }: IconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='none'
                onClick={onClick}
                width='100%' height='100%' viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect width='16' height='16' fill='#249F58' rx='8'/>
                <path fill='#FFDA2C' fillRule='evenodd' d='M8 3.307 14.4 8 8 12.693 1.6 8' clipRule='evenodd'/>
                <path fill='#1A47B8' d='M8 10.987a2.987 2.987 0 1 0 0-5.974 2.987 2.987 0 0 0 0 5.974Z'/>
                <path fill='#fff' fillRule='evenodd' d='M6.72 9.28v.853h.853V9.28H6.72Zm1.707 0v.853h.853V9.28h-.853ZM5.867 6.72s1.932.341 3.324.937l1.796.77' clipRule='evenodd'/>
                <path stroke='#fff' d='M5.867 6.72s1.932.341 3.324.937l1.796.77'/>
            </SvgElement>
        );
    }
);
