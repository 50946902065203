import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { PolicyContent, HelpBoxTitle, HelpBoxSubHeader, ArrowBack } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/McBookieResetPasswordFormTerms.style';

interface PropsType {
    id: string
}

export const Policy = observer('Policy', (props: PropsType) => {
    const { id } = props;
    const { appPlayersState } = useAppStateContext();

    const staticPage = appPlayersState.usersState.getPage(id);
    const staticPageData = staticPage.type === 'ready' ? staticPage.value : null;

    if (staticPageData !== null && staticPageData !== undefined) {
        const { content } = staticPageData;

        return <PolicyContent dangerouslySetInnerHTML={{ __html: content }}/>;

    }

    return (
        <React.Fragment>
            <HelpBoxSubHeader>
                <HelpBoxTitle>404 not found</HelpBoxTitle>
                <ArrowBack position='left' />
            </HelpBoxSubHeader>

            <PolicyContent>404 NOT FOUND</PolicyContent>
        </React.Fragment>
    );
});
