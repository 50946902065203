import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { PaymentMethodDropdown } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/paymentMethodDropdown/PaymentMethodDropdown';
import {
    WithdrawForm,
    WithdrawProcedureBtnWrapper,
    BalanceRowWrapper,
    WithdrawInput,
    BalanceWrapper,
    BalanceValue,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawProcedure.style';
import { WithdrawFormState } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/WithdrawFormState';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const preventDefault = (event: React.FormEvent<HTMLFormElement>): void => event.preventDefault();

interface WithdrawProcedurePropType {
    state: WithdrawFormState;
    paymentMethodDropdownState: PaymentMethodDropdownState;
}

export const WithdrawProcedure = observer(
    'WithdrawProcedure',
    ({ state, paymentMethodDropdownState }: WithdrawProcedurePropType) => {
        const {
            appPlayersState: {
                usersState: { moneySymbol },
            },
        } = useAppStateContext();

        return (
            <>
                <BalanceWrapper>
                    <span data-test='withdrawable-balance-label'>
                        <I18n langKey='account.withdraw.cash-balance-label' defaultText='Withdrawable balance' />
                    </span>
                    <BalanceValue data-test='balance-value'>{state.showBalance}</BalanceValue>
                </BalanceWrapper>

                <WithdrawForm onSubmit={preventDefault}>
                    <BalanceRowWrapper key='payment-rows'>
                        <WithdrawInput
                            type='text'
                            maxLength={10}
                            placeholder='0.00'
                            label={
                                <I18n langKey='account.withdraw.description-label' defaultText='Amount to withdraw' />
                            }
                            currency={moneySymbol}
                            state={state.amountState}
                            onChange={state.handleAmountInputChange}
                            onBlur={state.setAmount}
                            colorTheme='light'
                            inputHasBackground={false}
                            dataTest='withdraw-amount-input'
                        />
                    </BalanceRowWrapper>
                    <PaymentAdditions
                        key='payment-additions'
                        value={state.amountState.value}
                        currency={moneySymbol}
                        onChange={state.handlePaymentAdditionsChange}
                    />

                    <PaymentMethodDropdown
                        key='payment-method-dropdown'
                        label={<I18n langKey='account.top-up.withdraw-method.select.label' defaultText='Withdraw to' />}
                        options={paymentMethodDropdownState.savedPaymentMethods}
                        value={paymentMethodDropdownState.paymentMethodsValue}
                        onChangeCB={paymentMethodDropdownState.handlePaymentSelectChange}
                    />

                    {state.withdrawErrorMessage === null ? null : (
                        <Messages type='error' message={state.withdrawErrorMessage} />
                    )}

                    {state.withdrawSuccessMessage === null ? null : (
                        <Messages marginTop='8px' type='success' message={state.withdrawSuccessMessage} />
                    )}

                    <WithdrawProcedureBtnWrapper
                        type='submit'
                        size='large'
                        onClick={state.submitWithdrawForm}
                        disabled={state.isWithdrawFormSubmitting}
                        dataTest='withdraw-procedure-btn'
                    >
                        <I18n langKey='account.withdraw.submit.label' defaultText='Withdraw' />
                    </WithdrawProcedureBtnWrapper>
                </WithdrawForm>
            </>
        );
    }
);
