import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ChangePasswordProcedureState } from './ChangePasswordProcedure.state';

import {
    FormWrapper,
    UpdateButton,
} from 'src/domains/players/webview/components/Account/personalDetailsTab/PersonalDetailsTab.styles';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const ChangePasswordProcedure = observer('ChangePasswordProcedure', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { accountState } = appPlayersState;
    const { languagesState } = appLayoutsState;
    const [changePasswordState] = React.useState(() => new ChangePasswordProcedureState(accountState, languagesState));
    const isFormLoading = changePasswordState.isLoading === true;

    const passwordChangedMessage = languagesState.getTranslation(
        'account.password-procedure.success-message',
        'You have successfully changed your password.'
    );

    return (
        <FormWrapper>
            <Input
                type='password'
                label={<I18n langKey='account.password-procedure.old-password.label' defaultText='*Old password' />}
                placeholder={languagesState.getTranslation(
                    'account.password-procedure.old-password.placeholder',
                    '*Old password'
                )}
                state={changePasswordState.oldPasswordState}
                colorTheme='light'
                dataTest='old-password-input'
                autocomplete='new-password'
            />
            <Input
                type='password'
                label={<I18n langKey='account.password-procedure.new-password.label' defaultText='*New password' />}
                placeholder={languagesState.getTranslation(
                    'account.password-procedure.new-password.placeholder',
                    '*New password'
                )}
                state={changePasswordState.newPasswordState}
                colorTheme='light'
                dataTest='new-password-input'
                autocomplete='new-password'
            />
            <Input
                type='password'
                label={
                    <I18n langKey='account.password-procedure.confirm-password.label' defaultText='*Confirm password' />
                }
                placeholder={languagesState.getTranslation(
                    'account.password-procedure.confirm-password.placeholder',
                    '*Confirm password'
                )}
                state={changePasswordState.confirmPasswordState}
                colorTheme='light'
                dataTest='confirm-password-input'
                autocomplete='new-password'
            />

            {changePasswordState.isSuccessChangePassword === true ? (
                <Messages type='success' marginTop='8px' message={passwordChangedMessage} />
            ) : null}

            <UpdateButton
                size='large'
                onClick={changePasswordState.onSubmit}
                isLoading={isFormLoading}
                dataTest='update-password-button'
                disabled={changePasswordState.isButtonDisabled}
            >
                <I18n langKey='account.password-procedure.submit.label' defaultText='Update Password' />
            </UpdateButton>
        </FormWrapper>
    );
});
