//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/customer/wallet -> get -> ${code}`);
    };
};

export const Response200IO = t.interface({
    backofficeWithdrawableBalance: t.number,
    backofficeWithdrawableBalancePrecise: t.string,
    balance: t.number,
    balancePrecise: t.string,
    createdAt: t.string,
    creditLimit: t.number,
    creditLimitPrecise: t.string,
    currency: t.string,
    fundsLocked: t.number,
    fundsLockedPrecise: t.string,
    id: t.string,
    lastBetAt: t.string,
    operatorAccumulatedProfit: t.number,
    operatorAccumulatedProfitPrecise: t.string,
    operatorBalance: t.number,
    operatorBalancePrecise: t.string,
    operatorExternalProfit: t.number,
    operatorExternalProfitPrecise: t.string,
    operatorProfit: t.number,
    operatorProfitPrecise: t.string,
    playableBalance: t.number,
    playableBalancePrecise: t.string,
    profit: t.number,
    profitPrecise: t.string,
    universe: t.string,
    updatedAt: t.string,
    withdrawableBalance: t.number,
    withdrawableBalancePrecise: t.string,
});
export type Response200Type = {
    backofficeWithdrawableBalance: number;
    backofficeWithdrawableBalancePrecise: string;
    balance: number;
    balancePrecise: string;
    createdAt: string;
    creditLimit: number;
    creditLimitPrecise: string;
    currency: string;
    fundsLocked: number;
    fundsLockedPrecise: string;
    id: string;
    lastBetAt: string;
    operatorAccumulatedProfit: number;
    operatorAccumulatedProfitPrecise: string;
    operatorBalance: number;
    operatorBalancePrecise: string;
    operatorExternalProfit: number;
    operatorExternalProfitPrecise: string;
    operatorProfit: number;
    operatorProfitPrecise: string;
    playableBalance: number;
    playableBalancePrecise: string;
    profit: number;
    profitPrecise: string;
    universe: string;
    updatedAt: string;
    withdrawableBalance: number;
    withdrawableBalancePrecise: string;
};
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiProxyCustomerWalletRequest"
 */
export const openapi_proxy_customer_wallet = async (api_url: string, api_timeout: number, backendToken: string, _params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/customer/wallet${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiProxyCustomerWalletParamsType = ParamsType;

export type OpenapiProxyCustomerWalletResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiProxyCustomerWalletResponse200Type = Response200Type;

export const openapiProxyCustomerWalletRequest = async (api_url: string, api_timeout: number, backendToken: string, _params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiProxyCustomerWalletResponseType> => {
    const response = await openapi_proxy_customer_wallet(api_url, api_timeout, backendToken, _params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiProxyCustomerWalletRequest - unhandled response ${response.status}`);
};

