import styled from '@emotion/styled';
import { SelectNew } from 'src/domains/players/webview/components/form/selectNew/SelectNew';

export const WithdrawProcedureWrapper = styled('div', { label: 'WithdrawProcedureWrapper' })`
    padding: 16px;
`;

export const WithdrawContainer = styled('div', { label: 'WithdrawContainer' })`
    margin-top: 16px;
`;

export const WithdrawTypeSelect = styled(SelectNew, { label: 'WithdrawTypeSelect' })`
    margin: 0;
`;
