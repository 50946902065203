import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BreadcrumbsCircles } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/breadcrumbs/breadcrumbsCircles/BreadcrumbsCircles';
import { ChevronLeftIconWrapper } from './BreadcrumbsPanel.styles';
import { SignUpState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUp.state';

interface PropsType {
    signUpState: SignUpState;
}
export const BreadcrumbsPanel = observer('BreadcrumbsPanel', ({ signUpState }: PropsType) => {
    const { onMoveBack, isStepWithBackOption } = signUpState.currentView;

    return (
        <>
            {isStepWithBackOption === true ? <ChevronLeftIconWrapper position='left' onClick={onMoveBack} /> : null}
            <BreadcrumbsCircles signUpState={signUpState} />
        </>
    );
});
