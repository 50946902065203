import styled from '@emotion/styled';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { withConfig } from 'src/withConfig';

export const CaptionWrapper = withConfig(theme => styled('div', { label: 'CaptionWrapper' })`
    background: ${theme.star.accountHistoryHeader.bgColor};
    color: ${theme.star.accountHistoryHeader.txtColor};
    display: flex;
    flex-flow: row nowrap;
    line-height: 1.4;
    margin: 0 -10px;
    padding: 7px 10px 6px;
`);

export const TransactionListDay = withConfig(theme => styled('div', { label: 'TransactionListDay' })`
    color: ${theme.star.transactionHistory.txtColorQuaternary};
    line-height: 1.4;
    font-size: ${theme.star.fontSize.xSmall};
`);

export const HeaderElem = withConfig(theme => styled('span', { label: 'HeaderElem' })`
    flex: 0 0 87px;
    font-size: ${theme.star.fontSize.xSmall};
    &:first-of-type {
        text-align: left;
        flex: 1 1 0%;
    }
`);

export const HeaderElemNetDeposit = withConfig(theme => styled('span', { label: 'HeaderElemNetDeposit' })`
    width: 33.333%;
    text-align: left;
    font-size: ${theme.star.fontSize.xSmall};
`);

export const BetsListWrapper = withConfig(theme => styled(AccountContent, { label: 'BetsListWrapper' })`
    font-size: ${theme.star.fontSize.small};
    padding: 0 16px 16px;
`);

interface TransactionType {
    type?: string;
}

export const BetsListBet = withConfig(theme => styled('div', { label: 'BetsListBet' })<TransactionType>`
    display: flex;
    flex-wrap: nowrap;
    line-height: 1.34;
    padding: ${({ type }): string => type === 'net-deposit' ? '24px 0' : '10px 0'};
    position: relative;
    &::after {
        border-bottom: 1px solid ${theme.star.transactionHistory.borderColor};
        bottom: 0;
        content: '';
        left: -10px;
        position: absolute;
        right: -10px;
    }
`);

export const StatusStyle = withConfig(theme => styled('div', { label: 'StatusStyle' })<{status: 'pending' | 'fail'}>`
    font-size: ${theme.star.fontSize.xSmall};
    background-color: ${({ status }): string => status === 'fail' ? theme.star.transactionHistory.bgColor : theme.star.transactionHistory.bgColorSecondary};
    margin-left: 4px;
    line-height: 1;
    padding: 2px 4px;
`);

export const NameStyle = withConfig(theme => styled('div', { label: 'NameStyle' })`
    display: flex;
    align-items: center;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.4;
`);

export const TransactionListLoaderWrapper = styled(Loader, { label: 'TransactionListLoaderWrapper' })`
    color: currentcolor;
    justify-content: center;
    margin-top: 10px;
`;

export const TransactionHistoryItemWrapper = withConfig(theme => styled('div', { label: 'TransactionHistoryItemWrapper' })`
    font-size: ${theme.star.fontSize.small};
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 0%;
`);

export const TransactionHistoryItem = styled('div', { label: 'TransactionHistoryItem' })`
    max-width: 100%;
    flex: 1 0 80px;
`;

export const TransactionListEmptyMessageContainer = withConfig(theme => styled('div', { label: 'TransactionListEmptyMessageContainer' })`
    font-size: ${theme.star.fontSize.small};
    margin: 8px 16px;
    padding: 8px;

    --border-color: ${theme.star.transactionHistory.borderColorSecondary};
    --linear-gradient: var(--border-color),
        var(--border-color) 9px,
        transparent 9px,
        transparent 18px,
        var(--border-color) 18px;

    background-image: repeating-linear-gradient(0deg, var(--linear-gradient)),
        repeating-linear-gradient(90deg, var(--linear-gradient)),
        repeating-linear-gradient(180deg, var(--linear-gradient)),
        repeating-linear-gradient(270deg, var(--linear-gradient));

    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 1px 100%,
        100% 1px,
        1px 100%,
        100% 1px;
`);


export const TransactionListMessage = styled('div', { label: 'TransactionListMessage' })`
    padding: 0 10px;
    text-align: center;
`;
