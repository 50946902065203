import React, { useEffect } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { RacingStream } from 'src/domains/sportsbook/webview/components/stream/RacingStream';
import { autorun } from 'mobx';
import { RacingStreamGlobalState } from 'src/domains/sportsbook/webview/components/stream/RacingStreamGlobal.state';
import { RacingStreamRMG } from 'src/domains/sportsbook/webview/components/stream/RacingStreamRMG';
import { observer } from 'src/utils/mobx-react';

export interface RacingStreamGlobalPropsTypes {
    isRaceCard: boolean;
    isAnyCasinoPage: boolean;
    isNotificationsList: boolean;
}

export const RacingStreamGlobal = observer(
    'RacingStreamGlobal',
    (props: RacingStreamGlobalPropsTypes): JSX.Element | null => {
        const {
            appSportsBookState: { streamingState },
        } = useAppStateContext();
        const [state] = React.useState(() => new RacingStreamGlobalState(props, streamingState));

        useEffect(() => {
            const dispose = autorun(state.runObserve);
            return (): void => {
                dispose();
            };
        }, []);

        if (props.isRaceCard) {
            return null;
        }

        const { providerName } = streamingState;

        return providerName === 'RMG' ? (
            <RacingStreamRMG isNotificationsList={props.isNotificationsList} streamIsInViewport={false} />
        ) : (
            <RacingStream isNotificationsList={props.isNotificationsList} />
        );
    }
);
