import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    StyledSelectedContainer,
    StyledContainer,
    StyledDropdownMenu,
    StyledDropdownMenuItem,
    SortIconWrapper,
} from './Dropdown.style';
import { DropdownState } from './Dropdown.state';
import { useAsObservableSource } from 'mobx-react-lite';
import { CheckMark } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/dropdown/icons/CheckMark';

type DropdownOption = {
    label: string;
    value: string;
    onClick: VoidFunction;
};

interface DropdownProps {
    selected: string | null;
    options: DropdownOption[];
}

export const Dropdown = observer('Dropdown' ,(propsIn: DropdownProps): JSX.Element => {
    const { options, selected } = useAsObservableSource(propsIn);
    const [{ handleOpen, open }] = React.useState(() => new DropdownState());

    return (
        <StyledContainer>
            <SortIconWrapper onClick={handleOpen} />

            {open ? (
                <StyledDropdownMenu>
                    {options.map(({ label, onClick, value }) => {
                        const handleClick = (): void => {
                            onClick();
                            handleOpen();
                        };
                        const isSelected = selected === value;

                        return (
                            <StyledSelectedContainer selected={isSelected} onClick={handleClick} key={value}>
                                <StyledDropdownMenuItem>
                                    {label}
                                </StyledDropdownMenuItem>
                                {isSelected && <CheckMark />}
                            </StyledSelectedContainer>
                        );
                    })}
                </StyledDropdownMenu>
            ) : null}
        </StyledContainer>
    );
});

