import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    LogoRow,
    ResponsibleInfo,
    SingleItem,
    SingleItemLink,
    LogoGameCareSwiftyWrapper,
    Logo18PlusWrapper,
    SingleItemAntillephone,
    ValidationScriptImgWrapper,
    ValidationScriptLinkWrapper,
} from './ResponsibleGambling.style';
import { useAppStateContext } from 'src/appState/AppState';
import { FooterHeader } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent.style';
import { ResponsibleGamblingInfo } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/responsibleGambling/ResponsibleGamblingDefault';

const FooterAntillephoneScript = observer(
    'FooterAntillephoneScript',
    ({ antillephoneId }: { antillephoneId: string }) => {
        React.useEffect(() => {
            setTimeout(() => {
                const widgetScript = document.createElement('script');
                widgetScript.type = 'text/javascript';

                widgetScript.src = `https://${antillephoneId}.snippet.antillephone.com/apg-seal.js`;

                document.body.appendChild(widgetScript);
            }, 0);
        }, []);

        return (
            <SingleItemAntillephone>
                <div
                    data-apg-image-size='256'
                    data-apg-image-type='basic-light-large'
                    data-apg-seal-id={antillephoneId}
                    id={`apg-${antillephoneId}`}
                />
            </SingleItemAntillephone>
        );
    }
);

interface FooterCEGScriptPropsType {
    links: {
        iconUrl: string;
        verificationUrl: string;
    };
}

const FooterCEGScript = observer('FooterCEGScript', (props: FooterCEGScriptPropsType) => {
    return (
        <ValidationScriptLinkWrapper to={props.links.verificationUrl}>
            <ValidationScriptImgWrapper
                src={props.links.iconUrl}
                alt='Licence Seal'
            />
        </ValidationScriptLinkWrapper>
    );
});

export const ResponsibleGamblingVerificationScript = observer('ResponsibleGamblingSwifty', (): JSX.Element => {
    const { appLayoutsState } = useAppStateContext();
    const {
        languagesState,
        configComponents: { config },
    } = appLayoutsState;
    const gamcareLabel = languagesState.getTranslation(
        'footer.responsible.gambling.gamcare.label',
        'read more about gamcare'
    );
    const antillephoneId = config.antillephoneId;
    return (
        <>
            <FooterHeader>
                <I18n
                    langKey='footer.responsible.gambling'
                    defaultText='Responsible Gambling'
                />
            </FooterHeader>

            <LogoRow>
                <SingleItem>
                    <Logo18PlusWrapper />
                </SingleItem>

                <SingleItem>
                    <SingleItemLink
                        aria-label={gamcareLabel}
                        to='https://www.gamcare.org.uk/self-help/links-to-other-support-agencies/international-support-contacts/'
                    >
                        <LogoGameCareSwiftyWrapper />
                    </SingleItemLink>
                </SingleItem>

                {config.footerCEGLinks === null ? null : <FooterCEGScript links={config.footerCEGLinks} />}
                {antillephoneId === null || config.antillephoneDisplaySector !== 'responsible-gambling' ? null : (
                    <FooterAntillephoneScript antillephoneId={antillephoneId} />
                )}
            </LogoRow>

            <ResponsibleInfo>
                <ResponsibleGamblingInfo />
            </ResponsibleInfo>
        </>
    );
});
