import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { Amount } from 'src_common/common/amount/Amount';
import { BoostWrapperIcon, SelectionBoostedMessageWrapper, SelectionBoostedValue } from './SelectionBoostedMessage.style';

interface PropsTypes {
    value: number | undefined;
    potentialReturns: Amount | null;
    betHistory?: boolean;
};

export const SelectionBoostedMessage = observer('SelectionBoostedMessage',({ value, potentialReturns, betHistory }: PropsTypes): JSX.Element => {
    const { appLayoutsState } = useAppStateContext();
    const { sdkCustomer, configComponents } = appLayoutsState;

    const getSelectionBoostedValue = (): string => {
        if (value !== undefined && potentialReturns !== null) {
            const newPotentialReturns = potentialReturns;
            const newValue = configComponents.precision.newFromOldAndFloor(value * 100);

            return sdkCustomer.money(newPotentialReturns.add(newValue));
        }

        return '-';
    };

    return (
        <SelectionBoostedMessageWrapper betHistory={betHistory}>
            <BoostWrapperIcon betHistory={betHistory}/>
            Your boosted return
            <SelectionBoostedValue betHistory={betHistory}>
                {getSelectionBoostedValue()}
            </SelectionBoostedValue>
        </SelectionBoostedMessageWrapper>
    );
});
