import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SectionHeaderWrapper, ChevronStyle, TitleStyle } from './SectionHeader.style';

interface PropsTypes {
    title: React.ReactNode;
    isOpen: boolean;
    handleOnToggle: () => void;
    dataTest?: string;
}

export const SectionHeader = observer('SectionHeader', (props: PropsTypes) => {
    const { title, handleOnToggle, isOpen, dataTest } = props;

    return (
        <SectionHeaderWrapper onClick={handleOnToggle} data-test={dataTest}>
            <ChevronStyle position={isOpen ? 'down' : 'right'} />
            <TitleStyle data-test='section-title'>{title}</TitleStyle>
        </SectionHeaderWrapper>
        
    );
});
