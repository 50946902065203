import { autorun } from 'mobx';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';

interface InputParam<T> {
    key: string,
    decode: (data: unknown) => T | Error,
    defaultValue: T,
    useSessionStorage?: boolean
}

const getStartValue = <T>(isBrowser: boolean, params: InputParam<T>): T => {
    if (isBrowser === false) {
        return params.defaultValue;
    }

    try {
        const itemData = params.useSessionStorage === true ? sessionStorage.getItem(params.key) : localStorage.getItem(params.key);
        if (itemData === null) {
            return params.defaultValue;
        }

        const startValue = JSON.parse(itemData);

        const decodeResult = params.decode(startValue);
        if (decodeResult instanceof Error) {
            console.warn(decodeResult);
            return params.defaultValue;
        }

        return decodeResult;
    } catch (err) {
        console.warn(err);
    }

    return params.defaultValue;
};

export const createLocalStorageItem = <T>(isBrowser: boolean, params: InputParam<T>): LocalStorageItemState<T> => {
    const localStorageItem = new LocalStorageItemState(params.key, getStartValue(isBrowser, params));

    if (isBrowser) {
        const dispose = autorun(() => {
            if (params.useSessionStorage === true) {
                sessionStorage.setItem(params.key, localStorageItem.export());
            } else {
                localStorage.setItem(params.key, localStorageItem.export());
            }
        });

        window.addEventListener('beforeunload', () => {
            dispose();
        });
    }

    return localStorageItem;
};
