import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    FlexInput,
    PostcodeWrapper,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/YourDetails.style';
import { YourDetailsState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/YourDetails.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';

interface PostCodeOnlyInputPropsType {
    yourDetailsState: YourDetailsState;
}

export const PostCodeOnlyInput = observer('PostCodeOnlyInput', (props: PostCodeOnlyInputPropsType) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents, languagesState } = appLayoutsState;
    const { customKeyboard } = appPlayersState;
    const { config } = configComponents;
    const { yourDetailsState } = props;

    return (
        <PostcodeWrapper>
            <FlexInput
                state={yourDetailsState.postcodeState}
                placeholder={
                    config.zipCodeAndCountryCheck === true
                        ? languagesState.getTranslation(
                              'signup.address-procedure.enter-postcode.placeholder-zip',
                              'Enter Zip Code'
                          )
                        : languagesState.getTranslation(
                              'signup.address-procedure.enter-postcode.placeholder',
                              'Enter postcode'
                          )
                }
                label={
                    config.zipCodeAndCountryCheck === true ? (
                        <I18n
                            langKey='signup.address-procedure.postcode.label-zip'
                            defaultText='Zip Code'
                        />
                    ) : (
                        <I18n
                            langKey='signup.address-procedure.postcode.label'
                            defaultText='Postcode'
                        />
                    )
                }
                asideElement={customKeyboard.getAsideElement}
                showSuccessIcon={Boolean(yourDetailsState.isShownFullAddressForm)}
                dataTest='postcode-input'
            />
        </PostcodeWrapper>
    );
});
