import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, makeObservable } from 'mobx';
import { useAppStateContext } from 'src/appState/AppState';
import { CenteredVerifyHeadline, VerifyInfo, HomeButton } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/ProfileVerification.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    VerificationFailedWrapper,
    EmailLink,
    WarningTriangle,
    WarningTriangleIconWrapper
} from './VerificationFailed.style';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
// import { SignUpState } from 'src/domains/players/webview/components/SignUp/SignupPrimary/signupState/SignUp.state';
import { SignupTypes } from 'src/domains/players/webview/components/SignUp';

export class VerificationFailedPageState {

    public constructor(public readonly signUpState: SignupTypes, public readonly router: StarRouter) {
        makeObservable(this);
    }

    @action public onHomeClick = (): void => {
        this.signUpState.state.rebuildState();
        this.router.redirectToHomepage();
    };
}

interface PropsType {
    signUpState: SignupTypes;// SignUpState;
}

export const VerificationFailedPage = observer('VerificationFailedPage', ({ signUpState }: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents: { config }, starRouter } = appLayoutsState;

    const [state] = useState(() => new VerificationFailedPageState(signUpState, starRouter));
    const { signUpFooterMail } = config;

    return (
        <VerificationFailedWrapper>
            <WarningTriangle>
                <WarningTriangleIconWrapper />
            </WarningTriangle>
            <CenteredVerifyHeadline data-test='centered-verify-headline'>
                <I18n
                    langKey='signup-journey.verification-failed.headline'
                    defaultText='Sorry we are not able to verify you at this time'
                />
            </CenteredVerifyHeadline>
            <VerifyInfo data-test='verify-info'>
                <I18n
                    langKey='signup-journey.verification-failed.info1'
                    defaultText='Your account has been suspended pending verification.'
                />
            </VerifyInfo>
            <VerifyInfo data-test='verify-info'>
                <I18n
                    langKey='signup-journey.verification-failed.info2'
                    defaultText='Please contact customer services on'
                />
                <EmailLink href={`mailto: ${signUpFooterMail}`}> {signUpFooterMail}</EmailLink>
            </VerifyInfo>
            <HomeButton size='large' onClick={state.onHomeClick} dataTest='home-button'>
                <I18n
                    langKey='signup-journey.verification-failed.home-btn'
                    defaultText='Home'
                />
            </HomeButton>
        </VerificationFailedWrapper>
    );
});
