import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { LoadingWrapper } from 'src/domains/layouts/shared/loaders';
import { CookiesPolicyWrapper, CookieMessageText, CookiesPolicyLink, CookiesPolicyButton } from './CookiesPolicyBox.style';
import { CookiesPolicyBoxState } from './CookiesPolicyBox.state';

export const CookiesPolicyBox = observer('CookiesPolicyBox', () => {
    const appState = useAppStateContext();
    const [cookiesPolicyBoxState] = useState(() => new CookiesPolicyBoxState(appState.localStorageState.cookiesPolicy));


    if (cookiesPolicyBoxState.isAccepted) {
        return null;
    }

    return (
        <LoadingWrapper computedHeight={cookiesPolicyBoxState.boxHeight}>
            <CookiesPolicyWrapper ref={cookiesPolicyBoxState.setRef} className='CookiesPolicyWrapper'>
                <CookieMessageText>
                    <I18n langKey='cookies-policy.box.message' defaultText='This website uses cookies and other technology to provide you a more personalized experience. To find out more, see our' />

                    <CookiesPolicyLink
                        dataTest='CookiesPolicyLink'
                        to={{ account: 'static', static: 'privacy-policy' }}
                    >
                        <I18n langKey='common.policy-box.privacy-policy' defaultText='Privacy Policy' />
                    </CookiesPolicyLink>
                </CookieMessageText>

                <CookiesPolicyButton
                    onClick={cookiesPolicyBoxState.acceptPrivacyPolicy}
                    size='small'
                    data-test='cookies-policy-button'
                >
                    <I18n langKey='common.policy-box.accept.label' defaultText='Accept' />
                </CookiesPolicyButton>
            </CookiesPolicyWrapper>
        </LoadingWrapper>
    );
});
