import { computed, makeObservable } from 'mobx';
import { PossibleBetsRequestState } from 'src/domains/sportsbook/betting/betSlipState/possibleBetsState/PossibleBetsState';
import { CombinationsType, CastBetType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipTypes';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { TypeId } from 'src_common/common/websocket2/type';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import { BetslipCombinationId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { ReferralState } from 'src/domains/sportsbook/betting/betSlipState/ReferralState';
import { BasicBetSlipState } from 'src/domains/sportsbook/betting/betSlipState/BasicBetSlipState';
import { BetslipIdsFactory } from 'src/domains/sportsbook/betting/models/BetslipIdsFactory';

export class CombinationState {
    public constructor(
        private readonly configComponents: ConfigComponents,
        private readonly sdkCustomer: SdkCustomer,
        private readonly possibleBetsRequestState: PossibleBetsRequestState,
        private readonly getChannel: () => ChannelType,
        private readonly referralState: ReferralState,
        private readonly basicBetSlipState: BasicBetSlipState,
        private readonly ids: BetslipIdsFactory
    ) {
        makeObservable(this);
    }

    public getCombinationById = (combinationType: string): CombinationsType | null => {
        return this.possibleBetsRequestState.combinationsForViewMap.get(combinationType) ?? null;
    };

    private getStakeOneCombinationById = (combinationType: string): CombinationsType | null => {
        return this.possibleBetsRequestState.stakeOneCombinationsForViewMap.get(combinationType) ?? null;
    };

    public getSpecialMessageStake = (combinationType: string): Amount | null => {
        const combination = this.getStakeOneCombinationById(combinationType);
        if (combination === null) {
            return null;
        }

        if (combination.price === null || combination.price === undefined)
            return Amount.newOption(combination.potentialReturns);

        return new Amount('1').multiply(combination.price.d);
    };

    @computed private get combinationsForViewArr(): Array<CombinationsType> {
        return Array.from(this.possibleBetsRequestState.combinationsForViewMap.values());
    }

    @computed public get castCombinationsPossibleBetsResponse(): Array<CastBetType> {
        const preparedCasts: Array<CastBetType> = [];

        if (this.possibleBetsRequestState.coreCombinationsPossibleBetsResponse !== null) {
            for (const cast of this.possibleBetsRequestState.coreLegsPossibleBetsResponse) {
                if (
                    cast.type !== 'SGL' &&
                    cast.type !== 'RAB' &&
                    this.configComponents.precision.newFromAnything(cast.stakePerLine).isGreaterThanZero()
                ) {
                    const combination =
                        this.possibleBetsRequestState.coreCombinationsPossibleBetsResponse[cast.type] ?? null;

                    const legs =
                        combination === null
                            ? []
                            : combination.legs.map((leg) => {
                                  const selectionId = leg.selection?.id ?? null;
                                  const marketId = leg.market?.id ?? null;
                                  if (selectionId === null || marketId === null) {
                                      return leg;
                                  }
                                  const eventId = leg.event?.id;

                                  if (eventId === undefined) {
                                      return leg;
                                  }

                                  const selection = this.sdkCustomer.models.getSelectionAndLoad(
                                      TypeId.newMarketId(eventId, marketId),
                                      selectionId
                                  );

                                  return {
                                      ...leg,
                                      uuid: selection?.uuid ?? null,
                                  };
                              });

                    const tempCast: CastBetType = {
                        channel: this.getChannel(),
                        correlationId: cast.correlationId ?? null,
                        country: cast.country,
                        currency: cast.currency,
                        eachWay: cast.eachWay,
                        freebetCredits: null,
                        freebetRemarks: null,
                        id: cast.id,
                        ip: cast.ip,
                        legs: legs,
                        maxStake:
                            cast.maxStake === null || cast.maxStake === undefined
                                ? null
                                : new Amount(cast.maxStake).value,
                        potentialReturns:
                            cast.potentialReturns === null || cast.potentialReturns === undefined
                                ? null
                                : new Amount(cast.potentialReturns).value,
                        potentialReturnsEw:
                            cast.potentialReturnsEw === null || cast.potentialReturnsEw === undefined
                                ? null
                                : new Amount(cast.potentialReturnsEw).value,
                        totalStake:
                            cast.totalStake === null || cast.totalStake === undefined
                                ? null
                                : new Amount(cast.totalStake).value,
                        price: cast.price,
                        stakePerLine: new Amount(cast.stakePerLine).value,
                        type: cast.type,
                        numLines: combination === null ? 1 : combination.numLines,
                        errors: cast.errors,
                    };
                    preparedCasts.push(tempCast);
                }
            }
        }

        return preparedCasts;
    }

    @computed public get forPotentialReturn(): Amount {
        let totalPotentialReturn: Amount = new Amount('0');
        for (const combination of this.combinationsForViewArr) {
            if (
                combination.potentialReturns !== null &&
                combination.stakePerLine !== null &&
                this.configComponents.precision.newFromAnything(combination.stakePerLine).isGreaterThanZero()
            ) {
                totalPotentialReturn = totalPotentialReturn.add(
                    this.configComponents.precision.newFromAnything(combination.potentialReturns)
                );
            }
        }
        return totalPotentialReturn;
    }

    @computed public get isError(): boolean {
        return this.castCombinationsPossibleBetsResponse.some((elem) => {
            return elem.errors.length > 0;
        });
    }

    //....

    @computed public get combinationsCast(): Array<string> {
        const castTypes = ['FC', 'RFC', 'CFC', 'TC', 'CTC'];

        if (this.referralState.referralData !== null) {
            return this.referralState.combinationsTypes.filter((type) => castTypes.includes(type));
        }

        const combinationTypes: Array<string> = [];

        for (const combination of Array.from(this.possibleBetsRequestState.combinationsForViewMap.values())) {
            if (castTypes.includes(combination.type)) {
                combinationTypes.push(combination.type);
            }
        }

        return combinationTypes;
    }

    @computed public get combinationsCast2(): Array<BetslipCombinationId> {
        return this.combinationsCast.map((combinationId) => this.ids.getCombinationId(combinationId));
    }

    @computed public get combinationWithoutCast(): Array<string> {
        const castTypes = ['FC', 'RFC', 'CFC', 'TC', 'CTC'];

        if (this.basicBetSlipState.related || this.basicBetSlipState.relatedOnAdd) {
            return [];
        }
        if (this.referralState.referralData !== null) {
            return this.referralState.combinationsTypes.filter((type) => !castTypes.includes(type));
        }

        const combinationTypes: Array<string> = [];

        for (const combination of Array.from(this.possibleBetsRequestState.combinationsForViewMap.values())) {
            if (!castTypes.includes(combination.type)) {
                combinationTypes.push(combination.type);
            }
        }

        return combinationTypes;
    }

    @computed public get combinationWithoutCast2(): Array<BetslipCombinationId> {
        return this.combinationWithoutCast.map((combinationId) => this.ids.getCombinationId(combinationId));
    }
}
