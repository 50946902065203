import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { EventsListMobx } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventsListMobx';
import {
    FiltersState,
    FiltersStateStrategy,
    FilterType
} from 'src/domains/layouts/webview/components/filters/Filters.state';
import { computed, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import {
    EventsTableInfo,
    FootballFiltersWrapper
} from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventsTableMobX.style';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { MatchType } from 'src/domains/sportsbook/state/eventsListState/markets';
import { EventsListState } from 'src/domains/sportsbook/state/eventsListState/EventsListState';
import { EventsTableMarketsLoader } from 'src/domains/sportsbook/webview/components/loaders/EventsTableMarketsLoader';
import { useAppStateContext } from 'src/appState/AppState';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
interface StatusPropsType {
    eventsListState: EventsListState;
}

const Status = observer('Status', (props: StatusPropsType) => {
    const { showEvents, isLoading } = props.eventsListState;

    if (isLoading) {
        return (
            <EventsTableMarketsLoader />
        );
    }

    if (showEvents === false) {
        return (
            <EventsTableInfo>
                <I18n langKey='events.table.empty' defaultText="Sorry, we haven't found any events with such criteria." />
            </EventsTableInfo>
        );
    }

    return null;
});

class EventsFiltersStrategy implements FiltersStateStrategy {

    public constructor(
        private eventsListState: EventsListState
    ) {
        makeObservable(this);
    }

    @computed public get filters(): FilterType[] {
        return this.eventsListState.marketForEnv.map((item) => {
            return {
                id: item.id,
                key: item.id,
                label: item.name,
            };
        });
    }

    public getActiveFilterId(): MatchType {
        return this.eventsListState.filter_market;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (
            id === 'match-winner'
            || id === 'both-teams-to-score'
            || id === 'total-goals-over-under-2.5'
            || id === 'total-goals-over-under-1.5'
            || id === 'total-goals-over-under-0.5'
            || id === 'draw-no-bet'
            || id === '1st-half-winner'
            || id === 'match-result-and-both-to-score'
        ) {
            this.eventsListState.changeFilter_market(id);
        }
    }

    public isFilterDisabled(): boolean {
        return this.eventsListState.isOutrightsMarket();
    }

}
interface EventsTablePropsType {
    competitionId: CompetitionId | null;
    sportId: string | null;
}
export const EventsTableMobX = observer('EventsTableMobX', (props: EventsTablePropsType) => {
    const { appSportsBookState, config } = useAppStateContext();

    const eventsListState = appSportsBookState.eventsListState.get(props.competitionId);
    const filtersState = new FiltersState(new EventsFiltersStrategy(eventsListState));

    return (
        <div className='events-table--sport-list'>
            <FootballFiltersWrapper>
                <FiltersScrollableWrapper>
                    <Filters state={filtersState} skin={config.filtersVersion.football} />
                </FiltersScrollableWrapper>
            </FootballFiltersWrapper>


            <EventsListMobx eventsListState={eventsListState} sportId={props.sportId} />

            <Status eventsListState={eventsListState}/>

            {eventsListState.showEvents ? <PriceChangeLegend isRace={false} /> : null}

        </div>
    );
});

