import * as t from 'io-ts';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { convertTimeSettingsTimeline, TimelineType } from 'src_common/common/websocket2/models/EventModel';
import { EventId, WebsocketId } from 'src_common/common/websocket2/id/WebsocketId';
import { createGuard } from 'src_common/common/createGuard';

const EventsCollectionQuerySportReplaceIO = t.array(t.interface({
    from: t.string,
    to: t.string,
}));

export type EventsCollectionQuerySportReplaceType = t.TypeOf<typeof EventsCollectionQuerySportReplaceIO>;

export const EventsCollectionQueryFilterMarketIO = t.partial({
    templateId: t.string,
    line: t.union([t.number, t.null]),
});

export const EventsCollectionQueryFilterMarketStatusIO = t.partial({
    eq: t.array(t.string),
    noteq: t.array(t.string),
});

export type EventsCollectionQueryFilterMarketType = t.TypeOf<typeof EventsCollectionQueryFilterMarketIO>;

export const EventsCollectionQueryIO = t.partial({
    state: t.literal('open'),
    display: t.literal(false),
    'market.display': t.literal(true),

    'startTime[from]': t.string,
    'startTime[to]': t.string,

    sport: t.array(t.string),
    sportNot: t.array(t.string),
    sportReplace: EventsCollectionQuerySportReplaceIO,
    competition: t.number,
    competitionsIds: t.array(t.number),
    q: t.string,
    started: t.boolean,
    'timeSettings.tradedInPlay': t.literal('true'),
    time: t.union([t.literal('in-play'), t.literal('next-off'), t.literal('upcoming'), t.literal('search-time')]),

    filterMarket: EventsCollectionQueryFilterMarketIO,
    filterMarketStatus: EventsCollectionQueryFilterMarketStatusIO,

    page: t.number,
    pageSize: t.number,
});

export type EventsCollectionQueryType = t.TypeOf<typeof EventsCollectionQueryIO>;

const EventListGroupEventItemIO = t.interface({
    id: t.number,
    name: t.string,
    state: t.string,
    template: t.string,
    antePost: t.boolean,
    startTime: t.number, //ms
    timeline: t.string,
    tags: t.record(t.string, t.string),
});

export interface EventListGroupEventItemType {
    id: number;
    id2: EventId;
    name: string;
    state: string;
    template: string;
    antePost: boolean;
    startTime: number; //ms
    timeline: TimelineType;
    tags: Record<string, string>;
}

const EventListGroupCompetitionItemIO = t.interface({
    id: t.number,
    name: t.string,
    displayOrder: t.number,
    events: t.array(EventListGroupEventItemIO),
});

export interface EventListGroupCompetitionItemType {
    id: number;
    name: string;
    displayOrder: number;
    events: Array<EventListGroupEventItemType>;
}

const EventListGroupSportItemIO = t.interface({
    id: t.string,
    name: t.string,
    displayOrder: t.number,
    competitions: t.array(EventListGroupCompetitionItemIO)
});

export interface EventListGroupSportItemType {
    id: string;
    name: string;
    displayOrder: number;
    competitions: Array<EventListGroupCompetitionItemType>;
}

const EventListGroupIO = t.interface({
    sports: t.array(EventListGroupSportItemIO)
});

export interface EventListGroupType {
    sports: Array<EventListGroupSportItemType>;
}

export const decodeEventListGroup = buildValidator('decodeEventListGroup', EventListGroupIO, true);

export const isEventListGroup = createGuard(EventListGroupIO);

const convertEventListGroupEventItem = (getWebsocketId: () => WebsocketId, event: t.TypeOf<typeof EventListGroupEventItemIO>): EventListGroupEventItemType => {
    return {
        ...event,
        timeline: convertTimeSettingsTimeline(event.timeline),
        id2: getWebsocketId().getEventId(event.id),
    };
};

const convertEventListGroupCompetitionItem = (getWebsocketId: () => WebsocketId, competition: t.TypeOf<typeof EventListGroupCompetitionItemIO>): EventListGroupCompetitionItemType => {
    const events = [];

    for (const event of competition.events) {
        events.push(convertEventListGroupEventItem(getWebsocketId, event));
    }

    return {
        ...competition,
        events
    };
};

const convertEventListGroupSportItemType = (getWebsocketId: () => WebsocketId, group: t.TypeOf<typeof EventListGroupSportItemIO>): EventListGroupSportItemType => {
    const competitions: Array<EventListGroupCompetitionItemType> = [];

    for (const competition of group.competitions) {
        competitions.push(convertEventListGroupCompetitionItem(getWebsocketId, competition));
    }

    return {
        ...group,
        competitions
    };
};

export const convertEventListGroup = (getWebsocketId: () => WebsocketId, dataFromSocket: t.TypeOf<typeof EventListGroupIO>): EventListGroupType => {

    const sports: Array<EventListGroupSportItemType> = [];

    for (const sport of dataFromSocket.sports) {
        sports.push(convertEventListGroupSportItemType(getWebsocketId, sport));
    }

    return {
        sports
    };
};

