import { action, autorun, computed, makeObservable } from 'mobx';
import { CurrencySymbolType, CurrencyType, moneySymbol } from 'src_common/common/amount/website-money/currency';
import { BetsListState } from 'src/domains/sportsbook/betting/state/BetsListState';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { BetsState } from 'src/domains/sportsbook/betting/state/BetsState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { BetSlipState } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { StreamingState } from 'src/domains/sportsbook/state/streamingState/StreamingState';
import { AlternativeEventState } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeEventState';
import { ServerTimeState } from 'src_common/common/websocket2/ServerTimeState';
import { AllSports } from 'src/domains/layouts/state/allSports/AllSports';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import { EventsListState } from 'src/domains/sportsbook/state/eventsListState/EventsListState';
import { SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { BetWidgetsState } from 'src/domains/sportsbook/state/betWidgetsState/BetWidgetsState';
import { Session } from 'src_common/sdk/session';
import { CustomerFreeBetsType, ResourceCustomer } from 'src/domains/players/shared/Types';
import { WalletDataTypeAmountType } from 'src/domains/players/state/UsersState';
import { SportsConfigState } from './sportsConfigState/sportsConfigState';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import {
    RacesSliderState,
    RacesSliderType,
} from 'src/domains/sportsbook/webview/components/racesSlider/RacesSlider.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { HorseRacingState } from 'src/domains/sportsbook/webview/components/racing/NewRacingPage.state';
import { MarketFiltersForSportState } from 'src/domains/sportsbook/state/marketFiltersState/MarketFiltersForSportState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { EventsListSelectTabState } from './eventsListState/EventsListSelectTabState';
import { SpecialSportsListState } from './specialSportsState/SpecialSportListState';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { CompetitionId, EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { EnvVariables } from 'src/domains/common/contextStore/EnvVariables';
import { TrpcClient } from 'src/appState/TrpcClient';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';

type AppSportsBookStateParamsType = {
    getTranslation: (key: string, defaultText: string) => string;
    onRedirectToBetslip: () => void;
    onRedirectToLogin: () => void;
    onRedirectToPromoTermsAndConditions: () => void;
    getConfig: () => ConfigComponents;
    getOddsFormat: () => 'f' | 'd';
    getCurrency: () => CurrencyType;
    getFreeBetsData: () => ResourceCustomer<CustomerFreeBetsType | null>;
    getWalletData: () => ResourceCustomer<WalletDataTypeAmountType>;
    getIsRabFeatureOn: () => boolean;
    onRoutingAccountChange: (route: string) => void;
    getIsBrowser: () => boolean;
    eventViewTag: ({
        eventId,
        eventName,
        eventTime,
    }: {
        eventId: number;
        eventName: string | undefined;
        eventTime: string | undefined;
    }) => void;
};

export class AppSportsBookState {
    public readonly models: ModelsState;
    public readonly betsListState: BetsListState;
    public readonly betsState: BetsState;
    public readonly betSlipState: BetSlipState;
    public readonly rab: RabState;
    public readonly streamingState: StreamingState;

    public readonly alternativeEventState: MobxMapAutoNew<EventId | null, AlternativeEventState>;
    public readonly eventsCollection: EventsCollectionState;
    public readonly eventsListState: MobxMapAutoNew<CompetitionId | null, EventsListState>;
    public readonly specialSportsMap: MobxMapAutoNew<string | null, SpecialSportsState>;
    public readonly specialSportsListState: SpecialSportsListState;
    public readonly serverTime: ServerTimeState;
    public readonly betWidgetsState: BetWidgetsState;
    public readonly sportsConfigState: SportsConfigState;
    public readonly raceSlider: MobxMapAutoNew<RacesSliderType, RacesSliderState>;
    public readonly horseRacingState: MobxMapAutoNew<'horseracing' | 'greyhoundracing', HorseRacingState>;
    public readonly marketFiltersForSport: MobxMapAutoNew<
        string,
        MobxMapAutoNew<EventId | null, MarketFiltersForSportState>
    >;

    public constructor(
        public readonly trpcClient: TrpcClient,
        public readonly language: LanguagesState,
        private readonly sdkCustomer: SdkCustomer,
        private readonly websocketV1: WebsocketV1,
        private readonly configComponents: ConfigComponents,
        private readonly lifeSpanState: LifeSpanState,
        private readonly allSports: AllSports,
        private readonly sessionState: Session,
        private readonly starRouter: StarRouter,
        private readonly envVariables: EnvVariables,
        private readonly params: AppSportsBookStateParamsType,
        private readonly localStorageState: LocalStorageState
    ) {
        makeObservable(this);
        this.models = this.sdkCustomer.models;
        this.sessionState = sessionState;
        this.betsListState = new BetsListState(this.websocketV1, this.sessionState);
        this.betsState = new BetsState(this.sdkCustomer, this.websocketV1, this.configComponents, this.betsListState, {
            getTranslation: this.params.getTranslation,
            getOddsFormat: (): 'd' | 'f' => this.params.getOddsFormat(),
        });
        this.rab = new RabState(
            this.sdkCustomer,
            this.params.getConfig(),
            this.language,
            this.params.getIsRabFeatureOn,
            () => this.betSlipState.basicBetSlipState.channel,
            this.configComponents.config.decimalLength
        );
        this.betSlipState = new BetSlipState(
            this.models.id,
            this.rab,
            this.lifeSpanState,
            this.sdkCustomer,
            this.betsState,
            this.envVariables,
            {
                onRedirectToBetslip: (): void => this.params.onRedirectToBetslip(),
                onRedirectToLogin: (): void => this.params.onRedirectToLogin(),
                getConfig: (): ConfigComponents => this.params.getConfig(),
                getOddsFormat: (): 'd' | 'f' => this.params.getOddsFormat(),
                getFreeBetsData: (): ResourceCustomer<CustomerFreeBetsType | null> => this.params.getFreeBetsData(),
                getWalletData: (): ResourceCustomer<WalletDataTypeAmountType> => this.params.getWalletData(),
            },
            this.localStorageState,
            this.configComponents.config.decimalLength
        );
        this.streamingState = new StreamingState(this.trpcClient, this.models);
        this.alternativeEventState = new MobxMapAutoNew((eventId) => {
            return new AlternativeEventState(this.models, this.configComponents, eventId, this.trpcClient, {
                onRedirectToPromoTermsAndConditions: params.onRedirectToPromoTermsAndConditions,
                getTranslation: params.getTranslation,
            });
        });
        this.serverTime = new ServerTimeState();
        this.eventsCollection = new EventsCollectionState(
            this.sdkCustomer,
            this.configComponents,
            this.models,
            this.serverTime,
            this.allSports
        );

        const selectTab = new EventsListSelectTabState(params.getTranslation);

        this.eventsListState = new MobxMapAutoNew((competitionId) => {
            return new EventsListState(
                this.eventsCollection,
                this.serverTime,
                this.configComponents,
                this.models,
                competitionId,
                {
                    getTranslation: params.getTranslation,
                },
                selectTab
            );
        });
        this.sportsConfigState = new SportsConfigState(this.trpcClient);

        this.specialSportsListState = new SpecialSportsListState(
            this.eventsCollection,
            this.sportsConfigState,
            this.trpcClient
        );

        this.specialSportsMap = new MobxMapAutoNew((sportId) => {
            return new SpecialSportsState(this.models, this.eventsCollection, sportId, this.specialSportsListState);
        });

        this.betWidgetsState = new BetWidgetsState(this.betSlipState, this.rab, {
            onRedirectToBetslip: params.onRedirectToBetslip,
        });

        this.raceSlider = new MobxMapAutoNew((raceSliderType: RacesSliderType) => {
            return new RacesSliderState(this.language, this.eventsCollection, this.sdkCustomer.models, raceSliderType);
        });

        this.horseRacingState = new MobxMapAutoNew((raceType: 'horseracing' | 'greyhoundracing') => {
            return new HorseRacingState(raceType, this.eventsCollection, this.configComponents, this.starRouter);
        });

        this.marketFiltersForSport = new MobxMapAutoNew((sport) => {
            return new MobxMapAutoNew((eventId): MarketFiltersForSportState => {
                return new MarketFiltersForSportState(
                    sport,
                    eventId,
                    {
                        getTranslation: this.params.getTranslation,
                    },
                    this.trpcClient
                );
            });
        });

        starRouter.onChangeCurrentView((_prevCurrentView, nextCurrentView) => {
            const eventId = this.getEventId(nextCurrentView);
            const eventModel = eventId === null ? null : this.models.getEvent(eventId);

            if (eventModel !== null) {
                this.params.eventViewTag({
                    eventId: eventModel.id,
                    eventName: eventModel.name,
                    eventTime: eventModel.timeSettingsStartTime,
                });
            }
        });

        if (this.params.getIsBrowser()) {
            this.runOnStart();
        }
    }
    //TODO: WebsocketV1 to remove - move to new WS

    @action public getOddsFormat = (): 'd' | 'f' => {
        return this.params.getOddsFormat();
    };

    @action public onAccountGTMRoutingChange = (route: string): void => {
        return this.params.onRoutingAccountChange(route);
    };

    @computed public get moneySymbol(): CurrencySymbolType {
        return moneySymbol(this.params.getCurrency());
    }

    public getMarketFiltersBySport = (sport: string, eventId: EventId | null): MarketFiltersForSportState => {
        return this.marketFiltersForSport.get(sport).get(eventId);
    };

    private runOnStart = (): void => {
        //For better UX - preload data for football page - first visit
        autorun(() => {
            const configItem = this.eventsListState.get(null).marketForEnv[0];

            if (configItem !== undefined) {
                const collection = this.eventsCollection.listOfSport('football', {
                    templateId: configItem.template,
                    line: configItem.line,
                });

                return collection.length;
            }
        });

        // not sure if it is necessary
        autorun(() => {
            const { legsIds2, referralState } = this.betSlipState;

            legsIds2.map((leg) => {
                const selectionModel = leg.id.getModel();

                if (selectionModel?.display === false && referralState.isReferred === false) {
                    this.betSlipState.legsState.betslipData.onRemoveLeg(leg);
                }
            });
        });

        //For better UX - preload data for football page - change tabs with markets
        autorun(() => {
            const currentView = this.starRouter.currentView;
            const fakeOut: Array<number> = [];

            if (currentView?.name === 'sport' && currentView.id === 'football') {
                for (const configItem of this.eventsListState.get(null).marketForEnv) {
                    const collection = this.eventsCollection.listOfSport('football', {
                        templateId: configItem.template,
                        line: configItem.line,
                    });

                    fakeOut.push(collection.length);
                }
            }
        });
    };

    private getEventId = (currentView: RouteViewType | null): number | null => {
        if (currentView === null) {
            return null;
        }

        if (currentView.name === 'racecard' && currentView.selected !== null) {
            return currentView.selected;
        }

        if (currentView.name === 'event') {
            return currentView.id;
        }

        return null;
    };
}
