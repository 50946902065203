import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { HomePageCarouselState } from 'src/domains/layouts/state/cmsState/homePageCarouselState/HomePageCarouselState';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

export type CarouselModeType = 'homepage' | 'casino' | 'virtuals' | 'live-casino';

export class CarouselMainState {
    public readonly carouselStateMap: MobxMapAutoNew<CarouselModeType, HomePageCarouselState>;

    public constructor(
        models: ModelsState,
        languagesState: LanguagesState,
        configComponents: ConfigComponents,
        trpcClient: TrpcClient
    ) {
        this.carouselStateMap = new MobxMapAutoNew(
            (carouselMode: CarouselModeType): HomePageCarouselState =>
                new HomePageCarouselState(carouselMode, models, languagesState, configComponents, trpcClient)
        );
    }
}
