import React from 'react';
import { range } from 'lodash';
import { observer } from 'src/utils/mobx-react';
import { RaceBoxListElement, RaceBoxList, RaceBoxLoaderWrapper } from './RaceBox.style';
import { SecondaryHeaderLoader, ViewRaceCardLoader } from 'src/domains/layouts/shared/loaders';
import { RaceBoxSelectionLoader } from 'src/domains/sportsbook/webview/components/raceBoxSelection/RaceBoxSelectionLoader';

interface RaceBoxLoaderPropsType {
    items: number;
}

export const RaceBoxLoader = observer('RaceBoxLoader', (props: RaceBoxLoaderPropsType) => {
    const { items } = props;

    return (
        <RaceBoxLoaderWrapper>
            <SecondaryHeaderLoader />
            <RaceBoxList key='race-box-selections'>
                {range(0, items).map(x => (
                    <RaceBoxListElement key={x}>
                        <RaceBoxSelectionLoader />
                    </RaceBoxListElement>
                ))}
                <RaceBoxListElement>
                    <ViewRaceCardLoader />
                </RaceBoxListElement>
            </RaceBoxList>
        </RaceBoxLoaderWrapper>
    );
});
