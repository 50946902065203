import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface HeartOutlinedIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const HeartOutlinedIcon = observer('HeartOutlinedIcon', ({ className, onClick }: HeartOutlinedIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M20.133 11.8l-8.132 8.537-8.133-8.541c-1.863-1.953-1.863-5.13-.001-7.082.754-.792 1.75-1.228 2.806-1.228 1.058 0 2.055.436 2.81 1.228l2.515 2.637 2.517-2.63c.755-.793 1.752-1.23 2.81-1.23s2.055.437 2.808 1.227c1.862 1.954 1.862 5.13 0 7.082zm1.062-8.111c-2.136-2.245-5.605-2.245-7.74 0L12 5.209l-1.454-1.525c-2.142-2.245-5.605-2.245-7.742 0C.4 6.207.4 10.302 2.804 12.825L12 22.483l9.195-9.653c2.407-2.523 2.407-6.618 0-9.141z'
                fill='fill'
                fillRule='evenodd'
            />
        </SvgElement>
    );
});
