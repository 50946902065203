import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface LiveTvIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const LiveTvIcon = observer(
    'LiveTvIcon',
    ({ className, onClick }: LiveTvIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M21.804 3c.579 0 1.09.213 1.532.638.443.426.664.945.664 1.558v13.072c0 .579-.221 1.08-.664 1.506-.442.426-.953.639-1.532.639H2.196c-.579 0-1.09-.213-1.532-.639C.22 19.35 0 18.848 0 18.268V5.196c0-.613.221-1.132.664-1.558C1.106 3.213 1.617 3 2.196 3h19.608zm0 2.196v13.072H2.196V5.196h19.608z'
                    fill='fill'
                />
                <path
                    d='M8.732 7.34l7.608 4.392-7.608 4.34V7.34z'
                    fill='fill'
                    fillRule='nonzero'
                />
            </SvgElement>
        );
    }
);
