//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';



export interface ParamsType {
    universe: string,
    accountId: string,
}


const Response200IO = t.array(t.interface({
    accountId: t.number,
    amount: t.number,
    cardNumber: t.string,
    currency: t.string,
    id: t.number,
    tdate: t.string,
}));

export type Response200Type = Array<{
    accountId: number,
    amount: number,
    cardNumber: string,
    currency: string,
    id: number,
    tdate: string,
}>;

export const decodeResponse200 = (data: unknown): Response200Type => {
    const decodeResult = Response200IO.decode(data);
    if (isRight(decodeResult)) {
        return decodeResult.right;
    }
    throw Error('Response decoding error /withdrawal-list/{universe}/{accountId} -> get -> 200');
};




const Response500IO = t.unknown;

export type Response500Type = unknown;

export const decodeResponse500 = (data: unknown): Response500Type => {
    const decodeResult = Response500IO.decode(data);
    if (isRight(decodeResult)) {
        return decodeResult.right;
    }
    throw Error('Response decoding error /withdrawal-list/{universe}/{accountId} -> get -> 500');
};






export const openapi_wallet_getWithdrawalListForUser = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType): Promise<FetchGeneralRawResponseType> => {
    const url = `${api_url}/withdrawal-list/${params.universe}/${params.accountId}`;
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};
