import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { HomeIcon } from 'src/domains/layouts/shared/assets/icons/HomeIcon';
import { FootballIcon } from 'src/domains/layouts/shared/assets/icons/FootballIcon';
import { InPlayIcon } from 'src/domains/layouts/shared/assets/icons/InPlayIcon';
import { CasinoIcon } from 'src/domains/layouts/shared/assets/icons/CasinoIcon';
import { CasinoTableGamesIcon } from 'src/domains/layouts/shared/assets/icons/CasinoTableGamesIcon';
import { PromotionsIcon } from 'src/domains/layouts/shared/assets/icons/PromotionsIcon';
import { HorseRacingIcon } from 'src/domains/layouts/shared/assets/icons/HorseRacingIcon';
import { CasinoLucky6Icon } from 'src/domains/layouts/shared/assets/icons/CasinoLucky6Icon';
import { CashOutIcon } from 'src/domains/layouts/shared/assets/icons/CashOutIcon';
import { SportsListIcon } from 'src/domains/layouts/shared/assets/icons/SportsListIcon';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';

export const MobileIconWrapper = styled(LazyLoadingImg, { label: 'MobileIconWrapper' })`
    height: 20px;
    width: 20px;
`;

const setMobileIcon = (): SerializedStyles => {
    return css`
        fill: currentcolor;
        width: 20px;
    `;
};

export const SportsListIconWrapper = styled(SportsListIcon, { label: 'SportsListIconWrapper' })`
    ${setMobileIcon()};
`;

export const InPlayIconWrapper = styled(InPlayIcon, { label: 'InPlayIconWrapper' })`
    ${setMobileIcon()};
`;

export const CasinoIconWrapper = styled(CasinoIcon, { label: 'CasinoIconWrapper' })`
    ${setMobileIcon()};
`;

export const LiveCasinoIconWrapper = styled(CasinoTableGamesIcon, { label: 'LiveCasinoIconWrapper' })`
    ${setMobileIcon()};
`;

export const CasinoLucky6IconWrapper = styled(CasinoLucky6Icon, { label: 'CasinoLucky6IconWrapper' })`
    ${setMobileIcon()};
`;

export const BingoIconWrapper = styled(CasinoLucky6Icon, { label: 'BingoIconWrapper' })`
    ${setMobileIcon()};
`;

export const CashOutIconWrapper = styled(CashOutIcon, { label: 'CashOutIconWrapper' })`
    ${setMobileIcon()};
`;

export const LoudSpeakerIconWrapper = styled(PromotionsIcon, { label: 'LoudSpeakerIconWrapper' })`
    ${setMobileIcon()};
`;

export const HomeIconWrapper = styled(HomeIcon, { label: 'HomeIconWrapper' })`
    ${setMobileIcon()};
`;

export const FootballIconWrapper = styled(FootballIcon, { label: 'FootballIconWrapper' })`
    ${setMobileIcon()};
`;

export const HorseracingIconWrapper = styled(HorseRacingIcon, { label: 'HorseracingIconWrapper' })`
    ${setMobileIcon()};
`;
