import React from 'react';
import { SelectionView } from './SelectionView';
import { observer } from 'src/utils/mobx-react';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface SelectionPropsType {
    selectionId: SelectionId;
}

export const Selection = observer('Selection',({ selectionId }: SelectionPropsType) => {
    const selection = selectionId.getModel();

    if (selection !== null) {
        const selectionForView = selection.forView(false);

        if (selectionForView !== null) {
            return <SelectionView selection={selection} selectionForView={selectionForView}/>;
        }
    }

    return null;
});
