import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { HeaderStyle, HeaderSpan, PanelContainer } from './Panel.style';

interface PropsType {
    title: string | JSX.Element;
    titleDataTest: string;
    children: React.ReactNode;
    isAzMobile?: boolean;
}

export const Panel = observer('Panel', (props: PropsType): JSX.Element => {

    const { title, children, isAzMobile = false, titleDataTest } = props;

    return (
        <PanelContainer isAzMobile={isAzMobile} data-test={titleDataTest}>
            <HeaderStyle>
                <HeaderSpan>{title}</HeaderSpan>
            </HeaderStyle>
            {children}
        </PanelContainer>
    );
});
