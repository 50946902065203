import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    WithdrawForm,
    WithdrawProcedureBtnWrapper,
    BalanceRowWrapper,
    BalanceWrapper,
    BalanceValue,
    WithdrawInput,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawProcedure.style';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { WithdrawSwiftyGlobalProviderState } from 'src/domains/players/webview/components/WithdrawAndDeposit';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const preventDefault = (event: React.FormEvent<HTMLFormElement>): void => event.preventDefault();

interface PropsType {
    state: WithdrawSwiftyGlobalProviderState;
}

export const WithdrawSwiftyGlobalProviderProcedure = observer(
    'WithdrawSwiftyGlobalProviderProcedure',
    ({ state }: PropsType) => {
        const {
            appPlayersState: {
                usersState: { moneySymbol },
            },
        } = useAppStateContext();

        return (
            <>
                <BalanceWrapper>
                    <span data-test='withdrawable-balance-label'>
                        <I18n langKey='account.withdraw.cash-balance-label' defaultText='Withdrawable balance' />
                    </span>
                    <BalanceValue data-test='balance-value'>{state.showBalance}</BalanceValue>
                </BalanceWrapper>

                <WithdrawForm onSubmit={preventDefault}>
                    <BalanceRowWrapper key='payment-rows'>
                        <WithdrawInput
                            type='text'
                            maxLength={10}
                            placeholder='0.00'
                            label={
                                <I18n
                                    langKey='account.top-up.withdraw.description-label'
                                    defaultText='Amount to withdraw'
                                />
                            }
                            currency={moneySymbol}
                            state={state.amountState}
                            colorTheme='light'
                            inputHasBackground={false}
                            dataTest='withdraw-amount-input'
                        />
                    </BalanceRowWrapper>

                    <PaymentAdditions
                        key='payment-additions'
                        currency={moneySymbol}
                        value={state.amountState.value}
                        onChange={state.handlePaymentAdditionsChange}
                    />

                    {state.withdrawErrorMessage === null ? null : (
                        <Messages type='error' message={state.withdrawErrorMessage} />
                    )}

                    {state.withdrawSuccessMessage === null ? null : (
                        <Messages
                            marginTop='10px'
                            marginBottom='20px'
                            type='success'
                            message={state.withdrawSuccessMessage}
                        />
                    )}

                    <WithdrawProcedureBtnWrapper
                        type='submit'
                        size='large'
                        onClick={state.submitWithdrawForm}
                        disabled={state.isWithdrawFormSubmitting || state.amountState.result.value.type === 'error'}
                        dataTest='withdraw-procedure-btn'
                    >
                        <I18n langKey='account.withdraw.submit.label' defaultText='Withdraw' />
                    </WithdrawProcedureBtnWrapper>
                </WithdrawForm>
            </>
        );
    }
);
