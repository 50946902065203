import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface BingoMiniGamesIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const BingoMiniGamesIcon = observer('BingoMiniGamesIcon', (props: BingoMiniGamesIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={props.className}
            onClick={props.onClick}
            fill='#fff'
            viewBox='0 0 20 20'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M17.4453 1.13096C17.4066 1.23231 17.3473 1.32774 17.2674 1.41087C15.3323 3.42382 14.1667 6.06829 14.1667 8.95833C14.1667 9.30978 14.1839 9.6573 14.2176 10.0003C16.9555 10.0276 19.1667 12.2556 19.1667 15C19.1667 17.7614 16.9281 20 14.1667 20C11.4053 20 9.16668 17.7614 9.16668 15C9.16668 12.7973 10.591 10.9273 12.5688 10.2607C12.5233 9.83218 12.5 9.39769 12.5 8.95833C12.5 6.33079 13.3324 3.88908 14.7565 1.84893C11.19 2.53887 8.29829 5.12401 7.17323 8.5149C7.83399 8.69824 8.43973 9.01385 8.96014 9.43142C9.37834 9.76698 9.32876 10.3821 8.99058 10.7982C8.0586 11.9448 7.50001 13.4072 7.50001 15C7.50001 15.6563 7.59484 16.2904 7.77154 16.8894C7.92325 17.4037 7.73316 17.991 7.21784 18.1392C6.77818 18.2656 6.31367 18.3333 5.83334 18.3333C3.07192 18.3333 0.833344 16.0948 0.833344 13.3333C0.833344 10.6908 2.8833 8.52707 5.4797 8.34565C5.84855 7.10147 6.41923 5.94404 7.15383 4.91135C6.82303 4.96961 6.48262 5 6.1351 5C4.34137 5 2.73674 4.19039 1.66668 2.91667C2.73674 1.64294 4.34137 0.833333 6.1351 0.833333C7.55522 0.833333 8.85681 1.3408 9.86843 2.18428C11.7824 0.809538 14.1298 0 16.6667 0C16.7753 0 16.8791 0.02079 16.9743 0.0586087C17.0719 0.0973345 17.1638 0.15529 17.2442 0.232587C17.4068 0.388897 17.4922 0.595296 17.4995 0.8042C17.5004 0.830915 17.5001 0.857671 17.4985 0.884369C17.4932 0.970777 17.4749 1.05363 17.4453 1.13096ZM5.83334 11.6667C4.91287 11.6667 4.16668 12.4129 4.16668 13.3333C4.16668 13.7936 3.79358 14.1667 3.33334 14.1667C2.87311 14.1667 2.50001 13.7936 2.50001 13.3333C2.50001 11.4924 3.99239 10 5.83334 10C6.29358 10 6.66668 10.3731 6.66668 10.8333C6.66668 11.2936 6.29358 11.6667 5.83334 11.6667ZM12.5 15C12.5 14.0795 13.2462 13.3333 14.1667 13.3333C14.6269 13.3333 15 12.9602 15 12.5C15 12.0398 14.6269 11.6667 14.1667 11.6667C12.3257 11.6667 10.8333 13.1591 10.8333 15C10.8333 15.4602 11.2064 15.8333 11.6667 15.8333C12.1269 15.8333 12.5 15.4602 12.5 15Z'
                fill='fill'
            ></path>
        </SvgElement>
    );
});
