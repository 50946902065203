import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { AllSports } from 'src/domains/layouts/state/allSports/AllSports';

export class TranslationsStore {
    public readonly language: LanguagesState;
    public readonly env: EnvironmentState;
    private readonly allSports: AllSports;

    public constructor(language: LanguagesState, env: EnvironmentState, allSports: AllSports) {
        this.language = language;
        this.env = env;
        this.allSports = allSports;
    }

    /**
     * @deprecated - remove this function.
     */
    public translateSport = (sportId: string): string => {
        const defaultName = this.firstLetterToUpperCase(this.allSports.getSport(sportId) ?? sportId);
        return this.language.getSportTranslationName(sportId, defaultName);
    };

    private firstLetterToUpperCase(value: string): string {
        const firstLetter = value[0];

        return [firstLetter === undefined ? '' : firstLetter.toUpperCase(), value.slice(1)].join('');
    }

    public getBetslipTranslation = (multiple: string, betHistory?: boolean): string => {
        switch (multiple) {
            case 'Single':
                if (betHistory === true) {
                    return this.language.getTranslation('betslip.multiples.Single', 'Single');
                } else {
                    return this.language.getTranslation('betslip.multiples.Singles', 'Singles');
                }
            case 'YouDecide':
                return this.language.getTranslation('betslip.multiples.YouDecide', 'YouDecide');

            case 'Tote Win':
                return this.language.getTranslation('betslip.multiples.ToteWin', 'Tote Win');

            case 'Tote Place':
                return this.language.getTranslation('betslip.multiples.TotePlace', 'Tote Place');

            case 'Double':
                return this.language.getTranslation('betslip.multiples.Double', 'Double');

            case 'Forecast':
                return this.language.getTranslation('betslip.multiples.Forecast', 'Forecast');

            case 'Reverse Forecast':
                return this.language.getTranslation('betslip.multiples.ReverseForecast', 'Reverse Forecast');

            case 'Single Stakes About':
                return this.language.getTranslation('betslip.multiples.SingleStakesAbout', 'Single Stakes About');

            case 'Exacta':
                return this.language.getTranslation('betslip.multiples.Exacta', 'Exacta');

            case 'Reverse Exacta':
                return this.language.getTranslation('betslip.multiples.ReverseExacta', 'Reverse Exacta');

            case 'Treble':
                return this.language.getTranslation('betslip.multiples.Treble', 'Treble');

            case 'Doubles from 3':
                return this.language.getTranslation('betslip.multiples.Doublesfrom3', 'Doubles from 3');

            case 'Trixie':
                return this.language.getTranslation('betslip.multiples.Trixie', 'Trixie');

            case 'Patent':
                return this.language.getTranslation('betslip.multiples.Patent', 'Patent');

            case 'Round Robin':
                return this.language.getTranslation('betslip.multiples.RoundRobin', 'Round Robin');

            case 'Tricast':
                return this.language.getTranslation('betslip.multiples.Tricast', 'Tricast');

            case 'Combination Forecast':
                return this.language.getTranslation('betslip.multiples.CombinationForecast', 'Combination Forecast');

            case 'Combination Tricast':
                return this.language.getTranslation('betslip.multiples.CombinationTricast', 'Combination Tricast');

            case 'Trifecta':
                return this.language.getTranslation('betslip.multiples.Trifecta', 'Trifecta');

            case 'Combination Exacta':
                return this.language.getTranslation('betslip.multiples.CombinationExacta', 'Combination Exacta');

            case 'Combination Trifecta':
                return this.language.getTranslation('betslip.multiples.CombinationTrifecta', 'Combination Trifecta');

            case '4 fold':
                return this.language.getTranslation('betslip.multiples.4fold', '4 fold');

            case 'Doubles from 4':
                return this.language.getTranslation('betslip.multiples.Doublesfrom4', 'Doubles from 4');

            case 'Trebles from 4':
                return this.language.getTranslation('betslip.multiples.Treblesfrom4', 'Trebles from 4');

            case 'Yankee':
                return this.language.getTranslation('betslip.multiples.Yankee', 'Yankee');

            case 'Lucky 15':
                return this.language.getTranslation('betslip.multiples.Lucky15', 'Lucky 15');

            case 'Flag':
                return this.language.getTranslation('betslip.multiples.Flag', 'Flag');

            case 'Quadpot':
                return this.language.getTranslation('betslip.multiples.Quadpot', 'Quadpot');

            case '5 fold':
                return this.language.getTranslation('betslip.multiples.5fold', '5 fold');

            case 'Doubles from 5':
                return this.language.getTranslation('betslip.multiples.Doublesfrom5', 'Doubles from 5');

            case 'Trebles from 5':
                return this.language.getTranslation('betslip.multiples.Treblesfrom5', 'Trebles from 5');

            case '4 folds from 5':
                return this.language.getTranslation('betslip.multiples.4foldsfrom5', '4 folds from 5');

            case 'Canadian':
                return this.language.getTranslation('betslip.multiples.Canadian', 'Canadian');

            case 'Lucky 31':
                return this.language.getTranslation('betslip.multiples.Lucky31', 'Lucky 31');

            case 'Super Flag':
                return this.language.getTranslation('betslip.multiples.SuperFlag', 'Super Flag');

            case '6 fold':
                return this.language.getTranslation('betslip.multiples.6fold', '6 fold');

            case 'Doubles from 6':
                return this.language.getTranslation('betslip.multiples.Doublesfrom6', 'Doubles from 6');

            case 'Trebles from 6':
                return this.language.getTranslation('betslip.multiples.Treblesfrom6', 'Trebles from 6');

            case '4 folds from 6':
                return this.language.getTranslation('betslip.multiples.4foldsfrom6', '4 folds from 6');

            case '5 folds from 6':
                return this.language.getTranslation('betslip.multiples.5foldsfrom6', '5 folds from 6');

            case 'Heinz':
                return this.language.getTranslation('betslip.multiples.Heinz', 'Heinz');

            case 'Lucky 63':
                return this.language.getTranslation('betslip.multiples.Lucky63', 'Lucky 63');

            case 'Jackpot':
                return this.language.getTranslation('betslip.multiples.Jackpot', 'Jackpot');

            case 'Scoop 6':
                return this.language.getTranslation('betslip.multiples.Scoop6', 'Scoop 6');

            case '7 fold':
                return this.language.getTranslation('betslip.multiples.7fold', '7 fold');

            case 'Doubles from 7':
                return this.language.getTranslation('betslip.multiples.Doublesfrom7', 'Doubles from 7');

            case 'Trebles from 7':
                return this.language.getTranslation('betslip.multiples.Treblesfrom7', 'Trebles from 7');

            case '4 folds from 7':
                return this.language.getTranslation('betslip.multiples.4foldsfrom7', '4 folds from 7');

            case '5 folds from 7':
                return this.language.getTranslation('betslip.multiples.5foldsfrom7', '5 folds from 7');

            case '6 folds from 7':
                return this.language.getTranslation('betslip.multiples.6foldsfrom7', '6 folds from 7');

            case 'Super Heinz':
                return this.language.getTranslation('betslip.multiples.SuperHeinz', 'Super Heinz');

            case '8 fold':
                return this.language.getTranslation('betslip.multiples.8fold', '8 fold');

            case 'Doubles from 8':
                return this.language.getTranslation('betslip.multiples.Doublesfrom8', 'Doubles from 8');

            case 'Trebles from 8':
                return this.language.getTranslation('betslip.multiples.Treblesfrom8', 'Trebles from 8');

            case '4 folds from 8':
                return this.language.getTranslation('betslip.multiples.4foldsfrom8', '4 folds from 8');

            case '5 folds from 8':
                return this.language.getTranslation('betslip.multiples.5foldsfrom8', '5 folds from 8');

            case '6 folds from 8':
                return this.language.getTranslation('betslip.multiples.6foldsfrom8', '6 folds from 8');

            case '7 folds from 8':
                return this.language.getTranslation('betslip.multiples.7foldsfrom8', '7 folds from 8');

            case 'Goliath':
                return this.language.getTranslation('betslip.multiples.Goliath', 'Goliath');

            case '9 fold':
                return this.language.getTranslation('betslip.multiples.9fold', '9 fold');

            case 'Doubles from 9':
                return this.language.getTranslation('betslip.multiples.Doublesfrom9', 'Doubles from 9');

            case 'Trebles from 9':
                return this.language.getTranslation('betslip.multiples.Treblesfrom9', 'Trebles from 9');

            case '8 folds from 9':
                return this.language.getTranslation('betslip.multiples.8foldsfrom9', '8 folds from 9');

            case '10 fold':
                return this.language.getTranslation('betslip.multiples.10fold', '10 fold');

            case 'Doubles from 10':
                return this.language.getTranslation('betslip.multiples.Doublesfrom10', 'Doubles from 10');

            case 'Trebles from 10':
                return this.language.getTranslation('betslip.multiples.Treblesfrom10', 'Trebles from 10');

            case '9 folds from 10':
                return this.language.getTranslation('betslip.multiples.9foldsfrom10', '9 folds from 10');

            case '11 fold':
                return this.language.getTranslation('betslip.multiples.11fold', '11 fold');

            case 'Doubles from 11':
                return this.language.getTranslation('betslip.multiples.Doublesfrom11', 'Doubles from 11');

            case 'Trebles from 11':
                return this.language.getTranslation('betslip.multiples.Treblesfrom11', 'Trebles from 11');

            case '10 folds from 11':
                return this.language.getTranslation('betslip.multiples.10foldsfrom11', '10 folds from 11');

            case '12 fold':
                return this.language.getTranslation('betslip.multiples.12fold', '12 fold');

            case 'Doubles from 12':
                return this.language.getTranslation('betslip.multiples.Doublesfrom12', 'Doubles from 12');

            case 'Trebles from 12':
                return this.language.getTranslation('betslip.multiples.Treblesfrom12', 'Trebles from 12');

            case '11 folds from 12':
                return this.language.getTranslation('betslip.multiples.11foldsfrom12', '11 folds from 12');

            case '13 fold':
                return this.language.getTranslation('betslip.multiples.13fold', '13 fold');

            case 'Doubles from 13':
                return this.language.getTranslation('betslip.multiples.Doublesfrom13', 'Doubles from 13');

            case 'Trebles from 13':
                return this.language.getTranslation('betslip.multiples.Treblesfrom13', 'Trebles from 13');

            case '12 folds from 13':
                return this.language.getTranslation('betslip.multiples.12foldsfrom13', '12 folds from 13');

            case '14 fold':
                return this.language.getTranslation('betslip.multiples.14fold', '14 fold');

            case 'Doubles from 14':
                return this.language.getTranslation('betslip.multiples.Doublesfrom14', 'Doubles from 14');

            case 'Trebles from 14':
                return this.language.getTranslation('betslip.multiples.Treblesfrom14', 'Trebles from 14');

            case '13 folds from 14':
                return this.language.getTranslation('betslip.multiples.13foldsfrom14', '13 folds from 14');

            case '15 fold':
                return this.language.getTranslation('betslip.multiples.15fold', '15 fold');

            case 'Doubles from 15':
                return this.language.getTranslation('betslip.multiples.Doublesfrom15', 'Doubles from 15');

            case 'Trebles from 15':
                return this.language.getTranslation('betslip.multiples.Treblesfrom15', 'Trebles from 15');

            case '14 folds from 15':
                return this.language.getTranslation('betslip.multiples.14foldsfrom15', '14 folds from 15');

            case '16 fold':
                return this.language.getTranslation('betslip.multiples.16fold', '16 fold');

            case 'Doubles from 16':
                return this.language.getTranslation('betslip.multiples.Doublesfrom16', 'Doubles from 16');

            case 'Trebles from 16':
                return this.language.getTranslation('betslip.multiples.Treblesfrom16', 'Trebles from 16');

            case '15 folds from 16':
                return this.language.getTranslation('betslip.multiples.15foldsfrom16', '15 folds from 16');

            case '17 fold':
                return this.language.getTranslation('betslip.multiples.17fold', '17 fold');

            case 'Doubles from 17':
                return this.language.getTranslation('betslip.multiples.Doublesfrom17', 'Doubles from 17');

            case 'Trebles from 17':
                return this.language.getTranslation('betslip.multiples.Treblesfrom17', 'Trebles from 17');

            case '16 folds from 17':
                return this.language.getTranslation('betslip.multiples.16foldsfrom17', '16 folds from 17');

            case '18 fold':
                return this.language.getTranslation('betslip.multiples.18fold', '18 fold');

            case 'Doubles from 18':
                return this.language.getTranslation('betslip.multiples.Doublesfrom18', 'Doubles from 18');

            case 'Trebles from 18':
                return this.language.getTranslation('betslip.multiples.Trebles from 18', 'Trebles from 18');

            case '17 folds from 18':
                return this.language.getTranslation('betslip.multiples.17foldsfrom18', '17 folds from 18');

            case '19 fold':
                return this.language.getTranslation('betslip.multiples.19fold', '19 fold');

            case 'Doubles from 19':
                return this.language.getTranslation('betslip.multiples.Doublesfrom19', 'Doubles from 19');

            case 'Trebles from 19':
                return this.language.getTranslation('betslip.multiples.Treblesfrom19', 'Trebles from 19');

            case '18 folds from 19':
                return this.language.getTranslation('betslip.multiples.18foldsfrom19', '18 folds from 19');

            case '20 fold':
                return this.language.getTranslation('betslip.multiples.20fold', '20 fold');

            case 'Doubles from 20':
                return this.language.getTranslation('betslip.multiples.Doublesfrom20', 'Doubles from 20');

            case 'Trebles from 20':
                return this.language.getTranslation('betslip.multiples.Treblesfrom20', 'Trebles from 20');

            case 'Trebles':
                return this.language.getTranslation('betslip.multiples.Trebles', 'Trebles');

            case '19 folds from 20':
                return this.language.getTranslation('betslip.multiples.19foldsfrom20', '19 folds from 20');

            case '21 fold':
                return this.language.getTranslation('betslip.multiples.21fold', '21 fold');

            case 'Doubles from 21':
                return this.language.getTranslation('betslip.multiples.Doublesfrom21', 'Doubles from 21');

            case 'Trebles from 21':
                return this.language.getTranslation('betslip.multiples.Treblesfrom21', 'Trebles from 21');

            case '20 folds from 21':
                return this.language.getTranslation('betslip.multiples.20foldsfrom21', '20 folds from 21');

            case '22 fold':
                return this.language.getTranslation('betslip.multiples.22fold', '22 fold');

            case 'Doubles from 22':
                return this.language.getTranslation('betslip.multiples.Doublesfrom22', 'Doubles from 22');

            case 'Trebles from 22':
                return this.language.getTranslation('betslip.multiples.Treblesfrom22', 'Trebles from 22');

            case '21 folds from 22':
                return this.language.getTranslation('betslip.multiples.21foldsfrom22', '21 folds from 22');

            case '23 fold':
                return this.language.getTranslation('betslip.multiples.23fold', '23 fold');

            case 'Doubles from 23':
                return this.language.getTranslation('betslip.multiples.Doublesfrom23', 'Doubles from 23');

            case 'Trebles from 23':
                return this.language.getTranslation('betslip.multiples.Treblesfrom23', 'Trebles from 23');

            case '22 folds from 23':
                return this.language.getTranslation('betslip.multiples.22foldsfrom23', '22 folds from 23');

            case '24 fold':
                return this.language.getTranslation('betslip.multiples.24fold', '24 fold');

            case 'Doubles from 24':
                return this.language.getTranslation('betslip.multiples.Doublesfrom24', 'Doubles from 24');

            case 'Trebles from 24':
                return this.language.getTranslation('betslip.multiples.Treblesfrom24', 'Trebles from 24');

            case '23 folds from 24':
                return this.language.getTranslation('betslip.multiples.23foldsfrom24', '23 folds from 24');

            case '25 fold':
                return this.language.getTranslation('betslip.multiples.25fold', '25 fold');

            case 'Doubles from 25':
                return this.language.getTranslation('betslip.multiples.Doublesfrom25', 'Doubles from 25');

            case 'Trebles from 25':
                return this.language.getTranslation('betslip.multiples.Treblesfrom25', 'Trebles from 25');

            case '24 folds from 25':
                return this.language.getTranslation('betslip.multiples.24foldsfrom25', '24 folds from 25');

            case 'Add to all Single Bets':
                return this.language.getTranslation('betslip.add-to-all-single-bets', 'Add to all Single Bets');
        }

        return multiple;
    };

    public transactionHistoryName = (type: string): string | null => {
        switch (type) {
            case 'deposit':
                return this.language.getTranslation('account.transaction.types.deposit.label', 'Deposit');
            case 'deposit-paid':
                return this.language.getTranslation('account.transaction.types.deposit-paid.label', 'Deposit');
            case 'deposit-rejected':
                return this.language.getTranslation('account.transaction.types.deposit-rejected.label', 'Deposit');
            case 'withdrawal':
                return this.language.getTranslation('account.transaction.types.withdrawal.label', 'Withdrawal');
            case 'withdrawal-paid':
                return this.language.getTranslation('account.transaction.types.withdrawal-paid.label', 'Withdrawal');
            case 'bonus':
                return this.language.getTranslation('account.transaction.types.bonus.label', 'Bonus');
            case 'bonus-revoked':
                return this.language.getTranslation('account.transaction.types.bonus-revoked.label', 'Bonus');
            case 'bonus-given':
                return this.language.getTranslation('account.transaction.types.bonus-given.label', 'Bonus');
            case 'bet':
                return this.language.getTranslation('account.transaction.types.bet.label', 'Bet');
            case 'bet-settlement':
                return this.language.getTranslation('account.transaction.types.bet-settlement.label', 'Bet settlement');
            case 'reward-given':
                return this.language.getTranslation('account.transaction.types.reward-given.label', 'Bonus given');
            case 'bet-unsettlement':
                return this.language.getTranslation(
                    'account.transaction.types.bet-unsettlement.label',
                    'Bet unsettlement'
                );
            case 'bet-placement':
                return this.language.getTranslation('account.transaction.types.bet-placement.label', 'Bet placement');
            case 'bet-cancellation':
                return this.language.getTranslation(
                    'account.transaction.types.bet-cancellation.label',
                    'Bet cancellation'
                );
            case 'bingo-result':
                return this.language.getTranslation('account.transaction.types.bingo-result.label', 'Bingo');
            case 'bingo-wager':
                return this.language.getTranslation('account.transaction.types.bingo-wager.label', 'Bingo');
            case 'bingo-wager-rollback':
                return this.language.getTranslation('account.transaction.types.bingo-wager-rollback.label', 'Bingo');
            case 'vs-result':
                return this.language.getTranslation('account.transaction.types.vs-result.label', 'Virtual sport');
            case 'vs-wager':
                return this.language.getTranslation('account.transaction.types.vs-wager.label', 'Virtual sport');
            case 'vs-wager-rollback':
                return this.language.getTranslation(
                    'account.transaction.types.vs-wager-rollback.label',
                    'Virtual sport'
                );
            case 'withdrawal-funds-locked':
                return this.language.getTranslation(
                    'account.transaction.types.withdrawal-funds-locked.label',
                    'Withdrawal funds locked'
                );
            case 'result':
                return this.language.getTranslation('account.transaction.types.result.label', 'Casino');
            case 'result-unsettlement':
                return this.language.getTranslation('account.transaction.types.result-unsettlement.label', 'Casino');
            case 'wager':
                return this.language.getTranslation('account.transaction.types.wager.label', 'Casino');
            case 'wager-rollback':
                return this.language.getTranslation('account.transaction.types.wager-rollback.label', 'Casino');
            case 'lc-result':
                return this.language.getTranslation('account.transaction.types.lc-result.label', 'Live casino');
            case 'lc-result-unsettlement':
                return this.language.getTranslation(
                    'account.transaction.types.lc-result-unsettlement.label',
                    'Live casino'
                );
            case 'lc-wager':
                return this.language.getTranslation('account.transaction.types.lc-wager.label', 'Live casino');
            case 'lc-wager-rollback':
                return this.language.getTranslation('account.transaction.types.lc-wager-rollback.label', 'Live casino');
            case 'casino-credit':
                return this.language.getTranslation('account.transaction.types.casino-credit.label', 'Casino');
            case 'casino-debit':
                return this.language.getTranslation('account.transaction.types.casino-debit.label', 'Casino');
            case 'casino-debit-rollback':
                return this.language.getTranslation('account.transaction.types.casino-debit-rollback.label', 'Casino');
            case 'live-casino-credit':
                return this.language.getTranslation(
                    'account.transaction.types.live-casino-credit.label',
                    'Live casino'
                );
            case 'live-casino-debit':
                return this.language.getTranslation('account.transaction.types.live-casino-debit.label', 'Live casino');
            case 'live-casino-debit-rollback':
                return this.language.getTranslation(
                    'account.transaction.types.live-casino-debit-rollback.label',
                    'Live casino'
                );

            default:
                return null;
        }
    };
}
