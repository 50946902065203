import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { withConfig } from 'src/withConfig';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';

export const TopUpLimitsProcedureBtnWrapper = styled(Button, { label: 'TopUpLimitsProcedureBtnWrapper' })`
    display: block;
    margin-top: 4px;
    width: 100%;
    opacity: ${({ disabled }): string => disabled === true ? '0.7' : ''};
    pointer-events: ${({ disabled }): string => disabled === true ? 'none' : ''};
`;

export const TopUpLimitsInput = styled(Input, { label: 'TopUpLimitsInput' })`
    margin: 0;
`;

export const TopUpLimitsForm = styled('form', { label: 'TopUpLimitsForm' })`
    margin: 0;
`;

export const TopUpLimitsFormContent = styled('p', { label: 'TopUpLimitsFormContent' })`
    line-height: 1.33;
    margin: 8px 0 32px;
`;

export const FaqLink = withConfig(theme => styled(Link, { label: 'FaqLink' })`
    display: inline;
    color: ${theme.star.topUp.txtColorSecondary};
    font-weight: bold;
    text-decoration: none;
`);

export const TPWrapper = styled('div', { label: 'TPWrapper' })`
    display: flex;
    justify-content: space-between;
    margin: 12px 0 24px 0;
`;

export const SectionHeaderContainer = styled('div', { label: 'SectionHeaderContainer' })`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

interface SectionHeaderProps {
    isUppercase: boolean;
}

export const SectionHeader = withConfig(theme => styled('h4', { label: 'SectionHeader' }) <SectionHeaderProps>`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0;
    text-transform: ${({ isUppercase }): string => isUppercase ? 'uppercase' : 'none'};
`);

export const AmountInputDescription = withConfig(theme => styled('div', { label: 'AmountInputDescription' })`
    color: ${theme.star.form.txtColorSecondary};
`);

export const LifetimeNetDepositLimitsContainer = withConfig(theme => styled('div', { label: 'LifetimeNetDepositLimitsContainer' })`
    background-color: ${theme.star.topUp.bgColorTertiary};
    line-height: 1.33;
    margin: 8px 0 32px;
    padding: 12px;
`);

export const LifetimeNetDepositLimitsDescription = styled('p', { label: 'LifetimeNetDepositLimitsDescription' })`
    margin: 8px 0 0 0;
`;

export const LifeTimeLimitAmount = withConfig(theme => styled('span', { label: 'LifeTimeLimitAmount' })`
    font-weight: ${theme.star.fontWeight.bold};
`);
