import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface UserIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const UserIcon = observer(
    'UserIcon',
    ({ className, onClick }: UserIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M22 22s0-1.375-.115-2.094c-.093-.567-.88-1.317-4.224-2.547-3.291-1.208-3.088-.62-3.088-2.843 0-1.443.734-.604 1.203-3.344.182-1.078.328-.36.724-2.089.208-.906-.14-.974-.099-1.406.042-.432.083-.818.162-1.703C16.655 4.88 15.64 2 12 2 8.36 2 7.344 4.88 7.443 5.98c.078.88.12 1.27.161 1.702.042.433-.307.5-.099 1.407.396 1.723.542 1.005.724 2.088.469 2.74 1.203 1.901 1.203 3.344 0 2.229.203 1.64-3.088 2.844C3 18.589 2.208 19.344 2.12 19.912 2 20.625 2 22 2 22h20z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
