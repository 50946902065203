import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface MyBetsIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const MyBetsIcon = observer(
    'MyBetsIcon',
    ({ className, onClick }: MyBetsIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#fff'
                height='24'
                onClick={onClick}
                viewBox='0 0 22 24'
                width='22'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M0.469727 0H14.5779V17.2456H0.469727V0Z' fill='fill' />
                <path d='M19.4983 6.63574V22.1659H7.30518V24H21.5908V6.63574H19.4983Z' fill='fill' />
                <path d='M18.091 20.7589V3.31787H15.9847V18.6525H3.88721V20.7589H18.091Z' fill='fill' />
            </SvgElement>
        );
    }
);
