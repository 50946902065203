export const catchError = (toRun: () => void): number => {
    let counter = 0;

    try {
        toRun();
    } catch (_error) {
        counter += 1;
    }

    return counter;
};

export const parseNumberAmount = (value: string): number => {
    const valueNumber = parseInt(value, 10);

    if (isNaN(valueNumber)) {
        throw Error(`parseNumberAmount: is not a number: ${value}`);
    }

    return valueNumber;
};

export const trimZerosFromRight = (value: string): string => value.replace(/\.?0+$/, '');
