import React, { PropsWithChildren } from 'react';
import { BannersWrapper } from 'src/domains/layouts/webview/components/banners/Banners.style';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';

export const Banners = observer('Banners', (props: PropsWithChildren<unknown>) => {
    const { children } = props;

    const { appLayoutsState } = useAppStateContext();
    const { bannersBoxState } = appLayoutsState;

    const streamBoxHeight = appLayoutsState.getStreamViewDetails().boxHeight;
    const streamFloating = appLayoutsState.getStreamViewDetails().isFloating;

    const fixedStreamHeight = streamBoxHeight - 1; // Remove 1px hap between stream and main header.

    return <BannersWrapper
        ref={bannersBoxState.setRef}
        offsetTop={fixedStreamHeight}
        isStreamFloating={streamFloating}
    >
        {children}
    </BannersWrapper>;
});
