import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { SignUpPrimaryStepsContainer } from 'src/domains/players/webview/components/SignUp/signupPrimary/SignupStepsContainer';

export const SignUp = observer('SignUp', (): JSX.Element => {
    const { appPlayersState } = useAppStateContext();
    const { signUpState } = appPlayersState;

    return <SignUpPrimaryStepsContainer signUpState={signUpState} />;
});
