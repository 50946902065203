import { observable, action, computed, makeObservable } from 'mobx';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { PromoCodesState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/stepsStructure/createAccount/promoCodesState/PromoCodes.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateRequire, validateCpf } from 'src/domains/players/webview/components/ValidatorsNew';
import { TrpcClient } from 'src/appState/TrpcClient';
import { verifyCpf } from 'src_server/trpc/types';

export interface SendCpfResponse {
    cpf: string;
    firstName: string;
    surname: string;
    birthDate: string;
}
export class SignUpWelcomeState {
    @observable public backendCreateAccountError: string | null = null;
    @observable public isPromoCode = false;
    @observable public isShowPassword = false;

    public promoCodesState: PromoCodesState;
    public readonly cpfState: FormInputState<string, string>;
    public readonly firstNameState: FormInputState<string, string>;
    public readonly surnameState: FormInputState<string, string>;
    public readonly birthDateState: FormInputState<string, string>;
    public readonly cpfFormModel: FormModel<verifyCpf.VerifyCpfInput['body']>;
    public cpfResponseFormModel: FormModel<SendCpfResponse>;

    public constructor(
        private readonly customKeyboard: CustomKeyboardState,
        private readonly moveToNextStage: () => void,
        public readonly language: LanguagesState,
        private trpc: TrpcClient,
        private setLoading: (isLoading: boolean) => void,
        initialUserData?: verifyCpf.VerifyCpfInput['body'],
    ) {
        makeObservable(this);
        this.promoCodesState = new PromoCodesState();

        const initialCpf = initialUserData?.cpf === undefined ? '' : initialUserData.cpf;
        this.cpfState = FormInputState.new(initialCpf)
            .map(validateRequire)
            .map(value => validateCpf(value, language))
            .map((value: string): Result<string> => {
                const valueTrim = value.trim();

                return Result.createOk(valueTrim);
            });

        this.firstNameState = FormInputState.new('').map(validateRequire);
        this.surnameState = FormInputState.new('').map(validateRequire);
        this.birthDateState = FormInputState.new('').map(validateRequire);

        this.cpfFormModel = FormModel.group({
            cpf: this.cpfState
        });

        this.cpfResponseFormModel = FormModel.group({
            cpf: this.cpfState,
            firstName: this.firstNameState,
            surname: this.surnameState,
            birthDate: this.birthDateState
        });
    };

    @computed public get stepIsValid(): boolean {
        return this.cpfState.errorsForView.length === 0;
    }

    @computed public get isWelcomeStepFormModelOk(): boolean {
        return this.cpfFormModel.result.value.type === 'ok';
    }

    @action public sendCpf = async (): Promise<'success' | 'error' | undefined> => {
        if (this.cpfFormModel.result.value.type === 'ok') {
            this.setLoading(true);
            const cpfResponse = await this.trpc.client.signup.verifyCpf.mutate({ body:this.cpfFormModel.result.value.data });

            if (cpfResponse.responseStatus === 'success') {
                this.setLoading(false);

                this.firstNameState.setValue(cpfResponse.data.hashedFirstName);
                this.surnameState.setValue(cpfResponse.data.hashedSurname);
                this.birthDateState.setValue(cpfResponse.data.birthDate);
            } else if (cpfResponse.responseStatus === 'error') {
                this.setLoading(false);
                this.createErrorMessage(cpfResponse.data.error.code);
            }
            return cpfResponse.responseStatus;
        }
        return undefined;

    };

    public onMoveToYourDetails = async (): Promise<void> => {
        this.customKeyboard.scrollAsideToTop();
        this.cpfFormModel.setAsVisited();
        const cpfStatus = await this.sendCpf();
        if (this.cpfFormModel.result.value.type === 'error' || cpfStatus !== 'success') {
            console.error('Error cpf');
            return;
        }
        this.moveToNextStage();
    };

    public createErrorMessage = (errorType: string): void => {
        switch (errorType) {
            case 'cpf-verification-failed':
                this.backendCreateAccountError = this.language.getTranslation('errors.cpf.verification', 'CPF Verification Failed');
                break;
            case 'cpf-verification-failed-age':
                this.backendCreateAccountError = this.language.getTranslation('errors.cpf.verification-age', 'CPF owner is under 18');
                break;
            case 'cpf-verification-failed-dead':
                this.backendCreateAccountError = this.language.getTranslation('errors.cpf.verification-dead', 'CPF number is invalid');
                break;
            case 'cpf-already-regiestered':
                this.backendCreateAccountError = this.language.getTranslation('errors.cpf.already-registered', 'CPF is already registered');
                break;
            case 'external-api-error':
            case 'kyc-api-server-error':
            case 'cpf-verification-failed-no-credits':
                this.backendCreateAccountError = this.language.getTranslation('errors.cpf.external', 'Something went wrong. Please contact our support.');
                break;
            default:
                this.backendCreateAccountError = `Unknown error happen - ${errorType}`;
        }
    };

}
