import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { TypeId } from 'src_common/common/websocket2/type';
import { RabMarketsModel } from 'src_common/common/websocket2/models/RabMarketsModel';
import { CompetitionModel } from 'src_common/common/websocket2/models/CompetitionModel';
import { AutoMap, autoMapKeyAsString } from 'src_common/common/mobx-utils/AutoMap';
import { EventMarketItemType } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { autorun } from 'mobx';

const TIMEOUT = 30000;

const getModelAsync = <T>(label: string, key: string, getModel: () => T | null): Promise<T> => {
    return new Promise((resolve, reject) => {
        const dispose = autorun((dispose) => {
            const model = getModel();

            if (model !== null) {
                dispose.dispose();
                resolve(model);
            }
        });

        setTimeout(() => {
            dispose();
            reject(new Error(`${label}: Timeout for ${key}`));
        }, TIMEOUT);
    });
};

export class WebsocketIdContext {
    public constructor(
        public readonly getSelectionAndLoad: (marketId: TypeId.MarketId, selectionId: number) => SelectionModel | null,
        public readonly getMarket: (id: TypeId.MarketId) => MarketModel | null,
        public readonly getEvent: (id: number) => EventModel | null,
        public readonly getRabMarketsByEventId: (id: number) => RabMarketsModel | null,
        public readonly getEventMarkets: (id: number) => Array<EventMarketItemType> | null,
        public readonly getCompetitionModel: (id: number) => CompetitionModel | null
    ) {}
}

export class CompetitionId {
    public constructor(
        private readonly context: WebsocketIdContext,
        private readonly competitionId: number
    ) {}

    public getModel(): CompetitionModel | null {
        return this.context.getCompetitionModel(this.competitionId);
    }

    public getModelAsync(): Promise<CompetitionModel> {
        return getModelAsync('CompetitionId.getModelAsync', this.key, () => this.getModel());
    }

    /**
     * @deprecated - this method is only needed in the period of migration to the new ids
     */
    public toOldId(): number {
        return this.competitionId;
    }

    public get key(): string {
        return `CompetitionId_competitionId=${this.competitionId}`;
    }

    public async idv2(): Promise<string> {
        const model = await this.getModelAsync();
        return model.getData().idv2;
    }
}

export class EventId {
    public constructor(
        private readonly context: WebsocketIdContext,
        private readonly eventId: number,
        private readonly getMarketIdFactory: (eventId: number, marketId: number) => MarketId
    ) {}

    public getEventModel(): EventModel | null {
        return this.context.getEvent(this.eventId);
    }

    public getEventModelAsync(): Promise<EventModel> {
        return getModelAsync('EventId.getEventModelAsync', this.key, () => this.getEventModel());
    }

    /**
     * @deprecated - this method is only needed in the period of migration to the new ids
     */
    public toOldId(): number {
        return this.eventId;
    }

    public get rawId(): string {
        return this.eventId.toString();
    }

    public getMarketId(marketId: number): MarketId {
        return this.getMarketIdFactory(this.eventId, marketId);
    }

    public getRabModel(): RabMarketsModel | null {
        return this.context.getRabMarketsByEventId(this.eventId);
    }

    public getEventMarkets(): Array<EventMarketItemType> | null {
        return this.context.getEventMarkets(this.eventId);
    }

    public get key(): string {
        return `EventId_eventId=${this.eventId}`;
    }

    public async idv2(): Promise<string> {
        const model = await this.getEventModelAsync();
        return model.getData().idv2;
    }

    [autoMapKeyAsString](): string {
        return JSON.stringify([this.eventId]);
    }
}

export class MarketId {
    public constructor(
        private readonly context: WebsocketIdContext,
        private readonly eventId: number,
        private readonly marketId: number,
        private readonly getEventIdFactory: (eventId: number) => EventId,
        private readonly getSelectionIdFactory: (eventId: number, marketId: number, selectionId: number) => SelectionId
    ) {}

    public getModel(): MarketModel | null {
        const marketId = TypeId.newMarketId(this.eventId, this.marketId);
        return this.context.getMarket(marketId);
    }

    public getModelAsync(): Promise<MarketModel> {
        return getModelAsync('MarketId.getModelAsync', this.key, () => this.getModel());
    }

    public getEventId(): EventId {
        return this.getEventIdFactory(this.eventId);
    }

    public getSelectionId(selectionId: number): SelectionId {
        return this.getSelectionIdFactory(this.eventId, this.marketId, selectionId);
    }

    /**
     * @deprecated - this method is only needed in the period of migration to the new ids
     */
    public toOldId(): number {
        return this.marketId;
    }

    public get rawId(): string {
        return this.marketId.toString();
    }

    public get key(): string {
        return `MarketId_eventId=${this.eventId}_marketId=${this.marketId}`;
    }

    public async idv2(): Promise<string> {
        const model = await this.getModelAsync();
        return model.getData().idv2;
    }

    [autoMapKeyAsString](): string {
        return JSON.stringify([this.eventId, this.marketId]);
    }
}

export class SelectionId {
    public constructor(
        private readonly context: WebsocketIdContext,
        private readonly eventId: number,
        private readonly marketId: number,
        private readonly selectionId: number,
        private readonly getEventIdFactory: (eventId: number) => EventId,
        private readonly getMarketIdFactory: (eventId: number, marketId: number) => MarketId
    ) {}

    public getModel(): SelectionModel | null {
        const marketId = TypeId.newMarketId(this.eventId, this.marketId);
        return this.context.getSelectionAndLoad(marketId, this.selectionId);
    }

    public getModelAsync(): Promise<SelectionModel> {
        return getModelAsync('SelectionId.getModelAsync', this.key, () => this.getModel());
    }

    /**
     * @deprecated - this method is only needed in the period of migration to the new ids
     */
    public toOldId(): number {
        return this.selectionId;
    }

    public getEventId(): EventId {
        return this.getEventIdFactory(this.eventId);
    }

    public getMarketId(): MarketId {
        return this.getMarketIdFactory(this.eventId, this.marketId);
    }

    public get key(): string {
        return `SelectionId_eventId=${this.eventId}_marketId=${this.marketId}_selectionId=${this.selectionId}`;
    }

    public get rawId(): string {
        return this.selectionId.toString();
    }

    public async idv2(): Promise<string> {
        const model = await this.getModelAsync();
        return model.getData().idv2;
    }
    [autoMapKeyAsString](): string {
        return JSON.stringify([this.eventId, this.marketId, this.selectionId]);
    }
}

export class WebsocketId {
    private readonly competition: AutoMap<number, CompetitionId>;
    private readonly events: AutoMap<number, EventId>;
    private readonly markets: AutoMap<[number, number], MarketId>;
    private readonly selections: AutoMap<[number, number, number], SelectionId>;

    public constructor(context: WebsocketIdContext) {
        this.competition = new AutoMap((competitionId: number) => {
            return new CompetitionId(context, competitionId);
        });

        this.events = new AutoMap((eventId) => {
            return new EventId(context, eventId, this.getMarketId);
        });

        this.markets = new AutoMap(([eventId, marketId]) => {
            return new MarketId(context, eventId, marketId, this.getEventId, this.getSelectionId);
        });

        this.selections = new AutoMap(([eventId, marketId, selectionId]) => {
            return new SelectionId(context, eventId, marketId, selectionId, this.getEventId, this.getMarketId);
        });
    }

    public getCompetitionId(competitionId: number): CompetitionId {
        return this.competition.get(competitionId);
    }

    public getEventId = (eventId: number): EventId => {
        return this.events.get(eventId);
    };

    public getEventIdOption = (eventId: number | null): EventId | null => {
        if (eventId === null) {
            return null;
        }

        return this.events.get(eventId);
    };

    public getMarketId = (eventId: number, marketId: number): MarketId => {
        return this.markets.get([eventId, marketId]);
    };

    public getSelectionId = (eventId: number, marketId: number, selectionId: number): SelectionId => {
        return this.selections.get([eventId, marketId, selectionId]);
    };
}
