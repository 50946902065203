import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const From = styled('form', { label: 'From' })`
    margin-bottom: 20px;
`;

export const Description = withConfig(theme => styled('p', { label: 'Description' })`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.43;
    margin: 8px 0 24px 0;
`);

export const QuestionsList = styled('ul', { label: 'QuestionsList' })``;

export const Question = styled('li', { label: 'Question' })``;

export const QuestionTitle = withConfig(theme => styled('h5', { label: 'QuestionTitle' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 6px 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
`);

export const SaveButton = styled(Button, { label: 'SaveButton' })`
    width: 100%;
`;
