import { css, SerializedStyles } from '@emotion/react';

import styled from '@emotion/styled';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { withConfig } from 'src/withConfig';



const setBogIndicator = (theme: EmotionTheme, size: 'small' | 'medium'): SerializedStyles => {

    if (size === 'small') {

        return css`

            font-size: ${theme.star.fontSize.xxSmall};

            height: 16px;

            line-height: 1;

            padding: 4px 2px;

            width: 24px;

        `;

    }



    return css`

        font-size: ${theme.star.fontSize.small};

        height: 24px;

        line-height: 0.8;

        padding: 8px 2px 7px;

        width: 36px;

    `;

};



interface BogIndicatorPropsType {

    size: 'small' | 'medium';

}



export const BogIndicator = withConfig(theme => styled('div', { label: 'BogIndicator' })<BogIndicatorPropsType>`

    ${({ size }): SerializedStyles => setBogIndicator(theme, size)};

    background-color: ${theme.star.bog.bgColor};

    border-radius: 2px;

    color: ${theme.star.bog.txtColor};

    font-weight: ${theme.star.fontWeight.bold};

    letter-spacing: -0.5px;

    text-align: center;

`);



export const BogIndicatorRace = styled(BogIndicator, { label: 'BogIndicatorRace' })`

    position: absolute;

    right: 8px;

    top: 6px;

`;



export const BogIndicatorLegsInBet = styled(BogIndicator, { label: 'BogIndicatorLegsInBet' })`

    margin-left: 8px;

`;

