import { SelectionModel } from './SelectionModel/SelectionModel';
import { EventModel } from './EventModel';
import { ModelBoxContext } from 'src_common/common/websocket2/common/ModelBoxContext';
import { LazyComputed, compareArrays } from 'src_common/common/websocket2/common/LazyComputed';
import {
    MarketModelType,
    MarketTemplateModelType,
    EachWayType,
    DividendsType,
    BetLimitMapType,
    FeedSettingsType,
    CashoutMarketSettingsType
} from 'src_common/common/websocket2/modelsApi/Market';
import { Value } from 'src_common/common/mobx-utils/Value';
import { TypeId } from 'src_common/common/websocket2/type';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { CriteriaType, sortSelectionsByCriteria } from 'src_common/utils/sport/sort';
import { EventId, MarketId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

/*
template:
    eventTemplateId: "match"
    id: "jqxxchsh-tfqr5t21"
    name: "Winning Margin"
    sportId: "football"
*/

const NewMarketTemplateIdIO = t.union([
    t.literal('match-winner'),
    t.literal('custom-race-market'),
    t.literal('total-goals-over-under'),
    t.literal('correct-score'),
    t.literal('half-time-correct-score'),
    t.literal('outrights'),
    t.literal('custom-outrights-market'),
    t.literal('win-only'),
    t.literal('first-goalscorer'),
    t.literal('anytime-goalscorer'),
    t.literal('hattrick-goalscorer'),
    t.literal('match-bets'),
    t.literal('two-way-handicap'),
    t.literal('total-points-over-under'),
    t.literal('flavorless-template'),

    //included
    t.literal('each-way-extra'),
    t.literal('place-betting'),
]);

const isNewMarketTemplateId = createGuard(NewMarketTemplateIdIO);

export type NewMarketTemplateIdType = t.TypeOf<typeof NewMarketTemplateIdIO> | 'other';

export class MarketModel {
    private readonly modelBoxContext: ModelBoxContext;
    private readonly model: Value<MarketModelType>;

    private computedDisplayed: LazyComputed<boolean>;
    private computedSelections: LazyComputed<Array<SelectionModel>>;
    private computedSelectionFilterByAwayTemplate: LazyComputed<Array<SelectionModel>>;
    private computedSelectionFilterByActive: LazyComputed<Array<SelectionModel>>;
    private computedSelectionFilterByHomeTemplate: LazyComputed<Array<SelectionModel>>;

    public constructor(modelBoxContext: ModelBoxContext, model: Value<MarketModelType>) {
        this.modelBoxContext = modelBoxContext;
        this.model = model;

        this.computedDisplayed = LazyComputed.create(() => {
            if (this.getData().display === true) {
                return true;
            }

            for (const selectionId of this.selectionsIds) {
                const selectionModel = this.modelBoxContext.getSelection(selectionId);

                if (selectionModel !== null && selectionModel.display) {
                    return true;
                }
            }
            return false;
        });

        this.computedSelections = LazyComputed.create(() => {
            const out = [];

            for (const id of this.getData().selections) {
                const item = this.modelBoxContext.getSelection(id);
                if (item !== null) {
                    out.push(item);
                }
            }

            return out;
        }, compareArrays);

        this.computedSelectionFilterByAwayTemplate = new LazyComputed((): Array<SelectionModel> => {
            return this.selections.filter(selection => {
                return selection.templateId === 'A';
            });
        }, compareArrays);

        this.computedSelectionFilterByActive = new LazyComputed((): Array<SelectionModel> => {
            return this.selections.filter(selection => {
                return selection.shouldDisplay;
            });
        }, compareArrays);

        this.computedSelectionFilterByHomeTemplate = new LazyComputed((): Array<SelectionModel> => {
            return this.selections.filter(selection => {
                return selection.templateId === 'H';
            });
        }, compareArrays);
    }

    public getData(): MarketModelType {
        return this.model.getValue();
    }

    public getRawData(): MarketModelType {
        return this.getData();
    }

    /**
     * @deprecated - please use id2
     */
    public get id(): TypeId.MarketId {
        return this.getData().id;
    }

    public get id2(): MarketId {
        return this.modelBoxContext.websocketId().getMarketId(
            this.getData().event.id,
            TypeId.getMarketId(this.getData().id)
        );
    }

    public get uuid(): string | null {
        return this.getData().uuid ?? null;
    }

    public get displayed(): boolean {
        return this.computedDisplayed.get();
    }

    public get template(): MarketTemplateModelType {
        return this.getData().template;
    }

    /**
     * @deprecated - Use the newTemplateId field.
     */
    public get templateId(): string {
        return this.getData().template.id;
    }

    public get newTemplateId(): NewMarketTemplateIdType {
        const templateId = this.getData().template.id;

        if (templateId.includes('each-way-extra')) {
            return 'each-way-extra';
        }

        if (templateId.includes('place-betting')) {
            return 'place-betting';
        }

        if (isNewMarketTemplateId(templateId)) {
            return templateId;
        }

        return 'other';
    }

    public get isEachWayExtra(): boolean {
        return this.newTemplateId === 'each-way-extra';
    }

    public get name(): string {
        return this.getData().name;
    }

    public get dividends(): DividendsType | null {
        const { dividends } = this.getData();
        if (dividends !== undefined && dividends !== null) {
            return dividends;
        }
        return null;
    }

    public getTag(name: string): string | undefined {
        return this.getData().tags[name];
    }

    public get websiteMain(): boolean {
        const websiteMain = this.getTag('website-main');
        return websiteMain === 'yes';
    }

    public get websiteNotPopular(): boolean {
        const websitetPopular = this.getTag('website-popular');
        return !(websitetPopular === '-');
    }

    public get displayOrderTag(): string | undefined {
        return this.getTag('display-order');
    }

    public get trapChallengeTag(): string | undefined {
        return this.getTag('trap-challenge');
    }

    public get displayTemplate(): string | undefined {
        return this.getTag('display-template');
    }

    public get groupTemplate(): string | undefined {
        return this.getTag('group-template');
    }

    public get selectionsIds(): Array<number> {
        return this.getData().selections;
    }

    public get selectionsIds2(): Array<SelectionId> {
        return this.getData().selections.map((selectionId) => {
            return this.id2.getSelectionId(selectionId);
        });
    }

    public get selections(): Array<SelectionModel> {
        return this.computedSelections.get();
    }

    public selectionWithSorting(sortType: CriteriaType): Array<SelectionModel> {
        return sortSelectionsByCriteria(this.selections, sortType);
    }

    public get activeSelections(): Array<SelectionModel> {
        return this.computedSelectionFilterByActive.get();
    }

    public get selectionFilterByAwayTemplate(): Array<SelectionModel> {
        return this.computedSelectionFilterByAwayTemplate.get();
    }

    public get selectionFilterByHomeTemplate(): Array<SelectionModel> {
        return this.computedSelectionFilterByHomeTemplate.get();
    }

    public get tradedInPlay(): boolean {
        return this.getData().tradedInPlay;
    }

    public get spOnly(): boolean {
        return this.getData().spOnly;
    }

    public get sp(): boolean {
        return this.getData().sp;
    }

    public get bp(): boolean {
        return this.getData().bp;
    }

    /**
     * @deprecated - use MarketModel.name instead this
     */
    public get marketName(): string {
        return this.name;
    }

    public get platformMarketName(): string | null {
        return this.getData().platformMarketName ?? null;
    }

    public get display(): boolean {
        const value = this.getData().display;
        if (value === true) {
            return true;
        }

        return false;
    }

    public get displayOrder(): number {
        return this.getData().displayOrder;
    }

    public get isTotalGoalsOverUnder(): boolean {
        return this.newTemplateId === 'total-goals-over-under';
    }

    public get isCorrectScore(): boolean {
        return (
            this.newTemplateId === 'correct-score' || 
            this.newTemplateId === 'half-time-correct-score'
        );
    }

    public get isOutright(): boolean {
        return (
            this.newTemplateId === 'outrights' || 
            this.newTemplateId === 'custom-outrights-market'
        );
    }

    public get isGoalscorer(): boolean {
        return (
            this.newTemplateId === 'first-goalscorer' || 
            this.newTemplateId === 'anytime-goalscorer' || 
            this.newTemplateId === 'hattrick-goalscorer'
        );
    }

    public get displayTemplateFirst(): string | undefined {
        return this.displayTemplate;
    }

    public get groupTemplateFirst(): string | undefined {
        return this.groupTemplate;
    }

    public get eachWay(): EachWayType | undefined {
        return this.getData().eachWay ?? undefined;
    }

    public get eachWayTermsWithBet(): boolean {
        return this.eachWay?.termsWithBet ?? false;
    }

    public get eachWayOffered(): boolean {
        const eachWay = this.eachWay;
        if (eachWay !== undefined) {
            return eachWay.offered;
        }

        return false;
    }

    public get eachWayTermsPlaces(): number | undefined {
        const eachWay = this.eachWay;

        if (eachWay !== undefined) {
            const termsItem = eachWay.terms[0];

            if (termsItem !== undefined) {
                return termsItem.places;
            }
        }

        return undefined;
    }

    public get eachWayTermsReduction(): string | undefined {
        const eachWay = this.eachWay;

        if (eachWay !== undefined) {
            const termsItem = eachWay.terms[0];

            if (termsItem !== undefined) {
                return termsItem.reduction;
            }
        }

        return undefined;
    }

    public get tricastsOffered(): boolean {
        const tricastsOffered = this.getData().tricastsOffered;
        return tricastsOffered === true ? true : false;
    }

    public get forecastsOffered(): boolean {
        const forecastsOffered = this.getData().forecastsOffered;
        return forecastsOffered === true ? true : false;
    }

    public get cashoutAvailable(): boolean {
        const cashoutAvailable = this.getData().cashoutAvailable;
        return cashoutAvailable === true ? true : false;
    }

    public get active(): boolean {
        return this.getData().active;
    }

    /**
     * @deprecated - please use eventId2
     */
    public get eventId(): number {
        return this.getData().event.id;
    }

    public get eventId2(): EventId {
        return this.modelBoxContext.websocketId().getEventId(this.getData().event.id);
    }

    public get eventModel(): EventModel | null {
        return this.modelBoxContext.getEvent(this.eventId);
    }

    public getEvent(): EventModel | null {
        return this.modelBoxContext.getEvent(this.eventId);
    }

    public get activated(): boolean {
        const parentEvent = this.eventModel;

        if (parentEvent !== null) {
            return parentEvent.active && this.active;
        }

        return false;
    }

    public get line(): number | null {
        return this.getData().line ?? null;
    }

    public get activeAndDisplayed(): boolean {
        return this.display === true && this.active === true;
    }

    public get getSuspendTime(): string {
        return this.getData().suspendTime ?? '';
    }

    public get getAutoTakeDown(): boolean {
        return this.getData().autoTakeDown ?? false;
    }

    public get getSpApplicable(): boolean {
        return this.getData().spApplicable ?? false;
    }

    public get getBpApplicable(): boolean {
        return this.getData().bpApplicable ?? false;
    }

    public get getBlockSettlement(): boolean | null {
        const blockSettlementData = this.getData().blockSettlement;
        return blockSettlementData === null || blockSettlementData === undefined ? null : blockSettlementData;
    }

    public get getBetReferralEnabled(): boolean | null {
        const betReferralEnabled = this.getData().betReferralEnabled;
        return betReferralEnabled === null || betReferralEnabled === undefined ? null : betReferralEnabled;
    }

    public get inPlayDelay(): number {
        const inPlayDelay = this.getData().inPlayDelay;
        return typeof inPlayDelay === 'number' ? inPlayDelay : 0;
    }

    public get eachWayStakeReduction(): number {
        const stakeReduction = this.getData().eachWayStakeReduction;
        return typeof stakeReduction === 'number' ? stakeReduction : 0;
    }

    public get betLimitsMap(): BetLimitMapType | null {
        const limits = this.getData().betLimitsMap;
        if (limits === undefined || limits === null) {
            return null;
        }
        return limits;

    }

    public get feedSettings(): FeedSettingsType | null {
        return this.getData().feedSettings ?? null;
    }

    public get cashoutMarketSettings(): CashoutMarketSettingsType | null {
        return this.getData().cashoutMarketSettings ?? null;
    }

    public get singlesOnly(): boolean {
        return this.getData().singlesOnly ?? false;
    }
}
