import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const NameWrapper = withConfig(theme => styled('div', { label: 'NameWrapper' })`
    align-items: center;
    color: ${theme.star.betslipGeneral.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    justify-content: space-between;
    line-height: 1.43;
`);

const setIndicatorColorStatus = (theme: EmotionTheme, status?: string): SerializedStyles => {
    if (status !== undefined) {
        switch (status) {
            case 'won':
                return css`
                    background: ${theme.star.betslipSelection.bgColor};
                `;
            case 'lost':
                return css`
                    background: ${theme.star.betslipSelection.bgColorSecondary};
                `;
            case 'halfwon':
                return css`
                    background: ${theme.star.betslipSelection.bgColorTertiary};
                `;
            case 'halflost':
                return css`
                    background: ${theme.star.betslipSelection.bgColorQuaternary};
                `;
            case 'place':
                return css`
                    background: ${theme.star.betslipSelection.bgColorQuaternary};
                `;
        }
    }

    return css`
        background: ${theme.star.betslipSelection.bgColorQuinary};
    `;
};

interface NameInfoWrapperType {
    isSettled: boolean;
    status: string | undefined;
}

export const NameInfoWrapper = withConfig(theme => styled('div', { label: 'NameInfoWrapper' })<NameInfoWrapperType>`
    align-items: center;
    display: flex;
    position: relative;
    &::before {
        ${({ isSettled }): string => (isSettled ? 'content: ""' : 'content: none')};
        border-radius: 50%;
        height: 8px;
        margin-right: 6px;
        width: 8px;
        ${({ status }): SerializedStyles => setIndicatorColorStatus(theme, status)};
    }
`);
