import { timeout } from 'src_common/common/mobx-utils/timeout';

const infinite = <T>(): Promise<T> => {
    return new Promise(() => {});
};

export class RequestDebounce {
    private curentRequest: {} | null = null;

    public constructor(private timeoutMs: number) {}

    public async call<T>(get: () => Promise<T>): Promise<T> {
        const marker = {};
        this.curentRequest = marker;

        await timeout(this.timeoutMs);

        if (this.curentRequest === marker) {
            const response = await get();
            return response;
        } else {
            const response = await infinite<T>();
            return response;
        }
    }
}
