import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { withConfig } from 'src/withConfig';

export const HeaderWrapper = withConfig(theme => styled('header', { label: 'HeaderWrapper' })`
    align-items: center;
    background: ${theme.star.sidebar.bgColor};
    color: ${theme.star.sidebar.txtColor};
    display: flex;
    flex: 0 0 48px;
    position: relative;
`);

export const TitleWrapper = withConfig(theme => styled('h3', { label: 'TitleWrapper' })`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3;
    margin: 0 0 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
`);

export const HeaderCloseIcon = styled(CloseIcon, { label: 'HeaderCloseIcon' })`
    cursor: pointer;
    fill: currentcolor;
    flex: 0 0 48px;
    margin-left: auto;
    padding: 14px;
`;

