import React from 'react';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import emptyHRSilk_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import emptyHRSilk from './assets/empty-hr-silk.svg';
import emptyGHSilk from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import classNames from 'classnames';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel'; 
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { useAppStateContext } from 'src/appState/AppState';
import {
    QuickPickNamePlace, QuickPickNameRunner, QuickPickNameRunnerTitle, QuickPickNameSilk,
    QuickPickNameStyle,
    QuickPickNameTitle
} from 'src/domains/sportsbook/webview/components/quickPick/QuickPick.style';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Trap } from 'src/domains/sportsbook/webview/components/trap/Trap';
import { RunnerDesc } from 'src/domains/sportsbook/webview/components/quickPick/QuickPickName.style';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsTypes {
    race: EventModel | undefined;
    market: MarketModel;
    sort: (selections: Array<SelectionModel>) => Array<SelectionModel>;
    hoveredSelection: SelectionId | null;
    sport: string | undefined;
}

export const QuickPickName = observer('QuickPickName', (props: PropsTypes) => {
    const { config } = useAppStateContext();
    const { market, race, sort, sport, hoveredSelection } = props;

    const isHorseracing = sport === 'horseracing';
    const selections = sort(market.selections.filter((x: SelectionModel) => x.display));

    return (
        <QuickPickNameStyle>
            <QuickPickNameTitle>
                {isHorseracing && <QuickPickNamePlace>#</QuickPickNamePlace>}
                <QuickPickNameSilk>
                    <I18n langKey='events.quick-pick.silk.label' defaultText='Silk' />
                </QuickPickNameSilk>
                <QuickPickNameRunnerTitle>
                    <I18n langKey='events.quick-pick.runner-jockey-trainer.label' defaultText='Runner, Jockey, Trainer' />
                </QuickPickNameRunnerTitle>
            </QuickPickNameTitle>
            {
                selections.map((selection: SelectionModel) => {
                    const isUnnamedFavourite = selection.templateId === 'unnamed-favourite';
                    const nonRunner = selection.resultType === 'void';
                    const jockey = selection.metaDataJockey;
                    const trainer = selection.metaDataTrainer;
                    const number = selection.metaDataNumber;
                    const drawn = selection.metaDataDrawn;
                    const courseType = race?.feedCourseType;
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    const description = jockey ? trainer ? `${jockey} / ${trainer}` : jockey : trainer || 'n/a';

                    const silkUrl = selection.metaDataSilkUrl;

                    const cls = classNames({
                        'quick-pick__name--nr': nonRunner,
                        'hovered': selection.id2 === hoveredSelection
                    });

                    return (
                        <li key={selection.id} className={cls}>
                            {isUnnamedFavourite || !isHorseracing ?
                                undefined :
                                <QuickPickNamePlace>
                                    {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                                    {number ? number : 'n/a'}
                                    <br />
                                    {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                                    {drawn && courseType === 'Flat' ? `(${drawn})` : undefined}
                                </QuickPickNamePlace>
                            }
                            {isUnnamedFavourite ?
                                undefined :
                                <QuickPickNameSilk>
                                    { isHorseracing ? (
                                        <LazyImage
                                            src={silkUrl}
                                            placeholder={config.shouldDisplaySilkWithoutQuestionMark ? emptyHRSilk : emptyHRSilk_old}
                                            alt={selection.metaDataSilk}
                                        />
                                    ) : (
                                        <Trap
                                            number={selection.metaDataNumber}
                                            placeholder={emptyGHSilk}
                                            selectionId={selection.id2}
                                        />
                                    )}
                                </QuickPickNameSilk>
                            }
                            <QuickPickNameRunner>
                                {selection.name === '' ? 'n/a' : selection.name}
                                { isUnnamedFavourite || !isHorseracing ?
                                    undefined :
                                    <RunnerDesc>{description}</RunnerDesc>
                                }
                            </QuickPickNameRunner>
                        </li>
                    );
                })
            }
        </QuickPickNameStyle>
    );
});
