import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { CarouselSelection } from './CarouselSelection';
import { CarouselEventNavigationState } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigationState';

interface PropsType {
    state: CarouselEventNavigationState;
}

export const CarouselEventNavigationCompetition = observer('CarouselEventNavigationCompetition', (props: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    return (
        <CarouselSelection
            options={props.state.competitionState.competitionsData}
            placeholder={languagesState.getTranslation('events.navigation.competition', 'Select competition')}
            active={props.state.competitionState.active}
            type='competition'
        />
    );
});


