import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { TransactionHistoryTabState } from 'src/domains/players/webview/components/Account/transactionHistoryTab/TransactionHistoryTab.state';
import { BetsListBet, TransactionHistoryItemWrapper } from './TransactionsList.style';
import { NetDepositEmail, NetDepositEmailInformation, NetDepositText } from './NetDeposit.style';
import { TransactionNetDepositCurrency } from './Transaction.style';
import { useAppStateContext } from 'src/appState/AppState';
import { DateTime } from 'src_common/utils/time/time';

interface PropsTypes {
    state: TransactionHistoryTabState;
}

export const NetDeposit = observer('NetDeposit', (props: PropsTypes) => {
    const { collection } = props.state;
    const range = collection.range;

    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const { accountHelperMail } = configComponents.config;
    const { usersState } = appPlayersState;

    const totalValue = collection.netDeposit?.aggregations.totalValue === undefined ? null : collection.netDeposit?.aggregations.totalValue;

    const rangeFrom = range?.from;
    const startDate = (rangeFrom === undefined) ? DateTime.from('2018-01-01') : DateTime.from(rangeFrom);
    const startDateForView = startDate?.format('D MMM YYYY');

    const rangeTo = range?.to;
    const endDate = rangeTo === undefined ? DateTime.current() : DateTime.from(`${rangeTo}T23:59:59`);
    const endDateForView = endDate?.format('D MMM YYYY');

    return (
        <>
            <BetsListBet type='net-deposit'>
                <TransactionHistoryItemWrapper>
                    <TransactionNetDepositCurrency>
                        <span data-test='date'>{startDateForView}</span>
                    </TransactionNetDepositCurrency>
                    <TransactionNetDepositCurrency>
                        <span data-test='date'>{endDateForView}</span>
                    </TransactionNetDepositCurrency>
                    <TransactionNetDepositCurrency isNegative={totalValue === null ? true : totalValue.isLessThanZero()}>
                        <b>
                            {usersState.money(totalValue)}
                        </b>
                    </TransactionNetDepositCurrency>
                </TransactionHistoryItemWrapper>
            </BetsListBet>

            <NetDepositText>
                <I18n langKey='account.transaction.list.net-deposit.text1' defaultText='A positive net deposit amount means you have more money in your wallet than you have deposited in the timeframe specified. ' />
            </NetDepositText>

            <NetDepositText>
                <I18n langKey='account.transaction.list.net-deposit.text2' defaultText='A negative net deposit amount means you have deposited more than you have in your wallet in the timeframe specified.' />

                <NetDepositEmailInformation>
                    <I18n langKey='account.transaction.list.net-deposit.email-info' defaultText='If you have any concerns please contact ' />

                    <NetDepositEmail to={`mailto:${accountHelperMail}`}>{accountHelperMail}</NetDepositEmail>
                </NetDepositEmailInformation>
            </NetDepositText>
        </>
    );
});
