import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';
import { SocketEventType, StartSocketResultType, UnsubscribeFnType, startSocket } from './SocketConnection';
import { runInAction } from 'mobx';
import { LogContext } from './SocketConnect';

class SubscriptionsConnect {
    public readonly socket: StartSocketResultType;
    private readonly batchTimer: ReturnType<typeof setInterval>;

    public constructor(
        private readonly log: LogContext,
        private readonly host: string,
        private readonly timeout: number,
        private readonly batchTime: number,
        private eventMessage: EventEmmiter<SocketEventType>
    ) {
        const messageBuffer: Array<SocketEventType> = [];

        this.socket = startSocket(this.log, this.host, this.timeout, (data: SocketEventType) => {
            messageBuffer.push(data);
        });

        this.batchTimer = setInterval(() => {
            const toSend = [...messageBuffer];
            messageBuffer.length = 0;

            runInAction(() => {
                for (const item of toSend) {
                    this.eventMessage.trigger(item);
                }
            });
        }, this.batchTime);
    }

    public socketOff = (): void => {
        this.socket.dispose();
        clearInterval(this.batchTimer);
    };
}

class Subscriptions {
    private value: null | SubscriptionsConnect = null;
    private readonly data: Set<{}>;

    public constructor(
        private readonly log: LogContext,
        private readonly connect: () => SubscriptionsConnect
    ) {
        this.data = new Set();
    }

    public getSocket(): StartSocketResultType | null {
        return this.value?.socket ?? null;
    }

    public subscribe = (): UnsubscribeFnType => {
        const id = {};

        if (this.data.size === 0) {
            if (this.value !== null) {
                this.log.error('SocketController -> subscribe -> null expected');
                this.value.socketOff();
                this.value = null;
            }

            this.value = this.connect();
        }

        this.data.add(id);

        return (): void => {
            this.data.delete(id);

            if (this.data.size === 0) {
                if (this.value === null) {
                    this.log.error('SocketController -> subscribe -> not null expected');
                } else {
                    this.value.socketOff();
                    this.value = null;
                }
            }
        };
    };

    public reconnect(): void {
        this.log.info('reconnect');

        if (this.value !== null) {
            this.value.socketOff();
            this.value = this.connect();
        }
    }
}

export class SocketController {
    private readonly eventMessage: EventEmmiter<SocketEventType>;
    private subscriptions: Subscriptions;
    private log: LogContext;

    public constructor(
        label: string,
        host: string,
        private readonly isBrowser: boolean,
        timeout: number,
        batchTime: number
    ) {
        this.log = new LogContext(label);
        this.eventMessage = new EventEmmiter();
        this.subscriptions = new Subscriptions(
            this.log,
            () => new SubscriptionsConnect(this.log, host, timeout, batchTime, this.eventMessage)
        );

        if (isBrowser) {
            document.addEventListener('visibilitychange', () => {
                if (!document.hidden) {
                    this.subscriptions.reconnect();
                }
            });
        }
    }

    public subscribe = (): UnsubscribeFnType => {
        if (this.isBrowser) {
            return this.subscriptions.subscribe();
        } else {
            return () => {};
        }
    };

    public send(message: string): void {
        const socket = this.subscriptions.getSocket();

        if (socket === null) {
            this.log.error('SocketController - send - no active connection');
        } else {
            socket.send(message);
        }
    }

    public onData(callback: (data: SocketEventType) => void): () => void {
        return this.eventMessage.on(callback);
    }
}
