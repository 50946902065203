import { action, computed, observable, makeObservable } from 'mobx';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel } from 'src_common/common/mobx-utils/Form2/FormModel';
import { UsersState, SrgQuestionsOnlyType, UserBasicDataType } from 'src/domains/players/state/UsersState';
import { Resource } from 'src_common/common/mobx-utils/Resource';

type InputValues = 'yes' | 'no' | '';

export interface QuestionSetType {
    step: number;
    question: string;
    state: FormInputState<InputValues, InputValues>;
}
export class SrgQuestionnaireState {
    @observable public currentSrgStep: number = 0;
    @observable public moreDescription: boolean = false;
    @observable public maxQuestionsLength: number = 6;
    @observable public isModalOpen: boolean = true;
    @observable public srgPassed: boolean | null = null;
    @observable public isLoading: boolean = false;
    public readonly srgQuestionsOnlyResource: Resource<SrgQuestionsOnlyType | null>;

    public question1: FormInputState<InputValues, InputValues>;
    public question2: FormInputState<InputValues, InputValues>;
    public question3: FormInputState<InputValues, InputValues>;
    public question4: FormInputState<InputValues, InputValues>;
    public question5: FormInputState<InputValues, InputValues>;
    public question6: FormInputState<InputValues, InputValues>;
    public questions: FormInputState<InputValues, InputValues>[] = Array.from({ length: 6 }, () =>
        FormInputState.new<InputValues>('')
    );

    public constructor(private readonly usersState: UsersState) {
        makeObservable(this);

        this.question1 = this.questions[0] ?? FormInputState.new<InputValues>('');
        this.question2 = this.questions[1] ?? FormInputState.new<InputValues>('');
        this.question3 = this.questions[2] ?? FormInputState.new<InputValues>('');
        this.question4 = this.questions[3] ?? FormInputState.new<InputValues>('');
        this.question5 = this.questions[4] ?? FormInputState.new<InputValues>('');
        this.question6 = this.questions[5] ?? FormInputState.new<InputValues>('');

        this.srgQuestionsOnlyResource = new Resource(async (): Promise<SrgQuestionsOnlyType | null> => {
            const response = await this.usersState.srgQuestionsOnly({ question_set_id: 'latest' });

            this.maxQuestionsLength = Object.keys(response.questions).length;

            return response;
        });
    }

    @computed public get userBasicData(): UserBasicDataType['valueReady'] | null {
        const userData = this.usersState.basicData.valueReady;
        if (userData === null) return null;

        return userData;
    }

    @computed public get srgQuestionsOnly(): SrgQuestionsOnlyType | null {
        const response = this.srgQuestionsOnlyResource.get();

        return response?.type === 'ready' && response.value !== null ? response.value : null;
    }

    @computed public get questionSet(): QuestionSetType[] {
        if (this.srgQuestionsOnly === null) return [];

        return [
            {
                step: 1,
                question: this.srgQuestionsOnly.questions['1'] ?? '',
                state: this.question1,
            },
            {
                step: 2,
                question: this.srgQuestionsOnly.questions['2'] ?? '',
                state: this.question2,
            },
            {
                step: 3,
                question: this.srgQuestionsOnly.questions['3'] ?? '',
                state: this.question3,
            },
            {
                step: 4,
                question: this.srgQuestionsOnly.questions['4'] ?? '',
                state: this.question4,
            },
            {
                step: 5,
                question: this.srgQuestionsOnly.questions['5'] ?? '',
                state: this.question5,
            },
            {
                step: 6,
                question: this.srgQuestionsOnly.questions['6'] ?? '',
                state: this.question6,
            },
        ];
    }

    @action public goToNextStep = (): void => {
        this.currentSrgStep = this.currentSrgStep + 1;
    };

    @action public goToPrevStep = (): void => {
        this.currentSrgStep = this.currentSrgStep - 1;
    };

    @action public toggleDescription = (): void => {
        this.moreDescription = !this.moreDescription;
    };

    @computed public get showMoreDescription(): boolean {
        if (this.currentSrgStep === 0 || this.moreDescription === true) {
            return true;
        }
        return false;
    }
    @computed public get showToggleInfo(): boolean {
        return this.currentSrgStep !== 0 || this.srgPassed !== null;
    }

    @computed public get showSrgForm(): boolean {
        return (
            this.userBasicData?.srgStatus === 'requested' ||
            ((this.userBasicData?.srgStatus === 'passed' || this.userBasicData?.srgStatus === 'failed') &&
                (this.srgPassed === true || this.srgPassed === false))
        );
    }

    @action public onSubmit = async (): Promise<void> => {
        await this.SubmitRequest();
    };

    private preparedForm = (): Record<number, FormInputState<InputValues, InputValues>> => {
        const fillObj: Record<number, FormInputState<InputValues, InputValues>> = {};
        return this.questions
            .filter((_, index) => index < this.maxQuestionsLength)
            .reduce((acc, curr, index) => {
                acc[index + 1] = curr;
                return acc;
            }, fillObj);
    };

    @action private SubmitRequest = async (): Promise<void> => {
        const srgAnswersGroup = FormModel.group(this.preparedForm());
        const formResult = srgAnswersGroup?.result.value.type === 'ok' ? srgAnswersGroup.result.value : null;

        try {
            this.isLoading = true;
            if (formResult?.type !== 'ok' || this.userBasicData === null || this.srgQuestionsOnly === null)
                throw new Error('Problem with validation form or with user data');

            const body = {
                accountId: this.userBasicData.id.toString(),
                answerTimestamp: new Date().toISOString(),
                answeredQuestions: formResult.data,
                questionSetId: this.srgQuestionsOnly.questionSetId,
                universe: this.userBasicData.universe,
            };
            this.srgPassed = (await this.usersState.srgValidate({ requestBody: body })).srgPassed;
        } catch (e) {
            console.error('submit error:', e);
        } finally {
            this.isLoading = false;
        }
    };
}
