import React, { useState } from 'react';
import { SelectionParticipantName, SelectionItemStyle } from 'src/domains/sportsbook/webview/components/Selection/Selection.style';
import { observer } from 'src/utils/mobx-react';
import { SelectionState } from 'src/domains/sportsbook/webview/components/Selection/Selection.state';
import { useAsObservableSource } from 'mobx-react-lite';
import { useAppStateContext } from 'src/appState/AppState';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    selectionId: SelectionId | undefined;
    sp?: boolean;
    showParticipantName?: string | null;
    borderTop?: boolean;
    borderRight?: boolean;
    borderBottom?: boolean;
    borderLeft?: boolean;
    className?: string;
}

export const Selection = observer('Selection', (propsIn: PropsType) => {
    const props = useAsObservableSource(propsIn);
    const { showParticipantName, borderTop, borderRight, borderBottom, borderLeft, className } = props;
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { sdkCustomer } = appLayoutsState;
    const { betSlipState } = appSportsBookState;

    const [state] = useState(() => new SelectionState(betSlipState, props, sdkCustomer));

    if (state.selectionView === null) {
        return (
            <SelectionItemStyle
                borderBottom={borderBottom}
                borderLeft={borderLeft}
                borderRight={borderRight}
                borderTop={borderTop}
                className={`${className === undefined ? '' : className} selection`}
                href='#'
                onClick={state.chooseNew}
            >-</SelectionItemStyle>
        );
    }

    const { priceChange, suspended, price } = state.selectionView;
    const isEmpty = price === undefined;

    const selectionId: string | number | undefined = price === 'sp'
        ? `sp-${props.selectionId?.toOldId() ?? 'id-undefined'}`
        : props.selectionId?.toOldId();

    return (
        <SelectionItemStyle
            href='#'
            data-test-selection-id={selectionId}
            onClick={state.chooseNew}
            className={`selection ${className === undefined ? '' : className}`}
            isSelected={state.isSelected}
            isSuspended={suspended}
            isEmpty={isEmpty}
            isOddsUp={priceChange === 'up'}
            isOddsDown={priceChange === 'down'}
            isShowParticipantName={showParticipantName !== undefined}
            borderTop={borderTop}
            borderRight={borderRight}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
            data-test='selection-item'
        >
            {
                showParticipantName === undefined ? null : (
                    <SelectionParticipantName>{showParticipantName}</SelectionParticipantName>
                )
            }
            {state.getOddsValue}
        </SelectionItemStyle>
    );
});
