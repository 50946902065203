import { MethodType, GenerateUrlApiType, ResponseType, ParamsFetchType } from 'src_common/browser/apiUtils';
import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import {
    openapi_website_cms_getActiveQuickLinkTray,
    decodeResponse200,
    Response200Type,
} from 'src/api_openapi/generated/openapi_website_cms_getActiveQuickLinkTray';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export type ActiveQuickLinkTrayType = {
    casino_game_id?: null | undefined | number;
    competition_id?: null | undefined | number;
    direct_url?: null | undefined | string;
    display_order: number;
    event_id?: null | undefined | EventId;
    icon: string;
    id: number;
    is_active: boolean;
    label: string;
    redirect_option: string;
    sport: string;
    universe: string;
};

export const getActiveQuickLinkTray = {
    browser: {
        params: (): ParamsFetchType<{}> => {
            return {
                type: MethodType.GET,
                url: '/api-web/cms/active-quick-link-tray',
            };
        },
        decode: (status: number, data: ResponseType): Response200Type => {
            if (status === 200 && data.type === 'json') {
                return decodeResponse200(data.json);
            }

            throw new Error(`unhandled response ${status} - ${data.type}`);
        },
    },
    express: {
        method: MethodType.GET,
        urlBrowser: '/api-web/cms/active-quick-link-tray',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<{}>): Promise<GenerateUrlApiType> => {
        const resp = await openapi_website_cms_getActiveQuickLinkTray(
            params.API_URL,
            params.API_TIMEOUT,
            params.jwtToken,
            {
                universe: params.API_UNIVERSE,
            }
        );

        return {
            passToBackend: false,
            status: resp.status,
            responseBody: resp.body,
        };
    },
};
