/**
 * @deprecated
 */
export interface FilterType<T = string | number | null> {
    id: T,
    key: string,
    name?: string,
    label: string | JSX.Element,
    dataTest?: string,
}

/**
 * @deprecated
 */
export interface FiltersStateStrategy<T = string | number | null> {
    filters: FilterType<T>[],
    getActiveFilterId: () => T,
    setActiveFilterId: (id: T) => void,
    isFilterDisabled?: (id: T) => boolean,
}

/**
 * @deprecated
 */
export class FiltersState<T = string | number | null> {

    public constructor(
        private readonly strategy: FiltersStateStrategy<T>,
        public readonly id: string = ''
    ) {
    }

    public get filters(): FilterType<T>[] {
        return this.strategy.filters;
    }

    public get activeFilterId(): T {
        return this.strategy.getActiveFilterId();
    }

    public bindSetActiveFilterId = (id: T) => {
        return (): void => {
            this.strategy.setActiveFilterId(id);
        };
    }

    public isFilterDisabled = (id: T): boolean => {
        if (this.strategy.isFilterDisabled !== undefined) {
            return this.strategy.isFilterDisabled(id);
        }
        return false;
    }

    public isFilterActive = (id: T): boolean => {
        return this.activeFilterId === id;
    }

}
