import { observer } from 'src/utils/mobx-react';
import {
    SelectionCast
} from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCast';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import React from 'react';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

export interface SelectionDividendPropsType {
    className?: string;
    selectionId: SelectionId;
    index: number;
}

export const SelectionCastByPlace = observer('SelectionCastFirstPlace', (props: SelectionDividendPropsType ) => {

    switch (props.index) {
        case 0:
            return (
                <SelectionCast
                    selectionId={props.selectionId}
                    title={<I18n langKey='events.race-summary.1nd.label' defaultText='1st' />}
                    index={props.index}
                    borderLeft={true}
                />
            );
        case 1:
            return (
                <SelectionCast
                    selectionId={props.selectionId}
                    title={<I18n langKey='events.race-summary.2nd.label' defaultText='2nd' />}
                    index={props.index}
                    borderLeft={true}
                />
            );
        case 2:
            return (
                <SelectionCast
                    selectionId={props.selectionId}
                    title={<I18n langKey='events.race-summary.3nd.label' defaultText='3rd' />}
                    index={props.index}
                    borderLeft={true}
                />
            );
    }
    return null;
});
