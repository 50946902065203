import React from 'react';
import { Icon, IconContainer, TooltipContainer, TooltipContent, TooltipTriangle } from './Tooltip.style';

type TooltipPropsType = {
    content: React.ReactNode;
};
export const Tooltip = ({ content }: TooltipPropsType): JSX.Element => {
    return (
        <TooltipContainer>
            <IconContainer>
                <Icon />
            </IconContainer>
            <TooltipTriangle className='tooltip-triangle' />
            <TooltipContent className='tooltip-content'>{content}</TooltipContent>
        </TooltipContainer>
    );
};
