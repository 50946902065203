import { computed, makeObservable } from 'src_common/common/mobx-wrapper';
import { Value } from 'src_common/common/mobx-utils/Value';
import { DateTime } from 'src_common/utils/time/time';

const TIME_REFRESH = 2000;
//const TIME_REFRESH = 60000;

const currentTime: Value<DateTime> = new Value(DateTime.current().utc(), (setValue) => {
    setValue(DateTime.current().utc());

    const timer = setInterval(() => {
        setValue(DateTime.current().utc());
    }, TIME_REFRESH);

    return (): void => {
        clearInterval(timer);
    };
});

const getCurrent = (): DateTime => {
    return currentTime.getValue().clone();
};

interface ServerTimeRangeType {
    readonly from: number;
    readonly to: number;
}

export class ServerTimeRange {
    public readonly get: () => ServerTimeRangeType;

    public constructor(get: () => { from: number; to: number }) {
        this.get = get;
        makeObservable(this);
    }

    @computed private get range(): ServerTimeRangeType {
        return this.get();
    }

    @computed private get from(): number {
        return this.range.from;
    }

    @computed private get to(): number {
        return this.range.to;
    }

    public matchMs(timeUnixMsSeconds: number): boolean {
        return this.from <= timeUnixMsSeconds && timeUnixMsSeconds <= this.to;
    }
}

const getMillisecond = (time: DateTime): number => time.unixSeconds() * 1000;

export class ServerTimeState {
    public readonly searchFrom: () => number;
    public readonly inPlay: ServerTimeRange;
    public readonly startedAndFinished: ServerTimeRange;
    public readonly nextOff: ServerTimeRange;
    public readonly upcoming: ServerTimeRange;
    public readonly today: ServerTimeRange;
    public readonly tomorrow: ServerTimeRange;
    public readonly weekend: ServerTimeRange;
    public readonly currentWeek: ServerTimeRange;
    public readonly nextWeek: ServerTimeRange;

    public readonly fromNowToNext24h: ServerTimeRange;
    public readonly fromNowToNextWeek: ServerTimeRange;

    public constructor() {
        this.searchFrom = (): number => getCurrent().subtractDays(6).unixSeconds();

        this.inPlay = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent().subtractDays(6)),
                to: getMillisecond(getCurrent()),
            })
        );

        this.startedAndFinished = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent().subtractYears(1)),
                to: getMillisecond(getCurrent()),
            })
        );

        this.nextOff = new ServerTimeRange((): ServerTimeRangeType => {
            const startFrom = getCurrent().startOfDays();

            return {
                from: getMillisecond(startFrom),
                to: getMillisecond(startFrom.clone().addHours(48)),
            };
        });

        this.upcoming = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent()),
                to: getMillisecond(getCurrent().addYears(1).addDays(1)),
            })
        );

        this.today = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent().startOfDays()),
                to: getMillisecond(getCurrent().endOfDays()),
            })
        );

        this.tomorrow = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent().addDays(1).startOfDays()),
                to: getMillisecond(getCurrent().addDays(1).endOfDays()),
            })
        );

        this.weekend = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent().endOfIsoWeek().subtractDays(2)),
                to: getMillisecond(getCurrent().endOfIsoWeek()),
            })
        );

        this.currentWeek = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent().startOfIsoWeek()),
                to: getMillisecond(getCurrent().endOfIsoWeek()),
            })
        );

        this.nextWeek = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent().addWeeks(1).startOfIsoWeek()),
                to: getMillisecond(getCurrent().addWeeks(1).endOfIsoWeek()),
            })
        );

        this.fromNowToNext24h = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent()),
                to: getMillisecond(getCurrent().addHours(24)),
            })
        );

        this.fromNowToNextWeek = new ServerTimeRange(
            (): ServerTimeRangeType => ({
                from: getMillisecond(getCurrent()),
                to: getMillisecond(getCurrent().addWeeks(1)),
            })
        );
    }
}
