import React, { useState, useLayoutEffect, useRef } from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observable, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Iframe, MessageDataType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/Iframe';
import { RealexLoaderWrapper, RealexFrameWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/realexFrame/RealexFrame.style';


class RealexFrameState {
    @observable public id: number = 0;
    @observable public loaded: boolean = false;

    public constructor() {
        makeObservable(this);
        this.id++;
    }

    public getDepositID = (formData: Record<string, string>): string | undefined => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return formData?.GP_DEPOSIT_ID || formData?.DEPOSIT_ID;
    }

    public hideLoader = (): void => {
        this.loaded = true;
    }
}

export interface RealexFrameProps {
    formActionUrl: string,
    formData: Record<string, string>,
    onMessage: (data: MessageDataType) => void,
}

export const RealexFrame = observer('RealexFrame', (propsIn: RealexFrameProps): JSX.Element | null => {
    const props = useAsObservableSource(propsIn);
    const [state] = useState(() => new RealexFrameState());

    const btnRef = useRef<HTMLButtonElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    useLayoutEffect(() => {
        if (formRef.current !== null) {
            formRef.current.submit();
        }
        if (btnRef.current !== null) {
            btnRef.current.click();
        }
    });

    const { formData, formActionUrl, onMessage } = props;

    const getFormName = (): string => {
        const iframeId = state.getDepositID(formData);
        if (iframeId === undefined) {
            new Error('No Iframe target Name');
            return '';
        } else {
            return `realexFrame_${iframeId}_${state.id}`;
        }
    };

    const fields = Object.keys(formData).map(key => {
        return (
            <input
                type='hidden'
                key={key}
                name={key}
                value={formData[key]}
            />
        );
    });

    const loader = (
        <RealexLoaderWrapper size={12}>
            <I18n langKey='account.realex-frame.loading' defaultText='Loading...' />
        </RealexLoaderWrapper>
    );

    return (
        <RealexFrameWrapper className='realex-frame'>
            <form
                ref={formRef}
                method='POST'
                action={formActionUrl}
                target={getFormName()}
                style={{ display: 'none' }}
            >
                {fields}

                <button
                    type='submit'
                    ref={btnRef}
                />
            </form>
            {!state.loaded && loader}
            <Iframe
                title='realex-frame'
                name={getFormName()}
                onMessage={onMessage}
                onLoad={state.hideLoader}
            />
        </RealexFrameWrapper>
    );
});
