import React from 'react';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { observer as observerLite } from 'src/utils/mobx-react';
import { EventRowSelections, EventRowSelectionsName, EventRowSelectionsElement, EventRowSingleSelection } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/EventRowSelectionsGroup.style';

interface SelectionsGroupPropsType {
    market?: MarketModel;
    marketTypeName?: string;
    getSelectionNameCallback: (market:MarketModel | undefined, selection: SelectionModel) => string;
}

export const SelectionsGroup = observerLite<SelectionsGroupPropsType>('SelectionsGroup',( props ) => {

    const { market, marketTypeName = '-', getSelectionNameCallback } = props;

    if (market === undefined) {
        return (
            <EventRowSelections>
                <EventRowSelectionsName>
                    { marketTypeName }
                </EventRowSelectionsName>
            </EventRowSelections>
        );
    }
    return (
        <EventRowSelections>
            <EventRowSelectionsName>
                { marketTypeName }
            </EventRowSelectionsName>
            {market.selections?.map((selection) => {
                return (
                    <EventRowSelectionsElement key={selection.id2.toOldId()}>
                        <EventRowSingleSelection
                            showParticipantName={getSelectionNameCallback(market, selection)}
                            selectionId={selection.id2}
                            borderLeft={true}
                            borderRight={true}
                            borderBottom={true}
                        />
                    </EventRowSelectionsElement>
                );
            })}
        </EventRowSelections>
    );
});
