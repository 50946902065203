import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { MiniGameImgWrapperLazy, MiniGameWrapper } from './MiniGame.style';
import { ThumbnailOverlay } from 'src/domains/casino/webview/CasinoGames/single-game-ext/thumbnail-overlay/ThumbnailOverlay';

interface MiniGamePropsType {
    appCasinoState: AppCasinoState;
    gameId: number;
}

export const MiniGame = observer('MiniGame', (props: MiniGamePropsType) => {
    const { appCasinoState } = props;
    const gameModel = appCasinoState.gameStoreExt.getGameModel(props.gameId);

    if (gameModel === null) {
        return null;
    }

    const miniGameData = gameModel.data;
    if (miniGameData === null) {
        return null;
    }

    return (
        <MiniGameWrapper data-test-game-id={gameModel.id}>
            <MiniGameImgWrapperLazy
                alt={miniGameData.name}
                imgBase={{
                    src: miniGameData.image,
                    optimizeOptions: {
                        width: 240,
                        quality: 75,
                    },
                }}
                placeholder={true}
            />
            <ThumbnailOverlay
                appCasinoState={appCasinoState}
                gameModel={gameModel}
                showLikeAndInfo={false}
                handleOnMobileClick={(): void => gameModel.startGame({ mode: 'mini' })}
                handlePlayClick={(): void => gameModel.startGame({ mode: 'mini' })}
            />
        </MiniGameWrapper>
    );
});
