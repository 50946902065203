import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { deburr } from 'src/utils/deburr';
import { checkIsLetter, checkIsSpecialCharacter } from 'src_common/common/forms/validators/validatingFunctions';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';


const matchChar = (char: string): boolean => {
    const allowedSpecialChar = [' ', '-', '\''];

    if (checkIsLetter(char)) {
        return true;
    }
    if (checkIsSpecialCharacter(allowedSpecialChar, char)) {
        return true;
    }
    return false;
};

export const checkName = (name: string): boolean => {
    for (const char of name) {
        if (matchChar(char) === false) {
            return false;
        }
    }
    return true;
};

export const validateFirstName = (value: string): Result<string> => {
    const maxLength = 50;

    if (value.length > maxLength) {
        const errorLengthMessage = getErrorByCode('ERROR_MAX_LENGTH').replace('%length', maxLength.toString());
        return Result.createError(errorLengthMessage);
    }

    if (checkName(deburr(value))) {
        return Result.createOk(value);
    }

    const errorRequiredField = getErrorByCode('ERROR_INCORRECT_FIRST_NAME');
    return Result.createError(errorRequiredField);
};

export const validateLastName = (value: string): Result<string> => {
    const maxLength = 50;

    if (value.length > maxLength) {
        const errorLengthMessage = getErrorByCode('ERROR_MAX_LENGTH').replace('%length', maxLength.toString());
        return Result.createError(errorLengthMessage);
    }

    if (checkName(deburr(value))) {
        return Result.createOk(value);
    }
    const errorRequiredField = getErrorByCode('ERROR_INCORRECT_LAST_NAME');
    return Result.createError(errorRequiredField);
};
