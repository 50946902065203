import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { FiltersSkin, FiltersSkinType as FiltersSkinType } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

interface FilterPropsType {
    skin?: FiltersSkinType;
    isActive?: boolean;
    isDisabled?: boolean;
    isMultipleLine?: boolean;
}

export const FiltersWrapper = styled('div', { label: 'FiltersWrapper' })`
    position: relative;
    overflow-y: auto;
`;

export const GradientOverlay = withConfig(theme => styled('div', { label: 'GradientOverlay' })`
    background-image: ${`linear-gradient(to right,${theme.star.filtersColors.wrapper.bgColorSecondary},transparent)`};
    height: 32px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 56px;
    z-index: ${theme.star.zIndexGlobal.above};
`);

const setBorders = (theme: EmotionTheme, skin?: FiltersSkinType): SerializedStyles => {
    if (skin === FiltersSkin.PRIMARY) {
        return css`
            border: 1px solid ${theme.star.filtersColors.primary.borderColor};
        `;
    }

    if (skin === FiltersSkin.SECONDARY) {
        return css`
            border: 1px solid ${theme.star.filtersColors.secondary.borderColor};
        `;
    }

    if (skin === FiltersSkin.TERTIARY) {
        return css`
            border: 1px solid ${theme.star.filtersColors.tertiary.borderColor};
        `;
    }

    return css``;
};

const setColors = (theme: EmotionTheme, isActive?: boolean, skin?: FiltersSkinType): SerializedStyles => {
    if (skin === FiltersSkin.PRIMARY) {
        if (isActive === true) {
            return css`
                background: ${theme.star.filtersColors.primary.bgColor};
                color: ${theme.star.filtersColors.primary.txtColorSecondary};
            `;
        }
        return css`
            background: transparent;
            color: ${theme.star.filtersColors.primary.txtColor};
        `;
    }

    if (skin === FiltersSkin.SECONDARY) {
        if (isActive === true) {
            return css`
                background: ${theme.star.filtersColors.secondary.bgColor};
                color: ${theme.star.filtersColors.secondary.txtColorSecondary};
            `;
        } 
        return css`
            background: transparent;
            color: ${theme.star.filtersColors.secondary.txtColor};
        `;
    }

    if (skin === FiltersSkin.TERTIARY) {
        if (isActive === true) {
            return css`
                background: ${theme.star.filtersColors.tertiary.bgColor};
                color: ${theme.star.filtersColors.tertiary.txtColorSecondary};
            `;
        } 
        return css`
            background: transparent;
            color: ${theme.star.filtersColors.tertiary.txtColor};
        `;
    }

    return css`
        color: red;
        background-color: blue;
    `;
};

export const Filter = withConfig(theme => styled('button', { label: 'Filter' })<FilterPropsType>`
    ${({ skin }): SerializedStyles => setBorders(theme, skin)};
    ${({ isActive, skin }): SerializedStyles => setColors(theme, isActive, skin)};
    align-items: center;
    align-self: flex-start;
    border-radius: 0;
    cursor: ${({ isDisabled }): string => isDisabled === true ? 'not-allowed' : 'pointer'};
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    margin: ${({ isMultipleLine }): string => isMultipleLine === true ? '0 4px 4px 0' : '0 4px 0 0'};
    height: 32px;
    padding: 0 12px;
    user-select: none;
    white-space: nowrap;
    &:last-of-type {
        margin-right: 0;
    }
`);
