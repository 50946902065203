import React, { useState } from 'react';
import { EventsTable } from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable';
import { useAppStateContext } from 'src/appState/AppState';
import { FilterKindType } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterType';
import { computed, makeObservable } from 'mobx';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { observer } from 'src/utils/mobx-react';
import { EventsTableHeaderStyle } from 'src/domains/sportsbook/webview/components/eventsTable/EventsMainSectionWrapper';
import { AppSportsBookState } from 'src/domains/sportsbook/state/AppSportsBook.state';

const filters: Array<FilterKindType> = ['sport'];

class InPlayState {
    public constructor(private readonly appSportsBookState: AppSportsBookState){
        makeObservable(this);
    }

    @computed public get inPlayEventsCollection(): EventsCollectionList {
        return this.appSportsBookState.eventsCollection.listInPlay;
    }

    public get tableLabel(): string {
        return this.appSportsBookState.language.getTranslation('special-box.header.in-play', 'In play');
    }
};

interface PropsTypes {
    showPriceLegend: boolean,
    isLoading: boolean,
    headerStyleLevel: 'sportLevel' | 'competitionLevel'
}

export const InPlay = observer('InPlay', (props: PropsTypes) => {
    const { appSportsBookState, config } = useAppStateContext();
    const [inPlayState] = useState(() => new InPlayState(appSportsBookState));
    return (
        <EventsTable
            headerStyle={EventsTableHeaderStyle.SOLID}
            filterVersion={config.filtersVersion.inPlay}
            icon='in-play'
            title={inPlayState.tableLabel}
            showHeaderSport={true}
            allGroupsOpen={true}
            eventsCollection={inPlayState.inPlayEventsCollection}
            filters={filters}
            isTogglable={true}
            showPriceLegend={props.showPriceLegend}
            isLoading={props.isLoading}
            dataTest='in-play'
            hideNoEventsMessage={true}
            headerStyleLevel={props.headerStyleLevel}
            isAmericanFootball={false}
        />
    );
});
