import React, { useState } from 'react';
import { computed, observable, action, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import {
    TopUpLimitsForm,
    TopUpLimitsFormContent,
    FaqLink,
    SectionHeader,
    SectionHeaderContainer,
    TopUpLimitsProcedureBtnWrapper,
    LifetimeNetDepositLimitsContainer,
    LifetimeNetDepositLimitsDescription,
    LifeTimeLimitAmount,
} from 'src/domains/players/webview/components/Account/limitsTab/topUpLimitsProcedure/TopUpLimitsProcedure.style';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { TopUpLimitsProcedureItem, TopUpLimitsProcedureItemState } from './TopUpLimitsProcedureItem';
import { OpenapiProxyCustomerDepositLimitsResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_deposit_limits';
import { Switch } from 'src/domains/layouts/webview/components/Switch';
import { UsersState } from 'src/domains/players/state/UsersState';
import { AccountState } from 'src/domains/players/shared/Types';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

type InfoMessage = 'success' | 'error' | 'empty' | 'failure' | 'belowOne';

interface ConvertedLimits {
    dailyValue: Amount | undefined;
    weeklyValue: Amount | undefined;
    monthlyValue: Amount | undefined;
}

interface PreviousLimits {
    previousDaily: Amount | undefined;
    previousWeekly: Amount | undefined;
    previousMonthly: Amount | undefined;
}

class TopUpLimitsProcedureState {
    public readonly daily: TopUpLimitsProcedureItemState;
    public readonly weekly: TopUpLimitsProcedureItemState;
    public readonly monthly: TopUpLimitsProcedureItemState;

    @observable public infoMessage: InfoMessage | null = null;
    @observable public isDisableButton = false;
    @observable public isGamblingTurnedOn: boolean;

    public constructor(
        private readonly language: LanguagesState,
        private readonly configComponents: ConfigComponents,
        private readonly usersState: UsersState,
        private readonly accountState: AccountState
    ) {
        makeObservable(this);

        this.daily = new TopUpLimitsProcedureItemState(
            this.language,
            this.configComponents,
            usersState,
            this.clearInfoMessage,
            () => this.getDepositLimitsData()?.daily
        );

        this.weekly = new TopUpLimitsProcedureItemState(
            this.language,
            this.configComponents,
            usersState,
            this.clearInfoMessage,
            () => this.getDepositLimitsData()?.weekly
        );

        this.monthly = new TopUpLimitsProcedureItemState(
            this.language,
            this.configComponents,
            usersState,
            this.clearInfoMessage,
            () => this.getDepositLimitsData()?.monthly
        );

        this.isGamblingTurnedOn = this.checkisPreviousLimitsSet;
    }

    private getDepositLimitsData = (): OpenapiProxyCustomerDepositLimitsResponse200Type | null => {
        return this.usersState.depositLimitsData.valueReady;
    };
    @computed public get maxLifetimeNetDepositLimit(): string | null {
        const rawValue = this.getDepositLimitsData()?.maxLifetimeNetDepositLimit.active;

        return rawValue === null || rawValue === undefined
            ? null
            : new Amount(rawValue.toString()).div(100).format(this.usersState.currency);
    }
    private clearInfoMessage = (): void => {
        this.infoMessage = null;
    };

    private clearInputs = (): void => {
        this.daily.resetValue();
        this.weekly.resetValue();
        this.monthly.resetValue();
    };

    private getActualLimits = (): ConvertedLimits => {
        const dailyValue =
            this.daily.getInputState.result.value.type === 'ok'
                ? this.daily.getInputState.result.value.data
                : undefined;
        const weeklyValue =
            this.weekly.getInputState.result.value.type === 'ok'
                ? this.weekly.getInputState.result.value.data
                : undefined;
        const monthlyValue =
            this.monthly.getInputState.result.value.type === 'ok'
                ? this.monthly.getInputState.result.value.data
                : undefined;

        return { dailyValue, weeklyValue, monthlyValue };
    };

    public getPreviousLimits = (): PreviousLimits => {
        const daily = this.daily.getLimits()?.active?.amount;
        const weekly = this.weekly.getLimits()?.active?.amount;
        const monthly = this.monthly.getLimits()?.active?.amount;

        const previousDaily = daily === undefined ? undefined : this.configComponents.precision.newFromOld(daily);
        const previousWeekly = weekly === undefined ? undefined : this.configComponents.precision.newFromOld(weekly);
        const previousMonthly = monthly === undefined ? undefined : this.configComponents.precision.newFromOld(monthly);

        return { previousDaily, previousWeekly, previousMonthly };
    };

    public checkIsLessThanOne(limits: ConvertedLimits): boolean {
        const { dailyValue, weeklyValue, monthlyValue } = limits;
        const limit = new Amount('1');

        const dailyBelowOne = dailyValue !== undefined && dailyValue.isLessThan(limit);
        const weeklyBelowOne = weeklyValue !== undefined && weeklyValue.isLessThan(limit);
        const monthlyBelowOne = monthlyValue !== undefined && monthlyValue.isLessThan(limit);

        return dailyBelowOne || weeklyBelowOne || monthlyBelowOne;
    }

    @computed public get checkisPreviousLimitsSet(): boolean {
        const { previousDaily, previousMonthly, previousWeekly } = this.getPreviousLimits();

        return previousDaily !== undefined || previousMonthly !== undefined || previousWeekly !== undefined;
    }

    private chekisDepositLimitAlreadySet = (limits: ConvertedLimits, previousLimits: PreviousLimits): boolean => {
        const { dailyValue, weeklyValue, monthlyValue } = limits;
        const { previousDaily, previousWeekly, previousMonthly } = previousLimits;

        const dailyEqualsPrevious = dailyValue === undefined && previousDaily !== undefined;
        const weeklyEqualsPrevious = weeklyValue === undefined && previousWeekly !== undefined;
        const monthlyEqualsPrevious = monthlyValue === undefined && previousMonthly !== undefined;

        return dailyEqualsPrevious || weeklyEqualsPrevious || monthlyEqualsPrevious;
    };

    @computed public get isErrorInput(): boolean {
        return (
            (this.daily.isError || this.weekly.isError || this.monthly.isError) &&
            this.daily.amount.value === '' &&
            this.weekly.amount.value === '' &&
            this.monthly.amount.value === ''
        );
    }

    @computed public get haveMaximumLimit(): boolean {
        const havedailyMaxLimit = this.daily.maxDepositLimit !== undefined;
        const haveWeeklyMaxLimit = this.weekly.maxDepositLimit !== undefined;
        const haveMonthlyMaxLimit = this.monthly.maxDepositLimit !== undefined;

        return havedailyMaxLimit || haveWeeklyMaxLimit || haveMonthlyMaxLimit;
    }

    @computed public get maximumLimitLanguageParam(): string {
        const limitsMax = [
            this.daily.maxDepositLimit === undefined
                ? undefined
                : `${this.daily.maxDepositLimit?.format(this.usersState.currency)} per day`,
            this.weekly.maxDepositLimit === undefined
                ? undefined
                : `${this.weekly.maxDepositLimit?.format(this.usersState.currency)} per week`,
            this.monthly.maxDepositLimit === undefined
                ? undefined
                : `${this.monthly.maxDepositLimit?.format(this.usersState.currency)} per month`,
        ]
            .filter(Boolean)
            .join(', ');

        return limitsMax;
    }

    @computed public get isLoading(): boolean {
        const depositsModel = this.usersState.depositLimitsData.get();

        return depositsModel?.type === 'loading';
    }

    @action public toggleGambling = (): void => {
        if (this.checkisPreviousLimitsSet) {
            this.isGamblingTurnedOn = true;
            this.infoMessage = 'failure';
            return;
        }

        this.isGamblingTurnedOn = !this.isGamblingTurnedOn;
    };

    @action public onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        if (this.isDisableButton === true) {
            console.warn('action is executed');
            return;
        }

        e.preventDefault();

        const account = this.accountState.account;

        if (account === null) {
            return;
        }

        const { dailyValue, weeklyValue, monthlyValue } = this.getActualLimits();
        const { previousDaily, previousWeekly, previousMonthly } = this.getPreviousLimits();

        if (dailyValue === undefined && weeklyValue === undefined && monthlyValue === undefined) {
            this.clearInputs();
            this.infoMessage = 'empty';
            return;
        }

        if (
            this.chekisDepositLimitAlreadySet(
                { dailyValue, weeklyValue, monthlyValue },
                { previousDaily, previousMonthly, previousWeekly }
            )
        ) {
            this.clearInputs();
            this.infoMessage = 'failure';
            return;
        }

        if (this.checkIsLessThanOne({ dailyValue, weeklyValue, monthlyValue })) {
            this.clearInputs();
            this.infoMessage = 'belowOne';
            return;
        }

        try {
            this.isDisableButton = true;
            await account.onUpdateTopUpLimits(
                dailyValue?.value === undefined ? null : dailyValue,
                weeklyValue?.value === undefined ? null : weeklyValue,
                monthlyValue?.value === undefined ? null : monthlyValue
            );

            this.infoMessage = 'success';
            this.isDisableButton = false;
        } catch (error) {
            console.log(error);
            this.infoMessage = 'error';
            this.isDisableButton = false;
        }
    };
}

const renderMessage = (infoMessage: InfoMessage | null): JSX.Element | string => {
    if (infoMessage === 'error') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.top-up-limits-error-message'
                        defaultText='Sorry, we seem to have a problem. Please try again.'
                    />
                }
            />
        );
    } else if (infoMessage === 'success') {
        return (
            <Messages
                type='success'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.top-up-limits.success-message'
                        defaultText='Set limits finished successfully.'
                    />
                }
            />
        );
    } else if (infoMessage === 'empty') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.top-up-limits-error-message-empty-deposit'
                        defaultText='You have to choose at least one deposit limit to save.'
                    />
                }
            />
        );
    } else if (infoMessage === 'failure') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.top-up-limits-error-message-failure-deposit'
                        defaultText='You cannot set this deposit limit.'
                    />
                }
            />
        );
    } else if (infoMessage === 'belowOne') {
        return (
            <Messages
                type='error'
                marginBottom='8px'
                message={
                    <I18n
                        langKey='account.top-up-limits-error-message-below-one-deposit'
                        defaultText='You cannot set deposit limit below 1'
                    />
                }
            />
        );
    }

    return '';
};

export const TopUpLimitsProcedure = observer('TopUpLimitsProcedure', () => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents, languagesState } = appLayoutsState;
    const { accountState, usersState } = appPlayersState;

    const [state] = useState(
        () => new TopUpLimitsProcedureState(languagesState, configComponents, usersState, accountState)
    );

    if (state.isLoading) {
        return (
            <I18n
                langKey='account.contact-preferences.loading'
                defaultText='Loading...'
            />
        );
    }

    const limitsTitle = (
        <I18n
            langKey='account.top-up-limits.responsible-gambling.title'
            defaultText='Responsible Gambling'
        />
    );
    const limitInfo = (): JSX.Element => {
        return languagesState.translateTokens(
            languagesState.getTranslation(
                'account.top-up-limits.turn-on.max-deposit-limit-label',
                'You can set a limit of up to [limitsTogetherString]. More information about limits and options to increase them can be found in our [FAQ]'
            ),

            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'FAQ') {
                    return <FaqLink to={{ account: 'static', static: 'help' }}>FAQ</FaqLink>;
                } else if (singleParam.tag === 'limitsTogetherString') {
                    return state.maximumLimitLanguageParam;
                }
            }
        );
    };

    const lifetimelimitInfo = (): JSX.Element => {
        return languagesState.translateTokens(
            languagesState.getTranslation(
                'account.top-up-limits.lifetime-net-deposit-limits',
                "Your current lifetime net deposit limit is [net_limit]. This refers to the total amount you can deposit into your account, minus any withdrawals you've made. "
            ),

            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'net_limit') {
                    return <LifeTimeLimitAmount>{state.maxLifetimeNetDepositLimit}</LifeTimeLimitAmount>;
                }
            }
        );
    };
    return (
        <TopUpLimitsForm
            onSubmit={(e): Promise<void> => {
                return state.onSubmit(e);
            }}
            data-test='top-up-limits-procedure'
        >
            <>
                <SectionHeader
                    isUppercase={true}
                    data-test='responsible-gambling-header'
                >
                    {limitsTitle}
                </SectionHeader>
                <TopUpLimitsFormContent data-test='top-up-limits-form-content'>
                    <I18n
                        langKey='account.top-up-limits.description'
                        defaultText='If you wish you can limit how much we allow you to top up per day, per week or per month. Whichever limit is reached first in any given period will bring the top up restriction into effect.'
                    />
                </TopUpLimitsFormContent>
                {state.maxLifetimeNetDepositLimit === null ? null : (
                    <LifetimeNetDepositLimitsContainer>
                        <SectionHeader
                            isUppercase={true}
                            data-test='net-deposit-limit-header'
                        >
                            Net deposit limit
                        </SectionHeader>
                        <LifetimeNetDepositLimitsDescription data-test='net-deposit-limit-description'>
                            {lifetimelimitInfo()}
                        </LifetimeNetDepositLimitsDescription>
                    </LifetimeNetDepositLimitsContainer>
                )}

                <SectionHeaderContainer>
                    <SectionHeader
                        isUppercase={false}
                        data-test='deposit-limits-header'
                    >
                        <I18n
                            langKey='account.top-up-limits.turn-on.label'
                            defaultText='Turn on deposit limits'
                        />
                    </SectionHeader>
                    <Switch
                        onChange={state.toggleGambling}
                        switched={state.isGamblingTurnedOn || state.checkisPreviousLimitsSet}
                        version='primary'
                    />
                </SectionHeaderContainer>
                {state.haveMaximumLimit ? <TopUpLimitsFormContent>{limitInfo()}</TopUpLimitsFormContent> : null}
            </>

            {(state.isGamblingTurnedOn || state.checkisPreviousLimitsSet) === true ? (
                <>
                    <TopUpLimitsProcedureItem
                        state={state.daily}
                        label={
                            <I18n
                                langKey='account.top-up-limits.daily-limit.label'
                                defaultText='Set New Daily Limit'
                            />
                        }
                    />

                    <TopUpLimitsProcedureItem
                        state={state.weekly}
                        label={
                            <I18n
                                langKey='account.top-up-limits.weekly-limit.label'
                                defaultText='Set New Weekly Limit'
                            />
                        }
                    />

                    <TopUpLimitsProcedureItem
                        state={state.monthly}
                        label={
                            <I18n
                                langKey='account.top-up-limits.monthly-limit.label'
                                defaultText='Set New Monthly Limit'
                            />
                        }
                    />

                    {renderMessage(state.infoMessage)}

                    <TopUpLimitsProcedureBtnWrapper
                        type='submit'
                        size='medium'
                        disabled={state.isDisableButton || state.isErrorInput}
                        dataTest='deposit-limits-save-button'
                    >
                        <I18n
                            langKey='account.top-up-limits.submit.label'
                            defaultText='Save'
                        />
                    </TopUpLimitsProcedureBtnWrapper>
                </>
            ) : null}
        </TopUpLimitsForm>
    );
});
