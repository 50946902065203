import { action, computed, observable, makeObservable } from 'mobx';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { CASINO_MINI_GAMES_NAME } from 'src/domains/casino/utils/types';
import { GameStoreExt } from 'src/domains/casino/state/AppCasino/store/game-store/GameStoreExt';
import { CasinoGameModelExt } from 'src/domains/casino/state/AppCasino/store/game-store/CasinoGameModelExt';

export class MiniGamesListState {
    @observable public isGamesListDisplayed: boolean = false;
    @observable public isGamerGridDisplayed: boolean = false;
    @observable public isGamesListExtended: boolean = false;

    public constructor(
        public readonly gameStoreExt: GameStoreExt,
        private readonly breakpointsState: BreakpointsState,
        private readonly starRouter: StarRouter
    ) {
        makeObservable(this);
    }

    @computed public get casinoMiniGamesList(): Array<CasinoGameModelExt> {
        const miniCasinoCategory = this.gameStoreExt.getCollection('casino').getCategoryByName(CASINO_MINI_GAMES_NAME);

        const games: Array<CasinoGameModelExt> = [];
        if (miniCasinoCategory !== null) {
            for (const id of miniCasinoCategory.game_ids) {
                const game = this.gameStoreExt.getGameModel(id);
                if (game !== null) {
                    games.push(game);
                }
            }
        }
        return games;
    }

    @computed public get miniGamesToDisplay(): Array<CasinoGameModelExt> {
        const isDesktop = this.breakpointsState.desktop.isBiggerOrEq;
        const numberOfMiniGames = this.casinoMiniGamesList.length;
        const sliceNumberDesktop = this.isGamesListExtended ? numberOfMiniGames : 2;
        const sliceNumber = isDesktop === true ? sliceNumberDesktop : numberOfMiniGames;
        return this.casinoMiniGamesList.slice(0, sliceNumber);
    }

    @computed public get isMiniGamesListEmpty(): boolean {
        return this.miniGamesToDisplay.length <= 0;
    }

    @computed public get isOverlayOpenForCasinoMiniMobile(): boolean {
        if (this.breakpointsState.desktop.isBiggerOrEq === true || this.starRouter.currentView?.name === 'casino') {
            return false;
        }
        return this.isGamesListDisplayed;
    }

    @action public isTransitionFinished = (event: React.TransitionEvent): void => {
        if (this.isGamesListDisplayed === false && event.propertyName === 'transform') {
            this.isGamerGridDisplayed = false;
        }
    };

    @action public showGamesList = (): void => {
        this.isGamesListDisplayed = true;
        this.isGamerGridDisplayed = true;
    };

    @action public hideGamesList = (): void => {
        this.isGamesListDisplayed = false;
    };

    @action public toggleExtendGamesList = (): void => {
        this.isGamesListExtended = !this.isGamesListExtended;
    };
}
