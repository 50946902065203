import { CompetitionModelType } from 'src_common/common/websocket2/modelsApi/Competition';
import { Value } from 'src_common/common/mobx-utils/Value';
import { ModelBoxContext } from 'src_common/common/websocket2/common/ModelBoxContext';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';

export class CompetitionModel {
    private readonly modelBoxContext: ModelBoxContext;
    private readonly model: Value<CompetitionModelType>;

    public constructor(modelBoxContext: ModelBoxContext, model: Value<CompetitionModelType>) {
        this.modelBoxContext = modelBoxContext;
        this.model = model;
    }

    public getData(): CompetitionModelType {
        return this.model.getValue();
    }

    public getRawData(): CompetitionModelType {
        return this.getData();
    }

    /**
     * @deprecated - please use id2
     */
    public get id(): number {
        return this.getData().id;
    }

    public get id2(): CompetitionId {
        return this.modelBoxContext.websocketId().getCompetitionId(this.getData().id);
    }

    public get displayOrder(): number {
        return this.getData().displayOrder;
    }

    public get name(): string {
        return this.getData().name;
    }
}
