import { computed, observable, action, makeObservable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { SelectState } from 'src/domains/players/webview/components/form/select/Select.state';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { Result } from 'src_common/common/mobx-utils/Resource';
import { SelectOptionType } from 'src/domains/players/webview/components/form/select/Select';
import { OpenapiProxyCustomerRealityCheckResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_reality_check';

export class RealityCheckState {
    @observable public readonly selectedDuration: SelectState;
    @observable public isSwitched: boolean = false;
    @observable public infoMessage: string = '';

    public constructor(
        public readonly account: AccountState,
        public readonly language: LanguagesState
    ) {
        makeObservable(this);
        this.language = language;
        const duration = this.activeDuration !== undefined && this.activeDuration !== null ? this.realityCheckOptions[this.activeDuration] : undefined;
        this.selectedDuration = new SelectState(duration);
    }

    @computed public get realityCheckOptions(): Array<SelectOptionType> {
        return [
            { '30': this.language.getTranslation('signup.reality-check.30', '30 Minutes') },
            { '60': this.language.getTranslation('signup.reality-check.60', '1 Hour') },
            { '90': this.language.getTranslation('signup.reality-check.90', '90 Minutes') },
            { '120': this.language.getTranslation('signup.reality-check.120', '2 Hours') },
            { '180': this.language.getTranslation('signup.reality-check.180', '3 Hours') },
            { '300': this.language.getTranslation('signup.reality-check.300', '5 Hours') }
        ];
    }

    @computed public get realityCheckResponse(): Result<OpenapiProxyCustomerRealityCheckResponse200Type> | undefined {
        return this.account.usersState.realityCheckData.get();
    }

    @computed public get activeDuration(): number | null | undefined {
        if (this.realityCheckResponse?.type === 'ready') {
            const value = this.realityCheckResponse.value.active;
            if (value !== undefined && value !== null) {
                return value.duration;
            }
        }
        return undefined;
    }

    @action public onSubmitRealityCheckForm = async (): Promise<void> => {
        if (this.selectedDuration.value === undefined) {
            this.infoMessage = 'empty';
            return undefined;
        }
        try {
            const selectedDurationKey = Object.keys(this.selectedDuration.value)[0];

            if (selectedDurationKey === undefined) {
                throw new Error();
            }

            await this.account.usersState.setRealityCheck({
                status: this.activeDuration === undefined ? 'active' : 'pending',
                requestBody:{
                    duration: parseInt(selectedDurationKey, 10)
                }
            }); this.infoMessage = 'success';
        } catch (e) {
            console.error(e);
            this.infoMessage = 'error';
        }
    };

    public onChange = (): void => {
        this.infoMessage = '';
    };

    public onSwitch = (): void => {
        this.isSwitched = !this.isSwitched;
    };
}
