import { observable, makeObservable } from 'mobx';

export class LocalStorageItemState<T> {
    @observable private value: T;

    public constructor(_key: string, value: T) {
        makeObservable(this);
        this.value = value;
    }

    public getValue(): T {
        return this.value;
    }

    public setValue(value: T): void {
        this.value = value;
    }

    public export(): string {
        return JSON.stringify(this.value);
    }
}
