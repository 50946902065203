import * as t from 'io-ts';
import { action, computed, observable, makeObservable } from 'mobx';
import { createGuard } from 'src_common/common/createGuard';
import queryString from 'query-string';
import { UserAttributionsLocalStorage, UserAttributionsLocalStorageObjectType } from './UserAttributionsLocalStorage';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { localStorageUserAttributions } from 'src/domains/layouts/state/localStorage/localStorageItems/localStorageUserAttributions';

const UserAttributionTagIO = t.union([
    t.literal('ajs_uid'),
    t.literal('ajs_aid'), 
    t.literal('ajs_event'),
    t.literal('utm_id'),
    t.literal('utm_source'),
    t.literal('utm_medium'),
    t.literal('utm_campaign'),
    t.literal('utm_term'),
    t.literal('utm_content'),
    t.literal('utm_channel'),
]);

const customUserAttributionTags: Array<string> = [
    'ajs_prop_',
    'ajs_trait_',
    'utm_custom_',
];

const isUserAttributionTagIO = createGuard(UserAttributionTagIO);

interface ExtendedAttributeType {
    name: string;
    value: string;
}

export class UserAttributionState {
    public readonly userAttributionsLocalStore: LocalStorageItemState<UserAttributionsLocalStorageObjectType>;
    public readonly userAttributionsLocalStorage: UserAttributionsLocalStorage;
    @observable private attributions: Record<string, string | ExtendedAttributeType> = {};
    
    public constructor (isBrowser: boolean, getIncomeaccess: () => string | null) {
        makeObservable(this);
        this.userAttributionsLocalStore = localStorageUserAttributions(isBrowser, 'user-attributions');

        this.userAttributionsLocalStorage = new UserAttributionsLocalStorage(this.userAttributionsLocalStore, getIncomeaccess);

        this.getAttributes();
        const size = Object.keys(this.userAttributions).length;
        if (size > 0) {
            this.userAttributionsLocalStorage.onSetNewAttribute(this.userAttributions);
        }
    }

    @computed private get userAttributionQuery(): Record<string, string | Array<string> | null | undefined> {
        // tslint:disable-next-line
        if (typeof window === 'undefined') {
            return {};
        }

        return queryString.parse(window.location.search ?? '');
    }

    @action public setAttribution = (tag: string, value: string | ExtendedAttributeType): void => {
        this.attributions[tag] = value;
    };

    public isValidTag = (tag: string): boolean => {
        return customUserAttributionTags.some((customTag: string): boolean => tag.startsWith(customTag));
    };

    @action private getAttributes = (): void => {
        
        for (const tag of Object.keys(this.userAttributionQuery)) {
            if (isUserAttributionTagIO(tag) || this.isValidTag(tag)) {
                const userAttributionData = this.userAttributionQuery[tag] ?? null;
                const userAttributionValue = Array.isArray(userAttributionData) ? null : userAttributionData;

                if (userAttributionValue !== null) {
                   
                    if ( tag.startsWith('ajs_prop_')) {
                        this.setAttribution('ajs_prop', { name: tag, value: userAttributionValue });
                    }
                    else if (tag.startsWith('ajs_trait_')) {
                        this.setAttribution('ajs_trait', { name: tag, value: userAttributionValue });
                    }
                    else if (tag.startsWith('utm_custom_')) {
                        this.setAttribution('utm_custom', { name: tag, value: userAttributionValue });
                    } 
                    else {
                        this.setAttribution(tag, userAttributionValue);
                    }
                }

            } else {
                console.log('Cannot decode user attribution: ', tag);
            }
        }
    };

    @computed public get userAttributions(): Record<string, string | ExtendedAttributeType> {
        return this.attributions;
    }
}
