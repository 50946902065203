import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    KycWarningContainer,
    KycWarningIconWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/kycWarning/KycWarning.style';
import { Amount } from 'src_common/common/amount/Amount';
import { DateTime } from 'src_common/utils/time/time';

interface PropsType {
    isSignup: boolean;
}

export const KycWarning = observer('KycWarning', ({ isSignup }: PropsType) => {
    const {
        appLayoutsState,
        appPlayersState,
        config: { showKycDepositWaring },
    } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const { usersState } = appPlayersState;
    const kycRequestExpireDate = usersState.basicData.valueReady?.kycRequestExpireDate ?? '';
    const kycStatus = usersState.basicData.valueReady?.kycStatus ?? '';
    const cashBalance =
        usersState.walletData.valueReady?.balance === undefined
            ? undefined
            : new Amount(usersState.walletData.valueReady?.balance);

    const dateKyc = DateTime.from(kycRequestExpireDate)?.format('YYYY-MM-DD HH:mm:ssZ');
    const kycWarningEnabledFirst =
        kycStatus !== 'passed' &&
        cashBalance !== undefined &&
        cashBalance.isEqualWith(new Amount('0')) &&
        dateKyc !== undefined && dateKyc >
            DateTime.current().format('YYYY-MM-DD HH:mm:ssZ');

    const kycWarningEnabled72 =
        kycStatus !== 'passed' && cashBalance !== undefined && cashBalance.isGreaterThan(new Amount('2000'));

    const kycExpireDate = DateTime.from(kycRequestExpireDate)?.subtractDays(2).format('YYYY-MM-DD HH:mm:ssZ');
    const kycWarningEnabled48 =
        kycStatus !== 'passed' &&
        kycExpireDate !== undefined && kycExpireDate < DateTime.current().format('YYYY-MM-DD HH:mm:ssZ');

    const remainHours = kycWarningEnabled72 ? 72 : 48;
    const email = configComponents.config.emailKycWarning;

    if (showKycDepositWaring === false) {
        return null;
    }

    if (kycWarningEnabledFirst) {
        return (
            <KycWarningContainer isSignup={isSignup} data-test='kyc-warning'>
                <KycWarningIconWrapper isSignup={isSignup} />
                <div data-test='kyc-warning-text'>
                    <I18n
                        langKey='account.kyc-warning.first.line2'
                        defaultText='As part of our Know Your Customer verification, we will require a copy of your driving licence or passport and a copy of utility bill, bank statement or payslip showing your current address not more than 3 months old.'
                    />
                    <br />
                    <I18n
                        langKey='account.kyc-warning.first.line3'
                        defaultText='You may not be able to make a withdrawal or your account may be suspended until your account is verified.'
                    />
                    <br />
                    <I18n
                        langKey='account.kyc-warning.first.line4'
                        defaultText='Please send all documentation to {email}'
                        params={{ email }}
                    />
                </div>
            </KycWarningContainer>
        );
    }
    if (kycWarningEnabled72 || kycWarningEnabled48) {
        return (
            <KycWarningContainer isSignup={isSignup} data-test='kyc-warning'>
                <KycWarningIconWrapper isSignup={isSignup} />
                <div data-test='kyc-warning-text'>
                    <I18n
                        langKey='account.kyc-warning.time-limit.line1'
                        defaultText='You have {remainHours} hours to verify your account.'
                        params={{ remainHours }}
                    />
                    <br />
                    <I18n
                        langKey='account.kyc-warning.time-limit.line2'
                        defaultText='As part of our Know Your Customer verification, we will require a copy of your driving licence or passport and a copy of utility bill, bank statement or payslip showing your current address not more than 3 months old.'
                    />
                    <br />
                    <I18n
                        langKey='account.kyc-warning.time-limit.line3'
                        defaultText='You may not be able to make a withdrawal or your account may be suspended until your account is verified.'
                    />
                    <br />
                    <I18n
                        langKey='account.kyc-warning.time-limit.line4'
                        defaultText='Please send all documentation to {email}'
                        params={{ email }}
                    />
                </div>
            </KycWarningContainer>
        );
    }

    return null;
});
