import { SocketController } from 'src_common/common/mobx-utils/Socket/SocketController';
import { SocketMap } from 'src_common/common/mobx-utils/Socket/SocketMap';
import { CasinoTableType, isCasinoTableModel } from './SocketGameDetailsModels';
import { CasinoTableModel } from './CasinoTableModel';
import { assertNever } from 'src_common/common/assertNever';
import { Value } from 'src_common/common/mobx-utils/Value';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';

type MessageTo = {
    casinoId: string;
    currency: string;
    key: string;
    type: 'subscribe';
};

const createMessageSubscriptions = (casinoId: string, currency: string, key: string): MessageTo => ({
    casinoId,
    currency,
    key,
    type: 'subscribe'
});

export class SocketGameDetails {
    private readonly socketController: SocketController;
    private readonly casinoListSocketMap: SocketMap<string, CasinoTableType, CasinoTableModel>;

    public constructor(
        public readonly host: string,
        public readonly casinoId: string,
        public readonly currency: string,
        public readonly isBrowser: boolean,
        config: ConfigState
    ) {
        this.socketController = new SocketController(
            'RTL Pragmatic - GameDetails',
            host,
            isBrowser,
            10000,
            50
        );

        this.casinoListSocketMap = new SocketMap(
            this.socketController,
            (tableId: string): string => {
                return JSON.stringify(createMessageSubscriptions(
                    this.casinoId,
                    this.currency,
                    tableId
                ));
            },
            null,
            (data: Value<CasinoTableType>) => CasinoTableModel.create(data, config)
        );

        this.socketController.onData((data) => {
            if (data.type === 'open') {
                this.casinoListSocketMap.onNewSocket(data.socket);
                return;
            }

            if (data.type === 'message') {
                const json = JSON.parse(data.message);
                if (isCasinoTableModel(json)) {
                    this.casinoListSocketMap.onMessage(json.tableId, json);
                } else {
                    console.error('socket, model CasinoTableType: message decoding error', json);
                }

                return;
            }

            if (data.type === 'close') {
                return;
            }

            return assertNever('socketController data.type', data);
        });
    }

    public getModel(tableId: string): CasinoTableModel | null {
        return this.casinoListSocketMap.getById(tableId);
    }
}
