import * as React from 'react';
import { computed, observable, makeObservable, action } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import {
    PlayBrakeParagraph,
    PlayBrakeExpiryDate,
    PlayBreakTitle,
    PlayBreakProcedureButton,
} from './PlayBreakProcedure.style';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { ConfirmationBoxState } from 'src/domains/players/state/ConfirmationBoxState';
import { SelectNew, SelectedOption } from 'src/domains/players/webview/components/form/selectNew/SelectNew';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateExpectNumber, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { DateTime } from 'src_common/utils/time/time';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

class PlayBreakState {
    @observable private infoMessage = '';

    public playBreakInput: FormInputState<string, number>;

    public constructor(
        private readonly language: LanguagesState,
        private readonly confirmationBox: ConfirmationBoxState,
        private readonly accountState: AccountState
    ) {
        makeObservable(this);

        this.playBreakInput = FormInputState.new('').map(validateRequire).map(validateExpectNumber);
    }

    @computed public get status(): 'loading' | 'error' | 'ready' | undefined {
        const dataModel = this.accountState.usersState.basicData.get();
        return dataModel.type;
    }

    @computed public get playBreakOptions(): SelectedOption[] {
        return [
            { label: this.language.getTranslation('account.playbreak.duration.24-hours', '24 Hours'), value: '1' },
            { label: this.language.getTranslation('account.playbreak.duration.1-week', '1 Week'), value: '7' },
            { label: this.language.getTranslation('account.playbreak.duration.3-weeks', '3 Weeks'), value: '21' },
            { label: this.language.getTranslation('account.playbreak.duration.4-weeks', '4 Weeks'), value: '28' },
            { label: this.language.getTranslation('account.playbreak.duration.6-weeks', '6 Weeks'), value: '42' },
        ];
    }

    public renderExpiryDate(): JSX.Element | null {
        const playBreakExpiry = this.accountState.usersState.basicData.valueReady?.playBreakExpiry ?? null;

        if (playBreakExpiry === null || playBreakExpiry === '') {
            return null;
        }

        const expiryDateUser = DateTime.from(playBreakExpiry)?.format('YYYY-MM-DD HH:mm:ssZ');
        const currentDate = DateTime.current().format('YYYY-MM-DD HH:mm:ssZ');
        const currentDateUtc = DateTime.from(currentDate)?.utc();
        if (currentDateUtc === undefined) {
            return null;
        }
        const isPlayBreakLongs = expiryDateUser !== '' && DateTime.from(expiryDateUser)?.utc().isAfter(currentDateUtc);

        const expiryDate = DateTime.from(playBreakExpiry)?.format('YYYY-MM-DD HH:mm');

        if (isPlayBreakLongs === true) {
            return (
                <div data-test='expiry-date'>
                    <I18n
                        langKey='account.playbreak.expiry-date.prefix'
                        defaultText='Expiry date:'
                    />{' '}
                    <PlayBrakeExpiryDate>{expiryDate}</PlayBrakeExpiryDate>
                </div>
            );
        }

        return null;
    }

    public showPopup = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.playBreakInput.setAsVisited();
        if (this.playBreakInput.result.value.type === 'error') {
            return;
        }

        const playbreakValue = this.playBreakInput.result.value.data;
        const playbreakLengthStrng = playbreakValue === 1 ? `${playbreakValue} day` : `${playbreakValue} days`;

        this.confirmationBox.showConfirmation(
            <I18n
                langKey='common.confirmation-box.title'
                defaultText='Confirm'
            />,
            <I18n
                langKey='account.playbreak.confirmation'
                defaultText='Are you sure that you want to set play break for {defaultTextInfo}?'
                params={{ defaultTextInfo: playbreakLengthStrng }}
            />,
            this.onSubmit,
            () => {}
        );
    };

    @action private onSubmit = async (): Promise<void> => {
        if (this.accountState.account === null || this.playBreakInput.result.value.type === 'error') {
            return;
        }

        try {
            const resp = await this.accountState.account.onChangePlayBreak(this.playBreakInput.result.value.data);
            if (resp.responseStatus === 'error') {
                this.infoMessage = 'error';
            } else {
                this.infoMessage = 'success';
            }
        } catch (e) {
            this.infoMessage = 'error';
        }
    };

    public renderMessage = (): JSX.Element | string => {
        if (this.infoMessage === 'error') {
            return (
                <Messages
                    type='error'
                    marginBottom='8px'
                    message={
                        <I18n
                            langKey='account.play-break.error-message-new'
                            defaultText="Playbreak can't be requested for a shorter duration than the current one."
                        />
                    }
                />
            );
        } else if (this.infoMessage === 'success') {
            return (
                <Messages
                    type='success'
                    marginBottom='8px'
                    message={
                        <I18n
                            langKey='account.play-break.success-message-new'
                            defaultText='Your play break started successfully.'
                        />
                    }
                />
            );
        }

        return '';
    };
}

export const PlayBreakProcedure = observer('PlayBreakProcedure', (): JSX.Element => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { accountState, confirmationBox } = appPlayersState;

    const [state] = React.useState(() => new PlayBreakState(languagesState, confirmationBox, accountState));

    if (state.status === 'loading') {
        return (
            <I18n
                langKey='account.self-exclusion.loading'
                defaultText='Loading...'
            />
        );
    }

    return (
        <form
            onSubmit={state.showPopup}
            data-test='play-break-procedure'
        >
            <PlayBreakTitle data-test='play-break-header'>
                <I18n
                    langKey='account.playbreak.title'
                    defaultText='Playbreak'
                />
            </PlayBreakTitle>
            <PlayBrakeParagraph data-test='play-break-paragraph'>
                <I18n
                    langKey='account.playbreak.description'
                    defaultText='We take responsible gambling seriously. The tools below can be used to manage your betting activity.'
                />
            </PlayBrakeParagraph>

            <SelectNew
                dataTest='play-break-select'
                options={state.playBreakOptions}
                inputState={state.playBreakInput}
                placeholder={languagesState.getTranslation(
                    'account.playbreak.duration.select.label',
                    'Choose time-out period'
                )}
            />

            {state.renderMessage()}

            {state.renderExpiryDate()}

            <PlayBreakProcedureButton
                type='submit'
                size='medium'
                dataTest='play-break-save-button'
            >
                <I18n
                    langKey='account.playbreak.submit.label'
                    defaultText='Save'
                />
            </PlayBreakProcedureButton>
        </form>
    );
});
