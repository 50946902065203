import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { getError } from 'src/domains/layouts/webview/components/common/errorMessage/getError';
import { ErrorMessageWrapper, WarningIconWrapper } from './styles';
import { UniverseVersion } from 'src/domains/common/UniverseVersion';
import { UniverseType } from 'src_common/common/universe';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface ErrorMessageDeprecatedPropsType {
    message: string | {};
    children?: React.ReactNode;
    hasWarningIcon?: boolean;
}

/** * @deprecated - Please use {@link Messages} instead. */
export const ErrorMessageDeprecated = observer(
    'ErrorMessage',
    (props: ErrorMessageDeprecatedPropsType): JSX.Element => {
        const { message, children, hasWarningIcon = false } = props;

        return (
            <ErrorMessageWrapper data-test='error-message-deprecated-content'>
                {hasWarningIcon ? <WarningIconWrapper /> : null}
                <UniverseVersion>
                    {(universe: UniverseType): React.ReactElement => (
                        <div>
                            {getError(universe, message)}
                            {children !== undefined && children}
                        </div>
                    )}
                </UniverseVersion>
            </ErrorMessageWrapper>
        );
    }
);
