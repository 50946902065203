import { LocalStorageItemState } from './LocalStorageItemState';
import { RecentlyVisitedLocalStorageObjectType } from './RecentlyVisitedLocalStorage';
import { BetsFromLocalStorageType } from 'src/domains/sportsbook/shared/Types';
import { DebugPanelSizeType, SelectTabIdType } from 'src/domains/layouts/webview/components/debugPanel/MainViewTypes';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { localStorageCgLanging } from './localStorageItems/localStorageCgLanging';
import { localStorageCgRecentlyVisited } from './localStorageItems/localStorageCgRecentlyVisited';
import { localStorageRealityCheckTime } from './localStorageItems/localStorageRealityCheckTime';
import { localStoragePingManager } from './localStorageItems/localStoragePingManager';
import { localStorageBetSlipBets } from './localStorageItems/localStorageBetSlipBets';
import { localStorageLanguage } from './localStorageItems/localStorageLanguage';
import { localStorageVisitedLandingPromotionsList } from './localStorageItems/localStorageVisitedLandingPromotionsList';
import { localStorageCookiesPolicy } from './localStorageItems/localStorageCookiesPolicy';
import { localStorageDismissedMarketingQuestions } from './localStorageItems/localStorageDismissedMarketingQuestions';
import { localStorageDebugPanel } from './localStorageItems/localStorageDebugPanel';
import { localStorageDebugPanelSize } from './localStorageItems/localStorageDebugPanelSize';
import { localStorageStreamBanner } from './localStorageItems/localStorageStreamBanner';
import { localStorageLiveNotifications } from './localStorageItems/localStorageLiveNotifications';
import { localStorageLastReadMessage } from './localStorageItems/localStorageLastReadMessage';
import { localStorageDebugPanelConfig } from './localStorageItems/localStorageDebugPanelConfig';
import { localStorageAutomatsLandingPromotionVisited } from './localStorageItems/localStorageAutomatsLandingPageVisited';
import { localStorageBalanceInHeader } from './localStorageItems/localStorageBalanceInHeader';
import { LiveNotificationLocalStorageObjectType } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { PofPopupLocalStorageObjectType, localStoragePofPopup } from './localStorageItems/localStoragePofPopup';
import { localStoragePromoCode } from './localStorageItems/localStoragePromoCode';
import { localStorageNewPlaceBet } from './localStorageItems/localStorageNewPlaceBet';
import { ChatMessageType } from 'src_server/trpc/types/chat';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { Common } from 'src/domains/common/Common';
export class LocalStorageState {
    protected nominal?: never;
    public readonly cgLanding: LocalStorageItemState<number | null>;
    public readonly cgRecentlyVisited: LocalStorageItemState<RecentlyVisitedLocalStorageObjectType[]>;
    public readonly realityCheckTime: LocalStorageItemState<number | null>;
    public readonly language: LocalStorageItemState<string | null>;
    public readonly pingManager: LocalStorageItemState<boolean>;
    public readonly visitedLandingPromotionsList: LocalStorageItemState<string[]>;
    public readonly automatsLandingPageVisited: LocalStorageItemState<boolean>;
    public readonly cgBetSlipBets: LocalStorageItemState<BetsFromLocalStorageType>;
    public readonly cookiesPolicy: LocalStorageItemState<boolean>;
    public readonly dismissedMarketingQuestions: LocalStorageItemState<string[]>;
    public readonly debugPanel: LocalStorageItemState<SelectTabIdType>;
    public readonly debugPanelSize: LocalStorageItemState<DebugPanelSizeType>;
    public readonly debugPanelConfig: LocalStorageItemState<ConfigType | null>;
    public readonly streamBannerInfo: LocalStorageItemState<boolean>;
    public readonly liveNotifications: LocalStorageItemState<Array<LiveNotificationLocalStorageObjectType>>;
    public readonly lastReadMessage: LocalStorageItemState<ChatMessageType | null>;
    public readonly balanceInHeader: LocalStorageItemState<boolean>;
    public readonly promoCode: LocalStorageItemState<string | null>;
    public readonly pofPopups: LocalStorageItemState<PofPopupLocalStorageObjectType>;
    public readonly newPlaceBet: LocalStorageItemState<boolean>;
    public static get = AutoWeakMap.create((common: Common) => new LocalStorageState(common));
    private constructor(common: Common) {
        const isBrowser = common.isBrowser;
        this.cgLanding = localStorageCgLanging(isBrowser, 'CG_LANDING');
        this.cgRecentlyVisited = localStorageCgRecentlyVisited(isBrowser, 'CG_RecentlyVisited');
        this.realityCheckTime = localStorageRealityCheckTime(isBrowser, 'realityCheckTime');
        this.pingManager = localStoragePingManager(isBrowser, '__pingManager');
        this.cgBetSlipBets = localStorageBetSlipBets(isBrowser, 'cgBetSlipBets'); //TO REMOVE?
        this.language = localStorageLanguage(isBrowser, 'language');
        this.cookiesPolicy = localStorageCookiesPolicy(isBrowser, 'cookiesPolicy');
        this.visitedLandingPromotionsList = localStorageVisitedLandingPromotionsList(
            isBrowser,
            'visitedLandingPromotionsList'
        );
        this.automatsLandingPageVisited = localStorageAutomatsLandingPromotionVisited(
            isBrowser,
            'automatsLandingPageVisited'
        );
        this.dismissedMarketingQuestions = localStorageDismissedMarketingQuestions(
            isBrowser,
            'dismissedMarketingQuestions'
        );
        this.debugPanel = localStorageDebugPanel(isBrowser, '__debug-panel');
        this.debugPanelSize = localStorageDebugPanelSize(isBrowser, '__debug-panel-size');
        this.streamBannerInfo = localStorageStreamBanner(isBrowser, 'stream_info');
        this.liveNotifications = localStorageLiveNotifications(isBrowser, 'liveNotifications');
        this.lastReadMessage = localStorageLastReadMessage(isBrowser, 'lastReadMessage');
        this.debugPanelConfig = localStorageDebugPanelConfig(isBrowser, 'debug-panel-config');
        this.balanceInHeader = localStorageBalanceInHeader(isBrowser, 'balanceInHeader');
        this.pofPopups = localStoragePofPopup(isBrowser, 'pofPopups');
        this.promoCode = localStoragePromoCode(isBrowser, 'promoCode');
        this.newPlaceBet = localStorageNewPlaceBet(isBrowser, 'newPlaceBet');
    }
}
