import { computed, makeObservable } from 'mobx';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventRow6PackState } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/EventRow6Pack.state';
import { getTeamShirt } from './MLBTeamLogos';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export const getMarketNameMLB = (getTranslation: (key: string, defaultText: string) => string): [string, string, string] => {
    return [
        getTranslation('market.mlb.moneyline', 'Money Line'),
        getTranslation('market.mlb.runspread', 'Run Spread'),
        getTranslation('market.mlb.totalruns', 'Total Runs')
    ];
};

export class MLBEventRow6PackState implements EventRow6PackState {

    public constructor(
        public readonly eventId: EventId,
        public readonly language: LanguagesState
    ) {
        makeObservable(this);
    }

    @computed public get event():EventModel | null {
        return this.eventId.getEventModel();
    }

    // --------------------

    public get firstMarketName():string {
        const marketsNamesMLB = getMarketNameMLB(this.language.getTranslation);
        return marketsNamesMLB[0] ?? '-';
    }

    public get secondMarketName():string {
        const marketsNamesMLB = getMarketNameMLB(this.language.getTranslation);
        return marketsNamesMLB[1] ?? '-';
    }

    public get thirdMarketName():string {
        const marketsNamesMLB = getMarketNameMLB(this.language.getTranslation);
        return marketsNamesMLB[2] ?? '-';
    }

    // --------------------

    @computed public get firstMarket():MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const moneyLine = event.markets.filter(market => market.templateId === 'match-winner');
        return moneyLine[0];
    }

    @computed public get secondMarket():MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const spreads = event.markets.filter(market => market.marketName.startsWith('Match handicap') || market.marketName.startsWith('Run Spread'));
        return (
            spreads.length === 1 ? spreads[0] : spreads[1]
        );
    }

    @computed public get thirdMarket():MarketModel | undefined {
        const event = this.event;
        if (event === null) {
            return undefined;
        }

        const totalRuns = event.markets.filter(market => market.marketName.startsWith('Total Runs U/O') || market.marketName.startsWith('Total Runs'));
        return (
            totalRuns.length === 1 ? totalRuns[0] : totalRuns[1]
        );
    }

    // --------------------

    public getSelectionName = (market:MarketModel | undefined, selection: SelectionModel): string => {
        if (market?.marketName.startsWith('Total Runs U/O') === true || market?.marketName.startsWith('Total Runs') === true) {
            return selection.name;
        }
        return selection.name.split(' ').slice(-1).join(' ');
    };

    // --------------------

    public getParticipantLogo = (name: string | null): string => {
        return getTeamShirt(name);
    };

}
