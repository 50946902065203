/* Copyright(c) 2016, iovation, inc. All rights reserved. */
window.io_global_object_name = "IGLOO"
window.IGLOO = window.IGLOO || {
  "install_flash" : false,
  "loader" : {
    "uri_hook" : "/iojs",
    "version" : "general5"
  }
};

export default () => {
};
