import { MarketFiltersForSportState } from 'src/domains/sportsbook/state/marketFiltersState/MarketFiltersForSportState';
import { FiltersStateStrategy, FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';

export class MarketFiltersStrategy implements FiltersStateStrategy {

    public constructor(
        private readonly marketFiltersForSport: MarketFiltersForSportState
    ) {
        // this.marketFiltersForSport.resetFilters();
    }

    public get filters(): FilterType[] {
        return this.marketFiltersForSport.filtersForView;
    }

    public getActiveFilterId(): string | null {
        return this.marketFiltersForSport.activeFilter;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (id !== null) {
            this.marketFiltersForSport.setActiveFilter(id.toString());
        }
    }
};
