import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';

interface QuickBetStyleTypes {
    isShowQuickBet: boolean;
}

export const QuickBetFrame = styled('div', { label: 'QuickBetFrame' }) <QuickBetStyleTypes>`
    ${positionElementFull};
    background-color: rgba(2, 13, 22, 0.5);
    cursor: pointer;
    display: ${({ isShowQuickBet }): string => isShowQuickBet ? 'block' : 'none'};
    position: fixed;
`;

export const SelectionWrapper = styled('ul', { label: 'SelectionWrapper' })`
    padding: 8px 0 0 0;
`;

export const LoginWrapper = styled('li', { label: 'LoginWrapper' })`
    margin-top: 15px;
`;

export const QuickBetWrapper = withConfig(theme => styled('div', { label: 'QuickBetWrapper' })`
    background-color: ${theme.star.betslipGeneral.bgColor};
    position: relative;
    z-index: ${theme.star.zIndexGlobal.above};
    max-width: 414px;
    margin: auto;
    padding: 0 8px;
`);

export const QuickBetContainer = withConfig(theme => styled('div', { label: 'QuickBetContainer' })`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.star.zIndexGlobal.footer};
    opacity: 1;
    transition: 250ms transform cubic-bezier(0.455, 0.03, 0.515);
`);


export const QuickBetSuccessWrapper = withConfig(theme => styled('div', { label: 'QuickBetSuccessWrapper' })`
    display: flex;
    align-items: center;
    background-color: ${theme.star.statuses.success.bgColor};
    color: ${theme.star.betslipGeneral.txtColor};
    padding: 16px;
    font-size: ${theme.star.fontSize.small};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const CheckMarkWrapper = styled(CheckSingleIcon, { label: 'CheckMarkWrapper' })`
    fill: currentcolor;
    margin-right: 10px;
    width: 16px;
`;

export const QuickBetHeader = withConfig(theme => styled('h3', { label: 'QuickBetHeader' })`
    background-color: ${theme.star.betslipGeneral.bgColorTertiary};
    color: ${theme.star.betslipGeneral.txtColor};
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4;
    margin: 0 -8px;
    height: 48px;
    padding: 10px 8px;
    position: relative;
    text-transform: uppercase;
`);

export const QuickBetCloseButton = styled(CloseIcon, { label: 'QuickBetCloseButton' })`
    bottom: 0;
    cursor: pointer;
    fill: currentcolor;
    height: 100%;
    padding: 14px;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
`;
