import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { PopupWrapper } from './PopupContainer.style';
import { assertNever } from 'src_common/common/assertNever';
import { HelpAndContactPopup } from './HelpAndContactPopup';
import { MarketingNotificationsPopupView } from 'src/domains/layouts/webview/components/popups/MarketingNotificationsPopup';
import { GameModal } from 'src/domains/casino/shared/Components';
import { useAppStateContext } from 'src/appState/AppState';
import { AgreeOrCancelPopup } from './agreeOrCancelPopup/AgreeOrCancelPopup';
import { RealityCheckPopup } from 'src/domains/layouts/webview/components/popups/realityCheckPopup/RealityCheckPopup';
import { RealityCheckNewValueConfirmationPopup } from './realityCheckNewValueConfirmationPopup/RealityCheckNewValueConfirmationPopup';
import { MultipleSlotsPopup } from 'src/domains/casino/webview/CasinoGames';

export const PopupContainer = observer('PopupContainer', (): JSX.Element | null => {
    const { appLayoutsState, appCasinoState } = useAppStateContext();
    const { popupState } = appLayoutsState;
    const { popupForView } = popupState;

    if (popupForView === null) {
        return null;
    }

    if (popupForView.type === 'helpAndContact') {
        return (
            <PopupWrapper>
                <HelpAndContactPopup popup={popupForView} />
            </PopupWrapper>
        );
    }

    if (popupForView.type === 'multipleSlotsPopup') {
        return (
            <PopupWrapper>
                <MultipleSlotsPopup />
            </PopupWrapper>
        );
    }

    if (popupForView.type === 'gameModal') {
        return <GameModal appCasinoState={appCasinoState} />;
    }

    if (popupForView.type === 'marketingNotificationsPopup') {
        return <MarketingNotificationsPopupView />;
    }

    if (popupForView.type === 'popupDeleteCard') {
        return <AgreeOrCancelPopup popupForView={popupForView} />;
    }

    if (popupForView.type === 'popupRealityCheck') {
        return <RealityCheckPopup />;
    }

    if (popupForView.type === 'popupRealityCheckNewValueConfirmation') {
        return <RealityCheckNewValueConfirmationPopup />;
    }

    return assertNever('PopupContainer', popupForView);
});
