import { LegItem } from 'src/domains/sportsbook/betting/betSlipState/LegItem';
import { action, observable, computed, makeObservable } from 'mobx';
import { CombinationItem } from 'src/domains/sportsbook/betting/betSlipState/CombinationItem';
import { AddToAllState } from 'src/domains/sportsbook/betting/betSlipState/AddToAllState';
import { ReferralState } from 'src/domains/sportsbook/betting/betSlipState/ReferralState';
import { LegsState } from 'src/domains/sportsbook/betting/state/betSlipState/LegsState';
import { CombinationState } from 'src/domains/sportsbook/betting/state/betSlipState/CombinationsState';
import { CastBetsState } from 'src/domains/sportsbook/betting/state/betSlipState/CastBetsState';
import { PossibleBetsRequestState } from 'src/domains/sportsbook/betting/betSlipState/possibleBetsState/PossibleBetsState';
import { SuccessPlaceBetResponseType } from 'src/domains/sportsbook/betting/betting/postPlaceBetTypes';
import { BasicBetSlipState } from 'src/domains/sportsbook/betting/betSlipState/BasicBetSlipState';
import { BetSlipSummaryState } from 'src/domains/sportsbook/betting/state/betSlipState/BetSlipSummaryState';
import { BetSlipUserDataType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';
import { BetsFilter, BetsState } from 'src/domains/sportsbook/betting/state/BetsState';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { QuickBetState } from 'src/domains/sportsbook/betting/betSlipState/quickBetState/QuickBetState';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { CustomerFreeBetsType, ResourceCustomer } from 'src/domains/players/shared/Types';
import { WalletDataTypeAmountType } from 'src/domains/players/state/UsersState';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { EnvVariables } from 'src/domains/common/contextStore/EnvVariables';
import { PlaceBetBetsType } from 'src/domains/sportsbook/betting/betting/postPlaceBet';
import { BetslipIdsFactory } from 'src/domains/sportsbook/betting/models/BetslipIdsFactory';
import { SelectionId, WebsocketId } from 'src_common/common/websocket2/id/WebsocketId';
import { BetslipModelsContext } from 'src/domains/sportsbook/betting/models/BetslipModelsContext';
import { BetslipCombinationId, BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { BetslipData } from 'src/domains/sportsbook/betting/betSlipState/BetslipData';
import { AutoMap, AutoMapSerialized } from 'src_common/common/mobx-utils/AutoMap';

export interface BetSlipStateParamsType {
    onRedirectToBetslip: () => void;
    onRedirectToLogin: () => void;
    getConfig: () => ConfigComponents;
    getOddsFormat: () => 'f' | 'd';
    getFreeBetsData: () => ResourceCustomer<CustomerFreeBetsType | null>;
    getWalletData: () => ResourceCustomer<WalletDataTypeAmountType>;
}

export enum BetSlipFilter {
    BETSLIP = 'BETSLIP',
    MY_BETS = 'MY_BETS',
}

export enum BetSlipSectionFilter {
    SINGLES = 'SINGLES',
    MULTIPLES = 'MULTIPLES',
}

export class BetSlipState {
    public readonly ids: BetslipIdsFactory;
    public readonly addToAllState: AddToAllState;
    public readonly rabState: RabState;
    public readonly possibleBetsRequestState: PossibleBetsRequestState;
    public readonly castBetsState: CastBetsState;
    public readonly basicBetSlipState: BasicBetSlipState;
    public readonly betSlipSummaryState: BetSlipSummaryState;
    public readonly referralState: ReferralState;
    public readonly legsState: LegsState;
    public readonly lifeSpanState: LifeSpanState;
    public refElem: HTMLElement | null = null;
    private readonly combinationState: CombinationState;
    public readonly quickBetState: QuickBetState;

    @observable public readonly reactiveWrapper: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);
    @observable public selectedTab: string = BetSlipFilter.BETSLIP;
    @observable public isFreeBetOn: boolean = false;
    public readonly betslipData: BetslipData;

    public forViewLegsItemMap: AutoMapSerialized<BetslipSingleId, LegItem>;
    public forViewCombinationsItemMap: AutoMap<string, CombinationItem>;

    public constructor(
        websocketId: WebsocketId,
        rabState: RabState,
        lifeSpanState: LifeSpanState,
        public readonly sdkCustomer: SdkCustomer,
        private readonly betsState: BetsState,
        private readonly envVariables: EnvVariables,
        private readonly params: BetSlipStateParamsType,
        private readonly localStorageState: LocalStorageState,
        private readonly decimalLength: number
    ) {
        makeObservable(this);
        this.quickBetState = new QuickBetState();

        const whenBetDelete = (betId: BetslipSingleId): void => {
            //TODO - it is run when bet is being deleted

            // DEACTIVED THE BOOST FROM THE SELECTION WHICH IS REMOVED FROM THE BETSLIP
            const selectionWithBoost = this.lifeSpanState.lifeSpanSocketState.selectionsWithAvailableBoosts(
                betId.id.rawId
            );
            if (selectionWithBoost !== null) {
                if (selectionWithBoost.selectionInfo?.reward?.active === true) {
                    this.lifeSpanState.lifeSpanSocketState.deactivationBoost(selectionWithBoost.selectionInfo);
                }
            }
        };

        this.addToAllState = new AddToAllState(this.getLegsIds, this.decimalLength);

        const context = new BetslipModelsContext(this.getLegItemById, this.getCombinationItemById, this.addToAllState);
        this.ids = new BetslipIdsFactory(websocketId, context);

        this.betslipData = new BetslipData(
            this.ids,
            this.quickBetState,
            () => this.referralState,
            whenBetDelete,
            () => this.lifeSpanState,
            this.decimalLength
        );

        this.rabState = rabState;

        this.lifeSpanState = lifeSpanState;

        this.possibleBetsRequestState = new PossibleBetsRequestState(
            websocketId,
            this.params.getConfig(),
            this.sdkCustomer,
            this.getAccountData,
            this.rabState,
            this.lifeSpanState,
            params.getConfig().config.lifeSpanActive,
            () => this.basicBetSlipState.channel,
            this.betslipData
        );
        this.basicBetSlipState = new BasicBetSlipState(
            this.possibleBetsRequestState,
            this.quickBetState,
            this.getAccountData
        );
        this.referralState = new ReferralState(
            websocketId,
            this.ids,
            this.params.getConfig(),
            this.possibleBetsRequestState,
            this.basicBetSlipState,
            this.onPlaceBetSuccess,
            this.getAccountData,
            this.cleanAll,
            this.params.onRedirectToBetslip,
            () => this.basicBetSlipState.channel,
            this.betslipData
        );

        this.combinationState = new CombinationState(
            this.params.getConfig(),
            this.sdkCustomer,
            this.possibleBetsRequestState,
            () => this.basicBetSlipState.channel,
            this.referralState,
            this.basicBetSlipState,
            this.ids
        );

        this.legsState = new LegsState(
            this.possibleBetsRequestState,
            this.lifeSpanState,
            this.sdkCustomer,
            this.betslipData
        );

        this.castBetsState = new CastBetsState(this.params.getConfig(), this.possibleBetsRequestState);

        this.betSlipSummaryState = new BetSlipSummaryState(
            this.params.getConfig(),
            this.possibleBetsRequestState,
            this.legsState,
            this.combinationState,
            this.rabState,
            this.referralState,
            this.basicBetSlipState,
            this.castBetsState,
            this.getFreeBetsAmount,
            this.getLegsIds,
            this.cleanAll,
            this.sdkCustomer,
            this.params.onRedirectToBetslip,
            this.freeBetsData,
            this.lifeSpanState,
            () => this.basicBetSlipState.channel,
            this.envVariables,
            this.localStorageState
        );

        this.forViewLegsItemMap = new AutoMapSerialized(
            (selectionId: BetslipSingleId) => selectionId.reactKey,
            (selectionId: BetslipSingleId) => {
                return new LegItem(
                    selectionId,
                    this.legsState,
                    this.referralState,
                    this.lifeSpanState,
                    this.sdkCustomer,
                    this.params,
                    this.betslipData
                );
            }
        );

        this.forViewCombinationsItemMap = new AutoMap((combinationId: string) => {
            return new CombinationItem(combinationId, this.referralState, this.combinationState, this.betslipData);
        });
    }

    public getAccountData = (): BetSlipUserDataType => {
        const basicData = this.sdkCustomer.basicData.valueReady;
        const walletData = this.walletData().valueReady;

        if (basicData !== null && walletData !== null) {
            return {
                currency: basicData.currency,
                country: basicData.country ?? '',
                balance: new Amount(walletData.playableBalance),
                accountAuthenticated: true,
                userId: basicData.id,
                ipUser: this.sdkCustomer.session.ipUser,
            };
        }

        return {
            currency: 'EUR',
            country: 'UK',
            accountAuthenticated: false,
            userId: null,
            balance: null,
            ipUser: null,
        };
    };

    @action public changeBetslipTab = (tabName: string): void => {
        this.selectedTab = tabName;
    };

    @action public changeSelectedFilterToOpen = (): void => {
        this.betsState.changeSelectedFilter(BetsFilter.OPEN);
    };

    @action public redirectToCashOut = (): void => {
        if (this.sdkCustomer.session.userId === null) {
            this.params.onRedirectToLogin();
        } else {
            this.params.onRedirectToBetslip();
            this.betsState.changeSelectedFilter(BetsFilter.CASH_OUT);
            this.changeBetslipTab(BetSlipFilter.MY_BETS);
        }
    };

    @action public onRemoveAllLegs = (): void => {
        this.cleanAll();
    };

    @action private cleanAll = (): void => {
        console.log('cleanAll');
        this.betslipData.deleteMapCorePrepareLegs();
        this.betslipData.deleteMapCorePrepareCombinations();

        this.possibleBetsRequestState.prevCorePossibleBetsResponse = null;

        // const legsItemKeys = this.legsState.forViewLegsItemMap.getAllKeys();
        // const combinationsItemKeys = this.combinationState.forViewCombinationsItemMap.getAllKeys();
        //this.addToAllState.stakeInputState.handleChange('');
        this.addToAllState.stakeInput.changeStake(new Amount('0'));

        this.referralState.referralDataInner = null;
        if (this.rabState.isBetBuilderOpen === true) {
            this.rabState.closeRab();
        }

        // for (const id of legsItemKeys) {
        //     this.legsState.forViewLegsItemMap.delete(id);

        //     // const selectionWithBoost = this.lifeSpanState.selectionsWithAvailableBoosts.get(id.toString());
        //     // if (selectionWithBoost.selectionInfo !== null && selectionWithBoost.selectionInfo.reward?.active === true) {
        //     //     this.lifeSpanState.deactivateSelectionBoost(selectionWithBoost.selectionInfo);
        //     // }
        // }

        // for (const id of combinationsItemKeys) {
        //     this.combinationState.forViewCombinationsItemMap.delete(id);
        // }

        this.lifeSpanState.lifeSpanSocketState.messageWithEmptyBetslip();
    };

    @action public cleanAllLifeSpanSelections = (): void => {
        // const legsItemKeys = this.legsState.forViewLegsItemMap.getAllKeys();

        for (const id of this.betslipData.getAllLegsIds()) {
            const selectionWithBoost = this.lifeSpanState.lifeSpanSocketState.selectionsWithAvailableBoosts(
                id.id.rawId
            );
            if (
                selectionWithBoost !== null &&
                selectionWithBoost.selectionInfo !== null &&
                selectionWithBoost.selectionInfo.reward?.active === true
            ) {
                this.lifeSpanState.deactivateSelectionBoost(selectionWithBoost.selectionInfo);
            }
        }
    };

    private getFreeBetsAmount = (): Amount | null => {
        return this.freeBetsAmount;
    };

    private getLegsIds = (): Array<BetslipSingleId> => {
        return this.legsIds2;
    };

    private getLegItemById = (legId: BetslipSingleId): LegItem => {
        return this.forViewLegsItemMap.get(legId);
    };

    public getLegItemByIdSelection = (selectionId: SelectionId, index: number): LegItem => {
        return this.ids.getSingleId(selectionId, index).getModel();
    };

    public getCombinationItemById = (combinationType: string): CombinationItem => {
        return this.forViewCombinationsItemMap.get(combinationType);
    };

    private onPlaceBetSuccess = (
        fromFreeBets: boolean,
        bets: PlaceBetBetsType | Array<SuccessPlaceBetResponseType>
    ): void => {
        this.betSlipSummaryState.onPlaceBetSuccess(fromFreeBets, bets);
    };

    @computed public get freeBetsAmount(): Amount | null {
        const freebetsData = this.freeBetsData().valueReady;

        if (freebetsData !== null) {
            const totalAmount = freebetsData.response?.totalAmount;

            if (totalAmount !== undefined) {
                return this.params.getConfig().precision.newFromAnything(totalAmount);
            }

            return null;
        }
        return null;
    }

    @computed public get shouldFreebetBeAllowed(): boolean {
        return this.freeBetsAmount === null
            ? false
            : this.betSlipSummaryState.totalStake.sub(this.freeBetsAmount).isGreaterThanZero();
    }

    @computed private get legsRawIds2(): Array<BetslipSingleId> {
        const tempLegsIds: Set<BetslipSingleId> = new Set();
        for (const leg of this.possibleBetsRequestState.coreLegsPossibleBetsRequest) {
            const selectionModel = leg.selectionId.getModel();
            if (selectionModel !== null) {
                tempLegsIds.add(this.ids.getSingleId(selectionModel.id2, 0));
            }
        }
        return Array.from(tempLegsIds);
    }

    @computed public get legsIds2(): Array<BetslipSingleId> {
        if (this.referralState.referralData !== null) {
            return this.referralState.legsIds2;
        }
        return this.legsRawIds2;
    }

    @computed public get firstSelection2(): BetslipSingleId | null {
        if (this.referralState.referralData !== null && this.referralState.legsIds2.length === 1) {
            return this.referralState.legsIds2[0] ?? null;
        }
        if (this.legsIds2.length === 1) {
            return this.legsIds2[0] ?? null;
        }
        return null;
    }

    /**
     * @deprecated - switch to combinationState.combinationsCast2
     */
    @computed public get combinationsCast2(): Array<BetslipCombinationId> {
        return this.combinationState.combinationsCast2;
    }

    /**
     * @deprecated - switch to combinationState.combinationWithoutCast2
     */
    @computed public get combinationWithoutCast2(): Array<BetslipCombinationId> {
        return this.combinationState.combinationWithoutCast2;
    }

    public isSelected = (id: SelectionId): boolean => {
        const legId = this.ids.getSingleId(id, 0);
        return this.legsIds2.includes(legId);
    };

    public onFreeBetToggle = (): void => {
        this.isFreeBetOn = !this.isFreeBetOn;
    };

    @action public onAcceptChanges = (): void => {
        for (const legId of this.legsIds2) {
            legId.getModel().acceptChanges();
        }

        this.rabState.acceptChanges();
    };

    @computed public get elementRefHeight(): number {
        const elementRef = this.reactiveWrapper.ref;
        return elementRef?.clientHeight ?? 0;
    }

    @action public freeBetsData = (): ResourceCustomer<CustomerFreeBetsType | null> => {
        return this.params.getFreeBetsData();
    };

    @action public walletData = (): ResourceCustomer<WalletDataTypeAmountType> => {
        return this.params.getWalletData();
    };
}
