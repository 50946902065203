import React from 'react';
import { BetDetailsWrapper, BetId } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet.style';
import { observer } from 'src/utils/mobx-react';
import { BetLegs } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetLegs';
import { BetReturnsLabel } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetReturnsLabel';
import { BetType } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetType';
import { BetPlacedDate } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetPlacedDate';
import { BetCombinedTypeStakeStatus } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetCombinedTypeStakeStatus';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface PropsTypes {
    bet: BetHistoryType,
}

export const BetCombinedDetails = observer<PropsTypes>('BetCombinedDetails', ({ bet }) => {

    return (
        <BetDetailsWrapper>
            <BetType bet={bet} />

            <BetLegs bet={bet} />

            <BetCombinedTypeStakeStatus bet={bet} />
            <BetReturnsLabel bet={bet} />

            <BetPlacedDate bet={bet} />
            <BetId data-test='bet-id'>#{bet.id}</BetId>
        </BetDetailsWrapper>
    );
});

