import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

interface ScrollWrapperPropsType {
    customHeight?: number;
}

export const ScrollWrapper = styled('nav', { label: 'ScrollWrapper' }) <ScrollWrapperPropsType>`
    overflow-x: auto;
    ${({ customHeight }): string => customHeight === undefined ? '' : `height: ${customHeight}px;`}
    width: 100%;
    display: flex;
    /* Hide scrollbar for IE, Edge and Firefox */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ScrollButton = withConfig(theme => styled('button', { label: 'ScrollButton' })`
    align-items: center;
    background-color: ${theme.star.customScrollBar.arrow.bgColor};
    border-radius: 50%;
    border: 0;
    color: ${theme.star.customScrollBar.arrow.txtColor};
    cursor: pointer;
    display: flex;
    filter: ${`drop-shadow(0px 0px 4px ${theme.star.customScrollBar.arrow.bgColorSecondary})`};
    height: 22px;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 5px;
    width: 22px;
`);

export const ScrollLeftButton = withConfig(theme => styled(ScrollButton, { label: 'ScrollLeftButton' })`
    left: 8px;
    z-index: ${theme.star.zIndexGlobal.above};
`);

export const ScrollRightButton = styled(ScrollButton, { label: 'ScrollRightButton' })`
    right: 8px;
`;

export const ButtonChevronIcon = styled(ChevronIcon, { label: 'ButtonChevronIcon' })`
    fill: currentcolor;
    width: 12px;
`;
