import { action, computed, makeObservable } from 'mobx';
import { CasinoGameModelExt } from 'src/domains/casino/state/AppCasino/store/game-store/CasinoGameModelExt';
import { CollectionType, ProviderType, SessionConfigType, SessionDataTypeExt, SessionResponseTypeExt } from 'src/domains/casino/types/types';
import { assertNever } from 'src_common/common/assertNever';
import { Resource } from 'src_common/common/mobx-utils/Resource';

export type GameDataType = {
    type: 'lucky6';
} | {
    type: 'bingo';
} | {
    type: 'model';
    gameModel: CasinoGameModelExt;
    mode?: 'mini' | 'demo' | 'bingo-mini';
}

export class GameDataState {
    public constructor(
        private readonly data: GameDataType,
        private config: SessionConfigType,
        public resource: Resource<SessionDataTypeExt> | null
    ) {
        makeObservable(this);
    }

    @computed public get id(): number {
        if (this.data.type === 'lucky6') {
            return 0;
        }

        if (this.data.type === 'bingo') {
            return 0;
        }

        if (this.data.type === 'model') {
            return this.data.gameModel.id;
        }

        return assertNever('GameDataState id', this.data);
    }

    @computed public get isDemo(): boolean {
        if (this.data.type === 'lucky6') {
            return false;
        }

        if (this.data.type === 'bingo') {
            return false;
        }

        if (this.data.type === 'model') {
            return this.data.mode === 'demo';
        }

        return assertNever('ModalGameModel isDemo', this.data);
    }

    @computed public get isMini(): boolean {
        if (this.data.type === 'lucky6') {
            return false;
        }

        if (this.data.type === 'bingo') {
            return false;
        }

        if (this.data.type === 'model') {
            return this.data.mode === 'mini' || this.data.mode === 'bingo-mini';
        }

        return assertNever('ModalGameModel isMini', this.data);
    }

    @computed public get name(): string {
        if (this.data.type === 'lucky6') {
            return 'Lucky6';
        }

        if (this.data.type === 'bingo') {
            return 'Bingo';
        }

        if (this.data.type === 'model') {
            return this.data.gameModel.name;
        }

        return assertNever('ModalGameModel name', this.data);
    }

    @computed public get gameType(): CollectionType | 'lucky6' | 'bingo' {
        if (this.data.type === 'lucky6') {
            return 'lucky6';
        }

        if (this.data.type === 'bingo') {
            return 'bingo';
        }

        if (this.data.type === 'model') {
            return this.data.gameModel.collection;
        }

        return assertNever('ModalGameModel gameType', this.data);
    }

    @computed public get provider(): ProviderType {
        if (this.data.type === 'lucky6') {
            return 'Nsoft';
        }

        if (this.data.type === 'bingo') {
            return 'Pragmatic';
        }

        if (this.data.type === 'model') {
            return this.config.provider;
        }

        return assertNever('ModalGameModel provider', this.data);
    }

    @computed public get launchId(): string {
        if (this.data.type === 'lucky6') {
            return 'luckysix';
        }

        if (this.data.type === 'bingo') {
            return '9999';
        }

        if (this.data.type === 'model') {
            return this.data.gameModel.launchGameId;
        }

        return assertNever('ModalGameModel launchId', this.data);
    }

    @computed public get session(): SessionResponseTypeExt {
        const result = this.resource?.get();

        if (result?.type === 'loading') {
            return {
                status: 'loading'
            };
        }

        if (result?.type === 'ready') {
            return {
                status: 'ready',
                value: result.value,
            };
        }

        return {
            status: 'error',
            message: 'Game session couldn\'t be launched. Try again later.'
        };
    }

    @computed public get mobile(): boolean {
        return this.config.provider === 'Nsoft' ? false : this.config.params.mobile === true;
    }

    @action public clearResourceAndWait = async (): Promise<void> => {
        return await this.resource?.refresh();
    };
};
