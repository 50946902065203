import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Scoreboard } from './AmericanSportMatchLead.style';
import { AmericanSportMatchLeadStateStrategy } from './AmericanSportMatchLeadStateStrategy';
import { AmericanSportLiveScore } from 'src/domains/sportsbook/webview/components/matchLead/americanSportMatchLead/AmericanSportLiveScore';
import { AmericanSportPreMatch } from 'src/domains/sportsbook/webview/components/matchLead/americanSportMatchLead/AmericanSportPreMatch';
import bgPatternHexagon from 'src/domains/layouts/shared/assets/backgrounds/sports/bgPatternHexagon.webp';

interface AmericanSportMatchLeadPropsType {
    state: AmericanSportMatchLeadStateStrategy;
}

export const AmericanSportMatchLead = observer<AmericanSportMatchLeadPropsType>('AmericanSportMatchLead', ({ state }) => {
    return (
        <Scoreboard bgImage={bgPatternHexagon}>
            {state.isStarted
                ? <AmericanSportLiveScore state={state} />
                : <AmericanSportPreMatch state={state} />
            }
        </Scoreboard>
    );
});
