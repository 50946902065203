import React from 'react';
import { Search } from 'src/domains/layouts/webview/components/search/Search';
import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { SearchRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsTypes {
    currentView: SearchRouteType;
    accountView: RightHandSideViewType | null;
    isSearchActive: boolean;
}

export const SearchPage = observer('SearchPage', ({ currentView, accountView, isSearchActive }: PropsTypes) => {
    return (
        <section>
            <LayoutSkeleton currentView={currentView} accountView={accountView} isSearchActive={isSearchActive} navigationType='global' >
                <Search />
            </LayoutSkeleton>
        </section>
    );
});
