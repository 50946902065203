import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, action, observable, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { Session } from 'src_common/sdk/session';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { DateTime } from 'src_common/utils/time/time';
import { SignupTypes } from 'src/domains/players/webview/components/SignUp';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getLandingPageActive';

const LandingPageDetailsIO = t.interface({
    idsList: t.array(t.number),
});

export type LandingPageDetailsType = t.TypeOf<typeof LandingPageDetailsIO>;

const isLandingPageDetails = createGuard(LandingPageDetailsIO);

type LandingPageModelType = Response200Type[number];

export type SidebarContentResponse = SidebarImageLoading | SidebarImageReady | SidebarImageError;

interface SidebarImageLoading {
    type: 'loading';
}

interface SidebarImageReady {
    type: 'ready';
    value: LandingPageModelType | null | undefined;
}

interface SidebarImageError {
    type: 'error';
    value: string;
}

export class LandingPage {
    private readonly router: StarRouter;
    private readonly signUpState: SignupTypes;
    private readonly localStorageState: LocalStorageState;
    private readonly session: Session;
    public readonly promotionLandingPage: Resource<Array<LandingPageModelType>>;
    private readonly promotionSlug: string | null;
    private readonly isHomepage: boolean;

    @observable public displayLPFirstTime: boolean;
    @observable public displayLP: boolean;

    public constructor(
        router: StarRouter,
        signupState: SignupTypes,
        localStorageState: LocalStorageState,
        session: Session,
        promotionSlug: string | null,
        trpc: TrpcClient
    ) {
        makeObservable(this);
        this.router = router;
        this.signUpState = signupState;
        this.localStorageState = localStorageState;
        this.session = session;
        this.displayLPFirstTime = false;
        this.displayLP = false;
        this.promotionSlug = promotionSlug;
        this.isHomepage = this.promotionSlug === 'homepage';
        this.promotionLandingPage = new Resource(async (): Promise<Array<LandingPageModelType>> => {
            this.displayLP = true;

            const response = await trpc.client.cms.getLandingPageActive.query();

            if (response.status !== 200) {
                return [];
            }

            //TODO handleDisplayFrequency - change displayLPFirstTime computed and setLocalstore on close promotion
            this.handleDisplayFrequency(response.body);

            if (this.promotionSlug === 'homepage') {
                const responseValue = response.body
                    .filter((elem) => elem.is_homepage === true)
                    .filter((elem) => DateTime.current().isBetween(elem.date_from, elem.date_to));
                return responseValue;
            } else {
                const responseValue = response.body
                    .filter((elem) => elem.slug === this.promotionSlug)
                    .filter((elem) => DateTime.current().isBetween(elem.date_from, elem.date_to));
                return responseValue;
            }
        });
    }

    @computed public get isLogged(): boolean {
        return this.session.isAuthorized;
    }

    @computed public get userId(): number | null {
        return this.session.userId;
    }

    @action public addPromoCodeToLocalStorage = (promotionSlug: string): void => {
        this.localStorageState.promoCode.setValue(promotionSlug);
    };

    @action public addToLocalStore = (): void => {
        this.addPromoCodeToLocalStorage(this.promoCode);
        if (this.signUpState.state.currentView.step.type === 'create-account') {
            this.signUpState.state.currentView.step.state.welcomeState.promoCodeTextState.setValue(this.promoCode);
            this.signUpState.state.currentView.step.state.welcomeState.setPromoCode(true);
        }
    };

    @action public addToLocalStoreAndClose = (): void => {
        this.addToLocalStore();
        this.closePromoSidebar();
    };

    @action private handleDisplayFrequency = (data: Array<LandingPageModelType>): void => {
        const promoSlugFromUrl = this.promotionSlug;
        const isHomepage = promoSlugFromUrl === 'homepage';
        const currentLP = data.find((promotion) =>
            isHomepage
                ? promotion.is_homepage === true && promotion.is_active === true
                : promoSlugFromUrl === promotion.slug
        );
        if (currentLP === undefined) {
            return;
        }
        const shouldShowLP = currentLP.show_frequency === 'multiple' || this.displayLPFirstTime === false;
        if (shouldShowLP) {
            const { show_frequency, slug, is_active, date_from, date_to } = currentLP;
            const visitedLandingPromotionsList = this.localStorageState.visitedLandingPromotionsList.getValue().slice();
            const isPromotionOnList = visitedLandingPromotionsList.includes(slug);
            const isInTimeRange = DateTime.current().isBetween(date_from, date_to);

            if (is_active === true && isInTimeRange === true) {
                if (show_frequency === 'once' && isPromotionOnList === false) {
                    this.displayLPFirstTime = true;
                    this.localStorageState.visitedLandingPromotionsList.setValue([
                        ...visitedLandingPromotionsList,
                        slug,
                    ]);
                }

                if (show_frequency === 'multiple') {
                    if (isPromotionOnList) {
                        const filteredPromotions = visitedLandingPromotionsList.filter((elem) => elem !== slug);
                        this.localStorageState.visitedLandingPromotionsList.setValue(filteredPromotions);
                    }
                }
            }
        }
    };

    @computed public get promoLandingPage(): SidebarContentResponse {
        const landingPage = this.promotionLandingPage.get();
        if (landingPage.type === 'ready') {
            return {
                type: 'ready',
                value: landingPage.value[0],
            };
        }

        if (landingPage.type === 'loading') {
            return {
                type: 'loading',
            };
        }

        if (landingPage.type === 'error') {
            return {
                type: 'error',
                value: 'Something went wrong with fetching landing page info',
            };
        }

        return {
            type: 'ready',
            value: null,
        };
    }

    @computed public get lpImgUrl(): string | null {
        if (this.promoLandingPage.type === 'ready') {
            if (this.promoLandingPage.value?.img_url === '') {
                return null;
            }
            return this.promoLandingPage.value?.img_url ?? null;
        }

        return null;
    }

    @computed public get lpImgHtmlUrl(): string | null {
        if (this.promoLandingPage.type === 'ready') {
            if (this.promoLandingPage.value?.img_html_url === '') {
                return null;
            }
            return this.promoLandingPage.value?.img_html_url ?? null;
        }

        return null;
    }

    public get isHTMLBanner(): boolean {
        if (this.promoLandingPage.type === 'ready') {
            return this.promoLandingPage.value?.banner_type === 'html';
        }
        return false;
    }

    private get isActive(): boolean {
        if (this.promoLandingPage.type === 'ready') {
            return this.promoLandingPage.value?.is_active === true;
        }

        return false;
    }

    private get showOnce(): boolean {
        if (this.promoLandingPage.type === 'ready') {
            return this.promoLandingPage.value?.show_frequency === 'once';
        }

        return false;
    }

    public get actionTemplate(): string | undefined {
        if (this.promoLandingPage.type === 'ready') {
            return this.promoLandingPage.value?.action_template;
        }

        return '';
    }

    public get promoCode(): string {
        if (this.promoLandingPage.type === 'ready') {
            const promoCode = this.promoLandingPage.value?.promo_code;
            return promoCode ?? '';
        }

        return '';
    }

    public get ctaText(): string {
        if (this.promoLandingPage.type === 'ready') {
            const ctaText = this.promoLandingPage.value?.cta_text;
            return ctaText ?? '';
        }

        return '';
    }
    public get ctaUrl(): string {
        if (this.promoLandingPage.type === 'ready') {
            const ctaUrl = this.promoLandingPage.value?.cta_url;
            return ctaUrl ?? '';
        }

        return '';
    }
    public get shouldDisplay(): boolean {
        const automatsLandingPageVisited = this.localStorageState.automatsLandingPageVisited.getValue();

        if (
            this.promoLandingPage.type === 'ready' &&
            this.isLPLinkAllowed &&
            this.isLPBetweenTimeRange &&
            this.isShowForUser
        ) {
            return (
                this.displayLP &&
                this.isActive &&
                !automatsLandingPageVisited &&
                ((this.showOnce && this.displayLPFirstTime === true) || this.showOnce === false) &&
                !((this.actionTemplate === 'signup' || this.actionTemplate === 'login') && this.isLogged)
            );
        }

        return false;
    }

    @computed public get landingPageDetails(): LandingPageDetailsType | null {
        if (this.promoLandingPage.type === 'ready') {
            const landingPage = this.promoLandingPage.value ?? null;
            if (landingPage !== null && isLandingPageDetails(landingPage.details)) {
                return landingPage.details;
            }
        }

        return null;
    }

    @computed public get isShowForUser(): boolean {
        if (this.landingPageDetails !== null && this.userId !== null && this.landingPageDetails.idsList.length > 0) {
            return this.landingPageDetails.idsList.includes(this.userId);
        }

        if (this.landingPageDetails !== null && this.isLogged === false && this.landingPageDetails.idsList.length > 0) {
            return false;
        }

        return true;
    }

    @computed private get isLPBetweenTimeRange(): boolean {
        if (this.promoLandingPage.type === 'ready') {
            const dateStart = this.promoLandingPage.value?.date_from;
            const dateTo = this.promoLandingPage.value?.date_to;
            const dateStartTimeStamp = dateStart === undefined ? null : DateTime.from(dateStart)?.unixSeconds();
            const dateEndTimeStamp = dateTo === undefined ? null : DateTime.from(dateTo)?.unixSeconds();
            const currentDateTimeStamp = DateTime.current().unixSeconds();

            if (
                dateStartTimeStamp !== null &&
                dateEndTimeStamp !== null &&
                dateStartTimeStamp !== undefined &&
                dateEndTimeStamp !== undefined
            ) {
                return currentDateTimeStamp >= dateStartTimeStamp && currentDateTimeStamp <= dateEndTimeStamp;
            } else if (dateEndTimeStamp === null && dateStartTimeStamp !== null && dateStartTimeStamp !== undefined) {
                return currentDateTimeStamp >= dateStartTimeStamp;
            } else if (dateEndTimeStamp !== null && dateEndTimeStamp !== undefined) {
                return currentDateTimeStamp <= dateEndTimeStamp;
            }
        }

        return false;
    }

    @computed private get isLPLinkAllowed(): boolean {
        return this.isHomepage || this.promotionSlug !== null;
    }

    @computed public get isMobileSidebar(): boolean {
        const width = getWindowInnerWidth();
        if (width !== null) {
            return width < 415;
        }
        return true;
    }

    @action public handleRedirectToLogin = (): void => {
        this.router.redirectToLogin();
        this.displayLPFirstTime = false;
        this.displayLP = false;
    };

    @action public handleRedirectToSignUp = (): void => {
        this.router.redirectToSignUp();
        this.displayLPFirstTime = false;
        this.displayLP = false;
    };

    @action public closePromoSidebar = (): void => {
        this.displayLP = false;
        this.displayLPFirstTime = false;
        this.router.closeAccount();
    };

    @action public closePromoSidebarAutomats = (): void => {
        this.displayLP = false;
        this.displayLPFirstTime = false;
        this.router.closeAccount();
    };

    @action public openHomepagePromoSidebar = (): void => {
        this.displayLP = true;
        this.displayLPFirstTime = false;
    };

    @action public showTermsAndConditions = (): void => {
        const promoParam = this.router.freeParams.promo;
        this.router.redirectToTermsAndConditionsPromoSpecial(promoParam);
    };
}
