import { css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { HeartFilledIcon } from 'src/domains/layouts/shared/assets/icons/HeartFilledIcon';
import { HeartOutlinedIcon } from 'src/domains/layouts/shared/assets/icons/HeartOutlineIcon';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { withConfig } from 'src/withConfig';

export const LikeAndInfoSection = styled('div', { label: 'LikeAndInfoSection' })`
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 17px;
    right: 0;
    left: 0;
`;

export const InfoIconWrapper = withConfig(theme => styled(InfoIcon, { label: 'InfoIconWrapper' })`
    cursor: pointer;
    fill: ${theme.star.casino.bgColorSecondary};
    margin-right: 18px;
    width: 22px;
`);

export const LikeButton = styled('button', { label: 'LikeButton' })`
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    padding: 0;
`;

const styledHeartIcon = (theme: EmotionTheme): SerializedStyles => css`
    fill: ${theme.star.casino.txtColor};
    width: 22px;
`;

export const HeartFilledIconWrapper = withConfig(theme => styled(HeartFilledIcon, { label: 'HeartFilledIconWrapper' })`
    ${styledHeartIcon(theme)};
`);

export const HeartOutlinedIconWrapper = withConfig(theme => styled(HeartOutlinedIcon, { label: 'HeartOutlinedIconWrapper' })`
    ${styledHeartIcon(theme)};
`);
