import styled from '@emotion/styled';
import { StarOutlineIcon } from 'src/domains/layouts/shared/assets/icons/StarOutlineIcon';
import { StarFillIcon } from 'src/domains/layouts/shared/assets/icons/StarFillIcon';
import { withConfig } from 'src/withConfig';

export const Wrapper = withConfig(theme => styled('div', { label: 'Wrapper' })`
    padding: 20px 9px 0 11px;
    @media ${theme.star.mediaQuery.desktop} {
        padding: 30px 20px 0 20px;
    }
`);

export const Header = withConfig(theme => styled('div', { label: 'Header' })`
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.regular};
    color: ${theme.star.heroEvents.txtColor};
    margin-top: 10px;
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.medium};
    }
`);

export const ContentContainer = withConfig(theme => styled('div', { label: 'ContentContainer' })`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    min-height: 150px;
    @media ${theme.star.mediaQuery.tablet} {
        margin-top: 16px;
        flex-direction: row;
        justify-content: space-between;
    }
`);

export const Paragraph = withConfig(theme => styled('div', { label: 'Paragraph' })`
    line-height: 1.5;
    color: ${theme.star.heroEvents.txtColor};
    font-size: ${theme.star.fontSize.small};
    p {
        margin: 0;
        padding-top: 10px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.xRegular};
        line-height: 1.4;
    }
`);

export const ParagraphsWrapper = withConfig(theme => styled('div', { label: 'ParagraphsWrapper' })`
    padding: 0;
    font-size: ${theme.star.fontSize.small};
    flex: 1 1 0%;
    @media ${theme.star.mediaQuery.desktop} {
        padding-right: 20px;
        font-size: ${theme.star.fontSize.xRegular};
    }
`);

export const StarRatingWrapper = styled('div', { label: 'StarRatingWrapper' })`
    display: flex;
    flex: 1 1 0%;
    padding-top: 10px;
`;

export const DetailsWrapper = styled('div', { label: 'DetailsWrapper' })`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
`;

export const InfoContainer = styled('div', { label: 'InfoContainer' })`
    display: flex;
    align-items: center;
`;

export const StarValueWrapper = styled('div', { label: 'StarValueWrapper' })`
    display: flex;
    margin-left: 16px;
`;

export const StarValueIconEmpty = withConfig(theme => styled(StarOutlineIcon, { label: 'StarValueIconEmpty' })`
    width: 15px;
    margin-right: 5px;
    fill: ${theme.star.heroEvents.bgColorQuaternary};
`);

export const StarValueIcon = withConfig(theme => styled(StarFillIcon, { label: 'StarValueIcon' })`
    width: 15px;
    margin-right: 5px;
    fill: ${theme.star.heroEvents.bgColorTertiary};
`);

export const SelectionName = withConfig(theme => styled('div', { label: 'SelectionName' })`
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.small};
    color: ${theme.star.heroEvents.txtColor};
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.xRegular};
    }
`);
