import { z } from 'zod';

export const InputSchema = z.object({
    body: z.object({
        customer: z.object({
            first_name: z.string(),
            last_name: z.string(),
            email: z.string(),
            country: z.string(),
            phone: z.string(),
            address: z.string(),
            city: z.string(),
            zip: z.string(),
            dob: z.string(),
        }),
        amount: z.string(),
        currency: z.string(),
    }),
});


export type InitiateWithdrawalPraxisInput = z.infer<typeof InputSchema>;

export const SuccessResponseSchema = z.string();


type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    error: z.object({
        code: z.string().optional().nullable(),
        message: z.string().optional().nullable(),
    })
});

export const TrpcErrorResponseSchema = z.object({
    code: z.string().optional().nullable(),
    message: z.string().optional().nullable(),
});

type TErrorResponse = z.infer<typeof TrpcErrorResponseSchema>;

export type TResponseType =
    { responseStatus: 'success'; data: TSuccessResponse } |
    { responseStatus: 'error'; data: TErrorResponse }
