import * as express from 'express';
import { assertNever } from 'src_common/common/assertNever';
import { getNewTradingCookie } from 'src_server/Api/multiLanguage';

type NewTradingFlagType = 'oldtrading' | 'new-trading-dev' | 'new-trading';

const computedNewtrading = (newtradingFromCookie: boolean | null, newtradingEnv: boolean): NewTradingFlagType => {
    if (newtradingFromCookie === true) {
        return 'new-trading-dev';
    }

    if (newtradingFromCookie === false) {
        return 'oldtrading';
    }

    if (newtradingEnv) {
        return 'new-trading';
    }

    return 'oldtrading';
};

class NewTradingFlag {
    public readonly value: NewTradingFlagType;

    public constructor(
        newtradingEnv: boolean,
        req: express.Request,
    ){
        const newtradingFromCookie = getNewTradingCookie(req);
        this.value = computedNewtrading(newtradingFromCookie, newtradingEnv);
    }

    public get isNewTrading(): boolean {
        if (this.value === 'oldtrading') {
            return false;
        }

        if (this.value === 'new-trading-dev' || this.value === 'new-trading') {
            return true;
        }

        return assertNever('isNewTrading', this.value);
    }
}

//simplified version for front-end-api
export const isNewTrading = (req: express.Request): boolean => {
    const newtradingEnv = process.env.NEWTRADING ?? '';
    const flag = new NewTradingFlag(newtradingEnv.toLowerCase() === 'true', req);
    return flag.isNewTrading;
};

export const isNewTradingValueFlag = (req: express.Request): NewTradingFlagType => {
    const newtradingEnv = process.env.NEWTRADING ?? '';
    const flag = new NewTradingFlag(newtradingEnv.toLowerCase() === 'true', req);
    return flag.value;
};
