import { computed, makeObservable, observable } from 'mobx';
import { apiCommon } from 'src/api/ApiCommon';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getActivePromotedSports';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { CompetitionId, EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

type SportsFiltersType = {
    key: string;
    isActive: boolean;
}

type PromotedSportType = Response200Type;

type PromotedSportExtendedType = Array<PromotedSportSingleType>;

export type PromotedSportSingleType = {
    competitions_list: Array<CompetitionType>;
    display_order: number;
    id: number;
    is_active: boolean;
    sport_id: string;
    universe: string;
}


type EventTypeFirst = {
    competition_id: number;
    display_order: number;
    event_id: number;
    id: number;
    is_active: boolean;
    market_id?: null | undefined | number;
    sport_id: string;
    universe: string;
}

export type EventType = {
    competition_id: CompetitionId;
    display_order: number;
    event_id: EventId;
    id: number;
    is_active: boolean;
    market_id?: null | undefined | MarketId;
    sport_id: string;
    universe: string;
}

export type CompetitionType = {
    competition_id: CompetitionId;
    display_order: number;
    events_list: Array<EventType>;
    id: number;
    is_active: boolean;
    sport_id: string;
    universe: string;
}

export class PromotedEventsState {

    private readonly activePromotedSportsResource: Resource<Response200Type | null>;

    @observable public openEvents: boolean = true;
    @observable public activeFilterKey: string = 'all';

    public constructor(
        public readonly sdkCustomer: SdkCustomer,

    ) {
        makeObservable(this);

        this.activePromotedSportsResource = new Resource(async (): Promise<Response200Type | null> => {
            const response = await apiCommon.getActivePromotedSportsElement.run({});
            return response;
        });

    }

    public setOpenEvents = (): void => {
        this.openEvents = !this.openEvents;
    };


    @computed public get promotedSports(): PromotedSportType {
        const response = this.activePromotedSportsResource.get();
        if (response.type === 'ready' && response.value !== null) {
            return response.value;
        }
        return [];
    }

    private constructEventsList = (eventsList: Array<EventTypeFirst>): Array<EventType> => {
        const extendedEventTypeArray: Array<EventType> = [];
        for (const eventEl of eventsList){
            const eventModel = this.sdkCustomer.models.getEvent(eventEl.event_id);
            if (eventModel === null) {
                continue;
            }

            const eventId = this.sdkCustomer.models.id.getEventId(eventEl.event_id);
            const oldMarketId = eventEl.market_id ?? null;
            const marketId = oldMarketId === null ? null : eventId.getMarketId(oldMarketId);

            extendedEventTypeArray.push({
                ...eventEl,
                competition_id: this.sdkCustomer.models.id.getCompetitionId(eventEl.competition_id),
                event_id: eventId,
                market_id: marketId,
            });
            
        }
        return extendedEventTypeArray;
    };

    @computed public get promotedSportsExtended(): PromotedSportExtendedType {
        return this.promotedSports.map(sport => {
            return (
                {
                    ...sport,
                    competitions_list: sport.competitions_list.map(competition => {
                        return (
                            {
                                ...competition,
                                competition_id: this.sdkCustomer.models.id.getCompetitionId(competition.competition_id),
                                events_list: this.constructEventsList( competition.events_list)
                            }
                        );
                    })
                }
            );
        });
     
    }

    @computed public get promotedSportsToShow(): PromotedSportExtendedType {
        return this.promotedSportsExtended
            .filter(sport => sport.sport_id === this.activeFilterKey || this.activeFilterKey === 'all')
            .filter(sport => this.isSportToShow(sport.competitions_list))
            .sort((a, b) => (a.display_order > b.display_order ? 1 : -1));
    }

    public isEventToShow = (eventModel: EventModel | null): boolean => {
        
        if (eventModel === null || eventModel.display === false || eventModel.timeSettingsStarted) {
            return false;
        }

        if ((eventModel.sport === 'horseracing' || eventModel.sport === 'greyhoundracing')) {
            return eventModel.template === 'outrights' || eventModel.antePost;
        }
        return true;
    };

    private isCompetitionToShow = (eventsList: Array<EventType>): boolean => {
        return eventsList.some(eventItem => this.isEventToShow(eventItem.event_id.getEventModel()));
    };

    private isSportToShow = (competitionsList: Array<CompetitionType>): boolean => {
        return competitionsList.some(competitionItem => this.isCompetitionToShow(competitionItem.events_list));
    };
    

    @computed public get activeSportFilters(): Array<SportsFiltersType> {
        return this.promotedSportsExtended.filter(sport => this.isSportToShow(sport.competitions_list)).map(sport => {
            return ({ key: sport.sport_id, isActive: true });
        });
    }

    @computed public get sportFiltersAll(): Array<SportsFiltersType> {
        return [ { key: 'all', isActive: true }, ...this.activeSportFilters,];
    }

    @computed public get isPromotedSportsDataLoading(): boolean {
        const promotedSportsData = this.activePromotedSportsResource.get();
        return promotedSportsData.type === 'loading';
    }

    public handleToggleIsSportFilterActive = (key: string): void => {
        this.activeFilterKey = key;
    };

    public isFilterActive = (key: string): boolean => {
        return this.activeFilterKey === key;
    };
}
