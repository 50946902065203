import React from 'react';
import { ActiveHomePageCarouselType } from 'src/api/config/cms/getActiveHomePageCarousel';
import { observer } from 'src/utils/mobx-react';
import {
    CardBodyWrapper,
    CardBodyTitle,
    CardBodySubTitle,
} from './HomePageCarouselPromos.style';

interface PropsTypes {
    promo: ActiveHomePageCarouselType;
}

export const CardBodyComponent = observer('CardBodyComponent', ({ promo }: PropsTypes) => {

    const { title, subtitle } = promo;

    return (
        <CardBodyWrapper>
            <CardBodyTitle data-test='promo-carousel-title'>{title}</CardBodyTitle>
            <CardBodySubTitle data-test='promo-carousel-subtitle'>{subtitle}</CardBodySubTitle>
        </CardBodyWrapper>
    );
});
