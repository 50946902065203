import { HelpIcon } from 'src/domains/layouts/shared/assets/icons/HelpIcon';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const RowRecordStyle = styled('div', { label: 'RowRecordStyle' })`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    &:last-of-type {
        margin-bottom: 8px;
    }
`;

export const UserRecordStyle = styled(RowRecordStyle, { label: 'UserRecordStyle' })`
    margin: 0 0 7px 0;
`;

export const UserNameStyle = withConfig(theme => styled('div', { label: 'UserNameStyle' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    max-width: 70%;
    line-height: 1.43;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`);

export const NameStyle = withConfig(theme => styled('div', { label: 'NameStyle' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const ValueStyle = withConfig(theme => styled('div', { label: 'ValueStyle' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
`);

export const AccountSummaryWrapper = withConfig(theme => styled('div', { label: 'AccountSummaryWrapper' })`
    color: ${theme.star.accountDrawer.txtColor};
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
`);

export const AccountSummaryStatusWrapper = withConfig(theme => styled('div', { label: 'AccountSummaryStatusWrapper' })`
    color: ${theme.star.accountDrawer.txtColor};
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`);

export const FreeBetsTextWithHelper = styled(NameStyle, { label: 'FreeBetsTextWithHelper' })`
    display: flex;
    align-items: center;
`;

export const HelpIconWrapper = styled(HelpIcon, { label: 'HelpIconWrapper' })`
    cursor: pointer;
    fill: currentcolor;
    width: 16px;
    margin: 0 5px;
`;

export const DepositButton = styled(Button, { label: 'DepositButton' })`
    width: 100%;
    margin-top: 8px;
`;
