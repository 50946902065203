import styled from '@emotion/styled';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

interface DepositSuccessWrapperType {
    isSignup: boolean;
}

export const DepositSuccessWrapper = styled('div', { label: 'DepositSuccessWrapper' }) <DepositSuccessWrapperType>`
    animation: fade-in 0.3s;
    margin: 0 16px 55px;
    padding-top: 80px;
    text-align: center;
    height: 100%;
    height: ${({ isSignup }): string => isSignup ? '100%' : 'calc(100% - 55px)'};
    @keyframes fade-in {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;

export const CheckIconWrapper = withConfig(theme => styled(CheckSingleIcon, { label: 'CheckIconWrapper' })`
    background: ${theme.star.topUp.bgColor};
    border-radius: 50%;
    display: block;
    height: 80px;
    margin: 0 auto 32px;
    padding: 24px;
    width: 80px;
`);

export const DepositTitle = withConfig(theme => styled('h2', { label: 'DepositTitle' })`
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    margin: 0;
`);

export const SuccessDepositInfo = withConfig(theme => styled('p', { label: 'SuccessDepositInfo' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.43;
    margin: 0 0 4px;
`);

export const CashBalance = withConfig(theme => styled('span', { label: 'CashBalance' })`
    display: block;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
`);

interface LinkDepositAgain {
    isSignup: boolean;
}

export const LinkDepositAgain = withConfig(theme => styled(Link, { label: 'LinkDepositAgain' }) <LinkDepositAgain>`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
    ${({ isSignup }): string => isSignup ? '' : `color: ${theme.star.topUp.txtColorSecondary}`};
`);


export const LinkWrapper = styled('div', { label: 'LinkWrapper' })`
    margin-top: 24px;
`;
