import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BabIconStyledWrapper } from 'src/domains/sportsbook/webview/components/events/row/StatusAndDate.style';

type BabIconWrapperPropsType = {
    onClick: () => void;
}

export const BabIconWrapper = observer('BabIconWrapper', ({ onClick }: BabIconWrapperPropsType) => {
    return <BabIconStyledWrapper onClick={onClick} />;
});
