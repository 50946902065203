import React, { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    AccountTabs,
    AccountOverlay,
    TabsWrapper,
    EmptyTab,
} from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { Tab } from 'src/domains/players/webview/components/Account/accountParts/Tab';
import { AccountDrawerState } from 'src/domains/players/webview/components/Account/accountDrawer/AccountDrawer.state';
import { AccountTabsBuilderState } from 'src/domains/players/webview/components/Account/accountDrawer/AccountTabsBuilder.state';
import { PromoSidebar } from 'src/domains/players/webview/components/Account/promoSidebar/PromoSidebar';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { Session } from 'src_common/sdk/session';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AppPlayersState } from 'src/domains/players/state/AppPlayers.state';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { BannersBoxState } from 'src/domains/layouts/state/bannersBoxState/BannersBoxState';

interface PropsType {
    promotionSlug: string;
    currentView: RouteViewType | null;
}

export class AccountDrawerLocalState {
    public readonly tabBuilderData: AccountTabsBuilderState;
    public readonly accountDrawerData: AccountDrawerState;

    public constructor(
        session: Session,
        configComponents: ConfigComponents,
        language: LanguagesState,
        appCasinoState: AppCasinoState,
        appPlayersState: AppPlayersState,
        starRouter: StarRouter,
        bannersBoxState: BannersBoxState
    ) {
        this.tabBuilderData = new AccountTabsBuilderState(
            session,
            configComponents,
            language,
            appCasinoState,
            appPlayersState
        );

        this.accountDrawerData = new AccountDrawerState(
            this.getTabBuilderState(),
            starRouter,
            session,
            bannersBoxState,
            appPlayersState
        );
    }

    public getTabBuilderState = (): AccountTabsBuilderState => {
        return this.tabBuilderData;
    };

    public getAccountDrawerState = (): AccountDrawerState => {
        return this.accountDrawerData;
    };
}

export const AccountDrawer = observer('AccountDrawer', (props: PropsType): JSX.Element | null => {
    const { appLayoutsState, appPlayersState, appCasinoState } = useAppStateContext();
    const { starRouter, configComponents, languagesState, bannersBoxState, session, popupState } = appLayoutsState;

    const routeName = props.currentView?.name ?? '';

    const [drawerState] = useState(
        () =>
            new AccountDrawerLocalState(
                session,
                configComponents,
                languagesState,
                appCasinoState,
                appPlayersState,
                starRouter,
                bannersBoxState
            )
    );

    const state = drawerState.getAccountDrawerState();

    useEffect((): (() => void) => {
        state.setIsMounted();
        return reaction(
            () => state.futureTabName,
            () => state.onNewTabRequest()
        );
    }, [routeName]);

    /**
     * Some sub-components of this Component trigger `redirect` immediately after mounting (see `VerifyAccountTab`).
     * Mentioned `redirect` had been done before this component were mounted.
     * Because this component is listening to "redirects" (AFTER it is mounted - because of `useEffect`), we have to do it in that way.
     * For now, `AccountDrawer` is mounted before its sub-components.
     */

    if (state.isMounted === false) {
        return null;
    }

    const { currentTabData, futureTabData, currentTabDataForHeader, futureTabDataForHeader } = state;
    let currentTab = <EmptyTab key='empty-tab' />;
    if (state.currentTabName !== null) {
        currentTab = (
            <Tab
                key={state.currentTabName.account}
                tabName={state.currentTabName}
                showHeader={state.shouldDisplayMainHeader(state.currentTabName)}
                tabData={currentTabData}
                tabDataForHeader={currentTabDataForHeader}
            />
        );
    }

    let futureTab = null;
    if (state.areTabsDiffer && state.futureTabName !== null) {
        futureTab = (
            <Tab
                key={state.futureTabName.account}
                tabName={state.futureTabName}
                showHeader={state.shouldDisplayMainHeader(state.futureTabName)}
                tabData={futureTabData}
                tabDataForHeader={futureTabDataForHeader}
            />
        );
    }
    return (
        <>
            <AccountTabs
                desktopNoRightSpace={popupState.rightPanelAttachedToRightEdge}
                key='account__tabs--with-header'
                isInvisible={!state.isOpened}
                topOffset={state.topOffset}
                data-test='Tab'
            >
                <TabsWrapper
                    className='account__tab'
                    isLeaving={state.isDuringForwardAnimation}
                    isEntering={state.isDuringBackwardAnimation}
                    animationDuration={state.animationDurationInSeconds}
                    onTransitionEnd={state.finishAnimation}
                    data-test={state.isDuringAnimation ? 'animating' : ''}
                >
                    {currentTab}
                    {futureTab}
                </TabsWrapper>
            </AccountTabs>

            <AccountOverlay
                isVisible={state.isOpening}
                onClick={starRouter.closeAccount}
            />

            <PromoSidebar
                promotionSlug={props.promotionSlug}
                key='promo-sidebar'
            />
        </>
    );
});
