import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import AddressesListNew from 'src/domains/players/webview/components/Account/personalDetailsTab/changeAddressProcedure/addressesList/AddressesListNew.jsx';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    FindAddressBtnWrapper,
    PostcodeInputWrapper,
    PostcodeWrapper,
} from 'src/domains/players/webview/components/Account/personalDetailsTab/changeAddressProcedure/ChangeAddressProcedure.style';
import {
    FormWrapper,
    UpdateButton,
} from 'src/domains/players/webview/components/Account/personalDetailsTab/PersonalDetailsTab.styles';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { ChangeAddressProcedureNewFormState } from './ChangeAddressProcedureNewForm.state';
import { AccountBasicDataType } from 'src/domains/layouts/state/customer';
import { useAppStateContext } from 'src/appState/AppState';
import { findAddress } from 'src_server/trpc/types/accountModel';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    initialData: Omit<AccountBasicDataType, 'oddsFormat'>;
}

export const ChangeAddressProcedureNewForm = observer('ChangeAddressProcedureNewForm', (props: PropsType) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const {
        configComponents: { config },
        languagesState,
    } = appLayoutsState;
    const { trpc } = appPlayersState;

    const [state] = React.useState(
        () =>
            new ChangeAddressProcedureNewFormState(
                () => props.initialData,
                config.signUpAddressSearchByPostCode,
                languagesState,
                trpc
            )
    );

    const renderBodyFields = (): JSX.Element => {
        return (
            <>
                <Input
                    name='addressLine1'
                    label={
                        <I18n
                            langKey='account.address-procedure.address-line-1.label'
                            defaultText='*Address line 1'
                        />
                    }
                    placeholder={languagesState.getTranslation(
                        'account.address-procedure.address-line-1.placeholder',
                        '*Address line 1'
                    )}
                    state={state.adress1State}
                    onBlur={state.resetMessage}
                    colorTheme='light'
                    dataTest='address-line1-input'
                />

                <Input
                    name='addressLine2'
                    label={
                        <I18n
                            langKey='account.address-procedure.address-line-2.label'
                            defaultText='Address line 2'
                        />
                    }
                    placeholder={languagesState.getTranslation(
                        'account.address-procedure.address-line-2.placeholder',
                        'Address line 2'
                    )}
                    state={state.adress2State}
                    onBlur={state.resetMessage}
                    colorTheme='light'
                    dataTest='address-line2-input'
                />

                <Input
                    name='city'
                    label={
                        <I18n
                            langKey='account.address-procedure.city.label'
                            defaultText='*Town or city'
                        />
                    }
                    placeholder={languagesState.getTranslation(
                        'account.address-procedure.city.placeholder',
                        '*Town or city'
                    )}
                    state={state.townOrCityState}
                    onBlur={state.resetMessage}
                    colorTheme='light'
                    dataTest='town-or-city-input'
                />
            </>
        );
    };

    const renderBodyAdressList = (adressList: findAddress.SuccessResponse): JSX.Element => {
        return (
            <AddressesListNew
                addresses={adressList}
                onChoose={state.onChoose}
            />
        );
    };

    const renderInputsBody = (): JSX.Element => {
        const adressList = state.adressList;
        if (adressList === null) {
            return renderBodyFields();
        }

        return renderBodyAdressList(adressList);
    };

    const renderMessage = (): JSX.Element | null => {
        if (state.messageAfterUpdate === null) {
            return null;
        }
        return (
            <Messages
                marginTop='8px'
                type='success'
                message={state.messageAfterUpdate}
            />
        );
    };

    const isFormLoading = state.updateProgress === true;

    const renderPostCode = (): JSX.Element => {
        return (
            <>
                <PostcodeWrapper data-test='postcode-wrapper'>
                    <PostcodeInputWrapper>
                        <Input
                            name='postCode'
                            label={state.postCodeLabel}
                            placeholder={state.postCodePlaceholder}
                            state={state.postcodeState}
                            onBlur={state.resetMessage}
                            colorTheme='light'
                            dataTest='postcode-input'
                        />
                    </PostcodeInputWrapper>

                    {config.signUpAddressSearchByPostCode === true ? (
                        <FindAddressBtnWrapper
                            disabled={state.isPostcodeButtonDisabled}
                            size='xs'
                            onClick={state.findAdress}
                            isLoading={state.loadingStatus}
                            dataTest='find-address-button'
                        >
                            <I18n
                                langKey='account.address-procedure.find-button.label'
                                defaultText='Find'
                            />
                        </FindAddressBtnWrapper>
                    ) : null}
                </PostcodeWrapper>
                {state.myAddressError === null ? null : (
                    <Messages
                        marginBottom='16px'
                        type='error'
                        message={state.myAddressError}
                    />
                )}
            </>
        );
    };

    const renderBody = (): JSX.Element => {
        if (config.signUpAddressSearchByPostCode === true) {
            return (
                <>
                    {renderPostCode()}
                    {renderInputsBody()}
                </>
            );
        } else {
            return (
                <>
                    {renderInputsBody()}
                    {renderPostCode()}
                </>
            );
        }
    };
    return (
        <FormWrapper method='POST'>
            {renderBody()}
            {renderMessage()}

            <UpdateButton
                type='submit'
                size='large'
                onClick={state.sendUpdate}
                isLoading={isFormLoading}
                disabled={state.isButtonDisabled}
                dataTest='update-address-button'
            >
                <I18n
                    langKey='account.address-procedure.submit.label'
                    defaultText='Update Address'
                />
            </UpdateButton>
        </FormWrapper>
    );
});
