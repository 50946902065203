import { action, computed, observable, makeObservable } from 'mobx';
import { MarketingQuestionsState, QuestionModel } from 'src/domains/players/state/MarketingQuestionsState';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';

class Navigations {
    @observable private currentQuestionId: string | null = null;

    public constructor(
        private readonly localStorageState: LocalStorageState,
        private readonly marketingQuestionsState: MarketingQuestionsState
    ) {
        makeObservable(this);
    }

    // is question ID in local storage
    private isQuestionDismissed = (id: string): boolean => {
        const dismissedQuestions = this.localStorageState.dismissedMarketingQuestions.getValue().slice();
        return dismissedQuestions.includes(id);
    };

    // non-answered and non-dismissed
    @computed public get availableQuestions(): QuestionModel[] {
        const result: QuestionModel[] = [];
        for (const question of this.marketingQuestionsState.unansweredQuestions) {
            if (!this.isQuestionDismissed(question.id)) {
                result.push(question);
            }
        }
        return result;
    }

    public get(): string | null {
        const currentQuestionId = this.currentQuestionId;

        if (currentQuestionId === null) {
            const firstQuestion = this.availableQuestions[0];
    
            if (firstQuestion !== undefined) {
                return firstQuestion.id;
            }
        }
    
        return currentQuestionId;
    }

    public set(newValue: string): void {
        this.currentQuestionId = newValue;
    }

    @computed public get currentQuestion(): QuestionModel | undefined {
        const currentQuestionId = this.get();
        return this.availableQuestions.find((question) => question.id === currentQuestionId);
    }
}

export class MarketingNotificationsState {

    private readonly marketingQuestionsState: MarketingQuestionsState;
    private readonly localStorageState: LocalStorageState;
    private readonly navigations: Navigations;

    public constructor(marketingQuestionsState: MarketingQuestionsState, localStorageState: LocalStorageState) {
        makeObservable(this);
        this.marketingQuestionsState = marketingQuestionsState;
        this.localStorageState = localStorageState;
        this.navigations = new Navigations(localStorageState, marketingQuestionsState);
    }

    @computed public get isBannerAvailable(): boolean {
        const innerWidth = getWindowInnerWidth();
        return this.navigations.availableQuestions.length > 0 && innerWidth !== null && innerWidth >= 1024;
    }

    @computed public get isModalAvailable(): boolean {
        const innerWidth = getWindowInnerWidth();
        return this.navigations.availableQuestions.length > 0 && innerWidth !== null && innerWidth < 1024;
    }


    public get currentQuestionId(): string | null {
        return this.navigations.get();
    }

    @computed public get currentQuestion(): QuestionModel | undefined {
        return this.navigations.currentQuestion;
    }

    @action public dismissQuestion = (id: string): void => {
        const dismissedQuestions = this.localStorageState.dismissedMarketingQuestions.getValue().slice();
        this.localStorageState.dismissedMarketingQuestions.setValue([...dismissedQuestions, id]);
    };

    @action public dismissAllQuestions = (): void => {
        const dismissedQuestions = this.localStorageState.dismissedMarketingQuestions.getValue().slice();
        const questions = this.navigations.availableQuestions.map(question => question.id);
        this.localStorageState.dismissedMarketingQuestions.setValue([...dismissedQuestions, ...questions]);
    };

    @action public setCurrentQuestionId = (id: string): void => {
        this.navigations.set(id);
    };

    @action public setNextQuestion = (): void => {
        const currentQuestionId = this.navigations.get();
        if (currentQuestionId === null) {
            return;
        }

        const questionIndex = this.navigations.availableQuestions.findIndex((question) => question.id === currentQuestionId);
        const questionModel = this.navigations.availableQuestions[questionIndex + 1];

        if (questionModel === undefined) {
            return;
        }

        this.navigations.set(questionModel.id);
    };

    public get availableQuestions(): Array<QuestionModel> {
        return this.navigations.availableQuestions;
    }

    public isLastQuestion = (): boolean => {
        const availableQuestions = this.navigations.availableQuestions;
        const lastQuestion = availableQuestions[availableQuestions.length - 1];
        const currentQuestion = this.currentQuestion;

        if (lastQuestion !== undefined && currentQuestion !== undefined) {
            return lastQuestion.id === currentQuestion.id;
        }

        return false;
    };

    public handleClick = (): boolean => {
        if (this.currentQuestion === undefined) {
            return false;
        }

        if (this.isLastQuestion()) {
            this.marketingQuestionsState.updateQuestions().catch((error) => {
                console.error(error);
            });
            
            return true;
        } else {
            this.setNextQuestion();
        }

        return false;
    };
}
