import React from 'react';
import { ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';
import { DirectionType } from 'src/domains/casino/webview/CasinoGames/home-games/casino-banners/CasinoBanners';
import { ScrollLeft, ScrollRight } from 'src/domains/casino/webview/CasinoGames/home-games/casino-banners/BannerArrow.style';

interface BannerArrowProps {
    slideInDirection: (direction: DirectionType) => void;
    direction: DirectionType;
}

export const BannerArrow = (props: BannerArrowProps): JSX.Element => {
    const { slideInDirection, direction } = props;
    return (
        <>
            {direction === 'left' && (
                <ScrollLeft
                    isVisible={direction === 'left'}
                    onClick={(): void => slideInDirection(direction)}
                >
                    <ButtonChevronIcon position='left' />
                </ScrollLeft>
            )}
            {direction === 'right' && (
                <ScrollRight
                    isVisible={direction === 'right'}
                    onClick={(): void => slideInDirection(direction)}
                >
                    <ButtonChevronIcon position='right' />
                </ScrollRight>
            )}
        </>
    );
};
