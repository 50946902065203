export class ApiTimeLog {
    public show(_responseCode: number): void {
        return;
    }

    public static createWithProcessEnv(_method: string, _url: string): ApiTimeLog {
        return new ApiTimeLog();
    }
}

interface ParamsType {
    method: string;
    url: string;
    body: unknown;
}

export const openApiFormatLog = (prefix: string, params: ParamsType): void => {
    const { method, url, body } = params;

    const prettyFormat = typeof process !== 'undefined' && process.env['LOGS_FORMAT'] === 'pretty';

    if (prettyFormat) {
        console.warn(require('cli-color').red(`${prefix} ${JSON.stringify({
            method: method,
            url: url,
            body: body
        })}`));
    } else {
        console.warn(`${prefix} ${JSON.stringify({
            method: method,
            url: url
        })}`);
    }
};
