import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string;
}

const SvgElement = styled('svg', { label: 'SvgElement' })<PropsType>`
    height: auto;
`;

export const LogoGambleAware = ({ className }: PropsType): JSX.Element => (
    <SvgElement
        xmlns='http://www.w3.org/2000/svg'
        width='122'
        height='16'
        fill='none'
        viewBox='0 0 122 16'
        className={className}
    >
        <path
            fill='#fff'
            d='M.963 8.308C.963 2.784 3.372 0 7.173 0c2.63 0 4.618 1.282 5.17 4.177l.088.465-2.254.486-.088-.42c-.42-1.944-1.503-2.695-2.96-2.695-2.056 0-3.691 1.79-3.691 6.142 0 4.11 1.48 5.834 3.558 5.834 1.48 0 3.204-.773 3.204-3.492v-.463H6.951V8.111h5.503v7.624h-1.967l-.133-1.879C9.624 15.38 8.19 16 6.51 16 3.15 15.997.963 13.324.963 8.308ZM20.937 14.671c-.686.729-1.591 1.305-3.05 1.326-2.076 0-3.425-1.393-3.425-3.182 0-1.944 1.614-2.895 3.028-3.293l3.426-.972v-.398c0-1.237-.775-1.856-1.99-1.856-1.127 0-2.032.707-2.43 1.503l-.067.132-1.746-.928.111-.22c.486-.996 1.835-2.365 4.331-2.365 2.496 0 4.088 1.26 4.088 3.535v5.812c0 .551.023 1.281.133 1.767l.044.2h-2.298l-.023-.111a6.361 6.361 0 0 1-.132-.95Zm-2.454-.42c1.017 0 1.924-.598 2.431-1.238V10.23l-2.386.73c-.905.287-1.702.752-1.702 1.702 0 .951.641 1.591 1.657 1.591v-.001ZM36.557 6.408c-.905 0-1.856.618-2.386 1.149v8.175h-2.298V8.108c0-1.127-.574-1.702-1.679-1.702-.905 0-1.856.619-2.386 1.15v8.174H25.51V4.684h2.188V5.9c.84-.884 2.033-1.48 3.27-1.48 1.547 0 2.475.706 2.917 1.678.84-.995 2.12-1.679 3.447-1.679 2.276 0 3.204 1.525 3.204 3.182v8.13h-2.298V8.109c0-1.127-.574-1.702-1.68-1.702v.002ZM45.242 14.715l-.221 1.017h-1.945V.265h2.298v5.326c.663-.751 1.68-1.172 2.74-1.172 2.519 0 4.22 1.879 4.22 5.635s-1.79 5.944-4.309 5.944c-1.127 0-2.165-.464-2.783-1.281v-.002Zm2.342-.574c1.304 0 2.409-1.193 2.409-4.021 0-2.829-1.15-3.824-2.454-3.824-.883 0-1.613.442-2.165 1.017v5.767c.575.598 1.326 1.06 2.21 1.06ZM54.234.265h2.297v15.467h-2.297V.265ZM58.565 10.363c0-3.49 1.746-5.944 4.795-5.944 2.144 0 4.396 1.282 4.396 5.259v1.037h-6.938v.133c0 2.144 1.128 3.337 2.629 3.337.972 0 1.79-.398 2.342-1.393l.132-.243 1.812.774-.11.22c-.818 1.636-2.276 2.454-4.242 2.454-2.784 0-4.816-1.967-4.816-5.635v.001Zm7.026-1.171v-.156c0-2.011-1.06-2.762-2.231-2.762-1.17 0-2.32.883-2.519 2.916h4.751l-.001.002Z'
        />
        <path
            fill='#EF681C'
            d='M73.854.265h3.338l4.374 15.467h-2.453l-1.084-4.066h-5.126l-1.083 4.066H69.5L73.853.265Zm3.647 9.501-2.033-7.6-2.033 7.6h4.066ZM80.99 4.684h2.298l1.812 7.889 2.011-7.889h2.166l1.967 7.889 1.879-7.889h2.1l-2.851 11.048h-2.277l-1.989-7.866-2.011 7.866h-2.254l-2.85-11.048ZM102.004 14.671c-.686.729-1.591 1.305-3.05 1.326-2.076 0-3.425-1.393-3.425-3.182 0-1.944 1.612-2.895 3.028-3.293l3.426-.972v-.398c0-1.237-.774-1.856-1.989-1.856-1.126 0-2.033.707-2.43 1.503l-.066.132-1.746-.928.111-.22c.486-.996 1.835-2.365 4.331-2.365 2.496 0 4.088 1.26 4.088 3.535v5.812c0 .551.023 1.281.133 1.767l.044.2h-2.298l-.023-.111a6.883 6.883 0 0 1-.133-.95h-.001Zm-2.452-.42c1.017 0 1.923-.598 2.431-1.238V10.23l-2.387.73c-.906.287-1.702.752-1.702 1.702 0 .951.642 1.591 1.658 1.591v-.001ZM106.511 4.684h2.144v2.077c1.458-1.702 2.763-2.21 3.867-2.232v2.144c-1.546 0-2.762.862-3.712 1.723v7.336h-2.297V4.684h-.002ZM112.763 10.363c0-3.49 1.746-5.944 4.795-5.944 2.144 0 4.398 1.282 4.398 5.259v1.037h-6.938v.133c0 2.144 1.128 3.337 2.63 3.337.972 0 1.791-.398 2.342-1.393l.133-.243 1.812.774-.11.22c-.818 1.636-2.277 2.454-4.242 2.454-2.784 0-4.817-1.967-4.817-5.635l-.003.001Zm7.026-1.171v-.156c0-2.011-1.06-2.762-2.232-2.762-1.172 0-2.321.883-2.519 2.916h4.751v.002Z'
        />
    </SvgElement>
);
