import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { PromoCodeWrapper, PromoCodeHeader, ChevronWrapper, PromoCodeInputWrapper } from './PromoCode.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { SignUpWelcomeState as SignUpWelcomeStatePrimary } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/signupWelcome/SignupWelcome.state';

import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';

interface PromoCodeState extends Partial<SignUpWelcomeStatePrimary> {
    promoCodeTextState: FormInputState<string,string>;
}

interface PromoCodeTypes {
    isPromoCode: boolean;
    setPromoCode: (v: boolean) => void;
    state: PromoCodeState;
}

export const PromoCode = observer('PromoCode', (props: PromoCodeTypes) => {
    const setPromoCodeValue = (): void => {
        props.setPromoCode(!props.isPromoCode);
    };
    const PromoCodeInput = (): JSX.Element | null => {
        const { isPromoCode, state } = props;
        if (isPromoCode) {
            return (
                <PromoCodeInputWrapper>
                    <Input dataTest='promo-code-input'
                        state={state.promoCodeTextState}
                        onChange={state.promoCodeTextChange}
                        name='promo-code'
                        type='text'
                    />
                </PromoCodeInputWrapper>
            );
        }
        return null;
    };

    const PromoCodeText = (): JSX.Element => {
        const { isPromoCode } = props;
        const arrowPosition = isPromoCode ? 'down' : 'right';

        return (
            <PromoCodeHeader
                onClick={setPromoCodeValue}
                data-test='promo-code-header'
            >
                <>
                    <ChevronWrapper position={arrowPosition} />
                    <I18n langKey='signup.step-one.promo-code' defaultText='Promo code?' />
                </>
            </PromoCodeHeader>
        );
    };

    return (
        <PromoCodeWrapper>
            {PromoCodeText()}
            {PromoCodeInput()}
        </PromoCodeWrapper>
    );
});
