import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { DepositTabWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/DepositProcedure.style';
import { ImageQrCode, PayButton, QrCodeContainer, QrCodeText } from './ZenetPayTab.style';
import { PaymentProviderImages } from './assets/PaymentProviderImages';
import { Amount } from 'src_common/common/amount/Amount';
import { useAppStateContext } from 'src/appState/AppState';
import { observable, action, makeObservable } from 'mobx';

export interface ZenetPayQrCodeTabPropsType {
    amount: Amount;
    qrCode: string;
    qrCodeBase64: string;
}

class ZenetPayQrCodeTabState {

    @observable public isQrCodeLoaded: boolean = true;

    public constructor (
        private readonly qrCode: string
    ) {
        makeObservable(this);
    }

    @action public setIsQrCodeLoaded = (): void => {
        this.isQrCodeLoaded = true;
    };

    public copyToClipboard = async (): Promise<void> => {
        try {
            await navigator.clipboard.writeText(this.qrCode);
        } catch (err) {
            console.error('error - cannot copy Pix code');
        }
    };
}

export const ZenetPayQrCodeTab = observer('ZenetPayQrCodeTab', ({ amount, qrCode }: ZenetPayQrCodeTabPropsType): JSX.Element => {
    const { appPlayersState: { usersState } } = useAppStateContext();
    const [state] = useState(new ZenetPayQrCodeTabState(qrCode));

    return (
        <DepositTabWrapper>
            <QrCodeContainer>

                <QrCodeText data-test='qr-code-header'>
                    <I18n langKey='account.top-up.zenet-pay.scan-to-deposit' defaultText='Scan to deposit ' />
                    {amount.format(usersState.currency)}
                </QrCodeText>

                {state.isQrCodeLoaded === false ? <div>Loading...</div> : null}

                <ImageQrCode
                    src={`https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${qrCode}&choe=UTF-8`}
                    alt='Pix qr code'
                    onLoad={state.setIsQrCodeLoaded}
                    data-test='qr-code'
                />

                <QrCodeText>
                    <I18n langKey='account.top-up.zenet-pay.or' defaultText='OR' />
                </QrCodeText>

                <PayButton size='large' version='secondary' onClick={state.copyToClipboard}>
                    <I18n langKey='account.top-up.zenet-pay.copy-pix-code' defaultText='Copy pix code' />
                </PayButton>

                <PaymentProviderImages/>
            </QrCodeContainer>
        </DepositTabWrapper>
    );
});
