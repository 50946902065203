import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { BetHistoryLegType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

export const BetSPPriceInfo = observer<{ leg: BetHistoryLegType }>('BetSPPriceInfo', ({ leg }): JSX.Element | null => {
    const appState = useAppStateContext();
    const oddsFormat = appState.appPlayersState.usersState.oddsFormatShort;

    if (leg.priceType !== 'bp') {
        return null;
    }

    const spPrice = leg.spPrice ?? null;

    if (spPrice !== null) {
        const spPriceD = spPrice.d ?? null;
        const spPriceF = spPrice.f ?? null;

        return <div>SP - {oddsFormat === 'd' && spPriceD !== null ? spPriceD.toFixed(2) : spPriceF}</div>;
    }

    return null;
});
