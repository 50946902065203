import styled from '@emotion/styled';
import * as React from 'react';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface CasinoLucky6IconPropsType {
    className?: string;
    onClick?: () => void;
}

export const CasinoLucky6Icon = observer(
    'CasinoLucky6Icon',
    ({ className, onClick }: CasinoLucky6IconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                onClick={onClick}
                viewBox='0 0 24 24'
                height='24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.512 16.683A16.035 16.035 0 0 0 1.284 14.5 11.091 11.091 0 0 1 1 12.017 11 11 0 0 1 21.112 5.92a14.351 14.351 0 0 0 .055 4.757 15.078 15.078 0 0 0 1.402 4.355 11.001 11.001 0 0 1-2.09 3.969 13.868 13.868 0 0 0-5.142 1.09 14.117 14.117 0 0 0-4.584 2.824 10.87 10.87 0 0 1-4.656-1.65 13.126 13.126 0 0 0-2.585-4.583Zm12.714 4.601a6.58 6.58 0 0 0-2.585 1.614 10.342 10.342 0 0 0 1.613-.376c.312-.454.778-.78 1.311-.917.197-.08.403-.136.614-.165-.284.193-.394.321-.33.468a10.29 10.29 0 0 0 1.834-1.11 5.225 5.225 0 0 0-2.457.486ZM23 12.75V11.22a1.228 1.228 0 0 1-.32-.751c-.08-.282-.05-.583.082-.844a9.162 9.162 0 0 0-.422-1.43 6.693 6.693 0 0 0 .055 2.439A8.05 8.05 0 0 0 23 12.75ZM8.333 3.574a5.968 5.968 0 0 1 7.874 3.062 5.986 5.986 0 0 1-3.666 7.618 5.986 5.986 0 0 1-7.874-3.062 6.004 6.004 0 0 1 3.666-7.618Zm4.978 5.253c.495 1.246-.514 2.264-1.742 2.75A2.695 2.695 0 0 1 7.73 10.1a2.98 2.98 0 0 1 1.833-4.244c.77-.303 2.08-.568 2.429.348a.797.797 0 0 1-.532 1.1c-.531.211-.971.083-1.1-.247a.56.56 0 0 1 .174-.67 1.008 1.008 0 0 0-.55.056c-.99.385-1.136 1.21-.733 2.218l.12.302a2.447 2.447 0 0 1 1.31-.98c1.155-.459 2.21-.22 2.63.843Zm-2.915.073c.486-.192.916 0 1.173.688.257.687.101 1.118-.385 1.32-.486.201-.916 0-1.192-.697-.275-.697-.091-1.118.404-1.31Zm-7.508 9.268a9.843 9.843 0 0 1-.843-1.485 7.335 7.335 0 0 1 1.64 1.613 6.29 6.29 0 0 1 1.147 2.063l-1.119-1.11a1.421 1.421 0 0 0-.302-.623 1.678 1.678 0 0 0-.523-.458Z'
                    fill='#fill'
                />
            </SvgElement>
        );
    }
);
