import Big from 'big.js';
import { CurrencyType, formatAmountByCurrency, formatAmountByCurrencyConfig } from 'src_common/common/amount/website-money/currency';

Big.PE = 100;
Big.NE = -100;

/*
    http://mikemcl.github.io/big.js/
    https://www.npmjs.com/package/big.js?activeTab=readme
*/

function checkStringValue<V>(value: V): string {
    if (typeof value !== 'string') {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Value is not as a string, value: ${value}. Type of value is: ${typeof value}`);
    }
    return value;
}

function convertComma(value: string): string {
    if (value.includes(',')) {
        console.warn(`Wrong format of value. Separator should be with dot not comma: ${value}`);
        return value.replace(',', '.');
    }
    return value;
}

function convertEmpty(value: string): string {
    if (value === '') {
        console.warn('Value is empty string, convert to 0!');
        return '0';
    }
    return value;
}

export class Amount {
    public readonly value: string;

    public constructor(value: string) {

        //checking that what comes in is in the right format
        try {
            this.value = new Big(convertEmpty(convertComma(checkStringValue(value)))).toString();
        } catch (e) {
            console.error('value is: ', value);
            throw e;
        }

    }

    public static newOption(value: string | null | undefined): Amount | null {
        if (value === null || value === undefined) {
            return null;
        }

        return new Amount(value);
    }

    private get asBigJs(): Big {
        return new Big(this.value);
    }

    public add(value: Amount): Amount {
        const valueBig = new Big(value.value);
        const newValue = new Big(this.value).add(valueBig).toString();
        return new Amount(newValue);
    }

    public sub(value: Amount): Amount {
        const valueBig = new Big(value.value);
        const newValue = new Big(this.value).sub(valueBig).toString();
        return new Amount(newValue);
    }

    public multiply(n: number): Amount {
        return new Amount(this.asBigJs.times(n).toString());
    }

    public div(n: number): Amount {
        return new Amount(this.asBigJs.div(n).toString());
    }

    public divByAmount(n: Amount): number {
        return this.asBigJs.div(n.value).toNumber();
    }

    //Shouldn't be used for crypto
    public round(): Amount {
        return new Amount(this.asBigJs.round().toString());
    }

    //Only for cypress tests (backend differences in rounding)
    public roundUp(number: number): Amount {
        return new Amount(this.asBigJs.round(number, Big.roundUp).toString());
    }

    //Only for cypress tests (backend differences in rounding)
    public roundDown(number: number): Amount {
        return new Amount(this.asBigJs.round(number, Big.roundDown).toString());
    }

    public toFixed(number: number): string {
        return this.asBigJs.toFixed(number);
    }

    public isLessThanZero(): boolean {
        return this.asBigJs.lt(0);
    }

    public isLessThan(amount: Amount): boolean {
        return this.asBigJs.lt(amount.value);
    }

    public isLessOrEqual(amount: Amount): boolean {
        return this.asBigJs.lte(amount.value);
    }

    public isGreaterThan(amount: Amount): boolean {
        return this.asBigJs.gt(amount.value);
    }

    public isGreaterOrEqual(amount: Amount): boolean {
        return this.asBigJs.gte(amount.value);
    }

    public isGreaterThanZero(): boolean {
        return this.asBigJs.gt(0);
    }

    public isEqualWith(amount: Amount): boolean {
        return this.asBigJs.eq(amount.value);
    }

    public isEqualWithZero(): boolean {
        return this.asBigJs.eq(0);
    }

    public format(currency: CurrencyType, config?: formatAmountByCurrencyConfig): string {
        return formatAmountByCurrency(currency, this, config);
    }

    public inverse(): Amount {
        return new Amount(this.asBigJs.neg().toString());
    }
}

