import React, { useState } from 'react';
import { EventsTable } from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable';
import { useAppStateContext } from 'src/appState/AppState';
import { computed, makeObservable } from 'mobx';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { observer } from 'src/utils/mobx-react';
import { EventsTableHeaderStyle } from 'src/domains/sportsbook/webview/components/eventsTable/EventsMainSectionWrapper';
import { AppSportsBookState } from 'src/domains/sportsbook/state/AppSportsBook.state';

class EventsStartingSoonState {
    public constructor(private readonly appSportsBookState: AppSportsBookState){
        makeObservable(this);
    }

    @computed public get eventsCollection(): EventsCollectionList {
        return this.appSportsBookState.eventsCollection.listStartingSoon;
    }

    public get tableLabel(): string {
        return this.appSportsBookState.language.getTranslation('special-box.header.starting-soon', 'Starting soon');
    }
};

interface PropsTypes {
    showPriceLegend: boolean,
    headerStyleLevel: 'sportLevel' | 'competitionLevel'
}

export const EventsStartingSoon = observer('EventsStartingSoon', ({ showPriceLegend, headerStyleLevel }: PropsTypes) => {
    const { appSportsBookState, config } = useAppStateContext();
    const [upcomingState] = useState(() => new EventsStartingSoonState(appSportsBookState));
    const isLoading = upcomingState.eventsCollection.isLoading ?? true;

    return (
        <EventsTable
            headerStyle={EventsTableHeaderStyle.SOLID}
            filterVersion={config.filtersVersion.startingSoon}
            icon='in-play'
            className='events-table--in-play events-table--dark'
            title={upcomingState.tableLabel}
            showHeaderSport={true}
            allGroupsOpen={true}
            eventsCollection={upcomingState.eventsCollection}
            isTogglable={true}
            showPriceLegend={showPriceLegend}
            filters={[]}
            isLoading={isLoading}
            hideNoEventsMessage={true}
            dataTest='starting-soon'
            headerStyleLevel={headerStyleLevel}
            isAmericanFootball={false}
        />
    );
});
