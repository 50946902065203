import Montserrat400 from 'src/domains/layouts/shared/assets/fonts/montserrat-regular.woff2';
import Montserrat500 from 'src/domains/layouts/shared/assets/fonts/montserrat-medium.woff2';
import Montserrat700 from 'src/domains/layouts/shared/assets/fonts/montserrat-bold.woff2';

import GentonaRegular400 from 'src/domains/layouts/shared/assets/fonts/gentona_light.woff2';
import GentonaMedium600 from 'src/domains/layouts/shared/assets/fonts/gentona_semi_bold.woff2';
import GentonaBold700 from 'src/domains/layouts/shared/assets/fonts/gentona_book_bold.woff2';

import OpenSans400 from 'src/domains/layouts/shared/assets/fonts/opensans-regular.woff2';
import OpenSans600 from 'src/domains/layouts/shared/assets/fonts/opensans-semibold.woff2';
import OpenSans700 from 'src/domains/layouts/shared/assets/fonts/opensans-bold.woff2';

interface IFontOptions {
    name: string;
    format: 'woff' | 'woff2';
    fonts: Array<{
        weight: number;
        path: string;
    }>;
}

const fontsDefault: Array<IFontOptions> = [
    {
        name: 'Montserrat',
        format: 'woff2',
        fonts: [
            {
                weight: 400, // Regular
                path: Montserrat400,
            },
            {
                weight: 500, // Medium
                path: Montserrat500,
            },
            {
                weight: 700, // Bold
                path: Montserrat700,
            },
        ],
    },
    {
        name: 'Gentona',
        format: 'woff2',
        fonts: [
            {
                weight: 400, // Regular
                path: GentonaRegular400,
            },
            {
                weight: 600, // Medium
                path: GentonaMedium600,
            },
            {
                weight: 700, // Bold
                path: GentonaBold700,
            },
        ],
    },
    {
        name: 'Open Sans',
        format: 'woff2',
        fonts: [
            {
                weight: 400, // Regular
                path: OpenSans400,
            },
            {
                weight: 600, // Semi bold
                path: OpenSans600,
            },
            {
                weight: 700, // Bold
                path: OpenSans700,
            },
        ],
    },
];

const generateFontFace = (optionsList: Array<IFontOptions>): string => {
    const optionsListResult: Array<string> = [];

    for (const options of optionsList) {
        const { name, fonts, format } = options;

        for (const font of fonts) {
            optionsListResult.push(`
                @font-face {
                    font-family: '${name}';
                    font-weight: ${font.weight};
                    font-style: normal;
                    src: url('${font.path}') format('${format}');
                    font-display: swap;
                }
            `);
        }
    }

    return optionsListResult.join('');
};

export const getUniverseFont = (): string => {
    return generateFontFace(fontsDefault);
};
