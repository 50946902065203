import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { FilterSuppliers } from 'src/domains/casino/webview/CasinoGames/filters-dropdown/FilterSuppliers';
import { AppCasinoState, CasinoPageState } from 'src/domains/casino/state/AppCasino.state';
import { CasinoFilterWrapper } from './CasinoFilters.style';

interface CasinoFiltersProps {
    appCasinoState: AppCasinoState;
    casinoPageState: CasinoPageState;
}

export const CasinoFilters = observer('CasinoFilters', (props: CasinoFiltersProps) => {
    const { appCasinoState, casinoPageState } = props;
    const { features } = appCasinoState;

    return (
        <CasinoFilterWrapper>
            <FiltersScrollableWrapper
                casinoGradientOverlay={true}
                customHeight={casinoPageState.casinoSearchExt.isDropdownOpen ? 236 : undefined}
            >
                <FiltersNewView
                    skin={features.config.filtersVersion.casino}
                    isMultipleLine={false}
                    select={casinoPageState.navigationState.activeTabIdForUrl}
                    filters={casinoPageState.navigationState.filtersHome}
                />
                <FilterSuppliers appCasinoState={appCasinoState} casinoPageState={casinoPageState} />
            </FiltersScrollableWrapper>
        </CasinoFilterWrapper>
    );
});
