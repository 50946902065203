import React, { useState } from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import {
    EventHeaderGroup,
    EventHeaderGroupName,
    EventHeaderGroupSelection,
    EventHeaderGroupSelectionName, ChevronIconStyle
} from 'src/domains/sportsbook/webview/components/eventsList/eventGroupHeader6Pack/EventsGroupHeader6Pack.style';
import { Hr } from 'src/domains/sportsbook/webview/components/eventGroupHeader/EventsGroupHeader.style';

interface StatePropsType {
    onToggle: () => void,
}

class State {
    private readonly props: StatePropsType;

    public constructor(props: StatePropsType) {
        this.props = props;
    }

    public onToggle = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        this.props.onToggle();
    }
}

interface EventGroupHeaderPropsType {
    label: string,
    small: boolean,
    open: boolean,
    onToggle: () => void,
    marketNames: [string, string, string],
    headerStyle: 'sportLevel' | 'competitionLevel'
}

export const EventGroupHeader6Pack = observer('EventGroupHeader6Pack', (propsIn: EventGroupHeaderPropsType) => {
    const props = useAsObservableSource(propsIn);
    const [state] = useState(() => new State(props));
    const { label, small, open, marketNames, headerStyle } = props;

    return (
        <>
            <EventHeaderGroup headerStyle={headerStyle} small={small} onClick={state.onToggle}>
                <ChevronIconStyle position={open ? 'down' : 'right'} />

                <EventHeaderGroupName className='event-group-header__name'>{label}</EventHeaderGroupName>
                {
                    open ?
                        marketNames.map((marketName) => {
                            return (
                                <EventHeaderGroupSelection key={marketName}>
                                    <EventHeaderGroupSelectionName>
                                        {marketName}
                                    </EventHeaderGroupSelectionName>
                                </EventHeaderGroupSelection>
                            );
                        }) : null
                }
            </EventHeaderGroup>

            <Hr isOpen={open} small={small} headerStyle={headerStyle} />
        </>
    );
});
