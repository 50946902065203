import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

import { RaceSummaryRowHorseRacing } from './raceSummaryRowSport/RaceSummaryRowHorseRacing';
import { RaceSummaryRowGreyhoundRacing } from './raceSummaryRowSport/RaceSummaryRowGreyhoundRacing';
import { RaceSummaryCastRowHorseRacing } from './raceSummaryRowSport/RaceSummaryCastRowHorseRacing';
import { RaceSummaryCastRowGreyhoundRacing } from './raceSummaryRowSport/RaceSummaryCastRowGreyhoundRacing';

import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { ThumbsUpIconWrapper, Tags, TagsAlternative, BoostIconWrapper, IconTextWrapper } from './RaceSummaryRow.style';
import { SpanSelection, RunnerDescription, SelectionTag } from 'src/domains/sportsbook/webview/components/horseRacingNewTab/RaceSummaryRow.style';
import { NonRunnerCell, RaceRunnerName } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';

import { SelectionRaceCard } from 'src/domains/sportsbook/webview/components/selectionRaceCard/SelectionRaceCard';
import { SelectionCast } from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCast';
import { useAppStateContext } from 'src/appState/AppState';
import {
    SelectionCastByPlace
} from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCastByPlace';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

export const getPlace = (selection: SelectionModel): React.ReactNode => {
    const number = selection.metaDataNumber;
    const drawn = selection.metaDataDrawn ?? null;

    return (
        <>
            {number ?? 'n/a'}
            <br />
            {drawn === null ? null : `(${drawn})`}
        </>
    );
};

export function getRunner(
    selection: SelectionModel,
    isUnnamedFavourite: boolean,
    viewDescription: boolean,
    displayTrainer?: boolean,
    isSpecials?: boolean
): React.ReactNode {
    const jockey = selection.metaDataJockey ?? null;
    const trainer = selection.metaDataTrainer ?? null;

    const description = jockey === null ? trainer ?? '' : trainer === null ? jockey : `${jockey} / ${trainer}`;

    return (
        selection.name === '' ? null : (
            <>
                <RaceRunnerName data-test='runner-name'>
                    {selection.name}
                </RaceRunnerName>

                {isUnnamedFavourite || !viewDescription || displayTrainer !== true || isSpecials === true
                    ? undefined
                    : <RunnerDescription data-test='runner-description'>
                        {description}
                    </RunnerDescription>
                }
            </>
        )
    );
};

export interface NotRunningPriceView {
    eachWayExtra?: boolean;
}

export const NotRunningPriceView = observer('NotRunningPriceView', (props: NotRunningPriceView): JSX.Element => {
    return <NonRunnerCell eachWayExtra={props.eachWayExtra}>NR</NonRunnerCell>;
});

export const NotVisiblePriceView = observer('NotVisiblePriceView', (): JSX.Element => {
    return <SpanSelection>
        <span>-</span>
    </SpanSelection>;
});

const getWinner = (selection: SelectionModel): React.ReactNode => {
    if (selection.resultType === 'void') {
        return <NotRunningPriceView />;
    }

    if (!selection.display) {
        return <NotVisiblePriceView />;
    }
    return (
        <SelectionRaceCard
            selectionId={selection.id2}
            sp={false}
            borderLeft={true}
            borderRight={true}
            isRacingPage={true}
        />
    );
};

export const getSP = (selection: SelectionModel, isFinished?: boolean): React.ReactNode => {
    if (selection.resultType === 'void') {
        return <NotRunningPriceView />;
    }

    if (!selection.display) {
        return <NotVisiblePriceView />;
    }

    return (
        <SelectionRaceCard
            selectionId={selection.id2}
            sp={true}
            borderLeft={true}
            isFinished={isFinished}
            isRacingPage={true}
        />
    );
};

const getCast = (selection: SelectionModel, idx: number): React.ReactNode => {
    if (selection.resultType === 'void') {
        return <NotRunningPriceView />;
    }

    if (!selection.display) {
        return <NotVisiblePriceView />;
    }

    return (
        <SelectionCastByPlace
            selectionId={selection.id2}
            index={idx}
        />
    );
};

const getAnyCast = (selection: SelectionModel, idx: number): React.ReactNode => {
    if (selection.resultType === 'void') {
        return <NotRunningPriceView />;
    }

    if (!selection.display) {
        return <NotVisiblePriceView />;
    }

    return (
        <SelectionCast
            selectionId={selection.id2}
            title={<I18n langKey='events.race-summary.any.label' defaultText='Any' />}
            index={idx}
            borderLeft={true}
        />
    );
};

interface TcFcType {
    oneCast: React.ReactNode;
    twoCast: React.ReactNode;
    threeCast?: React.ReactNode;
    anyCast: React.ReactNode;
}

function getTcFc(selection: SelectionModel, market: MarketModel): TcFcType {
    if (!market.tricastsOffered) {
        return {
            oneCast: getCast(selection, 0),
            twoCast: getCast(selection, 1),
            anyCast: getAnyCast(selection, 2)
        };
    }

    return {
        oneCast: getCast(selection, 0),
        twoCast: getCast(selection, 1),
        threeCast: getCast(selection, 2),
        anyCast: getAnyCast(selection, 3)
    };
}

const SPECIAL_TAGS: Array<['price-boost' | 'pricewise', React.ReactElement]> = [
    ['pricewise', <I18n key='pricewise' langKey='events.race-summary.pricewise.label' defaultText='Pricewise' />],
    ['price-boost', <I18n key='price-boost' langKey='events.race-summary.price-boost.places' defaultText='Price boost' />]
];

const PRICE_WISE_ALTERNATIVE = (
    <IconTextWrapper>
        <ThumbsUpIconWrapper position='up' />
        <I18n langKey='events.race-summary.toptip.label' defaultText='Top Tip' />
    </IconTextWrapper>
);

const PRICE_BOOST_ALTERNATIVE = (
    <IconTextWrapper>
        <BoostIconWrapper />
        <I18n langKey='events.race-summary.price-boost.places-avaliable' defaultText='Price boost available' />
    </IconTextWrapper>
);

const renderTags = (selection: SelectionModel, type: string | undefined, isAlternative: boolean): React.ReactNode | undefined => {
    const tags: Array<React.ReactNode> = [];

    if (isAlternative) {
        const isPriceBoostActive = selection.getTag('price-boost') === 'yes';
        const isPriceWiseActive = selection.getTag('pricewise') === 'yes';

        if (isPriceBoostActive && isPriceWiseActive) {
            return (
                <TagsAlternative isPriceBoost={true}>
                    {PRICE_WISE_ALTERNATIVE}
                    {PRICE_BOOST_ALTERNATIVE}
                </TagsAlternative>
            );
        }
        if (isPriceBoostActive && type !== 'cast') {
            return (
                <TagsAlternative isPriceBoost={true} key='price-boost'>
                    {PRICE_BOOST_ALTERNATIVE}
                </TagsAlternative>
            );
        }

        if (isPriceWiseActive) {
            return (
                <TagsAlternative isPriceBoost={false} key='pricewise'>
                    {PRICE_WISE_ALTERNATIVE}
                </TagsAlternative>
            );
        }

        return null;
    }

    for (const [tagName, jsx] of SPECIAL_TAGS) {
        if (selection.getTag(tagName) === 'yes' && (tagName === 'price-boost' && type === 'cast') === false) {
            tags.push((
                <SelectionTag isPriceBoost={tagName === 'price-boost'} key={tagName}>
                    {jsx}
                </SelectionTag>
            ));
        }
    }

    if (tags.length > 0) {
        return (
            <Tags>{tags}</Tags>
        );
    }

    return undefined;
};

interface PropsType {
    selectionId: SelectionId;
    type?: string;
    displayTrainer?: boolean;
    isSpecials?: boolean;
    isRaceInProgress?: boolean;
    isRaceFinished?: boolean;
}

export const RaceSummaryRow = observer('RaceSummaryRow', (props: PropsType) => {
    const { selectionId, type, displayTrainer, isSpecials, isRaceInProgress } = props;
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState, configComponents } = appLayoutsState;
    const { config } = configComponents;

    const selectionModel = selectionId.getModel();
    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    const eventModel = marketModel.getEvent();
    if (eventModel === null) {
        return null;
    }

    const sport = eventModel.sport;
    const isNonRunner = selectionModel.resultType === 'void';
    const isUnnamedFavourite = selectionModel.templateId === 'unnamed-favourite';

    const tags = renderTags(selectionModel, type, config.isAlternativeRacingBoost);

    // The SP for unnamed favourite should always be visible
    const spSelection = isUnnamedFavourite || breakpointsState.tablet.isBiggerOrEq === true ? getSP(selectionModel) : null;

    if (sport === 'horseracing' && type === undefined) {
        return (
            <RaceSummaryRowHorseRacing
                isRaceInProgress={isRaceInProgress}
                antePost={eventModel.antePost}
                isSpecials={isSpecials}
                isNonRunner={isNonRunner}
                isUnnamedFavourite={isUnnamedFavourite}
                selectionId={selectionId} //TODO - replace with selectionId
                tags={tags}
                place={getPlace(selectionModel)}
                runner={getRunner(selectionModel, isUnnamedFavourite, true, true, isSpecials)}
                winner={getWinner(selectionModel)}
                sp={spSelection}
                nrPricesToShow={breakpointsState.tablet.isBiggerOrEq === false ? 2 : undefined}
                dataTest='race-summary-row'
            />
        );
    } else if (sport === 'greyhoundracing' && type === undefined) {
        return (
            <RaceSummaryRowGreyhoundRacing
                isRaceInProgress={isRaceInProgress}
                antePost={eventModel.antePost}
                isNonRunner={isNonRunner}
                isUnnamedFavourite={isUnnamedFavourite}
                selectionId={selectionId}
                tags={tags}
                runner={getRunner(selectionModel, isUnnamedFavourite, false, displayTrainer)}
                winner={getWinner(selectionModel)}
                sp={spSelection}
                nrPricesToShow={breakpointsState.tablet.isBiggerOrEq === false ? 2 : false}
                trapChallengeTag={marketModel.trapChallengeTag}
                dataTest='race-summary-row'
            />
        );
    } else if (sport === 'horseracing' && type === 'cast') {
        return (
            <RaceSummaryCastRowHorseRacing
                isNonRunner={isNonRunner}
                isUnnamedFavourite={isUnnamedFavourite}
                selectionId={selectionId}
                runner={getRunner(selectionModel, isUnnamedFavourite, true, true)}
                place={getPlace(selectionModel)}
                {...getTcFc(selectionModel, marketModel)}
                dataTest='race-summary-cast-row'
            />
        );
        // eslint-disable-next-line no-dupe-else-if
    } else if (sport === 'greyhoundracing' && type === 'cast') {
        return (
            <RaceSummaryCastRowGreyhoundRacing
                isUnnamedFavourite={isUnnamedFavourite}
                selectionId={selectionId}
                runner={getRunner(selectionModel, isUnnamedFavourite, true)}
                place={getPlace(selectionModel)}
                isNonRunner={isNonRunner}
                {...getTcFc(selectionModel, marketModel)}
                dataTest='race-summary-cast-row'
            />
        );
    }
    return null;
});
