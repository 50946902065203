import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { PromoNotificationsModelForView } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { AppCasinoState } from 'src/domains/casino/shared/Types';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';

export class NotificationMediaState {
    public constructor(
        private readonly router: StarRouter,
        private readonly notification: PromoNotificationsModelForView,
        private readonly appCasinoState: AppCasinoState,
        private readonly models: ModelsState,
    ) {}

    public handleOnClick = (): void => {
        const { action_template, event_id, competition_id, sport, casino_game_id } = this.notification;
        const event_id_view = this.models.id.getEventIdOption(event_id ?? null);
        const competition_id_view = competition_id ?? null;
        const casino_game_id_view = casino_game_id ?? null;
        const sport_view = sport ?? '';

        if (action_template === 'sport' && sport_view !== '') {
            this.router.redirectToSportPage(sport_view);
            return;
        }

        if (action_template === 'event-id' && event_id_view !== null) {
            this.router.redirectToEvent(event_id_view);
            return;
        }

        if (action_template === 'competition-id' && competition_id_view !== null && sport_view !== '') {
            this.router.redirectToCompetitionPage(competition_id_view, sport_view);
            return;
        }

        if (action_template === 'casino-game-id' && casino_game_id_view !== null) {
            this.router.redirectToCasinoPage();
            this.appCasinoState.gameStoreExt.getGameModel(casino_game_id_view)?.startGame();
        }
    };
}

