import styled from '@emotion/styled';

export const EventCardWrapper = styled('div', { label: 'EventCardWrapper' })`
    position: relative;
`;

export const EventCardInfoWrapper = styled('div', { label: 'EventCardInfoWrapper' })`
    padding: 30px 5px;
    position: relative;
    text-align: center;
`;
