import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    RadioButtonWrapper,
    RadioButton,
    RadioButtonInput,
    RadioLabel
} from './RadioButton/RadioButton.style';

interface RadioButtonPropsTypes {
    value?: string | number,
    name?: string,
    label?: string,
    isActive: boolean,
    isDisabled: boolean,
    size: 'large' | 'small',
    version: 'dark' | 'light';
    onChange?: (value?: string | number) => void,
}

class RadioButtonUniversalState {
    public constructor(
        private readonly name?: string,
        private readonly onChange?: (value?: string | number) => void
    ) { }

    public handleChange = (): void => {
        if (this.onChange !== undefined && this.name !== undefined) {
            this.onChange(this.name);
        }
    };
}

export const RadioButtonUniversal = observer('RadioButtonUniversal', ({
    value,
    label,
    name,
    isActive,
    isDisabled,
    onChange,
    size,
    version }: RadioButtonPropsTypes) => {
    
    const [ state ] = React.useState(() => new RadioButtonUniversalState(name, onChange));

    return (
        <RadioButtonWrapper disabled={isDisabled}>
            {size === 'small' ? <RadioLabel size={size}>{label}</RadioLabel> : null}

            <RadioButton
                isActive={isActive}
                size={size}
                version={version}
            >
                <RadioButtonInput
                    type='radio'
                    name={name}
                    value={value}
                    checked={isActive}
                    onChange={state.handleChange}
                />
            </RadioButton>
            
            {size === 'large' ? <RadioLabel size={size}>{label}</RadioLabel> : null}
        </RadioButtonWrapper>
    );
});
