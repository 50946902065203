import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface InfoIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const InfoIcon = observer(
    'InfoIcon',
    ({ className, onClick }: InfoIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
                data-test='info-icon'
            >
                <path
                    d='M12 1C5.926 1 1 5.925 1 12c0 6.076 4.925 11 11 11 6.074 0 11-4.924 11-11 0-6.075-4.926-11-11-11zm1.027 3.971c1.073 0 1.388.622 1.388 1.334 0 .888-.71 1.71-1.924 1.71-1.015 0-1.499-.51-1.469-1.355 0-.711.595-1.689 2.005-1.689zM10.28 18.588c-.734 0-1.269-.446-.757-2.4l.84-3.466c.146-.554.17-.776 0-.776-.219 0-1.17.382-1.732.76l-.366-.599c1.782-1.488 3.83-2.361 4.707-2.361.734 0 .855.866.49 2.2l-.963 3.643c-.17.644-.097.866.073.866.22 0 .94-.265 1.648-.823l.415.556c-1.734 1.733-3.623 2.4-4.355 2.4z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
