import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { LiveTracker } from 'src/domains/sportsbook/webview/components/scoreboards/liveTracker/LiveTracker';
import { LSportWrapper } from 'src/domains/sportsbook/webview/components/scoreboards/lSport/lSport';
import { Scoreboards } from 'src/domains/sportsbook/webview/components/scoreboards/Scoreboards';
import { ImgArena } from 'src/domains/sportsbook/webview/components/scoreboards/imgArena/ImgArena';

interface MatchLeadPropsType {
    event: EventModel | null;
}

export const MatchLead = observer('MatchLead', ({ event }: MatchLeadPropsType) => {
    if (event === null) {
        return null;
    }

    return (
        <div>
            {/* All basic and animated scoreboards */}
            <Scoreboards event={event} />

            {/* LSport now is Statscore scoreboard */}
            <LSportWrapper
                sport={event.sport}
                lsportExternalId={event.lsportExternalId}
                timeSettingsStarted={event.timeSettingsStarted}
            />

            {/* Live Tracker from Planetsport with animation */}
            <LiveTracker
                sport={event.sport}
                eventId={event.id2.toOldId()}
            />

            {/* Img arena live tracer */}
            <ImgArena
                sport={event.sport}
                eventFeedGroupId={event.feedGroupId} // hardcoded event for stg testing: '644'
                eventId={event.id2.toOldId()}
            />
        </div>
    );
});
