import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import {
    Balance,
    BalanceWrapper,
    BalanceWrapperTitle,
} from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/balanceLinkNav/BalanceLinkNav.style';

const BalanceValue = observer('BalanceValue', (): JSX.Element | null => {
    const { appPlayersState } = useAppStateContext();
    const playableBalance = appPlayersState.usersState.walletData.valueReady?.playableBalance;

    if (playableBalance === undefined) {
        return null;
    }

    return <Balance>{appPlayersState.usersState.money(new Amount(playableBalance))}</Balance>;
});

export const BalanceLinkNav = observer('BalanceLinkNav', () => {
    const { localStorageState } = useAppStateContext();
    const showBalanceInHeader = localStorageState.balanceInHeader.getValue();

    const account: RightHandSideViewType = { account: 'top-up', amount: null };

    return (
        <BalanceWrapper
            dataTest='account-navigation-balance'
            routeNameForGtm='top-up'
            to={account}
        >
            <BalanceWrapperTitle>
                <I18n
                    langKey='account.navigation.balance'
                    defaultText='Balance'
                />
            </BalanceWrapperTitle>
            {showBalanceInHeader === true ? (
                <BalanceValue />
            ) : (
                <I18n
                    langKey='account.navigation.balance.hidden'
                    defaultText='Hidden'
                />
            )}
        </BalanceWrapper>
    );
});
