import * as React from 'react';

import styled from '@emotion/styled';

import { observer } from 'src/utils/mobx-react';



const SvgElement = styled('svg', { label: 'SvgElement' })`

    height: auto;

`;



interface LoadingSpinnerIconPropsType {

    className?: string;

    onClick?: () => void;

}



export const LoadingSpinnerIcon = observer(

    'LoadingSpinnerIcon',

    ({ className, onClick }: LoadingSpinnerIconPropsType): JSX.Element => {

        return (

            <SvgElement

                className={className}

                clipRule='evenodd'

                fillRule='evenodd'

                height='18'

                onClick={onClick}

                strokeLinejoin='round'

                strokeMiterlimit='2'

                viewBox='0 0 18 18'

                width='18'

                xmlns='http://www.w3.org/2000/svg'

            >

                <path

                    d='M8.252 1.636h.753a7.339 7.339 0 015.205 2.16 7.34 7.34 0 012.154 5.208 7.341 7.341 0 01-2.16 5.205 7.347 7.347 0 01-5.208 2.155 7.335 7.335 0 01-5.205-2.16 7.345 7.345 0 01-2.155-5.208C1.636 8.977 0 8.977 0 8.995a8.977 8.977 0 002.632 6.366A8.977 8.977 0 008.995 18a8.977 8.977 0 006.366-2.632A8.97 8.97 0 0018 9.006a8.981 8.981 0 00-2.632-6.366A8.976 8.976 0 009.005 0h-.753v1.636'

                    fillRule='nonzero'

                />

            </SvgElement>

        );

    }

);

