import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { RacesSlider } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSlider';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SectionHeader } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader';
import { MainSectionHR, MainSectionWrapper } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader.style';
import { PrimaryHeaderLoader } from 'src/domains/layouts/shared/loaders';
import { useAsObservableSource } from 'mobx-react-lite';
import { RacesSliderListState } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSliderList.state';

export const HomepageContentTabs = observer('HomepageContentTabs', () => {
    const appState = useAppStateContext();
    const racesSliderState = appState.appSportsBookState.raceSlider.get('homePageWidget');
    const sliderProps = useAsObservableSource({ events: racesSliderState.eventsCollection.eventsForView, sport: racesSliderState.nextOffWidgetSport });
    const [racesSliderListState] = React.useState(() => new RacesSliderListState(appState, sliderProps));

    const renderContent = (sport: string): JSX.Element | null => {
        switch (sport) {
            case 'horseracing':
                return (
                    <RacesSlider
                        key='next-off'
                        sport='horseracing'
                        mainPage={true}
                        isRacingToggle={true}
                        racesSliderState={racesSliderState}
                        racesSliderListState={racesSliderListState}
                    />
                );
            case 'greyhoundracing':
                return (
                    <RacesSlider
                        key='next-off'
                        sport='greyhoundracing'
                        mainPage={true}
                        isRacingToggle={true}
                        racesSliderState={racesSliderState}
                        racesSliderListState={racesSliderListState}
                    />
                );
        }
        return null;
    };
    return (
        <>
            <MainSectionWrapper>
                {racesSliderState.eventsCollection.eventsForView === 'loading' ?
                    <PrimaryHeaderLoader size='small' /> :
                    <SectionHeader
                        title={<I18n langKey='special-box.header.next-off' defaultText='Next off' />}
                        isOpen={racesSliderState.isOpen}
                        handleOnToggle={racesSliderState.onToggle}
                    />
                }
                {racesSliderState.isOpen ? renderContent(racesSliderState.nextOffWidgetSport) : null}
            </MainSectionWrapper>

            {racesSliderState.isOpen ? null : <MainSectionHR />}
        </>
    );
});
