import { computed, observable, makeObservable } from 'mobx';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { Session } from 'src_common/sdk/session';
import { BetInBetHistoryType, LifeSpanSocketConnectionState, UpdateBetslipMessageAvailableType, UserRewardsMessageAvailableType } from './LifespanSocketConnectionState';
import { Amount } from 'src_common/common/amount/Amount';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { SocketConnection, SocketConnectionController, SocketEventType } from './SocketConnection';

const getLifespanInformation = (configComponents: ConfigComponents): string | null => {
    if (configComponents.config.lifeSpanActive === false) {
        return null;
    }
    return configComponents.config.lifeSpanSocketUrls;
};

export interface BetsBoostTypes {
    reference:string;
    sport:string;
    competition:string;
    event:string;
    market:string | void;
    selection:string;
    decimalOdds:number | undefined;
    amount:number;
    currency: string;
    startTime: number;
}

type MessageTo = unknown

export class SelectionBoostState {
    @observable public selectionInfo: UpdateBetslipMessageAvailableType | null;

    public constructor() {
        makeObservable(this);
        this.selectionInfo = null;
    }
}

export class BetInfoBetHistory {
    @observable public betInfo: BetInBetHistoryType | null;

    public constructor() {
        makeObservable(this);
        this.betInfo = null;
    }
}

export class MyBetsBetHistoryState {
    private readonly isBetBoosted: MobxValue<BetInBetHistoryType | null>;

    public constructor(betId: string, socketController: SocketConnectionController<MessageTo>) {

        this.isBetBoosted = MobxValue.create<BetInBetHistoryType | null, void>({
            initValue: null,
            connect: {
                connect: (): void => {
                    socketController.send({
                        'action':'bets',
                        'data':{
                            'bets':[betId]
                        }
                    });
                },
                dispose: (): void => {}
            }
        });
    }

    public setValue(value: BetInBetHistoryType): void {
        this.isBetBoosted.setValue(value);
    }

    public get betInfo(): BetInBetHistoryType | null {
        return this.isBetBoosted.getValue();
    }
}

export class LifeSpanSocketState {
    private readonly socket: MobxValue<LifeSpanSocketConnectionState | null>;

    public constructor(
        session: Session,
        public readonly configComponents: ConfigComponents,
        googleTagManager: GoogleTagManagerState,
    ) {
        makeObservable(this);
        const isLifepsanAvailable = getLifespanInformation(configComponents);
        if (isLifepsanAvailable === null) {
            this.socket = MobxValue.create<LifeSpanSocketConnectionState | null, unknown>({
                initValue: null
            });
        } else {
            this.socket = MobxValue.create({
                initValue: null,
                connect: {
                    connect(self: MobxValue<LifeSpanSocketConnectionState | null>): LifeSpanSocketConnectionState {
                        const controller = new LifeSpanSocketConnectionState(session, SocketConnection.startSocket(
                            'Lifespan',
                            isLifepsanAvailable,
                            10000,
                            (event: SocketEventType<MessageTo>): void => {
                                controller.onSocketMessageCallback(event);
                            },
                        ), googleTagManager);

                        self.setValue(controller);
                        return controller;
                    },
                    dispose(controller: LifeSpanSocketConnectionState, self: MobxValue<LifeSpanSocketConnectionState | null>): void {
                        controller.socketController.dispose();
                        self.setValue(null);
                    }
                }
            });
        }
    }

    @computed public get userRewardsMessage(): Array<UserRewardsMessageAvailableType> {
        const connection = this.socket.getValue();

        if (connection === null) {
            return [];
        }

        return connection.userRewardsMessage;
    }

    public isSelectionsHasBoost = (bets: BetsBoostTypes[]): void => {
        const connection = this.socket.getValue();
        if (connection === null) {
            return;
        }

        connection.isSelectionsHasBoost(bets);
    };

    public activeBoost = (selectionWithBoost: UpdateBetslipMessageAvailableType): void => {
        const connection = this.socket.getValue();

        if (connection === null) {
            return;
        }

        connection.activeBoost(selectionWithBoost);
    };

    public deactivationBoost = (selectionWithBoost: UpdateBetslipMessageAvailableType): void => {
        const connection = this.socket.getValue();

        if (connection === null) {
            return;
        }

        connection.deactivationBoost(selectionWithBoost);
    };

    public selectionsWithAvailableBoosts(legId: string | undefined): SelectionBoostState | null {
        const connection = this.socket.getValue();

        if (connection === null || legId === undefined) {
            return null;
        }

        return connection.availableSelections.get(legId);
    }

    public myBetsBetHistory(legId: string): BetInfoBetHistory | null {
        const connection = this.socket.getValue();

        if (connection === null) {
            return null;
        }

        return connection.myBetsBetHistory.get(legId);
    }


    public messageWithEmptyBetslip(): void {
        const connection = this.socket.getValue();

        if (connection === null) {
            return;
        }

        connection.messageWithEmptyBetslip();
    }

    public isMyBetIsBoosted(betId: string): void {
        const connection = this.socket.getValue();

        if (connection === null) {
            return;
        }

        return connection.isMyBetIsBoosted(betId);
    }

    public get rewardValue(): Amount {
        const connection = this.socket.getValue();

        if (connection === null) {
            return new Amount('0');
        }

        return this.configComponents.precision.newFromAnything(connection.rewardValue);
    }

    public get rewardValueForBetslipReceipt(): Amount {
        const connection = this.socket.getValue();

        if (connection === null) {
            return new Amount('0');
        }

        return this.configComponents.precision.newFromAnything(connection.rewardValueForBetslipReceipt);
    }

    public buttonIsLoading(selectionId: string): boolean {
        const connection = this.socket.getValue();

        if (connection === null) {
            return false;
        }

        return connection.selectionButton.get(selectionId).isLoading;
    }
}
