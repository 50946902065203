import { computed, action, makeObservable } from 'mobx';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';

export class CookiesPolicyBoxState {
    public ref: HtmlElementReactive<HTMLElement>;

    public constructor(private readonly localStorageItem: LocalStorageItemState<boolean>) {
        makeObservable(this);
        this.ref = new HtmlElementReactive(500);
    }

    @action public setRef = (ref: HTMLElement | null): void => {
        this.ref.setRef(ref);
    };

    @computed public get boxHeight(): number {
        const element: HTMLElement | null = this.ref.ref;
        return element?.clientHeight ?? 0;
    }

    @computed public get isAccepted(): boolean {
        return this.localStorageItem.getValue();
    }

    @action public acceptPrivacyPolicy = (): void => {
        this.localStorageItem.setValue(true);
    }
}
