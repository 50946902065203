import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { LazyLoadingImgState } from './LazyLoadingImg.state';
import { deviceSize } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import {
    Image,
    ImageRaw,
    ImageWrapper,
} from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg.style';
import { FormatType, ImageOptimizeOptions } from 'src_common/common/imageOptimization';
import { useAppStateContext } from 'src/appState/AppState';

export type ImageFitType = 'fill' | 'cover' | 'scale-down' | 'contain' | 'none';

type ImageType = {
    src: string;
    optimizeOptions?: ImageOptimizeOptions;
};

type ImageDeviceType = {
    src: string;
    quality?: number;
};

type ImageDeviceSetType = {
    /** @description max-width 1024px */
    desktop?: ImageDeviceType;
    /** @description max-width 768px */
    tablet?: ImageDeviceType;
    /** @description max-width 415px */
    mobile?: ImageDeviceType;
};

interface RenderSourcePropsType {
    imgSrc: string;
    format?: FormatType;
    mediaMaxWidth?: number;
}

const RenderSource = (props: RenderSourcePropsType): ReactElement => {
    const { imgSrc, mediaMaxWidth, format } = props;

    return (
        <source
            srcSet={imgSrc}
            type={format === undefined ? undefined : `image/${format}`}
            media={mediaMaxWidth === undefined ? undefined : `(max-width: ${mediaMaxWidth}px)`}
        />
    );
};

const isImageRelative = (src: string): boolean => {
    return src[0] === '/';
};

interface RenderOptimizedVariantsPropsType {
    img: ImageType;
    formats: Array<FormatType>;
    mediaMaxWidth?: number;
}

const RenderOptimizedVariants = (props: RenderOptimizedVariantsPropsType): ReactElement | null => {
    const { appLayoutsState } = useAppStateContext();

    const { img, mediaMaxWidth } = props;
    const { src, optimizeOptions } = img;

    if (isImageRelative(src)) {
        return null;
    }

    return (
        <>
            {props.formats.map((format, idx) => {
                const optimizedSrc = appLayoutsState.optimizeImage(src, format, optimizeOptions);

                if (optimizedSrc === null) {
                    return null;
                }

                return <RenderSource imgSrc={optimizedSrc} format={format} mediaMaxWidth={mediaMaxWidth} key={idx} />;
            })}
        </>
    );
};

interface SourceSetPropsType {
    imgDeviceSet?: ImageDeviceSetType;
}

const DeviceSourceSet = (props: SourceSetPropsType): ReactElement => {
    const { imgDeviceSet } = props;

    return (
        <>
            {imgDeviceSet?.mobile === undefined ? null : (
                <>
                    <RenderOptimizedVariants
                        img={{
                            src: imgDeviceSet.mobile.src,
                            optimizeOptions: { width: deviceSize.mobile, quality: imgDeviceSet.mobile.quality },
                        }}
                        mediaMaxWidth={deviceSize.mobile}
                        formats={['avif', 'webp', 'jpeg']}
                    />
                    <RenderSource imgSrc={imgDeviceSet.mobile.src} mediaMaxWidth={deviceSize.mobile} />
                </>
            )}
            {imgDeviceSet?.tablet === undefined ? null : (
                <>
                    <RenderOptimizedVariants
                        img={{
                            src: imgDeviceSet.tablet.src,
                            optimizeOptions: { width: deviceSize.tablet, quality: imgDeviceSet.tablet.quality },
                        }}
                        mediaMaxWidth={deviceSize.tablet}
                        formats={['avif', 'webp', 'jpeg']}
                    />
                    <RenderSource imgSrc={imgDeviceSet.tablet.src} mediaMaxWidth={deviceSize.tablet} />
                </>
            )}
            {imgDeviceSet?.desktop === undefined ? null : (
                <>
                    <RenderOptimizedVariants
                        img={{
                            src: imgDeviceSet.desktop.src,
                            optimizeOptions: { width: deviceSize.desktop, quality: imgDeviceSet.desktop.quality },
                        }}
                        mediaMaxWidth={deviceSize.desktop}
                        formats={['avif', 'webp', 'jpeg']}
                    />
                    <RenderSource imgSrc={imgDeviceSet.desktop.src} mediaMaxWidth={deviceSize.desktop} />
                </>
            )}
        </>
    );
};

interface LazyLoadingImgPropsType {
    alt?: string;
    className?: string;
    imgFit?: ImageFitType;
    imgBase: ImageType;
    imgDeviceSet?: ImageDeviceSetType;
    onClick?: () => void;
    placeholder?: boolean;
}

export const LazyLoadingImg = observer('LazyLoadingImg', (props: LazyLoadingImgPropsType) => {
    const { alt, className, imgFit, imgBase, imgDeviceSet, onClick, placeholder } = props;
    const [state] = React.useState(() => new LazyLoadingImgState());

    if (placeholder === true) {
        return (
            <ImageWrapper className={className} isLoaded={state.allowShow} onClick={onClick}>
                <picture>
                    <DeviceSourceSet imgDeviceSet={imgDeviceSet} />
                    <RenderOptimizedVariants img={imgBase} formats={['avif', 'webp', 'jpeg']} />
                    <Image
                        allowShow={state.allowShow}
                        alt={alt}
                        decoding='async'
                        imgFit={imgFit}
                        loading='lazy'
                        onLoad={state.onLoad}
                        src={imgBase.src}
                    />
                </picture>
            </ImageWrapper>
        );
    }

    return (
        <picture>
            <DeviceSourceSet imgDeviceSet={imgDeviceSet} />
            <RenderOptimizedVariants img={imgBase} formats={['avif', 'webp', 'jpeg']} />
            <ImageRaw
                className={className}
                onClick={onClick}
                allowShow={state.allowShow}
                alt={alt}
                decoding='async'
                imgFit={imgFit}
                loading='lazy'
                onLoad={state.onLoad}
                src={imgBase.src}
            />
        </picture>
    );
});
