import { computed, observable, action, makeObservable } from 'mobx';
import { EventsGroupView, EventsGroupViewSport, EventsGroupViewCompetition } from './EventsGroupView';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { UserViewType, calculateNextUserView } from './calculateNextUserView';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { sortByEventStart, SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';

export class UserViewSport {
    public constructor(
        private readonly parent: UserView,
        private readonly getUserView: () => UserViewType,
        private readonly sportId: string,
    ) {
        makeObservable(this);
    }

    @computed public get visible(): boolean {
        const userView = this.getUserView();
        return userView.sports[this.sportId] ?? false;
    }

    public toggle = (): void => {
        this.parent.toggleSport(this.sportId);
    };
}

export class UserViewCompetition {
    public constructor(
        private readonly parent: UserView,
        private readonly getUserView: () => UserViewType,
        private readonly competitionId: number,
    ) {
        makeObservable(this);
    }

    @computed public get visible(): boolean {
        const userView = this.getUserView();
        return userView.competitions[this.competitionId] ?? false;
    }

    public toggle = (): void => {
        this.parent.toggleCompetition(this.competitionId);
    };
}

class UserView {
    @observable.ref public userView: UserViewType | null = null;
    public readonly sports: MobxMapAutoNew<string, UserViewSport>;
    public readonly competition: MobxMapAutoNew<number, UserViewCompetition>;
    public readonly defaultOpen: 'all-open' | number;
    public readonly specialSports: SpecialSportsState | null;

    public constructor(defaultOpen: 'all-open' | number, private readonly getEventsCollection: EventsCollectionQueryModel | null, specialSports: SpecialSportsState | null) {
        makeObservable(this);
        this.userView = null;
        this.defaultOpen = defaultOpen;
        this.specialSports = specialSports;

        this.sports = new MobxMapAutoNew(
            (sportId: string) => new UserViewSport(this, this.getUserView, sportId)
        );

        this.competition = new MobxMapAutoNew(
            (competitionId: number) => new UserViewCompetition(this, this.getUserView, competitionId)
        );
    }

    @computed private get nextUserView(): UserViewType {
        const isSpecial = this.specialSports?.isSpecial === true;
        return calculateNextUserView(this.userView, this.getEventsCollection, this.defaultOpen, isSpecial);
    }

    private getUserView = (): UserViewType => {
        return this.nextUserView;
    };

    public sportView(sportId: string): UserViewSport {
        return this.sports.get(sportId);
    }

    public competitionView(competitionId: number): UserViewCompetition {
        return this.competition.get(competitionId);
    }


    @action public toggleSport = (id: string): void => {
        const userView = this.nextUserView;

        this.userView = {
            sports: {
                ...userView.sports,
                [id]: !(userView.sports[id] ?? false),
            },
            competitions: userView.competitions,
        };
    };

    @action public toggleCompetition = (id: number): void => {
        const userView = this.nextUserView;

        this.userView = {
            sports: userView.sports,
            competitions: {
                ...userView.competitions,
                [id]: !(userView.competitions[id] ?? false),
            },
        };
    };
}

export class EventsGroupState {
    private readonly defaultOpen: 'all-open' | number;
    public readonly getTranslation: (key: string, defaultText: string) => string;
    public readonly specialSports: SpecialSportsState | null;

    private readonly userViewState: UserView;

    public constructor(
        defaultOpen: 'all-open' | number,
        getTranslation: (key: string, defaultText: string) => string,
        private readonly eventsCollection: EventsCollectionQueryModel | null,
        specialSports: SpecialSportsState | null
    ) {
        makeObservable(this);
        this.defaultOpen = defaultOpen;
        this.getTranslation = getTranslation;
        this.specialSports = specialSports;

        this.userViewState = new UserView(this.defaultOpen, this.eventsCollection, specialSports);
    }

    @computed public get listToView(): EventsGroupView {
        const eventsCollection = this.eventsCollection ?? { value: {
            sports: []
        } };


        return new EventsGroupView(eventsCollection.value.sports.map(sport => {
            return new EventsGroupViewSport(
                sport.id,
                this.userViewState.sportView(sport.id),
                sport.competitions.map(competition => {
                    return new EventsGroupViewCompetition(
                        this,
                        sport.id,
                        competition.name,
                        competition.id,
                        this.userViewState.competitionView(competition.id),
                        competition.events
                    );
                }),
            );
        }));
    }

    @computed private get allCompetitions(): Array<{sport: string; name: string; id: number; events: Array<EventListGroupEventItemType>}> {
        const temp: Array<{sport: string; name: string; id: number; events: Array<EventListGroupEventItemType>}> = [];
        const eventsCollection = this.eventsCollection ?? { value: {
            sports: []
        } };
        for (const sport of eventsCollection.value.sports) {
            for (const competition of sport.competitions) {
                temp.push({
                    sport: sport.id,
                    name: competition.name,
                    id: competition.id,
                    events: competition.events,
                });
            }
        }
        return temp;
    }

    @computed private get allCompetitionsSortedByTime(): Array<{sport: string; name: string; id: number; events: Array<EventListGroupEventItemType>}> {
        return this.allCompetitions.sort(sortByEventStart);
    }

    @computed public get specialSportListToView(): Array<EventsGroupViewCompetition> {
        return this.allCompetitionsSortedByTime.map(competition => {
            return new EventsGroupViewCompetition(
                this,
                competition.sport,
                competition.name,
                competition.id,
                this.userViewState.competitionView(competition.id),
                competition.events
            );
        });
    }
}
