import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { DepositFailure } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { PaysafeTabState } from './PaysafeTab.state';
import { PaysafeTab } from './PaysafeTab';
import { PaysafeFrame } from './paysafeFrame/PaysafeFrame';
import { assertNever } from 'src_common/common/assertNever';
import { PaysafePaymentProviderType } from 'src/domains/layouts/config/features/types';
import { DepositFinalView } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';

interface PropsType {
    isSignup: boolean;
    paysafePaymentProvider: PaysafePaymentProviderType;
}

export const PaysafeJourney = observer(
    'PaysafeJourney',
    ({ isSignup, paysafePaymentProvider }: PropsType): JSX.Element => {
        const { appLayoutsState, appPlayersState, trpcClient } = useAppStateContext();
        const { configComponents, googleTagManager, languagesState } = appLayoutsState;
        const { accountHelperMail, minDepositAmount, operatorFullName } = configComponents.config;
        const { usersState } = appPlayersState;

        const [state] = useState(
            () =>
                new PaysafeTabState(
                    usersState,
                    googleTagManager,
                    isSignup,
                    languagesState,
                    minDepositAmount,
                    configComponents.precision,
                    trpcClient,
                    operatorFullName,
                    paysafePaymentProvider
                )
        );

        const renderContent = (): JSX.Element => {
            switch (state.stepsState.step.type) {
                case 'set-method':
                    return <PaysafeTab state={state} />;
                case 'set-method-signup':
                    return <PaysafeTab state={state} />;
                case 'iframe-view':
                    return (
                        <PaysafeFrame
                            paymentIframeData={state.stepsState.step.paymentIframeData}
                            paysafeTabState={state}
                            paysafePaymentProvider={paysafePaymentProvider}
                        />
                    );
                case 'final-view':
                    return (
                        <DepositFinalView
                            transactionId={state.stepsState.step.transactionId}
                            isSignup={isSignup}
                            accountHelperMail={accountHelperMail}
                            transactionCallback={state.transactionCallback}
                            hideDepositSuccess={state.hideDepositSuccess}
                        />
                    );
                case 'failure-view':
                    return (
                        <DepositFailure
                            isSignup={isSignup}
                            issueType={state.stepsState.step.failureType}
                            accountHelperMail={accountHelperMail}
                            transactionCallback={state.stepsState.step.transactionCallback}
                            hideDepositSuccess={state.stepsState.redirectToSetMethod}
                        />
                    );
                default:
                    return assertNever('PaysafeJourney step', state.stepsState.step);
            }
        };

        return <>{renderContent()}</>;
    }
);
