import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    SaveButton,
    Description,
    Question,
    QuestionsList,
    QuestionTitle,
    From
} from 'src/domains/players/webview/components/Account/preferencesTab/MarketingQuestionsForm.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Select } from 'src/domains/layouts/webview/components/form/select/Select';
import { observer } from 'src/utils/mobx-react';
import { MarketingQuestionsState, QuestionModel } from 'src/domains/players/state/MarketingQuestionsState';

interface MarketingQuestionsItemPropsType {
    question: QuestionModel;
    marketingQuestionsState: MarketingQuestionsState;
}

const MarketingQuestionsItem = observer('MarketingQuestionsItem', (props: MarketingQuestionsItemPropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;

    const { question } = props;

    return <Question>
        <QuestionTitle data-test='question-title'>{question.name}</QuestionTitle>
        <Select
            name='question-options'
            placeholder={languagesState.getTranslation('account.contact-preferences.marketing-select.label', 'Please select...')}
            hasEmptyOption={true}
            options={question.selectOptions}
            disabled={question.hasAnswer}
            showIndicator={!question.hasAnswer}
            state={question.selectState}
            dataTest='select'
        />
    </Question>;
});

interface PropsType {
    marketingQuestionsState: MarketingQuestionsState;
}

const preventDefault = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
};

export const MarketingQuestionsForm = observer('MarketingQuestionsForm', ({ marketingQuestionsState }: PropsType) => {
    return (
        <From onSubmit={preventDefault} data-test='marketing-questions-form'>
            <Description data-test='description'>
                <I18n langKey='account.contact-preferences-tab.marketing-desc' defaultText='We will give you some special offers based on your preferences if we are able to contact you. If you need to change an answer you have to contact customer service.' />
            </Description>
            <QuestionsList>
                {marketingQuestionsState.allActiveQuestions.map((question) => (
                    <MarketingQuestionsItem
                        question={question}
                        marketingQuestionsState={marketingQuestionsState}
                        key={question.id}
                    />
                ))}
            </QuestionsList>
            { marketingQuestionsState.isSaveButtonDisplayed === true ? (
                <SaveButton
                    size='large'
                    disabled={!marketingQuestionsState.isSaveButtonEnabled}
                    type='submit'
                    onClick={marketingQuestionsState.updateQuestions}
                    dataTest='save-button'
                >
                    <I18n langKey='account.contact-preferences.marketing-save.label' defaultText='Save' />
                </SaveButton>
            ) : null }
            
        </From>
    );
});
