import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fadeIn, fadeOut } from 'src/domains/layouts/shared/Animation.style';
import { ScrollLeftButton, ScrollRightButton } from 'src/domains/layouts/shared/ScrollButtons.style';

const setScrollButton = css`
    bottom: 0;
    margin: auto 0;
    top: 0;
`;

interface ScrollButton {
    isVisible?: boolean;
}

export const ScrollLeft = styled(ScrollLeftButton, { label: 'ScrollLeft' })<ScrollButton>`
    ${setScrollButton};
    animation: ${({ isVisible }): string => isVisible === true ? fadeIn : fadeOut} 0.5s ease;
    left: 16px;
`;

export const ScrollRight = styled(ScrollRightButton, { label: 'ScrollRight' })<ScrollButton>`
    ${setScrollButton};
    animation: ${({ isVisible }): string => isVisible === true ? fadeIn : fadeOut} 0.5s ease;
    right: 40px;
`;
