import React, { useState } from 'react';
/* eslint-disable */
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import { TomorrowLabel } from 'src/domains/sportsbook/webview/components/raceCard/tabs/TomorrowLabel';
import {
    FilterItem,
    TabsContent,
    TabsSection,
    TabsUl,
} from 'src/domains/sportsbook/webview/components/raceCard/tabs/Tabs.style';

export interface TabItemType {
    key: string | number;
    label: React.ReactNode;
    labelDisabled: boolean;
    content: React.ReactElement;
    isActive: () => boolean;
    setActive: () => void;
    isFinished: boolean;
}

interface SwitcherPropsType {
    items: Array<TabItemType>;
}

const Switcher = observer('Switcher', (props: SwitcherPropsType): React.ReactElement | null => {
    const { items } = props;
    for (const x of items) {
        if (x.isActive()) {
            return x.content;
        }
    }

    return null;
});

class TabsItemState {
    public constructor(private readonly props: TabsItemPropsType) {}

    public setActive = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.item.labelDisabled) {
            return;
        }

        this.props.item.setActive();
    };
}

interface TabsItemPropsType {
    item: TabItemType;
}

export const TabsItem = observer('TabsItem', (propsIn: TabsItemPropsType): JSX.Element => {
    const props = useAsObservableSource(propsIn);
    const [state] = useState(() => new TabsItemState(props));

    const { item } = props;
    const isActive = item.isActive();
    const isFinished = item.isFinished;

    return (
        <FilterItem
            key={item.key}
            onClick={state.setActive}
            isActive={isActive}
            data-is-active={isActive}
            isFinished={isFinished}
            data-test-filter-item-key={item.key}
        >
            {item.label}
        </FilterItem>
    );
});

interface TabsPurePropsType {
    className?: string;
    items: Array<TabItemType>;
    setRefMenu?: (item: HTMLElement | null) => void;
}

class TabsPureState {
    public constructor(private readonly props: TabsPurePropsType) {}

    public setRefMenu = (item: HTMLElement | null): void => {
        const { setRefMenu } = this.props;
        if (setRefMenu !== undefined) {
            setRefMenu(item);
        }
    };
}

export const Tabs = observer('Tabs', (propsIn: TabsPurePropsType) => {
    const props = useAsObservableSource(propsIn);
    const [state] = React.useState(() => new TabsPureState(props));
    const { items } = props;

    return (
        <TabsSection>
            <TabsUl
                key='tabs_ul'
                ref={state.setRefMenu}
            >
                {items.map((tab) => {
                    if (tab.key === 'tomorrow') {
                        return <TomorrowLabel key={tab.key} />;
                    }
                    if (tab.key === 'thisWeek') {
                        return (
                            <TomorrowLabel
                                key={tab.key}
                                labelText='this-week'
                            />
                        );
                    }
                    return (
                        <TabsItem
                            key={tab.key}
                            item={tab}
                        />
                    );
                })}
            </TabsUl>
            <TabsContent key='tabs-wrapper--content'>
                <Switcher items={items} />
            </TabsContent>
        </TabsSection>
    );
});
