import * as t from 'io-ts';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

const ModernizrDetectIO = t.interface({
    flexbox: t.boolean,
    flexwrap: t.boolean,
    templatestrings: t.boolean,
    touchevents: t.boolean,
    websockets: t.boolean,
});

export type ModernizrDetectType = t.TypeOf<typeof ModernizrDetectIO>;
export const decodeModernizrDetect = buildValidator('ModernizrDetectIO', ModernizrDetectIO, true);
