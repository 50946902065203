import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    DepositLimitsPopupItemState,
    DepositLimitsPopupState,
    DepositLimitType,
} from 'src/domains/players/webview/components/DepositLimitPopup/DepositLimitsPopup.state';
import { Popup } from 'src/domains/players/webview/ui/Popup';
import {
    DepositLimitPopupBtn,
    DepositLimitPopupBtnWrapper,
    DepositLimitPopupDescription,
    DepositLimitPopupHeader,
    DepositLimitPopupInput,
    DepositLimitPopupPreviousSpan,
} from './DepositLimitPopup/DepositLimitPopup.style';
import { useAppStateContext } from 'src/appState/AppState';

interface DepositLimitPopupItemPropsType {
    state: DepositLimitsPopupItemState;
    label: string;
}

const DepositLimitPopupItem = observer(
    'DepositLimitPopupItem',
    (props: DepositLimitPopupItemPropsType): JSX.Element | null => {
        const {
            appPlayersState: { usersState },
        } = useAppStateContext();
        const { state, label } = props;
        const isNewLimit = state.pending !== undefined && state.limitExceededDeadline;

        return (
            <div>
                <DepositLimitPopupInput
                    type='text'
                    currency={usersState.moneySymbol}
                    maxLength={10}
                    label={
                        <I18n
                            langKey='account.deposit-limit-popup.new-limit.label'
                            defaultText='New {label} Limit'
                            params={{ label }}
                        />
                    }
                    state={state.returnActiveState}
                    placeholder={isNewLimit ? state.pending.value : state.active?.value}
                    colorTheme='light'
                    isDisabled={true}
                    isNewLimit={isNewLimit}
                />
                {isNewLimit && (
                    <DepositLimitPopupPreviousSpan>
                        <I18n
                            langKey='account.deposit-limit-popup.previous-limit.label'
                            defaultText='Previous limit  {active}'
                            params={{ active: state.active?.value ?? '' }}
                        />
                    </DepositLimitPopupPreviousSpan>
                )}
            </div>
        );
    }
);

export const DepositLimitPopup = observer('DepositLimitPopup', (): JSX.Element | null => {
    const appState = useAppStateContext();
    const { languagesState } = appState.appLayoutsState;
    const [state] = useState(() => new DepositLimitsPopupState(appState));
    const labelDaily = languagesState.getTranslation('account.deposit-limit-popup.label.daily', 'Daily');
    const labelWeekly = languagesState.getTranslation('account.deposit-limit-popup.label.weekly', 'Weekly');
    const labelMonthly = languagesState.getTranslation('account.deposit-limit-popup.label.monthly', 'Monthly');

    if (state.isActive === false) {
        return null;
    }

    return (
        <Popup
            title={
                <DepositLimitPopupHeader>
                    <I18n
                        langKey='account.deposit-limit-popup.title'
                        defaultText='Deposit limits'
                    />
                </DepositLimitPopupHeader>
            }
            closeButton={false}
            maxWidth='290px'
        >
            <div>
                <DepositLimitPopupDescription>
                    <I18n
                        langKey='account.deposit-limit-popup.description'
                        defaultText='Last time you were here you made some changes to your deposit limit for your account. Please confirm these changes before continuing.'
                    />
                </DepositLimitPopupDescription>

                <DepositLimitPopupItem
                    label={labelDaily}
                    state={state.daily}
                />
                <DepositLimitPopupItem
                    label={labelWeekly}
                    state={state.weekly}
                />
                <DepositLimitPopupItem
                    label={labelMonthly}
                    state={state.monthly}
                />
                <DepositLimitPopupBtnWrapper>
                    <DepositLimitPopupBtn
                        onClick={state.onConfirm}
                        size='medium'
                    >
                        <I18n
                            langKey='account.deposit-limit-popup.confirm-button.label'
                            defaultText='Accept New limit'
                        />
                    </DepositLimitPopupBtn>
                    <DepositLimitPopupBtn
                        onClick={state.onAmend}
                        size='medium'
                        version='secondary-light'
                    >
                        <I18n
                            langKey='account.deposit-limit-popup.amend-button.label'
                            defaultText='No, Revert Back to Previous'
                        />
                    </DepositLimitPopupBtn>
                </DepositLimitPopupBtnWrapper>
            </div>
        </Popup>
    );
});

export { DepositLimitType };
