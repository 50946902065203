import * as t from 'io-ts';
import { SubscriptionResourceUpdateIO } from './subscriptionId';
import { createGuard } from 'src_common/common/createGuard';

const MessagePongIO = t.interface({
    type: t.literal('Pong')
});

export const isMessagePong = createGuard(MessagePongIO);

const MessageUpdateIO = t.interface({
    type: t.literal('Update'),
    item: SubscriptionResourceUpdateIO
});

export const isMessageUpdate = createGuard(MessageUpdateIO);

const MessageMessageIO = t.interface({
    type: t.literal('Message'),
    text: t.string
});

export const isMessageMessage = createGuard(MessageMessageIO);
