import { makeObservable, observable, action } from 'mobx';

export class LazyLoadingImgState {
    @observable public allowShow: boolean = false;

    public constructor() {
        makeObservable(this);
    }

    @action public onLoad = (): void => {
        this.allowShow = true;
    };
}
