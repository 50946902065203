import { Value } from 'src_common/common/mobx-utils/Value';

class SelectionAnimationFrame {
    public priceChange: Value<null | 'up' | 'down'> = new Value<null | 'up' | 'down'>(null);

    public constructor(starting: 'up' | 'down', timeout: number) {
        this.priceChange.setValue(starting);

        setTimeout(() => {
            this.priceChange.setValue(null);
        }, timeout);
    }
}

export class SelectionAnimation {
    public readonly timeout: number;
    public animation: Value<SelectionAnimationFrame | null>;

    public constructor(timeout: number) {
        this.timeout = timeout;
        this.animation = new Value<SelectionAnimationFrame | null>(null);
    }

    public startAnimation(direction: 'up' | 'down'): void {
        this.animation.setValue(new SelectionAnimationFrame(direction, this.timeout));
    }

    public get priceChange(): null | 'up' | 'down' {
        const animation = this.animation.getValue();
    
        if (animation !== null) {
            return animation.priceChange.getValue();
        }

        return null;
    }
}
