import { action, computed, makeObservable } from 'mobx';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';


export class BannersBoxState {
    public ref: HtmlElementReactive<HTMLElement>;

    public constructor() {
        makeObservable(this);
        this.ref = new HtmlElementReactive(500);
    }

    @action public setRef = (ref: HTMLElement | null): void => {
        this.ref.setRef(ref);
    };

    @computed public get boxHeight(): number {
        const element: HTMLElement | null = this.ref.ref;
        return element?.clientHeight ?? 0;
    }

}
