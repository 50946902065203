import { getErrorByCode, MessagesKeyType } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { Amount } from 'src_common/common/amount/Amount';
import { CurrencySymbolType } from 'src_common/common/amount/website-money/currency';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const validateMinAmount =
    (minValue: Amount, currencySymbol: () => CurrencySymbolType, errorCode: MessagesKeyType) =>
    (amount: Amount): Result<Amount> => {
        if (amount.isLessThan(minValue)) {
            return Result.createError(
                getErrorByCode(errorCode)
                    .replace('%currencySymbol', currencySymbol())
                    .replace('%minValue', minValue.value)
            );
        }

        return Result.createOk(amount);
    };

export const validateMinAmountNew =
    (minValue: Amount, errorMessage: string) =>
    (amount: Amount): Result<Amount> => {
        if (amount.isLessThan(minValue)) {
            return Result.createError(errorMessage);
        }

        return Result.createOk(amount);
    };

export const validateMaxAmountNew =
    (maxValue: Amount | null, errorMessage: string) =>
    (amount: Amount): Result<Amount> => {
        if (maxValue === null || amount.isLessThan(maxValue)) {
            return Result.createOk(amount);
        }

        return Result.createError(errorMessage);
    };

export const validateMaxAmount =
    (maxValueFunction: () => Amount, currencySymbol: () => CurrencySymbolType, errorCode: MessagesKeyType) =>
    (amount: Amount): Result<Amount> => {
        const maxValue = maxValueFunction();

        if (amount.isGreaterThan(maxValue)) {
            return Result.createError(
                getErrorByCode(errorCode)
                    .replace('%currencySymbol', currencySymbol())
                    .replace('%maxValue', maxValue.value)
            );
        }

        return Result.createOk(amount);
    };
