/* stylelint-disable */
import { css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';

export const cookieBotWidget = (theme: EmotionTheme): SerializedStyles => css`
    /* NAVIGATION */
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
        color: ${theme.star.cookieBot.bgColor} !important;
        border-bottom: 2px ${theme.star.cookieBot.bgColor} !important;
    }

    #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a, #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink, #CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a, #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a, #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a, #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink, #CybotCookiebotDialogDetailBodyContentTextAbout a, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover, #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
        color: ${theme.star.cookieBot.bgColor} !important;
    }

    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
        color: ${theme.star.cookieBot.bgColor} !important;
    }

    #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
        background-color: ${theme.star.cookieBot.bgColorSecondary} !important;
    }

    /* SWITCH */
    #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.star.cookieBot.bgColor} !important;
    }
    #CybotCookiebotDialogBodyLevelButtonsSelectPane label .CybotCookiebotDialogBodyLevelButtonDescription:hover {
        color: ${theme.star.cookieBot.bgColor} !important;
    }
    #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelWrapper #CybotCookiebotDialogBodyLevelButtonsRow #CybotCookiebotDialogBodyLevelButtonsSelectPane form input[type="checkbox"][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.star.cookieBot.bgColor} !important;
        opacity: .5;
    }

    /* BUTTONS */
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        background-color: ${theme.star.cookieBot.bgColor} !important;
        border-color: ${theme.star.cookieBot.bgColor} !important;
    }

    #CybotCookiebotDialog form input[type=checkbox][disabled]:checked+.CybotCookiebotDialogBodyLevelButtonSlider, #CybotCookiebotDialog form input[type=checkbox]:not(:checked)+.CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.star.cookieBot.bgColorTertiary} !important;
    }

    #CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider:before {
        box-shadow: 0px 0px 4px 0px ${theme.star.cookieBot.bgColorQuaternary};
    }

    /* BORDERS */
    #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type), #CybotCookiebotDialogBody .CybotCookiebotScrollContainer, #CybotCookiebotDialogNav {
        border-color: ${theme.star.cookieBot.bgColorQuinary} !important;
    }

    /* HIDE ELEMENTS */
    #CybotCookiebotDialog #CybotCookiebotDialogPoweredByText, #CybotCookiebotDialogHeader, .CookiebotWidget-main-logo { 
        display: none !important;
    }

    /* COOKIE BOT SETTINGS */
    /* WRAPPER */
    #CookiebotWidget strong {
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    /* NAVIGATION */
    #CookiebotWidget-header *{
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-header .CookiebotWidget-close svg {
        fill: ${theme.star.cookieBot.txtColor} !important; 
    }

    #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
        fill: ${theme.star.cookieBot.bgColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-consent-details button {
        color: ${theme.star.cookieBot.bgColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-consent-details button svg {
        fill: ${theme.star.cookieBot.bgColor} !important;
    }

    /* DETAILS */
    #CookiebotWidget .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box {
        background-color: ${theme.star.cookieBot.bgColorTertiary} !important;
    }
    
    #CookiebotWidget .CookiebotWidget-consent-details strong {
        color: ${theme.star.cookieBot.txtColor} !important;
    }

    #CookiebotWidget .CookiebotWidget-consent-details div {
        color: ${theme.star.cookieBot.txtColor} !important;
    }


    /* BUTTONS */
    #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
        background-color: ${theme.star.cookieBot.bgColor} !important;
        border-color: ${theme.star.cookieBot.bgColor} !important;
    }
    
    #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
        border-color: ${theme.star.cookieBot.bgColor} !important;
        color: ${theme.star.cookieBot.txtColor} !important;
    }
`;
