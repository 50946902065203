import React from 'react';
import { observer } from 'src/utils/mobx-react';

import { PlayableBalanceMessage } from './PlayableBalanceMessage';
// import { RelatedWarningMessage } from './RelatedWarningMessage';
import { SinglesOnlyMessage } from './SinglesOnlyMessage';
import { LegsChangedMessage } from './LegsChangedMessage';
import { ReferredBetMessage } from './ReferredBetMessage';
import { RejectedByTraderMessage } from './RejectedByTraderMessage';
import { RejectedByCustomerMessage } from './RejectedByCustomerMessage';
import { OtherErrorsMessages } from './OtherErrorsMessages';
import { ResponseErrorMessages } from './ResponseErrorMessages';
import { RabSuspendedBetsMessage } from 'src/domains/sportsbook/betting/ui/betSlip/errorAndWarningMessages/RabSuspendedBetsMessage';
import { useAppStateContext } from 'src/appState/AppState';

export const ErrorsAndWarnings = observer('ErrorsAndWarnings', () => {
    const { appSportsBookState: { betSlipState } } = useAppStateContext();
    const { isPlayableBalanceWarning, isSinglesOnlyWarning, isLegsChangedWarning, responseErrorMsg } = betSlipState.betSlipSummaryState;
    const { isReferredBetMessage, isRejectedByTrader, isRejectedByCustomer, isBetSlipOffer } = betSlipState.referralState;
    const { related, relatedOnAdd } = betSlipState.basicBetSlipState;
    const { errors } = betSlipState.basicBetSlipState;
    const areSuspendedBets = betSlipState.rabState.suspendedBets.length > 0;
    const areBetsWithoutOffer = betSlipState.rabState.areBetsWithoutOffer;

    const isAnyError = isPlayableBalanceWarning || related || relatedOnAdd || isSinglesOnlyWarning || isLegsChangedWarning && !isBetSlipOffer || isReferredBetMessage || isRejectedByTrader || isRejectedByCustomer || responseErrorMsg.length > 0 || errors.length > 0 || areSuspendedBets || areBetsWithoutOffer;
    if (isAnyError === true) {
        return (
            <>
                {isPlayableBalanceWarning ? <PlayableBalanceMessage /> : null}
                {/* Temporary hide, do not remove */}
                {/*{related || relatedOnAdd ? <RelatedWarningMessage /> : null}*/}
                {isSinglesOnlyWarning ? <SinglesOnlyMessage /> : null}
                {!isBetSlipOffer && isLegsChangedWarning ? <LegsChangedMessage /> : null}
                {isReferredBetMessage ? <ReferredBetMessage /> : null}
                {isRejectedByTrader ? <RejectedByTraderMessage /> : null}
                {isRejectedByCustomer ? <RejectedByCustomerMessage /> : null}
                {responseErrorMsg.length > 0 ? <ResponseErrorMessages /> : null}
                {errors.length > 0 ? <OtherErrorsMessages /> : null}
                {areSuspendedBets || areBetsWithoutOffer ? <RabSuspendedBetsMessage /> : null}
            </>
        );
    }
    return null;
});
