import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const QuickLinkTrayWrapper = withConfig(theme => styled('nav', { label: 'QuickLinkTrayWrapper' })`
    align-items: center;
    background-color: ${theme.star.quickLinkTray.bgColor};
    box-shadow: inset 0 16px 9px -16px ${theme.star.quickLinkTray.bgColorSecondary};
    display: flex;
    flex-flow: row nowrap;
    height: 65px;
    padding: 0 5px;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
`);

export const SportName = withConfig(theme => styled('span', { label: 'SportName' })`
    display: block;
    color: ${theme.star.quickLinkTray.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.bold};
    text-align: center;
    line-height: 1.2;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75px;
`);

export const NavSportWrapper = styled('div', { label: 'NavSportWrapper' })`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    scroll-snap-align: start;
`;

export const LinkWrapper = styled('div', { label: 'LinkWrapper' })`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    text-decoration: none;
    scroll-snap-align: start;
`;

export const ImageWrapper = styled('div', { label: 'ImageWrapper' })<{ url: string }>`
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background-image: url(${({ url }): string => url});
    background-repeat: no-repeat;
    background-size: 100%;
`;
