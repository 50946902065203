import { LegType, ErrorsType } from './BetSlipTypes';
import { computed, action, makeObservable } from 'mobx';
import { convertToNumber } from 'src/appState/utils';
import { PriceType } from './BetSlipSheredTypes';
import { ReferralState } from './ReferralState';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { TypeId } from 'src_common/common/websocket2/type';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { BetSlipStateParamsType } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { Amount } from 'src_common/common/amount/Amount';
import { BetslipData } from './BetslipData';
import { StakeInputState2 } from './stakeInputState/StakeInputState2';
import { LegsState } from 'src/domains/sportsbook/betting/state/betSlipState/LegsState';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

interface OptionType {
    priceType: 'sp' | 'fp';
    label: string;
}

export class LegItem {
    public constructor(
        public readonly selectionId: BetslipSingleId,
        private readonly legsState: LegsState,
        private readonly referralState: ReferralState,
        public readonly lifeSpanState: LifeSpanState,
        private readonly sdkCustomer: SdkCustomer,
        private readonly params: BetSlipStateParamsType,
        private betslipData: BetslipData
    ) {
        makeObservable(this);
    }

    private getLeg(): LegType | null {
        if (this.referralState.referralData !== null) {
            return this.referralState.getLegById(this.selectionId);
        }
        return this.legsState.getLegById(this.selectionId);
    }

    public get stakeInput(): StakeInputState2 | null {
        return this.betslipData.getInputSingle(this.selectionId);
    }

    public get selectionPrice(): PriceType | null {
        return this.betslipData.getSelectionPrice(this.selectionId);
    }

    public set selectionPrice(newPrice: PriceType | null) {
        this.betslipData.setSelectionPrice(this.selectionId, newPrice);
    }

    @computed private get leg(): LegType | null {
        return this.getLeg();
    }

    @computed public get isSPSport(): boolean {
        if (this.leg === null) {
            return false;
        }

        const basicData = this.sdkCustomer.basicData.get();
        const eventId = this.leg.eventId;

        if (basicData.type === 'ready' && eventId !== null) {
            const eventModel = this.sdkCustomer.models.getEvent(eventId);
            const selectionSport = eventModel?.sportOriginal ?? '';
            return basicData.value.spOnlySports?.includes(selectionSport) ?? false;
        }

        return false;
    }

    @computed public get legId(): number | null {
        return this.leg?.selectionId ?? null;
    }
    @computed public get eachWay(): boolean | null {
        return this.leg?.eachWay ?? null;
    }
    @computed public get errors(): Array<ErrorsType> {
        return this.leg?.errors ?? [];
    }
    @computed public get eventId(): number | null {
        return this.leg?.eventId ?? null;
    }

    @computed public get marketId(): number | null {
        return this.leg === null ? null : convertToNumber(this.leg.marketId);
    }

    @computed public get potentialReturns(): Amount | null {
        if (this.leg === null || this.leg.potentialReturns === null) return null;
        return new Amount(this.leg.potentialReturns);
    }

    @computed public get potentialReturnsAt(): Amount | null {
        if (this.leg === null || this.leg.potentialReturnsAt === null) return null;

        return new Amount(this.leg.potentialReturnsAt);
    }

    @computed public get price(): PriceType | null {
        return this.leg?.price ?? null;
    }

    @computed public get priceType(): string | undefined {
        return this.leg?.priceType;
    }

    @computed public get related(): boolean | null {
        return this.leg?.related ?? null;
    }

    @computed public get stakePerLine(): Amount | null {
        if (this.leg === null || this.leg.stakePerLine === null || this.leg.stakePerLine === undefined) return null;

        return new Amount(this.leg.stakePerLine);
    }

    @computed public get isSelectedSP(): boolean {
        return this.leg === null ? false : this.leg.priceType === 'sp';
    }

    @action public acceptChanges = (): void => {
        this.selectionPrice = this.price;
    };

    @computed public get selectionChanged(): boolean {
        if (this.leg !== null && this.selectionPrice !== null && this.leg.price !== null) {
            return this.selectionPrice.d !== this.leg.price?.d;
        }
        return false;
    }

    @computed public get selectionChangedDirection(): 'up' | 'down' | null {
        if (
            this.leg !== null &&
            this.selectionPrice !== null &&
            this.leg.price !== null &&
            this.leg.price !== undefined
        ) {
            if (this.selectionPrice.d !== this.leg.price.d) {
                return this.selectionPrice.d > this.leg.price.d ? 'down' : 'up';
            }
        }
        return null;
    }

    public handleChangeEachWay = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.betslipData.onChangeEachWay(this.selectionId, e.currentTarget.checked);
    };

    public getEventModel(): EventModel | null {
        const eventId = this.eventId;

        if (eventId === null) {
            return null;
        }

        return this.sdkCustomer.models.getEvent(eventId);
    }

    public getMarketModel(): MarketModel | null {
        const eventId = this.eventId;
        const marketId = this.marketId;

        if (eventId === null || marketId === null) {
            return null;
        }

        return this.sdkCustomer.models.getMarket(TypeId.newMarketId(eventId, marketId));
    }

    public getSelectionModel(): SelectionModel | null {
        return this.selectionId.id.getModel();
    }

    @computed public get priceOptions(): Array<OptionType> | undefined {
        if (this.isSPSport) {
            return [
                {
                    priceType: 'sp',
                    label: 'SP',
                },
            ];
        }
        const selectionModel = this.getSelectionModel();
        const oddsFormat = this.params.getOddsFormat();
        if (selectionModel !== null) {
            const price = this.price === null ? selectionModel.price : this.price;
            const sp = selectionModel.sp;
            const options: Array<OptionType> = [];
            if (price !== undefined) {
                const label = oddsFormat === 'd' ? price.d.toFixed(2).toString() : price.f;

                options.push({
                    priceType: 'fp',
                    label: label,
                });
            }
            if (sp) {
                options.push({
                    priceType: 'sp',
                    label: 'SP',
                });
            }
            return options;
        }
    }

    @computed public get selectedPriceType(): string | undefined {
        return this.priceOptions
            ?.filter(({ priceType }): boolean => {
                if (this.priceType === priceType) {
                    return true;
                }
                return false;
            })
            .map((el) => el.label)[0];
    }

    @computed public get notSelectedPriceType(): 'sp' | 'fp' | undefined {
        return this.priceOptions
            ?.filter(({ priceType }): boolean => {
                if (this.priceType !== priceType) {
                    return true;
                }
                return false;
            })
            .map((el) => el.priceType)[0];
    }

    public get switchDisabled(): boolean {
        if (this.priceOptions !== undefined) {
            return this.priceOptions.length < 2 || this.referralState.isReferred;
        }
        return true;
    }

    public handleSwitchPriceType = (): void => {
        if (this.notSelectedPriceType !== undefined) {
            if (this.switchDisabled) {
                return;
            }
            this.betslipData.onChangePriceType(this.selectionId, this.notSelectedPriceType);
        }
    };

    public removeLeg = (): void => {
        this.betslipData.onRemoveLeg(this.selectionId);
    };

    @computed public get returnsWithBoost(): Amount | null {
        if (
            (this.potentialReturnsAt === this.stakePerLine || this.eachWay !== null) &&
            this.priceType !== 'sp' &&
            this.legId !== null
        ) {
            const selectionWithBoost = this.lifeSpanState.lifeSpanSocketState.selectionsWithAvailableBoosts(
                this.legId.toString()
            );
            const selectionBoostReturn = selectionWithBoost?.selectionInfo?.reward?.value;

            if (selectionBoostReturn !== undefined && selectionWithBoost?.selectionInfo?.reward?.active === true) {
                return this.potentialReturns === null
                    ? new Amount('0')
                    : this.potentialReturns.add(new Amount('1').multiply(selectionBoostReturn));
            }

            return this.potentialReturns ?? new Amount('0');
        }

        if (this.priceType === 'sp') {
            return null;
        }

        if (this.price !== null) {
            const price = this.price.f.split('/');
            const priceDen = convertToNumber(price[0]);
            const priceNum = convertToNumber(price[1]);

            if (priceDen !== null && priceNum !== null && this.stakePerLine !== null) {
                return this.stakePerLine.multiply(priceDen).div(priceNum).add(this.stakePerLine);
            }
        }
        return new Amount('0');
    }

    public isSelectedBet = (sp: boolean): boolean => {
        const { isReferred } = this.referralState;
        if (isReferred) return false;

        const selectionId = this.legId;
        const marketId = this.marketId;
        if (selectionId !== null && marketId !== null) {
            if (sp === true && this.isSelectedSP === false) {
                return false;
            }
            if (sp === false && this.isSelectedSP === true) {
                return false;
            }

            return true;
        }
        return false;
    };
}
