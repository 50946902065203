import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { observable, makeObservable, action, computed } from 'mobx';
import { DateRangeFormState } from 'src/domains/players/webview/components/Account/common/dateRangeForm/DateRangeForm.state';
import { UsersState } from 'src/domains/players/state/UsersState';
import { DateTime } from 'src_common/utils/time/time';
import { RangeType } from 'src/domains/players/webview/components/Account/transactionHistoryTab/TransactionHistoryTab.state';
import { OpenapiProxyCustomerBonusesResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_bonuses';
import { OpenapiProxyCustomerAllBetsResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_all_bets';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { ApiResponseWrapper } from 'src_common/common/mobx-utils/ApiResponseWrapper';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';

export type BetHistoryType =
    OpenapiProxyCustomerAllBetsResponse200Type['results'] extends Array<infer Model> ? Model : never;
export type BetHistoryLegType = BetHistoryType['legs'] extends Array<infer Model> ? Model : never;
export type StatusType = 'loading' | 'error' | 'ready' | undefined;

const createBetsHistory = (
    usersState: UsersState,
    websocketV1: WebsocketV1,
    userId: number
): AutoMap<[string | null, string | null], ApiResponseWrapper<OpenapiProxyCustomerAllBetsResponse200Type | null>> =>
    new AutoMap(
        ([from, to]) =>
            new ApiResponseWrapper(
                'betsHistory',
                null,
                async (): Promise<OpenapiProxyCustomerAllBetsResponse200Type | null> => {
                    return await usersState.postAllBets({
                        requestBody: {
                            perPage: 250,
                            from: from ?? undefined,
                            to: to ?? undefined,
                            statusOpen: undefined,
                            page: 1,
                        },
                    });
                },
                (refresh) => {
                    refresh();

                    websocketV1.subscribe(`${userId}:Bets`, refresh);
                    websocketV1.subscribe(`${userId}:Wallet`, refresh);

                    return (): void => {
                        websocketV1.unsubscribe(`${userId}:Bets`, refresh);
                        websocketV1.unsubscribe(`${userId}:Wallet`, refresh);
                    };
                }
            )
    );

export class BetHistoryTabState {
    @observable public betHistoryFilterType: BetHistoryFilterType = 'all-dates';
    @observable public range: RangeType | null;
    public readonly formState: DateRangeFormState;
    @observable public betsHistoryMap: AutoMap<
        [string | null, string | null],
        ApiResponseWrapper<OpenapiProxyCustomerAllBetsResponse200Type | null>
    >;

    public constructor(
        private readonly usersState: UsersState,
        private readonly websocketV1: WebsocketV1,
        private readonly userId: number
    ) {
        makeObservable(this);

        this.formState = new DateRangeFormState(DateTime.current().subtractMonths(1), DateTime.current());
        this.range = this.getRange(this.formState.from.dateString, this.formState.to.dateString);

        this.betsHistoryMap = createBetsHistory(this.usersState, this.websocketV1, this.userId);
    }

    private getRange(dateFrom: string, dateTo: string): RangeType {
        return {
            from: dateFrom,
            to: dateTo,
        };
    }

    @action public submitRange = (dateFrom: string, dateTo: string): void => {
        this.range = this.getRange(dateFrom, dateTo);
    };

    @action public onChangeRange = (filterType: BetHistoryFilterType): void => {
        this.betHistoryFilterType = filterType;
    };

    @computed public get userBets(): OpenapiProxyCustomerAllBetsResponse200Type | null {
        if (this.betHistoryFilterType === 'date-range') {
            return this.betsHistoryMap.get([this.range?.from ?? null, this.range?.to ?? null]).data;
        }

        return this.betsHistoryMap.get([null, null]).data;
    }

    @computed public get userBetsRequestStatus(): StatusType {
        return this.userBets === null ? 'loading' : 'ready';
    }

    @computed public get userBonuses(): OpenapiProxyCustomerBonusesResponse200Type {
        const bonuses = this.usersState.accountBonusesData.get();
        if (bonuses?.type === 'ready') {
            return bonuses.value;
        }
        return [];
    }
}

type BetHistoryFilterType = 'all-dates' | 'date-range';

export class TimeRangeBetHistoryTabState {
    @observable public betHistoryFilterType: BetHistoryFilterType = 'all-dates';

    public constructor(
        private readonly betHistoryTabState: BetHistoryTabState,
        private readonly language: LanguagesState
    ) {
        makeObservable(this);
    }

    @computed public get filters(): FiltersType[] {
        return [
            {
                key: 'all-dates',
                label: this.language.getTranslation('account.bet-history.all-dates.label', 'All Time'),
                onClick: () => this.setActiveFilterId('all-dates'),
            },
            {
                key: 'date-range',
                label: this.language.getTranslation('account.bet-history.date-range.label', 'Date Range'),
                onClick: () => this.setActiveFilterId('date-range'),
            },
        ];
    }

    @action public setActiveFilterId(filterType: BetHistoryFilterType): void {
        this.betHistoryFilterType = filterType;
        this.betHistoryTabState.onChangeRange(filterType);
    }
}
