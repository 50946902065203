import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { YouDecideMobile } from 'src/domains/sportsbook/shared/Components';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { FooterContent } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { useCommon } from 'src/domains/common/Common';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';

interface FooterVersionPropsType {
    rabEvent: EventId | null;
}

const FooterVersion = observer('FooterVersion', ({ rabEvent }: FooterVersionPropsType): JSX.Element | null => {
    const common = useCommon();
    const { appSportsBookState } = useAppStateContext();
    const featureState = FeatureState.get(common);
    const { rab } = appSportsBookState;

    if (featureState.rabFeature && rab.isBetBuilderOpen === true) {
        return <YouDecideMobile eventId={rabEvent} />;
    }

    return null;
});

interface PropsType {
    currentView: RouteViewType | null;
}

export const Footer = observer('Footer', ({ currentView }: PropsType) => {
    const appState = useAppStateContext();
    const eventId =
        currentView?.name === 'event' ? appState.appSportsBookState.models.id.getEventId(currentView.id) : null;

    return (
        <>
            <FooterContent />
            <FooterVersion rabEvent={eventId} />
        </>
    );
});
