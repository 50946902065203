import React from 'react';
import { useClickOutside } from 'src/domains/players/hooks/useClickOutside';
import { observer } from 'src/utils/mobx-react';
import {
    AddressesListItemLast,
    AddressesListWrapper,
    EnterManuallyAddressList,
    FlexInput,
    PostcodeWrapper,
    SearchAddressButton,
    ErrorMessageWrapper,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/YourDetails.style';
import { YourDetailsState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/YourDetails.state';
import { useAppStateContext } from 'src/appState/AppState';
import { AddressesListItemWrapper } from './AddressesListItemWrapper';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PostCodeSectionPropsType {
    yourDetailsState: YourDetailsState;
}

export const PostCodeSection = observer('PostCodeSection', (props: PostCodeSectionPropsType) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { customKeyboard } = appPlayersState;

    const { yourDetailsState } = props;
    const [setWrapperRef] = useClickOutside({ callback: yourDetailsState.handleClickOutside });

    return (
        <PostcodeWrapper>
            <FlexInput
                state={yourDetailsState.postcodeState}
                placeholder={languagesState.getTranslation(
                    'signup.address-procedure.enter-postcode.placeholder',
                    'Enter postcode'
                )}
                label={
                    yourDetailsState.isShownFullAddressForm ? (
                        <I18n
                            langKey='signup.address-procedure.postcode.label'
                            defaultText='Postcode'
                        />
                    ) : (
                        <I18n
                            langKey='signup.address-procedure.address.label'
                            defaultText='Address'
                        />
                    )
                }
                asideElement={customKeyboard.getAsideElement}
                showSuccessIcon={Boolean(yourDetailsState.isShownFullAddressForm)}
                dataTest='postcode-input'
                capitalizeLabel={true}
            />
            {yourDetailsState.addresses.length > 0 ? (
                <AddressesListWrapper ref={setWrapperRef}>
                    {yourDetailsState.addresses.map((address) => (
                        <AddressesListItemWrapper
                            key={address.Id}
                            address={address}
                            setAddressFromPostcode={yourDetailsState.setAddressFromPostcode}
                        />
                    ))}
                    <AddressesListItemLast>
                        <I18n
                            langKey='signup.address-procedure.cant-find-your-address'
                            defaultText="Can't find your address? "
                        />
                        <EnterManuallyAddressList onClick={yourDetailsState.showFullAddressFormHandler}>
                            <I18n
                                langKey='signup.address-procedure.enter-manually'
                                defaultText='Enter Manually'
                            />
                        </EnterManuallyAddressList>
                    </AddressesListItemLast>
                </AddressesListWrapper>
            ) : null}
            {yourDetailsState.isShownFullAddressForm ? null : (
                <SearchAddressButton
                    size='medium'
                    isLoading={yourDetailsState.loadingAddresses}
                    onClick={yourDetailsState.onSearchAddressHandler}
                    dataTest='sign-up-search-address-button'
                >
                    <I18n
                        langKey='signup.address.search-button'
                        defaultText='Search'
                    />
                </SearchAddressButton>
            )}
            {yourDetailsState.myAddressError === null ? null : (
                <ErrorMessageWrapper
                    type='error'
                    marginBottom='16px'
                    message={yourDetailsState.myAddressError}
                />
            )}
        </PostcodeWrapper>
    );
});
