import { computed, observable, action, makeObservable } from 'mobx';
import { Value } from 'src_common/common/mobx-utils/Value';

const SCROLL_OFFSER = 70 * 3;

class RefBox {
    public constructor(public readonly ref: HTMLElement) {}
}

class HtmlElementBox {
    private readonly box: Value<RefBox>;

    public constructor(ref: HTMLElement) {
        makeObservable(this);
        this.box = new Value(new RefBox(ref), (setValue) => {
            const timer = setInterval(() => {
                const refBox = this.box.getValue();
                setValue(new RefBox(refBox.ref));
            }, 500);

            return (): void => {
                clearInterval(timer);
            };
        });
    }

    public get innerRef(): HTMLElement {
        return this.box.getValue().ref;
    }

    @computed public get scrollLeft(): number {
        return this.box.getValue().ref.scrollLeft;
    }

    @computed public get scrollWidth(): number {
        return this.box.getValue().ref.scrollWidth;
    }

    @computed public get clientWidth(): number {
        return this.box.getValue().ref.clientWidth;
    }
}

const elementScrollTo = (racesList: HTMLElement, offset: number): void => {
    try {
        racesList.scrollBy({ left: offset, behavior: 'smooth' });
    } catch (ex) {
        racesList.scrollLeft = racesList.scrollLeft + offset;
    }
};

export class ScrollState {
    @observable private ref: HtmlElementBox | null = null;

    @action public setRef = (item: HTMLElement | null): void => {
        if (item === null) {
            this.ref = null;
        } else {
            this.ref = new HtmlElementBox(item);
        }
    };

    public constructor() {
        makeObservable(this);
    }

    @computed public get scrollLeftArrow(): boolean {
        const ref = this.ref;
        if (ref !== null) {
            if (ref.scrollLeft > 0) {
                return true;
            }
        }

        return false;
    }

    @action public goToLeft = (): void => {
        const ref = this.ref;
        if (ref !== null) {
            elementScrollTo(ref.innerRef, -SCROLL_OFFSER);
        }
    };

    @computed public get scrollRightArrow(): boolean {
        const ref = this.ref;
        if (ref !== null) {
            if (ref.scrollWidth > ref.clientWidth) {
                const isEnd = ref.scrollWidth === Math.round(ref.scrollLeft + ref.clientWidth);
                return !isEnd;
            }
        }

        return false;
    }

    @action public goToRight = (): void => {
        const ref = this.ref;
        if (ref !== null) {
            elementScrollTo(ref.innerRef, SCROLL_OFFSER);
        }
    };
}
