import React, { useState } from 'react';
import { RacingFilterGroupState } from './RacingFilterGroupState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { FiltersState, FiltersStateStrategy } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { MultipleFilters } from 'src/domains/sportsbook/webview/components/racingFilters/MultipleFilters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import {
    GroupedFilters,
    TomorrowChevronIconWrapper,
    TomorrowLabel
} from './RacingFilters.style';

const TomorrowButton = (): JSX.Element => {
    return (
        <TomorrowLabel>
            <I18n langKey='races-slider.tomorrow' defaultText='Tomorrow' />
            <TomorrowChevronIconWrapper position='right' />
        </TomorrowLabel>
    );
};

interface RacingFiltersListPropsType {
    todayStrategy: FiltersStateStrategy,
    tomorrowStrategy: FiltersStateStrategy
}

const RacingFiltersList = observer('RacingFiltersList', (props: RacingFiltersListPropsType) => {
    const { todayStrategy, tomorrowStrategy } = props;
    const [stateToday] = useState(() => new FiltersState(todayStrategy, 'today'));
    const [stateTomorrow] = useState(() => new FiltersState(tomorrowStrategy, 'tomorrow'));

    const separator = stateTomorrow.filters.length > 0
        ? <TomorrowButton key='tomorrow-separator' />
        : null;

    return (
        <FiltersScrollableWrapper>
            <MultipleFilters states={[stateToday, stateTomorrow]} separators={[separator]} skin={FiltersSkin.PRIMARY} />
        </FiltersScrollableWrapper>
    );
});

interface PropsType {
    eventsFilter: RacingFilterGroupState
}

export const RacingFilters = observer('RacingFilters', (props: PropsType): JSX.Element => {
    const { eventsFilter } = props;
    const todayStrategy = eventsFilter.todayFilterStrategy;
    const tomorrowStrategy = eventsFilter.tomorrowFilterStrategy;

    return (
        <GroupedFilters>
            <RacingFiltersList todayStrategy={todayStrategy} tomorrowStrategy={tomorrowStrategy} />
        </GroupedFilters>
    );
});
