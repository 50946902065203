import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LogoZenetPayWrapper, PaymentProviderImagesContainer } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/zenetPayProvider/ZenetPayTab.style';
import { LogoPix } from 'src/domains/common/paymentMethods/logos/LogoPix';

export const PaymentProviderImages = observer('PaymentProviderImages', (): JSX.Element => {

    return (
        <PaymentProviderImagesContainer
            data-test='payment-provider-images-container'
        >
            <LogoZenetPayWrapper/>
            <LogoPix/>
        </PaymentProviderImagesContainer>
    );
});
