import { computed, makeObservable } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { getOnline } from 'src_common/common/mobx-utils/Services/getOnline';

export class NetworkConnectionState {
    private readonly configComponents: ConfigComponents;

    public constructor(configComponents: ConfigComponents) {
        makeObservable(this);
        this.configComponents = configComponents;
    }

    @computed public get showOfflineMessage(): boolean {
        const { isNoConnectionPageVisible } = this.configComponents.config;
        const isOnline = getOnline();

        if (isNoConnectionPageVisible === true && isOnline === false) {
            return true;
        }

        return false;
    };
}
