import { action, observable, computed, makeObservable } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { GameModalPopup, MarketingNotificationsPopup, MultipleSlotsPopup } from './popupTypes';

// GENERAL POPUPS
export interface PopupHelpAndContact {
    readonly type: 'helpAndContact';
    readonly email: string;
    readonly textPart1: string;
    readonly textPart2: string;
}

export interface PopupDeleteCard {
    readonly type: 'popupDeleteCard';
    readonly title: string;
    readonly content: string;
    readonly popUpCard: string;
    readonly agreeAction: () => Promise<void>;
    readonly cancelAction?: () => void;
}

interface PopupRealityCheck {
    readonly type: 'popupRealityCheck';
}

interface PopupRealityCheckNewValueConfirmation {
    readonly type: 'popupRealityCheckNewValueConfirmation';
}

export type UserAllPopupsTypes = PopupHelpAndContact | GameModalPopup | MultipleSlotsPopup | PopupDeleteCard;

type ComputedAllPopupsTypes = PopupRealityCheck | PopupRealityCheckNewValueConfirmation | MarketingNotificationsPopup;

type AllPopupsType = UserAllPopupsTypes | ComputedAllPopupsTypes;

export class PopupState {
    private readonly getComputedPopups: () => ComputedAllPopupsTypes | null;
    @observable private popupsValue: UserAllPopupsTypes | null = null;

    public constructor(
        public readonly config: ConfigComponents,
        getComputedPopups: () => ComputedAllPopupsTypes | null
    ) {
        makeObservable(this);
        this.getComputedPopups = getComputedPopups;
    }

    @action public show = (newPopup: UserAllPopupsTypes): void => {
        this.popupsValue = newPopup;
    };

    @action public hide = (): void => {
        this.popupsValue = null;
    };

    @computed public get popups(): AllPopupsType | null {
        const computedPopups = this.getComputedPopups();

        if (computedPopups !== null) {
            return computedPopups;
        }

        return this.popupsValue;
    }

    @computed public get rightPanelAttachedToRightEdge(): boolean {
        const popup = this.popups;
        if (popup === null) {
            return false;
        }

        if ('rightPanelStickyToRight' in popup) {
            //TODO - goal is to remove that condition
            return popup.rightPanelStickyToRight;
        }

        return false;
    }

    // TODO: delete all what is below

    @computed public get isAnyPopup(): boolean {
        if (
            this.popups === null ||
            this.popups?.type === 'popupRealityCheck' ||
            this.popups?.type === 'popupRealityCheckNewValueConfirmation' ||
            this.popups?.type === 'marketingNotificationsPopup'
        ) {
            return false;
        }
        return true;
    }

    @computed public get isPopupDeleteCard(): boolean {
        return this.popupsValue?.type === 'popupDeleteCard';
    }

    @computed public get popupForView(): AllPopupsType | null {
        return this.popups;
    }
}
