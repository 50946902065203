import React from 'react';
import {
    BuildRaceCardButton,
    RaceCardChevronIconWrapper,
} from './NewRacingPage.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    getDefaultHeaderOptions,
    getHeaderOptions,
    HeaderOptionItemType,
} from 'src/domains/layouts/webview/components/sports/general/config';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { HorseRacingSelectedNew } from './NewRacingPage.state';

export const renderButton = (
    starRouter: StarRouter,
    isBuilding: boolean,
    selectedNew: HorseRacingSelectedNew,
    sport: string
): React.ReactNode => {
    const amount = selectedNew.racesSelected();
    
    if (isBuilding) {
        const ids = selectedNew.findSelectIds();

        if (ids.length === 0) {
            return null;
        }

        return (
            <BuildRaceCardButton size='small' onClick={(): void => starRouter.redirectToRaceBuilder(ids, sport)}>
                <I18n
                    langKey='sports.racing.build-racecard-button.label'
                    defaultText='Racecards ({amount})'
                    params={{ amount }}
                />
                <RaceCardChevronIconWrapper position='right' />
            </BuildRaceCardButton>
        );
    }
};

export const getHeader = (sportId: string): HeaderOptionItemType => {
    const options = getHeaderOptions()[sportId];

    if (options !== undefined) {
        return options;
    }
    return getDefaultHeaderOptions();
};

export const areSpecialEventsPresent = (events?: EventModel[] | null): boolean => {
    if (events === null || events === undefined) return false;

    const specialEvents = events.filter((event) => event.tagsFootballSpecial);

    return specialEvents.length > 0;
};
