import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { FiltersSkinType } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { TransactionHistoryFilterWrapperMain } from 'src/domains/players/webview/components/Account/transactionHistoryTab/TransactionHistoryTab.style';
import { FiltersNewView, FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';

interface FiltersNewPropsType {
    selected: string;
    filters: FiltersType[];
    skin?: FiltersSkinType;
}

export const HistoryFiltersNew = observer<FiltersNewPropsType>('HistoryFiltersNew', ({ selected, filters, skin }) => {
    return (
        <TransactionHistoryFilterWrapperMain>
            <FiltersNewView select={selected} filters={filters} skin={skin} />
        </TransactionHistoryFilterWrapperMain>
    );
});
