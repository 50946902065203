import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { UniverseComponent } from 'src/domains/common/universes';

export const QuickHeaderSignUpIcon = observer('QuickHeaderSignUpIcon', (): JSX.Element => {
    return (
        <UniverseComponent>
            {(universeModule): React.ReactElement => <universeModule.quickHeaderSignUpIcon />}
        </UniverseComponent>
    );
});
