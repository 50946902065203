import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const ResetPasswordFormWrapper = styled('form', { label: 'ResetPasswordFormWrapper' })`
    margin: 0;
    padding: 0 16px;
`;

interface SetNewPasswordBtnWrapperPropsType {
    isError: boolean;
}

export const SetNewPasswordBtnWrapper = styled(Button, { label: 'SetNewPasswordBtnWrapper' })<SetNewPasswordBtnWrapperPropsType>`
    display: block;
    margin-top: ${({ isError }): string => isError ? '-4px' : '16px'};
    text-transform: capitalize;
    width: 100%;
`;
