import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    RadioButton,
    RadioLabel
} from './RadioButtons.style';
import { SingleRadioButton } from './SingleRadioButton';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
export interface GroupOption<T> {
    value: T;
    label: JSX.Element | string;
}

interface RadioButtonStateLessPropsTypes {
    className?: string;
    color: 'light' | 'dark';
    gap?: string;
    group: Array<GroupOption<string>>;
    isDisabled?: boolean;
    name: string;
    onChangeCallback: (newSelectValue: string) => void;
    selectValue: string | null;
    size?: 'small' | 'large';
}
export const RadioButtonsStateLess = observer('RadioButtonsStateLess', (props: RadioButtonStateLessPropsTypes) => {
    const { gap, className, color, name, isDisabled = false, size = 'large', group, selectValue, onChangeCallback } = props;

    return (
        <>
            {group.map(({ value, label }) => {
                const itemValue: string = value;
                const labelItem: JSX.Element | string = label;
                const isChecked = itemValue === selectValue;

                return (
                    <RadioButton gap={gap} className={className} isDisabled={isDisabled} color={color} isActive={isChecked} key={`${itemValue}`} data-test-selected={isChecked ? true : false}>
                        <SingleRadioButton
                            name={name}
                            isDisabled={isDisabled}
                            isChecked={isChecked}
                            value={itemValue}
                            onChange={onChangeCallback}
                        />
                        <RadioLabel size={size} data-test={itemValue}>{labelItem}</RadioLabel>
                    </RadioButton>
                );
            })}
        </>
    );
});

interface RadioButtonPropsTypes<T extends string> {
    color: 'light' | 'dark';
    className?: string;
    name: string;
    group: Array<GroupOption<T>>;
    isDisabled?: boolean;
    size?: 'small' | 'large';
    gap?: string;
    state: FormInputState<T, T>;
    onChangeCallback?: () => void;
}

export const RadioButtons = observer('RadioButtons', <T extends string>(props: RadioButtonPropsTypes<T>) => {
    const handleChange = (newValue: T): void => {
        props.state.setValue(newValue);
        if (props.onChangeCallback !== undefined) {
            props.onChangeCallback();
        }
    };

    const { gap, className, color, name, isDisabled = false, size = 'large', state, group } = props;

    const currentValue = state.value;
    return (
        <>
            {group.map(({ value, label }) => {
                const itemValue: T = value;
                const labelItem: JSX.Element | string = label;
                const isChecked = itemValue === currentValue;

                return (
                    <RadioButton gap={gap} className={className} isDisabled={isDisabled} color={color} isActive={isChecked} key={`${itemValue}`} data-test-selected={isChecked}>
                        <SingleRadioButton
                            name={name}
                            isDisabled={isDisabled}
                            isChecked={isChecked}
                            value={itemValue}
                            onChange={handleChange}
                        />
                        <RadioLabel size={size} data-test={itemValue}>{labelItem}</RadioLabel>
                    </RadioButton>
                );
            })}
        </>
    );
});
