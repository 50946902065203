import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAsObservableSource } from 'mobx-react-lite';
import { SelectionTemplateRowState } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/selectionTemplateRow/SelectionTemplateRow.state';
import { AlternativeSelection } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/selectionGroup/alternativeSelection/AlternativeSelection';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

interface SelectionRowType {
    selection: SelectionModel;
    eventId: EventId;
    marketId: MarketId;
    isExpanded: boolean;
    itemsIn: Array<SelectionModel>;
}

export const SelectionRow = observer('SelectionRow', (props: SelectionRowType) => {
    const componentProps = useAsObservableSource(props);
    const [state] = useState(() => new SelectionTemplateRowState(componentProps));
    const { eventId, marketId } = componentProps;
    const { eventModel, selectionsToRender, displayTemplate } = state;

    if (eventModel !== null) {
        return (
            <>
                {selectionsToRender.map(selection => {
                    return (
                        <AlternativeSelection
                            key={selection.id}
                            eventId={eventId}
                            marketId={marketId}
                            selectionId={selection.id2}
                            displayTemplate={displayTemplate}
                        />
                    );
                }
                )}
            </>
        );

    }
    return null;
});
