import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface OverlayWrapperPropsType {
    isMobile?: boolean;
}

export const OverlayWrapper = withConfig(theme => styled('div', { label: 'OverlayWrapper' })<OverlayWrapperPropsType>`
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    /* Please try to use media query for mobile and desktop if possible */
    background-color: ${({ isMobile }): string => (isMobile === true) ? 'transparent' : theme.star.casino.game.bgColor};
    visibility: ${({ isMobile }): string => (isMobile === true) ? 'visible' : 'hidden'}; /* for desktop display on hover GameWrapper, for mobile always visible */
`);

export const ImageButtonsWrapper = styled('div', { label: 'ImageButtonsWrapper' })`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const PlayButton = withConfig(theme => styled('div', { label: 'PlayButton' })`
    align-items: center;
    bottom: 0;
    color: ${theme.star.casino.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.regular};
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .2s ease;
    &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 0 11px 17px;
        border-color: transparent transparent transparent ${theme.star.casino.txtColor};
    }
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.star.casino.liveCasino.bgColor};
        content: '';
        z-index: ${theme.star.zIndexGlobal.below};
    }
    @media screen and (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 1;
        }
    }
    @media (hover: none) {
        -webkit-tap-highlight-color: ${theme.star.casino.liveCasino.bgColor};
    }
`);
