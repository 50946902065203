import React, { ReactElement } from 'react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { observer } from 'src/utils/mobx-react';
import { ModalHeader } from './modal-header/ModalHeader';
import { ModalBody } from './modal-body/ModalBody';
import { ModalWrapper } from './GameModal.style';

interface GameModalPropsType {
    appCasinoState: AppCasinoState;
}

export const GameModal = observer('GameModal', (props: GameModalPropsType): ReactElement => {
    const { appCasinoState } = props;
    const { setRef, regularGameModel, isDepositOpened } = appCasinoState.gameModalState;

    return (
        <ModalWrapper
            data-test='game-modal-container'
            ref={setRef}
            isDepositOpened={isDepositOpened}
        >
            <ModalHeader
                appCasinoState={appCasinoState}
                gameDataState={regularGameModel}
            />
            {regularGameModel === null ? null : (
                <ModalBody
                    appCasinoState={appCasinoState}
                    gameDataState={regularGameModel}
                />
            )}
        </ModalWrapper>
    );
});
