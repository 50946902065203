import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const SidebarHeaderWrapper = withConfig(theme => styled('header', { label: 'SidebarHeaderWrapper' })`
    align-items: center;
    background-color: ${theme.star.promoSidebarSection.bgColor};
    color: ${theme.star.promoSidebarSection.txtColor};
    display: flex;
    flex: 0 0 48px;
    padding: 14px 56px 14px 16px;
    position: sticky;
    top: 0;
    z-index: ${theme.star.zIndexGlobal.header};
`);

export const LPOverlay = withConfig(theme => styled('div', { label: 'LPOverlay' })`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${theme.star.overlay.bgColor};
    z-index: ${theme.star.zIndexGlobal.above};
`);

export const PromoSidebarDesc = withConfig(theme => styled('article', { label: 'PromoSidebarDesc' })`
    padding: 0 16px;
    width: 100%;
    overflow-y: scroll;
    flex-grow: 1;
    @media ${theme.star.mediaQuery.xMobile} {
        flex-grow: 0;
        overflow-y: initial;
    }
`);

export const PromoSidebarDescHeader = withConfig(theme => styled('h3', { label: 'PromoSidebarDescHeader' })`
    color: ${theme.star.promoSidebarSection.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    margin: 0 0 8px;
`);

export const PromoSidebarList = withConfig(theme => styled('div', { label: 'PromoSidebarList' })`
    color: ${theme.star.promoSidebarSection.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    > p {
        &:first-of-type {
            margin: 0 0 16px;
        }
        &:last-of-type {
            margin: 16px 0 0;
        }
        margin: 16px 0;
    }
    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
    ol {
        list-style-type: decimal;
        list-style-position: inside;
    }
    li {
        margin: 16px 0;
        li:first-of-type {
            margin: 0 0 16px;
        }
        li:last-of-type {
            margin: 16px 0 0;
        }
    }
    h3 {
        color: ${theme.star.promoSidebarSection.txtColorSecondary};
        font-size: ${theme.star.fontSize.small};
        font-weight: ${theme.star.fontWeight.bold};
        line-height: 1.3334;
        margin: 16px 0 8px;
    }
`);

interface LinkWrapperPropsType {
    isHTMLBanner: boolean;
}

export const LinkWrapper = styled(Button, { label: 'LinkWrapper' })<LinkWrapperPropsType>`
    display: block;
    margin: ${({ isHTMLBanner }): string => isHTMLBanner ? '130px auto 32px auto' : '16px auto'};
    max-width: 100%;
    width: calc(100% - 32px);
`;
