import { z } from 'zod';

export const InputSchema = z.object({
    amount: z.string(),
    currencyCode: z.string(),
    description: z.string(),
    keywords: z.array(z.string()),
    paymentHandleToken: z.string(),
});

export type CreatePaymentPaysafeType = z.infer<typeof InputSchema>;

const CardExpirySchema = z.object({
    month: z.number().optional().nullable(),
    year: z.number().optional().nullable(),
});

const CardSchema = z.object({
    cardExpiry: CardExpirySchema.optional().nullable(),
    holderName: z.string().optional().nullable(),
    cardType: z.string().optional().nullable(),
    cardBin: z.string().optional().nullable(),
    lastDigits: z.string().optional().nullable(),
    cardCategory: z.string().optional().nullable(),
    issuingCountry: z.string().optional().nullable(),
    status: z.string().nullable().optional(),
});

const BillingDetailsSchema = z.object({
    nickName: z.string().optional().nullable(),
    street: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
});

export const SuccessResponseSchema = z.object({
    data: z.object({
        id: z.string(),
        transactionId: z.number(),
        timeToLiveSeconds: z.string().optional().nullable(),
        paymentType: z.string().optional().nullable(),
        paymentHandleToken: z.string().optional().nullable(),
        merchantRefNum: z.string().optional().nullable(),
        currencyCode: z.string().optional().nullable(),
        settleWithAuth: z.boolean().optional().nullable(),
        billingDetails: BillingDetailsSchema.optional().nullable(),
        singleUseCustomerToken: z.string().optional().nullable(),
        locale: z.string().optional().nullable(),
        firstName: z.string().optional().nullable(),
        lastName: z.string().optional().nullable(),
        phone: z.string().optional().nullable(),
        email: z.string().optional().nullable(),
        customerIp: z.string().optional().nullable(),
        status: z.string().optional().nullable(),
        amount: z.number().optional().nullable(),
        card: CardSchema.optional().nullable(),
    }),
});

type TsuccessResponseType = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            message: z.string().optional().nullable(),
            details: z.array(z.string()).optional().nullable(),
            fieldErrors: z.array(z.string()).optional().nullable(),
        })
    ),
});

type TerrorResponseType = z.infer<typeof ErrorResponseSchema>;

export type TresponseType =
    | { responseStatus: 'success'; response: TsuccessResponseType }
    | { responseStatus: 'error'; data: TerrorResponseType };
