import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { CollectionType } from 'src/domains/casino/types/types';
import { CarouselPromo } from 'src/domains/layouts/webview/components/homePageCarouselPromos/carouselPromo/CarouselPromo';
import { HeadlineCarousel } from 'src/domains/layouts/webview/components/headlineCarousel/HeadlineCarousel';

interface CasinoPromotionsPropsType {
    appCasinoState: AppCasinoState;
    collection: CollectionType;
}

export const CasinoPromotions = observer('CasinoPromotions', (props: CasinoPromotionsPropsType): JSX.Element => {
    const { appCasinoState, collection } = props;
    const { hasCarouselPromo, hasHeadlinePromo } = appCasinoState.features.config;

    return (
        <>
            {hasCarouselPromo === true && <CarouselPromo modeType={collection} />}
            {hasHeadlinePromo === true && <HeadlineCarousel route={collection} />}
        </>
    );
});
