import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';
import { observable, makeObservable } from 'mobx';

export class ExternalApiEmmiters {
    @observable public isLoginDisabled: boolean = false;
    @observable public isLogoutDontMoveToLoginPage: boolean = false;

    public readonly emmiterLoginClick: EventEmmiter<void>;
    public readonly emmiterLogoutClick: EventEmmiter<void>;

    public readonly emmiterLanguageSwitcherClick: EventEmmiter<string>;
    public readonly emmiterSignupFinished: EventEmmiter<void>;
    public readonly emmiterOpenModal: EventEmmiter<string>;
    public readonly emmiterFirstLoginFinished: EventEmmiter<{ id: string; pushNotificationsEnabled: boolean }>;

    public constructor() {
        makeObservable(this);
        this.emmiterLoginClick = new EventEmmiter();
        this.emmiterLogoutClick = new EventEmmiter();
        this.emmiterLanguageSwitcherClick = new EventEmmiter();
        this.emmiterSignupFinished = new EventEmmiter();
        this.emmiterOpenModal = new EventEmmiter();
        this.emmiterFirstLoginFinished = new EventEmmiter();
    }

    public emitEventLoginClick = (): void => {
        this.emmiterLoginClick.trigger();
    };

    public emitEventLogoutClick = (): void => {
        this.emmiterLogoutClick.trigger();
    };

    public emitEventLanguageClick = (lang: string): void => {
        this.emmiterLanguageSwitcherClick.trigger(lang);
    };
    public emitEventSignupFinished = (): void => {
        this.emmiterSignupFinished.trigger();
    };
    public emitModalOpen = (url: string): void => {
        this.emmiterOpenModal.trigger(url);
    };

    public emitEventFirstLoginFinished = (id: number, pushNotificationsEnabled: boolean): void => {
        this.emmiterFirstLoginFinished.trigger({
            id: id.toString(),
            pushNotificationsEnabled: pushNotificationsEnabled,
        });
    };
}
