import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const ConfirmationBoxButtonsWrapper = styled('div', { label: 'ConfirmationBoxButtonsWrapper' })`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
`;

export const ConfirmationBoxBtnWrapper = styled(Button, { label: 'ConfirmationBoxBtnWrapper' })`
    &:not(:last-of-type) {
        margin-right: 5px !important;
    }
`;

export const GamstopText = withConfig(theme => styled('p', { label: 'GamstopText' })`
    background-color: ${theme.star.commonConfirmationBox.bgColor};
    border-radius: 4px;
    line-height: 1.7;
    margin-top: 15px;
    padding: 3px 6px;
`);
