import React, { useEffect, useRef, useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { TypingMessage } from 'src/domains/players/webview/components/Account/traderChat/components/TypingMessage';
import { Message } from 'src/domains/players/webview/components/Account/traderChat/components/Message';
import { MessagesListState } from 'src/domains/players/webview/components/Account/traderChat/components/MessagesList.state';
import { observer } from 'src/utils/mobx-react';
import { ChatMessagesListWrapper } from 'src/domains/players/webview/components/Account/traderChat/components/MessagesList.style';
import { AssigneeInfoInChat } from 'src/domains/players/webview/components/Account/traderChat/components/AssigneeInfo';

export const MessagesList = observer('MessagesList', () => {
    const { appPlayersState } = useAppStateContext();
    const { assignee, messages, isTraderTyping } = appPlayersState.traderChatState;
    const viewportRef = useRef<HTMLUListElement>(null);

    const [state] = useState<MessagesListState>(() => new MessagesListState(viewportRef));

    useEffect(() => {
        state.scrollDownIfNeeded(messages);
    }, [assignee, messages, isTraderTyping]);

    const messagesList = messages.map((message) => {
        return <Message key={message.id} message={message} />;
    });

    return (
        <ChatMessagesListWrapper
            ref={viewportRef}
            onScroll={state.handleScroll}
        >
            {
                messagesList.length > 0 ? (
                    <>
                        {messagesList}
                        <AssigneeInfoInChat />
                    </>
                ) : null
            }
            <TypingMessage key='typing-message' />
        </ChatMessagesListWrapper>
    );
});
