import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Betslip as BetslipStar } from 'src/domains/sportsbook/betting/ui/betSlip/betslip/Betslip';
import { MyBets } from 'src/domains/sportsbook/betting/bettingTab/bets/MyBets';
import {
    BetsCounter,
    BetSlipHeader,
    BetSlipHeaderWrapper,
    BettingTabContainer,
    CloseIconBetSlip,
    RemoveAllWrapper,
    RemoveAllTrashIcon,
    TabsContainer,
} from './BettingTab.style';
import {
    FiltersState,
    FiltersStateStrategy,
    FilterType,
} from 'src/domains/layouts/webview/components/filters/Filters.state';
import { BetSlipState, BetSlipFilter } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { useAppStateContext } from 'src/appState/AppState';

class Strategy implements FiltersStateStrategy {
    public constructor(
        private readonly betSlipState: BetSlipState,
        private readonly language: LanguagesState
    ) {}

    public get filters(): FilterType[] {
        const { getTranslation } = this.language;
        const betslipLabel = getTranslation('betslip.tabs.betslip', 'Betslip');
        const myBetsLabel = getTranslation('betslip.tabs.my-bets', 'My bets');

        return [
            {
                id: BetSlipFilter.BETSLIP,
                key: BetSlipFilter.BETSLIP,
                label: betslipLabel,
            },
            {
                id: BetSlipFilter.MY_BETS,
                key: BetSlipFilter.MY_BETS,
                label: myBetsLabel,
            },
        ];
    }

    public getActiveFilterId(): string | null {
        return this.betSlipState.selectedTab;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (typeof id === 'string') {
            this.betSlipState.changeBetslipTab(id);
        } else {
            console.error('Selected filter can not be `null` or `number`');
        }
    }
}

export const BettingTab = observer('BettingTab', () => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { betSlipState, betWidgetsState, rab } = appSportsBookState;
    const {
        starRouter,
        sdkCustomer,
        languagesState,
        configComponents: { config },
    } = appLayoutsState;

    const [filtersState] = useState(() => new FiltersState(new Strategy(betSlipState, languagesState)));

    const { legsIds2 } = betSlipState;
    const { activeBets } = rab;

    const bets = legsIds2.length + activeBets.length;

    const isAuthorized = sdkCustomer.session.isAuthorized;

    const removeAllButton = (
        <RemoveAllWrapper onClick={betWidgetsState.removeAllTemporary}>
            <I18n langKey='betslip.remove-all' defaultText='Remove all' />
            <RemoveAllTrashIcon />
        </RemoveAllWrapper>
    );

    return (
        <BettingTabContainer>
            <>
                <BetSlipHeaderWrapper>
                    <BetSlipHeader>
                        <I18n langKey='bets.header' defaultText='Bets' />
                        {betWidgetsState.isIndicatorVisible ? <BetsCounter>{bets}</BetsCounter> : null}
                    </BetSlipHeader>

                    {betWidgetsState.isMobile ? <CloseIconBetSlip onClick={starRouter.closeAccount} /> : null}
                    {betWidgetsState.isRemoveAllAvailable && !betWidgetsState.isMobile ? removeAllButton : null}
                </BetSlipHeaderWrapper>

                {betWidgetsState.isRemoveAllAvailable && betWidgetsState.isMobile ? removeAllButton : null}
            </>
            {isAuthorized ? (
                <TabsContainer key='betslip-tab' className='betslip-tab'>
                    <div>
                        <Filters state={filtersState} skin={config.filtersVersion.betSlipTab} isMultipleLine={true} />
                    </div>
                    {filtersState.activeFilterId === BetSlipFilter.BETSLIP ? <BetslipStar key='betslip' /> : null}
                    {filtersState.activeFilterId === BetSlipFilter.MY_BETS ? <MyBets key='my-bets' /> : null}
                </TabsContainer>
            ) : (
                <BetslipStar key='betslip' />
            )}
        </BettingTabContainer>
    );
});
