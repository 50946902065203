import { action, computed, observable, makeObservable } from 'mobx';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { validateEmail, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { UsersState } from 'src/domains/players/state/UsersState';

const toLowerCase = (value: string): Result<string> => {
    return Result.createOk(value.trim().toLowerCase());
};

export type ForgotPasswordErrorMessageType = 'suspended'| 'call-credit' | string | null;
export class ForgotPasswordTabState {

    public readonly emailState: FormInputState<string, string>;
    @observable public inProgress: boolean;

    @observable public showSuccessMessage: boolean;
    @observable public errorMessage: ForgotPasswordErrorMessageType;
    @observable public errorStatus: string | null;

    public constructor(public readonly accountHelperMail: string, public readonly usersState: UsersState) {
        makeObservable(this);
        this.showSuccessMessage = false;
        this.errorMessage = null;
        this.errorStatus = null;
        this.inProgress = false;

        this.emailState = FormInputState.new('').map(validateRequire).map(toLowerCase).map(validateEmail);
    }

    @computed public get isEmailValid(): boolean {
        return this.emailState.result.value.type === 'ok';
    }

    @action public resetPassword = async (): Promise<void> => {
        this.inProgress = true;
        const resultEmail = this.emailState.result;

        if (resultEmail.value.type === 'ok') {
            const result = await this.usersState.requestResetPassword({
                requestBody: {
                    email: resultEmail.value.data,
                    sendViaSMS: false,
                    verify: false
                }
            });

            if (result.status !== 'ok') {
                this.inProgress = false;
                this.showSuccessMessage = false;
                this.errorMessage = this.mapErrorMessage(result.reason ?? null);
                this.errorStatus = result.status;
                return;
            }

            this.emailState.reset();
            this.showSuccessMessage = true;
            this.errorMessage = null;
            this.errorStatus = null;
        }

        this.inProgress = false;
    }

    @action public goBackToForgotPassword = (): void => {
        this.showSuccessMessage = false;
    }

    @action private mapErrorMessage(message: string | null): ForgotPasswordErrorMessageType {
        switch (message) {
            case null:
                return 'suspended';
            case 'Suspended because of failed login attempts':
                return 'suspended';
            case 'Suspended after call credit verification':
                return 'call-credit';
            default:
                return message;
        }
    }
}
