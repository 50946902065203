import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';

const isArray = createGuard(t.array(t.unknown));
const PromoCodesIO = t.interface({
    code: t.string,
    used: t.number
});

const PromoCodeObjectIO = t.interface({
    date_from: t.string,
    date_to: t.string,
    number_of_uses: t.union([t.null, t.undefined, t.number]),
    id: t.number,
    universe: t.string,
    promo_code: t.array(PromoCodesIO), //??
    affiliate_id: t.union([t.null, t.undefined, t.string]),
});

export type PromoCodesType = t.TypeOf<typeof PromoCodeObjectIO>;

const isPromoCodeObjectValid = createGuard(PromoCodeObjectIO);

export const decodePromoCodeObjects = (data: unknown): Array<PromoCodesType> => {
    const out: Array<PromoCodesType> = [];
    if (isArray(data)) {
        for (const item of data) {
            if (isPromoCodeObjectValid(item)) {
                out.push(item);
            } else {
                console.error('Invalid promo code -> ', item);
            }
        }
    } else {
        console.error('Should be array', data);
    }
    return out;
};


