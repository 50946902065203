import styled from '@emotion/styled';
import { ScrollLeftButton, ScrollRightButton } from 'src/domains/layouts/shared/ScrollButtons.style';
import { withConfig } from 'src/withConfig';

export const PaginationWrapper = withConfig(theme => styled('nav', { label: 'PaginationWrapper' })`
    align-self: flex-start;
    color: ${theme.star.customScrollBar.arrow.txtColor};
    display: flex;
    flex: 0 0 50px;
    height: 22px;
    position: absolute;
    right: 8px;
    top: 9px;
    width: 50px;
    z-index: ${theme.star.zIndexGlobal.base};
`);

interface ArrowBtnWrapperProps {
    isAvailable: boolean;
}

export const ScrollLeft = styled(ScrollLeftButton, { label: 'ScrollLeft' })<ArrowBtnWrapperProps>`
    display: ${({ isAvailable }): string => isAvailable ? 'flex' : 'none'};
    left: 0;
    top: 0;
    transform: none;
`;

export const ScrollRight = styled(ScrollRightButton, { label: 'ScrollRight' })<ArrowBtnWrapperProps>`
    display: ${({ isAvailable }): string => isAvailable ? 'flex' : 'none'};
    right: 0;
    top: 0;
    transform: none;
`;
