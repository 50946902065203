import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const PopupWrapper = withConfig(theme => styled('div', { label: 'PopupWrapper' })`
    background-color: ${theme.star.popup.bgColorSecondary};
    max-width: 488px;
    padding: 40px 32px 56px 32px;
    margin: auto 20px;
    @media ${theme.star.mediaQuery.tablet} {
        margin: 0;
    }
`);

export const PopupWhoopsImg = withConfig(theme => styled('img', { label: 'PopupWhoopsImg' })`
    display: block;
    max-width: 200px;
    margin: 0 auto 24px;
    @media ${theme.star.mediaQuery.tablet} {
        max-width: 213px;
    }
`);

export const PopupInformationWrapper = withConfig(theme => styled('div', { label: 'PopupInformationWrapper' })`
    color: ${theme.star.popup.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    letter-spacing: -0.25px;
    line-height: 1.42;
    margin-bottom: 24px;
    text-align: center;
`);

export const PopupInformationBold = withConfig(theme => styled('span', { label: 'PopupInformationBold' })`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const PopupInformationButton = styled(Button, { label: 'PopupInformationButton' })`
    display: block;
    margin: 0 auto;
    padding: 12px 72px;
`;
