import { action, observable, makeObservable } from 'mobx';
import { RedirectState } from './Redirect.state';

export class CasinoSidebarGameInfoState {
    @observable public sidebarGameId: number | null;

    public constructor(
        private readonly redirectState: RedirectState,
    ) {
        makeObservable(this);
        this.sidebarGameId = null;
    }

    @action public showSidebar = (gameId: number): void => {
        this.sidebarGameId = gameId;
        this.redirectState.redirectToCasinoGameInfo();
    }

    public isGameSelected = (): boolean => {
        return (this.sidebarGameId === null) ? false : true;
    }

}
