import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { DetailsWrapper, InfoContainer, StarValueWrapper, StarValueIcon, StarValueIconEmpty, SelectionName, StarRatingWrapper } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeEventDetails/AlternativeEventDetails.style';
import { MaskImg } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/maskImg/MaskImg';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { MaskModelType } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeCustomSelectionState';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    eventId: EventId;
}
export const EventDetailsParticipants = observer('EventDetailsParticipants', (props: PropsType) => {
    const { appSportsBookState: { alternativeEventState } } = useAppStateContext();

    const { getAlternativeEventItemById, eventId } = alternativeEventState.get(props.eventId);

    if (eventId === null) {
        return null;
    }

    const eventItem = getAlternativeEventItemById(eventId);

    const { customSelectionsForEventDetails, getCustomSelectionItemByMarketAndSelectionId, starRationParticipantsFromMarketFilter, activeMarketFilter, marketFilters } = eventItem;

    return (
        <StarRatingWrapper>
            <div>
                {activeMarketFilter !== 'all' && marketFilters.length > 0 ? starRationParticipantsFromMarketFilter.map(elem => {
                    return (
                        <StarRatingComponent
                            key={elem.name}
                            selectionModel={null}
                            mask={null}
                            name={elem.name}
                            starValue={elem.value}
                        />
                    );
                }) : customSelectionsForEventDetails.map(selectionId => {
                    const selectionItem = getCustomSelectionItemByMarketAndSelectionId(selectionId);
                    const selectionModel = selectionId.getModel();
                    const { mask, starValue, name } = selectionItem;
                    return (
                        <StarRatingComponent
                            key={selectionId.toOldId()}
                            selectionModel={selectionModel}
                            mask={mask}
                            name={name}
                            starValue={starValue}
                        />
                    );
                })}
            </div>
        </StarRatingWrapper>
    );
});

interface StarRatingComponentPropsTypes {
    selectionModel: SelectionModel | null;
    mask: MaskModelType | null;
    name: string;
    starValue: number;
}

export const StarRatingComponent = observer('StarRatingComponent', ({ selectionModel, mask, name, starValue }: StarRatingComponentPropsTypes) => {
    return (
        <DetailsWrapper>
            <InfoContainer>
                {selectionModel === null ? null : <MaskImg mask={mask} selectionModel={selectionModel} />}
                <SelectionName>{name}</SelectionName>
            </InfoContainer>
            <StarValueWrapper><StarValue rating={starValue} /></StarValueWrapper>
        </DetailsWrapper>
    );
});

export const StarValue = ({ rating }: { rating: number }): JSX.Element => {
    const emptyStars = 5 - rating;
    const starsArray: Array<React.ReactNode> = [];

    for (let i = 0; i < rating; i++) {
        starsArray.push(<StarValueIcon key={`full-${i}`} />);
    }
    for (let j = 0; j < emptyStars; j++) {
        starsArray.push(<StarValueIconEmpty key={`empty-${j}`} />);
    }
    return (
        <>
            {
                starsArray.map((star) => star)
            }
        </>
    );
};
