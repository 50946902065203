
import * as t from 'io-ts';
import { RabMarketSelectionTypeIO } from 'src_common/common/websocket2/modelsApi/RabMarket';
import { PriceIO } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';


export const ChannelIO = t.union([
    t.literal('quickbet'),
    t.literal('mobile'),
    t.literal('desktop')
]);

export type ChannelType = t.TypeOf<typeof ChannelIO>;

export const AccountTypeIO = t.interface({
    id: t.number,
    type: t.string,
    name: t.string,
});

export const EachWayTermsIO = t.interface({
    places: t.number,
    reduction: t.interface({ num: t.number, den: t.number })
});

export const SportInfoTypeIO = t.interface({
    id: t.union([t.string, t.number, t.undefined]),
    name: t.string
});



export const FirstLegsTypeIO = t.interface({
    id: t.string,
    type: t.string,
    result:  t.union([t.null, t.undefined]),
    eachWayTerms: t.union([ t.null, EachWayTermsIO, t.undefined, t.string, t.interface({ places: t.number, reduction: t.string }) ]),
    termsWithBet: t.union([ t.null, t.boolean, t.undefined ]),
    eventCountry: t.union([t.string,t.array(t.string), t.undefined, t.array(t.interface({}))]),
    inPlay: t.boolean,
    price: t.union([PriceIO, t.null, t.undefined]),
    spPrice: t.union([PriceIO, t.null, t.undefined]),
    priceType: t.string,
    sport: t.union([SportInfoTypeIO, t.undefined]),
    competition: t.union([SportInfoTypeIO, t.undefined]),
    event: SportInfoTypeIO,
    market: t.union([SportInfoTypeIO, t.undefined]),
    selection: t.union([SportInfoTypeIO, t.undefined]),
    problems: t.union([t.array(t.interface({})), t.undefined])
});

export const SmallLegIO = t.interface({
    type: t.union([t.undefined, t.string]),
    selection: t.union([t.undefined, t.interface({ id: t.number })]),
    market: t.union([t.undefined, t.interface({ id: t.number })]),
    event: t.union([t.undefined, t.interface({ id: t.number })]),
    price:  t.union([PriceIO, t.null, t.undefined]),
    priceType: t.union([t.undefined, t.string]),
});

export const ErrorIO = t.interface({
    code: t.string,
    debugDetails: t.union([t.null, t.unknown]),
    details: t.union([t.null, t.unknown]),
    field: t.union([t.null, t.unknown]),
    leg: t.union([ t.undefined, SmallLegIO ]),
    pointer: t.string,
    resource: t.string,
});

const RabMarketIO = t.interface ({
    marketType: t.union([t.string, t.undefined]),
    name: t.union([t.string, t.undefined]),
    bettable: t.union([t.string, t.undefined]),
    period: t.union([t.string, t.undefined]),
});

const RabSingleSelectionIO = t.interface ({
    name: t.string,
    selectionType: RabMarketSelectionTypeIO
});

const RabSelectionsIO = t.interface({
    market: RabMarketIO,
    selection: RabSingleSelectionIO
});

export const RabLegIO = t.interface({
    type: t.union([t.undefined, t.string]),
    sport: t.interface({ id: t.string }),
    priceType: t.string,
    channel: t.string,
    event: t.interface({
        id: t.number,
        externalId: t.string
    }),
    selections: t.union([t.undefined, t.array(RabSelectionsIO)]),
    price: t.union([PriceIO, t.null, t.undefined])
});

export const CountryIO = t.union([t.interface({
    readOnly: t.boolean,
    hidden: t.boolean,
    value: t.string
}), t.string ]);


export const ErrorsIO = t.interface({
    code: t.string,
    debugDetails: t.union([t.null, t.unknown]),
    details: t.union([t.null, t.unknown]),
    field: t.union([t.null, t.unknown]),
    leg: t.unknown,
    pointer: t.string,
    resource: t.string,
});


const FreeBetCreditsIO = t.interface({
    id: t.number,
    amount: t.string,
});


const FreeBetRemarksIO = t.interface({
    resource: t.string,
    code: t.string,
    details: t.union([
        t.interface({ minimum: t.string }),
        t.interface({ invalid: t.string }),
        t.interface({ negative: t.string })
    ])
});


const LegIO = t.interface({
    eachWay: t.union([t.boolean, t.null]),
    errors: t.array(ErrorsIO),
    eventId: t.union([t.number, t.null]),
    freebetCredits: t.union([t.array(FreeBetCreditsIO), t.null]),
    freebetRemarks: t.union([t.array(FreeBetRemarksIO), t.null]),
    marketId: t.union([t.number, t.null]),
    maxStake: t.union([t.string, t.undefined, t.null]),
    potentialReturns: t.union([t.string, t.null]),
    potentialReturnsAt: t.union([t.string, t.null]),
    potentialReturnsEw: t.union([t.string, t.null]),
    price: t.union([PriceIO, t.null, t.undefined]),
    priceType: t.string,
    related: t.union([t.boolean, t.null]),
    selectionId: t.union([t.number, t.null]),
    index: t.union([t.number, t.null]),
    stakePerLine: t.union([t.string, t.undefined, t.null]),
    totalStake: t.union([t.string, t.undefined, t.null]),
    timestamp: t.union([t.number, t.null]),
    numLines: t.union([t.number, t.null]),
    uuid: t.union([t.string, t.null]),
});

export const CurrencyIO = t.union([t.interface({
    readOnly: t.boolean,
    hidden: t.boolean,
    value: t.string
}), t.string ]);


export type LegType = t.TypeOf<typeof LegIO>;

export type CurrencyType = t.TypeOf<typeof CurrencyIO>;

export type FreeBetCreditsType = t.TypeOf<typeof FreeBetCreditsIO>;

export type FreeBetRemarksType = t.TypeOf<typeof FreeBetRemarksIO>;
export type PriceType = t.TypeOf<typeof PriceIO>;
export type ErrorType = t.TypeOf<typeof ErrorIO>;
export type CountryType = t.TypeOf<typeof CountryIO>;

export interface CastBetType {
    channel: string;
    correlationId: string | null;
    country: string | CountryType | null;
    currency: string | CurrencyType | null;
    eachWay: boolean;
    freebetCredits: Array<FreeBetCreditsType> | null;
    freebetRemarks: Array<FreeBetRemarksType> | null;
    id: string;
    ip: string;
    legs: Array<unknown>;
    maxStake: string | null;
    potentialReturns: string | null;
    potentialReturnsEw: string | null;
    totalStake: string | null;
    price?: PriceType | null;
    stakePerLine: string;
    type: string;
    numLines: number;
    errors: Array<ErrorType>;
}
export interface RabMarketSelectionType {
    name: string;
    selectionType: {
        id: string | undefined | null;
        name: string | undefined | null;
        away: number | undefined | null;
        home: number | undefined | null;
        oy: string | undefined | null;
        ou: string | undefined | null;
        team: string | undefined | null;
        yn: string | undefined | null;
        value: string | undefined | null;
    };
}

export interface RabPostBodyItemType {
    selection: RabMarketSelectionType;
    market: {
        marketType: string | undefined;
        name: string | undefined;
        bettable: string | undefined;
        period: string | undefined;
    };
}
