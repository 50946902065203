import { AppState } from 'src/appState/AppState';
import { CarouselEventNavigationState } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigationState';
import { computed, makeObservable } from 'mobx';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { OptionType, sortOptions } from 'src/domains/layouts/webview/components/eventNavigation/common';

export class CompetitionState {

    private readonly appState: AppState;
    private readonly state: CarouselEventNavigationState;

    public constructor(appState: AppState, state: CarouselEventNavigationState) {
        makeObservable(this);
        this.appState = appState;
        this.state = state;
    }

    @computed.struct public get collectionCompetitions(): EventsCollectionQueryModel | null {
        const page = this.state.pageType;

        if (page === null) {
            return null;
        }

        if (page.type === 'sport' || page.type === 'competition') {
            return this.appState.appLayoutsState.getListOfSport(page.sport)?.getQuery() ?? null;
        }

        if (page.type === 'event') {
            const event = this.state.event;

            if (event !== null) {
                return this.appState.appLayoutsState.getListOfSport(event.sport)?.getQuery() ?? null;
            }
        }

        return null;
    }

    @computed public get active(): string | null {
        const page = this.state.pageType;

        if (page === null) {
            return null;
        }

        if (page.type === 'competition') {
            return page.competition.toOldId().toString();
        }

        if (page.type === 'event') {
            const event = this.state.event;
            if (event !== null) {
                return event.competition.toString();
            }
        }

        return null;
    }

    @computed public get competitionsData(): OptionType[] {
        const collectionCompetitions = this.collectionCompetitions;

        if (collectionCompetitions === null) {
            return [];
        }

        const list: Array<OptionType> = [];

        for (const sport of collectionCompetitions.value.sports) {
            for (const competition of sport.competitions) {
                list.push({
                    value: competition.id.toString(),
                    sportId: sport.id,
                    label: competition.name
                });
            }
        }

        list.sort(sortOptions);

        return list;
    };
}
