import { computed, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { PopupState } from 'src/domains/layouts/state/popupState/PopupState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

interface CallbacksType {
    getIsCasinoMiniMobileOverlayOpen: () => boolean;
}

export class Overlay {
    public constructor(
        public readonly starRouter: StarRouter,
        private readonly popupState: PopupState,
        private readonly callbacks: CallbacksType
    ) {
        makeObservable(this);
    }

    @computed public get isAnyPopup(): boolean {
        return this.popupState.isAnyPopup;
    }

    @computed private get isPopupDeleteCard(): boolean {
        if (this.popupState.isPopupDeleteCard) {
            return true;
        }
        return false;
    }

    @computed public get isHamburgerMenuOpen(): boolean {
        const innerWidth = getWindowInnerWidth() ?? 0;

        if (innerWidth < 1200) {
            if (this.starRouter.accountView?.account === 'navigation') {
                return true;
            }
        }

        return false;
    }

    @computed public get shouldOverlayOpenWithIsStatic(): boolean {
        const popup = this.popupState.popupForView;

        if (popup === null) {
            return false;
        }

        if ('overlayVisible' in popup) {
            return popup.overlayVisible;
        }

        //TODO - To remove
        return this.isAnyPopup;
    }

    @computed public get shouldOverlayOpen(): boolean {
        if (this.isHamburgerMenuOpen || this.callbacks.getIsCasinoMiniMobileOverlayOpen()) {
            return true;
        }

        return this.shouldOverlayOpenWithIsStatic;
    }

    @computed public get overlayLevel(): number {
        if (this.isHamburgerMenuOpen) {
            return 11;
        }

        const popup = this.popupState.popupForView;

        if (popup === null) {
            return 0;
        }

        if ('overlayLevel' in popup) {
            //TODO - goal is to remove that condition
            return popup.overlayLevel;
        }

        //TODO - To remove
        if (this.isPopupDeleteCard) {
            return 11;
        }
        return 0;
    }
}
