import { action, computed, observable, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { Session } from 'src_common/sdk/session';
import { BannersBoxState } from 'src/domains/layouts/state/bannersBoxState/BannersBoxState';
import {
    AccountTabsBuilderState,
    TabDataType,
} from 'src/domains/players/webview/components/Account/accountDrawer/AccountTabsBuilder.state';
import { streamButtonBarDesktop } from 'src/domains/sportsbook/shared/Components';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { AppPlayersState } from 'src/domains/players/state/AppPlayers.state';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

export class AccountDrawerState {
    @observable public isMounted = false;

    private animationDuration = 250;
    private animationDelay = 100; // to let components mount correctly and improve UX/performance
    @observable public isDuringForwardAnimation = false;
    @observable public isDuringBackwardAnimation = false;

    // the Tab you are browsing (or the Tab you are leaving)
    @observable.ref public currentTabName: RightHandSideViewType | null = null;

    public constructor(
        private readonly accountTabsBuilderState: AccountTabsBuilderState,
        private readonly router: StarRouter,
        private readonly session: Session,
        private readonly bannersBoxState: BannersBoxState,
        private readonly appPlayersState: AppPlayersState
    ) {
        makeObservable(this);
        this.setCurrentTabName();
    }

    @action public setIsMounted(): void {
        this.isMounted = true;
    }

    // the Tab you just clicked (or the Tab we are going to)
    @computed public get futureTabName(): RightHandSideViewType | null {
        const selected = this.router.accountView;
        if (this.isTabAvailable(selected)) {
            return selected;
        }
        return null;
    }

    private isTabAvailable(tabName: RightHandSideViewType | null): boolean {
        if (tabName !== null) {
            const tabData = this.accountTabsBuilderState.getTabData(tabName);
            if (tabData !== null) {
                if (tabData.isAvailable === true) {
                    return true;
                }
            }
        }
        return false;
    }

    @action private setCurrentTabName = (): void => {
        this.currentTabName = this.futureTabName;
    };

    @computed public get isOpening(): boolean {
        return this.futureTabName !== null;
    }

    @computed public get isOpened(): boolean {
        return this.currentTabName !== null;
    }

    @computed public get isDuringAnimation(): boolean {
        return this.isDuringBackwardAnimation || this.isDuringForwardAnimation;
    }

    @action private goForward = (): void => {
        setTimeout(() => {
            this.isDuringForwardAnimation = true;
            this.isDuringBackwardAnimation = false;
        }, this.animationDelay);
    };

    @action private goBackward = (): void => {
        setTimeout(() => {
            this.isDuringBackwardAnimation = true;
            this.isDuringForwardAnimation = false;
        }, this.animationDelay);
    };

    @action public finishAnimation = (event: React.TransitionEvent): void => {
        if (event.propertyName === 'transform') {
            this.isDuringForwardAnimation = false;
            this.isDuringBackwardAnimation = false;
            this.setCurrentTabName();
        }
    };

    @computed private get shouldTriggerForwardAnimation(): boolean {
        return this.isChildOf(this.futureTabName, this.currentTabName);
    }

    @computed private get shouldTriggerBackwardAnimation(): boolean {
        return this.isParentOf(this.futureTabName, this.currentTabName);
    }

    @computed private get shouldTriggerOpeningAnimation(): boolean {
        return this.isOpening && !this.isOpened;
    }

    @computed private get shouldTriggerClosingAnimation(): boolean {
        return !this.isOpening;
    }

    private isChildOf = (
        childTabName: RightHandSideViewType | null,
        parentTabName: RightHandSideViewType | null
    ): boolean => {
        return this.tabData(childTabName)?.parent === parentTabName?.account;
    };

    private isParentOf = (
        parentTabName: RightHandSideViewType | null,
        childTabName: RightHandSideViewType | null
    ): boolean => {
        return this.tabData(childTabName)?.parent === parentTabName?.account;
    };

    @computed private get isSignUp(): boolean {
        return this.futureTabName?.account === 'signup';
    }

    @computed private get isBetting(): boolean {
        return this.futureTabName?.account === 'betting';
    }

    @computed private get isLogin(): boolean {
        return this.futureTabName?.account === 'login';
    }

    @computed private get isAuthorized(): boolean {
        return this.session.isAuthorized;
    }

    @computed private get isMobile(): boolean {
        const widthInner = getWindowInnerWidth();
        return widthInner !== null && widthInner < 1024;
    }

    private shouldShowHeader(tabName: RightHandSideViewType | null): boolean {
        if (tabName === null) {
            return true;
        }

        const tabData = this.tabData(tabName);

        if (tabData === null) {
            return true;
        }

        return tabData.showHeader ?? true;
    }

    public shouldDisplayMainHeader(tabName: RightHandSideViewType | null): boolean {
        const showHeader = this.shouldShowHeader(tabName);

        const isStaticPageWhileUnauthorized = tabName?.account === 'static' && !this.isAuthorized;
        const isSignUp = this.isSignUp;
        return showHeader && !isStaticPageWhileUnauthorized && !isSignUp;
    }

    private tabData(tabName: RightHandSideViewType | null): TabDataType | null {
        if (this.isTabAvailable(tabName)) {
            const tabData = this.accountTabsBuilderState.getTabData(tabName);
            return tabData;
        }
        return null;
    }

    @computed public get currentTabData(): TabDataType | null {
        if (this.currentTabName !== null) {
            return this.tabData(this.currentTabName) ?? null;
        }
        return null;
    }

    @computed public get futureTabData(): TabDataType | null {
        if (this.futureTabName !== null) {
            return this.tabData(this.futureTabName) ?? null;
        }
        return null;
    }

    @computed public get currentTabDataForHeader(): TabDataType | null {
        const currentTab = this.currentTabData;
        if (currentTab !== null) {
            return currentTab;
        }
        return null;
    }

    @computed public get futureTabDataForHeader(): TabDataType | null {
        const futureTab = this.futureTabData;
        if (futureTab !== null) {
            return futureTab;
        }
        return null;
    }

    public get animationDurationInSeconds(): number {
        return this.animationDuration / 1000;
    }

    @action public onNewTabRequest = (): void => {
        if (this.isDuringAnimation) {
            return;
        }

        if (this.shouldTriggerOpeningAnimation || this.shouldTriggerForwardAnimation) {
            this.goForward();
            return;
        }
        if (this.shouldTriggerClosingAnimation || this.shouldTriggerBackwardAnimation) {
            this.goBackward();
            return;
        }

        // animate "forward" if jumps to another tab-branch
        this.goForward();
    };

    @computed public get areTabsDiffer(): boolean {
        return this.currentTabName !== this.futureTabName;
    }

    @computed public get topOffset(): number {
        let bannersHeight = this.bannersBoxState.boxHeight;
        if (this.isMobile) {
            let mobileHeaderHeight = 48;

            if (this.isLogin || this.isBetting || this.isSignUp) {
                mobileHeaderHeight = 0;
            }

            const streamHeight =
                this.appPlayersState.streamViewDetails().boxHeight > 0
                    ? this.appPlayersState.streamViewDetails().isFloating
                        ? 0
                        : this.appPlayersState.streamViewDetails().boxHeight + streamButtonBarDesktop
                    : 0;
            bannersHeight += streamHeight + mobileHeaderHeight;
        }
        return bannersHeight;
    }
}
