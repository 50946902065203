import { action, computed, observable, makeObservable } from 'mobx';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { FiltersStateStrategy, FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { assertNever } from 'src_common/common/assertNever';

export class RacingFilterStrategy implements FiltersStateStrategy {

    @observable public activeFilterId: number | null = -1;

    public constructor(
        private getFiltersCallback: () => FilterType[] | null,
        private onChangeCallback: (id: number | null) => void
    ) {
        makeObservable(this);
    }

    @computed.struct public get filters(): FilterType[] {
        return this.getFiltersCallback() ?? [];
    }

    public getActiveFilterId(): number | null {
        return this.activeFilterId;
    }

    @action public setActiveFilterId(id: string | number | null): void {
        if (id === null) {
            this.activeFilterId = null;
        } else {
            // eslint-disable-next-line
            this.activeFilterId = Number(id);
        }

        this.onChangeCallback(this.activeFilterId);
    }

    @action public resetActiveFilterId(): void {
        this.activeFilterId = null;
    }

}

type FilterFunctionType = (event: EventModel) => boolean;

const noFilter = (): boolean => true;

export interface RacingFilterType extends FilterType {
    isTomorrow: boolean;
}

export class RacingFilterGroupState {
    public readonly getCollection: () => EventsCollectionList;
    public readonly language: LanguagesState;

    public readonly todayFilterStrategy: RacingFilterStrategy;
    public readonly tomorrowFilterStrategy: RacingFilterStrategy;

    public constructor(
        getCollection: () => EventsCollectionList,
        private readonly onChange: (value: 'all' | 'tomorrow' | 'today') => void,
        language: LanguagesState
    ) {
        makeObservable(this);
        this.getCollection = getCollection;
        this.language = language;

        this.todayFilterStrategy = new RacingFilterStrategy(
            () => this.competitionTodayFilters,
            () => {
                this.tomorrowFilterStrategy.resetActiveFilterId();
                this.onChange('today');
            }
        );

        this.tomorrowFilterStrategy = new RacingFilterStrategy(
            () => this.competitionTomorrowFilters,
            () => {
                this.todayFilterStrategy.resetActiveFilterId();
                this.onChange('tomorrow');
            }
        );
    }

    @computed public get competitionTodayFilters(): RacingFilterType[] {
        const collection = this.getCollection();

        return computed(() => [
            { id: -1, key: 'next-off', label: this.language.getTranslation('filters.next-off', 'Next Off'), isTomorrow: false },
            ...(collection.competitionIdsByDisplayOrderAndDay ?? [])
                .filter(item => item.isTomorrow === false)
                .map(item => ({ id: item.id, key: String(item.id), label: item.name, isTomorrow: item.isTomorrow }))
                .sort((o1, o2) => (o1.label).localeCompare(o2.label))
        ]).get();
    }

    @computed public get competitionTomorrowFilters(): RacingFilterType[] {
        const collection = this.getCollection();

        return computed(() => [
            ...(collection.competitionIdsByDisplayOrderAndDay ?? [])
                .filter(item => item.isTomorrow === true)
                .map(item => ({ id: item.id, key: String(item.id), label: item.name, isTomorrow: item.isTomorrow }))
                .sort((o1, o2) => (o1.label).localeCompare(o2.label))
        ]).get();
    }

    @computed public get filterByCompetition(): FilterFunctionType {
        const todayCompetitionId = this.todayFilterStrategy.getActiveFilterId();
        const tomorrowCompetitionId = this.tomorrowFilterStrategy.getActiveFilterId();

        if (todayCompetitionId === -1) {
            return noFilter;
        }

        return (event: EventModel): boolean => event.competition === todayCompetitionId || event.competition === tomorrowCompetitionId;
    }

    @computed private get filterByTomorrowDate(): FilterFunctionType {
        return (event: EventModel): boolean => event.timeMatchTomorrow === true;
    }

    // In today section should be also past events which are not finished yet
    @computed private get filterByTodayAndPast(): FilterFunctionType {
        return (event: EventModel): boolean => event.timeMatchTomorrow === false;
    }

    @computed private get filterEventToday(): FilterFunctionType {
        const filters = [
            this.filterByCompetition,
            this.filterByTodayAndPast
        ];
        return (event: EventModel): boolean => {
            for (const filter of filters) {
                if (filter(event) === false) {
                    return false;
                }
            }

            return true;
        };
    }

    @computed private get filterEventTomorrow(): FilterFunctionType {
        const filters = [
            this.filterByCompetition,
            this.filterByTomorrowDate
        ];
        return (event: EventModel): boolean => {
            for (const filter of filters) {
                if (filter(event) === false) {
                    return false;
                }
            }

            return true;
        };
    }

    public isEventShouldBeDisplayed(selectedList: 'all' | 'today' | 'tomorrow', event: EventModel): boolean {
        if (selectedList === 'all') {
            return true;
        }

        if (selectedList === 'today') {
            return this.filterEventToday(event);
        }

        if (selectedList === 'tomorrow') {
            return this.filterEventTomorrow(event);
        }

        return assertNever('isEventShouldBeDisplayed -> selectedList', selectedList);
    }
}
