import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { HeaderWithMovieWrapper, EventName, EventInfo, EventWrapper } from 'src/domains/layouts/webview/components/heroEvents/HomepageHeroWidget.style';
import { EventRowNFL } from 'src/domains/sportsbook/shared/Components';
import { HomepageWidgetNFLHeader } from './HomepageWidgetNFLHeader';
import { HeroWidgetModelType } from 'src/api/config/cms_new/hero_widget/get_hero_widget_active';
import { HeroWidgetItemState } from 'src/domains/layouts/webview/components/heroEvents/HeroWidgetItem.state';
import { HeroWidgetBanner } from 'src/domains/layouts/webview/components/heroEvents/HeroWidgetBanner';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsTypes {
    heroWidgetItem: HeroWidgetModelType;
    smallEvent: EventListGroupEventItemType;
}

export const HomepageHeroWidgetNFL = observer('HomepageHeroWidgetNFL', ({ heroWidgetItem, smallEvent }: PropsTypes): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState, starRouter } = appLayoutsState;

    const [heroWidgetItemState] = useState(() => new HeroWidgetItemState(starRouter, heroWidgetItem));
    const { headerImages, promoImages, marketModel } = heroWidgetItemState;

    const { event_id, is_active_promo, title, subtitle, id, tc_text, tc_title } = heroWidgetItem;

    if (marketModel !== null) {
        return (
            <React.Fragment key={marketModel.id}>
                <>
                    <HeaderWithMovieWrapper banners={headerImages} data-test='widget-banner'>
                        <EventWrapper>
                            <EventName data-test='hero-widget-title'>{title}</EventName>
                            <EventInfo data-test='hero-widget-subtitle'>{subtitle}</EventInfo>
                        </EventWrapper>

                    </HeaderWithMovieWrapper>
                    <>
                        {breakpointsState.desktop.isBiggerOrEq === true ?
                            <HomepageWidgetNFLHeader /> : null}
                        <EventRowNFL smallEvent={smallEvent} eventId={event_id} />
                    </>
                </>
                {is_active_promo ? <HeroWidgetBanner withMargin={false} banners={promoImages} slug={`${id}-hero-promo`} content={tc_text ?? ''} title={tc_title ?? ''} /> : null}
            </React.Fragment>
        );

    }
    return null;
});
