import React from 'react';
import { ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';
import { ScrollLeft, ScrollRight } from 'src/domains/layouts/webview/components/common/slider/SliderArrow.style';

type DirectionType = 'left' | 'right';

interface SliderArrowProps {
    slideInDirection: (direction: DirectionType) => void;
    direction: DirectionType;
}

export const SliderArrow = (props: SliderArrowProps): JSX.Element => {
    const { slideInDirection, direction } = props;

    return (
        <>
            {direction === 'left' && (
                <ScrollLeft
                    isVisible={direction === 'left'}
                    onClick={(): void => slideInDirection(direction)}
                >
                    <ButtonChevronIcon position='left' />
                </ScrollLeft>
            )}

            {direction === 'right' && (
                <ScrollRight
                    isVisible={direction === 'right'}
                    onClick={(): void => slideInDirection(direction)}>
                    <ButtonChevronIcon position='right' />
                </ScrollRight>
            )}
        
        </>
    );
};
