import * as React from 'react';
import { MoreLessGroup, LinkMoreLessWrapper, ChevronIconStyle } from './ExpandButton.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PropsTypes {
    isExpanded: boolean,
    toggleExpand(e: React.MouseEvent): void
}

export const ExpandButton = (props: PropsTypes): JSX.Element => (
    <MoreLessGroup onClick={props.toggleExpand}>
        <LinkMoreLessWrapper >
            {props.isExpanded
                ? <I18n langKey='events.selections-group.less' defaultText='Less' />
                : <I18n langKey='events.selections-group.more' defaultText='More' />}

            <ChevronIconStyle position={props.isExpanded ? 'down' : 'right'} />

        </LinkMoreLessWrapper>
    </MoreLessGroup>
);
