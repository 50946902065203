import { action, computed, observable, makeObservable } from 'mobx';
import { ActiveHomePageCarouselType } from 'src/api/config/cms/getActiveHomePageCarousel';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { AppCasinoState } from 'src/domains/casino/shared/Types';

const TERMS_LENGTH_LIMIT = 140;

const SelectionDetailsIO = t.interface({
    id: t.number,
    label: t.string,
});

const isSelectionDetails = createGuard(t.array(SelectionDetailsIO));

type SelectionDetailsType = t.TypeOf<typeof SelectionDetailsIO>;

type ActionTemplate = 'direct-link' | 'event-id' | 'sport' | 'competition-id' | 'casino-game-id';

export class HomePageCarouselItemState {
    @observable public isShowTC: boolean = false;

    public constructor(
        private readonly promo: ActiveHomePageCarouselType,
        private readonly router: StarRouter,
        private readonly appCasinoState: AppCasinoState,
    ) {
        makeObservable(this);
    }

    @computed public get actionTemplate(): ActionTemplate | null {
        const actionTemplate = this.promo.action_template ?? null;

        if (
            'direct-link' === actionTemplate ||
            'event-id' === actionTemplate ||
            'sport' === actionTemplate ||
            'competition-id' === actionTemplate ||
            'casino-game-id' === actionTemplate
        ) {
            return actionTemplate;
        }

        return null;
    }

    @computed public get btnLink(): string | null {
        const btn_link = this.promo.btn_link ?? null;
        return btn_link === '' ? null : btn_link;
    }

    @computed public get selectionDetails(): Array<SelectionDetailsType> {
        const selectionDetails = this.promo.selections_details;
        if (isSelectionDetails(selectionDetails)) {
            return selectionDetails;
        }
        return [];
    }


    @action public handleRedirect = (): void => {
        const { event_id, competition_id, sport, game_id } = this.promo;
        const event_id_view = event_id ?? null;
        const competition_id_view = competition_id ?? null;
        const casino_game_id_view = game_id ?? null;
        const sport_view = sport ?? '';

        if (this.actionTemplate === 'sport' && sport_view !== '') {
            this.router.redirectToSportPage(sport_view);
            return;
        }

        if (this.actionTemplate === 'event-id' && event_id_view !== null) {
            this.router.redirectToEvent(event_id_view);
            return;
        }

        if (this.actionTemplate === 'competition-id' && competition_id_view !== null && sport_view !== '') {
            this.router.redirectToCompetitionPage(competition_id_view, sport_view);
            return;
        }

        if (this.actionTemplate === 'casino-game-id' && casino_game_id_view !== null) {
            const gameLink = casino_game_id_view;
            this.appCasinoState.gameStoreExt.getGameModel(gameLink)?.startGame();
            return;
        }

        if (event_id_view !== null) {
            return this.router.redirectToEvent(event_id_view);
        }
    };

    @computed public get isTooLongTerms(): boolean {
        const txText = this.promo.tc_text ?? null;
        if (txText !== null) {
            const text = txText.replaceAll('&nbsp;', ' ');
            return text.length > TERMS_LENGTH_LIMIT;
        }

        return false;
    }

    @action public onApplyTC = (): void => {
        if (this.isTooLongTerms) {
            this.router.redirectToCarouselPromoTerms(this.promo.id);
        } else {
            this.isShowTC = true;
        }
    };
}
