import { observable, computed, makeObservable, action } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { SportRacesTypeParamType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { EventsCollectionState } from 'src/domains/sportsbook/shared/Types';
import { Value } from 'src_common/common/mobx-utils/Value';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';

export class HorseRacingSelectedNew {
    private data: Value<Set<EventId>>;

    public constructor() {
        this.data = new Value(new Set());
    }

    public toggleEvent = (eventId: EventId): void => {
        const currentSelect = this.data.getValue();

        if (currentSelect.has(eventId)) {
            currentSelect.delete(eventId);
        } else {
            currentSelect.add(eventId);
        }

        this.data.setValue(currentSelect);
    };

    public cleanSelected = (): void => {
        this.data.setValue(new Set());
    };

    public findSelectIds = (): Array<EventId> => {
        return Array.from(this.data.getValue().values());
    };

    public isSelected = (eventId: EventId): boolean => {
        const current = this.data.getValue();
        return current.has(eventId);
    };

    public racesSelected(): number {
        const data = this.data.getValue();
        return data.size;
    }
}

export class HorseRacingState {
    @observable public showBuildCardRaces = false;
    @observable public sportFormUrl = undefined;
    public readonly selectedNew: HorseRacingSelectedNew = new HorseRacingSelectedNew();
    @observable public isBuilding = false;
    @observable public sport: string | null = null;

    public refElem: HTMLElement | null = null;

    public constructor(
        public readonly sportFromUrl: 'horseracing' | 'greyhoundracing',
        private readonly eventsCollection: EventsCollectionState,
        private readonly configComponents: ConfigComponents,
        private readonly starRouter: StarRouter,
    ) {
        makeObservable(this);
    }

    @action public toggleEvent = (eventId: EventId): void => {
        this.isBuilding = true;
        this.selectedNew.toggleEvent(eventId);
    };

    @action public cleanSelected = (activeTab: SportRacesTypeParamType): void => {
        this.isBuilding = false;
        this.selectedNew.cleanSelected();
        this.starRouter.redirectToRace(this.sportFromUrl, activeTab, null);
    };

    @computed public get showTrapChallenge(): boolean {
        const trapChallenge = this.configComponents.config.trapChallenge;

        return this.sportFromUrl === 'greyhoundracing' && trapChallenge === true && this.trapChallengeEvents !== null && this.trapChallengeEvents.length > 0;
    };

    @computed public get collectionEventsGreyhoundQuery(): Array<EventModel> | null {
        const out: Array<EventModel> = [];
        const eventsList = this.eventsCollection.listOfSportWithMonthRange('greyhoundracing');

        for (const id of eventsList.ids2) {
            const event = id.getEventModel();

            if (event !== null) {
                out.push(event);
            }
        }

        return out;
    }

    @computed public get trapChallengeEvents(): Array<EventModel> | null {
        const greyhoundracingEvents = this.collectionEventsGreyhoundQuery;

        const filteredByTrapChallengeTag = greyhoundracingEvents?.filter(eventItem => {
            const condition = eventItem.markets.filter(marketItem => {
                return marketItem.trapChallengeTag === 'yes';
            });
            if (condition.length > 0 ) {
                return eventItem;
            }
        }) ?? null;

        return filteredByTrapChallengeTag;
    }

    @computed public get collectionEvents(): Array<EventModel> | null {
        const out: Array<EventModel> = [];
        const listHorseracingSpecials = this.eventsCollection.listHorseracingSpecials();

        for (const id of listHorseracingSpecials.ids2) {
            const event = id.getEventModel();

            if (event !== null) {
                out.push(event);
            }
        }

        return out;
    }

    @computed public get showSpecials(): boolean {
        return this.sportFromUrl === 'horseracing' && this.collectionEvents !== null && this.collectionEvents.length > 0;
    };

    public setRef = (ref: HTMLElement | null): void => {
        this.refElem = ref;
    };

    public handleScroll = (): void => {
        const isAntePostReverse = this.configComponents.config.isAntePostReverse;

        if (this.refElem !== null && isAntePostReverse) {
            window.scrollTo({
                top: this.refElem.offsetTop - 52,
                behavior: 'smooth'
            });
        }
    };
}
