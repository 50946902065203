import { action, computed, observable, makeObservable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { WithdrawSteps, WithdrawStepsType } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawJourney';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Amount } from 'src_common/common/amount/Amount';
import { validateAmountRequire, validateAmountPattern, validateMinAmount, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { SelectedOption } from 'src/domains/players/webview/components/form/selectNew/SelectNew';
import { TrpcClient } from 'src/appState/TrpcClient';
import { zenetPayValidation } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/zenetPayBillingInfoFieldsValidation';

enum PixKeyEnum {
    cpf = 'cpf',
    email = 'email',
    mobile = 'phone'
}
export class WithdrawZenetPayFormState {

    @observable public withdrawErrorMessage: string | null = null;
    @observable public isWithdrawFormSubmitting = false;

    public stepsState: WithdrawSteps;

    public amountState: FormInputState<string,Amount>;
    public pixKeyOption: FormInputState<string,string>;

    public constructor(
        private readonly usersState: UsersState,
        private readonly language: LanguagesState,
        private readonly trpClient: TrpcClient,
        private readonly googleTagManager: GoogleTagManagerState,
        private readonly minWithdraw: Amount,
    ) {
        makeObservable(this);
        this.stepsState = new WithdrawSteps();

        this.amountState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(validateMinAmount(this.minWithdraw, () => usersState.moneySymbol, 'ERROR_MIN_WITHDRAW'));

        this.pixKeyOption = FormInputState.new('')
            .map(validateRequire);
    }

    @computed public get pixKeyState (): FormInputState<string,string> {
        const userData = this.usersState.basicData.valueReady;

        if (this.pixKeyOption.value === PixKeyEnum.email){
            return FormInputState.new(userData?.email ?? '').map(validateRequire);
        }

        if (this.pixKeyOption.value === PixKeyEnum.mobile){
            return FormInputState.new(userData?.mobilePhone?.number ?? '').map(validateRequire);

        }
        if (this.pixKeyOption.value === PixKeyEnum.cpf){
            return FormInputState.new(userData?.cpf ?? '' ).map(validateRequire);
        }

        return FormInputState.new('').map(validateRequire);

    };

    @computed public get isPixKeyState(): boolean {
        return this.pixKeyState.value === '';
    };

    @computed public get pixKeyStateLabel(): string {
        if (this.pixKeyOption.value === PixKeyEnum.email){
            return this.language.getTranslation('account.pix-key.email', 'Email');
        }
        if (this.pixKeyOption.value === PixKeyEnum.mobile){
            return this.language.getTranslation('account.pix-key.mobile', 'Mobile');
        }
        if (this.pixKeyOption.value === PixKeyEnum.cpf){
            return this.language.getTranslation('account.pix-key.cpf', 'CPF');
        }
        return '';
    };

    @computed public get showBalance(): string | null {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
        return null;
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.amountState.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.amountState.setValue('');
        }
        return this.amountState.setValue(amount.toFixed(2));
    };

    public handlePaymentAdditionsChange = (amount: Amount): void => {
        this.amountState.setValue(amount.value);
        this.amountState.setAsVisited();
    };

    @computed public get pixKeyOptions (): SelectedOption[] {
        return [
            { label: this.language.getTranslation('account.pix-key.cpf', 'CPF') , value: PixKeyEnum.cpf },
            { label: this.language.getTranslation('account.pix-key.email', 'Email') , value: PixKeyEnum.email },
            { label: this.language.getTranslation('account.pix-key.mobile', 'Mobile' ) , value: PixKeyEnum.mobile },
        ];
    }

    @action public submitWithdrawForm = async (): Promise<void> => {
        this.amountState.setAsVisited();

        this.isWithdrawFormSubmitting = true;
        this.withdrawErrorMessage = null;

        try {

            if (this.isExceededWithdrawalBalance){
                console.error('Exceeded withdrawal balance');
                throw new Error();
            }

            if (this.amountState.result.value.type === 'error'|| this.pixKeyOption.result.value.type === 'error' || this.pixKeyState.result.value.type === 'error') {
                console.error('Error in input');
                throw new Error();
            }

            const walletData = await this.usersState.walletData.fetch();
            const withdrawableBalance = new Amount(walletData.withdrawableBalance);
            if (this.amountState.result.value.data.isGreaterThan(withdrawableBalance)){
                this.withdrawErrorMessage = this.language.getTranslation('errors.withdrawable-balance-at-least', 'You have exceeded your withdrawable balance.');
                return;
            }


            const userData = zenetPayValidation(this.usersState.basicData.valueReady);

            if (userData?.type !== 'ok'){
                console.error('No user data');
                throw new Error();
            }

            const { fullName, email, cpf } = userData.data;

            const response = await this.trpClient.client.zenetPay.initiateWithdrawal.mutate({
                body: {
                    amount: this.amountState.result.value.data.value,
                    cpf,
                    name: fullName,
                    email,
                    pixKey: this.pixKeyState.result.value.data,
                    pixKeyType: this.pixKeyOption.result.value.data,
                    description: 'Withdrawal Initiate',
                    webhookUri: 'https://webhook.site/f39b11c7-2a9a-4311-bc37-0be32165bbab'
                }
            });

            if (response.responseStatus === 'error'){
                throw new Error();
            }

            // eslint-disable-next-line no-restricted-globals
            this.googleTagManager.addWithdrawnTag(Number(this.amountState.value));
            this.amountState.reset();
            this.stepsState.redirectToPending();
            this.isWithdrawFormSubmitting = false;
        } catch (e) {
            this.isWithdrawFormSubmitting = false;
            this.stepsState.redirectToFailureView('serverIssue');
        }
    };

    @computed public get isExceededWithdrawalBalance(): boolean {

        if ( this.amountState.result.value.type === 'error' ) {
            return false;
        }

        const walletData = this.usersState.walletData.valueReady;

        if (walletData === null) {
            return true;
        }

        const withdrawableBalance = new Amount(walletData.withdrawableBalance);
        const amountValue = this.amountState.result.value.data;

        if (amountValue.isGreaterThan(withdrawableBalance)){
            return true;
        }

        return false;
    }

    @computed public get isButtonDisabled(): boolean {
        const isAmountInputOK = this.amountState.result.value.type === 'error';
        const isPixKeyOptionInputOK = this.pixKeyOption.result.value.type === 'error';
        const isPixKeyStatetInputOK = this.pixKeyState.result.value.type === 'error';

        return isAmountInputOK || isPixKeyOptionInputOK || isPixKeyStatetInputOK;
    }

    @computed public get currentStep(): WithdrawStepsType {
        return this.stepsState.step;
    }

}
