//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    requestBody: {
        amount: number;
        assignee?: null | undefined | {
            id: number;
            name: string;
            type: string;
        };
        channel?: null | undefined | string;
        displayed_reason?: null | undefined | string;
        payment_method?: null | undefined | {
            expires?: null | undefined | string;
            id?: null | undefined | string;
            lastUsedAt?: null | undefined | string;
            name?: null | undefined | string;
            number?: null | undefined | string;
            provider: string;
            providerReference?: null | undefined | string;
            subtype?: null | undefined | string;
            type: string;
        };
        status: 'requested' | 'paid' | 'failed' | 'rejected';
        tags?: null | undefined | {
            accountAdjustment?: null | undefined | boolean;
            adjustmentType?: null | undefined | string;
            tradingAdjustment?: null | undefined | boolean;
        };
    };
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/customer/withdrawals -> post -> ${code}`);
    };
};

export const Response200IO = t.union([t.interface({
    errors: t.array(t.interface({
        code: t.string,
        debugDetails: t.string,
        field: t.string,
        resource: t.string,
    })),
    status: t.literal('422'),
    type: t.literal('WithdrawalsErrorResponse'),
}), t.interface({
    data: t.interface({
        accountId: t.union([t.null, t.undefined, t.string]),
        amount: t.number,
        assignee: t.union([t.null, t.undefined, t.interface({
            id: t.number,
            name: t.string,
            type: t.string,
        })]),
        channel: t.union([t.null, t.undefined, t.string]),
        createdAt: t.union([t.null, t.undefined, t.string]),
        currency: t.string,
        customerName: t.union([t.null, t.undefined, t.string]),
        displayedReason: t.union([t.null, t.undefined, t.string]),
        finalizedAt: t.union([t.null, t.undefined, t.string]),
        id: t.number,
        internalReason: t.union([t.null, t.undefined, t.string]),
        paymentMethod: t.union([t.null, t.undefined, t.interface({
            expires: t.union([t.null, t.undefined, t.string]),
            id: t.union([t.null, t.undefined, t.string]),
            lastUsedAt: t.union([t.null, t.undefined, t.string]),
            name: t.union([t.null, t.undefined, t.string]),
            number: t.union([t.null, t.undefined, t.string]),
            provider: t.string,
            providerReference: t.union([t.null, t.undefined, t.string]),
            subtype: t.union([t.null, t.undefined, t.string]),
            type: t.string,
        })]),
        paymentResponse: t.union([t.null, t.undefined, t.string]),
        status: t.union([t.literal('requested'), t.literal('paid'), t.literal('failed'), t.literal('rejected')]),
        tags: t.interface({
            accountAdjustment: t.union([t.null, t.undefined, t.boolean]),
            adjustmentType: t.union([t.null, t.undefined, t.string]),
            tradingAdjustment: t.union([t.null, t.undefined, t.boolean]),
        }),
        type: t.union([t.null, t.undefined, t.string]),
        updatedAt: t.union([t.null, t.undefined, t.string]),
        url: t.string,
        walletTransactionUrl: t.string,
    }),
    status: t.literal('201'),
    type: t.literal('WithdrawalsSuccessResponse'),
})]);
export type Response200Type = {
    errors: Array<{
        code: string;
        debugDetails: string;
        field: string;
        resource: string;
    }>;
    status: '422';
    type: 'WithdrawalsErrorResponse';
} | {
    data: {
        accountId?: null | undefined | string;
        amount: number;
        assignee?: null | undefined | {
            id: number;
            name: string;
            type: string;
        };
        channel?: null | undefined | string;
        createdAt?: null | undefined | string;
        currency: string;
        customerName?: null | undefined | string;
        displayedReason?: null | undefined | string;
        finalizedAt?: null | undefined | string;
        id: number;
        internalReason?: null | undefined | string;
        paymentMethod?: null | undefined | {
            expires?: null | undefined | string;
            id?: null | undefined | string;
            lastUsedAt?: null | undefined | string;
            name?: null | undefined | string;
            number?: null | undefined | string;
            provider: string;
            providerReference?: null | undefined | string;
            subtype?: null | undefined | string;
            type: string;
        };
        paymentResponse?: null | undefined | string;
        status: 'requested' | 'paid' | 'failed' | 'rejected';
        tags: {
            accountAdjustment?: null | undefined | boolean;
            adjustmentType?: null | undefined | string;
            tradingAdjustment?: null | undefined | boolean;
        };
        type?: null | undefined | string;
        updatedAt?: null | undefined | string;
        url: string;
        walletTransactionUrl: string;
    };
    status: '201';
    type: 'WithdrawalsSuccessResponse';
};
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiProxyCustomerWithdrawalsRequest"
 */
export const openapi_proxy_customer_withdrawals = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/customer/withdrawals${query}`;
    
    const method = 'POST';
    const paramsFetch = {
        url,
        body: params.requestBody,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiProxyCustomerWithdrawalsParamsType = ParamsType;

export type OpenapiProxyCustomerWithdrawalsResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiProxyCustomerWithdrawalsResponse200Type = Response200Type;

export const openapiProxyCustomerWithdrawalsRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiProxyCustomerWithdrawalsResponseType> => {
    const response = await openapi_proxy_customer_withdrawals(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiProxyCustomerWithdrawalsRequest - unhandled response ${response.status}`);
};

