import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const BetSection = withConfig(theme => styled('div', { label: 'BetSection' })`
    border-bottom: 1px solid ${theme.star.betHistory.borderColor};
    color: ${theme.star.betHistory.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin-right: -7px;
    padding: 16px 0;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        padding-bottom: 0;
    }
`);

export const BetTypeWrapper = withConfig(theme => styled('h4', { label: 'BetTypeWrapper' })`
    color: ${theme.star.betHistory.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    margin: 0 0 4px 0;
`);

export const BetSingleLegResultWrapper = styled('div', { label: 'BetSingleLegResultWrapper' })`
    display: flex;
    align-items: center;
`;

export const BetId = withConfig(theme => styled('div', { label: 'BetId' })`
    color: ${theme.star.betHistory.txtColorQuaternary};
`);

export const BetLegSelectionName = withConfig(theme => styled('h4', { label: 'BetLegSelectionName' })<{ isSingle: boolean }>`
    color: ${theme.star.betHistory.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.3334;
    margin: ${({ isSingle }):string => isSingle === true ? '0 0 8px 0' : '0' };
`);

export const BetLegMarketName = withConfig(theme => styled('div', { label: 'BetLegMarketName' })`
    color: ${theme.star.betHistory.txtColorTertiary};
    line-height: 1.4;
`);

export const BetLeg = styled('div', { label: 'BetLeg' })<{ isSingle: boolean }>`
    margin: ${({ isSingle }):string => isSingle === true ? '0' : '0 0 8px 0' };
`;

export const BetDetailsWrapper = styled('div', { label: 'BetDetailsWrapper' })`
    display: inline-block;
    vertical-align: top;
    width: 50%;
`;

export const BetColumn = withConfig(theme => styled('div', { label: 'BetColumn' })` // stake and return value
    color: ${theme.star.betHistory.txtColorSecondary};
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    vertical-align: top;
    width: 25%;
`);
