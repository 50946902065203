import { computed, observable, action, makeObservable } from 'mobx';
import * as t from 'io-ts';
import { jsonParse } from 'src_common/common/jsonParse';
import { createGuard } from 'src_common/common/createGuard';
import { Value } from 'src_common/common/mobx-utils/Value';
import { Amount } from 'src_common/common/amount/Amount';
import { Session } from 'src_common/sdk/session';
import { DataTimeDuration } from 'src_common/utils/time/time';
import { SocketConnection, SocketConnectionController, SocketEventType } from './SocketConnection';
import { RedirectState } from './Redirect.state';
import { ConfigState } from './ConfigState';

const NetProfitIO = t.interface({
    action: t.literal('NetPosition'),
    totalLoss: t.number,
    totalProfit: t.number,
    session: t.interface({
        accountId: t.number,
        createdAt: t.string,
        gameId: t.number,
        id: t.number,
    })
});

const SessionInitiatedIO = t.interface({
    action: t.literal('SessionInitiated'),
    session: t.interface({
        accountId: t.number,
        createdAt: t.string,
        gameId: t.number,
        id: t.number,
    })
});

const SessionAlreadyExistIO = t.interface({
    action: t.literal('SessionAlreadyExist'),
    session: t.interface({
        accountId: t.number,
        createdAt: t.string,
        gameId: t.number,
        id: t.number,
    })
});

const ConnectionClosedIO = t.interface({
    action: t.literal('ConnectionClosed'),
});

const KnownMessageIO = t.union([NetProfitIO, SessionInitiatedIO, SessionAlreadyExistIO, ConnectionClosedIO]);

const isKnownMessage = createGuard(KnownMessageIO);

type MessageTo = unknown;

const getCurrentTime = (): number => {
    return new Date().getTime();
};

const currentTime = new Value(getCurrentTime(), (setValue) => {
    const timer = setInterval((): void => {
        setValue(getCurrentTime());
    }, 1000);

    return (): void => {
        clearInterval(timer);
    };
});

class GameStatusInnerState {
    private readonly socketController: SocketConnectionController<MessageTo>;
    private isSessionJustInited: boolean = false;

    @observable private startTime: string | null = null;
    @observable private totalWins: Amount = new Amount('0');
    @observable private totalLoss: Amount = new Amount('0');
    private totalTempWins: Amount = new Amount('0');
    private totalTempLoss: Amount = new Amount('0');

    public constructor(
        private readonly config: ConfigState,
        private readonly websocket_casino_host: string,
        private readonly gameId: number,
        private readonly userId: number,
        private readonly session: Session,
        private readonly redirectState: RedirectState,
        private readonly validateSession: (gameId?: number) => void,
    ) {
        makeObservable(this);
        this.socketController = SocketConnection.startSocket(
            `Casino game ${this.gameId} statistic for user ${this.userId}`,
            `${this.websocket_casino_host}${this.userId}`,
            10000,
            this.onSocketMessageCallback,
        );
    }

    public dispose(): void {
        this.socketController.dispose();
    }

    private onSocketMessageCallback = (event: SocketEventType<MessageTo>): void => {
        if (event.type === 'socket') {

            if (this.startTime === null) {
                this.socketController.send(
                    {
                        gameId: this.gameId,
                        jwt: this.session.currentJwt,
                        action: 'initSession'
                    }
                );
                this.isSessionJustInited = true;
            }
            return;
        }

        const messageJson = jsonParse(event.message);

        if (messageJson.type === 'json') {
            this.processMessage(messageJson.json);
        } else {
            console.info('unhandled message text', messageJson.text);
        }
    };


    @action private processMessage = (socketMessage: unknown): void => {
        if (isKnownMessage(socketMessage)) {
            console.info('socketMessage.action', socketMessage.action);
            if (socketMessage.action === 'SessionAlreadyExist') {
                if (this.isSessionJustInited) {
                    this.validateSession();
                }
                this.validateSession(socketMessage.session.gameId);
            }
            if (socketMessage.action === 'SessionInitiated') {
                this.validateSession(socketMessage.session.gameId);

                if (
                    this.isSessionJustInited === false &&
                    this.startTime !== null &&
                    socketMessage.session.gameId === this.gameId
                ) {
                    this.validateSession();
                }
                this.startTime = socketMessage.session.createdAt;
            }
            if (socketMessage.action === 'NetPosition') {
                this.totalWins = this.totalTempWins;
                this.totalLoss = this.totalTempLoss;
                this.totalTempWins = this.config.precision.newFromAnything(socketMessage.totalProfit);
                this.totalTempLoss = this.config.precision.newFromAnything(socketMessage.totalLoss);
            }

            this.isSessionJustInited = false;
        }
    };

    @computed.struct private get startGameTime(): number | null {
        if (this.startTime === null) {
            return null;
        }

        const time = new Date(this.startTime);
        return time.getTime();
    }

    @computed public get gameDurationCounter(): string {
        let hoursForView = '00';
        let minuterForView = '00';
        let secondsForView = '00';

        if (this.startGameTime !== null && this.startGameTime > 0) {
            const diffInMilliseconds = Math.floor(currentTime.getValue() - this.startGameTime);
            const duration = DataTimeDuration.from(diffInMilliseconds);

            const seconds = duration.seconds();
            const minutes = duration.minutes();
            const hours = duration.hours();

            if (hours > 0 || minutes > 0 || seconds > 0) {
                hoursForView = hours.toString().padStart(2, '0');
                minuterForView = minutes.toString().padStart(2, '0');
                secondsForView = seconds.toString().padStart(2, '0');
            }
        }

        return `${hoursForView}:${minuterForView}:${secondsForView}`;
    }

    @computed public get netPositionForView(): string {
        return this.totalWins.sub(this.totalLoss).format(this.redirectState.getCurrency());
    }
}

export class GameStatusState {
    @observable private gameStatusInnerStateValue: Value<null | GameStatusInnerState>;

    public constructor(
        private readonly config: ConfigState,
        private websocket_casino_host: string,
        private readonly gameId: number,
        private userId: number,
        private readonly session: Session,
        private readonly redirectState: RedirectState,
        private validateSession: (gameId?: number) => void,
    ) {
        makeObservable(this);

        this.gameStatusInnerStateValue = new Value<null | GameStatusInnerState>(null, (setValue) => {
            const state = new GameStatusInnerState(
                this.config, this.websocket_casino_host, this.gameId, this.userId, this.session, this.redirectState, this.validateSession
            );

            setValue(state);

            return (): void => {
                setValue(null);
                state.dispose();
            };
        });
    }

    @computed private get gameStatusInnerState(): GameStatusInnerState | null {
        return this.gameStatusInnerStateValue.getValue();
    }

    @computed public get gameDurationCounter(): string {
        return this.gameStatusInnerState?.gameDurationCounter ?? 'n/a';
    }

    @computed public get netPositionForView(): string {
        return this.gameStatusInnerState?.netPositionForView ?? 'n/a';
    }

    public closeSocket(): void {
        this.gameStatusInnerState?.dispose();
    }
}
