interface ReferencePointType {
    time: number;
    slide: number;
}

interface ParamsType {
    slidesTime: number;
    slidesQuantity: number;
    currentTime: number;
    referencePoint: ReferencePointType;
    jumpIndicator: number;
}

const movesForewardIterator = (
    slidesTime: number,
    currentTime: number,
    referencePoint: ReferencePointType,
): number => {
    return Math.floor((currentTime - referencePoint.time) / slidesTime);
};

export const currentSlide = (params: ParamsType): number => {
    const { slidesTime, slidesQuantity, currentTime, referencePoint, jumpIndicator } = params;
    const movesForewardIteratorValue = movesForewardIterator(slidesTime, currentTime, referencePoint);
    const slidesQuantityTest = Math.ceil(slidesQuantity / jumpIndicator);
    const current = (movesForewardIteratorValue + referencePoint.slide + 10 * slidesQuantityTest) % slidesQuantityTest;

    return current * jumpIndicator;
};
