import { Value } from 'src_common/common/mobx-utils/Value';

class Wrapper {
    public constructor(
        public readonly window: Window
    ) {}
}

const mobxValue = new Value<Wrapper | null>(null, (setValue) => {
    if (typeof window === 'undefined') {
        setValue(null);
        return (): void => {};
    }

    setValue(new Wrapper(window));

    const onResize = (): void => {
        setValue(new Wrapper(window));
    };

    window.addEventListener('resize', onResize);

    return (): void => {
        window.removeEventListener('resize', onResize);
    };
});

export const getWindowInnerWidth = (): null | number => {
    const wrapper = mobxValue.getValue();
    if (wrapper === null) {
        return null;
    }

    return wrapper.window.innerWidth;
};

export const getWindowInnerHeight = (): null | number => {
    const wrapper = mobxValue.getValue();
    if (wrapper === null) {
        return null;
    }

    return wrapper.window.innerHeight;
};
