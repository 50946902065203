import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { UserIcon } from 'src/domains/layouts/shared/assets/icons/UserIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

const renderOccupiedSeatsIcon = (largeVersion: boolean): SerializedStyles => {
    if (largeVersion) {
        return css`
            position: relative;
            top: 1px;
            width: 12px;
        `;
    }

    return css`
        position: relative;
        top: 1px;
        width: 9px;
    `;
};


interface OccupiedSeatsIconPropsType {
    largeVersion: boolean;
}

export const OccupiedSeatsIcon = withConfig(theme => styled(UserIcon, { label: 'OccupiedSeatsIcon' })<OccupiedSeatsIconPropsType>`
    ${({ largeVersion }): SerializedStyles => renderOccupiedSeatsIcon(largeVersion)};
    fill: ${theme.star.casino.txtColor};
`);


const renderOccupiedSeatsWrapper = (theme: EmotionTheme, largeVersion: boolean): SerializedStyles => {
    if (largeVersion) {
        return css`
            font-size: ${theme.star.fontSize.xRegular};
            height: 25px;
            line-height: 1.43;
            padding: 2px 10px;
        `;
    }

    return css`
        font-size: ${theme.star.fontSize.xSmall};
        height: 18px;
        line-height: 1.4;
        padding: 1px 5px;
    `;
};


interface OccupiedSeatsWrapperPropsType {
    largeVersion: boolean;
}

export const OccupiedSeatsWrapper = withConfig(theme => styled('div', { label: 'OccupiedSeatsWrapper' })<OccupiedSeatsWrapperPropsType>`
    backdrop-filter: blur(1.5px);
    background: ${theme.star.casino.liveCasino.bgColorTertiary};
    border-radius: 9px;
    border: solid 1px ${theme.star.casino.liveCasino.borderColor};
    color: ${theme.star.casino.txtColor};
    font-weight: ${theme.star.fontWeight.medium};
    position: absolute;
    right: 4px;
    text-align: right;
    top: 4px;
    ${({ largeVersion }): SerializedStyles => renderOccupiedSeatsWrapper(theme, largeVersion)};
`);
