import { DateInputSelectState } from './DateInputSelect.state';
import { computed, makeObservable } from 'mobx';
import { DateTime } from 'src_common/utils/time/time';

export class DateInputState {
    public readonly day: DateInputSelectState;
    public readonly month: DateInputSelectState;
    public readonly year: DateInputSelectState;

    public constructor(dateValue: DateTime) {
        makeObservable(this);
        this.day = new DateInputSelectState(dateValue.getDayOfMonth());
        this.month = new DateInputSelectState(dateValue.getMonth());
        this.year = new DateInputSelectState(dateValue.getYear());
    }

    @computed public get dateString(): string {
        return DateTime.strictFormattedYearMonthDay(this.year.value, this.month.value + 1, this.day.value, 'YYYY-M-D').format('YYYY-MM-DD');
    }
}
