import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

const CompetitionModelIO = t.interface({
    id: t.number,
    idv2: t.string,
    name: t.string,
    sport: t.string,
    displayOrder: t.number,
    updatedAt: t.string
});

export type CompetitionModelType = t.TypeOf<typeof CompetitionModelIO>;

export const isCompetitionModel = createGuard(CompetitionModelIO);

export const decodeCompetitionModel = buildValidator('CompetitionModel', CompetitionModelIO);

export type CompetitionsResponseProxy = Array<{
    active: boolean;
    autoTakeDown: boolean;
    display: boolean;
    displayOrder: number;
    grades?: null | undefined | {
        inPlay?: null | undefined | {
            id: number;
            name: string;
        };
        prematch?: null | undefined | {
            id: number;
            name: string;
        };
        show?: null | undefined | {
            id: number;
            name: string;
        };
    };
    id: number;
    idV2?: null | undefined | string;
    inPlayDelay?: null | undefined | number;
    name: string;
    tags: Record<string, string>;
}>;

export type CompetitionTypeProxy = CompetitionsResponseProxy[number];

