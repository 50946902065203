import styled from '@emotion/styled';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { PageSectionWrapper } from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { withConfig } from 'src/withConfig';

export const VerificationFailedWrapper = styled(PageSectionWrapper, { label: 'VerificationFailedWrapper' })`
    text-align: center;
`;

export const WarningTriangleIconWrapper = styled(WarningIcon, { label: 'WarningTriangleIconWrapper' })`
    position: absolute;
    top: 22px;
    right: 24px;
    width: 32px;
    fill: currentcolor;
`;

export const WarningTriangle = withConfig(theme => styled('div', { label: 'WarningTriangle' })`
    position: relative;
    margin: 160px auto 24px;
    width: 80px;
    height: 80px;
    background-color: ${theme.star.signUp.bgColorSecondary};
    border-radius: 50px;
`);

export const WarningTriangleGamStop = styled(WarningTriangle, { label: 'WarningTriangleGamStop' })`
    margin: 196px auto 16px;
`;

export const EmailLink = withConfig(theme => styled('a', { label: 'EmailLink' })`
    color: ${theme.star.signUp.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
`);

export const HighlightedTxt = withConfig(theme => styled('span', { label: 'HighlightedTxt' })`
    color: ${theme.star.signUp.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
`);

