import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { computed, makeObservable } from 'mobx';
import { getTeamShirt } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNBA/NBATeamLogos';
import { AmericanSportMatchLeadStateStrategy } from 'src/domains/sportsbook/webview/components/matchLead/americanSportMatchLead/AmericanSportMatchLeadStateStrategy';
import { DateTime } from 'src_common/utils/time/time';

export class MatchLeadNBAState implements AmericanSportMatchLeadStateStrategy {

    public constructor(
        private readonly event: EventModel
    ) {
        makeObservable(this);
    }

    @computed public get isStarted(): boolean {
        return this.event.timeSettingsStarted;
    }

    @computed private get startedTime(): DateTime | null {
        return DateTime.from(this.event.timeSettingsStartTime);
    }

    @computed public get timeToStart(): string | null {
        if (this.isStarted === false) {
            const startedTime = this.startedTime;
            const date = startedTime?.format('ddd Do MMM');
            const time = startedTime?.format('HH:mm');
            if (date === undefined || time === undefined) {
                return null;
            }
            return `${date} | ${time}`;
        }

        return null;
    }

    @computed public get homeTeamName(): string {
        return this.getNonBreakingName(this.event.homeParticipant ?? '');
    }

    @computed public get awayTeamName(): string {
        return this.getNonBreakingName(this.event.awayParticipant ?? '');
    }

    private getNonBreakingName(teamName: string): string {
        const nbsp = '\u00A0';
        switch (teamName) {
            case 'Los Angeles Lakers':
                return `Los${nbsp}Angeles Lakers`;
            case 'New Orleans Pelicans':
                return `New${nbsp}Orleans Pelicans`;
            case 'Golden State Warriors':
                return `Golden${nbsp}State Warriors`;
            case 'Oklahoma City Thunder':
                return `Oklahoma${nbsp}City Thunder`;
            case 'Los Angeles Clippers':
                return `Los${nbsp}Angeles Clippers`;
            case 'Portland Trail Blazers':
                return `Portland Trail${nbsp}Blazers`;
            case 'San Antonio Spurs':
                return `San${nbsp}Antonio Spurs`;
            case 'New York Knicks':
                return `New${nbsp}York Knicks`;
            default:
                const splittedName = teamName.split(' ');
                if (splittedName.length > 2) {
                    splittedName.splice(1, 0, nbsp);
                    splittedName.splice(3, 0, ' ');
                    return splittedName.join('');
                }
                return teamName;
        }
    }

    public get homeTeamLogo():string {
        return getTeamShirt(this.homeTeamName);
    }

    public get awayTeamLogo():string {
        return getTeamShirt(this.awayTeamName);
    }

    @computed public get homeScore(): string {
        return this.event.scoreHome?.toString() ?? '0';
    }

    @computed public get awayScore(): string {
        return this.event.scoreAway?.toString() ?? '0';
    }


}
