import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    PopupWrapper,
    PopupWhoopsImg,
    PopupInformationWrapper,
    PopupInformationButton,
    PopupInformationBold,
} from './MultipleSlotsPopup.styles';
import { useAppStateContext } from 'src/appState/AppState';
import { UniverseComponent } from 'src/domains/common/universes';

export const MultipleSlotsPopup = observer('MultipleSlotsPopup', () => {
    const { appLayoutsState } = useAppStateContext();
    const { popupState } = appLayoutsState;

    return (
        <PopupWrapper>
            <UniverseComponent>
                {(universeModule): React.ReactElement => <PopupWhoopsImg src={universeModule.whoops} />}
            </UniverseComponent>
            <PopupInformationWrapper>
                <I18n
                    langKey='popups.casino.different-game-open.info1'
                    defaultText='Looks like you&rsquo;re trying to play'
                />
                <PopupInformationBold>
                    <I18n langKey='popups.casino.different-game-open.info2' defaultText=' multiple slots ' />
                </PopupInformationBold>
                <I18n
                    langKey='popups.casino.different-game-open.info3'
                    defaultText='at the same time. Please close any open games before starting a new one.'
                />
            </PopupInformationWrapper>

            <PopupInformationButton onClick={popupState.hide} type='button' size='medium'>
                <I18n langKey='popups.casino.different-game-open.button' defaultText='Ok got it' />
            </PopupInformationButton>
        </PopupWrapper>
    );
});
