import { action, computed, observable, makeObservable } from 'mobx';
import { BetSlipFilter, BetSlipState } from 'src/domains/sportsbook/betting/state/BetSlipState';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';

interface CallbacksType {
    onRedirectToBetslip: () => void;
}

export class BetWidgetsState {
    @observable public isBetslipWidgetVisible: boolean | null;

    public constructor(
        private readonly betSlipState: BetSlipState,
        private readonly rab: RabState,
        private readonly callbacks: CallbacksType
    ) {
        makeObservable(this);
        this.isBetslipWidgetVisible = null;
    }

    @computed public get isIndicatorVisible(): boolean {
        const { legsIds2, referralState } = this.betSlipState;
        const { activeBets } = this.rab;
        const { isReferred } = referralState;

        const bets = legsIds2.length + activeBets.length;
        return isReferred ? false : bets > 0;
    }

    @computed public get isRemoveAllAvailable(): boolean {
        const { referralState, selectedTab } = this.betSlipState;
        const { isReferred } = referralState;
        return !isReferred && selectedTab === BetSlipFilter.BETSLIP && this.isIndicatorVisible;
    }

    @computed public get isMobile(): boolean {
        const widthInner = getWindowInnerWidth();
        return widthInner !== null && widthInner < 1024;
    }

    @action public removeAllTemporary = (): void => {
        const { betSlipSummaryState, onRemoveAllLegs, cleanAllLifeSpanSelections } = this.betSlipState;
        const { clearError } = betSlipSummaryState;

        cleanAllLifeSpanSelections();
        onRemoveAllLegs();
        this.rab.removeAllFromBetslip();
        clearError();
    };

    @action public openMyBetsNew = (): void => {
        this.isBetslipWidgetVisible = true;
        this.betSlipState.changeBetslipTab('MY_BETS');
        this.betSlipState.changeSelectedFilterToOpen();
        this.callbacks.onRedirectToBetslip();
    };

    @action public openMyBetsFromReceipt = (): void => {
        this.betSlipState.betSlipSummaryState.onClearMessage();
        this.rab.closeRab();
        this.openMyBetsNew();
    };
}
