import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { deburr } from 'src/utils/deburr';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const checkAddress = (address: string): boolean => {
    return /^[a-zA-Z0-9 "#$'()*+,\-.:;=?@\[\]^_`\{|}~]*$/.test(deburr(address));
};

export const validateAddress = (value: string): Result<string> => {
    const maxLength = 100;

    if (value.length > maxLength) {
        const errorLengthMessage = getErrorByCode('ERROR_MAX_LENGTH').replace('%length', maxLength.toString());
        return Result.createError(errorLengthMessage);
    }

    if (checkAddress(deburr(value))) {
        return Result.createOk(value);
    }

    const errorRequiredField = getErrorByCode('ERROR_INVALID_ADDRESS');
    return Result.createError(errorRequiredField);
};
