import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { assertNever } from 'src_common/common/assertNever';
import { useAppStateContext } from 'src/appState/AppState';
import { RealexJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexJourney';
import { SwiftyGlobalJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalJourney';
import { InfoPage } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/temporaryInfo/InfoPage';
import { ZenetPayJourney } from './zenetPayProvider/ZenetPayJourney';
import { PraxisJourney } from './praxisProvider/PraxisJourney';
import { PaysafeJourney } from './paysafeProvider/PaysafeJourney';

export const DepositProcedureProvider = observer('DepositProcedureProvider', (): JSX.Element => {
    const {
        appLayoutsState: {
            configComponents: { config },
        },
    } = useAppStateContext();
    const { usePaymentFormForUniverse, temporaryDepositPage, accountHelperMail } = config;

    if (temporaryDepositPage) {
        return <InfoPage accountHelperMail={accountHelperMail} infoPageType='deposit' />;
    } else if (usePaymentFormForUniverse.type === 'zenetPay') {
        return <ZenetPayJourney />;
    } else if (usePaymentFormForUniverse.type === 'realex') {
        return <RealexJourney isSignup={false} />;
    } else if (usePaymentFormForUniverse.type === 'swiftyGlobalProvider') {
        return <SwiftyGlobalJourney isSignup={false} />;
    } else if (usePaymentFormForUniverse.type === 'praxis') {
        return <PraxisJourney isSignup={false} />;
    } else if (usePaymentFormForUniverse.type === 'paysafe') {
        return <PaysafeJourney isSignup={false} paysafePaymentProvider={usePaymentFormForUniverse} />;
    }

    return assertNever('usePaymentFormForUniverse', usePaymentFormForUniverse);
});
