import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { renderBetTypeName } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/betUtils';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface PropsTypes {
    bet: BetHistoryType,
}

export const BetCombinedTypeStakeStatus = observer<PropsTypes>('BetCombinedTypeStakeStatus', ({ bet }) => {
    const appState = useAppStateContext();
    const { appLayoutsState, appPlayersState } = appState;
    const { usersState } = appPlayersState;
    const { configComponents } = appLayoutsState;
    const { status } = bet;

    return (
        <div>
            {`${renderBetTypeName(bet.type, appState)} ${usersState.money(configComponents.precision.newFromAnything(bet.stakePerLine))} ${status}`}
        </div>
    );
});



