import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface ChatIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const ChatIcon = observer('ChatIcon', ({ className, onClick }: ChatIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M8.176 1h11.492C21.508 1 23 2.417 23 4.162v8.901c0 1.745-1.491 3.168-3.332 3.168h-.899v3.384l-3.95-3.162c-.133-.1-.376-.222-.926-.222H8.547c-1.835 0-3.316-1.423-3.316-3.173V4.162C5.23 2.417 6.34 1 8.176 1zm4.913 16.553c.545 0 .788.143.926.238l1.37.978c-.238 1.09-1.529 1.693-2.745 1.693H7.89c-.417 0-.597.084-.697.158L4.385 23v-2.538h-.862C2.132 20.462 1 19.637 1 18.314v-6.816C1 10.176 2.052 9.09 3.443 9.09h.095v5.331c0 1.75 1.576 3.13 3.412 3.13h6.14z'
                fill='fill'
                fillRule='evenodd'
            />
        </SvgElement>
    );
});
