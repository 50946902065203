import { action, makeObservable } from 'mobx';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { DebugPanelSizeType, SelectTabIdType } from './MainViewTypes';

export class DebugPanelState {
    public openTab: LocalStorageItemState<SelectTabIdType>;
    public size: LocalStorageItemState<DebugPanelSizeType>;

    public constructor(openTab: LocalStorageItemState<SelectTabIdType>, size: LocalStorageItemState<DebugPanelSizeType>) {
        makeObservable(this);
        this.openTab = openTab;
        this.size = size;
    }

    @action public close(): void {
        this.openTab.setValue(null);
    }

    @action public show(): void {
        this.openTab.setValue('main');
    }

    public get isVisible(): boolean {
        return this.openTab.getValue() !== null;
    }

    @action public changeTab = (openTab: SelectTabIdType): void => {
        this.openTab.setValue(openTab);
    }

    public get activeTab(): SelectTabIdType {
        return this.openTab.getValue();
    }
}



