export const redactUrl = (url: string) => {
    const redactedUrl = redactCustomerPath(url);

    return redactedUrl
}

const redactCustomerPath = (url: string) => {
    const forbidden = 'customer?';

    const index = url.indexOf(forbidden)
    if (index === -1) {
        return url;
    }

    return url.slice(0, index + forbidden.length) + '<redacted>'
}