import { SearchIcon } from 'src/domains/layouts/shared/assets/icons/SearchIcon';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

interface SidebarSearchWrapperType {
    isSearchBarDesktopOpened: boolean;
}

export const SearchWrapper = withConfig(theme => styled('div', { label: 'SearchWrapper' })`
    @media ${theme.star.mediaQuery.largeDesktop} {
        background: ${theme.star.searchBar.bgColorQuaternary};
    }
`);

export const SearchInput = withConfig(theme => styled('input', { label: 'SearchInput' })<SidebarSearchWrapperType>`
    background-color: ${theme.star.searchBar.bgColorTertiary};
    border: 2px solid transparent;
    color: ${theme.star.searchBar.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    height: 40px;
    line-height: 1.43;
    margin: 0;
    padding: 8px 44px 8px 12px;
    transition: .2s ease border-color;
    width: 100%;
    &::placeholder {
        color: ${theme.star.searchBar.txtColorTertiary};
        opacity: 1;
    }
    &:focus {
        border: 2px solid ${theme.star.searchBar.borderColor};
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        background-color: ${({ isSearchBarDesktopOpened }): string => isSearchBarDesktopOpened ? theme.star.searchBar.bgColorTertiary : theme.star.searchBar.bgColor};
        border-radius: 0;
        &::placeholder {
            color: ${theme.star.searchBar.txtColorQuaternary};
        }
    }
`);

interface InputInnerWrapperType {
    isSearchBarDesktopOpened: boolean;
}

export const InputInnerWrapper = withConfig(theme => styled('div', { label: 'InputInnerWrapper' })<InputInnerWrapperType>`
    position: relative;
    transition: padding .2s ease;
    @media ${theme.star.mediaQuery.largeDesktop} {
        ${({ isSearchBarDesktopOpened }): string => isSearchBarDesktopOpened ? 'padding: 4px 8px 12px 48px' : ''};
    }
`);

interface LinkOverlayType {
    isSearchResultsOpened?: boolean;
    isSearchBarDesktopOpened?: boolean;
    isMobileOpen?: boolean;
}

export const LinkOverlay = withConfig(theme => styled(Link, { label: 'LinkOverlay' })<LinkOverlayType>`
    background-color: ${theme.star.overlay.bgColor};
    bottom: 0;
    content: '';
    cursor: pointer;
    left: 0;
    max-width: 375px;
    opacity: ${({ isSearchResultsOpened, isMobileOpen }): number => (isSearchResultsOpened === true && isMobileOpen === true) ? 1 : 0};
    position: fixed;
    top: 0;
    transition: opacity .2s ease;
    visibility: ${({ isSearchResultsOpened, isMobileOpen }): string => (isSearchResultsOpened === true && isMobileOpen === true) ? 'visible !important' : 'hidden'};
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.below};
    @media ${theme.star.mediaQuery.mobile} {
        max-width: 80%;
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        max-width: 100%;
        opacity: ${({ isSearchBarDesktopOpened }): number => isSearchBarDesktopOpened === true ? 1 : 0};
        visibility: ${({ isSearchBarDesktopOpened }): string => isSearchBarDesktopOpened === true ? 'visible' : 'hidden'};
    }
`);

const iconPosition = (theme: EmotionTheme, isSearchBarDesktopOpened: boolean): SerializedStyles => css`
    bottom: 4px;
    fill: ${theme.star.searchBar.bgColorSenary};
    margin: auto 0;
    opacity: 1;
    position: absolute;
    right: 16px;
    top: 4px;
    width: 16px;
    z-index: ${theme.star.zIndexLevelBase.hightLevel};
    @media ${theme.star.mediaQuery.largeDesktop} {
        bottom: 12px;
        fill: ${isSearchBarDesktopOpened ? theme.star.searchBar.bgColorSenary : theme.star.searchBar.bgColorQuinary};
        ${isSearchBarDesktopOpened ? '' : 'top: 12px'};
    }
`;

export const SearchIconWrapper = withConfig(theme => styled(SearchIcon, { label: 'SearchIconWrapper' })`
    ${({ isSearchBarDesktopOpened = false }): SerializedStyles => iconPosition(theme, isSearchBarDesktopOpened)};
    pointer-events: none;
`);

export const CloseIconWrapper = withConfig(theme => styled(CloseIcon, { label: 'CloseIconWrapper' })`
    ${({ isSearchBarDesktopOpened = false }): SerializedStyles => iconPosition(theme, isSearchBarDesktopOpened)};
    cursor: pointer;
`);

interface SearchResultsWrapperType {
    isSearchResultsOpened: boolean;
}

export const SearchResultsWrapper = withConfig(theme => styled('div', { label: 'SearchResultsWrapper' })<SearchResultsWrapperType>`
    background-color: ${theme.star.searchBar.bgColorTertiary};
    left: 0;
    max-height: 348px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    padding: 16px 0 0 0;
    position: absolute;
    right: 0;
    top: 48px;
    z-index: ${theme.star.zIndexLevelBase.hightLevelUp};
    @media ${theme.star.mediaQuery.largeDesktop} {
        ${({ isSearchResultsOpened }): string => isSearchResultsOpened ? `
            max-height: initial;
            position: static;
    ` : ''};
    }
`);

export const SearchResultsHeader = withConfig(theme => styled('h2', { label: 'SearchResultsHeader' })`
    color: ${theme.star.searchBar.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    margin: 0 0 0 16px;
`);

export const NotFoundItem = withConfig(theme => styled('p', { label: 'NotFoundItem' })`
    color: ${theme.star.searchBar.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.43;
    margin: 8px 16px 24px;
`);

export const ContentResultsList = styled('nav', { label: 'ContentResultsList' })`
    margin: 8px 0 16px;
`;

interface ResultListItemPropsType {
    isElementActive: boolean;
}

export const ResultListItem = withConfig(theme => styled(Link, { label: 'ResultListItem' })<ResultListItemPropsType>`
    color: ${theme.star.searchBar.txtColorSecondary};
    display: block;
    padding: 8px 16px;
    position: relative;
    text-decoration: none;
    z-index: ${theme.star.zIndexGlobal.base};
    &::before {
        background-color: ${theme.star.searchBar.bgColorSecondary};
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity .2s ease;
        width: 100%;
        z-index: ${theme.star.zIndexGlobal.below};
    }
    ${({ isElementActive }): string => isElementActive
        ? `
            &::before {
                opacity: 1;
            }`
        : ''};
    &:hover {
        &::before {
            opacity: 1;
        }
    }
`);

export const ResultsItemTime = withConfig(theme => styled('span', { label: 'ResultsItemTime' })`
    color: ${theme.star.searchBar.txtColorTertiary};
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin-top: 2px;
`);
