import * as React from 'react';
import { AppCasinoState, CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { LikeAndInfo } from './like-and-info/GameLikeAndInfo';
import { ImageButtonsWrapper, OverlayWrapper, PlayButton } from './ThumbnailOverlay.style';

interface PropsType {
    appCasinoState: AppCasinoState;
    gameModel: CasinoGameModelExt;
    showLikeAndInfo: boolean;
    handleOnMobileClick: () => void;
    handlePlayClick?: () => void;
    handleInfoClick?: () => void;
}

export const ThumbnailOverlay = observer('ThumbnailOverlay', (props: PropsType) => {
    const { appCasinoState, gameModel, showLikeAndInfo, handleOnMobileClick, handlePlayClick, handleInfoClick } = props;

    const isMobile = appCasinoState.breakpointsState.tablet.isBiggerOrEq === false;
    const detectFeatures = appCasinoState.env.modernizrDetect;
    const isTouchEventsAvailable = detectFeatures !== null && detectFeatures.touchevents === true;

    if (isMobile || isTouchEventsAvailable) {
        return <OverlayWrapper isMobile={true} onClick={handleOnMobileClick} />;
    }

    return (
        <OverlayWrapper>
            <ImageButtonsWrapper>
                <PlayButton onClick={handlePlayClick ?? ((): void => gameModel.startGame())} data-test='play-button'>
                    <I18n
                        langKey='casino-home.slider.game.play-button'
                        defaultText='Play Now'
                    />
                </PlayButton>
                {showLikeAndInfo ?
                    <LikeAndInfo
                        isFavourite={gameModel.isFavourite}
                        gameId={gameModel.id}
                        onHeartClick={gameModel.handleLikeAction}
                        onInfoClick={handleInfoClick}
                    /> : null}
            </ImageButtonsWrapper>
        </OverlayWrapper>
    );
});
