import { RefObject } from 'react';
import { ChatMessageType } from 'src_server/trpc/types/chat';

export class MessagesListState {
    public scrolled: boolean = false;
    public keepScrolled: boolean = false;

    public constructor(public readonly viewportRef: RefObject<HTMLUListElement>) {}

    public scrollDownIfNeeded = (messages: ChatMessageType[]): void => {
        const viewport = this.viewportRef.current;
        const hasMessages = messages.length > 0;

        if (viewport !== null && hasMessages) {
            const wasScrolled = this.scrolled === true;
            const isNewOwnMessage = messages[messages.length - 1]?.sender.type === 'customer';

            if (wasScrolled === false || isNewOwnMessage || this.keepScrolled) {
                this.scrolled = true;
                viewport.scrollTop = viewport.scrollHeight;
            }
        }
    };

    public handleScroll = (): void => {
        const viewport = this.viewportRef.current;
        if (viewport !== null) {
            // 5% of viewport.clientHeight
            const marginOfErrorPx = Math.round((5 / 100) * viewport.clientHeight);
            if (viewport.scrollTop + viewport.clientHeight >= viewport.scrollHeight - marginOfErrorPx) {
                this.keepScrolled = true;
            } else {
                this.keepScrolled = false;
            }
        }
    };
}
