import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface SelectionRabItemMenuItemType {
    isActive: boolean;
}

export const SelectionRabItemMenuItem = withConfig(theme => styled('li', { label: 'SelectionRabItemMenuItem' })<SelectionRabItemMenuItemType>`
    background: ${({ isActive }): string => isActive === true ? theme.star.marketGroupGoalscorer.filters.bgColorSecondary : 'transparent'};
    border: 1px solid ${theme.star.marketGroupGoalscorer.filters.borderColor};
    color: ${({ isActive }): string => isActive === true ? theme.star.marketGroupGoalscorer.filters.txtColorSecondary : theme.star.marketGroupGoalscorer.filters.txtColor};
    transition: .2s ease background-color, .2s ease color;
    font-weight: ${theme.star.fontWeight.medium};
    font-size: ${theme.star.fontSize.small};
    padding: 7px 12px;
    margin-right: 4px;
    cursor: pointer;
    line-height: 1.3334;
    &:hover {
        color: ${theme.star.marketGroupGoalscorer.filters.txtColorSecondary};
        background: ${theme.star.marketGroupGoalscorer.filters.bgColorSecondary};
    }
    &:not(:last-of-type) {
        margin-right: 4px !important;
    }
`);
