import * as t from 'io-ts';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { UserAttributionsLocalStorageObjectType } from 'src/domains/layouts/state/userAttributionState/UserAttributionsLocalStorage';

const decodeIO = t.interface({
    value: t.union([t.interface({ 
        ajs_uid: t.union([t.string, t.undefined]),
        ajs_event: t.union([t.string, t.undefined]),
        ajs_aid:  t.union([t.string, t.undefined]),
        ajs_prop: t.union([t.interface({ name: t.string, value: t.string }), t.null, t.undefined]),
        ajs_trait:  t.union([t.interface({ name: t.string, value: t.string }), t.null, t.undefined]),
        utm_id:  t.union([t.string, t.undefined]),
        utm_source:  t.union([t.string, t.undefined]),
        utm_medium: t.union([t.string, t.undefined]),
        utm_campaign:  t.union([t.string, t.undefined]),
        utm_term:  t.union([t.string, t.undefined]),
        utm_content:  t.union([t.string, t.undefined]),
        utm_channel:  t.union([t.string, t.undefined]),
        utm_custom:  t.union([t.interface({ name: t.string, value: t.string }), t.null, t.undefined]),  }),
    t.null]),
    expiryDate: t.union([t.number, t.null]),
    clientId: t.union([t.string, t.null]),
    incomeaccess: t.union([t.string, t.null]),
});

const decode = buildValidator('localStorageUserAttributions.decodeIO', decodeIO, true);

export const localStorageUserAttributions = (withAutorun: boolean, key: string): LocalStorageItemState<UserAttributionsLocalStorageObjectType> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: {
            value: null,
            expiryDate: null,
            clientId: null,
            incomeaccess: null,
        }
    });
};
