import { z } from 'zod';

export const InputSchema = z.object({
    externalCustomerId: z.string(),
    body: z.object({
        merchantRefNum: z.string(),
        paymentMethod: z.string().array(),
    }),
});

export type CreateSingleUseCustomerTokenPaysafeInput = z.infer<typeof InputSchema>;

export const SuccessResponseSchema = z.object({
    data: z.object({
        id: z.string().optional().nullable(),
        timeToLiveSeconds: z.number().optional().nullable(),
        status: z.string().optional().nullable(),
        singleUseCustomerToken: z.string(),
        locale: z.string().optional().nullable(),
        firstName: z.string().optional().nullable(),
        lastName: z.string().optional().nullable(),
        dateOfBirth: z
            .object({
                year: z.number().optional().nullable(),
                month: z.number().optional().nullable(),
                day: z.number().optional().nullable(),
            })
            .optional()
            .nullable(),
        email: z.string().optional().nullable(),
        phone: z.string().optional().nullable(),
        ip: z.string().optional().nullable(),
        customerId: z.string().optional().nullable(),
    }),
});

type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            message: z.string().optional().nullable(),
        })
    ),
});

type TErrorResponse = z.infer<typeof ErrorResponseSchema>;

export type TResponseType =
    | { responseStatus: 'success'; response: TSuccessResponse }
    | { responseStatus: 'error'; data: TErrorResponse };
