import * as React from 'react';
import {
    MoreLessGroup,
    LinkMoreLessWrapper,
    ChevronIconWrapper,
} from 'src/domains/sportsbook/webview/components/expandButton/ExpandButton.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PropsTypes {
    isExpanded: boolean;
    isHomepage?: boolean;
    toggleExpand(e: React.MouseEvent): void;
}

export const ExpandButton = (props: PropsTypes): JSX.Element => (
    <MoreLessGroup isHomepage={props.isHomepage}>
        <LinkMoreLessWrapper onClick={props.toggleExpand}>
            {props.isExpanded ? (
                <I18n
                    langKey='events.selections-group.less'
                    defaultText='Less'
                />
            ) : (
                <I18n
                    langKey='events.selections-group.more'
                    defaultText='More'
                />
            )}

            <ChevronIconWrapper position={props.isExpanded ? 'up' : 'down'} />
        </LinkMoreLessWrapper>
    </MoreLessGroup>
);
