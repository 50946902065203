
/**
 * @deprecated - Please use EventId or MarketId or SelectionId
 */

export namespace TypeId {
    const prefixMarket = 'market__id__';

    export type MarketId = `market__id__${number}_${number}`;

    /**
     * @deprecated
     */
    export const notRecommendedWayToCreateMarketId = (marketId: number): MarketId => {
        return `market__id__${0}_${marketId}`;
    };

    export const newMarketId = (eventId: number, marketId: number): MarketId => {
        return `market__id__${eventId}_${marketId}`;
    };

    const getEventIdFromMarket = (id: MarketId): number => {
        const chunks = id.substr(prefixMarket.length).split('_');
        return parseInt(chunks[0] ?? '', 10);
    };

    const getMarketIdFromMarket = (id: MarketId): number => {
        const chunks = id.substr(prefixMarket.length).split('_');
        return parseInt(chunks[1] ?? '', 10);
    };

    const prefixSelection = 'selection__id__';

    export type SelectionId = `selection__id__${number}_${number}_${number}`;

    export const newSelectionId = (eventId: number, marketId: number, selectionId: number): SelectionId => {
        return `selection__id__${eventId}_${marketId}_${selectionId}`;
    };

    const getEventIdFromSelection = (id: SelectionId): number => {
        const chunks = id.substr(prefixSelection.length).split('_');
        return parseInt(chunks[0] ?? '', 10);
    };

    const getMarketIdFromSelection = (id: SelectionId): number => {
        const chunks = id.substr(prefixSelection.length).split('_');
        return parseInt(chunks[1] ?? '', 10);
    };

    const getSelectionIdFromSelection = (id: SelectionId): number => {
        const chunks = id.substr(prefixSelection.length).split('_');
        return parseInt(chunks[2] ?? '', 10);
    };


    const isMarketId = (data: MarketId | SelectionId): data is MarketId => {
        return data.startsWith(prefixMarket);
    };

    export const getEventId = (id: MarketId | SelectionId): number => {
        if (isMarketId(id)) {
            return getEventIdFromMarket(id);
        }

        return getEventIdFromSelection(id);
    };

    export const getMarketId = (id: MarketId | SelectionId): number => {
        if (isMarketId(id)) {
            return getMarketIdFromMarket(id);
        }

        return getMarketIdFromSelection(id);
    };

    export const getSelectionId = (id: SelectionId): number => getSelectionIdFromSelection(id);
}
