

import * as React from 'react';

import { useAppStateContext } from 'src/appState/AppState';

import { EventRowTitle, MobileParticipant } from 'src/domains/sportsbook/webview/components/events/row/Participant.style';

import { observer } from 'src/utils/mobx-react';

import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';



interface ParticipantPropsType {

    smallEvent: EventListGroupEventItemType;

}



export const Participant = observer('Participant', (props: ParticipantPropsType) => {

    const { smallEvent } = props;

    const { appLayoutsState } = useAppStateContext();

    const { breakpointsState } = appLayoutsState;



    const eventModel = smallEvent.id2.getEventModel();



    if (eventModel === null) {

        return (

            <EventRowTitle>{smallEvent.name}</EventRowTitle>

        );

    }



    if ((breakpointsState.tablet.isBiggerOrEq === true) || (eventModel.homeParticipant === null && eventModel.awayParticipant === null)) {

        return (

            <EventRowTitle>{smallEvent.name}</EventRowTitle>

        );

    }



    let homeParticipant = eventModel.homeParticipant;

    let awayParticipant = eventModel.awayParticipant;



    if (eventModel.sport === 'americanfootball' || eventModel.sport === 'basketball') {

        const splitedEventName = smallEvent.name.split('vs');

        homeParticipant = splitedEventName[0] ?? eventModel.homeParticipant;

        awayParticipant = splitedEventName[1] ?? eventModel.awayParticipant;

    }



    return (

        <>

            <MobileParticipant>

                {homeParticipant}

            </MobileParticipant>

            <MobileParticipant>

                {awayParticipant}

            </MobileParticipant>

        </>

    );

});

