import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { NotificationsListWrapper } from 'src/domains/layouts/webview/components/notificationsPage/NotificationsPage.style';
import { Notification } from 'src/domains/layouts/webview/components/notificationsList/Notification';
import { observer } from 'src/utils/mobx-react';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { NotificationsState, PromoNotificationsModelForView } from 'src/domains/layouts/state/notificationsState/NotificationsState';

interface PropsTypes {
    isNotificationsList: boolean;
}

class State {
    public constructor(
        private readonly notificationsState: NotificationsState,
        private readonly props: PropsTypes
    ) {}

    public get notificationsList(): Array<PromoNotificationsModelForView> {
        const innerWidth = getWindowInnerWidth();

        if ((innerWidth !== null && innerWidth < 1024) && this.props.isNotificationsList === false) {
            return [];
        }
        return this.notificationsState.notifications_right_promo_sorted;
    }
}

export const NotificationsList = observer('NotificationsList', (props: PropsTypes) => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, notificationsState } = appLayoutsState;
    const { config } = configComponents;

    const [state] = useState(() => new State(notificationsState, props));

    if (!config.notificationsList || state.notificationsList.length === 0)
        return null;

    return (
        <NotificationsListWrapper>
            {
                state.notificationsList.map((notificationData, idx) => (
                    <Notification key={idx} notification={notificationData} />
                ))
            }
        </NotificationsListWrapper>)
    ;
});
