import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import * as t from 'io-ts';
import { ConfigType } from 'src/domains/layouts/config/features/types';

const DebugConfigIO = t.any;
const decode = buildValidator('localStorageConfig.debugPanel', DebugConfigIO, true);

export const localStorageDebugPanelConfig = (
    withAutorun: boolean,
    key: string
): LocalStorageItemState<ConfigType | null> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: null,
    });
};
