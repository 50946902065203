import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { MiniGameModal } from './mini-game-modal/MiniGameModal';
import { MiniGamesGrid } from './mini-games-grid/MiniGamesGrid';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';

interface MiniGamesPropsType {
    appCasinoState: AppCasinoState;
}

export const MiniGames = observer('MiniGames', (props: MiniGamesPropsType): JSX.Element | null => {
    const { appCasinoState } = props;
    const { miniGamesListState } = appCasinoState;
    const { isMiniGamesListEmpty } = miniGamesListState;

    if (isMiniGamesListEmpty) {
        return null;
    }

    const { miniGameModel, regularGameModel } = appCasinoState.gameModalState;
    const isGameOpened = miniGameModel !== null || regularGameModel !== null;

    return (
        <>
            {miniGameModel === null ? null : (
                <MiniGameModal
                    appCasinoState={appCasinoState}
                    gameDataState={miniGameModel}
                />
            )}
            <MiniGamesGrid
                appCasinoState={appCasinoState}
                isGameOpened={isGameOpened}
            />
        </>
    );
});
