import { Resource } from 'src_common/common/mobx-utils/Resource';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

type SportModelType = {
    id: string;
    name: string;
};

export class AllSports {
    private readonly allSportsResource: Resource<Array<SportModelType>>;

    public constructor(private readonly sdkCustomer: SdkCustomer) {
        this.allSportsResource = new Resource(
            async (): Promise<Array<SportModelType>> => this.sdkCustomer.getSportsList()
        );
    }

    /**
     * @deprecated - remove this function
     */
    public getAllSports(): Array<string> | null {
        const allSports = this.allSportsResource.get();

        if (allSports.type === 'ready') {
            return allSports.value.map((item) => item.id);
        }

        return null;
    }

    /**
     * @deprecated - remove this function
     */
    public getSport(sportId: string): string | null {
        const allSports = this.allSportsResource.get();

        if (allSports.type === 'ready') {
            for (const item of allSports.value) {
                if (item.id === sportId) {
                    return item.name;
                }
            }
        }

        return null;
    }

    public get allSports(): Array<SportModelType> | null {
        const allSports = this.allSportsResource.get();

        if (allSports.type === 'ready') {
            return allSports.value;
        }

        return null;
    }
}
