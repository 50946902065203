import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    IconEnvelopeWrapper,
    IconTwitterWrapper,
    IconPhoneWrapper,
    FacebookIconWrapper,
    InstagramIconWrapper,
} from './ContactUs.style';

type ContactElementsType = {
    Icon: JSX.Element | null;
    LabelValueText: JSX.Element | null;
    contactValueTo: string;
};

type LabelWrapperProps = {
    label: string;
    children: React.ReactNode | React.ReactNode[];
};
const LabelWrapper = ({ label, children }: LabelWrapperProps): JSX.Element => (
    <>
        {label}
        {children}
    </>
);

export const getContactElements = (
    contactType: string,
    contactLabel: string | null | undefined,
    contactValue: string
): ContactElementsType => {
    const label = typeof contactLabel === 'string' && contactLabel !== '' ? `${contactLabel} - ` : '';

    const contactValueTo = contactValue;

    switch (contactType) {
        case 'facebook':
            return {
                Icon: <FacebookIconWrapper />,
                LabelValueText: (
                    <LabelWrapper label={label}>
                        <I18n langKey='footer.facebook' defaultText='Facebook' />
                    </LabelWrapper>
                ),
                contactValueTo,
            };
        case 'instagram':
            return {
                Icon: <InstagramIconWrapper />,
                LabelValueText: (
                    <LabelWrapper label={label}>
                        <I18n langKey='footer.instagram' defaultText='Instagram' />
                    </LabelWrapper>
                ),
                contactValueTo,
            };
        case 'twitter':
            return {
                Icon: <IconTwitterWrapper />,
                LabelValueText: (
                    <LabelWrapper label={label}>
                        <I18n langKey='footer.twitter' defaultText='X' />
                    </LabelWrapper>
                ),
                contactValueTo,
            };
        case 'phone-number':
            return {
                Icon: <IconPhoneWrapper />,
                LabelValueText: <LabelWrapper label={label}>{contactValue}</LabelWrapper>,
                contactValueTo: `tel:${contactValue}`,
            };
        case 'e-mail':
            return {
                Icon: <IconEnvelopeWrapper />,
                LabelValueText: (
                    <LabelWrapper label={label}>
                        <I18n langKey='footer.email-us' defaultText='Email us' />
                    </LabelWrapper>
                ),
                contactValueTo: `mailto:${contactValue}`,
            };
        default:
            return {
                Icon: null,
                LabelValueText: null,
                contactValueTo: '',
            };
    }
};
