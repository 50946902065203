import { computed, makeObservable } from 'mobx';
import { RaceCompetitionState } from './RaceCompetition.state';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';

export class RaceCardFiltersState {
    public constructor(
        private readonly raceCompetitionState: RaceCompetitionState,
        private readonly router: StarRouter,
        private readonly sport: 'horseracing' | 'greyhoundracing'
    ) {
        makeObservable(this);
    }

    @computed public get filters(): Array<FiltersType> {
        return this.raceCompetitionState.optionsForView.map((item) => {
            return {
                key: item.key.toString(),
                label: item.label,
                onClick: () => this.setActiveFilterId(item.key),
            };
        });
    }

    public setActiveFilterId = (id: 'next-off' | 'race-meetings' | number): void => {
        if (id === 'race-meetings') {
            this.router.redirectToRaceMeetingsCard(null, this.sport, this.raceCompetitionState.racecardBuildIds);
        } else {
            this.router.redirectToRaceCard(null, this.sport, id);
        }
    };
}
