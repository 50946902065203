import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StaticPageHeader } from 'src/domains/players/webview/components/Account/staticPage/StaticPageHeader';
import {
    StaticPageInnerWrapper,
    StaticPageWrapper,
    StaticPageInfoWrapper,
    StaticPageLoaderWrapper,
} from 'src/domains/players/webview/components/Account/staticPage/StaticPage.styles';
import { useAppStateContext } from 'src/appState/AppState';
import { CarouselModeType } from 'src/domains/layouts/state/cmsState/homePageCarouselState/CarouselMainState';

const getCarouselMode = (routeName: string): CarouselModeType | null => {
    if (routeName === 'homepage') {
        return 'homepage';
    }
    if (routeName === 'casino') {
        return 'casino';
    }
    if (routeName === 'virtuals') {
        return 'virtuals';
    }
    if (routeName === 'live-casino') {
        return 'live-casino';
    }

    return 'homepage';
};

interface PropsType {
    promoId: number;
}

export const StaticResourceHomePageCarouselTerms = observer(
    'StaticResourceHomePageCarouselTerms',
    (props: PropsType): React.ReactElement | null => {
        const appState = useAppStateContext();
        const { promoId } = props;

        const { appLayoutsState } = useAppStateContext();
        const { cmsState } = appLayoutsState;

        const routeName = appState.appLayoutsState.starRouter.currentView?.name ?? '';
        const carouselModeType = getCarouselMode(routeName);

        if (carouselModeType === null) {
            return (
                <StaticPageWrapper>
                    <StaticPageHeader
                        title={<I18n langKey='account.static-page.error.title' defaultText='Error occurred' />}
                        back={true}
                        parent='summary'
                    />
                    <StaticPageInnerWrapper>
                        <StaticPageInfoWrapper>
                            <I18n
                                langKey='account.static-page.error.description'
                                defaultText="Sorry, we can't find page you are searching for."
                            />
                        </StaticPageInfoWrapper>
                    </StaticPageInnerWrapper>
                </StaticPageWrapper>
            );
        }

        const homePageCarouselState = cmsState.carouselMainState.carouselStateMap.get(carouselModeType);
        const homePageCarouselResourceType = homePageCarouselState.homePageCarouselResource.get().type;

        if (homePageCarouselResourceType === 'loading') {
            return <StaticPageLoaderWrapper />;
        }

        if (homePageCarouselResourceType === 'error') {
            return (
                <StaticPageWrapper>
                    <StaticPageHeader
                        title={<I18n langKey='account.static-page.error.title' defaultText='Error occurred' />}
                        back={true}
                        parent='summary'
                    />
                    <StaticPageInnerWrapper>
                        <StaticPageInfoWrapper>
                            <I18n
                                langKey='account.static-page.error.description'
                                defaultText="Sorry, we can't find page you are searching for."
                            />
                        </StaticPageInfoWrapper>
                    </StaticPageInnerWrapper>
                </StaticPageWrapper>
            );
        }

        const activeTerms = homePageCarouselState.getActiveTerms(promoId);

        return (
            <StaticPageWrapper>
                <StaticPageHeader
                    title={
                        <I18n langKey='static-page.header.terms-and-conditions' defaultText='Terms And Conditions' />
                    }
                    back={true}
                    parent='home'
                />
                <StaticPageInnerWrapper>
                    <div dangerouslySetInnerHTML={{ __html: activeTerms }} />
                </StaticPageInnerWrapper>
            </StaticPageWrapper>
        );
    }
);
