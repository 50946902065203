import { action, computed, observable, makeObservable } from 'mobx';
import { CategoriesAndGamesResponseType, CategoryType, GameType } from 'src/domains/casino/state/AppCasino/store/game-store/typesExt';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { runAsync } from 'src_common/common/mobx-utils/runAsync';
import { CasinoContextModelExt } from 'src/domains/casino/state/AppCasino/store/game-store/CasinoContextModelExt';
import { CollectionType, GameSharedModel } from './typesExt';
import { ProviderType } from 'src/domains/casino/types/types';
import { openapiCmsCustomerSetFavouriteGameRequest } from 'src/domains/casino/api/generated/cms/openapi_cms_customer_set_favourite_game';
import { openapiCmsCustomerSetRecentGameRequest } from 'src/domains/casino/api/generated/cms/openapi_cms_customer_set_recent_game';

export class CasinoGameModelExt {
    @observable private isFavouriteOptimistic: boolean | null = null;
    @observable private addedToFavouritesConfirmation: boolean = false;

    public constructor(
        public readonly collection: CollectionType,
        private readonly resource: Resource<CategoriesAndGamesResponseType>,
        private readonly gameId: number,
        private readonly contextModel: CasinoContextModelExt,
    ) {
        makeObservable(this);
    }

    /**
     * @deprecated - this getter should be private
     */
    @computed.struct public get data(): GameType | null {
        const data = this.resource.get();

        if (data.type === 'ready') {
            return data.value.games.find(game => game.id === this.gameId) ?? null;
        }
        return null;
    }

    @computed public get id(): number {
        return this.gameId;
    }

    @computed public get name(): string {
        return this.data?.name ?? '';
    }

    @computed public get imageHorizontal(): string {
        return this.data?.image ?? '';
    }

    @computed public get imageSquare(): string {
        return this.data?.image_square ?? '';
    }

    @computed public get imageVertical(): string {
        return this.data?.image_vertical ?? '';
    }

    @computed public get launchGameId(): string {
        return this.data?.launch_game_id ?? '';
    }

    @computed public get studioId(): string {
        return this.data?.studio_id ?? '';
    }

    @computed public get studioName(): string {
        return this.data?.studio_name ?? '';
    }

    @computed public get provider(): ProviderType | null {
        const data = this.data;

        if (data === null) {
            return null;
        }

        if (data.provider === 'Evolution' ||
            data.provider === 'Ezugi' ||
            data.provider === 'Leap' ||
            data.provider === 'Nsoft' ||
            data.provider === 'Pragmatic' ||
            data.provider === 'Relax' ||
            data.provider === 'SoftSwiss' ||
            data.provider === 'Blueprint'
        ) {
            return data.provider;
        }

        return 'SG Digital';
    }

    @computed public get firstCategory(): CategoryType | null {
        const data = this.resource.getReady();
        if (data === null) {
            return null;
        }

        for (const category of data.categories) {
            if (category.game_ids.includes(this.gameId)) {
                return category;
            }
        }

        return null;
    }

    @computed public get gameSharedModel(): GameSharedModel | null {
        if (this.data === null) {
            return null;
        }

        return {
            id: this.data.id,
            name: this.data.name,
        };
    }

    @computed public get isFavourite(): boolean {
        if (this.isFavouriteOptimistic !== null) {
            return this.isFavouriteOptimistic;
        }
        const favouriteGames = this.contextModel.favouriteGames.get();

        if (favouriteGames.type === 'ready') {
            if (favouriteGames.value === null) {
                return false;
            }

            return favouriteGames.value.game_ids.includes(this.gameId);
        }

        return false;
    }

    public handleLikeAction = (): void => {
        if (this.contextModel.goToLoginForm()) {
            return;
        }
        const newFavourite = !this.isFavourite;
        this.isFavouriteOptimistic = newFavourite;

        runAsync(async () => {
            const response = await this.contextModel.session.call(openapiCmsCustomerSetFavouriteGameRequest, {
                requestBody: {
                    game_id: this.gameId,
                    is_favourite: newFavourite,
                }
            });
            console.log('response', response);
            await this.contextModel.favouriteGames.refresh();
        });

        if (newFavourite) {
            this.addedToFavouritesConfirmation = true;
            setTimeout(() => {
                this.addedToFavouritesConfirmation = false;
            }, 3000);
        }
    };

    public get isAddedToFavouritesConfirmation(): boolean {
        return this.addedToFavouritesConfirmation;
    }

    @action public startGame = (options?: {mode?: 'demo' | 'mini' | 'bingo-mini'}): void => {
        this.contextModel.gameModalState().startGame({
            type: 'model',
            gameModel: this,
            mode: options?.mode
        });

        if (this.contextModel.redirectState.getIsUserAutorized() && this.collection !== 'virtuals') {
            this.addGameToRecent(this.gameId);
        }
    };

    private addGameToRecent = (gameId: number): void => {
        runAsync(async () => {
            await this.contextModel.session.call(openapiCmsCustomerSetRecentGameRequest, {
                requestBody: {
                    game_id: gameId,
                },
            });
            await this.contextModel.userRecentGamesResource.refresh();
        });
    };
}
