import React, { useEffect, useState } from 'react';
import { EventsFilterGroupState } from './EventsFilterGroupState';
import { FilterKindType } from './EventsFilterType';
import { assertNever } from 'src_common/common/assertNever';
import { observer } from 'src/utils/mobx-react';
import { autorun } from 'mobx';
import { FiltersState, FiltersStateStrategy } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import {
    FilterRowWrapper,
    Label,
    NameContainer,
    Right,
    Wrapper
} from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterGroup.style';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { useAppStateContext } from 'src/appState/AppState';

interface EventsFiltersPropsType {
    strategy: FiltersStateStrategy;
    skin: FiltersSkin;
}

const EventsFilters = observer('EventsFilters', (props: EventsFiltersPropsType) => {
    const { strategy, skin } = props;
    const [state] = useState(() => new FiltersState(strategy));
    return (
        <FilterRowWrapper>
            <FiltersScrollableWrapper>
                <Filters state={state} skin={skin} />
            </FiltersScrollableWrapper>
        </FilterRowWrapper>
    );
});

interface RenderFilterItemResult {
    name: React.ReactNode;
    body: React.ReactNode;
}

const renderFilterItem = (eventsFilterState: EventsFilterGroupState, filterId: FilterKindType, skin: FiltersSkin): RenderFilterItemResult => {
    if (filterId === 'sport') {
        const state = eventsFilterState.sport;

        return {
            name: <Label key='sport'>{state.name}</Label>,
            body: <EventsFilters strategy={state} skin={skin} key='sport' />
        };
    }

    if (filterId === 'competition') {
        const state = eventsFilterState.competition;

        return {
            name: <Label key='competition'>{state.name}</Label>,
            body: <EventsFilters strategy={state} skin={skin} key='competition' />
        };
    }

    if (filterId === 'time') {
        const state = eventsFilterState.time;

        return {
            name: <Label key='time'>{state.name}</Label>,
            body: <EventsFilters strategy={state} skin={skin} key='time' />
        };
    }

    if (filterId === 'region') {
        const state = eventsFilterState.region;

        return {
            name: <Label key='region'>{state.name}</Label>,
            body: <EventsFilters strategy={state} skin={skin} key='region' />
        };
    }

    if (filterId === 'country') {
        const state = eventsFilterState.country;

        return {
            name: <Label key='country'>{state.name}</Label>,
            body: <EventsFilters strategy={state} skin={skin} key='country' />
        };
    }

    return assertNever('', filterId);
};

const renderFilters = (eventsFilter: EventsFilterGroupState, filters: Array<FilterKindType>, skin: FiltersSkin): Array<RenderFilterItemResult> => {
    const out = [];

    for (const filterKind of filters) {
        out.push(renderFilterItem(eventsFilter, filterKind, skin));
    }

    return out;
};

interface PropsType {
    filters: Array<FilterKindType>;
    eventsFilter: EventsFilterGroupState;
    skin: FiltersSkin;
}

export const EventsFilterGroup = observer('EventsFilterGroup', (props: PropsType): React.ReactElement | null => {
    const { appLayoutsState } = useAppStateContext();
    const { defaultCompetitionState } = appLayoutsState;

    const { filters, eventsFilter, skin = FiltersSkin.SECONDARY } = props;

    useEffect(() => {
        const dispose = autorun(() => {
            const { getCompetitionDefault } = defaultCompetitionState;
            const state = eventsFilter.competition;

            if (getCompetitionDefault !== null) {
                const initialCompetition = state.filters.find(c => c.label === getCompetitionDefault);
                if (initialCompetition !== undefined) {
                    state.setActiveFilterId(String(initialCompetition.id));
                    defaultCompetitionState.setDefaultCompetitionFilter(null);
                }
            }

        });
        return (): void => {
            dispose();
        };
    }, []);


    const filtersJsx = renderFilters(eventsFilter, filters, skin);

    if (filtersJsx.length > 1) {
        return (
            <Wrapper>
                <NameContainer>
                    {filtersJsx.map((item) => item.name)}
                </NameContainer>

                <Right>
                    {filtersJsx.map((item) => item.body)}
                </Right>
            </Wrapper>
        );
    } else if (filtersJsx.length === 1) {
        const body = filtersJsx[0]?.body;

        return <div>{body}</div>;
    }

    return null;
});
