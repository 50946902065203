import React from 'react';
import { MainWrapper } from 'src/domains/layouts/layouts/homepage/Homepage.style';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { PromotedEvents } from 'src/domains/sportsbook/shared/Components';
import { observer } from 'src/utils/mobx-react';
import { StareventsRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { useAppStateContext } from 'src/appState/AppState';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsTypes {
    currentView: StareventsRouteType;
    accountView: RightHandSideViewType | null;
    isSearchActive: boolean;
}

export const StarEvents = observer('StarEvents', ({ currentView, accountView, isSearchActive }: PropsTypes) => {
    const appState = useAppStateContext();
    const { appLayoutsState: { promotedEventsState } } = appState;
    
    return (
        <MainWrapper className='homepage'>
            <LayoutSkeleton currentView={currentView} accountView={accountView} isSearchActive={isSearchActive} navigationType='global' >
                <PromotedEvents promotedEventsState={promotedEventsState} />
            </LayoutSkeleton>
        </MainWrapper>
    );
});
