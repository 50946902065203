import { Value } from 'src_common/common/mobx-utils/Value';
import { RabMarketType } from 'src_common/common/websocket2/modelsApi/RabMarket';

export class RabMarketsModel {
    private readonly model: Value<Array<RabMarketType>>;

    public constructor(model: Value<Array<RabMarketType>>) {
        this.model = model;
    }

    public get markets(): Array<RabMarketType> {
        return this.model.getValue();
    }
}
