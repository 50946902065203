import { computed, observable, action, makeObservable } from 'mobx';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';

const SCROLL_OFFSET = 240 * 2; // one element width, + 2 elements = scroll length

class RefBox {
    public constructor(public readonly ref: HTMLElement) { }
}

class ConnectWrapper {
    public connect(self: MobxValue<RefBox>): NodeJS.Timeout {
        const timer = setInterval(() => {
            const refBox = self.getValue();
            self.setValue(new RefBox(refBox.ref));
        }, 500);
        return timer;
    }
    public dispose(timer: NodeJS.Timeout): void {
        clearInterval(timer);
    }
}

class HtmlElementBox {
    private readonly box: MobxValue<RefBox>;

    public constructor(ref: HTMLElement) {
        makeObservable(this);
        this.box = MobxValue.create({
            initValue: new RefBox(ref),
            connect: new ConnectWrapper()
        });
    }

    public get innerRef(): HTMLElement {
        return this.box.getValue().ref;
    }

    @computed public get scrollLeft(): number {
        return this.box.getValue().ref.scrollLeft;
    }

    @computed public get scrollWidth(): number {
        return this.box.getValue().ref.scrollWidth;
    }

    @computed public get clientWidth(): number {
        return this.box.getValue().ref.clientWidth;
    }
}

const elementScrollTo = (itemsList: HTMLElement, offset: number): void => {
    try {
        itemsList.scrollBy( { left: offset, behavior: 'smooth' } );
    } catch ( ex ) {
        itemsList.scrollLeft = itemsList.scrollLeft + offset;
    }
};

export class SliderState {
    @observable private ref: HtmlElementBox | null = null;

    public constructor() {
        makeObservable(this);
    }

    @action public setRef = (item: HTMLElement | null): void => {
        if (item === null) {
            this.ref = null;
        } else {
            this.ref = new HtmlElementBox(item);
        }
    }

    @computed public get scrollLeftArrow(): boolean {
        const ref = this.ref;
        if (ref !== null) {
            if (ref.scrollLeft > 0) {
                return true;
            }
        }

        return false;
    }

    @action public goToLeft = (): void => {
        const ref = this.ref;
        if (ref !== null) {
            elementScrollTo(ref.innerRef, -SCROLL_OFFSET);
        }
    }

    @computed public get scrollRightArrow(): boolean {
        const ref = this.ref;
        if (ref !== null) {
            if (ref.scrollWidth > ref.clientWidth) {
                const isEnd = (ref.scrollWidth === ref.scrollLeft + ref.clientWidth);
                return !isEnd;
            }
        }

        return false;
    }

    @action public goToRight = (): void => {
        const ref = this.ref;
        if (ref !== null) {
            elementScrollTo(ref.innerRef, SCROLL_OFFSET);
        }
    }
}
