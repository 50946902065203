import { AppState } from 'src/appState/AppState';
import { action, computed, observable, makeObservable } from 'mobx';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';

const MIN_RACE_WIDTH = 240;
const MAX_COLUMNS = 3;

export type RacingSportType = 'horseracing' | 'greyhoundracing';

interface PropsType {
    events: Array<EventModel> | 'loading';
}

export class RacesSliderListState {
    public readonly MAX_COLUMNS: number;
    @observable public wrapperRef: HtmlElementReactive<HTMLDivElement>;
    @observable public wrapperInnerRef: HtmlElementReactive<HTMLDivElement>;
    @observable public noRaces = false;
    private startSwipeDataStartPos: number | null = null;
    @observable private currentSlideIndex = 1;

    public constructor(private readonly appState: AppState, private readonly props: PropsType) {
        makeObservable(this);
        this.MAX_COLUMNS = MAX_COLUMNS;
        this.wrapperRef = new HtmlElementReactive(300);
        this.wrapperInnerRef = new HtmlElementReactive(300);

        setTimeout(() => {
            this.noRaces = true;
        }, 18000);
    }

    @computed public get refWidth(): number | null {
        return this.wrapperRef.ref?.offsetWidth ?? null;
    }

    @computed public get refWidthInner(): number | null {
        return this.wrapperInnerRef.ref?.offsetWidth ?? null;
    }

    /**
     *  Number of columns that will be displayed on the screen
     */
    @computed public get pageSizeInner(): number {
        if (this.refWidthInner === null) {
            return MAX_COLUMNS;
        }

        for (let page = MAX_COLUMNS; page > 0; page--) {
            if (page * MIN_RACE_WIDTH < this.refWidthInner) {
                return page;
            }
        }

        return 1;
    }

    @computed public get shouldShowLoading(): boolean {
        return (this.eventsForView === 'loading' || this.itemWidth === null || this.appState.env.isBrowser === false) && this.noRaces === false;
    }

    @computed public get pageSize(): number {
        return Math.min(this.events.length, this.pageSizeInner);
    }

    @computed public get itemWidth(): number | null {
        if (this.refWidthInner === null || this.pageSize === 0) {
            return null;
        }
        return Math.floor(this.refWidthInner / this.pageSize);
    }

    @computed private get eventsArray(): Array<EventModel> | 'loading' {
        return this.props.events;
    }

    public sortEventsByTime = (outArray: Array<[EventModel, MarketModel]>): Array<[EventModel, MarketModel]> => {
        return outArray.sort((a: [EventModel, MarketModel], b: [EventModel, MarketModel]): number => {
            const time1 = a[0].timeSettingsStartTime;
            const time2 = b[0].timeSettingsStartTime;

            if (time1 < time2) {
                return -1;
            }
            if (time1 > time2) {
                return 1;
            }

            return 0;
        });
    };

    @computed public get eventsArrayFilter(): Array<EventModel> | 'loading' {
        if (this.eventsArray === 'loading') {
            return 'loading';
        }

        return this.eventsArray.filter(x => x.display && !x.timeSettingsStarted);
    }

    private isMarketCreatedWithTrapChallengeTemplate(templateName: string): boolean {
        return ['Trap Challenge - Outright', 'Trap Challenge'].includes(templateName);
    }

    @computed public get events(): Array<[EventModel, MarketModel]> | 'loading' {
        if (this.eventsArrayFilter === 'loading') {
            return 'loading';
        }

        const out: Array<[EventModel, MarketModel]> = [];
        let marketsLoading: number = 0;

        for (const event of this.eventsArrayFilter) {
            const eventMarkets = this.appState.appSportsBookState.models.getEventMarkets(event.id);

            if (eventMarkets === null) {
                marketsLoading++;
            } else {
                const smallMarket = eventMarkets[0];

                if (smallMarket !== undefined) {
                    const market = this.appState.appSportsBookState.models.getMarket(smallMarket.id);

                    if (market === null) {
                        marketsLoading++;
                    } else if (this.isMarketCreatedWithTrapChallengeTemplate(market.template.name)) {
                    /* do not include trap challenge into events for view
                no need to appear in nextoff when they have separate section */ 
                    } else {
                        out.push([event, market]);
                    }
                }
            }
        }

        const outToReturn = this.sortEventsByTime(out);

        if (outToReturn.length > 2) {
            return outToReturn;
        }

        if (marketsLoading > 0) {
            return 'loading';
        }

        return outToReturn;
    }

    @computed public get eventsForView(): Array<[EventModel, MarketModel, boolean]> | 'loading' {
        if (this.events === 'loading') {
            return 'loading';
        }

        const out: Array<[EventModel, MarketModel, boolean]> = [];
        for (const [index, [event, market]] of this.events.entries()) {
            const shouldSkipEvent = this.isMarketCreatedWithTrapChallengeTemplate(market.template.name);

            if (shouldSkipEvent) { 
                /* do not include trap challenge into events for view
                no need to appear in nextoff when they have separate section */ 
            } else {
                const showBox = index < this.numberOfLaizyLoadBoxed + this.currentSlideIndex - 1;
                out.push([event, market, showBox]);
            }
        }

        return out;
    }

    @computed public get eventsCount(): number {
        if (this.events === 'loading') {
            return 0;
        }
        return this.eventsForView.length;
    }

    @computed public get currentSlide(): number {
        const minIndex = 1;
        const maxIndex = Math.max(1, this.eventsCount - this.pageSize + 1);
        return Math.min(maxIndex, Math.max(minIndex, this.currentSlideIndex));
    }

    @action public goToLeft = (): void => {
        if (this.goLeftAvailable) {
            this.currentSlideIndex = this.currentSlide - this.pageSize;
        }
    };

    @action public goToRight = (): void => {
        if (this.goRightAvailable) {
            this.currentSlideIndex = this.currentSlide + this.pageSize;
        }
    };

    @computed public get goLeftAvailable(): boolean {
        return this.currentSlideIndex > 1;
    }

    @computed public get goRightAvailable(): boolean {
        return this.currentSlideIndex <= this.eventsCount - this.pageSize;
    }

    @action public onTouchStart = (e: React.TouchEvent<HTMLUListElement>): void => {
        //@ts-expect-error
        this.startSwipeDataStartPos = e.changedTouches[0].pageX;
    };

    @action public onTouchEnd = (e: React.TouchEvent<HTMLUListElement>): void => {
        const startPos = this.startSwipeDataStartPos;

        if (startPos !== null) {
            //@ts-expect-error
            const distance = e.changedTouches[0].pageX - startPos;

            if (Math.abs(distance) >= 25) {
                if (distance < 0) {
                    this.goToRight();
                } else if (distance > 0) {
                    this.goToLeft();
                }
            }
        }
    };

    @computed public get wrapperWidth(): number | null {
        if (this.itemWidth === null) {
            return null;
        }
        return this.itemWidth * this.pageSize;
    }

    @computed public get correctionTransX(): number {
        // removing extra offet to remove empty space after last element on mobile
        if (this.pageSize < 3 && !this.goRightAvailable) {
            const width = this.refWidth ?? 0;
            const widthInner = this.refWidthInner ?? 0;
            return width - widthInner + 1; // add magic 1 to fix unexpected additional spacing on the right
        }
        return 0;
    }

    @computed public get transX(): number {
        const itemWidth = this.itemWidth ?? 0;
        return itemWidth * this.currentSlide - itemWidth - this.correctionTransX;
    }

    @computed public get numberOfLaizyLoadBoxed(): number {
        switch (this.pageSize) {
            case (2):
                return 5;
            case (1):
                return 3;
            default:
                return 6;
        }
    }

}
