import { decodeModernizrDetect } from './ModernizrType';

require('src/domains/layouts/state/environmentState/modernizr/modernizr'); 

//@ts-expect-error
const Modernizr: unknown = window.Modernizr;

const modernizrDetect = decodeModernizrDetect(Modernizr);

if (modernizrDetect instanceof Error) {
    throw modernizrDetect;
}

export const ModernizrDetect = modernizrDetect;
