import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface StarFillIconPropsType {
    className?: string;
    onClick?: (event: React.SyntheticEvent) => void;
}

export const StarFillIcon = observer('StarFillIcon', ({ className, onClick }: StarFillIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M11.998 19.135l7.414 4.473-1.961-8.438 6.545-5.674-8.628-.74L11.998.8l-3.37 7.955L0 9.496l6.545 5.674-1.962 8.438 7.415-4.473z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
