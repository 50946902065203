import { SendIcon } from 'src/domains/layouts/shared/assets/icons/SendIcon';

import styled from '@emotion/styled';

import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

import { withConfig } from 'src/withConfig';



export const ChatWritingAreaForm = styled('form', { label: 'ChatWritingAreaForm' })`

    align-items: center;

    box-shadow: -4px -6px 16px -6px rgba(0, 0, 0, 0.16);

    display: flex;

    flex-flow: row nowrap;

    flex: 0 0 52px;

    justify-content: space-between;

    padding: 8px;

    position: relative;

`;



export const ChatWritingArea = withConfig(theme => styled('div', { label: 'ChatWritingArea' })`

    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {

        background-color: ${theme.star.customScrollBar.dark.bgColor};

        width: 4px;

    }

    &::-webkit-scrollbar-thumb {

        background-color: ${theme.star.customScrollBar.dark.bgColorSecondary};

        border-radius: 4px;

    }

    background-color: ${theme.star.form.light.bgColor};

    border: none;

    font-size: ${theme.star.fontSize.small};

    line-height: 1.3334;

    margin: 0 4px 0 0;

    max-height: 68px;

    min-height: 36px;

    overflow-y: auto;

    padding: 10px 12px;

    resize: none;

    scrollbar-color: ${theme.star.customScrollBar.dark.bgColorSecondary} ${theme.star.customScrollBar.dark.bgColor};

    scrollbar-width: thin;

    width: 100%;

    &:empty::before {

        content: attr(data-placeholder);

        color: ${theme.star.form.txtColorSecondary};

    }

`);



export const ChatWritingAreaBtn = styled(Button, { label: 'ChatWritingAreaBtn' })`

    flex: 0 0 40px;

    height: 100%;

    min-height: 36px;

    padding: 0;

`;



export const SendIconWrapper = withConfig(theme => styled(SendIcon, { label: 'SendIconWrapper' })`

    display: block;

    fill: ${theme.star.traderChat.messages.bgColorQuinary};

    margin: auto;

    width: 20px;

`);

