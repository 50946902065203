import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'src/utils/mobx-react';
import { SelectionViewModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionViewModel';
import { SelectionItemStyle } from 'src/domains/sportsbook/webview/components/Selection/Selection.style';
import { useAppStateContext } from 'src/appState/AppState';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    className?: string;
    selectionId: SelectionId | undefined;
    sp: boolean | undefined;
    onHoverSelection?: () => void;
    borderTop?: boolean;
    borderRight?: boolean;
    borderBottom?: boolean;
    borderLeft?: boolean;
}

export const SelectionNewVersion = observer('SelectionNewVersion', (props: PropsType) => {
    const { betSlipState } = useAppStateContext().appSportsBookState;
    const { selectionId, sp } = props;

    const selectionView = (): SelectionViewModel | null => {
        if (selectionId !== undefined) {
            const selectionModel = selectionId.getModel();

            if (selectionModel !== null) {
                return selectionModel.forView(sp);
            }
        }

        return null;
    };

    const choose = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault();

        if (selectionId !== undefined && sp !== undefined) {
            if (sp) {
                betSlipState.legsState.betslipData.onAddSPBet(selectionId);
            } else {
                betSlipState.legsState.betslipData.onAddSimpleBet(selectionId);
            }
        }
    };

    const onIsSelected = (): boolean => {
        if (selectionId !== undefined) {
            return betSlipState.isSelected(selectionId);
            // const selection = betSlipState.ids.getSingleId(selectionId, 0).getModel();
            // if (selection !== null) {
            //     const { priceType } = selection;
            //     if (sp !== undefined && sp && priceType !== 'sp') {
            //         return false;
            //     }
            //     return true;
            // }
        }

        return false;
    };

    const { className: classNameFromProps, onHoverSelection, borderTop, borderRight, borderBottom, borderLeft } = props;

    const modelForView = selectionView();

    if (modelForView === null) {
        return (
            <SelectionItemStyle
                borderBottom={borderBottom}
                borderLeft={borderLeft}
                borderRight={borderRight}
                borderTop={borderTop}
                className='selection'
                href='#'
                onClick={choose}
            >
                -
            </SelectionItemStyle>
        );
    }

    const { priceChange, suspended, price, displayPrice } = modelForView;

    const isEmpty = price === undefined;

    const selectedNew = onIsSelected();

    const className = classNames([classNameFromProps, 'selection']);

    const selectionIdDataTest: string | number | undefined =
        price === 'sp' ? `sp-${props.selectionId?.toOldId() ?? 'id-undefined'}` : props.selectionId?.toOldId();

    return (
        <SelectionItemStyle
            data-test={suspended ? 'selection-suspended' : 'selection'}
            data-test-selection-id={selectionIdDataTest}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
            borderRight={borderRight}
            borderTop={borderTop}
            className={className}
            href='#'
            isOddsDown={priceChange === 'down'}
            isOddsUp={priceChange === 'up'}
            isSelected={selectedNew}
            isSuspended={suspended}
            isEmpty={isEmpty}
            onClick={choose}
            onMouseOver={onHoverSelection}
        >
            {displayPrice}
        </SelectionItemStyle>
    );
});
