import React from 'react';
import { AccountContentWrapperDesktop, ScrollbarWrapper } from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { observer } from 'src/utils/mobx-react';
import { AccountNavigationWrapper } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';

export const AccountPanelPlaceholder = observer('AccountPanelPlaceholder', () => {
    return (
        <AccountContentWrapperDesktop>
            <AccountNavigationWrapper />
            <ScrollbarWrapper />
        </AccountContentWrapperDesktop>
    );
});
