import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { AccountNavigationLink } from 'src/domains/layouts/webview/components/accountNavigationLink/AccountNavigationLink';
import { withConfig } from 'src/withConfig';

interface BetslipType {
    active?: boolean;
    priceChanged?: boolean;
    isKeyBoardOpen?: boolean;
    isFocused?: boolean;
    isAuth?: boolean;
    disabled?: boolean;
}

export const PlaceButtonWrapper = withConfig(theme => styled(Button, { label: 'PlaceButtonWrapper' }) <BetslipType>`
    height: 48px;
    padding: 0;
    width: 100%;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3;
    flex: 1 1 50%;
`);

export const FreeBetButtonWrapper = styled(PlaceButtonWrapper, { label: 'FreeBetButtonWrapper' })`
    margin-left: 2px !important;
    margin-right: 0 !important;
`;

export const AcceptChangesBtnWrapper = styled(Button, { label: 'AcceptChangesBtnWrapper' })`
    display: block;
    width: 100%;
`;

export const LogInToPlaceBetBtnWrapper = styled(AccountNavigationLink, { label: 'LogInToPlaceBetBtnWrapper' })`
    display: block;
    position: sticky;
    text-transform: none;
    width: 100%;
    &:only-child {
        bottom: 8px;
    }
`;

export const OpenBetsBtnWrapper = styled(Link, { label: 'OpenBetsBtnWrapper' })`
    display: block;
    margin: 0 10px !important;
`;

export const ChevronIconWrapper = styled(ChevronIcon, { label: 'ChevronIconWrapper' })`
    position: absolute;
    margin: auto;
    float: left;
    top: 6px;
    width: 12px;
    fill: currentcolor;
    transition: transform .2s ease;
`;

export const HeaderWrapper = styled('li', { label: 'HeaderWrapper' })`
    position: relative;
`;

export const Label = withConfig(theme => styled('span', { label: 'Label' })`
    color: ${theme.star.betslipGeneral.txtColor};
    cursor: pointer;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    margin-left: 20px;
`);

interface MyBetsSectionPropsType {
    isMiniCasino: boolean;
}

export const BetslipMainWrapper = withConfig(theme => styled('section', { label: 'BetslipMainWrapper' }) <MyBetsSectionPropsType>`
    background-color: ${theme.star.betslipGeneral.bgColor};
    color: ${theme.star.betslipGeneral.txtColor};
    padding-bottom: ${({ isMiniCasino }): string => isMiniCasino ? '20px' : '40px'};
`);

