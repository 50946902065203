import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    InfoContentWrapper,
    CloseButton,
    StyledCloseIcon,
    InfoContentTitle,
    InfoContent,
    InfoWrapper,
} from './TermsAndConditions.style';

interface TermsPagePropsTypes {
    title: string;
    content: string;
    closePage: () => void;
}

export const TermsPage = observer('TermsPage', ({ title, content, closePage }: TermsPagePropsTypes) => {
    return (
        <InfoWrapper signUpCheckboxTC={false}>
            <InfoContentWrapper>
                {/* TODO: data-test='title-header' same as in Account/accountDrawerHeader/AccountDrawerHeader.tsx - should be changed to be more specific*/}
                <InfoContentTitle data-test='title-header'>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                    <CloseButton type='button' onClick={closePage}>
                        <StyledCloseIcon />
                    </CloseButton>
                </InfoContentTitle>
                <InfoContent dangerouslySetInnerHTML={{ __html: content }} />
            </InfoContentWrapper>
        </InfoWrapper>
    );
});
