import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    BetHistoryType,
    BetHistoryLegType,
} from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

export const BetEachWayInfo = observer<{ bet: BetHistoryType; leg: BetHistoryLegType }>(
    'BetEachWayInfo',
    ({ bet, leg }): JSX.Element | null => {
        if (bet.eachWay === false) {
            return null;
        }
        const reductionValue = leg.eachWayTerms?.reduction;
        let reduction = null;
        if (!(reductionValue instanceof Error)) {
            if (reductionValue instanceof Object) {
                reduction = `${reductionValue.num}/${reductionValue.den}`;
            }
        }
        const places = leg.eachWayTerms?.places ?? null;

        if (reduction !== null && places !== null) {
            return (
                <div>
                    <I18n
                        langKey='bets.list.bet.each-way'
                        defaultText='Each Way - {reduction} odds | {places} places'
                        params={{ reduction, places }}
                    />
                </div>
            );
        }

        return null;
    }
);
