import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { IconCheckMark } from './IconCheckMark';
import { RabItem, ItemType } from 'src/domains/sportsbook/webview/components/youDecide/RabItem';
import {
    ContentText,
    ErrorConflictWrapper,
    YouDecideWrapper,
    YouDecideContent,
    YouDecideAddToBetSlip,
    AddToBetSlipTxt,
    YouDecideList,
    YouDecideMore,
    AddToBetSlipCurrency,
    ContentConfirmationWrapper,
    YouDecideListHeader,
    YouDecideListHeaderCount,
    YouDecideListHeaderRemove,
    RabWarningIconWrapper,
    ErrorConflictDesc,
} from './YouDecide.style';
import { IconTrashWrapper } from 'src/domains/sportsbook/webview/components/youDecide/YouDecide.style';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { observer } from 'src/utils/mobx-react';
import { YouDecideState } from 'src/domains/sportsbook/webview/components/youDecide/YouDecideState';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

interface AddToBetslipPropsType {
    priceString: string;
    onAdd: () => void;
}

export const AddToBetslip = observer('AddToBetslip', (props: AddToBetslipPropsType) => {
    const { appSportsBookState } = useAppStateContext();
    const currencySign = appSportsBookState.moneySymbol;

    const handleAddRabToBetslip = (): void => {
        props.onAdd();
    };

    return (
        <YouDecideAddToBetSlip onClick={handleAddRabToBetslip} dataTest='add-to-betslip' size='large'>
            <AddToBetSlipTxt>
                <AddToBetSlipCurrency>{currencySign}1</AddToBetSlipCurrency>

                <I18n langKey='bets.you-decide.add-to-betslip.returns' defaultText='returns' />

                <AddToBetSlipCurrency>
                    {currencySign}
                    {props.priceString}
                </AddToBetSlipCurrency>
            </AddToBetSlipTxt>

            <AddToBetSlipTxt>
                <I18n langKey='bets.you-decide.add-to-betslip.content' defaultText='Add to Betslip' />
            </AddToBetSlipTxt>
        </YouDecideAddToBetSlip>
    );
});

export const Confirmation = observer('Confirmation', () => {
    const { appLayoutsState } = useAppStateContext();
    const { rabHeaderTitle } = appLayoutsState.configComponents.config;

    return (
        <ContentConfirmationWrapper data-test='confirmation'>
            <IconCheckMark />
            <ContentText>Your {rabHeaderTitle} bet has been successfully added to your betslip</ContentText>
        </ContentConfirmationWrapper>
    );
});

interface ErrorMessageTypes {
    primaryText?: string;
    secondaryText?: string;
}

export const ResolveConflictsLabel = observer('ResolveConflictsLabel', (props: ErrorMessageTypes) => {
    const {
        primaryText = 'Some or all of your selections are not currently available.',
        secondaryText = 'Please try different combinations.',
    } = props;

    return (
        <ErrorConflictWrapper data-test='conflicts'>
            <RabWarningIconWrapper />

            <ErrorConflictDesc>{primaryText}</ErrorConflictDesc>

            <ErrorConflictDesc>{secondaryText}</ErrorConflictDesc>
        </ErrorConflictWrapper>
    );
});

interface RenderAdditionalInfoPropsType {
    items: Array<ItemType>;
    areConflictingBets: boolean;
}

export const RenderAdditionalInfo = observer(
    'RenderAdditionalInfo',
    (props: RenderAdditionalInfoPropsType): React.ReactElement | null => {
        let addInfo = '';
        // TODO handling error - bad request POST http://localhost:3030/api-web/get-rab-price
        if (props.areConflictingBets === true) {
            return null;
        }

        if (props.items.length === 0) {
            addInfo = 'Make a selection';
        } else if (props.items.length === 1) {
            addInfo = 'Make another selection';
        } else {
            addInfo = 'Or make another selection';
        }

        return <YouDecideMore>{addInfo}</YouDecideMore>;
    }
);

interface YouDecideInnerPropsType {
    setRef: HtmlElementReactive<HTMLElement>;
    isFooterVisible: boolean;
    rabId: BetslipRabId;
}

export const YouDecide = observer('YouDecide', (props: YouDecideInnerPropsType) => {
    const appState = useAppStateContext();
    const [state] = useState(() => new YouDecideState(appState));

    const { isFooterVisible, rabId, setRef } = props;
    const rabBasket = rabId.getModel();
    const items = rabBasket.rabOffer;
    const price = rabBasket.rabStatus.price;
    const priceString = rabBasket.rabStatus.priceString;
    const conflictingBets = rabBasket.rabStatus.conflictingBets;
    const conflictingBetsMessage = rabBasket.rabStatus.conflictingBetsMessage;
    const conflictingBetsSecondaryMessage =
        conflictingBetsMessage === undefined ? undefined : 'Please resolve to continue.';
    const suspendedBets = rabBasket.rabStatus.suspendedBets;

    return (
        <YouDecideWrapper isFooter={isFooterVisible} ref={setRef.setRef}>
            {
                <YouDecideContent data-test='you-decide-content'>
                    {price !== null && priceString !== null ? (
                        <AddToBetslip priceString={priceString} onAdd={state.onAddToBetslipInner} />
                    ) : null}
                    {state.isConfirmationOpened === true ? <Confirmation /> : null}
                    {conflictingBets === true ? (
                        <ResolveConflictsLabel
                            primaryText={conflictingBetsMessage}
                            secondaryText={conflictingBetsSecondaryMessage}
                        />
                    ) : null}
                    {suspendedBets === true ? (
                        <ResolveConflictsLabel
                            primaryText='Some or all of your selections are no longer available.'
                            secondaryText='Please resolve to continue.'
                        />
                    ) : null}

                    <YouDecideListHeader>
                        {items.length > 0 ? (
                            <YouDecideListHeaderCount>{items.length} selections</YouDecideListHeaderCount>
                        ) : null}

                        {items.length > 0 ? (
                            <YouDecideListHeaderRemove
                                onClick={state.appState.appSportsBookState.rab.clearRabList}
                                data-test='remove-all'
                            >
                                Remove all
                                <IconTrashWrapper className='x-IconTrashWrapper' />
                            </YouDecideListHeaderRemove>
                        ) : null}
                    </YouDecideListHeader>

                    <YouDecideList data-test='you-decide-list'>
                        {items.length > 0 ? (
                            <>
                                {items.map((item, id) => (
                                    <RabItem
                                        key={id}
                                        item={item}
                                        conflicting={conflictingBets || suspendedBets}
                                        rabBasket={rabBasket}
                                    />
                                ))}
                            </>
                        ) : conflictingBets === true && suspendedBets === true ? (
                            <RenderAdditionalInfo items={items} areConflictingBets={conflictingBets || suspendedBets} />
                        ) : null}
                        <RenderAdditionalInfo items={items} areConflictingBets={conflictingBets || suspendedBets} />
                    </YouDecideList>
                </YouDecideContent>
            }
        </YouDecideWrapper>
    );
});
