import * as React from 'react';
import { UniverseType } from 'src_common/common/universe';
import { registerNetworRequest } from 'src_common/utils/Idle';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { assertNever } from 'src_common/common/assertNever';
import { observer } from 'src/utils/mobx-react';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { featuresDev } from './universesFeatureDev';
import { EnvVariables } from './contextStore/EnvVariables';
import { UniverseModuleType } from 'src/domains/common/universes.type';
import { useCommon } from './Common';

const imports: Record<UniverseType, () => Promise<{ universeModule: UniverseModuleType }>> = {
    oddsbet: () => import('./universes/oddsbet/oddsbet'),
    summitbet: () => import('./universes/summitbet/summitbet'),
    swiftyglobal: () => import('./universes/swiftyglobal/swiftyglobal'),
};

export type ThemeColorsStarType = UniverseModuleType['themeColors'];

export const getUniverseModule = async (universe: UniverseType, isProdMode: boolean): Promise<UniverseModuleType> => {
    const load = imports[universe];
    const module = (await load()).universeModule;

    if (isProdMode) {
        return module;
    }

    const featuresDevUniverse = featuresDev[universe];

    return {
        ...module,
        features: {
            ...module.features,
            ...featuresDevUniverse,
        },
    };
};

export const getUniverseModuleByEnvVariables = (envVariables: EnvVariables): Promise<UniverseModuleType> => {
    return getUniverseModule(envVariables.universe, envVariables.isProdMode);
};

class ModuleLoading {
    private readonly data: AutoMap<[UniverseType, boolean], Resource<UniverseModuleType>>;

    public constructor() {
        this.data = new AutoMap(
            ([universe, isProdMode]) =>
                new Resource(async () => {
                    const unregister = registerNetworRequest();
                    try {
                        const module = await getUniverseModule(universe, isProdMode);
                        unregister();
                        return module;
                    } catch (error) {
                        unregister();
                        throw error;
                    }
                })
        );
    }

    public getModule(universe: UniverseType, isProdMode: boolean): UniverseModuleType {
        const result = this.data.get([universe, isProdMode]).get();

        switch (result.type) {
            case 'ready':
                return result.value;
            case 'loading':
                throw result.whenReady;
            case 'error':
                throw Error(`module loading error for universe=${universe}`);
            default:
                return assertNever('ModuleLoading.getModule', result);
        }
    }
}

const state = new ModuleLoading();

const useUniverseModuleContext = (): UniverseModuleType => {
    const common = useCommon();
    const env = common.envVariables;
    const module = state.getModule(env.universe, env.isProdMode);
    return module;
};

interface UniverseComponentPropsType {
    children: (UniverseModule: Omit<UniverseModuleType, 'features' | 'themeColors'>) => React.ReactElement;
}

export const UniverseComponent = observer('UniverseComponent', (props: UniverseComponentPropsType) => {
    const { children } = props;

    const InnerComponent = (): React.ReactElement => {
        const universeModule = useUniverseModuleContext();
        return children(universeModule);
    };

    return (
        <React.Suspense>
            <InnerComponent />
        </React.Suspense>
    );
});
