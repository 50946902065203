import { css, SerializedStyles } from '@emotion/react';

import styled from '@emotion/styled';

import { ChevronIconFull } from 'src/domains/layouts/shared/assets/icons/ChevronFull';

import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';

import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';



export const baseDropdownStyle = (): SerializedStyles => css`

    &.custom-dropdown-select .react-select__control {

        box-shadow: none;

        border: none;

        cursor: pointer;

        border-radius: 0;

    }



    &.custom-dropdown-select .react-select__control--is-focused {

        box-shadow: none;

        border: none;

    }

    &.custom-dropdown-select .react-select__option {

        cursor: pointer;

    }



    &.custom-dropdown-select .react-select__menu {

        box-shadow: none;

        margin-top: 0;

        border-radius: 0;

    }



    &.custom-dropdown-select .react-select__value-container,

    &.custom-dropdown-select .react-select__menu-list {

        padding: 0;

    }



    &.custom-dropdown-select .react-select__indicator-separator {

        display: none;

    }



    &.custom-dropdown-select .react-select__single-value,

    &.custom-dropdown-select .react-select__placeholder {

        margin: 0;

    }



    &.custom-dropdown-select .react-select__option--is-selected {

        display: flex;

        flex-flow: row nowrap;

        justify-content: space-between;

    }



    &.custom-dropdown-select .react-select__menu-list {

        overflow-y: auto;

        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {

            width: 4px;

        }

        &::-webkit-scrollbar-thumb {

            border-radius: 4px;

        }

        scrollbar-width: thin;

    }

`;



// dropdown indicator

export const ChevronIconWrapper = styled(ChevronIcon, { label: 'ChevronIconWrapper' })`

    fill: currentcolor;

    width: 12px;

`;



// dropdown indicator full

export const ChevronFullIconWrapper = styled(ChevronIconFull, { label: 'ChevronFullIconWrapper' })`

    fill: currentcolor;

    width: 9px;

`;



// checkmark for selected option

export const SelectCheckIconWrapper = styled(CheckSingleIcon, { label: 'SelectCheckIconWrapper' })`

    fill: currentcolor;

    width: 12px;

`;

