import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SwiftyGlobalWrapper } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalTab.style';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    errorMessage: string;
}

export const SwiftyGlobalTab = observer('SwiftyGlobalTab', ({ errorMessage }: PropsType): JSX.Element => {
    return (
        <SwiftyGlobalWrapper>
            <Messages type='error' marginBottom='16px' message={errorMessage} />
        </SwiftyGlobalWrapper>
    );
});
