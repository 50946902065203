import { z } from 'zod';

export interface PaymentIframeData {
    customerId: number;
    data: {
        currency: string;
        amount: number;
        singleUseCustomerToken: string;
        customer: CustomerData;
        billingAddress: AddressData;
        merchantRefNum: string;
        simulator: 'EXTERNAL';
        environment: 'TEST' | 'LIVE';
        buttonColor: string;
        imageUrl: string;
        companyName: string;
        holderName: string;
        threeDs: ThreeDsData;
        displayPaymentMethods: string[];
        paymentMethodDetails: PaymentMethodDetailsData;
    };
}

interface CustomerData {
    locale: string;
    firstName: string;
    lastName: string;
    dateOfBirth: {
        year: number;
        month: number;
        day: number;
    };
    email: string;
    phone: string;
}

interface AddressData {
    nickName: string;
    street: string;
    street2?: string | null;
    city: string;
    zip: string;
    country: string;
    state: string;
}

interface ThreeDsData {
    merchantUrl: string;
    deviceChannel: string;
    messageCategory: string;
    transactionIntent: string;
    authenticationPurpose: string;
}

interface PaymentMethodDetailsData {
    card: {
        accountId: string;
    };
}

export interface PaysafeInstance {
    isOpen: () => boolean;
    close: () => void;
    showSuccessScreen: (message: string) => void;
    showFailureScreen: (message: string) => void;
}

export const PaysafeError = z.object({
    code: z.string(),
    displayMessage: z.string(),
    message: z.string(),
    detailedMessage: z.string(),
    correlationId: z.string(),
});

export const PaysafeResult = z.object({
    paymentHandleToken: z.string(),
    paymentMethod: z.string(),
    customerOperation: z.string().optional().nullable(),
    amount: z.number(),
    transactionType: z.string().optional().nullable(),
});

export const PaysafeStage = z.enum([
    'PAYMENT_HANDLE_NOT_CREATED',
    'PAYMENT_HANDLE_CREATED',
    'PAYMENT_HANDLE_REDIRECT',
    'PAYMENT_HANDLE_PAYABLE',
]);

export const PaysafeExpired = z.boolean();
