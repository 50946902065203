//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    collection_slug: string,
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/anonymous/casino/categories-and-games/{collection_slug} -> get -> ${code}`);
    };
};

const Response200IO = t.interface({
    categories: t.array(t.interface({
        active: t.boolean,
        fixed: t.boolean,
        game_ids: t.array(t.number),
        id: t.number,
        name: t.string,
        universe: t.string,
        visible_in_all_games: t.boolean,
        visible_in_navigation: t.boolean,
    })),
    games: t.array(t.interface({
        demo: t.boolean,
        description: t.string,
        id: t.number,
        image: t.string,
        image_square: t.string,
        image_vertical: t.string,
        jackpot_amount: t.string,
        launch_game_id: t.string,
        max_bet: t.string,
        max_win: t.string,
        min_bet: t.string,
        name: t.string,
        provider: t.string,
        published: t.boolean,
        return_to_player: t.string,
        studio_id: t.string,
        studio_name: t.string,
        universe: t.string,
    })),
});
export type Response200Type = {
    categories: Array<{
        active: boolean,
        fixed: boolean,
        game_ids: Array<number>,
        id: number,
        name: string,
        universe: string,
        visible_in_all_games: boolean,
        visible_in_navigation: boolean,
    }>,
    games: Array<{
        demo: boolean,
        description: string,
        id: number,
        image: string,
        image_square: string,
        image_vertical: string,
        jackpot_amount: string,
        launch_game_id: string,
        max_bet: string,
        max_win: string,
        min_bet: string,
        name: string,
        provider: string,
        published: boolean,
        return_to_player: string,
        studio_id: string,
        studio_name: string,
        universe: string,
    }>,
};
export const decodeResponse200 = createGuard(200, Response200IO);

const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiCmsAnonymousGetCategoriesAndGamesRequest"
 */
export const openapi_cms_anonymous_get_categories_and_games = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/anonymous/casino/categories-and-games/${params.collection_slug}${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiCmsAnonymousGetCategoriesAndGamesParamsType = ParamsType;

export type OpenapiCmsAnonymousGetCategoriesAndGamesResponseType = {
    status: 200,
    body: Response200Type,
} | {
    status: 400,
    body: Response400Type,
} | {
    status: 401,
    body: Response401Type,
} | {
    status: 500,
    body: Response500Type,
};

export type OpenapiCmsAnonymousGetCategoriesAndGamesResponse200Type = Response200Type;

export const openapiCmsAnonymousGetCategoriesAndGamesRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiCmsAnonymousGetCategoriesAndGamesResponseType> => {
    const response = await openapi_cms_anonymous_get_categories_and_games(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        throw Error(`Http status ${status} - json was expected`);
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiCmsAnonymousGetCategoriesAndGamesRequest - unhandled response ${response.status}`);
};

