import {
    EventListGroupCompetitionItemType,
    EventListGroupEventItemType,
    EventListGroupSportItemType
} from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';

import { FilterType } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';

export const filterByTeams = (
    allowedSportId: string,
    competitionFilter: (arg0: EventListGroupCompetitionItemType) => boolean,
    allowedTeams: Set<string>
) => (
    sport: EventListGroupSportItemType,
    competition: EventListGroupCompetitionItemType,
    event: EventListGroupEventItemType
) : boolean => {
    const teams = event.name.split(' vs ');
    return sport.id === allowedSportId && teams.some((teamName) => allowedTeams.has(teamName)) && competitionFilter(competition);
};

export const filterCompetition = (competitions: Array<number>) => (
    _sport: EventListGroupSportItemType,
    competition: EventListGroupCompetitionItemType,
    _event: EventListGroupEventItemType
) : boolean => competitions.includes(competition.id);

export const filtersComposition = (filters: FilterType[]) => (
    sport: EventListGroupSportItemType,
    competition: EventListGroupCompetitionItemType,
    event: EventListGroupEventItemType
) : boolean => 
    filters.find((filter) => filter(sport, competition, event)) !== undefined;
