import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';

export const PopupOverlay = withConfig(theme => styled('div', { label: 'PopupOverlay' })`
    ${positionElementFull};
    align-items: center;
    background: ${theme.star.overlay.bgColorSecondary};
    display: flex;
    justify-content: center;
    opacity: 1;
    position: fixed;
    transition: opacity .3s ease;
    visibility: visible;
    z-index: ${theme.star.zIndexGlobal.popup};
`);

export const PopupWrapper = withConfig(theme => styled('div', { label: 'PopupWrapper' })`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.popup};
`);

export const MarketingQuestionsPopupWrapper = withConfig(theme => styled('div', { label: 'MarketingQuestionsPopupWrapper' })`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${theme.star.notifications.marketing.txtColorSecondary};
    background: ${theme.star.notifications.marketing.bgColorTertiary};
    opacity: 0.87;
    width: 100%;
    height: 100%;
    padding: 86px 36px 26px 36px;
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: ${theme.star.zIndexPopups.marketingPopupWrapper};
`);

export const SimplePopupWrapper = withConfig(theme => styled('div', { label: 'SimplePopupWrapper' })`
    background-color: ${theme.star.popup.bgColor};
`);

export const SimplePopupHeader = styled('h2', { label: 'SimplePopupHeader' })`
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 11px 16px;
    margin: 0;
    background-color: #f8f9fb;
`;

export const CloseIconWrapper = styled(CloseIcon, { label: 'CloseIconWrapper' })`
    position: absolute;
    right: 16px;
    width: 16px;
    height: 16px;
    border: 0;
    fill: #000;
    cursor: pointer;
`;

export const ContentWrapper = withConfig(theme => styled('div', { label: 'ContentWrapper' })`
    font-size: ${theme.star.fontSize.small};
    padding: 16px;
`);

export const ContentLink = withConfig(theme => styled('a', { label: 'ContentLink' })`
    font-weight: ${theme.star.fontWeight.bold};
`);
