import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';

const userAtributionIO = t.interface({
    value: t.union([t.interface({}), t.null, t.undefined]),
    clientId: t.union([t.string, t.null, t.undefined]),
});

const isUserAtributionIO = createGuard(userAtributionIO);

export const getWebsiteUserAttributionHeader = (): Record<string, string> | undefined => {
    if (typeof localStorage !== 'undefined' && localStorage !== null) {
        const userAtributionLocalStorage = localStorage.getItem('user-attributions');
        const userAtributionData = userAtributionLocalStorage === null ? null : JSON.parse(userAtributionLocalStorage);

        if (isUserAtributionIO(userAtributionData)) {

            const userAtribution = userAtributionData.value ?? {};

            const userSession = {
                googleClientId: userAtributionData.clientId
            };

            return {
                'pbe-user-attribution': btoa(JSON.stringify(userAtribution)),
                'pbe-user-session': btoa(JSON.stringify(userSession)),
            };
        }
    }
};

