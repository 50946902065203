import { MethodType, ParamsFetchType, GenerateUrlApiType, ResponseType } from 'src_common/browser/apiUtils';

import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import { openapi_website_cms_updatePromoCode, ParamsType, decodeResponse200, Response200Type } from 'src/api_openapi/generated/openapi_website_cms_updatePromoCode';

export type PromoCodeModelType = Response200Type;

const decode = (status: number, data: ResponseType): PromoCodeModelType => {
    if (status === 200 && data.type === 'json') {
        return decodeResponse200(data.json);
    }

    throw new Error(`unhandled response ${status} - ${data.type}`);
};

export interface PromoCodeTypeSubmitType {
    data: ParamsType['body'],
    id: number
}

export const put_update_promo_code = {
    browser: {
        params: (params: PromoCodeTypeSubmitType): ParamsFetchType<PromoCodeTypeSubmitType> => {
            return {
                type: MethodType.PUT,
                url: '/api-web/update_promo_code',
                body: params
            };
        },
        decode: decode
    },
    express: {
        method: MethodType.PUT,
        urlBrowser: '/api-web/update_promo_code',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<PromoCodeTypeSubmitType>): Promise<GenerateUrlApiType> => {

        const response = await openapi_website_cms_updatePromoCode(params.API_URL, params.API_TIMEOUT, params.jwtToken, {
            universe: params.API_UNIVERSE,
            id: params.req.body.id,
            body: params.req.body.data
        });

        return {
            passToBackend: false,
            status: response.status,
            responseBody: response.body
        };
    }
};
