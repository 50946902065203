export type FormatType = 'avif' | 'webp' | 'jpeg';

export type ImageOptimizeOptions = {
    quality?: number;
    height?: number;
    width?: number;
    fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
    /**
     * Other posible parameters:
     * https://developers.cloudflare.com/images/transform-images/transform-via-url/
     */
    // anim?: boolean;
    // background?: string;
    // blur?: number;
    // border?: todo;
    // brightness?: number;
    // compression?: 'fast';
    // contrast?: number;
    // dpr?: number;
    // format?: 'auto' | 'avif' | 'webp' | 'jpeg' | 'baseline-jpeg' | 'json';
    // gamma?: number;
    // gravity: todo;
    // metadata?: 'keep' | 'copyright' | 'none';
    // onerror?: 'redirect';
    // rotate?: number;
    // sharpen?: number;
    // trim?: string;
};

export type CloudflareImageOptimizationParams = ImageOptimizeOptions & {
    imgSrc: string;
    format: FormatType;
};

export const imageOptimizedUrl = (
    params: CloudflareImageOptimizationParams,
    baseWebsiteUrl: string | null
): string | null => {
    const { imgSrc, ...rest } = params;
    const paramChunks: Array<string> = [];

    if (imgSrc.trim().length === 0) {
        return null;
    }

    for (const [key, value] of Object.entries(rest)) {
        paramChunks.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }

    const urlParameters = paramChunks.join(',');
    const websiteUrl = baseWebsiteUrl === null ? '' : baseWebsiteUrl; // prod = relative; stage & local = fixed path

    return `${websiteUrl}/cdn-cgi/image/${urlParameters}/${imgSrc}`;
};
