import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { AddressesListItem } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/YourDetails.style';
import { action, makeObservable } from 'mobx';
import { findAddress } from 'src_server/trpc/types/accountModel';

interface AddressesListItemWrapperPropsType {
    setAddressFromPostcode: (address: findAddress.AddressItem) => void;
    address: findAddress.AddressItem;
}

class AddressesListItemWrapperState {

    public constructor(public readonly props: AddressesListItemWrapperPropsType){
        makeObservable(this);
    }

    @action public setAddressFromPostcode = (): void => {
        this.props.setAddressFromPostcode(this.props.address);
    };
}

export const AddressesListItemWrapper = observer('AddressesListItemWrapper', (props: AddressesListItemWrapperPropsType) => {
    const [state] = useState(() => new AddressesListItemWrapperState(props));

    return <AddressesListItem
        key={props.address.Id}
        onClick={state.setAddressFromPostcode}
    >
        {props.address.Text}
    </AddressesListItem>;
});
