import { sortByEventStart } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';

export interface UserViewType {
    sports: Record<string, boolean | undefined>,
    competitions: Record<number, boolean | undefined>,
}

const listToViewAllOpen = (collection: EventsCollectionQueryModel | null): UserViewType => {
    const result: UserViewType =  {
        sports: {},
        competitions: {},
    };
    if (collection !== null) {
        for (const sport of collection.value.sports) {
            result.sports[sport.id] = true;

            for (const competition of sport.competitions) {
                result.competitions[competition.id] = true;
            }
        }
    }
    return result;
};

const listToViewTopOpen = (collection: EventsCollectionQueryModel | null, topOpen: number): UserViewType => {
    const result: UserViewType =  {
        sports: {},
        competitions: {},
    };

    let counter = 0;

    const getVisibleFlag = (): boolean => {
        if (counter < topOpen) {
            counter++;
            return true;
        }

        return false;
    };
    if (collection !== null) {
        for (const sport of collection.value.sports) {
            let isVisibleSport = false;

            for (const competition of sport.competitions) {
                const isVisibleCompetition = getVisibleFlag();

                if (isVisibleCompetition) {
                    isVisibleSport = true;
                }

                result.competitions[competition.id] = isVisibleCompetition;
            }

            result.sports[sport.id] = isVisibleSport;
        }
    }

    return result;
};

const getIsVisibleSport = (userView: UserViewType, sportId: string): boolean => {
    return userView.sports[sportId] ?? false;
};

const getIsVisibleCompetition = (userView: UserViewType, competionId: number): boolean => {
    return userView.competitions[competionId] ?? false;
};

const calculateNewEventsGroup = (collection: EventsCollectionQueryModel | null, userView: UserViewType): UserViewType => {
    const result: UserViewType =  {
        sports: {},
        competitions: {},
    };
    if (collection !== null) {
        for (const sport of collection.value.sports) {
            result.sports[sport.id] = getIsVisibleSport(userView, sport.id);

            for (const competition of sport.competitions) {
                result.competitions[competition.id] = getIsVisibleCompetition(userView, competition.id);
            }
        }
    }

    return result;
};

const calculateNewEventsForSpecialSportGroup = (collection: EventsCollectionQueryModel | null, topOpen: number): UserViewType => {
    const result: UserViewType =  {
        sports: {},
        competitions: {},
    };

    const competitions = collection?.value.sports.map(elem => elem.competitions).flat().sort(sortByEventStart) ?? [];

    for (const [key, competition] of competitions.entries()) {
        result.competitions[competition.id] = key < topOpen;
    }

    return result;
};

export const calculateNextUserView = (
    userView: UserViewType | null,
    collection: EventsCollectionQueryModel | null,
    defaultOpen: 'all-open' | number,
    isSpecial: boolean
): UserViewType => {

    if (userView === null) {
        if (defaultOpen === 'all-open') {
            return listToViewAllOpen(collection);
        } else {
            if (isSpecial) {
                return calculateNewEventsForSpecialSportGroup(collection, defaultOpen);
            }
            return listToViewTopOpen(collection, defaultOpen);
        }
    } else {
        return calculateNewEventsGroup(collection, userView);
    }
};
