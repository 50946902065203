import { AppState } from 'src/appState/AppState';
import { EventsGroupState } from 'src/domains/sportsbook/state/eventsListState/eventsGroup/EventsGroupState';
import { EventListPropsType } from 'src/domains/sportsbook/webview/components/eventsList/EventsList';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';

interface ExpandsFlagType {
    showHeader: boolean;
    showBody: boolean;
}

export class EventsListState {
    private readonly DEFAULT_OPEN_GROUPS = 5;
    public readonly groupEvents: MobxMapAutoNew<EventsCollectionQueryModel | null, EventsGroupState>;

    public constructor(private readonly appState: AppState, private readonly props: EventListPropsType) {
        const { languagesState } = this.appState.appLayoutsState;

        this.groupEvents = new MobxMapAutoNew((eventsCollection) => {
            return new EventsGroupState(
                this.props.allGroupsOpen ? 'all-open' : this.DEFAULT_OPEN_GROUPS,
                languagesState.getTranslation,
                eventsCollection,
                props.specialSportsState,
            );
        });
    }

    public calculateShowFlag = (showHeader: boolean, isOpen: boolean): ExpandsFlagType => {
        if (showHeader === false) {
            return {
                showHeader: false,
                showBody: true,
            };
        };

        return {
            showHeader: true,
            showBody: isOpen,
        };
    };
}
