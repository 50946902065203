import React from 'react';
import { CounterQuickHeader, LinkName } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { RewardsButton, RewardsIconWrapper } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/RewardsButton.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

export const RewardsLink = observer('RewardsLink', () => {
    const { appLayoutsState } = useAppStateContext();
    const { lifeSpanState } = appLayoutsState;
    const { userRewardsMessage } = lifeSpanState;

    const activeRewards = userRewardsMessage.length;
    const counter = activeRewards > 0 ? <CounterQuickHeader>{activeRewards}</CounterQuickHeader> : null;

    const handleOpenLifeSpanWallet = (): void => {
        lifeSpanState.toggleWalletOpen();
    };

    return (
        <RewardsButton
            className='x-QuickHeaderLinkWrapperWithArrow'
            onClick={handleOpenLifeSpanWallet}
            isOpen={lifeSpanState.isWalletOpen}
        >
            <RewardsIconWrapper />
            <LinkName>
                <I18n langKey='quick-header.rewards.label' defaultText='Rewards' />
            </LinkName>
            {counter}
        </RewardsButton>
    );
});
