import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { assertNever } from 'src_common/common/assertNever';
import { ModalSuccess } from './modal-success/ModalSuccess';
import { ModalError } from './modal-error/ModalError';
import { ModalLoader } from './modal-loader/ModalLoader';
import { GamesNavigation } from './games-navigation/GamesNavigation';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';

interface ModalBodyType {
    appCasinoState: AppCasinoState;
    gameDataState: GameDataState;
}

export const ModalBody = observer('ModalBody', (props: ModalBodyType): ReactElement => {
    const { appCasinoState, gameDataState } = props;
    const { session } = gameDataState;

    const modalContent = (): ReactElement => {
        if (session.status === 'loading') {
            return <ModalLoader />;
        }

        if (session.status === 'error') {
            return <ModalError appCasinoState={appCasinoState} message={session.message} />;
        }

        if (session.value.status !== 200) {
            return <ModalError appCasinoState={appCasinoState} message={session.value.payload} />;
        }

        if (session.value.status === 200) {
            return <ModalSuccess appCasinoState={appCasinoState} url={session.value.payload} gameDataState={props.gameDataState} />;
        }

        return assertNever('ModalBody', session.value.status);
    };

    return (
        <>
            <GamesNavigation appCasinoState={appCasinoState} game={props.gameDataState} />
            {modalContent()}
        </>
    );
});

