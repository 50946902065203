
export const getParamNumber = (params: Record<string, string>, name: string): number | null => {
    const paramValue = params[name];

    if (paramValue === undefined) {
        return null;
    }

    const paramNumber = parseInt(paramValue, 10);

    if (isNaN(paramNumber)) {
        return null;
    }

    return paramNumber;
};
