import React, { ReactElement } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { isBetSP } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/betUtils';
import { useAppStateContext } from 'src/appState/AppState';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface PropsType {
    bet: BetHistoryType
}

export const BetReturnsLabel = observer<PropsType>('BetReturnsLabel', ({ bet }): ReactElement | null => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;

    const { usersState } = appPlayersState;
    const freeBetCredits = bet.transaction?.tags?.freebetCredits ?? [];
    const isFreeBet = freeBetCredits.length > 0;

    const isBetFinished = bet.status === 'settled' || bet.status === 'cancelled';
    const betBonusTypes = bet.transaction.tags?.bonuses ?? [];
    const bogBonus = betBonusTypes.find(elem => elem.type === 'best-odds-guaranteed') ?? null;
    const betPayout = bet.payout === null || bet.payout === undefined ? null : configComponents.precision.newFromAnything(bet.payout);

    if (isBetFinished && bogBonus !== null) {
        const bogAmount = betPayout === null ? betPayout : betPayout.add(configComponents.precision.newFromAnything(bogBonus.amount));
        const bogAmountForView = usersState.money(bogAmount);
        return (
            <div><I18n langKey='bets.list.bet-bog.returns' defaultText='Returns {bogAmountForView}' params={{ bogAmountForView }} /></div>
        );
    }

    const betPotentialReturns = bet.potentialReturns === null || bet.potentialReturns === undefined ? null : configComponents.precision.newFromAnything(bet.potentialReturns);
    const amount = isBetFinished ? usersState.money(betPayout) : isBetSP(bet.legs) ? 'n/a' : usersState.money(betPotentialReturns);

    if (isBetFinished) {
        return null;
        // return (
        //     <span><I18n langKey='bets.list.bet.returns' defaultText='Returns {amount}' params={{ amount }} /></span>
        // );
    }
    if (isFreeBet) {
        return (
            <div><I18n langKey='bets.list.bet.potential-returns' defaultText='Potential Returns {amount} will exclude stake' params={{ amount }} /></div>
        );
    }
    return (
        <div data-test='potential-returns'><I18n langKey='bets.list.bet.potential-returns.free-bet' defaultText='Potential Returns {amount}' params={{ amount }} /></div>
    );
});
