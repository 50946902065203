
import styled from '@emotion/styled';
import { TrashIcon } from 'src/domains/layouts/shared/assets/icons/TrashIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { BogIndicator } from 'src/domains/sportsbook/webview/components/bog/BogIdicator.style';
import { withConfig } from 'src/withConfig';

export const BetSlipError = styled('div', { label: 'BetSlipError' })``;

export const ReturnWrapper = withConfig(theme => styled('div', { label: 'ReturnWrapper' })`
    align-items: center;
    color: ${theme.star.betslipGeneral.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
`);

export const BogIndicatorSelectionList = styled(BogIndicator, { label: 'BogIndicatorSelectionList' })`
    margin-right: 4px;
`;

interface BetSlipSelectionWrapperPropTypes {
    manyBets?: boolean;
    isHidden?: boolean;
}

export const BetSlipSelectionWrapper = styled('li', { label: 'BetSlipSelectionWrapper' })<BetSlipSelectionWrapperPropTypes>`
    margin: 16px 0;
    position: relative;
    display: block;
    transition: 400ms ease-in;
    overflow: hidden;
    ${({ manyBets }): string => manyBets === true ? '' : 'padding-bottom: 0 !important'};
    ${({ isHidden }): string => isHidden === true ? `
        opacity: 0;
        height: 0;
        max-height: 0;
        transition: max-height .2s ease, opacity .2s ease;
        margin: 0;
    ` : `
        opacity: 1;
        max-height: 300px;
        transition: max-height .2s ease, opacity .2s ease;
    `};
`;

export const EventTitle = withConfig(theme => styled('div', { label: 'EventTitle' })`
    display: flex;
    align-items: center;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight:  ${theme.star.fontWeight.medium};
    line-height: 1.3;
    margin-right: 40px;
    padding-bottom: 5px;
    text-align: left;
    @media ${theme.star.mediaQuery.desktop} {
        margin: 0;
    }
`);

export const EventName = withConfig(theme => styled('div', { label: 'EventName' })`
    color: ${theme.star.betslipSelectionList.txtColor};
    align-items: center;
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.43;
    margin: 0 4px 0 0;
    text-align: left;
`);

export const MarketInfoWrapper = withConfig(theme => styled('div', { label: 'MarketInfoWrapper' })`
    color: ${theme.star.betslipSelectionList.txtColorSecondary};
    line-height: 1;
    margin-bottom: 8px;
    padding-right: 24px;
    width: 100%;
`);

export const InfoName = withConfig(theme => styled('div', { label: 'InfoName' })`
    display: inline;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.4;
    position: relative;
`);

export const NameSeparator = withConfig(theme => styled('span', { label: 'NameSeparator' })`
    font-size: ${theme.star.fontSize.xSmall};
    margin: 0 4px;
`);

export const SelectionOptions = withConfig(theme => styled('div', { label: 'SelectionOptions' })`
    align-items: center;
    background-color: ${theme.star.betslipGeneral.bgColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    height: 40px;
    justify-content: space-between;
    width: 100%;
`);

export const EachWayBoxLabel = withConfig(theme => styled('span', { label: 'EachWayBoxLabel' })`
    color: ${theme.star.eachWayBox.txtColor};
    display: block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    position: relative;
    top: 1px;
`);

export const TrashIconWrapper = styled(TrashIcon, { label: 'TrashIconWrapper' })`
    align-self: flex-start;
    cursor: pointer;
    fill: currentcolor;
    flex: 0 0 24px;
    opacity: 0.8;
    padding: 4px;
    width: 24px;
`;

export const RelatedWarningIconWrapper = styled(WarningIcon, { label: 'RelatedWarningIconWrapper' })`
    align-self: center;
    fill: currentcolor;
    margin-right: 5px;
    width: 12px;
`;

export const TitleWrapper = styled('div', { label: 'TitleWrapper' })`
    display: flex;
    justify-content: space-between;
`;

export const EachWayBox = withConfig(theme => styled('div', { label: 'EachWayBox' })`
    background-color: ${theme.star.eachWayBox.bgColor};
    flex: 0 0 66px;
    height: 100%;
    padding: 12px 8px;
    width: 66px;
`);
