import React from 'react';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import {
    SrgQuestionnaireDescriptionMore,
    SrgQuestionnaireMoreLess,
    SrgQuestionnaireEmailOrPhoneLink,
    SrgQuestionnaireDescriptionEmailInfoContainer,
    SrgParagraphSmall,
    StaticLinkWrapper,
} from './SrgQuestionnaire.style';

interface LanguagePropsTypes {
    isGreetingPart?: boolean;
    languagesState: LanguagesState;
    accountHelperMail: string;
}

const EmailHelperInformation = observer(
    'EmailHelperInformation',
    ({ isGreetingPart, languagesState, accountHelperMail }: LanguagePropsTypes) => {
        return (
            <SrgQuestionnaireDescriptionEmailInfoContainer isHighlited={isGreetingPart}>
                {languagesState.translateTokens(
                    languagesState.getTranslation(
                        'srg.verdict.footer.email-helper',
                        'If you require assistance in implementing any changes to your account using our Responsible Gambling tools you can email us at [accountHelperMail]'
                    ),

                    (singleParam: LanguageTokenType) => {
                        if (singleParam.tag === 'accountHelperMail') {
                            return (
                                <SrgQuestionnaireEmailOrPhoneLink
                                    isHighlited={isGreetingPart}
                                    href={`mailto:${accountHelperMail}`}
                                >
                                    {accountHelperMail}
                                </SrgQuestionnaireEmailOrPhoneLink>
                            );
                        }
                    }
                )}
            </SrgQuestionnaireDescriptionEmailInfoContainer>
        );
    }
);

const GameCareHelperInformation = observer('GameCareHelperInformation', ({ languagesState }: LanguagePropsTypes) => {
    return languagesState.translateTokens(
        languagesState.getTranslation(
            'srg.footer.gamecare-helper',
            'We would also like to make you aware of the below organisations available to you: GamCare: The leading authority providing counselling, advice, and practical help in addressing the social impact of gambling in the UK, can be visited at [gamcareHelperMail]'
        ),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'gamcareHelperMail') {
                return (
                    <StaticLinkWrapper
                        isUnderlined={true}
                        isHighlited={true}
                        to='https://www.gamcare.org.uk'
                    >
                        www.gamcare.org.uk
                    </StaticLinkWrapper>
                );
            }
        }
    );
});

const HelpLineInformation = observer('HelpLineInformation', ({ languagesState }: LanguagePropsTypes) => {
    return languagesState.translateTokens(
        languagesState.getTranslation('srg.footer.helpline-helper', ' Its confidential helpline is [helpLineTel]'),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'helpLineTel') {
                return (
                    <StaticLinkWrapper
                        isHighlited={true}
                        to='tel:+0845 6000 133'
                    >
                        0845 6000 133
                    </StaticLinkWrapper>
                );
            }
        }
    );
});

const GamblerInformation = observer('GamblerInformation', ({ languagesState }: LanguagePropsTypes) => {
    return (
        <div>
            {languagesState.translateTokens(
                languagesState.getTranslation(
                    'srg.footer.gambler-info',
                    'The Gamblers Anonymous international service site is at [gamblerInformation]'
                ),

                (singleParam: LanguageTokenType) => {
                    if (singleParam.tag === 'gamblerInformation') {
                        return (
                            <StaticLinkWrapper
                                isUnderlined={true}
                                isHighlited={true}
                                to='https://www.gamblersanonymous.org.uk'
                            >
                                www.gamblersanonymous.org.uk
                            </StaticLinkWrapper>
                        );
                    }
                }
            )}
        </div>
    );
});

const GamstopInformation = observer('GamstopInformation', ({ languagesState }: LanguagePropsTypes) => {
    return languagesState.translateTokens(
        languagesState.getTranslation(
            'srg.footer.gamstop-info',
            'Gamstop: Gamstop lets you put controls in place to restrict your online gambling activities. You will be prevented from using gambling websites and apps run by companies licensed in Great Britain, for a period of your choosing: [gamstopInformation]'
        ),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'gamstopInformation') {
                return (
                    <StaticLinkWrapper
                        isUnderlined={true}
                        isHighlited={true}
                        to='https://www.gamstop.co.uk'
                    >
                        www.gamstop.co.uk
                    </StaticLinkWrapper>
                );
            }
        }
    );
});

interface PropsTypes {
    isGreetingPart: boolean;
    languagesState: LanguagesState;
    accountHelperMail: string;
    showMoreDescription: boolean;
    toggleDescription: () => void;
    showToggleInfo: boolean;
}

export const SrgFooterDescription = observer(
    'SrgFooterDescription',
    ({
        isGreetingPart,
        languagesState,
        accountHelperMail,
        showMoreDescription,
        toggleDescription,
        showToggleInfo,
    }: PropsTypes): JSX.Element | null => {
        return (
            <>
                <SrgQuestionnaireDescriptionMore>
                    <EmailHelperInformation
                        languagesState={languagesState}
                        accountHelperMail={accountHelperMail}
                        isGreetingPart={isGreetingPart}
                    />

                    {showMoreDescription === true ? (
                        <>
                            <SrgParagraphSmall>
                                <GameCareHelperInformation
                                    languagesState={languagesState}
                                    accountHelperMail={accountHelperMail}
                                />
                                <HelpLineInformation
                                    languagesState={languagesState}
                                    accountHelperMail={accountHelperMail}
                                />
                            </SrgParagraphSmall>

                            <SrgParagraphSmall>
                                <I18n
                                    langKey='srg.footer.gamacare-info'
                                    defaultText='Non-UK residents can contact GamCare for details of International support organisations. Gamblers Anonymous: A fellowship of men and women who have joined together to do something about their gambling problem and help other compulsive gamblers do the same. There are regional fellowships around the world.'
                                />

                                <GamblerInformation
                                    languagesState={languagesState}
                                    accountHelperMail={accountHelperMail}
                                />
                            </SrgParagraphSmall>

                            <SrgParagraphSmall>
                                <GamstopInformation
                                    languagesState={languagesState}
                                    accountHelperMail={accountHelperMail}
                                />
                            </SrgParagraphSmall>
                        </>
                    ) : null}
                </SrgQuestionnaireDescriptionMore>

                {showToggleInfo ? (
                    <SrgQuestionnaireMoreLess onClick={toggleDescription}>
                        {showMoreDescription === true ? 'Less Info' : 'More Info...'}
                    </SrgQuestionnaireMoreLess>
                ) : null}
            </>
        );
    }
);
