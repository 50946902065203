import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BtnWrapper, LoginForm } from './PromoLogin.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const onSubmitDisable = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
};

interface PropsTypes {
    promotionSlug: string;
}

export const PromoLogin = observer('PromoLoginStep', ({ promotionSlug }: PropsTypes) => {
    const { appPlayersState } = useAppStateContext();
    const { specialEvents, loginState } = appPlayersState;

    const { ctaText } = specialEvents.getLandingPageState(promotionSlug);

    return (
        <LoginForm key='login-form' onSubmit={onSubmitDisable} id='login'>
            <Input
                name='email'
                state={loginState.emailState}
                placeholder='Username'
                placeholderSize='small'
                dataTest='landing-page-email-input'
            />
            <Input
                name='password'
                placeholder='Password'
                placeholderSize='small'
                type={loginState.isShowPassword ? 'text' : 'password'}
                showPassword={loginState.setShowPassword}
                isShowPassword={loginState.isShowPassword}
                showPasswordFlag={true}
                state={loginState.passwordState}
                dataTest='landing-page-password-input'
            />

            {loginState.loginErrorMsg === null ? null : (
                <Messages type='error' marginBottom='16px' message={loginState.loginErrorMsg} />
            )}
            <BtnWrapper
                dataTest='landing-page-login-button'
                disabled={!loginState.isFormValid}
                onClick={(): void => specialEvents.logIn(promotionSlug)}
                size='large'
                type='submit'
            >
                {ctaText === '' ? 'Login' : ctaText}
            </BtnWrapper>
        </LoginForm>
    );
});
