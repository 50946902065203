import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { GenderTitleState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/titleSection/TitleSection.state';
import { TitleSectionBoxState } from './TitleSectionBox.state';
import { TitleChooseBoxWrapper, TitleChooseBoxTitle } from './TitleSectionBox.style';
import { RadioButtonUniversal } from 'src/domains/layouts/webview/components/RadioButton';

interface PropsTypes {
    titleLabel: string;
    title: 'ms' | 'mr' | '';
    state: GenderTitleState;
    asideElement?: (() => HTMLElement | null) | undefined;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    dataTest?: string;
}

const checkTheTitle = (state: GenderTitleState, title: 'ms' | 'mr' | ''): boolean => {
    return state.titleChooseState.value === title;
};

export const TitleChooseBox = observer('TitleChooseBox', (props: PropsTypes) => {
    const { title, titleLabel, state, asideElement, onClick, dataTest } = props;
    const [ titleSectionBoxState ] = React.useState(() => new TitleSectionBoxState(state, title, asideElement, onClick));

    return (
        <TitleChooseBoxWrapper
            onClick={titleSectionBoxState.onClickBox}
        >
            <TitleChooseBoxTitle
                isActive={checkTheTitle(state, title)}
                data-test={dataTest}
            >
                {titleLabel}
            </TitleChooseBoxTitle>

            <RadioButtonUniversal
                isActive={checkTheTitle(state, title)}
                isDisabled={false}
                size='small'
                name={title}
                value={title}
                version='light'
            />
        </TitleChooseBoxWrapper>
    );
});
