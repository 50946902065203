import React from 'react';
import { observer } from 'src/utils/mobx-react';
import styled from '@emotion/styled';
import { useAppStateContext } from 'src/appState/AppState';

const Wrapper = styled('div', { label: 'Wrapper' })`
    padding: 10px;
`;

interface PropsType {
    notificationId: string | null;
    dataTest?: string;
}

export const PromotionTermsAndConditions = observer('PromotionTermsAndConditions', (props: PropsType) => {
    const { notificationId } = props;
    const { dataTest } = props;

    const { appLayoutsState } = useAppStateContext();
    const { notificationsState } = appLayoutsState;
    const notifications = notificationsState.notifications_right_promo_sorted;
    const notification = notifications.find(item => item.id.toString() === notificationId) ?? null;

    if (notification === null) {
        return null;
    }

    const htmlContentTermsAndConditions = notification.tc_text;

    return (
        <Wrapper dangerouslySetInnerHTML={{ __html: htmlContentTermsAndConditions }} data-test={dataTest}/>
    );
});
