import { TimelineType } from 'src_common/common/websocket2/models/EventModel';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';

type RaceViewStatusType = 'RaceStatusPrematch' | 'RaceStatusInProgress' | 'RaceStatusFinished';

export const calculateRaceStatus = (timeline: TimelineType, state: string): RaceViewStatusType => {
    if (timeline === 'finished') {
        if (state === 'resulted' || state === 'settled' || state === 'suspended') {
            return 'RaceStatusFinished';
        }
        return 'RaceStatusInProgress';
    }
    if (timeline === 'started') {
        return 'RaceStatusInProgress';
    }
    return 'RaceStatusPrematch';
};

export const calculateRaceStatusFromEventItem = (item: EventListGroupEventItemType): RaceViewStatusType => {
    return calculateRaceStatus(item.timeline, item.state);
};
