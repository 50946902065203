//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    universe: string;
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /website-cms/{universe}/live-notifications/active -> get -> ${code}`);
    };
};

const Response200IO = t.array(t.interface({
    cta_event: t.union([t.null, t.undefined, t.string]),
    cta_game: t.union([t.null, t.undefined, t.string]),
    cta_html_link: t.union([t.null, t.undefined, t.string]),
    cta_text: t.union([t.null, t.undefined, t.string]),
    date_from: t.union([t.null, t.undefined, t.string]),
    date_to: t.union([t.null, t.undefined, t.string]),
    display_order: t.number,
    id: t.number,
    img_url: t.union([t.null, t.undefined, t.string]),
    is_active: t.boolean,
    is_show_for_all_users: t.boolean,
    message_text: t.union([t.null, t.undefined, t.string]),
    title: t.string,
    universe: t.string,
    users_ids: t.unknown,
    visibility_option: t.union([t.null, t.undefined, t.string]),
}));
export type Response200Type = Array<{
    cta_event?: null | undefined | string;
    cta_game?: null | undefined | string;
    cta_html_link?: null | undefined | string;
    cta_text?: null | undefined | string;
    date_from?: null | undefined | string;
    date_to?: null | undefined | string;
    display_order: number;
    id: number;
    img_url?: null | undefined | string;
    is_active: boolean;
    is_show_for_all_users: boolean;
    message_text?: null | undefined | string;
    title: string;
    universe: string;
    users_ids: unknown;
    visibility_option?: null | undefined | string;
}>;
export const decodeResponse200 = createGuard(200, Response200IO);

const Response500IO = t.unknown;
export type Response500Type = unknown;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiWebsiteCmsGetLiveNotificationsActiveRequest"
 */
export const openapi_website_cms_getLiveNotificationsActive = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/website-cms/${params.universe}/live-notifications/active${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiWebsiteCmsGetLiveNotificationsActiveParamsType = ParamsType;

export type OpenapiWebsiteCmsGetLiveNotificationsActiveResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiWebsiteCmsGetLiveNotificationsActiveResponse200Type = Response200Type;

export const openapiWebsiteCmsGetLiveNotificationsActiveRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiWebsiteCmsGetLiveNotificationsActiveResponseType> => {
    const response = await openapi_website_cms_getLiveNotificationsActive(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiWebsiteCmsGetLiveNotificationsActiveRequest - unhandled response ${response.status}`);
};

