import { computed, makeObservable } from 'mobx';
import { apiCommon } from 'src/api/ApiCommon';
import { HeadlineCarouselActiveModelType } from 'src/api/config/cms_new/headlineCarouselActive/getHeadlineCarouselActive';
import { Resource } from 'src_common/common/mobx-utils/Resource';

export class HeadlineCarouselState {

    public readonly headlineCarouselResource: Resource<Array<HeadlineCarouselActiveModelType>>;
    
    public constructor(
        private readonly route: 'homepage' | 'casino' | 'live-casino' | 'virtuals'
    ) {
        makeObservable(this);
        this.headlineCarouselResource = new Resource(async (): Promise<Array<HeadlineCarouselActiveModelType>> => {
            return await apiCommon.getHeadlineCarouselActive.run({
                collectionSlug: this.route
            });
        });
    }


    @computed public get headlineCarouselData(): Array<HeadlineCarouselActiveModelType> {
        const response = this.headlineCarouselResource.get();
        if (response.type === 'ready') {
            return response.value;
        }

        return [];
    }
    
    @computed public get headlineCarouselDataTyoe(): string {
        const response = this.headlineCarouselResource.get();

        return response.type;
        
    }
    public getActiveTerms = (id: string): string | null => {
        const item = this.headlineCarouselData.find(x => x.id === Number(id));
        return item?.tc_text ?? '';
    }

}
