import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { withConfig } from 'src/withConfig';

export const StaticPageWrapper = styled('div', { label: 'StaticPageWrapper' })`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StaticPageInnerWrapper = withConfig(theme => styled('div', { label: 'StaticPageInnerWrapper' })`
    flex: 1 0 0%;
    padding: 10px 16px 16px 16px;
    overflow-x: hidden;
    line-height: 1.6;
    height: 100%;
    background-color: ${theme.star.accountDrawer.bgColorSecondary};
    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
        margin: 0 0 5px 0;
        line-height: 1.3;
        padding: 0;
        color: ${theme.star.staticPage.txtColor};
        font-weight: 500;
    }
    p {
        margin-top: 0;
        line-height: 1.6;
    }
    ul {
        padding: 5px 0;
    }
    ul > li {
        padding-left: 20px;
        position: relative;
        margin-bottom: 10px;
    }
    ul > li::before {
        content: '';
        position: absolute;
        border-radius: 50px;
        border: 1px solid ${theme.star.staticPage.borderColor};
        width: 10px;
        height: 10px;
        left: 0;
        top: 5px;
    }
`);

export const HeaderWrapper = withConfig(theme => styled('header', { label: 'HeaderWrapper' })`
    display: flex;
    flex: 0 0 48px;
    align-items: center;
    position: relative;
    background: ${theme.star.sidebar.bgColor};
    color: ${theme.star.sidebar.txtColor};
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.3;
`);

export const TitleWrapper = withConfig(theme => styled('h3', { label: 'TitleWrapper' })`
    color: ${theme.star.sidebar.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 0 16px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`);

export const HeaderCloseIcon = styled(CloseIcon, { label: 'HeaderCloseIcon' })`
    cursor: pointer;
    fill: currentcolor;
    margin-left: auto;
    padding: 14px;
    width: 48px;
`;
