import styled from '@emotion/styled';
import { setRaceCell, RaceSummaryCaption, SelectionCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { withConfig } from 'src/withConfig';

export const RaceResultsRowCaption = withConfig(theme => styled(RaceSummaryCaption, { label: 'RaceResultsRowCaption' })`
    background-color: ${theme.star.raceCardColors.bgColorResultsTableRow};
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: ${theme.star.raceSummary.borderColor};
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    height: 48px;
    justify-content: space-between;
    line-height: 1.4;
    @media ${theme.star.mediaQuery.tablet} {
        height: 56px;
    }
`);

export const ResultsTableMessage = withConfig(theme => styled('div', { label: 'ResultsTableMessage' })`
    background-color: ${theme.star.raceCardColors.bgColorResultsTableMessage};
    color: ${theme.star.raceCardColors.txtColorResults};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    padding: 12px 8px;
`);

export const ResultCaption = withConfig(theme => styled('span', { label: 'ResultCaption' })`
    ${setRaceCell(theme)};
    flex: 0 0 64px;
    text-align: center;
`);

export const ResultCell = withConfig(theme => styled(ResultCaption, { label: 'ResultCell' })`
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    color: ${theme.star.raceSummary.txtColor};
    flex-flow: column nowrap;
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const ResultSelectionCaption = withConfig(theme => styled(SelectionCaption, { label: 'ResultSelectionCaption' })`
    color: ${theme.star.raceCardColors.txtColorSelectionCaption};
`);

export const ResultSelectionCell = withConfig(theme => styled(SelectionCaption, { label: 'ResultSelectionCell' })`
    color: ${theme.star.raceCardColors.txtColorResultedSelectionPrice};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xRegular};
    }
`);

export const InformationBox = withConfig(theme => styled('div', { label: 'InformationBox' })`
    align-items: center;
    background: ${theme.star.raceCardColors.bgColorResultsInformationBox};
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: ${theme.star.raceSummary.borderColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    padding: 8px;
`);

export const InfoIconWrapper = withConfig(theme => styled(InfoIcon, { label: 'InfoIconWrapper' })`
    fill: ${theme.star.raceCardColors.iconColorResultsInformationBox};
    flex: 0 0 16px;
    height: 16px;
    /* TODO: Lack of design in new tokens - please check later */
    ${(): string => theme.star.themeTokens.brand.primary === '' ? 'opacity: 1' : 'opacity: 0.8'}
`);

export const InfoElement = styled('span', { label: 'InfoElement' })`
    margin-left: 8px;
`;
