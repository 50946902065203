import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsType {
    langKey: string;
    defaultText: string;
    params?: Record<string, string | number>;
}

export const I18n = observer('I18n', (props: PropsType): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { langKey, defaultText, params } = props;

    return <>{languagesState.getTranslation(langKey, defaultText, params)}</>;
});
