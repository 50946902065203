import { observable, action, computed, makeObservable } from 'mobx';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { comparePassword, checkPassword, validateEmail, validateEmailRaw, validatePassword, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { emailCheckMap } from 'src/domains/players/webview/components/SignUp/signupParts/emailValidation/EmailValidation';

export interface EmailAndPasswordFormType {
    email: string;
    password: string;
}

export class EmailAndPasswordState {
    @observable public isShowPassword = false;
    @observable public isShowSecondPassword = false;


    private readonly emailCheckMap: MobxMapAutoNew<string, Resource<boolean>>;

    public readonly emailState: FormInputState<string,string>;
    public readonly passwordState: FormInputState<string,string>;
    public readonly secondPasswordState: FormInputState<string,string>;
    public readonly emailAndPasswordModel: FormModel<EmailAndPasswordFormType>;

    public constructor(
        public readonly language: LanguagesState,
    ) {
        makeObservable(this);
        this.emailCheckMap = emailCheckMap();


        this.emailState = FormInputState.new('').map(validateRequire).map((value: string): Result<string> => {
            const valueTrim = value.trim();
            return Result.createOk(valueTrim);
        })
            .map(validateEmail)
            .map((value: string): Result<string> => {
                const isExistResult = this.emailCheckMap.get(value).get();

                if (isExistResult.type === 'ready') {
                    const isExist = isExistResult.value;

                    if (isExist) {
                        return Result.createError(getErrorByCode('ERROR_ALREADY_EXISTS'));
                    }
                }

                return Result.createOk(value);
            });

        this.passwordState = FormInputState.new('').map(validatePassword);
        this.secondPasswordState = FormInputState.new('').map(value => comparePassword(value, this.passwordState.value,language));

        this.emailAndPasswordModel = FormModel.group({
            email: this.emailState,
            password: this.passwordState,
        });
    };

    @computed public get isCorrectEmail(): boolean {
        const email = this.emailState.value;
        const emailItem = this.emailCheckMap.get(this.emailState.value).get();

        if (emailItem.type === 'ready') {
            const isExist = emailItem.value;
            if (isExist) {
                return false;
            }

            const isEmailOk = validateEmailRaw(email);
            return isEmailOk;
        }

        return false;
    }

    @computed public get isCorrectPassword(): boolean {
        const password = this.passwordState.value;

        return checkPassword(password);
    }

    @computed public get emailAndPasswordIsValid(): boolean {
        return this.emailState.errorsForView.length === 0 &&
        this.passwordState.errorsForView.length === 0 &&
        this.secondPasswordState.errorsForView.length === 0 &&
        this.emailState.value !== '' &&
        this.passwordState.value !== '' &&
        this.secondPasswordState.value !== '';
    }

    @action public showPassword = (): void => {
        this.isShowPassword = !this.isShowPassword;
    };


    @computed public get isCorrectSecondPassword(): boolean {
        const password = this.secondPasswordState.value;

        return checkPassword(password);
    }


    @action public showSecondPassword = (): void => {
        this.isShowSecondPassword = !this.isShowSecondPassword;
    };
}
