import { Value } from 'src_common/common/mobx-utils/Value';

const mobxValue = new Value(true, (setValue) => {
    if (typeof window === 'undefined') {
        return (): void => {};
    }

    const onOffline = (): void => {
        setValue(false);
    };
    const onOnline = (): void => {
        setValue(true);
    };

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    setValue(navigator.onLine);

    return (): void => {
        window.removeEventListener('offline', onOffline);
        window.removeEventListener('online', onOnline);
    };
});

export const getOnline = (): boolean => {
    return mobxValue.getValue();
};
