import { observable, makeObservable } from 'mobx';

export class DateInputSelectState {
    @observable public value: number;

    public constructor(value: number) {
        makeObservable(this);
        this.value = value;
    }

    public onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.value = parseInt(event.target.value, 10);
    };
}
