import * as React from 'react';
import {
    InfoIconWrapper,
    MessageContent,
    MessageWrapper,
    SuccessIconWrapper,
    WarningIconWrapper,
} from './Messages.style';
import { observer } from 'src/utils/mobx-react';

type MessageType = 'error' | 'info' | 'success';

interface MessagesPropsType {
    className?: string;
    hasIcon?: boolean;
    marginBottom?: string;
    marginTop?: string;
    message: React.ReactNode | string;
    type: MessageType;
    dataTest?: string;
}

const iconType: Record<MessageType, JSX.Element> = {
    error: <WarningIconWrapper />,
    info: <InfoIconWrapper />,
    success: <SuccessIconWrapper />,
};

export const Messages = observer('Messages', (props: MessagesPropsType): JSX.Element | null => {
    const { dataTest, message, marginTop, marginBottom, hasIcon, className, type } = props;

    if (message === null) {
        return null;
    }

    const renderContent = (): React.ReactNode | string => {
        if (hasIcon === true) {
            const IconComponent = iconType[type];

            return (
                <>
                    {IconComponent}
                    <MessageContent>{message}</MessageContent>
                </>
            );
        }

        return message;
    };

    return (
        <MessageWrapper
            className={className}
            data-test={dataTest === undefined ? `${type}-message-content` : dataTest}
            hasIcon={hasIcon}
            marginBottom={marginBottom}
            marginTop={marginTop}
            type={type}
        >
            {renderContent()}
        </MessageWrapper>
    );
});
