import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LifeSpanBoostButton } from 'src/domains/sportsbook/betting/ui/betSlip/lifeSpanBoost/LifeSpanBoostButton';
import { SelectionBoostState } from 'src/domains/layouts/state/lifespanState/LifespanSocketState';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { Amount } from 'src_common/common/amount/Amount';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface LifeSpanButtonPropsType {
    selectionWithBoost: SelectionBoostState;
    isStarted: boolean;
    lifeSpanState: LifeSpanState;
    legModelReturns: Amount | null;
    selectionId: SelectionId;
    stakePerLine: Amount | null;
    selectedPriceType: string | undefined;
}

export const LifeSpanButton = observer('LifeSpanButton', (props: LifeSpanButtonPropsType): JSX.Element | null => {
    const {
        selectionWithBoost,
        isStarted,
        lifeSpanState,
        legModelReturns,
        selectionId,
        selectedPriceType,
        stakePerLine,
    } = props;
    const isNotStartingPrice = selectedPriceType !== 'SP';
    const stakePerLineAmount = stakePerLine ?? null;

    if (selectionWithBoost.selectionInfo?.reward !== undefined && isStarted === false && isNotStartingPrice) {
        return (
            <LifeSpanBoostButton
                selectionBoost={selectionWithBoost}
                activateBoost={lifeSpanState.activeSelectionBoost}
                deactivateBoost={lifeSpanState.deactivateSelectionBoost}
                potentialReturn={legModelReturns}
                selectionId={selectionId}
                stakePerLine={stakePerLineAmount}
            />
        );
    }

    return null;
});
