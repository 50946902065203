import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const validateOldEmail = (usersState: UsersState) => (value: string): Result<string> => {
    const accountData = usersState.basicData.get();

    if (accountData.type === 'loading' || accountData.type === 'ready' && (accountData.value.email ?? '').trim().toLowerCase() === value) {
        return Result.createOk(value);
    }

    return Result.createError(getErrorByCode('ERROR_INCORRECT_OLD_EMAIL'));
};
