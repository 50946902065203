import { UniverseType } from 'src_common/common/universe';
import { ConfigType } from 'src/domains/layouts/config/features/types';

type FeaturesDevType = Partial<ConfigType>;

export const featuresDev: Record<UniverseType, FeaturesDevType> = {
    oddsbet: {
        gtm: 'GTM-KG6T57VL',
        fullstory: null,
        withdrawValidateBtcAddress: false,
    },
    summitbet: {
        gtm: 'GTM-T9HP7C2',
        fullstory: null,
        withdrawValidateBtcAddress: false,
    },
    swiftyglobal: {
        gtm: 'GTM-N9QM5V8',
        fullstory: null,
        imageOptimization: {
            type: 'baseUrl',
            url: 'https://website-swiftyglobal.stg.swiftyglobal-multi.pbe-cloud.com',
        },
        withdrawValidateBtcAddress: false,
    },
};
