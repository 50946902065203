import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface EmailIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const EmailIcon = observer(
    'EmailIcon',
    ({ className, onClick }: EmailIconPropsType): JSX.Element => {
        return (
            <SvgElement 
                className={className}
                fill='#072d4f'
                height='24px'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24px'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    clipRule='evenodd'
                    d='M7.13 8.97 1.1 5.7c-.24-.12-.64-.33-.73-.58-.15-.44.02-.61.66-.61h22.06c.64 0 .8.17.65.6-.09.25-.48.47-.72.6l-6.04 3.27-3.74 2.04c-.33.17-.66.25-1.18.25-.52 0-.86-.08-1.19-.25L7.13 8.97ZM21 7.65c1.3-.9 2.3-1.59 2.52-1.7.48-.24.48.01.48.24v11.87c0 .53-.68 1.2-1.24 1.2H1.36c-.57 0-1.36-.66-1.36-1.2V6.24c0-.24.03-.55.54-.29.22.12 1.29.85 2.65 1.78 1.53 1.05 3.44 2.34 4.92 3.3l-4.74 5.34c-.11.12-.16.26-.1.33.08.07.23.03.35-.08l5.69-4.8 1.56.99c.45.22.76.25 1.19.25.42 0 .74-.03 1.18-.25.1-.06.71-.44 1.57-1l5.69 4.8c.12.11.27.15.34.09.06-.07.01-.21-.1-.33l-4.75-5.33c1.53-.99 3.47-2.33 5.01-3.39Z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
