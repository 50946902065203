/* eslint-disable import/no-relative-parent-imports */
import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Close, Container, Wrapper } from './AuthLandingPage.style';
import { UniverseComponent } from 'src/domains/common/universes';
import { LoginTab } from 'src/domains/players/webview/components/Account/loginTab/LoginTab';
import { ForgotPasswordTab } from 'src/domains/players/shared/Components';
import { RouteViewType } from '../../state/router/newRouter/mainRouteTypes';
import { SignUp } from 'src/domains/players/webview/components/SignUp/SignUp';
import { useAppStateContext } from 'src/appState/AppState';
import { ResetPasswordProcedure } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/ResetPasswordProcedure';
import { StarRouter } from '../../state/router/StarRouter';

type AuthLandingPagePropsType = {
    currentView: RouteViewType;
};

enum AuthActions {
    LOGIN = 'login',
    SIGNUP = 'signup',
    FORGOT_PASSWORD = 'forgot-password',
    RESET_PASSWORD = 'reset-password',
}

export const AuthLandingPage = observer('AuthLandingPage', ({ currentView }: AuthLandingPagePropsType) => {
    const { appLayoutsState, common } = useAppStateContext();
    const {
        starRouter: { redirectToLoginHomepage },
    } = appLayoutsState;
    const router = StarRouter.get(common);

    const handleRedirectToLogin = (): void => redirectToLoginHomepage();

    let content;

    switch (currentView.name) {
        case AuthActions.LOGIN:
            content = <LoginTab />;
            break;
        case AuthActions.SIGNUP:
            content = <SignUp />;
            break;
        case AuthActions.FORGOT_PASSWORD:
            content = <ForgotPasswordTab />;
            break;
        case AuthActions.RESET_PASSWORD:
            content = (
                <ResetPasswordProcedure currentRoute={{ ...router.resetPasswordParams, account: 'reset-password' }} />
            );
            break;
        default:
            content = <LoginTab />;
    }

    return (
        <Wrapper>
            {currentView.name !== AuthActions.LOGIN && <Close onClick={handleRedirectToLogin} />}

            {currentView.name !== AuthActions.SIGNUP && (
                <UniverseComponent>
                    {(universeVersion): React.ReactElement => (
                        <universeVersion.headerLink dataTest='operator-logo'>
                            <universeVersion.signUpLogo />
                        </universeVersion.headerLink>
                    )}
                </UniverseComponent>
            )}

            <Container>{content}</Container>
        </Wrapper>
    );
});
