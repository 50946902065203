import { Session } from 'src_common/sdk/session';
import { OpenapiProxyAccountBasicDataResponse200Type } from 'src/api_openapi/generated/openapi_proxy_account_basic_data';
import { OpenapiProxyCustomerTransactionHistoryResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_transaction_history';
import {
    openapiProxyAnonymousSportsRequest,
    OpenapiProxyAnonymousSportsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_sports';
import { OpenapiProxyCustomerNetDepositResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_net_deposit';
import { Resource, Result } from 'src_common/common/mobx-utils/Resource';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import {
    openapiProxyAnonymousFindPageRequest,
    OpenapiProxyAnonymousFindPageResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_find_page';

import { computed, makeObservable } from 'mobx';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { ModelsStateSocketConfig } from 'src_common/common/websocket2/ModelsStateSocketConfig';
import {
    CurrencySymbolType,
    CurrencyType,
    moneySymbol,
    formatAmountWithCurrency,
} from 'src_common/common/amount/website-money/currency';
import { LayoutsCallbacksType } from './AppLayouts.state';
import { ResourceCustomer } from 'src/domains/players/shared/Types';
import { Amount } from 'src_common/common/amount/Amount';
import {
    OpenapiProxyAnonymousRequestPasswordResetParamsType,
    openapiProxyAnonymousRequestPasswordResetRequest,
    OpenapiProxyAnonymousRequestPasswordResetResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_request_password_reset';
import {
    openapiProxyAnonymousMobileAppUpdateRequest,
    OpenapiProxyAnonymousMobileAppUpdateResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_mobile_app_update';

export type AccountBasicDataType = OpenapiProxyAccountBasicDataResponse200Type;
export type CustomerTransactionHistoryType = OpenapiProxyCustomerTransactionHistoryResponse200Type;
export type CustomerNetDepositType = OpenapiProxyCustomerNetDepositResponse200Type;
export type PageCMSType = OpenapiProxyAnonymousFindPageResponse200Type['result'];

interface SdkCustomerConfig {
    socketJoinSport: boolean;
    oddsFormatShortDefault?: 'f' | 'd';
    currencyDefault: CurrencyType;
}

export class SdkCustomer {
    public readonly config: SdkCustomerConfig;
    public readonly session: Session;
    public readonly models: ModelsState;
    private readonly newStaticPage: MobxMapAutoNew<string, Resource<PageCMSType | null>>;

    public readonly apiEventListIncludes: Record<string, string[]>;

    public constructor(
        session: Session,
        config: SdkCustomerConfig,
        private readonly callbacks: LayoutsCallbacksType
    ) {
        makeObservable(this);
        this.config = config;
        this.session = session;

        this.newStaticPage = new MobxMapAutoNew(
            (pageId: string) =>
                new Resource(async (): Promise<PageCMSType | null> => {
                    const data = await this.session.call(openapiProxyAnonymousFindPageRequest, {
                        requestBody: {
                            slug: pageId,
                            // Leave en-gb language for now
                            page_language: 'en-gb',
                        },
                    });

                    return data.result ?? null;
                })
        );

        this.apiEventListIncludes = config.socketJoinSport
            ? {
                  horseracing: ['internationalhorseracing', 'horseracingantepost'],
                  greyhoundracing: ['internationalgreyhoundracing'],
              }
            : {};

        const modelsStateSocketConfig = new ModelsStateSocketConfig({
            apiEventListIncludes: (): Record<string, string[]> => this.apiEventListIncludes,
            oddsFormatShort: (): 'f' | 'd' | 'a' => this.callbacks.getOddsFormat(),
        });

        this.models = new ModelsState(
            this.session.isBrowser(),
            modelsStateSocketConfig,
            this.session.websocketUrl //initData.websocket_host_v2,
        );
    }

    public get basicData(): ResourceCustomer<Omit<AccountBasicDataType, 'oddsFormat'>> {
        return this.callbacks.getBasicData();
    }

    public getPage(slug: string): Result<PageCMSType | null> {
        const newPage = this.newStaticPage.get(slug).get();

        if (newPage.type === 'ready') {
            return {
                type: 'ready',
                value: newPage.value,
            };
        }

        if (newPage.type === 'loading') {
            return newPage;
        }
        return {
            type: 'ready',
            value: null,
        };
    }

    @computed public get currency(): CurrencyType {
        return this.callbacks.getCurrency();
    }

    public money = (amount: Amount | null | undefined, withoutZeros?: boolean): string => {
        return formatAmountWithCurrency(this.currency, amount, withoutZeros);
    };

    @computed public get moneySymbol(): CurrencySymbolType {
        return moneySymbol(this.currency);
    }

    public getVersionList(): Promise<OpenapiProxyAnonymousMobileAppUpdateResponse200Type> | null {
        return this.session.call(openapiProxyAnonymousMobileAppUpdateRequest, {});
    }

    public getSportsList(): Promise<OpenapiProxyAnonymousSportsResponse200Type> {
        return this.session.call(openapiProxyAnonymousSportsRequest, {});
    }

    public requestResetPassword(
        params: OpenapiProxyAnonymousRequestPasswordResetParamsType
    ): Promise<OpenapiProxyAnonymousRequestPasswordResetResponse200Type> {
        return this.session.call(openapiProxyAnonymousRequestPasswordResetRequest, params);
    }
}
