import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { MessageBox } from './MessageBarWrapper/MessageBox';
import { computed, makeObservable } from 'mobx';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { LoadingWrapper } from 'src/domains/layouts/shared/loaders';
import { NotificationsState } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { Wrapper } from 'src/domains/layouts/webview/components/MessageBarWrapper/MessageBoxWrapper.style';
import { useAppStateContext } from 'src/appState/AppState';

class MessageBarWrapperState {
    public readonly reactiveWrapper: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);

    public constructor(
        public readonly notificationsState: NotificationsState
    ) {
        makeObservable(this);
    }

    @computed public get elementRefHeight(): number {
        const elementRef = this.reactiveWrapper.ref;
        return elementRef?.clientHeight ?? 0;
    }
}

export const MessageBarWrapper = observer('MessageBarWrapper', (): JSX.Element => {
    const { appLayoutsState } = useAppStateContext();
    const { notificationsState } = appLayoutsState;
    const [state] = useState(() => new MessageBarWrapperState(notificationsState));

    return (
        <LoadingWrapper computedHeight={state.elementRefHeight}>
            <Wrapper areAllClosed={notificationsState.areAllNotificationsClosed || notificationsState.hideAll} ref={state.reactiveWrapper.setRef}>
                {
                    notificationsState.messagesBox.length > 0 ?
                        notificationsState.messagesBox.map(
                            message => <MessageBox key={message.id} message={message} />
                        ) : null
                }
            </Wrapper>
        </LoadingWrapper>
    );
});
