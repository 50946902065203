import * as React from 'react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import { IframeStandard, ScoreBoardWrapper } from 'src/domains/sportsbook/webview/components/scoreboards/standard/ScoreboardStandard.style';

interface UriParams {
    eventId: string,
}

const getBaseballUrl = ({ eventId }: UriParams): string => {
    return `//scoreboards.sportingsolutions.com/baseball_responsive.html#&event_id=${eventId}&customer=starsports`;
};

interface PropsType {
    event: EventModel,
}

export const BaseballScoreboard = observer('BaseballScoreboard', ({ event }: PropsType) => {
    const { feedGroupId } = event;

    if (feedGroupId !== null && feedGroupId !== undefined) {
        const url = getBaseballUrl({
            eventId: feedGroupId
        });

        return (
            <ScoreBoardWrapper>
                <IframeStandard src={url} key={feedGroupId} />
            </ScoreBoardWrapper>
        );
    }

    return null;
});
