import { observable, computed, makeObservable } from 'mobx';
import React, { useState, ReactElement } from 'react';
import { StaticListWrapperStyled, ScrollbarListWrapperStyled, TwoColumnsListWrapper } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll.style';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { observer } from 'src/utils/mobx-react';

type ListPropsType = {
    children: ReactElement
    wrapperState:RaceSummaryTwoColumnsListState
    columnCount: number
}

const StaticListWrapper = observer('StaticListWrapper', (props: ListPropsType) => {
    return <StaticListWrapperStyled isScrollbar={props.wrapperState.shouldBeScrollbar}>{props.children}</StaticListWrapperStyled>;
});

const ScrollbarListWrapper = observer('ScrollbarListWrapper', (props: ListPropsType) => {
    return <ScrollbarListWrapperStyled ref={props.wrapperState.scrollListElem.setRef} columnCount={props.columnCount}>{props.children}</ScrollbarListWrapperStyled>;
});

type RaceSummaryTwoColumnsListPropsType = {
    staticListElements: ReactElement,
    scrollListElements: ReactElement,
    columnCount: number
}

class RaceSummaryTwoColumnsListState {
    @observable public scrollListElem: HtmlElementReactive<HTMLElement>;

    public constructor() {
        makeObservable(this);
        this.scrollListElem = new HtmlElementReactive(300);
    }

    @computed public get shouldBeScrollbar(): boolean {
        if (this.scrollListElem.ref === null) {
            return false;
        }
        if (this.scrollListElem.ref.clientWidth < this.scrollListElem.ref.scrollWidth) {
            return true;
        }

        return false;
    }
}

export const RaceSummaryTwoColumnsList = observer('TwoColumnsListWrapper', (props: RaceSummaryTwoColumnsListPropsType) => {
    const [state] = useState(() => new RaceSummaryTwoColumnsListState());

    return (
        <TwoColumnsListWrapper>
            <StaticListWrapper wrapperState={state} columnCount={props.columnCount}>
                {props.staticListElements}
            </StaticListWrapper>

            <ScrollbarListWrapper wrapperState={state} columnCount={props.columnCount}>
                {props.scrollListElements}
            </ScrollbarListWrapper>
        </TwoColumnsListWrapper>
    );

});
