//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    universe: string;
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /website-cms/{universe}/contact-us -> get -> ${code}`);
    };
};

export const Response200IO = t.array(t.interface({
    contact_type: t.string,
    contact_value: t.string,
    display_order: t.number,
    id: t.number,
    is_active: t.boolean,
    label: t.union([t.null, t.undefined, t.string]),
    universe: t.string,
}));
export type Response200Type = Array<{
    contact_type: string;
    contact_value: string;
    display_order: number;
    id: number;
    is_active: boolean;
    label?: null | undefined | string;
    universe: string;
}>;
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.unknown;
export type Response400Type = unknown;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.unknown;
export type Response401Type = unknown;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response404IO = t.unknown;
export type Response404Type = unknown;
export const decodeResponse404 = createGuard(404, Response404IO);

export const Response500IO = t.unknown;
export type Response500Type = unknown;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiWebsiteCmsGetContactUsElementsRequest"
 */
export const openapi_website_cms_getContactUsElements = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/website-cms/${params.universe}/contact-us${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiWebsiteCmsGetContactUsElementsParamsType = ParamsType;

export type OpenapiWebsiteCmsGetContactUsElementsResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 404;
    body: Response404Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiWebsiteCmsGetContactUsElementsResponse200Type = Response200Type;

export const openapiWebsiteCmsGetContactUsElementsRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiWebsiteCmsGetContactUsElementsResponseType> => {
    const response = await openapi_website_cms_getContactUsElements(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 404) {
        return { status: 404, body: decodeResponse404(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiWebsiteCmsGetContactUsElementsRequest - unhandled response ${response.status}`);
};

