import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const validateEmailRaw = (value: string): boolean => {
    const EMAIL_REGEXP = /^([\w\-+]+(?:\.[\w\-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

    if (!EMAIL_REGEXP.test(value)) {
        return false;
    }

    return true;
};

export const validateEmail = (value: string): Result<string> => {
    const isOk = validateEmailRaw(value);

    if (!isOk) {
        return Result.createError(getErrorByCode('ERROR_INVALID_EMAIL'));
    }

    return  Result.createOk(value);
};

export const validateEmailOrTelebettingID = (value: string): Result<string> => {
    const EMAIL_REGEXP = /^([\w\-+]+(?:\.[\w\-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    const TELEBETTING_ID_REGEXP = /^[A-Za-z0-9]{2,}$/;

    if (!TELEBETTING_ID_REGEXP.test(value) && !EMAIL_REGEXP.test(value)) {
        return  Result.createError(getErrorByCode('ERROR_INVALID_EMAIL'));
    }

    return Result.createOk(value);
};
