export const convertRecordToMap = <V1, V2>(data: Record<string, V1>, mapItem: (v1: V1) => V2): Map<string, V2> => {
    const out: Map<string, V2> = new Map();

    for (const [key, item] of Object.entries(data)) {
        out.set(key, mapItem(item));
    }

    return out;
};

export const convertRecordToMapDefault = <V1>(data: Record<string, V1>): Map<string, V1> => convertRecordToMap(data, (item) => item);

export const convertMapToRecord = <V1, V2>(data: Map<string, V1>, mapItem: (v1: V1) => V2): Record<string, V2> => {
    const out: Record<string, V2> = {};

    for (const [key, item] of data) {
        out[key] =mapItem(item);
    }

    return out;
};

export const convertMapToRecordDefault = <V1>(data: Map<string, V1>): Record<string, V1> => convertMapToRecord(data, (item) => item);

export const isDecimalCheck = (stake: string):boolean => {
    const regex = /[.,]/;

    return regex.exec(stake) !== null;
};

export const convertToNumber = (toConvert:string | number | undefined | null):number | null => {
    if (typeof toConvert === 'number') {
        return toConvert;
    }

    if (toConvert === '') {
        return 0;
    }

    if (toConvert === null || toConvert === undefined) {
        return null;
    }

    const parsedNumber = parseFloat(toConvert);

    if (isNaN(parsedNumber)) {
        return null;
    }

    return parsedNumber;
};
