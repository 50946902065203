import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface CheckSingleIconPropsType {
    className?: string;
    onClick?: () => void;
    dataTest?: string;
}

export const CheckSingleIcon = observer('CheckSingleIcon', ({ className, onClick, dataTest }: CheckSingleIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
            data-test={dataTest ?? 'check-single-icon'}
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M8.533 20.42c-.278.275-.672.504-1.02.504-.347 0-.74-.24-1.03-.515L0 14.008l2.06-2.035 5.465 5.396L21.974 3 24 5.069 8.533 20.421z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
