import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { HeaderWrapper } from './Header.style';
import { UniverseComponent } from 'src/domains/common/universes';

export const Header = observer('Header', () => {
    return (
        <HeaderWrapper>
            <UniverseComponent>
                {(universeModule): React.ReactElement => <universeModule.signUpLogo />}
            </UniverseComponent>

            <I18n
                langKey='signup.step-one.lets-started'
                defaultText="Let's get you started"
            />
        </HeaderWrapper>
    );
});
