import { ExternalApi } from 'src/domains/layouts/state/externalApi/ExternalApi';
import { Session } from 'src_common/sdk/session';
import {
    openapiProxyAccountBasicDataRequest,
    OpenapiProxyAccountBasicDataResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_account_basic_data';
import {
    openapiProxyCustomerWalletRequest,
    OpenapiProxyCustomerWalletResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_wallet';
import {
    openapiProxyCustomerFreeBetsRequest,
    OpenapiProxyCustomerFreeBetsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_free_bets';
import {
    openapiProxyCustomerTransactionHistoryRequest,
    OpenapiProxyCustomerTransactionHistoryResponse200Type,
    OpenapiProxyCustomerTransactionHistoryParamsType,
} from 'src/api_openapi/generated/openapi_proxy_customer_transaction_history';
import {
    OpenapiProxyCustomerAllBetsParamsType,
    openapiProxyCustomerAllBetsRequest,
    OpenapiProxyCustomerAllBetsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_all_bets';
import {
    openapiProxyAnonymousSportsRequest,
    OpenapiProxyAnonymousSportsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_sports';
import {
    openapiProxyCustomerDepositLimitsRequest,
    OpenapiProxyCustomerDepositLimitsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_deposit_limits';
import {
    openapiProxyCustomerRealityCheckRequest,
    OpenapiProxyCustomerRealityCheckResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_reality_check';

import {
    openapiProxyCustomerSetRealityCheckRequest,
    OpenapiProxyCustomerSetRealityCheckResponse200Type,
    OpenapiProxyCustomerSetRealityCheckParamsType,
} from 'src/api_openapi/generated/openapi_proxy_customer_set_reality_check';

import {
    openapiProxyCustomerBonusesRequest,
    OpenapiProxyCustomerBonusesResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_bonuses';
import {
    openapiProxyCustomerNetDepositRequest,
    OpenapiProxyCustomerNetDepositParamsType,
    OpenapiProxyCustomerNetDepositResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_net_deposit';
import { Resource, Result } from 'src_common/common/mobx-utils/Resource';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import {
    openapiProxyAnonymousFindPageRequest,
    OpenapiProxyAnonymousFindPageResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_find_page';
import {
    OpenapiProxyCustomerDepositTransactionCallbackRealexParamsType,
    openapiProxyCustomerDepositTransactionCallbackRealexRequest,
    OpenapiProxyCustomerDepositTransactionCallbackRealexResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_deposit_transaction_callback_realex';

import {
    OpenapiProxyCustomerWithdrawalsParamsType,
    openapiProxyCustomerWithdrawalsRequest,
    OpenapiProxyCustomerWithdrawalsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_withdrawals';

import {
    OpenapiProxyCustomerBankingConfigsParamsType,
    openapiProxyCustomerBankingConfigsRequest,
    OpenapiProxyCustomerBankingConfigsResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_banking_configs';
import {
    openapiProxyCustomerBankingCoinspaidAddressRequest,
    OpenapiProxyCustomerBankingCoinspaidAddressResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_banking_coinspaid_address';
import {
    OpenapiProxyCustomerBankingCoinspaidWithdrawalParamsType,
    openapiProxyCustomerBankingCoinspaidWithdrawalRequest,
    OpenapiProxyCustomerBankingCoinspaidWithdrawalResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_banking_coinspaid_withdrawal';

import {
    OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateParamsType,
    openapiProxyCustomerBankingSwiftyglobalDepositInitiateRequest,
    OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_banking_swiftyglobal_deposit_initiate';

import {
    OpenapiProxyCustomerBankingSwiftyglobalWithdrawalInitiateParamsType,
    openapiProxyCustomerBankingSwiftyglobalWithdrawalInitiateRequest,
    OpenapiProxyCustomerBankingSwiftyglobalWithdrawalInitiateResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_banking_swiftyglobal_withdrawal_initiate';
import {
    OpenapiProxyAnonymousRequestPasswordResetParamsType,
    openapiProxyAnonymousRequestPasswordResetRequest,
    OpenapiProxyAnonymousRequestPasswordResetResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_request_password_reset';
import {
    OpenapiProxyAnonymousPasswordResetParamsType,
    openapiProxyAnonymousPasswordResetRequest,
    OpenapiProxyAnonymousPasswordResetResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_anonymous_password_reset';
import {
    openapiProxyCustomerDepositRequest,
    OpenapiProxyCustomerDepositResponse200Type,
    OpenapiProxyCustomerDepositParamsType,
} from 'src/api_openapi/generated/openapi_proxy_customer_deposit';
import {
    openapiProxyCustomerSrgQuestionsOnlyRequest,
    OpenapiProxyCustomerSrgQuestionsOnlyResponse200Type,
    OpenapiProxyCustomerSrgQuestionsOnlyParamsType,
} from 'src/api_openapi/generated/openapi_proxy_customer_srg_questions_only';
import {
    openapiProxyCustomerSrgValidateRequest,
    OpenapiProxyCustomerSrgValidateResponse200Type,
    OpenapiProxyCustomerSrgValidateParamsType,
} from 'src/api_openapi/generated/openapi_proxy_customer_srg_validate';

import { action, computed, makeObservable, observable } from 'mobx';
import {
    CurrencySymbolType,
    CurrencyType,
    moneySymbol,
    formatAmountWithCurrency,
    isCurrencyCode,
} from 'src_common/common/amount/website-money/currency';
import { Amount } from 'src_common/common/amount/Amount';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { ConfigType } from 'src/domains/layouts/config/features/types';

export type UserBasicDataType = ResourceCustomer<Omit<AccountBasicDataType, 'oddsFormat'>>;
export type AccountBasicDataType = OpenapiProxyAccountBasicDataResponse200Type;
export type WalletDataType = OpenapiProxyCustomerWalletResponse200Type;
export type CustomerFreeBetsType = OpenapiProxyCustomerFreeBetsResponse200Type;
export type CustomerTransactionHistoryType = OpenapiProxyCustomerTransactionHistoryResponse200Type;
export type CustomerNetDepositType = OpenapiProxyCustomerNetDepositResponse200Type;
export type TransactionCallbackRealexType = OpenapiProxyCustomerDepositTransactionCallbackRealexResponse200Type;
export type PageCMSType = OpenapiProxyAnonymousFindPageResponse200Type['result'];
export type BankingCoinspaidAddressType = OpenapiProxyCustomerBankingCoinspaidAddressResponse200Type;
export type BankingCoinspaidWithdrawalType = OpenapiProxyCustomerBankingCoinspaidWithdrawalResponse200Type;
export type BankingSwiftyglobalDepositInitiateType =
    OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateResponse200Type;
export type SrgQuestionsOnlyType = OpenapiProxyCustomerSrgQuestionsOnlyResponse200Type;
export type SrgValidationType = OpenapiProxyCustomerSrgValidateResponse200Type;
export class ResourceCustomer<T> {
    private readonly resource: Resource<T>;

    public constructor(
        private readonly label: string,
        private readonly session: Session,
        private readonly fetchData: () => Promise<T>
    ) {
        this.resource = new Resource(fetchData);
    }

    public fetch(): Promise<T> {
        if (this.session.isAuthorized) {
            return this.fetchData();
        }

        console.error(
            `ResourceSession: ${this.label} => fetch => anable to retrieve data from this resource because user is not logged in`
        );
        return new Promise(() => {});
    }

    public get(): Result<T> {
        if (this.session.isAuthorized) {
            return this.resource.get();
        } else {
            return {
                type: 'loading',
                whenReady: new Promise(() => {}),
            };
        }
    }

    public get valueReady(): T | null {
        if (this.session.isAuthorized) {
            const data = this.get();

            if (data.type === 'ready') {
                return data.value;
            }
        }

        return null;
    }

    public async refreshAndWait(): Promise<void> {
        return this.resource.refresh();
    }

    public refresh(): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.resource.refresh();
    }

    public clear(): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.resource.refresh();
    }
}

interface SdkCustomerConfigType {
    socketJoinSport: boolean;
    oddsFormatShortDefault?: 'f' | 'd';
    currencyDefault: CurrencyType;
    canDownloadTerms: boolean;
}

export type WalletDataTypeAmountType = {
    balance: string;
    createdAt: string;
    creditLimit: string;
    currency: string;
    fundsLocked: string;
    id: string;
    lastBetAt: string;
    operatorAccumulatedProfit: string;
    operatorBalance: string;
    operatorExternalProfit: string;
    operatorProfit: string;
    playableBalance: string;
    /**
     * @deprecated - Please use this method "playableBalance" attribute
     */
    playableBalanceOld: number;
    profit: string;
    universe: string;
    updatedAt: string;
    withdrawableBalance: string;
    backofficeWithdrawableBalance: string;
};

enum ComplianceRestrictionSource {
    POF = 'pof',
    SRG = 'srg',
}

export class UsersState {
    public readonly config: SdkCustomerConfigType;
    public readonly session: Session;
    private readonly basicDataInner: ResourceCustomer<AccountBasicDataType>;
    public readonly walletData: ResourceCustomer<WalletDataTypeAmountType>; //WalletDataType>;
    public readonly freeBetsData: ResourceCustomer<CustomerFreeBetsType | null>;
    public readonly depositLimitsData: ResourceCustomer<OpenapiProxyCustomerDepositLimitsResponse200Type>;
    public readonly realityCheckData: ResourceCustomer<OpenapiProxyCustomerRealityCheckResponse200Type>;
    public readonly accountBonusesData: ResourceCustomer<OpenapiProxyCustomerBonusesResponse200Type>;
    public readonly bankingCoinspaidAddressData: ResourceCustomer<BankingCoinspaidAddressType>;
    private readonly newStaticPage: MobxMapAutoNew<string, Resource<PageCMSType | null>>;

    @observable public isSrgModalOpen: boolean;
    @observable private isPofRequestedPopupOpen: boolean;
    @observable private isPofRequestedWithoutCrPopupOpen: boolean;
    @observable private isPofFailedPopupOpen: boolean;

    public constructor(
        session: Session,
        configTypes: ConfigType,
        public readonly externalApi: ExternalApi,
        public localStorageState: LocalStorageState,
        config: SdkCustomerConfigType
    ) {
        makeObservable(this);
        this.config = config;
        this.session = session;
        this.isSrgModalOpen = configTypes.srgQuestionnaire;

        //Check if Pof modal already showed, if no show modal
        const pofPopupsLocalStorage = this.localStorageState.pofPopups.getValue();
        this.isPofRequestedPopupOpen =
            pofPopupsLocalStorage.isPofRequestedModalAlreadyShowed === false && configTypes.pof === true;
        this.isPofRequestedWithoutCrPopupOpen =
            pofPopupsLocalStorage.isPofisPofRequestedWithoutCRModalAlreadyShowed === false && configTypes.pof === true;
        this.isPofFailedPopupOpen =
            pofPopupsLocalStorage.isPofFailedModalAlreadyShowed === false && configTypes.pof === true;

        this.basicDataInner = new ResourceCustomer('basicData', session, async () => {
            return await this.session.call(openapiProxyAccountBasicDataRequest, {});
        });

        this.walletData = new ResourceCustomer('walletData', session, async () => {
            const response = await this.session.call(openapiProxyCustomerWalletRequest, {});

            return {
                id: response.id,
                createdAt: response.createdAt,
                currency: response.currency,
                lastBetAt: response.lastBetAt,
                universe: response.universe,
                updatedAt: response.updatedAt,
                balance: response.balancePrecise,
                creditLimit: response.creditLimitPrecise,
                fundsLocked: response.fundsLockedPrecise,
                operatorAccumulatedProfit: response.operatorAccumulatedProfitPrecise,
                operatorBalance: response.operatorBalancePrecise,
                operatorExternalProfit: response.operatorExternalProfitPrecise,
                operatorProfit: response.operatorProfitPrecise,
                profit: response.profitPrecise,
                withdrawableBalance: response.withdrawableBalancePrecise,
                playableBalance: response.playableBalancePrecise,
                playableBalanceOld: parseInt(response.balancePrecise.replace('.', ''), 10),
                backofficeWithdrawableBalance: response.backofficeWithdrawableBalancePrecise,
            };
        });

        this.freeBetsData = new ResourceCustomer('freeBetsData', session, async () => {
            return await this.session.call(openapiProxyCustomerFreeBetsRequest, {});
        });

        this.depositLimitsData = new ResourceCustomer('depositLimitsData', session, async () => {
            return await this.session.call(openapiProxyCustomerDepositLimitsRequest, {});
        });

        this.realityCheckData = new ResourceCustomer('realityCheckData', session, async () => {
            return this.session.call(openapiProxyCustomerRealityCheckRequest, {});
        });

        this.accountBonusesData = new ResourceCustomer(
            'accountBonusesData',
            session,
            async (): Promise<OpenapiProxyCustomerBonusesResponse200Type> => {
                return this.session.call(openapiProxyCustomerBonusesRequest, {});
            }
        );

        this.bankingCoinspaidAddressData = new ResourceCustomer(
            'bankingCoinspaidAddressData',
            session,
            async (): Promise<BankingCoinspaidAddressType> => {
                return this.session.call(openapiProxyCustomerBankingCoinspaidAddressRequest, {
                    requestBody: {
                        currency: 'BTC',
                    },
                });
            }
        );

        this.newStaticPage = new MobxMapAutoNew(
            (pageId: string) =>
                new Resource(async (): Promise<PageCMSType | null> => {
                    const data = await this.session.call(openapiProxyAnonymousFindPageRequest, {
                        requestBody: {
                            slug: pageId,
                            // Leave en-gb language for now
                            page_language: 'en-gb',
                        },
                    });

                    return data.result ?? null;
                })
        );
    }

    @action public toggleSrgModal = (): void => {
        this.isSrgModalOpen = !this.isSrgModalOpen;
    };

    @computed public get isSrgRequested(): boolean {
        return this.basicData.valueReady?.srgStatus === 'requested';
    }

    @action public togglePofFailedPopup = (): void => {
        this.isPofFailedPopupOpen = !this.isPofFailedPopupOpen;
        this.localStorageState.pofPopups.setValue({
            ...this.localStorageState.pofPopups.getValue(),
            isPofFailedModalAlreadyShowed: true,
        });
    };

    @action public togglePofRequestedPopup = (): void => {
        this.isPofRequestedPopupOpen = !this.isPofRequestedPopupOpen;
        this.localStorageState.pofPopups.setValue({
            ...this.localStorageState.pofPopups.getValue(),
            isPofRequestedModalAlreadyShowed: true,
        });
    };

    @action public togglePofRequestedWithoutCRPopup = (): void => {
        this.isPofRequestedWithoutCrPopupOpen = !this.isPofRequestedWithoutCrPopupOpen;
        this.localStorageState.pofPopups.setValue({
            ...this.localStorageState.pofPopups.getValue(),
            isPofisPofRequestedWithoutCRModalAlreadyShowed: true,
        });
    };

    @computed public get isPofFailedModal(): boolean {
        const cr = this.basicData.valueReady?.complianceRestriction?.source;

        const isFailedPofStatus = this.basicData.valueReady?.pofStatus === 'failed';
        const isCompRestiction = cr === ComplianceRestrictionSource.POF;

        return isFailedPofStatus && isCompRestiction && this.isPofFailedPopupOpen;
    }

    @computed public get isPofRequestedModal(): boolean {
        const cr = this.basicData.valueReady?.complianceRestriction?.source;

        const isRequestedPofStatus = this.basicData.valueReady?.pofStatus === 'requested';
        const isCompRestiction = cr === ComplianceRestrictionSource.POF;

        return isRequestedPofStatus && isCompRestiction && this.isPofRequestedPopupOpen;
    }

    @computed public get isPofRequestedWithoutCRModal(): boolean {
        const cr = this.basicData.valueReady?.complianceRestriction?.source;

        const isRequestedPofStatus = this.basicData.valueReady?.pofStatus === 'requested';
        const isNoCompRestiction = cr !== ComplianceRestrictionSource.POF;

        return isRequestedPofStatus && isNoCompRestiction && this.isPofRequestedWithoutCrPopupOpen;
    }

    public get basicData(): UserBasicDataType {
        return this.basicDataInner;
    }
    @computed public get basicDataComp(): AccountBasicDataType | null {
        const data = this.basicDataInner.get();
        if (data.type === 'ready') {
            return data.value;
        }
        return null;
    }

    public getPage(slug: string): Result<PageCMSType | null> {
        const newPage = this.newStaticPage.get(slug).get();

        if (newPage.type === 'ready') {
            return {
                type: 'ready',
                value: newPage.value,
            };
        }

        if (newPage.type === 'loading') {
            return newPage;
        }
        return {
            type: 'ready',
            value: null,
        };
    }

    @computed public get oddsFormatShort(): 'f' | 'd' {
        const oddsFormat = this.basicDataInner.valueReady?.oddsFormat ?? null;
        if (oddsFormat !== null) {
            const format = oddsFormat.charAt(0);
            if (format === 'f') {
                return 'f';
            }
            if (format === 'd') {
                return 'd';
            }

            console.error(`Unknown offsets format ${format}`);
        }

        if (this.config.oddsFormatShortDefault !== undefined) {
            return this.config.oddsFormatShortDefault;
        }

        return 'f';
    }

    @computed public get currency(): CurrencyType {
        const { currencyDefault } = this.config;

        if (this.session.isAuthorized === false) {
            return currencyDefault;
        }

        const currency = this.basicData.valueReady?.currency ?? null;

        if (currency !== null) {
            if (isCurrencyCode(currency)) {
                return currency;
            }

            console.error('Unknown currency', currency);
            return currencyDefault;
        }

        return currencyDefault;
    }

    public money = (amount: Amount | null | undefined, withoutZeros?: boolean): string => {
        return formatAmountWithCurrency(this.currency, amount, withoutZeros);
    };

    @computed public get moneySymbol(): CurrencySymbolType {
        return moneySymbol(this.currency);
    }

    public postAllBets(
        params: OpenapiProxyCustomerAllBetsParamsType
    ): Promise<OpenapiProxyCustomerAllBetsResponse200Type> {
        return this.session.call(openapiProxyCustomerAllBetsRequest, params);
    }

    public postTransactionHistory(
        params: OpenapiProxyCustomerTransactionHistoryParamsType
    ): Promise<CustomerTransactionHistoryType> {
        return this.session.call(openapiProxyCustomerTransactionHistoryRequest, params);
    }

    public postNetDeposit(params: OpenapiProxyCustomerNetDepositParamsType): Promise<CustomerNetDepositType> {
        return this.session.call(openapiProxyCustomerNetDepositRequest, params);
    }

    public getSportsList(): Promise<OpenapiProxyAnonymousSportsResponse200Type> {
        return this.session.call(openapiProxyAnonymousSportsRequest, {});
    }

    public withdraw(
        params: OpenapiProxyCustomerWithdrawalsParamsType
    ): Promise<OpenapiProxyCustomerWithdrawalsResponse200Type> {
        return this.session.call(openapiProxyCustomerWithdrawalsRequest, params);
    }

    public bankingConfigs(
        params: OpenapiProxyCustomerBankingConfigsParamsType
    ): Promise<OpenapiProxyCustomerBankingConfigsResponse200Type> {
        return this.session.call(openapiProxyCustomerBankingConfigsRequest, params);
    }

    public transactionCallbackRealex(
        params: OpenapiProxyCustomerDepositTransactionCallbackRealexParamsType
    ): Promise<TransactionCallbackRealexType> {
        return this.session.call(openapiProxyCustomerDepositTransactionCallbackRealexRequest, params);
    }

    public coinspaidWithdraw(
        params: OpenapiProxyCustomerBankingCoinspaidWithdrawalParamsType
    ): Promise<BankingCoinspaidWithdrawalType> {
        return this.session.call(openapiProxyCustomerBankingCoinspaidWithdrawalRequest, params);
    }

    public swiftyGlobalDepositInitiate(
        params: OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateParamsType
    ): Promise<BankingSwiftyglobalDepositInitiateType> {
        return this.session.call(openapiProxyCustomerBankingSwiftyglobalDepositInitiateRequest, params);
    }

    public swiftyGlobaWithdrawalInitiate(
        params: OpenapiProxyCustomerBankingSwiftyglobalWithdrawalInitiateParamsType
    ): Promise<OpenapiProxyCustomerBankingSwiftyglobalWithdrawalInitiateResponse200Type> {
        return this.session.call(openapiProxyCustomerBankingSwiftyglobalWithdrawalInitiateRequest, params);
    }

    public requestResetPassword(
        params: OpenapiProxyAnonymousRequestPasswordResetParamsType
    ): Promise<OpenapiProxyAnonymousRequestPasswordResetResponse200Type> {
        return this.session.call(openapiProxyAnonymousRequestPasswordResetRequest, params);
    }

    public resetPassword(
        params: OpenapiProxyAnonymousPasswordResetParamsType
    ): Promise<OpenapiProxyAnonymousPasswordResetResponse200Type> {
        return this.session.call(openapiProxyAnonymousPasswordResetRequest, params);
    }

    public depositInitiate(
        params: OpenapiProxyCustomerDepositParamsType
    ): Promise<OpenapiProxyCustomerDepositResponse200Type> {
        return this.session.call(openapiProxyCustomerDepositRequest, params, {
            'X-Advertisement-Id': this.externalApi.adjustAdID ?? '',
        });
    }

    public srgQuestionsOnly(params: OpenapiProxyCustomerSrgQuestionsOnlyParamsType): Promise<SrgQuestionsOnlyType> {
        return this.session.call(openapiProxyCustomerSrgQuestionsOnlyRequest, params);
    }

    public srgValidate(params: OpenapiProxyCustomerSrgValidateParamsType): Promise<SrgValidationType> {
        return this.session.call(openapiProxyCustomerSrgValidateRequest, params);
    }

    public async setRealityCheck(
        params: OpenapiProxyCustomerSetRealityCheckParamsType
    ): Promise<OpenapiProxyCustomerSetRealityCheckResponse200Type> {
        const response = await this.session.call(openapiProxyCustomerSetRealityCheckRequest, params);
        this.realityCheckData.refresh();
        return response;
    }
}
