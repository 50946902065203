import { action, computed, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { MarketingQuestionType,QuestionAnswers } from 'src/domains/players/webview/components/Account';
import { UsersState } from 'src/domains/players/state/UsersState';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { SelectOptionsType } from 'src/domains/layouts/webview/components/form/select/Select';
import { SelectState } from 'src/domains/layouts/webview/components/form/select/Select.state';
import { TrpcClient } from 'src/appState/TrpcClient';

export class QuestionModel {
    public readonly selectState: SelectState;

    public constructor(
        private readonly questionsResource: Resource<{ questions:MarketingQuestionType[] }>,
        private readonly usersState: UsersState,
        public id: string,
    ) {
        this.selectState = new SelectState(() => this.userAnswer);
    }

    @computed private get questionsAnswers(): QuestionAnswers | null {
        const basicData = this.usersState.basicData.valueReady;
        if (basicData !== null) {
            return basicData.marketingQuestionsAnswers ?? {};
        }
        return null;
    }

    @computed public get userAnswer(): string | undefined {
        const answers = this.questionsAnswers;

        if (answers === null) {
            return undefined;
        }

        return answers[this.id];
    }

    @computed public get hasAnswer(): boolean {
        return this.userAnswer !== undefined;
    }

    @computed private get model(): MarketingQuestionType | null {
        const models = this.questionsResource.getReady();

        if (models === null) {
            return null;
        }

        for (const question of models.questions) {
            if (question.id === this.id) {
                return question;
            }
        }

        return null;
    }

    @computed public get name(): string | null {
        return this.model?.name ?? null;
    }

    private get possibleAnswers(): Array<string> {
        return this.model?.possibleAnswers ?? [];
    }

    public get selectOptions(): Array<SelectOptionsType> {
        const currentAnswer = this.userAnswer;
        if (currentAnswer !== undefined) {
            return [{ 
                label: currentAnswer,
                value: currentAnswer
            }];
        }

        const result: Array<SelectOptionsType> = [];
        for (const answer of this.possibleAnswers) {
            result.push({ 
                label: answer,
                value: answer
            });
        };
        return result;
    }

    public get selectAnswerValue(): string | null {
        return this.selectState.currentValue ?? null;
    }
}


export class MarketingQuestionsState {

    private models: AutoMap<string, QuestionModel>;
    private readonly usersState: UsersState;
    private readonly questionsResource: Resource<{questions:MarketingQuestionType[]}>;

    public constructor(
        private readonly trpClient: TrpcClient,
        usersState: UsersState,
    ) {
        makeObservable(this);
        this.usersState = usersState;

        this.questionsResource = new Resource( async () => {
            const response = await this.trpClient.client.accounts.accountGetMarketingQuestions.query();
            if (response.type === 'ok') {
                return {
                    questions: response.list
                };
            }

            console.error(response);
            throw Error('Fetching marketing questions failed');
        });

        this.models = new AutoMap((id: string) => {
            return new QuestionModel(
                this.questionsResource,
                usersState,
                id,
            );
        });
    }

    @computed private get activeQuestions(): MarketingQuestionType[] {
        const questions = this.questionsResource.get();
        if (questions.type === 'ready') {
            return questions.value.questions.filter((question) => question.active);
        }
        return [];
    }

    @computed public get allActiveQuestions(): Array<QuestionModel> {
        if (this.usersState.session.isAuthorized) {
            return this.activeQuestions.map((question) => this.models.get(question.id));
        }
        return [];
    }

    @computed public get unansweredQuestions(): Array<QuestionModel> {
        return this.allActiveQuestions.filter((question) => !question.hasAnswer);
    }

    @computed public get showMarketingQuestions(): boolean {
        return this.allActiveQuestions.length > 0;
    }

    private get answers(): Record<string, string> | null {
        const basicData = this.usersState.basicData.valueReady;
        if (basicData !== null) {
            return basicData.marketingQuestionsAnswers ?? {};
        }
        return null;
    };

    private getSelectsValues = (): Record<string, string> => {
        const out: Record<string, string> = {};
        for (const question of this.allActiveQuestions) {
            const answer = question.selectAnswerValue;
            if (answer !== null) {
                out[question.id] = answer;
            }
        }
        return out;
    };

    @computed public get isSaveButtonEnabled(): boolean {
        for (const question of this.allActiveQuestions) {
            if (question.hasAnswer === false && question.selectAnswerValue !== null) {
                return true;
            }
        }
        return false;
    }

    @computed public get isSaveButtonDisplayed(): boolean {
        for (const question of this.allActiveQuestions) {
            if (question.hasAnswer === false) {
                return true;
            }
        }
        return false;
    }

    @action public updateQuestions = async (): Promise<void> => {
        const formAnswers = this.getSelectsValues();

        if (Object.keys(formAnswers).length > 0) {
            const finalAnswers: Record<string, string> = { ...formAnswers, ...this.answers };
            const status = await this.trpClient.client.accounts.accountUpdateMarketingQuestion.mutate({ finalAnswers });

            if (status.type === 'ok') {
                for (const question of this.allActiveQuestions) {
                    question.selectState.reset();
                }
            } else {
                console.error('updateQuestions -> ', status);
            }
        }
    };


}
