import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { AccountNavigationLink } from 'src/domains/layouts/webview/components/accountNavigationLink/AccountNavigationLink';
import { withConfig } from 'src/withConfig';

export const LinkWrapper = withConfig(theme => styled(AccountNavigationLink, { label: 'LinkWrapper' })`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${theme.star.accountLinks.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    padding: 14px 16px;
    margin-bottom: 1px;
    text-decoration: none;
    position: relative;
    &::after {
        background-color: ${theme.star.accountLinks.borderColor};
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
    }
    &:last-child::after {
        content: none;
    }
`);

export const Arrow = styled(ChevronIcon, { label: 'Arrow' })`
    fill: currentcolor;
    width: 12px;
`;
