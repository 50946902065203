import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { getPrice } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/betUtils';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetSPPriceInfo } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetSPPriceInfo';
import { useAppStateContext } from 'src/appState/AppState';
import { BetHistoryLegType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

const displayOdds = (sportId: string | undefined, odds: string | number | null): JSX.Element | null => {
    if (sportId === 'golf' || sportId === 'tennis') {
        return null;
    } else {
        if (odds === null) {
            return null;
        }
        return (
            <div>
                <I18n
                    langKey='bets.list.bet.odds'
                    defaultText='Odds - {odds}'
                    params={{ odds }}
                />
            </div>
        );
    }
};

export const BetCombinedLegResult = observer<{ leg: BetHistoryLegType }>(
    'BetCombinedLegResult',
    ({ leg }): JSX.Element => {
        const appState = useAppStateContext();
        const oddsFormat = appState.appPlayersState.usersState.oddsFormatShort;
        const odds = getPrice(leg, oddsFormat) ?? null;
        const status = leg.result?.type ?? null;
        const oddsInfo = displayOdds(leg.sport?.id, odds);

        return (
            <>
                {oddsInfo}
                <BetSPPriceInfo leg={leg} />
                {status === null ? null : (
                    <div>
                        <I18n
                            langKey='bets.list.bet.leg.status'
                            defaultText='Status - {status}'
                            params={{ status }}
                        />
                    </div>
                )}
            </>
        );
    }
);
