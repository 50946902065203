import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { assertNever } from 'src_common/common/assertNever';
import { InfoPopup } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupPopups/InfoPopup';
import { AgreeOrCancelPopup } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupPopups/AgreeOrCancelPopup';
import { useAppStateContext } from 'src/appState/AppState';


export const SignupPopupContainer = observer('SignupPopupContainer', (): JSX.Element | null => {
    const { appPlayersState: { signupPopupState } } = useAppStateContext();

    if (signupPopupState.popups === null) {
        return null;
    };

    const popup = signupPopupState.popups;
    if (popup.type === 'infoPopup') {
        return <InfoPopup popup={popup} />;
    }
    if (popup.type === 'agreeOrCancel') {
        return <AgreeOrCancelPopup popup={popup} />;
    }
    return assertNever('', popup);
});
