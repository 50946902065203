import styled from '@emotion/styled';

export const RaceBoxWrapper = styled('div', { label: 'RaceBoxWrapper' })`
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const HorseInfoWrapper = styled('div', { label: 'HorseInfoWrapper' })`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
