import styled from '@emotion/styled';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';

interface DepositIssueWrapperType {
    isSignup: boolean;
}

export const DepositIssueWrapper = styled('div', { label: 'DepositIssueWrapper' })<DepositIssueWrapperType>`
    animation: fadein 0.3s;
    margin: 0 16px 40px;
    padding-top: ${({ isSignup }): string => isSignup === true ? '0' : '80px'};
    text-align: center;
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;

export const WarningIconBox = withConfig(theme => styled('div', { label: 'WarningIconBox' })`
    background: ${theme.star.topUp.bgColor};
    border-radius: 50%;
    display: inline-flex;
    margin-bottom: 32px;
    padding: 24px;
`);

export const InfoIconBox = withConfig(theme => styled('div', { label: 'InfoIconBox' })`
    background: ${theme.star.topUp.bgColor};
    border-radius: 50%;
    display: inline-flex;
    margin-bottom: 20px;
    padding: 24px;
`);

export const WarningIconWrapper = styled(WarningIcon, { label: 'WarningIconWrapper' })`
    width: 32px;
`;

export const InfoIconWrapper = styled(InfoIcon, { label: 'InfoIconWrapper' })`
    width: 32px;
`;

export const DepositTitle = withConfig(theme => styled('h2', { label: 'DepositTitle' })`
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    margin: 0;
`);

export const IssueDepositTitle = withConfig(theme => styled('h2', { label: 'IssueDepositTitle' })`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    max-width: 220px;
    margin: 0 auto;
`);

export const IssueDepositInfo = withConfig(theme => styled('p', { label: 'IssueDepositInfo' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    max-width: 290px;
    line-height: 1.43;
    margin: 8px auto 0;
`);

export const IssueDepositInfoSecondary = withConfig(theme => styled('p', { label: 'IssueDepositInfoSecondary' })`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.43;
    margin: 20px auto 0;
`);

export const MailWrapper = withConfig(theme => styled('span', { label: 'MailWrapper' })`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const ButtonContainer = styled('div', { label: 'ButtonContainer' })`
    margin: 0 16px;
`;

export const ButtonWrapper = styled(Button, { label: 'ButtonWrapper' })`
    margin-top: 8px;
    width: 100%;
`;
