import { getCookie, setCookie } from './domains/layouts/config/config';

//This code is temporary. It is to be deleted after the new trading is deployed

export const initParamTrading = (): void => {

    const cookie = getCookie('newtrading');
    const shouldBeOn = window.location.search.toLowerCase().includes('newtrading=true');
    const shouldBeOff = window.location.search.toLowerCase().includes('newtrading=false');

    console.info('INIT PARAM ...', {
        cookie,
        shouldBeOn,
        shouldBeOff,
    });

    if (shouldBeOn) {
        if (cookie === 'true') {
            //ok
            console.info('INIT PARAM TRADING - should true, ok');
        } else {
            console.info('INIT PARAM TRADING - should true, set true');
            setCookie('newtrading', 'true', 365);
            
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        return;
    }

    if (shouldBeOff) {
        if (cookie === 'true') {
            console.info('INIT PARAM TRADING - should false, set false');
            setCookie('newtrading', 'false', 365);

            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            //ok
            console.info('INIT PARAM TRADING - should false, ok');
        }
    }
};
