import { AppState } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventsListState } from 'src/domains/sportsbook/state/eventsListState/EventsListState';

export const getMarketForDisplay = (appState: AppState, event: EventModel, eventsListState: EventsListState): MarketModel | 'loading' | null => {
    const simpleMarkets = event.eventMarkets;

    if (simpleMarkets === null) {
        return 'loading';
    }

    const marketNewShort = simpleMarkets.find(item =>
        item.templateId === eventsListState.filter_marketTemplateId &&
        item.keyLine === eventsListState.filter_marketLine
    );

    if (marketNewShort === undefined) {
        return null;
    }

    const marketNew = appState.appSportsBookState.models.getMarket(marketNewShort.id);

    if (marketNew === null) {
        return 'loading';
    }

    return marketNew;
};
