import * as t from 'io-ts';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

const decodeIO = t.union([
    t.string,
    t.null
]);

const decode = buildValidator('localStorageLanguage.decodeIO', decodeIO, true);

export const localStorageLanguage = (withAutorun: boolean, key: string): LocalStorageItemState<string | null> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: null
    });
};
