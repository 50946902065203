import React from 'react';
import { TermsConditionsWrapper, TermsContent, Header } from './PromotionTermsAndConditions.style';
import { CloseButton } from 'src/domains/players/webview/components/Account/promoSidebar/PromoSidebar.style';
import { PromoSidebarList } from 'src/domains/players/webview/components/Account/promoSidebar/PromoSidebarPartials/PromoSidebarPartials.style';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

export const PromotionTermsAndConditions = observer('PromotionTermsAndConditions', () => {
    const appState = useAppStateContext();

    const promotionSlug = appState.appLayoutsState.starRouter.freeParams.promo ?? 'homepage';
    const promoLandingPage = appState.appPlayersState.specialEvents.getLandingPageState(promotionSlug).promoLandingPage;
    const value = promoLandingPage.type === 'ready' ? promoLandingPage.value : null;
    return promoLandingPage.type === 'ready' ? (
        <TermsConditionsWrapper>
            <TermsContent>
                {value?.tc_text === undefined ? null : (
                    <PromoSidebarList dangerouslySetInnerHTML={{ __html: value.tc_text }} />
                )}
            </TermsContent>
        </TermsConditionsWrapper>
    ) : null;
});

export const PromotionTermsAndConditionsHeader = observer('PromotionTermsAndConditionsHeader', () => {
    const { appPlayersState } = useAppStateContext();
    const { specialEvents } = appPlayersState;

    return (
        <Header>
            Terms & Condition
            <CloseButton onClick={specialEvents.closePromoTermsConditions} />
        </Header>
    );
});
