import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

import { AccountNavigationWrapper, } from './AccountNavigation.style';
import { SignUpLinkNav } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/signUpLinkNav/SignUpLinkNav';
import { LogInLinkNav } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/loginLinkNav/LoginLinkNav';
import { FaqLinkNav } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/faqLinkNav/FaqLinkNav';
import { RewardLinkNav } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/rewardLinkNav/RewardLinkNav';
import { BalanceLinkNav } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/balanceLinkNav/BalanceLinkNav';
import { AccountLinkNav } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/accountLinkNav/AccountLinkNav';
import { TraderChatLinkNav } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/traderChatLinkNav/TraderChatLinkNav';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

interface AccountNavigationPropsType {
    currentView: RouteViewType | null;
}

export const AccountNavigation = observer('AccountNavigation', ({ currentView }: AccountNavigationPropsType) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents, session } = appLayoutsState;
    const { traderChatState } = appPlayersState;
    const { isAuthorized } = session;

    const { faqSection, lifeSpanActive } = configComponents.config;
    const routeName = currentView?.name;

    if (isAuthorized === true) {
        return (
            <AccountNavigationWrapper>
                {lifeSpanActive ? <RewardLinkNav /> : null}
                <BalanceLinkNav />
                {traderChatState.isTraderChatForView && routeName !== 'casino' ? <TraderChatLinkNav /> : null}
                {faqSection.isOnForAuthorized ? <FaqLinkNav /> : null}
                <AccountLinkNav />
            </AccountNavigationWrapper>
        );
    }

    return (
        <AccountNavigationWrapper>
            <LogInLinkNav />
            <SignUpLinkNav />
            { faqSection.isOnForNonAuthorized ? <FaqLinkNav /> : null }
        </AccountNavigationWrapper>
    );
});
