import styled from '@emotion/styled';
import { progress } from 'src/domains/layouts/shared/loaders';
import { withConfig } from 'src/withConfig';

interface RowPlacehoderType {
    width: number;
}

const LoaderLoaderContainer = withConfig(theme => styled('div', { label: 'LoaderLoaderContainer' })`
    position: relative;
    overflow: hidden;
    &::after {
        animation: ${progress} 1.2s linear infinite;
        background-image: ${`linear-gradient(to right, ${theme.star.skeletonLoader.gradient.bgColorNonary} 0%, ${theme.star.skeletonLoader.gradient.bgColorQuinary} 30%, ${theme.star.skeletonLoader.gradient.bgColorSecondary} 50%, ${theme.star.skeletonLoader.gradient.bgColorQuinary} 70%, ${theme.star.skeletonLoader.gradient.bgColorNonary} 100%)`};
        content: '';
        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        width: 100%;
        background-size: 500%;
    }
`);

const RowLoaderContainer = withConfig(theme => styled('div', { label: 'RowLoaderContainer' })`
    position: relative;
    overflow: hidden;
    &::after {
        animation: ${progress} 1.2s linear infinite;
        background-image: ${`linear-gradient(to right, ${theme.star.skeletonLoader.gradient.bgColorDenary} 0%, ${theme.star.skeletonLoader.gradient.bgColorQuinary}  30%, ${theme.star.skeletonLoader.gradient.bgColorTertiary} 50%, ${theme.star.skeletonLoader.gradient.bgColorQuinary} 70%, ${theme.star.skeletonLoader.gradient.bgColorDenary} 100%)`};
        content: '';
        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        width: 100%;
        background-size: 500%;
    }
`);

const PlaceHolder = withConfig(theme => styled(LoaderLoaderContainer, { label: 'PlaceHolder' })`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorTertiary};
    position: relative;
    overflow: hidden;
    height: 12px;
    margin: 16px 16px 8px 0;
`);

export const RowLoader = withConfig(theme => styled(RowLoaderContainer, { label: 'RowLoader' })<RowPlacehoderType>`
    height: 8px;
    margin: 9px 0;
    width: ${({ width }): string => `${width}px`};
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorOctonary};
`);

export const LoaderContentWrapper = withConfig(theme => styled('div', { label: 'LoaderContentWrapper' })`
    padding: 0 16px;
    @media ${theme.star.mediaQuery.largeDesktop} {
        padding: 0 16px;
    }
`);

export const PopularLoader = styled(PlaceHolder, { label: 'PopularLoader' })`
    width: 72px;
`;

export const RecentlyViewedLoader = styled(PlaceHolder, { label: 'RecentlyViewedLoader' })`
    width: 152px;
`;

export const SportsLoader = styled(PlaceHolder, { label: 'SportsLoader' })`
    width: 32px;
`;

export const HelpAndSupportLoader = styled(PlaceHolder, { label: 'HelpAndSupportLoader' })`
    width: 128px;
`;
