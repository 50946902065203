import { computed, action, observable, makeObservable } from 'mobx';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';

interface MessageType {
    readonly title: string,
    readonly message: string, 
}

export class MessageBoxState {
    private readonly router: StarRouter;
    private userSendLogoutAction: boolean = false;

    @observable.ref private message: MessageType | null = null;

    private readonly eventWhenActionNotLogged: EventEmmiter<void>;

    public constructor(router: StarRouter) {
        makeObservable(this);
        this.router = router;
        this.eventWhenActionNotLogged = new EventEmmiter();
    }

    @computed public get messageForView(): MessageType | null {
        if (this.userSendLogoutAction) {
            return null;
        }

        return this.message;
    }

    @action public showMessage(title: string, message: string): void {
        this.message = {
            title,
            message
        };
    }

    @action public markAsLogout = (): void => {
        this.userSendLogoutAction = true;
    }

    @action public onCloseModal = (): void => {
        this.message = null;
    }

    @action public onClose = (): void => {
        if (this.userSendLogoutAction === false) {
            this.userSendLogoutAction = true;
            this.router.redirectToLogin();
            setTimeout(() => {
                window.location.reload();
            }, 0);
        }
    }

    @action public runActionNotLogged = (): void => {
        this.showMessage('You are not logged in', 'You need to login to perform this action.');
        this.eventWhenActionNotLogged.trigger();
    }

    public onEventWhenActionNotLogged(callback: () => void): (() => void) {
        return this.eventWhenActionNotLogged.on(callback);
    }
}
