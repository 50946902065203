import { Value } from 'src_common/common/mobx-utils/Value';
import { JackpotsListType } from './JackpotsDetailsModels';

export class JackpotsListModel {
    private constructor(
        private readonly data: Value<JackpotsListType>,
    ) {}

    public static create(data: Value<JackpotsListType>): JackpotsListModel {
        return new JackpotsListModel(data);
    }

    public get jackpotsList(): JackpotsListType {
        return this.data.getValue();
    }
}
