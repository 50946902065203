import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { ResponsibleGamblingDefault } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/responsibleGambling/ResponsibleGamblingDefault';
import { ResponsibleGamblingVerificationScript } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/responsibleGambling/ResponsibleGamblingVerificationScript';

export const ResponsibleGambling = observer('ResponsibleGambling', (): JSX.Element => {
    const { config } = useAppStateContext();

    if (config.responsibleGamblingVerificationScript === 'swifty-type') {
        return <ResponsibleGamblingVerificationScript />;
    }

    return <ResponsibleGamblingDefault />;
});
