import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const GroupedFilters = styled('div', { label: 'GroupedFilters' })`
    margin: 8px 0 8px 8px;
    overflow-x: hidden;
`;

export const TomorrowLabel = withConfig(theme => styled('button', { label: 'TomorrowLabel' })`
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    color: ${theme.star.racingNextOff.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    height: 32px;
    justify-content: center;
    line-height: 1.3333;
    margin: 0;
    user-select: none;
`);

export const TomorrowChevronIconWrapper = styled(ChevronIcon, { label: 'TomorrowChevronIconWrapper' })`
    fill: currentcolor;
    flex: 0 0 14px;
`;
