import React from 'react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { observer } from 'src/utils/mobx-react';
import { GridBody } from './grid-body/GridBody';
import { GridHeader } from './grid-header/GridHeader';
import { MobileWrapper, DesktopWrapper } from './MiniGamesGrid.style';

interface PropsType {
    appCasinoState: AppCasinoState;
    isGameOpened: boolean;
}

export const MiniGamesGrid = observer('MiniGamesGrid', (props: PropsType) => {
    const { appCasinoState } = props;
    const { breakpointsState } = appCasinoState;

    const isDesktop = breakpointsState.desktop.isBiggerOrEq;
    const { isGamesListDisplayed, isTransitionFinished, isGamerGridDisplayed } = appCasinoState.miniGamesListState;

    if (isDesktop === true) {
        return (
            <DesktopWrapper>
                <GridHeader appCasinoState={appCasinoState} />
                <GridBody appCasinoState={appCasinoState} />
            </DesktopWrapper>
        );
    }

    return (
        <MobileWrapper
            isMobileGridOpen={isGamesListDisplayed && props.isGameOpened === false}
            onTransitionEnd={isTransitionFinished}
        >
            <GridHeader appCasinoState={appCasinoState} />
            {isGamerGridDisplayed ? <GridBody appCasinoState={appCasinoState} /> : null}
        </MobileWrapper>
    );
});
