import { observable, makeObservable } from 'mobx';
import { CouponIdType } from './coupons';
import { EventsListMarkets } from './EventsListMarkets';

export class EventsListSelectTabState {

    @observable public filter_coupon: CouponIdType = 'all';
    public readonly eventsListMarkets: EventsListMarkets;

    public constructor(
        getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string
    ) {
        makeObservable(this);
        this.eventsListMarkets = new EventsListMarkets(getTranslation);
    }
}


