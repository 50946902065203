import { action, computed, observable, makeObservable } from 'mobx';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { validateConfirmEmail } from 'src/domains/players/webview/components/Account/common/formNew/formNewValidator/validateConfirmEmail';
import { UsersState } from 'src/domains/players/state/UsersState';
import { validateNewAndConfirmEmail } from 'src/domains/players/webview/components/Account/common/formNew/formNewValidator/validateNewAndConfirmEmail';
import { validateNewAndOldEmail } from 'src/domains/players/webview/components/Account/common/formNew/formNewValidator/validateNewAndOldEmail';
import { validateEmail, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { validateOldEmail } from 'src/domains/players/webview/components/Account/common/formNew/formNewValidator/validateOldEmail';
import { TrpcClient } from 'src/appState/TrpcClient';

export interface NewEmailGroupModelTypes {
    newEmail: string;
    confirmEmail: string;
}

export interface SetNewEmailGroupModelTypes {
    oldEmail: string;
    newEmail: string;
}

const toLowerCase = (value: string): Result<string> => {
    return Result.createOk(value.trim().toLowerCase());
};
export class ChangeEmailProcedureState {
    public readonly usersState: UsersState;

    public readonly oldEmailState: FormInputState<string, string>;

    public readonly newEmailState: FormInputState<string, string>;

    public readonly confirmEmailState: FormInputState<string, string>;

    public readonly newEmailGroup: FormModel<NewEmailGroupModelTypes>;
    public readonly setNewEmailGroup: FormModel<SetNewEmailGroupModelTypes>;

    @observable public isShowSuccessMessage: boolean;
    @observable public alreadyExistingEmail: string;

    public constructor(
        usersState: UsersState,
        private readonly trpcClient: TrpcClient
    ) {
        makeObservable(this);
        this.usersState = usersState;
        this.isShowSuccessMessage = false;
        this.alreadyExistingEmail = '';

        this.oldEmailState = FormInputState.new('')
            .map(validateRequire)
            .map(toLowerCase)
            .map(validateEmail)
            .map(validateOldEmail(this.usersState));

        this.newEmailState = FormInputState.new('').map(validateRequire).map(toLowerCase).map(validateEmail);

        this.confirmEmailState = FormInputState.new('')
            .map(validateRequire)
            .map(toLowerCase)
            .map(validateEmail)
            .map(validateConfirmEmail(this.newEmailState));

        this.newEmailGroup = FormModel.group({
            newEmail: this.newEmailState,
            confirmEmail: this.confirmEmailState,
        }).map(validateNewAndConfirmEmail);

        this.setNewEmailGroup = FormModel.group({
            oldEmail: this.oldEmailState,
            newEmail: this.newEmailState,
        }).map(validateNewAndOldEmail);
    }

    @computed public get isButtonDisabled(): boolean {
        return (
            this.newEmailState.value === '' || this.oldEmailState.value === '' || this.confirmEmailState.value === ''
        );
    }

    @computed public get setNewEmailErrorMessage(): string | null {
        if (this.setNewEmailGroup.result.value.type === 'error') {
            const alreadyPickedErrorMessage = this.setNewEmailGroup.result
                .errors()
                .find((errorMessage) => errorMessage === getErrorByCode('ERROR_OLD_EMAIL_EQUAL_NEW'));
            if (this.setNewEmailGroup.isVisited() && alreadyPickedErrorMessage !== undefined) {
                return alreadyPickedErrorMessage;
            }
        }
        return null;
    }

    @computed public get newEmailErrorMessage(): string | null {
        if (this.setNewEmailGroup.result.value.type === 'error') {
            const confirmEmailErrorMessage = this.newEmailGroup.result
                .errors()
                .find((errorMessage) => errorMessage === getErrorByCode('ERROR_CONFIRM_EMAIL'));
            if (this.newEmailGroup.isVisited() && confirmEmailErrorMessage !== undefined) {
                return confirmEmailErrorMessage;
            }
        }
        return null;
    }

    @computed public get newEmailAlreadyExistsErrorMessage(): string | null {
        if (
            this.alreadyExistingEmail !== '' &&
            this.alreadyExistingEmail === this.newEmailState.value &&
            this.alreadyExistingEmail === this.confirmEmailState.value
        ) {
            const result = Result.createError(getErrorByCode('ERROR_NEW_EMAIL_ALREADY_EXISTS'));
            return result.errors()[0] ?? null;
        }
        return null;
    }

    @computed public get changeEmailIsValid(): SetNewEmailGroupModelTypes | null {
        const setNewEmailGroupResult = this.setNewEmailGroup.result;
        const newEmailGroupResult = this.newEmailGroup.result;

        if (setNewEmailGroupResult.value.type === 'ok' && newEmailGroupResult.value.type === 'ok') {
            return setNewEmailGroupResult.value.data;
        }

        return null;
    }

    @action public update = async (): Promise<void> => {
        this.triggerInputsOnChange();
        if (this.changeEmailIsValid !== null) {
            const result = await this.onChangeEmail(this.changeEmailIsValid.newEmail, this.changeEmailIsValid.oldEmail);
            if (result) {
                this.handleSuccess();
            }
        }
    };

    //@todo: ugly solution. find out how to trigger onChange externally on inputs
    @action public triggerInputsOnChange = (): void => {
        this.oldEmailState.setValue(this.oldEmailState.value);
        this.oldEmailState.setAsVisited();

        this.newEmailState.setValue(this.newEmailState.value);
        this.newEmailState.setAsVisited();

        this.confirmEmailState.setValue(this.confirmEmailState.value);
        this.confirmEmailState.setAsVisited();
    };

    @action public handleSuccess = (): void => {
        this.isShowSuccessMessage = true;
        this.oldEmailState.reset();
        this.newEmailState.reset();
        this.confirmEmailState.reset();
        setTimeout(() => {
            this.isShowSuccessMessage = false;
        }, 5000);
    };

    @action public onChangeEmail = async (email: string, oldEmail: string): Promise<boolean> => {
        const response = await this.trpcClient.client.accounts.accountChangeEmail.mutate({
            email,
            oldEmail,
        });

        if (response.responseStatus === 'error' && response.data.code === '422') {
            this.alreadyExistingEmail = email;
            return false;
        }

        if (response.responseStatus === 'success') {
            return true;
        }

        return false;
    };
}
