import { css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

export const buildBtnSize = (theme: EmotionTheme, size: 'large' | 'medium' | 'small' | 'xs'): SerializedStyles => {
    if (size === 'large') {
        return css`
            font-size: ${theme.star.fontSize.regular};
            padding: 12px 16px;
            line-height: 1.5;
        `;
    }
    if (size === 'medium') {
        return css`
            font-size: ${theme.star.fontSize.xRegular};
            padding: 10px 16px;
            line-height: 1.43;
        `;
    }
    if (size === 'small') {
        return css`
            font-size: ${theme.star.fontSize.small};
            padding: 8px 12px;
            line-height: 1.3334;
        `;
    }

    // xs is default
    return css`
        font-size: ${theme.star.fontSize.small};
        padding: 4px 8px;
        line-height: 1.3334;
    `;
};

export const buildBtnBackgroundColor = (theme: EmotionTheme, version: 'primary' | 'secondary' | 'secondary-light' | 'secondary-dark' | 'tertiary-dark' | 'tertiary-light'): SerializedStyles => {
    if (version === 'tertiary-light') {
        return css`
            background-color: ${theme.star.buttons.tertiary.light.bgColor};
            color: ${theme.star.buttons.tertiary.light.txtColor};
            &::before {
                background: ${theme.star.buttons.tertiary.light.bgColorSecondary};
            }
        `;
    }

    if (version === 'tertiary-dark') {
        return css`
            background-color: ${theme.star.buttons.tertiary.dark.bgColor};
            color: ${theme.star.buttons.tertiary.dark.txtColor};
            &::before {
                background: ${theme.star.buttons.tertiary.dark.bgColorSecondary};
            }
        `;
    }

    if (version === 'secondary') {
        return css`
            background-color: ${theme.star.buttons.secondary.bgColor};
            color: ${theme.star.buttons.secondary.txtColor};
            box-shadow: inset 0 0 0 1px ${theme.star.buttons.secondary.borderColor};
            &::before {
                background: ${theme.star.buttons.secondary.bgColorSecondary};
            }
        `;
    }


    if (version === 'secondary-light') {
        return css`
            background-color: ${theme.star.buttons.secondary.light.bgColor};
            color: ${theme.star.buttons.secondary.light.txtColor};
            &::before {
                background: ${theme.star.buttons.secondary.light.bgColorSecondary};
            }
        `;
    }

    if (version === 'secondary-dark') {
        return css`
            background-color: ${theme.star.buttons.secondary.dark.bgColor};
            color: ${theme.star.buttons.secondary.dark.txtColor};
            &::before {
                background: ${theme.star.buttons.secondary.dark.bgColorSecondary};
            }
        `;
    }

    return css`
        background-color: ${theme.star.buttons.primary.bgColor};
        color: ${theme.star.buttons.primary.txtColor};
        &::before {
            background: ${theme.star.buttons.primary.bgColorSecondary};
        }
    `;
};

export const buildBtnDisabled = (disabled?: boolean): SerializedStyles | null => {
    if (disabled === true) {
        return css`
            cursor: not-allowed;
            opacity: .4;
            pointer-events: none;
        `;
    }
    return null;
};

export const buildBtnLoading = (loading?: boolean): SerializedStyles | null => {
    if (loading === true) {
        return css`
            cursor: not-allowed;
            opacity: .6;
            pointer-events: none;
        `;
    }
    return null;
};


export const buildSpinner = (size: 'large' | 'medium' | 'small' | 'xs' | undefined): SerializedStyles => {
    if (size === 'large') {
        return css`
            height: 16px;
            margin: 4px auto;
            width: 16px;
        `;
    }

    if (size === 'medium') {
        return css`
            height: 16px;
            width: 16px;
        `;
    }

    return css`
        height: 12px;
        width: 12px;
    `;
};


