import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { getError } from 'src/domains/layouts/webview/components/common/errorMessage/getError';
import { InfoMessageWrapper, InfoIconWrapper } from './styles';
import { UniverseType } from 'src_common/common/universe';
import { UniverseVersion } from 'src/domains/common/UniverseVersion';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface InfoMessageDeprecatedPropsType {
    message?: string | {};
    children?: React.ReactNode;
    backgroundColor?: 'default' | 'transparent';
    hasMargin?: boolean;
    hasIcon?: boolean;
    className?: string;
}

// TODO - Temporary solution, introduced to not change getErrors file, for errors that contain message only
const parseErrorMessage = (error: string | undefined): undefined | string => error?.split(':undefined')[0];

/** * @deprecated - Please use {@link Messages} instead. */
export const InfoMessageDeprecated = observer('InfoMessage', (props: InfoMessageDeprecatedPropsType): JSX.Element => {
    const { className, message, children, backgroundColor = 'default', hasIcon = true, hasMargin = true } = props;

    return (
        <InfoMessageWrapper
            backgroundColor={backgroundColor}
            className={className}
            hasIcon={hasIcon}
            hasMargin={hasMargin}
        >
            {hasIcon ? <InfoIconWrapper /> : null}
            <UniverseVersion>
                {(universe: UniverseType): React.ReactElement => (
                    <div data-test='info-message-content'>
                        {parseErrorMessage(getError(universe, message))}
                        {children !== undefined && children}
                    </div>
                )}
            </UniverseVersion>
        </InfoMessageWrapper>
    );
});
