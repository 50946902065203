import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';
interface FormStyledPropsTypes {
    isMessage: string;
}
// TODO: ---> add the rest of styles and styled components in another task
export const RealityCheckFormWrapper = styled('div', { label: 'RealityCheckFormWrapper' })<FormStyledPropsTypes>`
    margin-bottom: ${(props): string => props.isMessage === '' ? '30px' : '10px'};
`;

export const RealityCheckStatusHeader = styled('h4', { label: 'RealityCheckStatusHeader' })`
    margin: 0;
`;

export const RealityCheckStatusContainer = styled('div', { label: 'RealityCheckStatusContainer' })`
    margin: 0 0 16px;
`;

export const RealityCheckStatus = styled('div', { label: 'RealityCheckStatus' })`
    display: flex;
    justify-content: space-between;
    margin: 0 0 4px 0;
`;

export const RealityCheckParagraph = withConfig(theme => styled('p', { label: 'RealityCheckParagraph' })`
    line-height: 1.4285;
    margin: 0 0 16px;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const SectionHeader = withConfig(theme => styled('h4', { label: 'SectionHeader' })`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 4px;
`);

export const RealityCheckButton = styled(Button, { label: 'RealityCheckButton' })`
    width: 100%;
`;
