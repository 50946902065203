import React, { useState, useEffect } from 'react';
import { observer } from 'src/utils/mobx-react';

import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetSlipOfferTimerState } from './BetslipOfferTimer.state';
import { when } from 'mobx';
import { BetSlipOfferTimerContent, CalendarEndIconWrapper, OfferTimer, RejectLink, StopWatchIconWrapper, WarningIconWrapper } from './BetslipOfferTimer.style';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsTypes {
    offerExpiryTime: number | null,
    shouldShowOfferExpired: boolean
};

const BetSlipOfferTimerMessages = observer('BetSlipOfferTimerMessages', ({ offerExpiryTime, shouldShowOfferExpired }: PropsTypes) => {
    const { appSportsBookState: { betSlipState } } = useAppStateContext();
    const { referralState } = betSlipState;

    if (offerExpiryTime === 0 && shouldShowOfferExpired) {
        return (
            <BetSlipOfferTimerContent>
                <I18n langKey='betslip.offer.error.expired' defaultText='Offer has expired' />
            </BetSlipOfferTimerContent>
        );
    }

    return (
        <BetSlipOfferTimerContent>
            <I18n langKey='betslip.offer.expires-in' defaultText='This offer will expire in {secs}s' params={{ secs: offerExpiryTime ?? 'n/a' }} /><br />
            <RejectLink href='#' onClick={referralState.onRejectOffer}>
                <I18n langKey='betslip.offer.expires-in-accept' defaultText='or you may reject it' />
            </RejectLink>
        </BetSlipOfferTimerContent>
    );
});

export const BetSlipOfferTimer = observer('BetSlipOfferTimer', () => {
    const { appSportsBookState: { betSlipState } } = useAppStateContext();
    const { referralState } = betSlipState;
    const [state] = useState(() => new BetSlipOfferTimerState(referralState));

    useEffect(() => {
        const dispose = when(
            () => state.shouldShowOfferExpired === true,
            () => {
                referralState.onCancelOffer();
            }
        );

        return (): void => {
            dispose();
        };
    }, []);

    return (
        <OfferTimer>
            {referralState.isRejectedBet ? (
                <WarningIconWrapper />
            ) : state.offerExpiryTime !== null && state.offerExpiryTime <= 0 ? (
                <CalendarEndIconWrapper />
            ) : (
                <StopWatchIconWrapper />
            )}

            <BetSlipOfferTimerMessages
                offerExpiryTime={state.offerExpiryTime}
                shouldShowOfferExpired={state.shouldShowOfferExpired}
            />
        </OfferTimer>
    );
});
