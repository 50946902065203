import { FormModel } from 'src_common/common/mobx-utils/Form2/FormModel';
import { DateTime } from 'src_common/utils/time/time';

export class DateInputStatus {
    private inputModel: FormModel<DateTime>;

    public constructor(inputModel: FormModel<DateTime>) {
        this.inputModel = inputModel;
    }

    public get type(): 'success' | 'error' {
        const isError = this.inputModel.result.value.type === 'error';
        const isVisited = this.inputModel.isVisited();

        if (isVisited && isError) {
            return 'error';
        }
        return 'success';
    }

    public get message(): string | null {
        return this.inputModel.result.errors()?.[0] ?? null;
    }
}
