import { computed, observable, makeObservable } from 'mobx';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { Result } from 'src_common/common/mobx-utils/Resource';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { DateTime } from 'src_common/utils/time/time';
import { StatusType, TransactionParamsType, TransactionsForViewTypes } from './types';
import { OpenapiProxyCustomerTransactionHistoryResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_transaction_history';
import { CustomerNetDepositType } from 'src/domains/layouts/state/customer';
import { Amount } from 'src_common/common/amount/Amount';

export interface RangeType {
    from: string;
    to: string;
}

type CustomerTransactionHistoryType = OpenapiProxyCustomerTransactionHistoryResponse200Type;

interface AmountCustomerNetDepositType {
    aggregations: {
        total: Amount;
        totalValue: Amount;
    };
}

export class TransactionHistoryCollection {
    @observable private page: number = 0;

    public constructor(
        private readonly account: AccountState,
        private readonly translationsStore: TranslationsStore,
        public readonly activeFilter: TransactionParamsType,
        public readonly range: RangeType | null,
    ) {
        makeObservable(this);
    }

    private getFromPage(page: number): Result<CustomerTransactionHistoryType> | undefined {
        return this.account.account?.userTransactionHistory.get([
            25,
            page,
            this.range?.from,
            this.range?.to,
            this.activeFilter === 'all' ? null : this.activeFilter
        ]).get();
    }

    public setActiveFilter(activeFilter: TransactionParamsType): TransactionHistoryCollection {
        return new TransactionHistoryCollection(
            this.account,
            this.translationsStore,
            activeFilter,
            this.range,
        );
    }

    public setRange(range: RangeType | null): TransactionHistoryCollection {
        return new TransactionHistoryCollection(
            this.account,
            this.translationsStore,
            this.activeFilter,
            range,
        );
    }

    @computed public get activePage(): number {
        return this.page;
    }

    @computed public get totalPages(): number {
        const currentPages = this.page + 1;
        const nextPage = this.hasNextPage ? 1 : 0;
        return currentPages + nextPage;
    }

    public get isFirstPage(): boolean {
        return this.page === 0;
    };

    public get isLastPage(): boolean {
        if (this.hasNextPage) {
            return false;
        }

        return true;
    }

    public get hasPrevPage(): boolean {
        return this.page > 0;
    }

    public get hasNextPage(): boolean {
        const list = this.getFromPage(this.page + 1);

        if (list === undefined) {
            return false;
        }

        if (list.type === 'ready') {
            return list.value.data.length > 0;
        }

        return false;
    }

    public get hasNextPageLoading(): boolean {
        const list = this.getFromPage(this.page + 1);

        if (list === undefined) {
            return false;
        }

        return list.type === 'loading';
    }

    public prevPage = (): void => {
        if (this.page > 0) {
            this.page -= 1;
            this.account.scrollAccountTab();
        }
    };

    public nextPage = (): void => {
        if (this.hasNextPage) {
            this.page += 1;
            this.account.scrollAccountTab();
        }
    };

    @computed private get transactionHistoryResponse(): Result<CustomerTransactionHistoryType> | undefined | null {
        return this.getFromPage(this.page);
    }

    @computed public get transactions(): CustomerTransactionHistoryType {
        if (this.transactionHistoryResponse?.type === 'ready') {
            return this.transactionHistoryResponse.value;
        }

        return {
            data: [],
            metadata: {
                page: 0,
                perPage: 0,
                total: 0,
                totalPages: 0,
            }
        };
    }

    @computed public get responseStatusTransactions(): StatusType {
        return this.transactionHistoryResponse?.type;
    }


    @computed public get transactionsForView(): Array<TransactionsForViewTypes> {
        return this.transactionsAllNormalized.sort((a,b) => {
            const dateB = DateTime.from(b.date)?.unixMs();
            const dateA = DateTime.from(a.date)?.unixMs();

            if (dateB === undefined || dateA === undefined) {
                return 0;
            }

            return dateB - dateA;
        });
    };

    @computed private get transactionsAllNormalized(): Array<TransactionsForViewTypes> {
        return this.transactionsNormalized;
    }
    @computed private get transactionsNormalized(): Array<TransactionsForViewTypes> {
        const transactions: Array<TransactionsForViewTypes> = this.transactions.data.map((elem) => {
            const { transactionHistoryName } = this.translationsStore;
            return {
                id: elem.id,
                displayId: elem.displayId,
                balanceDelta: elem.balanceDelta,
                balanceAfter: elem.balanceAfter,
                currency: elem.currency,
                date: elem.date,
                status: elem.status,
                type: transactionHistoryName(elem.type) ?? transactionHistoryName(elem.type) ?? elem.type,
                cashout: elem.cashout,
                displayedReason: elem.displayedReason,
                fundsLocked: elem.fundsLocked,
                adjustmentType: elem.adjustmentType,
            };
        });

        return transactions;
    }

    @computed public get isNetDeposit(): boolean {
        return this.activeFilter === 'net-deposit';
    }

    @computed private get netDepositResponse(): Result<CustomerNetDepositType> | undefined | null {
        if (this.range === null) {
            return this.account.account?.userNetDeposit.get([undefined, undefined]).get();
        }
        return this.account.account?.userNetDeposit.get([this.range.from, this.range.to]).get();
    }

    @computed public get netDeposit(): AmountCustomerNetDepositType | null {
        if (this.netDepositResponse?.type === 'ready') {
            return {
                aggregations: {
                    total: new Amount(this.netDepositResponse.value.aggregations.total),
                    totalValue: new Amount(this.netDepositResponse.value.aggregations.totalValue),
                }
            };
        }
        return null;
    }

    @computed public get responseStatusNetDeposit(): StatusType {
        return this.netDepositResponse?.type;
    }

}
