import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface BalanceDepositType {
    isFakeButton: boolean;
};

export const BalanceDepositWrapper = withConfig(theme => styled('div', { label: 'BalanceDepositWrapper' })<BalanceDepositType>`
    ${({ isFakeButton }): string => isFakeButton ? 'cursor: pointer' : ''};
    background-color: ${theme.star.buttons.secondary.dark.bgColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    padding: 16px 14px;
    text-align: center;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: ${({ isFakeButton }): string => isFakeButton === true ? theme.star.buttons.secondary.dark.bgColorSecondary : theme.star.buttons.secondary.dark.bgColor};
    }
`);

export const BalanceDepositEmphasised = withConfig(theme => styled('strong', { label: 'BalanceDepositEmphasised' })`
    color: ${theme.star.betslipGeneral.txtColorSeptenary};
    display: block;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4286;
    margin-top: 8px;
`);
