import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { HeaderWrapper, Headline, ViewAll, ViewAllTxt, ChevronRight } from './SliderHeader.style';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';

interface SliderHeaderPropsType {
    appCasinoState: AppCasinoState;
    title: string | JSX.Element;
    withoutViewAll?: boolean;
    onViewAllClick?: () => void;
}

export const SliderHeader = observer('SliderHeader', (props: SliderHeaderPropsType): JSX.Element => {
    const { title, onViewAllClick, withoutViewAll } = props;

    return (
        <HeaderWrapper data-test='slider-header'>
            <Headline>{title}</Headline>
            {withoutViewAll === true ? null : (
                <ViewAll
                    onClick={onViewAllClick}
                    data-test='view-all-button'
                >
                    <ViewAllTxt>
                        <I18n
                            langKey='casino.home.slider.header.view-all'
                            defaultText='View All'
                        />
                    </ViewAllTxt>
                    <ChevronRight
                        position='right'
                        key='view-all'
                    />
                </ViewAll>
            )}
        </HeaderWrapper>
    );
});
