import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { sortSelections } from 'src/domains/layouts/utils/sortSelections';
import { RaceSummaryRow } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { HeadersHorseRacing } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/HeadersHorseRacing';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { calculateRaceStatus } from 'src/domains/sportsbook/utils/filterRacesWithFinishState';
import { RaceSummaryHeader } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow.style';
import {
    RaceCardFeed,
    RaceSummaryMeta,
    RaceHeaderListMeta,
    RaceHeaderListItemMeta,
    RaceHeaderListItemSeparatorMeta,
    RaceSummaryNoHistory,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { HorseRacingSortOrderType } from 'src/domains/sportsbook/webview/components/raceCard/RaceCompetition.state';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';

interface MetaPropsType {
    race: EventModel;
    marketModel: MarketModel;
}

export const MetaHorseRacing = observer('MetaHorseRacing', ({ race, marketModel }: MetaPropsType) => {
    const goingInfo = race.feedDataGoing ?? marketModel.name;
    const handicapCond = race.feedHandicap ?? null;
    const courseType = race.feedCourseType ?? null;
    const distance = race.feedDistance ?? null;

    return (
        <RaceHeaderListMeta>
            <RaceHeaderListItemMeta>{goingInfo}</RaceHeaderListItemMeta>
            {handicapCond === null ? null : (
                <RaceHeaderListItemMeta>
                    <I18n langKey='events.handicap.label' defaultText='Handicap' />
                </RaceHeaderListItemMeta>
            )}
            {courseType === null ? null : <RaceHeaderListItemMeta>{courseType}</RaceHeaderListItemMeta>}
            {distance !== null || courseType !== null || handicapCond !== null ? (
                <RaceHeaderListItemSeparatorMeta>&nbsp;</RaceHeaderListItemSeparatorMeta>
            ) : null}
            {distance === null ? null : <RaceHeaderListItemMeta>{distance}</RaceHeaderListItemMeta>}
        </RaceHeaderListMeta>
    );
});

interface PropsType {
    sortOrder?: HorseRacingSortOrderType;
    marketId: MarketId;
    ewTerms?: React.ReactNode | void;
    type?: string;
    isAntePost?: boolean;
    isSpecials?: boolean;
}

const getSortOrder = (sortOrder?: HorseRacingSortOrderType, displayOrder?: string): string => {
    if (sortOrder !== null && sortOrder !== undefined) {
        return sortOrder;
    }

    return displayOrder !== undefined && displayOrder !== '-' ? displayOrder : 'by-creation';
};

export const RaceSummaryHorseRacing = observer('RaceSummaryHorseRacing', (props: PropsType) => {
    const { marketId, ewTerms, type, isAntePost, isSpecials, sortOrder } = props;

    const marketModel = marketId.getModel();

    if (marketModel === null) {
        return null;
    }

    const raceModel = marketModel.getEvent();

    if (raceModel === null) {
        return null;
    }
    const selections = sortSelections(marketModel.selections).filter((x: SelectionModel): boolean => x.display);
    const isPriceOnly = marketModel.templateId === 'win-only';
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const hidePriceHistoryColumn = !selections.find((selection) => selection.priceHistory.length) || isPriceOnly;

    const displayOrder = marketModel.displayOrderTag;
    const order = getSortOrder(sortOrder, displayOrder);
    const itemsForView = sortSelectionsByCriteria(selections, order);

    const raceStatus = calculateRaceStatus(raceModel.timeSettingsTimeline, raceModel.state);

    return (
        <RaceSummaryNoHistory isPriceHistoryHidden={hidePriceHistoryColumn}>
            {isAntePost === true ? (
                <RaceSummaryHeader>
                    <RaceCardFeed>
                        <MetaHorseRacing race={raceModel} marketModel={marketModel} />
                    </RaceCardFeed>
                    {ewTerms !== null && ewTerms !== undefined && ewTerms !== '' && ewTerms !== 0 ? (
                        <RaceSummaryMeta data-test='race-summary-meta'>{ewTerms}</RaceSummaryMeta>
                    ) : (
                        undefined
                    )}
                </RaceSummaryHeader>
            ) : null}

            <ul className='race-summary__list'>
                <HeadersHorseRacing
                    type={type}
                    race={raceModel}
                    market={marketModel}
                    isSpecials={isSpecials}
                    isRaceInProgress={raceStatus === 'RaceStatusInProgress'}
                    isPriceOnly={isPriceOnly}
                />
                {itemsForView.map((selection) => (
                    <RaceSummaryRow
                        key={selection.id}
                        selectionId={selection.id2}
                        type={type}
                        isSpecials={isSpecials}
                        isRaceInProgress={raceStatus === 'RaceStatusInProgress'}
                        isRaceFinished={raceStatus === 'RaceStatusFinished'}
                    />
                ))}
            </ul>
        </RaceSummaryNoHistory>
    );
});
