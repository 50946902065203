import { UniverseType } from 'src_common/common/universe';
import { RealTimeLobbyGameDetails } from './RealTimeLobbyGameDetails';
import { RealTimeLobbyGameModel } from './RealTimeLobbyGameModel';

export class RealTimeLobbySocket {
    private readonly data: RealTimeLobbyGameDetails | null = null;

    public constructor(
        public readonly websocket_casino_rt_lobby_host: string | null,
        public readonly universe: UniverseType,
        public readonly isBrowser: boolean
    ) {
        if (websocket_casino_rt_lobby_host === null) {
            console.warn('Casino RTL host is not defined.');
            return;
        }
        this.data = new RealTimeLobbyGameDetails(websocket_casino_rt_lobby_host, universe, isBrowser);
    }

    public getModel(gameId: number): RealTimeLobbyGameModel | null {
        if (this.data === null) {
            return null;
        }
        return this.data.getModel(gameId);
    }
}
