import { SMSCodeState } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithSMS/SMSCode.state';

export class SMSInputState {
    public constructor(private readonly state: SMSCodeState, private readonly idx: number) {
    }

    public onInput = (): void => {
        const next = this.state.getNextInputRef(this.idx);

        if (next !== null) {
            next.focus();
        }
    };
}
