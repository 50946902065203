import { createGuard } from 'src_common/common/createGuard';
import * as t from 'io-ts';

const OptionDataIO = t.interface({
    value: t.string,
    label: t.string,
});

export type OptionType = t.TypeOf<typeof OptionDataIO>;

export const checkOptionType = createGuard(OptionDataIO);
