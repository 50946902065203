import * as React from 'react';
import { observable, computed, action, makeObservable } from 'mobx';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountBasicDataType } from 'src/domains/layouts/state/customer';
import { FormInputState as FormInputState2 } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel } from 'src_common/common/mobx-utils/Form2/FormModel';
import {
    validateAddress,
    validateCity,
    validatePostCode,
    validateRequire,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { findAddress } from 'src_server/trpc/types/accountModel';

interface GroupType {
    postcode: string;
    adress1: string;
    adress2: string;
    townOrCity: string;
}

export class ChangeAddressProcedureNewFormState {
    @observable public findPostcodeProgress: boolean;
    @observable public findPostcodeDetailsProgress: boolean;
    @observable public updateProgress: boolean;
    @observable public adressList: findAddress.SuccessResponse | null = null;
    @observable public messageAfterUpdate: React.ReactNode | null = null;
    @observable public myAddressError: string | null = null;

    private readonly initialData: Omit<AccountBasicDataType, 'oddsFormat'>;

    public readonly postcodeState: FormInputState2<string, string>;

    public readonly adress1State: FormInputState2<string, string>;

    public readonly adress2State: FormInputState2<string, string>;

    public readonly townOrCityState: FormInputState2<string, string>;

    public readonly group: FormModel<GroupType>;

    public constructor(
        initialData: () => Omit<AccountBasicDataType, 'oddsFormat'>,
        private readonly signUpAddressSearchByPostCode: boolean,
        private readonly language: LanguagesState,
        private readonly trpc: TrpcClient
    ) {
        makeObservable(this);
        this.initialData = initialData();
        const initialPostcode = this.initialData.postCode ?? '';
        const initialAddres1 = this.initialData.addressLine1 ?? '';
        const initialAddres2 = this.initialData.addressLine2 ?? '';
        const initialCity = this.initialData.city ?? '';

        this.postcodeState = FormInputState2.new(initialPostcode).map(validatePostCode(this.language.getTranslation));

        this.adress1State = FormInputState2.new(initialAddres1).map(validateRequire).map(validateAddress);

        this.adress2State = FormInputState2.new(initialAddres2).map(validateAddress);

        this.townOrCityState = FormInputState2.new(initialCity).map(validateRequire).map(validateCity);

        this.findPostcodeProgress = false;
        this.findPostcodeDetailsProgress = false;
        this.updateProgress = false;

        this.group = FormModel.group({
            postcode: this.postcodeState,
            adress1: this.adress1State,
            adress2: this.adress2State,
            townOrCity: this.townOrCityState,
        });
    }

    public findAdress = async (): Promise<void> => {
        this.findPostcodeProgress = true;
        this.myAddressError = null;
        const payload = {
            Countries: '',
            Text: this.postcodeState.value,
        };

        const response = await this.trpc.client.accounts.findAddress.mutate(payload);

        this.findPostcodeProgress = false;
        if (response.responseStatus === 'success') {
            this.adressList = response.data;
        } else {
            this.myAddressError = 'Error, enter address manually';
        }
    };

    public onChoose = async (addressItem: findAddress.AddressItem): Promise<void> => {
        this.findPostcodeDetailsProgress = true;

        const response = await this.trpc.client.accounts.findAddressDetails.mutate({ addressId: addressItem.Id });

        this.findPostcodeDetailsProgress = false;
        this.adressList = null;
        let firstItem;

        if (response.responseStatus === 'success') {
            firstItem = response.data[0];
        } else {
            this.myAddressError = 'Error, enter address manually';
        }

        if (firstItem !== undefined) {
            this.adress1State.setValue(firstItem.Line1);
            this.adress2State.setValue(firstItem.Line2);
            this.townOrCityState.setValue(firstItem.City);
        }
    };

    public sendUpdate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        e.preventDefault();
        e.stopPropagation();

        const result = this.group.result;
        if (result.value.type === 'ok') {
            const params = {
                postCode: result.value.data.postcode,
                addressLine1: result.value.data.adress1,
                addressLine2: result.value.data.adress2,
                city: result.value.data.townOrCity,
            };

            this.updateProgress = true;

            await this.trpc.client.accounts.accountChangeAddress.mutate(params);

            this.updateProgress = false;
            this.messageAfterUpdate = (
                <I18n
                    langKey='account.address-procedure.success-message'
                    defaultText='You have successfully changed your address.'
                />
            );
        }
    };

    @action public resetMessage = (): void => {
        this.messageAfterUpdate = null;
    };

    @computed public get loadingStatus(): boolean {
        return this.findPostcodeProgress || this.findPostcodeDetailsProgress;
    }

    @computed public get postCodePlaceholder(): string {
        return this.signUpAddressSearchByPostCode === true
            ? this.language.getTranslation('account.address-procedure.postcode.placeholder-gb', 'Postcode')
            : this.language.getTranslation(
                  'account.address-procedure.postcode.placeholder-world',
                  'Post Code / Zip Code'
              );
    }

    @computed public get postCodeLabel(): JSX.Element {
        return this.signUpAddressSearchByPostCode === true ? (
            <I18n
                langKey='account.address-procedure.postcode.label-gb'
                defaultText='Address'
            />
        ) : (
            <I18n
                langKey='account.address-procedure.postcode.label-world'
                defaultText='*Postcode / ZIP Code'
            />
        );
    }

    @computed public get isPostcodeButtonDisabled(): boolean {
        return this.postcodeState.result.value.type === 'error';
    }

    @computed public get isButtonDisabled(): boolean {
        const isAdress1Wrong = this.adress1State.result.value.type === 'error';
        const isTownOrCityWrong = this.townOrCityState.result.value.type === 'error';
        const isPostCodeWrong = this.postcodeState.result.value.type === 'error';

        return isAdress1Wrong || isTownOrCityWrong || isPostCodeWrong;
    }
}
