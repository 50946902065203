import { ExternalApiEmmiters } from './ExternalApiEmmiters';
import { observable, makeObservable, action } from 'mobx';
import { assertNever } from 'src_common/common/assertNever';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { LoginResponseType } from 'src_common/sdk/session';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { CasinoAppVersionModelType } from 'src/api/config/cms_new/mobileCasinoVersion/getMobileCasinoVersion';
import { TrpcClient } from 'src/appState/TrpcClient';

type UnsubscribeType = () => void;
type SubscribeCallbackType = () => void;
type ParametersCallbackType = (res: string) => void;

interface ExternalApiInterfaceType {
    isUserLogin(): void;
    setIsLoginDisabled(isLoginDisabled: boolean): void;
    logoutDontMoveToLoginPage(isLogoutDontMoveToLoginPage: boolean): void;
    onLoginRequired(callback: SubscribeCallbackType): UnsubscribeType;
    onLogoutRequired(callback: SubscribeCallbackType): UnsubscribeType;
    openSignUp(): void;
    loginUser(email: string, password: string, callback: ParametersCallbackType): void;
    loginUserWithInfo(
        email: string,
        password: string,
        callback: (res: LoginUserWithInfoResponseType) => void
    ): Promise<void>;
    getAuthorizationInfo(callback: (res: CheckIfAuthorizedType) => void): void;
    getConnectionInfo(callback: (res: CheckIfConnectedType) => void): void;
    getMobileAppUpdateList(callback: (res: GetMobileAppListoResponseType) => void): void;
    onCameraRequired(callback: () => void | null): void;
    setCallbackOpenAppSettings(callback: () => void | null): void;
    requestPasswordReset(email: string, callback: (status: ResetPasswordType) => void): void;
    getLoginErrorTranslation(error: string): string;
}

type CheckIfAuthorizedType =
    | {
          responseType: 'ok';
          isAuthorized: boolean;
          userId: number | null;
      }
    | {
          responseType: 'error';
          message: string;
      };
type CheckIfConnectedType =
    | {
          responseType: 'ok';
      }
    | {
          responseType: 'error';
      };

type ResetPasswordType = {
    responseType: 'ok' | 'error';
    message: string;
};

type LoginUserWithInfoResponseType =
    | {
          responseType: 'ok';
          userInfo: {
              email: string;
              surname: string;
              firstName: string;
              title: string | null;
              id: number;
              pushNotificationsEnabled: boolean;
          };
      }
    | {
          responseType: 'error';
          message: string;
          reset_password_require: boolean;
          email: string;
      };

type GetMobileAppListoResponseType =
    | {
          responseType: 'ok';
          value: Array<MobileAppVersionType> | null;
      }
    | {
          responseType: 'error';
          value: null;
      }
    | {
          responseType: 'loading';
          value: null;
      };

type GetAppReviewListoResponseType =
    | {
          responseType: 'ok';
          value: Array<CasinoAppVersionModelType> | null;
      }
    | {
          responseType: 'error';
          value: null;
      }
    | {
          responseType: 'loading';
          value: null;
      };

type GetAppTranslationsResponseType =
    | {
          responseType: 'ok';
          translationsValue: Record<string, string> | null;
      }
    | {
          responseType: 'error';
          translationsValue: null;
      };

const loginUserWithInfoInner = async (
    sdkCustomer: SdkCustomer,
    email: string,
    password: string,
    loginUserCallback: (email: string, password: string, type: 'login' | 'registration') => Promise<LoginResponseType>,
    getLoginErrorTranslation: (error: string, failedLoginLimit?: number | null) => string
): Promise<LoginUserWithInfoResponseType> => {
    const loginResponse = await loginUserCallback(email, password, 'login');

    switch (loginResponse.type) {
        case 'CreateSessionResponseOk': {
            const basicData = await sdkCustomer.basicData.fetch();

            return {
                responseType: 'ok',
                userInfo: {
                    email: basicData.email ?? '',
                    surname: basicData.surname ?? '',
                    firstName: basicData.firstName ?? '',
                    title: basicData.title ?? null,
                    id: basicData.id,
                    pushNotificationsEnabled: basicData.pushNotificationsEnabled ?? false,
                },
            };
        }
        case 'CreateSessionResponseErrorAccess': {
            return {
                responseType: 'error',
                message: loginResponse.message,
                reset_password_require: loginResponse.code === 'reset_password_require',
                email: email,
            };
        }
        case 'CreateSessionResponseErrors': {
            return {
                responseType: 'error',
                message: getLoginErrorTranslation(loginResponse.error_description, loginResponse.failedLoginLimit),
                reset_password_require: false,
                email: email,
            };
        }
        default: {
            return assertNever('loginUserWithInfoInner', loginResponse);
        }
    }
};

interface MobileAppVersionType {
    app_version: string;
    force_update: boolean;
    platform: string;
    universe: string;
}
export class ExternalApi implements ExternalApiInterfaceType {
    public readonly allVersionsResource: Resource<Array<MobileAppVersionType> | null>;

    @observable public isMobileAppLogout: boolean = false;
    @observable public adjustAdID: string | null = null;
    public openUserSettingsCallback: (() => void) | null = null;
    public onCameraRequiredCallback: (() => void) | null = null;

    private readonly emitersPrivate: ExternalApiEmmiters;
    private readonly router: StarRouter;

    public constructor(
        router: StarRouter,
        public readonly loginUserCallback: (
            email: string,
            password: string,
            type: 'login' | 'registration'
        ) => Promise<LoginResponseType>,
        public readonly setVerificationEmailCallback: (email: string) => void,
        public readonly getLoginErrorTranslation: (error: string) => string,
        public readonly config: ConfigComponents,
        public readonly sdkCustomer: SdkCustomer,
        public readonly env: EnvironmentState,
        public readonly trpc: TrpcClient
    ) {
        makeObservable(this);
        this.emitersPrivate = router.externalApiEmmiters;
        this.router = router;

        this.allVersionsResource = new Resource(
            async (): Promise<Array<MobileAppVersionType> | null> => this.sdkCustomer.getVersionList()
        );
    }

    public isUserLogin(): boolean {
        return this.sdkCustomer.session.isAuthorized;
    }

    public reviewVersions(callback: (res: GetAppReviewListoResponseType) => void): void {
        try {
            const versions = this.env.casinoMobileAppVersions.get();
            if (versions.type === 'ready') {
                callback({ responseType: 'ok', value: versions.value });
            } else if (versions.type === 'loading') {
                callback({ responseType: 'loading', value: null });
            } else {
                callback({ responseType: 'error', value: null });
            }
        } catch (e) {
            callback({ responseType: 'error', value: null });
        }
    }

    public setIsLoginDisabled = (isLoginDisabled: boolean): void => {
        this.emitersPrivate.isLoginDisabled = isLoginDisabled;
    };
    public setSignupFinish = (): void => {
        this.emitersPrivate.emitEventSignupFinished();
    };
    public openLinkInModal = (link: string): void => {
        this.emitersPrivate.emitModalOpen(link);
    };

    @action public sendFirstLoginMsg = (id: number, pushNotificationsEnabled: boolean): void => {
        this.emitersPrivate.emitEventFirstLoginFinished(id, pushNotificationsEnabled);
    };

    public onFirstLoginMsgSend(callback: () => void): () => void {
        return this.emitersPrivate.emmiterFirstLoginFinished.on(callback);
    }
    public onOpenLinkModal(callback: () => void): () => void {
        return this.emitersPrivate.emmiterFirstLoginFinished.on(callback);
    }

    public setMobileAppLogout = (val: boolean): void => {
        this.isMobileAppLogout = val;
    };

    public setMobileAppNewExternalLinksHandling = (val: boolean): void => {
        if (val) {
            this.router.setExternalLinkOpen((url: string) => {
                this.openLinkInModal(url);
            });
        } else {
            this.router.setExternalLinkOpen(null);
        }
    };

    @action public setAdjustAppId = (v: string): void => {
        this.adjustAdID = v;
    };

    public logoutDontMoveToLoginPage = (isLogoutDontMoveToLoginPage: boolean): void => {
        this.emitersPrivate.isLogoutDontMoveToLoginPage = isLogoutDontMoveToLoginPage;
    };

    public onLoginRequired(callback: () => void): () => void {
        return this.emitersPrivate.emmiterLoginClick.on(callback);
    }

    public onLogoutRequired(callback: () => void): () => void {
        return this.emitersPrivate.emmiterLogoutClick.on(callback);
    }

    public onLanguageChange(callback: () => void): () => void {
        return this.emitersPrivate.emmiterLanguageSwitcherClick.on(callback);
    }

    public sendSignupSuccessMsg(callback: () => void): () => void {
        return this.emitersPrivate.emmiterSignupFinished.on(callback);
    }

    public sendOpenLinkInModal(callback: () => void): () => void {
        return this.emitersPrivate.emmiterOpenModal.on(callback);
    }

    public async getTranslations(
        language: string,
        callback: (res: GetAppTranslationsResponseType) => void
    ): Promise<void> {
        try {
            const response = await this.trpc.client.translations.getTranslations.query({ language: language });

            callback({ responseType: 'ok', translationsValue: response.translations });
        } catch (error) {
            console.error(error);
            callback({ responseType: 'error', translationsValue: null });
        }
    }

    public setAdjustAdID(addID: string, platform: string, callback: (res: string) => void): void {
        const advertisementId = JSON.stringify({ advertisementId: addID, platform: platform });
        this.setAdjustAppId(advertisementId);
        callback('Adjust app ID sent.');
    }

    public getMobileAppUpdateList(callback: (res: GetMobileAppListoResponseType) => void): void {
        try {
            const versions = this.allVersionsResource.get();
            if (versions.type === 'ready') {
                callback({ responseType: 'ok', value: versions.value });
            } else if (versions.type === 'loading') {
                callback({ responseType: 'loading', value: null });
            } else {
                callback({ responseType: 'error', value: null });
            }
        } catch (e) {
            callback({ responseType: 'error', value: null });
        }
    }

    public openSignUp(): void {
        if (!this.isUserLogin()) {
            this.router.redirectToSignUp();
        }
    }

    public loginUser(email: string, password: string, callback: (res: string) => void): void {
        this.loginUserCallback(email, password, 'login')
            .then((res) => {
                if (res.type === 'CreateSessionResponseOk') {
                    callback('');
                } else {
                    callback('incorrect login or password');
                }
            })
            .catch((error) => {
                console.error(error);
                callback('incorrect login or password');
            });
    }

    public async loginUserWithInfo(
        email: string,
        password: string,
        callback: (res: LoginUserWithInfoResponseType) => void
    ): Promise<void> {
        try {
            const response = await loginUserWithInfoInner(
                this.sdkCustomer,
                email,
                password,
                this.loginUserCallback,
                this.getLoginErrorTranslation
            );
            callback(response);
        } catch (error) {
            const errorString = new String(error).toString();

            callback({
                responseType: 'error',
                message: `An error occurred in the login method ${errorString}`,
                reset_password_require: false,
                email,
            });
        }
    }

    public verifyAccount(email: string, callback: (res: string) => void): void {
        this.setVerificationEmailCallback(email);
        this.router.redirectToVerifyAccount();
        callback('Account verified successfully');
    }

    public refreshSocket(callback: (res: string) => void): void {
        this.sdkCustomer.models.refreshSocket();
        callback('Socket has been refreshed');
    }

    public getAuthorizationInfo(callback: (res: CheckIfAuthorizedType) => void): void {
        try {
            const response = this.sdkCustomer.session.isAuthorized;
            const userId = this.sdkCustomer.session.userId;

            callback({ responseType: 'ok', isAuthorized: response, userId: userId });
        } catch (error) {
            const errorString = new String(error).toString();

            callback({
                responseType: 'error',
                message: `An error occurred in the login method ${errorString}`,
            });
        }
    }

    public getConnectionInfo(callback: (res: CheckIfConnectedType) => void): void {
        try {
            callback({ responseType: 'ok' });
        } catch (error) {
            callback({
                responseType: 'error',
            });
        }
    }

    public setCallbackOpenAppSettings(callback: () => void | null): void {
        this.openUserSettingsCallback = callback;
    }

    public onCameraRequired(callback: () => void | null): void {
        this.onCameraRequiredCallback = callback;
    }

    public requestPasswordReset = async (
        email: string,
        callback: (status: ResetPasswordType) => void
    ): Promise<void> => {
        let resp = null;
        try {
            resp = await this.sdkCustomer.requestResetPassword({
                requestBody: {
                    email,
                    sendViaSMS: false,
                    verify: false,
                },
            });

            if (resp.status === 'ok') {
                callback({
                    responseType: 'ok',
                    message: '',
                });
                return;
            }

            if (resp.reason !== null && resp.reason !== undefined) {
                switch (resp.reason) {
                    case 'suspended': {
                        callback({
                            responseType: 'error',
                            message: getErrorByCode('ERROR_ACCOUNT_SUSPENDED'),
                        });

                        return;
                    }
                    case 'blocked': {
                        callback({
                            responseType: 'error',
                            message: getErrorByCode('ERROR_ACCOUNT_BLOCKED'),
                        });

                        return;
                    }
                    case 'closed': {
                        callback({
                            responseType: 'error',
                            message: getErrorByCode('ERROR_ACCOUNT_CLOSED'),
                        });
                        return;
                    }
                    case 'self-excluded': {
                        callback({
                            responseType: 'error',
                            message: getErrorByCode('ERROR_ACCOUNT_SELF_EXCLUDED'),
                        });
                        return;
                    }
                    default: {
                        callback({
                            responseType: 'error',
                            message: getErrorByCode('ERROR_ACCOUNT_UNKNOWN_REASON'),
                        });
                        return;
                    }
                }
            }
        } catch (err) {
            console.error(err);
        }
    };
}

/*
For testing

IOS
$appState.externalApi.onLoginRequired(() => {
    console.info("dsadsa");
});
$appState.externalApi.setIsLoginDisabled(true);


Android
$appState.externalApi.onLoginRequired(() => {
    console.info("dsadsa");
});
$appState.externalApi.setIsLoginDisabled(true);
$appState.externalApi.logoutDontMoveToLoginPage(true);
*/
