import * as React from 'react';
import { Time, CounterWrapper, Number, Format } from './TimeCounter.style';
import { CounterState } from './TimeCounter.state';
import { observer } from 'src/utils/mobx-react';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    isSidebar?: boolean;
    eventId: EventId;
}

export const TimeCounter = observer('TimeCounter', ({ eventId, isSidebar }: PropsType) => {
    const [counterState] = React.useState(() => new CounterState(eventId));

    const { day, hour, min, sec, isEventStarted } = counterState;

    if (isEventStarted) {
        return (
            <CounterWrapper>
                <Format>Event started</Format>
            </CounterWrapper>
        );
    }

    return (
        <CounterWrapper>
            {day === null ? null : (
                <Time>
                    <Number isSidebar={isSidebar}>{day}</Number>
                    <Format isSidebar={isSidebar}>d</Format>
                </Time>
            )}

            {hour === null ? null : (
                <Time>
                    <Number isSidebar={isSidebar}>{hour}</Number>
                    <Format isSidebar={isSidebar}>h</Format>
                </Time>
            )}

            {min === null ? null : (
                <Time>
                    <Number isSidebar={isSidebar}>{min}</Number>
                    <Format isSidebar={isSidebar}>m</Format>
                </Time>
            )}

            {sec === null ? null : (
                <Time>
                    <Number isSidebar={isSidebar}>{sec}</Number>
                    <Format isSidebar={isSidebar}>s</Format>
                </Time>
            )}
        </CounterWrapper>
    );
});
