import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import {
    NonRunnerCellMatchBet,
    PlaceCell,
    RaceSummary,
    RaceSummaryRow, SilkCell
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { getPlace, getRunner } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { EachWayExtraRunnerCell } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/eachWayExtraSymmary/EachWayExtraSymmary.style';
import { SelectionCast } from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCast';
import { ChevronIconWrapper } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventGroupHeader.style';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import { action, observable, makeObservable } from 'mobx';
import {
    EWInformation,
    MarketsTitle,
    SelectionList
} from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummerRowMarkets.style';
import { sortSelections, sortSelectionsByName } from 'src/domains/layouts/utils/sortSelections';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';

class MatchBetsHorseRacingClass {
    @observable public isOpen: boolean = true;

    @action public toggleIsOpen = (): void => {
        this.isOpen = !this.isOpen;
    };

    public constructor() {
        makeObservable(this);
    }
}

interface PropsType {
    marketId: MarketId;
    toggleMarkets?: boolean;
}

export const RaceSummerRowMarket = observer('RaceSummerRowMarket', (props: PropsType) => {
    const [state] = useState(() => new MatchBetsHorseRacingClass());
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const oddsFormat = appSportsBookState.getOddsFormat();
    const marketModel = props.marketId.getModel();
    
    const eventModel = marketModel?.eventModel;
    if (marketModel === null) {
        return null;
    }

    if (eventModel === null || eventModel === undefined) {
        return null;
    }

    const marketSortFunction = marketModel.displayOrderTag === 'by-selection-name' ? sortSelectionsByName : sortSelections;
    const marketSelections = marketSortFunction(marketModel.selections.filter(x => x.display));

    const { breakpointsState, configComponents } = appLayoutsState;

    const eachWayTitle = (): JSX.Element | null => {
        const isMobile = breakpointsState.mobile.isBiggerOrEq === false;

        if (marketModel.eachWayOffered === false || marketModel.eachWayTermsReduction === undefined || marketModel.eachWayTermsPlaces === undefined) {
            return null;
        }

        const ewTerms = `EW Terms: ${marketModel.eachWayTermsReduction}`;
        const places = `${marketModel.eachWayTermsPlaces} places`;
        if (isMobile === true) {
            return (<>
                <span>{ewTerms}</span><br />{places}
            </>);
        } else {
            return (<>
                {ewTerms} {places}
            </>);
        }
    };

    const { config } = configComponents;

    return (
        <RaceSummary>
            <MarketsTitle withIcon={props.toggleMarkets === true}
                onClick={props.toggleMarkets === true ? state.toggleIsOpen : undefined}>
                <div>
                    {props.toggleMarkets === true ?
                        <ChevronIconWrapper position={state.isOpen ? 'down' : 'right'} /> : null}
                    {marketModel.name}
                </div>
                <EWInformation>
                    {eachWayTitle()}
                </EWInformation>
            </MarketsTitle>
            <SelectionList className='race-summary__list'>
                {state.isOpen === false ? null :
                    marketSelections.map(selection => {
                        const isNonRunner = selection.resultType === 'void';
                        const silkUrl = selection.metaDataSilkUrl;
                        return (
                            <RaceSummaryRow
                                key={selection.name}
                                isNonRunner={isNonRunner}
                            >
                                <PlaceCell>{getPlace(selection)}</PlaceCell>
                                <SilkCell>
                                    <LazyImage
                                        src={silkUrl}
                                        placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                                        alt={selection.metaDataSilk}
                                    />
                                </SilkCell>
                                <EachWayExtraRunnerCell>{getRunner(selection, false, true, true)}</EachWayExtraRunnerCell>
                                {
                                    isNonRunner ?
                                        <NonRunnerCellMatchBet>NR</NonRunnerCellMatchBet> :
                                        <SelectionCast
                                            key={selection.id}
                                            selectionId={selection.id2}
                                            title={oddsFormat === 'd' ? selection?.price?.d : selection?.price?.f}
                                            borderLeft={true}
                                            index={selection.id}
                                        />
                                }
                            </RaceSummaryRow>
                        );
                    })
                }
            </SelectionList>
        </RaceSummary>
    );
});
