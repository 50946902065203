import React, { ReactElement } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    isFreeBet: boolean,
    stake: string
}

export const BetStake = observer<PropsType>('BetStake', ({ isFreeBet, stake }): ReactElement => {

    if (isFreeBet) {
        return (
            <span data-test='bet-stake'>
                <I18n langKey='bets.list.bet.free-bet-credit' defaultText='FREE' />
            </span>
        );
    }

    return (
        <span data-test='bet-stake' title={stake}>{stake}</span>
    );

});
