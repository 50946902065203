import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { TraderChatNotificationBar } from 'src/domains/players/webview/components/Account/traderChat/components/NotificationBar.style';
import { NotificationIcons } from 'src/domains/players/webview/components/Account/traderChat/components/NotificationIcons';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';

export const ChatNightModeMessage = observer('ChatNightModeMessage', () => {
    const { appPlayersState } = useAppStateContext();
    const { isNightModeEnabled, nightModeMessage } = appPlayersState.traderChatState;

    const isNightModeActive = isNightModeEnabled === true;
    const isNightModeHasMessage = nightModeMessage !== null && nightModeMessage !== '';

    const defaultMessage = (
        <I18n
            langKey='account.trader-chat.night-mode-info'
            defaultText='We are away at the moment. Please contact support another time.'
        />
    );

    if (isNightModeActive) {
        if (isNightModeHasMessage) {
            return (
                <TraderChatNotificationBar>
                    <NotificationIcons />
                    {nightModeMessage}
                </TraderChatNotificationBar>
            );
        } else {
            return (
                <TraderChatNotificationBar>
                    <NotificationIcons />
                    {defaultMessage}
                </TraderChatNotificationBar>
            );
        }
    }
    return null;
});
