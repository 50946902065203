import React, { useEffect, useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { autorun } from 'mobx';
import { getWindowInnerWidth, getWindowInnerHeight } from 'src_common/common/mobx-utils/Services/window';
import { BetslipSelection } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSelection/BetslipSelection';
import { CustomKeyboard } from 'src/domains/sportsbook/betting/ui/betSlip/customKeyboard/CustomKeyboard';
import {
    QuickBetFrame,
    QuickBetContainer,
    QuickBetWrapper,
    SelectionWrapper,
    LoginWrapper,
    QuickBetSuccessWrapper,
    CheckMarkWrapper,
    QuickBetHeader,
    QuickBetCloseButton,
} from './QuickBet.style';
import { BetSlipButtons } from 'src/domains/sportsbook/betting/ui/betSlip/betSlipButtons/BetSlipButtons';
import { observer } from 'src/utils/mobx-react';
import { LogInToPlaceBet } from 'src/domains/sportsbook/betting/ui/betSlip/betSlipButtons/BetslipButtonsElements';
import { BasicBetSlipState } from 'src/domains/sportsbook/betting/betSlipState/BasicBetSlipState';
import { useAppStateContext } from 'src/appState/AppState';

class QuickbetState {
    private readonly basicBetSlipState: BasicBetSlipState;

    public constructor(basicBetSlipState: BasicBetSlipState) {
        this.basicBetSlipState = basicBetSlipState;
    }

    public handleCloseQuickBet = (): void => {
        this.basicBetSlipState.onCloseQuickBet();
    };
}

export const QuickBet = observer('QuickBet', () => {
    const {
        appSportsBookState: { betSlipState },
        session,
    } = useAppStateContext();
    const { basicBetSlipState, firstSelection2, betSlipSummaryState, quickBetState } = betSlipState;
    const [state] = useState(() => new QuickbetState(basicBetSlipState));
    // const { isShowQuickBet} = basicBetSlipState;
    const { isVisibleQuickBet, isShowQuickBet } = quickBetState;
    const { betReceipt } = betSlipSummaryState;

    useEffect(() => {
        const dispose = autorun(() => {
            const { isShowQuickBet, onCloseQuickBet } = betSlipState.basicBetSlipState;

            const innerWidth = getWindowInnerWidth();
            const innerHeight = getWindowInnerHeight();

            if (
                isShowQuickBet &&
                innerWidth !== null &&
                innerHeight !== null &&
                (innerWidth > innerHeight || innerWidth >= 1024)
            ) {
                onCloseQuickBet();
            }
        });
        return (): void => {
            dispose();
        };
    }, []);

    if (isVisibleQuickBet && firstSelection2 !== null) {
        return (
            <QuickBetContainer data-test='quick-bet-container'>
                <QuickBetFrame isShowQuickBet={isShowQuickBet} onClick={state.handleCloseQuickBet} />
                <QuickBetWrapper>
                    <QuickBetHeader data-test='quick-bet-header'>
                        <I18n langKey='bets.quick-bet.header' defaultText='Quick Bet' />
                        <QuickBetCloseButton onClick={state.handleCloseQuickBet} />
                    </QuickBetHeader>

                    <SelectionWrapper>
                        <BetslipSelection id={firstSelection2} />
                        {!session.isAuthorized === true ? (
                            <LoginWrapper>
                                <LogInToPlaceBet />
                            </LoginWrapper>
                        ) : null}
                    </SelectionWrapper>

                    {session.isAuthorized && <BetSlipButtons />}

                    <CustomKeyboard isQuickBet={true} />
                </QuickBetWrapper>
            </QuickBetContainer>
        );
    }

    if (betReceipt !== null && isVisibleQuickBet) {
        return (
            <QuickBetSuccessWrapper data-test='quick-bet-success-message'>
                <CheckMarkWrapper />
                <I18n langKey='bets.quick-bet.placed-successfully' defaultText='Bet placed successfully!' />
            </QuickBetSuccessWrapper>
        );
    }

    return null;
});
