import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { RaceSummary } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummary';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import {
    BtnBack,
    MeetingsBackTitle,
    MeetingsEventHeader,
    ChevronLeftIconWrapper,
    MeetingsEvent
} from 'src/domains/sportsbook/webview/components/meetingsAntePostAndSpecials/Meetings.style';
import { useAppStateContext } from 'src/appState/AppState';

interface MeetingsRaceCardTypes {
    section: 'ante-post' | 'specials';
    competitionName: string | null;
    event: EventModel;
    markets: Array<MarketModel> | null;
    isAntePost: boolean;
}

export const MeetingsRaceCard = observer('MeetingsRaceCard', ({ section, competitionName, event, markets, isAntePost }: MeetingsRaceCardTypes): JSX.Element => {
    const { appLayoutsState: { starRouter } } = useAppStateContext();

    const closeEvent = (): void => {
        if (event.sport === 'horseracing' || event.sport === 'greyhoundracing') {
            starRouter.redirectToRace(event.sport, section, null);
        }
    };

    return (
        <MeetingsEvent className='meetings-ante-post__event'>
            <MeetingsEventHeader>
                <BtnBack className='btn--back' onClick={closeEvent}>
                    <ChevronLeftIconWrapper position='left' />
                </BtnBack>

                <MeetingsBackTitle>
                    { event.name}
                    &nbsp;|&nbsp;
                    {competitionName ?? ''}
                </MeetingsBackTitle>
            </MeetingsEventHeader>
            {(markets ?? []).map((market: MarketModel) =>
                <RaceSummary
                    key={market.id}
                    marketId={market.id2}
                    isAntePost={isAntePost}
                    isSpecials={section === 'specials'}
                />
            )}
        </MeetingsEvent>
    );
});
