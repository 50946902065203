import { Resource } from 'src_common/common/mobx-utils/Resource';
import { SessionDataTypeExt } from 'src/domains/casino/types/types';
import { Session } from 'src_common/sdk/session';
import { Response200Type, openapiProvidersCustomerGetSessionRequest } from 'src/domains/casino/api/generated/providers/openapi_providers_customer_get_session';
import { openapiProvidersCustomerGetPragmaticSessionRequest } from 'src/domains/casino/api/generated/providers/openapi_providers_customer_get_pragmatic_session';
import { slugifyString } from 'src/domains/casino/utils/utils';
import { GameDataState } from 'src/domains/casino/state/AppCasino/game-modal/GameData.state';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';

export const getSessionData = (session: Session, gameDataState: GameDataState, config: ConfigState): Resource<SessionDataTypeExt> => {
    const { launchId, isDemo, mobile, isMini } = gameDataState;
    const provider = gameDataState.provider === 'SG Digital' ? 'SG' : gameDataState.provider;

    if (provider === 'Pragmatic') {

        let promise: Promise<Response200Type>;

        if (config.commonConfig.newPragmatic || gameDataState.gameType === 'bingo') {
            promise = session.call(openapiProvidersCustomerGetSessionRequest, {
                provider: slugifyString('new-pragmatic'),
                requestBody: {
                    launch_game_id: launchId,
                    demo_mode: isDemo,
                    mini_mode: isMini,
                    mobile,
                },
            });
        } else {
            promise = session.call(openapiProvidersCustomerGetPragmaticSessionRequest, {
                requestBody: {
                    launch_game_id: launchId,
                    demo_mode: isDemo,
                    is_mini: isMini,
                    platform: mobile === true ? 'MOBILE' : 'WEB',
                    technology: 'H5',
                    language: 'en',
                }
            });
        }

        return new Resource(async (): Promise<SessionDataTypeExt> => promise);
    }

    const promise = session.call(openapiProvidersCustomerGetSessionRequest, {
        provider: slugifyString(provider),
        requestBody: {
            launch_game_id: launchId,
            demo_mode: isDemo,
            mini_mode: isMini,
            mobile,
        },
    });

    return new Resource(async (): Promise<SessionDataTypeExt> => promise);
};
