import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { sortSelections, sortGreyhoundsSelections } from 'src/domains/layouts/utils/sortSelections';
import { RaceSummaryRow } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { HeadersGreyhoundRacing } from './HeadersGreyhoundRacing';
import { RaceSummaryHeader } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow.style';
import { RaceHeaderListMeta, RaceHeaderListItemMeta, RaceSummaryMeta, RaceSummaryNoHistory } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { calculateRaceStatus } from 'src/domains/sportsbook/utils/filterRacesWithFinishState';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';

interface MetaPropsType {
    marketId: MarketId;
}

const MetaGreyhound = observer('MetaGreyhound', (props: MetaPropsType) => {
    const { marketId } = props;
    const marketModel = marketId.getModel();

    if (marketModel === null) {
        return null;
    }

    return (
        <RaceHeaderListMeta>
            <RaceHeaderListItemMeta>{marketModel.name}</RaceHeaderListItemMeta>
        </RaceHeaderListMeta>
    );
});

interface PropsType {
    marketId: MarketId;
    ewTerms?: React.ReactNode;
    type?: string;
    isAntePost?: boolean;
}

export const RaceSummaryGreyhoundRacing = observer('RaceSummaryGreyhoundRacing', (props: PropsType) => {
    const { marketId, ewTerms, type, isAntePost } = props;
    const marketModel = marketId.getModel();

    if (marketModel === null) {
        return null;
    }

    const raceModel = marketModel.getEvent();

    if (raceModel === null) {
        return null;
    }

    const selections = raceModel.antePost
        ? sortSelections(marketModel.selections.filter(x => x.display))
        : sortGreyhoundsSelections(marketModel.selections.filter(x => x.display));

    const selectionWithPriceHistory = (selection: SelectionModel): boolean => selection.priceHistory.length > 0;
    const hidePriceHistoryColumn = selections.find(selectionWithPriceHistory) === undefined;

    const displayOrder = marketModel.displayOrderTag === undefined || marketModel.displayOrderTag === '-' ? 'by-place' : marketModel.displayOrderTag;
    const marketSelections = selections.filter(x => x.display);
    const itemsForView = sortSelectionsByCriteria(marketSelections, displayOrder);

    const raceStatus = calculateRaceStatus(raceModel.timeSettingsTimeline, raceModel.state);

    return (
        <RaceSummaryNoHistory isPriceHistoryHidden={hidePriceHistoryColumn}>
            {isAntePost === true ? (
                <RaceSummaryHeader>
                    <MetaGreyhound marketId={marketId} />
                    <RaceSummaryMeta data-test='race-summary-meta'>
                        {ewTerms}
                    </RaceSummaryMeta>
                </RaceSummaryHeader>
            ) : null}

            <ul className='race-summary__list'>
                <HeadersGreyhoundRacing type={type} race={raceModel} market={marketModel} isRaceInProgress={raceStatus === 'RaceStatusInProgress'} />
                {
                    itemsForView.map((selection: SelectionModel) => (
                        <RaceSummaryRow
                            key={selection.id}
                            selectionId={selection.id2}
                            type={type}
                            displayTrainer={type === 'cast' ? false : true}
                            isRaceInProgress={raceStatus === 'RaceStatusInProgress'}
                            isRaceFinished={raceStatus === 'RaceStatusFinished'}
                        />
                    ))
                }
            </ul>
        </RaceSummaryNoHistory>
    );
});
