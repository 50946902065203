import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Wrapper, Header, Paragraph, ParagraphsWrapper, ContentContainer } from './AlternativeEventDetails.style';
import { EventDetailsParticipants } from './EventDetailsParticipants';
import { useAppStateContext } from 'src/appState/AppState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    eventId: EventId | null;
}

export const AlternativeEventDetails = observer('AlternativeEventDetails', (props: PropsType) => {
    const { appSportsBookState: { alternativeEventState } } = useAppStateContext();
    const { getAlternativeEventItemById, eventId } = alternativeEventState.get(props.eventId);

    if (eventId === null) {
        return null;
    }

    const eventItem = getAlternativeEventItemById(eventId);
    const { description, overviewText, isShowOverview } = eventItem;

    if (isShowOverview === true) {
        return (
            <Wrapper>
                <Header>{overviewText === '' ? 'Overview' : overviewText}</Header>
                <ContentContainer>
                    <ParagraphsWrapper>
                        <Paragraph dangerouslySetInnerHTML={{ __html: description ?? '' }} />
                    </ParagraphsWrapper>
                    <EventDetailsParticipants eventId={eventId}/>
                </ContentContainer>
            </Wrapper>
        );
    }

    return null;
});
