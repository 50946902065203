import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { ScrollLeftButton, ScrollRightButton } from 'src/domains/layouts/shared/ScrollButtons.style';
import { withConfig } from 'src/withConfig';

interface StreamIframeWrapperRMGPropsType {
    streamIsInViewport: boolean;
}

export const SectionRaceCard = styled('div', { label: 'SectionRaceCard' })<StreamIframeWrapperRMGPropsType>`
    position: ${({ streamIsInViewport }): string => streamIsInViewport === true ? 'relative' : 'static'};
`;

export const FiltersWrapper = withConfig(theme => styled('div', { label: 'FiltersWrapper' })`
    background-color: ${theme.star.filtersColors.wrapper.bgColor};
    padding: 8px 0 8px 56px;
    position: relative;
`);

export const FiltersBackButton = styled('button', { label: 'FiltersBackButton' })`
    background-color: transparent;
    border: none;
    height: 100%;
    min-width: 48px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
`;

export const ChevronIconWrapper = withConfig(theme => styled(ChevronIcon, { label: 'ChevronIconWrapper' })`
    width: 20px;
    fill: ${theme.star.raceCardColors.iconColorChevron};
`);

export const TabsWrapper = styled('div', { label: 'TabsWrapper' })`
    position: relative;
`;

export const ScrollLeft = styled(ScrollLeftButton, { label: 'ScrollLeft' })`
    top: 12px;
    margin: 0;
`;

export const ScrollRight = styled(ScrollRightButton, { label: 'ScrollRight' })`
    top: 12px;
    margin: 0;
`;
