import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AccountSummaryLinkType } from 'src/domains/players/webview/components/Account/summaryTab/SummaryTab';
import { LinkWrapper, Arrow } from 'src/domains/players/webview/components/Account/common/accountLinks/AccountLinks.style';

const getGtmParam = (link: AccountSummaryLinkType): string | null => {
    if (link.to.account === 'static') {
        return link.to.static;
    }

    return link.to.account;
};

interface PropsType {
    links: Array<AccountSummaryLinkType>;
}

export const AccountLinks = observer('AccountLinks', ({ links }: PropsType) => {

    return (
        <nav>
            {links.map((link, id: number) => {
                const gtmParam = getGtmParam(link);

                return (
                    <LinkWrapper to={link.to} key={id} routeNameForGtm={gtmParam}>
                        {link.name}
                        <Arrow position='right' />
                    </LinkWrapper>
                );})}
        </nav>
    );
});
