import { computed, makeObservable } from 'src_common/common/mobx-wrapper';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import {
    openapiProxySessionCustomerLoginRequest,
    OpenapiProxySessionCustomerLoginResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_session_customer_login';
import { openapiProxySessionPingRequest } from 'src/api_openapi/generated/openapi_proxy_session_ping';
import {
    openapiProxySessionStaffLoginRequest,
    OpenapiProxySessionStaffLoginResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_session_staff_login';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';

const JwtDataIO = t.interface({
    sub: t.string,
    nbf: t.number,
    sc: t.string,
    sn: t.string,
    ip: t.union([t.string, t.undefined, t.null]),
});

export type JwtDataType = t.TypeOf<typeof JwtDataIO>;

const isJwtData = createGuard(JwtDataIO);

export type LoginResponseType = OpenapiProxySessionCustomerLoginResponse200Type;

interface ParamsType {
    readonly websocket_api_host: string; // TODO remove and change to envVariabled
    readonly websocketUrl: string; // TODO remove and change to envVariabled
    readonly apiTimeout: number;
    readonly getInitJwt: () => string | null;
    readonly setJwt: (jwt: string) => void; //side effect
    readonly startPing: boolean;
    readonly cacheApiCall: Record<string, string>; // TODO remove and change to envVariabled
    readonly refreshingJwtFromCookie?: boolean; //TODO - to be removed in the near future
    readonly mode?: 'browser' | 'server';
}

export class JwtValue {
    private readonly setJwt: (jwt: string) => void;
    private readonly current: MobxValue<string>;

    public constructor(initValue: string, setJwt: (jwt: string) => void) {
        this.setJwt = setJwt;
        this.current = MobxValue.create({
            initValue,
        });

        setJwt(initValue);
    }

    public setValue(newJwt: string): void {
        this.current.setValue(newJwt);
        this.setJwt(newJwt);
    }

    public get(): string {
        return this.current.getValue();
    }
}

type ApiResponseType<R> =
    | {
          status: 200;
          body: R;
      }
    | {
          status: 400;
          body: string;
      }
    | {
          status: 401;
          body: string;
      }
    | {
          status: 500;
          body: unknown;
      };

interface SessionApiType {
    call<P, R>(
        method: (
            api_url: string,
            api_timeout: number,
            backendToken: string,
            params: P,
            extraHeaders: Record<string, string>
        ) => Promise<ApiResponseType<R>>,
        params: P,
        extraHeaders?: Record<string, string>
    ): Promise<R>;

    ping(): Promise<void>;
}

class SessionApiFake {
    public async call<P, R>(
        _method: (
            api_url: string,
            api_timeout: number,
            backendToken: string,
            params: P,
            extraHeaders: Record<string, string>
        ) => Promise<ApiResponseType<R>>,
        _params: P
    ): Promise<R> {
        return new Promise(() => {});
    }

    public async ping(): Promise<void> {}
}

class SessionApiRest {
    private readonly apiUrl: string;
    private readonly apiTimeout: number;
    private readonly jwtValue: JwtValue;

    public constructor(apiUrl: string, apiTimeout: number, jwtValue: JwtValue) {
        this.apiUrl = apiUrl;
        this.apiTimeout = apiTimeout;
        this.jwtValue = jwtValue;
    }

    public async call<P, R>(
        method: (
            api_url: string,
            api_timeout: number,
            backendToken: string,
            params: P,
            extraHeaders: Record<string, string>
        ) => Promise<ApiResponseType<R>>,
        params: P,
        extraHeaders: Record<string, string>
    ): Promise<R> {
        const result = await method(this.apiUrl, this.apiTimeout, this.jwtValue.get(), params, extraHeaders);
        const status = result.status;

        if (result.status === 200) {
            return result.body;
        }

        if (result.status === 400) {
            console.error('SessionApi: User error', result.body);
            throw Error('SessionApi: User error');
        }

        if (result.status === 401) {
            console.error('SessionApi: 401', result.body);
            await this.ping();
        }

        if (result.status === 500) {
            console.error('SessionApi: Internal server error', result.body);
            throw Error('SessionApi: Internal server error');
        }

        throw new Error(`Unhandled response ${status}`);
    }

    public async ping(): Promise<void> {
        const response = await openapiProxySessionPingRequest(this.apiUrl, this.apiTimeout, this.jwtValue.get(), {});

        if (response.status === 200) {
            const new_jwt = response.body.new_jwt ?? null;
            if (new_jwt !== null) {
                this.jwtValue.setValue(new_jwt);
            }
        } else {
            throw Error('Response code 200 expected');
        }
    }
}

export class Session {
    public readonly websocketUrl: string;
    private readonly mode: 'browser' | 'server' | 'native';
    private readonly jwtValue: JwtValue;
    private readonly api: SessionApiType;
    public cacheApiCall: Record<string, string>;

    private constructor(
        websocketUrl: string,
        mode: 'browser' | 'server' | 'native',
        jwtValue: JwtValue,
        api: SessionApiType,
        cacheApiCall: Record<string, string>
    ) {
        this.websocketUrl = websocketUrl;
        this.mode = mode;
        this.jwtValue = jwtValue;
        this.api = api;
        this.cacheApiCall = cacheApiCall;
        makeObservable(this);
    }

    public static startFakeSession(): Session {
        const jwtValue = new JwtValue('', () => {});
        const api = new SessionApiFake();
        return new Session('', 'server', jwtValue, api, {});
    }

    private startTimers(params: ParamsType): void {
        if (params.refreshingJwtFromCookie === true) {
            setInterval(async () => {
                const jwt = params.getInitJwt();
                const prevJwt = this.jwtValue.get();

                if (jwt !== null && jwt !== prevJwt) {
                    console.info('Init JWT from cookie');
                    this.jwtValue.setValue(jwt);
                }
            }, 1000);
        }

        if (params.startPing) {
            setInterval(async () => {
                await this.api.ping();
            }, 60 * 1000);
        }
    }

    public static async start(params: ParamsType): Promise<Session> {
        const apiUrl = params.websocket_api_host;
        const apiTimeout = params.apiTimeout;
        const initJwt = params.getInitJwt();
        const setJwt = params.setJwt;

        const jwtValue = new JwtValue(initJwt ?? '', setJwt);
        const api = new SessionApiRest(apiUrl, apiTimeout, jwtValue);
        const mode = params.mode ?? 'browser';
        const session = new Session(params.websocketUrl, mode, jwtValue, api, params.cacheApiCall);

        await api.ping();

        session.startTimers(params);
        return session;
    }

    public isBrowser(): boolean {
        return this.mode === 'browser';
    }

    public async loginCustomer(
        username: string,
        password: string,
        disableGeo: boolean
    ): Promise<OpenapiProxySessionCustomerLoginResponse200Type> {
        const response = await this.api.call(openapiProxySessionCustomerLoginRequest, {
            requestBody: {
                username,
                password,
                disable_geo: disableGeo === false ? undefined : true,
            },
        });

        if (response.type === 'CreateSessionResponseOk') {
            this.jwtValue.setValue(response.access_token);
        }

        return response;
    }

    public async loginStaff(username: string, password: string): Promise<OpenapiProxySessionStaffLoginResponse200Type> {
        const response = await this.api.call(openapiProxySessionStaffLoginRequest, {
            requestBody: {
                username,
                password,
            },
        });

        if (response.type === 'CreateSessionResponseOk') {
            this.jwtValue.setValue(response.access_token);
        }

        return response;
    }

    public async logout(): Promise<void> {
        throw Error('TODO');
    }

    private isServer = (): boolean => typeof window === 'undefined';

    public async call<P, R>(
        method: (
            api_url: string,
            api_timeout: number,
            backendToken: string,
            params: P,
            extraHeaders: Record<string, string>
        ) => Promise<ApiResponseType<R>>,
        params: P,
        extraHeaders?: Record<string, string>
    ): Promise<R> {
        const key = `${method.name}-${JSON.stringify(params)}`;
        const isServer = this.isServer();

        if (isServer === false) {
            const cacheValue = this.cacheApiCall[key];

            if (cacheValue !== undefined) {
                try {
                    const response = JSON.parse(cacheValue);
                    return response;
                } catch (error) {
                    console.error('client parse error', error);
                }
            }
        }

        const result = await this.api.call(method, params, extraHeaders);

        if (isServer) {
            this.cacheApiCall[key] = JSON.stringify(result);
        }

        return result;
    }

    public get currentJwt(): string {
        return this.jwtValue.get();
    }

    @computed.struct public get decodedJwtData(): JwtDataType | null {
        if (this.currentJwt !== '') {
            const chunks = this.currentJwt.split('.');

            if (chunks.length !== 3) {
                console.error('decodedJwtData1: three parts were expected');
                return null;
            }

            const jwtData = chunks[1];

            if (jwtData === undefined) {
                console.error('decodedJwtData2: second part missing');
                return null;
            }

            try {
                const data = JSON.parse(atob(jwtData));

                if (isJwtData(data)) {
                    return data;
                }

                if (this.mode === 'browser') {
                    console.error(data);
                }
            } catch (error) {
                console.error('decodedJwtData3: json expected', { error, jwtData });
                return null;
            }

            return null;
        }

        return null;
    }

    @computed.struct public get userId(): number | null {
        if (this.decodedJwtData === null) {
            return null;
        }

        if (this.decodedJwtData.sub === '') {
            return null;
        }

        const subNum = parseInt(this.decodedJwtData.sub, 10);

        if (isNaN(subNum)) {
            console.error('userId NaN');
            return null;
        }

        return subNum;
    }

    @computed.struct public get ipUser(): string | null {
        if (this.decodedJwtData === null) {
            return null;
        }

        return this.decodedJwtData.ip ?? null;
    }

    @computed.struct public get isAuthorized(): boolean {
        return this.userId !== null;
    }

    @computed.struct public get userIpCountry(): string | null {
        const decodedData = this.decodedJwtData;
        if (decodedData instanceof Error) {
            console.error(decodedData);
            return null;
        }

        if (decodedData === null || decodedData.sc === '') {
            return null;
        }

        return decodedData.sc;
    }

    @computed.struct public get loggedTime(): number | null {
        if (this.decodedJwtData !== null) {
            return this.decodedJwtData.nbf * 1000;
        }
        return null;
    }

    @computed.struct public get loggedUserName(): string | null {
        if (this.decodedJwtData !== null) {
            return this.decodedJwtData.sn;
        }

        return null;
    }

    public clearCache = (): void => {
        this.cacheApiCall = {};
    };
}
