import { AccountBasicDataType } from 'src/domains/players/state/UsersState';
import { DateTime } from 'src_common/utils/time/time';
import { z } from 'zod';

const isParsedError = (value: string | undefined | null): boolean => {
    if (value === undefined || value === null || value.trim() === '') {
        return true;
    }

    return false;
};

interface GlobalValidationDepositToValidateResponse {
    currency: string;
    body: {
        first_name: string;
        last_name: string;
        email: string;
        country_code: string;
        phone: {
            prefix: string;
            number: string;
            country: string;
        };
        address: string;
        city: string;
        zip_code: string;
        dob: string;
    };
}

interface GlobalValidationDepositErrorResponse {
    type: 'error';
    fieldsRequired: string[];
}
export interface GlobalValidationDepositSuccessResponse {
    type: 'ok';
    data: GlobalValidationDepositToValidateResponse;
}

const billingInfoFields = z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    country_code: z.string(),
    prefix: z.string(),
    number: z.string(),
    country: z.string(),
    address: z.string(),
    city: z.string(),
    zip_code: z.string(),
    dob: z.string(),
});

export type ValidateBillingInfoFieldsSwifty = z.infer<typeof billingInfoFields>;

export const isValidBillingInfoFieldsSwifty = (value: unknown): value is ValidateBillingInfoFieldsSwifty =>
    billingInfoFields.safeParse(value).success;

export const swiftyGlobalValidationDeposit = (
    basicData: Omit<AccountBasicDataType, 'oddsFormat'> | null
): GlobalValidationDepositErrorResponse | GlobalValidationDepositSuccessResponse | undefined => {
    if (basicData !== null) {
        const dataToValid = {
            currency: basicData.currency,
            first_name: basicData.firstName,
            last_name: basicData.surname,
            email: basicData.email,
            country_code: basicData.country,
            prefix: basicData.mobilePhone?.prefix ?? null,
            number: basicData.mobilePhone?.number ?? null,
            country: basicData.mobilePhone?.country ?? null,
            address: basicData.addressLine1,
            city: basicData.city,
            zip_code: basicData.postCode,
            dob: DateTime.from(basicData.birthDate)?.format('DD/MM/YYYY'),
        };
        if (isValidBillingInfoFieldsSwifty(dataToValid)) {
            return {
                type: 'ok',
                data: {
                    currency: dataToValid.currency,
                    body: {
                        first_name: dataToValid.first_name,
                        last_name: dataToValid.last_name,
                        email: dataToValid.email,
                        phone: {
                            prefix: dataToValid.prefix,
                            number: dataToValid.number,
                            country: dataToValid.country,
                        },
                        country_code: dataToValid.country_code,
                        address: dataToValid.address,
                        city: dataToValid.city,
                        zip_code: dataToValid.zip_code,
                        dob: dataToValid.dob,
                    },
                },
            };
        }

        const fieldsRequired: Array<string> = [];
        if (isParsedError(dataToValid['first_name'])) {
            fieldsRequired.push('first_name');
        }
        if (isParsedError(dataToValid['last_name'])) {
            fieldsRequired.push('last_name');
        }
        if (isParsedError(dataToValid['email'])) {
            fieldsRequired.push('email');
        }
        if (isParsedError(dataToValid['country_code'])) {
            fieldsRequired.push('country_code');
        }
        if (isParsedError(dataToValid['prefix'])) {
            fieldsRequired.push('prefix');
        }
        if (isParsedError(dataToValid['number'])) {
            fieldsRequired.push('number');
        }
        if (isParsedError(dataToValid['country'])) {
            fieldsRequired.push('country');
        }
        if (isParsedError(dataToValid['address'])) {
            fieldsRequired.push('address');
        }
        if (isParsedError(dataToValid['city'])) {
            fieldsRequired.push('city');
        }
        if (isParsedError(dataToValid['zip_code'])) {
            fieldsRequired.push('zip_code');
        }
        if (isParsedError(dataToValid['dob'])) {
            fieldsRequired.push('dob');
        }
        if (isParsedError(dataToValid['currency'])) {
            fieldsRequired.push('currency');
        }

        return {
            type: 'error',
            fieldsRequired,
        };
    }
    return undefined;
};
