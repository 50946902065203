import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { PendingWithdrawals, WithdrawProcedureWrapper } from 'src/domains/players/webview/components/Account';
import { action, observable, makeObservable } from 'mobx';
import { WithdrawFailuresType } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawJourney';
import { useAppStateContext } from 'src/appState/AppState';
import { WithdrawSwiftyGlobalProviderState } from 'src/domains/players/webview/components/WithdrawAndDeposit';
import { WithdrawSwiftyGlobalProviderProcedure } from './WithdrawSwiftyGlobalProviderProcedure';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';
import { WithdrawSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess';
import { SwiftyGlobalIframeStyle } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalJourney.style';

export type WithdrawSwiftyGlobalProviderStepsType = {
    readonly type: 'set-card';
} | {
    readonly type: 'failure-view';
    readonly failureType: WithdrawFailuresType;
} | {
    type: 'withdraw-pending';
} | {
    type: 'iframe';
    url: string;
}

export class WithdrawSwiftyGlobalProviderSteps {

    @observable.ref public step: WithdrawSwiftyGlobalProviderStepsType;

    public constructor() {
        makeObservable(this);
        this.step = {
            type: 'set-card',
        };
    }


    @action public redirectToSetCard = (): void => {
        this.step = {
            type: 'set-card',
        };
    };

    @action public redirectToIframe = (iframeUrl: string): void => {
        this.step = {
            type: 'iframe',
            url: iframeUrl
        };
    };

    @action public redirectToFailureView = (failureType: WithdrawFailuresType): void => {
        this.step = {
            type: 'failure-view',
            failureType
        };
    };

    @action public redirectToPending = (): void => {
        this.step = {
            type: 'withdraw-pending',
        };
    };
}


export const WithdrawSwiftyGlobalProvider = observer('WithdrawSwiftyGlobalProvider', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const { accountState } = appPlayersState;

    const [state] = useState(() => new WithdrawSwiftyGlobalProviderState(configComponents, accountState));

    switch (state.currentStep.type) {
        case 'set-card':
            return (
                <>
                    <WithdrawProcedureWrapper
                        data-test='withdraw-procedure-wrapper'>
                        <WithdrawSwiftyGlobalProviderProcedure state={state} />
                    </WithdrawProcedureWrapper>
                    <PendingWithdrawals />
                </>
            );
        case 'iframe':
            return <SwiftyGlobalIframeStyle src={state.currentStep.url} dataTest='swifty-global-iframe-style'/>;
        case 'failure-view':
            return <WithdrawIssue failureType={state.currentStep.failureType} />;
        case 'withdraw-pending':
            return <WithdrawSuccess />;
    }
});
