
import { observer } from 'src/utils/mobx-react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createCache from '@emotion/cache';
import { MainView } from './MainView';
import { AppState, useAppStateContext } from 'src/appState/AppState';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

class DebugPanelPortalContentState {
    public readonly myCache: EmotionCache;

    public constructor(root: HTMLElement) {

        this.myCache = createCache({
            key: 'debug-panel',
            container: root
        });
    }
}

interface DebugPanelPortalContentPropsType {
    appState: AppState;
    cssElement: HTMLElement;
    currentView: RouteViewType;
}

const DebugPanelPortalContent = observer('DebugPanelPortalContent', (props: DebugPanelPortalContentPropsType) => {
    const [ state ] = React.useState(() => new DebugPanelPortalContentState(props.cssElement));

    return (
        <CacheProvider value={state.myCache}>
            <MainView
                appState={props.appState}
                currentView={props.currentView}
            />
        </CacheProvider>
    );
});

interface SizeType {
    width: number;
    height: number;
    left: number;
    top: number;
}

interface DebugPanePropsType {
    currentView: RouteViewType | null;
}

const DebugPanelPortal = observer('DebugPanelPortal', ({ currentView }: DebugPanePropsType) => {
    const appState = useAppStateContext();
    
    const cssElement = document.createElement('div');
    const containerEl = document.createElement('div');
    let externalWindow: Window | null = null;

    let timer: NodeJS.Timer | null = null;

    if (currentView === null) {
        return null;
    }

    const formatParams = (params: Record<string, number | string>): string => {
        const out: Array<String> = [];

        for (const [key, value] of Object.entries(params)) {
            out.push(`${key}=${value}`);
        }
        return out.join(',');
    };

    const getInitSize = (): SizeType => {
        const size = appState.appLayoutsState.debugPanel.size.getValue();

        if (size === null) {
            return {
                width: 600,
                height: 400,
                left: 200,
                top: 200,
            };
        }

        return size;
    };

    const getInitSizeParams = (): string => {
        const params = getInitSize();
        const paramsRecord: Record<string, string | number> = {
            ...params
        };
        return formatParams(paramsRecord);
    };

    const unmountWindow = (): void => {
        if (externalWindow !== null) {
            externalWindow.close();
        }
        ReactDOM.unmountComponentAtNode(containerEl);
        window.removeEventListener('beforeunload', unmountWindow);

        if (timer !== null) {
            clearInterval(timer);
            timer = null;
        }
    };

    const createWindow = (): Window | null => {
        const externalWindow = window.open('', '', getInitSizeParams());

        if (externalWindow === null) {
            console.error('DebugPanel - createWindow error');
            return null;
        }

        externalWindow.document.body.appendChild(cssElement);
        externalWindow.document.body.appendChild(containerEl);
        externalWindow.document.title = 'Debug panel';

        externalWindow.addEventListener('beforeunload', () => {
            appState.appLayoutsState.debugPanel.close();
        });

        window.addEventListener('beforeunload', unmountWindow);

        timer = setInterval(() => {
            const size: SizeType = {
                left: externalWindow.screenLeft,
                top: externalWindow.screenTop,
                width: externalWindow.innerWidth,
                height: externalWindow.innerHeight,
            };
            appState.appLayoutsState.debugPanel.size.setValue(size);
        }, 1000);

        return externalWindow;
    };

    React.useEffect(() => {
        externalWindow = createWindow();

        const domJSX = (
            <DebugPanelPortalContent
                appState={appState}
                currentView={currentView}
                cssElement={cssElement}
            />
        );

        ReactDOM.render(domJSX, containerEl);
        
        return (): void => {
            unmountWindow();
        };
    }, []);


    return null;
});

export const DebugPanel = observer('DebugPanel', (props: DebugPanePropsType) => {
    const { appLayoutsState, env } = useAppStateContext();
    const { debugPanel } = appLayoutsState;

    if (props.currentView === null) {
        return null;
    }

    if (env.isBrowser && debugPanel.isVisible) {
        return (
            <DebugPanelPortal currentView={props.currentView} />
        );
    }

    return null;
});
