import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';


export const HeaderWrapper = withConfig(theme => styled('div', { label: 'HeaderWrapper' })`
    color: ${theme.star.signUp.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 48px 0 29px 0;
    text-align: center;
`);

