import React, { useState } from 'react';
import { PaginationWrapper, ScrollLeft, ScrollRight } from './Pagination.style';
import { observer } from 'src/utils/mobx-react';
import { useAsObservableSource } from 'mobx-react-lite';
import { ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';

interface PropsType {
    goLeftAvailable: boolean,
    goRightAvailable: boolean,
    onLeft: () => void,
    onRight: () => void,
}

class PaginationState {
    private readonly props: PropsType;
    public constructor(props: PropsType) {
        this.props = props;
    }
    public handleOnLeft = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        const { goLeftAvailable, onLeft } = this.props;

        if (goLeftAvailable) {
            onLeft();
        }
    };

    public handleOnRight = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        const { goRightAvailable, onRight } = this.props;

        if (goRightAvailable) {
            onRight();
        }
    };
}

export const Pagination = observer('Pagination', (props: PropsType) => {
    const propsObservable = useAsObservableSource(props);
    const [paginationState] = useState(() => new PaginationState(propsObservable));
    const { goRightAvailable, goLeftAvailable } = propsObservable;

    return (
        <PaginationWrapper>
            <ScrollLeft
                data-test='arrow-previous'
                isAvailable={goLeftAvailable}
                key='pagination-back'
                onClick={paginationState.handleOnLeft}
            >
                <ButtonChevronIcon position='left' />
            </ScrollLeft>

            <ScrollRight
                data-test='arrow-next'
                isAvailable={goRightAvailable}
                key='pagination-forward'
                onClick={paginationState.handleOnRight}
            >
                <ButtonChevronIcon position='right' />
            </ScrollRight>
        </PaginationWrapper>
    );
});
