import { LegItem } from 'src/domains/sportsbook/betting/betSlipState/LegItem';
import { CombinationItem } from 'src/domains/sportsbook/betting/betSlipState/CombinationItem';
import { AddToAllState } from 'src/domains/sportsbook/betting/betSlipState/AddToAllState';
import { BetslipSingleId } from './BetslipIdModels';

export class BetslipModelsContext {
    protected nominal: null = null;

    public constructor(
        //DI
        public readonly getLegItemById: (legId: BetslipSingleId) => LegItem,
        public readonly getCombinationItemById: (combinationId: string) => CombinationItem,
        public readonly addToAllState: AddToAllState
    ) {}
}
