import styled from '@emotion/styled';
import { ImagesType } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeEventDetailsItemState';
import { withConfig } from 'src/withConfig';

interface BannersType {
    banners: ImagesType;
}

export const PromoBannerAlternativeEvent = withConfig(theme => styled('div', { label: 'PromoBannerAlternativeEvent' })<BannersType>`
    background-image: url('${({ banners }): string => banners.mobile === '' ? 'none' : banners.mobile }');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 10px;
    height: 96px;
    margin: 0 10px;
    margin: 5px;
    cursor: pointer;

    @media ${theme.star.mediaQuery.tablet} {
        background-image: url('${({ banners }): string => banners.tablet === '' ? 'none' : banners.tablet}');
    }

    @media ${theme.star.mediaQuery.desktop} {
        background-image: url('${({ banners }): string => banners.tablet === '' ? 'none' : banners.tablet}');
    }

    @media ${theme.star.mediaQuery.largeDesktop}{
        background-image: url('${({ banners }): string => banners.desktop === '' ? 'none' : banners.desktop}');
    }
`);

export const PromoBannerAlternativeEventWrapper = withConfig(theme => styled('div', { label: 'PromoBannerAlternativeEventWrapper' })`
    padding: 0 10px;
    @media ${theme.star.mediaQuery.desktop} {
        padding: 0 20px;
    }
`);
