import * as React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { MessageBox } from 'src/domains/players/webview/ui/MessageBoxWrapper/MessageBox';
import { observer } from 'src/utils/mobx-react';

export const MessageBoxWrapper = observer('MessageBoxWrapper', () => {
    const { appPlayersState: { messageBox } } = useAppStateContext();

    if (messageBox.messageForView === null) {
        return null;
    }

    return (
        <MessageBox
            title={messageBox.messageForView.title}
            message={messageBox.messageForView.message}
            onClose={messageBox.onClose}
        />
    );
});
