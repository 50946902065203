/**
 * @deprecated - Please use AutoMap
 */
export class MobxMapAutoNew<K, V> {
    private data: Map<K, V>;

    public constructor(
        private readonly fnBuild: (key: K) => V
    ) {
        this.data = new Map();
    }

    public get(key: K): V {
        const value = this.data.get(key);
        if (typeof value !== 'undefined') {
            return value;
        }

        const newValue = this.fnBuild(key);
        this.data.set(key, newValue);
        return newValue;
    }

    /**
     * @deprecated - We want to del it
     */
    public delete(key: K): void {
        this.data.delete(key);
    }

    /**
     * @deprecated - Dont use it!
     */
    public getAllKeys(): Array<K> {
        return Array.from(this.data.keys());
    }
}
