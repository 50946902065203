import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    CloseStream,
    RacingStreamWrapperRMG,
    StreamButtonsWrapperRMG,
    StreamIframeWrapperRMG,
    NewStreamRMG,
} from 'src/domains/sportsbook/webview/components/stream/RMG';
import { observer } from 'src/utils/mobx-react';

interface PropsTypes {
    isNotificationsList: boolean;
    streamIsInViewport: boolean;
}

export const RacingStreamRMG = observer(
    'RacingStreamRMG',
    ({ isNotificationsList, streamIsInViewport }: PropsTypes) => {
        const { appLayoutsState, appSportsBookState } = useAppStateContext();
        const { bannersBoxState, notificationsState, starRouter } = appLayoutsState;
        const { streamingState } = appSportsBookState;

        if (streamingState.openEventId === null) {
            return null;
        }

        const streamEvent = streamingState.getModel(streamingState.openEventId);

        const isOpen = streamingState.isStreamOpen;
        const boxHeight = streamingState.boxHeightHeightRmgRestrictions;

        const renderButtons = (
            <StreamButtonsWrapperRMG isOpen={isOpen}>
                <CloseStream isOpen={isOpen} onClick={streamingState.closeStream} />
            </StreamButtonsWrapperRMG>
        );

        return (
            <RacingStreamWrapperRMG
                ref={streamingState.setStreamingWrapperRef}
                isOpen={isOpen}
                streamIsInViewport={streamIsInViewport}
                isAccountOpen={starRouter.isAccountParam}
            >
                {streamingState.isStreamFloating ? '' : renderButtons}

                <StreamIframeWrapperRMG
                    isNotificationAvailable={isNotificationsList === true ? notificationsState.notifications : []}
                    bannersHeight={bannersBoxState.boxHeight}
                    height={boxHeight}
                    isFloating={streamingState.isStreamFloating}
                    isOpen={isOpen}
                    ref={streamingState.setIframeRef}
                    streamIsInViewport={streamIsInViewport}
                >
                    {streamingState.isStreamFloating ? renderButtons : ''}
                    <NewStreamRMG
                        isStreamFloating={streamingState.isStreamFloating}
                        streamEvent={streamEvent}
                        boxHeight={boxHeight}
                        boxWidth={streamingState.boxWidth}
                        isMobile={streamingState.isMobile}
                    />
                </StreamIframeWrapperRMG>
            </RacingStreamWrapperRMG>
        );
    }
);
