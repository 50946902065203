import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

export const isArray = createGuard(t.array(t.unknown));

const FieldIO = t.interface({
    value: t.string,
    label: t.string,
    slug: t.string
});

export type FieldType = t.TypeOf<typeof FieldIO>;

const CustomSelectionIO = t.interface({
    id: t.number,
    selection_type: t.string,
    star_value: t.number,
    image: t.string,
    description: t.string,
    color: t.string,
    fields: t.array(FieldIO),
});

export type CustomSelectionType = t.TypeOf<typeof CustomSelectionIO>;

const CustomSelectionsIO = t.interface({
    market_id: t.number,
    selection_type: t.string,
    selections: t.array(CustomSelectionIO)
});

export type CustomSelectionsType = Omit<t.TypeOf<typeof CustomSelectionsIO>, 'market_id'> & {
    market_id: MarketId;
}

const isCustomSelection = createGuard(CustomSelectionsIO);

export const decodeCustomSelections = (eventId: EventId, data: unknown): Array<CustomSelectionsType> => {
    const out: Array<CustomSelectionsType> = [];
    if (isArray(data)) {
        for (const item of data) {
            if (isCustomSelection(item)) {
                out.push({
                    ...item,
                    market_id: eventId.getMarketId(item.market_id),
                });
            } else {
                console.error('Invalid event details', item);
            }
        }
    } else {
        console.error('Should be array', data);
    }
    return out;
};

const MarketIO = t.interface({
    id: t.string,
    displayOrder: t.number,
});

const StarValueIO = t.interface({
    name: t.string,
    value: t.number,
});

export type StarValueType = t.TypeOf<typeof StarValueIO>;

const MarketFilterIO = t.interface({
    slug: t.string,
    label: t.string,
    displayOrder: t.number,
    markets: t.array(MarketIO),
    description: t.string,
    starValues: t.array(StarValueIO)
});

export type MarketFilterType = Omit<t.TypeOf<typeof MarketFilterIO>, 'markets'> & {
    markets: Array<{
        id: MarketId;
        displayOrder: number;
    }>;
};

export const isMarketFilter = createGuard(MarketFilterIO);
