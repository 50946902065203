import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';

import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { PromoNotificationsModelType } from 'src/api/config/cms_new/promo_notifications/get_promo_notifications_active';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';

import { css, SerializedStyles } from '@emotion/react';



export const setRacingStreamWrapperATR = (theme: EmotionTheme, isOpen: boolean, isFloating: boolean): SerializedStyles => {

    if (isOpen) {

        if (isFloating) {

            return css`

                overflow: visible;

            `;

        }

        return css`

            max-height: 370px;

            @media ${theme.star.mediaQuery.largeDesktop} {

                max-height: 338px;

            }

        `;

    };



    return css`

        max-height: 0;

    `;

};



interface RacingStreamWrapperPropsType {

    isOpen: boolean;

    isFloating: boolean;

}



export const RacingStreamWrapperATR = withConfig(theme => styled('div', { label: 'RacingStreamWrapperATR' })<RacingStreamWrapperPropsType>`

    background-color: ${theme.star.stream.bgColor};

    left: 0;

    overflow: hidden;

    position: fixed;

    top: 0;

    width: 100%;

    z-index: ${theme.star.zIndexGlobal.stream};

    ${({ isOpen, isFloating }): SerializedStyles => setRacingStreamWrapperATR(theme, isOpen, isFloating)};

    @media ${theme.star.mediaQuery.desktop} {

        position: relative;

    }

`);



interface StreamWrapperATRPropsType {

    isOpen: boolean;

    isFloating: boolean;

    height: number;

    bannersHeight: number;

    isNotificationAvailable: null | PromoNotificationsModelType[];

}



const setStreamWrapperATR = (theme: EmotionTheme, isOpen: boolean, isFloating: boolean, bannersHeight: number, isNotificationAvailable: null | PromoNotificationsModelType[]): SerializedStyles => {

    const notificationHeight = isNotificationAvailable === null ? '0' : '53px';



    const mobilePositionY = `calc(100vh - 93px - ${bannersHeight}px - ${notificationHeight})`;

    const desktopPositionY = `calc(100vh - 252px - ${bannersHeight}px - ${notificationHeight})`;



    if (isOpen) {

        if (isFloating) {

            return css`

                left: 0;

                width: 160px;

                height: 90px;

                position: fixed;

                right: 0;

                transform: translateX(calc(-50vw + 98px)) translateY(${mobilePositionY});

                @media ${theme.star.mediaQuery.desktop} {

                    height: 180px;

                    transform: translateX(calc(-50vw + 185px)) translateY(${desktopPositionY});

                    width: 320px;

                }

            `;

        }



        return css`

            height: 100%;

        `;

    }



    return css`

        height: 0;

    `;

};



export const StreamWrapperATR = withConfig(theme => styled('div', { label: 'StreamWrapperATR' })<StreamWrapperATRPropsType>`

    margin: 0 auto;

    max-width: 600px;

    position: relative;

    transition: transform .2s ease;

    width: 100%;

    z-index: ${theme.star.zIndexGlobal.above};

    ${({ isOpen, isFloating, bannersHeight, isNotificationAvailable }): SerializedStyles => setStreamWrapperATR(theme, isOpen, isFloating, bannersHeight, isNotificationAvailable)};

`);



interface StreamButtonsWrapperATRPropsType {

    isOpen: boolean;

    provider: string | undefined;

    isFloating: boolean;

}



export const streamButtonBarDesktop = 32;



const setStreamButtonsWrapperATR = (theme: EmotionTheme, isOpen: boolean, isFloating: boolean): SerializedStyles => {

    if (isOpen) {

        if (isFloating) {

            return css`

                background-color: ${theme.star.stream.bgColor};

                height: 24px;

                position: relative;

                margin-top: -24px;

            `;

        }



        return css`

            height: ${streamButtonBarDesktop}px;

            left: 0;

            position: relative;

            right: 0;

            top: 0;

            width: 100%;

            z-index: ${theme.star.zIndexGlobal.stream};

            @media ${theme.star.mediaQuery.largeDesktop} {

                position: absolute;

                height: 0;

            }

        `;

    };



    return css``;

};



export const StreamButtonsWrapperATR = withConfig(theme => styled('div', { label: 'StreamButtonsWrapperATR' })<StreamButtonsWrapperATRPropsType>`

    ${({ isOpen, isFloating }): SerializedStyles => setStreamButtonsWrapperATR(theme, isOpen, isFloating)};

    color: ${theme.star.stream.txtColor};

`);



interface StreamButtonsPropsType {

    isOpen: boolean;

    provider: string | undefined;

    isFloating: boolean;

}



const setActionButton = (isOpen: boolean, isFloating: boolean, theme: EmotionTheme): SerializedStyles => {

    if (isOpen) {

        if (isFloating) {

            return css`

                height: 24px;

                padding: 6px;

                top: 0;

                transform: rotate(360deg);

                width: 24px;

                z-index: ${theme.star.zIndexGlobal.stream};

            `;

        }



        return css`

            height: 40px;

            padding: 12px;

            top: 0;

            width: 40px;

            z-index: ${theme.star.zIndexGlobal.stream};

        `;

    };

    

    return css``;

};



export const SwitchView = withConfig(theme => styled(ChevronIcon, { label: 'SwitchView' })<StreamButtonsPropsType>`

    ${({ isOpen, isFloating }): SerializedStyles => setActionButton(isOpen, isFloating, theme)};

    cursor: pointer;

    fill: currentcolor;

    left: 0;

    position: absolute;

`);



export const CloseStream = withConfig(theme => styled(CloseIcon, { label: 'CloseStream' })<StreamButtonsPropsType>`

    ${({ isOpen, isFloating }): SerializedStyles => setActionButton(isOpen, isFloating, theme)};

    cursor: pointer;

    fill: currentcolor;

    position: absolute;

    right: 0;

`);



export const StreamLoader = withConfig(theme => styled('div', { label: 'StreamLoader' })`

    display: flex;

    align-items: center;

    justify-content: center;

    height: 100%;

    color: ${theme.star.loader.bgColorSecondary};

`);

