import React from 'react';
import { TitleChooseBox } from './titleSectionBox/TitleSectionBox';
import { TitleSectionWrapper, LabelTextWrapper, TitleChooseBoxWrapper } from './TitleSection.style';
import { GenderTitleState } from './TitleSection.state';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsTypes {
    state: GenderTitleState;
}

export const TitleSection = observer('TitleSection', (props: PropsTypes) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { customKeyboard } = appPlayersState;
    const { state } = props;

    const labelMr = languagesState.getTranslation('signup-journey.your-details.title-section.mr-label', 'Mr');
    const labelMs = languagesState.getTranslation('signup-journey.your-details.title-section.ms-label', 'Ms');

    return (
        <TitleSectionWrapper data-test='title-section'>
            <LabelTextWrapper data-test='title-label'>
                <I18n langKey='signup-journey.your-details.title' defaultText='Title' />
            </LabelTextWrapper>

            <TitleChooseBoxWrapper data-test='title-choose-box'>
                <TitleChooseBox
                    state={state}
                    title='mr'
                    titleLabel={labelMr}
                    asideElement={customKeyboard.getAsideElement}
                    dataTest='mr-title-choose-box'
                />
                <TitleChooseBox
                    state={state}
                    title='ms'
                    titleLabel={labelMs}
                    asideElement={customKeyboard.getAsideElement}
                    dataTest='ms-title-choose-box'
                />
            </TitleChooseBoxWrapper>
            {state.titleChooseState.result.errors()?.[0] === undefined ? null : (
                <Messages
                    type='error'
                    margin-marginBottom='16px'
                    message={state.titleChooseState.result.errors()[0] ?? ''}
                />
            )}
        </TitleSectionWrapper>
    );
});
