import { observable, action, makeObservable } from 'mobx';
import { DatePartInputState } from './DatePartInput.state';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { getOffset } from 'src/domains/layouts/utils/GetOffset';
import { DateInputStatus } from './DateInputStatus';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { FormModel } from 'src_common/common/mobx-utils/Form2/FormModel';
import { validateAgeDateOfBirth } from 'src/domains/players/webview/components/ValidatorsNew';
import { DateTime } from 'src_common/utils/time/time';

export class DateInputState {
    @observable public message: string | null = null;
    @observable public refDay: HTMLInputElement | null;
    @observable public refMonth: HTMLInputElement | null;
    @observable public refYear: HTMLInputElement | null;

    public readonly day: DatePartInputState;
    public readonly month: DatePartInputState;
    public readonly year: DatePartInputState;
    public readonly dateModel: FormModel<DateTime>;
    public readonly dateInputStatus: DateInputStatus;

    public constructor(private readonly customKeyboard: CustomKeyboardState) {
        makeObservable(this);
        this.day = new DatePartInputState('');
        this.month = new DatePartInputState('');
        this.year = new DatePartInputState('');

        this.refDay = null;
        this.refMonth = null;
        this.refYear = null;

        this.dateModel = FormModel.group({
            day: this.day.inputState,
            month: this.month.inputState,
            year: this.year.inputState,
        }).map(validateAgeDateOfBirth);

        this.dateInputStatus = new DateInputStatus(this.dateModel);
    }

    @action public handleFocus = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        const headerHeight = 110;
        const innerWidth = getWindowInnerWidth() ?? 0;
        const getAsideElement = this.customKeyboard.getAsideElement;
        const child = event.currentTarget;

        if (innerWidth <= 768) {
            const asideElement = getAsideElement();

            if (asideElement !== null) {
                const offset = getOffset(asideElement, child);

                asideElement.scrollTo({ top: offset - headerHeight });
            }
        }
    };

    public onFocusDay = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        this.handleFocus(event);
        this.setZeroValue();
    };

    public onFocusMonth = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        this.handleFocus(event);
        this.setZeroValue();
    };

    public onFocusYear = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        this.handleFocus(event);
        this.setZeroValue();
    };

    public setInputDayRef = (node: HTMLInputElement | null): void => {
        this.refDay = node;
    };

    public setInputMonthRef = (node: HTMLInputElement | null): void => {
        this.refMonth = node;
    };

    public setInputYearRef = (node: HTMLInputElement | null): void => {
        this.refYear = node;
    };

    public setZeroValue = (): void => {
        if (this.day.inputState.value.length === 1) {
            const value: string = this.day.inputState.value;
            this.day.inputState.setValue(`0${value}`);
        }
        if (this.month.inputState.value.length === 1) {
            const value: string = this.month.inputState.value;
            this.month.inputState.setValue(`0${value}`);
        }
    };

    public onChangeDayInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currLength = event.currentTarget.value.length;
        if (currLength === 2) {
            this.refMonth?.focus();
        }
    };

    public onChangeMonthInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currLength = event.currentTarget.value.length;
        if (currLength === 2) {
            this.refYear?.focus();
        }
    };

    public onReturnMonthInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Backspace') {
            const currLength = event.currentTarget.value.length;
            if (currLength === 0) {
                this.refDay?.focus();
                event.preventDefault();
            }
        }
    };

    public onReturnYearInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Backspace') {
            const currLength = event.currentTarget.value.length;
            if (currLength === 0) {
                this.refMonth?.focus();
                event.preventDefault();
            }
        }
    };

    public setInitialValues = (birthDate: string): void => {
        const splitDate = birthDate.split('-');

        if (splitDate.length === 3 && splitDate.every((part) => part.length > 0)) {
            const [year, month, day] = splitDate;
            if (day !== undefined && month !== undefined && year !== undefined) {
                this.day.inputState.setValue(day);
                this.month.inputState.setValue(month);
                this.year.inputState.setValue(year);
            }
        }
    };

    public getErrorMessage = (): string | null => {
        const errorMessage = this.dateModel.result.errors()?.[0] ?? null;
        if (errorMessage !== null) {
            return errorMessage;
        }

        const errorMessageDay = this.day.inputState.result.errors()?.[0] ?? null;
        if (errorMessageDay !== null) {
            return errorMessageDay;
        }

        const errorMessageMonth = this.month.inputState.result.errors()?.[0] ?? null;
        if (errorMessageMonth !== null) {
            return errorMessageMonth;
        }

        const errorMessageYear = this.year.inputState.result.errors()?.[0] ?? null;
        if (errorMessageYear !== null) {
            return errorMessageYear;
        }

        return null;
    };
}
