import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface InPlayIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const InPlayIcon = observer(
    'InPlayIcon',
    ({ className, onClick }: InPlayIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12.046 20.478a7.024 7.024 0 01-7.024-7.024c0-1.785.525-2.895 1.419-4.226l4.79 4.106c.295.313.82.106.824-.325l.055-6.576a7.023 7.023 0 01-.064 14.045zm7.95-12.304l1.047-1.011a.313.313 0 00.008-.444l-1.78-1.994a.316.316 0 00-.444-.008l-1.104 1.065a9.524 9.524 0 00-3.656-1.657v-.89h.616c.21 0 .379-.13.379-.288V1.288c0-.159-.17-.288-.379-.288H9.69c-.209 0-.378.13-.378.288v1.659c0 .159.17.288.378.288h.617v.835a9.546 9.546 0 109.689 4.104z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
