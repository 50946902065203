import { range, sortBy, uniqBy } from 'lodash';
import { computed, observable, makeObservable } from 'mobx';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { PropsTypes } from './SportMeetingsList';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList.js';
import { calculateRaceStatusFromEventItem } from 'src/domains/sportsbook/utils/filterRacesWithFinishState';
import { AppSportsBookState } from 'src/domains/sportsbook/state/AppSportsBook.state';
import { DateTime } from 'src_common/utils/time/time';

export type SportType = 'horseracing' | 'greyhoundracing';

export const getEventsList = (appSportsBookState: AppSportsBookState, sport: SportType, isBuilding: boolean): EventsCollectionList | null => {
    const races = appSportsBookState.eventsCollection.getRacesForWeek(sport);
    const racesForBuilding = races.filterBySportAndEvent((_sport, event): boolean => {
        if (calculateRaceStatusFromEventItem(event) === 'RaceStatusFinished') {
            return false;
        }
        return true;
    });
    if (isBuilding) {
        return racesForBuilding;
    }

    return appSportsBookState.eventsCollection.getRacesForWeek(sport);
};

export class SportMeetingsListState {
    private readonly props: PropsTypes;
    @observable private dayNewInner: number | null;
    @observable public isOpenUkAndIreland: boolean = true;
    @observable public isOpenInternational: boolean = true;

    public constructor(props: PropsTypes, private readonly appSportsBookState: AppSportsBookState) {
        makeObservable(this);
        this.props = props;
        this.dayNewInner = null;
    }

    public setDays = (day: number): void => {
        this.dayNewInner = day;
    };

    public handleClickUkAndIreland = (): void => {
        this.isOpenUkAndIreland = !this.isOpenUkAndIreland;
    };

    public handleClickInternational = (): void => {
        this.isOpenInternational = !this.isOpenInternational;
    };

    @computed private get dayNew(): number | null {
        const days = this.getDays();

        if (this.dayNewInner === null && days !== null) {
            const firstDay = days[0];

            if (firstDay === undefined) {
                return null;
            }

            return firstDay.unixMs();
        }

        return this.dayNewInner;
    }

    @computed private get getSport(): EventsCollectionList | null {
        return getEventsList(this.appSportsBookState, this.props.sport, this.props.isBuilding);
    }


    @computed public get collectionEvents(): Array<EventModel> | null {
        const out: Array<EventModel> = [];
        const eventsList = this.getSport;
        if (eventsList === null){
            return null;
        }
        for (const id of eventsList.ids) {
            const event = this.appSportsBookState.models.getEvent(id);

            if (event !== null) {
                out.push(event);
            }
        }

        return out;
    }


    private getDays(): Array<DateTime> | null {
        if (this.getSport === null){
            return null;
        }
        const eventsIds: Array<string> = this.collectionEvents?.map(eventModel => {
            return eventModel.timeSettingsStartTime;
        }) ?? [];
        const time = sortBy(eventsIds, x => x);
        const start = DateTime.from(time[0])?.startOfDays();
        const end = DateTime.from(time[time.length - 1])?.startOfDays();
        if (start === undefined) {
            console.error('Start time error. Time', time);
            return null;
        }
        const diff = end?.diffInDays(start);

        if (diff === undefined) {
            return null;
        }

        const days = uniqBy(
            range(0, diff + 1).map(x => start.addDays(x).startOfDays()), x => x.unixMs()

        );
        return days;
    }


    private isSameDayDisplay(eventId: number, days = 0): boolean {
        const eventModel = this.appSportsBookState.models.getEvent(eventId);
        if (eventModel === null) {
            return false;
        }
        const time = DateTime.from(eventModel.timeSettingsStartTime)?.isSameDay(DateTime.current().addDays(days)) ?? false;
        return eventModel.display && time;
    }

    public hasEventsPerDay = (whatDay: number):boolean => {
        if (this.collectionEvents === null ){
            return false;
        }
        return this.collectionEvents.some((eventModel: EventModel) => {
            const marketsIds = eventModel.marketsIds;
            const firstMarketId = marketsIds[0];
            if (firstMarketId !== undefined) {
                const marketModel = this.appSportsBookState.models.getMarket(firstMarketId);
                if (marketModel !== null) {
                    return this.isSameDayDisplay(eventModel.id, whatDay);
                }
            }
        });
    };

    @computed public get filterEventsComputed(): EventModel[] | null {

        if (this.dayNew === null || this.collectionEvents === null) {
            return null;
        }

        return this.collectionEvents.filter(eventModel => {
            return DateTime.from(eventModel.timeSettingsStartTime)?.startOfDays().unixMs() === this.dayNew;
        });
    }

    @computed public get filterEventsComputedUkIreland(): EventModel[] | null {
        return this.filterEventsComputed?.filter(eventModel => eventModel.tagsCountry === 'GB' || eventModel.tagsCountry === 'IE') ?? null;
    }

    @computed public get filterEventsComputedInternational(): EventModel[] | null {
        return this.filterEventsComputed?.filter(eventModel => eventModel.tagsCountry !== 'GB' && eventModel.tagsCountry !== 'IE') ?? null;
    }
}
