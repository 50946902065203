import React from 'react';
import { SelectionWrapper } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/AlternativeMainContent.styles';
import { observer } from 'src/utils/mobx-react';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { SelectionViewModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionViewModel';
import { useAppStateContext } from 'src/appState/AppState';

interface SelectionViewPropsType {
    selection: SelectionModel;
    selectionForView: SelectionViewModel;
}

export const SelectionView = observer('SelectionView', (props: SelectionViewPropsType) => {
    const { selection, selectionForView } = props;
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();

    const legModel = betSlipState.ids.getSingleId(selection.id2, 0).getModel();

    const isSelected = (): boolean => {
        const isSp = selectionForView.displayPrice === 'SP';
        return legModel.isSelectedBet(isSp);
    };

    const handleSelect = (): void => {
        const isSp = selectionForView.displayPrice === 'SP';
        if (isSp) {
            betSlipState.legsState.betslipData.onAddSPBet(selection.id2);
        } else {
            betSlipState.legsState.betslipData.onAddSimpleBet(selection.id2);
        }
    };

    return (
        <SelectionWrapper
            disabled={selectionForView.suspended}
            isSelected={isSelected()}
            onClick={handleSelect}
        >
            {selectionForView.suspended === true ? 'SUSP' : selectionForView.displayPrice}
        </SelectionWrapper>
    );
});
