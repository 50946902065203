import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BetSlipOfferTimer } from 'src/domains/sportsbook/betting/ui/betSlip/betslipOfferTimer/BetslipOfferTimer';
import { BetslipCombination } from 'src/domains/sportsbook/betting/ui/betSlip/betslipCombination/BetslipCombination';
import { ErrorsAndWarnings } from 'src/domains/sportsbook/betting/ui/betSlip/errorAndWarningMessages/ErrorsAndWarnings';
import { BetSlipButtons } from 'src/domains/sportsbook/betting/ui/betSlip/betSlipButtons/BetSlipButtons';
import { BetslipSpecialMessage } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSpecialMessage/BetslipSpecialMessage';
import { BetslipMultiplesSection } from 'src/domains/sportsbook/betting/ui/betSlip/betslipMultiplesSection/BetslipMultiplesSection';
import { BetslipSinglesSection } from 'src/domains/sportsbook/betting/ui/betSlip/betslipSinglesSection/BetslipSinglesSection';
import { BetslipButtonsAndMessageWrapper, BetSlipContent, CombinationsList } from './BetslipMainContent.style';
import { CustomKeyboard } from 'src/domains/sportsbook/betting/ui/betSlip/customKeyboard/CustomKeyboard';
import { BetslipYouDecide } from 'src/domains/sportsbook/betting/ui/betSlip/betslipYouDecide/BetslipYouDecide';
import { useAppStateContext } from 'src/appState/AppState';

export const BetslipMainContent = observer('BetslipMainContent', () => {
    const {
        appSportsBookState: { betSlipState },
        config,
    } = useAppStateContext();
    const { combinationsCast2, combinationWithoutCast2, referralState, basicBetSlipState, rabState } = betSlipState;

    const isMiniCasino = config.casinoMiniGames;
    const { isVisibleKeyBoard, focusedSelectionId } = basicBetSlipState;
    const { referralData } = referralState;
    const combinationId = combinationWithoutCast2[0];

    return (
        <BetSlipContent
            computedHeight={betSlipState.elementRefHeight}
            isVisibleKeyboard={isVisibleKeyBoard && focusedSelectionId !== null}
            isMiniCasino={isMiniCasino}
        >
            {
                /* eslint-disable-next-line */
                referralData &&
                (referralData.status === 'offered' || referralData.status === 'offer') &&
                referralData.expiresAt !== null &&
                referralData.expiresAt !== undefined ? (
                    <BetSlipOfferTimer />
                ) : undefined
            }

            <BetslipSpecialMessage />

            <CombinationsList data-test='betslip-forecast-tricast-section'>
                {combinationsCast2.length > 0
                    ? combinationsCast2.map((combination) => (
                          <BetslipCombination key={combination.reactKey} selectionId={combination} />
                      ))
                    : null}
            </CombinationsList>

            {combinationWithoutCast2.length > 0 && combinationId !== undefined ? (
                <CombinationsList data-test='betslip-acca-bets-section'>
                    <BetslipCombination selectionId={combinationId} />
                </CombinationsList>
            ) : null}

            {rabState.activeBets2.map((bet) => {
                return <BetslipYouDecide rabSelectionId={bet} key={bet.reactKey} />;
            })}

            <>
                <BetslipSinglesSection />
                <BetslipMultiplesSection />
            </>

            <BetslipButtonsAndMessageWrapper
                computedHeight={betSlipState.elementRefHeight}
                isVisibleKeyboard={isVisibleKeyBoard && focusedSelectionId !== null}
            >
                <div ref={betSlipState.reactiveWrapper.setRef}>
                    <ErrorsAndWarnings />
                    <BetSlipButtons />
                </div>
            </BetslipButtonsAndMessageWrapper>

            <CustomKeyboard isQuickBet={false} />
        </BetSlipContent>
    );
});
