import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ActiveHomePageCarouselType } from 'src/api/config/cms/getActiveHomePageCarousel';
import { ItemTypePromo } from './ItemTypePromo';
import { ItemTypeBetting } from './ItemTypeBetting';

interface PropsTypes {
    promo: ActiveHomePageCarouselType;
}

export const HomePageCarouselItem = observer('HomePageCarouselItem', ({ promo }: PropsTypes) => {

    if (promo.media_type === 'betting') {
        return (
            <ItemTypeBetting promo={promo} />
        );
    } else {
        return (
            <ItemTypePromo promo={promo} />
        );
    }
});
