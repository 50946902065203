import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { RadioButtonInput } from './RadioButtons.style';

interface RadioButtonItemPropsTypes<T extends string> {
    name: string;
    isDisabled: boolean;
    isChecked: boolean;
    value: T;
    onChange: (newValue: T) => void;
}

export const SingleRadioButton = observer('SingleRadioButton', <T extends string>(props: RadioButtonItemPropsTypes<T>) => {

    const { value, isDisabled, isChecked } = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        props.onChange(props.value);
    };

    return (
        <RadioButtonInput
            type='radio'
            value={value}
            disabled={isDisabled}
            checked={isChecked}
            onChange={handleChange}
        />
    );
});
