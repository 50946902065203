import { SocketController } from 'src_common/common/mobx-utils/Socket/SocketController';
import { SocketMap } from 'src_common/common/mobx-utils/Socket/SocketMap';
import { RealTimeLobbyGameModel } from './RealTimeLobbyGameModel';
import { RealTimeLobbyGameType, isRealTimeLobbyGameModel } from './RealTimeLobbyGameDetailsModels';
import { UniverseType } from 'src_common/common/universe';
import { assertNever } from 'src_common/common/assertNever';

type MessageTo = {
    game_id: number;
    message_type: 'Subscribe' | 'Unsubscribe';
    universe: UniverseType;
};

const createMessageSubscriptions = (universe: UniverseType, gameId: number): MessageTo => ({
    game_id: gameId,
    message_type: 'Subscribe',
    universe,
});

const createMessageUnsubscriptions = (universe: UniverseType, gameId: number): MessageTo => ({
    game_id: gameId,
    message_type: 'Unsubscribe',
    universe,
});

export class RealTimeLobbyGameDetails {
    private readonly socketController: SocketController;
    private readonly socketMap: SocketMap<number, RealTimeLobbyGameType, RealTimeLobbyGameModel>;

    public constructor(host: string, universe: UniverseType, isBrowser: boolean) {
        this.socketController = new SocketController(
            'RealTimeLobby - GameDetails',
            host,
            isBrowser,
            10000,
            50
        );

        this.socketMap = new SocketMap(
            this.socketController,
            (gameId: number): string => {
                return JSON.stringify(createMessageSubscriptions(
                    universe,
                    gameId
                ));
            },
            (gameId: number): string => {
                return JSON.stringify(createMessageUnsubscriptions(
                    universe,
                    gameId
                ));
            },
            RealTimeLobbyGameModel.create
        );

        this.socketController.onData((data) => {
            if (data.type === 'open') {
                this.socketMap.onNewSocket(data.socket);
                return;
            }

            if (data.type === 'message') {
                const json = JSON.parse(data.message);
                if (isRealTimeLobbyGameModel(json)) {
                    this.socketMap.onMessage(json.game_id, json);
                } else {
                    console.error('socket, model RealTimeLobbyGameType: message decoding error', json);
                }

                return;
            }

            if (data.type === 'close') {
                return;
            }

            return assertNever('socketController data.type', data);
        });
    }

    public getModel(gameId: number): RealTimeLobbyGameModel | null {
        return this.socketMap.getById(gameId);
    }
}
