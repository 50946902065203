import React, { Suspense } from 'react';
import { observer } from 'src/utils/mobx-react';

import { SportType } from 'src/domains/layouts/webview/components/ScoreboardNewVersion/appState/Types';

const AppScorebord = React.lazy(() => import('src/domains/layouts/webview/components/ScoreboardNewVersion/app/App'));

interface PropsType {
    groupId: string,
    sportId: SportType,
    isProdEnv: boolean,
}

export const ScoreboardNewVersion = observer('ScoreboardNewVersion', (props: PropsType) => {
    const { groupId, sportId, isProdEnv } = props;

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <AppScorebord
                language='EN'

                sportType={sportId}
                groupId={groupId}

                isProdEnv={isProdEnv ?? true}
            />
        </Suspense>
    );
});
