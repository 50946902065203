import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    DepositSuccessWrapper,
    CheckIconWrapper,
    DepositTitle,
    SuccessDepositInfo,
    CashBalance,
    LinkDepositAgain,
    LinkWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/depositSuccess/DepositSuccess.style';

interface DepositSuccessTypes {
    balance: string | undefined;
    hideDepositSuccess: () => void;
    isSignup: boolean;
}

export const DepositSuccess = observer(
    'DepositSuccess',
    ({ balance, hideDepositSuccess, isSignup }: DepositSuccessTypes) => {
        return (
            <DepositSuccessWrapper isSignup={isSignup}>
                <CheckIconWrapper />
                <DepositTitle data-test='deposit-title'>
                    <I18n
                        langKey='account.top-up.success.title'
                        defaultText='Deposit Successful'
                    />
                </DepositTitle>
                <SuccessDepositInfo data-test='success-deposit-info'>
                    <I18n
                        langKey='account.top-up.success.info'
                        defaultText='Your new balance is'
                    />
                </SuccessDepositInfo>
                <CashBalance data-test='cash-balance'>{balance}</CashBalance>

                <LinkWrapper>
                    <LinkDepositAgain
                        isSignup={isSignup}
                        onClick={hideDepositSuccess}
                    >
                        <I18n
                            langKey='account.top-up.success.deposit-again'
                            defaultText='Deposit again'
                        />
                    </LinkDepositAgain>
                </LinkWrapper>
            </DepositSuccessWrapper>
        );
    }
);
