import { computed, makeObservable } from 'mobx';
import { StreamType } from 'src_common/common/websocket2/modelsApi/Event';
import { Resource, Result } from 'src_common/common/mobx-utils/Resource';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { streaming } from 'src_server/trpc/types';

export class StreamingModel {
    private readonly streamData: AutoMap<[string, number, boolean], Resource<streaming.StreamUrlResponseType | null>>;

    public constructor(
        private readonly eventId: number,
        private readonly models: ModelsState,
        private readonly trpcClient: TrpcClient
    ) {
        makeObservable(this);

        this.streamData = new AutoMap(
            ([provider, streamId, isMobile]) =>
                new Resource(async (): Promise<streaming.StreamUrlResponseType | null> => {
                    if (provider === 'ATR' || provider === 'SIS') {
                        const response = await this.trpcClient.client.streaming.getStreamUrls.query({ streamId });

                        const streamsNumber = response.streams?.length;

                        if (response.streams !== null && streamsNumber !== undefined) {
                            const lastStreamSource = response.streams[streamsNumber - 1];

                            return lastStreamSource ?? null;
                        }
                    }

                    return await this.trpcClient.client.streaming.getRMGStreamUrl.query({ streamId, isMobile });
                })
        );
    }

    @computed private get streamForView(): StreamType | null {
        const eventModel = this.models.getEvent(this.eventId);

        if (eventModel === null) {
            return null;
        }

        const stream = eventModel.streamList;

        for (const item of stream) {
            if (item.provider === 'RMG') {
                return item;
            }
        }

        const streamData = stream[0] ?? null;
        return streamData;
    }

    private streamResource(): Result<streaming.StreamUrlResponseType | null> | null {
        const streamData = this.streamForView;
        if (streamData === null) {
            return null;
        }

        const streamId = streamData.id;
        const provider = streamData.provider;

        const innerWidth = getWindowInnerWidth();
        const isMobile = innerWidth !== null && innerWidth < 1024;

        return this.streamData.get([provider, streamId, isMobile]).get();
    }

    public get stream(): Array<StreamType> {
        const streamForView = this.streamForView;

        if (streamForView === null) {
            return [];
        }

        return [streamForView];
    }

    @computed public get newRMGStreamMetaData(): Resource<streaming.RmgStreamMetadataResponseType> {
        return new Resource(async (): Promise<streaming.RmgStreamMetadataResponseType> => {
            const input = {
                eventId: this.eventId,
            };

            return await this.trpcClient.client.streaming.getRMGStreamMetaData.query(input);
        });
    }

    public get streamUrl(): { type: 'loading' } | { type: 'ready'; url: string | null } | null {
        const res = this.streamResource();

        if (res === null) {
            return null;
        }

        if (res.type === 'ready') {
            return {
                type: 'ready',
                url: res.value?.url ?? null,
            };
        }

        if (res.type === 'loading') {
            return { type: 'loading' };
        }

        return null;
    }

    private operatorNameArray(): Array<string> {
        return this.stream.map((provider) => provider.provider);
    }

    public get providerName(): string | undefined {
        return this.operatorNameArray().join();
    }
}
