import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { HaveAccountWrapper, HaveAccountText, HaveAccountRedirect } from './AlreadyHaveAccount.styles';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

export const AlreadyHaveAccount = observer('AlreadyHaveAccount', () => {
    const { common } = useAppStateContext();

    const { currentView, redirectToLoginHomepage, redirectToLogin } = StarRouter.get(common);

    return (
        <HaveAccountWrapper>
            <HaveAccountText data-test='have-account-text'>
                <I18n
                    langKey='signup-journey.footer.have-account'
                    defaultText='Already have an account?'
                />
            </HaveAccountText>

            <HaveAccountRedirect
                onClick={currentView?.name === 'signup' ? redirectToLoginHomepage : redirectToLogin}
                dataTest='have-account-redirect'
            >
                <I18n
                    langKey='signup-journey.footer.login'
                    defaultText='Login'
                />
            </HaveAccountRedirect>
        </HaveAccountWrapper>
    );
});
