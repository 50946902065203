import { computed, makeObservable } from 'mobx';
import { apiCommon } from 'src/api/ApiCommon';
import { MobxMap } from 'src_common/common/mobx-utils/MobxMap';
import { createGuard } from 'src_common/common/createGuard';
import * as t from 'io-ts';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { WeatherModelType } from 'src/api/config/weather/get_weather';
import { Session } from 'src_common/sdk/session';
import { openapiProxyAnonymousWeatherRequest } from 'src/api_openapi/generated/openapi_proxy_anonymous_weather';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { DateTime } from 'src_common/utils/time/time';

interface LocationType {
    latitude: string;
    longitude: string;
    is_active: boolean;
}

interface WeatherType {
    forecastWeatherText?: string;
    forecastWeatherIcon?: string;
    forecastTemperature?: string | number;
}

const LocationResponseIO = t.interface({
    id: t.number,
    is_active: t.boolean,
    lat: t.string,
    lon: t.string,
    race_name: t.string,
    race_slug: t.string,
    universe: t.string
});

const isLocationResponse = createGuard(LocationResponseIO);
type LocationResponseType = t.TypeOf<typeof LocationResponseIO>;

export class WeatherState {
    private readonly locationResource: MobxMap<string, LocationResponseType | null>;
    private readonly weatherResource: MobxMap<[string, string], WeatherModelType | null>;

    public constructor(
        private readonly session: Session,
        private readonly eventId: EventId,
    ){
        makeObservable(this);
        this.locationResource = new MobxMap((async (raceTrack: string): Promise<LocationResponseType | null> => {
            const response = await apiCommon.getWeatherLocation.run({ race_slug: raceTrack });
            if (isLocationResponse(response)) {
                return response;
            }

            console.log('error - cannot decode location');
            return null;
        }));

        this.weatherResource = new MobxMap((async ([latitude, longitude]): Promise<WeatherModelType | null> => {
            const response = await this.session.call(openapiProxyAnonymousWeatherRequest, { requestBody: { lat: latitude.toString(), lon: longitude.toString() } });
            return response;
        }));
    }

    @computed public get event(): EventModel | null {
        return this.eventId.getEventModel();
    }

    @computed private get raceTrack(): string | null {
        return this.event?.getData().feedData?.trackCode ?? null;
    }

    @computed public get getLocationDetails(): LocationType | null {
        if (this.raceTrack !== null) {
            const response = this.locationResource.get(this.raceTrack).get();

            if (response.type === 'ready') {
                const latitude = response.value?.lat;
                const longitude = response.value?.lon;
                const is_active = response.value?.is_active;

                if (latitude !== undefined && longitude !== undefined && is_active !== undefined) {
                    return {
                        latitude,
                        longitude,
                        is_active
                    };
                }
            }
        }

        return null;
    }

    @computed private get latitude(): string | undefined {
        return this.getLocationDetails?.latitude;
    }

    @computed private get longitude(): string | undefined {
        return this.getLocationDetails?.longitude;
    }

    @computed public get is_active(): boolean | undefined {
        return this.getLocationDetails?.is_active;
    }

    @computed private get raceStartTime(): number | null {
        const startTime = this.event?.timeSettingsStartTime;
        const raceStartHour = DateTime.from(startTime)?.subtractMinutes(31).startOfHours().format('HH');

        if (raceStartHour === undefined) {
            return null;
        }

        return parseInt(raceStartHour, 10);
    };

    @computed public get getWeatherDetails(): WeatherType | null {
        if (this.latitude !== undefined && this.longitude !== undefined) {
            const response = this.weatherResource.get([this.latitude, this.longitude]).get();

            if (response.type === 'ready' && response.value !== null && this.raceStartTime !== null) {
                const dayIndex = this.event?.timeMatchToday === true ? 0 : 1;
                const forecast = response.value.result?.forecast.forecastday[dayIndex]?.hour[this.raceStartTime];
                
                const forecastWeatherText = forecast?.condition.text;
                const forecastWeatherIcon = forecast?.condition.icon;
                const forecastTemperature = forecast === undefined ? '' : Math.round(forecast?.temp_c);
                
                return {
                    forecastWeatherText,
                    forecastWeatherIcon,
                    forecastTemperature,
                };
            }
        }
        return null;
    }
}
