import { BoostIcon } from 'src/domains/layouts/shared/assets/icons/BoostIcon';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import styled from '@emotion/styled'; 
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';
import { fadeIn } from 'src/domains/layouts/shared/Animation.style';


export const LifeSpanContainer = withConfig(theme => styled('div', { label: 'LifeSpanContainer' })`
    background: ${theme.star.lifeSpan.bgColor};
    padding-top: 8px;
    position: absolute;
    top: 48px;
    width: 100%;
    &::after {
        content: none;
        @media ${theme.star.mediaQuery.desktop} {
            content: '';
            width: 100%;
            height: 100vh;
            background: ${theme.star.overlay.bgColorSecondary};
            display: block;
            position: absolute;
        }
    }
    @media ${theme.star.mediaQuery.desktop} {
        animation: ${fadeIn} .2s ease forwards;
        background: ${theme.star.lifeSpan.bgColorSecondary};
        top: 56px;
        padding-top: 0;
        z-index: 2;
    }
`);

export const LifeSpanWrapper = withConfig(theme => styled('div', { label: 'LifeSpanWrapper' })`
    border-top: 1px solid ${theme.star.lifeSpan.borderColor};
    @media ${theme.star.mediaQuery.desktop} {
        border-top: none;
    }
`);

export const LifeSpanHeader = withConfig(theme => styled('div', { label: 'LifeSpanHeader' })`
    color: ${theme.star.lifeSpan.txtColor};
    display: flex;
    align-items: center;
    padding: 9px 10px 2px 20px;
    position: relative;
`);

export const LifeSpanHeaderIcon = styled(BoostIcon, { label: 'LifeSpanHeaderIcon' })`
    padding: 3px;
    margin-right: 8px;
    height: 24px;
`;

export const LifeSpanHeaderTitle = withConfig(theme => styled('span', { label: 'LifeSpanHeaderTitle' })`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const LifeSpanHeaderCloseIconWrapper = styled(CloseIcon, { label: 'LifeSpanHeaderCloseIconWrapper' })`
    cursor: pointer;
    fill: currentcolor;
    margin-left: auto;
    width: 12px;
`;

export const LifeSpanTable = withConfig(theme => styled('div', { label: 'LifeSpanTable' })`
    padding: 0 4px 16px 16px;
    @media ${theme.star.mediaQuery.desktop} {
        padding: 0 4px 16px 8px;
    }
`);

export const LifeSpanTableHeader = styled('div', { label: 'LifeSpanTableHeader' })`
    display: flex;
    padding: 3px 16px 6px 0;
`;

const renderColumnWidth = (place: string): SerializedStyles => {
    if (place === 'first') {
        return css`
            flex: 0 0 50px;
            justify-content: center;
        `;
    }

    if (place === 'second') {
        return css`
            flex: 1 1 0%;
            line-height: 1.4;
        `;
    }

    return css`
        flex: 0 0 85px;
        justify-content: flex-end;
        padding-right: 12px;
    `;
};

interface TableColumnPropsTypes {
    place: string;
}

export const LifeSpanHeaderTableColumn = withConfig(theme => styled('div', { label: 'LifeSpanHeaderTableColumn' })<TableColumnPropsTypes>`
    font-size: ${theme.star.fontSize.xSmall};
    color: ${theme.star.lifeSpan.txtColor};
    ${({ place }): SerializedStyles => renderColumnWidth(place)};
    display: flex;
    align-items: center;
`);

export const LifeSpanTableListContainer = styled('div', { label: 'LifeSpanTableListContainer' })`
    max-height: 124px;
    overflow: auto;
`; 

interface LifeSpanTableListPropsTypes {
    isScroll: boolean;
}

export const LifeSpanTableList = styled('div', { label: 'LifeSpanTableList' })<LifeSpanTableListPropsTypes>`
    padding-right: ${({ isScroll }): string => isScroll === true ? '16px' : '8px'};
`; 

export const LifeSpanTableListItem = withConfig(theme => styled('div', { label: 'LifeSpanTableListItem' })`
    background-color: rgba(255, 255, 255, 0.12);
    color: ${theme.star.lifeSpan.txtColor};
    cursor: pointer;
    display: flex;
    margin-bottom: 1px;
    padding: 5px 0;
    transition: color .2s ease;
    &:last-of-type {
        margin-bottom: 0;
    }
    &:hover {
        color: ${theme.star.lifeSpan.txtColorSecondary};
    }
`);

export const LifeSpanListTableColumn = styled(LifeSpanHeaderTableColumn, { label: 'LifeSpanListTableColumn' })`
    color: inherit;
`;

export const LifeSpanTableBoostInfo = withConfig(theme => styled('span', { label: 'LifeSpanTableBoostInfo' })`
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.small};
`);

export const LifeSpanEmptyWalletMessage = withConfig(theme => styled('div', { label: 'LifeSpanEmptyWalletMessage' })`
    font-size: ${theme.star.fontSize.xRegular};
    text-align: left;
    padding: 8px 12px 0 0;
    color: ${theme.star.lifeSpan.txtColorSecondary};
    @media ${theme.star.mediaQuery.desktop} {
        padding: 8px 12px 0 8px;
    }
`);
