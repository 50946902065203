import { MethodType, ParamsFetchType, GenerateUrlApiType, ResponseType } from 'src_common/browser/apiUtils';
import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import { CreateAccountErrorModel, CreateAccountModel, decodeCreateAccountErrorModel, decodeCreateAccountSuccessModel } from './createAccountDecode';
import { createAccountPrimary } from 'src_server/trpc/types';

export type CreateAccountResponseType =
    { responseStatus: 'success'; data: CreateAccountModel } |
    { responseStatus: 'error'; data: CreateAccountErrorModel }
    ;

const decode = (
    status: number,
    data: ResponseType
): CreateAccountResponseType => {

    if (status === 200 && data.type === 'json') {
        return {
            responseStatus: 'success',
            data: decodeCreateAccountSuccessModel(data.json),
        };
    }

    if (status === 201 && data.type === 'json') {
        return {
            responseStatus: 'success',
            data: decodeCreateAccountSuccessModel(data.json),
        };
    }

    if (status === 422 && data.type === 'json') {
        return {
            responseStatus: 'error',
            data: decodeCreateAccountErrorModel(data.json),
        };
    }

    throw new Error(`unhandled response ${status} - ${data.type}`);
};

export const createAccount = {
    browser: {
        params: (params: createAccountPrimary.CreateAccountInput): ParamsFetchType<createAccountPrimary.CreateAccountInput> => {
            return {
                type: MethodType.POST,
                url: '/api-web/user/newUser',
                body: params,
            };
        },
        decode,
    },
    express: {
        method: MethodType.POST,
        urlBrowser: '/api-web/user/newUser',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<createAccountPrimary.CreateAccountInput>): Promise<GenerateUrlApiType> => {
        return {
            url: `${params.API_URL}/accounts/${params.API_UNIVERSE}/customer/create`,
            method: MethodType.POST,
            passToBackend: true,
            body: params.req.body
        };
    },
};
