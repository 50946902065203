import Axios from 'axios';
import { observable, makeObservable, action } from 'mobx';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { timeout } from 'src_common/common/mobx-utils/timeout';

const RETRY_TIMEOUT = 3000;

const runAsync = (run: () => Promise<void>): void => {
    run().then(
        () => {},
        (err) => {
            console.error(err);
        }
    );
};

const checkImage = async (src: string): Promise<boolean> => {
    return new Promise((resolve): void => {
        const image = new Image();

        image.onload = (): void => {
            resolve(true);
        };

        image.onerror = (): void => {
            resolve(false);
        };

        image.src = src;
    });
};

const loadImage = async (src: string): Promise<string | null> => {
    if (src === '') {
        return null;
    }

    const response = await Axios({
        url: src,
        method: 'GET',
        responseType: 'blob',
    });

    if (response.status === 200) {
        const urlImgBlob = URL.createObjectURL(response.data);
        const isOk = await checkImage(urlImgBlob);

        if (isOk === false) {
            URL.revokeObjectURL(urlImgBlob);
            console.warn(`Error load image(1) ${src}`);
            return null;
        }

        return urlImgBlob;
    } else {
        console.warn(`Error load image(2) ${src}`);
        return null;
    }
};

class ImageState {
    @observable private blobInner: null | string = null;

    public constructor(src: string) {
        makeObservable(this);

        runAsync(async (): Promise<void> => {
            for (let i = 0; i < 3; i++) {
                try {
                    const blobSrc = await loadImage(src);

                    if (blobSrc !== null) {
                        this.setBlob(blobSrc);
                        return;
                    }
                } catch (err) {
                    console.warn(err);
                }

                await timeout(RETRY_TIMEOUT);
            }
        });
    }

    @action private setBlob(blobSrc: string): void {
        this.blobInner = blobSrc;
    }

    public get blob(): string | null {
        return this.blobInner;
    }
}

export class ImagePreloadState {
    private data = new MobxMapAutoNew((src: string): ImageState => new ImageState(src));

    public imageBlob(src: string): string | null {
        return this.data.get(src).blob;
    }

    public getSrc(src: string | null, placeholder: string): string {
        if (typeof src === 'string') {
            const imageToRender = this.imageBlob(src);

            if (imageToRender !== null) {
                return imageToRender;
            }
        }

        return placeholder;
    }
}
