import { observer } from 'src/utils/mobx-react';
import React, { useEffect, useRef } from 'react';
import { Spinner } from 'src/domains/layouts/shared/assets/icons/Spinner';
import { StreamingModel } from 'src/domains/sportsbook/state/streamingState/StreamingModel';
import {
    NewRMGPlayerContainer,
    StreamLoader,
} from 'src/domains/sportsbook/webview/components/stream/RMG/RacingStreamRMG.style';

interface INewStreamRMGProps {
  streamEvent: StreamingModel;
  isStreamFloating: boolean;
  boxHeight: number;
  boxWidth: number;
  isMobile: boolean;
}

export const NewStreamRMG = observer(
    'NewStreamRMG',
    ({ streamEvent, isStreamFloating, boxWidth, boxHeight, isMobile }: INewStreamRMGProps) => {
        const contentRef = useRef<HTMLDivElement | null>(null);
        const iframeRef = useRef<DocumentFragment | null>(null);
        
        const RMGMetaData = streamEvent.newRMGStreamMetaData.get();
        const isDraggable = isMobile ? 'false' : 'true';
        const playerMode = isMobile ? 'mini' : 'default';

        useEffect(() => {
            if (RMGMetaData.type === 'loading' || RMGMetaData.type === 'error') {
                return;
            }

            const data = RMGMetaData.value;

            const fragment = document.createRange().createContextualFragment(
                `<script
              src="https://player.performgroup.com/csb.js"
              data-uuid="${data.fixtureId}"
              data-outletkey="${data.outletAuthKey}"
              data-apptype="csb"
              data-env="betting"
              data-streamuuid="${data.assetId}"
              data-streamuser="${data.accountId}"
              data-rmg="true"
              data-oauthtoken="${data.accessToken}"
              data-flyaway="${isDraggable}"
              data-flyaway-draggable="${isDraggable}"
              data-width="${boxWidth}"
              data-height="${boxHeight}"
              data-left="25px"
              data-top="50px"
              data-mode="${playerMode}"
          />`,
            );

            if (contentRef.current !== null && iframeRef.current === null) {
                contentRef.current.appendChild(fragment);
                iframeRef.current = fragment;
            }
        }, [RMGMetaData.type, boxHeight, boxWidth, isStreamFloating, isDraggable, isMobile]);

        if (RMGMetaData.type === 'loading') {
            return (
                <StreamLoader>
                    <Spinner />
                </StreamLoader>
            );
        }

        if (RMGMetaData.type === 'error') {
            return null;
        }

        return RMGMetaData.type === 'ready' ? (
            <NewRMGPlayerContainer ref={contentRef} id='player-container' />
        ) : null;
    },
);
