import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { UniverseComponent } from 'src/domains/common/universes';
import { LogoLabel, LogoWrapper } from './HeaderMainLogo.style';
import { useAppStateContext } from 'src/appState/AppState';

export const HeaderMainLogo = observer('HeaderMainLogo', (): JSX.Element | null => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents: { config } } = appLayoutsState;

    return (
        <UniverseComponent>
            {(universeVersion): React.ReactElement => (
                <LogoWrapper>
                    <universeVersion.headerLink to={{ name: 'homepage' }} dataTest='LinkWrapper'>
                        <universeVersion.headerLogo />
                        <LogoLabel>{config.operatorFullName} logo</LogoLabel>
                    </universeVersion.headerLink>
                </LogoWrapper>
            )}
        </UniverseComponent>
    );
});
