import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { StaticPageWrapper, StaticPageInnerWrapper, HeaderWrapper, TitleWrapper, HeaderCloseIcon } from './CasinoBannersSidebar.style';

interface CasinoBannersSliderPropsType {
    appCasinoState: AppCasinoState;
}

export const CasinoBannersSidebar = observer('CasinoBannersSidebar', (props: CasinoBannersSliderPropsType): JSX.Element | null => {
    const { appCasinoState } = props;
    const { bannerStore, redirectState } = appCasinoState;

    const banner = bannerStore.sidebarTermsActiveBanner;

    return (
        <StaticPageWrapper>
            <HeaderWrapper>
                <TitleWrapper>Terms &amp; Conditions</TitleWrapper>
                <HeaderCloseIcon onClick={redirectState.redirectWithoutAccountParam}/>
            </HeaderWrapper>
            {banner === null ? (
                <StaticPageInnerWrapper>Something went wrong.</StaticPageInnerWrapper>
            ) : (
                <StaticPageInnerWrapper dangerouslySetInnerHTML={{ __html: banner.terms_full }} />
            )}
        </StaticPageWrapper>
    );
});
