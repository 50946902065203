import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { ListItem } from 'src/domains/sportsbook/webview/components/selectionRab/SelectionRabItem';
import {
    MoreLessGroup,
    LinkMoreLessWrapper,
} from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/outrightMarket/OutrightMarket.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionRabNestedMenuItem } from 'src/domains/sportsbook/webview/components/selectionRab/selectionRabCorrectScore/selectionRabCorrectScoreHeader/SelectionRabCorrectScoreMenuItem';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { slug } from 'src/utils/deburr';
import { MarketRabView } from 'src/domains/sportsbook/betting/state/rabState/Types';
import {
    SelectionGroupWrapper,
    SelectionRabList,
    MarketGroup,
    MarketGroupHeader,
    SelectionsGroup,
    RabHeaderWrapper,
    ChevronIconWrapper,
} from 'src/domains/sportsbook/webview/components/selectionRab/SelectionRab.style';
import {
    SelectionRabFiltersItem,
    ThreeColumnContainer,
    ThreeColumnItem,
    SelectionNestedWrapper,
} from './SelectionRabCorrectScore.style';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

interface SelectionItemType {
    name: string;
    id: string;
    home: number | undefined | null;
    away: number | undefined | null;
    isSelect: boolean;
}

interface RabMarketType {
    name: string;
    selections: Array<SelectionItemType>;
    templateType: string;
}

interface SelectionRabPropsType {
    rabId: BetslipRabId;
    rabMarket: MarketRabView;
    order: number;
}

const correctScoreMenu = ['Home Team', 'Draw', 'Away Team'];

const filterParticipantName = (
    correctScoreActive: string,
    rabId: BetslipRabId,
    rabMarket: RabMarketType
): string | null => {
    const event = rabId.getModel().eventId.getEventModel();

    if (event !== null) {
        if (correctScoreActive === 'home-team') {
            return event.homeParticipant;
        }
        if (correctScoreActive === 'draw') {
            if (rabMarket.templateType === 'WinningMargin' || rabMarket.templateType === 'HalfWinningMargin') {
                return null;
            }
            return 'Draw';
        }
        if (correctScoreActive === 'away-team') {
            return event.awayParticipant;
        }
    }

    return '';
};

const filterSelections = (
    selections: SelectionItemType[],
    correctScoreActive: string,
    moreOpen: boolean
): SelectionItemType[] => {
    const out: SelectionItemType[] = [];
    selections.map((select) => {
        const homeGoals = select.home;
        const awayGoals = select.away;
        if (homeGoals !== undefined && awayGoals !== undefined && awayGoals !== null && homeGoals !== null) {
            if (correctScoreActive === 'home-team') {
                if (homeGoals > awayGoals) {
                    out.push(select);
                    return out.sort((a, b) =>
                        a.home !== undefined && b.home !== undefined && b.home !== null && a.home !== null
                            ? a.home - b.home
                            : 0
                    );
                }
            }
            if (correctScoreActive === 'draw') {
                if (homeGoals === awayGoals) {
                    return out.push(select);
                }
            }
            if (correctScoreActive === 'away-team') {
                if (homeGoals < awayGoals) {
                    out.push(select);
                    return out.sort((a, b) =>
                        a.away !== undefined && b.away !== undefined && b.away !== null && a.away !== null
                            ? a.away - b.away
                            : 0
                    );
                }
            }
        }
    });

    if (moreOpen === true) {
        return out;
    }

    return out.slice(0, 4);
};

const changeSelectionName = (rabMarket: RabMarketType, selectionName: string): string => {
    if (rabMarket.templateType === 'WinningMargin' || rabMarket.templateType === 'HalfWinningMargin') {
        return selectionName.replace('Home', '').replace('Away', '');
    }

    return selectionName;
};

const renderColumns = (moreOpen: boolean, rabId: BetslipRabId, rabMarket: RabMarketType): JSX.Element[] => {
    return correctScoreMenu.map((name) => {
        const participantName = filterParticipantName(slug(name), rabId, rabMarket);
        return (
            <ThreeColumnItem key={name}>
                {filterSelections(rabMarket.selections, slug(name), moreOpen).map((selection) => {
                    const selectionName = changeSelectionName(rabMarket, selection.name);
                    return (
                        <ListItem
                            key={`${rabMarket.name} ${selection.name}-${name}`}
                            rabId={rabId}
                            marketName={rabMarket.name}
                            marketTemplateType={rabMarket.templateType}
                            name={selection.name}
                            viewName={selectionName}
                            isSelect={selection.isSelect}
                            participantName={participantName}
                            displayName={true}
                        />
                    );
                })}
            </ThreeColumnItem>
        );
    });
};

class State {
    @observable public collapsed: boolean = false;
    @observable public moreOpen: boolean = false;
    @observable public correctScoreActive: string = 'home-team';

    public constructor(order: number) {
        makeObservable(this);
        this.collapsed = order > 4;
    }

    public toggleCollapsed = (): void => {
        if (this.collapsed) {
            this.collapsed = false;
            this.moreOpen = false;
        } else {
            this.collapsed = true;
        }
    };

    public onClickMore = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        this.moreOpen = true;
    };

    public onClickLess = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        this.moreOpen = false;
    };

    @action public changeActiveMenu = (activeMenu: string): void => {
        this.correctScoreActive = activeMenu;
    };
}

const renderMore = (state: State): React.ReactNode => {
    return (
        <MoreLessGroup>
            <LinkMoreLessWrapper onClick={state.onClickMore}>
                <I18n langKey='events.request-a-bet-selection.more' defaultText='More' />
                <ChevronIconWrapper position='down' />
            </LinkMoreLessWrapper>
        </MoreLessGroup>
    );
};

const renderLess = (state: State): React.ReactNode => {
    return (
        <MoreLessGroup>
            <LinkMoreLessWrapper onClick={state.onClickLess}>
                <I18n langKey='events.request-a-bet-selection.less' defaultText='Less' />
                <ChevronIconWrapper position='up' />
            </LinkMoreLessWrapper>
        </MoreLessGroup>
    );
};

const renderMoreLess = (state: State): React.ReactNode => {
    const { moreOpen } = state;
    if (moreOpen) {
        return renderLess(state);
    }

    return renderMore(state);
};

export const SelectionRabCorrectScore = observer('SelectionRabCorrectScore', (props: SelectionRabPropsType) => {
    const { rabMarket, rabId, order } = props;
    const [state] = React.useState(() => new State(order));
    const { collapsed, moreOpen } = state;
    const innerWidth = getWindowInnerWidth();

    const selectionsCorrectScore = filterSelections(rabMarket.selections, state.correctScoreActive, moreOpen);
    const selectionsCorrectScoreAllItems = filterSelections(rabMarket.selections, state.correctScoreActive, true);
    const selectionsCorrectScoreMoreLess = moreOpen ? selectionsCorrectScore : selectionsCorrectScore.slice(0, 4);

    return (
        <MarketGroup data-test-rab-market-name={rabMarket.name} data-test-collapsed={collapsed}>
            <RabHeaderWrapper onClick={state.toggleCollapsed}>
                <ChevronIconWrapper position={collapsed ? 'right' : 'down'} />
                <MarketGroupHeader isCollapsed={collapsed}>{rabMarket.name}</MarketGroupHeader>
            </RabHeaderWrapper>

            {collapsed ? null : (
                <SelectionNestedWrapper>
                    {innerWidth !== null && innerWidth < 1024 ? (
                        <>
                            <SelectionRabFiltersItem>
                                {correctScoreMenu.map((element) => {
                                    return (
                                        <SelectionRabNestedMenuItem
                                            changeActiveMenu={state.changeActiveMenu}
                                            isActive={state.correctScoreActive === slug(element)}
                                            name={element}
                                            key={element}
                                        />
                                    );
                                })}
                            </SelectionRabFiltersItem>

                            <SelectionGroupWrapper>
                                <SelectionsGroup>
                                    <SelectionRabList columns='Column'>
                                        {selectionsCorrectScoreMoreLess.map((selection) => {
                                            const participantName = filterParticipantName(
                                                state.correctScoreActive,
                                                rabId,
                                                rabMarket
                                            );

                                            return (
                                                <ListItem
                                                    key={`${rabMarket.name} ${selection.name}`}
                                                    rabId={rabId}
                                                    marketName={rabMarket.name}
                                                    name={selection.name}
                                                    isSelect={selection.isSelect}
                                                    participantName={participantName}
                                                    displayName={true}
                                                />
                                            );
                                        })}
                                    </SelectionRabList>
                                </SelectionsGroup>
                            </SelectionGroupWrapper>
                        </>
                    ) : (
                        <ThreeColumnContainer>{renderColumns(moreOpen, rabId, rabMarket)}</ThreeColumnContainer>
                    )}

                    {selectionsCorrectScoreAllItems.length > 4 ? renderMoreLess(state) : null}
                </SelectionNestedWrapper>
            )}
        </MarketGroup>
    );
});
