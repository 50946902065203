import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { EventsCollectionList } from './EventsCollectionList';
import { assertNever } from 'src_common/common/assertNever';
import { EventsCollectionQueryType, EventsCollectionQueryFilterMarketType, EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { ServerTimeState } from 'src_common/common/websocket2/ServerTimeState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { translateQuery } from './translateQuery';
import { AllSports } from 'src/domains/layouts/state/allSports/AllSports';
import { computed, makeObservable } from 'mobx';
import {
    getQueryListForSport,
    getQueryListAntePost,
    getListFromSearch,
    getQueryListInPlay,
    getQueryListUpcoming,
    getQueryListNextOffRacing,
    getQueryListForEventsByTemplate,
    getQueryListNextOffRacingWithStarted,
    getQueryListForSportWithMonthRange,
    getQueryListWithCompetitions,
    getQueryListHorseracing,
    getQueryListRacingForWeek
} from './getQuery';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

const sortByDate = (a: EventListGroupEventItemType, b: EventListGroupEventItemType): number => {
    const dateA = a.startTime;
    const dateB = b.startTime;

    if (dateA < dateB) {
        return -1;
    }
    if (dateA > dateB) {
        return 1;
    }
    return 0;
};

const prepareId = (query: EventsCollectionQueryType): string => {
    return JSON.stringify(query);
};

const splitToChunks = (searchText: string): [string, Array<string>] => {
    const chunks = searchText
        .replaceAll(')', '')
        .replaceAll('(', '')
        .split(' ')
        .map(item => item.trim())
        .filter(item => item !== '')
        .filter(item => item.includes('/') === false)
        .filter(item => item.length > 2)
    ;

    const first = chunks.shift();

    if (first === undefined) {
        return ['', []];
    }

    return [first, chunks];
};

const filterAntePost = (list: EventsCollectionList): EventsCollectionList => {
    const filteredAntePost = list.filterBySportAndEvent((_sport: string, event: EventListGroupEventItemType): boolean => {
        return event.antePost === false;
    });

    return filteredAntePost;
};

const filterOnlyWithAntePost = (list: EventsCollectionList): EventsCollectionList => {
    const filteredAntePost = list.filterBySportAndEvent((_sport: string, event: EventListGroupEventItemType): boolean => {
        return event.antePost;
    });

    return filteredAntePost;
};

export class EventsCollectionState {
    private readonly sdkCustomer: SdkCustomer;
    private readonly configComponents: ConfigComponents;
    private readonly serverTime: ServerTimeState;
    private readonly modelsState: ModelsState;
    private readonly allSports: AllSports;
    private readonly lists: Map<string, EventsCollectionList>;

    public constructor(sdkCustomer: SdkCustomer, configComponents: ConfigComponents, modelsState: ModelsState, serverTime: ServerTimeState, allSports: AllSports) {
        makeObservable(this);
        this.sdkCustomer = sdkCustomer;
        this.configComponents = configComponents;
        this.serverTime = serverTime;
        this.modelsState = modelsState;
        this.allSports = allSports;
        this.lists = new Map();
    }

    public get = (queryIn: EventsCollectionQueryType): EventsCollectionList => {

        const id = prepareId(queryIn);

        const list = this.lists.get(id);

        if (list !== undefined) {
            return list;
        }

        const query = translateQuery(this.sdkCustomer, queryIn);

        const newList = new EventsCollectionList(
            () => this.modelsState.getEventQuery(query),
        );

        this.lists.set(id, newList);
        return newList;
    };

    public get listInPlay(): EventsCollectionList {
        return this.get(getQueryListInPlay());
    }

    public get listUpcoming(): EventsCollectionList {
        return this.get(getQueryListUpcoming());
    }

    public get listNextOffHorseracing(): EventsCollectionList {
        return filterAntePost(this.get(getQueryListNextOffRacing('horseracing')));
    }

    public get listNextOffGreyhoundracing(): EventsCollectionList {
        return filterAntePost(this.get(getQueryListNextOffRacing('greyhoundracing')));
    }

    public getRaces(type: 'horseracing' | 'greyhoundracing'): EventsCollectionList{
        if (type === 'horseracing') {
            return this.listNextOffHorseracing;
        }

        if (type === 'greyhoundracing') {
            return this.listNextOffGreyhoundracing;
        }

        return assertNever('getRace', type);
    }

    public listRacingForWeek(sport: 'horseracing' | 'greyhoundracing'): EventsCollectionList {
        const listWithoutAntePost = filterAntePost(this.get(getQueryListRacingForWeek(sport)));

        if (sport === 'horseracing') {
            return listWithoutAntePost
                .filterBySportAndEvent((_sport, event): boolean => {
                    const eventModel = this.modelsState.getEvent(event.id);
                    if (eventModel === null) {
                        return false;
                    }
                    return eventModel.tagsFootballSpecial !== 'yes';
                });
        }

        return listWithoutAntePost;
    }

    public getRacesForWeek(sport: 'horseracing' | 'greyhoundracing'): EventsCollectionList {
        return this.listRacingForWeek(sport);
    }

    public getByCompetitions(competitionIds: Array<number>): EventsCollectionList {
        return this.get(getQueryListWithCompetitions(competitionIds));
    }

    public getListNextOffRacingWithoutFinishState(sport: 'horseracing' | 'greyhoundracing'): EventsCollectionList {
        const filteredCollection = filterAntePost(this.get(getQueryListNextOffRacingWithStarted(sport)));
        const collection = filteredCollection.filterBySportAndEvent((_sport: string, event): boolean => event.timeline !== 'finished');
        return collection.filterBySportAndEvent((_sport, event): boolean => {
            const eventModel = this.modelsState.getEvent(event.id);
            if (eventModel === null) {
                return false;
            }
            return eventModel.tagsFootballSpecial !== 'yes';
        });
    }

    public listOfSport(sport: string, filterMarket?: EventsCollectionQueryFilterMarketType): EventsCollectionList {
        if (sport === 'horseracing' || sport === 'greyhoundracing') {
            return this.getRaces(sport);
        }

        return this.get(getQueryListForSport(sport, filterMarket));
    }

    public listOfSportWithMonthRange(sport: string, filterMarket?: EventsCollectionQueryFilterMarketType): EventsCollectionList {
        return this.get(getQueryListForSportWithMonthRange(sport, filterMarket));
    }

    public listOfEventsByTemplate(templateId: string): EventsCollectionList {
        return this.get(getQueryListForEventsByTemplate(templateId));
    }

    public listOfSportAndCompetition(sport: string, competition: number): EventsCollectionList {
        const listOfSport = this.listOfSport(sport);
        return listOfSport
            .filterByCompetition((competitionId: number): boolean => competitionId === competition);
    }

    public listAntePostRacing(sport: 'horseracing' | 'greyhoundracing'): EventsCollectionList {
        return filterOnlyWithAntePost(this.get(getQueryListAntePost(sport)));
    }

    public listHorseracingSpecials(): EventsCollectionList {
        const horseRacingSpecials = this.get(getQueryListHorseracing());

        return horseRacingSpecials.filterBySportAndEvent((_sport, event): boolean => {
            const eventModel = this.modelsState.getEvent(event.id);
            if (eventModel === null) {
                return false;
            }
            return eventModel.tagsFootballSpecial === 'yes';
        });
    }

    public listOfSearch(searchText: string): EventsCollectionList {
        const [searchTextFirst, chunks] = splitToChunks(searchText.toLocaleLowerCase());
        
        const list = this
            .get(getListFromSearch(searchTextFirst))
            .filterBySportAndEvent((_sport: string, event): boolean => {
                for (const chunkItem of chunks) {
                    if (event?.name.toLocaleLowerCase().includes(chunkItem) === false) {
                        return false;
                    }
                }

                return true;
            });

        return list;
    }

    public listInPlayOrFilteredUpcoming(): EventsCollectionList {
        if (this.listInPlay.isEmpty === true) {
            const listUpcoming = this.listUpcoming.filterBySportAndEvent((sport, event) => {
                if (sport === 'football' || sport === 'tennis' || sport === 'basketball') {
                    return this.serverTime.fromNowToNext24h.matchMs(event.startTime);
                } else {
                    return this.serverTime.fromNowToNextWeek.matchMs(event.startTime);
                }
            });
            return listUpcoming.limit(25);
        }

        return this.listInPlay;
    }

    public get listStartingSoon(): EventsCollectionList {
        const firstFiveUpcoming = this.listUpcoming.eventItems === null ? [] : this.listUpcoming.eventItems
            .sort(sortByDate)
            .map(event => event.id)
            .slice(0, 5);

        return this.listUpcoming.filterBySportAndEvent((_sport, event): boolean => {
            if (firstFiveUpcoming.includes(event.id)) {
                return true;
            }
            return false;
        });
    }

    @computed.struct public get sportsAndEventsList(): Map<string, number> {
        const sportNames = this.allSports.getAllSports() ?? [];
        const resultMap = new Map();
        for (const name of sportNames) {
            const idsLength = this.listOfSport(name)?.ids.length ?? 0;
            resultMap.set(name, idsLength);
        };
        return resultMap;
    }

    @computed public get allSportsLength(): number {
        return this.sportsAndEventsList.size;
    }


    @computed public get sportsAndEventsListAllowed(): Map<string, number> {
        const allowedSports = new Map();
        for (const [sportName, idsLength] of this.sportsAndEventsList.entries()) {
            if (idsLength > 0 || this.configComponents.config.hideEmptySports === false) {
                allowedSports.set(sportName, idsLength);
            }
        }
        return allowedSports;
    }
}


