const key = 'new-design';

const setNewDesign = (value: 'true' | 'false'): void => {
    sessionStorage.setItem(key, value);
};

const removeNewDesign = (): void => {
    sessionStorage.removeItem(key);
};

export const isNewDesign = (): boolean => {
    // tslint:disable-next-line:strict-type-predicates
    if (typeof sessionStorage === 'undefined') {
        return false;
    }

    const value = sessionStorage.getItem(key);
    return value === 'true';
};

export const initParamNewDesign = (): void => {
    try {
        const url = new URL(window.location.href);
        const value = url.searchParams.get(key);

        if (value === 'true' || value === 'false') {
            console.info(`%cDesign version => New Design`, 'font-weight: bold; color: #333; font-size: 16px;');
            setNewDesign(value);
            return;
        }

        removeNewDesign();
    } catch (error) {
        console.error(error);
    }
};
