import React from 'react';
import { RaceSummaryHorseRacing } from './raceSummarySport/RaceSummaryHorseRacing';
import { RaceSummaryGreyhoundRacing } from './raceSummarySport/RaceSummaryGreyhoundRacing';

import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { HorseRacingSortOrderType } from 'src/domains/sportsbook/webview/components/raceCard/RaceCompetition.state';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';

const ewTerms = (marketModel: MarketModel): JSX.Element | null => {
    if (marketModel.eachWayOffered) {
        const places = marketModel.eachWayTermsPlaces;
        const reduction = marketModel.eachWayTermsReduction;

        if (marketModel.templateId.includes('place-betting')) {
            return (
                <ul>
                    <li>
                        <I18n langKey='events.race-summary.each-way-terms.places' defaultText='{places} Places' params={{ places: places ?? '' }} />
                    </li>
                </ul>
            );
        } else {
            return (
                <ul>
                    <li>
                        <I18n langKey='events.race-summary.each-way-terms.label' defaultText='EW:' />
                    </li>
                    <li>{reduction}</li>
                    <li>
                        <I18n langKey='events.race-summary.each-way-terms.for' defaultText='for' />
                    </li>
                    <li>
                        <I18n langKey='events.race-summary.each-way-terms.places' defaultText='{places} Places' params={{ places: places ?? '' }} />
                    </li>
                </ul>
            );
        }
    }
    return null;
};

interface PropsType {
    marketId: MarketId;
    sortOrder?: HorseRacingSortOrderType;
    isAntePost?: boolean;
    isSpecials?: boolean;
}

export const RaceSummary = observer('RaceSummary', (props: PropsType) => {
    const { marketId, isAntePost, isSpecials, sortOrder } = props;

    const marketModel = marketId.getModel();

    if (marketModel === null) {
        return null;
    }

    const raceModel = marketModel.getEvent();
    if (raceModel === null) {
        return null;
    }

    const sport = raceModel.sport;

    const allHorseRaces = sport === 'horseracing';
    const allGreyhoundRaces = sport === 'greyhoundracing';

    if (allHorseRaces) {
        return (
            <RaceSummaryHorseRacing
                sortOrder={sortOrder}
                ewTerms={ewTerms(marketModel)}
                marketId={marketId}
                isAntePost={isAntePost}
                isSpecials={isSpecials}
            />
        );
    } else if (allGreyhoundRaces) {
        return (
            <RaceSummaryGreyhoundRacing
                ewTerms={ewTerms(marketModel)}
                marketId={marketId}
                isAntePost={isAntePost}
            />
        );
    }

    return null;
});
