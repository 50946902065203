import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    BetLegMarketName,
    BetLegSelectionName,
} from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet.style';
import { BetHistoryLegType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';
import { DateTime } from 'src_common/utils/time/time';

export const BetLegTitle = observer<{ leg: BetHistoryLegType; isSingle: boolean }>(
    'BetLegTitle',
    ({ leg, isSingle }): ReactElement | null => {
        const { appLayoutsState, appSportsBookState } = useAppStateContext();
        const { languagesState } = appLayoutsState;
        const { models } = appSportsBookState;

        if (leg.type === 'rab') {
            const selections = leg.selections ?? null;

            if (selections === null) {
                console.error('This branch should never occur (bet1)');
            } else {
                const label = selections.map((item) => `${item.selection.name} | ${item.market.name}`).join(' & ');

                return (
                    <>
                        <BetLegSelectionName
                            isSingle={isSingle}
                            data-test='event-name'
                        >
                            {leg.event.name}
                        </BetLegSelectionName>
                        <BetLegMarketName data-test='market-name'>{label}</BetLegMarketName>
                    </>
                );
            }
        } else {
            const selectionId = leg.selection?.id ?? null;
            const selectionName = leg.selection?.name ?? null;
            if (selectionName === null) {
                return null;
            }

            const marketIdNumber = leg.market?.id ?? null;
            const marketName = leg.market?.name ?? null;
            if (marketName === null) {
                return null;
            }

            if (marketIdNumber === null) {
                return null;
            }

            // eslint-disable-next-line no-restricted-globals
            const eventIdRaw = Number(leg.event.id ?? leg.event.eventId) ?? null;
            const eventId = models.id.getEventIdOption(isNaN(eventIdRaw) ? null : eventIdRaw);

            if (eventId === null) {
                return null;
            }

            const marketId = eventId.getMarketId(marketIdNumber);

            if (marketName === 'Trap Challenge') {
                const timeSettingsStartTime = eventId.getEventModel()?.timeSettingsStartTime;
                const trapChallengeCompetitionDay = DateTime.from(timeSettingsStartTime)?.format('DD MMM YYYY');

                return (
                    <>
                        <BetLegSelectionName
                            isSingle={isSingle}
                            data-test='selection-name'
                        >
                            {languagesState.showSelectionTranslation(marketId, selectionId, selectionName)}
                        </BetLegSelectionName>
                        <BetLegMarketName>
                            <span data-test='market-name'>
                                {languagesState.showMarketTranslation(marketId, marketName)}
                            </span>{' '}
                            | <span data-test='event-name'>{leg.event.name}</span>
                        </BetLegMarketName>
                        <div>{trapChallengeCompetitionDay}</div>
                    </>
                );
            } else {
                return (
                    <>
                        <BetLegSelectionName
                            isSingle={isSingle}
                            data-test='selection-name'
                        >
                            {languagesState.showSelectionTranslation(marketId, selectionId, selectionName)}
                        </BetLegSelectionName>

                        <BetLegMarketName>
                            <span data-test='market-name'>
                                {languagesState.showMarketTranslation(marketId, marketName)}
                            </span>{' '}
                            | <span data-test='event-name'>{leg.event.name}</span>
                        </BetLegMarketName>
                    </>
                );
            }
        }

        return null;
    }
);
