import React, { useState } from 'react';
import { QuickLinkTrayItem } from './QuickLinkTrayItem';
import { QuickLinkTrayWrapper } from './QuickLinkTray.style';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { QuickLinkTrayState } from './QuickLinkTray.state';

const QuickLinkTray = observer('QuickLinkTray', () => {
    const { appSportsBookState } = useAppStateContext();
    const [quickLinkTrayState] = useState(() => new QuickLinkTrayState(appSportsBookState.models));

    const { sortedLinks } = quickLinkTrayState;

    if (sortedLinks.length > 0) {
        return (
            <QuickLinkTrayWrapper data-test='quick-link-tray'>
                {sortedLinks.map(link => (
                    <QuickLinkTrayItem
                        key={link.id}
                        link={link}
                    />
                ))}
            </QuickLinkTrayWrapper>
        );
    }
    return null;
});


export const QuickLinkTrayContainer = observer('QuickLinkTrayContainer', () => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const isBiggerOrEq = breakpointsState.tablet.isBiggerOrEq;

    if (isBiggerOrEq === false) {
        return <QuickLinkTray />;
    }

    return null;
});
