import Select from 'react-select';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { carouselEventDropdown } from 'src/domains/layouts/webview/components/eventNavigation/carouselEventDropDown.style';

export const EventNavigation = withConfig(theme => styled('div', { label: 'EventNavigation' })`
    background-color: ${theme.star.sportEventNav.bgColor};
    padding-right: 8px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
`);

export const EventNavigationDropDownWrapper = withConfig(theme => styled('div', { label: 'EventNavigationDropDownWrapper' })`
    padding: 8px 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
    width: 100%;

    @media ${theme.star.mediaQuery.tablet} {
        flex-flow: row nowrap;
    }
`);

export const SelectWrapper = withConfig(theme => styled(Select, { label: 'SelectWrapper' })`
    /* External library custom drop down */
    ${carouselEventDropdown(theme)}
    width: 100%;

    @media ${theme.star.mediaQuery.tablet} {
        width: 306px;
    }
`);

export const BackButtonWrapper = styled('span', { label: 'BackButtonWrapper' })`
    height: 48px;
    margin-right: 8px;
`;

export const BackButton = withConfig(theme => styled(ChevronIcon, { label: 'BackButton' })`
    cursor: pointer;
    fill: ${theme.star.sportEventNav.txtColor};
    padding: 14px;
    width: 48px;
`);
