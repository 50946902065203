import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

interface WrapperType {
    areAllClosed: boolean;
}

export const MessageBoxContainer = withConfig(theme => styled('div', { label: 'MessageBoxContainer' })`
    align-items: center;
    background-color: ${theme.star.notifications.liveNotifications.bgColor};
    border-bottom: 1px solid ${theme.star.notifications.liveNotifications.bgColorSecondary};
    display: flex;
    height: 56px;
    justify-content: space-between;
    line-height: 1.3;
    padding: 12px 20px 12px 24px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    &:first-of-type{
        position: relative;
        z-index: 1;
    }
`);

export const Wrapper = withConfig(theme => styled('div', { label: 'Wrapper' })<WrapperType>`
    position: relative;
    ${({ areAllClosed }): string => areAllClosed === true ? '' : 'min-height: 48px'};
    height: auto;
    overflow: hidden;
    background-color: ${theme.star.notifications.liveNotifications.bgColor};
`);

export const ContentWrapper = styled('div', { label: 'ContentWrapper' })`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
`;

export const MessageContent = withConfig(theme => styled('div', { label: 'MessageContent' })`
    color: ${theme.star.notifications.liveNotifications.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.333;
    display: flex;
    align-items: center;
    height: auto;
`);

export const CloseIconWrapper = withConfig(theme => styled(CloseIcon, { label: 'CloseIconWrapper' })`
    fill: ${theme.star.notifications.liveNotifications.txtColor};
    width: 16px;
    cursor: pointer;
    margin-left: 16px;
`);

export const MessageImageWrapper = styled('div', { label: 'MessageImageWrapper' })`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-right: 8px;
`;

export const MessageImage = styled('img', { label: 'MessageImage' })`
    max-height: 32px;
`;

export const ButtonWrapper = styled(Button, { label: 'ButtonWrapper' })`
    white-space: nowrap;
    margin-right: 16px;
`;
