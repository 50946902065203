import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { SocketGameDetails } from './SocketGameDetails';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { SocketGameList } from './SocketGameList';
import { CasinoTableModel } from './CasinoTableModel';
import { RedirectState } from 'src/domains/casino/state/AppCasino/Redirect.state';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';

type ConfigType = {
    host: string;
    casinoId: string;
};

const getConfig = (config: ConfigState): ConfigType | null => {
    return config.commonConfig.socketPragmatic;
};

export class LiveCasinoSocket {
    private readonly listAvaiable: SocketGameList | null = null;
    private readonly data: MobxMapAutoNew<string, SocketGameDetails> | null = null;
    private readonly hasReportedMessageForTable: Set<string> = new Set();

    public constructor(
        public readonly config: ConfigState,
        public readonly env: EnvironmentState,
        private readonly redirectState: RedirectState,
        private readonly isBrowser: boolean
    ) {
        const socketConfig = getConfig(this.config);

        if (socketConfig !== null) {
            this.listAvaiable = new SocketGameList(socketConfig.host, socketConfig.casinoId, isBrowser);
            this.data = new MobxMapAutoNew(
                (currency: string): SocketGameDetails =>
                    new SocketGameDetails(
                        socketConfig.host,
                        socketConfig.casinoId,
                        currency,
                        this.isBrowser,
                        this.config
                    )
            );
        }
    }

    public get currency(): string {
        return this.redirectState.getCurrency();
    }

    private get socket(): SocketGameDetails | null {
        if (this.data === null) {
            return null;
        }
        return this.data.get(this.currency);
    }

    public getModel(tableId: string): CasinoTableModel | null {
        const list = this.listAvaiable?.list ?? null;

        if (list === null || this.socket === null) {
            return null;
        }

        if (list.includes(tableId) === false) {
            if (this.hasReportedMessageForTable.has(tableId) === false) {
                this.hasReportedMessageForTable.add(tableId);
                console.warn(`DGA Live Casino - not avaible information about ${tableId}`);
            }

            return null;
        }

        return this.socket.getModel(tableId);
    }
}
