import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { action, computed, makeObservable } from 'mobx';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export const SHORT_LIST_COUNT = 7;

export interface RowMarketsStateStrategy {
    eventId: EventId;
    isShownMore: boolean;
    markets: Array<MarketModel>;
}

export class RowMarketsState {
    public constructor(
        private readonly marketTypeState: RowMarketsStateStrategy,
    ) {
        makeObservable(this);
    }

    @computed public get markets(): Array<MarketModel> {
        return this.marketTypeState.markets;
    }

    @computed public get shouldShowMoreLessButton(): boolean {
        const currentEvent = this.marketTypeState.eventId.getEventModel();
        if (currentEvent === null) {
            return false;
        }
        return currentEvent.marketMatchBet.length > SHORT_LIST_COUNT;
    }

    @computed public get isShownMore(): boolean {
        return this.marketTypeState.isShownMore;
    }

    @action public showMoreToggle = (): void => {
        this.marketTypeState.isShownMore = !this.marketTypeState.isShownMore;
    };
}
