import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    RacecardSecondaryHeaderLoader,
    RacecardRowLoader,
    FirstLineRaceInfo,
    SecondLineRaceInfo,
    RacecardSummaryHeaderLoader
} from 'src/domains/layouts/shared/loaders';
import { RaceRow } from './RaceRow';

export const RaceSummaryLoader = observer('RaceSummaryLoader', () => {
    const eventRows = [];
    for (let i = 0; i < 15; i++) {
        eventRows.push(<RaceRow key={i} />);
    }
    return (
        <>
            <RacecardRowLoader>
                <FirstLineRaceInfo />
                <SecondLineRaceInfo />
                <SecondLineRaceInfo additional={true} />
            </RacecardRowLoader>
            <RacecardSecondaryHeaderLoader />
            <RacecardSummaryHeaderLoader />
            {eventRows}
        </>
    );
});
