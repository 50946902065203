import React, { Suspense, lazy } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { RacingStreamPropsTypes } from 'src/domains/sportsbook/webview/components/stream/RacingStream';

const RacingStreamLazyComponent = lazy(() =>
    import('src/domains/sportsbook/webview/components/stream/RacingStream').then(module => ({
        default: module.RacingStream,
    }))
);

export const RacingStreamLazy = ({ isNotificationsList }: RacingStreamPropsTypes): JSX.Element => (
    <Suspense fallback={<I18n langKey='layouts.loading.label' defaultText='Loading' />}>
        <RacingStreamLazyComponent isNotificationsList={isNotificationsList} />
    </Suspense>
);
