import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const ErrorPageWrapper = withConfig(theme => styled('section', { label: 'ErrorPageWrapper' })`
    align-items: center;
    background: ${theme.star.utilityPage.bgColor};
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0%;
    justify-content: center;
    padding: 0 0 48px; /* 48px -> header height */
    text-align: center;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        padding-bottom: 56px; /* 56px -> header height */
    }
`);

export const ErrorPageContent = withConfig(theme => styled('div', { label: 'ErrorPageContent' })`
    max-width: 288px;
    @media ${theme.star.mediaQuery.tablet} {
        max-width: 435px;
    }
`);

export const SpinnerWrapper = withConfig(theme => styled('div', { label: 'SpinnerWrapper' })`
    margin: auto;
    position: absolute;
    background: ${theme.star.utilityPage.bgColor};
    color: ${theme.star.loader.bgColorTertiary};
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
`);

interface ErrorHeadline {
    isDefault: boolean;
}

export const ErrorHeadline = withConfig(theme => styled('h4', { label: 'ErrorHeadline' }) <ErrorHeadline>`
    color: ${theme.star.utilityPage.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 24px auto 0;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${({ isDefault }): string => isDefault ? theme.star.fontSize.regular : theme.star.fontSize.big};
        line-height: ${({ isDefault }): number => isDefault ? 1.5 : 1.25};
    }
`);

export const ErrorInfo = withConfig(theme => styled('p', { label: 'ErrorInfo' })`
    color: ${theme.star.utilityPage.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4285;
    margin: 8px auto;
    max-width: 288px;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        max-width: 435px;
    }
`);

export const ButtonWrapper = styled(Button, { label: 'ButtonWrapper' })`
    margin: 16px 0 0;
    max-width: 224px;
    width: 100%;
`;

export const Error404MainHeadline = withConfig(theme => styled('h1', { label: 'Error404MainHeadline' })`
    color: ${theme.star.utilityPage.txtColor};
    font-size: 52px; /* special font-size for the headline */
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3845;
    margin: 26px 0 -16px;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: 80px; /* special font-size for the headline */
        line-height: 0.9;
        margin: 24px 0 0;
    }
`);

export const error404BaseCss = (): SerializedStyles => css`
    display: block;
    margin: 0 auto;
`;
