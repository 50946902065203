import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, makeObservable } from 'mobx';
import { apiCommon } from 'src/api/ApiCommon';
import { HeroWidgetModelType } from 'src/api/config/cms_new/hero_widget/get_hero_widget_active';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { sortByNumberField } from 'src_common/utils/sport/sort';

interface HeroWidgetLoading {
    type: 'loading';
}

interface HeroWidgetReady {
    type: 'ready';
    value: Array<HeroWidgetModelType>;
}

interface HeroWidgetError {
    type: 'error';
    value: string;
}

type HeroWidgetResponseType = HeroWidgetLoading | HeroWidgetReady | HeroWidgetError;

export class HeroWidgetState {
    public readonly heroWidgetResource: Resource<Array<HeroWidgetModelType>>;

    public constructor(models: ModelsState) {
        makeObservable(this);

        this.heroWidgetResource = new Resource(async (): Promise<Array<HeroWidgetModelType>> => {
            const list = await apiCommon.get_hero_widget_active.run({});

            const result = [];

            for (const item of list) {
                const eventId = models.id.getEventId(item.event_id);
                const marketId = eventId.getMarketId(item.market_id);

                result.push({
                    ...item,
                    event_id: eventId,
                    market_id: marketId
                });
            }

            return result;
        });
    }

    @computed private get heroWidgetResponse(): HeroWidgetResponseType {
        const heroWidget = this.heroWidgetResource.get();
        if (heroWidget.type === 'ready') {
            return {
                type: 'ready',
                value: heroWidget.value
            };

        }
        if (heroWidget.type === 'loading') {
            return {
                type: 'loading'
            };
        }

        return {
            type: 'error',
            value: 'Something went wrong with fethcing landing page info'
        };
    }

    @computed public get isLoading(): boolean {
        return this.heroWidgetResponse.type === 'loading';
    }

    @computed public get errorMessage(): string | null {
        if (this.heroWidgetResponse.type === 'error') {
            return this.heroWidgetResponse.value;
        }
        return null;
    }

    @computed public get heroWidgetList(): Array<HeroWidgetModelType> {
        if (this.heroWidgetResponse.type === 'ready') {
            return this.heroWidgetResponse.value;
        }
        return [];
    }

    @computed public get heroWidgetListActiveList(): Array<HeroWidgetModelType> {
        return this.heroWidgetList.filter(elem => {
            if (elem.is_active_widget) {
                const now = new Date().getTime();
                const dateFrom = new Date(elem.date_from).getTime();
                const dateTo = new Date(elem.date_to).getTime();

                if (dateFrom < now && now < dateTo) {
                    return elem;
                }
            }
        });
    }

    @computed public get heroWidgetListForView(): Array<HeroWidgetModelType> {
        return this.heroWidgetListActiveList.sort((v1: HeroWidgetModelType, v2: HeroWidgetModelType) => sortByNumberField(v1.display_order, v2.display_order));
    }

    public actualHeroWidgetInfo(pageSlug: string): HeroWidgetModelType | undefined {
        const getId = pageSlug.substring(0, pageSlug.indexOf('-'));

        return this.heroWidgetList.find(x => x.id === parseInt(getId, 10));
    }
}
