import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface ModalWrapperPropsType {
    isDepositOpened: boolean;
}

export const ModalWrapper = withConfig(theme => styled('div', { label: 'ModalWrapper' })<ModalWrapperPropsType>`
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isDepositOpened }): number => isDepositOpened ? theme.star.zIndexGlobal.below + theme.star.zIndexGlobal.rhsMenu : theme.star.zIndexGlobal.highLevel};
`);
