import React from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { RaceResultsRow } from './RaceResultsRow';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { PlaceCaption, RaceSummaryCaption, RunnerCaption, SilkCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { ResultCaption, ResultSelectionCaption, ResultsTableMessage, InformationBox, InfoIconWrapper, InfoElement } from './RaceResults.style';
import { useAppStateContext } from 'src/appState/AppState';

const getSelectionsResulted = (race: EventModel, models: ModelsState): Array<SelectionModel> | null => {
    for (const market of race.marketsAll) {
        const marketId = market.id;
        const marketModel = models.getMarket(marketId);
        if (marketModel === null) {
            return null;
        }
        return marketModel.selections.filter(selection => selection.state === 'settled' || selection.state === 'resulted');
    }
    return null;
};

const getTopSelectionsSorted = (race: EventModel, models: ModelsState, sport: 'horseracing' | 'greyhoundracing'): Array<SelectionModel> | null => {
    const selectionsResulted = getSelectionsResulted(race, models);

    if (selectionsResulted === null) {
        return null;
    }

    const placesNumber = sport === 'horseracing' ? 4 : 3;

    return selectionsResulted
        .filter(selection => selection.resultType === 'won' || (selection.resultPlace !== null && selection.resultPlace <= placesNumber))
        .sort((sel1, sel2) => {
            if (sel1.resultType === 'won') {
                return -1;
            }
            if (sel2.resultType === 'won') {
                return 1;
            }
            if (sel1.resultPlace === null) {
                return 1;
            }
            if (sel2.resultPlace === null) {
                return -1;
            }
            return sel1.resultPlace - sel2.resultPlace;
        });
};

interface DividendsType {
    forecast: number | undefined;
    tricast: number | undefined;
}

const getDividends = (race: EventModel, models: ModelsState): DividendsType | null => {
    for (const market of race.marketsAll) {
        const marketId = market.id;
        const marketModel = models.getMarket(marketId);
        if (marketModel !== null) {
            const { dividends } = marketModel;
            if (dividends !== null) {
                const forecastArr = dividends.forecast ?? [];
                const tricastArr = dividends.tricast ?? [];
                return {
                    forecast: forecastArr[0]?.dividend,
                    tricast: tricastArr[0]?.dividend
                };
            }
        }
    }
    return null;
};

interface RaceResultsPropsType {
    race: EventModel;
    sport: 'horseracing' | 'greyhoundracing';
}

export const RaceResults = observer('RaceResults', (propsIn: RaceResultsPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { race, sport } = props;
    const { appSportsBookState } = useAppStateContext();
    const { models } = appSportsBookState;

    const topSelectionsSorted = getTopSelectionsSorted(race, models, sport);

    if (topSelectionsSorted === null) {
        return null;
    }

    const dividends = getDividends(race, models);
    const isForecast = dividends?.forecast !== undefined;
    const isTricast = dividends?.tricast !== undefined;

    const dividendsInformationBox = dividends === null ? null : (
        <InformationBox>
            <InfoIconWrapper />
            {isForecast && <InfoElement><I18n langKey='events.race-card.results.forecast' defaultText='Forecast' />: £{dividends.forecast}</InfoElement>}
            {isForecast && isTricast && <InfoElement>|</InfoElement>}
            {isTricast && <InfoElement><I18n langKey='events.race-card.results.tricast' defaultText='Tricast' />: £{dividends.tricast}</InfoElement>}
        </InformationBox>
    );

    return (
        <>
            <ResultsTableMessage>
                <I18n langKey='events.race-results.label' defaultText='Race Results' />
            </ResultsTableMessage>

            <RaceSummaryCaption>
                <ResultCaption>
                    <I18n langKey='events.race-results.result.label' defaultText='Result' />
                </ResultCaption>
                {sport === 'horseracing' ? <PlaceCaption>#</PlaceCaption> : null}
                <SilkCaption>
                    {
                        sport === 'horseracing'
                            ? <I18n langKey='events.race-results.silk.label' defaultText='Silk' />
                            : <I18n langKey='events.race-results.trap.label' defaultText='Trap' />
                    }
                </SilkCaption>
                <RunnerCaption>
                    {
                        sport === 'horseracing'
                            ? <I18n langKey='events.race-results.horse-jockey-trainer.label' defaultText='Horse / Jockey / Trainer' />
                            : <I18n langKey='events.race-results.runner.label' defaultText='Runner' />
                    }
                </RunnerCaption>
                <ResultSelectionCaption><I18n langKey='events.race-results.selection.sp' defaultText='SP' /></ResultSelectionCaption>
            </RaceSummaryCaption>

            {topSelectionsSorted.map(selection => {
                return (
                    <RaceResultsRow
                        key={selection.id}
                        selection={selection}
                        sport={sport}
                    />
                );
            })}
            {dividendsInformationBox}
        </>
    );
});
