import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fadeIn, fadeOut } from 'src/domains/layouts/shared/Animation.style';
import { ScrollLeftButton, ScrollRightButton } from 'src/domains/layouts/shared/ScrollButtons.style';

export const SliderWrapper = styled('div', { label: 'SliderWrapper' })`
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

export const Swiper = styled('nav', { label: 'Swiper' })`
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const setScrollButton = css`
    bottom: 0;
    margin: auto 0;
    top: 0;
`;

interface ScrollButtonType {
    isVisible?: boolean;
}

export const ScrollLeft = styled(ScrollLeftButton, { label: 'ScrollLeft' })<ScrollButtonType>`
    ${setScrollButton};
    animation: ${({ isVisible }): string => isVisible === true ? fadeIn : fadeOut} 0.5s ease;
    left: 16px;
`;

export const ScrollRight = styled(ScrollRightButton, { label: 'ScrollRight' })<ScrollButtonType>`
    ${setScrollButton};
    animation: ${({ isVisible }): string => isVisible === true ? fadeIn : fadeOut} 0.5s ease;
    right: 16px;
`;
