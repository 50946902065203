import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { CirclesWrapper, Circle, CirclesWrapperWhenNoArrow, WarningTriangleIconWrapper, CheckIconWrapper } from './BreadcrumbsCircles.styles';
import { SignUpState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUp.state';
import { StepsForCircles } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/stepsStructure/SignupMainSteps';
import { useAppStateContext } from 'src/appState/AppState';

const stepsAndCirclesFlow: Map<StepsForCircles, number | null> = new Map([
    ['create-account', 1],
    //['profile-verification', 2],
    ['responsible-gambling', 2],
    ['deposit', 3]
]);

const stepsAndCirclesSecondFlow: Map<StepsForCircles, number | null> = new Map([
    ['create-account', 1],
    //['profile-verification', 2],
    // ['responsible-gambling', 2],
    ['deposit', 2]
]);

const renderSteps = (state: SignUpState, limitsAndResponsibleGamblingOnSignup: boolean): Array<JSX.Element> => {
    const stepsAndCircles = limitsAndResponsibleGamblingOnSignup === true ? stepsAndCirclesFlow : stepsAndCirclesSecondFlow;

    const out = [];
    const currentCircle = stepsAndCircles.get(state.currentView.step.type) ?? null;

    for (const stepName of new Set(stepsAndCircles.keys())) {
        const circle = stepsAndCircles.get(stepName);
        if (circle === null || circle === undefined || currentCircle === null) continue;
        const stepWithError = state.currentView.stepsWithError.includes(stepName);
        out.push(
            <Circle
                key={`step-${ circle }`}
                isActive={currentCircle >= circle}
                data-test={`breadcrumbs-circle-${circle}`}
            >
                { stepWithError ?
                    <WarningTriangleIconWrapper />
                    :
                    currentCircle <= circle ? circle : <CheckIconWrapper dataTest='breadcrumbs-circle-check-icon' /> }
            </Circle>
        );
    }

    return out;
};

interface PropsType {
    signUpState: SignUpState;
}

export const BreadcrumbsCircles = observer('BreadcrumbsCircles', (props: PropsType): JSX.Element => {
    const { signUpState } = props;
    const step = signUpState.currentView.step.type;

    const { appLayoutsState } = useAppStateContext();
    const limitsAndResponsibleGamblingOnSignup = appLayoutsState.configComponents.config.limitsAndResponsibleGamblingOnSignup;

    const circles = (): JSX.Element => {
        if (step === 'responsible-gambling' || step === 'deposit') {
            return (
                <CirclesWrapperWhenNoArrow>
                    {renderSteps(signUpState, limitsAndResponsibleGamblingOnSignup)}
                </CirclesWrapperWhenNoArrow>
            );
        }
        return (
            <CirclesWrapper>
                {renderSteps(signUpState, limitsAndResponsibleGamblingOnSignup)}
            </CirclesWrapper>
        );
    };

    return circles();
});
