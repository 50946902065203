import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { sortGreyhoundsSelections, sortSelections } from 'src/domains/layouts/utils/sortSelections';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { RacesSliderListState, RacingSportType } from './RacesSliderList.state';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { Pagination } from 'src/domains/sportsbook/webview/components/pagination/Pagination';
import { RaceBox } from 'src/domains/sportsbook/webview/components/raceBox/RaceBox';
import { RaceBoxLoader } from 'src/domains/sportsbook/webview/components/raceBox/RaceBoxLoader';

import emptyHrSilk_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import emptyHrSilk from 'src/domains/sportsbook/webview/components/racesSlider/assets/empty-hr-silk.svg';
import emptyGhSilk from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import {
    RacingCard,
    RacingCardsWrapper,
    RaceSliderWrapper,
    RacesSliderInfo,
    RacesSliderInner,
} from './RacesSlider.style';

interface OptionType {
    items: number;
    defaultSilk: string;
    sortSelections: (selections: Array<SelectionModel>) => Array<SelectionModel>;
    moreInfo: boolean;
}

const OPTIONS = (sport: string, useNewSilk: boolean): OptionType => {
    if (sport === 'horseracing') {
        return {
            items: 6,
            defaultSilk: useNewSilk ? emptyHrSilk : emptyHrSilk_old,
            sortSelections: sortSelections,
            moreInfo: true, // show jockey name and additional columns when view extended
        };
    }

    if (sport === 'greyhoundracing') {
        return {
            items: 6,
            defaultSilk: emptyGhSilk,
            sortSelections: sortGreyhoundsSelections,
            moreInfo: false,
        };
    }

    return {
        items: 6,
        defaultSilk: emptyHrSilk,
        sortSelections: sortSelections,
        moreInfo: true, // show jockey name and additional columns when view extended
    };
};

interface RacesSliderListType {
    sport: RacingSportType; // TODO: to remove, access inside RacesSliderListState
    events: Array<EventModel> | 'loading'; // TODO: to remove
    racesSliderListState: RacesSliderListState;
}

export const RacesSliderList = observer('RacesSliderList', ({ racesSliderListState, sport }: RacesSliderListType) => {
    const appState = useAppStateContext();
    const {
        wrapperRef,
        wrapperInnerRef,
        pageSize,
        goLeftAvailable,
        goRightAvailable,
        goToLeft,
        goToRight,
        onTouchStart,
        onTouchEnd,
        transX,
        eventsForView,
        shouldShowLoading,
        wrapperWidth,
        itemWidth,
    } = racesSliderListState;

    const detectFeatures = appState.env.modernizrDetect;
    const isTouchEventsAvailable = detectFeatures !== null && detectFeatures.touchevents === true;
    const options = OPTIONS(sport, appState.config.shouldDisplaySilkWithoutQuestionMark);
    return (
        <>
            <RaceSliderWrapper ref={wrapperRef.setRef} className={`races-slider__races-${pageSize}`} key='races-slider-races'>
                <RacesSliderInner ref={wrapperInnerRef.setRef} key='races-slider-inner'>
                    {shouldShowLoading || eventsForView === 'loading' ? (
                        <RacingCardsWrapper
                            onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                            transX={0}
                            width={wrapperWidth}
                            inProgress={true}
                        >
                            <RacingCard key='placeholder-1'>
                                <RaceBoxLoader items={options.items} />
                            </RacingCard>
                            <RacingCard key='placeholder-2'>
                                <RaceBoxLoader items={options.items} />
                            </RacingCard>
                            <RacingCard key='placeholder-3'>
                                <RaceBoxLoader items={options.items} />
                            </RacingCard>
                        </RacingCardsWrapper>
                    ) : (
                        <RacingCardsWrapper
                            onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                            width={wrapperWidth}
                            transX={transX}
                            inProgress={false}
                        >
                            {eventsForView?.map(([race, market, showBox]) => (
                                <RacingCard key={race.id} data-test-event-id={race.id} width={itemWidth}>
                                    {showBox && (
                                        <RaceBox
                                            race={race}
                                            market={market}
                                            items={options.items}
                                            defaultSilk={options.defaultSilk}
                                            moreInfo={options.moreInfo}
                                            sortSelections={options.sortSelections}
                                            extendSelection={eventsForView.length === 1}
                                        />
                                    )}
                                </RacingCard>
                            ))}
                        </RacingCardsWrapper>
                    )}
                </RacesSliderInner>

                {isTouchEventsAvailable || racesSliderListState.eventsForView === 'loading' ? null : (
                    <Pagination
                        key='races-slider-pagination'
                        goLeftAvailable={goLeftAvailable}
                        goRightAvailable={goRightAvailable}
                        onLeft={goToLeft}
                        onRight={goToRight}
                    />
                )}
            </RaceSliderWrapper>
            {eventsForView !== 'loading' || eventsForView.length === 0 && (
                <RacesSliderInfo className='races-slider__info'>
                    <I18n
                        langKey='races-slider.no-found-races'
                        defaultText="Sorry, we haven't found any races with such criteria."
                    />
                </RacesSliderInfo>
            )}
        </>
    );
});
