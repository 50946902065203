import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { AccountState } from 'src/domains/players/shared/Types';
import {
    GlobalValidationDepositSuccessResponse,
    swiftyGlobalValidationDeposit,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/swiftyBillingInfoFieldsValidation';

export type DepositInitiateType =
    | {
          readonly type: 'ok';
          readonly iframeUrl: string;
      }
    | {
          readonly type: 'error';
          readonly error: string;
      }
    | {
          readonly type: 'serverIssue';
      }
    | {
          readonly type: 'loading';
      };

type ValidateUserDataType = {
    readonly type: 'error';
    readonly error: string;
};

const createErrorMessage = (
    languagesState: LanguagesState,
    errorType: string,
    requiredFields?: Array<string>,
    fieldType?: string
): string => {
    const unknownError = `Unknown error happen - ${errorType}`;

    switch (errorType) {
        case 'missing-fields':
            if (requiredFields === undefined) {
                return unknownError;
            }
            return `Please add customer ${requiredFields.join(', ')} to customer 'Personal account' details.`;
        case 'deposits-not-allowed':
            if (fieldType === 'playbreak') {
                return languagesState.getTranslation(
                    'errors.account-playbreak-unacceptable',
                    'You are currently on a Play Break'
                );
            } else {
                return `Deposit not allowed - ${fieldType ?? ''}`;
            }
            break;
        default:
            return unknownError;
    }
};

const handleDepositInitiate = async (
    account: AccountState,
    languagesState: LanguagesState,
    usersState: UsersState,
    isSignup: boolean,
    userData: GlobalValidationDepositSuccessResponse
): Promise<DepositInitiateType> => {
    const body = {
        currency: userData.data.currency,
        theme: isSignup === true ? 'dark' : 'light',
        customer: userData.data.body,
        ip: account.ipUser ?? '127.0.0.1',
    };

    try {
        const response = await usersState.swiftyGlobalDepositInitiate({ requestBody: body });

        if (response.status === '422') {
            const errorMessage = response.errors?.[0];

            if (errorMessage === undefined) {
                return {
                    type: 'error',
                    error: createErrorMessage(languagesState, 'unknown'),
                };
            }

            return {
                type: 'error',
                error: createErrorMessage(languagesState, errorMessage.code, undefined, errorMessage.field),
            };
        }

        return {
            type: 'ok',
            iframeUrl: response.bodyJson.iframeUrl,
        };
    } catch (e) {
        return {
            type: 'serverIssue',
        };
    }
};

const validationUserDataInfo = async (
    usersState: UsersState,
    languagesState: LanguagesState
): Promise<ValidateUserDataType | GlobalValidationDepositSuccessResponse> => {
    try {
        const basicData = await usersState.basicData.fetch();
        const billingInfo = swiftyGlobalValidationDeposit(basicData);

        if (billingInfo?.type === 'error') {
            return {
                error: createErrorMessage(languagesState, 'missing-fields', billingInfo.fieldsRequired),
                type: 'error',
            };
        } else if (billingInfo === undefined) {
            return {
                error: createErrorMessage(languagesState, 'unknown'),
                type: 'error',
            };
        }

        return billingInfo;
    } catch (e) {
        return {
            error: createErrorMessage(languagesState, 'unknown'),
            type: 'error',
        };
    }
};

export const swiftyGlobalDepositInitiateRequest =
    (account: AccountState, languagesState: LanguagesState, usersState: UsersState, isSignup: boolean) =>
    async (): Promise<DepositInitiateType> => {
        const validatedUseraData = await validationUserDataInfo(usersState, languagesState);

        if (validatedUseraData.type === 'error') {
            return {
                type: 'error',
                error: validatedUseraData.error,
            };
        }

        return await handleDepositInitiate(account, languagesState, usersState, isSignup, validatedUseraData);
    };
