import { css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { baseDropdownStyle } from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';

export const carouselEventDropdown = (theme: EmotionTheme): SerializedStyles => css`
    ${baseDropdownStyle()}

    &.custom-dropdown-select .react-select__control {
        color: ${theme.star.sportEventNav.txtColor};
        background: ${theme.star.sportEventNav.bgColor};
        border: 1px solid ${theme.star.sportEventNav.borderColorSecondary};
        padding: 0 8px;
        min-height: 32px;
    }

    &.custom-dropdown-select .react-select__value-container {
        font-size: ${theme.star.fontSize.small};
        position: initial;
    }

    &.custom-dropdown-select .react-select__control.react-select__control--menu-is-open {
        border-color: ${theme.star.sportEventNav.borderColorTertiary};

        .react-select__indicators {
            transform: scaleY(-1);
        }
    }

    &.custom-dropdown-select .react-select__option,
    &.custom-dropdown-select .react-select__placeholder,
    &.custom-dropdown-select .react-select__single-value {
        color: ${theme.star.sportEventNav.txtColor};
    }

    &.custom-dropdown-select .react-select__option,
    &.custom-dropdown-select .react-select__menu-notice--no-options {
        background: ${theme.star.sportEventNav.bgColorSecondary};
        font-size: ${theme.star.fontSize.xSmall};
        font-weight: ${theme.star.fontWeight.regular};
        padding: 4px 12px;

        :hover {
            color: ${theme.star.sportEventNav.txtColorSecondary};
            background: ${theme.star.sportEventNav.bgColorTertiary};
        }
    }

    &.custom-dropdown-select .react-select__option--is-selected {
        color: ${theme.star.sportEventNav.txtColorSecondary};
        background: ${theme.star.sportEventNav.bgColorTertiary};
    }

    &.custom-dropdown-select .react-select__menu-list {
        &::-webkit-scrollbar {
            background-color: rgba(0, 0, 0, .1);
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.2);
        }
        background: ${theme.star.sportEventNav.bgColorSecondary};
        max-height: 224px;
        scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.1);
    }
`;
