import styled from '@emotion/styled';

export const ScoreBoardWrapper = styled('div', { label: 'ScoreBoardWrapper' })`
    text-align: center;
`;

export const IframeStandard = styled('iframe', { label: 'IframeStandard' })`
    border: 0;
    display: block;
    height: 200px;
    margin: 0 auto 10px;
    width: 100%;
`;

export const IframeStandardDesktop = styled(IframeStandard, { label: 'IframeStandardDesktop' })`
    height: 324px;
`;
