import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { LinkWrapper } from './AboutPages.style';

const linkComponents = {
    'about-us': (
        <I18n
            langKey='footer.about-us'
            defaultText='About Us'
        />
    ),
    'responsible-gambling': (
        <I18n
            langKey='footer.responsible-gambling'
            defaultText='Responsible Gambling'
        />
    ),
    faq: (
        <I18n
            langKey='footer.faqs'
            defaultText="FAQ's"
        />
    ),
    'privacy-policy': (
        <I18n
            langKey='footer.privacy.policy'
            defaultText='Privacy Policy'
        />
    ),
    'terms-and-conditions': (
        <I18n
            langKey='footer.terms-conditions'
            defaultText='Terms & Conditions'
        />
    ),
    'betting-rules': (
        <I18n
            langKey='footer.betting-rules'
            defaultText='Betting Rules'
        />
    ),
    'give-feedback': (
        <I18n
            langKey='footer.feedback'
            defaultText='Give us Feedback'
        />
    ),
    affiliates: (
        <I18n
            langKey='footer.affiliates'
            defaultText='Affiliates'
        />
    ),
};

export const AboutPagesItems = observer('AboutPagesItems', () => {
    const { appLayoutsState } = useAppStateContext();
    const {
        configComponents: { config },
    } = appLayoutsState;
    const { footerUsefulLinks } = config;

    const routesForGTM = ['terms-and-conditions'];

    const sortedFooterUsefulLinks = Object.values(footerUsefulLinks).sort((a, b) => a.order - b.order);

    const renderLinks = (): JSX.Element[] => {
        return Object.values(sortedFooterUsefulLinks).map((linkItem) => {
            const { id, to } = linkItem;

            return (
                <LinkWrapper
                    key={id}
                    to={to}
                    dataTest={id}
                    routeNameForGtm={routesForGTM.includes(id) ? id : null}
                >
                    {linkComponents[id]}
                </LinkWrapper>
            );
        });
    };

    return <>{renderLinks()}</>;
});
