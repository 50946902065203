import { observable, action, makeObservable, computed } from 'mobx';
import { BannerType } from 'src/domains/casino/state/AppCasino.state';
import { BannerStore } from 'src/domains/casino/state/AppCasino/store/banner-store/Banner.store';
import { CollectionType } from 'src/domains/casino/types/types';
import { DirectionType } from 'src/domains/casino/webview/CasinoGames/home-games/casino-banners/CasinoBanners';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';

export class CasinoBannersState {
    @observable public activeBannerIndex: number;
    private touchStartX: number;
    private touchEndX: number;
    
    public constructor(
        private bannerStore: BannerStore,
        private collection: CollectionType,
        private breakpointsState: BreakpointsState,
    ) {
        makeObservable(this);
        this.activeBannerIndex = 0;
        this.touchStartX = 0;
        this.touchEndX = 0;
    }

    @action public swipeStart = (touchX: number): void => {
        this.touchStartX = touchX;
    };

    @action public swipeEnd = (touchX: number, goLeftAvailable: boolean, goRightAvailable: boolean): void => {
        this.touchEndX = touchX;
        if (this.touchEndX > this.touchStartX && goLeftAvailable) this.slideInDirection('left');
        if (this.touchEndX < this.touchStartX && goRightAvailable) this.slideInDirection('right');
    };

    @action public slideWithDot = (dotIndex: number): void => {
        const indexOffset = dotIndex - this.activeBannerIndex;
        this.activeBannerIndex = this.activeBannerIndex + indexOffset;
    };

    @action public slideInDirection = (direction: DirectionType): void => {
        const indexOffset = direction === 'left' ? -1 : 1;
        this.activeBannerIndex = this.activeBannerIndex + indexOffset;
    };

    private get isDesktop(): boolean {
        return this.breakpointsState.tablet.isBiggerOrEq ?? false;
    };

    public get banners(): Array<BannerType> {
        return this.bannerStore.getBanners(this.collection);
    };

    @computed public get bannersIndexes(): Array<{id: number}> {
        return this.banners.map((b) => { return ({ id: b.id });});
    };

    @computed public get hasOneBanner(): boolean {
        return this.banners.length === 1;
    };

    @computed public get goLeftAvailable(): boolean {
        return this.activeBannerIndex > 0;
    };
    @computed public get goRightAvailable(): boolean {
        return this.activeBannerIndex + 1 < this.banners.length;
    };

    @computed public get showLeftArrow(): boolean {
        return this.goLeftAvailable && this.isDesktop;
    };
    @computed public get showRightArrow(): boolean {
        return this.goRightAvailable && this.isDesktop;
    };

}
