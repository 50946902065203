import { action, computed, makeObservable } from 'mobx';
import { MatchType as MarketType, ItemType, getMarkets } from './markets';
import { couponsStar, CouponType, isCouponId, CouponIdType } from './coupons';
import { europeanEliteTemplate, scotlandRegex, walesTemplateId, welshFootballTeamTemplate } from './EventsTemplates';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    EventsCollectionQueryCompetitionType,
    EventsCollectionQueryModel,
    FilterType,
} from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { ServerTimeState } from 'src_common/common/websocket2/ServerTimeState';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { EventsListSelectTabState } from './EventsListSelectTabState';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { filterByTeams, filterCompetition, filtersComposition } from './filters';

// eslint-disable-next-line
interface CallbacksTypes {
    getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string;
}

export class EventsListState {
    private readonly serverTime: ServerTimeState;
    private readonly configComponents: ConfigComponents;
    private readonly eventsCollection: EventsCollectionState;
    private readonly models: ModelsState;

    private readonly selectTab: EventsListSelectTabState;

    public constructor(
        eventsCollection: EventsCollectionState,
        serverTime: ServerTimeState,
        configComponents: ConfigComponents,
        models: ModelsState,
        private readonly competitionId: CompetitionId | null,
        private readonly callbacks: CallbacksTypes,
        selectTab: EventsListSelectTabState
    ) {
        makeObservable(this);
        this.eventsCollection = eventsCollection;
        this.serverTime = serverTime;
        this.configComponents = configComponents;
        this.selectTab = selectTab;
        this.models = models;
    }

    public get filter_market(): MarketType {
        return this.selectTab.eventsListMarkets.filters_market;
    }

    @computed public get filter_marketTemplateId(): string {
        return this.selectTab.eventsListMarkets.marketTemplateId;
    }

    @computed public get filter_marketLine(): number | null {
        return this.selectTab.eventsListMarkets.marketLine;
    }

    @computed.struct public get lastSelections(): Array<string> {
        return this.selectTab.eventsListMarkets.lastSelections;
    }

    @computed.struct public get lastSelectionsHeader(): Array<string> {
        return this.selectTab.eventsListMarkets.lastSelectionsHeader;
    }

    public isOutrightsMarket = (): boolean => {
        const coupon = this.selectTab.filter_coupon;
        return coupon === 'custom-outrights-market' || coupon === 'specials';
    };

    public get collection(): EventsCollectionList {
        if (this.isOutrightsMarket()) {
            return this.eventsCollection.listOfSport('football');
        } else {
            return this.eventsCollection.listOfSport('football', {
                templateId: this.filter_marketTemplateId,
                line: this.filter_marketLine,
            });
        }
    }

    @computed public get isLoading(): boolean {
        // eslint-disable-next-line
        return this.collection.isLoading ?? true;
    }

    @computed public get collectionQuery(): EventsCollectionQueryModel | null {
        return this.collection.getQuery() ?? null;
    }

    @computed.struct public get competitionsArray(): Array<EventsCollectionQueryCompetitionType> {
        return this.collectionQuery?.competitionsSortById ?? [];
    }

    public filterByCompetition(routeId: CompetitionId): EventsCollectionQueryModel | null {
        return this.visibleEvents?.filterByCompetition((competitionId) => competitionId === routeId.toOldId()) ?? null;
    }

    @computed public get couponForEnv(): CouponType[] {
        const { couponForEnv } = this.configComponents.config;

        switch (couponForEnv) {
            case 'couponsStar': {
                return couponsStar;
            }
        }
    }

    @computed.struct public get translatedCouponsForEnv(): Array<{ name: string; id: string }> {
        return this.couponForEnv.map((filter) => ({
            id: filter.id,
            name: filter.translatedName(this.callbacks.getTranslation),
        }));
    }

    @computed public get marketForEnv(): Array<ItemType> {
        return getMarkets(this.callbacks.getTranslation, this.configComponents.config);
    }

    @action public changeFilter_coupon = (value: string): void => {
        this.changeFilter_market('match-winner');

        if (isCouponId(value)) {
            this.selectTab.filter_coupon = value;
        } else {
            console.error('EventsListState - Incorrect filter value', value);
            this.selectTab.filter_coupon = 'all';
        }
    };

    @action public changeFilter_market = (id: MarketType): void => {
        this.selectTab.eventsListMarkets.filters_market = id;
    };

    public get visibleEvents(): EventsCollectionQueryModel | null {
        return this.collectionQuery;
    }

    @computed public get scottishTemplateId(): Array<number> {
        const competitionsArray = this.competitionsArray;
        const idsArray: Array<number> = [];

        for (const competition of competitionsArray) {
            for (const rx of scotlandRegex) {
                if (rx.test(competition.name.toLowerCase())) {
                    idsArray.push(competition.id);
                }
            }
        }

        return idsArray;
    }

    @computed public get welshTemplateId(): Array<number> {
        const competitionsArray = this.competitionsArray;
        return competitionsArray
            .filter(({ name }) => walesTemplateId.some((templateId) => templateId === name))
            .map(({ id }) => id);
    }

    @computed public get europeanEliteTemplateId(): Array<number> {
        const competitionsArray = this.competitionsArray;
        const idsArray: Array<number> = [];

        for (const competition of competitionsArray) {
            if (europeanEliteTemplate.includes(competition.name)) {
                idsArray.push(competition.id);
            }
        }

        return idsArray;
    }

    public applyFilterCoupon(events: EventsCollectionQueryModel | null): EventsCollectionQueryModel | null {
        if (events === null) {
            return events;
        }

        switch (this.selectTab.filter_coupon) {
            case 'all':
                return events;

            case 'today':
                return events.filter((event) => this.serverTime.today.matchMs(event.startTime));

            case 'tomorrow':
                return events.filter((event) => this.serverTime.tomorrow.matchMs(event.startTime));

            case 'uk-list':
                return events.filter((event) => event.tags['region'] === 'domestic');

            case 'scottish':
                return events.filterByCompetitionWhenIn(this.scottishTemplateId);

            case 'welsh': {
                const teamFilter: FilterType = filterByTeams(
                    'football',
                    (competition) => competition.events.length > 0,
                    welshFootballTeamTemplate
                );
                const competitionFilter: FilterType = filterCompetition(this.welshTemplateId);
                const filter = filtersComposition([teamFilter, competitionFilter]);
                return events.applyFilter(filter);
            }

            case 'european-elite':
                return events.filterByCompetitionWhenIn(this.europeanEliteTemplateId);

            case 'european-list':
                return events
                    .filter((event) => event.tags['region'] === 'europe')
                    .filterByCompetition((competitionId) => !this.europeanEliteTemplateId.includes(competitionId));

            case 'european-all':
                return events.filter((event) => event.tags['region'] === 'europe');

            case 'row':
                return events.filter((event) => {
                    if (
                        event.tags['region'] === 'africa' ||
                        event.tags['region'] === 'asia' ||
                        event.tags['region'] === 'australasia' ||
                        event.tags['region'] === 'north-america' ||
                        event.tags['region'] === 'south-america'
                    ) {
                        return true;
                    }

                    return false;
                });

            case 'custom-outrights-market':
                return events.filter((elem) => elem.template === 'outrights' && elem.tags['outright'] === 'yes');

            case 'specials':
                return events.filter((event) => {
                    const evnetModel = this.models.getEvent(event.id);
                    return evnetModel?.tagsFootballSpecial === 'yes';
                });
        }
    }

    @computed public get getFilteredEvent(): EventsCollectionQueryModel | null {
        let events = this.visibleEvents;
        events = this.applyFilterCoupon(events);

        if (this.competitionId !== null) {
            events = this.filterByCompetition(this.competitionId);
        }

        return events;
    }

    @computed public get events(): EventsCollectionQueryModel | null {
        return this.getFilteredEvent;
    }

    @computed public get showEvents(): boolean {
        const filteredEvents = this.getFilteredEvent;
        if (filteredEvents === null) {
            return false;
        }
        return filteredEvents.length > 0;
    }

    @computed public get filter_coupon(): CouponIdType {
        return this.selectTab.filter_coupon;
    }
}
