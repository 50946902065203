import { action, observable, computed, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';

export class QuickBetState {
    @observable public isShowQuickBetInner: boolean = false;
    public constructor() {
        makeObservable(this);
    }

    @action public onUpdateQuickBet = (isShow: boolean): void => {
        this.isShowQuickBetInner = isShow;
    };

    @computed public get canBeVisibleQuickBet(): boolean {
        const innerWidth = getWindowInnerWidth();
        if (innerWidth !== null) {
            const maxResolutionForQuickbet = innerWidth <= 768;
            return maxResolutionForQuickbet;
        }
        return false;
    }

    @computed public get isVisibleQuickBet(): boolean {
        return this.canBeVisibleQuickBet && this.isShowQuickBet;
    }

    @computed public get isShowQuickBet(): boolean {
        return this.isShowQuickBetInner;
    }
}
