import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { NewEmailGroupModelTypes } from 'src/domains/players/webview/components/Account/personalDetailsTab/changeEmailProcedure/ChangeEmailProcedure.state';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const validateNewAndConfirmEmail = (value: NewEmailGroupModelTypes): Result<NewEmailGroupModelTypes> => {

    if (value.confirmEmail !== value.newEmail) {
        return Result.createError(getErrorByCode('ERROR_CONFIRM_EMAIL'));
    }

    return Result.createOk({
        newEmail: value.newEmail,
        confirmEmail: value.confirmEmail
    });
};
