import * as React from 'react';
import { SwitchCircle, SwitchWrapper } from './Switch/Switch.style';

interface SwitchPropsType {
    onChange: () => void;
    switched: boolean;
    version: 'primary' | 'secondary';
}

export const Switch = ({ onChange, switched, version }: SwitchPropsType): JSX.Element => {
    return (
        <SwitchWrapper
            onClick={(): void => onChange()}
            switched={switched}
            version={version}
        >
            <SwitchCircle switched={switched} data-test='switch-circle'/>
        </SwitchWrapper>
    );
};
