import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { assertNever } from 'src_common/common/assertNever';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LayoutSignUp, SignUpState } from 'src/domains/players/webview/components/SignUp';
import { RealexJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexJourney';
import {
    SkipButton,
    SkipButtonContainer,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/Deposit.style';
import { SwiftyGlobalJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/swiftyGlobalTempIframe/SwiftyGlobalJourney';
import { InfoPage } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/temporaryInfo/InfoPage';
import { PraxisJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/praxisProvider/PraxisJourney';
import { PaysafeJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/paysafeProvider/PaysafeJourney';

interface DepositSignupLayoutProps {
    children: React.ReactElement;
    skipAction: () => void;
    signUpState: SignUpState;
}

export const DepositSignupLayout = observer(
    'DepositSignupLayout',
    ({ children, skipAction, signUpState }: DepositSignupLayoutProps): JSX.Element => {
        const { appLayoutsState } = useAppStateContext();
        const { googleTagManager } = appLayoutsState;

        const handleSkipAction = (): void => {
            skipAction();
            googleTagManager.gtmSignUpStepFour(0);
        };

        return (
            <LayoutSignUp stepsCounter={true} footer={true} signUpState={signUpState}>
                <>
                    {children}
                    <SkipButtonContainer>
                        <SkipButton
                            version='secondary-dark'
                            size='medium'
                            onClick={handleSkipAction}
                            dataTest='skip-button'
                        >
                            <I18n langKey='signup-journey.deposit.skip-button' defaultText='Skip' />
                        </SkipButton>
                    </SkipButtonContainer>
                </>
            </LayoutSignUp>
        );
    }
);

interface PropsType {
    signUpState: SignUpState;
}

export const DepositSignupProvider = observer(
    'DepositSignupProvider',
    ({ signUpState }: PropsType): JSX.Element | null => {
        const {
            config: { usePaymentFormForUniverse, temporaryDepositPage, accountHelperMail },
        } = useAppStateContext();

        if (temporaryDepositPage) {
            return (
                <DepositSignupLayout skipAction={signUpState.toggleAside} signUpState={signUpState}>
                    <InfoPage infoPageType='deposit' accountHelperMail={accountHelperMail} />
                </DepositSignupLayout>
            );
        }

        if (usePaymentFormForUniverse.type === 'realex') {
            return (
                <DepositSignupLayout skipAction={signUpState.toggleAside} signUpState={signUpState}>
                    <RealexJourney isSignup={true} />
                </DepositSignupLayout>
            );
        }

        if (usePaymentFormForUniverse.type === 'swiftyGlobalProvider') {
            return (
                <DepositSignupLayout skipAction={signUpState.toggleAside} signUpState={signUpState}>
                    <SwiftyGlobalJourney isSignup={true} />
                </DepositSignupLayout>
            );
        }

        if (usePaymentFormForUniverse.type === 'zenetPay') {
            return <div>TODO</div>;
        }

        if (usePaymentFormForUniverse.type === 'praxis') {
            return (
                <DepositSignupLayout skipAction={signUpState.toggleAside} signUpState={signUpState}>
                    <PraxisJourney isSignup={true} />
                </DepositSignupLayout>
            );
        }

        if (usePaymentFormForUniverse.type === 'paysafe') {
            return (
                <DepositSignupLayout skipAction={signUpState.toggleAside} signUpState={signUpState}>
                    <PaysafeJourney isSignup={true} paysafePaymentProvider={usePaymentFormForUniverse} />
                </DepositSignupLayout>
            );
        }

        return assertNever('DepositSignupProvider', usePaymentFormForUniverse);
    }
);
