import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import { EachWayExtraRaceSummaryRowHeader, EachWayExtraRunnerCell } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/eachWayExtraSymmary/EachWayExtraSymmary.style';
import { PlaceCell, SilkCell } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { observer } from 'src/utils/mobx-react';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    place?: React.ReactNode;
    runner?: React.ReactNode;
    selectionId: SelectionId;
    isNonRunner?: boolean;
}

export const RaceSummaryRowEachWayExtra = observer('RaceSummaryRowEachWayExtra', (props: PropsType) => {
    const { place, runner, selectionId, isNonRunner } = props;
    const { appLayoutsState } = useAppStateContext();
    const { config } = appLayoutsState.configComponents;

    const selectionModel = selectionId.getModel();
    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    const eventModel = marketModel.getEvent();
    if (eventModel === null) {
        return null;
    }

    const silkUrl = selectionModel.metaDataSilkUrl;

    return (
        <EachWayExtraRaceSummaryRowHeader isNonRunner={isNonRunner}>
            <PlaceCell>{ place }</PlaceCell>
            <SilkCell>
                <LazyImage
                    src={silkUrl}
                    placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                    alt={selectionModel.metaDataSilk}
                />
            </SilkCell>
            <EachWayExtraRunnerCell>{ runner }</EachWayExtraRunnerCell>
        </EachWayExtraRaceSummaryRowHeader>
    );
});
