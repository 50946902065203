import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

import { SignUpIcons } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/signUpLinkNav/SignUpIcons';
import { AccountNavLink, AccountNavText } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';

export const SignUpLinkNav = observer('SignUpLinkNav', () => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState, googleTagManager } = appLayoutsState;
    const { gtmSignUpWelcomePage } = googleTagManager;

    const language = languagesState.userLang ?? 'en';

    return (
        <AccountNavLink
            dataTest='account-navigation-signup'
            isButton={true}
            onClickCallback={gtmSignUpWelcomePage}
            routeNameForGtm='signup'
            to={{ account: 'signup', promo: null }}
        >
            <SignUpIcons />

            <AccountNavText lang={language}>
                <I18n langKey='account.sign-up' defaultText='Sign Up' />
            </AccountNavText>
        </AccountNavLink>
    );
});
