import React from 'react';
import { SingleBetItemState } from 'src/domains/sportsbook/betting/betSlipState/betsState/SingleBetItemState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetsFilter } from 'src/domains/sportsbook/betting/state/BetsState';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { BogBanner } from 'src/domains/layouts/webview/components/Messages/BogBanner';

interface PropsTypes {
    betItem: SingleBetItemState;
}

export const BogMessageComponent = observer('BogMessageComponent', ({ betItem }: PropsTypes) => {
    const { appSportsBookState, appLayoutsState } = useAppStateContext();
    const { betsState } = appSportsBookState;
    const { sdkCustomer } = appLayoutsState;

    const bogValue = sdkCustomer.basicData.valueReady?.bpEnabled ?? false;
    const { isAnyBP, bogBonus, isAllTypeVoid } = betItem;

    if (
        isAnyBP &&
        (betsState.selectedFilter === BetsFilter.SETTLED || betsState.selectedFilter === BetsFilter.ALL) &&
        bogBonus !== null &&
        bogValue &&
        !isAllTypeVoid
    ) {
        return (
            <BogBanner
                message={
                    <I18n
                        langKey='betslip.selection-bog'
                        defaultText='You won an extra {amount} with Best Odds Guaranteed'
                        params={{ amount: sdkCustomer.money(bogBonus.amount) }}
                    />
                }
            />
        );
    }

    return null;
});
