import * as t from 'io-ts';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';

const decodeIO = t.boolean;

const decode = buildValidator('localStorageAutomatsLandingPromotionVisited.decodeIO', decodeIO, true);

export const localStorageAutomatsLandingPromotionVisited = (withAutorun: boolean, key: string): LocalStorageItemState<boolean> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: false
    });
};
