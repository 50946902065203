import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { EventsTableInfo, EventsTableMarketsLoader } from 'src/domains/sportsbook/shared/Components';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { SpecialEventsList } from 'src/domains/layouts/layouts/specialSportPage/eventList/EventsList';
import {
    SpecialSportsActiveSportsType,
    SpecialSportsState,
} from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { useAppStateContext } from 'src/appState/AppState';

interface StatusType {
    specialSports: SpecialSportsActiveSportsType;
}

const Status = observer('Status', ({ specialSports }: StatusType) => {
    if (specialSports === 'loading') {
        return <EventsTableMarketsLoader />;
    }

    if (specialSports.length === 0) {
        return (
            <EventsTableInfo>
                <I18n
                    langKey='events.table.empty'
                    defaultText="Sorry, we haven't found any events with such criteria."
                />
            </EventsTableInfo>
        );
    }

    return null;
});

interface EventsTableType {
    specialSportState: SpecialSportsState;
}

export const EventsTable = observer('EventsTable', ({ specialSportState }: EventsTableType) => {
    const { appSportsBookState } = useAppStateContext();
    const specialSports = appSportsBookState.specialSportsListState.activeSpecialSports;

    return (
        <>
            <SpecialEventsList specialSportsState={specialSportState} />

            <Status specialSports={specialSports} />

            {specialSports !== 'loading' && specialSports.length > 0 ? <PriceChangeLegend isRace={false} /> : null}
        </>
    );
});
