import * as t from 'io-ts';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { TypeId } from 'src_common/common/websocket2/type';

//http://10.110.0.32:8080/operator-events/${universe}/16935/markets/1522764
//http://10.110.0.32:8080/operator-markets/iyisans/1522764

const SelectionApiModelFieldTagsIO = t.union([t.record(t.string, t.array(t.string)), t.undefined]);
export type SelectionApiModelFieldTagsType = t.TypeOf<typeof SelectionApiModelFieldTagsIO>;


const TermsModelTypeIO = t.interface({
    places: t.number,
    reduction: t.string
});

export type TermsModelType = t.TypeOf<typeof TermsModelTypeIO>;

const PlaceReductionsItemIO = t.interface({
    eachWayTerms: TermsModelTypeIO,
    placeReduction: t.union([t.string, t.undefined, t.null]),
});


const PriceIO = t.interface({
    d: t.number,
    f: t.string,
});

export type PriceType = t.TypeOf<typeof PriceIO>;

const SpPriceIO = t.interface({
    d: t.number,
    f: t.string,
});

export type SpPriceType = t.TypeOf<typeof SpPriceIO>;

const TemplateIO = t.interface({
    id: t.string,
    marketTemplateId: t.string
});

export type TemplateType = t.TypeOf<typeof TemplateIO>;

const PriceHistoryItemIO = t.interface({
    t: t.string,
    p: t.interface({
        d: t.number,
        f: t.string
    })
});


export type PriceHistoryItemType = t.TypeOf<typeof PriceHistoryItemIO>;

const FeedSettingsIO = t.interface({
    updates: t.union([ t.boolean, t.undefined, t.null ]),
    prices: t.union([ t.boolean, t.undefined, t.null ]),
    results: t.union([ t.boolean, t.undefined, t.null ]),
    eachWay: t.union([ t.boolean, t.undefined, t.null ])
});

export type FeedSettingsType = t.TypeOf<typeof FeedSettingsIO>;

const BetLimitsIO = t.interface({
    liabilityLimit: t.union([t.number, t.undefined, t.null]),
    maxBet: t.union([t.number, t.undefined, t.null]),
    maxMultipleBet: t.union([t.number, t.undefined, t.null]),
    maxWin: t.union([t.number, t.undefined, t.null]),
});

export type BetLimitsType = t.TypeOf<typeof BetLimitsIO>;

const SelectionSettingsIO = t.interface({
    defaultSpPrice: t.union([t.string, t.undefined, t.null])
});

export type SelectionSettingsType = t.TypeOf<typeof SelectionSettingsIO>;

const SelectionApiModelIO = t.interface({
    id: t.number,
    idv2: t.string,
    uuid: t.union([ t.string, t.undefined, t.null ]),
    display: t.union([t.boolean, t.undefined]),
    price: t.union([PriceIO, t.undefined, t.null]),
    template: t.union([TemplateIO, t.undefined]),
    displayOrder: t.union([t.number, t.undefined, t.null]),
    active: t.union([t.boolean, t.undefined, t.null]),
    name: t.union([t.string, t.undefined]),
    state: t.union([t.string, t.undefined]),
    result: t.union([
        t.interface({
            type: t.union([t.string, t.undefined, t.null]),
            place: t.union([t.number, t.undefined, t.null]),
            winReduction: t.union([t.string, t.undefined, t.null]),
            placeReductions: t.union([t.array(PlaceReductionsItemIO), t.undefined, t.null]),
        }),
        t.undefined,
        t.null
    ]),
    spPrice: t.union([PriceIO, t.undefined, t.null]),
    priceHistory: t.union([
        t.undefined,
        t.null,
        t.array(PriceHistoryItemIO)]
    ),
    feedSettings: t.union([FeedSettingsIO, t.undefined, t.null]),
    tags: t.record(t.string, t.string),
    betLimits: t.union([BetLimitsIO, t.undefined, t.null]),
    selectionSettings: t.union([SelectionSettingsIO, t.undefined, t.null]),
    platformObject: t.union([t.null, t.undefined, t.interface({ name: t.string })]),
    priceType: t.union([t.string, t.undefined, t.null])
});

export type SelectionApiModelType = t.TypeOf<typeof SelectionApiModelIO>;

export type SelectionModelType = t.TypeOf<typeof SelectionApiModelIO> & {
    eventId: number;
    marketId: TypeId.MarketId;
}


const MarketTemplateModelTypeIO = t.interface({
    id: t.string,
    name: t.string,
    marketTemplateType: t.union([t.string, t.undefined, t.null])
});

export type MarketTemplateModelType = t.TypeOf<typeof MarketTemplateModelTypeIO>;

const EachWayIO = t.interface({
    offered: t.boolean,
    terms: t.array(TermsModelTypeIO),
    termsWithBet: t.boolean,
});

export type EachWayType = t.TypeOf<typeof EachWayIO>;

const ForeCastModelIO = t.interface({
    dividend: t.number,
    id: t.number,
    winner: t.number,
    '2nd': t.number
});

const TriCastModelIO = t.interface({
    dividend: t.number,
    id: t.number,
    winner: t.number,
    '2nd': t.number,
    '3rd': t.number
});


const DividendsModelIO = t.interface({
    forecast: t.union([t.null, t.undefined, t.array(ForeCastModelIO)]),
    tricast: t.union([t.null, t.undefined, t.array(TriCastModelIO)])
});

export type DividendsType = t.TypeOf<typeof DividendsModelIO>;

const BetLimitItemIO = t.interface({
    liabilityLimit: t.number,
    maxBet: t.number,
    maxMultipleBet: t.number,
    maxWin: t.number
});

const BetLimitsMapIO = t.interface({
    inPlay: BetLimitItemIO,
    prematch: BetLimitItemIO,
    show: BetLimitItemIO
});

export type BetLimitMapType = t.TypeOf<typeof BetLimitsMapIO>;

const CashoutMarketSettingsIO = t.interface({
    baseCashout: t.union([t.number, t.null, t.undefined]),
    cashoutOfferEasing: t.union([t.number, t.null, t.undefined]),
    cashoutOverround: t.union([t.number, t.null, t.undefined])
});

export type CashoutMarketSettingsType = t.TypeOf<typeof CashoutMarketSettingsIO>;

const Rule4IO = t.interface({
    id: t.number,
    endTime: t.union([ t.string, t.undefined, t.null ]),
    selection: t.union([ t.number, t.undefined, t.null ]),
    deduction: t.union([ t.number, t.undefined, t.null ]),
});

export type Rule4Type = t.TypeOf<typeof Rule4IO>;

export const MarketApiModelIO = t.interface({
    id: t.number,
    idv2: t.string,
    uuid: t.union([ t.string, t.undefined, t.null ]),
    event: t.interface({
        id: t.number,
    }),
    state: t.string,
    selections: t.record(t.string, SelectionApiModelIO),
    //updatedAt: t.string,
    updated: t.interface({
        updatedAt: t.string,
    }),
    display: t.union([t.boolean, t.undefined, t.null]),
    template: MarketTemplateModelTypeIO,
    line: t.union([t.number, t.undefined, t.null]),
    eachWay: t.union([EachWayIO, t.undefined, t.null]),
    active: t.boolean,
    name: t.string,
    platformMarketName: t.union([t.string, t.undefined, t.null]),
    tradedInPlay: t.boolean,
    spOnly: t.boolean,
    sp: t.boolean,
    bp: t.boolean,
    displayOrder: t.number,
    tricastsOffered: t.union([t.boolean, t.undefined, t.null]),
    forecastsOffered: t.union([t.boolean, t.undefined, t.null]),
    cashoutAvailable: t.union([t.boolean, t.undefined, t.null]),
    dividends: t.union([t.null, t.undefined, DividendsModelIO]),
    tags: t.record(t.string, t.string),
    suspendTime: t.union([t.null, t.undefined, t.string]),
    autoTakeDown: t.union([t.null, t.undefined, t.boolean]),
    spApplicable: t.union([t.null, t.undefined, t.boolean]),
    bpApplicable: t.union([t.null, t.undefined, t.boolean]),
    blockSettlement: t.union([t.null, t.undefined, t.boolean]),
    betReferralEnabled: t.union([t.null, t.undefined, t.boolean]),
    inPlayDelay: t.union([t.null, t.undefined, t.number]),
    eachWayStakeReduction: t.union([t.null, t.undefined, t.number]),
    betLimitsMap: t.union([t.null, t.undefined, BetLimitsMapIO]),
    feedSettings: t.union([FeedSettingsIO, t.undefined, t.null]),
    cashoutMarketSettings: t.union([CashoutMarketSettingsIO, t.null, t.undefined]),
    singlesOnly: t.union([t.boolean, t.null, t.undefined]),
    rule4: t.union([t.null, t.undefined, t.array(Rule4IO)])
});

export type MarketApiModelType = t.TypeOf<typeof MarketApiModelIO>;

export type MarketModelType = Omit<t.TypeOf<typeof MarketApiModelIO>, 'selections' | 'id'> & {
    id: TypeId.MarketId;
    selections: Array<number>;
}

export const decodeMarketResponse = buildValidator('MarketResponseIO', MarketApiModelIO);

export type PossibleMarketsTypeProxy = Array<{
    displayOrder?: null | undefined | number;
    id?: null | undefined | string;
    matchingMarkets?: null | undefined | string;
    metadata?: null | undefined | Record<string, string>;
    name: string;
    requiredParameters: Record<string, string>;
}>;

export type CreateEventMarketResponseProxy = {
    message: string;
    response?: null | undefined | {
        id: string;
        markets: Record<string, {
            betLimits: {
                inPlay: {
                    liabilityLimit: number;
                    maxBet: number;
                    maxMultipleBet: number;
                    maxWin: number;
                };
                prematch: {
                    liabilityLimit: number;
                    maxBet: number;
                    maxMultipleBet: number;
                    maxWin: number;
                };
                show: {
                    liabilityLimit: number;
                    maxBet: number;
                    maxMultipleBet: number;
                    maxWin: number;
                };
            };
            display: boolean;
            dividends?: null | undefined | {
                forecasts: Array<{
                    dividend: number;
                    id: number;
                    second: string;
                    winner: string;
                }>;
                tricasts: Array<{
                    dividend: number;
                    id: number;
                    second: string;
                    third: string;
                    winner: string;
                }>;
            };
            eachWay: {
                offered: boolean;
                termsWithBet: boolean;
            };
            eventId: string;
            id: string;
            inPlaySettings: {
                betDelay: number;
                tradedInPlay: boolean;
            };
            metadata: Record<string, string>;
            name: string;
            revision: number;
            rule4?: null | undefined | Array<{
                deduction: {
                    deduction: number;
                    endTime: string;
                };
                selectionId: string;
            }>;
            selections: Record<string, {
                display: boolean;
                id: string;
                metadata: Record<string, string>;
                name: string;
                selectionType: {
                    first?: null | undefined | {
                        source: {
                            away?: null | undefined | number;
                            home?: null | undefined | number;
                            lowerMinuteBound?: null | undefined | number;
                            p?: null | undefined | string;
                            quantity?: null | undefined | number;
                            t?: null | undefined | {
                                type: string;
                            };
                            type: string;
                            upperMinuteBound?: null | undefined | number;
                            value?: null | undefined | number;
                        };
                    };
                    participantId?: null | undefined | string;
                    second?: null | undefined | {
                        source: {
                            away?: null | undefined | number;
                            home?: null | undefined | number;
                            lowerMinuteBound?: null | undefined | number;
                            p?: null | undefined | string;
                            quantity?: null | undefined | number;
                            t?: null | undefined | {
                                type: string;
                            };
                            type: string;
                            upperMinuteBound?: null | undefined | number;
                            value?: null | undefined | number;
                        };
                    };
                    source?: null | undefined | {
                        away?: null | undefined | number;
                        home?: null | undefined | number;
                        lowerMinuteBound?: null | undefined | number;
                        p?: null | undefined | string;
                        quantity?: null | undefined | number;
                        t?: null | undefined | {
                            type: string;
                        };
                        type: string;
                        upperMinuteBound?: null | undefined | number;
                        value?: null | undefined | number;
                    };
                    type: string;
                    underlyingSelection?: null | undefined | string;
                };
                state: {
                    result?: null | undefined | {
                        placeReductions?: null | undefined | Array<{
                            eachWayTerms?: null | undefined | {
                                places?: null | undefined | number;
                                reductionFactor?: null | undefined | string;
                            };
                            placeReduction?: null | undefined | string;
                        }>;
                        result?: null | undefined | {
                            place?: null | undefined | number;
                            type: string;
                        };
                        winReduction?: null | undefined | string;
                    };
                    type: string;
                };
                uuid: string;
            }>;
            settings: {
                autoTakeDown: boolean;
                bestOddsGuaranteed: boolean;
                betReferralEnabled: boolean;
                blockSettlement: boolean;
                bpApplicable: boolean;
                cashOut: boolean;
                cashoutMarketSettings?: null | undefined | {
                    baseCashout?: null | undefined | number;
                    cashoutOfferEasing?: null | undefined | number;
                    cashoutOverround?: null | undefined | number;
                };
                eachWayStakeReduction: number;
                feedSettings: {
                    eachWay: boolean;
                    prices: boolean;
                    results: boolean;
                    updates: boolean;
                };
                forecastOffered?: null | undefined | boolean;
                rule4: boolean;
                singlesOnly: boolean;
                spApplicable: boolean;
                startingPrice: boolean;
                startingPriceOnly: boolean;
                tricastOffered?: null | undefined | boolean;
            };
            suspendTime?: null | undefined | string;
            uuid: string;
        }>;
        revision: number;
        type: string;
    };
    status: string;
};
