import * as React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { RabCloseIconWrapper, YouDecideExitWrapper } from './YouDecideHeaderHref.style';

interface PropsTypes {
    onClick?: (e?: React.SyntheticEvent) => void;
    isYouDecideOpen: boolean;
}

export const YouDecideHref = ({ onClick, isYouDecideOpen }: PropsTypes): JSX.Element => {
    if (isYouDecideOpen) {
        return (
            <YouDecideExitWrapper onClick={onClick}>
                Exit
                <RabCloseIconWrapper />
            </YouDecideExitWrapper>
        );
    }

    return <Button size='small' onClick={onClick} dataTest='bet-builder'><I18n langKey='you-decide.button.build-bet' defaultText='Build-a-Bet' /></Button>;
};
