import { observable, action, computed, makeObservable } from 'mobx';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { SportRouteType, SportSpecialRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { assertNever } from 'src_common/common/assertNever';
import { ActiveSpecialSportsType } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';

interface SportModelType {
    id: string;
    label: string;
    route: 'sport' | 'sport-special';
}

export interface SportLinkType {
    key: string;
    to: SportRouteType | SportSpecialRouteType;
    label: string;
}

export class SportsList {
    @observable public loading: boolean;
    private readonly sdkCustomer: SdkCustomer;
    private readonly configComponents: ConfigComponents;
    private readonly translationsStore: TranslationsStore;

    public constructor(
        sdkCustomer: SdkCustomer,
        configComponents: ConfigComponents,
        translationsStore: TranslationsStore,
        private readonly callbacks: {
            getActiveSpecialSportsForView: () => Array<ActiveSpecialSportsType>;
            getSportsAndEventsListAllowed: () => Map<string, number>;
        }
    ) {
        makeObservable(this);
        this.sdkCustomer = sdkCustomer;
        this.configComponents = configComponents;
        this.translationsStore = translationsStore;
        this.loading = true;

        setTimeout(() => {
            this.loading = false;
        }, 7000);
    }

    @computed public get sportsAndEventsListAllowed(): Map<string, number> {
        return this.callbacks.getSportsAndEventsListAllowed();
    }

    @computed public get activeSpecialSportsForView(): Array<ActiveSpecialSportsType> {
        return this.callbacks.getActiveSpecialSportsForView();
    }

    @observable public isMobileMenuOpen: boolean = false;

    @action public setIsMobileMenuOpen(isMobileMenuOpen: boolean): void {
        this.isMobileMenuOpen = isMobileMenuOpen;
    }

    private get sportsToSkip(): Set<string> {
        const out: Set<string> = new Set();

        for (const listToSkip of Object.values(this.sdkCustomer.apiEventListIncludes)) {
            for (const sport of listToSkip) {
                out.add(sport);
            }
        }

        for (const sport of this.configComponents.config.hiddenSportsList) {
            out.add(sport);
        }

        return out;
    }

    @computed public get translatedSports(): Array<SportModelType> {
        const translatedSports: Array<SportModelType> = [];
        const notEmptySports = this.sportsAndEventsListAllowed;
        const hiddenSportsNames = this.sportsToSkip;

        for (const sport of notEmptySports.keys()) {
            if (!hiddenSportsNames.has(sport)) {
                translatedSports.push({
                    id: sport,
                    label: this.translationsStore.translateSport(sport),
                    route: 'sport',
                });
            }
        }

        for (const sport of this.specialSportsLinks) {
            translatedSports.push({
                id: sport.key,
                label: sport.label,
                route: 'sport-special',
            });
        }

        const sortedSports: Array<SportModelType> = translatedSports.sort((s1, s2) => s1.label.localeCompare(s2.label));
        return sortedSports;
    }

    @computed public get specialSportsLinks(): SportLinkType[] {
        return this.activeSpecialSportsForView.map((specialSport: ActiveSpecialSportsType) => ({
            key: specialSport.slug,
            to: {
                name: 'sport-special',
                id: specialSport.slug,
            },
            label: specialSport.label,
        }));
    }

    @computed public get sportsLinks(): SportLinkType[] {
        return this.translatedSports.map((sport: SportModelType): SportLinkType => {
            switch (sport.route) {
                case 'sport': {
                    if (sport.id === 'horseracing' || sport.id === 'greyhoundracing') {
                        return {
                            key: sport.label,
                            to: {
                                name: 'sport',
                                nameType: 'races',
                                id: sport.id,
                                event: null,
                                type: null,
                            },
                            label: sport.label,
                        };
                    }

                    return {
                        key: sport.label,
                        to: {
                            name: 'sport',
                            nameType: 'regular',
                            id: sport.id,
                        },
                        label: sport.label,
                    };
                }
                case 'sport-special': {
                    return {
                        key: sport.label,
                        to: {
                            name: 'sport-special',
                            id: sport.id,
                        },
                        label: sport.label,
                    };
                }

                default: {
                    return assertNever('', sport.route);
                }
            }
        });
    }
}
