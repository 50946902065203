import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { SettingsFormState } from 'src/domains/players/webview/components/Account/preferencesTab/SettingsForm.state';
import { ButtonsWrapper, Header, SectionWrapper, RadioButtonWrapper } from './SettingsForm.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { GroupOption } from 'src/domains/layouts/webview/components/form/radioButtons/RadioButtons';
import { useAppStateContext } from 'src/appState/AppState';
import { AccountModel } from 'src/domains/players/state/accountState/AccountModel';

const oddsFormatOptions: Array<GroupOption<'fractional' | 'decimal'>> = [
    {
        value: 'fractional',
        label: <I18n langKey='account.summary.odds-type.fractional.label' defaultText='Fractional' />
    },
    {
        value: 'decimal',
        label: <I18n langKey='account.summary.odds-type.decimal.label' defaultText='Decimal' />
    },
];

const balanceInHeaderOptions: Array<GroupOption<'show' | 'hide'>> = [
    {
        value: 'show',
        label: <I18n langKey='account.summary.balance.show' defaultText='Show' />
    },
    {
        value: 'hide',
        label: <I18n langKey='account.summary.balance.hide' defaultText='Hide' />,
    },
];


interface SettingsFromPropsType {
    account: AccountModel;
}

const SettingsFrom = observer<SettingsFromPropsType>('SettingsFrom', ({ account }) => {
    const { localStorageState, appPlayersState } = useAppStateContext();
    const [state] = useState(() => new SettingsFormState(appPlayersState.usersState, account, localStorageState));

    return <>
        <SectionWrapper>
            <Header data-test='odds-format-header'>
                <I18n langKey='account.contact-preferences-tab.settings-odds-format' defaultText='Odds format' />
            </Header>
            <ButtonsWrapper>
                <RadioButtonWrapper gap='35' name='odds-format' color='light' group={oddsFormatOptions} onChangeCallback={state.updateOddsTypeIfChanged} selectValue={state.oddsType} />
            </ButtonsWrapper>
        </SectionWrapper>

        <SectionWrapper>
            <Header data-test='balance-in-header'>
                <I18n langKey='account.contact-preferences-tab.settings-balance-in-header' defaultText='Balance in header' />
            </Header>
            <ButtonsWrapper>
                <RadioButtonWrapper gap='35' name='balance-in-header' color='light' group={balanceInHeaderOptions} onChangeCallback={state.updateBalanceInHeaderIfChanged} selectValue={state.balanceInHeader} />
            </ButtonsWrapper>
        </SectionWrapper>
    </>;
});

export const SettingsFromWrapper = observer('SettingsFromWrapper', () => {
    const { appPlayersState } = useAppStateContext();
    const { account } = appPlayersState.accountState;

    if (account !== null) {
        if (account.basicDataReady !== null) {
            return <SettingsFrom account={account} />;
        }
    }

    return null;
});
