export function amountViewToValue(view: string): string {
    const escapedValue = String(view)
        .replace(/,/g, '.') // converts ',' to '.'
        .replace(/[^0-9\.]+/g, ''); // allow digits only

    const totalAndFractional = escapedValue
        .split('.') // split by '.'
        .slice(0, 2); // slice, just in case many '.' passed

    let total: string | undefined;
    let dot: string | undefined;
    let fractional: string | undefined;

    switch (totalAndFractional.length) {
        case 1: // only total passed
            total = totalAndFractional[0];
            break;
        case 2: // total and fractional passed
            total = totalAndFractional[0];
            fractional = totalAndFractional[1];
            if (fractional !== undefined) {
                dot = '.';
                fractional = fractional.slice(0, 2); // two decimals
            }
            break;
    }

    if (totalAndFractional.length > 0) {
        const amountView = `${total ?? ''}${dot ?? ''}${fractional ?? ''}`;
        const amountValue = parseFloat(amountView);
        if (isNaN(amountValue)) {
            return '';
        } else {
            return amountView;
        }
    }

    return '';
}
