import { RefObject } from 'react';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { ChatMessageType } from 'src_server/trpc/types/chat';

export class MessageState {
    private readonly observer: IntersectionObserver;
    private readonly observerOptions: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 0.25,
    };

    public constructor(
        private readonly message: ChatMessageType,
        private readonly messageRef: RefObject<HTMLLIElement>,
        private readonly traderChatState: TraderChatState
    ) {
        this.observer = new IntersectionObserver(this.handleRevealMessage, this.observerOptions);
    }

    public observe = (): void => {
        if (this.messageRef.current !== null) {
            this.observer.observe(this.messageRef.current);
        }
    };

    private handleRevealMessage = (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void => {
        entries.forEach((change) => {
            if (change.intersectionRatio > 0) {
                this.traderChatState.readMessage(this.message);
                observer.unobserve(change.target);
            }
        });
    };

    public unobserve = (): void => {
        this.observer.disconnect();
    };
}
