import { insertIncomeAccessPixelWrapper } from 'src/domains/players/state/accountState/insertIncomeAccessPixelWrapper';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { LoginResponseType, Session } from 'src_common/sdk/session';

export const loginUser = async (session: Session, disable_geo: true | undefined, email: string, password: string, type: 'login' | 'registration', googleTagManager: GoogleTagManagerState): Promise<LoginResponseType> => {
    const loginStatus = await session.loginCustomer(email, password, disable_geo ?? false);
    if (loginStatus.type !== 'CreateSessionResponseOk') {
        return loginStatus;
    }

    const accountId = loginStatus.accountId;

    googleTagManager.loginTag(accountId);
    insertIncomeAccessPixelWrapper(accountId, type);

    return loginStatus;
};
