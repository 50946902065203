import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    WithdrawProcedureBtnWrapper,
    WithdrawInput,
    BalanceValue,
    LabelInput,
    BalanceWrapperSecondary,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdraw/WithdrawProcedure.style';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import {
    WithdrawProcedureWrapper,
    WithdrawContainer,
    WithdrawTypeSelect,
} from 'src/domains/players/webview/components/Account';
import { WithdrawZenetPayFormState } from './WithdrawZenetPayFormState';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { PaymentProviderImages } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/zenetPayProvider/assets/PaymentProviderImages';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface WithdrawProcedurePropType {
    state: WithdrawZenetPayFormState;
}

export const WithdrawZenetPayProcedure = observer(
    'WithdrawZenetPayProcedure',
    ({ state }: WithdrawProcedurePropType) => {
        const {
            appPlayersState: {
                usersState: { moneySymbol },
            },
        } = useAppStateContext();

        return (
            <WithdrawProcedureWrapper data-test='withdraw-zenet-pay-procedure-wrapper'>
                <BalanceWrapperSecondary>
                    <span data-test='withdrawal-balance-label'>
                        <I18n
                            langKey='account.zenet-pay.withdraw.cash-balance-label'
                            defaultText='Withdrawal balance'
                        />
                    </span>
                    <BalanceValue data-test='balance-value'>{state.showBalance}</BalanceValue>
                </BalanceWrapperSecondary>

                <WithdrawContainer>
                    <WithdrawTypeSelect
                        dataTest='pix-key-select'
                        label={
                            <LabelInput data-test='pix-key-select-label'>
                                <I18n langKey='account.withdraw.pixKeySelect' defaultText='Pix Key' />
                            </LabelInput>
                        }
                        options={state.pixKeyOptions}
                        inputState={state.pixKeyOption}
                        isDisabled={state.isWithdrawFormSubmitting}
                        placeholder=''
                    />
                    {state.pixKeyState.value === '' ? null : (
                        <Input
                            key={state.pixKeyState.value}
                            type='text'
                            label={<LabelInput>{state.pixKeyStateLabel}</LabelInput>}
                            state={state.pixKeyState}
                            isDisabled={true}
                            dataTest='pix-key-input'
                            colorTheme='light'
                            inputHasBackground={false}
                        />
                    )}
                    <WithdrawInput
                        type='text'
                        maxLength={10}
                        placeholder='0.00'
                        label={
                            <LabelInput>
                                <I18n
                                    langKey='account.zenet-pay.withdraw.description-label'
                                    defaultText='Withdrawal amount'
                                />
                            </LabelInput>
                        }
                        currency={moneySymbol}
                        state={state.amountState}
                        onBlur={state.setAmount}
                        colorTheme='light'
                        inputHasBackground={false}
                        dataTest='withdraw-amount-input'
                    />
                    <PaymentAdditions
                        key='payment-additions'
                        value={state.amountState.value}
                        currency={moneySymbol}
                        options={['50', '100', '250', '500']}
                        onChange={state.handlePaymentAdditionsChange}
                    />
                </WithdrawContainer>

                {state.withdrawErrorMessage === null ? null : (
                    <Messages type='error' message={state.withdrawErrorMessage} />
                )}

                {state.isExceededWithdrawalBalance === false ? null : (
                    <Messages
                        type='error'
                        message={
                            <I18n
                                langKey='errors.withdrawable-balance-at-least'
                                defaultText='You have exceeded your withdrawable balance.'
                            />
                        }
                    />
                )}

                <WithdrawProcedureBtnWrapper
                    size='large'
                    onClick={state.submitWithdrawForm}
                    disabled={
                        state.isButtonDisabled || state.isExceededWithdrawalBalance || state.isWithdrawFormSubmitting
                    }
                    dataTest='withdraw-procedure-btn'
                >
                    <I18n langKey='account.withdraw.submit.label' defaultText='Withdraw' />
                </WithdrawProcedureBtnWrapper>
                <PaymentProviderImages />
            </WithdrawProcedureWrapper>
        );
    }
);
