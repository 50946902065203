import React, { useEffect, useRef, useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { MessageState } from 'src/domains/players/webview/components/Account/traderChat/components/Message.state';
import { observer } from 'src/utils/mobx-react';
import {
    Author,
    MessageAuthor,
    MessageItem,
} from 'src/domains/players/webview/components/Account/traderChat/components/Message.style';
import { DateTime } from 'src_common/utils/time/time';
import { ChatMessageType } from 'src_server/trpc/types/chat';

interface MessagePropsType {
    message: ChatMessageType;
}

export const Message = observer<MessagePropsType>('Message', ({ message }) => {
    const { appPlayersState } = useAppStateContext();
    const messageRef = useRef<HTMLLIElement>(null);
    const [state] = useState<MessageState>(
        () => new MessageState(message, messageRef, appPlayersState.traderChatState)
    );

    useEffect(() => {
        state.observe();
        return (): void => state.unobserve();
    }, []);

    if (message.type !== 'standard') {
        return null;
    }

    const messageSenderType = message.sender.type;
    const customerMessage = message.sender.type === 'customer';
    const staffMessage = message.sender.type === 'staff';
    const existTime = message.sentAt;
    const time = DateTime.from(message.sentAt)?.format('DD/MM/YYYY HH:mm');
    const author = customerMessage ? (
        <I18n
            langKey='account.trader-chat.sender.you'
            defaultText='You'
        />
    ) : staffMessage ? (
        <I18n
            langKey='account.trader-chat.staff.you'
            defaultText='Support'
        />
    ) : (
        message.sender.name
    );
    const content = message.content?.text;

    return (
        <MessageItem
            ref={messageRef}
            senderType={messageSenderType}
            data-test='message-item'
        >
            {content}
            <MessageAuthor senderType={messageSenderType}>
                <Author senderType={messageSenderType}>{author}</Author>
                {existTime === '' ? null : time}
            </MessageAuthor>
        </MessageItem>
    );
});
