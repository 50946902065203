import { AppState } from 'src/appState/AppState';
import { getBetsTranslation } from 'src/domains/layouts/shared/translations/bets';
import { BetHistoryLegType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

export const renderBetTypeName = (typeBet: string, appState: AppState): string => {
    return getBetsTranslation(appState, typeBet) ?? '';
};

export const getPrice = (leg: BetHistoryLegType, oddsFormat: string): string | number | undefined | null => {
    const spPrice = leg.spPrice ?? null;
    const price = leg.price ?? null;

    if (leg.priceType === 'sp' && spPrice === null) {
        return 'SP';
    }

    if (spPrice !== null) {
        const isBP = leg.priceType === 'bp';
        const spPriceD = spPrice.d ?? null;
        const priceD = price?.d ?? null;
        const priceF = price?.f ?? null;

        if (priceD !== null && priceF !== null && spPriceD !== null && isBP && spPriceD < priceD) {
            return oddsFormat === 'd' ? priceD.toFixed(2) : priceF;
        }

        if (isBP && priceD !== null && priceF !== null) {
            return oddsFormat === 'd' ? priceD.toFixed(2) : priceF;
        }

        return oddsFormat === 'd' && spPriceD !== null ? spPriceD.toFixed(2) : spPrice.f;
    }

    const priceD = price?.d ?? null;
    const priceF = price?.f ?? null;
    if (oddsFormat === 'd' && priceD !== null) {
        return priceD.toFixed(2);
    }
    return priceF ?? 'n/a';
};

export const isBetSP = (legs: Array<BetHistoryLegType>): boolean => {
    return legs.filter((leg) => leg.priceType === 'sp').length > 0;
};
