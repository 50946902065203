import React from 'react';
import { LinkName, QuickHeaderButton } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/QuickHeader.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { QuickHeaderSignUpIcon } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/SignUpIcons';

export const SignUpButtons = observer('SignUpButtons', () => {
    const { appLayoutsState } = useAppStateContext();
    const { starRouter, googleTagManager } = appLayoutsState;

    const handleRedirectToSignUp = (): void => {
        starRouter.redirectToSignUp();
        googleTagManager.gtmSignUpWelcomePage();
    };

    return (
        <QuickHeaderButton
            data-test='account-navigation-signup-link'
            isActive={false}
            onClick={handleRedirectToSignUp}
        >
            <QuickHeaderSignUpIcon />

            <LinkName>
                <I18n langKey='quick-header.sign-up.label' defaultText='Sign Up' />
            </LinkName>
        </QuickHeaderButton>
    );
});
