import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { isBetSP } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/betUtils';
import { useAppStateContext } from 'src/appState/AppState';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface PropsType {
    bet: BetHistoryType,
}

export const BetLegsStake = observer<PropsType>('BetLegsStake', ({ bet }): ReactElement | null => {
    const appState = useAppStateContext();
    const oddsFormat = appState.appPlayersState.usersState.oddsFormatShort;

    const { legs } = bet;

    if (legs.length === 0) {
        return <span>@ n/a</span>;
    }

    if (legs.length > 1) {
        return (
            <span>{`${bet.cashOut ? 'cashout' : bet.status} `}</span>
        );
    }
    const isSP = isBetSP(legs);
    const leg = legs[0] ?? null;

    if (isSP) {
        if (oddsFormat === 'f') {
            return <span>{`@ ${leg?.spPrice?.f ?? '-'} `}</span>;
        } else {
            const priceD = leg?.spPrice?.d ?? null;
            return <span>{`@ ${priceD === null ? '-' : priceD.toFixed(2)} `}</span>;
        }
    } else {
        if (oddsFormat === 'f') {
            return <span>{`@ ${leg?.price?.f ?? '-'} `}</span>;
        } else {
            const priceD = leg?.price?.d ?? null;
            return <span>{`@ ${priceD === null ? '-' : priceD.toFixed(2)} `}</span>;
        }
    }

});
