import React from 'react';

import { LPOverlay } from './PromoSidebarPartials.style';

interface OverlayPropsType {
    shouldDisplay: boolean;
    isRhsMenuVisible: boolean;
}
export const Overlay = ({ shouldDisplay, isRhsMenuVisible }: OverlayPropsType): JSX.Element | null => {
    if (shouldDisplay === true) {
        if (isRhsMenuVisible) {
            return null;
        }
        return <LPOverlay />;
    }
    return null;
};
