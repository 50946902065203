import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface FreeBetIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const FreeBetIcon = observer(
    'FreeBetIcon',
    ({ className, onClick }: FreeBetIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M10.222 6.453c-1.551 0-2.593-.193-3.223-.628-1.55-1.064-2.035-3.094-1.018-4.52C6.54.483 7.508 0 8.55 0c.727 0 1.43.218 2.035.628.727.508 1.308 1.62 1.72 2.659.412-1.063.994-2.175 1.72-2.659A3.623 3.623 0 0116.061 0c1.042 0 2.011.483 2.568 1.305.994 1.426.51 3.456-1.042 4.52-.63.41-1.696.628-3.222.628h-4.143zm-7.972.87h7.996V12.4H2.25V7.323zm1.284 6.502h6.712V24H3.534V13.825zm17.47 0h-6.712V24h6.712V13.825zm-6.712-6.502h7.996V12.4h-7.996V7.323zm.485-5.559c.388-.266.824-.41 1.26-.41.605 0 1.139.265 1.454.725.557.821.242 1.981-.679 2.634-.17.12-.775.387-2.447.387-.46 0-.897-.024-1.236-.049.388-1.329 1.042-2.9 1.648-3.287zm-6.251-.41c-.606 0-1.14.265-1.454.725-.558.797-.243 1.981.702 2.634.194.12.776.387 2.448.387.46 0 .896-.024 1.235-.049-.412-1.305-1.09-2.876-1.672-3.287a2.23 2.23 0 00-1.26-.41h.001z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
