export interface ContainerConfigType {
    containerId: string;
    scriptSrc: string;
  }
  
export const getLiveTrackerContainerConfig = (
    url: string,
): Record<string, ContainerConfigType> => ({
    basketball: {
        containerId: 'the-basketball-sport-widget',
        scriptSrc: `${url}/js/thebasketballsportwidget.min.js`,
    },
    football: {
        containerId: 'the-football-sport-widget',
        scriptSrc: `${url}/js/thefootballsportwidget.min.js`,
    },
    tennis: {
        containerId: 'the-tennis-sport-widget',
        scriptSrc: `${url}/js/thetennissportwidget.min.js`,
    },
});
  
