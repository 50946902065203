import { GenerateUrlApiType, MethodType, ParamsFetchType, ResponseType } from 'src_common/browser/apiUtils';
import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { jsonParse } from 'src_common/common/jsonParse';

const Response200IO = t.interface({
    accountId: t.number,
    access_token: t.string,
});

type Response200Type = t.TypeOf<typeof Response200IO>;

const isResponse200 = createGuard(Response200IO);

const Error400IO = t.interface({
    error: t.string,
    error_description: t.string
});

type Error400Type = t.TypeOf<typeof Error400IO>;

const isError400 = createGuard(Error400IO);


const Error403IO = t.interface({
    code: t.string,
    message: t.string,
});

/*
code: "reset_password_require"
debugDetails: null
details: {login: "grzegorz.szeliga@twoupdigital.com", platformAccountId: 1046}
errors: null
message: "Customer should reset his password"
*/

type Error403Type = t.TypeOf<typeof Error403IO>;

const isError403 = createGuard(Error403IO);


export type LoginResponseType = {
    type: 'ok',
    data: Response200Type,
} | {
    type: 'errors',
    errors: Error400Type
} | {
    type: 'error_access'
    errors: Error403Type,
};

const getAccessToken = (body: string): string | null => {
    const bodyJson = jsonParse(body);
        
    if (bodyJson.type === 'json' && isResponse200(bodyJson.json)) {
        return bodyJson.json.access_token;
    }

    return null;
};

const decodeLoginResponse = (status: number, data: ResponseType): LoginResponseType => {
    if (status === 200 || status === 201) {
        if (data.type === 'json' && isResponse200(data.json)) {
            return {
                type: 'ok',
                data: data.json,
            };
        }

        throw Error(`status ${status} - Incorrect response type`);
    }

    if (status === 400) {
        if (data.type === 'json' && isError400(data.json)) {
            return {
                type: 'errors',
                errors: data.json
            };
        }

        throw Error('status 400 - Incorrect response type');
    }

    if (status === 403 && data.type === 'json') {
        if (isError403(data.json)) {
            return {
                type: 'error_access',
                errors: data.json
            };
        }

        throw Error('status 403 - Incorrect response type');
    }

    throw new Error(`unhandled response ${status}`);
};


const createSession = async (
    params: GenerateUrlApiParamsType<LoginParams>,
    api_url: string,
    api_universe: string,
    api_username: string,
    api_password: string,
    role: string,
    xForwardedFor: string | null,
): Promise<FetchGeneralRawResponseType> => {
    const extraHeaders: Record<string, string> = xForwardedFor === null ? {} : {
        'x-forwarded-for': xForwardedFor
    };

    const sessionResponse = await fetchGeneralRaw('POST', {
        url: `${api_url}/sessions/${api_universe}/${role}`,
        body: {
            username: api_username,
            password: api_password,
            grant_type: 'password',
        },
        extraHeaders: extraHeaders,
        timeout: params.API_TIMEOUT,
        backendToken: params.jwtToken,
    });

    return sessionResponse;
};


const sendRequest = async (params: GenerateUrlApiParamsType<LoginParams>, xForwardedFor: string | null): Promise<GenerateUrlApiType> => {
    const accessTokenResponse = await createSession(
        params,
        params.API_URL,
        params.API_UNIVERSE,
        params.req.body.email,
        params.req.body.password,
        'customer',
        xForwardedFor
    );

    if (accessTokenResponse.status === 201 || accessTokenResponse.status === 200) {
        const accessToken = getAccessToken(accessTokenResponse.body);
        
        if (accessToken === null) {
            const errorResponse = {
                message: 'Incorrect response from BE. Field access_token is missing',
            };

            return {
                passToBackend: false,
                status: 500,
                responseBody: errorResponse,
            };

        } else {
            return {
                passToBackend: false,
                status: accessTokenResponse.status,
                responseBody: accessTokenResponse.body,
                cookie: {
                    key: 'website.sid',
                    value: accessToken
                }
            };
        }
    }

    return {
        passToBackend: false,
        status: accessTokenResponse.status,
        responseBody: accessTokenResponse.body,
    };
};

interface LoginParams {
    email: string,
    password: string,
    disable_geo?: true,
}


export const loginRequest = {
    browser: {
        params: (params: LoginParams): ParamsFetchType<LoginParams> => {
            return {
                type: MethodType.POST,
                url: '/api-web/user/session',
                body: params
            };
        },
        decode: decodeLoginResponse,
    },
    express: {
        method: MethodType.POST,
        urlBrowser: '/api-web/user/session',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<LoginParams>): Promise<GenerateUrlApiType> => {
        const disable_geo = params.req.body.disable_geo;

        if (disable_geo === true) {
            return await sendRequest(params, null);
        }


        const xForwardedFor = params.getHeader('x-forwarded-for') ?? params.req?.connection?.remoteAddress;

        if (xForwardedFor === undefined) {
            return {
                passToBackend: false,
                status: 400,
                responseBody: {
                    errorMessage: 'Missing header "x-forwarded-for"',
                }
            };
        }

        return await sendRequest(params, xForwardedFor);
    },
};
