import { slug } from 'src/utils/deburr';
import DEFAULT from 'src/domains/sportsbook/webview/components/eventsList/eventRowNHL/assets/logos/default-nhl-team.png';

import AC from './assets/logos/AC.png';
import AD from './assets/logos/AD.png';
import BB from './assets/logos/BB.png';
import BS from './assets/logos/BS.png';
import CA from './assets/logos/CA.png';
import CB from './assets/logos/CB.png';
import CBJ from './assets/logos/CBJ.png';
import CF from './assets/logos/CF.png';
import CH from './assets/logos/CH.png';
import DRW from './assets/logos/DRW.png';
import DS from './assets/logos/DS.png';
import EO from './assets/logos/EO.png';
import FP from './assets/logos/FP.png';
import LAK from './assets/logos/LAK.png';
import MC from './assets/logos/MC.png';
import MW from './assets/logos/MW.png';
import NJD from './assets/logos/NJD.png';
import NJI from './assets/logos/NJI.png';
import NP from './assets/logos/NP.png';
import NYR from './assets/logos/NYR.png';
import OS from './assets/logos/OS.png';
import PF from './assets/logos/PF.png';
import PP from './assets/logos/PP.png';
import S from './assets/logos/S.png';
import SEA from './assets/logos/SEA.png';
import SJS from './assets/logos/SJS.png';
import SLB from './assets/logos/SLB.png';
import TBL from './assets/logos/TBL.png';
import TML from './assets/logos/TML.png';
import VC from './assets/logos/VC.png';
import VGK from './assets/logos/VGK.png';
import WC from './assets/logos/WC.png';
import WJ from './assets/logos/WJ.png';

export const getTeamShirt = (name: string | null): string => {
    if (name !== null) {
        const slugName = slug(name);
        switch (slugName) {
            case 'arizona-coyotes':
                return AC;
            case 'anaheim-ducks':
                return AD;
            case 'boston-bruins':
                return BB;
            case 'buffalo-sabres':
                return BS;
            case 'colorado-avalanche':
                return CA;
            case 'chicago-blackhawks':
                return CB;
            case 'columbus-blue-jackets':
                return CBJ;
            case 'calgary-flames':
                return CF;
            case 'carolina-hurricanes':
                return CH;
            case 'detroit-red-wings':
                return DRW;
            case 'dallas-stars':
                return DS;
            case 'edmonton-oilers':
                return EO;
            case 'florida-panthers':
                return FP;
            case 'los-angeles-kings':
                return LAK;
            case 'montreal-canadiens':
                return MC;
            case 'minnesota-wild':
                return MW;
            case 'new-jersey-devils':
                return NJD;
            case 'new-york-islanders':
                return NJI;
            case 'nashville-predators':
                return NP;
            case 'new-york-rangers':
                return NYR;
            case 'ottawa-senators':
                return OS;
            case 'philadelphia-flyers':
                return PF;
            case 'pittsburgh-penguins':
                return PP;
            case 'seattle':
                return S;
            case 'seattle-kraken':
                return SEA;
            case 'san-jose-sharks':
                return SJS;
            case 'st-louis-blues':
                return SLB;
            case 'tampa-bay-lightning':
                return TBL;
            case 'toronto-maple-leafs':
                return TML;
            case 'vancouver-canucks':
                return VC;
            case 'vegas-golden-knights':
                return VGK;
            case 'washington-capitals':
                return WC;
            case 'winnipeg-jets':
                return WJ;
        }
    }

    return DEFAULT;

};
