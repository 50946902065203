import { computed, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getCopyrightFooter';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { TrpcClient } from 'src/appState/TrpcClient';

export class FooterCopyrightState {
    private readonly footerCopyrightResource: Resource<Response200Type | null>;

    public constructor(
        private readonly config: ConfigType,
        private readonly trpc: TrpcClient
    ) {
        makeObservable(this);

        this.footerCopyrightResource = new Resource(async (): Promise<Response200Type | null> => {
            const response = await this.trpc.client.cms.getFooterCopyrightText.query();

            if (response.status === 200) {
                return response.body;
            }

            if (response.status === 404) {
                return null;
            }

            console.error(`Unexpected http response code=${response.status}`, response);
            return null;
        });
    }

    private get data(): Omit<Response200Type, 'universe'> {
        const data = this.footerCopyrightResource.get();

        if (data.type === 'ready' && data.value !== null) {
            return data.value;
        }

        return this.config.footerCopyrightTemporaryDefaultMock;
    }

    @computed public get licenceNumber(): string {
        return this.data.licence_number;
    }

    @computed public get licenceUrl(): string | null {
        return this.data.licence_url ?? null;
    }

    @computed private get descriptionTextInner(): string {
        return this.data.description_text ?? '';
    }

    @computed public get copyrightDescription(): Array<string> | string {
        if (this.descriptionTextInner.includes('{{licenceNumber}}')) {
            return this.descriptionTextInner.split('{{licenceNumber}}');
        }

        return this.descriptionTextInner;
    }
}
