import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const MeetingsMonthStyle = styled('article', { label: 'MeetingsMonthStyle' })`
    width: 100%;
`;

interface MeetingsRowPropsType {
    isOpen: boolean;
}

export const MeetingsRow = withConfig(theme => styled('div', { label: 'MeetingsRow' })<MeetingsRowPropsType>`
    border-bottom: 1px solid ${theme.star.meetingsAntePost.borderColor};
    color: ${theme.star.meetingsAntePost.txtColor};
    cursor: pointer;
    display: ${({ isOpen }): string => isOpen ? 'flex' : 'none'};
    font-size: ${theme.star.fontSize.small};
    flex-direction: column;
    line-height: 1.3333;
    padding: 5px 8px;
    position: relative;
    :nth-of-type(odd) {
        border-right: 1px solid ${theme.star.meetingsAntePost.borderColor};
        &:last-of-type {
            border-bottom: none;
        }
    }
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 50%;
    }
`);

export const ArrowRightWrapper = withConfig(theme => styled(ChevronIcon, { label: 'ArrowRightWrapper' })`
    fill: ${theme.star.meetingsAntePost.bgColorSenary};
    position: absolute;
    right: 18px;
    top: 14px;
    width: 12px;
`);

export const ElementsContainer = withConfig(theme => styled('div', { label: 'ElementsContainer' })`
    background-color: ${theme.star.meetingsAntePost.bgColor};
    border-top: 1px solid ${theme.star.meetingsAntePost.borderColor};
    display: block;
    @media ${theme.star.mediaQuery.tablet} {
        display: flex;
        flex-flow: row wrap;
    }
`);

export const MeetingsMeta = withConfig(theme => styled('span', { label: 'MeetingsMeta' })`
    color: ${theme.star.meetingsAntePost.txtColorSecondary};
    line-height: 1.4;
    font-size: ${theme.star.fontSize.xSmall};
`);

export const MeetingsSubheader = withConfig(theme => styled('div', { label: 'MeetingsSubheader' })`
    align-items: center;
    background-color: ${theme.star.headings.market.bgColor};
    color: ${theme.star.headings.market.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: flex-start;
    line-height: 1.333;
    padding: 4px 0;
    user-select: none;
`);

interface HrPropsType {
    isOpen: boolean;
}

export const Hr = withConfig(theme => styled('hr', { label: 'Hr' })<HrPropsType>`
    background-color: ${theme.star.headings.market.borderColor};
    border: 0;
    display: ${({ isOpen }): string => isOpen ? 'none': 'block' };
    height: 1px;
    margin: 0;
    &:last-of-type {
        display: none;
    }
`);

export const MeetingsChevron = styled(ChevronIcon, { label: 'MeetingsChevron' })`
    height: 100%;
    width: 12px;
    margin: 0 8px;
    fill: currentcolor;
`;
