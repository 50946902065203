import { createGuard } from 'src_common/common/createGuard';
import * as t from 'io-ts';

const OptionDataIO = t.interface({
    value: t.string,
    sportId: t.union([t.string, t.undefined]),
    label: t.string,
});

export type OptionType = t.TypeOf<typeof OptionDataIO>;

export const checkOptionType = createGuard(OptionDataIO);

export const sortOptions = (a: OptionType, b: OptionType): number => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};
