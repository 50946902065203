import * as t from 'io-ts';
import { computed, makeObservable } from 'mobx';
import { Value } from 'src_common/common/mobx-utils/Value';
import { SocketController } from 'src_common/common/mobx-utils/Socket/SocketController';
import { SocketMap } from 'src_common/common/mobx-utils/Socket/SocketMap';
import { assertNever } from 'src_common/common/assertNever';
import { createGuard } from 'src_common/common/createGuard';

type MessageTableList = {
    casinoId: string;
    type: 'available';
};

type MessageTo = MessageTableList;

const MessageFromTableListIO = t.interface({
    tableKey: t.array(t.string),
});

type MessageFromTableList = t.TypeOf<typeof MessageFromTableListIO>;

export const isMessageFromTableListModel = createGuard(MessageFromTableListIO);

const createMessageSubscriptions = (casinoId: string): MessageTo => ({
    casinoId,
    type: 'available'
});

class MessageFromTableListModel {
    public constructor(
        private readonly data: Value<MessageFromTableList>
    ) { }

    public static create(data: Value<MessageFromTableList>): MessageFromTableListModel {
        return new MessageFromTableListModel(data);
    }

    public getData(): MessageFromTableList {
        return this.data.getValue();
    }
}

export class SocketGameList {
    private readonly socketController: SocketController;
    private readonly casinoListSocketMap: SocketMap<string, MessageFromTableList, MessageFromTableListModel>;


    public constructor(
        public readonly host: string,
        public readonly casinoId: string,
        public readonly isBrowser: boolean
    ) {
        makeObservable(this);
        this.host = host;
        this.casinoId = casinoId;

        this.socketController = new SocketController(
            'RTL Pragmatic - GameList',
            host,
            isBrowser,
            10000,
            50
        );

        this.casinoListSocketMap = new SocketMap(
            this.socketController,
            (casinoId: string): string => {
                return JSON.stringify(createMessageSubscriptions(
                    casinoId
                ));
            },
            null,
            MessageFromTableListModel.create
        );

        this.socketController.onData((data) => {
            if (data.type === 'open') {
                this.casinoListSocketMap.onNewSocket(data.socket);
                return;
            }

            if (data.type === 'message') {
                const json = JSON.parse(data.message);
                if (isMessageFromTableListModel(json)) {
                    this.casinoListSocketMap.onMessage(casinoId, json);
                } else {
                    console.error('socket, model SocketGameList: message decoding error', json);
                }

                return;
            }

            if (data.type === 'close') {
                return;
            }

            return assertNever('socketController data.type', data);
        });
    }

    @computed public get list(): Array<string> | null {
        const model = this.casinoListSocketMap.getById(this.casinoId);
        if (model === null) {
            return null;
        }

        return model.getData().tableKey;
    }
}
