import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';

export const BannerSection = styled('div', { label: 'BannerSection' })`
    padding-top: 64px;
`;

export const SliderWrapper = styled('div', { label: 'SliderWrapper' })`
    position: relative;
`;

export const BannersWrapper = styled('div', { label: 'BannersWrapper' })`
    display: grid;
    grid-template: 'banner' auto;
    grid-template-areas: 'banner';
    overflow-x: hidden;
`;

export const BannerWrapper = withConfig(theme => styled('div', { label: 'BannerWrapper' })<{offset: number}>`
    position: relative;
    padding-right: 16px;
    transform: translateX(${({ offset }): number => offset * 100}%);
    transition: transform .5s ease;
    grid-area: banner;
    @media ${theme.star.mediaQuery.desktop} {
        padding-right: 24px;
    }
`);

export const BannerImage = styled(LazyLoadingImg, { label: 'BannerImage' })`
    display: block;
    cursor: pointer;
    width: 100%;
`;

export const TermsWrapper = withConfig(theme => styled('div', { label: 'TermsWrapper' })`
    display: inline-block;
    margin-top: 8px;
    color: ${theme.star.headerMain.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    cursor: pointer;
`);
