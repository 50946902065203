import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface PropsType {
    className?: string;
    onClick?: () => void;
}

export const ExpandIcon = observer('ExpandIcon', ({ className, onClick }: PropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#fff'
            height='16'
            onClick={onClick}
            viewBox='0 0 16 16'
            width='16'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M15.333 10.444v4.89h-4.889l1.872-1.872-2.368-2.33 1.184-1.184 2.33 2.368 1.871-1.872zm-9.777 4.89H.666v-4.89l1.872 1.872 2.33-2.368 1.184 1.184-2.368 2.33 1.872 1.871zM.666 5.555V.666h4.89L3.684 2.539l2.368 2.33-1.184 1.184-2.33-2.368L.667 5.556zm9.778-4.89h4.89v4.89l-1.872-1.872-2.33 2.368-1.184-1.184 2.368-2.33L10.444.667z'
                fill='fill'
            />
        </SvgElement>
    );
});
