import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    FooterColumnMobile,
    FooterNavigation,
    MainContentWrapper,
    MainFooter,
    MainWrapper,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent.style';
import { PaymentMethod } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/paymentMethods/PaymentMethods';
import { ContactUs } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/contactUs/ContactUs';
import { MobileFooter } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/mobileFooter/MobileFooter';
import { GetTheApp } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/getTheApp/GetTheApp';
import { AboutPages } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/aboutPages/AboutPages';
import { FooterCopyright } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/copyright/FooterCopyright';
import { ResponsibleGambling } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/responsibleGambling/ResponsibleGambling';
import { UniverseComponent } from 'src/domains/common/universes';

export const FooterContent = observer('FooterContent', () => (
    <>
        <MainFooter>
            <MainWrapper>
                <MainContentWrapper>
                    <FooterColumnMobile>
                        <FooterNavigation>
                            <AboutPages />
                        </FooterNavigation>

                        <FooterNavigation>
                            <ContactUs />

                            <GetTheApp />
                        </FooterNavigation>
                    </FooterColumnMobile>

                    <UniverseComponent>
                        {(universeComponent): React.ReactElement => (
                            <FooterColumnMobile>
                                {universeComponent.paymentLogotypes() === null ? null : (
                                    <FooterNavigation>
                                        <PaymentMethod />
                                    </FooterNavigation>
                                )}
                                <FooterNavigation>
                                    <ResponsibleGambling />
                                </FooterNavigation>
                            </FooterColumnMobile>
                        )}
                    </UniverseComponent>
                </MainContentWrapper>

                <FooterCopyright />
            </MainWrapper>
        </MainFooter>

        <MobileFooter />
    </>
));
