import * as React from 'react';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { getSimpleDate, getSimpleDateTodayTomorrow } from 'src/utils/date';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    EventRowInPlayInfo,
    EventInPlayIcon,
    EventRowTimeInfo,
    EventStatus,
} from 'src/domains/sportsbook/webview/components/events/row/StatusAndDate.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BabIconWrapper } from 'src/domains/sportsbook/webview/components/events/row/BabIconWrapper';
import { DateTime } from 'src_common/utils/time/time';

interface EventStatusAndDatePropsType {
    dateFormat: 'onlyToday' | 'todayAndTomorrow';
    marketType?: '6pack';
    smallEvent: EventListGroupEventItemType;
}

export const EventStatusAndDate = observer('EventStatusAndDate', (props: EventStatusAndDatePropsType) => {
    const { smallEvent, dateFormat, marketType } = props;

    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const eventModel = smallEvent.id2.getEventModel();

    const { languagesState } = appLayoutsState;

    if (eventModel === null) {
        return <>{getSimpleDate(DateTime.from(smallEvent.startTime)?.toISOString() ?? 'time error', languagesState)}</>;
    }

    const isRabAvailable = appSportsBookState.rab.isAvailableForEvent(eventModel.id2);
    const onIconClick = (): void => {
        appSportsBookState.rab.onOpenForEvent(eventModel.id2);
    };

    if (eventModel.timeSettingsStarted) {
        if (marketType === '6pack') {
            return (
                <EventRowInPlayInfo>
                    <EventInPlayIcon />
                    <I18n langKey='events.row.6pack.in-play' defaultText='In-Play' />
                    {isRabAvailable ? <BabIconWrapper onClick={onIconClick} /> : null}
                </EventRowInPlayInfo>
            );
        }
        return (
            <EventStatus>
                <I18n langKey='events.row.in-play' defaultText='In Play' />
                {isRabAvailable ? <BabIconWrapper onClick={onIconClick} /> : null}
            </EventStatus>
        );
    }

    if (eventModel.timeSettingsStarted === false) {
        if (dateFormat === 'todayAndTomorrow') {
            return (
                <EventRowTimeInfo>
                    {getSimpleDateTodayTomorrow(eventModel.timeSettingsStartTime, languagesState)}
                    {isRabAvailable ? <BabIconWrapper onClick={onIconClick} /> : null}
                </EventRowTimeInfo>
            );
        }

        return (
            <>
                {getSimpleDate(eventModel.timeSettingsStartTime, languagesState)}
                {isRabAvailable ? <BabIconWrapper onClick={onIconClick} /> : null}
            </>
        );
    }

    return null;
});
