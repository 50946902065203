import * as React from 'react';

import { slug } from 'src/utils/deburr';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { EventSummaryConnect } from 'src/domains/sportsbook/webview/components/containers/eventSummary/EventSummary';

import { observer } from 'src/utils/mobx-react';
import {
    EventRowWrapper,
    EventRowHeader,
    EventRowMarkets,
    EventRowSelections,
    EventRowSelectionsElement,
    EventRowMeta,
    EventRowNameLink,
} from 'src/domains/sportsbook/webview/components/eventRow/EventRow.style';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { useAppStateContext } from 'src/appState/AppState';
import { Score } from 'src/domains/sportsbook/webview/components/events/row/Score';
import { Participant } from 'src/domains/sportsbook/webview/components/events/row/Participant';
import { EventStatusAndDate } from 'src/domains/sportsbook/webview/components/events/row/StatusAndDate';
import { PossibleMarketAndSelectionInListType } from 'src/domains/sportsbook/state/eventsListState/eventsGroup/getMarketAndSelections';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

interface EventRowComponentSelectionsPropsType {
    smallEvent: EventListGroupEventItemType;
    possibleMarketAndSelectionInList: PossibleMarketAndSelectionInListType;
    dataTest?: string;
}

const EventRowComponentSelections = observer('EventRowComponentSelections', (props: EventRowComponentSelectionsPropsType) => {
    const appState = useAppStateContext();

    const { smallEvent, possibleMarketAndSelectionInList, dataTest } = props;

    const eventSelections = possibleMarketAndSelectionInList.markets[smallEvent.id];

    if (eventSelections === undefined) {
        return null;
    }

    const [marketId, selections] = eventSelections;

    return (
        <EventRowSelections data-test={dataTest}>
            {selections.map((selectionId) => {
                const newSelectionId = selectionId === null ? undefined : appState.appSportsBookState.models.id.getSelectionId(
                    smallEvent.id,
                    marketId.toOldId(),
                    selectionId
                );

                return (
                    <EventRowSelectionsElement key={selectionId}>
                        <Selection
                            borderLeft={true}
                            borderRight={true}
                            selectionId={newSelectionId}
                        />
                    </EventRowSelectionsElement>
                );
            })}
        </EventRowSelections>
    );
});

interface PropsType {
    smallEvent: EventListGroupEventItemType;
    possibleMarketAndSelectionInList: PossibleMarketAndSelectionInListType;
    filterMarketsByPopularTag: boolean;
    sport?: string;
}

export const EventRow = observer('EventRow', (props: PropsType) => {
    const appState = useAppStateContext();
    const { sport, smallEvent } = props;
    const eventId = smallEvent.id2;
    const { possibleMarketAndSelectionInList, filterMarketsByPopularTag } = props;

    if (eventId.getEventModel()?.display === false) {
        return null;
    }

    const isOutrightEvent = smallEvent.template === 'outrights';

    const isAntePostRace = sport !== undefined && (sport === 'horseracing' || sport === 'greyhoundracing') && smallEvent.template !== 'outrights';

    const linkTo: RouteViewType = isAntePostRace ?
        {
            name: 'sport',
            nameType: 'races',
            id: 'horseracing',
            type: 'ante-post',
            event: { 
                id: eventId.toOldId(),
                slug: slug(smallEvent.name)
            }
        } :
        {
            name: 'event',
            id: eventId.toOldId(),
            slug: slug(smallEvent.name)
        };

    return (
        <EventRowWrapper data-test='event-row'>
            <EventRowHeader>
                <EventRowNameLink to={linkTo} dataTest='EventRowNameLink'>
                    <Participant smallEvent={smallEvent} />

                    <EventRowMeta data-test='event-status-and-date'>
                        <EventStatusAndDate
                            dateFormat='onlyToday'
                            smallEvent={smallEvent}
                        />
                    </EventRowMeta>

                </EventRowNameLink>

                <Score smallEvent={smallEvent} dataTest='event-score' />
            </EventRowHeader>
            {isOutrightEvent ? (
                <EventRowMarkets>
                    <EventSummaryConnect id={appState.appSportsBookState.models.id.getEventId(smallEvent.id)} filterMarketsByPopularTag={filterMarketsByPopularTag} />
                </EventRowMarkets>
            ) : (
                <EventRowComponentSelections
                    smallEvent={smallEvent}
                    possibleMarketAndSelectionInList={possibleMarketAndSelectionInList}
                    dataTest='event-selection-component'
                />
            )}
        </EventRowWrapper>
    );
});
