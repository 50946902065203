import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const AddTooAllWrapper = styled('div', { label: 'AddTooAllWrapper' })`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 16px 0;
    padding-bottom: 20px;
`;

export const AddTooAllTitle = withConfig(theme => styled('div', { label: 'AddTooAllTitle' })`
    color: ${theme.star.betslipGeneral.txtColor};
    font-weight: ${theme.star.fontWeight.medium};
    font-size: ${theme.star.fontSize.xRegular};
    padding-bottom: 10px;
    line-height: 1.43;
    margin: 0 4px 0 0;
`);

export const AddTooAllInputWrapper = styled('div', { label: 'AddTooAllInputWrapper' })`
    height: 30px;
`;
