import { AccountBasicDataType } from 'src/domains/players/state/UsersState';
import { z } from 'zod';

const isParsedError = (value: string | undefined | null): boolean => {
    if (value === undefined || value === null || value.trim() === '') {
        return true;
    }

    return false;
};

interface BillingInfoData {
    email: string | undefined | null;
    prefix: string | null;
    number: string | null;
    addressLine1: string | undefined | null;
    addressLine2: string | undefined | null;
    city: string | undefined | null;
    country: string | undefined | null;
    postCode: string | undefined | null;
}
export interface BillingInfoError {
    type: 'error';
    fieldsRequired: string[];
    data: BillingInfoData;
}
export interface BillingInfoSuccess {
    type: 'ok';
    data: {
        email: string;
        prefix: string;
        number: string;
        addressLine1: string;
        addressLine2: string | undefined | null;
        city: string;
        country: string;
        postCode: string | undefined | null;
    };
}


const billingInfoFields = z.object({
    email: z.string(),
    prefix: z.string(),
    number: z.string(),
    addressLine1: z.string(),
    city: z.string(),
    country: z.string(),
});


export type ValidateBillingInfoFields = z.infer<typeof billingInfoFields>;

export const isValidBillingIfoFields = (value: unknown): value is ValidateBillingInfoFields =>
    billingInfoFields.safeParse(value).success;



export const billingInfoFieldsValidationDeposit = (basicData: Omit<AccountBasicDataType, 'oddsFormat'> | null): BillingInfoError | BillingInfoSuccess | undefined => {

    if (basicData !== null) {

        const dataNotValidated = {
            addressLine2: basicData.addressLine2,
            postCode: basicData.postCode
        };

        const dataToValid = {
            email: basicData.email,
            prefix: basicData.mobilePhone?.prefix ?? null,
            number: basicData.mobilePhone?.number ?? null,
            addressLine1: basicData.addressLine1,
            city: basicData.city,
            country: basicData.country,
        };

        if (isValidBillingIfoFields(dataToValid)) {
            return {
                type: 'ok',
                data: { ...dataToValid, ...dataNotValidated }
            };
        }

        const fieldsRequired: Array<string> = [];

        if (isParsedError(dataToValid['email'])) {
            fieldsRequired.push('email');
        }
        if (isParsedError(dataToValid['prefix'])) {
            fieldsRequired.push('prefix phone number');
        }
        if (isParsedError(dataToValid['number'])) {
            fieldsRequired.push('phone number');
        }
        if (isParsedError(dataToValid['addressLine1'])) {
            fieldsRequired.push('address');
        }
        if (isParsedError(dataToValid['city'])) {
            fieldsRequired.push('city');
        }
        if (isParsedError(dataToValid['country'])) {
            fieldsRequired.push('country');
        }

        return {
            type: 'error',
            fieldsRequired,
            data: { ...dataToValid, ...dataNotValidated }
        };
    };
    return undefined;
};
