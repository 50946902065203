//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    requestBody: {
        currency: string;
        customer: {
            address: string;
            city: string;
            country_code: string;
            dob: string;
            email: string;
            first_name: string;
            last_name: string;
            phone: {
                country: string;
                number: string;
                prefix: string;
            };
            zip_code: string;
        };
        ip: string;
        theme: string;
    };
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/customer/swifity/deposit/initiate -> post -> ${code}`);
    };
};

export const Response200IO = t.union([t.interface({
    errors: t.array(t.interface({
        code: t.string,
        debugDetails: t.string,
        field: t.string,
        resource: t.string,
    })),
    status: t.literal('422'),
    type: t.literal('SwiftyErrorResponse'),
}), t.interface({
    bodyJson: t.interface({
        iframeUrl: t.string,
    }),
    status: t.literal('200'),
    type: t.literal('SwiftySuccessResponse'),
})]);
export type Response200Type = {
    errors: Array<{
        code: string;
        debugDetails: string;
        field: string;
        resource: string;
    }>;
    status: '422';
    type: 'SwiftyErrorResponse';
} | {
    bodyJson: {
        iframeUrl: string;
    };
    status: '200';
    type: 'SwiftySuccessResponse';
};
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiProxyCustomerBankingSwiftyglobalDepositInitiateRequest"
 */
export const openapi_proxy_customer_banking_swiftyglobal_deposit_initiate = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/customer/swifity/deposit/initiate${query}`;
    
    const method = 'POST';
    const paramsFetch = {
        url,
        body: params.requestBody,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateParamsType = ParamsType;

export type OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateResponse200Type = Response200Type;

export const openapiProxyCustomerBankingSwiftyglobalDepositInitiateRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiProxyCustomerBankingSwiftyglobalDepositInitiateResponseType> => {
    const response = await openapi_proxy_customer_banking_swiftyglobal_deposit_initiate(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiProxyCustomerBankingSwiftyglobalDepositInitiateRequest - unhandled response ${response.status}`);
};

