import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { PhoneNumberState } from './PhoneNumber.state';
import { LabelTextWrapper, PhoneNumberWrapper, PrefixWrapper, Phone } from './PhoneNumber.style';
import { SelectPrefix } from 'src/domains/players/webview/components/form/select/SelectPrefix';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsType {
    state: PhoneNumberState;
    hasPrefixes: boolean;
    placeholder?: string;
    colorTheme?: 'light' | 'dark';
    onBlur?: () => void;
}

export const PhoneNumber = observer('PhoneNumber', (props: PropsType): JSX.Element => {
    const {
        appPlayersState: { customKeyboard },
        config: { priorityPrefixes },
    } = useAppStateContext();
    const { state, hasPrefixes, placeholder, colorTheme, onBlur } = props;

    return (
        <PhoneNumberWrapper>
            <LabelTextWrapper data-test='phone-number-label'>
                <I18n langKey='signup-journey.your-details.phone.title' defaultText='Phone number' />
            </LabelTextWrapper>
            <PrefixWrapper data-test='prefix-dropdown'>
                <SelectPrefix
                    state={state.prefix}
                    hasPrefixes={hasPrefixes}
                    colorTheme={colorTheme}
                    onBlur={onBlur}
                    priorityPrefixes={priorityPrefixes}
                />
            </PrefixWrapper>
            <Phone
                inputTextAlign='left'
                state={state.phoneNumber}
                showSuccessIcon={true}
                inputRef={state.setInputPhoneRef}
                asideElement={customKeyboard.getAsideElement}
                dataTest='number-input'
                placeholder={placeholder ?? ''}
                colorTheme={colorTheme}
                type='tel'
            />
        </PhoneNumberWrapper>
    );
});
