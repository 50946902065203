import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { PromoNotificationsModelType } from 'src/api/config/cms_new/promo_notifications/get_promo_notifications_active';

import { streamButtonBarDesktop } from 'src/domains/sportsbook/shared/Components';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';

import { css, SerializedStyles } from '@emotion/react';



export const setRacingStreamWrapperRMG = (theme: EmotionTheme, isOpen: boolean): SerializedStyles => {

    if (isOpen) {

        return css`

            max-height: 488px;

            @media ${theme.star.mediaQuery.largeDesktop} {

                max-height: 448px;

            }

        `;

    };



    return css`

        max-height: 0;

    `;

};



const setZIndexRacingStreamWrapperRMG = (theme: EmotionTheme, streamIsInViewport?: boolean, isAccountOpen?: boolean): SerializedStyles => {

    if (streamIsInViewport === true || isAccountOpen === true) {

        return css`

            z-index: ${theme.star.zIndexGlobal.base};

        `;

    };



    return css`

        z-index: ${theme.star.zIndexGlobal.floatingStream};

    `;

};



interface RacingStreamWrapperPropsType {

    isOpen: boolean;

    streamIsInViewport?: boolean;

    isAccountOpen?: boolean;

}



export const RacingStreamWrapperRMG = withConfig(theme => styled('div', { label: 'RacingStreamWrapperRMG' })<RacingStreamWrapperPropsType>`

    background-color: ${theme.star.stream.bgColor};

    left: 0;

    overflow: hidden;

    position: fixed;

    top: 0;

    transition: max-height .3s ease;

    width: 100%;

    ${({ streamIsInViewport, isAccountOpen }): SerializedStyles => setZIndexRacingStreamWrapperRMG(theme, streamIsInViewport, isAccountOpen)};

    ${({ isOpen }): SerializedStyles => setRacingStreamWrapperRMG(theme, isOpen)};

    @media ${theme.star.mediaQuery.desktop} {

        position: ${({ streamIsInViewport }): string => streamIsInViewport === true ? 'relative' : 'static'};

    }

`);



const setStreamRMGWrapper = (isOpen: boolean, height: number): SerializedStyles => {

    if (isOpen) {

        return css`

            height: ${height}px;

        `;

    }



    return css`

        height: 0;

    `;

};



interface StreamIframeWrapperRMGPropsType {

    isOpen: boolean;

    height: number;

    bannersHeight: number;

    isNotificationAvailable: null | PromoNotificationsModelType[];

    streamIsInViewport: boolean | undefined;

    isFloating: boolean;

}



export const StreamIframeWrapperRMG = withConfig(theme => styled('div', { label: 'StreamIframeWrapperRMG' })<StreamIframeWrapperRMGPropsType>`

    margin: 0 auto;

    max-width: 600px;

    position: ${({ streamIsInViewport }): string => streamIsInViewport === true ? 'relative' : 'static'};

    transition: transform .2s ease;

    width: 100%;

    z-index: ${theme.star.zIndexGlobal.highLevel};

    ${({ isOpen, height }): SerializedStyles => setStreamRMGWrapper(isOpen, height)};

`);



const setStreamButtonsWrapperRMG = (theme: EmotionTheme, isOpen: boolean): SerializedStyles => {

    if (isOpen) {

        return css`

            height: ${streamButtonBarDesktop}px;

            left: 0;

            position: relative;

            right: 0;

            top: 0;

            width: 100%;

            z-index: ${theme.star.zIndexGlobal.stream};

            @media ${theme.star.mediaQuery.largeDesktop} {

                position: absolute;

                height: 0;

            }

        `;

    };



    return css``;

};



interface StreamButtonsWrapperRMGPropsType {

    isOpen: boolean;

}



export const StreamButtonsWrapperRMG = withConfig(theme => styled('div', { label: 'StreamButtonsWrapperRMG' })<StreamButtonsWrapperRMGPropsType>`

    ${({ isOpen }): SerializedStyles => setStreamButtonsWrapperRMG(theme, isOpen)};

    color: ${theme.star.stream.txtColor};

`);



interface CloseIconPropsType {

    isOpen: boolean;

}



export const CloseStream = withConfig(theme => styled(CloseIcon, { label: 'CloseStream' })<CloseIconPropsType>`

    cursor: pointer;

    fill: currentcolor;

    height: 40px;

    padding: 12px;

    position: absolute;

    right: 0;

    top: 0;

    width: 40px;

    z-index: ${theme.star.zIndexGlobal.above};

`);



export const StreamLoader = withConfig(theme => styled('div', { label: 'StreamLoader' })`

    align-items: center;

    display: flex;

    height: 100%;

    justify-content: center;

    color: ${theme.star.loader.bgColorSecondary};

`);



export const NewRMGPlayerContainer = withConfig(theme => styled('div', { label: 'NewRMGPlayerContainer' })`

    position: relative;

    width: 100%;

    height: 100%;

    z-index: ${theme.star.zIndexGlobal.highLevel};



    .rmg-container {

        z-index: ${theme.star.zIndexGlobal.highLevel};

    }

`);

