import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    RewardNavLink,
    RewardsCounter,
    RewardsCounterWrapper,
    RewardsIconWrapper,
} from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/rewardLinkNav/RewardLinkNav.style';

export const RewardLinkNav = observer('RewardLinkNav', () => {
    const { appLayoutsState } = useAppStateContext();
    const { lifeSpanState, starRouter } = appLayoutsState;
    const activeRewards = lifeSpanState.userRewardsMessage.length;

    const handleOpenLifeSpanWallet = (): void => {
        lifeSpanState.toggleWalletOpen();
        starRouter.closeAccount();
    };

    return (
        <RewardNavLink
            dataTest='account-navigation-rewards'
            isActiveLifespan={lifeSpanState.isWalletOpen}
            onClickCallback={handleOpenLifeSpanWallet}
            routeNameForGtm={null}
        >
            <RewardsCounterWrapper>
                <RewardsIconWrapper />

                {activeRewards > 0 ? <RewardsCounter>{activeRewards}</RewardsCounter> : null}
            </RewardsCounterWrapper>

            <I18n langKey='account.navigation.rewards' defaultText='Rewards' />
        </RewardNavLink>
    );
});
