import * as React from 'react';
import { observer } from 'src/utils/mobx-react';

interface ScoreboardLSportPropsType {
    lSportExternalId: string;
    groupId: string;
}

export const ScoreboardLSport = observer('ScoreboardLSport', ({ lSportExternalId, groupId }: ScoreboardLSportPropsType) => {
    const eventId = lSportExternalId;

    const embedScript = `
        !function(){var d="STATSCOREWidgetsEmbederScript";if(!window.document.getElementById(d)){window.STATSCOREWidgets={},window.STATSCOREWidgets.onLoadCallbacks=[],window.STATSCOREWidgets.onLoad=function(d){window.STATSCOREWidgets.onLoadCallbacks.push(d)};var n=window.document.createElement("script");n.src="https://wgt-s3-cdn.statscore.com/bundle/Embeder.js",n.async=!0,n.id=d,n.addEventListener("error",function(d){for(var n=0;n<window.STATSCOREWidgets.onLoadCallbacks.length;n++)window.STATSCOREWidgets.onLoadCallbacks[n](d)}),window.document.body.appendChild(n)}}();
    `;

    const widgetLoadScript = `
        STATSCOREWidgets.onLoad(err => {
            if (err) {
                switch (err.type) {
                    case 'NetworkError':
                        // Handle network error here
                        break;
        
                    case 'BrowserNotSupported':
                        // Handle unsupported browser here
                        break;
                }
                return;
            }
            const element = document.getElementById('myWidget');
            const configurationId = '${groupId}';
            const inputData = { eventId: 'm:${eventId}', language: 'en' };
            const options = {};
        
            const widget = new window.STATSCOREWidgets.WidgetGroup(element, configurationId, inputData, options);

            widget.on('error', async e => {
                if (e == null) {
                    return;
                }
                await widget.destroy();
            });
        });
    `;

    React.useEffect(() => {
        setTimeout(() => {
            const widgetEmbedScript = document.createElement('script');
            widgetEmbedScript.type = 'text/javascript';
            widgetEmbedScript.innerHTML = embedScript;
            
            const widgetScript = document.createElement('script');
            widgetScript.type = 'text/javascript';
            widgetScript.innerHTML = widgetLoadScript;

            document.body.appendChild(widgetEmbedScript);
            document.body.appendChild(widgetScript);
        }, 0);
    }, []);

    return <div id='myWidget'></div>;
});

