import { action, observable, makeObservable } from 'mobx';

export class CustomKeyboardState {
    @observable public asideElement: HTMLElement | null = null;

    @action public setAsideElementRef = (asideElement: HTMLElement | null): void => {
        this.asideElement = asideElement;
    };

    public getAsideElement = (): HTMLElement | null => {
        return this.asideElement;
    };

    public scrollAsideToTop = (): void => {
        if (this.asideElement !== null) {
            this.asideElement.scrollTo(0, 0);
        }
    }

    constructor() {
        makeObservable(this);
    }
}
