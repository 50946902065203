import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { SrgQuestionnaireSteps, SrgQuestionnaireTitle, SrgQuestionnaireButton, SrgQuestionnaireDescription, SrgQuestionnaireQuestionContainer, SrgQuestionnaireQuestionText, SrgQuestionnaireQuestionsOptionsWrapper, SrgQuestionnaireBackButton, SrgParagraph, SrgGreetingParagraph } from 'src/domains/players/state/srgQuestionnaire/SrgQuestionnaire.style';
import { QuestionSetType, SrgQuestionnaireState } from 'src/domains/players/state/srgQuestionnaire/srgQuestionnaire/SrgQuestionnaireState';
import { GroupOption, RadioButtons } from 'src/domains/layouts/webview/components/form/radioButtons/RadioButtons';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';

const groupValues: Array<GroupOption<'yes' | 'no'>> = [
    {
        value: 'yes',
        label: <I18n langKey='signup.contact-preferences.yes.label' defaultText='Yes' />
    },
    {
        value: 'no',
        label: <I18n langKey='account.contact-preferences.no.label' defaultText='No' />,
    },
];


const renderSteps = (currentSrgStep: number, maxQuestionsLength: number): string | null => {
    if (currentSrgStep === 0) {
        return null;
    }

    return `${currentSrgStep} of ${maxQuestionsLength}`;
};

interface GreetingPropsTypes {
    languagesState: LanguagesState;
    operator?: string;
}
const GreetingPart1 = observer('GamstopInformation', ({ languagesState, operator }: GreetingPropsTypes) => {
    const greetingString = languagesState.translateTokens(
        languagesState.getTranslation(
            'srg.content.greeting-part1',
            'Our aim at [operator] is to make every customer\'s gambling experience enjoyable and fun. Part of our responsible gambling commitment is to check up from time to time to ensure customers aren\'t gambling beyond their means.'
        ),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'operator') {
                return (
                    <span>
                        {operator}
                    </span>
                );
            }
        }
    );

    return <SrgParagraph>{greetingString}</SrgParagraph>;
});

const renderContent = (state: SrgQuestionnaireState, userName: string | undefined, operator: string | undefined, questionSet: QuestionSetType[], languagesState: LanguagesState): JSX.Element => {
    const { currentSrgStep } = state;

    if (currentSrgStep === 0) {
        return (
            <>
                <SrgQuestionnaireDescription data-test='srg-first-description'>
                    <SrgGreetingParagraph>
                        <I18n langKey='srg.content.greeting' defaultText='Hey ' />{userName}
                    </SrgGreetingParagraph>

                    <GreetingPart1 languagesState={languagesState} operator={operator} />

                    <SrgParagraph>
                        <I18n langKey='srg.content.greeting-part2' defaultText='In line with your recent account activity, we kindly ask you to reply with responses to ALL of the following questions. Please note: If we do not receive satisfactory responses to ALL OF THE QUESTIONS, your account may be suspended.' />
                    </SrgParagraph>
                </SrgQuestionnaireDescription>

                <SrgQuestionnaireButton
                    size='large'
                    onClick={state.goToNextStep}
                    dataTest='srg-first-content-button'
                >
                    <I18n langKey='srg.content.button.go-to' defaultText="Let's Go" />
                </SrgQuestionnaireButton>
            </>
        );
    }

    const actualQuestion = questionSet.find(item => item.step === currentSrgStep);

    if (actualQuestion !== undefined) {
        return (
            <>
                <SrgQuestionnaireQuestionContainer isDisabled={actualQuestion.state.value === ''}>
                    <SrgQuestionnaireQuestionText>
                        {actualQuestion.question}
                    </SrgQuestionnaireQuestionText>

                    <SrgQuestionnaireQuestionsOptionsWrapper data-test='answer-radio-buttons'>
                        <RadioButtons gap='24' name='srg-question' color='dark' group={groupValues} state={actualQuestion.state} />
                    </SrgQuestionnaireQuestionsOptionsWrapper>
                </SrgQuestionnaireQuestionContainer>

                {
                    currentSrgStep === state.maxQuestionsLength ?

                        <SrgQuestionnaireButton
                            size='large'
                            onClick={state.onSubmit}
                            disabled={actualQuestion.state.value === ''}
                            isLoading={state.isLoading}
                            dataTest='srg-submit-question-button'
                        >
                            <I18n langKey='srg.content.submit' defaultText='Submit' />
                        </SrgQuestionnaireButton>

                        :

                        <SrgQuestionnaireButton
                            size='large'
                            onClick={state.goToNextStep}
                            disabled={actualQuestion.state.value === ''}
                            dataTest='srg-next-question-button'
                        >
                            <I18n langKey='srg.content.next-step' defaultText='Next' />
                        </SrgQuestionnaireButton>
                }

                <SrgQuestionnaireBackButton
                    onClick={state.goToPrevStep}
                    data-test='srg-previous-question-button'
                >
                    {actualQuestion.step === 1 ?
                        <I18n langKey='srg.content.button.back-slide' defaultText='Back to previous slide' /> :
                        <I18n langKey='srg.content.button.back-question' defaultText='Back to previous question' />
                    }
                </SrgQuestionnaireBackButton>
            </>
        );
    }

    return (
        <SrgQuestionnaireQuestionContainer>
            <SrgQuestionnaireQuestionText>
                <I18n langKey='srg.content.not-found' defaultText='No question found' />
            </SrgQuestionnaireQuestionText>
        </SrgQuestionnaireQuestionContainer>
    );
};



interface PropsTypes {
    state: SrgQuestionnaireState;
    languagesState: LanguagesState;
    userName?: string;
    operatorFullName: string;
    questionSet: QuestionSetType[];
}

export const SrgContent = observer('SrgContent', ({ state, userName, operatorFullName, questionSet, languagesState }: PropsTypes): JSX.Element | null => {
    return (
        <>
            <SrgQuestionnaireSteps data-test='question-number-step'>
                {renderSteps(state.currentSrgStep, state.maxQuestionsLength)}
            </SrgQuestionnaireSteps>

            <SrgQuestionnaireTitle>
                <I18n langKey='srg.content.header' defaultText='Customer Interaction Questionnaire' />
            </SrgQuestionnaireTitle>

            {renderContent(state, userName, operatorFullName, questionSet, languagesState)}
        </>
    );
});
