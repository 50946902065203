import { action, computed, observable, makeObservable } from 'mobx';
import { sortBy } from 'lodash';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MeetingsListPropsTypes } from './MeetingsList';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';


const sortEventList = (events: Array<EventModel>): Array<EventModel> => {
    return sortBy(events, (eventModel: EventModel) => {
        return eventModel.timeSettingsStartTime;
    });
};

class CompetitionRowState {
    @observable private open: boolean = true;

    public constructor() {
        makeObservable(this);
    }

    @computed public get isOpened(): boolean {
        return this.open;
    }

    @action public toggle = (): void => {
        this.open = !this.open;
    };
}

export interface RowType {
    competitionId: CompetitionId;
    events: Array<EventModel>;
}

export class MeetingsListState {
    private readonly props: MeetingsListPropsTypes;

    public readonly isRowOpen: MobxMapAutoNew<CompetitionId, CompetitionRowState>;

    public constructor(props: MeetingsListPropsTypes) {
        makeObservable(this);
        this.props = props;
        this.isRowOpen = new MobxMapAutoNew(() => new CompetitionRowState());
    }

    public handleClickRow = (competitionId: CompetitionId): void => {
        this.isRowOpen.get(competitionId).toggle();
    };

    @computed private get totalEvents(): Map<CompetitionId, Array<EventModel>> {
        const result: Map<CompetitionId, Array<EventModel>> = new Map();

        this.props.events?.forEach(eventModel => {
            const markets = eventModel.marketRaceWinnerAll;
            const marketModel = markets[0];

            if (marketModel !== undefined) {
                const list = result.get(eventModel.competition2);

                if (list === undefined) {
                    result.set(eventModel.competition2, [eventModel]);
                } else {
                    list.push(eventModel);
                }
            }
        });

        for (const [competitionId, eventList] of result.entries()) {
            result.set(competitionId, sortEventList(eventList));
        }

        return result;
    }

    @computed public get totalEventsByRow(): Array<RowType> {
        const tot1: Array<RowType> = [];

        for (const [competitionId, events] of this.totalEvents.entries()) {
            tot1.push({
                competitionId,
                events
            });
        }

        return sortBy(tot1, (row: RowType) => {
            const firstEvent = row.events[0];

            if (firstEvent !== undefined) {
                return firstEvent.timeSettingsStartTime;
            }
        });
    }
}
