import { head } from 'lodash';
import { computed, observable, makeObservable } from 'mobx';
import { FiltersStateStrategy, FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { SportMeetingsListState } from './SportMeetingsList.state';
import { DateTime } from 'src_common/utils/time/time';

export class SportMeetingsListFilterStrategy implements FiltersStateStrategy {

    @observable private activeFilterId: number | null = null;

    public constructor(
        public readonly listState: SportMeetingsListState,
        public readonly getTranslation: (key: string, defaultText: string) => string,
    ) {
        makeObservable(this);
    }

    @computed public get filters(): FilterType[] {
        const { hasEventsPerDay } = this.listState;
        const emptyArr: FilterType[] = [];

        const timeFilters = emptyArr.concat(
            hasEventsPerDay(0) === true
                ? [{
                    id: DateTime.current().startOfDays().unixMs(),
                    key: 'offset-0',
                    label: this.getTranslation('events.time-options.today', 'Today')
                }]
                : [],
            hasEventsPerDay(1) === true
                ? [{
                    id: DateTime.current().addDays(1).startOfDays().unixMs(),
                    key: 'offset-1',
                    label: this.getTranslation('events.time-options.tomorrow', 'Tomorrow')
                }]
                : [],
            hasEventsPerDay(2) === true
                ? [{
                    id: DateTime.current().addDays(2).startOfDays().unixMs(),
                    key: 'offset-2',
                    label: DateTime.current().addDays(2).format('dddd')
                }]
                : [],
            hasEventsPerDay(3) === true
                ? [{
                    id: DateTime.current().addDays(3).startOfDays().unixMs(),
                    key: 'offset-3',
                    label: DateTime.current().addDays(3).format('dddd')
                }]
                : [],
            hasEventsPerDay(4) === true
                ? [{
                    id: DateTime.current().addDays(4).startOfDays().unixMs(),
                    key: 'offset-4',
                    label: DateTime.current().addDays(4).format('dddd')
                }]
                : [],
            hasEventsPerDay(5) === true
                ? [{
                    id: DateTime.current().addDays(5).startOfDays().unixMs(),
                    key: 'offset-5',
                    label: DateTime.current().addDays(5).format('dddd')
                }]
                : [],
            hasEventsPerDay(6) === true
                ? [{
                    id: DateTime.current().addDays(6).startOfDays().unixMs(),
                    key: 'offset-6',
                    label: DateTime.current().addDays(6).format('dddd')
                }]
                : []
        );
        return timeFilters;
    }

    public getActiveFilterId(): number | null {
        const first: FilterType | undefined = head(this.filters);
        const firstAvailableFilterId = this.activeFilterId ?? first?.id ?? null;

        return firstAvailableFilterId === null ? DateTime.current().startOfDays().unixMs() : DateTime.from(firstAvailableFilterId)?.unixMs() ?? null;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (typeof id === 'number') {
            this.activeFilterId = id;
            this.listState.setDays(this.activeFilterId);
        } else {
            console.error('Selected filter can not be `null` or `string`');
        }
    }
}
