import React from 'react';
import { SidebarHeaderWrapper } from './PromoSidebarPartials.style';
import {
    CloseButton,
    LoginLinkWrapper,
    LoginInfo,
} from 'src/domains/players/webview/components/Account/promoSidebar/PromoSidebar.style';

interface SidebarHeaderPropsType {
    onCloseHandle: () => void;
    onLoginHandle: () => void;
    onSignUpHandle: () => void;
    isUserAuthorized: boolean;
    operatorName: string;
}
export const SidebarHeader = ({
    onCloseHandle,
    onLoginHandle,
    onSignUpHandle,
    isUserAuthorized,
    operatorName,
}: SidebarHeaderPropsType): JSX.Element => (
    <SidebarHeaderWrapper>
        {isUserAuthorized ? (
            <LoginInfo>
                Already have an account?
                <LoginLinkWrapper
                    onClick={onLoginHandle}
                    data-test='landing-page-login-link'
                >
                    Log In
                </LoginLinkWrapper>
            </LoginInfo>
        ) : (
            <LoginInfo>
                New to {operatorName}?
                <LoginLinkWrapper
                    onClick={onSignUpHandle}
                    data-test='landing-page-signup-link'
                >
                    Sign Up
                </LoginLinkWrapper>
            </LoginInfo>
        )}
        <CloseButton onClick={onCloseHandle} />
    </SidebarHeaderWrapper>
);
