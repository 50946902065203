import styled from '@emotion/styled';

import { BabIcon } from 'src/domains/layouts/shared/assets/icons/BabIcon';

import { InPlayIcon } from 'src/domains/layouts/shared/assets/icons/InPlayIcon';

import { withConfig } from 'src/withConfig';



export const EventStatus = withConfig(theme => styled('span', { label: 'EventStatus' })`

    color: ${theme.star.eventRow.txtColorQuaternary};

`);



export const EventRowTimeInfo = withConfig(theme => styled('div', { label: 'EventRowTimeInfo' })`

    color: ${theme.star.eventRow.txtColorSecondary};

`);



export const EventRowInPlayInfo = withConfig(theme => styled('div', { label: 'EventRowInPlayInfo' })`

    color: ${theme.star.eventRow6PackColors.txtColorSecondary};

    display: flex;

    align-items: center;

    gap: 4px;

`);



export const EventInPlayIcon = styled(InPlayIcon, { label: 'EventInPlayIcon' })`

    fill: currentcolor;

    width: 12px;

`;



export const BabIconStyledWrapper = withConfig(theme => styled(BabIcon, { label: 'BabIconStyledWrapper' })`

    fill: ${theme.star.eventRow.bgColorQuinary};

    width: 14px;

    margin: 0 4px;

`);

