import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { DebugPanelSizeIO, DebugPanelSizeType } from 'src/domains/layouts/webview/components/debugPanel/MainViewTypes';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';


const decode = buildValidator('localStorageCookiesPolicy.debugPanelSize', DebugPanelSizeIO, true);

export const localStorageDebugPanelSize = (withAutorun: boolean, key: string): LocalStorageItemState<DebugPanelSizeType> => {
    return createLocalStorageItem(withAutorun, {
        key,
        decode,
        defaultValue: null
    });
};
