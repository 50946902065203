import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { assertNever } from 'src_common/common/assertNever';
import {
    WithdrawJourney,
    WithdrawZenetPayJourney,
    WithdrawSwiftyGlobalProvider,
    InfoPage,
    WithdrawPraxisProvider,
} from 'src/domains/players/webview/components/WithdrawAndDeposit';
import { useAppStateContext } from 'src/appState/AppState';
import { WithdrawPaysafeJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawPaysafe/WithdrawPaysafeJourney';

export const WithdrawTab = observer('WithdrawProcedure', () => {
    const {
        appLayoutsState: {
            configComponents: { config },
        },
    } = useAppStateContext();
    const { temporaryWithdrawalPage, usePaymentFormForUniverse, accountHelperMail } = config;

    if (temporaryWithdrawalPage) {
        return <InfoPage accountHelperMail={accountHelperMail} infoPageType='withdrawal' />;
    } else if (usePaymentFormForUniverse.type === 'zenetPay') {
        return <WithdrawZenetPayJourney />;
    } else if (usePaymentFormForUniverse.type === 'realex') {
        return <WithdrawJourney />;
    } else if (usePaymentFormForUniverse.type === 'swiftyGlobalProvider') {
        return <WithdrawSwiftyGlobalProvider />;
    } else if (usePaymentFormForUniverse.type === 'praxis') {
        return <WithdrawPraxisProvider />;
    } else if (usePaymentFormForUniverse.type === 'paysafe') {
        return <WithdrawPaysafeJourney />;
    }

    return assertNever('usePaymentFormForUniverse', usePaymentFormForUniverse);
});
