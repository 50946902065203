import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string;
}

const SvgElement = styled('svg', { label: 'SvgElement' }) <PropsType>`
    height: auto;
`;

export const StatsIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement
        className={className}
        fill='#fff'
        height={16}
        viewBox='0 0 16 16'
        width={16}
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_3587_17183)'>
            <path d='M15.7131 4.53449C15.5379 3.4714 15.0342 2.49004 14.2729 1.72828C13.5116 0.966342 12.5307 0.462503 11.4682 0.287166C9.17192 -0.0957219 6.82793 -0.0957219 4.53164 0.287166C3.46938 0.462838 2.4888 0.966833 1.72746 1.72864C0.966159 2.4904 0.46227 3.47157 0.286861 4.53453C-0.0956202 6.83239 -0.0956202 9.17763 0.286861 11.4753C0.461904 12.5384 0.965625 13.5198 1.72692 14.2815C2.4884 15.0435 3.46916 15.5473 4.5316 15.7226C5.68148 15.9107 6.84478 16.0034 8.00982 15.9999C9.17188 15.9996 10.3319 15.9035 11.4781 15.7127C12.5406 15.5374 13.5213 15.0333 14.2826 14.2716C15.0441 13.5098 15.5476 12.5283 15.7228 11.4652C15.9097 10.3214 16.0024 9.16404 16 8.00489C15.9996 6.84213 15.9036 5.68142 15.7129 4.5345L15.7131 4.53449ZM13.753 11.1478C13.6462 11.7996 13.3376 12.4014 12.8708 12.8684C12.404 13.3355 11.8026 13.6443 11.1512 13.7511C10.1128 13.9205 9.06219 14.0042 8.01005 14.0009C6.95786 14.0042 5.90727 13.9205 4.86886 13.7511C4.21748 13.6443 3.61603 13.3355 3.14928 12.8684C2.68253 12.4014 2.37385 11.7996 2.26711 11.1478C1.92085 9.06761 1.92085 6.94437 2.26711 4.86419C2.37387 4.21205 2.68233 3.60975 3.14892 3.14231C3.61572 2.67488 4.2173 2.36556 4.86886 2.25822C6.94778 1.91158 9.06973 1.91158 11.1486 2.25822C11.8006 2.3647 12.4027 2.67318 12.8698 3.14026C13.3371 3.60734 13.6461 4.20946 13.7531 4.8616C13.9224 5.90065 14.006 6.95191 14.0027 8.0047C14.006 9.05753 13.9224 10.1087 13.7531 11.1478H13.753Z' fill='fill'/>
            <path d='M8.00041 3.7647C7.70914 3.7647 7.42992 3.86797 7.22387 4.05176C7.01801 4.23573 6.90234 4.485 6.90234 4.74505V10.6275C6.90234 10.9777 7.11165 11.3013 7.45136 11.4765C7.79109 11.6516 8.20971 11.6516 8.5494 11.4765C8.88912 11.3013 9.09842 10.9777 9.09842 10.6275V4.74505C9.09842 4.48501 8.98276 4.23572 8.77689 4.05176C8.57084 3.86797 8.29163 3.7647 8.00035 3.7647H8.00041Z' fill='fill'/>
            <path d='M11.6081 5.96079C11.3586 5.96079 11.1192 6.06423 10.9427 6.24851C10.7661 6.43262 10.667 6.68249 10.667 6.94299V10.6258C10.667 10.9766 10.8464 11.3008 11.1375 11.4763C11.4287 11.6517 11.7875 11.6517 12.0788 11.4763C12.37 11.3008 12.5493 10.9766 12.5493 10.6258V6.94299C12.5493 6.6825 12.4502 6.43261 12.2736 6.24851C12.0972 6.06423 11.8577 5.96079 11.6081 5.96079H11.6081Z' fill='fill'/>
            <path d='M4.54933 7.52941C4.25809 7.52941 3.9787 7.63049 3.77286 7.81039C3.56683 7.99046 3.45117 8.23445 3.45117 8.489V10.6483C3.45117 10.991 3.66046 11.3078 4.00014 11.4793C4.34003 11.6507 4.75862 11.6507 5.09828 11.4793C5.43797 11.3078 5.64725 10.991 5.64725 10.6483V8.489C5.64725 8.23446 5.53159 7.99046 5.32556 7.81039C5.11972 7.63049 4.84034 7.52941 4.54928 7.52941H4.54933Z' fill='fill'/>
        </g>
        <defs>
            <clipPath id='clip0_3587_17183'>
                <rect width='16' height='16' fill='fill'/>
            </clipPath>
        </defs>
    </SvgElement>
);
