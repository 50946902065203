import { AccountBasicDataType } from 'src/domains/players/state/UsersState';
import { z } from 'zod';

const isParsedError = (value: string | undefined | null): boolean => {
    if (value === undefined || value === null || value.trim() === '') {
        return true;
    }

    return false;
};

interface ZenetPayValidationDepositToValidateResponse {
    fullName: string;
    email: string;
    cpf: string;
}

interface ZenetPayValidationDepositErrorResponse {
    type: 'error';
    fieldsRequired: string[];
}
export interface ZenetPayValidationDepositSuccessResponse {
    type: 'ok';
    data: ZenetPayValidationDepositToValidateResponse;
}

const billingInfoFields = z.object({
    fullName: z.string(),
    email: z.string(),
    cpf: z.string()
});

export type ValidateBillingInfoFieldsZenetPay = z.infer<typeof billingInfoFields>;

export const isValidBillingInfoFieldsZenetPay = (value: unknown): value is ValidateBillingInfoFieldsZenetPay =>
    billingInfoFields.safeParse(value).success;



export const zenetPayValidation = (basicData: Omit<AccountBasicDataType, 'oddsFormat'> | null): ZenetPayValidationDepositErrorResponse | ZenetPayValidationDepositSuccessResponse | undefined => {

    if (basicData !== null) {
        const dataToValid = {
            cpf: basicData.cpf,
            fullName: basicData.fullName,
            email: basicData.email,
        };

        if (isValidBillingInfoFieldsZenetPay(dataToValid)) {
            return {
                type: 'ok',
                data: {
                    fullName: dataToValid.fullName,
                    email: dataToValid.email,
                    cpf: dataToValid.cpf
                }
            };
        }

        const fieldsRequired: Array<string> = [];
        if (isParsedError(dataToValid['fullName'])) {
            fieldsRequired.push('fullName');
        }
        if (isParsedError(dataToValid['email'])) {
            fieldsRequired.push('email');
        }
        if (isParsedError(dataToValid['cpf'])) {
            fieldsRequired.push('cpf');
        }

        return {
            type: 'error',
            fieldsRequired,
        };
    };
    return undefined;
};
