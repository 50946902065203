import { z } from 'zod';

export const InputSchema = z.object({
    merchantCustomerId: z.string(),
    locale: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    dateOfBirth: z.object({
        year: z.number(),
        month: z.number(),
        day: z.number(),
    }),
    email: z.string().email(),
    phone: z.string(),
});

export type VerifyUserPaysafeInput = z.infer<typeof InputSchema>;

export const SuccessResponseSchema = z.object({
    data: z.object({
        internalId: z.string(),
        externalId: z.string(),
    }),
});

type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            message: z.string().optional().nullable(),
        })
    ),
});

type TErrorResponse = z.infer<typeof ErrorResponseSchema>;

export type TResponseType =
    | { responseStatus: 'success'; response: TSuccessResponse }
    | { responseStatus: 'error'; data: TErrorResponse };
