import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import {
    RaceSummaryCaption,
    PlaceCaption,
    SilkCaption,
    RunnerCaption,
    WeightCaption,
    SelectionCaption,
    PriceHistoryCaption,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { SpanSelection } from 'src/domains/sportsbook/webview/components/horseRacingNewTab/RaceSummaryRow.style';

interface HeadersPropsType {
    type?: string;
    race: EventModel;
    market: MarketModel;
    isSpecials?: boolean;
    isRaceInProgress: boolean;
    isPriceOnly?: boolean;
}

export const HeadersHorseRacing = observer('HeadersHorseRacing', (props: HeadersPropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;
    const { type, race, market, isSpecials, isRaceInProgress } = props;

    if (type === 'cast') {
        return (
            <RaceSummaryCaption>
                <PlaceCaption className='cast' data-test='race-summary-header-place'>#</PlaceCaption>
                <SilkCaption className='cast' data-test='race-summary-header-silk'>
                    <I18n langKey='events.race-summary.silk.label' defaultText='Silk' />
                </SilkCaption>
                <RunnerCaption data-test='race-summary-header-runner'>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-1st'>
                    <I18n langKey='events.race-summary.1st.label' defaultText='1st' />
                </SelectionCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-2nd'>
                    <I18n langKey='events.race-summary.2nd.label' defaultText='2nd' />
                </SelectionCaption>

                {market.tricastsOffered ? (
                    <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-3rd'>
                        <I18n langKey='events.race-summary.3rd.label' defaultText='3rd' />
                    </SelectionCaption>
                ) : (
                    ''
                )}

                <SelectionCaption isRaceInProgress={isRaceInProgress} data-test='race-summary-header-anyorder'>
                    <I18n langKey='events.race-summary.any-order.label' defaultText='Any Order' />
                </SelectionCaption>
            </RaceSummaryCaption>
        );
    } else if (market.templateId.includes('place-betting')) {
        return (
            <RaceSummaryCaption>
                <PlaceCaption>#</PlaceCaption>
                <SilkCaption>
                    <I18n langKey='events.race-summary.silk.label' defaultText='Silk' />
                </SilkCaption>
                <RunnerCaption>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>

                <WeightCaption>
                    <I18n langKey='events.race-summary.weight.label' defaultText='Weight' />
                </WeightCaption>

                <SpanSelection>
                    <I18n langKey='events.race-summary.place.label' defaultText='Place' />
                </SpanSelection>
            </RaceSummaryCaption>
        );
    } else if (race.antePost || isSpecials === true) {
        return (
            <RaceSummaryCaption>
                <RunnerCaption>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress}>
                    <I18n langKey='events.race-summary.price.label' defaultText='Price' />
                </SelectionCaption>
            </RaceSummaryCaption>
        );
    } else {
        return (
            <RaceSummaryCaption>
                <PlaceCaption>#</PlaceCaption>

                <SilkCaption>
                    <I18n langKey='events.race-summary.silk.label' defaultText='Silk' />
                </SilkCaption>

                <RunnerCaption>
                    <I18n langKey='events.race-summary.runner.label' defaultText='Runner' />
                </RunnerCaption>

                {props.isPriceOnly === true ? null : (
                    <WeightCaption>
                        <I18n langKey='events.race-summary.weight.label' defaultText='Weight' />
                    </WeightCaption>
                )}

                <PriceHistoryCaption
                    isRaceInProgress={isRaceInProgress}
                    className='race-summary__selection__price-history'
                >
                    <I18n langKey='events.race-summary.price-history.label' defaultText='Price history' />
                </PriceHistoryCaption>

                <SelectionCaption isRaceInProgress={isRaceInProgress}>
                    <I18n langKey='events.race-summary.price.label' defaultText='Price' />
                </SelectionCaption>

                {breakpointsState.tablet.isBiggerOrEq === false || props.isPriceOnly === true ? (
                    ''
                ) : (
                    <SelectionCaption isRaceInProgress={isRaceInProgress}>SP</SelectionCaption>
                )}
            </RaceSummaryCaption>
        );
    }
});
