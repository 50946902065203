import React from 'react';
import { EventCard } from 'src/domains/sportsbook/webview/components/eventCard/EventCard';
import { CarouselEventNavigation } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigation';
import { Wrapper, Event } from './EventPage.style';
import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { AlternativeEvent } from 'src/domains/sportsbook/webview/modules/AlternativeEvent';
import { BackButton, BackButtonWrapper, EventNavigation } from 'src/domains/layouts/webview/components/eventNavigation/CarouselSelection.style';
import { useAppStateContext } from 'src/appState/AppState';
import { EventRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface EventLayoutPropsType {
    currentView: EventRouteType;
    isSearchActive: boolean;
    accountView: RightHandSideViewType | null;
    isNotificationsList: boolean;
}

export const EventLayout = observer('EventLayout', (props: EventLayoutPropsType) => {
    const { currentView, accountView, isSearchActive, isNotificationsList } = props;

    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const { alternativeEventState, models } = appSportsBookState;

    const currentViewId = models.id.getEventId(currentView.id);
    const actualEvent = currentViewId.getEventModel();
    const isRacing = actualEvent === null ? false : ['greyhoundracing', 'horseracing'].includes(actualEvent.sport);
    const { isAlternativeEvent } = alternativeEventState.get(currentViewId);

    if (isAlternativeEvent) {
        return (
            <Event>
                <LayoutSkeleton currentView={currentView} accountView={accountView} isSearchActive={isSearchActive} navigationType='global' >
                    <Wrapper>
                        <AlternativeEvent currentView={currentView}/>
                    </Wrapper>
                </LayoutSkeleton>
            </Event>
        );
    }

    const handleRedirectToCompetitionPage = (): void => {
        if (actualEvent !== null) {
            starRouter.redirectToCompetitionPage(actualEvent.competition, actualEvent.sport);
        }
    };

    return (
        <Event>
            <LayoutSkeleton currentView={currentView} accountView={accountView} isSearchActive={isSearchActive} navigationType='global' >
                <Wrapper>
                    {
                        isRacing !== true &&
                        <EventNavigation>
                            <BackButtonWrapper onClick={handleRedirectToCompetitionPage}>
                                <BackButton position='left' />
                            </BackButtonWrapper>
                            <CarouselEventNavigation currentView={currentView} />
                        </EventNavigation>
                    }
                    <EventCard id={appSportsBookState.models.id.getEventId(currentView.id)} isNotificationsList={isNotificationsList}/>
                    {isRacing === true ? <PriceChangeLegend isRace={false} /> : null}
                </Wrapper>
            </LayoutSkeleton>
        </Event>
    );
});
