import { css, SerializedStyles } from '@emotion/react';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export interface EventHeaderType {
    headerStyle: 'sportLevel' | 'competitionLevel';
    small: boolean;
}

const setHeaderSeparator = (theme: EmotionTheme, headerStyle: 'sportLevel' | 'competitionLevel', isOpen: boolean, small: boolean): SerializedStyles => {
    /* Don't show separator if section is open */
    if ((small || headerStyle === 'sportLevel') && isOpen) {
        return css`
            display: none;
        `;
    }

    if (small) {
        return css`
            background-color: ${theme.star.headings.competition.borderColor};
            &:last-of-type {
                display: none;
            }
        `;
    }

    if (headerStyle === 'sportLevel') {
        return css`
            background-color: ${theme.star.headings.sport.borderColor};
            &:last-of-type {
                display: none;
            }
        `;
    }

    return css`
        background-color: ${theme.star.headings.competition.borderColor};
        &:last-of-type {
            display: none;
        }
    `;
};

interface HrPropsType {
    headerStyle: 'sportLevel' | 'competitionLevel';
    isOpen: boolean;
    small: boolean;
};

export const Hr = withConfig(theme => styled('hr', { label: 'Hr' })<HrPropsType>`
    ${({ headerStyle, isOpen, small }): SerializedStyles => setHeaderSeparator(theme, headerStyle, isOpen, small)};
    border: 0;
    height: 1px;
    margin: 0;
`);

const setHeaderSize = (theme: EmotionTheme, small: boolean): SerializedStyles => {
    return css`
        font-size: ${small ? theme.star.fontSize.small : theme.star.fontSize.xRegular};
        line-height: 1.2;
        min-height: ${small ? '24px' : '28px'};
        padding: 4px 0 3px;
        @media ${theme.star.mediaQuery.tablet} {
            height: ${small ? '28px' : '32px'};
        }
    `;
};

export const setHeaderAppearance = (theme: EmotionTheme, small: boolean, headerStyle: 'sportLevel' | 'competitionLevel'): SerializedStyles => {
    if (small) {
        return css`
            background-color: ${theme.star.headings.competition.bgColor};
            color: ${theme.star.headings.competition.txtColor};
        `;
    }

    if (headerStyle === 'sportLevel') {
        return css`
            background-color: ${theme.star.headings.sport.bgColor};
            color: ${theme.star.headings.sport.txtColor};
        `;
    }

    return css `
        background-color: ${theme.star.headings.competition.bgColor};
        border-top: 1px solid ${theme.star.headings.competition.borderColor};
        color: ${theme.star.headings.competition.txtColor};
    `;
};

export const EventHeaderGroup = withConfig(theme => styled('header', { label: 'EventHeaderGroup' })<EventHeaderType>`
    ${({ small, headerStyle }): SerializedStyles => setHeaderAppearance(theme, small, headerStyle)};
    ${({ small }): SerializedStyles => setHeaderSize(theme, small)};
    align-items: center;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.base};
`);

export const EventHeaderGroupName = withConfig(theme => styled('h4', { label: 'EventHeaderGroupName' })`
    color: currentcolor;
    flex: 1 1 100%;
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
`);

export const EventHeaderGroupSelection = withConfig(theme => styled('div', { label: 'EventHeaderGroupSelection' })`
    align-items: center;
    color: ${theme.star.headings.competition.txtColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    flex: 0 0 50px;
    height: 100%;
    justify-content: center;
    text-align: center;
    @media ${theme.star.mediaQuery.tablet} {
        flex-basis: 68px;
    }
`);

export const ChevronIconStyle = styled(ChevronIcon, { label: 'ChevronIconStyle' })`
    fill: currentcolor;
    flex: 0 0 12px;
    margin: 0 8px;
    width: 12px;
`;
