import { observable, computed, makeObservable } from 'mobx';
import { getMarketTranslation, MatchType } from './markets';

const SELECTIONS_LABELS = (getTranslation: (key: string, defaultText: string) => string): Record<string, string> => ({
    S: getTranslation('market.score', 'Score'),
    H: getTranslation('market.home', 'Home'),
    T: getTranslation('market.tie', 'Tie'),
    D: getTranslation('market.draw', 'Draw'),
    A: getTranslation('market.away', 'Away'),
    O: getTranslation('market.over', 'Over'),
    U: getTranslation('market.under', 'Under'),
    'unnamed-favourite': getTranslation('market.uf', 'UF'),
    'tennis:H': getTranslation('market.player-1', 'Player 1'),
    'tennis:A': getTranslation('market.player-2', 'Player 2'),
});

type LastSelectionsType = Array<'H' | 'D' | 'A' | 'O' | 'U' | 'Y' | 'N' | 'H-Y' | 'D-Y' | 'A-Y' | 'H-N' | 'A-N'>;

const getLastSelections = (filters_market: string): LastSelectionsType => {
    const lastMarket = filters_market;

    if (lastMarket === 'match-winner') {
        return ['H', 'D', 'A'];
    }
    if (lastMarket === 'both-teams-to-score') {
        return ['Y', 'N'];
    }
    if (lastMarket === 'total-goals-over-under') {
        return ['O', 'U'];
    }
    if (lastMarket === 'draw-no-bet') {
        return ['H', 'A'];
    }
    if (lastMarket === '1st-half-winner') {
        return ['H', 'D', 'A'];
    }
    if (lastMarket === 'match-result-and-both-to-score') {
        return ['H-Y', 'D-Y', 'A-Y'];
    }

    return [];
};

export class EventsListMarkets {
    @observable public filters_market: MatchType = 'match-winner';

    public constructor(
        public readonly getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string
    ) {
        makeObservable(this);
    }

    @computed public get marketTemplateId(): string {
        const marketId = getMarketTranslation(this.getTranslation)[this.filters_market].template;

        return marketId;
    }

    @computed public get marketLine(): number | null {
        return getMarketTranslation(this.getTranslation)[this.filters_market].line;
    }

    @computed.struct public get lastSelections(): Array<string> {
        return getLastSelections(this.marketTemplateId);
    }

    @computed.struct public get lastSelectionsHeader(): Array<string> {
        return this.lastSelections.map(x => SELECTIONS_LABELS(this.getTranslation)[x] ?? x);
    }
}
