type MatchState<T> = {
    type: 'not-match',
    params: Record<string, string>,
} | {
    type: 'ok',
    value: T
};

export class MatchRouting<T> {
    private constructor(private readonly state: MatchState<T>) {
    }

    public static create(params: Record<string, string>): MatchRouting<never> {
        return new MatchRouting({
            type: 'not-match',
            params
        });
    }

    private static ok<K>(value: K): MatchRouting<K> {
        return new MatchRouting({
            type: 'ok',
            value
        });
    }

    public tryMatch<R>(match: (params: Record<string, string>) => R | null): MatchRouting<T | R> {
        if (this.state.type === 'ok') {
            return this;
        }

        const result = match(this.state.params);

        if (result === null) {
            return this;
        }

        return MatchRouting.ok(result);
    }

    public get(): T | null {
        if (this.state.type === 'ok') {
            return this.state.value;
        }

        return null;
    }
}
