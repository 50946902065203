import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string;
}

const SvgElement = styled('svg', { label: 'SvgElement' }) <PropsType>`
    height: auto;
`;

export const PhoneIcon = ({ className }: PropsType): JSX.Element => (
    <SvgElement
        className={className}
        fill='#fff'
        height={24}
        viewBox='0 0 24 24'
        width={24}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.411 10.51c1.834 3.553 4.527 6.245 8.079 8.079l2.692-2.693c.382-.382.802-.478 1.26-.287a13.68 13.68 0 0 0 4.355.688c.344 0 .63.114.86.344.228.229.343.515.343.859v4.297c0 .344-.115.63-.344.86-.229.228-.515.343-.86.343-5.728 0-10.627-2.034-14.694-6.102C3.034 12.831 1 7.932 1 2.203c0-.344.115-.63.344-.86.229-.228.515-.343.86-.343H6.5c.344 0 .63.115.86.344.229.229.343.515.343.86 0 1.527.23 2.978.688 4.353.152.497.057.917-.287 1.26L5.411 10.51Z'
            fill='fill'
        />
    </SvgElement>
);
