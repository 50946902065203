import React, { useState, TouchEvent } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAsObservableSource } from 'mobx-react-lite';
import { SliderWrapper, BannersWrapper, PaginationWrapper, BannerWrapper } from './Slider.styles';
import { SliderState } from './Slider.state';
import { useAppStateContext } from 'src/appState/AppState';
import { SliderArrow } from './SliderArrow';
import { Dots } from 'src/domains/layouts/webview/components/common/dots/Dots';

export interface SliderType {
    id: string,
    component: JSX.Element,
}

interface SliderPropsTypes {
    banners: Array<SliderType>,
    isHomepage: boolean
}

export const Slider = observer('Slider', (propsIn: SliderPropsTypes) => {
    const props = useAsObservableSource(propsIn);
    const { banners } = props;
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;
    const bannersIndexes = banners.map((_b, i) => { return ({ id: i });});
    const [sliderState] = useState(() => new SliderState(banners.length));
    const { slideWithDot, currentSlide, slideInDirection, swipeStart, swipeEnd } = sliderState;
    const isDesktop = breakpointsState.tablet.isBiggerOrEq;

    const goLeftAvailable = currentSlide > 0;
    const goRightAvailable = currentSlide + 1 < banners.length;

    const showLeftArrow = goLeftAvailable && isDesktop;
    const showRightArrow = goRightAvailable && isDesktop;

    const handleTouchStart = (e: TouchEvent<HTMLDivElement>): void => {
        if (e.changedTouches[0] !== undefined) swipeStart(e.changedTouches[0].screenX);
    };

    const handleTouchEnd = (e: TouchEvent<HTMLDivElement>): void => {
        if (e.changedTouches[0] !== undefined) swipeEnd(e.changedTouches[0].screenX, goLeftAvailable, goRightAvailable);
    };

    return (
        <>
            <SliderWrapper>
                {showLeftArrow === true && <SliderArrow direction='left' slideInDirection={slideInDirection} />}

                
                <BannersWrapper>
                    {banners.map((banner) => {
                        return (
                            <BannerWrapper
                                key={banner.id}
                                offset={currentSlide}
                                maxHeight={256}
                                minHeight={200}
                                onTouchStart={(e): void => handleTouchStart(e)}
                                onTouchEnd={(e): void => handleTouchEnd(e)}
                            >
                                {banner.component}
                            </BannerWrapper>
                        );
                    })}
                </BannersWrapper>

                {showRightArrow === true && <SliderArrow direction='right' slideInDirection={slideInDirection} />}

            </SliderWrapper>

            {banners.length > 1 ?
                <PaginationWrapper>
                    <Dots banners={bannersIndexes} currentSlide={currentSlide} smallDotIndex={3} slideWithDot={slideWithDot} position='absolute' isHomepage={props.isHomepage} />
                </PaginationWrapper> : null}
        </>
    );
});
