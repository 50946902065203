/* eslint-disable no-restricted-globals */
import React from 'react';
import { computed, observable, makeObservable } from 'mobx';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import { CompetitionsTrapChallengeGroup } from 'src/domains/sportsbook/webview/components/containers/competitionsTrapChallenge/CompetitionsTrapChallengeGroup';
import { head, range, sortBy, uniqBy } from 'lodash';
import { FiltersWrapperTrapChallenge, MeetingsTraChallengeInfo } from 'src/domains/sportsbook/webview/components/containers/competitionsTrapChallenge/CompetitionsTrapChallenge.style';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import {
    FiltersState,
    FiltersStateStrategy,
    FilterType
} from 'src/domains/layouts/webview/components/filters/Filters.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { useAsObservableSource } from 'mobx-react-lite';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { LoaderWrapper, MeetingsInfo } from 'src/domains/sportsbook/webview/components/meetingsAntePostAndSpecials/Meetings.style';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { DateTime } from 'src_common/utils/time/time';

interface PropsTypes {
    shared: true;
    sportEvents: Array<EventModel> | null;
    isLoading: boolean;
}

class CompetitionsTrapChallengeState {
    private readonly props: PropsTypes;
    @observable private dayNewInner: number | null;

    public constructor(props: PropsTypes) {
        makeObservable(this);
        this.props = props;
        this.dayNewInner = null;
    }

    public setDays = (day: number): void => {
        this.dayNewInner = day;
    };

    @computed private get dayNew(): number | null {
        const getDay = this.getDays();
        if (this.dayNewInner === null && getDay !== null) {
            const firstDay = getDay[0];

            if (firstDay === undefined) {
                return null;
            }

            return firstDay.startOfDays().unixMs();
        }

        return this.dayNewInner;
    }

    private getDays(): Array<DateTime> | null {
        if (this.props.sportEvents === null) {
            return null;
        }
        const eventsIds: Array<string> = this.props.sportEvents.map(eventModel => {
            return eventModel.timeSettingsStartTime;
        });

        const time = sortBy(eventsIds, x => x);
        const start = DateTime.from(time[0])?.startOfDays();
        const end = DateTime.from(time[time.length - 1])?.startOfDays();
        if (start === undefined || end === undefined) {
            console.error('Time error, time: ', time);
            return null;
        }
        const diff = end.diffInDays(start);

        const days = uniqBy(
            range(0, diff + 1).map(x => start.addDays(x).startOfDays()), x => x.unixMs()
        );
        return days;
    }

    private isSameDayDisplay(eventId: EventId, days = 0): boolean {
        const eventModel = eventId.getEventModel();
        if (eventModel === null) {
            return false;
        }

        const time = DateTime.from(eventModel.timeSettingsStartTime)?.isSameDay(DateTime.current().addDays(days)) ?? false;
        return eventModel.display && time;
    }


    public hasEventsPerDay = (whatDay: number): boolean => {
        const meetings = this.props.sportEvents;
        if (meetings === null) {
            return false;
        }
        return meetings.some((eventModel: EventModel) => {
            const marketsIds = eventModel.marketsIds2;
            const firstMarketId = marketsIds[0];
            if (firstMarketId !== undefined) {
                const marketModel = firstMarketId.getModel();
                if (marketModel !== null) {
                    return this.isSameDayDisplay(eventModel.id2, whatDay);
                }
            }
        });
    };

    @computed public get filterEventsComputed(): EventModel[] {
        const eventsCollection = this.props.sportEvents;

        if (this.dayNew === null || eventsCollection === null || eventsCollection.length === 0) {
            return [];
        }

        return eventsCollection.filter(eventModel => {
            return DateTime.from(eventModel.timeSettingsStartTime)?.startOfDays().unixMs() === this.dayNew;
        });
    }
}

class FiltersStrategy implements FiltersStateStrategy {
    @observable private activeFilterId: number | null = null;

    public constructor(
        private readonly listState: CompetitionsTrapChallengeState,
        private readonly language: LanguagesState,
    ) {
        makeObservable(this);
    }

    @computed public get filters(): FilterType[] {
        const { getTranslation } = this.language;
        const { hasEventsPerDay } = this.listState;
        const emptyArr: FilterType[] = [];

        const timeFilters = emptyArr.concat(
            hasEventsPerDay(0) === true
                ? [{
                    id: DateTime.current().startOfDays().unixMs(),
                    key: 'offset-0',
                    label: getTranslation('events.time-options.today', 'Today')
                }]
                : []
            ,
            hasEventsPerDay(1) === true
                ? [{
                    id: DateTime.current().addDays(1).startOfDays().unixMs(),
                    key: 'offset-1',
                    label: getTranslation('events.time-options.tomorrow', 'Tomorrow')
                }]
                : []
            ,
            hasEventsPerDay(2) === true
                ? [{
                    id: DateTime.current().addDays(2).startOfDays().unixMs(),
                    key: 'offset-2',
                    label: DateTime.current().addDays(2).startOfDays().format('dddd'), 
                }]
                : []
        );
        return timeFilters;
    }

    public getActiveFilterId(): number | null {
        const first: FilterType | undefined = head(this.filters);
        const firstAvailableFilterId = this.activeFilterId ?? first?.id ?? null;

        const activeFilter = firstAvailableFilterId === null ? DateTime.current().startOfDays().unixMs() : DateTime.from(firstAvailableFilterId)?.unixMs();

        return activeFilter ?? null;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (typeof id === 'number') {
            this.activeFilterId = id;
            this.listState.setDays(this.activeFilterId);
        } else {
            console.error('Selected filter can not be `null` or `string`');
        }
    }
}

export const CompetitionsTrapChallenge = observer('CompetitionsTrapChallenge', (propsIn: PropsTypes) => {
    const props = useAsObservableSource(propsIn);

    const appState = useAppStateContext();
    const { appLayoutsState } = appState;
    const { configComponents, languagesState } = appLayoutsState;
    const { config } = configComponents;
    const [state] = React.useState(() => new CompetitionsTrapChallengeState(props));
    const { filterEventsComputed } = state;
    const { isDarkUniverse } = config;

    const [filtersState] = React.useState(() => new FiltersState(new FiltersStrategy(state, languagesState)));

    if (props.sportEvents === null || props.isLoading) {
        return (
            <MeetingsInfo>
                <LoaderWrapper
                    size={14}
                    version={isDarkUniverse ? 'light' : 'dark'}
                >
                    <I18n langKey='events.ante-post.loading' defaultText='Loading races...' />
                </LoaderWrapper>
            </MeetingsInfo>
        );
    } else if (props.sportEvents.length === 0) {
        return (
            <MeetingsTraChallengeInfo>
                <I18n langKey='events.ante-post.empty' defaultText='There are no races available at the moment. Please check again later.' />
            </MeetingsTraChallengeInfo>
        );
    } else {
        return (
            <div>
                <FiltersWrapperTrapChallenge>
                    <FiltersScrollableWrapper>
                        <Filters state={filtersState} skin={FiltersSkin.SECONDARY} />
                    </FiltersScrollableWrapper>
                </FiltersWrapperTrapChallenge>

                <CompetitionsTrapChallengeGroup events={filterEventsComputed} />
            </div>
        );
    }
});
