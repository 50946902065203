import * as React from 'react';

import styled from '@emotion/styled';

import { observer } from 'src/utils/mobx-react';



const setThumbsPosition = (position: 'up' | 'down'): string => {

    if (position === 'down') {

        return 'rotate(180deg)';

    }



    return 'rotate(0)';

};



interface SvgElementPropsType {

    position: 'up' | 'down';

}



const SvgElement = styled('svg', { label: 'SvgElement' })<SvgElementPropsType>`

    height: auto;

    transform: ${({ position } ): string => setThumbsPosition(position)};

`;



interface ThumbsPropsType {

    className?: string;

    onClick?: () => void;

    position: 'up' | 'down';

}



export const ThumbsIcon = observer('ThumbsIcon', ({ className, onClick, position }: ThumbsPropsType): JSX.Element => {

    return (

        <SvgElement

            className={className}

            fill='#072d4f'

            height='24'

            onClick={onClick}

            position={position}

            viewBox='0 0 24 24'

            width='24'

            xmlns='http://www.w3.org/2000/svg'

        >

            <path

                fill='fill'

                fillRule='evenodd'

                d='M16.836 8.776c-.162-.434 4.326-4.441 1.709-7.689-.613-.759-2.69 3.636-5.64 5.626-1.629 1.097-5.416 3.434-5.416 4.726v8.363c0 1.553 6.006 3.198 10.57 3.198 1.673 0 4.096-10.482 4.096-12.146 0-1.672-5.159-1.643-5.319-2.078zm-10.57.116c-.804 0-3.666.489-3.666 3.817v5.925c0 3.326 2.862 3.693 3.667 3.693.803 0-1.223-.7-1.223-2.763v-7.789c0-2.16 2.026-2.883 1.223-2.883z'

                clipRule='evenodd'

            />

        </SvgElement>

    );

});

