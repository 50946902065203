import { computed, observable, action, makeObservable } from 'mobx';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { TransactionParamsType } from './types';
import { getFilters } from './getFilters';
import { TransactionHistoryCollection } from './TransactionHistoryCollection';

const TOUCH_WAY = 75; // difference between swiping your finger across the screen

export interface RangeType {
    from: string;
    to: string;
}

export class TransactionHistoryTabState {
    @observable.ref private transaction: TransactionHistoryCollection;

    private touchStart: number = 0;
    private touchEnd: number = 0;

    public constructor(
        private readonly account: AccountState,
        private readonly translationsStore: TranslationsStore,
        private readonly language: LanguagesState,
        private readonly config: ConfigType
    ) {
        makeObservable(this);
        this.transaction = new TransactionHistoryCollection(this.account, this.translationsStore, 'all', null);
    }

    public get collection(): TransactionHistoryCollection {
        return this.transaction;
    }

    @computed public get filtersHome(): Array<FiltersType> {
        return getFilters(this.setFilter, this.language, this.config);
    }

    @action public resetRange = (): void => {
        this.transaction = this.transaction.setRange(null);
    };

    @action public submitRange = (dateFrom: string, dateTo: string): void => {
        this.transaction = this.transaction.setRange({
            from: dateFrom,
            to: dateTo,
        });
    };

    @action public setFilter = (filter: TransactionParamsType): void => {
        this.transaction = this.transaction.setActiveFilter(filter);
    };

    public handleTouchStart = (e: React.TouchEvent): void => {
        if (e.nativeEvent.touches[0] !== undefined) {
            this.touchStart = e.nativeEvent.touches[0].clientX;
        }
    };

    public handleTouchMove = (e: React.TouchEvent): void => {
        if (e.nativeEvent.touches[0] !== undefined) {
            this.touchEnd = e.nativeEvent.touches[0].clientX;
        }
    };
    public handleTouchEnd = (): void => {
        if (this.touchStart - this.touchEnd > TOUCH_WAY) {
            this.transaction.nextPage();
        }

        if (this.touchStart - this.touchEnd < -TOUCH_WAY) {
            this.transaction.prevPage();
        }
    };
}
