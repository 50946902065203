import { createContext } from 'src/utils/createContext';
import { EnvVariables } from './contextStore/EnvVariables';
import { AutoWeakMap, autoWeakMapKey } from 'src_common/common/mobx-utils/AutoWeakMap';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Session } from 'src_common/sdk/session';
import { UniverseModuleType } from './universes.type';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { ModernizrDetectType } from 'src/domains/layouts/state/environmentState/modernizr/ModernizrType';
// import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

export class Common {
    public [autoWeakMapKey] = (): void => {};

    public constructor(
        public readonly startingUrl: string,
        public readonly envVariables: EnvVariables,
        public readonly session: Session,
        public readonly isBrowser: boolean,
        public readonly trpcClient: TrpcClient,
        public readonly themeColors: UniverseModuleType['themeColors'],
        public readonly features: ConfigType,
        public readonly faviconUrl: string,
        public readonly modernizrDetect: ModernizrDetectType | null,
        public readonly websiteBaseUrl: string
        // public readonly router: StarRouter
    ) {
        AutoWeakMap.register(this);
    }

    // session,
    // url,
    // ModernizrDetect,

    //localStorage
    //trpc
    //ConfigComponents
    //
}

const { AppContext, useContext } = createContext<Common>('Common');

export const ProviderCommon = AppContext.Provider;

export const useCommon = (): Common => {
    return useContext();
};
