import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { SportModel } from 'src_common/common/websocket2/models/SportModel/SportModel';
import { CompetitionModel } from 'src_common/common/websocket2/models/CompetitionModel';
import { EventsCollectionQueryType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { EventMarketItemType } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { RabMarketsModel } from 'src_common/common/websocket2/models/RabMarketsModel';
import { ModelsStateSocketConfig } from './ModelsStateSocketConfig';
import { autorun } from 'src_common/common/mobx-wrapper';
import { MarketModelType, SelectionModelType } from './modelsApi/Market';
import { TypeId } from './type';
import { RabMarketType } from 'src_common/common/websocket2/modelsApi/RabMarket';
import { search } from './search';
import { WebsocketId, WebsocketIdContext } from './id/WebsocketId';
import { ModelsEventStateNew } from './ModelsEventStateNew/ModelsEventStateNew';

export class ModelsState {
    public readonly id: WebsocketId;
    private readonly modelsEvent: ModelsEventStateNew;

    public constructor(
        isBrowser: boolean,
        modelsStateSocketConfig: ModelsStateSocketConfig,
        websocket_host_v2: string,
    ) {
        this.modelsEvent = new ModelsEventStateNew(
            isBrowser,
            modelsStateSocketConfig,
            websocket_host_v2,
            () => this.id,
        );

        const context = new WebsocketIdContext(
            this.modelsEvent.getSelectionAndLoad,
            this.modelsEvent.getMarket,
            this.modelsEvent.getEvent,
            //this.getRabMarketsByEventId,
            (eventId: number): RabMarketsModel | null => {
                const event = this.modelsEvent.getEvent(eventId);
                if (event === null) {
                    return null;
                }
        
                const platformId = event.rabId;

                if (platformId !== null) {
                    return this.getRabMarkets(platformId);
                }
        
                return null;
            },
            this.getEventMarkets,
            this.getCompetitionModel,
        );
        this.id = new WebsocketId(context);
    }

    public getSport(sportId: string): SportModel | null {
        return this.modelsEvent.getSport(sportId);
    }

    /**
     * @deprecated - please use getSelectionAndLoad
     */
    public getSelection = (id: number): SelectionModel | null => {
        return this.modelsEvent.getSelection(id);
    };

    public getSelectionAndLoad = (marketId: TypeId.MarketId, selectionId: number): SelectionModel | null => {
        return this.modelsEvent.getSelectionAndLoad(marketId, selectionId);
    };

    public getMarket = (id: TypeId.MarketId): MarketModel | null => {
        return this.modelsEvent.getMarket(id);
    };

    public getEvent = (id: number): EventModel | null => {
        return this.modelsEvent.getEvent(id);
    };

    /**
     * @deprecated - this method will be removed
     */
    public getEventMarkets = (id: number): Array<EventMarketItemType> | null => {
        return this.modelsEvent.getEventMarkets(id);
    };

    public getEventMarketsAll = (id: number): Array<EventMarketItemType> | null => {
        return this.modelsEvent.getEventMarketsAll(id);
    };

    /**
     * @deprecated - this method will be removed
     */
    public getCompetitionModel = (id: number): CompetitionModel | null => {
        return this.modelsEvent.getCompetitionModel(id);
    };

    public refreshSocket = (): void => {
        this.modelsEvent.refreshSocket();
    };
    
    public getEventQuery(query: EventsCollectionQueryType): EventsCollectionQueryModel | null {
        return this.modelsEvent.getEventQuery(query);
    }

    public getEventQuerySearch(query: EventsCollectionQueryType): EventsCollectionQueryModel | null {
        return search(this.id, this.modelsEvent, query);
    }

    /**
     * @deprecated - this method will be removed
     */
    public getRabMarkets(platformId: string): RabMarketsModel | null {
        return this.modelsEvent.getRabMarkets(platformId);
    }

    /**
     * @deprecated - this method will be removed
     */
    public getPlatformIdByEventId(eventId: number): string | null {
        const event = this.getEvent(eventId);
        if (event === null) {
            return null;
        }

        return event.rabId;
    }

    /**
     * @deprecated - this method will be removed
     */
    public getRabMarketsByEventId = (eventId: number): RabMarketsModel | null => {
        const platformId = this.getPlatformIdByEventId(eventId);

        if (platformId !== null) {
            return this.getRabMarkets(platformId);
        }

        return null;
    };

    private debugMarkets(eventMarkets: Array<EventMarketItemType> | null): {
        smallMarkets: Array<EventMarketItemType>;
        normalMarkets: Array<{
            market: MarketModelType | undefined;
            selections: SelectionModelType[];
            }>;
        } | 'loading' {
        if (eventMarkets === null) {
            return 'loading';
        }

        const markets = eventMarkets.map((smallMarket) => {
            const market = this.getMarket(smallMarket.id)?.getData();
            const selections = [];

            if (market !== undefined) {
                for (const selectionId of market.selections) {
                    const selection = this.getSelectionAndLoad(smallMarket.id, selectionId)?.getData();
                    if (selection !== undefined) {
                        selections.push(selection);
                    }
                }
            }

            return {
                market,
                selections
            };
        });

        return {
            smallMarkets: eventMarkets,
            normalMarkets: markets,
        };
    }

    public debugRab(id: number): Array<RabMarketType> | 'loading-event' | 'loading-rab' {
        const eventModel = this.getEvent(id);
        const rabId = eventModel?.rabId ?? null;

        if (rabId === null) {
            return 'loading-event';
        }

        const rabMarkets = this.getRabMarkets(rabId);

        if (rabMarkets === null) {
            return 'loading-rab';
        }

        return rabMarkets.markets ?? [];
    }

    public debugEvent(eventId: number): void {
        autorun(() => {
            const event = this.getEvent(eventId)?.getRawData();

            console.info(`Event=${eventId}`, {
                event,
                eventMarkets: this.debugMarkets(this.getEventMarkets(eventId)),
                eventMarketsAll: this.debugMarkets(this.getEventMarketsAll(eventId)),
                rabMarkets: this.debugRab(eventId),
            });
        });
    }
}
