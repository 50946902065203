import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LikeAndInfoSection, LikeButton, HeartFilledIconWrapper, HeartOutlinedIconWrapper, InfoIconWrapper } from './GameLikeAndInfo.style';

interface PropsType {
    isFavourite: boolean,
    gameId: number,
    onHeartClick: (providerGameId: number) => void,
    onInfoClick?: () => void
}

export const LikeAndInfo = observer('LikeAndInfo', (props: PropsType): JSX.Element => {
    const { isFavourite, onInfoClick } = props;

    const handleLikeButton = (): void => {
        props.onHeartClick(props.gameId);
    };

    return (
        <LikeAndInfoSection data-test='like-and-info-section'>
            <InfoIconWrapper
                onClick={onInfoClick}
            />
            <LikeButton onClick={handleLikeButton} data-test='like-button'>
                {isFavourite ? <HeartFilledIconWrapper /> : <HeartOutlinedIconWrapper />}
            </LikeButton>
        </LikeAndInfoSection>
    );
});
