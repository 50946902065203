import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';
import {
    GameStatusWrapper,
    PropertyWrapper,
    PropertyLabel,
    PropertyValue,
    PropertyValueCounter,
} from './GameStatus.style';

interface GameStatusInnerPropsType {
    appCasinoState: AppCasinoState;
    gameDataState: GameDataState;
    userId: number;
}

const GameStatusInner = observer('GameStatus', (props: GameStatusInnerPropsType): React.ReactElement | null => {
    const { appCasinoState } = props;
    const { isFullScreen } = appCasinoState.gameModalState;
    const { gameDataState } = props;
    const gameStatusState = appCasinoState.gameModalState.loadedGameStatusState;

    if (gameStatusState === null) {
        return null;
    }

    const { netPositionForView, gameDurationCounter } = gameStatusState;
    const hasStatusBar = gameDataState.gameType === 'casino' && appCasinoState.features.config.gameStatusBar;

    if (hasStatusBar === false) {
        return null;
    }

    return (
        <GameStatusWrapper isFullScreen={isFullScreen} isMini={gameDataState.isMini === true} data-test='game-status'>

            <PropertyWrapper >
                <PropertyLabel data-test='elapsed-time-label'>
                    <I18n langKey='casino.game-status.timer' defaultText='Elapsed Time' />
                </PropertyLabel>
                <PropertyValueCounter>
                    {gameDurationCounter}
                </PropertyValueCounter>
            </PropertyWrapper>

            <PropertyWrapper >
                <PropertyLabel data-test='net-position-label'>
                    <I18n langKey='casino.game-status.position' defaultText='Net Position' />
                </PropertyLabel>
                <PropertyValue data-test='net-position-value'>
                    {netPositionForView}
                </PropertyValue>
            </PropertyWrapper>

        </GameStatusWrapper>
    );
});

interface PropsType {
    appCasinoState: AppCasinoState;
    gameDataState: GameDataState;
}

export const GameStatus = observer('GameStatus', (props: PropsType): React.ReactElement | null => {
    const { appCasinoState } = props;
    const { sdkCustomer } = appCasinoState;

    if (sdkCustomer.session.userId === null) {
        return null;
    }

    return <GameStatusInner appCasinoState={appCasinoState} gameDataState={props.gameDataState} userId={sdkCustomer.session.userId} />;
});
