import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { EventsFilterGroup } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterGroup';
import { EventsFilterGroupState } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterGroupState';
import { EventsList } from 'src/domains/sportsbook/webview/components/eventsList/EventsList';
import { FilterKindType } from 'src/domains/sportsbook/webview/components/eventsFilters/EventsFilterType';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { SectionHeader } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader';
import { SportHeaderStar } from 'src/domains/layouts/webview/components/sportHeader/SportHeader';
import { SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import NFLlogo from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/Official_NFL_logo.webp';
import { MainSectionWrapper } from 'src/domains/layouts/webview/components/sectionHeader/SectionHeader.style';
import {
    EventsNFLLogoImage,
    EventsNFLLogoImageWrapper,
    EventsTableHeader,
    FiltersStyle,
    Heading,
    HeadingTitle,
} from 'src/domains/sportsbook/webview/components/eventsTable/EventsTable.style';

interface PropsType {
    showHeaderSport: boolean;
    allGroupsOpen: boolean;
    title: React.ReactNode;
    icon: 'in-play' | 'star-fill' | null;
    isNotOutright?: boolean;
    filters: Array<FilterKindType>;
    headerStyle?: EventsTableHeaderStyle;
    isTogglable: boolean;
    showPriceLegend: boolean;
    dataTest?: string;
    eventsFilter: EventsFilterGroupState;
    collection: EventsCollectionQueryModel;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    filterVersion: FiltersSkin;
    isCompetitionPage?: boolean;
    isAmericanFootball: boolean;
    specialSportState: SpecialSportsState | null;
}

export enum EventsTableHeaderStyle {
    SOLID = 'solid',
    DOUBLE = 'double',
}

export const EventsMainSectionWrapper = observer('EventsMainSectionWrapper', (props: PropsType): JSX.Element => {
    const {
        allGroupsOpen,
        dataTest,
        eventsFilter,
        headerStyle = EventsTableHeaderStyle.SOLID,
        icon,
        isNotOutright = true,
        filters,
        isTogglable,
        showHeaderSport,
        showPriceLegend,
        title,
        collection,
        headerStyleLevel,
        filterVersion,
        isCompetitionPage,
        isAmericanFootball,
        specialSportState,
    } = props;
    const { appSportsBookState } = useAppStateContext();

    const isNotSpecial = specialSportState === null || specialSportState.isSpecial === false;
    const isSpecialNotLoading =
        specialSportState === null || appSportsBookState.specialSportsListState.isLoading === false;
    return (
        <MainSectionWrapper data-test={dataTest}>
            {isNotSpecial ? (
                isTogglable ? (
                    <SectionHeader
                        title={title}
                        isOpen={eventsFilter.isOpen}
                        handleOnToggle={eventsFilter.onToggle}
                    />
                ) : (
                    <EventsTableHeader headerStyle={headerStyle}>
                        <Heading headerStyle={headerStyle}>
                            <HeadingTitle>{title}</HeadingTitle>
                        </Heading>
                    </EventsTableHeader>
                )
            ) : null}

            {eventsFilter.isOpen ? (
                <>
                    {isNotOutright && isNotSpecial && isSpecialNotLoading && filters.length > 0 ? (
                        <FiltersStyle
                            headerStyle={headerStyle}
                            isCompetitionPage={isCompetitionPage}
                        >
                            <EventsFilterGroup
                                skin={filterVersion}
                                eventsFilter={eventsFilter}
                                filters={filters}
                                key='header-filters'
                            />
                        </FiltersStyle>
                    ) : null}

                    {eventsFilter.isSpecialSport ? (
                        <SportHeaderStar
                            options={{ desktopImage: '', mobileImage: '', tabletImage: '' }}
                            isSpecialEvent={true}
                            sportId={specialSportState?.sportId ?? ''}
                            isCompetitionPage={false}
                            competitionId={null}
                        />
                    ) : null}

                    <EventsList
                        events={collection}
                        filterMarketsByPopularTag={icon === 'in-play' || icon === 'star-fill'}
                        showHeaderSport={showHeaderSport}
                        allGroupsOpen={allGroupsOpen}
                        headerStyle={headerStyleLevel}
                        specialSportsState={specialSportState}
                    />

                    {showPriceLegend ? <PriceChangeLegend isRace={false} /> : null}

                    {isAmericanFootball ? (
                        <EventsNFLLogoImageWrapper>
                            <EventsNFLLogoImage
                                src={NFLlogo}
                                alt='Official NFL logo'
                            />
                        </EventsNFLLogoImageWrapper>
                    ) : null}
                </>
            ) : null}
        </MainSectionWrapper>
    );
});
