import { action, computed, observable, makeObservable } from 'mobx';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { AppCasinoState } from 'src/domains/casino/shared/Types';
import { HeadlineCarouselActiveModelType } from 'src/api/config/cms_new/headlineCarouselActive/getHeadlineCarouselActive';

const TERMS_LENGTH_LIMIT = 140;

export enum RedirectTypeEnum {
    REDIRECT_URL = 'REDIRECT_URL',
    REDIRECT_NONE = 'REDIRECT_NONE',
    REDIRECT_GAME = 'REDIRECT_GAME',
    REDIRECT_CATEGORY_CASINO = 'REDIRECT_CATEGORY_CASINO',
    REDIRECT_CATEGORY_LIVE_CASINO = 'REDIRECT_CATEGORY_LIVE_CASINO',
}

export interface HeadlineCarouselSlideImagesType {
    desktopImg: string | undefined;
    tabletImg: string | undefined;
    mobileImg: string | undefined;
}

export class HeadlineCarouselSlideState {
    @observable public isShowTC: boolean = false;

    public constructor(
        private readonly headlineCarouselItem: HeadlineCarouselActiveModelType,
        private readonly router: StarRouter,
        private readonly appCasinoState: AppCasinoState
    ) {
        makeObservable(this);
    }

    @computed public get actionTemplate(): RedirectTypeEnum | null {
        const actionTemplate = this.headlineCarouselItem.action_template ?? null;

        if (
            RedirectTypeEnum.REDIRECT_URL === actionTemplate ||
            RedirectTypeEnum.REDIRECT_NONE === actionTemplate ||
            RedirectTypeEnum.REDIRECT_GAME === actionTemplate ||
            RedirectTypeEnum.REDIRECT_CATEGORY_CASINO === actionTemplate ||
            RedirectTypeEnum.REDIRECT_CATEGORY_LIVE_CASINO === actionTemplate
        ) {
            return actionTemplate;
        }

        return null;
    }

    @computed public get directLink(): string | null {
        if (this.actionTemplate === RedirectTypeEnum.REDIRECT_URL) {
            const btn_link = this.headlineCarouselItem.direct_link ?? '';
            return btn_link === '' ? null : btn_link;
        }

        return null;
    }

    @computed public get bgImagesUrl(): HeadlineCarouselSlideImagesType {
        const desktopImg = this.headlineCarouselItem.img_url_desktop ?? '';
        const tabletImg = this.headlineCarouselItem.img_url_tablet ?? '';
        const mobileImg = this.headlineCarouselItem.img_url_mobile ?? '';

        return {
            desktopImg: desktopImg === '' ? undefined : desktopImg,
            tabletImg: tabletImg === '' ? undefined : tabletImg,
            mobileImg: mobileImg === '' ? undefined : mobileImg,
        };
    }

    @action public handleMatchUrl = (): void => {
        if (this.directLink !== null) {
            this.router.handleUrlRedirection(this.directLink);
        }
    };

    @action public handleRedirect = (): void => {
        const { game_id, category_id, direct_link } = this.headlineCarouselItem;
        const casino_game_id_view = game_id ?? null;
        const category_id_view = category_id ?? null;
        const direct_link_view = direct_link ?? null;

        if (this.actionTemplate === RedirectTypeEnum.REDIRECT_GAME && casino_game_id_view !== null) {
            const gameLink = parseInt(casino_game_id_view, 10);

            if (isNaN(gameLink) === false) {
                this.appCasinoState.gameStoreExt.getGameModel(gameLink)?.startGame();
            }
            return;
        }

        if (this.actionTemplate === RedirectTypeEnum.REDIRECT_CATEGORY_CASINO && category_id_view !== null) {
            this.router.redirectToCasinoTabPage(category_id_view);
            return;
        }

        if (this.actionTemplate === RedirectTypeEnum.REDIRECT_CATEGORY_LIVE_CASINO && category_id_view !== null) {
            this.router.redirectToLiveCasinoTabPage(category_id_view.toString());
            return;
        }

        if (this.actionTemplate === RedirectTypeEnum.REDIRECT_URL && direct_link_view !== null) {
            this.router.handleUrlRedirection(direct_link_view);
            return;
        }
    };

    @computed public get isTooLongTerms(): boolean {
        const txText = this.headlineCarouselItem.tc_text ?? null;
        if (txText !== null) {
            const text = txText.replaceAll('&nbsp;', ' ');
            return text.length > TERMS_LENGTH_LIMIT;
        }

        return false;
    }

    @action public onApplyTC = (): void => {
        this.router.redirectToHeadlineCarouselTerms(this.headlineCarouselItem.id);
    };
}
