import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { MatchLeadNBAState } from './MatchLeadNBA.state';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { AmericanSportMatchLead } from 'src/domains/sportsbook/webview/components/matchLead/americanSportMatchLead/AmericanSportMatchLead';

interface MatchLeadNBAPropsType {
    event: EventModel;
}

export const MatchLeadNBA = observer<MatchLeadNBAPropsType>('MatchLeadNBA', ({ event }) => {
    const [state] = useState(() => new MatchLeadNBAState(event));

    return <AmericanSportMatchLead state={state} />;
});
