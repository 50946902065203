import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const PostcodeWrapper = styled('div', { label: 'PostcodeWrapper' })`
    display: flex;
`;

export const PostcodeInputWrapper = styled('div', { label: 'PostcodeInputWrapper' })`
    flex: 1 1 0%;
`;

export const FindAddressBtnWrapper = withConfig(theme => styled(Button, { label: 'FindAddressBtnWrapper' })`
    border: 0;
    flex: 0 0 82px;
    font-size: ${theme.star.fontSize.xRegular};
    height: 40px;
    margin: 36px 0 0 3px;
    padding: 0;
`);
