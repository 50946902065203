import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { VerifyWithSMS } from './verifyWithSMS/VerifyWithSMS';
import { VerifyWithEmail } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithEmail/VerifyWithEmail';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import {
    VerifyAccountHeader,
    VerifyAccountHeaderWelcome,
    VerifyAccountHeaderWrapper,
    VerifyAccountInfoHeader,
    VerifyAccountInfoWrapper,
    VerifyAccountText,
    VerifyAccountWrapper
} from './VerifyAccountTab.style';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';

export const VerifyAccountTab = observer('VerifyAccountTab',() => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { configComponents, starRouter } = appLayoutsState;
    const { isVerifyAccountWithSMSAvailable } = configComponents.config;

    const { loginState } = appPlayersState;
    const email = loginState.emailToVerifyAccount;

    useEffect((): (() => void) => {
        const dispose = autorun(() => {
            if (email === null) {
                starRouter.redirectToLogin();
            }
        });
        return dispose;
    }, []);

    return (
        <div className='verify-account'>
            <VerifyAccountWrapper className='verify-account-tab'>
                <VerifyAccountHeaderWrapper key='verify-desc-1'>
                    <VerifyAccountHeader>
                        <VerifyAccountHeaderWelcome>
                            <I18n langKey='account.verify-account-tab.title' defaultText='Welcome back!' />
                        </VerifyAccountHeaderWelcome>
                    </VerifyAccountHeader>
                </VerifyAccountHeaderWrapper>

                <VerifyAccountText key='verify-desc-2'>
                    <I18n langKey='account.verify-account-tab.please-verify-note.line-1' defaultText='Our new and improved website is now live, packed with new features and improved user experience. Please verify your account to continue.' />
                </VerifyAccountText>

                {
                    isVerifyAccountWithSMSAvailable === true
                        ? <VerifyWithSMS key='verify-with-SMS' />
                        : null
                }

                {
                    isVerifyAccountWithSMSAvailable === true
                        ? <VerifyAccountText key='verify-conjunction-txt'>
                            <I18n langKey='account.verify-account-tab.or' defaultText='or' />
                        </VerifyAccountText>
                        : null
                }

                <VerifyWithEmail key='verify-with-email' />

                <VerifyAccountInfoWrapper key='verify-desc-4' >
                    <VerifyAccountInfoHeader data-test='verify-account-info-header'>
                        <I18n langKey='account.verification-troubles.title' defaultText='Having Trouble?' />
                    </VerifyAccountInfoHeader>

                    <VerifyAccountText info={true} data-test='verify-account-text'>
                        <I18n langKey='account.verification-troubles.description' defaultText='If you’re having any issues, please contact support and we’ll get you sorted.' />
                    </VerifyAccountText>
                </VerifyAccountInfoWrapper>

                <AccountHelperInfo key='verify-account-helper-info' />
            </VerifyAccountWrapper>
        </div>
    );
});
