import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const LegsChangedMessage = observer('LegsChangedMessage', () => {
    return (
        <Messages
            type='info'
            message={
                <I18n
                    langKey='betslip.warning-message.selection_changed'
                    defaultText='The line, odds or availability of your selections have changed'
                />
            }
        />
    );
});
