import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

import { assertNever } from 'src_common/common/assertNever';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const ResponseErrorMessages = observer('ResponseErrorMessages', () => {
    const { appSportsBookState } = useAppStateContext();
    const { responseErrorMsg } = appSportsBookState.betSlipState.betSlipSummaryState;

    return (
        <>
            {responseErrorMsg.map((elem) => {
                if (elem === 'BET-TOO-HIGH') {
                    return (
                        <Messages
                            type='info'
                            key='Warning:change'
                            message={
                                <I18n
                                    langKey='betslip.message.warning.bet-too-high'
                                    defaultText='Bet stake is too high'
                                />
                            }
                        />
                    );
                }
                if (elem === 'BET-TOO-LOW') {
                    return (
                        <Messages
                            type='info'
                            key='Warning:change'
                            message={
                                <I18n
                                    langKey='betslip.message.warning.bet-too-low'
                                    defaultText='Bet is below the minimum stake'
                                />
                            }
                        />
                    );
                }
                if (elem === 'MAX-BET-EXCEEDED') {
                    return (
                        <Messages
                            type='info'
                            key='Warning:change'
                            message={
                                <I18n
                                    langKey='betslip.message.warning.max.bet.exceeded'
                                    defaultText='You have exceeded your max bet for this combination'
                                />
                            }
                        />
                    );
                }
                if (elem === 'PAYOUT-TOO-HIGH') {
                    return (
                        <Messages
                            type='info'
                            key='Warning:change'
                            message={
                                <I18n
                                    langKey='betslip.message.warning.bet-payout-too-high'
                                    defaultText='Bet exceeds max payout'
                                />
                            }
                        />
                    );
                }
                if (elem === 'INTERNAL_SERVER_ERROR_MESSAGE') {
                    return (
                        <Messages
                            type='error'
                            key='Warning:change'
                            message={
                                <I18n
                                    langKey='betslip.message.place-bet-error'
                                    defaultText='Sorry, we seem to have a problem. Please try again.'
                                />
                            }
                        />
                    );
                }
                return assertNever('ResponseErrorMessages', elem);
            })}
        </>
    );
});
