import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ScoreboardLSport } from 'src/domains/sportsbook/webview/components/scoreboards/lSport/ScoreboardLSport';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface PropsType {
    sport: string;
    lsportExternalId: string | null;
    timeSettingsStarted: boolean;
}

export const LSportWrapper = observer(
    'LSportWrapper',
    ({ sport, lsportExternalId, timeSettingsStarted }: PropsType): JSX.Element | null => {
        const common = useCommon();
        const config = ConfigComponents.get(common).config;

        const lSportExternalId = lsportExternalId ?? null;

        if (lSportExternalId === null || config.scoreboard.lSport === null) {
            return null;
        }

        /* StatsCore (old lSport) -> we have implemented only tennis and golf on BE */
        if (sport === 'tennis') {
            const groupIdForView = timeSettingsStarted
                ? config.statsCoreGroupId.liveMatchPro
                : config.statsCoreGroupId.preMatchPro;

            return (
                <ScoreboardLSport
                    lSportExternalId={lSportExternalId}
                    groupId={groupIdForView}
                />
            );
        }

        return null;
    }
);
