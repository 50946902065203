import { computed, makeObservable } from 'mobx';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { EventsGroupState, UserViewSport, UserViewCompetition } from './EventsGroupState';
import { getMarketAndSelections, PossibleMarketAndSelectionInListType } from './getMarketAndSelections';

// TODO: Translation
export const SELECTIONS_LABELS = (getTranslation: (key: string, defaultText: string) => string): Record<string, string> => ({
    S: getTranslation('market.score', 'Score'),
    H: getTranslation('market.home', 'Home'),
    T: getTranslation('market.tie', 'Tie'),
    D: getTranslation('market.draw', 'Draw'),
    A: getTranslation('market.away', 'Away'),
    'unnamed-favourite': getTranslation('market.uf', 'UF'),
    'tennis:H': getTranslation('market.player-1', 'Player 1'),
    'tennis:A': getTranslation('market.player-2', 'Player 2'),
});

export class EventsGroupViewCompetition {
    public readonly state: EventsGroupState; 
    public readonly sport: string;
    public readonly name: string;
    public readonly id: number;
    private readonly competitionController: UserViewCompetition;
    public readonly events: Array<EventListGroupEventItemType>;

    public constructor(
        state: EventsGroupState,
        sport: string,
        name: string, //competition name
        id: number,
        competitionController: UserViewCompetition,
        events: Array<EventListGroupEventItemType>
    ) {
        makeObservable(this);
        this.state = state;
        this.sport = sport;
        this.name = name;
        this.id = id;
        this.competitionController = competitionController;
        this.events = events;
    }

    public get visible(): boolean {
        return this.competitionController.visible;
    }

    @computed.struct public get possibleMarketAndSelectionInList(): PossibleMarketAndSelectionInListType {
        return getMarketAndSelections(this.events);
    }

    public get areEventsTwoParticipantsOnly(): boolean {
        return this.events.some((event) => event.template.startsWith('two-participant'));
    }

    public isFirstEventOutright = (events: Array<EventListGroupEventItemType>): boolean => {
        const first = events[0];
        if (first !== undefined) {
            return first.template === 'outrights';
        }

        return false;
    };
    
    public get isOutrightEvent(): boolean {
        return this.isFirstEventOutright(this.events);
    }

    @computed.struct public get lastSelectionsHeader(): Array<string> {
        const lastSelections = this.possibleMarketAndSelectionInList.headers;

        const selectionsLabels = SELECTIONS_LABELS(this.state.getTranslation);
        return lastSelections.map(x =>
            selectionsLabels[`${this.sport}:${x}`] ??
            selectionsLabels[x] ??
            x
        );
    }
    public toggle = (): void => {
        this.competitionController.toggle();
    };
}

export class EventsGroupViewSport {
    public readonly id: string;
    private readonly sportController: UserViewSport;
    public readonly competitions: ReadonlyArray<EventsGroupViewCompetition>;
    
    public constructor(id: string, sportController: UserViewSport, competitions: ReadonlyArray<EventsGroupViewCompetition>) {
        this.id = id;
        this.sportController = sportController;
        this.competitions = competitions;
    }

    public get visible(): boolean {
        return this.sportController.visible;
    }

    public toggle = (): void => {
        this.sportController.toggle();
    };
}

export class EventsGroupView {
    public readonly sports: ReadonlyArray<EventsGroupViewSport>;

    public constructor(sports: ReadonlyArray<EventsGroupViewSport>) {
        this.sports = sports;
    }
}
