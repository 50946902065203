import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppState } from 'src/appState/AppState';
import { CollectionType } from 'src_common/cypress/types/casino';
import { GroupHeader, GroupWrapper } from 'src/domains/layouts/webview/components/debugPanel/DebugPanel.style';
import { RTLGameDetails } from './RTLGameDetails';

interface PropsTypes {
    appState: AppState;
    collection: CollectionType | null;
    tabId: string | null;
}

export const RTLDebug = observer('RTLDebug', ({ appState, collection, tabId }: PropsTypes) => {
    if (collection === null) {
        return <span>
            Open collection page (virtuals | casino | live-casino).
        </span>;
    }

    const casinoPageState = appState.appCasinoState.casinoPageState.get(collection).get(tabId);

    if (collection !== 'virtuals' && casinoPageState.navigationState.isHomeActive) {
        return <span>Casino Home. Open any category.</span>;
    }

    const gameModels = (collection === 'virtuals') ? casinoPageState.navigationState.gamesListToDisplayNonCategorized : casinoPageState.navigationState.gamesListToDisplay;

    if (gameModels === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <>
            <GroupWrapper>
                <GroupHeader>Collection: {collection} | TabId: {tabId}</GroupHeader>
            </GroupWrapper>

            {gameModels.map((gameModel) => {
                return (
                    <RTLGameDetails
                        key={gameModel.id}
                        appState={appState}
                        gameModel={gameModel}
                    />
                );
            })}
        </>
    );
});
