import * as React from 'react';
import { ParticipantWrapper, SelectionName, ChevronIconStyle, SmallDropdownChevronWrapper } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/AlternativeMainContent.styles';
import { observer } from 'src/utils/mobx-react';
import { AlternativeCustomSelectionState } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeCustomSelectionState';
import { MaskImg } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/maskImg/MaskImg';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';

interface SelectionNameComponentType {
    alternativeSelection: AlternativeCustomSelectionState,
    selectionModel: SelectionModel
}

export const SelectionNameComponent = observer('SelectionNameComponent', ({ alternativeSelection, selectionModel }: SelectionNameComponentType) => {

    const { name, mask, isShouldShowDetails, isShowDetails } = alternativeSelection;

    return (
        <ParticipantWrapper>
            {isShouldShowDetails === true ? (
                <SmallDropdownChevronWrapper>
                    <ChevronIconStyle position={isShowDetails ? 'down' : 'right'} />
                </SmallDropdownChevronWrapper> 
            ): null}
            
            <MaskImg mask={mask} selectionModel={selectionModel} />

            <SelectionName>{name}</SelectionName>
        </ParticipantWrapper>
    );
});
