import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { ScrollState } from 'src/domains/sportsbook/shared/Types';
import { ScrollWrapper, ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';
import { LinksContentWrapper, LinksWrapper, ScrollButtonWrapper, ScrollLeftButton, ScrollRightButton } from './LinksScrollableWrapper.style';
import { useAppStateContext } from 'src/appState/AppState';

interface PropsTypes {
    children?: React.ReactNode;
    languageWidth: number;
}

export const LinksScrollableWrapper = observer('LinksScrollableWrapper', ({ children, languageWidth }: PropsTypes) => {
    const [scrollState] = useState(() => new ScrollState());

    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;
    const isLeftButtonVisible = isLargeDesktop === true && scrollState.scrollLeftArrow === true;
    const isRightButtonVisible = isLargeDesktop === true && scrollState.scrollRightArrow === true;

    const contentView = <LinksContentWrapper>{children}</LinksContentWrapper>;
    return (
        <LinksWrapper languageWidth={languageWidth}>
            {isLeftButtonVisible === true ?
                <ScrollButtonWrapper isVisible={true} position='left' onClick={scrollState.goToLeft}>
                    <ScrollLeftButton>
                        <ButtonChevronIcon position='left' />
                    </ScrollLeftButton>
                </ScrollButtonWrapper> : null
            }
            <ScrollWrapper ref={scrollState.setRef}>
                {contentView}
            </ScrollWrapper>
            {isRightButtonVisible === true ?
                <ScrollButtonWrapper isVisible={true} position='right' onClick={scrollState.goToRight}>
                    <ScrollRightButton>
                        <ButtonChevronIcon position='right' />
                    </ScrollRightButton>
                </ScrollButtonWrapper> : null
            }
        </LinksWrapper>
    );

});
