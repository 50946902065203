import { observable, computed, action, makeObservable } from 'mobx';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { DateTime } from 'src_common/utils/time/time';

interface AttributionType {
     utm_id?: string;
     utm_source?: string;
     utm_medium?: string;
     utm_campaign?: string;
     utm_term?: string;
     utm_content?: string;
     utm_channel?: string;
     utm_custom?: { name: string; value: string} | null;

     ajs_uid?: string;
     ajs_event?: string;
     ajs_aid?: string;
     ajs_prop?: { name: string; value: string} | null;
     ajs_trait?: { name: string; value: string} | null;
}


export type UserAttributionsLocalStorageObjectType = {
   value: AttributionType | null; 
   expiryDate: number | null;
   clientId: string | null;
   incomeaccess: string | null;
};

const customUserAttributionTags: Array<string> = [
    'ajs_prop',
    'ajs_trait',
    'utm_custom',
];

export class UserAttributionsLocalStorage {

    @observable public userAttributions: LocalStorageItemState<UserAttributionsLocalStorageObjectType>;

    public constructor(
        localStorageItem: LocalStorageItemState<UserAttributionsLocalStorageObjectType>,
        private readonly getIncomeaccess: () => string | null
    ) {
        makeObservable(this);
        this.userAttributions = localStorageItem;
    }

    @computed public get attributions(): AttributionType | null {
        return this.userAttributions.getValue().value;
    }

    @computed public get attributionsFlattened(): Record<string, string> {
        const tempAttributions: Record<string, string> = {};
        const attributionsRow = this.attributions ?? {};

        for (const [key, value] of Object.entries(attributionsRow)) {
            if (value !== undefined) {
                if (customUserAttributionTags.includes(key)) {
                    tempAttributions[value.name] = value.value;
                } else {
                    tempAttributions[key] = value;
                }
            }
        }
        
        return tempAttributions;
    }

    @computed public get attributionsNormalized(): Record<string, string> {
        const tempAttributions: Record<string, string> = {};

        for (const [key, value] of Object.entries(this.attributionsFlattened)) {
            const isCustomTag = customUserAttributionTags.some((customTag: string): boolean => key.startsWith(customTag));

            if (key === 'ajs_uid' || key === 'ajs_aid') {
                tempAttributions[key] = value;
            }
            if (key === 'ajs_event') {
                tempAttributions['external_event'] = value;
            }
            if (isCustomTag === true) {
                tempAttributions[key.slice(4)] = value;
            }
        }

        return tempAttributions;
    }

    @computed public get expiryDate(): number | null {
        return this.userAttributions.getValue().expiryDate;
    }

    @computed public get isDateExpired(): boolean {
        const newDate = DateTime.current().unixSeconds();
        return this.expiryDate === null ? true : newDate > this.expiryDate;
    }

    @computed public get gtmClientId(): string | null {
        const match = /(?:^|;)\s*_ga=([^;]*)/.exec(document.cookie);
        const matchElem = match === null ? null : match[1] ?? null;
        const raw = matchElem === null ? null : decodeURIComponent(matchElem);
    
        if (raw !== null) {
            const matchId = /(\d+\.\d+)$/.exec(raw);
            return matchId === null ? matchId : matchId[1] ?? null;
        }
        return null;
    }

    @computed public get incomeaccess(): string | null {
        return this.getIncomeaccess();
    }

    @action public validateDate = (): void => {
        if (this.isDateExpired) {
            this.userAttributions.setValue({
                value: null,
                expiryDate: null,
                clientId: null,
                incomeaccess: null
            });
        } else {
            this.onSetAttribution(this.attributions);
        }
    };

    private onSetAttribution = (attribute: AttributionType | null): void => {
        const newDate = DateTime.current().utc().addMinutes(30).unixSeconds() ;

        this.userAttributions.setValue({ 
            value: attribute,
            expiryDate: newDate,
            clientId: this.gtmClientId,
            incomeaccess: this.incomeaccess
        });   
    };

    @action public onSetNewAttribute = (attribute: AttributionType): void => {
        this.onSetAttribution(attribute);   
    };

}
