import React, { useState } from 'react';
import { computed, makeObservable } from 'mobx';
import { HeroWidgetRacing } from './HeroWidgetRacing';
import { observer } from 'src/utils/mobx-react';
import { HomepageHeroWidgetDefault } from './HeroWidgetDefault';
import { HomepageHeroWidgetNFL } from './nfl/HomepageHeroWidgetNFL';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { LoadingWrapper } from 'src/domains/layouts/shared/loaders';
import { useAppStateContext } from 'src/appState/AppState';

class HeroWidgetState {
    public readonly reactiveWrapper: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);

    public constructor() {
        makeObservable(this);
    }

    @computed public get elementRefHeight(): number {
        const elementRef = this.reactiveWrapper.ref;
        return elementRef?.clientHeight ?? 0;
    }
}

export const HeroWidget = observer('HeroWidget', (): JSX.Element | null => {
    // delete specialEvents when django cms will be deleted
    const [state] = useState(() => new HeroWidgetState());
    const { appLayoutsState } = useAppStateContext();
    const { heroWidgetListForView } = appLayoutsState.heroWidgetState;

    return (
        <LoadingWrapper computedHeight={state.elementRefHeight} data-test='hero-widget'>
            <div ref={state.reactiveWrapper.setRef}>
                {heroWidgetListForView.length > 0
                    ? heroWidgetListForView.map((heroWidgetItem) => {
                          const { event_id, market_id } = heroWidgetItem;
                          const eventModel = event_id.getEventModel();
                          const marketModel = market_id.getModel();

                          if (eventModel !== null && marketModel !== null) {
                              if (eventModel.sport === 'horseracing' || eventModel.sport === 'greyhoundracing') {
                                  return (
                                      <HeroWidgetRacing
                                          key={event_id.toOldId()}
                                          heroWidgetItem={heroWidgetItem}
                                          sport={eventModel.sport}
                                      />
                                  );
                              }
                              if (eventModel.sport === 'americanfootball') {
                                  const smallEvent = eventModel.convertToSmallEvent();
                                  return (
                                      <HomepageHeroWidgetNFL
                                          key={event_id.toOldId()}
                                          heroWidgetItem={heroWidgetItem}
                                          smallEvent={smallEvent}
                                      />
                                  );
                              }

                              return (
                                  <HomepageHeroWidgetDefault key={event_id.toOldId()} heroWidgetItem={heroWidgetItem} />
                              );
                          }
                      })
                    : null}
            </div>
        </LoadingWrapper>
    );
});
