import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const svgAnimation = keyframes`
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
`;

const circleAnimationKey = keyframes`
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }
    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
`;

interface IconSpinnerType {
    className?: string;
    type?: 'basic';
}

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

const AnimationSvg = styled(SvgElement, { label: 'AnimationSvg' })`
     animation: 1s linear infinite ${svgAnimation};
`;

const CircleAnimation = styled('circle', { label: 'CircleAnimation' })`
    animation: 1.4s ease-in-out infinite both ${circleAnimationKey};
    fill: transparent;
    stroke: currentColor;
    stroke-linecap: square;
    stroke-dasharray: 283;
    stroke-dashoffset: 60;
    stroke-width: 8px;
    transform-origin: 50% 50%;
`;

export const Spinner = ({ className }: IconSpinnerType): JSX.Element => (
    <AnimationSvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 100 100' className={className}>
        <CircleAnimation cx='50' cy='50' r='45' className={className} />
    </AnimationSvg>
);
