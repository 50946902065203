import { computed, action, observable, makeObservable } from 'mobx';
import { PossibleBetsRequestState } from 'src/domains/sportsbook/betting/betSlipState/possibleBetsState/PossibleBetsState';
import { FreeBetRemarksType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

export interface CastBetsStateTypes {
    totalStake: Amount;
    stakePerLine: Amount;
    freebetRemarks: Array<FreeBetRemarksType> | null;
    shouldShow: boolean;
}

export class CastBetsState {
    private readonly possibleBetsRequestState: PossibleBetsRequestState;
    @observable public minOddsValue: number | undefined = undefined;

    public constructor(
        private readonly configComponents: ConfigComponents,
        possibleBetsRequestState: PossibleBetsRequestState
    ) {
        makeObservable(this);
        this.possibleBetsRequestState = possibleBetsRequestState;
    }

    @action public setMinOddsValue = (value: number): void => {
        this.minOddsValue = value;
    };

    @computed private get castBetsRaw(): Array<CastBetsStateTypes> {
        const tempCastBets: Array<CastBetsStateTypes> = [];
        const rawCastBets = [
            ...Array.from(this.possibleBetsRequestState.combinationsForViewMap.values()),
            ...this.possibleBetsRequestState.parsedLegsPossibleBetsResponse,
        ];

        for (const cast of rawCastBets) {
            if (cast.stakePerLine !== undefined && cast.stakePerLine !== null) {
                const castElem: CastBetsStateTypes = {
                    totalStake: Amount.newOption(cast.totalStake) ?? new Amount('0'),
                    stakePerLine: new Amount(cast.stakePerLine),
                    freebetRemarks: cast.freebetRemarks,
                    shouldShow:
                        this.minOddsValue !== undefined && cast.price !== null && cast.price !== undefined
                            ? cast.price.d <= this.minOddsValue
                            : false,
                };
                tempCastBets.push(castElem);
            }
        }
        return tempCastBets;
    }

    @computed public get shouldDisplayMinOdds(): boolean {
        return this.castBetsRaw.some((elem: CastBetsStateTypes) => elem.shouldShow === true) === true;
    }

    @computed public get showCredit(): boolean {
        return !this.isFreeBetExceedsAmount;
    }

    @computed public get minOdds(): string {
        for (const bet of this.castBetsRaw) {
            if (bet.freebetRemarks !== null) {
                for (const remark of bet.freebetRemarks) {
                    if (remark.code === 'minimum' && 'minimum' in remark.details) {
                        return remark.details.minimum;
                    }
                }
            }
        }

        return 'n/a';
    }

    @computed public get minOddsWarning(): boolean {
        for (const bet of this.castBetsRaw) {
            if (bet.stakePerLine.isGreaterThanZero() && bet.freebetRemarks !== null) {
                for (const remark of bet.freebetRemarks) {
                    if (remark.code === 'minimum' && 'minimum' in remark.details) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private isFreeBetExceedsAmountForRemark = (freebetRemarkItem: FreeBetRemarksType, totalStake: Amount): boolean => {
        // @ts-expect-error TODO: verify BE model
        const required = freebetRemarkItem.details.required ?? null;

        if (required !== null && typeof required === 'number') {
            if (required === this.configComponents.precision.valueOldFormat(totalStake)) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    @computed public get isFreeBetExceedsAmount(): boolean {
        for (const bet of this.castBetsRaw) {
            if (bet.stakePerLine.isGreaterThanZero() && bet.freebetRemarks !== null && bet.freebetRemarks.length > 0) {
                for (const remark of bet.freebetRemarks) {
                    if (this.isFreeBetExceedsAmountForRemark(remark, bet.totalStake)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
}
