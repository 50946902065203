import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { observable, makeObservable } from 'mobx';

class ExperimentItemState {
    @observable public result: string | null = null;

    public constructor(experimentID: string) {
        makeObservable(this);

        try {
            // tslint:disable-next-line
            if (typeof window === 'undefined') {
                return;
            }

            //Only for browser
            const updateVariant = (value: unknown): void => {
                //@ts-expect-error
                this.result = value === undefined || value === null ? '0' : value;
            };

            //@ts-expect-error
            const { dataLayer, google_optimize } = window;
            const delayedInitialization = (): void => {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                const value = google_optimize && google_optimize.get(experimentID);
                this.result = value;
            };

            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (dataLayer) {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                const hideEnd = dataLayer.hide && dataLayer.hide.end;

                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (hideEnd) {
                    dataLayer.hide.end = (): void => {
                        delayedInitialization();
                        hideEnd();
                    };
                } else {
                    delayedInitialization();
                }

                dataLayer.push('event', 'optimize.callback', {
                    name: experimentID,
                    callback: updateVariant,
                });
            }
        } catch (err) {
            console.error('Failed to initialise ExperimentItemState class', err);
        }
    }

    public getVariant(): string | null {
        return this.result;
    }
}

export class GoogleOptimizeState {
    public readonly variants: MobxMapAutoNew<string, ExperimentItemState>;

    public constructor() {
        this.variants = new MobxMapAutoNew((experimentId: string) => new ExperimentItemState(experimentId));
    }

    public getVariant(experimentID: string): string | null {
        const result = this.variants.get(experimentID);
        return result.getVariant();
    }
}
