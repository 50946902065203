import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { PromotedEventsState, PromotedSportSingleType } from 'src/domains/layouts/webview/components/promotedSports/PromotedEventsState.state';
import { observer } from 'src/utils/mobx-react';
import { EventGroupHeader } from 'src/domains/sportsbook/webview/components/eventGroupHeader/EventGroupHeader';
import { makeObservable, observable } from 'mobx';
import { CompetitionData } from './CompetitionData';
interface PromotedSportWrapperPropsTypes {
    sportSlug: string;
    sportData: PromotedSportSingleType;
    promotedEventsState: PromotedEventsState;
}
class State {
    @observable public isOpenedSport: boolean = true;

    public constructor() {
        makeObservable(this);
    }

    public setIsOpened = (): void => {
        this.isOpenedSport = !this.isOpenedSport;
    };

}

export const PromotedSportSectionWrapper = observer('PromotedSportSectionWrapper', ({ sportSlug, sportData }: PromotedSportWrapperPropsTypes) => {
    const { appLayoutsState : { sdkCustomer: { models } } } = useAppStateContext();
    const [state] = useState(() => new State());

    const sportName = models.getSport(sportSlug)?.name ?? '';
    const competitionsSorted = sportData.competitions_list.sort((a,b) => a.display_order - b.display_order);
    return (
        <>
            <EventGroupHeader
                selections={[]}
                label={sportName}
                headerStyle='sportLevel'
                open={state.isOpenedSport}
                isOutrightEvent={false}
                small={false}
                onToggle={state.setIsOpened}
                data-test='promoted-event-group'
            /> 
            {state.isOpenedSport ? <>
                {competitionsSorted.map((competition) => (
                    <CompetitionData key={competition.id} sportSlug={sportSlug} competition={competition}/>
                ))}
            </> : null }
        </>
    );
});
