import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

type GetTranslationType = (id: string, defaultText: string) => string;

export const validatePhone = (getTranslation: GetTranslationType) => (value: string): Result<string> => {
    const REGEXP = /^([0-9]\$*){7,15}$/;
    if (!REGEXP.test(value)) {
        return Result.createError(getTranslation('errors.phoneNumber', 'Phone number can contain only 7-15 numbers.'));
    }
    return Result.createOk(value);
};
