import { MethodType, ParamsFetchType, GenerateUrlApiType, ResponseType } from 'src_common/browser/apiUtils';

import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import { openapiProxyAnonymousWeatherRequest, decodeResponse200, Response200Type, ParamsType } from 'src/api_openapi/generated/openapi_proxy_anonymous_weather';

export type WeatherModelType = Response200Type;

const decode = (status: number, data: ResponseType): WeatherModelType => {
    if (status === 200 && data.type === 'json') {
        return decodeResponse200(data.json);
    }

    throw new Error(`unhandled response ${status} - ${data.type}`);
};

interface ParamsDataType {
    data: ParamsType['requestBody']
}

export const get_weather = {
    browser: {
        params: (params: ParamsDataType): ParamsFetchType<ParamsDataType> => {
            return {
                type: MethodType.POST,
                url: '/api-web/get_weather',
                body: params
            };
        },
        decode: decode
    },
    express: {
        method: MethodType.POST,
        urlBrowser: '/api-web/get_weather',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<ParamsDataType>): Promise<GenerateUrlApiType> => {
        const response = await openapiProxyAnonymousWeatherRequest(params.API_URL, params.API_TIMEOUT, params.jwtToken, {
            requestBody: { 
                lat: params.req.body.data.lat,
                lon: params.req.body.data.lon,
            }
        });

        return {
            passToBackend: false,
            status: response.status,
            responseBody: response.body
        };
    }
};
