import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface VirtualsIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const VirtualsIcon = observer(
    'VirtualsIcon',
    ({ className, onClick }: VirtualsIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M3.71 8.394l7.398 4.174v8.464l-7.397-4.164V8.394H3.71zm8.5-6.047l7.388 4.166-7.384 4.167-7.389-4.167 7.384-4.166h.001zm10.168 3.225a.02.02 0 01-.014-.005l.002-.003-9.608-5.42a1.119 1.119 0 00-1.098 0L2.052 5.562l.004.009-.006.001c-.342.194-.55.554-.55.941v10.99c0 .392.212.753.557.947l9.61 5.41c.065.035.134.06.205.082.02.005.038.017.06.024a1.209 1.209 0 00.564 0c.022-.005.04-.017.06-.024.07-.023.14-.047.205-.083l9.608-5.409c.346-.194.56-.555.56-.948V6.515c0-.389-.21-.749-.55-.943h-.001z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
