import * as React from 'react';

import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { NotificationsPageStyle, NotificationsWrapper } from './NotificationsPage.style';
import { NotificationsList } from 'src/domains/layouts/webview/components/notificationsList/NotificationsList';
import { NotificationsListRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsTypes {
    currentView: NotificationsListRouteType;
    accountView: RightHandSideViewType | null;
    isSearchActive: boolean;
    isNotificationsList: boolean;
}

export const Notifications = observer('Notifications', ({ currentView, accountView, isSearchActive, isNotificationsList }: PropsTypes) => {
    return (
        <NotificationsPageStyle className='notifications-page'>
            <LayoutSkeleton currentView={currentView} accountView={accountView} isSearchActive={isSearchActive} navigationType='global' >
                <NotificationsWrapper>
                    <NotificationsList isNotificationsList={isNotificationsList}/>
                </NotificationsWrapper>
            </LayoutSkeleton>
        </NotificationsPageStyle>
    );
});
