import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export interface MainWrapperUlPropsType {
    inProgress: boolean;
    width: number | null;
    transX: number;
}

export const RacingCardsWrapper = styled('ul', { label: 'RacingCardsWrapper' })<MainWrapperUlPropsType>`
    align-items: flex-start;
    display: flex;
    transform: translateX(-${({ transX }): number => transX}px);
    transition: ${({ inProgress }): string => inProgress ? 'none': '.5s transform cubic-bezier(0, 0, 0, 1.04)'};
    width: ${({ width }): string => width === null ? '100%' : `${width}px;`};
`;

export const RacingCard = styled('li', { label: 'RacingCard' })<{width?: number | null}>`
    flex-shrink: 0;
    width: ${({ width }): string => width === undefined || width === null ? '100%' : `${width}px`};
`;

interface HeaderPropsType {
    isRacingToggle: boolean;
}

export const Header = withConfig(theme => styled('div', { label: 'Header' })<HeaderPropsType>`
    align-items: center;
    background-color: ${theme.star.racingNextOff.slider.bgColorSecondary};
    display: flex;
    flex-direction: row;
    justify-content: ${({ isRacingToggle }): string => isRacingToggle ? 'flex-start' : 'space-between'};
`);

export const RacesSliderSection = withConfig(theme => styled('section', { label: 'RacesSliderSection' })`
    background-color: ${theme.star.racingNextOff.slider.bgColor};
    color: ${theme.star.racingNextOff.slider.txtColor};
    overflow: hidden;
    position: relative;
`);

export const RacesSliderInfo = styled('div', { label: 'RacesSliderInfo' })`
    border-top: none;
    padding: 10px 5px;
    position: relative;
    text-align: center;
`;

export const RaceSliderWrapper = styled('div', { label: 'RaceSliderWrapper' })`
    overflow: hidden;
    position: relative;
    width: 100%;
`;

export const RacesSliderInner = withConfig(theme => styled('div', { label: 'RacesSliderInner' })`
    width: 64%;
    @media ${theme.star.mediaQuery.xMobile} {
        width: 80%;
    }
    @media ${theme.star.mediaQuery.tablet} {
        width: calc(100% + 2px);
    }
`);
