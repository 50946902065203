import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ssrReactLazy } from 'src_common/utils/Idle';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';
import { LazyLoadWrapper } from 'src/domains/casino/utils/lazyLoadWrapper';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface GamesOnHomepagePropsType {
    appCasinoState: AppCasinoState;
}

const CasinoGamesHomepage = ssrReactLazy(
    async () => (await import('src/domains/casino/webview/gamesOnHomepage/CasinoGamesHomepage')).CasinoGamesHomepage
);

export const CasinoGamesOnHomepage = observer('CasinoGamesOnHomepage', (props: GamesOnHomepagePropsType) => {
    const common = useCommon();
    const featureState = FeatureState.get(common);
    const configState = ConfigComponents.get(common).config;
    const { appCasinoState } = props;

    if (featureState.allowCasinoInIOSWrapperNew === false) {
        return null;
    }

    if (configState.casinoPublishedCollections.includes('casino') === false) {
        return null;
    }

    return (
        <LazyLoadWrapper>
            <CasinoGamesHomepage appCasinoState={appCasinoState} />
        </LazyLoadWrapper>
    );
});

const VirtualGamesHomepage = ssrReactLazy(
    async () => (await import('src/domains/casino/webview/gamesOnHomepage/VirtualGamesHomepage')).VirtualGamesHomepage
);

export const VirtualGamesOnHomepage = observer('VirtualGamesOnHomepage', (props: GamesOnHomepagePropsType) => {
    const common = useCommon();
    const featureState = FeatureState.get(common);
    const configState = ConfigComponents.get(common).config;
    const { appCasinoState } = props;

    if (featureState.allowCasinoInIOSWrapperNew === false) {
        return null;
    }

    if (configState.casinoPublishedCollections.includes('virtuals') === false) {
        return null;
    }

    return (
        <LazyLoadWrapper>
            <VirtualGamesHomepage appCasinoState={appCasinoState} />
        </LazyLoadWrapper>
    );
});
