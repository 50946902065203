import styled from '@emotion/styled';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';
import { styleConfig } from 'src/domains/casino/webview/CasinoGames/game-modal/modal-body/modal-success/bingo-content/styleConfig';
import { BingoMiniGamesIcon } from 'src/domains/layouts/shared/assets/icons';

// 0-840px mobile view
// from 841px tablet/desktop view (bar width: 320px)

export const BingoMiniGamesContainer = styled('div', { label: 'BingoMiniGamesContainer' }) <{ extended: boolean }>`
    background-color: ${styleConfig.color.white};
    width: 100%;
    height: ${(props): string => props.extended ? '33vh' : '40px'};
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    transition: height ease .5s;

    @media (min-width: ${styleConfig.width.tablet}) {
        width: 280px;
        height: 100%;
        transition: none;
    }

    @media (max-height: ${styleConfig.height.smallMobile}) {
        display: none;
    }
`;

export const HeaderContainer = styled('div', { label: 'HeaderContainer' })`
    margin: 0 10px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (min-width: ${styleConfig.width.tablet}) {
        height: 50px;
    }
    @media (min-width: ${styleConfig.width.desktop}) {
        height: 84px;
    }
`;

export const ExpandContainer = styled('div', { label: 'ExpandContainer' })`
    display: flex;
    align-items: center;
`;

export const HeaderTitle = styled('p', { label: 'HeaderTitle' })`
    color: ${styleConfig.color.black};
    font-family: ${styleConfig.font.family};
    font-weight: ${styleConfig.font.weight};
    margin: 0 5px;
`;

export const HeaderArrow = styled('div', { label: 'HeaderArrow' }) <{ extended: boolean }>`
    width: 26px;
    height: 26px;
    position: relative;
    transform: rotate(${({ extended }): string => extended ? '180deg' : '0deg'});
    cursor: pointer;

    @media (min-width: 841px) {
        display: none;
    }

    &::before, &::after {
        content: '';
        background-color: ${styleConfig.color.black};
        width: 10px;
        height: 3px;
        display: block;
        border-radius: 1px;
        position: absolute;
        top: 30%;
    }

    &::before {
        left: 2px;
        transform: rotate(-45deg);
        transform-origin: center right;
    }
    &::after {
        left: calc(50% - 2px);
        transform: rotate(45deg);
        transform-origin: center left;
    }
`;

export const BingoIcon = styled(BingoMiniGamesIcon, { label: 'BingoIcon' })`
    fill: ${styleConfig.color.orange};
`;

export const CloseButton = styled('div', { label: 'CloseButton' }) <{ isModalEnabled: boolean }>`
    width: 28px;
    height: 28px;
    display: ${(props): string => props.isModalEnabled ? 'block' : 'none'};
    position: relative;
    transform: translate(-50% -50%);
    cursor: pointer;

    &::before, &::after {
        content: '';
        background-color: ${styleConfig.color.black};
        height: 15px;
        width: 3px;
        border-radius: 1px;
        position: absolute;
        left: 50%;
        top: 25%;
    }

    &::before {
        transform: rotate(-45deg);
    }
    &::after {
        transform: rotate(45deg);
    }
`;

export const BodyContainer = styled('div', { label: 'BodyContainer' }) <{ isModalEnabled: boolean }>`
    display: ${(props): string => props.isModalEnabled ? 'none' : 'block'};
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: ${styleConfig.width.tablet}) {
        display: block;
    }
`;

export const GamesGridContainer = styled('div', { label: 'GamesGridContainer' })`
    padding: 0 10px 10px;
    display: grid;
    grid-template-columns: 1fr  1fr;
    grid-auto-rows: auto;
    gap: 10px;

    @media (min-width: ${styleConfig.width.mobile}) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: ${styleConfig.width.tablet}) {
        grid-template-columns: 1fr  1fr;
    }
`;

export const GameTile = styled('div', { label: 'GameTile' })`
    background-color: gray;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
`;

export const GameImage = styled(LazyLoadingImg, { label: 'GameImage' })`
    width: 100%;
    padding-top: 70%;
    position: relative;
`;
