import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const DateInputWrapper = styled('div', { label: 'DateInputWrapper' })``;



export const InputDescription = withConfig(theme => styled('span', { label: 'InputDescription' })`

    display: block;

    font-size: ${theme.star.fontSize.small};

    letter-spacing: -0.2px;

    line-height: 1.3334;

    opacity: .7;

`);

