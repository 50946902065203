import * as React from 'react';

import { SingleScoreItem, SingleScoreWrapper } from 'src/domains/sportsbook/webview/components/events/row/Score.style';

import { observer } from 'src/utils/mobx-react';

import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';



interface ScorePropsType {

    smallEvent: EventListGroupEventItemType;

    dataTest?: string;

}



export const Score = observer('Score', (props: ScorePropsType) => {

    const { smallEvent, dataTest } = props;



    const eventModel = smallEvent.id2.getEventModel();



    if (eventModel === null) {

        return null;

    }



    const scoreHome = eventModel.timeSettingsStarted ? eventModel.scoreHome : null;

    const scoreAway = eventModel.timeSettingsStarted ? eventModel.scoreAway : null;



    if (scoreHome !== null && scoreAway !== null) {

        return (

            <SingleScoreWrapper data-test={dataTest}>

                <SingleScoreItem>{scoreHome}</SingleScoreItem>

                <SingleScoreItem>{scoreAway}</SingleScoreItem>

            </SingleScoreWrapper>

        );

    }



    return null;

});

