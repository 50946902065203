import { css } from '@emotion/react';

// Change it to function
export const positionElementFull = css`
    inset: 0;
    @supports not (inset: 0) {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;

export const positionAbsoluteToRight = css`
    inset: 0 0 0 auto;
    margin: auto 0 auto auto;
    position: absolute;
    @supports not (inset: 0) {
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
    }
`;
