import React, { useState } from 'react';
import { RenderAdditionalInfo, AddToBetslip, Confirmation, ResolveConflictsLabel } from 'src/domains/sportsbook/webview/components/youDecide/YouDecide';
import { RabItem, ItemType } from 'src/domains/sportsbook/webview/components/youDecide/RabItem';
import {
    YouDecideMobileWrapper,
    OpacityLayer,
    YouDecideMobileContent,
    YouDecideMobileTab,
    YouDecideMobileTabShow,
    YouDecideMobileList,
    RabSelectionChevronIconWrapper
} from 'src/domains/sportsbook/webview/components/youDecide/youDecideMobile/YouDecideMobile.style';
import { YouDecideListHeaderRemove, IconTrashWrapper } from 'src/domains/sportsbook/webview/components/youDecide/YouDecide.style';

import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { YouDecideMobileState } from 'src/domains/sportsbook/webview/components/youDecide/youDecideMobile/YouDecideMobileState';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface MobileLabelsType {
    items: Array<ItemType>;
    rabListIsOpened: boolean;
}

const MobileLabels = observer('MobileLabels', (props: MobileLabelsType): JSX.Element | null => {
    const { items, rabListIsOpened } = props;
    const amount = items.length;

    if (rabListIsOpened) {
        if (amount === 1) {
            return (
                <I18n
                    langKey='bets.you-decide.additional-info.hide'
                    defaultText='Hide {amount} selection'
                    params={{ amount }}
                />
            );
        }
        return (
            <I18n
                langKey='bets.you-decide.additional-info.hide.more'
                defaultText='Hide {amount} selections'
                params={{ amount }}
            />
        );
    }

    if (amount === 1) {
        return (
            <I18n
                langKey='bets.you-decide.additional-info.show'
                defaultText='Show {amount} selection'
                params={{ amount }}
            />
        );
    }

    return (
        <I18n
            langKey='bets.you-decide.additional-info.show.more'
            defaultText='Show {amount} selections'
            params={{ amount }}
        />
    );
});

interface YouDecideMobilePropsType {
    eventId: EventId | null;
}

export const YouDecideMobile = observer('YouDecideMobile', ({ eventId }: YouDecideMobilePropsType): JSX.Element | null => {
    const appState = useAppStateContext();
    const { appSportsBookState } = appState;
    const [state] = useState(() => new YouDecideMobileState(appState));
    const rabBasket = eventId === null ? null : appSportsBookState.rab.getOrCreateRabBasket(eventId);
    const price = rabBasket?.rabStatus.price;
    const priceString = rabBasket?.rabStatus.priceString;
    const conflictingBets = rabBasket?.rabStatus.conflictingBets;
    const conflictingBetsMessage = rabBasket?.rabStatus.conflictingBetsMessage;
    const conflictingBetsSecondaryMessage = conflictingBetsMessage === undefined ? undefined : 'Please resolve to continue.';
    const suspendedBets = rabBasket?.rabStatus.suspendedBets;
    const items = rabBasket?.rabOffer;

    if (eventId === null || conflictingBets === undefined || suspendedBets === undefined) {
        return null;
    }

    const showContentAndOverlay = (): JSX.Element | null | undefined => {
        if (state.isConfirmationOpened === true) {
            return <OpacityLayer onClick={state.handleCloseRabSection} />;
        }

        return null;
    };

    return (
        <YouDecideMobileWrapper>
            {showContentAndOverlay()}
            <YouDecideMobileContent>
                {(price !== null && price !== undefined && priceString !== null && priceString !== undefined) ?
                    <AddToBetslip
                        priceString={priceString}
                        onAdd={state.onAddToBetslipInner}
                    /> : null}
                {state.isConfirmationOpened === true ? <Confirmation /> : null}
                {conflictingBets === true ? <ResolveConflictsLabel primaryText={conflictingBetsMessage}
                    secondaryText={conflictingBetsSecondaryMessage} /> : null}
                {suspendedBets === true ? <ResolveConflictsLabel primaryText='Some or all of your selections are no longer available.'
                    secondaryText='Please resolve to continue.' /> : null}

                {
                    items !== undefined && items.length > 0 ?
                        <>
                            <YouDecideMobileTab>
                                <YouDecideMobileTabShow onClick={state.handleToggleRabSection}>
                                    <RabSelectionChevronIconWrapper
                                        position={state.rabListIsOpened ? 'down' : 'right'}
                                    />
                                    <MobileLabels
                                        items={items}
                                        rabListIsOpened={state.rabListIsOpened}
                                    />
                                </YouDecideMobileTabShow>

                                <YouDecideListHeaderRemove onClick={state.appState.appSportsBookState.rab.clearRabList}>

                                    Remove all
                                    <IconTrashWrapper className='x-IconTrashWrapper' />
                                </YouDecideListHeaderRemove>
                            </YouDecideMobileTab>

                            <YouDecideMobileList isOpened={state.rabListIsOpened}>
                                {items.map((item, id) => (
                                    <RabItem key={id} item={item} conflicting={conflictingBets || suspendedBets} rabBasket={rabBasket} />
                                ))}
                                <RenderAdditionalInfo
                                    items={items}
                                    areConflictingBets={conflictingBets || suspendedBets}
                                />
                            </YouDecideMobileList>

                        </> :
                        items === undefined ? null : (
                            <YouDecideMobileList isOpened={true}>
                                <RenderAdditionalInfo
                                    items={items}
                                    areConflictingBets={conflictingBets || suspendedBets}
                                />
                            </YouDecideMobileList>
                        )
                }
            </YouDecideMobileContent>
        </YouDecideMobileWrapper>
    );
});
