import { AccountModel } from 'src/domains/players/state/accountState/AccountModel';
import { computed, observable, makeObservable } from 'mobx';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { assertNever } from 'src_common/common/assertNever';

export type OddsTypeValues = 'fractional' | 'decimal';
type BalanceInHeaderValues = 'show' | 'hide';

export class SettingsFormState {

    @observable public savingOddsType: boolean = false;

    public constructor(
        private readonly usersState: UsersState,
        private readonly accountModel: AccountModel,
        private readonly localStorageState: LocalStorageState,
    ){
        makeObservable(this);
    }

    @computed public get oddsType(): OddsTypeValues | null {
        const isFraction = this.oddsTypeFractional;
        const isDecimal = this.oddsTypeDecimal;

        if (isFraction) {
            return 'fractional';
        }

        if (isDecimal) {
            return 'decimal';
        }

        return null;
    }

    @computed private get oddsTypeFractional(): boolean {
        if (this.oddsFormat === 'fractional' && this.savingOddsType === false) {
            return true;
        }

        if (this.oddsFormat === 'decimal' && this.savingOddsType === true) {
            return true;
        }

        return false;
    }

    @computed private get oddsTypeDecimal(): boolean {
        if (this.oddsFormat === 'decimal' && this.savingOddsType === false) {
            return true;
        }

        if (this.oddsFormat === 'fractional' && this.savingOddsType === true) {
            return true;
        }

        return false;
    }

    @computed private get oddsFormat(): OddsTypeValues | null {
        const oddsFormatShort = this.usersState.oddsFormatShort;

        if (oddsFormatShort === 'd') {
            return 'decimal';
        }

        if (oddsFormatShort === 'f') {
            return 'fractional';
        }

        return assertNever('SettingsForm->oddsFormat', oddsFormatShort);
    }

    @computed public get balanceInHeader(): BalanceInHeaderValues {
        const showBalanceInHeader = this.localStorageState.balanceInHeader.getValue();
        return showBalanceInHeader === true ? 'show' : 'hide';
    }

    public updateOddsTypeIfChanged = async (): Promise<void> => {
        if (this.savingOddsType) {
            return;
        }

        const oddsType = this.oddsType;

        if (oddsType === null) {
            return;
        }

        this.savingOddsType = true;

        const newOddsType: OddsTypeValues = oddsType === 'fractional' ? 'decimal' : 'fractional';

        try {
            await this.accountModel.onChangeOddsType(newOddsType);
            await this.usersState.basicData.refreshAndWait();
        } catch (error) {
            console.error(error);
        }

        this.savingOddsType = false;
    };


    public updateBalanceInHeaderIfChanged = (newValue: string): void => {
        const newBooleanValue = newValue === 'show' ? true : false;
        const oldValue = this.localStorageState.balanceInHeader.getValue();

        if (newBooleanValue !== oldValue) {
            this.localStorageState.balanceInHeader.setValue(newBooleanValue);
        }
    };

}
