import { useAsObservableSource } from 'mobx-react-lite';
import { observable, makeObservable, action } from 'mobx';
import { useState, useEffect } from 'react';

interface Params {
    callback?: (isClickOut: boolean) => void;
}

class State {
    @observable public refElement: HTMLElement | null = null;
    @observable public isClickOutside: boolean = false;

    public constructor(private readonly params: Params) {
        makeObservable(this);
    };

    @action public handleClick = (event: MouseEvent | TouchEvent): void => {
        if (this.refElement === null) {
            return;
        };
        const target = event.target;

        if (target instanceof HTMLElement) {
            this.isClickOutside = !this.refElement.contains(target);

            if (this.params.callback !== undefined) {
                this.params.callback(this.isClickOutside);
            };
        } else {
            console.error('useClickOutside: Event target is not HTML element');
        };
    };

    public setRef = (refElement: HTMLElement | null): void => {
        this.refElement = refElement;
    };
}

type SetRef = (refElement: HTMLElement | null) => void;

// TODO: move to global hooks library
export const useClickOutside = (paramsIn: Params): [SetRef, boolean] => {
    const params = useAsObservableSource(paramsIn);
    const [state] = useState(() => new State(params));

    useEffect(() => {
        window.addEventListener('click', state.handleClick);
        document.addEventListener('touchstart', state.handleClick);
        document.addEventListener('mousedown', state.handleClick);

        return (): void => {
            window.removeEventListener('click', state.handleClick);
            document.removeEventListener('mousedown', state.handleClick);
            document.removeEventListener('touchstart', state.handleClick);
        };
    }, [state.handleClick]);

    return [state.setRef, state.isClickOutside];
};
