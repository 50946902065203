import { computed, makeObservable } from 'mobx';
import { apiCommon } from 'src/api/ApiCommon';
import { ActiveQuickLinkTrayType } from 'src/api/config/cms/getActiveQuickLinkTray';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { sortByNumberField } from 'src_common/utils/sport/sort';

export class QuickLinkTrayState {
    private readonly quickLinkTrayResource: Resource<Array<ActiveQuickLinkTrayType>>;

    public constructor(models: ModelsState) {
        makeObservable(this);
        this.quickLinkTrayResource = new Resource(async (): Promise<Array<ActiveQuickLinkTrayType>> => {
            const list = await apiCommon.getActiveQuickLinkTray.run({});

            const result = [];

            for (const item of list) {
                result.push({
                    ...item,
                    event_id: models.id.getEventIdOption(item.event_id ?? null),
                });
            }

            return result;
        });
    }

    @computed public get isLoading(): boolean {
        const result = this.quickLinkTrayResource.get();
        return result.type === 'loading';
    }

    @computed private get activeQuickLinkTray(): Array<ActiveQuickLinkTrayType> {
        const result = this.quickLinkTrayResource.get();
        if (result.type === 'ready') {
            const links = result.value.filter(link => {
                if (link.redirect_option === 'event-id') {
                    const eventId = link.event_id ?? null;
                    if (eventId !== null) {
                        const eventModel = eventId.getEventModel();
                        if (eventModel !== null) {
                            return eventModel.active && eventModel.display;
                        }

                    }
                }
                return true;
            });
            return links;
        }
        return [];
    }

    @computed public get sortedLinks(): Array<ActiveQuickLinkTrayType> {
        return this.activeQuickLinkTray.sort((v1: ActiveQuickLinkTrayType, v2: ActiveQuickLinkTrayType) => sortByNumberField(v1.display_order, v2.display_order));
    }
}
