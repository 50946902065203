import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { LogContext, WebSocketApi, connect } from './SocketConnect';

export type SocketEventType = {
    type: 'message';
    message: string;
    socket: SocketConnection;
} | {
    type: 'open';
    socket: SocketConnection;
} | {
    type: 'close';
    socket: SocketConnection;
};

export type UnsubscribeFnType = () => void;

export interface StartSocketResultType {
    send: (message: string) => void;
    dispose: UnsubscribeFnType;
}

export class SocketConnection {
    public constructor(
        public readonly close: () => void,
        public readonly send: (message: string) => void,
        public readonly log: LogContext,
    ) {}
}

export const startSocket = (
    log: LogContext,
    host: string,
    timeoutTime: number,
    sendMessage: (data: SocketEventType) => void,
): StartSocketResultType => {
    let isConnect: boolean = true;
    let socketContext: WebSocketApi | null = null;

    (async (): Promise<void> => {
        while (isConnect) {
            const eventMessage = new EventEmmiter<string>();
            const openSocketResult = connect(log, host, timeoutTime, eventMessage.trigger);

            const socketApi = await openSocketResult.socket;

            if (socketApi === null) {
                log.info('reconnect after error wait 3000ms');
                await timeout(3000);
                log.info('reconnect after error go forth');
                continue;
            }

            const socket = new SocketConnection(socketApi.close, socketApi.send, log);

            socketContext = socket;
            sendMessage({
                type: 'open',
                socket
            });

            eventMessage.on(message => {
                sendMessage({
                    type: 'message',
                    message,
                    socket
                });
            });

            await openSocketResult.done;

            sendMessage({
                type: 'close',
                socket
            });
        }

        log.info('disconnect');
    })().catch((error) => {
        console.error(error);
    });

    return {
        send: (message: string): void => {
            if (socketContext !== null) {
                socketContext.send(message);
            }
        },
        dispose: (): void => {
            isConnect = false;
            socketContext?.close();
        }
    };
};
