import React from 'react';
import { RealityCheckPopupState } from 'src/domains/layouts/state/popups/RealityCheckPopupState';
import { TimerItem, TimerWrapper } from 'src/domains/layouts/webview/components/popups/realityCheckPopup/RealityCheckTimer.style';
import { observer } from 'src/utils/mobx-react';

interface RealityCheckTimerPropsType {
    state: RealityCheckPopupState;
}

export const RealityCheckTimer = observer('RealityCheckTimer', ({ state }: RealityCheckTimerPropsType) => {
    return (
        <TimerWrapper>
            <TimerItem>{state.realityCheckTimerHours}</TimerItem>
            <TimerItem>{state.realityCheckTimerMinutes}</TimerItem>
            <TimerItem>{state.realityCheckTimerSeconds}</TimerItem>
        </TimerWrapper>
    );
});
