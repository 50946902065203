import { sortBy } from 'lodash';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';

export const name = (x: SelectionModel): string => {
    return x.name;
};

const nrSort = (x: SelectionModel): number => {
    return x.resultType === 'void' || x.templateId === 'NG'  ? 99999 : 99998;
};

export const price = (x: SelectionModel): number => {
    return (
        x.resultType === 'void' ? 99999 :
            x.templateId === 'NG' ? 99999 :
                x.templateId === 'unnamed-favourite' ? 100000 :
                    x.price !== undefined ? x.price.d : 99998
    );
};

export const sortSelections = (selections: Array<SelectionModel>): Array<SelectionModel> => {
    const selectionsByName = sortSelectionsByName(selections);
    const selectionsByPriceAndName = sortBy(selectionsByName, price);

    return selectionsByPriceAndName;
};

export const sortSelectionsByName = (selections: Array<SelectionModel>): Array<SelectionModel> => {
    const byName = sortBy(selections, name);
    return sortBy(byName, nrSort);
};

export const sortGreyhoundsSelections = (selections: Array<SelectionModel>): Array<SelectionModel> => {
    return sortBy(selections, [
        (x: SelectionModel): number => {
            if (typeof x.metaDataNumber === 'string') {
                return 0;
            }

            if (x.resultType === 'void') {
                return 2;
            }

            return 1;
        },
        (x: SelectionModel): number => {
            const metaDataNumber = x.metaDataNumber;
            if (typeof metaDataNumber === 'string' && x.resultType !== 'void') {
                return parseInt(metaDataNumber, 10);
            }

            return 1000;
        }
    ]);
};

