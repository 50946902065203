import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { CheckIcon, NewSelect, SelectContainer, SelectIcon } from './SelectNew.style';
import { OptionProps, components, DropdownIndicatorProps } from 'react-select';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { createGuard } from 'src_common/common/createGuard';
import * as t from 'io-ts';
import { OptionType } from 'src/domains/layouts/webview/components/dropdown/dropdown.types';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export interface SelectedOption {
    label: string;
    value: string | number;
}

const OptionDataIO = t.interface({
    value: t.string,
    label: t.string,
});

const checkOptionType = createGuard(OptionDataIO);

interface SelectPropsTypes {
    options: Array<SelectedOption>;
    inputState: FormInputState<string, string | number>;
    isDisabled?: boolean;
    onChange?: (option: OptionType | undefined) => void;
    placeholder?: string;
    isSearchable?: boolean;
    dataTest?: string;
    label?: JSX.Element;
}

class SelectInnerState {
    public constructor(private readonly props: SelectPropsTypes) {}

    public onChangeHandler = (option: unknown): void => {
        if (!checkOptionType(option)) {
            return;
        }

        this.props.inputState.setValue(option.value);

        if (this.props.onChange !== undefined) {
            this.props.onChange(option);
        }
    };
}

export const SelectNew = observer('SelectNew', (props: SelectPropsTypes): JSX.Element => {
    const [innerState] = useState(() => new SelectInnerState(props));

    const { options, placeholder, isSearchable, isDisabled, dataTest, label } = props;
    const CustomOption = (props: OptionProps): JSX.Element | null => {
        const { label } = props;
        const OptionDataTest = dataTest === undefined ? 'select-option' : `${dataTest}-option`;
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <components.Option {...props}>
                <div data-test={OptionDataTest}>{label}</div>
                {props.isSelected === true ? <CheckIcon /> : null}
            </components.Option>
        );
    };

    const CustomIndicator = (props: DropdownIndicatorProps): JSX.Element => {
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <components.DropdownIndicator {...props}>
                <SelectIcon position='down' />
            </components.DropdownIndicator>
        );
    };

    return (
        <SelectContainer data-test={dataTest}>
            {label === undefined ? null : label}
            <NewSelect
                className='custom-dropdown-select'
                classNamePrefix='react-select'
                placeholder={placeholder}
                options={options}
                onChange={innerState.onChangeHandler}
                components={{ Option: CustomOption, DropdownIndicator: CustomIndicator }}
                isSearchable={isSearchable ?? false}
                isDisabled={isDisabled ?? false}
            />
            {props.inputState.result.value.type === 'error' && props.inputState.isVisited() ? (
                <Messages
                    type='error'
                    marginTop='8px'
                    marginBottom='24px'
                    message={props.inputState.result.value.message}
                />
            ) : null}
        </SelectContainer>
    );
});
