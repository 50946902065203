import { action, observable, computed, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import {
    validatePassword,
    validatePasswordsEqual,
    validateRequire,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { ResetPasswordRhsRouteType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface PasswordFormType {
    newPassword: string;
    confirmPassword: string;
    terms: boolean;
    marketing: boolean;
}

interface PasswordFormToSendType {
    newPassword: string;
    confirm: string;
    terms: boolean;
    marketing: boolean;
}

const termsValidator = (value: boolean): Result<boolean> => {
    if (!value) {
        return Result.createError(getErrorByCode('ERROR_REQUIRED_FIELD'));
    }
    return Result.createOk(value);
};
export class ResetPasswordFormState {
    @observable public isSuccess: boolean = false;
    @observable public errors: string[] = [];
    @observable public inProgress: boolean = false;
    @observable public isShowPassword: boolean = false;
    @observable public isShowConfirmPassword: boolean = false;

    public readonly newPasswordState: FormInputState<string, string>;

    public readonly confirmPasswordState: FormInputState<string, string>;

    public readonly termsState: FormInputState<boolean, boolean>;

    public readonly marketingState: FormInputState<boolean, boolean>;

    public readonly form: FormModel<PasswordFormToSendType>;

    public constructor(
        private readonly router: StarRouter,
        public readonly config: ConfigComponents,
        defaultTermsValue: boolean,
        defaultMarketingValue: boolean,
        public readonly accountState: AccountState,
        private readonly isEnhancedResetPassword: boolean = false
    ) {
        makeObservable(this);

        this.newPasswordState = FormInputState.new('').map(validateRequire).map(validatePassword);

        this.confirmPasswordState = FormInputState.new('')
            .map(validateRequire)
            .map((value) => {
                const validateIsTheSamePassords = validatePasswordsEqual({
                    password: this.newPasswordState.value,
                    confirm: value,
                });

                return validateIsTheSamePassords;
            });

        this.termsState = FormInputState.new(defaultTermsValue).map(termsValidator);

        this.marketingState = FormInputState.new(defaultMarketingValue);

        this.form = FormModel.group({
            newPassword: this.newPasswordState,
            confirmPassword: this.confirmPasswordState,
            terms: this.termsState,
            marketing: this.marketingState,
        }).map((item: PasswordFormType): Result<PasswordFormToSendType> => {
            return Result.createOk({
                newPassword: item.newPassword,
                confirm: item.confirmPassword,
                terms: item.terms,
                marketing: item.marketing,
            });
        });
    }

    @computed public get isFormValid(): boolean {
        return (
            this.newPasswordState.result.value.type === 'ok' &&
            this.confirmPasswordState.result.value.type === 'ok' &&
            this.termsState.result.value.type === 'ok'
        );
    }

    @computed public get isConfirmPassError(): boolean {
        return this.confirmPasswordState.errorsForView.length > 0;
    }

    @action public onSubmit = async (currentRoute: ResetPasswordRhsRouteType): Promise<void> => {
        this.inProgress = true;
        this.errors = [];
        this.form.setAsVisited();
        const result = this.form.result;
        if (result.value.type === 'ok') {
            const { confirm, newPassword, terms, marketing } = result.value.data;
            const { email, token, receivedVia } = currentRoute;
            const receivedViaStr: string = receivedVia ?? 'mail';
            const data = {
                confirm: confirm,
                newPassword: newPassword,
                email: email ?? '',
                token: token ?? '',
                receivedVia: receivedViaStr,
                terms: terms,
                marketing: marketing,
            };
            try {
                const resetPasswordResponse = await this.accountState.usersState.resetPassword({ requestBody: data });
                if (resetPasswordResponse.status === 'error') {
                    const codeMessages = resetPasswordResponse.error?.code;
                    const errorMessages = resetPasswordResponse.error?.errors?.map((item) => item.debugDetails);

                    this.errors = this.mapErrorsMessages(errorMessages, codeMessages);
                    this.inProgress = false;
                    return;
                }

                this.isSuccess = true;

                const loginAfterResetPassword =
                    this.isEnhancedResetPassword || this.config.config.loginOnResetPasswordSuccess;

                if (loginAfterResetPassword && email !== null) {
                    await this.login({
                        email: email,
                        password: newPassword,
                    });
                }
            } catch (error) {
                this.errors = this.mapErrorsMessages(undefined);
                this.inProgress = false;
                return;
            }
        }
        this.inProgress = false;
    };

    @action public login = async (data: { email: string; password: string }): Promise<void> => {
        const loggedUser = await this.accountState.loginUser(data.email, data.password, 'login');
        if (loggedUser.type === 'CreateSessionResponseOk') {
            this.form.reset();
            this.router.redirectToAccountSummary();
        }
    };

    @action public setShowPassword = (): void => {
        this.isShowPassword = !this.isShowPassword;
    };

    @action public setShowConfirmPassword = (): void => {
        this.isShowConfirmPassword = !this.isShowConfirmPassword;
    };

    public mapErrorsMessages = (errors?: (string | null | undefined)[], codeMessage?: string): string[] => {
        if (codeMessage === 'reset_password_not_allowed') {
            return [
                `Your account is not allowed to set new password. Please contact ${this.config.config.accountHelperMail}.`,
            ];
        }

        if (errors === undefined || errors.length === 0) {
            return ['Sorry, we seem to have a problem. Please try again.'];
        }

        const parsedErrors = errors.map((item) => item ?? '');

        if (parsedErrors.some((error) => error.includes('Your password reset token has expired'))) {
            return ['Your password reset link has expired, please use the Forgot password once again'];
        }

        return parsedErrors;
    };
}
