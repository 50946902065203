import React from 'react';
import { slug } from 'src/utils/deburr';
import { useAppStateContext } from 'src/appState/AppState';
import {
    EventRowNameContainer,
    EventRow,
    EventRowName,
    EventRowSelectionsContainer,
    EventRowAdditions,
    EventRowMoreMarketsLink,
    MoreMarketChevron,
    EventRowMobileChevron,
} from './EventRow6Pack.style';
import { EventRow6PackState } from './EventRow6Pack.state';
import { SelectionsGroup } from './EventRowSelectionsGroup';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { Score6Pack } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/Score6Pack';
import { Participant6Pack } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/Participant6Pack';
import { EventStatusAndDate } from 'src/domains/sportsbook/webview/components/events/row/StatusAndDate';

interface EventRow6PackPropsType {
    state: EventRow6PackState;
    smallEvent: EventListGroupEventItemType;
}

export const EventRow6Pack = observer<EventRow6PackPropsType>('EventRow6Pack', ({ state, smallEvent }) => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const eventId = smallEvent.id2;

    if (eventId.getEventModel()?.display === false) {
        return null;
    }

    return (
        <EventRow>
            <EventRowName to={{ name: 'event', id: eventId.toOldId(), slug: slug(smallEvent.name) }}>
                <EventRowNameContainer>
                    <Participant6Pack
                        smallEvent={smallEvent}
                        state={state}
                    />
                </EventRowNameContainer>

                <EventRowAdditions>
                    <EventStatusAndDate
                        dateFormat='todayAndTomorrow'
                        marketType='6pack'
                        smallEvent={smallEvent}
                    />

                    {breakpointsState.desktop.isBiggerOrEq === true ? (
                        <EventRowMoreMarketsLink>
                            <I18n langKey='events.row.6pack.more-markets' defaultText='More markets' />
                            <MoreMarketChevron position='right' />
                        </EventRowMoreMarketsLink>
                    ) : (
                        <EventRowMobileChevron position='right' />
                    )}
                </EventRowAdditions>
            </EventRowName>

            <Score6Pack smallEvent={smallEvent} />

            <EventRowSelectionsContainer>
                <SelectionsGroup
                    market={state.firstMarket}
                    marketTypeName={state.firstMarketName}
                    getSelectionNameCallback={state.getSelectionName}
                />
                <SelectionsGroup
                    market={state.secondMarket}
                    marketTypeName={state.secondMarketName}
                    getSelectionNameCallback={state.getSelectionName}
                />
                <SelectionsGroup
                    market={state.thirdMarket}
                    marketTypeName={state.thirdMarketName}
                    getSelectionNameCallback={state.getSelectionName}
                />
            </EventRowSelectionsContainer>
        </EventRow>
    );
});
