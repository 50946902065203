import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'src/utils/mobx-react';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { SelectionItemStyle } from 'src/domains/sportsbook/webview/components/selectionRaceCard/SelectionRaceCard.style';
import { useAppStateContext } from 'src/appState/AppState';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface DataType {
    selection: SelectionModel;
    indexAnyColumn: 2 | 3;
}

interface PropsType {
    className?: string;
    title: string | React.ReactNode;
    selectionId: SelectionId;
    index: number;
    borderTop?: boolean;
    borderRight?: boolean;
    borderBottom?: boolean;
    borderLeft?: boolean;
}

export const SelectionCast = observer('SelectionCast', (props: PropsType) => {
    const { appSportsBookState } = useAppStateContext();

    const getData = (): DataType | null => {
        const { selectionId } = props;
        const selectionModel = selectionId.getModel();

        if (selectionModel === null) {
            console.error('Expected selection', selectionId);
            return null;
        }

        const marketModel = selectionModel.getMarket();

        if (marketModel === null) {
            console.error('Expected market', selectionModel.marketId);
            return null;
        }

        const indexAnyColumn = marketModel.tricastsOffered ? 3 : 2;

        return {
            selection: selectionModel,
            indexAnyColumn,
        };
    };

    const choose = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void | null => {
        e.preventDefault();

        const data = getData();

        if (data === null) {
            return;
        }
        const { selection } = data;

        const selectionView = selection.forView();

        if (selectionView === null) {
            console.error(`Expected selectionView for ${props.selectionId.toOldId()}`);
            return null;
        }

        const { displayPrice } = selectionView;

        const sp = displayPrice === 'SP';
        appSportsBookState.betSlipState.betslipData.onAddCastBet(props.selectionId, props.index, sp);
    };

    const data = getData();

    if (data === null) {
        return null;
    }

    const { selection, indexAnyColumn } = data;

    const selectionView = selection.forView();

    if (selectionView === null) {
        console.error(`Expected selectionView for ${props.selectionId.toOldId()}`);
        return null;
    }

    const { suspended, price, priceChange } = selectionView;

    const { title, index, className: classNameFromProps } = props;

    const selectedId = selection.id;

    const selectedSelectionNew = appSportsBookState.betSlipState.legsState.coreLegsPossibleBetsRequest.find(
        (x) => x.selectionId.toOldId() === selectedId && x.index === index
    );

    const isColumnDisabledNew = appSportsBookState.betSlipState.legsState.coreLegsPossibleBetsRequest.find(
        (x) => x.selectionId.toOldId() === selectedId && x.index !== index
    );

    const isRowDisabledNew =
        (appSportsBookState.betSlipState.legsState.coreLegsPossibleBetsRequest.find(
            (x) => x.index === indexAnyColumn
        ) !== undefined &&
            index < indexAnyColumn) ||
        appSportsBookState.betSlipState.legsState.coreLegsPossibleBetsRequest.find(
            (x) => x.index !== indexAnyColumn && x.index === index && x.selectionId.toOldId() !== selectedId
        ) !== undefined ||
        appSportsBookState.betSlipState.legsState.coreLegsPossibleBetsRequest.find(
            (x) => x.index !== indexAnyColumn && index === indexAnyColumn
        ) !== undefined;

    const isDisabled = Boolean(isRowDisabledNew) || Boolean(isColumnDisabledNew);
    const isEmpty = price === undefined;
    const isSelected = selectedSelectionNew !== undefined;

    const className = classNames([classNameFromProps, 'selection']);

    const selectionId: number = selection.id2.toOldId();
    return (
        <SelectionItemStyle
            href='#'
            data-test-disabled={isDisabled}
            data-test-selection-id={selectionId}
            id={index.toString()}
            onClick={choose}
            className={className}
            isSelected={isSelected}
            isSuspended={suspended}
            isEmpty={isEmpty}
            isOddsUp={priceChange === 'up'}
            isOddsDown={priceChange === 'down'}
            isDisabled={isDisabled}
            borderBottom={props.borderBottom}
            borderLeft={props.borderLeft}
            borderRight={props.borderRight}
            borderTop={props.borderTop}
        >
            {title}
        </SelectionItemStyle>
    );
});
