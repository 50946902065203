//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
    requestBody: {
        endDate?: null | undefined | string;
        page: number;
        perPage: number;
        startDate?: null | undefined | string;
        types?: null | undefined | Array<string>;
    };
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/customer/transaction-history -> post -> ${code}`);
    };
};

export const Response200IO = t.interface({
    data: t.array(t.interface({
        adjustmentType: t.union([t.null, t.undefined, t.string]),
        balanceAfter: t.union([t.null, t.undefined, t.string]),
        balanceDelta: t.string,
        cashout: t.union([t.null, t.undefined, t.boolean]),
        currency: t.string,
        date: t.string,
        displayId: t.number,
        displayedReason: t.string,
        fundsLocked: t.string,
        id: t.string,
        status: t.string,
        type: t.string,
    })),
    metadata: t.interface({
        page: t.number,
        perPage: t.number,
        total: t.number,
        totalPages: t.number,
    }),
});
export type Response200Type = {
    data: Array<{
        adjustmentType?: null | undefined | string;
        balanceAfter?: null | undefined | string;
        balanceDelta: string;
        cashout?: null | undefined | boolean;
        currency: string;
        date: string;
        displayId: number;
        displayedReason: string;
        fundsLocked: string;
        id: string;
        status: string;
        type: string;
    }>;
    metadata: {
        page: number;
        perPage: number;
        total: number;
        totalPages: number;
    };
};
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiProxyCustomerTransactionHistoryRequest"
 */
export const openapi_proxy_customer_transaction_history = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/customer/transaction-history${query}`;
    
    const method = 'POST';
    const paramsFetch = {
        url,
        body: params.requestBody,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiProxyCustomerTransactionHistoryParamsType = ParamsType;

export type OpenapiProxyCustomerTransactionHistoryResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiProxyCustomerTransactionHistoryResponse200Type = Response200Type;

export const openapiProxyCustomerTransactionHistoryRequest = async (api_url: string, api_timeout: number, backendToken: string, params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiProxyCustomerTransactionHistoryResponseType> => {
    const response = await openapi_proxy_customer_transaction_history(api_url, api_timeout, backendToken, params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiProxyCustomerTransactionHistoryRequest - unhandled response ${response.status}`);
};

