import { AccountBasicDataType } from 'src/domains/players/state/UsersState';
import { DateTime } from 'src_common/utils/time/time';
import { z } from 'zod';

const isParsedError = (value: string | number | undefined | null): boolean => {
    if (value === undefined || value === null || value.toString().trim() === '') {
        return true;
    }

    return false;
};

export interface PaysafeBillingInfoError {
    type: 'error';
    fieldsRequired: string[];
}

export interface PaysafeBillingInfoSuccess {
    type: 'ok';
    data: {
        merchantCustomerId: number;
        currency: string;
        customer: {
            locale: string;
            firstName: string;
            lastName: string;
            dateOfBirth: {
                year: number;
                month: number;
                day: number;
            };
            email: string;
            phone: string;
        };
        billingAddress: {
            nickName: string;
            street: string;
            street2?: string | null;
            city: string;
            zip: string;
            country: string;
            state: string;
        };
    };
}

const paysafeBillingInfoFields = z.object({
    merchantCustomerId: z.number(),
    currency: z.string(),
    customer: z.object({
        firstName: z.string(),
        lastName: z.string(),
        dateOfBirth: z.object({
            year: z.number(),
            month: z.number(),
            day: z.number(),
        }),
        email: z.string().email(),
        phone: z.string(),
    }),
    billingAddress: z.object({
        street: z.string(),
        street2: z.string().optional().nullable(),
        city: z.string(),
        zip: z.string(),
        country: z.string(),
        state: z.string(),
    }),
});

export type PaysafeValidateBillingInfoFields = z.infer<typeof paysafeBillingInfoFields>;

export const isValidBillingIfoFields = (value: unknown): value is PaysafeValidateBillingInfoFields =>
    paysafeBillingInfoFields.safeParse(value).success;

export const paysafeBillingInfoFieldsValidation = (
    basicData: Omit<AccountBasicDataType, 'oddsFormat'> | null
): PaysafeBillingInfoError | PaysafeBillingInfoSuccess | undefined => {
    if (basicData !== null) {
        const dataToValid = {
            merchantCustomerId: basicData.id,
            currency: basicData.currency,
            customer: {
                email: basicData.email,
                firstName: basicData.firstName,
                lastName: basicData.surname,
                phone: basicData.mobilePhone?.number ?? null,
                dateOfBirth: {
                    day: DateTime.from(basicData.birthDate)?.getDayOfMonth(),
                    month: DateTime.from(basicData.birthDate)?.getMonth(),
                    year: DateTime.from(basicData.birthDate)?.getYear(),
                },
            },
            billingAddress: {
                street: basicData.addressLine1,
                street2: basicData.addressLine2,
                city: basicData.city,
                zip: basicData.postCode,
                country: basicData.country,
                state: basicData.county ?? basicData.country,
            },
        };

        if (isValidBillingIfoFields(dataToValid)) {
            return {
                type: 'ok',
                data: {
                    merchantCustomerId: dataToValid.merchantCustomerId,
                    currency: dataToValid.currency,
                    customer: {
                        locale: 'en_GB',
                        email: dataToValid.customer.email,
                        firstName: dataToValid.customer.firstName,
                        lastName: dataToValid.customer.lastName,
                        phone: dataToValid.customer.phone,
                        dateOfBirth: {
                            day: dataToValid.customer.dateOfBirth.day,
                            month: dataToValid.customer.dateOfBirth.month + 1,
                            year: dataToValid.customer.dateOfBirth.year,
                        },
                    },
                    billingAddress: {
                        street: dataToValid.billingAddress.street,
                        street2: dataToValid?.billingAddress?.street2 ?? null,
                        city: dataToValid.billingAddress.city,
                        country: dataToValid.billingAddress.country,
                        zip: dataToValid.billingAddress.zip,
                        nickName: `${dataToValid.customer.firstName} ${dataToValid.customer.lastName}`,
                        state: dataToValid.billingAddress.state,
                    },
                },
            };
        }

        const fieldsRequired: Array<string> = [];

        if (isParsedError(dataToValid.currency)) {
            fieldsRequired.push('currency');
        }

        if (isParsedError(dataToValid.customer.email)) {
            fieldsRequired.push('email');
        }

        if (isParsedError(dataToValid.customer.firstName)) {
            fieldsRequired.push('firstName');
        }

        if (isParsedError(dataToValid.customer.lastName)) {
            fieldsRequired.push('lastName');
        }

        if (isParsedError(dataToValid.merchantCustomerId)) {
            fieldsRequired.push('merchantCustomerId');
        }

        if (isParsedError(dataToValid.customer.phone)) {
            fieldsRequired.push('phone');
        }

        if (isParsedError(dataToValid.billingAddress.city)) {
            fieldsRequired.push('city');
        }

        if (isParsedError(dataToValid.billingAddress.country)) {
            fieldsRequired.push('country');
        }

        if (isParsedError(dataToValid.billingAddress.street)) {
            fieldsRequired.push('street');
        }

        if (isParsedError(dataToValid.billingAddress.zip)) {
            fieldsRequired.push('zip');
        }

        if (isParsedError(dataToValid.billingAddress.state)) {
            fieldsRequired.push('state');
        }

        return {
            type: 'error',
            fieldsRequired,
        };
    }
    return undefined;
};
