import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import {
    TransactionHelpIconWrapper,
    TransactionProfit,
    TransactionBalance,
    TransactionId,
} from 'src/domains/players/webview/components/Account/transactionsList/Transaction.style';
import {
    BetsListBet,
    TransactionListDay,
    StatusStyle,
    NameStyle,
    TransactionHistoryItemWrapper,
    TransactionHistoryItem,
} from './TransactionsList.style';
import { TransactionsForViewTypes } from 'src/domains/players/webview/components/Account/transactionHistoryTab/types';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import { DateTime } from 'src_common/utils/time/time';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface TransactionPropsTypes {
    transaction: TransactionsForViewTypes;
}

export const Transaction = observer('Transaction', (props: TransactionPropsTypes) => {
    const { transaction } = props;

    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;

    const profit =
        new Amount(transaction.balanceDelta).isEqualWith(new Amount('0')) === true
            ? new Amount(transaction.fundsLocked)
            : new Amount(transaction.balanceDelta);
    const displayedReason = transaction.displayedReason;
    const cashOut = transaction.cashout;
    const type = transaction.type;
    const adjustmentType = transaction.adjustmentType ?? '';

    const transactionName = (): JSX.Element | string => {
        if (cashOut === true && type !== 'Bet placement')
            return (
                <I18n
                    langKey='account.transaction.bet-cashed-out.label'
                    defaultText='Bet Cashed Out'
                />
            );

        if (adjustmentType === 'casino-bonus')
            return (
                <I18n
                    langKey='account.transaction.bet-casino-bonus.label'
                    defaultText='Casino Bonus'
                />
            );

        return type;
    };

    const getBalanceAfter = (): string => {
        if (transaction.balanceAfter === null) return '-';

        if (transaction.balanceAfter === undefined) return appPlayersState.usersState.money(transaction.balanceAfter);

        const newBalanceAfter = configComponents.precision.newFromAnything(transaction.balanceAfter);

        return appPlayersState.usersState.money(newBalanceAfter);
    };

    return (
        <BetsListBet className='bets-list__bet'>
            <TransactionHistoryItemWrapper>
                <TransactionHistoryItem className='bets-list__bet__content'>
                    <NameStyle data-test='transaction-name'>
                        {transactionName()}
                        {transaction.status === 'pending' ? <StatusStyle status='pending'>Pending</StatusStyle> : null}
                        {transaction.status === 'cancelled' ? (
                            <StatusStyle status='pending'>Cancelled</StatusStyle>
                        ) : null}
                        {transaction.status === 'internal-error' ||
                        transaction.status === 'rejected' ||
                        transaction.status === 'failed' ? (
                            <StatusStyle status='fail'>Fail</StatusStyle>
                        ) : null}
                        {displayedReason === '' ? null : (
                            <span title={displayedReason}>
                                <TransactionHelpIconWrapper />
                            </span>
                        )}
                    </NameStyle>
                    <TransactionListDay
                        className='bets-list__day'
                        data-test='transaction-date'
                    >
                        {DateTime.from(transaction.date)?.format('DD MMM YYYY | HH:mm')}
                    </TransactionListDay>
                    <TransactionId data-test='transaction-id'>
                        #{/* TODO - restor ---> transaction.displayId */ transaction.id}
                    </TransactionId>
                </TransactionHistoryItem>
                <TransactionProfit
                    isNegative={profit.isLessThanZero()}
                    data-test='transaction-profit'
                >
                    {appPlayersState.usersState.money(profit)}
                </TransactionProfit>
                <TransactionBalance data-test='transaction-balance'>{getBalanceAfter()}</TransactionBalance>
            </TransactionHistoryItemWrapper>
        </BetsListBet>
    );
});
