import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { MarketingQuestionsState } from 'src/domains/players/state/MarketingQuestionsState';
import { MarketingNotificationsState } from 'src/domains/players/state/MarketingNotificationsState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { VerifyAccountState } from 'src/domains/players/state/VerifyAccountState';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { TraderChatState } from './traderChat/TraderChatState';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { ConfirmationBoxState } from 'src/domains/players/state/ConfirmationBoxState';
import { MessageBoxState } from 'src/domains/players/state/MessageBoxState';
import { SignupPopupState } from 'src/domains/players/state/SignupPopupState';
import { Session } from 'src_common/sdk/session';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { StarLoginState } from 'src/domains/players/state/starLoginState/StarLoginState';
import { SignUpState, SignupTertiaryState, SignupTypes } from 'src/domains/players/webview/components/SignUp';
import { SpecialEvents } from 'src/domains/players/state/specialEventsState/SpecialEvents';
import { StreamViewDetails } from 'src/domains/sportsbook/shared/Types';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { autorun } from 'mobx';
import { UserAllPopupsTypes } from 'src/domains/layouts/state/popupState/PopupState';
import { CmsDrawerPagesState } from 'src/domains/players/state/CmsDrawerPagesState';
import { EnvVariables } from 'src/domains/common/contextStore/EnvVariables';
import { TrpcClient } from 'src/appState/TrpcClient';
import { GeolocalizationState } from 'src/domains/layouts/state/geolocalizationState/GeolocalizationState';
import { createAccountPrimary } from 'src_server/trpc/types';
import { ExternalApi } from 'src/domains/layouts/state/externalApi/ExternalApi';

const AccountStatusEnum = createAccountPrimary.AccountStatusEnum;

interface PlayersCallbacksType {
    isShowQuickBet: () => boolean;
    isMobileAppLogout: () => boolean;
    largeDesktopIsBiggerOrEq: () => boolean | null;
    whenUserLogin: () => void;
    streamViewDetails: () => StreamViewDetails;
    getIsBrowser: () => boolean;
    shopPopup: (popup: UserAllPopupsTypes) => void;
}

export class AppPlayersState {
    public readonly withdrawalsListState: WithdrawalsListState;
    public readonly marketingQuestionsState: MarketingQuestionsState;
    public readonly marketingNotificationsState: MarketingNotificationsState;
    public readonly verifyAccountState: VerifyAccountState;
    public readonly traderChatState: TraderChatState;
    public readonly customKeyboard: CustomKeyboardState;
    public readonly confirmationBox: ConfirmationBoxState;
    public readonly messageBox: MessageBoxState;
    public readonly signupPopupState: SignupPopupState;
    public readonly accountState: AccountState;
    public readonly loginState: StarLoginState;
    public readonly signUpState: SignupTypes;
    public readonly specialEvents: SpecialEvents;
    public readonly usersState: UsersState;
    public readonly cmsDrawerPagesState: CmsDrawerPagesState;
    public readonly trpc: TrpcClient;
    public readonly streamViewDetails: () => StreamViewDetails;

    public constructor(
        localStorageState: LocalStorageState,
        websocketV1: WebsocketV1,
        private readonly session: Session,
        feature: FeatureState,
        googleTagManager: GoogleTagManagerState,
        starRouter: StarRouter,
        config: ConfigComponents,
        language: LanguagesState,
        private readonly externalApi: ExternalApi,
        callbacks: PlayersCallbacksType,
        env: EnvVariables,
        trpc: TrpcClient,
        geolocalization: GeolocalizationState
    ) {
        this.usersState = new UsersState(session, config.config, this.externalApi, localStorageState, {
            socketJoinSport: true,
            oddsFormatShortDefault: config.config.oddsFormatShortDefault,
            currencyDefault: geolocalization.currencyCode ?? config.config.currencyDefault,
            canDownloadTerms: config.config.termsAndConditionsDownloadLink,
        });

        this.trpc = trpc;

        this.withdrawalsListState = new WithdrawalsListState();
        this.accountState = new AccountState(
            session,
            this.usersState,
            feature,
            googleTagManager,
            starRouter,
            this.withdrawalsListState,
            this.externalApi,
            this.trpc,
            config.precision,
            callbacks.isMobileAppLogout
        );

        this.marketingQuestionsState = new MarketingQuestionsState(trpc, this.usersState);
        this.marketingNotificationsState = new MarketingNotificationsState(
            this.marketingQuestionsState,
            localStorageState
        );
        this.verifyAccountState = new VerifyAccountState();
        this.traderChatState = new TraderChatState(websocketV1, localStorageState, this.usersState, this.trpc, config);

        this.customKeyboard = new CustomKeyboardState();
        this.confirmationBox = new ConfirmationBoxState();
        this.messageBox = new MessageBoxState(starRouter);
        this.signupPopupState = new SignupPopupState();

        this.loginState = new StarLoginState(this.verifyAccountState, starRouter, this.accountState, config, language, {
            whenUserLogin: callbacks.whenUserLogin,
        });

        if (config.config.signupType === 'tertiary') {
            this.signUpState = {
                type: 'SignupTertiary',
                state: new SignupTertiaryState({
                    customKeyboard: this.customKeyboard,
                    accountState: this.accountState,
                    language: language,
                    config: config,
                    signupPopupState: this.signupPopupState,
                    router: starRouter,
                    googleTagManager: googleTagManager,
                    trpc,
                    localStorageState,
                }),
            };
        } else {
            this.signUpState = {
                type: 'SignupPrimary',
                state: new SignUpState({
                    customKeyboard: this.customKeyboard,
                    accountState: this.accountState,
                    externalApi: this.externalApi,
                    language: language,
                    config: config,
                    signupPopupState: this.signupPopupState,
                    router: starRouter,
                    googleTagManager: googleTagManager,
                    trpc,
                    env,
                    geolocalization,
                    localStorageState,
                }),
            };
        }

        this.specialEvents = new SpecialEvents(
            starRouter,
            session,
            localStorageState,
            this.signUpState,
            this.loginState,
            this.accountState,
            googleTagManager,
            trpc,
            {
                largeDesktopIsBiggerOrEq: callbacks.largeDesktopIsBiggerOrEq,
            }
        );

        this.streamViewDetails = callbacks.streamViewDetails;

        this.cmsDrawerPagesState = new CmsDrawerPagesState(this.usersState, this.customKeyboard);

        starRouter.onChangeAccountView((_prevAccountView, nextAccountView) => {
            if (nextAccountView?.account === 'top-up' || nextAccountView?.account === 'limits') {
                this.usersState.depositLimitsData.refresh();
            }
        });

        if (callbacks.getIsBrowser()) {
            this.runOnStart();
        }
        this.detectBasicData(config.config.detectLoginInMobileApp);
    }

    private runOnStart = (): void => {
        autorun(async () => {
            const accountStatus = this.accountState.account?.basicDataReady?.status;

            if (accountStatus === AccountStatusEnum.SUSPENDED) {
                await this.accountState.handleLogout();
            }
        });

        let prevUser: number | null = null;
        autorun(() => {
            const userId = this.session.userId;

            if (prevUser !== null && userId === null) {
                this.messageBox.runActionNotLogged();
            }

            prevUser = userId;
        });
    };

    private detectBasicData = (detectLoginInMobileApp: boolean): void => {
        if (detectLoginInMobileApp === false) {
            return;
        }

        autorun((dispose) => {
            const id = this.usersState.basicDataComp?.id ?? null;
            const pushNotificationsEnabled = this.usersState.basicDataComp?.pushNotificationsEnabled;
            const isDataValid = id !== null;
            if (isDataValid) {
                this.accountState.sendInfoAboutFirstLoginToMobileApp(id, pushNotificationsEnabled);
                dispose.dispose();
            }
        });
    };
}
