import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { RacingPostIndicator } from 'src/domains/sportsbook/webview/components/raceCard/tabs/Tabs.style';
import { DateTime } from 'src_common/utils/time/time';

const extractRaceStartTime = (race: EventModel): DateTime | null => {
    return DateTime.from(race.timeSettingsStartTime);
};

const getSliceForName = (sliceNameRaceLabel: boolean, name: string): string => {
    if (!sliceNameRaceLabel) {
        return name;
    }

    return name.slice(0,3);
};

interface RenderLabelPropsType {
    race: EventModel;
    displayCompetition: boolean;
    isFinishedRace?: boolean;
}

export const RaceLabel = observer('RaceLabel', (props: RenderLabelPropsType) => {
    const { race, displayCompetition, isFinishedRace } = props;

    const { appLayoutsState } = useAppStateContext();
    const { config } = appLayoutsState.configComponents;
    const { sliceNameRaceLabel } = config;

    //we display competition in label only if collection is part of pseudo filters , next-off or race-meetings
    const competition = race.competition2.getModel();

    const startTime = extractRaceStartTime(race);
    const getShortName = competition === null ? null : getSliceForName(sliceNameRaceLabel, competition.name);

    return (
        <>
            {isFinishedRace === true && <RacingPostIndicator />}
            {startTime?.format('HH:mm')}{' '}
            {displayCompetition && getShortName}
        </>
    );
});
