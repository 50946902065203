import styled from '@emotion/styled';
import { RaceSummaryCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { withConfig } from 'src/withConfig';

interface MatchBetTitlePropsType {
    withIcon?: boolean;
    isToggable?: boolean;
}

export const MarketsTitle = withConfig(theme => styled('div', { label: 'MarketsTitle' })<MatchBetTitlePropsType>`
    background: ${theme.star.headings.market.bgColor};
    color: ${theme.star.headings.market.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    padding: ${({ withIcon }): string => withIcon === true ? '6px 0' : '6px 8px'};
    cursor: ${({ withIcon }): string => withIcon === true ? 'pointer' : 'inherit'};
    display: flex;
    justify-content: space-between;
    align-items: center;
`);

export const EWInformation = withConfig(theme => styled('span', { label: 'EWInformation' })`
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.4;
    text-align: right;
    min-width: 70px;
`);

export const SelectionList = withConfig(theme => styled('ul', { label: 'SelectionList' })`
    border: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const MatchBetSelection = withConfig(theme => styled('li', { label: 'MatchBetSelection' })`
    display: flex;
    flex-flow: row nowrap;
    height: 60px;
    justify-content: space-between;
    position: relative;
    &:not(:last-of-type) {
        border-bottom: 1px solid  ${theme.star.raceSummary.borderColor};
    }
`);

export const RaceCaption = styled(RaceSummaryCaption, { label: 'RaceCaption' })`
    height: 32px;
`;
