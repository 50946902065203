import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

import { FooterList } from './AboutPages.style';
import { FooterHeader } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent.style';
import { AboutPagesItems } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/aboutPages/AboutPagesItems';

export const AboutPages = observer('AboutPages', () => (
    <>
        <FooterHeader>
            <I18n langKey='footer.useful-links.header' defaultText='Useful Links' />
        </FooterHeader>
        <FooterList>
            <AboutPagesItems />
        </FooterList>
    </>
));
