import { action, computed, observable, makeObservable } from 'mobx';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    decodeCustomSelections,
    CustomSelectionsType,
    CustomSelectionType,
    MarketFilterType,
    isArray,
    isMarketFilter,
    StarValueType,
} from './commonCmsGuard';
import { AlternativeCustomSelectionState } from './AlternativeCustomSelectionState';
import { Value } from 'src_common/common/mobx-utils/Value';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { EventId, MarketId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { TypeId } from 'src_common/common/websocket2/type';
import { sortByNumberField } from 'src_common/utils/sport/sort';
import { DateTime } from 'src_common/utils/time/time';
import { AlternativeEventDetailsModelType } from './AlternativeEventState';
import { AutoMapSerialized } from 'src_common/common/mobx-utils/AutoMap';

interface CallbacksType {
    onRedirectToPromoTermsAndConditions: () => void;
    getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string;
}

export interface ImagesType {
    mobile: string;
    tablet: string;
    desktop: string;
}

export interface ImagesHeightType {
    mobile: string | null;
    tablet: string | null;
    desktop: string | null;
}
interface MarketType {
    id: MarketId;
    displayOrder: number;
}

const getCurrentTime = (): number => {
    return new Date().getTime();
};

const currentTime = new Value(getCurrentTime(), (setValue) => {
    const timer = setInterval((): void => {
        setValue(getCurrentTime());
    }, 1000);

    return (): void => {
        clearInterval(timer);
    };
});

export class AlternativeEventDetailsItemState {
    @observable public isStreamActive: boolean = false;
    @observable public isOpenMarket: boolean = false;
    @observable public activeMarketFilterInner: string = 'all';

    private readonly customSelectionMap: AutoMapSerialized<[MarketId, SelectionId], AlternativeCustomSelectionState>;

    public constructor(
        private readonly configComponents: ConfigComponents,
        private readonly models: ModelsState,
        private readonly getAlternativeEventRawById: (eventId: EventId) => AlternativeEventDetailsModelType | null,
        private readonly eventId: EventId,
        private readonly callbacks: CallbacksType
    ) {
        makeObservable(this);

        this.customSelectionMap = new AutoMapSerialized(
            ([marketId, selectionId]: [MarketId, SelectionId]) => `${marketId.rawId}-${selectionId.rawId}`,
            ([marketId, selectionId]: [MarketId, SelectionId]) => {
                return new AlternativeCustomSelectionState(
                    marketId,
                    selectionId,
                    this.getCustomSelectionByMarketAndSelectionId
                );
            }
        );
    }

    public getCustomSelectionByMarketAndSelectionId = (
        marketId: MarketId,
        selectionId: SelectionId
    ): CustomSelectionType | null => {
        const market = this.customSelections.find((market) => market.market_id === marketId) ?? null;
        if (market !== null) {
            return market.selections.find((selection) => marketId.getSelectionId(selection.id) === selectionId) ?? null;
        }
        return null;
    };

    public getCustomSelectionItemByMarketAndSelectionId = (
        selectionId: SelectionId
    ): AlternativeCustomSelectionState => {
        const marketId = selectionId.getMarketId();
        return this.customSelectionMap.get([marketId, selectionId]);
    };

    @computed public get activeMarketFilter(): string {
        const activeFilter =
            this.marketFiltersForView.find((elem) => elem.key === this.activeMarketFilterInner) ?? null;
        if (activeFilter === null) {
            return 'all';
        }

        return this.activeMarketFilterInner;
    }

    @computed private get alternativeEventRaw(): AlternativeEventDetailsModelType | null {
        return this.getAlternativeEventRawById(this.eventId);
    }

    @computed private get eventModel(): EventModel | null {
        return this.eventId.getEventModel();
    }

    @computed public get streamUrl(): string {
        return this.alternativeEventRaw?.stream_url ?? '';
    }

    @computed public get description(): string {
        if (this.activeMarketFilter !== 'all' && this.marketFilters.length > 0) {
            const marketFilter = this.getActiveMarketFilter();
            const description = marketFilter?.description ?? '';

            return description;
        }
        return this.alternativeEventRaw?.description ?? '';
    }

    @computed public get starRationParticipantsFromMarketFilter(): Array<StarValueType> {
        if (this.activeMarketFilter !== 'all' && this.marketFilters.length > 0) {
            const marketFilter = this.getActiveMarketFilter();
            const starValues = marketFilter?.starValues ?? [];
            return starValues.filter((elem) => elem.value > 0 && elem.name.length > 0);
        }
        return [];
    }

    @computed public get overviewText(): string {
        return this.alternativeEventRaw?.over_view_text ?? '';
    }

    @computed public get isActiveStream(): boolean {
        return this.alternativeEventRaw?.is_active_stream ?? false;
    }

    @computed public get isActivePromo(): boolean {
        return this.alternativeEventRaw?.is_active_promo ?? false;
    }

    @computed public get isStreamUrlAvailable(): boolean {
        return this.streamUrl !== '' && this.isActiveStream;
    }

    @computed public get isShowHeaderWithoutStream(): boolean {
        const { mobile, tablet, desktop } = this.bgHeaderImages;
        return mobile !== '' || tablet !== '' || desktop !== '';
    }

    @computed public get bgHeaderImages(): ImagesType {
        return {
            mobile: this.alternativeEventRaw?.header_img_small ?? '',
            tablet: this.alternativeEventRaw?.header_img_medium ?? '',
            desktop: this.alternativeEventRaw?.header_img_big ?? '',
        };
    }

    @computed public get bgHeaderHeightImages(): ImagesHeightType {
        return {
            mobile: this.alternativeEventRaw?.header_img_height_small ?? null,
            tablet: this.alternativeEventRaw?.header_img_height_medium ?? null,
            desktop: this.alternativeEventRaw?.header_img_height_big ?? null,
        };
    }

    @computed public get bgHeaderUnderStreamImages(): ImagesType {
        return {
            mobile: this.alternativeEventRaw?.header_img_under_stream_small ?? '',
            tablet: this.alternativeEventRaw?.header_img_under_stream_medium ?? '',
            desktop: this.alternativeEventRaw?.header_img_under_stream_big ?? '',
        };
    }

    @computed public get bgPromoImages(): ImagesType {
        return {
            mobile: this.alternativeEventRaw?.promo_img_small ?? '',
            tablet: this.alternativeEventRaw?.promo_img_medium ?? '',
            desktop: this.alternativeEventRaw?.promo_img_big ?? '',
        };
    }

    @computed public get isTimeToShowStream(): boolean {
        if (this.configComponents.config.isStreamControlledByTime === false) {
            return true;
        }

        if (this.timeToStartEvent !== null) {
            return this.timeToStartEvent < 30;
        }
        return false;
    }

    @computed private get timeToStartEvent(): number | null {
        const curTime = currentTime.getValue();

        if (this.startTime !== null && curTime >= 0) {
            const currentTime = DateTime.from(curTime);
            const nextRaceTime = DateTime.from(this.startTime);

            if (currentTime === null) {
                return null;
            }

            return nextRaceTime?.diffInMinutes(currentTime) ?? null;
        }

        return null;
    }

    @computed private get startTime(): number | null {
        if (this.eventModel !== null) {
            const startTime = new Date(this.eventModel.timeSettingsStartTime);
            return startTime.getTime();
        }
        return null;
    }

    @computed public get eventName(): string {
        if (this.alternativeEventRaw !== null && this.alternativeEventRaw.name !== '') {
            return this.alternativeEventRaw.name ?? '';
        }
        if (this.eventModel !== null) {
            return this.eventModel.name;
        }
        return '';
    }

    @computed public get horseRacingHasEnded(): boolean {
        if (this.eventModel === null) {
            return false;
        }
        const timeline = this.eventModel.timeSettingsTimeline;
        const state = this.eventModel.state;
        if (this.eventModel.eventMarkets?.length === 0) {
            return true;
        }

        if (timeline === 'finished') {
            if (state === 'resulted' || state === 'settled') {
                return true;
            }
            return false;
        }
        return false;
    }

    @computed public get inernationalRacingHasEnded(): boolean {
        if (this.eventModel === null) {
            return true;
        }
        const active = this.eventModel.active;
        const display = this.eventModel.display;

        if (this.eventModel.eventMarkets?.length === 0) {
            return true;
        }

        if (active === false || display === false) {
            return true;
        }
        return false;
    }

    @computed public get eventHasEnded(): boolean {
        if (this.eventModel === null) {
            return false;
        }
        const sportName = this.eventModel.sportOriginal;
        return sportName === 'internationalhorseracing' ? this.inernationalRacingHasEnded : this.horseRacingHasEnded;
    }

    @computed public get streamStartTimeFormat(): string | null {
        return this.startTime === null
            ? null
            : DateTime.from(this.startTime)?.subtractMinutes(30)?.format('hh:mmA') ?? null;
    }

    @computed public get customSelections(): Array<CustomSelectionsType> {
        if (this.alternativeEventRaw !== null) {
            const custom_selections = decodeCustomSelections(this.eventId, this.alternativeEventRaw.custom_selections);
            return custom_selections;
        }

        return [];
    }

    @computed public get customSelectionsForEventDetails(): Array<SelectionId> {
        const selectionsForEventDetails = [];

        for (const customSelection of this.customSelections) {
            for (const selection of customSelection.selections) {
                if (selection.star_value > 0) {
                    selectionsForEventDetails.push(customSelection.market_id.getSelectionId(selection.id));
                }
            }
        }

        return selectionsForEventDetails;
    }

    @computed private get allMarketsIds(): Array<MarketId> {
        return this.eventModel?.marketsIds2 ?? [];
    }

    @computed public get isShowOverview(): boolean {
        return this.customSelectionsForEventDetails.length > 0 || this.description !== '';
    }

    @action public onPlayStream = (): void => {
        this.isStreamActive = true;
    };

    @action public onCloseStream = (): void => {
        this.isStreamActive = false;
    };

    @action public handleToggleMarket = (): void => {
        this.isOpenMarket = !this.isOpenMarket;
    };

    @computed public get marketFiltersDecode(): Array<MarketFilterType> {
        return this.decodeMarketFilters(this.alternativeEventRaw?.market_filters ?? []);
    }

    @computed public get marketFilters(): Array<MarketFilterType> {
        const temp: Array<MarketFilterType> = [];
        for (const marketFilter of this.marketFiltersDecode) {
            const isAnyMarket = marketFilter.markets.some((elem) => {
                const marketModel = elem.id.getModel();
                if (marketModel === null) {
                    return false;
                }

                if (marketModel.eventModel === null) {
                    return false;
                }

                if (marketModel.eventModel.display === false) {
                    return false;
                }

                return marketModel.display;
            });

            if (isAnyMarket) {
                temp.push(marketFilter);
            }
        }

        return temp;
    }

    private getActiveMarketFilter = (): MarketFilterType | null => {
        return this.marketFilters.find((filter) => filter.slug === this.activeMarketFilter) ?? null;
    };

    @computed private get filteredMarkets(): Array<MarketId> {
        if (this.activeMarketFilter === 'all') {
            return this.allMarketsIds;
        }
        const marketFilter = this.getActiveMarketFilter();
        if (marketFilter !== null) {
            const sort = (v1: MarketType, v2: MarketType): number =>
                sortByNumberField(v1.displayOrder, v2.displayOrder);

            return marketFilter.markets.sort(sort).map((elem) => elem.id);
        }

        return [];
    }

    @computed public get marketsForViewIds(): Array<MarketId> {
        if (this.marketFiltersForView.length > 0) {
            return this.filteredMarkets;
        }
        return this.allMarketsIds;
    }

    @computed public get marketFiltersForView(): Array<{ id: string; key: string; label: string }> {
        const filters = this.handleParseAndOrderMarketFilters(this.marketFilters);
        if (filters.length > 0) {
            const defaultFilter = {
                id: 'all',
                key: 'all',
                label: this.callbacks.getTranslation(
                    'alternative-event.market-filter.all-markets.label',
                    'All Markets'
                ),
            };

            return [defaultFilter, ...filters];
        }

        return [];
    }

    private decodeMarketFilters = (marketFiltersRaw: unknown): Array<MarketFilterType> => {
        const marketFilters: Array<MarketFilterType> = [];

        if (isArray(marketFiltersRaw)) {
            for (const marketFilter of marketFiltersRaw) {
                if (isMarketFilter(marketFilter)) {
                    marketFilters.push({
                        ...marketFilter,
                        markets: marketFilter.markets.map((item) => {
                            //@ts-expect-error
                            const marketTypeIdOld: TypeId.MarketId = item.id;

                            // todo back to old representation for eventId and mArketId on CMS (as string)
                            const eventIdNumber = TypeId.getEventId(marketTypeIdOld);
                            const marketIdNumber = TypeId.getMarketId(marketTypeIdOld);

                            const marketId = this.models.id.getMarketId(eventIdNumber, marketIdNumber);

                            return {
                                ...item,
                                id: marketId,
                            };
                        }),
                    });
                }
            }
        }

        return marketFilters;
    };

    private handleParseAndOrderMarketFilters = (
        marketFilters: Array<MarketFilterType>
    ): Array<{ id: string; key: string; label: string }> => {
        return marketFilters
            .filter((elem) => elem.markets.length > 0)
            .sort((v1: MarketFilterType, v2: MarketFilterType) => sortByNumberField(v1.displayOrder, v2.displayOrder))
            .map((elem) => ({
                id: elem.slug,
                key: elem.slug,
                label: elem.label,
            }));
    };

    @action public setActiveFilter = (filter: string): void => {
        this.activeMarketFilterInner = filter;
    };

    @action public showTermsAndConditions = (): void => {
        this.callbacks.onRedirectToPromoTermsAndConditions();
    };
}
