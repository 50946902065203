import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface FullScreenInIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const FullScreenInIcon = observer('FullScreenInIcon', ({ className, onClick }: FullScreenInIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M3.75 23l4.03-4.168 2.827 3.34v-8.815H1.825l3.341 2.861L1 20.25 3.75 23zm18.425-12.357L18.834 7.78 23 3.75 20.25 1l-4.03 4.168-2.827-3.34v8.815h8.782z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
