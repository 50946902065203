import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



interface WrapperPropsType {

    margin?: string;

}



export const ErrorWrapper = withConfig(theme => styled('div', { label: 'ErrorWrapper' })<WrapperPropsType>`

    background-color: ${theme.star.statuses.error.bgColor};

    border-radius: 0;

    color: ${theme.star.statuses.error.txtColor};

    display: flex;

    font-weight: ${theme.star.fontWeight.regular};

    margin: 0 8px 8px;

    padding: 4px 8px;

    text-align: left;

`);

