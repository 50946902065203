import { autorun, makeAutoObservable } from 'mobx';
import { init as initFullStory, FullStory } from '@fullstory/browser';
import { Common } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class FullStoryState {
    public static get = AutoWeakMap.create((common: Common) => new FullStoryState(common));

    private isFillStoryInit: boolean = false;

    private constructor(private readonly common: Common) {
        makeAutoObservable(this);
    }

    public runOnStart = (): void => {
        const configComponents = ConfigComponents.get(this.common);

        if (this.common.isBrowser === false) {
            return;
        }

        if (configComponents.config.fullstory === null) {
            return;
        }

        if (this.isFillStoryInit) {
            return;
        }

        this.isFillStoryInit = true;

        initFullStory({ orgId: configComponents.config.fullstory });
        autorun(() => {
            const { userId } = this.common.session;

            if (userId !== null) {
                FullStory('setIdentity', {
                    uid: userId.toString(),
                });
            }
        });
    };
}
