import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    LazyImageWrapper,
    LazyImageWrapperNew,
    OutrightFill,
    RaceBoxSilk,
    TrapWrapper,
} from './RaceBoxSelection.style';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    defaultSilk: string;
    selectionId: SelectionId;
}

export const RaceBoxSilkAndTrap = observer('RaceBoxSilkAndTrap', (props: PropsType) => {
    const { selectionId, defaultSilk } = props;

    const selection = selectionId.getModel();
    const market = selection?.marketId === undefined ? null : selection?.marketId2.getModel();

    if (selection === null) {
        return null;
    }

    if (market === null) {
        return null;
    }

    const silkUrl = selection.metaDataSilkUrl;

    const selectionModel = selectionId.getModel();
    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    const eventModel = marketModel.getEvent();
    if (eventModel === null) {
        return null;
    }

    const sport = eventModel.sport;

    if (market.isOutright === true) {
        return <OutrightFill />;
    }

    if (sport === 'greyhoundracing') {
        return (
            <RaceBoxSilk>
                <TrapWrapper number={selection.metaDataNumber} placeholder={defaultSilk} selectionId={selection.id2} />
            </RaceBoxSilk>
        );
    }

    return (
        <RaceBoxSilk>
            <LazyImageWrapperNew>
                <LazyImageWrapper
                    alt={selection.isMetaData ? selection.metaDataSilk : null}
                    placeholder={defaultSilk}
                    src={silkUrl}
                />
            </LazyImageWrapperNew>
        </RaceBoxSilk>
    );
});
