import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BetCombinedDetails } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetCombinedDetails';
import { BetSingleDetails } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetSingleDetails';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface PropsTypes {
    bet: BetHistoryType,
}

export const BetDetails = observer<PropsTypes>('BetDetails', ({ bet }) => {
    const isCombined = bet.legs.length > 1;

    if (isCombined === true) {
        return <BetCombinedDetails bet={bet} />;
    }

    return <BetSingleDetails bet={bet} />;
});
