import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { ChatMessageType } from 'src_server/trpc/types/chat';
import { chat } from 'src_server/trpc/types';

const decode = (data: unknown): ChatMessageType => {
    if (data !== null) {
        const validator = chat.MessageSchema.safeParse(data);

        if (validator.success) {
            return validator.data;
        }
    }

    throw new Error('unhandled decoding: lastReadMessage');
};

export const localStorageLastReadMessage = (
    withAutorun: boolean,
    key: string
): LocalStorageItemState<ChatMessageType> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: {
            id: '0',
            sender: {
                id: 0,
                type: 'system',
                name: '',
            },
            sentAt: new Date().toISOString(),
            tags: [],
            type: 'standard',
            content: {
                text: 'standard',
            },
            chatId: '0',
        },
    });
};
