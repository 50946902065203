import { UsersState } from 'src/domains/players/state/UsersState';
import { observable, action, makeObservable } from 'mobx';

export class VerifyWithEmailState {

    @observable public sentInfoView: boolean = false;

    public constructor(
        private email: string | null,
        private usersState: UsersState

    ) {
        makeObservable(this);
    }

    @action public verifyAccountWithEmail = ():void => {
        if (this.email === null) {
            throw new Error('Missing login to verify account.');
        }

        this.sentInfoView = true;

        this.usersState.requestResetPassword({ requestBody: {
            email: this.email,
            sendViaSMS: false,
            verify: true
        }

        }).catch((error) => {
            console.error(error);
        });
    }
};
