import React from 'react';
import { RaceSummaryRow } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { observer } from 'src/utils/mobx-react';
import { RaceSummary } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { MarketsTitle, SelectionList } from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummerRowMarkets.style';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    marketId: MarketId;
    channel?: React.ReactNode;
    ewTerms?: React.ReactNode | void;
    type?: string;
    selections: Array<SelectionModel>;
    matchBetTitle: string;
}

export const MatchBetsGreyhound = observer('MatchBetsGreyhound', (props: PropsType) => {
    const { marketId, type, matchBetTitle } = props;
    const marketModel = marketId.getModel();

    if (marketModel === null) {
        return null;
    }

    const raceModel = marketModel.getEvent();
    if (raceModel === null) {
        return null;
    }

    const displayOrder = raceModel?.tagsDisplayOrder;
    const order = displayOrder !== undefined && displayOrder !== '-' ? displayOrder : 'by-creation';
    const marketSelections = props.selections.filter(x => x.display);
    const itemsForView = sortSelectionsByCriteria(marketSelections, order);

    return (
        <RaceSummary>
            <MarketsTitle>{matchBetTitle}</MarketsTitle>
            <SelectionList className='race-summary__list'>
                {
                    itemsForView.map((selection: SelectionModel) => (
                        <RaceSummaryRow
                            key={selection.name}
                            selectionId={selection.id2}
                            type={type}
                            displayTrainer={type === 'cast' ? false : true}
                        />
                    ))
                }
            </SelectionList>
        </RaceSummary>
    );
});
