import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { CasinoCollection } from 'src/domains/casino/webview/CasinoGames';
import { VirtualsPageMainWrapper } from './VirtualsPage.style';
import { MainContent } from 'src/domains/layouts/webview/modules/layoutSkeleton/LayoutSkeleton.style';
import { VirtualsRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface VirtualsPagePropsType {
    appCasinoState: AppCasinoState;
    currentView: VirtualsRouteType;
    accountView: RightHandSideViewType | null;
    isSearchActive: boolean;
}

export const VirtualsPage = observer('VirtualsPage', (props: VirtualsPagePropsType) => {
    const { appCasinoState, currentView, isSearchActive, accountView } = props;

    return (
        <VirtualsPageMainWrapper>
            <LayoutSkeleton
                navigation={false}
                accountView={accountView}
                currentView={currentView}
                isSearchActive={isSearchActive}
                navigationType='global'
            >
                <MainContent>
                    <CasinoCollection
                        appCasinoState={appCasinoState}
                        collection='virtuals'
                        tabId='all_games'
                    />
                </MainContent>
            </LayoutSkeleton>
        </VirtualsPageMainWrapper>
    );
});
