import * as express from 'express';
import * as cookie from 'cookie';

import { ConfigServer } from 'src_server/ConfigServer/ConfigServer';
import { getTranslations } from '../Api/getTranslations';
import { LangDetailsMapType } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { getUniverseModule } from 'src/domains/common/universes';

const ALL_TRANSLATIONS_REFRESH_TIMEOUT = 5 * 1000; //5s

export const getAllTranslations = async (config: ConfigServer): Promise<Map<string, LangDetailsMapType>> => {
    const { THEME_UNIVERSE } = config.envParams;
    
    const universeModule = await getUniverseModule(THEME_UNIVERSE, config.envParams.MODE === 'prod');
    const languages = universeModule.features.languagesAvailable;
    const out: Map<string, LangDetailsMapType> = new Map();
    for (const lang of languages) {
        const langKey = lang.langCode;

        const translations = await getTranslations(config, langKey, languages);
        if (translations !== null) {
            out.set(langKey, translations);
        }
    }

    return out;
};

export const getAllTranslationsForApp = async (config: ConfigServer, langCode: string): Promise<LangDetailsMapType | null> => {
    const { API_UNIVERSE } = config.envParams;
    const universeModule = await getUniverseModule(API_UNIVERSE, config.envParams.MODE === 'prod');
    const languages = universeModule.features.languagesAvailable;
    const langKey = langCode;

    const translations = await getTranslations(config, langKey, languages, true);

    return translations;
};

type AllTranslationDataReturn = Map<string, LangDetailsMapType>;

interface AllTranslationReturn {
    data: AllTranslationDataReturn;
}

export const initTranslationData = async (languagesAvailable: string | null, config: ConfigServer): Promise<AllTranslationReturn> => {
    const result = {
        data: new Map()
    };

    if (languagesAvailable === null) {
        return result;
    }

    console.info('Init translations');
    try {
        const newData = await getAllTranslations(config);
        result.data = newData;
    } catch (err) {
        console.error(err);
    }

    setInterval(async () => {
        console.info('Refresh translations');
        result.data = await getAllTranslations(config);
    }, ALL_TRANSLATIONS_REFRESH_TIMEOUT);

    return result;
};

export const getUserSelectedLanguage = (defaultLanguage: string | null, req: express.Request): string | null => {
    const cookieValue = req.headers['cookie'];
    const cookieParsed = cookie.parse(cookieValue ?? '');
    return cookieParsed['selected-language'] ?? defaultLanguage;
};

export const getCookieFromRequest = (req: express.Request, cookieName: string): string | undefined => {
    const cookieValue = req.headers['cookie'];
    const cookieParsed = cookie.parse(cookieValue ?? '');
    return cookieParsed[cookieName];
};

export const getNewTradingCookie = (req: express.Request): boolean | null => {
    const cookieValue = req.headers['cookie'];
    const cookieParsed = cookie.parse(cookieValue ?? '');
    const value = cookieParsed['newtrading']?.toLowerCase();

    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    return null;
};
