import styled from '@emotion/styled';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { withConfig } from 'src/withConfig';

export const BetCommentWrapper = withConfig(theme => styled('div', { label: 'BetCommentWrapper' })`
    color: ${theme.star.betHistory.txtColorQuinary};
    display: inline;
    height: 12px;
    margin-left: 4px;
    position: relative;
`);

export const BetCommentIcon = withConfig(theme => styled(InfoIcon, { label: 'BetCommentIcon' })`
    width: 12px;
    cursor: pointer;
    fill: ${theme.star.betHistory.bgColorSecondary};
`);

export const BetCommentTooltipWrapper = withConfig(theme => styled('div', { label: 'BetCommentTooltipWrapper' })<{ verticalOffset: number }>`
    width: 315px;
    position: absolute;
    top: 100%;
    margin-top: 8px;
    left: ${({ verticalOffset }):string => `-${verticalOffset}px`};
    margin-left: 16px;
    ${({ verticalOffset }):string => `
        &:before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: -9px;
            left: calc(${verticalOffset}px - 18px);
            border-style: solid;
            border-width: 0px 8px 9px 8px;
            border-color: transparent transparent ${theme.star.betHistory.bgColor} transparent;
        }
    `}
`);

export const BetCommentTooltip = withConfig(theme => styled('p', { label: 'BetCommentTooltip' })`
    background-color: ${theme.star.betHistory.bgColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 0;
    padding: 6px;
    position: relative;
    text-align: center;
    width: 100%;
`);
