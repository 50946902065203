import React from 'react';
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { EventsCollectionList, FilterKindType } from 'src/domains/sportsbook/shared/Types';
import { EventsTable, EventsTableHeaderStyle } from 'src/domains/sportsbook/shared/Components';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';

interface OutrightsConfigType {
    sports: Array<string>;
    filters: Array<FilterKindType>;
}

const OUTRIGHTS_CONFIG: OutrightsConfigType = {
    sports: ['golf', 'motorsport', 'cycling', 'pool', 'tvspecials', 'bowls', 'athletics'],
    filters: ['time', 'region', 'country']
};

const isOutrightSport = (sport: string): boolean => OUTRIGHTS_CONFIG.sports.includes(sport);

const filtersSport: Array<FilterKindType> = ['competition', 'time', 'region', 'country'];
const filtersCollection: Array<FilterKindType> = ['time', 'region', 'country'];

interface TitleElementPropsType {
    collection: EventsCollectionList;
}

const TitleElement = observer('TitleElement', (props: TitleElementPropsType) => {
    const { collection } = props;
    const { total } = collection;
    return <I18n langKey='events.table.header.total' defaultText='{total} events' params={{ total }} />;
});

const getFilters = (filters: Array<FilterKindType>, sport: string): Array<FilterKindType> => {
    if (sport === 'olympics') {
        return filters.filter(elem => elem !== 'region' && elem !== 'country');
    }
    return filters;
};

interface SportEventsListInnerPropsType {
    sport: string;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isAmericanFootball: boolean;
}

export const SportEventsList = observer('SportEventsList', (propsIn: SportEventsListInnerPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { appLayoutsState, config, appSportsBookState } = useAppStateContext();
    const specialSportsState = appSportsBookState.specialSportsMap.get(props.sport);


    const { sport, headerStyleLevel, isAmericanFootball } = props;
    
    const specialExpandForSport = specialSportsState.specialExpandForSport;
    const collection = specialExpandForSport === null ? appLayoutsState.getListOfSport(sport) : specialSportsState.extendedCollection;
    return (
        <EventsTable
            headerStyle={EventsTableHeaderStyle.DOUBLE}
            filterVersion={config.filtersVersion.sportEvent}
            icon={null}
            key={sport}
            className='events-table--sport-list'
            title={<TitleElement collection={collection} />}
            isNotOutright={!isOutrightSport(sport)}
            filters={getFilters(filtersSport, sport)}
            showHeaderSport={false}
            allGroupsOpen={false}
            eventsCollection={collection}
            isTogglable={false}
            showPriceLegend={true}
            isLoading={collection.isLoading}
            isSportPage={true}
            headerStyleLevel={headerStyleLevel}
            isAmericanFootball={isAmericanFootball}
            sportId={props.sport}
        />
    );
});

interface SportCompetitionEventsListPropsType {
    sport: string;
    competition: CompetitionId;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isCompetitionPage?: boolean;
}

export const SportCompetitionEventsList = observer('SportCompetitionEventsList', (propsIn: SportCompetitionEventsListPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { config, appLayoutsState } = useAppStateContext();
    const { sport, competition, headerStyleLevel, isCompetitionPage } = props;

    const collection = appLayoutsState.getListOfSportAndCompetition(sport, competition);
    return (
        <EventsTable
            headerStyle={EventsTableHeaderStyle.SOLID}
            filterVersion={isCompetitionPage === true ? config.filtersVersion.competitionPage : config.filtersVersion.sportEvent}
            icon={null}
            className='events-table--sport-list'
            title={<TitleElement collection={collection} />}
            isNotOutright={!isOutrightSport(sport)}
            filters={getFilters(filtersCollection, sport)}
            showHeaderSport={false}
            allGroupsOpen={false}
            eventsCollection={collection}
            isTogglable={false}
            showPriceLegend={true}
            isLoading={collection.isLoading}
            headerStyleLevel={headerStyleLevel}
            isCompetitionPage={isCompetitionPage}
            isAmericanFootball={false}
            sportId={sport}
        />
    );
});
