import React from 'react';
import { BetColumn, BetSection } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet.style';
import { BetDetails } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetDetails';
import { observer } from 'src/utils/mobx-react';
import { BogBonusMessage } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BogBonusMessage';
import { BetStake } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/BetStake';
import { BetInfoBetHistory } from 'src/domains/layouts/state/lifespanState/LifespanSocketState';
import { SelectionBoostedMessage } from 'src/domains/sportsbook/shared/Components';
import { OpenapiProxyCustomerBonusesResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_bonuses';
import { useAppStateContext } from 'src/appState/AppState';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface PropsTypes {
    bet: BetHistoryType;
    bonuses: OpenapiProxyCustomerBonusesResponse200Type;
}

const checkIfBetIsWon = (betPayout: Amount | null): boolean => {
    return betPayout !== null && betPayout.isGreaterThanZero();
};

const allTypeVoid = (bet: BetHistoryType): boolean => {
    return bet.legs.every((val) => val.result?.type === 'void');
};

const renderBoostMessage = (
    betBoostedInfo: BetInfoBetHistory | null,
    bet: BetHistoryType,
    configComponents: ConfigComponents
): JSX.Element | null => {

    const potentialReturnString = bet?.potentialReturns ?? null;
    const potentialReturn = potentialReturnString === null ? null: configComponents.precision.newFromAnything(potentialReturnString);
    const betPayoutString = bet?.payout ?? null;
    const betPayout = betPayoutString === null ? null: configComponents.precision.newFromAnything(betPayoutString);

    const isBetNotCashOut = bet.cashOut === false;
    const hasBetBoostedInfo = betBoostedInfo !== null && betBoostedInfo.betInfo !== null;
    const isBetWonAndOpen = checkIfBetIsWon(betPayout) === true || bet.status === 'open';

    if (isBetNotCashOut && hasBetBoostedInfo && allTypeVoid(bet) === false && isBetWonAndOpen) {
        return (
            <SelectionBoostedMessage
                value={betBoostedInfo?.betInfo?.reward.value ?? undefined}
                potentialReturns={potentialReturn}
                betHistory={true}
            />
        );
    }

    return null;
};

export const Bet = observer<PropsTypes>('Bet', ({ bet, bonuses }) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { lifeSpanState, configComponents } = appLayoutsState;
    const { usersState } = appPlayersState;

    const payout = bet.status === 'open' ? null : bet.payout ?? null;
    const stake = usersState.money(configComponents.precision.newFromAnything(bet.totalStake));
    const freeBetCredits = bet.transaction?.tags?.freebetCredits ?? [];
    const isFreeBet = freeBetCredits.length > 0;
    const betBonusTypes = bet.transaction.tags?.bonuses ?? [];
    const bogBonus = betBonusTypes.find(elem => elem.type === 'best-odds-guaranteed') ?? null;
    const payoutForView = payout === null ? null : bogBonus === null ? configComponents.precision.newFromAnything(payout) : configComponents.precision.newFromAnything(payout).add(configComponents.precision.newFromAnything(bogBonus.amount));
    const result = payoutForView === null ? '-' : usersState.money(payoutForView);

    const betBoostedInfo = lifeSpanState.lifeSpanSocketState.myBetsBetHistory(bet.id.toString());

    return (
        <BetSection data-test='bet-list-item' data-test-bet-type={bet.type}>
            <BetDetails bet={bet}/>
            <BetColumn><BetStake isFreeBet={isFreeBet} stake={stake} /></BetColumn>
            <BetColumn><span data-test='bet-return' title={result}>{ result }</span></BetColumn>
            <BogBonusMessage betBonusTypes={betBonusTypes} bonuses={bonuses} />

            {renderBoostMessage(betBoostedInfo, bet, configComponents)}
        </BetSection>
    );
});
