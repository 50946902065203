import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { NavigationWrapper, GameButton, AllGamesButton, GridIconWrapper } from './GamesNavigation.style';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';

interface PropsType {
    appCasinoState: AppCasinoState;
    game: GameDataState;
}

export const GamesNavigation = observer('GamesNavigation', (props: PropsType): ReactElement | null => {
    const { appCasinoState } = props;
    const { isFullScreen, closeGame } = appCasinoState.gameModalState;
    const { game } = props;

    const showNavigation = game?.gameType === 'virtuals' && isFullScreen === false;
    const games = appCasinoState.gameStoreExt.getCollection('virtuals').games;
    const gameId = game?.id;

    if (showNavigation === false) {
        return null;
    }

    return (
        <NavigationWrapper>
            <AllGamesButton onClick={closeGame}>
                <GridIconWrapper />
                <I18n langKey='virtuals.game-modal.filter.all-games' defaultText='All Games' />
            </AllGamesButton>
            {games.map((game) => (
                <GameButton
                    key={game.id}
                    isActive={game.id === gameId}
                    onClick={(): void => game.startGame()}
                >
                    {game.name}
                </GameButton>
            ))}
        </NavigationWrapper>
    );
});
