import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { NotificationsState } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { LiveNotificationsModelType } from 'src/api/config/cms_new/liveNotificationsActive/getLiveNotificationsActive';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    MessageBoxContainer,
    MessageContent,
    CloseIconWrapper,
    MessageImageWrapper,
    MessageImage,
    ButtonWrapper,
    ContentWrapper,
} from './MessageBoxWrapper.style';
import { AppCasinoState } from 'src/domains/casino/shared/Types';
import { useAppStateContext } from 'src/appState/AppState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';

interface MessageBoxPropsType {
    message: LiveNotificationsModelType;
}

class State {
    @observable public isOpen: boolean = true;

    public constructor(
        private readonly message: LiveNotificationsModelType,
        private readonly notificationsState: NotificationsState,
        private readonly appCasinoState: AppCasinoState,
        private readonly starRouter: StarRouter
    ) {
        makeObservable(this);
        this.notificationsState.addItemToLiveNotificationVisibilityArray(message.id.toString(), { show: true });
    }

    @action public closeHandler = (): void => {
        this.notificationsState.onCloseOne(this.message.id.toString());
        this.isOpen = false;
    };

    public handleOnClickAction = (): void => {
        const ctaGameLink = this.message.cta_game ?? '';
        const ctaHtmlLink = this.message.cta_html_link ?? '';
        const ctaEventId = this.message.cta_event ?? '';
        if (ctaGameLink !== '' && ctaGameLink !== 'none') {
            const gameLink = parseInt(ctaGameLink, 10);
            this.appCasinoState.gameStoreExt.getGameModel(gameLink)?.startGame();
        } else if (ctaHtmlLink !== '') {
            this.starRouter.handleUrlRedirection(ctaHtmlLink);
        } else if (ctaEventId !== '') {
            this.notificationsState.redirectToEvent(ctaEventId);
        }
        this.notificationsState.onCloseOne(this.message.id.toString());
        this.isOpen = false;
    };
}

const IconResourceWrapper = observer('IconResourceWrapper', ({ imgUrl }: { imgUrl: string }): JSX.Element | null => {
    return (
        <MessageImageWrapper>
            <MessageImage
                src={imgUrl}
                data-test='notification-icon'
            />
        </MessageImageWrapper>
    );
});

const customCtaText = (language: LanguagesState, ctaKey: string): JSX.Element => {
    return language.translateTokens(
        language.getTranslation('message-bar.button.custom-cta-cms-text', '[ctaKey]'),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'ctaKey') {
                return <div>{ctaKey}</div>;
            }
        }
    );
};

const renderCtaText = (language: LanguagesState, ctaKey: string): JSX.Element => {
    if (ctaKey === 'play_now') {
        return (
            <I18n
                langKey='message-bar.button.play-now'
                defaultText='Play Now'
            />
        );
    } else if (ctaKey === 'claim_now') {
        return (
            <I18n
                langKey='message-bar.button.claim-now'
                defaultText='Claim Now'
            />
        );
    } else {
        return customCtaText(language, ctaKey);
    }
};

export const MessageBox = observer('MessageBox', (props: MessageBoxPropsType): JSX.Element | null => {
    const { message } = props;

    const { appLayoutsState, appCasinoState } = useAppStateContext();
    const { languagesState, notificationsState, starRouter } = appLayoutsState;

    const [state] = useState(() => new State(message, notificationsState, appCasinoState, starRouter));

    const imgUrl = message.img_url ?? '';

    return state.isOpen ? (
        <MessageBoxContainer data-test='notification-box'>
            <ContentWrapper>
                {imgUrl === '' ? null : <IconResourceWrapper imgUrl={imgUrl} />}
                <MessageContent data-test='notification-message'>{message.message_text}</MessageContent>
            </ContentWrapper>
            <>
                {message.cta_text === 'no_link' ||
                message.cta_text === '' ||
                message.cta_text === null ||
                message.cta_text === undefined ? null : (
                    <ButtonWrapper
                        onClick={state.handleOnClickAction}
                        size='small'
                        dataTest='notification-button'
                    >
                        {renderCtaText(languagesState, message.cta_text)}
                    </ButtonWrapper>
                )}
                <CloseIconWrapper onClick={state.closeHandler} />
            </>
        </MessageBoxContainer>
    ) : null;
});
