import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import * as t from 'io-ts';

const decodeIO = t.boolean;
const decode = buildValidator('localStorage.balanceInHeader', decodeIO, true);

export const localStorageBalanceInHeader = (withAutorun: boolean, key: string): LocalStorageItemState<boolean> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: true
    });
};
