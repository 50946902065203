import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { ScoreboardBasic } from 'src/domains/sportsbook/webview/components/scoreboards/basic/ScoreboardBasic';
import { ScoreboardStandard } from 'src/domains/sportsbook/webview/components/scoreboards/standard/ScoreboardStandard';
import { PreMatch } from 'src/domains/sportsbook/webview/components/matchLead/PreMatch';

interface ScoreboardsPropsType {
    event: EventModel;
}

export const Scoreboards = observer('Scoreboards', ({ event }: ScoreboardsPropsType): JSX.Element => {
    const { feedGroupId, timeSettingsStarted } = event;
    if (timeSettingsStarted && feedGroupId !== null && feedGroupId !== undefined) {
        return (
            <>
                <ScoreboardBasic event={event} />
                <ScoreboardStandard event={event} />
            </>
        );
    }

    return <PreMatch event={event} />;
});
