import { DatePartInput } from 'src/domains/players/webview/components/form/DateInput';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const DateRangeFormBtnWrapper = styled(Button, { label: 'DateRangeFormBtnWrapper' })`
    display: block;
    margin-top: 8px;
    width: 100%;
`;
interface PropsType {
    isError?: boolean;
};

export const DataRageRowWrapper = styled('div', { label: 'DataRageRowWrapper' })`
    margin-bottom: 8px;
`;

export const DateRangeFormTitle = withConfig(theme => styled('span', { label: 'DateRangeFormTitle' })`
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.3334;
`);

export const DateRangeFormContainer = styled('div', { label: 'DateRangeFormContainer' })`
    margin-bottom: 16px;
`;

export const DateInputsRangeWrapper = withConfig(theme => styled('div', { label: 'DateInputsRangeWrapper' }) <PropsType>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0;
    background-color: ${theme.star.form.light.bgColor};
    border: 1px solid ${({ isError }): string => isError === true ? theme.star.statuses.error.bgColor : 'transparent'};
    &:focus-within {
        border: 1px solid ${({ isError }): string => isError === true ? theme.star.statuses.error.bgColor : theme.star.form.borderColor};
    }
`);

export const DatePartInputRange = withConfig(theme => styled(DatePartInput, { label: 'DatePartInputRange' })`
    input {
        background-color: ${theme.star.form.light.bgColor};
    }
`);

export const DateRangeResultWrapper = styled('div', { label: 'DateRangeResultWrapper' })`
    margin-top: 8px;
`;

export const DateRangeResultText = withConfig(theme => styled('p', { label: 'DateRangeResultText' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3;
    margin: 0;
`);

export const DateRangeResultTextBold = styled('b', { label: 'DateRangeResultTextBold' })`
    margin: 0 3px;
`;

export const DateRangeResultUpdate = withConfig(theme => styled('span', { label: 'DateRangeResultUpdate' })`
    color: ${theme.star.layoutGlobal.linkColor};
    cursor: pointer;
    font-weight: ${theme.star.fontWeight.bold};
`);
