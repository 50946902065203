import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    RaceSummaryRowMarkets,
    RaceSummaryRowMarketsConfig
} from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummaryRowMarkets';
import {
    RowMarketsState,
    RowMarketsStateStrategy,
    SHORT_LIST_COUNT
} from 'src/domains/sportsbook/webview/components/raceSummary/templates/rowMarkets/RaceSummaryRowMarkets.state';
import { computed, observable, makeObservable } from 'mobx';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export class RaceSummarySubRacesState implements RowMarketsStateStrategy {
    @observable public isShownMore: boolean = true;

    public constructor(public readonly eventId: EventId) {
        makeObservable(this);
    }

    @computed public get markets(): Array<MarketModel> {
        const currentEvent = this.eventId.getEventModel();
        if (currentEvent === null) {
            return [];
        }
        const out = currentEvent.marketSubRace.sort((mA, mB) => mA.displayOrder < mB.displayOrder ? -1 : 1);
        if (out.length < SHORT_LIST_COUNT) {
            return out;
        }

        return this.isShownMore ? out : out.slice(0, SHORT_LIST_COUNT);
    }

}

const viewConfig: RaceSummaryRowMarketsConfig = {
    displayShowMoreButton: false,
    toggleMarkets: false,
    withTableHeader: true
};

export interface RaceSummarySubRacesPropsTypes {
    eventId: EventId;
}

export const RaceSummarySubRaces = observer('RaceSummarySubRaces', (props: RaceSummarySubRacesPropsTypes) => {
    const [state] = useState(() => new RowMarketsState(new RaceSummarySubRacesState(props.eventId)));

    return <RaceSummaryRowMarkets state={state} eventId={props.eventId} config={viewConfig}/>;
});
