import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';



export const validateExpectNumber = (value: string): Result<number> => {

    const valueNumber = parseInt(value, 10);

    if (isNaN(valueNumber)) {

        return Result.createError('Number expected');

    }



    return Result.createOk(valueNumber);

};

