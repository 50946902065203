import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    HomePageCarouselPromosComponent,
    HomePageCarouselPromosListWrapper,
    SliderWrapper,
} from './HomePageCarouselPromos.style';
import { HomePageCarouselItem } from './HomePageCarouselItem';
import { ChevronsWrapper } from './ChevronsWrapper';
import { HomePageCarouselState } from 'src/domains/layouts/state/cmsState/homePageCarouselState/HomePageCarouselState';
import { useAppStateContext } from 'src/appState/AppState';
import { Dots } from 'src/domains/layouts/webview/components/common/dots/Dots';
import { CarouselModeType } from 'src/domains/layouts/state/cmsState/homePageCarouselState/CarouselMainState';

interface PropsTypes {
    carouselPromoState: HomePageCarouselState;
    modeType: CarouselModeType;
}

export const HomePageCarouselPromos = observer(
    'HomePageCarouselPromos',
    ({ carouselPromoState, modeType }: PropsTypes) => {
        const { appLayoutsState } = useAppStateContext();
        const { breakpointsState } = appLayoutsState;

        const {
            sortedLinks,
            setRefSlider,
            calcShift,
            setCurrentSlide,
            dotsList,
            dotsIndexes,
            currentSlideGroupId,
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        } = carouselPromoState;
        const isDot = dotsList.length > 1;
        const isTabletAtLeast = breakpointsState.tablet.isBiggerOrEq;
        const isHomepage = modeType === 'homepage';

        if (sortedLinks.length > 0) {
            return (
                <HomePageCarouselPromosComponent
                    isDot={isDot}
                    isHomePage={isHomepage}
                >
                    <HomePageCarouselPromosListWrapper
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                        ref={setRefSlider}
                        data-test='homepage-carousel-wrapper'
                    >
                        <SliderWrapper calcShift={calcShift}>
                            {sortedLinks.map((promo) => (
                                <HomePageCarouselItem
                                    key={promo.id}
                                    promo={promo}
                                />
                            ))}
                        </SliderWrapper>
                        {isDot && isTabletAtLeast === true ? (
                            <ChevronsWrapper carouselPromoState={carouselPromoState} />
                        ) : null}
                        {isDot ? (
                            <Dots
                                banners={dotsIndexes}
                                currentSlide={currentSlideGroupId}
                                smallDotIndex={3}
                                slideWithDot={setCurrentSlide}
                                position='absolute'
                                isHomepage={isHomepage}
                            />
                        ) : null}
                    </HomePageCarouselPromosListWrapper>
                </HomePageCarouselPromosComponent>
            );
        }
        return null;
    }
);
