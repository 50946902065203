import { observable, action, makeObservable } from 'mobx';

export class DropdownState {
    @observable public open: boolean = false;

    public constructor() {
        makeObservable(this);
    }

    @action public handleOpen = (): void => {
        this.open = !this.open;
    };
}
