import { computed, makeObservable } from 'mobx';
import { FiltersStateStrategy, FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { EventsListState } from 'src/domains/sportsbook/shared/Types';

export class SportFilterStrategy implements FiltersStateStrategy {
    public constructor(
        private readonly eventsListState: EventsListState,
        private readonly defaultFilterId?: string | undefined
    ) {
        makeObservable(this);
        if (this.defaultFilterId !== undefined) {
            this.setActiveFilterId(this.defaultFilterId);
        }
    }

    @computed public get filters(): FilterType[] {
        return this.eventsListState.translatedCouponsForEnv.map((item) => {
            return {
                id: item.id,
                key: item.id,
                label: item.name,
            };
        });
    }

    public getActiveFilterId(): string {
        return this.eventsListState.filter_coupon;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (typeof id === 'string') {
            this.eventsListState.changeFilter_coupon(id);
        }
    }
}
