import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const MeetingsTraChallengeInfo = withConfig(theme => styled('div', { label: 'MeetingsTraChallengeInfo' })`
    display: block;
    flex: 1 1 0%;
    font-size: ${theme.star.fontSize.regular};
    padding: 10px 5px;
    position: relative;
    text-align: center;
`);

export const FiltersWrapperTrapChallenge = withConfig(theme => styled('div', { label: 'FiltersWrapperTrapChallenge' })`
    padding: 8px 0 8px 8px;
    border: 1px solid ${theme.star.raceSummary.borderColor};
`);
