import * as t from 'io-ts';
import { ConfigServer } from 'src_server/ConfigServer/ConfigServer';
import { LangDetailsMapType } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguagesAvailableType } from 'src/domains/layouts/config/features/types';
import { fetchGeneral } from 'src_common/common/fetch';
import { createGuard } from 'src_common/common/createGuard';
import { convertRecordToMapDefault } from 'src/appState/utils';

const getLanguageName = (langCode: string, languagesAvailable: LanguagesAvailableType | null): string => {
    if (languagesAvailable !== null) {
        for (const language of languagesAvailable) {
            if (langCode === language.langCode) {
                return language.langName;
            }
        }
    }
    return langCode;
};

const isResponse = createGuard(t.interface({
    description: t.union([t.string, t.undefined, t.null]),
    properties: t.record(t.string, t.string)
}));

export const getTranslations = async (config: ConfigServer, lang: string, languagesAvailable: LanguagesAvailableType | null, isMobileApp?: boolean): Promise<LangDetailsMapType | null> => {
    const result = await fetchGeneral('GET', {
        url: isMobileApp === true ? `${config.envParams.API_URL}/public-translations/${config.envParams.API_UNIVERSE}/mobile/${lang}` : `${config.envParams.API_URL}/public-translations/${config.envParams.API_UNIVERSE}/site/${lang}`,
        timeout: 'default'
    });

    if (result.status === 200) {
        const body = result.body;

        if (body.type !== 'json') {
            console.error('getTranslations - expected json');
            return null;
        }

        if (!isResponse(body.json)) {
            console.error('getTranslations - decode error');
            return null;
        }

        const description = body.json.description ?? undefined;
        const properties = convertRecordToMapDefault(body.json.properties);

        if (description !== undefined) {
            return {
                description,
                properties,
            };
        }

        return {
            description: getLanguageName(lang, languagesAvailable),
            properties,
        };
    }

    if (result.status === 404) {
        return null;
    }


    console.error(`getTranslations - Unhandled response code - ${result.status}`);
    return null;
};
