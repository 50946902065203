import * as t from 'io-ts';
import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { RecentlyVisitedLocalStorageObjectType } from 'src/domains/layouts/state/localStorage/RecentlyVisitedLocalStorage';

const decodeIO = t.array(t.interface({
    type: t.union(([
        t.literal('event'),
        t.literal('sport'),
        t.literal('casino'),
    ])),
    id: t.string
}));

const decode = buildValidator('localStorageCgRecentlyVisited.decodeIO', decodeIO, true);

export const localStorageCgRecentlyVisited = (withAutorun: boolean, key: string): LocalStorageItemState<RecentlyVisitedLocalStorageObjectType[]> => {
    return createLocalStorageItem(withAutorun, {
        key: key,
        decode: decode,
        defaultValue: []
    });
};
