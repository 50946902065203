import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const EventListWrapper = withConfig(theme => styled('section', { label: 'EventListWrapper' })`

    font-size: ${theme.star.fontSize.xRegular};

    overflow: hidden;

`);

