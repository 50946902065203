import React from 'react';
import { PromoSidebarDesc, PromoSidebarDescHeader, PromoSidebarList } from './PromoSidebarPartials.style';

interface TermsPropsType {
    tc_text?: string;
}

export const Terms = ({ tc_text }: TermsPropsType): JSX.Element | null => (
    <PromoSidebarDesc>
        {tc_text !== undefined && tc_text !== '' ? (
            <>
                <PromoSidebarDescHeader data-test='landing-page-terms'>Terms & conditions:</PromoSidebarDescHeader>
                <PromoSidebarList
                    dangerouslySetInnerHTML={{ __html: tc_text }}
                    data-test='landing-page-terms-text'
                />
            </>
        ) : null}
    </PromoSidebarDesc>
);
