import * as React from 'react';
import { MessageBoxBtnWrapper } from './MessageBox.style';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Popup } from 'src/domains/players/webview/ui/Popup';

interface PropsType {
    title: string,
    message: string,
    onClose?: () => void,
}

export const MessageBox = observer('MessageBox', (props: PropsType) => {
    const close = (e?: React.SyntheticEvent): void => {
        const { onClose } = props;
        if (e !== undefined) {
            e.preventDefault();
        }

        if (onClose !== undefined) {
            onClose();
        }
    };

    const { title, message } = props;

    return (
        <Popup
            title={title}
            className='message-box'
            priority={10100}
            overlayClose={true}
            onClose={close}>
            <>
                {message}
                <MessageBoxBtnWrapper
                    type='button'
                    onClick={close}
                    size='xs'
                    dataTest='button-ok'>
                    <I18n langKey='common.message-box.ok.label' defaultText='OK' />
                </MessageBoxBtnWrapper>
            </>
        </Popup>
    );
});
