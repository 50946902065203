import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { LiveCasinoPageMainWrapper } from './LiveCasinoPage.style';
import { MainContent } from 'src/domains/layouts/webview/modules/layoutSkeleton/LayoutSkeleton.style';
import { LiveCasinoRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { CasinoCollection } from 'src/domains/casino/webview/CasinoGames';

interface PropsType {
    appCasinoState: AppCasinoState;
    currentView: LiveCasinoRouteType;
    accountView: RightHandSideViewType | null;
    isSearchActive: boolean;
}

export const LiveCasinoPage = observer('LiveCasinoPage', (props: PropsType) => {
    const { appCasinoState, currentView, isSearchActive, accountView } = props;

    return (
        <LiveCasinoPageMainWrapper>
            <LayoutSkeleton
                navigation={false}
                accountView={accountView}
                currentView={currentView}
                isSearchActive={isSearchActive}
                navigationType='live-casino'
            >
                <MainContent>
                    <CasinoCollection
                        appCasinoState={appCasinoState}
                        collection='live-casino'
                        tabId={currentView.tabId ?? null}
                    />
                </MainContent>
            </LayoutSkeleton>
        </LiveCasinoPageMainWrapper>
    );
});
