import { computed, observable, action, makeObservable } from 'mobx';
import { RabPostBodyItemType } from 'src/domains/sportsbook/betting/state/rabState/Types';
// TODO: there is SBK-2264 ticket in backlog created to fix it - use session.call mechanism
// eslint-disable-next-line no-restricted-imports
import { apiCommon } from 'src/api/ApiCommon';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { RabPriceError, RabPriceSuccess, RabPriceSuspended } from 'src/api/config/eventsRab/rabGetPrice';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

export type PriceResourceType = null | RabPriceError | RabPriceSuccess | RabPriceSuspended;

class ConnectWrapper {
    public currentRequest: number = 0;

    public constructor(
        public readonly eventId: EventId,
        public readonly getPostBody: () => Array<RabPostBodyItemType>,
        public readonly whenLoad: () => void
    ) {}

    public connect(self: MobxValue<PriceResourceType>): NodeJS.Timeout {
        const refresh = (): void => {
            this.currentRequest++;

            const currentRequest = this.currentRequest;

            const platformObjectId = this.eventId.getEventModel()?.rabId ?? null;

            if (platformObjectId === null) {
                self.setValue(null);
                this.whenLoad();
                return;
            }

            const postBody = this.getPostBody();
            if (postBody.length < 2) {
                self.setValue(null);
                this.whenLoad();
                return;
            }

            (async (): Promise<void> => {
                const response = await apiCommon.rabGetPrice.run({
                    platformId: platformObjectId,
                    selectionLegs: postBody,
                    stake: 1,
                });

                if (currentRequest === this.currentRequest) {
                    self.setValue(response);
                    this.whenLoad();
                }
            })().catch((error) => {
                console.error(error);
            });
        };

        setTimeout(refresh, 0);
        const timer = setInterval(refresh, 1000);
        return timer;
    }

    public dispose(timer: NodeJS.Timeout, self: MobxValue<PriceResourceType>): void {
        self.setValue(null);
        clearInterval(timer);
    }
}

export class PriceResponseItem {
    public readonly valuePrice: MobxValue<PriceResourceType>;

    @observable public priceThatUserWants: null | number = null;

    public constructor(eventId: EventId, getPostBody: () => Array<RabPostBodyItemType>) {
        makeObservable(this);

        this.valuePrice = MobxValue.create({
            initValue: null,
            connect: new ConnectWrapper(eventId, getPostBody, () => {
                if (this.priceThatUserWants === null) {
                    const value = this.price;
                    if (value !== null && value.type === 'ok') {
                        this.priceThatUserWants = value.data.price;
                    }
                }
            }),
        });
    }

    @action public reset(): void {
        this.valuePrice.setValue(null);
        this.priceThatUserWants = null;
    }

    @computed.struct public get price4(): PriceResourceType {
        const value = this.valuePrice.getValue();

        if (value === null || value.type === 'error' || value.type === 'suspended') {
            return value;
        }

        return value;
    }

    @computed.struct public get price(): PriceResourceType {
        return this.price4;
    }

    public isError(): boolean {
        return this.price !== null && this.price.type === 'error';
    }

    @action public acceptChanges(): void {
        const value = this.price;
        if (value !== null && value.type === 'ok') {
            this.priceThatUserWants = value.data.price;
        }
    }

    public get selectionChanged(): boolean {
        const value = this.price;

        if (value === null || value.type === 'error' || value.type === 'suspended') {
            return false;
        }

        const priceThenUserWants = this.priceThatUserWants;

        if (priceThenUserWants === null) {
            return false;
        }

        if (value.data.price === priceThenUserWants) {
            //only this status can qualify for a beta deposit
            return false;
        }

        return true;
    }

    public get selectionChangedDown(): boolean {
        const value = this.price;

        if (value === null || value.type === 'error' || value.type === 'suspended') {
            return false;
        }

        const priceThenUserWants = this.priceThatUserWants;

        if (priceThenUserWants === null) {
            return false;
        }

        if (value.data.price < priceThenUserWants) {
            //only this status can qualify for a beta deposit
            return false;
        }

        return true;
    }

    public dump(): void {
        console.info('Dump', {
            priceThatUserWants: this.priceThatUserWants,
            selectionChanged: this.selectionChanged,
            price: this.price,
        });
    }
}
