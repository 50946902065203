import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { LoaderWrapper, InfoWrapper } from './BetsListMessage.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { StatusType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

interface PropsType {
    status: StatusType,
    betsListLength: number
}

export const BetsListMessage = observer<PropsType>('', ({ status, betsListLength }) => {

    if (status === 'error') {
        return <InfoWrapper key='error'>
            <I18n langKey='bets.loading.error' defaultText="Sorry, because of temporary issues we can't load bets. Try again." />
        </InfoWrapper>;
    }

    if (status === 'loading') {
        return <InfoWrapper key='loading'>
            <LoaderWrapper size={14} version='dark'>
                <I18n langKey='bets.loading' defaultText='Loading bets...' />
            </LoaderWrapper>
        </InfoWrapper>;
    }

    if (betsListLength < 1) {
        return <InfoWrapper key='empty'>
            <I18n langKey='bets.empty.alternative' defaultText="We haven't found any bets." />
        </InfoWrapper>;
    }

    return null;
});
