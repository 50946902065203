import { ConfigType } from 'src/domains/layouts/config/features/types';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export type GenderTitleType = 'ms' | 'mr' | undefined;
export class GenderTitleState {
    public titleChooseState: FormInputState<string, GenderTitleType>;

    public constructor(config: ConfigType) {
        this.titleChooseState = FormInputState.new('').map((value) => {
            if (config.signUpTitle === false) {
                return Result.createOk(undefined);
            } else if (value === 'ms' || value === 'mr') {
                return Result.createOk(value);
            }
            return Result.createError(getErrorByCode('ERROR_REQUIRED_FIELD'));
        });
    }
}
