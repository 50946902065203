import React from 'react';
import { createGuard } from 'src_common/common/createGuard';
import { BoostIconWrapper, SpaceWrapper } from 'src/domains/sportsbook/lifespan/ArrowDisplay/LifespanBoostArrowDisplay.style';
import { WalletType } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { WalletPromotionLink } from 'src/domains/sportsbook/lifespan/LifespanWallet';
import { TypeId } from 'src_common/common/websocket2/type';
import { observer } from 'src/utils/mobx-react';
import { jsonParse } from 'src_common/common/jsonParse';

const isWalletPromotionAvailable = createGuard(WalletPromotionLink);

interface LifespanBoostArrowDisplayType {
    userRewardsMessageGrouped: WalletType[],
    type: string,
    id?: string | number,
    competitionId?: string,
    sportId?: string,
    eventId?: string | number | null,
    marketId?: string,
    selectionId?: string,
}
 
export const LifespanBoostArrowDisplay = observer('LifespanBoostArrowDisplay', ({ data }: {data: LifespanBoostArrowDisplayType}): JSX.Element | null => {
    const { userRewardsMessageGrouped, type, id, competitionId, sportId, eventId, marketId, selectionId } = data;

    const BoostData = userRewardsMessageGrouped.map(reward => {

        if (reward.routeInfo === undefined) {
            return null;
        }

        const parsedRouteInfo = jsonParse(reward.routeInfo);
        if (parsedRouteInfo.type === 'text') {
            console.error('Parsing error: ', parsedRouteInfo.text);

        }
            
        if (parsedRouteInfo.type === 'json' && isWalletPromotionAvailable(parsedRouteInfo.json)) {
            return parsedRouteInfo.json;
        } 
    });
    
    const BoostDataResult = BoostData.find(routeInfoJson => { 
        
        if (routeInfoJson?.type === 'sport' && type === 'sport') {

            return (id === routeInfoJson?.id);

        } else if (routeInfoJson?.type === 'event') {
    
            return (id === routeInfoJson?.id);

        } else if (routeInfoJson?.type === 'competition') {
    
            return (competitionId === routeInfoJson?.competitionId && sportId === routeInfoJson?.sportId);

        } else if (routeInfoJson?.type === 'market') {
            
            return (eventId?.toString() === routeInfoJson?.eventId && marketId === TypeId.newMarketId(Number(routeInfoJson?.eventId), Number(routeInfoJson?.marketId)));
        
        } else if (routeInfoJson?.type === 'selection') {
      
            return (eventId?.toString() === routeInfoJson?.eventId && selectionId === routeInfoJson?.selectionId && marketId === TypeId.newMarketId(Number(routeInfoJson?.eventId), Number(routeInfoJson?.marketId)));
        }

    });

    if (BoostDataResult === null || BoostDataResult === undefined){
        return null;
    } 
    
    return (
        <SpaceWrapper type={type} >
            <BoostIconWrapper/>
        </SpaceWrapper>
    );
});
