import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, observable, makeObservable } from 'mobx';
import {
    MoreLessGroup,
    LinkMoreLessWrapper,
} from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/outrightMarket/OutrightMarket.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionRabNestedMenuItem } from 'src/domains/sportsbook/webview/components/selectionRab/selectionRabCorrectScore/selectionRabCorrectScoreHeader/SelectionRabCorrectScoreMenuItem';
import { slug } from 'src/utils/deburr';
import { MarketRabView, SelectionView } from 'src/domains/sportsbook/betting/state/rabState/Types';
import {
    SelectionGroupWrapper,
    SelectionRabList,
    MarketGroup,
    MarketGroupHeader,
    SelectionsGroup,
    RabHeaderWrapper,
    ChevronIconWrapper,
} from 'src/domains/sportsbook/webview/components/selectionRab/SelectionRab.style';
import { SelectionRabItemMenu, SelectionNestedWrapper } from './SelectionRabCombined.style';
import { SelectionRabCombinedDesktop } from './selectionRabCombinedDesktop/SelectionRabCombinedDesktop';
import { SelectionRabCombineRow } from './selectionRabCombinedRow/SelectionRabCombinedRow';
import { useAppStateContext } from 'src/appState/AppState';
import { RabItemState } from 'src/domains/sportsbook/betting/state/rabState/RabItemState';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
interface SelectionRabPropsType {
    rabId: BetslipRabId;
    order: number;
    marketName: string;
    templateType: string;
    firstMarketName: string;
    secondMarketName: string;
}

const mergeSelections = (markets: Array<MarketRabView> | undefined): Array<SelectionView> => {
    const out = [];

    if (markets === undefined) {
        return [];
    }

    for (const item of markets) {
        out.push(...item.selections);
    }
    return out;
};

const correctScoreMenu = ['Home Team', 'Away Team'];

const filterSelectionByTeam = (item: SelectionView[], side: string): SelectionView[] => {
    if (side === 'home-team') {
        return item.filter((item) => item.team === 'team-home');
    }

    return item.filter((item) => item.team === 'team-away');
};

const createCombinedMarkets = (marketName: string, rabMarket: RabItemState | null): MarketRabView[] | undefined => {
    if (marketName === 'FirstNextGoalScorer') {
        return rabMarket?.marketsForViewInner.filter(
            (item) => item.templateType === 'FirstNextGoalScorer' || item.templateType === 'AnytimeScorer'
        );
    }

    return rabMarket?.marketsForViewInner.filter(
        (item) => item.templateType === 'FirstNextCardedPlayer' || item.templateType === 'AnytimeCardedPlayer'
    );
};

class State {
    @observable public collapsed: boolean = false;
    @observable public moreOpen: boolean = false;
    @observable public correctScoreActive: string = 'home-team';

    public constructor(order: number) {
        makeObservable(this);
        this.collapsed = order > 4;
    }

    public toggleCollapsed = (): void => {
        if (this.collapsed) {
            this.collapsed = false;
            this.moreOpen = false;
        } else {
            this.collapsed = true;
        }
    };

    public onClickMore = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        this.moreOpen = true;
    };

    public onClickLess = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();

        this.moreOpen = false;
    };

    @action public changeActiveMenu = (activeMenu: string): void => {
        this.correctScoreActive = activeMenu;
    };
}

const renderMore = (state: State): React.ReactNode => {
    return (
        <MoreLessGroup>
            <LinkMoreLessWrapper onClick={state.onClickMore}>
                <I18n langKey='events.request-a-bet-selection.more' defaultText='More' />
                <ChevronIconWrapper position='down' />
            </LinkMoreLessWrapper>
        </MoreLessGroup>
    );
};

const renderLess = (state: State): React.ReactNode => {
    return (
        <MoreLessGroup>
            <LinkMoreLessWrapper onClick={state.onClickLess}>
                <I18n langKey='events.request-a-bet-selection.less' defaultText='Less' />
                <ChevronIconWrapper position='up' />
            </LinkMoreLessWrapper>
        </MoreLessGroup>
    );
};

const renderMoreLess = (state: State): React.ReactNode => {
    const { moreOpen } = state;
    if (moreOpen) {
        return renderLess(state);
    }

    return renderMore(state);
};

export const SelectionRabCombined = observer('SelectionRabCombined', (props: SelectionRabPropsType) => {
    const { rabId, order, marketName, templateType, firstMarketName, secondMarketName } = props;
    const [state] = React.useState(() => new State(order));
    const { collapsed } = state;

    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;
    const rabMarket = rabId.getModel();

    const combinedMarkets = createCombinedMarkets(templateType, rabMarket);

    const selections = mergeSelections(combinedMarkets);

    const elementsByTeam = filterSelectionByTeam(selections, state.correctScoreActive);
    const renderElements = state.moreOpen ? elementsByTeam : elementsByTeam.slice(0, 6);

    const teamHome = filterSelectionByTeam(selections, 'home-team');
    const teamAway = filterSelectionByTeam(selections, 'away-team');

    const teamHomeNames = renderElements.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
    const teamAwayNames = renderElements.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

    const renderTeamNames = state.correctScoreActive === 'home-team' ? teamHomeNames : teamAwayNames;

    return (
        <MarketGroup data-test-rab-market-name={marketName} data-test-collapsed={collapsed}>
            <RabHeaderWrapper onClick={state.toggleCollapsed}>
                <ChevronIconWrapper position={collapsed ? 'right' : 'down'} />
                <MarketGroupHeader isCollapsed={collapsed}>{marketName}</MarketGroupHeader>
            </RabHeaderWrapper>

            {collapsed ? null : (
                <SelectionNestedWrapper>
                    {isLargeDesktop === true ? (
                        <SelectionRabCombinedDesktop
                            rabId={rabMarket.id}
                            moreOpen={state.moreOpen}
                            teamHome={teamHome}
                            teamAway={teamAway}
                            firstMarketName={firstMarketName}
                            secondMarketName={secondMarketName}
                        />
                    ) : (
                        <>
                            <SelectionRabItemMenu>
                                {correctScoreMenu.map((element) => {
                                    return (
                                        <SelectionRabNestedMenuItem
                                            changeActiveMenu={state.changeActiveMenu}
                                            isActive={state.correctScoreActive === slug(element)}
                                            name={element}
                                            key={element}
                                        />
                                    );
                                })}
                            </SelectionRabItemMenu>

                            <SelectionGroupWrapper>
                                <SelectionsGroup>
                                    <SelectionRabList>
                                        <SelectionsGroup>
                                            <SelectionRabCombineRow
                                                selections={selections}
                                                rabId={rabId}
                                                selectionsName={renderTeamNames}
                                                firstMarketName={firstMarketName}
                                                secondMarketName={secondMarketName}
                                            />
                                        </SelectionsGroup>
                                    </SelectionRabList>
                                </SelectionsGroup>
                            </SelectionGroupWrapper>
                        </>
                    )}
                    {renderMoreLess(state)}
                </SelectionNestedWrapper>
            )}
        </MarketGroup>
    );
});
