import { Session } from 'src_common/sdk/session';
import { CollectionType } from 'src/domains/casino/types/types';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { BannersResponseType, BannerType } from 'src/domains/casino/state/AppCasino/store/banner-store/types';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { openapiCmsAnonymousGetBannersRequest } from 'src/domains/casino/api/generated/cms/openapi_cms_anonymous_get_banners';

export class BannerStore {
    private bannersData: MobxMapAutoNew<CollectionType, Resource<BannersResponseType>>;
    private scheduledBanners: Array<BannerType>;
    public sidebarTermsActiveBanner: BannerType| null;

    public constructor(private session: Session) {
        this.bannersData = new MobxMapAutoNew((collection) => {
            const promise = this.session.call(openapiCmsAnonymousGetBannersRequest, { collection_slug: collection });
            return new Resource(async (): Promise<BannersResponseType> => promise);
        });
        this.scheduledBanners = [];
        this.sidebarTermsActiveBanner = null;
    }

    private filterBannersByScheduledDate = (banners: Array<BannerType>): Array<BannerType> => {
        const filteredBanners = banners.filter(banner => {
            const { date_start, date_end } = banner;

            const dateStart = new Date(date_start).toString();
            const dateEnd = new Date(date_end).toString();
            const dateNow = new Date().toString();

            const startValue = Date.parse(dateStart);
            const endValue = Date.parse(dateEnd);
            const nowValue = Date.parse(dateNow);
            
            return startValue < nowValue && endValue > nowValue;
        });
        return filteredBanners;
    };

    public getBanners(collection: CollectionType): Array<BannerType> {
        const resource = this.bannersData.get(collection);
        const result = resource.get();

        if (result.type === 'loading') {
            return [];
        }

        if (result.type === 'ready') {
            this.scheduledBanners = this.filterBannersByScheduledDate(result.value.banners);
            return this.scheduledBanners;
        }

        console.error(`${collection} banners couldn't get loaded`);
        return [];
    }

    public setSidebarTermsActiveBanner(bannerId: number): void {
        this.sidebarTermsActiveBanner = this.scheduledBanners.find(banner => banner.id === bannerId) ?? null;
    };
}
