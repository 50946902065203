import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';

const RealTimeLobbyGameIO = t.interface({
    game_id: t.number,
    min_bet: t.string,
    max_bet: t.string,
    dealer_name: t.string,
    seats_total: t.union([t.number, t.null, t.undefined]),
    seats_taken: t.union([t.number, t.null, t.undefined]),
    last_numbers: t.union([t.array(t.string), t.null, t.undefined]),
    provider: t.union([t.string, t.null, t.undefined])
});

export type RealTimeLobbyGameType = t.TypeOf<typeof RealTimeLobbyGameIO>;

export const isRealTimeLobbyGameModel = createGuard(RealTimeLobbyGameIO);
