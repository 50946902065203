import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface ValueWrapperPropsType {
    isValueTooLong: boolean;
}

export const BetslipFooterContainer = styled('div', { label: 'BetslipFooterContainer' })`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const TotalReturnsWrapper = withConfig(theme => styled('div', { label: 'TotalReturnsWrapper' })<ValueWrapperPropsType>`
    align-items: ${({ isValueTooLong }): string => isValueTooLong ? 'flex-start' : 'center'};
    color: ${theme.star.betslipGeneral.txtColor};
    display: flex;
    flex-flow: ${({ isValueTooLong }): string => isValueTooLong ? 'column nowrap' : 'row nowrap'};
    font-size: ${theme.star.fontSize.xSmall};
    justify-content: flex-start;
    line-height: 1.4;
    @media ${theme.star.mediaQuery.desktop} {
        width: 50%;
    }
`);

export const BalanceWrapper = styled(TotalReturnsWrapper, { label: 'BalanceWrapper' })<ValueWrapperPropsType>`
    justify-content: flex-end;
    align-items: ${({ isValueTooLong }): string => isValueTooLong ? 'flex-end' : 'center'};
    margin-left: 4px;
`;

export const ReturnsLabel = styled('div', { label: 'ReturnsLabel' })`
    min-width: 50%;
    margin-right: 4px;
`;

export const BalanceLabel = styled(ReturnsLabel, { label: 'BalanceLabel' })`
    text-align: right;
    margin: 0;
`;

export const TotalReturnsValue = withConfig(theme => styled('div', { label: 'TotalReturnsValue' })`
    color: ${theme.star.betslipGeneral.txtColorTertiary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.43;
`);

export const BalanceValue = withConfig(theme => styled(TotalReturnsValue, { label: 'BalanceValue' })`
    color: ${theme.star.betslipGeneral.txtColor};
    margin-left: 4px;
    text-align: right;
`);
