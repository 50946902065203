import { computed, makeObservable } from 'mobx';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { DepositInitiateType, swiftyGlobalDepositInitiateRequest } from './SwiftyGlobalDepositInitiateRequest';

export class SwiftyGlobalTabState {
    public swiftyDepositInitiateResource: Resource<DepositInitiateType | null>;

    public constructor(
        private readonly account: AccountState,
        private readonly usersState: UsersState,
        private readonly languagesState: LanguagesState,
        private readonly isSignup: boolean
    ) {
        makeObservable(this);

        this.swiftyDepositInitiateResource = new Resource(swiftyGlobalDepositInitiateRequest(this.account, this.languagesState, this.usersState, this.isSignup));
    }

    @computed public get depositInitiateState(): DepositInitiateType {
        return this.swiftyDepositInitiateResource.getReady() ?? { type: 'loading' };
    };
}
