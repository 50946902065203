import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ErrorLaunchGameModal } from './error-launch-game-modal/ErrorLaunchGameModal';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';

interface ModalErrorPropsType {
    appCasinoState: AppCasinoState,
    message: string
}

export const ModalError = observer('ModalError', (props: ModalErrorPropsType): ReactElement => {
    const { appCasinoState } = props;
    const { closeGame } = appCasinoState.gameModalState;

    return (
        <ErrorLaunchGameModal
            onBtnClickAction={closeGame}
            headerText={<I18n langKey='casino.mini-games.error.header' defaultText='Error' />}
            contentText={<I18n
                langKey='casino.mini-games.error.access-error'
                defaultText='{message}'
                params={{ message: props.message }} />}
            actionBtnText={<I18n langKey='casino.mini-games.error.back-button' defaultText='Go Back' />}
        />
    );
});
