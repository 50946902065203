import { observable, action, computed, makeObservable } from 'mobx';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { amountViewToValue } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/amountViewToValue';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { ZenetPayDepositSteps } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/zenetPayProvider/ZenetPayJourney';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateAmountRequire, validateMinAmountNew } from 'src/domains/players/webview/components/ValidatorsNew';
import { Amount } from 'src_common/common/amount/Amount';
import { TrpcClient } from 'src/appState/TrpcClient';
import { ZenetPayValidationDepositSuccessResponse, zenetPayValidation } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/zenetPayBillingInfoFieldsValidation';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';

export class ZenetPayTabState {
    public stepsState: ZenetPayDepositSteps;

    @observable public refDepositInput: HTMLInputElement | null;
    @observable public errorMessage: Array<string> = [];
    @observable public isRequesting: boolean = false;

    public readonly depositAmount: FormInputState<string, Amount>;

    public constructor(
        private readonly account: AccountState,
        private readonly usersState: UsersState,
        private readonly trpcClient: TrpcClient,
        public readonly googleTagManager: GoogleTagManagerState,
        public readonly languagesState: LanguagesState,
        public readonly minDepositAmount: Amount,
        private readonly amountPrecision: AmountPrecision
    ) {
        makeObservable(this);
        this.stepsState = new ZenetPayDepositSteps();

        this.refDepositInput = null;
        this.depositAmount = FormInputState.new('').map(validateAmountRequire)
            .map(validateMinAmountNew(this.minDepositAmount, this.languagesState.getTranslation('errors.min-deposit', 'Minimum deposit amount is {currencySymbol}{minValue}', { currencySymbol: this.usersState.moneySymbol, minValue: this.minDepositAmount.value })));


        this.areMissingFieldsForBillingInfoZenetPay();
    }

    public areMissingFieldsForBillingInfoZenetPay = (): boolean => {
        const basicData = this.account.usersState.basicData.valueReady ?? null;

        const billingInfo = zenetPayValidation(basicData);

        if (billingInfo?.type === 'error') {
            this.createErrorMessage('missing-fields', billingInfo.fieldsRequired);

            return true;
        }

        return false;
    };

    @computed public get showBalance(): string | undefined {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
    };

    /* amount Input start*/
    public onChange = (): void => {
        const formatValue = amountViewToValue(this.depositAmount.value);
        this.depositAmount.setValue(formatValue);
        this.clearErrorMessage();
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.depositAmount.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.depositAmount.setValue('');
        }
        return this.depositAmount.setValue(amount.toFixed(2));
    };

    public handleAdditionsChange = (amount: Amount): void => {
        this.depositAmount.setValue(amount.value);
        this.depositAmount.setAsVisited();
        this.clearErrorMessage();
    };

    public onSetInputToEmpty = (): void => {
        this.depositAmount.reset();
    };

    @computed public get hasInputValue(): boolean {
        return this.depositAmount.value !== '';
    }
    /* amount Input end*/

    /* ringFencedFundsFlag start */
    @computed public get hasRingFencedFunds(): boolean {
        return this.usersState.basicData.valueReady?.ringFencedFunds ?? false;
    }
    /* ringFencedFundsFlag end*/

    private clearErrorMessage = (): void => {
        this.errorMessage = [];
    };

    @action public submitDepositForm = async (): Promise<void> => {
        this.depositAmount.setAsVisited();
        this.clearErrorMessage();
        this.isRequesting = true;

        if (this.depositAmount.result.value.type === 'error'){
            console.error('invalid amount');
            this.isRequesting = false;
            return;
        }

        const amount = this.amountPrecision.valueOldFormat(this.depositAmount.result.value.data);
        const account = this.account.account;

        if (account === null) {
            console.error('submitDepositForm - User is anonymous');
            this.isRequesting = false;
            return;
        }

        const billingInfo = zenetPayValidation(this.usersState.basicData.valueReady);

        if (billingInfo?.type !== 'ok') {
            console.error('submitDepositForm - No basic user data');
            this.isRequesting = false;
            return;
        }

        try {
            if (this.hasRingFencedFunds === false) {
                await this.account.account?.onChangeRingFencedFlag();
            }

            this.googleTagManager.depositedMoney(amount, false);

            await this.onInitiateDeposit(this.depositAmount.result.value.data, billingInfo.data);
            this.isRequesting = false;
        } catch (e) {
            this.isRequesting = false;
            this.stepsState.redirectToFailureView('serverIssue');
            throw e;
        }
    };

    private onInitiateDeposit = async (
        amount: Amount,
        billingInfoData: ZenetPayValidationDepositSuccessResponse['data'],
    ): Promise<void> => {

        const response = await this.trpcClient.client.zenetPay.initiateDeposit.mutate({
            body: {
                cpf: billingInfoData.cpf,
                amount: amount.value,
                email: billingInfoData.email,
                name: billingInfoData.fullName,
                description: 'Deposit Initiate',
                webhookUri: 'https://webhook.site/f39b11c7-2a9a-4311-bc37-0be32165bbab',
            }
        });

        if (response.responseStatus === 'success'){
            this.stepsState.redirectToQrCodeView({ amount, qrCode: response.data.qrCode, qrCodeBase64: response.data.qrCodeBase64 });
            this.googleTagManager.addDepositTag(null);
            return;
        }

        const { data } = response;
        const error = data.errors?.[0];

        if (error === undefined){
            this.stepsState.redirectToFailureView('serverIssue');
            return;
        }

        if (error?.code === 'minimum') {
            this.createErrorMessage('minimum');
        } else if (error?.code === 'limit-reached') {
            this.createErrorMessage('limit-reached');
        } else if (error?.code === 'deposits-not-allowed') {
            this.createErrorMessage('deposits-not-allowed');
        } else {
            this.stepsState.redirectToFailureView('serverIssue');
        }
    };

    public hideDepositSuccess = (): void => {
        this.onSetInputToEmpty();
        this.stepsState.redirectToSetMethod();
    };

    public createErrorMessage = (errorType: string, requiredFields?: Array<string>): void => {
        switch (errorType) {
            case 'invalid-data':
                this.errorMessage.push(getErrorByCode('invalid'));
                break;
            case 'minimum':
                this.errorMessage.push(getErrorByCode('ERROR_MINIMUM_AMOUNT'));
                break;
            case 'limit-reached':
                this.errorMessage.push(getErrorByCode('ERROR_DEPOSIT_LIMIT_REACHED'));
                break;
            case 'deposits-not-allowed':
                this.errorMessage.push(getErrorByCode('ERROR_UNVERIFIED_CLIENT'));
                break;
            case 'declined':
                this.errorMessage.push('ERROR_DEPOSIT_DECLINED');
                break;
            case 'missing-fields':
                if (requiredFields === undefined) {
                    return;
                }

                this.errorMessage.push(`Please add customer ${requiredFields.join(
                    ', '
                )} to customer 'Personal account' details.`);
                break;
            default:
                this.errorMessage.push(`Unknown error happen - ${errorType}`);
        }
    };

    /* nextButton start (in accounts Tab) */
    @computed public get isButtonDisabled(): boolean {
        if (!this.hasInputValue|| this.isRequesting || this.stepsState.step.type !== 'set-method') {
            return true;
        }
        return false;
    }
    /* nextButton end */

    public setDepositInputRef = (node: HTMLInputElement | null): void => {
        this.refDepositInput = node;
    };
    /* used in signUp end */
}
