import { observable, action, makeObservable } from 'mobx';

export class AccordionState {

    @observable isOpened: boolean;

    constructor(initialStatus: boolean = true) {
        makeObservable(this);
        this.isOpened = initialStatus;
    }

    @action toggle = ():void => {
        this.isOpened = !this.isOpened;
    }

}
