import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const DepositHeader = withConfig(theme => styled('legend', { label: 'DepositHeader' })`
    margin: 0 0 12px 0;
    padding: 0 !important;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    text-transform: uppercase;
`);

export const DescriptionsWrapper = withConfig(theme => styled('div', { label: 'DescriptionsWrapper' })`
    margin: 16px 0 0 0;
    padding: 16px;
    border: solid 1px ${theme.star.signUp.borderColor};
`);

export const Description = withConfig(theme => styled('p', { label: 'Description' })`
    margin: 0;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const ShowMoreWrapper = withConfig(theme => styled('div', { label: 'ShowMoreWrapper' })`
    color: ${theme.star.signUp.txtColorSecondary};
    cursor: pointer;
    margin: 4px 0;
    position: relative;
`);

export const Arrow = styled(ChevronIcon, { label: 'Arrow' })`
    position: absolute;
    top: 6px;
    width: 12px;
    fill: currentcolor;
`;

export const ShowMoreOrLess = withConfig(theme => styled('p', { label: 'ShowMoreOrLess' })`
    display: inline-block;
    margin: 0 0 0 16px;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
`);

export const AdditionalDescription = styled(Description, { label: 'AdditionalDescription' })`
    margin-top: 8px;
`;

export const MarkedTxt = withConfig(theme => styled('a', { label: 'MarkedTxt' })`
    text-decoration: none;
    color: ${theme.star.signUp.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const PayButton = styled(Button, { label: 'PayButton' })`
    width: 100%;
    margin-top: 32px;
    text-transform: capitalize;
`;

export const CardInfo = withConfig(theme => styled('p', { label: 'CardInfo' })`
    margin: 8px 0;
    text-align: center;
    font-size: ${theme.star.fontSize.small};
    color: ${theme.star.signUp.txtColor};
    opacity: .7;
    line-height: 1.3334;
`);

export const SkipButtonContainer = styled('div', { label: 'SkipButtonContainer' })`
    margin: 0 16px;
`;

export const SkipButton = styled(Button, { label: 'SkipButton' })`
    width: 100%;
    padding: 14px 16px;
`;
