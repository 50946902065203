import React from 'react';
import { SelectionView } from 'src/domains/sportsbook/betting/state/rabState/Types';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    GoalscorerName,
    SelectionsInRow,
} from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup.style';
import { ListItem } from 'src/domains/sportsbook/webview/components/selectionRab/SelectionRabItem';
import {
    CaptionDesktopRab,
    CaptionGroupDesktop,
    GoalScorerMarketListItem,
    GoalScorerMarketListWrapper,
    MarketGroupCaptionDesktop,
} from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalScorerMarket.style';
import { BetslipRabId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';

interface PropsTypes {
    selections: SelectionView[];
    rabId: BetslipRabId;
    selectionsName: SelectionView[];
    firstMarketName: string;
    secondMarketName: string;
}

const getIsSelect = (getSelection: SelectionView[]): boolean => {
    return getSelection[0]?.isSelect ?? false;
};

const renderSelectionByName = (
    selections: SelectionView[],
    name: string,
    rabId: BetslipRabId,
    marketName: string,
    itemMarketName: string,
    borderLeft: boolean,
    borderRight: boolean
): JSX.Element => {
    const getSelection = selections.filter((item) => item.name === name && item.marketName === itemMarketName);

    return (
        <ListItem
            key={`${marketName} ${name}`}
            rabId={rabId}
            marketName={marketName}
            name={name}
            isSelect={getIsSelect(getSelection)}
            participantName=''
            displayName={false}
            borderLeft={borderLeft}
            borderRight={borderRight}
        />
    );
};

export const SelectionRabCombineRow = (props: PropsTypes): JSX.Element => {
    const { selections, rabId, selectionsName, firstMarketName, secondMarketName } = props;

    return (
        <GoalScorerMarketListWrapper>
            <MarketGroupCaptionDesktop>
                <CaptionDesktopRab isFirst={true}>
                    <I18n langKey='events.goalscorer-market.player' defaultText='Player' />
                </CaptionDesktopRab>
                <CaptionGroupDesktop>
                    <CaptionDesktopRab>
                        <I18n langKey='events.goalscorer-market.first' defaultText='First' />
                    </CaptionDesktopRab>
                    <CaptionDesktopRab>
                        <I18n langKey='events.goalscorer-market.anytime' defaultText='Anytime' />
                    </CaptionDesktopRab>
                </CaptionGroupDesktop>
            </MarketGroupCaptionDesktop>

            {selectionsName.map(({ name }, idx) => (
                <GoalScorerMarketListItem key={`${idx}${name}`}>
                    <GoalscorerName templateId=''>{name}</GoalscorerName>

                    <SelectionsInRow className='market-group__goalscorers__selections'>
                        {renderSelectionByName(selections, name, rabId, firstMarketName, 'next', true, false)}
                        {renderSelectionByName(selections, name, rabId, secondMarketName, 'anytime', true, false)}
                    </SelectionsInRow>
                </GoalScorerMarketListItem>
            ))}
        </GoalScorerMarketListWrapper>
    );
};
