import React from 'react';
import { AlternativeCustomSelectionState } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeCustomSelectionState';
import { observer } from 'src/utils/mobx-react';
import { 
    ParticipantDetailsWrapper,
    DescriptionWrapper,
    RenderDetailsWrapper,
    DetailsRowWrapper,
    DetailsColumnWrapper,
    DetailsTitle,
    Detail
} from './SelectionDetails.styles';

interface SelectionDetailsPropsTypes {
    alternativeSelection: AlternativeCustomSelectionState
}

export const SelectionDetails = observer('SelectionDetails', ({ alternativeSelection }: SelectionDetailsPropsTypes) => {
    const { detailsListForView, description } = alternativeSelection;

    return (
        <ParticipantDetailsWrapper>
            {detailsListForView.length > 0 ? (
                <RenderDetailsWrapper>
                    <DetailsRowWrapper>
                        {detailsListForView.map(elem => {
                            return (
                                <DetailsColumnWrapper key={elem.label} isSeparateLine>
                                    <DetailsTitle>{elem.label}</DetailsTitle>
                                    <Detail>{elem.value}</Detail>
                                </DetailsColumnWrapper>
                            );
                        })}
                    </DetailsRowWrapper>
                </RenderDetailsWrapper>
            ): null}
            {description === '' ? null : <DescriptionWrapper dangerouslySetInnerHTML={{ __html: description }} />}
        </ParticipantDetailsWrapper>
    );
});
