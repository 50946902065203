import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { VerifyWithEmailBtnWrapper, VerifyWithEmailSendAgain } from './VerifyWithEmail.style';
import { observer } from 'src/utils/mobx-react';
import { VerifyWithEmailState } from 'src/domains/players/webview/components/Account/verifyAccountTab/verifyWithEmail/VerifyWithEmail.state';
import { VerifyInformationStyle, SendAgainStyle } from 'src/domains/players/webview/components/Account/verifyAccountTab/VerifyAccountTab.style';
import { useAppStateContext } from 'src/appState/AppState';

export const VerifyWithEmail = observer('VerifyWithEmail', () => {
    const { appPlayersState } = useAppStateContext();
    const { usersState, loginState } = appPlayersState;

    const [state] = useState(() => new VerifyWithEmailState(loginState.emailToVerifyAccount,usersState));

    if (state.sentInfoView) {
        return (
            <VerifyInformationStyle>

                <I18n langKey='account.verify-account.through-email.description' defaultText='Please check your email and click the link to verify your account.' />

                <VerifyWithEmailSendAgain>
                    <I18n langKey='account.verify-account.resend.description' defaultText='Haven’t got it?' />

                    <SendAgainStyle onClick={state.verifyAccountWithEmail} href='#'>
                        <I18n langKey='account.verify-account-email.resend.button' defaultText='Send again' />
                    </SendAgainStyle>
                </VerifyWithEmailSendAgain>
            </VerifyInformationStyle>
        );
    }

    return (
        <VerifyWithEmailBtnWrapper
            size='large'
            onClick={state.verifyAccountWithEmail}>
            <I18n langKey='account.verify-account.through-email.submit.label' defaultText='Verify with email' />
        </VerifyWithEmailBtnWrapper>
    );
});
