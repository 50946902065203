import { createLocalStorageItem } from 'src/domains/layouts/state/localStorage/createLocalStorageItem';
import { LocalStorageItemState } from 'src/domains/layouts/state/localStorage/LocalStorageItemState';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { SelectTabIdType, SelectTabIdIO } from 'src/domains/layouts/webview/components/debugPanel/MainViewTypes';

const decode = buildValidator('localStorageCookiesPolicy.debugPanel', SelectTabIdIO, true);

export const localStorageDebugPanel = (withAutorun: boolean, key: string): LocalStorageItemState<SelectTabIdType> => {
    return createLocalStorageItem(withAutorun, {
        key,
        decode,
        defaultValue: null
    });
};
