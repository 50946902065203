import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, makeObservable } from 'mobx';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import { SportsConfigState } from 'src/domains/sportsbook/state/sportsConfigState/sportsConfigState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { ActiveSpecialSportsType, SpecialSportsActiveSportsType } from './SpecialSportsState';
import { z } from 'zod';

const SpecialSportsZOD = z.object({
    competitionIds: z.array(z.number()),
});

export class SpecialSportsListState {
    private readonly specialSportsResource: Resource<Array<ActiveSpecialSportsType>>;
    private readonly eventsCollection: EventsCollectionState;
    private readonly sportsConfigState: SportsConfigState;

    public constructor(
        eventsCollection: EventsCollectionState,
        sportsConfigState: SportsConfigState,
        private readonly trpcClient: TrpcClient
    ) {
        makeObservable(this);
        this.specialSportsResource = new Resource(async (): Promise<Array<ActiveSpecialSportsType>> => {
            const response = await this.trpcClient.client.cms.getActiveSpecialSports.query();
            return response.body;
        });
        this.eventsCollection = eventsCollection;
        this.sportsConfigState = sportsConfigState;
    }

    @computed public get sportsConfigActiveResourceData(): SpecialSportsActiveSportsType {
        if (this.sportsConfigState.sportsConfigActiveResourceData === 'loading') {
            return 'loading';
        }
        const response: Array<ActiveSpecialSportsType> = this.sportsConfigState.sportsConfigActiveResourceData.map(
            (elem) => {
                return {
                    details: elem.details,
                    id: elem.id,
                    is_active: elem.is_active,
                    label: elem.sport_label ?? '',
                    slug: elem.sport_slug,
                    sport_type: elem.sport_type ?? '',
                    universe: elem.universe,
                };
            }
        );

        return response;
    }

    @computed public get activeSpecialSports(): SpecialSportsActiveSportsType {
        const result = this.specialSportsResource.get();
        if (result.type === 'loading') {
            return 'loading';
        }
        if (this.sportsConfigActiveResourceData === 'loading') {
            return 'loading';
        }
        if (result.type === 'ready') {
            const temp = [];

            for (const value of result.value) {
                const isValue = this.sportsConfigActiveResourceData.some((elem) => elem.slug === value.slug);
                if (isValue === false) {
                    temp.push(value);
                }
            }

            return [...temp, ...this.sportsConfigActiveResourceData];
        }
        return [];
    }

    @computed public get activeSpecialSportsForView(): Array<ActiveSpecialSportsType> {
        if (this.activeSpecialSports === 'loading') {
            return [];
        }

        return this.activeSpecialSports.filter((elem) => {
            if (elem.sport_type !== 'new-sport') {
                return false;
            }

            const detailsParsed = SpecialSportsZOD.safeParse(elem.details);
            if (detailsParsed.success) {
                const evensInSport = this.eventsCollection.getByCompetitions(detailsParsed.data.competitionIds);
                return evensInSport.total > 0;
            }
            console.error('Validation error - Check special sports details');
            return false;
        });
    }

    @computed public get isLoading(): boolean {
        const result = this.specialSportsResource.get();
        return result.type === 'loading';
    }

    @computed public get allSpecialSports(): Array<ActiveSpecialSportsType> {
        if (this.activeSpecialSports === 'loading') {
            return [];
        }
        return this.activeSpecialSports;
    }
}
