import { action, autorun, computed, observable, makeObservable } from 'mobx';
import * as t from 'io-ts';
import { assertNever } from 'src_common/common/assertNever';
import { runAsync } from 'src_common/common/mobx-utils/runAsync';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { jsonParse } from 'src_common/common/jsonParse';
import { createGuard } from 'src_common/common/createGuard';
import { Session } from 'src_common/sdk/session';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { PopupState } from 'src/domains/layouts/state/popupState/PopupState';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import { getSessionData } from 'src/domains/casino/state/AppCasino/store/provider-store/Provider.store';
import { SessionConfigType } from 'src/domains/casino/types/types';
import { FullScreenState } from 'src/domains/casino/state/AppCasino/game-modal/FullScreen.state';
import { RedirectState } from 'src/domains/casino/state/AppCasino/Redirect.state';
import { GameStatusState } from 'src/domains/casino/state/AppCasino/GameStatus.state';
import { GameModalPopup, MultipleSlotsPopup } from 'src/domains/layouts/state/popupState/popupTypes';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';
import { GameDataType, GameDataState } from './GameData.state';

type ActionToRunType =
    | 'choiceResize'
    | 'choiceMyAccount'
    | 'choiceLogout'
    | 'choiceQuitGame'
    | 'updateBalance'
    | 'homeButton'
    | 'sendRelaxHandshake';
type SendActionToIframeType = 'updateBalance' | 'stopAutoPlay' | 'oprg_Ready';

export class GameModalState {
    @observable.ref private gameInner: GameDataState | null;
    @observable.ref private bingoMiniGameInner: GameDataState | null;
    @observable public isDepositOpened: boolean;

    private fullScreenState: FullScreenState;
    private iframeRef: Iframe | null = null;
    private bingoMiniGameIframeRef: Iframe | null = null;
    private gameStatusState: AutoMap<number, AutoMap<number, GameStatusState>>;

    public constructor(
        private popupState: PopupState,
        private config: ConfigState,
        private breakpointsState: BreakpointsState,
        private session: Session,
        private redirectState: RedirectState,
        private env: EnvironmentState
    ) {
        makeObservable(this);

        this.gameInner = null;
        this.bingoMiniGameInner = null;
        this.isDepositOpened = false;
        this.fullScreenState = new FullScreenState();

        this.gameStatusState = new AutoMap(
            (userId: number) =>
                new AutoMap((gameId: number) => {
                    return new GameStatusState(
                        config,
                        this.env.envVariables.websocket_casino_host,
                        gameId,
                        userId,
                        this.session,
                        this.redirectState,
                        this.validateSession
                    );
                })
        );
    }

    public get loadedGameStatusState(): GameStatusState | null {
        if (this.bingoMiniGameInner !== null && this.session.userId !== null) {
            return this.gameStatusState.get(this.session.userId).get(this.bingoMiniGameInner.id);
        }

        if (this.gameInner !== null && this.gameInner.gameType === 'casino' && this.session.userId !== null) {
            return this.gameStatusState.get(this.session.userId).get(this.gameInner.id);
        }

        return null;
    }

    public get showResizeButton(): boolean {
        const hideResizeButton = navigator.userAgent.includes('iPhone') || this.env.isMobileApp();
        return hideResizeButton === false;
    }

    @action public startGame = (gameData: GameDataType): void => {
        let sessionConfig: SessionConfigType;

        if (gameData.type === 'model') {
            if (gameData.gameModel.provider === null || gameData.gameModel.provider === 'Nsoft') {
                return console.error('getSession - no provider data');
            }

            sessionConfig = {
                provider: gameData.gameModel.provider,
                params: {
                    gameId: gameData.gameModel.launchGameId,
                    mode: gameData.mode === 'bingo-mini' ? 'mini' : gameData.mode,
                    mobile: this.breakpointsState.tablet.isBiggerOrEq === false,
                    homeAddress: this.redirectState.getHomePage(gameData.gameModel.collection),
                },
            };
        } else if (gameData.type === 'bingo') {
            sessionConfig = {
                provider: 'Pragmatic',
                params: {
                    gameId: '9999',
                    mobile: this.breakpointsState.tablet.isBiggerOrEq === false,
                    homeAddress: this.redirectState.getHomePage('casino'),
                },
            };
        } else {
            sessionConfig = {
                provider: 'Nsoft',
            };
        }

        if (gameData.type === 'model' && gameData.mode === 'bingo-mini') {
            this.bingoMiniGameInner = new GameDataState(gameData, sessionConfig, null); // TODO: change to map

            this.continueStartingGameBingoMini();
        } else {
            this.gameInner = new GameDataState(gameData, sessionConfig, null);
            this.continueStartingGame();
        }
    };

    @action private continueStartingGameBingoMini = (): void => {
        if (this.bingoMiniGameInner === null) {
            return;
        }

        this.bingoMiniGameInner.resource = getSessionData(this.session, this.bingoMiniGameInner, this.config);
        this.runTagManager(this.bingoMiniGameInner);
    };

    @action public continueStartingGame = (): void => {
        if (this.redirectState.goToLoginForm()) {
            return;
        }

        if (this.gameInner === null) {
            return;
        }

        this.gameInner.resource = getSessionData(this.session, this.gameInner, this.config);

        this.redirectState.redirectWithoutAccountParam();

        if (this.gameInner.isMini === false) {
            this.popupState.show(new GameModalPopup(() => this.isDepositOpened, this.gameInner.isMini));
        }
        this.runTagManager(this.gameInner);
    };

    @action private runTagManager = (gameInner: GameDataState): void => {
        const { id, isDemo, name, gameType } = gameInner;

        if (isDemo === false) {
            autorun((dispose) => {
                const gameModel = this.gameInner;

                if (gameModel === null) {
                    return;
                }

                dispose.dispose();
                this.redirectState.onGoogleTagManagerGamePlayTag({
                    gameId: id,
                    gameName: name,
                    gameType,
                });
            });
        }
    };

    @action public startLucky6Game = (): void => {
        this.startGame({
            type: 'lucky6',
        });
    };

    @action public startBingoGame = (): void => {
        this.startGame({
            type: 'bingo',
        });
    };

    @action private resetRealityCheckTimerOnClose = (): void => {
        if (this.gameInner?.isMini !== true) {
            this.redirectState.resetRealityCheckTime();
        }
    };

    @action public closeGame = (): void => {
        if (this.gameInner?.gameType === 'bingo') {
            this.redirectState.redirectToCasinoPage();
        }
        this.resetRealityCheckTimerOnClose();
        this.loadedGameStatusState?.closeSocket();
        this.popupState.hide();
        this.gameInner = null;
        this.bingoMiniGameInner = null;
        this.fullScreenState.clearFullScreen();
    };

    @action public closeBingoMiniGame = (): void => {
        this.loadedGameStatusState?.closeSocket();
        this.bingoMiniGameInner = null;
    };

    @action public closeGameAndRedirectToHomePage = (): void => {
        this.closeGame();
        this.redirectState.redirectToHomepage();
    };

    @action private reloadGame = (): void => {
        console.info('Start reloading game');

        runAsync(async (): Promise<void> => {
            await this.gameInner?.clearResourceAndWait();
            this.continueStartingGame();

            console.info('End reloading game');
        });
    };

    @computed public get isFullScreen(): boolean {
        return this.fullScreenState.isFullScreen;
    }

    @action public toggleFullScreen = (): void => {
        if (this.gameInner?.isMini === true && this.isFullScreen === false) {
            this.fullScreenState.isFullScreen = true;
            this.popupState.show(new GameModalPopup(() => this.isDepositOpened, this.gameInner.isMini));
            return;
        }

        if (this.gameInner?.isMini === true && this.isFullScreen === true) {
            this.fullScreenState.isFullScreen = false;
            this.popupState.hide();
            return;
        }

        if (this.gameInner?.isMini === false) {
            this.fullScreenState.toggleFullscreen();
        }
    };

    @action private forceCloseFullScreen = (): void => {
        if (this.gameInner?.isMini === true) {
            return;
        }

        if (this.isFullScreen) {
            this.fullScreenState.toggleFullscreen();
        }
    };

    @action public setRef = (ref: HTMLElement | null): void => {
        this.fullScreenState.setRef(ref);
    };

    @action public onDepositOpen = (): void => {
        if (this.gameInner === null) {
            return;
        }

        this.forceCloseFullScreen();

        setTimeout(() => {
            // Prevents betslip blink on animation start
            this.isDepositOpened = true;
        }, 200);

        this.sendMessageToIframe('stopAutoPlay');

        if (this.bingoMiniGameInner !== null) {
            this.sendMessageToBingoMiniGameIframe('stopAutoPlay');
        }
    };

    @action public onDepositClose = (): void => {
        if (this.gameInner === null) {
            return;
        }

        this.isDepositOpened = false;
        this.sendMessageToIframe('updateBalance');

        if (this.bingoMiniGameInner !== null) {
            this.sendMessageToBingoMiniGameIframe('updateBalance');
        }

        if (this.shouldReloadAfterDeposit) {
            this.reloadGame();
        }
    };

    private get shouldReloadAfterDeposit(): boolean {
        if (this.gameInner === null) {
            return false;
        }
        return this.config.commonConfig.reloadGameAfterDepositForProviders.includes(this.gameInner.provider);
    }

    // IFRAMES AND POST MESSAGES

    public setIframeRef = (ref: Iframe | null): void => {
        this.iframeRef = ref;
    };

    public setBingoMiniGameIframeRef = (ref: Iframe | null): void => {
        this.bingoMiniGameIframeRef = ref;
    };

    public sendMessageToIframe = (action: SendActionToIframeType): void => {
        if (action === 'stopAutoPlay') {
            if (this.gameInner?.provider === 'Pragmatic') {
                this.iframeRef?.ref?.contentWindow?.postMessage('{"type":"Tilt"}', '*');

                console.info('stopAutoPlay sent to Pragmatic');
                return;
            }

            console.warn('stopAutoPlay not available for ', this.gameInner?.provider);
            return;
        }

        if (action === 'updateBalance') {
            if (this.gameInner?.provider === 'Pragmatic') {
                this.iframeRef?.ref?.contentWindow?.postMessage('updateBalance', '*');

                console.info('updateBalance sent to Pragmatic');
                return;
            }

            console.warn('updateBalance not available for ', this.gameInner?.provider);
            return;
        }

        if (action === 'oprg_Ready') {
            if (this.gameInner?.provider === 'Relax') {
                const readyMessage = {
                    rgMessage: 'oprg_Ready',
                };
                this.iframeRef?.ref?.contentWindow?.postMessage(readyMessage, '*');
                console.info('postMessage handshake sent to Relax');
                return;
            }

            console.warn('postMessage handshake not available for ', this.gameInner?.provider);
            return;
        }

        return assertNever('sendMessageToIframe', action);
    };

    public sendMessageToBingoMiniGameIframe = (action: SendActionToIframeType): void => {
        if (action === 'updateBalance') {
            if (this.bingoMiniGameInner !== null) {
                this.bingoMiniGameIframeRef?.ref?.contentWindow?.postMessage('updateBalance', '*');

                console.info('updateBalance sent to Bingo mini game');
                return;
            }

            console.warn('updateBalance not available for bingo mini games');
            return;
        }
    };

    private createActionToRunForIframeMessage = (message: string): ActionToRunType | null => {
        const JsonMessageIO = t.interface({
            msg_type: t.literal('rc_choice'),
            msg: t.string,
        });

        const actionForMessageMap = new Map<ActionToRunType, Array<string>>([
            ['choiceResize', ['pplive_enter_fs', 'pplive_exit_fs', 'enter_fs_pp', 'exit_fs_pp']],
            ['choiceMyAccount', ['gprg_UserAction_showTransactionHistory']],
            ['choiceLogout', []],
            [
                'choiceQuitGame',
                ['gameQuit', 'redirect', 'RC_QUIT', 'rcQuit', 'notifyCloseContainer', 'gprg_UserAction_closeGame'],
            ],
            ['updateBalance', ['updateBalance', 'post_updateBalance', 'balanceChanged']],
            ['homeButton', ['homeButton']],
            ['sendRelaxHandshake', ['gprg_Listening']],
        ]);

        const isJsonMessage = createGuard(JsonMessageIO);
        const data = jsonParse(message);

        if (data.type === 'json') {
            const json = data.json;

            if (isJsonMessage(json)) {
                if (json.msg_type === 'rc_choice' && json.msg === 'myAccount') {
                    return 'choiceMyAccount';
                }

                if (json.msg_type === 'rc_choice' && json.msg === 'logout') {
                    return 'choiceLogout';
                }

                console.warn('Message from iframe (json) - unhandled command --> ', message);
            } else {
                console.warn('Message from iframe - decode problem --> ', message);
            }
        } else {
            for (const [action, assignedMessages] of actionForMessageMap.entries()) {
                if (assignedMessages.includes(message)) {
                    return action;
                }
            }
            console.info('Message from iframe (text) - unhandled command --> ', message);
        }

        return null;
    };

    @action public onIframeMessage = (message: string): void => {
        const action = this.createActionToRunForIframeMessage(message);

        if (action === null) {
            return;
        }

        if (action === 'choiceResize') {
            this.toggleFullScreen();
            return;
        }

        if (action === 'choiceMyAccount') {
            this.closeGame();
            this.redirectState.redirectToTransactionHistory();
            return;
        }

        if (action === 'choiceLogout') {
            this.closeGame();
            return;
            // accountState.handleLogout().catch((error) => {
            //     console.error(error);
            // });
        }

        if (action === 'choiceQuitGame') {
            this.closeGame();
            return;
        }

        if (action === 'updateBalance') {
            this.sendMessageToBingoMiniGameIframe('updateBalance');
            return;
        }

        if (action === 'homeButton') {
            this.closeGame();
            this.redirectState.redirectToCasinoPage();
            return;
        }

        if (action === 'sendRelaxHandshake') {
            this.sendMessageToIframe('oprg_Ready');
            return;
        }

        return assertNever('actionToRun', action);
    };

    @action public onBingoMiniGameIframeMessage = (message: string): void => {
        const action = this.createActionToRunForIframeMessage(message);

        if (action === null) {
            return;
        }

        if (action === 'choiceResize') {
            this.toggleFullScreen();
            return;
        }

        if (action === 'choiceMyAccount') {
            this.closeGame();
            this.redirectState.redirectToTransactionHistory();
            return;
        }

        if (action === 'choiceLogout') {
            this.closeGame();
            return;
        }

        if (action === 'choiceQuitGame') {
            this.closeGame();
            return;
        }

        if (action === 'updateBalance') {
            this.sendMessageToIframe('updateBalance');
            return;
        }

        if (action === 'homeButton') {
            this.closeGame();
            this.redirectState.redirectToCasinoPage();
            return;
        }

        if (action === 'sendRelaxHandshake') {
            this.sendMessageToIframe('oprg_Ready');
            return;
        }

        return assertNever('actionToRun', action);
    };

    public validateSession = (launchedGame?: number): void => {
        const currentGame = this.bingoMiniGameInner?.id ?? this.gameInner?.id;

        if (currentGame !== launchedGame) {
            this.closeGame();
            this.popupState.show(new MultipleSlotsPopup());
        }
    };

    @computed private get modal():
        | { type: 'regular'; model: GameDataState }
        | { type: 'mini'; model: GameDataState }
        | null {
        if (this.gameInner === null) {
            return null;
        }

        if (this.redirectState.getIsUserAutorized() === false) {
            return null;
        }

        if (this.gameInner.isMini === true) {
            return {
                type: 'mini',
                model: this.gameInner,
            };
        }

        return {
            type: 'regular',
            model: this.gameInner,
        };
    }

    @computed public get miniGameModel(): GameDataState | null {
        if (this.modal?.type === 'mini' && this.isFullScreen === false) {
            return this.modal.model;
        }

        return null;
    }

    @computed public get bingoMiniGameModel(): GameDataState | null {
        // ensures us that will be opened only if bingo iframe is launched
        if (this.modal?.model.gameType === 'bingo') {
            return this.bingoMiniGameInner;
        }

        return null;
    }

    @computed public get regularGameModel(): GameDataState | null {
        if (this.modal?.type === 'regular' || (this.modal?.type === 'mini' && this.isFullScreen === true)) {
            return this.modal.model;
        }

        return null;
    }

    @computed public get realityCheckPopupForCasino(): boolean {
        if (this.gameInner?.gameType === 'bingo') {
            return false;
        } else if (
            this.redirectState.currentView === 'casino' ||
            this.redirectState.currentView === 'live-casino' ||
            this.redirectState.currentView === 'virtuals'
        ) {
            return false;
        }
        return true;
    }
}
