import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';

const addZeroToNumber = (value: number): string => {
    if (value < 10) {
        return `0${value}`;
    }

    return value.toString();
};

const getTime = (): number => {
    const currentTimeDate = new Date();
    return currentTimeDate.getTime();
};

class ConnectWrapperTimer {
    public connect(self: MobxValue<number>): NodeJS.Timeout {
        self.setValue(getTime());

        const timer  = setInterval((): void => {
            self.setValue(getTime());
        }, 1000);
        return timer;
    }

    public dispose(timer: NodeJS.Timeout): void {
        clearInterval(timer);
    }
}

const currentTime: MobxValue<number> = MobxValue.create({
    initValue: getTime(),
    connect: new ConnectWrapperTimer(),
});

export const renderTimer = (time: number): string => {
    const startTime = new Date(time).getTime();

    const currentDate = new Date(currentTime.getValue()).getTime();

    let seconds = Math.floor((startTime - (currentDate)) / 1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    const days = Math.floor(hours/24);

    hours = hours - (days*24);
    minutes = minutes - (days*24*60) - (hours*60);
    seconds = seconds - (days*24*60*60) - (hours*60*60) - (minutes*60);

    if (startTime < currentDate) {
        return '00:00:00';
    }

    if (days >= 2) {
        return `${days} Days Left`;
    }

    if (days === 1) {
        return `${hours + 24} Hours Left`;
    }

    if (hours === 0 && minutes === 0 && seconds === 0) {
        return '00:00:00';
    } 

    return `${addZeroToNumber(hours)}:${addZeroToNumber(minutes)}:${addZeroToNumber(seconds)}`;
};
