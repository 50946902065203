import React from 'react';

import { Trap } from 'src/domains/sportsbook/webview/components/trap/Trap';

import EMPTY_SILK from 'src/domains/layouts/shared/assets/silk/empty-gh-silk.webp';
import { observer } from 'src/utils/mobx-react';
import { RunnerCell, RaceSummaryRow, SilkCell } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    className?: string;
    place?: React.ReactNode;
    runner?: React.ReactNode;
    oneCast?: React.ReactNode;
    twoCast?: React.ReactNode;
    threeCast?: React.ReactNode;
    anyCast?: React.ReactNode;
    selectionId: SelectionId;
    isUnnamedFavourite: boolean;
    isNonRunner?: boolean;
    dataTest?: string;
}

export const RaceSummaryCastRowGreyhoundRacing = observer('RaceSummaryCastRowGreyhoundRacing', (props: PropsType) => {
    const { className, isUnnamedFavourite, runner, oneCast, twoCast, threeCast, anyCast, selectionId, isNonRunner, dataTest } = props;

    const selectionModel = selectionId.getModel();
    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    if (isUnnamedFavourite === false) {
        return (
            <RaceSummaryRow className={className} isNonRunner={isNonRunner} data-test={dataTest}>
                <SilkCell className='cast'>
                    <Trap
                        number={selectionModel.metaDataNumber}
                        placeholder={EMPTY_SILK}
                        selectionId={selectionModel.id2}
                    />
                </SilkCell>

                <RunnerCell>{ runner }</RunnerCell>
                { oneCast }
                { twoCast }
                { marketModel.tricastsOffered && threeCast }
                { anyCast }
            </RaceSummaryRow>
        );
    } else {
        return null;
    }
});
