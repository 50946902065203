import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

const checkboxStyled = (theme: EmotionTheme, isChecked: boolean, mode: 'dark' | 'light'): string | undefined => {
    if (mode === 'light') {
        if (isChecked) {
            return `
                background-color: ${theme.star.checkbox.light.bgColorSecondary};
                border-color: ${theme.star.checkbox.light.borderColorSecondary};
            `;
        }
        return `
            background-color: ${theme.star.checkbox.light.bgColor};
            border-color: ${theme.star.checkbox.light.borderColor};
        `;
    }

    if (mode === 'dark') {
        if (isChecked) {
            return `
                background-color: ${theme.star.checkbox.dark.bgColorSecondary};
                border-color: ${theme.star.checkbox.dark.borderColorSecondary};
            `;
        }
        return `
            background-color: ${theme.star.checkbox.dark.bgColor};
            border-color: ${theme.star.checkbox.dark.borderColor};
        `;
    }
};

const setCheckboxSize = (size?: string): string | undefined => {
    if (size !== undefined) {
        return `
            flex: 0 0 ${size};
            height: ${size};
            width: ${size};
        `;
    }

    return `
        flex: 0 0 20px;
        height: 20px;
        width: 20px;
    `;
};

const checkIsDisable = (disabled?: boolean): string | null => {
    if (disabled !== undefined && disabled) {
        return `
            opacity: .3;
            cursor: not-allowed;
        `;
    }

    return null;
};

const labelPosition = (labelSide?: 'left' | 'right'): string => {
    if (labelSide !== undefined && labelSide === 'left') {
        return `
            margin-right: 8px;
            text-align: right;
        `;
    }
    return `
        margin-left: 8px;
        text-align: left;
    `;
};

export const CheckboxLabel = withConfig(theme => styled('label', { label: 'CheckboxLabel' })`
    align-items: center;
    display: inline-flex;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    position: relative;
`);

export const CheckboxInput = styled('input', { label: 'CheckboxInput' })`
    display: none;
`;

interface CheckboxWrapperType {
    size?: string;
    disabled?: boolean;
    isChecked: boolean;
    mode: 'dark' | 'light';
}

export const CheckboxWrapper = withConfig(theme => styled('span', { label: 'CheckboxWrapper' })<CheckboxWrapperType>`
    align-self: flex-start;
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    margin: auto;
    position: relative;
    ${({ disabled }): string | null => checkIsDisable(disabled)};
    ${({ size }): string | undefined => setCheckboxSize(size)};
    ${({ isChecked, mode }): string | undefined => checkboxStyled(theme, isChecked, mode)}
    &::before {
        background: ${({ isChecked }): string => isChecked ? theme.star.checkbox.light.bgColorSecondary : theme.star.checkbox.light.bgColor};
        bottom: 0;
        content: '';
        left: 0;
        opacity: ${({ isChecked }): string => isChecked ? '1' : '0'};
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .2s ease;
    }
`);

interface CheckMarkWrapperType {
    isChecked: boolean;
}

export const CheckMarkWrapper = withConfig(theme => styled(CheckSingleIcon, { label: 'CheckMarkWrapper' })<CheckMarkWrapperType>`
    ${({ isChecked }): string => isChecked ? 'transform: scale(1)' : 'transform: scale(0)'};
    bottom: 0;
    fill: ${theme.star.checkbox.bgColor};
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform .2s ease;
    width: calc(100% - 3px);
`);

interface CheckboxTxtWrapperType {
    labelSide?: 'left' | 'right';
}

export const CheckboxTxtWrapper = styled('span', { label: 'CheckboxTxtWrapper' })<CheckboxTxtWrapperType>`
    user-select: none;
    ${({ labelSide }): string | undefined => labelPosition(labelSide)};
`;
