import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { UniverseComponent } from 'src/domains/common/universes';
import { LoadingPageWrapper } from './LoadingPage.style';

export const LoadingPage = observer('LoadingPage', () => {
    return (
        <LoadingPageWrapper>
            <UniverseComponent>
                {(universeVersion): React.ReactElement => (
                    <universeVersion.headerLink dataTest='operator-logo'>
                        <universeVersion.signUpLogo />
                    </universeVersion.headerLink>
                )}
            </UniverseComponent>
        </LoadingPageWrapper>
    );
});
