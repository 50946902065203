import styled from '@emotion/styled';
import { GreyhoundRacingIcon } from 'src/domains/layouts/shared/assets/icons/GreyhoundRacingIcon';
import { HorseRacingIcon } from 'src/domains/layouts/shared/assets/icons/HorseRacingIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const NextOffToggleWrapper = withConfig(theme => styled('div', { label: 'NextOffToggleWrapper' })`
    display: flex;
    position: relative;
    padding: 0 16px 0 0;
    &::before {
        background-color: ${theme.star.racingNextOff.toggle.bgColor};
        bottom: 0;
        content: '';
        height: 100%;
        left: 100%;
        position: absolute;
        top: -4px;
        width: 1px;
    }
`);

interface PropsType {
    isSelected: boolean;
}

export const NextOffRadioWrapper = withConfig(theme => styled('div', { label: 'NextOffRadioWrapper' })<PropsType>`
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 0 2px 25px;
    margin-left: 16px;
    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
    }
    &::before {
        border: 2px solid ${theme.star.racingNextOff.toggle.borderColor};
        width: 20px;
        height: 20px;
    }
    &::after {
        background: ${theme.star.racingNextOff.toggle.bgColorSecondary};
        opacity: ${({ isSelected }): string => isSelected ? '1' : '0'};
        width: 12px;
        height: 12px;
        top: 4px;
        left: 4px;
    }
`);

const iconStyled = (theme: EmotionTheme): string => {
    return `
        fill: ${theme.star.racingNextOff.toggle.bgColorSecondary};
        position: relative;
        top: -3px;
    `;
};

export const HorseRacingIconWrapper = withConfig(theme => styled(HorseRacingIcon, { label: 'HorseRacingIconWrapper' })`
    ${iconStyled(theme)};
`);

export const GreyhoundRacingIconWrapper = withConfig(theme => styled(GreyhoundRacingIcon, { label: 'GreyhoundRacingIconWrapper' })`
    ${iconStyled(theme)};
`);
