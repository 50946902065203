import { fetchGeneral } from 'src_common/common/fetch';

export const insertIncomeAccessPixelWrapper = async (accountId: number, type: 'login' | 'registration'): Promise<void> => {
    // tslint:disable-next-line
    if (window !== undefined) {
        //@ts-expect-error
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {

            console.info('Send after login - iovation');

            //@ts-expect-error
            const { blackbox } = window.IGLOO.getBlackbox();

            await fetchGeneral('POST', {
                url: '/api/iovation',
                body: {
                    id: accountId,
                    blackbox,
                    type
                },
                timeout: 'default'
            });
        }
    }
};
