import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { UniverseType } from 'src_common/common/universe';
import { useCommon } from './Common';

interface PropsType {
    children: (universe: UniverseType) => React.ReactElement;
}

/**
 * @deprecated - please use UniverseModule --> src/domains/common/universes.tsx
 */
export const UniverseVersion = observer('UniverseVersion', (props: PropsType) => {
    const common = useCommon();
    const { children } = props;
    return children(common.envVariables.universe);
});
