import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const PolicyContent = withConfig(theme => styled('div', { label: 'PolicyContent' })`
    height: 100%;
    line-height: 1.6;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 16px;
    word-break: break-word;
    h6, h5, h4, h3, h2, h1 {
        color: ${theme.star.mcbookieResetPassword.txtColor};
        font-weight: ${theme.star.fontWeight.medium};
        line-height: 1.3;
        margin: 0 0 5px 0;
        padding: 0;
    }
    p {
        line-height: 1.6;
        margin-top: 0;
    }
    ul {
        padding: 5px 0;
    }
    ul > li {
        margin-bottom: 10px;
        padding-left: 20px;
        position: relative;
    }
    ul > li::before {
        border-radius: 50%;
        border: 1px solid ${theme.star.mcbookieResetPassword.borderColorSecondary};
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        top: 5px;
        width: 10px;
    }
`);

export const HelpBoxTitle = withConfig(theme => styled('h3', { label: 'HelpBoxTitle' })`
    color: ${theme.star.mcbookieResetPassword.txtColorSecondary};
    flex: 1 1 auto;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    text-transform: uppercase;
`);

export const HelpBoxSubHeader = withConfig(theme => styled('header', { label: 'HelpBoxSubHeader' })`
    align-items: center;
    background-color: ${theme.star.mcbookieResetPassword.bgColor};
    display: flex;
    font-size: ${theme.star.fontSize.regular};
    height: 40px;
    justify-content: space-between;
    line-height: 1.3;
    min-height: 40px;
    padding: 0;
    position: relative;
    vertical-align: middle;
`);

export const ArrowBack = withConfig(theme => styled(ChevronIcon, { label: 'ArrowBack' })`
    background: transparent;
    border-radius: 50%;
    border: 1px solid ${theme.star.mcbookieResetPassword.borderColor};
    cursor: pointer;
    fill: ${theme.star.mcbookieResetPassword.bgColorSecondary};
    height: 24px;
    margin: 0 8px !important;
    order: -1;
    padding: 4px;
    width: 24px;
`);
