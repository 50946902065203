import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { computed } from 'mobx';
import { ConfigType } from 'src/domains/layouts/config/features/types';

export class TermsAndConditionsCheckboxState {
    public readonly termsAndConditions: FormInputState<boolean, boolean>;
    public readonly privacyPolicy: FormInputState<boolean, boolean>;

    public constructor(private readonly config: ConfigType) {
        this.termsAndConditions = FormInputState.new(false).map((value) => {
            return Result.createOk(value);
        });
        this.privacyPolicy = FormInputState.new(false).map((value) => {
            return Result.createOk(value);
        });
    }

    @computed public get checkBoxesTermsAndConditions(): boolean {
        return (this.termsAndConditions.value && this.privacyPolicy.value) || this.config.signUpCheckboxTC === false;
    }
}
