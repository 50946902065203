import * as t from 'io-ts';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import { TypeId } from 'src_common/common/websocket2/type';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';

const EventMarketItemIO = t.interface({
    id: t.number,
    state: t.string,
    display: t.boolean,
    active: t.boolean,
    createdAt: t.number,
    eventId: t.number,
    templateId: t.string,
    templateName: t.string,
    name: t.string,
    displayOrder: t.number,
    keyLine: t.union([t.number, t.null, t.undefined]),
    tags: t.record(t.string, t.string),
});

const EventMarketsIO = t.array(EventMarketItemIO);

export const decodeEventMarkets = buildValidator('decodeEventMarkets', EventMarketsIO);

export type EventMarketItemApiType = t.TypeOf<typeof EventMarketItemIO>;

export type EventMarketItemType = Omit<EventMarketItemApiType, 'id'> & {
    id: TypeId.MarketId;
    id2: MarketId;
};

// export interface EventMarketItemType {
//     id: number,
//     templateId: string,
//     websiteMain: boolean,
//     raceWinner: boolean,
//     antePost: boolean
// }
