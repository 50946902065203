import { computed, makeObservable } from 'src_common/common/mobx-wrapper';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { DateTime } from 'src_common/utils/time/time';
import { distance as formatDistance } from 'src/domains/sportsbook/utils/format';
import { RacecardRaceRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RaceBoxPropsType } from './RaceBox';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { AppSportsBookState } from 'src/domains/sportsbook/state/AppSportsBook.state';

export class RaceBoxState {
    public constructor(
        private readonly props: RaceBoxPropsType,
        private readonly config: ConfigType,
        private readonly appSportsBookState: AppSportsBookState
    ) {
        makeObservable(this);
    }

    @computed private get eventModel(): EventModel | null {
        return this.props.race;
    }

    @computed private get marketModel(): MarketModel | null {
        return this.props.market;
    }

    @computed public get name(): string | undefined {
        if (this.eventModel === null) {
            return undefined;
        }

        const nameFragments = /^(\d{2}:\d{2}) (.*)/.exec(this.eventModel.name);
        return nameFragments?.pop();
    }

    @computed public get time(): string | undefined {
        if (this.eventModel === null) {
            return undefined;
        }

        return DateTime.from(this.eventModel.timeSettingsStartTime)?.format('HH:mm');
    }

    @computed public get distance(): string | undefined {
        return this.eventModel?.feedDistance === undefined ? undefined : formatDistance(this.eventModel.feedDistance);
    }

    @computed public get sport(): string | undefined {
        return this.eventModel?.sport;
    }

    @computed public get routingData(): RacecardRaceRouteType | undefined {
        if (this.eventModel === null || this.sport === undefined) {
            return undefined;
        }
        return {
            name: 'racecard',
            collection: this.eventModel.competition,
            sport: this.sport,
            selected: this.props.race.id,
        };
    }

    @computed private get prepareDisplayOrder(): string | undefined {
        if (this.eventModel === null) {
            return undefined;
        }
        const tagsDisplayOrder = this.eventModel.tagsDisplayOrder;

        if (tagsDisplayOrder !== undefined && tagsDisplayOrder !== '-') {
            return tagsDisplayOrder;
        }
        return this.eventModel.sport === 'horseracing' ? 'by-price' : 'by-creation';
    }

    @computed public get itemsForView(): SelectionModel[] | undefined {
        if (this.marketModel === null || this.eventModel === null) {
            return undefined;
        }
        const sortFromInterface = this.props
            .sortSelections(this.marketModel.selections)
            .filter((x) => x.resultType !== 'void' && x.display)
            .slice(0, this.props.items);

        if (this.eventModel.sport === 'greyhoundracing') {
            return sortFromInterface;
        }

        return sortSelectionsByCriteria(sortFromInterface, this.prepareDisplayOrder);
    }

    @computed public get hasStream(): boolean {
        return (
            this.config.hasEventLiveStreaming && this.appSportsBookState.streamingState.hasStream(this.props.race.id)
        );
    }
}
