import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';

export interface JackpotForGameType {
    id: string;
    name: string;
    amount: string;
}

const JackpotDetailsIO = t.interface({
    id: t.string,
    name: t.string,
    amount: t.string,
    games: t.array(t.number),
});

const JackpotsListIO = t.interface({
    jackpots: t.array(JackpotDetailsIO),
});

export type JackpotsListType = t.TypeOf<typeof JackpotsListIO>;
export const isJackpotsListModel = createGuard(JackpotsListIO);
