import React from 'react';
import {
    CheckIcon,
    PrimaryDescription,
    Title,
    Wrapper,
    AccountHelperContainer,
    LinkButton,
} from 'src/domains/players/webview/components/Account/forgotPasswordTab/resetPasswordSent/ResetPasswordSent.style';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import { AlignBottomWrapper } from 'src/domains/players/webview/components/Account/forgotPasswordTab/ForgotPasswordTab.style';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';

interface ResetPasswordSentPropsType {
    goBackToForgotPassword: () => void;
}

export const ResetPasswordSent = observer(
    'ResetPasswordSent',
    ({ goBackToForgotPassword }: ResetPasswordSentPropsType) => {
        const { appLayoutsState } = useAppStateContext();
        const { starRouter } = appLayoutsState;

        const redirectToLogin = (): void => {
            if (starRouter.currentView?.name === 'forgot-password') {
                starRouter.redirectToLoginHomepage();
            } else {
                starRouter.redirectToLogin();
            }
        };

        return (
            <AccountHelperContainer>
                <Wrapper>
                    <CheckIcon />
                    <Title data-test='password-recovery-link-sent-title'>
                        <I18n
                            langKey='account.reset-password-sent.title'
                            defaultText='Password recovery link Sent'
                        />
                    </Title>
                    <PrimaryDescription data-test='password-recovery-primary-description'>
                        <I18n
                            langKey='account.reset-password-sent.primary-desc'
                            defaultText='We have sent you an email with instructions and a link on how to reset your password'
                        />
                    </PrimaryDescription>
                    <LinkButton
                        onClick={redirectToLogin}
                        size='large'
                        dataTest='back-to-login-button'
                    >
                        <I18n
                            langKey='account.reset-password-sent.login-link'
                            defaultText='Back to Login'
                        />
                    </LinkButton>
                    <LinkButton
                        onClick={goBackToForgotPassword}
                        version={starRouter.currentView?.name === 'forgot-password' ? 'secondary' : 'secondary-light'}
                        size='large'
                        dataTest='resend-email-button'
                    >
                        <I18n
                            langKey='account.reset-password-sent.resend-link'
                            defaultText='Resend Email'
                        />
                    </LinkButton>
                </Wrapper>
                <AlignBottomWrapper>
                    <AccountHelperInfo />
                </AlignBottomWrapper>
            </AccountHelperContainer>
        );
    }
);
