import { autorun } from 'mobx';
import { AppState } from 'src/appState/AppState';

const TIMEOUT = 1000;

export const debounce = (callbackOriginal: () => void): (() => void) => {

    let timer: NodeJS.Timeout | null = null;

    return (): void => {
        if (timer !== null) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            callbackOriginal();
            timer = null;
        }, TIMEOUT);
    };
};

export const initWebsocketV1 = (appState: AppState): void => {
    const refreshInfoAboutAccount = (): void => {
        appState.appPlayersState.accountState.account?.refreshAll();
    };

    const subscriptionForUser: Set<number> = new Set();

    const runSubscriptionForUser = (userId: number): void => {
        if (subscriptionForUser.has(userId)) {
            return;
        }
        subscriptionForUser.add(userId);

        console.info(`Run subscribe to websocketV1 for id ${userId}`);

        appState.appLayoutsState.websocketV1.subscribe(`${userId}:Wallet`, debounce(() => {
            refreshInfoAboutAccount();
        }));

        appState.appLayoutsState.websocketV1.subscribe(`${userId}:Bets`, debounce(() => {
            refreshInfoAboutAccount();
        }));

    };

    autorun(() => {
        const { userId } = appState.sdkCustomer.session;
        if (userId !== null) {
            runSubscriptionForUser(userId);
        }
    });
};
