import { z } from 'zod';

export const InputSchema = z.object({
    body: z.object({ token: z.string(),
    })
});

export type CheckAccountStatusInput = z.infer<typeof InputSchema>

export const SuccessResponseSchema = z.object({
    ageVerification: z.string(),
    kycStatus: z.string(),
    status: z.string(),
});


type SuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    error: z.object({
        code: z.string(),
        message: z.string()
    })
});

type ErrorResponse = z.infer<typeof ErrorResponseSchema>;

export type TResponseType =
    { responseStatus: 'success'; data: SuccessResponse } |
    { responseStatus: 'error'; data: ErrorResponse }
