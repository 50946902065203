import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';

const MAX_SELECTION_IDENTIFIERS = 8;

const tryMatchFlavorlessTemplateAsMatchWinner = (market: MarketModel): [string, DictionarySelection] | null => {
    const eventName = market.eventModel?.name;

    if (eventName === undefined) {
        return null;
    }

    const chunks = eventName.trim().split('vs');

    const [homeChunk, awayChunk, ...rest] = chunks;

    if (homeChunk === undefined || awayChunk === undefined || rest.length > 0) {
        return null;
    }

    const home = homeChunk.trim().toLowerCase();
    const away = awayChunk.trim().toLowerCase();

    const homeSelection = market.selections.find((item) => item.name.toLowerCase().trim() === home);
    const drawSelection = market.selections.find((item) => item.name.toLowerCase().trim() === 'draw');
    const awaySelection = market.selections.find((item) => item.name.toLowerCase().trim() === away);

    const dict = new Map();

    if (homeSelection !== undefined) {
        dict.set('H', homeSelection);
    }

    if (drawSelection !== undefined) {
        dict.set('D', drawSelection);
    }

    if (awaySelection !== undefined) {
        dict.set('A', awaySelection);
    }

    if (dict.size > 0) {
        return ['match-winner', dict];
    }

    return null;
};

export type DictionarySelection = Map<string, SelectionModel>;
export type DictionaryMarket = Map<string, [MarketModel, DictionarySelection]>;
export type DictionaryEvents = Map<number, DictionaryMarket>;

const buildDictionarySelection = (market: MarketModel): [string, DictionarySelection] | null => {

    const markeTemplateId = market.templateId;

    if (markeTemplateId === 'flavorless-template') {
        const result = tryMatchFlavorlessTemplateAsMatchWinner(market);

        if (result !== null) {
            return result;
        }
    }

    const result: DictionarySelection = new Map();

    for (const selection of market.selections) {
        const selectionTemplateId = selection.selectionIdentifiers;

        if (selectionTemplateId !== undefined) {
            if (selectionTemplateId.length <= MAX_SELECTION_IDENTIFIERS) {
                result.set(selectionTemplateId, selection);
            }
        }
    }

    if (result.size > 0) {
        return [markeTemplateId, result];
    }

    return null;
};

const buildDictionaryMarket = (event: EventListGroupEventItemType): DictionaryMarket | null => {
    const eventModel = event.id2.getEventModel();

    if (eventModel === null) {
        return null;
    }

    const resultMarkets: DictionaryMarket = new Map();

    for (const smallMarket of eventModel.marketsWithWebsiteMain) {
        const marketModel = smallMarket.id2.getModel();

        if (marketModel === null || !marketModel.displayed) {
            continue;
        }

        const marketIds = buildDictionarySelection(marketModel);

        if (marketIds === null) {
            continue;
        }

        const [marketTemplateId, selectionsIds] = marketIds;

        resultMarkets.set(marketTemplateId, [marketModel, selectionsIds]);
    }

    return resultMarkets;
};

export const buildDictionaryEvents = (eventsModel: Array<EventListGroupEventItemType>): DictionaryEvents => {
    const result: DictionaryEvents = new Map();

    for (const event of eventsModel) {
        const dictMarket = buildDictionaryMarket(event);

        if (dictMarket !== null) {
            result.set(event.id, dictMarket);
        }
    }

    return result;
};
