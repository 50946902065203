import * as React from 'react';
import { BetslipActualScore } from './EventScore.style';
import { observer } from 'src/utils/mobx-react';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    eventId: EventId;
}

export const EventScore = observer('EventScore', ({ eventId }: PropsType): JSX.Element | null => {
    const event = eventId.getEventModel();

    if (event !== null) {
        const scoreHome = event.scoreHome;
        const scoreAway = event.scoreAway;

        if (scoreHome !== null && scoreAway !== null && event.sport !== 'tennis') {
            return (
                <BetslipActualScore>
                    {scoreHome}-{scoreAway}
                </BetslipActualScore>
            );
        }
    }

    return null;
});
