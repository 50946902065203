import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from './appState/AppState';
import { EmotionTheme } from './domains/layouts/config/emotionTheme';

export interface ConfigType {
    star: EmotionTheme['star'];
}

type ComponentType<PropsType> = (props: PropsType) => React.ReactElement | null;

const computedWithCache = <P, R>(computedResult: (params: P) => R): ((params: P) => R) => {
    let cache: null | [P, R] = null;

    return (params: P): R => {
        if (cache === null) {
            const result = computedResult(params);
            cache = [params, result];
            return result;
        }

        const [oldParams, oldResult] = cache;

        if (oldParams === params) {
            return oldResult;
        }

        const result = computedResult(params);
        cache = [params, result];
        return result;
    };
};

export const withConfig = <PropsType extends {}>(
    buildComponent: (c: ConfigType) => ComponentType<PropsType>
): ComponentType<PropsType> => {
    // const getConfig = computedWithCache((theme: ConfigType): ConfigType => ({
    //     star: theme.star
    // }));
    const getComponent = computedWithCache(buildComponent);

    const WithConfig = observer(
        'WithConfig',
        (props: PropsType & { forwardedRef: React.ForwardedRef<unknown> }): React.ReactElement => {
            const appState = useAppStateContext();

            const theme = appState.appLayoutsState.env.emotionTheme;
            // const config = getConfig(theme);
            const Component = getComponent(theme);

            const { forwardedRef, ...propsInner } = props;

            return (
                //@ts-expect-error
                <Component
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...propsInner}
                    ref={forwardedRef}
                />
            );
        }
    );

    //@ts-expect-error
    return React.forwardRef((props, ref) => {
        return (
            <WithConfig
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                forwardedRef={ref}
            />
        );
    });
};
