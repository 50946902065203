import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { computed, makeObservable } from 'mobx';
import { ReferralState } from 'src/domains/sportsbook/betting/betSlipState/ReferralState';
import { DataTimeDuration } from 'src_common/utils/time/time';

class ConnectWrapperTimer {
    public connect(self: MobxValue<number>): NodeJS.Timeout {
        const timer = setInterval((): void => {
            const currentTimeDate = new Date();

            self.setValue(currentTimeDate.getTime());
        }, 1000);

        return timer;
    }

    public dispose(timer: NodeJS.Timeout): void {
        clearInterval(timer);
    }
}

const currentTime: MobxValue<number> = MobxValue.create({
    initValue: 0,
    connect: new ConnectWrapperTimer(),
});

export class BetSlipOfferTimerState {
    private readonly referralState: ReferralState;

    public constructor(referralState: ReferralState) {
        makeObservable(this);
        this.referralState = referralState;
    }

    @computed public get offerExpiryTime(): number | null {
        const curTime = currentTime.getValue();
        const offerExpiryDate = this.referralState.expiresAt === null ? null : new Date(this.referralState.expiresAt).getTime();

        if (offerExpiryDate !== null && curTime > 0) {
            const diffInMilliseconds = Math.floor(offerExpiryDate - curTime);
            const duration = DataTimeDuration.from(diffInMilliseconds);
            const seconds = duration.seconds();

            if (seconds < 0) {
                return 0;
            }
            return seconds;
        }
        return null;
    }

    @computed public get shouldShowOfferExpired(): boolean {
        return this.offerExpiryTime === 0 && this.referralState.expiresAt !== null;
    }
}
