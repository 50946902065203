import { observable, action, computed, makeObservable } from 'mobx';
import { SelectOptionType } from './Select';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateNotRequired, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

interface SelectedOptionFormattedType {
    key: string;
    value: string;
}

export class SelectState {

    @observable public inputState: FormInputState<string,string>;
    @observable public value: SelectOptionType | undefined;
    @observable public isInputFocus: boolean;
    @observable public isError: boolean;
    @observable public isOpen: boolean;

    public constructor(defaultValue?: SelectOptionType, isRequired?: boolean) {
        makeObservable(this);
        this.value = defaultValue;

        const initValue = this.currentValueFormatted === undefined ? '' : this.currentValueFormatted.value;
        const validator = (value: string): Result<string> => isRequired === true ? validateRequire(value) : validateNotRequired(value);
        this.inputState = FormInputState.new(initValue).map(validator);

        this.isError = false;
        this.isInputFocus = false;
        this.isOpen = false;
    }

    @action public handleClick = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        this.isInputFocus = true;
        this.isOpen = !this.isOpen;
        this.isError = false;
    };

    @action public handleBlur = (): void => {
        this.isInputFocus = false;
    };

    @action public handleOpen = (): void => {
        this.isOpen = true;
        this.isInputFocus = true;
    };

    @action public handleClickOutside = (clickOutside: boolean): void => {
        if (clickOutside === true) {
            this.isOpen = false;
        }
    };

    @action public close = (): void => {
        this.isOpen = false;
    };

    @action public setCurrentValue(newValue: SelectOptionType): void {
        this.value = newValue;
        const formattedValue = this.currentValueFormatted;
        if (formattedValue !== undefined) {
            this.inputState.setValue(formattedValue.value);
        }
    }

    @computed public get currentValueFormatted(): SelectedOptionFormattedType | undefined {
        if (this.value === undefined) {
            return undefined;
        }

        const key = Object.keys(this.value)[0];

        //@ts-expect-error
        const value = this.value[key];

        return {
            //@ts-expect-error
            key: key,
            value: value
        };
    }

    @computed public get isSelected(): boolean {
        return this.value === undefined ? false : true;
    }

    @computed public get currentValue(): string {

        if (this.value === undefined){
            return '';
        }

        return Object.values(this.value)[0] ?? '';
    }


}
