import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { HomePageCarouselPromos } from 'src/domains/layouts/webview/components/homePageCarouselPromos/HomePageCarouselPromos';
import { HtmlElementReactive } from 'src_common/common/mobx-utils/HtmlElementReactive';
import { computed, makeObservable } from 'mobx';
import { LoadingWrapper } from 'src/domains/layouts/shared/loaders';
import { useAppStateContext } from 'src/appState/AppState';
import { CarouselModeType } from 'src/domains/layouts/state/cmsState/homePageCarouselState/CarouselMainState';

class CarouselPromoState {
    public readonly reactiveWrapper: HtmlElementReactive<HTMLElement> = new HtmlElementReactive(500);

    public constructor() {
        makeObservable(this);
    }

    @computed public get elementRefHeight(): number {
        const elementRef = this.reactiveWrapper.ref;
        return elementRef?.clientHeight ?? 0;
    }
}

interface PropsType {
    modeType: CarouselModeType;
}

export const CarouselPromo = observer('CarouselPromo', (props: PropsType) => {
    const { modeType } = props;

    const { appLayoutsState } = useAppStateContext();
    const { cmsState } = appLayoutsState;
    const carouselStateItem = cmsState.carouselMainState.carouselStateMap.get(modeType);

    const [state] = useState(() => new CarouselPromoState());

    if (carouselStateItem.sortedLinks.length === 0) {
        return null;
    }

    return (
        <LoadingWrapper computedHeight={state.elementRefHeight}>
            <div ref={state.reactiveWrapper.setRef}>
                <HomePageCarouselPromos
                    carouselPromoState={carouselStateItem}
                    modeType={modeType}
                />
            </div>
        </LoadingWrapper>
    );
});
