import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

type GetTranslationType = (id: string, defaultText: string) => string;

export const validatePostCode =
    (getTranslation: GetTranslationType) =>
    (value: string): Result<string> => {
        const REGEXP = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/gm;
        if (!REGEXP.test(value)) {
            return Result.createError(getTranslation('errors.postCode', 'Please enter valid post code'));
        }
        return Result.createOk(value);
    };
