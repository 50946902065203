import * as React from 'react';
import { SpinnerWrapper, SpinnerLight, SpinnerDark } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner.style';

export const DarkLoadingSpinner = (): JSX.Element => {
    return (
        <SpinnerWrapper>
            <SpinnerDark type='basic' />
        </SpinnerWrapper>
    );
};

export const LoadingSpinner = (): JSX.Element => {
    return (
        <SpinnerWrapper>
            <SpinnerLight type='basic' />
        </SpinnerWrapper>
    );
};
