//The contents of this file have been generated automatically. Do not edit this file.

import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { fetchGeneralRaw, FetchGeneralRawResponseType } from 'src_common/common/fetch';
import { ApiTimeLog } from 'src_common/server/webDriver/logFormat';


export interface ParamsType {
}


const createGuard = <A>(code: number, decoder: t.Type<A, A, unknown>): ((data: unknown) => A) => {
    return (data: unknown): A => {
        const decodeResult = decoder.decode(data);
        if (isRight(decodeResult)) {
            return decodeResult.right;
        }
        throw Error(`Response decoding error /api-proxy/anonymous/mobile-versions -> get -> ${code}`);
    };
};

export const Response200IO = t.array(t.interface({
    app_version: t.string,
    force_update: t.boolean,
    platform: t.string,
    universe: t.string,
}));
export type Response200Type = Array<{
    app_version: string;
    force_update: boolean;
    platform: string;
    universe: string;
}>;
export const decodeResponse200 = createGuard(200, Response200IO);

export const Response400IO = t.string;
export type Response400Type = string;
export const decodeResponse400 = createGuard(400, Response400IO);

export const Response401IO = t.string;
export type Response401Type = string;
export const decodeResponse401 = createGuard(401, Response401IO);

export const Response500IO = t.string;
export type Response500Type = string;
export const decodeResponse500 = createGuard(500, Response500IO);



type ExtraHeadersType = Record<string, string>


/**
 * @deprecated - Please use this method "openapiProxyAnonymousMobileAppUpdateRequest"
 */
export const openapi_proxy_anonymous_mobile_app_update = async (api_url: string, api_timeout: number, backendToken: string, _params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<FetchGeneralRawResponseType> => {
    
        
    const query = '';
    const url = `${api_url}/api-proxy/anonymous/mobile-versions${query}`;
    
    const method = 'GET';
    const paramsFetch = {
        url,
        body: undefined,
        backendToken,
        timeout: api_timeout,
        extraHeaders,
    };

    const apiTime = ApiTimeLog.createWithProcessEnv(method, url);
    const response = await fetchGeneralRaw(method, paramsFetch);
    apiTime.show(response.status);
    return response;
};


export type OpenapiProxyAnonymousMobileAppUpdateParamsType = ParamsType;

export type OpenapiProxyAnonymousMobileAppUpdateResponseType = {
    status: 200;
    body: Response200Type;
} | {
    status: 400;
    body: Response400Type;
} | {
    status: 401;
    body: Response401Type;
} | {
    status: 500;
    body: Response500Type;
};

export type OpenapiProxyAnonymousMobileAppUpdateResponse200Type = Response200Type;

export const openapiProxyAnonymousMobileAppUpdateRequest = async (api_url: string, api_timeout: number, backendToken: string, _params: ParamsType, extraHeaders?: ExtraHeadersType): Promise<OpenapiProxyAnonymousMobileAppUpdateResponseType> => {
    const response = await openapi_proxy_anonymous_mobile_app_update(api_url, api_timeout, backendToken, _params, extraHeaders);
    const { status, body } = response;

    let json = null;

    try {
        json = JSON.parse(body);
    } catch (_err) {
        console.warn(`Http status ${status} - json was expected`);
        json = {};
    }
    
    if (status === 200) {
        return { status: 200, body: decodeResponse200(json) };
    }

    if (status === 400) {
        return { status: 400, body: decodeResponse400(json) };
    }

    if (status === 401) {
        return { status: 401, body: decodeResponse401(json) };
    }

    if (status === 500) {
        return { status: 500, body: decodeResponse500(json) };
    }

    throw new Error(`openapiProxyAnonymousMobileAppUpdateRequest - unhandled response ${response.status}`);
};

