import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { observable, makeObservable } from 'mobx';
import { slug } from 'src/utils/deburr';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import {
    MeetingsMeta,
    MeetingsMonthStyle,
    MeetingsRow,
    MeetingsSubheader,
    ElementsContainer,
    Hr, ArrowRightWrapper,
    MeetingsChevron
} from 'src/domains/sportsbook/webview/components/meetingsAntePostAndSpecials/MeetingsGroup.style';
import { useAppStateContext } from 'src/appState/AppState';
import { CompetitionId, EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { DateTime } from 'src_common/utils/time/time';

interface MeetingsElTypes {
    eventId: EventId;
    name: string;
    isOpen: boolean;
    sport: string;
    timeSettingsStartTime: string;
    competitionId: CompetitionId;
    section: 'ante-post' | 'specials';
    handleScroll?: () => void;
}

const MeetingsEl = observer('MeetingsEl',(props: MeetingsElTypes) => {
    const { eventId, sport, name, isOpen, timeSettingsStartTime, section, competitionId, handleScroll } = props;

    const { appSportsBookState, appLayoutsState } = useAppStateContext();
    const { starRouter, configComponents } = appLayoutsState;

    const { alternativeEventState } = appSportsBookState;
    const isAlternativeEvent = alternativeEventState.get(eventId).checkIsAlternativeEvent();

    const onClick = (): void => {
        if (configComponents.config.isAntePostReverse && handleScroll !== undefined) {
            handleScroll();
        }
        
        if (isAlternativeEvent){
            starRouter.redirectToEvent(eventId);
        } else {
            if (sport === 'horseracing' || sport === 'greyhoundracing') {
                starRouter.redirectToRace(sport, section, { slug: slug(name), id: eventId.toOldId() });
            } else {
                console.error(`It is not possible to redirect to the race page because the event=${eventId.toOldId()} is not a race`);
            }
        }
    };
    const competition = competitionId.getModel();
    return (
        <MeetingsRow isOpen={isOpen} onClick={onClick}>

            {name}

            <MeetingsMeta>
                {competition?.name} | {DateTime.from(timeSettingsStartTime)?.format('DD MMM YYYY | HH:mm')}
            </MeetingsMeta>
            <ArrowRightWrapper position='right' />

        </MeetingsRow>
    );
});

class State {
    @observable private open: boolean = true;

    public constructor() {
        makeObservable(this);
    }

    public get isOpen(): boolean {
        return this.open;
    }

    public handleClick = (): void => {
        this.open = !this.open;
    };
}

interface MeetingsGroupInnerPropTypes {
    state: State;
    month: string;
    events: Record<string, Array<(EventModel | null)>>;
    section: 'ante-post' | 'specials';
    handleScroll?: () => void;
}

const MeetingsGroupInner = observer('MeetingsGroup',(props: MeetingsGroupInnerPropTypes) => {
    const { events, month, state, section, handleScroll } = props;
    const getEvents = events[month] ?? null;

    return (
        <MeetingsMonthStyle>
            <MeetingsSubheader onClick={state.handleClick}>
                <MeetingsChevron position={state.isOpen ? 'down' : 'right'} />
                {DateTime.from(month)?.format('MMMM')}
            </MeetingsSubheader>
            <ElementsContainer>
                {
                    getEvents !== null && Object.keys(events).length !== 0 && getEvents.map((event: EventModel | null) =>
                        event === null ? null : (
                            <MeetingsEl
                                isOpen={state.isOpen}
                                eventId={event.id2}
                                sport={event.sport}
                                timeSettingsStartTime={event.timeSettingsStartTime}
                                key={event.id}
                                competitionId={event.competition2}
                                name={event.name}
                                section={section}
                                handleScroll={handleScroll}
                            />
                        )
                    )
                }
            </ElementsContainer>

        </MeetingsMonthStyle>
    );
});

interface MeetingsGroupPropTypes {
    month: string;
    events: Record<string, EventModel[]>;
    section: 'ante-post' | 'specials';
    handleScroll?: () => void;
}

const MeetingsGroup = observer('MeetingsGroup', (props: MeetingsGroupPropTypes) => {
    const [state] = useState(() => new State());
    const { events, month, section, handleScroll } = props;

    return (
        <>
            <MeetingsGroupInner
                events={events}
                month={month}
                state={state}
                section={section}
                handleScroll={handleScroll}
            />
            <Hr isOpen={state.isOpen} />
        </>
    );
});

interface MeetingsEventsListTypes {
    events: Record<string, Array<EventModel>>;
    section: 'ante-post' | 'specials';
    handleScroll?: () => void;
}

export const MeetingsEventsList = ({ section, events, handleScroll }: MeetingsEventsListTypes): JSX.Element => {
    return (
        <div className='meetings-ante-post__events' data-test='ante-post-events'>
            {Object.keys(events).map(month => (
                <MeetingsGroup
                    key={month}
                    events={events}
                    month={month}
                    section={section}
                    handleScroll={handleScroll}
                />
            ))}
        </div>
    );
};
