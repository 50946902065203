import { z } from 'zod';
import { SavedCard } from './getSavedCards';

export const InputSchema = z.object({
    merchantRefNum: z.string(),
    amount: z.string(),
    currencyCode: z.string(),
    paymentHandleToken: z.string(),
    description: z.string(),
    paymentMethod: SavedCard,
});

export const SuccessResponseSchema = z.object({});

type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            message: z.string().optional().nullable(),
        })
    ),
});

type TErrorResponse = z.infer<typeof ErrorResponseSchema>;

export type TResponseType =
    | { responseStatus: 'success'; response: TSuccessResponse }
    | { responseStatus: 'error'; data: TErrorResponse };
