import React, { useState } from 'react';
import { EventRow6Pack } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/EventRow6Pack';
import { observer } from 'src/utils/mobx-react';
import { MLBEventRow6PackState } from './EventRowMLB.state';
import { useAppStateContext } from 'src/appState/AppState';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';

interface PropsType {
    eventId: EventId;
    smallEvent: EventListGroupEventItemType;
}

export const EventRowMLB = observer<PropsType>('EventRowMLB', ({ eventId, smallEvent }) => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const [state] = useState(() => new MLBEventRow6PackState(eventId, languagesState));

    return <EventRow6Pack smallEvent={smallEvent} state={state}/>;
});
