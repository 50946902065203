import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Select } from './Select';
import { SelectState } from './Select.state';
import { getPrefixesOptions, translatedPhonePrefixOptions, getPrefixesWithPlusAndWithPriority } from 'src/domains/layouts/config/prefixes';

enum SelectPrefixTypeEnum {
    ONLY_PREFIX = 'onlyPrefix',
    ONLY_COUNTRY = 'onlyCountry',
    PREFIX_AND_COUNTRY = 'prefixAndCountry',
}

interface SelectPrefixPropsTypes {
    state: SelectState;
    /** default type: ONLY_PREFIX */
    type?: SelectPrefixTypeEnum;
    /** default false */
    prefixesSortedByCountries?: boolean;
    hasPrefixes?: boolean;
    colorTheme?: 'light' | 'dark';
    onBlur?: () => void;
    priorityPrefixes: string[];
}

export const SelectPrefix = observer('SelectPrefix', (props: SelectPrefixPropsTypes): JSX.Element => {
    const { hasPrefixes, colorTheme, priorityPrefixes, onBlur } = props;
    const prefixesSortedByCountries = props.prefixesSortedByCountries === undefined ? false : props.prefixesSortedByCountries;
    const rawTranslatedPhonePrefixOptions = translatedPhonePrefixOptions();
    const translatedPhonePrefixOptionsArray = Object.keys(translatedPhonePrefixOptions()).map((key: string) => {
        const prefixItem = rawTranslatedPhonePrefixOptions[key];
        return {
            prefix: prefixItem === undefined ? '' : prefixItem,
            id: key
        };
    });
    let prefixesList;

    const onBlurHandler = (): void => {
        if (onBlur !== undefined) {
            onBlur();
        }
    };

    switch (props.type) {
        case SelectPrefixTypeEnum.ONLY_COUNTRY:
            prefixesList = getPrefixesOptions(prefixesSortedByCountries).map((value) => ({ prefix: value.id, id: value.prefix + value.id }));
            break;
        case SelectPrefixTypeEnum.PREFIX_AND_COUNTRY:
            prefixesList = translatedPhonePrefixOptionsArray;
            break;
        default:
            prefixesList = getPrefixesWithPlusAndWithPriority(priorityPrefixes);
            break;
    }

    return <Select options={hasPrefixes === true ? prefixesList : []}
        state={props.state}
        hideLabel={true}
        labelText='Prefix'
        dataTest='prefix-number-dropdown'
        hasPrefixes={hasPrefixes}
        colorTheme={colorTheme}
        onBlur={onBlurHandler}
        isSearchable={true}
    />;
});
