import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    displayMobileFooterLinks,
    FooterItemType,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/mobileFooter/Footer-config';
import { AppState, useAppStateContext } from 'src/appState/AppState';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import {
    CustomWrapper,
    FooterMobile,
    Label,
    LinkWrapper,
} from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/mobileFooter/MobileFooter.style';
import { StarRouterActiveLink } from 'src/domains/layouts/state/router/StarRouterActiveLink';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';

const renderFooterItem = (appState: AppState, item: FooterItemType): React.ReactNode => {
    const fireGtmTag = (route: string | undefined): void => {
        appState.appLayoutsState.googleTagManager.categoryViewTag(route);
    };

    const onClickAction = (route: string, action: (() => void) | null): undefined => {
        fireGtmTag(route);

        if (action !== null) {
            action();
            return undefined;
        }
        return undefined;
    };

    if (item.action.type === 'redirect') {
        const { to } = item.action;
        if ('customId' in to) {
            const customId = to.customId;
            const { redirectToCashOut } = appState.appSportsBookState.betSlipState;

            const BETSLIP_REDIRECTION = {
                'cash-out': {
                    isActive: false,
                    action: redirectToCashOut,
                },
            };

            const redirectionType = BETSLIP_REDIRECTION[customId];
            return (
                <CustomWrapper
                    key={customId}
                    isActive={redirectionType.isActive}
                    onClick={(): undefined => onClickAction(customId, redirectionType.action)}
                    data-test={`mobile-footer-menu-${item.key}-link`}
                >
                    {item.icon}
                    <Label>{item.label}</Label>
                </CustomWrapper>
            );
        } else {
            const common = appState.common;
            const isActive = StarRouterActiveLink.get(common).isActive(to);

            return (
                <LinkWrapper
                    key={item.key}
                    isActive={isActive}
                    onClick={(): undefined => onClickAction(to.name, null)}
                    to={to}
                    dataTest={`mobile-footer-menu-${item.key}`}
                >
                    {item.icon}
                    <Label>{item.label}</Label>
                </LinkWrapper>
            );
        }
    }

    return (
        <LinkWrapper
            key={item.key}
            isActive={false}
            onClick={item.action.onClick}
            dataTest={`mobile-footer-menu-${item.key}`}
        >
            {item.icon}
            <Label>{item.label}</Label>
        </LinkWrapper>
    );
};

export const MobileFooter = observer('MobileFooter', (): JSX.Element | null => {
    const common = useCommon();
    const appState = useAppStateContext();
    const { appCasinoState } = appState;
    const config = ConfigComponents.get(common).config;
    const featureState = FeatureState.get(common);
    const { startBingoGame } = appCasinoState.gameModalState;
    const { mobileFooterLinks, casinoPublishedCollections } = config;

    const filteredMobileFooterLinks = mobileFooterLinks.filter((item) => {
        if (item === 'casino' || item === 'live-casino' || item === 'bingo') {
            if (featureState.allowCasinoInIOSWrapperNew === false) {
                return false;
            }
            return casinoPublishedCollections.includes(item);
        }
        return true;
    });

    const footerItems = displayMobileFooterLinks(filteredMobileFooterLinks, startBingoGame);
    const widthInner = getWindowInnerWidth();

    const footerItemElements = footerItems.map((item) => renderFooterItem(appState, item));

    if (widthInner !== null && widthInner < 1024) {
        return <FooterMobile data-test='mobile-footer-menu'>{footerItemElements}</FooterMobile>;
    }

    return null;
});
