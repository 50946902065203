import { computed, observable, makeObservable } from 'mobx';

export class GroupState {
    @observable public isOpenInner: boolean | null = null;

    public constructor (
        private readonly order: number,
    ) {
        makeObservable(this);
    }

    @computed public get isOpen(): boolean {
        if (this.isOpenInner === null) {
            return this.isOpenOnStart;
        }

        return this.isOpenInner;
    }

    @computed public get isOpenOnStart(): boolean {
        return this.order <= 4;
    }

    public setIsOpen = (): void => {
        if (this.isOpenInner === null) {
            this.isOpenInner = !this.isOpenOnStart;
        } else {
            this.isOpenInner = !this.isOpenInner;
        }
    }
}


