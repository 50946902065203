import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { ChatIconWrapper, ChatIconWrapperFloating, TraderChatButtonFloatingWrapper, TraderChatCounter, TraderChatWrapper, TraderChatWrapperFloating, TraderChatWrapperTxt } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/traderChatLinkNav/TraderChatLinkNav.style';
import { AccountNavLink } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { UniverseComponent } from 'src/domains/common/universes';

interface UnreadIndicatorPropsType {
    unreadMessagesCount: number;
}

const UnreadIndicator = observer('UnreadIndicator', ({ unreadMessagesCount }: UnreadIndicatorPropsType) => {
    if (unreadMessagesCount < 1) {
        return null;
    }
    const label = unreadMessagesCount < 10 ? unreadMessagesCount : '9+';

    return (
        <TraderChatCounter>
            {label}
        </TraderChatCounter>
    );
});

export const TraderChatButtonFloating = observer('TraderChatButtonFloating', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { starRouter } = appLayoutsState;
    const { traderChatState } = appPlayersState;

    return (
        <TraderChatButtonFloatingWrapper
            dataTest='account-navigation-chat-floating'
            onClick={starRouter.redirectToTraderChat}
            size='small'
        >
            <TraderChatWrapperFloating>
                <ChatIconWrapperFloating />

                <UnreadIndicator unreadMessagesCount={traderChatState.unreadMessagesCount}/>
            </TraderChatWrapperFloating>
        </TraderChatButtonFloatingWrapper>
    );
});

export const TraderChatLinkNav = observer('TraderChatLinkNav', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { traderChatState } = appPlayersState;
    const { configComponents: { config } } = appLayoutsState;

    const { traderChat } = config;

    if (traderChat.isBubbleChatButton === true) {
        return null;
    }

    return (
        <AccountNavLink
            dataTest='account-navigation-chat'
            to={{ account: 'trader-chat' }}
            routeNameForGtm={null}
        >
            <TraderChatWrapper>
                <ChatIconWrapper />

                <TraderChatWrapperTxt>
                    <UniverseComponent>
                        {(universeVersion): React.ReactElement => <universeVersion.traderChatLinkNav />}
                    </UniverseComponent>

                    <UnreadIndicator unreadMessagesCount={traderChatState.unreadMessagesCount}/>
                </TraderChatWrapperTxt>
            </TraderChatWrapper>
        </AccountNavLink>
    );
});
