import { PromiseBox } from 'src_common/common/mobx-utils/PromiseBox';
import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';
import { timeout } from 'src_common/common/mobx-utils/timeout';

const reconnectDelay = async (label: string): Promise<void> => {
    console.info(`${label} wait 3000ms`);
    await timeout(3000);
    console.info(`${label} go forth`);
};

export type SocketEventType<MessageTo> = {
    type: 'message';
    message: string;
} | {
    type: 'socket';
    socket: SocketConnection<MessageTo>;
};

type OnMessageType<MessageTo> = (message: SocketEventType<MessageTo>) => void;
type UnsubscribeFnType = () => void;

interface OpenSocketResult<MessageTo> {
    socket: Promise<SocketConnection<MessageTo> | null>;
    done: Promise<void>;
}

export interface SocketConnectionController<MessageTo> {
    send: (message: MessageTo) => void;
    dispose: UnsubscribeFnType;
}

class LogContext {
    public constructor(
        private label: string,
        private host: string
    ) {}

    public formatLog = (message: string): string => {
        return `Socket ${this.label}, ${this.host} ==> ${message}`;
    };
}
export class SocketConnection<MessageTo> {
    private eventMessage: EventEmmiter<string>;
    private closeSocket: () => void;
    private sendCallback: (message: MessageTo) => void;

    private constructor(
        closeSocket: () => void,
        sendCallback: (message: MessageTo) => void,
    ) {
        this.eventMessage = new EventEmmiter();
        this.closeSocket = closeSocket;
        this.sendCallback = sendCallback;
    }

    private onMessage(callback: (message: string) => void): void {
        this.eventMessage.on(callback);
    }

    public send(message: MessageTo): void {
        this.sendCallback(message);
    }

    public close(): void {
        this.closeSocket();
    }

    private static connect<MessageTo>(
        log: LogContext,
        host: string,
        timeout: number,
    ): OpenSocketResult<MessageTo> {
        const result = new PromiseBox<SocketConnection<MessageTo> | null>();
        const done = new PromiseBox<void>();
        const socket = new WebSocket(host);
        let isClose: boolean = false;

        console.info(log.formatLog('starting ...'));

        const closeSocket = (): void => {
            if (isClose) {
                return;
            }

            console.info(log.formatLog('close'));

            isClose = true;
            result.resolve(null);
            done.resolve();
            socket.close();
            socket.onmessage = null;
            socket.onopen = null;
            socket.onclose = null;
            socket.onerror = null;
        };


        const socketConnection = new SocketConnection<MessageTo>(
            closeSocket,
            (message: MessageTo) => {
                if (isClose) {
                    return;
                }
                socket.send(JSON.stringify(message));
            }
        );

        setTimeout(() => {
            if (result.isFulfilled() === false) {
                console.error(log.formatLog(`timeout (${timeout}ms)`));
                closeSocket();
            }
        }, timeout);

        const onOpen = (): void => {
            console.info(log.formatLog('open'));
            result.resolve(socketConnection);
        };

        const onError = (error: Event): void => {
            console.error(log.formatLog('error'), error);
            closeSocket();
        };

        const onMessage = (event: MessageEvent): void => {
            if (isClose) {
                return;
            }

            const dataRaw = event.data;

            if (typeof dataRaw === 'string') {
                socketConnection.eventMessage.trigger(dataRaw);
                return;
            }

            console.error(log.formatLog('onMessage - expected string'), dataRaw);
        };

        socket.addEventListener('open', onOpen);
        socket.addEventListener('error', onError);
        socket.addEventListener('close', closeSocket);
        socket.addEventListener('message', onMessage);

        return {
            socket: result.promise,
            done: done.promise
        };
    }

    public static startSocket<MessageTo>(
        label: string,
        host: string,
        timeout: number,
        onMessage: OnMessageType<MessageTo>,
    ): SocketConnectionController<MessageTo> {
        let isConnect: boolean = true;
        let socketConnection: SocketConnection<MessageTo> | null = null;

        const log = new LogContext(label, host);

        (async (): Promise<void> => {
            while (isConnect) {
                const openSocketResult = SocketConnection.connect<MessageTo>(log, host, timeout);

                const socket = await openSocketResult.socket;

                if (socket === null) {
                    await reconnectDelay(log.formatLog('reconnect after error'));
                    continue;
                }

                socketConnection = socket;
                onMessage({
                    type: 'socket',
                    socket
                });
                socket.onMessage(message => {
                    onMessage({
                        type: 'message',
                        message
                    });
                });

                await openSocketResult.done;

                if (!isConnect) {
                    console.info(log.formatLog('disconnect (1)'));
                    return;
                }

                await reconnectDelay(log.formatLog('reconnect after close'));
            }

            console.info(log.formatLog('disconnect (2)'));
        })().catch((error) => {
            console.error(error);
        });

        return {
            send: (message: MessageTo): void => {
                if (socketConnection !== null) {
                    socketConnection.send(message);
                }
            },
            dispose: (): void => {
                isConnect = false;
                socketConnection?.close();
            }
        };
    }
}
