import React from 'react';

import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { NavigationPanel } from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { useAppStateContext } from 'src/appState/AppState';
import { NavigationSidebarContent, NavigationSportsInner, SectionWrapper } from 'src/domains/layouts/layouts/navigationSportsPage/NavigationSportsPage.style';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { NavigationSportsRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface PropsTypes {
    currentView: NavigationSportsRouteType;
    isSearchActive: boolean;
    accountView: RightHandSideViewType | null;
}

export const NavigationSports = observer('NavigationSports', ({ currentView, accountView, isSearchActive }: PropsTypes) => {
    const { appLayoutsState } = useAppStateContext();
    const { sportsList } = appLayoutsState;
    const { sportsLinks } = sportsList;

    return (
        <SectionWrapper>
            <LayoutSkeleton accountView={accountView} currentView={currentView} isSearchActive={isSearchActive} navigationType='global' >
                <NavigationSportsInner>
                    <NavigationSidebarContent>
                        <NavigationPanel
                            title={<I18n langKey='left-menu.sports-az.title' defaultText='A-Z' />}
                            titleDataTest='a-z'
                            links={sportsLinks}
                            isAzMobile={true}
                            typeTag='sport'
                        />
                    </NavigationSidebarContent>
                </NavigationSportsInner>
            </LayoutSkeleton>
        </SectionWrapper>
    );
});
