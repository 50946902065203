import React, { useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { ScrollState } from 'src/domains/sportsbook/webview/components/raceCard/ScrollState';
import { RacingStreamLazy } from 'src/domains/sportsbook/webview/components/stream/RacingStreamLazy';
import { RacingStreamRMG } from 'src/domains/sportsbook/webview/components/stream/RacingStreamRMG';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { RaceCardLoader } from 'src/domains/sportsbook/webview/components/raceCard/raceCardLoader/RaceCardLoader';
import { RaceCompetitionSetOrderState, RaceCompetitionState } from './RaceCompetition.state';
import { RaceCardFiltersState } from './RaceCardFiltersStrategy.state';
import { Tabs, TabItemType } from './tabs/Tabs';
import { ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';
import {
    FiltersBackButton,
    FiltersWrapper,
    ChevronIconWrapper,
    SectionRaceCard,
    TabsWrapper,
    ScrollLeft,
    ScrollRight,
} from './NewRaceCard.style';
import { RaceLabel } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceLabel';
import { RaceDetails } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails';
import { calculateRaceStatus } from 'src/domains/sportsbook/utils/filterRacesWithFinishState';

const raceDetailsItems = (
    state: RaceCompetitionState,
    sport: 'horseracing' | 'greyhoundracing',
    orderState: RaceCompetitionSetOrderState
): Array<TabItemType> => {
    const { displayCompetition, currentCategory } = state;
    const { sortOrder, setSortOrder } = orderState;
    const raceDetails: Array<TabItemType> = [];

    for (const race of state.todayEvents) {
        const isFinishedRace = calculateRaceStatus(race.timeSettingsTimeline, race.state) === 'RaceStatusFinished';

        raceDetails.push({
            key: race.id,
            label: <RaceLabel race={race} displayCompetition={displayCompetition} isFinishedRace={isFinishedRace} />,
            labelDisabled: false,
            content: (
                <RaceDetails
                    key={race.id}
                    eventId={race.id2}
                    sport={sport}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                />
            ),
            isActive: () => state.currentEventId === race.id,
            setActive: () => {
                if (currentCategory === 'race-meetings') {
                    state.starRouter.redirectToRaceMeetingsCard(race.id, sport, state.racecardBuildIds);
                } else if (currentCategory === 'next-off') {
                    state.starRouter.redirectToRaceCard(race.id2, sport, 'next-off');
                } else {
                    state.starRouter.redirectToEvent(race.id2);
                }
            },
            isFinished: isFinishedRace,
        });
    }

    if (state.tomorrowEvents.length > 0) {
        raceDetails.push({
            key: 'tomorrow',
            label: <div />,
            labelDisabled: true,
            content: <div />,
            isActive: () => false,
            setActive: () => {},
            isFinished: false,
        });
    }

    for (const race of state.tomorrowEvents) {
        raceDetails.push({
            key: race.id,
            label: <RaceLabel race={race} displayCompetition={displayCompetition} />,
            labelDisabled: false,
            content: <RaceDetails key={race.id} eventId={race.id2} sortOrder={sortOrder} setSortOrder={setSortOrder} />,
            isActive: () => state.currentEventId === race.id,
            setActive: () => {
                if (currentCategory === 'race-meetings') {
                    state.starRouter.redirectToRaceMeetingsCard(race.id, sport, state.racecardBuildIds);
                } else if (currentCategory === 'next-off') {
                    state.starRouter.redirectToRaceCard(race.id2, sport, 'next-off');
                } else {
                    state.starRouter.redirectToEvent(race.id2);
                }
            },
            isFinished: false,
        });
    }

    if (state.thisWeekEvents.length > 0) {
        raceDetails.push({
            key: 'thisWeek',
            label: <div />,
            labelDisabled: true,
            content: <div />,
            isActive: () => false,
            setActive: () => {},
            isFinished: false,
        });
    }

    for (const race of state.thisWeekEvents) {
        raceDetails.push({
            key: race.id,
            label: <RaceLabel race={race} displayCompetition={displayCompetition} />,
            labelDisabled: false,
            content: <RaceDetails key={race.id} eventId={race.id2} sortOrder={sortOrder} setSortOrder={setSortOrder} />,
            isActive: () => state.currentEventId === race.id,
            setActive: () => {
                if (currentCategory === 'race-meetings') {
                    state.starRouter.redirectToRaceMeetingsCard(race.id, sport, state.racecardBuildIds);
                } else if (currentCategory === 'next-off') {
                    state.starRouter.redirectToRaceCard(race.id2, sport, 'next-off');
                } else {
                    state.starRouter.redirectToEvent(race.id2);
                }
            },
            isFinished: false,
        });
    }

    return raceDetails;
};

interface PropsType {
    sport: 'horseracing' | 'greyhoundracing';
    timezone?: string;
    racecardBuildIds: Array<number>;
    racecardCollection: 'next-off' | 'race-meetings' | number | null;
    racecardSelected: number | null;
    isNotificationsList: boolean;
    streamRmgWrapperIsVisible: boolean;
}

const activeFilter = (currentCategory: number | 'race-meetings' | 'next-off' | null): string => {
    if (currentCategory === null) {
        return '';
    }

    if (currentCategory === 'race-meetings' || currentCategory === 'next-off') {
        return currentCategory;
    }
    return currentCategory.toString();
};

export const RaceCard = observer('RaceCard', (props: PropsType) => {
    const { appSportsBookState, appLayoutsState } = useAppStateContext();
    const { languagesState, starRouter, breakpointsState, configComponents } = appLayoutsState;
    const { config } = configComponents;
    const { streamingState, models, eventsCollection } = appSportsBookState;

    const [scrollState] = useState(() => new ScrollState());

    const eventsCollectionList = eventsCollection.getRacesForWeek(props.sport);

    const areEventsLoading = eventsCollectionList?.isLoading ?? true;

    const raceCompetition = new RaceCompetitionState(models, languagesState, starRouter, eventsCollection, props);

    const [raceCompetitionSetOrderState] = useState(() => new RaceCompetitionSetOrderState());

    const [filtersState] = useState(() => new RaceCardFiltersState(raceCompetition, starRouter, props.sport));
    const { filters } = filtersState;

    const activeFilterId = activeFilter(props.racecardCollection);

    const streamProvider = streamingState.providerName;
    const isDesktop = breakpointsState.desktop.isBiggerOrEq === true;

    if (areEventsLoading) {
        return <RaceCardLoader />;
    }

    return (
        <SectionRaceCard data-test='race-card' streamIsInViewport={props.streamRmgWrapperIsVisible}>
            {streamProvider === 'RMG' ? (
                <RacingStreamRMG
                    isNotificationsList={props.isNotificationsList}
                    streamIsInViewport={props.streamRmgWrapperIsVisible}
                />
            ) : (
                <RacingStreamLazy isNotificationsList={props.isNotificationsList} />
            )}
            <FiltersWrapper>
                <FiltersBackButton onClick={raceCompetition.onBackClick}>
                    <ChevronIconWrapper position='left' />
                </FiltersBackButton>

                <FiltersScrollableWrapper>
                    <FiltersNewView
                        select={activeFilterId}
                        skin={config.filtersVersion.raceCardMain}
                        filters={filters}
                    />
                </FiltersScrollableWrapper>
            </FiltersWrapper>
            <TabsWrapper>
                {scrollState.scrollLeftArrow && isDesktop ? (
                    <ScrollLeft onClick={scrollState.goToLeft}>
                        <ButtonChevronIcon position='left' />
                    </ScrollLeft>
                ) : null}

                <Tabs
                    items={raceDetailsItems(raceCompetition, props.sport, raceCompetitionSetOrderState)}
                    setRefMenu={scrollState.setRef}
                />

                {scrollState.scrollRightArrow && isDesktop ? (
                    <ScrollRight onClick={scrollState.goToRight}>
                        <ButtonChevronIcon position='right' />
                    </ScrollRight>
                ) : null}
            </TabsWrapper>
        </SectionRaceCard>
    );
});
