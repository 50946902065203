import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { MaskModelType } from 'src/domains/sportsbook/state/alternativeEventState/AlternativeCustomSelectionState';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import { ParticipantColor, SilkWrapper, ParticipantColorWrapper } from 'src/domains/sportsbook/webview/modules/AlternativeEvent/alternativeMainContent/AlternativeMainContent.styles';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';

interface MaskImgPropsTypes {
    mask: MaskModelType | null,
    selectionModel: SelectionModel
}

export const MaskImg = observer('MaskImg', ({ mask, selectionModel }: MaskImgPropsTypes) => {

    if (mask === null) {
        return null;
    }

    return (
        <ParticipantColorWrapper>
            {mask.color.length > 0 ? <ParticipantColor teamColor={mask.color} /> : null}
            {
                mask.url.length > 0 ? (
                    <SilkWrapper>
                        <LazyImage
                            placeholder=''
                            alt={selectionModel.isMetaData ? selectionModel.metaDataSilk : null}
                            justSrc={mask.url}
                        />
                    </SilkWrapper>
                ) : null
            }
        </ParticipantColorWrapper>
    );
});
