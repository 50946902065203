import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface DotPatternIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const DotPatternIcon = observer(
    'DotPatternIcon',
    ({ className, onClick }: DotPatternIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='none'
                onClick={onClick}
                viewBox='0 0 343 25'
                width='343'
                height='25'
                xmlns='http://www.w3.org/2000/svg'
            >
                <circle cx={36} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={52} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={68} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={84} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={100} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={116} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={132} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={148} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={164} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={180} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={196} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={212} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={228} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={244} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={260} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={276} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={292} cy={12.5} r={4} fill='#E8EBEF' />
                <circle cx={308} cy={12.5} r={4} fill='#E8EBEF' />
            </SvgElement>
        );
    }
);
