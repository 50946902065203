import * as t from 'io-ts';
import { Amount } from 'src_common/common/amount/Amount';
import { createGuard } from 'src_common/common/createGuard';

const DealerIO = t.interface({
    name: t.string,
});

const TableLimitsIO = t.interface({
    maxBet: t.number,
    maxPlayers: t.number,
    minBet: t.number,
});

export interface TableLimitsType {
    maxBet: Amount;
    maxPlayers: number;
    minBet: Amount;
}

const LastResultItemIO = t.interface({
    //color: t.union([t.literal('black'), t.literal('red'), t.literal('green')]),
    color: t.union([t.string, t.undefined, t.null]),
    result: t.union([t.number, t.undefined, t.null]),
    time: t.string,
});

const CasinoTableRequiredIO = t.interface({
    tableType: t.string,
    tableId: t.string,
    tableImage: t.string,
    dealer: DealerIO,
    tableLimits: TableLimitsIO,
    tableName: t.string
});

const CasinoTableOptionalIO = t.partial({
    totalSeatedPlayers: t.number,
    availableSeats: t.number,
    last20Results: t.union([
        t.null,
        t.array(LastResultItemIO)
    ]),
    hotNumbers: t.array(t.number),
    coldNumbers: t.array(t.number),
});

const CasinoTableIO = t.intersection([CasinoTableRequiredIO, CasinoTableOptionalIO]);

export const isCasinoTableModel = createGuard(CasinoTableIO);

export type CasinoTableType = t.TypeOf<typeof CasinoTableIO>;
