import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';



export const validateDateGreaterThanZero = (value: number): Result<number> => {

    if (value < 1) {

        return Result.createError('The date should be greater than 0');

    }

    return Result.createOk(value);

};

