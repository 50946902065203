import { apiCommon } from 'src/api/ApiCommon';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const toLowerCase = (value: string): Result<string> => {
    return Result.createOk(value.trim().toLowerCase());
};


const onValidateEmail = async (email: string): Promise<boolean> => {
    const response = await apiCommon.accountValidateEmail.run(
        email
    );

    return response.exists;
};

export const emailCheckMap = (): MobxMapAutoNew<string, Resource<boolean>> => new MobxMapAutoNew(
    (email: string) => new Resource(async (): Promise<boolean> => {
        const exist = await onValidateEmail(email);

        return exist;
    })
);
