import { sortBy, uniqBy } from 'lodash';
import { countryName, isCountryCodeType } from './countries';

const PRIORITY_COUNTRIES = [ 'GB', 'IE' ];

interface SelectOptionsType {
    [key: string]: string;
}

let prefixes = [
    { prefix: '44', id: 'GB' },
    { prefix: '353', id: 'IE' },
    { prefix: '242', id: 'CG' },
    { prefix: '355', id: 'AL' },
    { prefix: '376', id: 'AD' },
    { prefix: '1-264', id: 'AI' },
    { prefix: '672', id: 'AQ' },
    { prefix: '1-268', id: 'AG' },
    { prefix: '54', id: 'AR' },
    { prefix: '374', id: 'AM' },
    { prefix: '297', id: 'AW' },
    { prefix: '994', id: 'AZ' },
    { prefix: '1-242', id: 'BS' },
    { prefix: '973', id: 'BH' },
    { prefix: '1-246', id: 'BB' },
    { prefix: '375', id: 'BY' },
    { prefix: '229', id: 'BJ' },
    { prefix: '1-441', id: 'BM' },
    { prefix: '975', id: 'BT' },
    { prefix: '591', id: 'BO' },
    { prefix: '599', id: 'BQ' },
    { prefix: '387', id: 'BA' },
    { prefix: '267', id: 'BW' },
    { prefix: '47', id: 'BV' },
    { prefix: '246', id: 'IO' },
    { prefix: '673', id: 'BN' },
    { prefix: '359', id: 'BG' },
    { prefix: '226', id: 'BF' },
    { prefix: '257', id: 'BI' },
    { prefix: '237', id: 'CM' },
    { prefix: '238', id: 'CV' },
    { prefix: '1-345', id: 'KY' },
    { prefix: '56', id: 'CL' },
    { prefix: '57', id: 'CO' },
    { prefix: '269', id: 'KM' },
    { prefix: '682', id: 'CK' },
    { prefix: '506', id: 'CR' },
    { prefix: '385', id: 'HR' },
    { prefix: '53', id: 'CU' },
    { prefix: '599', id: 'CW' },
    { prefix: '225', id: 'CI' },
    { prefix: '1-767', id: 'DM' },
    { prefix: '1-809', id: 'DO' },
    { prefix: '1-829', id: 'DO' },
    { prefix: '1-849', id: 'DO' },
    { prefix: '20', id: 'EG' },
    { prefix: '503', id: 'SV' },
    { prefix: '291', id: 'ER' },
    { prefix: '372', id: 'EE' },
    { prefix: '500', id: 'FK' },
    { prefix: '298', id: 'FO' },
    { prefix: '679', id: 'FJ' },
    { prefix: '241', id: 'GA' },
    { prefix: '220', id: 'GM' },
    { prefix: '995', id: 'GE' },
    { prefix: '233', id: 'GH' },
    { prefix: '350', id: 'GI' },
    { prefix: '1-473', id: 'GD' },
    { prefix: '502', id: 'GT' },
    { prefix: '44', id: 'GG' },
    { prefix: '224', id: 'GN' },
    { prefix: '592', id: 'GY' },
    { prefix: '672', id: 'HM' },
    { prefix: '39-06', id: 'VA' },
    { prefix: '504', id: 'HN' },
    { prefix: '354', id: 'IS' },
    { prefix: '964', id: 'IQ' },
    { prefix: '44', id: 'IM' },
    { prefix: '39', id: 'IT' },
    { prefix: '1-876', id: 'JM' },
    { prefix: '44', id: 'JE' },
    { prefix: '962', id: 'JO' },
    { prefix: '7', id: 'KZ' },
    { prefix: '254', id: 'KE' },
    { prefix: '686', id: 'KI' },
    { prefix: '965', id: 'KW' },
    { prefix: '996', id: 'KG' },
    { prefix: '856', id: 'LA' },
    { prefix: '961', id: 'LB' },
    { prefix: '266', id: 'LS' },
    { prefix: '231', id: 'LR' },
    { prefix: '423', id: 'LI' },
    { prefix: '370', id: 'LT' },
    { prefix: '352', id: 'LU' },
    { prefix: '853', id: 'MO' },
    { prefix: '389', id: 'MK' },
    { prefix: '261', id: 'MG' },
    { prefix: '265', id: 'MW' },
    { prefix: '960', id: 'MV' },
    { prefix: '356', id: 'MT' },
    { prefix: '692', id: 'MH' },
    { prefix: '222', id: 'MR' },
    { prefix: '230', id: 'MU' },
    { prefix: '52', id: 'MX' },
    { prefix: '691', id: 'FM' },
    { prefix: '373', id: 'MD' },
    { prefix: '377', id: 'MC' },
    { prefix: '976', id: 'MN' },
    { prefix: '382', id: 'ME' },
    { prefix: '1-664', id: 'MS' },
    { prefix: '212', id: 'MA' },
    { prefix: '258', id: 'MZ' },
    { prefix: '264', id: 'NA' },
    { prefix: '674', id: 'NR' },
    { prefix: '977', id: 'NP' },
    { prefix: '31', id: 'NL' },
    { prefix: '64', id: 'NZ' },
    { prefix: '505', id: 'NI' },
    { prefix: '227', id: 'NE' },
    { prefix: '234', id: 'NG' },
    { prefix: '683', id: 'NU' },
    { prefix: '47', id: 'NO' },
    { prefix: '968', id: 'OM' },
    { prefix: '680', id: 'PW' },
    { prefix: '970', id: 'PS' },
    { prefix: '507', id: 'PA' },
    { prefix: '675', id: 'PG' },
    { prefix: '595', id: 'PY' },
    { prefix: '51', id: 'PE' },
    { prefix: '870', id: 'PN' },
    { prefix: '974', id: 'QA' },
    { prefix: '40', id: 'RO' },
    { prefix: '590', id: 'BL' },
    { prefix: '290', id: 'SH' },
    { prefix: '247', id: 'SH' },
    { prefix: '1-869', id: 'KN' },
    { prefix: '1-758', id: 'LC' },
    { prefix: '590', id: 'MF' },
    { prefix: '1-784', id: 'VC' },
    { prefix: '685', id: 'WS' },
    { prefix: '378', id: 'SM' },
    { prefix: '239', id: 'ST' },
    { prefix: '966', id: 'SA' },
    { prefix: '221', id: 'SN' },
    { prefix: '248', id: 'SC' },
    { prefix: '48', id: 'PL' },
    { prefix: '232', id: 'SL' },
    { prefix: '1-721', id: 'SX' },
    { prefix: '386', id: 'SI' },
    { prefix: '677', id: 'SB' },
    { prefix: '27', id: 'ZA' },
    { prefix: '500', id: 'GS' },
    { prefix: '34', id: 'ES' },
    { prefix: '94', id: 'LK' },
    { prefix: '597', id: 'SR' },
    { prefix: '47', id: 'SJ' },
    { prefix: '268', id: 'SZ' },
    { prefix: '886', id: 'TW' },
    { prefix: '992', id: 'TJ' },
    { prefix: '255', id: 'TZ' },
    { prefix: '66', id: 'TH' },
    { prefix: '670', id: 'TL' },
    { prefix: '228', id: 'TG' },
    { prefix: '690', id: 'TK' },
    { prefix: '676', id: 'TO' },
    { prefix: '1-868', id: 'TT' },
    { prefix: '216', id: 'TN' },
    { prefix: '1-649', id: 'TC' },
    { prefix: '688', id: 'TV' },
    { prefix: '256', id: 'UG' },
    { prefix: '598', id: 'UY' },
    { prefix: '678', id: 'VU' },
    { prefix: '58', id: 'VE' },
    { prefix: '1-284', id: 'VG' },
    { prefix: '212', id: 'EH' },
    { prefix: '260', id: 'ZM' },
    { prefix: '358', id: 'AX' },
    { prefix: '1', id: 'CA' },
    { prefix: '358', id: 'FI' },
    { prefix: '49', id: 'DE' },
    { prefix: '43', id: 'AT' },
    { prefix: '213', id: 'DZ' },
    { prefix: '1-684', id: 'AS' },
    { prefix: '880', id: 'BD' },
    { prefix: '1-246', id: 'BB' },
    { prefix: '501', id: 'BZ' },
    { prefix: '244', id: 'AO' },
    { prefix: '855', id: 'BH' },
    { prefix: '235', id: 'TD' },
    { prefix: '357', id: 'CY' },
    { prefix: '253', id: 'DJ' },
    { prefix: '243', id: 'CD' },
    { prefix: '593', id: 'EC' },
    { prefix: '240', id: 'GQ' },
    { prefix: '251', id: 'ET' },
    { prefix: '594', id: 'GF' },
    { prefix: '689', id: 'PF' },
    { prefix: '299', id: 'GL' },
    { prefix: '590', id: 'GP' },
    { prefix: '1-671', id: 'GU' },
    { prefix: '62', id: 'ID' },
    { prefix: '383', id: 'XK' },
    { prefix: '853', id: 'MO' },
    { prefix: '596', id: 'MQ' },
    { prefix: '262', id: 'YT' },
    { prefix: '687', id: 'NC' },
    { prefix: '1-670', id: 'MP' },
    { prefix: '92', id: 'PK' },
    { prefix: '1-787', id: 'PR' },
    { prefix: '262', id: 'RE' },
    { prefix: '250', id: 'RW' },
    { prefix: '239', id: 'ST' },
    { prefix: '670', id: 'TL' },
    { prefix: '993', id: 'TM' },
    { prefix: '971', id: 'AE' },
    { prefix: '1', id: 'UM' },
    { prefix: '1', id: 'VI' },
    { prefix: '998', id: 'UZ' },
    { prefix: '84', id: 'VN' },
    { prefix: '681', id: 'WF' },
    { prefix: '855', id: 'KH' },
];

export const prefixesByCountryId: Record<string, string> = {
    AD: '376',
    AE: '971',
    AG: '1-268',
    AL: '355',
    AM: '374',
    AO: '244',
    AS: '1-684',
    AT: '43',
    AW: '297',
    AZ: '994',
    BB: '1246',
    BD: '880',
    BF: '226',
    BH: '973',
    BI: '257',
    BJ: '229',
    BL: '590',
    BM: '1-441',
    BN: '673',
    BO: '591',
    BS: '1-242',
    BT: '975',
    BW: '267',
    BZ: '501',
    CA: '1',
    CD: '243',
    CK: '682',
    CM: '237',
    CO: '57',
    CR: '506',
    CV: '238',
    CW: '599',
    CY: '357',
    DE: '49',
    DJ: '253',
    DM: '1-767',
    DO: '809',
    DZ: '213',
    EC: '593',
    EH: '212',
    ET: '251',
    FI: '358',
    FJ: '679',
    FK: '500',
    FM: '691',
    FO: '298',
    GA: '241',
    GB: '44',
    GD: '1-473',
    GE: '995',
    GF: '594',
    GH: '233',
    GL: '299',
    GM: '220',
    GP: '590',
    GQ: '240',
    GT: '502',
    GU: '1-671',
    GY: '592',
    HN: '504',
    ID: '62',
    IM: '44',
    JM: '1-876',
    JO: '962',
    KG: '996',
    KH: '855',
    KI: '686',
    KM: '269',
    KN: '1-869',
    KW: '965',
    KY: '1-345',
    KZ: '7',
    LA: '856',
    LB: '961',
    LC: '1-758',
    LI: '423',
    LK: '94',
    LR: '231',
    LS: '266',
    LU: '352',
    MA: '212',
    MC: '377',
    MF: '590',
    MG: '261',
    MK: '389',
    MN: '976',
    MO: '853',
    MP: '1-670',
    MQ: '596',
    MR: '222',
    MT: '356',
    MU: '230',
    MV: '960',
    MW: '265',
    MZ: '258',
    NA: '264',
    NC: '687',
    NE: '227',
    NG: '234',
    NI: '505',
    NO: '47',
    NP: '977',
    NR: '674',
    NZ: '64',
    OM: '968',
    PA: '507',
    PE: '51',
    PF: '689',
    PG: '675',
    PK: '92',
    PR: '1-787',
    PW: '680',
    PY: '595',
    QA: '974',
    RE: '262',
    RW: '250',
    SB: '677',
    SC: '248',
    SL: '232',
    SM: '378',
    SN: '221',
    SR: '597',
    ST: '239',
    SV: '503',
    SZ: '268',
    TC: '1-649',
    TD: '235',
    TG: '228',
    TJ: '992',
    TL: '670',
    TM: '993',
    TO: '676',
    TT: '1-868',
    TV: '688',
    TZ: '255',
    UG: '256',
    UM: '1',
    UY: '598',
    UZ: '998',
    VC: '1-784',
    VI: '1',
    VN: '84',
    VU: '678',
    WF: '681',
    WS: '685',
    XK: '383',
    YT: '262',
    ZA: '27',
    ZM: '260'
};

export const translatedPhonePrefixOptions = (): SelectOptionsType => {
    const translatedPhonePrefixOptions: SelectOptionsType = {};

    for (const key of Object.keys(prefixesByCountryId)) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        translatedPhonePrefixOptions[key] = `+${prefixesByCountryId[key] } - ${isCountryCodeType(key) ? countryName[key] : '' }`;
    }
    return translatedPhonePrefixOptions;
};

function getPrefixes(prefixesSortedByCountries: boolean):{ prefix: string; id: string }[] {
    if (prefixesSortedByCountries) {
        return prefixes = sortBy(prefixes, ({ id }) => -PRIORITY_COUNTRIES.indexOf(id));
    }

    return sortBy(prefixes, 'prefix');
};

function getPrefixesWithPlus(prefixesSortedByCountries: boolean):{ prefix: string; id: string }[] {
    const prefixesWithPlus = prefixes.map((code) => ({ prefix: `+${code.prefix}`, id: code.id }));


    if (prefixesSortedByCountries) {
        return sortBy(prefixesWithPlus, ({ id }) => -PRIORITY_COUNTRIES.indexOf(id));
    }

    return sortBy(prefixesWithPlus, 'prefix');
};

export function getPrefixesWithPlusAndWithPriority(priorityPrefixList: string[]):SelectOptionsType[] {
    const sortedPrefixes = uniqBy(getPrefixesWithPlus(false), 'prefix');

    const list = sortedPrefixes.filter(item => {
        const prefixWithoutPlus = item.prefix.replace('+','');
        return priorityPrefixList.includes(prefixWithoutPlus);
    }).reverse();

    return [...list, ...sortedPrefixes].map(item => ({ [item.id]: item.prefix }));
};

export const getPrefixesOptions = (prefixesSortedByCountries: boolean):{ prefix: string; id: string }[] => getPrefixes(prefixesSortedByCountries);
