import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { deburr } from 'src/utils/deburr';
import { checkIsCapitalLetter, checkIsNumericCharacter, checkIsSmallLetter, checkIsSpecialCharacter } from 'src_common/common/forms/validators/validatingFunctions';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

const allowedSpecialChar = [' ', '.', '-', '\''];

const matchChar = (char: string): boolean => {
    if (checkIsSmallLetter(char)) {
        return true;
    }
    if (checkIsCapitalLetter(char)) {
        return true;
    }
    if (checkIsNumericCharacter(char)) {
        return true;
    }
    if (checkIsSpecialCharacter(allowedSpecialChar, char)) {
        return true;
    }
    return false;
};

export const checkCity = (city: string): boolean => {
    for (const char of city) {
        if (matchChar(char) === false) {
            return false;
        }
    }
    return true;
};

export const validateCity = (value: string): Result<string> => {
    const maxLength = 50;

    if (value.length > maxLength) {
        const errorLengthMessage = getErrorByCode('ERROR_MAX_LENGTH').replace('%length', maxLength.toString());
        return Result.createError(errorLengthMessage);
    }

    if (checkCity(deburr(value))) {
        return Result.createOk(value);
    }

    const errorRequiredField = getErrorByCode('ERROR_INVALID_CITY');
    return Result.createError(errorRequiredField);
};
