import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { PlayNowButton as StyledPlayNowButton, CloseWidgetIcon } from './PlayNowButton.style';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';

interface PlayNowButtonPropsType {
    appCasinoState: AppCasinoState;
}

export const PlayNowButton = observer('PlayNowButton', (props: PlayNowButtonPropsType) => {
    const { appCasinoState } = props;
    const { isGamesListDisplayed, showGamesList, hideGamesList } = appCasinoState.miniGamesListState;
    const desktopVersion = appCasinoState.breakpointsState.desktop.isBiggerOrEq;

    if (desktopVersion === true) {
        return null;
    }

    return isGamesListDisplayed === false ? (
        <StyledPlayNowButton onClick={showGamesList}>
            <I18n
                langKey='casino.mini-games-widget.button'
                defaultText='Play Now'
            />
        </StyledPlayNowButton>
    ) : (
        <CloseWidgetIcon onClick={hideGamesList} />
    );
});
