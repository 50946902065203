import { action, computed, observable, makeObservable } from 'mobx';

// SIGNUP POPUPS
export class PopupAgreeOrCancel {
    public readonly type = 'agreeOrCancel';

    public constructor(
        public readonly title: string,
        public readonly content: string,
        public readonly agreeAction: () => void,
        public readonly cancelAction?: () => void
    ){}
}
export class PopupInfo {
    public readonly type = 'infoPopup';

    public constructor(
        public readonly title: string,
        public readonly closeIcon: boolean,
        public readonly popupText1: string,
        public readonly popupText2?: string
    ){}
}


type AllSignupPopupsTypes = PopupInfo | PopupAgreeOrCancel;

export class SignupPopupState {
    @observable public popups: AllSignupPopupsTypes | null = null;

    @action public show = (newpopup: AllSignupPopupsTypes): void => {
        this.popups = newpopup;
    };

    @action public hide = (): void => {
        this.popups = null;
    };

    public constructor() {
        makeObservable(this);
    }

    @computed public get isAnyPopup(): boolean {
        return this.popups !== null;
    }
}
