import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const CirclesWrapper = styled('div', { label: 'CirclesWrapper' })`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px 0;
`;

export const CirclesWrapperWhenNoArrow = styled(CirclesWrapper, { label: 'CirclesWrapperWhenNoArrow' })`
    padding: 22px 0 22px 0;
`;

interface CirclePropsType {
    isActive: boolean;
}

export const Circle = withConfig(theme => styled('div', { label: 'Circle' })<CirclePropsType>`
    align-items: center;
    border-radius: 50%;
    border: 2px solid ${theme.star.signUp.breadcrumbs.borderColor};
    color: currentcolor;
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    height: 36px;
    justify-content: center;
    margin: 0 0 0 28px;
    opacity: ${({ isActive }): string => isActive ? '1' : '0.4'};
    position: relative;
    width: 36px;
    &::before {
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        content: '••';
        display: flex;
        align-items: center;
        font-size: ${theme.star.fontSize.regular};
        line-height: 1;
        margin: auto 7px auto 0;
        white-space: nowrap;
        letter-spacing: 2px;
    }

    &:first-of-type {
        margin: 0;
        &::before {
            display: none;
        }
    }
`);

export const WarningTriangleIconWrapper = styled(WarningIcon, { label: 'WarningTriangleIconWrapper' })`
    width: 16px;
    margin-bottom: 4px;
    fill: currentcolor;
`;

export const CheckIconWrapper = withConfig(theme => styled(CheckSingleIcon, { label: 'CheckIconWrapper' })`
    width: 16px;
    fill: ${theme.star.signUp.breadcrumbs.bgColor};
`);
