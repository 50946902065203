import { action, computed, makeObservable, observable } from 'mobx';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { EventsCollectionState } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionState';
import { calculateRaceStatusFromEventItem } from 'src/domains/sportsbook/utils/filterRacesWithFinishState';
import { DateTime } from 'src_common/utils/time/time';

interface PropsType {
    sport: 'horseracing' | 'greyhoundracing';
    timezone?: string;
    racecardBuildIds: Array<number>;
    racecardCollection: 'next-off' | 'race-meetings' | number | null;
    racecardSelected: number | null;
}
const MOVE_A_TO_LOWER_INDEX = -1;
const MOVE_B_TO_LOWER_INDEX = 1;

const compareEvents = (a: EventModel, b: EventModel): number => {
    const time1 = a.timeSettingsStartTimeUnixMs;
    const time2 = b.timeSettingsStartTimeUnixMs;

    if (time2 > time1) {
        return MOVE_A_TO_LOWER_INDEX;
    }
    if (time1 > time2) {
        return MOVE_B_TO_LOWER_INDEX;
    }

    return 0;
};

const sortRaces = (racesIn: Array<EventModel>): Array<EventModel> => {
    const races = racesIn.concat([]);
    races.sort(compareEvents);
    return races;
};

interface EventsFilterOption {
    readonly isActive: boolean;
    readonly label: string;
    readonly key: 'next-off' | 'race-meetings' | number;
    readonly isArrowLeft?: boolean;
}

export type HorseRacingSortOrderType = 'by-price' | 'by-place' | null;

export class RaceCompetitionSetOrderState {
    @observable public sortOrder: HorseRacingSortOrderType = null;

    public constructor() {
        makeObservable(this);
    }

    @action.bound public setSortOrder = (sort: HorseRacingSortOrderType): void => {
        this.sortOrder = sort;
    };
}

export class RaceCompetitionState {
    private readonly models: ModelsState;
    private readonly language: LanguagesState;
    public readonly starRouter: StarRouter;
    private readonly eventsCollectionState: EventsCollectionState;
    private readonly props: PropsType;

    public constructor(
        models: ModelsState,
        language: LanguagesState,
        starRouter: StarRouter,
        eventsCollectionState: EventsCollectionState,
        props: PropsType
    ) {
        this.models = models;
        this.language = language;
        this.starRouter = starRouter;
        this.eventsCollectionState = eventsCollectionState;
        this.props = props;
    }

    @computed public get racesCollection(): EventsCollectionList {
        return this.eventsCollectionState.getRacesForWeek(this.props.sport);
    }

    @computed public get currentCategory(): 'next-off' | 'race-meetings' | number {
        return this.props.racecardCollection ?? 'next-off';
    }

    @computed.struct public get racecardBuildIds(): Array<number> {
        const eventsModel: Array<EventModel> = [];

        for (const eventId of this.props.racecardBuildIds) {
            const eventModel = this.models.getEvent(eventId);
            if (eventModel !== null) {
                eventsModel.push(eventModel);
            }
        }
        eventsModel.sort((a: EventModel, b: EventModel) => {
            return a.timeSettingsStartTimeUnixMs - b.timeSettingsStartTimeUnixMs;
        });

        return eventsModel.map((item) => item.id);
    }

    @computed private get eventsBeforeSortSimple(): Array<number> {
        const eventItems = this.racesCollection.eventItems ?? [];

        if (this.currentCategory === 'next-off') {
            return eventItems
                .filter((item) => calculateRaceStatusFromEventItem(item) !== 'RaceStatusFinished')
                .map((item) => item.id);
        }

        if (this.currentCategory === 'race-meetings') {
            return this.racecardBuildIds;
        }

        return this.racesCollection.filterByCompetition(
            (competitionId: number) => competitionId === this.currentCategory
        ).ids;
    }

    @computed private get eventsBeforeSort(): Array<EventModel> {
        const result = [];
        for (const id of this.eventsBeforeSortSimple) {
            const eventModel = this.models.getEvent(id);
            if (eventModel !== null) {
                result.push(eventModel);
            }
        }
        return result;
    }

    @computed public get events(): Array<EventModel> {
        const events = this.eventsBeforeSort.concat([]);
        return sortRaces(events);
    }

    @computed public get currentEventId(): number | null {
        const events = this.events;

        const eventId = this.props.racecardSelected;

        for (const event of events) {
            if (event.id === eventId) {
                return eventId;
            }
        }

        //get first
        for (const event of events) {
            return event.id;
        }

        return null;
    }

    @computed public get sport(): 'horseracing' | 'greyhoundracing' {
        return this.props.sport;
    }

    @computed public get optionsForView(): Array<EventsFilterOption> {
        const currentCategory = this.currentCategory;
        const out: Array<EventsFilterOption> = [];
        const racecardBuildIds = this.racecardBuildIds;
        const { getTranslation } = this.language;

        if (racecardBuildIds.length > 0) {
            const isActive = currentCategory === 'race-meetings';

            out.push({
                isActive: isActive,
                label: getTranslation('events.racecard.filters.race-meetings', 'Racecard Builder'),
                key: 'race-meetings',
            });
        }

        const nextOffIsActive = currentCategory === 'next-off';

        out.push({
            isActive: nextOffIsActive,
            label: getTranslation('events.racecard.filters.next-off', 'Next Off'),
            key: 'next-off',
        });

        const competitions = this.racesCollection?.competitionForViewSortByName ?? [];

        for (const competitionItem of competitions) {
            const { id, name } = competitionItem;
            const isActive = currentCategory === id;

            out.push({
                isActive: isActive,
                label: name,
                key: id,
            });
        }

        return out;
    }

    @computed public get displayCompetition(): boolean {
        if (this.currentCategory === 'next-off') {
            return true;
        }

        if (this.currentCategory === 'race-meetings') {
            return true;
        }

        return false;
    }

    @computed public get todayDate(): string {
        const date = DateTime.current().addDays(1).format('YYYY-MM-DD');
        return date;
    }

    @computed public get tomorrowDate(): string {
        const date = DateTime.current().addDays(1).format('YYYY-MM-DD');
        return date;
    }

    @computed public get afterTomorrowDate(): string {
        const date = DateTime.current().addDays(2).format('YYYY-MM-DD');
        return date;
    }

    @computed public get todayEvents(): Array<EventModel> {
        const out: Array<EventModel> = [];
        const races = this.events;
        for (const race of races) {
            const startTime = new Date(race.timeSettingsStartTime);
            const today = new Date(this.todayDate);

            if (startTime <= today) {
                out.push(race);
            }
        }
        return out;
    }

    @computed public get tomorrowEvents(): Array<EventModel> {
        const out: Array<EventModel> = [];
        const races = this.events;

        for (const race of races) {
            const startTime = new Date(race.timeSettingsStartTime);
            const tomorrow = new Date(this.tomorrowDate);
            const afterTomorrow = new Date(this.afterTomorrowDate);

            if (startTime >= tomorrow && startTime < afterTomorrow) {
                out.push(race);
            }
        }

        return out;
    }

    @computed public get thisWeekEvents(): Array<EventModel> {
        const out: Array<EventModel> = [];
        const races = this.events;

        for (const race of races) {
            const startTime = new Date(race.timeSettingsStartTime);
            const afterTomorrow = new Date(this.afterTomorrowDate);

            if (startTime >= afterTomorrow) {
                out.push(race);
            }
        }

        return out;
    }

    public onBackClick = (): void => {
        this.starRouter.redirectToRacingSport(this.props.sport);
    };
}
