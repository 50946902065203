import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { computed, makeObservable } from 'mobx';
import { getTeamShirt } from 'src/domains/sportsbook/webview/components/eventsList/eventRowMLB/MLBTeamLogos';
import { AmericanSportMatchLeadStateStrategy } from 'src/domains/sportsbook/webview/components/matchLead/americanSportMatchLead/AmericanSportMatchLeadStateStrategy';
import { DateTime } from 'src_common/utils/time/time';

export class MatchLeadMLBState implements AmericanSportMatchLeadStateStrategy {

    public constructor(
        private readonly event: EventModel
    ) {
        makeObservable(this);
    }

    @computed public get isStarted(): boolean {
        return this.event.timeSettingsStarted;
    }

    @computed private get startedTime(): DateTime | null{
        return DateTime.from(this.event.timeSettingsStartTime);
    }

    @computed public get timeToStart(): string | null {
        if (this.isStarted === false) {
            const startedTime = this.startedTime;
            const date = startedTime?.format('ddd Do MMM');
            const time = startedTime?.format('HH:mm');
            if (date === undefined || time === undefined) {
                return null;
            }
            return `${date} | ${time}`;
        }
        return null;
    }

    @computed public get homeTeamName(): string {
        return this.getNonBreakingName(this.event.homeParticipant ?? '');
    }

    @computed public get awayTeamName(): string {
        return this.getNonBreakingName(this.event.awayParticipant ?? '');
    }

    private getNonBreakingName(teamName: string): string {
        const nbsp = '\u00A0';
        switch (teamName) {
            case 'Boston Red Sox':
                return `Boston Red${nbsp}Sox`;
            case 'Chicago White Sox':
                return `Chicago White${nbsp}Sox`;
            case 'Kansas City Royals':
                return `Kansas${nbsp}City Royals`;
            case 'Los Angeles Angels':
                return `Los${nbsp}Angeles Angels`;
            case 'Los Angeles Dodgers':
                return `Los${nbsp}Angeles Dodgers`;
            case 'New York Mets':
                return `New${nbsp}York Mets`;
            case 'New York Yankees':
                return `New${nbsp}York Yankees`;
            case 'San Diego Padres':
                return `San${nbsp}Diego Padres`;
            case 'San Francisco Giants':
                return `San${nbsp}Francisco Giants`;
            case 'St. Louis Cardinals':
                return `St.${nbsp}Louis Cardinals`;
            case 'Tampa Bay Rays':
                return `Tampa Bay${nbsp}Rays`;
            case 'Toronto Blue Jays':
                return `Toronto Blue${nbsp}Jays`;
            default:
                const splittedName = teamName.split(' ');
                if (splittedName.length > 2) {
                    splittedName.splice(1, 0, nbsp);
                    splittedName.splice(3, 0, ' ');
                    return splittedName.join('');
                }
                return teamName;
        }
    }

    public get homeTeamLogo():string {
        return getTeamShirt(this.homeTeamName);
    }

    public get awayTeamLogo():string {
        return getTeamShirt(this.awayTeamName);
    }

    @computed public get homeScore(): string {
        return this.event.scoreHome?.toString() ?? '0';
    }

    @computed public get awayScore(): string {
        return this.event.scoreAway?.toString() ?? '0';
    }


}
