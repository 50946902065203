import React, { ReactElement } from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, GameDataState } from 'src/domains/casino/state/AppCasino.state';
import { GameContent } from './game-content/GameContent';
import { BingoContent } from './bingo-content/BingoContent';

interface ModalSuccessPropsType {
    appCasinoState: AppCasinoState;
    url: string;
    gameDataState: GameDataState;
}

export const ModalSuccess = observer('ModalSuccess', (props: ModalSuccessPropsType): ReactElement => {
    const { appCasinoState, url, gameDataState } = props;

    if (props.gameDataState.gameType === 'bingo') {
        return (
            <BingoContent
                appCasinoState={appCasinoState}
                url={url}
                gameDataState={gameDataState}
            />
        );
    }

    return (
        <GameContent
            appCasinoState={appCasinoState}
            url={url}
            gameDataState={gameDataState}
        />
    );
});
