import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { SendCpfResponse, SignUpWelcomeState } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupWelcome/SignupWelcome.state';
import { YourDetailsFormType, YourDetailsState } from 'src/domains/players/webview/components/SignUp/signupTertiary/yourDetails/YourDetails.state';
import { action, observable, makeObservable } from 'mobx';
import { getCookie, setCookie } from 'src/domains/layouts/config/config';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { isCountryCodeType } from 'src/domains/layouts/config/countries';
import { EmailAndPasswordFormType } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/createAccount/EmailAndPassword.state';
import { createAccountLatam, verifyCpf , createAccountPrimary } from 'src_server/trpc/types';
import { SignUpParamsType } from 'src/domains/players/webview/components/SignUp/signupTertiary/signupState/SignUpParams';
import { CurrencyType, isCurrencyCode } from 'src_common/common/amount/website-money/currency';

const AccountAgeVerificationEnum = createAccountPrimary.AccountAgeVerificationEnum;
const AccountStatusEnum = createAccountPrimary.AccountStatusEnum;

interface SignUpStepsGroupModel {
    welcomeState: SendCpfResponse;
    yourDetailsState: YourDetailsFormType;
    emailAndPasswordState: EmailAndPasswordFormType;
}

interface CallbacksType {
    readonly redirectToVerificationFailed: () => void;
    readonly redirectToNextStep: () => void;
    readonly setLoading: (isLoading: boolean) => void;
    readonly toggleAside: () => Promise<void>;
    readonly resetPromoCodeInLocalStorage: () => void;
}

export class CreateAccountState {
    @observable public backendCreateAccountError: string | null = null;

    public welcomeState: SignUpWelcomeState;
    public yourDetailsState: YourDetailsState;

    @observable.ref public signUpFormModel: FormModel<createAccountLatam.CreateAccountLatamInput['body']>;
    @observable.ref private signUpStepsGroup: FormModel<SignUpStepsGroupModel>;

    public constructor(
        public readonly params: SignUpParamsType,
        private readonly callbacks: CallbacksType,
        private readonly initialUserData?: verifyCpf.VerifyCpfInput['body']
    ) {
        makeObservable(this);

        this.welcomeState = new SignUpWelcomeState(this.params.customKeyboard, this.moveToNextStage, params.language, this.params.trpc, this.callbacks.setLoading, this.initialUserData);
        this.yourDetailsState = new YourDetailsState(this.params.customKeyboard, this.params.language, this.params.config, () => this.welcomeState.cpfResponseFormModel);
        this.signUpStepsGroup = FormModel.group({
            welcomeState: this.welcomeState.cpfResponseFormModel,
            emailAndPasswordState: this.yourDetailsState.emailAndPasswordState.emailAndPasswordModel,
            yourDetailsState: this.yourDetailsState.yourDetailsFormModel,
        });

        this.signUpFormModel = this.signUpStepsGroup.map((value): Result<createAccountLatam.CreateAccountLatamInput['body']> => {
            const btag = getCookie('btag');
            const stag = getCookie('stag');
            const referrer = getCookie('referrer');

            const mobilePhoneCountryKey = this.yourDetailsState.phoneNumber.prefix.currentValueFormatted?.key ?? '';
            const currency = value.yourDetailsState.currency;
            const defCurrency: CurrencyType = 'EUR';

            const createAccountData: createAccountLatam.CreateAccountLatamInput['body'] = {
                cpf: value.welcomeState.cpf,
                birthDate: value.welcomeState.birthDate,
                email: value.emailAndPasswordState.email,
                password: value.emailAndPasswordState.password,
                currency: isCurrencyCode(currency) ? currency : defCurrency,
                contactPreferences: value.yourDetailsState.contactPreferences,
                mobilePhone: {
                    country: isCountryCodeType(mobilePhoneCountryKey) ? mobilePhoneCountryKey : 'GB',
                    prefix: value.yourDetailsState.prefix.replace('+', ''),
                    number: value.yourDetailsState.phoneNumber
                },
                referrer: referrer ?? undefined,
                incomeaccess: value.yourDetailsState.affiliateId ?? btag ?? stag ?? undefined,
                promoID: value.yourDetailsState.promoCode,
            };

            return Result.createOk(createAccountData);
        });
    }

    @action private onCreateAccount = async (): Promise<boolean> => {
        this.signUpStepsGroup.setAsVisited();
        const fullFormResult = this.signUpFormModel.result;
        await this.welcomeState.promoCodesState.promoCodesResource.refresh();

        if (fullFormResult.value.type === 'ok') {
            this.yourDetailsState.loadingCreateAccountReq = true;
            const email = this.yourDetailsState.emailAndPasswordState.emailState.value;
            const password = this.yourDetailsState.emailAndPasswordState.passwordState.value;

            if (this.welcomeState.promoCodesState.promoCodes.type === 'ready') {
                const isPromoCodeValid = this.welcomeState.promoCodesState.checkIfCodeExistsAndIsValid(this.yourDetailsState.promoCodeTextState.value);

                if (isPromoCodeValid === false) {
                    const message = this.params.language.getTranslation('sign-up.create-account.promo-code.error', 'This promo code is not valid');
                    this.backendCreateAccountError = message;
                    this.yourDetailsState.loadingCreateAccountReq = false;
                    return false;
                }
            }
            const createAccountResponse = await this.params.trpc.client.signup.createAccountLatam.mutate({ body: fullFormResult.value.data });

            this.yourDetailsState.loadingCreateAccountReq = false;

            if (createAccountResponse.responseStatus === 'error') {
                const arrEl = createAccountResponse.data.errors[0];
                if (arrEl?.field !== undefined && arrEl?.field !== null) {
                    if ((arrEl.debugDetails !== undefined && arrEl?.debugDetails !== null) || (arrEl.code !== null && arrEl.code !== undefined)) {
                        const description = arrEl.debugDetails ?? arrEl.code ?? 'error';
                        this.backendCreateAccountError = `In ${arrEl.field} - ${description}`;

                    }
                }
                console.error('onCreateAccount - error1', createAccountResponse.data);
                return false;
            } else if (createAccountResponse.responseStatus === 'success' && this.yourDetailsState.promoCodeTextState.value !== '') {
                await this.welcomeState.promoCodesState.updatePromoCode(this.yourDetailsState.promoCodeTextState.value);
            }

            if (createAccountResponse.responseStatus === 'success') {
                this.params.router.clearBtagAndStag();

                setCookie('btag', '', 0, 'None');
                setCookie('stag', '', 0, 'None');
            }

            this.callbacks.setLoading(true);
            this.callbacks.resetPromoCodeInLocalStorage();

            for (let i = 0; i < 150; i++) {////7min try login user, waiting for account verify.
                const accountStatus = await this.params.trpc.client.signup.checkStatusOfAccount.mutate({ body: { token: createAccountResponse.data.token } });
                if (accountStatus.responseStatus === 'success'){
                    const isBlockedAccount = accountStatus.data.status === AccountStatusEnum.BLOCKED;
                    const isActiveAccount = accountStatus.data.status === AccountStatusEnum.ACTIVE;
                    const isSuspendedAccount = accountStatus.data.status === AccountStatusEnum.SUSPENDED;
                    const isAgeVerificationPassed = accountStatus.data.ageVerification === AccountAgeVerificationEnum.PASSED;
                    const isAvVerificationFailed = accountStatus.data.ageVerification === AccountAgeVerificationEnum.FAILED;
                    const isAvVerificationUnknown = accountStatus.data.ageVerification === AccountAgeVerificationEnum.UNKNOWN;

                    if (isSuspendedAccount || isBlockedAccount) {
                        const loginUser = await this.params.accountState.loginUser(email, password, 'registration');

                        if (loginUser.type === 'CreateSessionResponseOk') {
                            this.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                        }

                    }

                    if ((this.params.config.config.signUpOmitAgeVerification === true || isAgeVerificationPassed) && isActiveAccount) {
                        const loginUser = await this.params.accountState.loginUser(email, password, 'registration');

                        if (this.params.config.config.ringFencedFundsSetByDefault === true) {
                        // set by default RingFencedFlag for new user
                            await this.params.accountState.account?.onChangeRingFencedFlag();
                        }
                        if (loginUser.type === 'CreateSessionResponseOk') {
                            this.params.googleTagManager.registerFinishedTag(loginUser.accountId);
                        }
                        return true;
                    }

                    if (isBlockedAccount || isSuspendedAccount || isAvVerificationFailed || isAvVerificationUnknown) {
                        this.callbacks.setLoading(false);
                        this.callbacks.redirectToVerificationFailed(); //TODO
                        return false;
                    }

                }
                await timeout(3000);
            }
        }

        return false;
    };

    @action public createAccount = async (): Promise<void> => {
        this.yourDetailsState.yourDetailsFormModel.setAsVisited();

        const createAccountStatus = await this.onCreateAccount();
        if (createAccountStatus) {
            this.callbacks.setLoading(false);
            await this.callbacks.toggleAside();
        }
    };

    @action public moveToNextStage = (): void => {
        this.callbacks.redirectToNextStep();
        this.yourDetailsState.setCpfResponse();
    };
}

