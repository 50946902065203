import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';
import * as t from 'io-ts';
import { PriceIO } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';

const FreeBetCreditsIO = t.interface({
    id: t.number,
    amount: t.number,
});

const ReferralParsedLegsIO = t.interface({
    type: t.string,
    price: t.union([PriceIO, t.null, t.undefined]),
    priceType: t.string,
    index: t.union([t.number, t.string]),
    stakePerLine: t.string,
    eachWay: t.boolean,
    empty: t.union([t.boolean, t.null, t.undefined]),
    selectionName: t.union([ t.string, t.undefined ]),
    eventName: t.union([ t.string, t.undefined ]),
    id: t.union([t.string, t.number, t.null]),
    eventId: t.union([t.number, t.null]),
    marketId: t.union([t.number, t.null]),
    marketName: t.union([t.string, t.null, t.undefined]),
    selectionId: t.union([t.number, t.null]),
    betEventId: t.union([t.number, t.null]),
    betEventName: t.union([t.string, t.null, t.undefined]),
    potentialReturns: t.union([t.string, t.null, t.undefined]),
});

export type ReferralParsedLegsType = t.TypeOf<typeof ReferralParsedLegsIO>;

const ParsedCombinationsIO = t.interface({
    type: t.string,
    stakePerLine: t.string,
    eachWay: t.boolean,
    ip: t.union([ t.string, t.null ]),
    potentialReturns: t.union([ t.string, t.null ]),
    freebetCredits: t.union([ t.array(FreeBetCreditsIO), t.undefined]),
});


const ParsedReferredBetsIO = t.interface({
    combinations: t.record(t.string, ParsedCombinationsIO),
    legs: t.record(t.string, ReferralParsedLegsIO),
    channel: t.string,
    expiresAt: t.union([ t.string, t.null, t.undefined ]),
    type: t.string
});

export type ParsedReferredBetsType = t.TypeOf<typeof ParsedReferredBetsIO>;

export const decodeResponseGetReferredBets = buildValidator('API - getReferredBets', ParsedReferredBetsIO, true);
