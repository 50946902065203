import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    MatchTime,
    Team,
    TeamImg,
    TeamName,
    TeamsWrapper,
    VersusSign
} from './AmericanSportMatchLead.style';
import { AmericanSportMatchLeadStateStrategy } from './AmericanSportMatchLeadStateStrategy';

interface PropsType {
    state: AmericanSportMatchLeadStateStrategy;
}

export const AmericanSportPreMatch = observer<PropsType>('AmericanSportPreMatch', ({ state }) => {
    const homeName = state.homeTeamName;
    const awayName = state.awayTeamName;

    return (
        <>
            <MatchTime isPreMatch={true}>{ state.timeToStart }</MatchTime>

            <TeamsWrapper>
                <Team isHome={true}>
                    <TeamName>{homeName}</TeamName>
                    <TeamImg decoding='async' src={state.homeTeamLogo} alt={`${homeName} logo`} />
                </Team>
                
                <VersusSign>V</VersusSign>

                <Team isHome={false}>
                    <TeamName>{awayName}</TeamName>
                    <TeamImg decoding='async' src={state.awayTeamLogo} alt={`${awayName} logo`} />
                </Team>
            </TeamsWrapper>
        </>
    );
});
