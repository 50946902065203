import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

export const RejectedByCustomerMessage = observer('RejectedByCustomerMessage', () => {
    return (
        <Messages
            type='info'
            message={<I18n langKey='betslip.offer.user-rejected' defaultText='You rejected trader offer' />}
        />
    );
});
