import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, makeObservable } from 'mobx';
import { AlternativeEventDetailsItemState } from './AlternativeEventDetailsItemState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ModelsState } from 'src_common/common/websocket2/ModelsState';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getEventDetailsListActive';
import { AutoMapSerialized } from 'src_common/common/mobx-utils/AutoMap';

export type AlternativeEventDetailsModelType = Response200Type extends Array<infer Model> ? Model : never;

interface CallbacksType {
    onRedirectToPromoTermsAndConditions: () => void;
    getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string;
}

export class AlternativeEventState {
    private readonly alternativeActiveEventResource: Resource<Array<AlternativeEventDetailsModelType>>;
    private readonly alternativeActiveEventDetailsMap: AutoMapSerialized<EventId, AlternativeEventDetailsItemState>;

    public constructor(
        private readonly models: ModelsState,
        private readonly configComponents: ConfigComponents,
        private readonly currentViewEventId: EventId | null,
        private readonly trpcClient: TrpcClient,
        callbacks: CallbacksType
    ) {
        makeObservable(this);
        this.alternativeActiveEventResource = new Resource(async () => {
            const response = await this.trpcClient.client.cms.getAlternativeEventDetailsActive.query();
            return response.body;
        });

        this.alternativeActiveEventDetailsMap = new AutoMapSerialized(
            (eventId: EventId) => eventId.rawId,
            (eventId: EventId): AlternativeEventDetailsItemState =>
                new AlternativeEventDetailsItemState(
                    this.configComponents,
                    models,
                    this.getAlternativeEventRawById,
                    eventId,
                    {
                        onRedirectToPromoTermsAndConditions: callbacks.onRedirectToPromoTermsAndConditions,
                        getTranslation: callbacks.getTranslation,
                    }
                )
        );
    }

    public getAlternativeEventRawById = (eventId: EventId): AlternativeEventDetailsModelType | null => {
        return (
            this.alternativeActiveEventDetailsList.find(
                (elem) => this.models.id.getEventId(elem.event_id) === eventId
            ) ?? null
        );
    };

    public getAlternativeEventItemById = (eventId: EventId): AlternativeEventDetailsItemState => {
        return this.alternativeActiveEventDetailsMap.get(eventId);
    };

    @computed public get isAlternativeEvent(): boolean {
        if (this.eventId !== null) {
            return this.alternativeActiveEventDetailsIds.includes(this.eventId);
        }

        return false;
    }

    @computed public get eventId(): EventId | null {
        return this.currentViewEventId;
    }

    @computed public get alternativeActiveEventDetailsList(): Array<AlternativeEventDetailsModelType> {
        const notifications = this.alternativeActiveEventResource.get();
        if (notifications.type === 'ready') {
            return notifications.value;
        }

        return [];
    }

    @computed public get alternativeActiveEventDetailsIds(): Array<EventId> {
        return this.alternativeActiveEventDetailsList.map((elem) => this.models.id.getEventId(elem.event_id));
    }

    @computed public get isErrorWithGetData(): boolean {
        const notifications = this.alternativeActiveEventResource.get();
        if (notifications.type === 'error') {
            return true;
        }

        return false;
    }

    @computed public get isLoading(): boolean {
        const notifications = this.alternativeActiveEventResource.get();
        if (notifications.type === 'loading') {
            return true;
        }

        return false;
    }

    public divideByColumns = (
        divider: number | null,
        selectionsToRender: SelectionModel[]
    ): Array<Array<SelectionModel>> => {
        const widthInner = getWindowInnerWidth();
        if (widthInner !== null && widthInner > 768) {
            if (divider === 3) {
                const thirdCol: Array<SelectionModel> = [];
                const secondCol: Array<SelectionModel> = [];
                const firstCol: Array<SelectionModel> = [];
                selectionsToRender.map((selection: SelectionModel, index: number) => {
                    if (index % 3 === 0) {
                        firstCol.push(selection);
                    } else if (index % 3 === 1) {
                        secondCol.push(selection);
                    } else if (index % 3 === 2) {
                        thirdCol.push(selection);
                    }
                });
                return [firstCol, secondCol, thirdCol];
            } else if (divider === 2) {
                const secondCol: Array<SelectionModel> = [];
                const firstCol: Array<SelectionModel> = [];
                selectionsToRender.map((selection: SelectionModel, index: number) => {
                    if (index % 2 === 0) {
                        firstCol.push(selection);
                    } else if (index % 2 === 1) {
                        secondCol.push(selection);
                    }
                });
                return [firstCol, secondCol, []];
            } else {
                const firstCol = selectionsToRender;
                return [firstCol];
            }
        } else {
            const firstCol = selectionsToRender;
            return [firstCol];
        }
    };

    public checkIsAlternativeEvent = (): boolean => {
        if (this.eventId === null) {
            return false;
        }
        return this.alternativeActiveEventDetailsIds.includes(this.eventId);
    };
}
