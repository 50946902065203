import React from 'react';
/* eslint-disable */
import { useAsObservableSource } from 'mobx-react-lite';
import { AccountTab } from 'src/domains/players/webview/components/Account/accountParts/accountTab/AccountTab';
import { TabWrapper } from './Account.style';
import { AccountDrawerHeader } from 'src/domains/players/webview/components/Account/accountDrawerHeader/AccountDrawerHeader';
import { TabDataType } from 'src/domains/players/webview/components/Account/accountDrawer/AccountTabsBuilder.state';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';

interface TabSubComponentPropsType {
    tabData: TabDataType;
}

const TabHeader = observer('TabHeader', (propsIn: TabSubComponentPropsType) => {
    const props = useAsObservableSource(propsIn);
    const { header, parent } = props.tabData;
    const { session } = useAppStateContext();

    if (header !== undefined) {
        if (header.component !== undefined) {
            return React.createElement(header.component, null, [header.title]);
        }

        return (
            <AccountDrawerHeader
                title={header.title}
                icon={header.icon}
                hasClose={header.close ?? true}
                hasBack={(header.back === true && parent !== undefined) ?? false}
                isSmallerVersion={header.isSmallerVersion}
                parent={parent}
                isAuthorized={session.isAuthorized}
                key='tab-header'
            />
        );
    }

    return null;
});

const TabContent = observer('TabContent', (props: TabSubComponentPropsType) => {
    const { tabData } = props;
    const componentJsx = tabData.component();

    return <AccountTab>{componentJsx}</AccountTab>;
});

interface TabPropsType {
    tabName: RightHandSideViewType | null;
    showHeader: boolean;
    tabData: TabDataType | null;
    tabDataForHeader: TabDataType | null;
}

export const Tab = observer('Tab', (props: TabPropsType) => {
    const { tabName, showHeader, tabData, tabDataForHeader } = props;

    const { appLayoutsState } = useAppStateContext();
    const { starRouter } = appLayoutsState;

    //TODO - Temporary solution needed to be able to migrate to the new acountDrawer.
    //When the new acountDrawer is active, it will be possible to remove this reference in favour of information from the new acountDrawer state
    const account = starRouter.accountView?.account;
    const isActive = tabName?.account === account;
    const dataAccountDrawerTab = isActive ? 'account-drawer-tab-visible' : 'account-drawer-tab-hidden';

    let tabHeader = null;
    if (tabDataForHeader !== null && showHeader) {
        tabHeader = (
            <TabHeader
                key='tab-header'
                tabData={tabDataForHeader}
            />
        );
    }

    const tabContent = tabData === null ? null : <TabContent tabData={tabData} />;

    return (
        <TabWrapper
            key={`tab-${tabName?.account ?? ''}`}
            data-test={dataAccountDrawerTab}
        >
            {tabHeader}
            {tabContent}
        </TabWrapper>
    );
});
