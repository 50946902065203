import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { JackpotBarWrapper } from './JackpotBar.style';

interface PropsType {
    appCasinoState: AppCasinoState;
    gameModel: CasinoGameModelExt;
    isBigTile: boolean;
    showJackpotBar?: boolean;
}

export const JackpotBar = observer('JackpotBar', (props: PropsType) => {
    const { appCasinoState } = props;

    if (props.showJackpotBar === false) {
        return null;
    }

    const jackpotModel = appCasinoState.jackpotsSocket.getModel(props.gameModel.id);

    if (jackpotModel === null) {
        return null;
    }

    return (
        <JackpotBarWrapper
            isBigTile={props.isBigTile}
        >
            {jackpotModel.amount}
        </JackpotBarWrapper>
    );
});
