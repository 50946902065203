import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    CashBalance,
    CashBalanceWrapper,
    DepositTabWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/DepositProcedure.style';
import { PraxisDepositButton } from './PraxisTab.style';
import { PraxisTabState } from './PraxisTab.state';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    state: PraxisTabState;
}

export const PraxisTab = observer('PraxisTab', ({ state }: PropsType): JSX.Element => {
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();

    return (
        <DepositTabWrapper data-test='praxis-tab-wrapper'>
            <CashBalanceWrapper data-test='praxis-tab-cash-balance'>
                <I18n langKey='account.top-up.cash-balance-label' defaultText='Cash balance' />
                <CashBalance>{state.showBalance}</CashBalance>
            </CashBalanceWrapper>

            <Input
                type='text'
                maxLength={10}
                placeholder='0.00'
                currency={usersState.moneySymbol}
                label={<I18n langKey='account.top-up.description-label' defaultText='Deposit amount' />}
                state={state.depositAmount}
                onChange={state.onChange}
                onBlur={state.setAmount}
                colorTheme='light'
                inputHasBackground={false}
                dataTest='deposit-amount-input'
            />

            <PaymentAdditions
                key='payment-additions'
                currency={usersState.moneySymbol}
                value={state.depositAmount.value}
                onChange={state.handleAdditionsChange}
            />

            {state.errorMessage.length > 0
                ? state.errorMessage.map((errorString) => (
                      <Messages type='error' marginTop='8px' key={errorString} message={errorString} />
                  ))
                : null}

            <PraxisDepositButton
                size='large'
                onClick={state.submitDepositForm}
                isLoading={state.isRequesting}
                disabled={state.isButtonDisabled}
                dataTest='praxis-make-deposit-button'
            >
                <I18n langKey='account.deposit.iframe.deposit' defaultText='Make Deposit' />
            </PraxisDepositButton>
        </DepositTabWrapper>
    );
});
