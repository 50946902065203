import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { TwitterWidgetLink, TwitterWidgetWrapper } from 'src/domains/layouts/webview/components/twitterWIdget/TwitterWidget.style';

interface TwitterWidgetPropsType {
    isWidgetNotVisible: boolean;
    socialWidgetData: {
        url: string;
        theme: 'light' | 'dark';
    };
}

export const TwitterWidget = observer('TwitterWidget', ({ isWidgetNotVisible, socialWidgetData }: TwitterWidgetPropsType ): JSX.Element | null => {
    React.useEffect(() => {
        if (document.getElementById('twitter-widget-script') !== null) {
            return;
        }

        const widgetScript = document.createElement('script');
        widgetScript.type = 'text/javascript';
        widgetScript.async = true;
        widgetScript.id = 'twitter-widget-script';
        widgetScript.src = 'https://platform.twitter.com/widgets.js';
        document.body.appendChild(widgetScript);

        return () => {
            const existingScript = document.getElementById('twitter-widget-script');

            if (existingScript !== null) {
                document.body.removeChild(existingScript);
            }
        };
    }, []);

    return (
        <TwitterWidgetWrapper isWidgetNotVisible={isWidgetNotVisible}>
            <TwitterWidgetLink
                className='twitter-timeline'
                data-height='384'
                data-theme={socialWidgetData.theme}
                href={socialWidgetData.url}
            >Twitter widget.</TwitterWidgetLink>
        </TwitterWidgetWrapper>
    );
});
