import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { RouteViewType, isMainViewRouteEq } from './newRouter/mainRouteTypes';
import { StarRouter } from './StarRouter';

export class StarRouterActiveLink {
    public static get = AutoWeakMap.create((common: Common) => new StarRouterActiveLink(common));

    private constructor(private readonly common: Common) {}

    public isActive(to: RouteViewType): boolean {
        const currentView = StarRouter.get(this.common).currentView;
        if (currentView === null) {
            return false;
        }

        return isMainViewRouteEq(to, currentView);
    }
}
