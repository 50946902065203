import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState, CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { CasinoGamesLoader } from 'src/domains/casino/webview/CasinoGames/category-games/CasinoGamesLoader';
import { CasinoGridWrapper } from './CasinoGames.style';
import { SingleGameExt } from 'src/domains/casino/webview/CasinoGames/single-game-ext/SingleGameExt';

interface GamesListPropsType {
    appCasinoState: AppCasinoState;
    gameModels: Array<CasinoGameModelExt>;
}

const CasinoGamesList = observer(
    'CasinoGamesList',
    ({ appCasinoState, gameModels }: GamesListPropsType): React.ReactElement => {
        return (
            <>
                {gameModels.map((gameModel) => {
                    return (
                        <SingleGameExt
                            appCasinoState={appCasinoState}
                            key={gameModel.id}
                            gameModel={gameModel}
                        />
                    );
                })}
            </>
        );
    }
);

export interface CasinoGamesPropsType {
    appCasinoState: AppCasinoState;
    gameModels: Array<CasinoGameModelExt> | 'loading';
}

export const CasinoGames = observer('CasinoGames', (props: CasinoGamesPropsType): JSX.Element | null => {
    const { appCasinoState, gameModels } = props;

    if (gameModels === 'loading') {
        return <CasinoGamesLoader />;
    }

    if (gameModels.length === 0) {
        return null;
    }

    return (
        <CasinoGridWrapper>
            <CasinoGamesList
                appCasinoState={appCasinoState}
                gameModels={gameModels}
            />
        </CasinoGridWrapper>
    );
});
