import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Provider as ProviderAppState, AppState } from 'src/appState/AppState';
import { MessageBoxWrapper } from 'src/domains/players/webview/ui/MessageBoxWrapper';
import { ConfirmationsBoxWrapper } from 'src/domains/players/webview/ui/ConfirmationsBoxWrapper';
import { App } from 'src/domains/layouts/ui/ui';
import { Common, ProviderCommon } from './common/Common';

console.info('Create App module');

const renderMainContent = (): JSX.Element => {
    return (
        <>
            <App />
            <ConfirmationsBoxWrapper />
            <MessageBoxWrapper />
        </>
    );
};

interface PropsType {
    common: Common;
    appState: AppState;
}

export const VdomWrapper = observer('VdomWrapper', ({ common, appState }: PropsType) => {
    return (
        <ProviderAppState value={appState}>
            <ProviderCommon value={common}>{renderMainContent()}</ProviderCommon>
        </ProviderAppState>
    );
});

export const vdom = (common: Common, appState: AppState): React.ReactElement => {
    return (
        <VdomWrapper
            common={common}
            appState={appState}
        />
    );
};
