import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { PromoCode } from 'src/domains/players/webview/components/SignUp/signupParts/promoCode/PromoCode';
import { Header } from 'src/domains/players/webview/components/SignUp/signupParts/header/Header';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { PageSectionWrapper } from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { LoginBtnWrapper } from 'src/domains/players/webview/components/SignUp/signupPrimary/SignUp.styles';
import { LayoutSignUp } from 'src/domains/players/webview/components/SignUp/signupPrimary/SignupLayout';
import { SignUpWelcomeState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/signupWelcome/SignupWelcome.state';
import { AlreadyHaveAccount } from 'src/domains/players/webview/components/SignUp/signupParts/alreadyHaveAccount/AlreadyHaveAccount';
import { useAppStateContext } from 'src/appState/AppState';
import { SignupTypes } from 'src/domains/players/webview/components/SignUp';

interface SignUpWelcomePagePropsType {
    state: SignUpWelcomeState;
    signUpState: SignupTypes;
}

export const SignUpWelcomePage = observer('SignUpWelcomePage', ({ state, signUpState }: SignUpWelcomePagePropsType) => {
    const { config } = useAppStateContext();

    if (signUpState.type === 'SignupTertiary') {
        return <div>Wrong Signup!</div>;
    }

    return (
        <LayoutSignUp
            stepsCounter={false}
            footer={true}
            signUpState={signUpState.state}
        >
            <PageSectionWrapper>
                <Header />

                <Input
                    label={
                        <I18n
                            langKey='signup.welcome.email'
                            defaultText='Email'
                        />
                    }
                    state={state.emailState}
                    showSuccessIcon={state.isCorrectEmail}
                    dataTest='email-input'
                />

                <Input
                    label={
                        <I18n
                            langKey='signup.welcome.create-password'
                            defaultText='Create password'
                        />
                    }
                    state={state.passwordState}
                    type={state.isShowPassword ? 'text' : 'password'}
                    showPassword={state.showPassword}
                    showPasswordFlag={true}
                    isShowPassword={state.isShowPassword}
                    showSuccessIcon={state.isCorrectPassword}
                    autocomplete='new-password'
                    dataTest='create-password-input'
                />

                {config.signUpShowPromoCode ? (
                    <PromoCode
                        isPromoCode={state.isPromoCode}
                        setPromoCode={state.setPromoCode}
                        state={state}
                    />
                ) : null}

                <LoginBtnWrapper
                    disabled={!state.stepIsValid}
                    type='submit'
                    size='large'
                    onClick={state.onMoveToYourDetails}
                    dataTest='create-account-button'
                >
                    <I18n
                        langKey='signup.welcome.create-account'
                        defaultText='Create Account'
                    />
                </LoginBtnWrapper>

                <AlreadyHaveAccount />
            </PageSectionWrapper>
        </LayoutSignUp>
    );
});
