import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';

import {
    AccountNavLink,
    AccountNavText,
} from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { UserIconWrapper } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/accountLinkNav/AccountLinkNav.style';

export const AccountLinkNav = observer('AccountLinkNav', () => (
    <AccountNavLink
        dataTest='account-navigation-account'
        to={{ account: 'summary' }}
        routeNameForGtm='account-summary'
    >

        <UserIconWrapper />

        <AccountNavText>
            <I18n langKey='account.navigation.summary' defaultText='Account' />
        </AccountNavText>
    </AccountNavLink>
));
