import React from 'react';
import {
    ErrorModalInner,
    ErrorModalWrapper,
    ErrorModalHeader,
    ErrorModalContent,
    ErrorModalContentHeader,
    ErrorModalContentText,
    ErrorModalBtnWrapper,
    ErrorModalIconWrapper,
} from './ErrorLaunchGameModal.style';

interface PropsType {
    headerText: string | React.ReactElement;
    contentText: string | React.ReactElement;
    actionBtnText: string | React.ReactElement;
    onBtnClickAction(): void;
}

export const ErrorLaunchGameModal = ({
    headerText,
    contentText,
    actionBtnText,
    onBtnClickAction,
}: PropsType): JSX.Element => (
    <ErrorModalWrapper data-test='error-modal-wrapper'>
        <ErrorModalInner data-test='error-modal-inner'>
            <ErrorModalHeader className='error-modal-header'>
                <ErrorModalIconWrapper />
            </ErrorModalHeader>

            <ErrorModalContent data-test='error-modal-content'>
                <ErrorModalContentHeader data-test='error-modal-content-header'>{headerText}</ErrorModalContentHeader>
                <ErrorModalContentText
                    data-test='error-modal-content-text'
                    className='error-launch-modal__content'
                >
                    {contentText}
                </ErrorModalContentText>
                <div data-test='error-modal-content-button'>
                    <ErrorModalBtnWrapper
                        onClick={onBtnClickAction}
                        size='medium'
                    >
                        {actionBtnText}
                    </ErrorModalBtnWrapper>
                </div>
            </ErrorModalContent>
        </ErrorModalInner>
    </ErrorModalWrapper>
);
