import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { FiltersState } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { Filters } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { SpecialSportFilterStrategy } from 'src/domains/layouts/state/SpecialSportsFilterStrategy';
import { EventsListState } from 'src/domains/sportsbook/shared/Types';
import { SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { SportFilterStrategy } from 'src/domains/layouts/webview/components/sportHeader/SportHeaderFilters.state';
import { FiltersWrapper } from 'src/domains/layouts/webview/components/sportHeader/SportHeaderFilters.style';

const getStrategy = (
    isSpecialEvent: boolean | undefined,
    specialSportsState: SpecialSportsState,
    eventsListState: EventsListState
): SportFilterStrategy | SpecialSportFilterStrategy => {
    if (isSpecialEvent === true) {
        return new SpecialSportFilterStrategy(specialSportsState);
    } else {
        return new SportFilterStrategy(eventsListState);
    }
};

type RenderEventFiltersPropsType = {
    specialSportsState: SpecialSportsState;
    isSpecialEvent: boolean | undefined;
    isCompetitionPage: boolean;
    sportName: string | null;
    competitionId: CompetitionId | null;
};

export const RenderEventFilters = observer('renderEventFilters', (props: RenderEventFiltersPropsType) => {
    const { appSportsBookState } = useAppStateContext();

    const eventsListState = appSportsBookState.eventsListState.get(props.competitionId);
    const filterStrategy = getStrategy(props.isSpecialEvent, props.specialSportsState, eventsListState);
    const [filtersState] = useState(new FiltersState(filterStrategy));

    if (props.isCompetitionPage === true) {
        return null;
    }

    return (
        <FiltersWrapper>
            <FiltersScrollableWrapper>
                <Filters state={filtersState} />
            </FiltersScrollableWrapper>
        </FiltersWrapper>
    );
});
