import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { Amount } from 'src_common/common/amount/Amount';

export const validateRequire = (value: string): Result<string> => {
    if (value.trim().length < 1) {
        return Result.createError(getErrorByCode('ERROR_REQUIRED_FIELD'));
    }

    return Result.createOk(value);
};

export const validateRequireIfWasTouched = (value: string, wasTouched: boolean): Result<string> => {
    if (wasTouched) {
        if (value.trim().length < 1) {
            return Result.createError(getErrorByCode('ERROR_REQUIRED_FIELD'));
        } else {
            return Result.createOk(value);
        }
    }

    return Result.createOk(value);
};

export const validateNotRequired = (value: string): Result<string> => {
    return Result.createOk(value);
};

/**
 * validator for a amount as a string
 */
export const validateAmountRequire = (value: string): Result<Amount> => {
    if (value.trim() === '') {
        return Result.createError(getErrorByCode('ERROR_REQUIRED_FIELD'));
    }
    try {
        return Result.createOk(new Amount(value));
    } catch (e) {
        return Result.createError(getErrorByCode('ERROR_DECIMAL'));
    }
};

/**
 * validator for a amount pattern as a string
 */
export const validateAmountPattern = (amount: Amount): Result<Amount> => {
    const hasPricePattern = /^\d+(\.\d{1,2})?$/.test(amount.value);
    if (!hasPricePattern) {
        return Result.createError(getErrorByCode('ERROR_DECIMAL'));
    }
    return Result.createOk(amount);
};
