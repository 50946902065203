import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { SetNewEmailGroupModelTypes } from 'src/domains/players/webview/components/Account/personalDetailsTab/changeEmailProcedure/ChangeEmailProcedure.state';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';

export const validateNewAndOldEmail = (value: SetNewEmailGroupModelTypes): Result<SetNewEmailGroupModelTypes> => {
    if (value.oldEmail === value.newEmail) {
        return Result.createError(getErrorByCode('ERROR_OLD_EMAIL_EQUAL_NEW'));
    }
    return Result.createOk({
        newEmail: value.newEmail,
        oldEmail: value.oldEmail
    });
};
