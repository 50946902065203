import React from 'react';
import { Wrapper } from 'src/domains/players/webview/components/Account/limitsTab/LimitsTab.style';
import { PlayBreakProcedure } from 'src/domains/players/webview/components/Account/limitsTab/playBreakProcedure/PlayBreakProcedure';
import { RealityCheck } from 'src/domains/players/webview/components/Account/limitsTab/realityCheck/RealityCheck';
import { SelfExclusionProcedure } from 'src/domains/players/webview/components/Account/limitsTab/selfExclusionProcedure/SelfExclusionProcedure';
import { TopUpLimitsProcedure } from 'src/domains/players/webview/components/Account/limitsTab/topUpLimitsProcedure/TopUpLimitsProcedure';
import { observer } from 'src/utils/mobx-react';

export const LimitsTab = observer('LimitsTab', () => {
    return (
        <Wrapper>
            <TopUpLimitsProcedure />
            <PlayBreakProcedure />
            <SelfExclusionProcedure />
            <RealityCheck />
        </Wrapper>
    );
});
