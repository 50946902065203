import { CasinoGameModelExt } from 'src/domains/casino/state/AppCasino.state';
import { TabIdType } from './types';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { ConfigState } from 'src/domains/casino/state/AppCasino/ConfigState';

export const convertUrlToTabIdType = (isLogginUser: boolean, tabId: string | null): TabIdType => {
    const defaultCategory = 'home';

    if (tabId === null) {
        return {
            type: defaultCategory
        };
    }

    if (tabId === 'all_games') {
        return {
            type: 'all_games'
        };
    }

    if (tabId === 'favourite_games') {
        if (isLogginUser) {
            return {
                type: 'favourite_games'
            };
        }
        return {
            type: defaultCategory
        };
    }

    if (tabId.startsWith('supplier_')) {
        const supplier_id = tabId.substr(9);

        return {
            type: 'supplier_games',
            id: supplier_id
        };
    }

    const valueNum = parseInt(tabId, 10);

    if (!isNaN(valueNum)) {
        return {
            type: 'numeric',
            id: valueNum,
        };
    }

    return {
        type: defaultCategory
    };
};

export const getCasinoRoute = (view: RouteViewType | null): 'casino' | 'live-casino' | 'virtuals' | 'other' => {
    if (view === null) {
        return 'other';
    }

    if (view.name === 'casino') {
        return 'casino';
    }

    if (view.name === 'live-casino') {
        return 'live-casino';
    }

    if (view.name === 'virtuals') {
        return 'virtuals';
    }

    return 'other';
};

export const getAccountRoute = (view: RightHandSideViewType | null): 'deposit' | 'other' => {
    if (view === null) {
        return 'other';
    }

    if (view.account === 'top-up' || view.account === 'add-credit') {
        return 'deposit';
    }

    return 'other';
};

export const convertTabIdTypeToUrl = (tabId: TabIdType): string => {
    if (tabId.type === 'supplier_games') {
        return `supplier_${tabId.id}`;
    }

    if (tabId.type === 'numeric') {
        return tabId.id.toString();
    }

    return tabId.type;
};

export const sortGamesAlphabetically = (games: Array<CasinoGameModelExt>): Array<CasinoGameModelExt> => {
    const sortedGames = [...games].sort((a, b) => a.name.localeCompare(b.name));
    return sortedGames;
};

export const extractUserFirstName = (fullName: string | null): string | null => {
    const firstName = fullName?.split(' ')[0] ?? null;
    return firstName;
};

export const convertTitleToDataTest = (title: string | JSX.Element): string => {
    let plainText = '';

    if (typeof title === 'object') {
        if (typeof title.props.defaultText === 'string') {
            plainText = title.props.defaultText;
        }
    } else {
        plainText = title;
    }
    const regExpParams = new RegExp('{(.*?)}', 'g');
    return plainText.replaceAll(regExpParams, '').trim().replaceAll(' ', '-').toLowerCase();
};

export const scrollToNavigationBar = (
    breakpointsState: BreakpointsState,
    config: ConfigState,
    scrollTop?: number | null
): void => {
    const isMobile = breakpointsState.desktop.isBiggerOrEq === false;
    const navOffset = isMobile ? 48 : (config.commonConfig.stickyDesktopHeader === true ? 56 : 0);
    const scrollTopExt = scrollTop ?? 0;
    const scrollOffset = scrollTopExt - navOffset;

    if (window.scrollY > scrollOffset) {
        window.scrollTo({ top: scrollOffset });
    }
};

export const slugifyString = (text: string): string => {
    return text
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
};
