import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface EuroPropsType {
    className?: string;
    onClick?: () => void;
}

export const EuroIcon = observer('EuroIcon', ({ className, onClick }: EuroPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M14.343 22.313c1.553 0 3.105-.353 4.657-1.06 1.552-.706 2.886-1.646 4-2.82L19.836 15c-.736.756-1.572 1.353-2.508 1.791-.935.438-1.84.657-2.716.657-1.075 0-2.055-.274-2.94-.821-.886-.547-1.568-1.289-2.045-2.224h6.537v-1.97H9c-.04-.398-.06-.707-.06-.925 0-.26.01-.458.03-.598h7.194V8.94H9.478c.457-1.015 1.144-1.82 2.06-2.418.915-.597 1.94-.895 3.074-.895.915 0 1.84.249 2.776.746.935.498 1.751 1.164 2.448 2L23 4.583c-1.035-1.095-2.313-1.966-3.836-2.613-1.522-.647-3.06-.97-4.612-.97-1.79 0-3.438.333-4.94 1-1.503.667-2.771 1.602-3.806 2.806C4.771 6.01 4.065 7.388 3.686 8.94H1v1.97h2.388c-.02.14-.03.359-.03.657l.001.216c.004.274.013.49.03.65H1v1.97h2.716c.398 1.552 1.1 2.925 2.105 4.12 1.005 1.193 2.244 2.124 3.716 2.79 1.473.667 3.075 1 4.806 1z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
