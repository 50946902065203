import React, { useEffect, useState } from 'react';
import { Checkbox } from 'src/domains/layouts/webview/components/form/checkbox/Checkbox';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account/component/accountHelperInfo/AccountHelperInfo';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    AlignBottomWrapper,
    ForgotPasswordLink,
    LoginBtnWrapper,
    LoginForm,
    LoginInput,
    LoginMessage,
    LoginTabWrapper,
    SignUpHelper,
    SignUpLink,
    RememberMeWrapper,
} from './LoginTab.style';
import { LoginTabUtil } from 'src/domains/players/webview/components/Account/loginTab/LoginTabUtil';
import { observer } from 'src/utils/mobx-react';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { SpinnerDark } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner.style';

const onSubmitDisable = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
};

// eslint-disable-next-line @typescript-eslint/naming-convention
interface LoginTabProps {
    isLoginRoute?: boolean;
}

export const LoginTab = observer('LoginTab', ({ isLoginRoute }: LoginTabProps) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState, starRouter } = appLayoutsState;
    const { loginState } = appPlayersState;

    const emailLabel = LoginTabUtil.getLoginInputLabel();
    const passwordLabel = LoginTabUtil.getPasswordInputLabel();

    const [isLoading, setIsLoading] = useState(false);
    const logIn = (): Promise<void> => {
        if (starRouter.currentView?.name === 'login') {
            setIsLoading(true);

            return loginState
                .logIn()
                .then(() => starRouter.redirectToHomepage())
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return loginState.logIn();
    };

    useEffect(() => {
        return loginState.resetState;
    }, []);

    return (
        <LoginTabWrapper
            key='login-tab'
            data-test='LoginTab'
        >
            <LoginForm
                key='login-form'
                onSubmit={onSubmitDisable}
                id='login'
            >
                {isLoginRoute === true ||
                starRouter.currentView?.name === 'login' ? null : starRouter.isAccountParam === false ? null : (
                    <LoginMessage>
                        {languagesState.getTranslation('account.tabs.login.message', 'Login to access page')}
                    </LoginMessage>
                )}
                <LoginInput
                    name='email'
                    type='email'
                    label={emailLabel}
                    state={loginState.emailState}
                    colorTheme='light'
                    dataTest='email-input'
                    isDisabled={isLoading}
                    autoFocus
                />
                <LoginInput
                    name='password'
                    type={loginState.isShowPassword ? 'text' : 'password'}
                    label={passwordLabel}
                    state={loginState.passwordState}
                    showPassword={loginState.setShowPassword}
                    showPasswordFlag={true}
                    isShowPassword={loginState.isShowPassword}
                    colorTheme='light'
                    dataTest='password-input'
                    isDisabled={isLoading}
                />

                {loginState.loginErrorMsg === null ? null : (
                    <Messages
                        type='error'
                        hasIcon={true}
                        marginBottom='16px'
                        message={loginState.loginErrorMsg}
                    />
                )}

                <RememberMeWrapper key='form-buttons'>
                    <Checkbox
                        key='login-remember-me-checkbox'
                        state={loginState.rememberMeState}
                    >
                        <I18n
                            langKey='account.remember-me'
                            defaultText='Remember me?'
                        />
                    </Checkbox>

                    {starRouter.currentView?.name === 'login' ? (
                        <Link
                            key='forgot-password'
                            to='/forgot-password'
                            dataTest='forgot-password'
                        >
                            <I18n
                                langKey='account.forgot-password'
                                defaultText='Forgot password?'
                            />
                        </Link>
                    ) : (
                        <ForgotPasswordLink
                            key='forgot-password'
                            to={{ account: 'forgot-password' }}
                            routeNameForGtm='forgot-password'
                            dataTest='forgot-password'
                        >
                            <I18n
                                langKey='account.forgot-password'
                                defaultText='Forgot password?'
                            />
                        </ForgotPasswordLink>
                    )}
                </RememberMeWrapper>

                <LoginBtnWrapper
                    type='submit'
                    size='large'
                    onClick={logIn}
                    disabled={loginState.inProgress || !loginState.isFormValid}
                    dataTest='login-button'
                >
                    {isLoading ? (
                        <SpinnerDark type='basic' />
                    ) : (
                        <I18n
                            defaultText='Login'
                            langKey='account.login'
                        />
                    )}
                </LoginBtnWrapper>

                <SignUpHelper>
                    <I18n
                        langKey='account.forgot-password.no-account'
                        defaultText='Don’t have an account? '
                    />

                    {starRouter.currentView?.name === 'login' ? (
                        <Link
                            key='signup'
                            to='/signup'
                            dataTest='login-tab-sign-up'
                        >
                            <I18n
                                langKey='account.forgot-password.sign-up.link'
                                defaultText='Sign Up'
                            />
                        </Link>
                    ) : (
                        <SignUpLink
                            to={{ account: 'signup', promo: null }}
                            routeNameForGtm='signup'
                            dataTest='login-tab-sign-up'
                        >
                            <I18n
                                langKey='account.forgot-password.sign-up.link'
                                defaultText='Sign Up'
                            />
                        </SignUpLink>
                    )}
                </SignUpHelper>
            </LoginForm>

            <AlignBottomWrapper>
                <AccountHelperInfo key='login-tab-need-help' />
            </AlignBottomWrapper>
        </LoginTabWrapper>
    );
});
