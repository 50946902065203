import { Value } from './Value';

class RefBox<T> {
    public constructor(public readonly ref: T) {}
}

export class HtmlElementReactive<T> {
    private readonly box: Value<RefBox<T | null>>;

    public constructor(timeout: number) {
        this.box = new Value<RefBox<T | null>>(new RefBox(null), (setValue) => {
            const timer = setInterval(() => {
                const refBox = this.box.getValue();
                setValue(new RefBox(refBox.ref));
            }, timeout);

            return (): void => {
                clearInterval(timer);
            };
        });
    }

    public setRef = (ref: T | null): void => {
        setTimeout(() => {
            this.box.setValue(new RefBox(ref));
        }, 0);
    };

    public get ref(): T | null {
        return this.box.getValue().ref;
    }
}
