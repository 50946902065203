import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface SettingsIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const SettingsIcon = observer(
    'SettingsIcon',
    ({ className, onClick }: SettingsIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M11.746 15.873c1.067 0 1.98-.378 2.737-1.136A3.728 3.728 0 0015.619 12c0-1.067-.378-1.98-1.136-2.737a3.728 3.728 0 00-2.737-1.136c-1.067 0-1.98.378-2.737 1.136-.757.757-1.136 1.67-1.136 2.737 0 1.067.379 1.98 1.136 2.737a3.728 3.728 0 002.737 1.136zm8.211-2.788l2.324 1.807c.241.172.276.413.104.723l-2.221 3.822c-.138.24-.362.31-.671.206l-2.737-1.084c-.724.516-1.343.878-1.86 1.084l-.413 2.892c-.069.31-.24.465-.516.465H9.525c-.275 0-.447-.155-.516-.465l-.413-2.892a8.483 8.483 0 01-1.86-1.084L4 19.643c-.31.104-.534.035-.672-.206l-2.22-3.822c-.173-.31-.138-.55.103-.723l2.324-1.807A8.248 8.248 0 013.483 12c0-.482.017-.844.052-1.085L1.21 9.108c-.241-.172-.276-.413-.103-.723l2.22-3.822c.138-.24.362-.31.672-.206L6.737 5.44c.723-.516 1.342-.878 1.859-1.084l.413-2.892c.069-.31.241-.465.516-.465h4.442c.275 0 .447.155.516.465l.413 2.892a8.483 8.483 0 011.86 1.084l2.737-1.084c.31-.103.533-.035.67.206l2.222 3.822c.172.31.137.55-.104.723l-2.324 1.807c.035.242.052.603.052 1.085s-.017.844-.052 1.085v.001z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
