import { z } from 'zod';

export const InputSchema = z.object({
    body: z.object({
        cpf: z.string(),
        amount: z.string(),
        name: z.string(),
        email: z.string(),
        description: z.string(),
        webhookUri: z.string(),
    }),
});

export type InitiateDepositZenetPayInput = z.infer<typeof InputSchema>;

export const SuccessResponseSchema = z.object({
    id: z.string(),
    pixId: z.string(),
    acquirerId: z.string(),
    qrCode: z.string(),
    qrCodeBase64: z.string(),
});

type TSuccessResponse = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    code: z.string().optional().nullable(),
    debugDetails: z.string().optional().nullable(),
    details: z.string().optional().nullable(),
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            debugDetails: z.string().optional().nullable(),
            field: z.string().optional().nullable(),
            pointer: z.string().optional().nullable(),
            resource: z.string().optional().nullable(),
        })
    ),
    message: z.string().optional().nullable(),
});


type TErrorResponse = z.infer<typeof ErrorResponseSchema>;

export type TResponseType =
    { responseStatus: 'success'; data: TSuccessResponse } |
    { responseStatus: 'error'; data: TErrorResponse }
