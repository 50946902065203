import * as React from 'react';
import { AppState, useAppStateContext } from 'src/appState/AppState';
import { EventRowMobxWrapper } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventRowMobx';
import { EventGroupHeader } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventGroupHeader';
import { EventListWrapper } from 'src/domains/sportsbook/webview/components/eventsList/EventList.style';
import { observer } from 'src/utils/mobx-react';
import { EventsGroupViewCompetition, EventsGroupViewSport } from 'src/domains/sportsbook/state/eventsListState/eventsGroup/EventsGroupView';
import { EventsGroupState } from 'src/domains/sportsbook/state/eventsListState/eventsGroup/EventsGroupState';
import { EventsListState } from 'src/domains/sportsbook/state/eventsListState/EventsListState';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';

const DEFAULT_OPEN_GROUPS = 5;

const renderCompetition = (appState: AppState, competitionGroup: EventsGroupViewCompetition): Array<React.ReactNode> => {
    const events = competitionGroup.events;
    const out = [];

    if (events.length > 0) {
        for (const event of events) {
            out.push((
                <EventRowMobxWrapper
                    key={`event_${event.id}`}
                    smallEvent={event}
                    competitionId={appState.appSportsBookState.models.id.getCompetitionId(competitionGroup.id)}
                />
            ));
        }
    }

    return out;
};

const renderSportGroup = (appState: AppState, sportGroup: EventsGroupViewSport, eventsListState: EventsListState): Array<React.ReactNode> => {
    const out = [];

    for (const competition of sportGroup.competitions) {
        const lastSelectionsHeader = eventsListState.lastSelectionsHeader;
        const competitionName = competition.name;
        const isVisible = competition.visible;

        out.push((
            <EventGroupHeader
                key={`group_${competition.id}`}
                selections={lastSelectionsHeader}
                label={competitionName}
                open={isVisible}
                onToggle={competition.toggle}
                sportName={competition.sport}
                competitionId={appState.appSportsBookState.models.id.getCompetitionId(competition.id)}
            />
        ));

        if (isVisible) {
            const outCompetition = renderCompetition(appState, competition);
            out.push(...outCompetition);
        }
    }

    return out;
};

class State {
    public readonly appState: AppState;
    public readonly groupEvents: MobxMapAutoNew<EventsCollectionQueryModel | null, EventsGroupState>;

    public constructor(appState: AppState, specialSportsState: SpecialSportsState) {
        this.appState = appState;

        this.groupEvents = new MobxMapAutoNew((eventsCollection) => {
            const { languagesState } = this.appState.appLayoutsState;

            return new EventsGroupState(
                DEFAULT_OPEN_GROUPS,
                languagesState.getTranslation,
                eventsCollection,
                specialSportsState
            );
        });
    }
}

const EventsListMobxContent = observer('EventsListMobxContent', (props: EventsListPropsType) => {
    const appState = useAppStateContext();
    const { appSportsBookState } = useAppStateContext();
    const specialSportsState = appSportsBookState.specialSportsMap.get(props.sportId);
    const [state] = React.useState(() => new State(appState, specialSportsState));
    const listToView = state.groupEvents.get(props.eventsListState.events).listToView;

    return (
        <EventListWrapper>
            {listToView.sports.map(
                (item) => renderSportGroup(appState, item, props.eventsListState))}
        </EventListWrapper>
    );
});

interface EventsListPropsType {
    eventsListState: EventsListState;
    sportId: string | null;
}

export const EventsListMobx = observer('EventsListMobx', (props: EventsListPropsType) => {
    const filters_coupon = props.eventsListState.filter_coupon;
    return (
        <EventsListMobxContent key={filters_coupon} eventsListState={props.eventsListState} sportId={props.sportId}/>
    );
});

