import * as React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { useAppStateContext } from 'src/appState/AppState';

export const LogoutButton = observer('LogoutButton', () => {
    const { appPlayersState: { loginState } } = useAppStateContext();

    return (
        <Button onClick={loginState.logOut} size='xs' dataTest='button-logout'>
            <I18n langKey='account.help-box.logout.label' defaultText='Log Out' />
        </Button>
    );
});
