import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { SelectionId, WebsocketId } from 'src_common/common/websocket2/id/WebsocketId';
import { BetslipAllSinglesId, BetslipCombinationId, BetslipSingleId } from './BetslipIdModels';
import { BetslipModelsContext } from './BetslipModelsContext';

export class BetslipIdsFactory {
    private readonly single: AutoMap<[number, number, number, number], BetslipSingleId>; // eventId: number, marketId: number, selectionId: number, index: number (bet refferal)
    private readonly combination: AutoMap<string, BetslipCombinationId>;
    private readonly all: BetslipAllSinglesId;

    public constructor(websocketId: WebsocketId, context: BetslipModelsContext) {
        this.single = new AutoMap(([oldEventId, oldMarketId, oldSelectionId, betRefferalIndex]): BetslipSingleId => {
            const selectionId = websocketId.getSelectionId(oldEventId, oldMarketId, oldSelectionId);
            return new BetslipSingleId(context, selectionId, betRefferalIndex);
        });

        this.combination = new AutoMap((id: string): BetslipCombinationId => {
            return new BetslipCombinationId(context, id);
        });

        this.all = new BetslipAllSinglesId(context);
    }

    public getSingleId(selectionId: SelectionId, index: number): BetslipSingleId {
        const oldEventId = selectionId.getEventId().toOldId();
        const oldMarketId = selectionId.getMarketId().toOldId();
        const oldSelectionId = selectionId.toOldId();

        return this.single.get([oldEventId, oldMarketId, oldSelectionId, index]);
    }

    public getCombinationId(id: string): BetslipCombinationId {
        return this.combination.get(id);
    }

    public getAll(): BetslipAllSinglesId {
        return this.all;
    }
}
