import { action, computed, observable, makeObservable } from 'mobx';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateDateGreaterThanZero, validateExpectNumber, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';

export class DatePartInputState {
    @observable public isFocus: boolean = false;

    public readonly inputState: FormInputState<string,number>;

    public constructor (value: string) {
        makeObservable(this);
        this.inputState = FormInputState.new(value).map(validateRequire).map(validateExpectNumber)
            .map(validateDateGreaterThanZero);
    }

    private isNumberCheck = (value: string): boolean => {
        if (value === '') {
            return true;
        }

        const number = parseInt(value, 10);

        return !isNaN(number);
    };


    @action public handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const maxLengthValue = event.currentTarget.getAttribute('maxLength');
        const newValueIsCorrectNumber = this.isNumberCheck(event.target.value);
        const newValueLengthIsLowerThanMaxLength = maxLengthValue === null ?
            true :
            parseInt(maxLengthValue) >= event.target.value.length;

        if ( newValueIsCorrectNumber && newValueLengthIsLowerThanMaxLength ) {
            this.inputState.setValue(event.target.value);
        }

        return;
    };

    @action public handleBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.inputState.setAsVisited();

        const value = this.inputState.value;
        const isSingleDigit = value.length === 1;

        if (isSingleDigit && event.target.name !== 'year') {
            this.inputState.setValue(`0${value}`);
        }
    };

    @computed public get isEmpty(): boolean {
        return this.inputState.value !== '';
    }
}
