import { sortBy } from 'lodash';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { buildDictionaryEvents, DictionaryEvents, DictionarySelection } from './buildDictionary';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';

const SELECTIONS_ORDER = [ 'S', 'A', 'T', 'D', 'H', 'H-D', 'A-D', 'H-A' ];

const findMax = (possibleMarkets: Record<string, number>): string | null => {
    let max = 0;
    let resultTemplateId: string | null = null;

    for (const [templateId, count] of Object.entries(possibleMarkets)) {
        if (count > max) {
            max = count;
            resultTemplateId = templateId;
        }
    }

    return resultTemplateId;
};

const findHeaders = (eventsDict: DictionaryEvents, marketTemplateId: string): Array<string> => {
    const selectionsSet: Set<string> = new Set();

    for (const [_a, markets] of eventsDict) {
        for (const [marketId, [_c, selection]] of markets) {
            if (marketId === marketTemplateId) {
                for (const [selectionTemplateId, _] of selection) {
                    selectionsSet.add(selectionTemplateId);
                }
            }
        }
    }

    const selectionsUnsorted: Array<string> = Array.from(selectionsSet);
    return sortBy(selectionsUnsorted, x => -SELECTIONS_ORDER.indexOf(x));
};

const buildResponseMarket = (headers: Array<string>, selections: DictionarySelection): Array<number | null> | null => {
    const response = [];

    for (const header of headers) {
        const selectionModel = selections.get(header);

        if (selectionModel !== undefined && selectionModel.display) {
            response.push(selectionModel.id);
        } else {
            response.push(null);
        }
    }

    if (response.every((item) => item === null)) {
        return null;
    }

    return response;
};

const buildResponse = (dict: DictionaryEvents, marketTemplateId: string): PossibleMarketAndSelectionInListType => {
    const headers = findHeaders(dict, marketTemplateId);

    const markets: Record<number, [MarketId, Array<number | null>]> = {};

    for (const [eventId, marketDict] of dict) {
        for (const [localMarketTemplateId, [marketModel, selections]] of marketDict) {
            if (localMarketTemplateId === marketTemplateId) {
                const eventResponse = buildResponseMarket(headers, selections);

                if (eventResponse !== null) {
                    markets[eventId] = [marketModel.id2, eventResponse];
                }        
            }
        }
    }

    return {
        headers,
        markets
    };
};

const countPossibleMarkets = (eventsDict: DictionaryEvents): Record<string, number> => {
    const possibleMarkets: Record<string, number> = {};

    for (const [_, markets] of eventsDict) {
        for (const [marketTemplateId, _] of markets) {
            possibleMarkets[marketTemplateId] = (possibleMarkets[marketTemplateId] ?? 0) + 1;
        }
    }

    return possibleMarkets;
};

export interface PossibleMarketAndSelectionInListType {
    //Labelki do wyświetlenia w nagłówku
    headers: Array<string>;
    //Markety do wyświetlenia na liście
    markets: Record<number, [MarketId, Array<number | null>]>;
}

export const getMarketAndSelections = (events: Array<EventListGroupEventItemType>): PossibleMarketAndSelectionInListType => {
    const dict = buildDictionaryEvents(events);

    const possibleMarkets: Record<string, number> = countPossibleMarkets(dict);
    
    const marketTemplateId = findMax(possibleMarkets);

    if (marketTemplateId === null) {
        return {
            headers: [],
            markets: {}
        };
    }

    return buildResponse(dict, marketTemplateId);
};
