import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { Value } from 'src_common/common/mobx-utils/Value';
import { SocketConnection } from './SocketConnection';
import { SocketController } from './SocketController';

type BoxValue<T> = {
    type: 'loading';
} | {
    type: 'ready';
    value: T;
};

const createLoading = <T>(): BoxValue<T> => ({
    type: 'loading',
});

const createValue = <T>(value: T): BoxValue<T> => ({
    type: 'ready',
    value,
});

class RecordWrapper<V, M> {
    public readonly recordValue: Value<V>;
    public readonly recordModel: M;

    public constructor(
        mobxValue: Value<V>,
        createModel: (mobxValue: Value<V>) => M,
    ) {
        this.recordValue = mobxValue;
        this.recordModel = createModel(mobxValue);
    }
}

export class SocketMap<K, V, M> {
    private readonly socketController: SocketController;
    private readonly createSubscribtionMessage: ((id: K) => string) | null;
    private readonly createUnsubscribtionMessage: ((id: K) => string) | null;
    private readonly createModel: (mobxValue: Value<V>) => M;
    private readonly data: MobxMapAutoNew<K, Value<BoxValue<RecordWrapper<V, M>>>>;
    private socket: SocketConnection | null;

    public constructor(
        socketController: SocketController,
        createSubscribtionMessage: ((id: K) => string) | null,
        createUnsubscribtionMessage: ((id: K) => string) | null,
        createModel: (mobxValue: Value<V>) => M,
    ) {
        this.socketController = socketController;
        this.createSubscribtionMessage = createSubscribtionMessage;
        this.createUnsubscribtionMessage = createUnsubscribtionMessage;
        this.createModel = createModel;
        this.data = new MobxMapAutoNew((id: K): Value<BoxValue<RecordWrapper<V, M>>> => {
            return new Value(createLoading(), (_setValue) => {
                const unsub = this.socketController.subscribe();

                const socket = this.getSocket();
        
                if (socket !== null && this.createSubscribtionMessage !== null) {
                    socket.send(this.createSubscribtionMessage(id));
                }

                return () => {
                    const socket = this.getSocket();
            
                    if (socket !== null && this.createUnsubscribtionMessage !== null) {
                        socket.send(this.createUnsubscribtionMessage(id));
                    }

                    unsub();
                };
            });
        });
        this.socket = null;
    }

    private getSocket = (): SocketConnection | null => {
        return this.socket;
    };

    public onNewSocket(socket: SocketConnection): void {
        if (this.createSubscribtionMessage === null) {
            return;
        }

        this.socket = socket;

        for (const id of this.data.getAllKeys()) {
            const model = this.data.get(id);

            if (model.isObserved()) {
                socket.send(this.createSubscribtionMessage(id));
            }
        }
    }

    public onMessage(id: K, newValue: V): void {
        const item = this.data.get(id);

        const wrapper = item.getValue();

        if (wrapper.type === 'loading') {
            const newWrappper = new RecordWrapper(
                new Value(newValue),
                this.createModel,
            );

            item.setValue(createValue(newWrappper));
            return;
        }

        wrapper.value.recordValue.setValue(newValue);
    }

    public getById(id: K): null | M {
        const item = this.data.get(id).getValue();

        if (item.type === 'loading') {
            return null;
        }

        return item.value.recordModel;
    }
}
