import React from 'react';

import { observer } from 'src/utils/mobx-react';

import { UniverseComponent } from 'src/domains/common/universes';



export const NotificationIcons = observer('NotificationIcons', (): JSX.Element => {

    return (

        <UniverseComponent>

            {(universeVersion): React.ReactElement => <universeVersion.traderChatNotificationIcon />}

        </UniverseComponent>

    );

});

