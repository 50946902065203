import * as React from 'react';
import { InfoIconWrapper, TextMessageContent, TextMessageWrapper, WarningIconWrapper } from './TextMessages.style';
import { observer } from 'src/utils/mobx-react';

type TextMessageType = 'error' | 'info';

interface TextMessagePropsType {
    className?: string;
    hasIcon?: boolean;
    marginBottom?: string;
    marginTop?: string;
    message: React.ReactNode | string;
    type: TextMessageType;
}

const iconType: Record<TextMessageType, JSX.Element> = {
    error: <WarningIconWrapper />,
    info: <InfoIconWrapper />,
};

export const TextMessages = observer('TextMessages', (props: TextMessagePropsType): JSX.Element | null => {
    const { message, marginTop, marginBottom, className, type } = props;

    if (message === null) {
        return null;
    }

    const renderContent = (): React.ReactNode | string => {
        const IconComponent = iconType[type];

        return (
            <>
                {IconComponent}
                <TextMessageContent>{message}</TextMessageContent>
            </>
        );
    };

    return (
        <TextMessageWrapper
            className={className}
            data-test={`text-${type}-message-content`}
            marginBottom={marginBottom}
            marginTop={marginTop}
            type={type}
        >
            {renderContent()}
        </TextMessageWrapper>
    );
});
