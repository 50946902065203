import { ConfigType } from 'src/domains/layouts/config/features/types';

export type MatchType =
    | 'match-winner'
    | 'both-teams-to-score'
    | 'total-goals-over-under-2.5'
    | 'total-goals-over-under-1.5'
    | 'total-goals-over-under-0.5'
    | 'draw-no-bet'
    | '1st-half-winner'
    | 'match-result-and-both-to-score';

export interface ItemType {
    name: string;
    id: MatchType;
    template: string;
    line: number | null;
}

export const getMarketTranslation = (
    getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string
): Record<MatchType, ItemType> => {
    const marketsArray: Record<MatchType, ItemType> = {
        'match-winner': {
            name: getTranslation('football.filter.marketName.match-result', 'Match Result'),
            id: 'match-winner',
            template: 'match-winner',
            line: null,
        },
        'both-teams-to-score': {
            name: getTranslation('football.filter.marketName.bot-teams-to-score', 'Both Teams To Score'),
            id: 'both-teams-to-score',
            template: 'both-teams-to-score',
            line: null,
        },
        'match-result-and-both-to-score': {
            name: getTranslation('football.filter.marketName.match-result-btts', 'Match Result BTTS'),
            id: 'match-result-and-both-to-score',
            template: 'match-result-and-both-to-score',
            line: null,
        },
        'total-goals-over-under-0.5': {
            name: getTranslation('football.filter.marketName.over-under-0-5-goals', 'Over/Under 0.5 Goals'),
            id: 'total-goals-over-under-0.5',
            template: 'total-goals-over-under',
            line: 0.5,
        },
        'total-goals-over-under-1.5': {
            name: getTranslation('football.filter.marketName.over-under-1-5-goals', 'Over/Under 1.5 Goals'),
            id: 'total-goals-over-under-1.5',
            template: 'total-goals-over-under',
            line: 1.5,
        },
        'total-goals-over-under-2.5': {
            name: getTranslation('football.filter.marketName.over-under-2-5-goals', 'Over/Under 2.5 Goals'),
            id: 'total-goals-over-under-2.5',
            template: 'total-goals-over-under',
            line: 2.5,
        },
        'draw-no-bet': {
            name: getTranslation('football.filter.marketName.draw-no-bet', 'Draw No Bet'),
            id: 'draw-no-bet',
            template: 'draw-no-bet',
            line: null,
        },
        '1st-half-winner': {
            name: getTranslation('football.filter.marketName.first-half-result', 'First Half Result'),
            id: '1st-half-winner',
            template: '1st-half-winner',
            line: null,
        },
    };

    return marketsArray;
};

export const getMarkets = (
    getTranslation: (key: string, defaultText: string, params?: Record<string, string | number>) => string,
    config: ConfigType
): Array<ItemType> => {
    const universeSetup: Array<MatchType> = config.universeSetup;
    const array: Array<ItemType> = [];

    for (const matchTypeId of universeSetup) {
        array.push(getMarketTranslation(getTranslation)[matchTypeId]);
    }

    return array;
};
