import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { DatePartInputState } from 'src/domains/players/webview/components/form/DateInput/DatePartInput.state';
import { DatePartInputWrapper, DatePart } from 'src/domains/players/webview/components/form/DateInput/DateInput.style';

interface DateInputSelectPropsType {
    className?: string;
    state: DatePartInputState;
    placeholder: string;
    name: string;
    pattern: string;
    inputMode?: 'numeric';
    maxLength: number;
    isDisabled?: boolean;
    inputRef: (node: HTMLInputElement | null) => void;
    onClick?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onChangeInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onInputReturn?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    isReadOnly?: boolean;
    dataTest?: string;
}

export const DatePartInput = observer('DatePartInput', (props: DateInputSelectPropsType) => {
    const {
        className,
        state,
        pattern,
        inputMode,
        name,
        maxLength,
        inputRef,
        onClick,
        onChangeInput,
        onInputReturn,
        dataTest,
        isReadOnly,
    } = props;

    function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
        state.handleOnChange(event);
        if (onChangeInput !== undefined) {
            onChangeInput(event);
        }
    }

    return (
        <DatePartInputWrapper className={className}>
            <DatePart
                ref={inputRef}
                pattern={pattern}
                name={name}
                placeholder={props.placeholder}
                value={state.inputState.value}
                maxLength={maxLength}
                onClick={onClick}
                inputMode={inputMode}
                onChange={onChange}
                onBlur={state.handleBlur}
                onKeyDown={onInputReturn}
                type='text'
                readOnly={isReadOnly}
                data-test={dataTest}
            />
        </DatePartInputWrapper>
    );
});
