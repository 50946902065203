import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    NavigationPanel,
    LinkItemLinkType,
} from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { HelpSectionWrapper } from './NavigationSidebar.style';

const getLinks = (isAuth: boolean, traderChat: boolean): Array<LinkItemLinkType> => {
    const out: Array<LinkItemLinkType> = [
        {
            key: 'faq',
            to: {
                account: 'static',
                static: 'help',
            },
            label: (
                <I18n
                    langKey='left-menu.faq.label'
                    defaultText="FAQ's"
                />
            ),
        },
    ];

    if (isAuth) {
        if (traderChat) {
            out.push({
                key: 'trader-chat',
                to: {
                    account: 'trader-chat',
                },
                label: (
                    <I18n
                        langKey='left-menu.live-chat.label'
                        defaultText='Live Chat'
                    />
                ),
            });
        }
        out.push({
            key: 'limits',
            to: {
                account: 'limits',
            },
            label: (
                <I18n
                    langKey='left-menu.responsible-gambling.label'
                    defaultText='Responsible Gambling'
                />
            ),
        });
    }

    return out;
};

interface PropsType {
    withWrapper: boolean;
}

export const NavigationSidebarSectionSupport = observer(
    'NavigationSidebarSectionSupport',
    (props: PropsType): React.ReactElement | null => {
        const { withWrapper } = props;

        const { appLayoutsState, appPlayersState } = useAppStateContext();
        const { sdkCustomer, configComponents } = appLayoutsState;
        const { traderChatState } = appPlayersState;

        const { config } = configComponents;
        const { navigationHelpSupport } = config;
        const authenticated = sdkCustomer.session.isAuthorized;

        if (navigationHelpSupport === false) {
            return null;
        }

        const panel = (
            <NavigationPanel
                title={
                    <I18n
                        langKey='left-menu.help-and-support.title'
                        defaultText='Help &amp; Support'
                    />
                }
                titleDataTest='help-support'
                links={getLinks(authenticated, traderChatState.isTraderChatForView)}
            />
        );

        if (withWrapper) {
            return <HelpSectionWrapper>{panel}</HelpSectionWrapper>;
        }

        return panel;
    }
);
