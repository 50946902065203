import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, makeObservable } from 'mobx';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Response200Type } from 'src/api_openapi/generated/openapi_website_cms_getSportDetailsActive';

export type SportModelType = Response200Type extends Array<infer Model> ? Model : never;

export class SportsConfigState {
    private readonly sportsConfigActiveResource: Resource<Array<SportModelType>>;
    public constructor(private readonly trpcClient: TrpcClient) {
        makeObservable(this);
        this.sportsConfigActiveResource = new Resource(async (): Promise<Array<SportModelType>> => {
            const response = await this.trpcClient.client.cms.getSportsActive.query();
            return response.body ?? [];
        });
    }

    @computed public get sportsConfigActiveResourceData(): Array<SportModelType> | 'loading' {
        const result = this.sportsConfigActiveResource.get();
        if (result.type === 'loading') {
            return 'loading';
        }

        if (result.type === 'ready') {
            return result.value;
        }

        return [];
    }
}
