import { observable, action, makeObservable } from 'mobx';

export class VerifyAccountState {
    @observable public email: string | null = null;

    constructor() {
        makeObservable(this);
    }

    @action public setEmail(email: string): void {
        this.email = email;
    }
};
