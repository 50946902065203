import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import * as React from 'react';

export class LoginTabUtil {
    public static getLoginInputLabel(): JSX.Element {
        return <I18n langKey='account.input-username-label' defaultText='Email' />;
    }

    public static getPasswordInputLabel(): JSX.Element {
        return <I18n langKey='account.input-password-label' defaultText='Password' />;
    }

}
