import * as React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import { FootballScoreboard } from 'src/domains/sportsbook/webview/components/scoreboards/standard/singleScoreboard/FootballScoreboard';
import { TennisScoreboard } from 'src/domains/sportsbook/webview/components/scoreboards/standard/singleScoreboard/TennisScoreboard';
import { AmericanFootballScoreboard } from 'src/domains/sportsbook/webview/components/scoreboards/standard/singleScoreboard/AmericanFootballScoreboard';
import { BaseballScoreboard } from 'src/domains/sportsbook/webview/components/scoreboards/standard/singleScoreboard/BaseballScoreboard';
import { CricketScoreboard } from 'src/domains/sportsbook/webview/components/scoreboards/standard/singleScoreboard/CricketScoreboard';
import { RugbyScoreboard } from 'src/domains/sportsbook/webview/components/scoreboards/standard/singleScoreboard/RugbyScoreboard';

interface PropsType {
    event: EventModel;
}

export const ScoreboardStandard = observer('ScoreboardStandard', ({ event }: PropsType) => {
    const {
        config: { scoreboard },
    } = useAppStateContext();
    const { sport } = event;

    if (scoreboard.standard === null) {
        return null;
    }

    for (const item of scoreboard.standard) {
        if (item === sport) {
            switch (item) {
                case 'cricket':
                    return <CricketScoreboard event={event} />;
                case 'baseball':
                    return <BaseballScoreboard event={event} />;
                case 'americanfootball':
                    return <AmericanFootballScoreboard event={event} />;
                case 'tennis':
                    return <TennisScoreboard event={event} />;
                case 'football':
                    return <FootballScoreboard event={event} />;
                case 'rugbyunion':
                    return <RugbyScoreboard event={event} />;
                case 'rugbyleague':
                    return <RugbyScoreboard event={event} />;
                default:
                    return null;
            }
        }
    }

    return null;
});
