import { computed, makeObservable } from 'mobx';
import { FiltersStateStrategy, FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { SpecialSportsState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
export class SpecialSportFilterStrategy implements FiltersStateStrategy {

    public constructor(
        private readonly specialSportsState: SpecialSportsState,
    ) {
        makeObservable(this);
    }

    @computed public get filters(): FilterType[] {
        if (this.specialSportsState.specialExpandForSport !== null) {
            return this.specialSportsState.customFiltersForExistingEvent.map((item) => {
                return {
                    id: item.id,
                    key: item.id,
                    label: item.name,
                };
            });
        }
        return this.specialSportsState.availableSports.map((item) => {
            return {
                id: item.id,
                key: item.id,
                label: item.name,
            };
        });
    }

    public getActiveFilterId(): string {
        return this.specialSportsState.filter_coupon;
    }

    public setActiveFilterId(id: string | number | null): void {
        if (typeof id === 'string') {
            this.specialSportsState.changeFilter_coupon(id);
        }
    }

}
