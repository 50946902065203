import React from 'react';

import { CarouselEventNavigation } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigation';
import { observer } from 'src/utils/mobx-react';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { BackButton, BackButtonWrapper, EventNavigation } from 'src/domains/layouts/webview/components/eventNavigation/CarouselSelection.style';

import { SportCompetitionEventsList } from 'src/domains/layouts/webview/components/sportEventsList/SportEventsList';
import { getHeaderOptions, getDefaultHeaderOptions } from 'src/domains/layouts/webview/components/sports/general/config';
import { SportHeaderStar } from 'src/domains/layouts/webview/components/sportHeader/SportHeader';
import { EventsTableMobX } from 'src/domains/sportsbook/webview/components/sportEventsListNew/EventsTableMobX';
import { useAppStateContext } from 'src/appState/AppState';
import { SectionWrapper } from 'src/domains/sportsbook/webview/layouts/competitionPage/CompetitionPage.style';
import { CompetitionRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';

interface CompetitionPageGeneralPropsType {
    sportId: string;
    competitionId: CompetitionId;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isCompetitionPage: boolean;
}

const CompetitionPageGeneral = observer('CompetitionPageGeneral', (props: CompetitionPageGeneralPropsType): JSX.Element | null => {
    const { sportId, competitionId, headerStyleLevel, isCompetitionPage } = props;

    const optionItem = getHeaderOptions();
    const options = optionItem[sportId] ?? getDefaultHeaderOptions();

    if (sportId === 'football') {
        return (
            <section className='sport-general'>
                <SportHeaderStar options={options} sportId={sportId} isCompetitionPage={isCompetitionPage} competitionId={competitionId}/>
                <EventsTableMobX competitionId={competitionId} sportId={sportId} />
            </section>
        );
    } 
    return (
        <section className='sport-general'>
            <SportHeaderStar options={options} sportId={sportId} isCompetitionPage={isCompetitionPage} competitionId={competitionId}/>
            <SportCompetitionEventsList
                sport={sportId}
                competition={competitionId}
                headerStyleLevel={headerStyleLevel}
                isCompetitionPage={isCompetitionPage}
            />
        </section>
    );
});

interface CompetitionPagePropsType {
    currentView: CompetitionRouteType;
    accountView: RightHandSideViewType | null;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isSearchActive: boolean;
}

export const CompetitionPage = observer('CompetitionPage', (props: CompetitionPagePropsType) => {
    const { currentView, accountView, headerStyleLevel, isSearchActive } = props;

    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { starRouter } = appLayoutsState;

    const handleRedirectToSportPage = (): void => {
        starRouter.redirectToSportPage(currentView.slug);
    };

    return (
        <SectionWrapper>
            <LayoutSkeleton accountView={accountView} currentView={currentView} isSearchActive={isSearchActive} navigationType='global' >
                <>
                    <EventNavigation>
                        <BackButtonWrapper onClick={handleRedirectToSportPage}>
                            <BackButton position='left' />
                        </BackButtonWrapper>
                        <CarouselEventNavigation currentView={currentView} />
                    </EventNavigation>
                    <CompetitionPageGeneral sportId={currentView.slug} competitionId={appSportsBookState.models.id.getCompetitionId(currentView.id)} headerStyleLevel={headerStyleLevel} isCompetitionPage={true} />
                </>
            </LayoutSkeleton>
        </SectionWrapper>
    );
});
