import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    TermsWrapper,
    SingleTermsWrapper,
    MarketHeaderContent,
    MarketHeaderTitle,
    ChevronIconWrapper,
} from './MarketHeader.style';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { LifespanBoostArrowDisplay } from 'src/domains/sportsbook/lifespan/ArrowDisplay';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

interface TermsPropsType {
    marketId: MarketId | undefined;
}

const Terms = observer('Terms', ({ marketId }: TermsPropsType): JSX.Element | null => {
    const marketModel = marketId === undefined ? null : marketId.getModel();

    if (marketModel !== null && marketModel.eachWay !== undefined && marketModel.eachWay.offered) {
        const ew = marketModel.eachWay;
        const terms = ew.terms[0];

        if (terms !== undefined) {
            if (marketModel.templateId.includes('place-betting')) {
                return (
                    <TermsWrapper>
                        <SingleTermsWrapper>
                            <I18n
                                langKey='events.market-group.each-way-terms.places'
                                defaultText='{places} Places'
                                params={{ places: terms.places }}
                            />
                        </SingleTermsWrapper>
                    </TermsWrapper>
                );
            }

            return (
                <TermsWrapper>
                    <SingleTermsWrapper>
                        <I18n defaultText='EW Terms' langKey='events.market-group.each-way-terms.label' />
                    </SingleTermsWrapper>

                    <SingleTermsWrapper>{terms.reduction}</SingleTermsWrapper>

                    <SingleTermsWrapper>
                        <I18n
                            defaultText='{places} Places'
                            langKey='events.market-group.each-way-terms.places'
                            params={{ places: terms.places }}
                        />
                    </SingleTermsWrapper>
                </TermsWrapper>
            );
        }
    }

    return null;
});

interface MarketHeaderPropsTypes {
    name: string;
    isOpen: boolean;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    templateId: string;
    eventId: EventId;
    marketId: MarketId | undefined;
}

export const MarketHeader = observer('MarketHeader', (props: MarketHeaderPropsTypes): JSX.Element | null => {
    const { isOpen, eventId, marketId, name, onClick } = props;

    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { configComponents, languagesState, lifeSpanState } = appLayoutsState;
    const { rab } = appSportsBookState;
    const marketModel = marketId === undefined ? null : marketId.getModel();

    if (marketModel === null) {
        return null;
    }

    const { rabHeaderTitle } = configComponents.config;
    const isRAB = rab.isBetBuilderOpen;
    const rabAvailable = appSportsBookState.rab.isAvailableForEvent(eventId);

    const nameToDisplay =
        name === 'First Goalscorer'
            ? languagesState.getTranslation('_market.player to score', 'Player To Score')
            : languagesState.getMarketTranslationName(marketModel);

    const marketTranslationForView = nameToDisplay;

    const handleOnClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        onClick(e);
    };

    const { userRewardsMessageGrouped } = lifeSpanState;

    const LifespanArrowDisplayData = {
        userRewardsMessageGrouped,
        type: 'market',
        eventId: eventId?.toOldId(),
        marketId: marketId?.toOldId().toString(),
    };

    return (
        <MarketHeaderContent onClick={handleOnClick}>
            <ChevronIconWrapper position={isOpen ? 'down' : 'right'} />

            <LifespanBoostArrowDisplay data={LifespanArrowDisplayData} />

            <MarketHeaderTitle data-test-market-name={marketTranslationForView}>
                {isRAB === true && rabAvailable === true ? `#${rabHeaderTitle} - ` : ''}
                {marketTranslationForView}
            </MarketHeaderTitle>

            <Terms marketId={marketId} />
        </MarketHeaderContent>
    );
});
