import { css, SerializedStyles } from '@emotion/react';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { baseDropdownStyle } from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';



export const paymentDropDown = (theme: EmotionTheme): SerializedStyles => css`

    ${baseDropdownStyle()}



    &.custom-dropdown-select .react-select__control {

        font-size: ${theme.star.fontSize.xRegular};

        background: ${theme.star.form.light.bgColor};

        padding: 0 16px 0 12px;

        height: 40px;

    }



    &.custom-dropdown-select .react-select__control,

    &.custom-dropdown-select .react-select__option,

    &.custom-dropdown-select .react-select__placeholder {

        border: 1px solid ${theme.star.form.light.borderColor};

        color: ${theme.star.form.txtColor};

        cursor: pointer;

    }



    &.custom-dropdown-select .react-select__option {

        border-top: none;

        font-size: ${theme.star.fontSize.small};



        :hover {

            background: ${theme.star.form.bgColorSecondary};

        }

    }



    &.custom-dropdown-select .react-select__option--is-selected,

    &.custom-dropdown-select .react-select__option--is-focused {

        background-color: ${theme.star.form.dark.bgColor};

    }

`;

