import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { ContainerStepOne, BtnWrapper, PromoCodeContainer } from './PromoSignUp.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { LandingPage } from 'src/domains/players/state/specialEventsState/LandingPage';
import { action, makeObservable } from 'mobx';
import { SpecialEvents } from 'src/domains/players/state/specialEventsState/SpecialEvents';
interface PropsType {
    promotionSlug: string;
    lpState: LandingPage;
}

export class PromoSignupState {
    public constructor(
        private readonly specialEvents: SpecialEvents,
        private readonly lpState: LandingPage,
        private readonly promotionSlug: string
    ) {
        makeObservable(this);
    }

    @action public redirectToSignup = (): void => {
        this.lpState.addToLocalStore();
        this.specialEvents.handleRedirectToNewSignup(this.promotionSlug);
    };
}

export const PromoSignUpStep = observer('PromoSignUpStep', ({ lpState, promotionSlug }: PropsType) => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { specialEvents } = appPlayersState;
    const { languagesState } = appLayoutsState;
    const state = specialEvents.promoSignUpData;
    const { promoCode, ctaText } = specialEvents.getLandingPageState(promotionSlug);
    const [promoSignupState] = useState(() => new PromoSignupState(specialEvents, lpState, promotionSlug));

    const emailPlaceholder = languagesState.getTranslation('promo-sidebar.signup.placeholder.email', '*Email address');
    const passwordPlaceholder = languagesState.getTranslation(
        'promo-sidebar.signup.placeholder.password',
        '*Create password'
    );

    if (state === null) {
        return null;
    }

    return (
        <ContainerStepOne>
            <Input
                state={state.welcomeState.emailState}
                placeholder={emailPlaceholder}
                placeholderSize='small'
                showSuccessIcon={state.welcomeState.isCorrectEmail}
                dataTest='landing-page-email-input'
            />

            <Input
                placeholder={passwordPlaceholder}
                placeholderSize='small'
                state={state.welcomeState.passwordState}
                type={state.welcomeState.isShowPassword === true ? 'text' : 'password'}
                showPassword={state.welcomeState.showPassword}
                isShowPassword={state.welcomeState.isShowPassword}
                showPasswordFlag={true}
                showSuccessIcon={state.welcomeState.isCorrectPassword}
                dataTest='landing-page-password-input'
            />

            <PromoCodeContainer data-test='landing-page-promo-code'>{promoCode}</PromoCodeContainer>

            <BtnWrapper
                disabled={state.welcomeState.stepIsValid === false}
                type='submit'
                size='large'
                onClick={promoSignupState.redirectToSignup}
                dataTest='landing-page-signup-button'
            >
                {ctaText === '' ? 'Continue' : ctaText}
            </BtnWrapper>
        </ContainerStepOne>
    );
});
