import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { getBetsTranslation } from 'src/domains/layouts/shared/translations/bets';
import { BetTypeWrapper } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/betsList/Bet.style';
import { BetHistoryType } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.state';

export const BetType = observer<{ bet: BetHistoryType }>('BetType', ({ bet }) => {
    const appState = useAppStateContext();

    if (bet.type === 'RAB') {
        return <BetTypeWrapper>{ appState.config.rabHeaderTitle }</BetTypeWrapper>;
    }

    const betLabel = getBetsTranslation(appState, bet.type);
    if ( betLabel !== undefined) {
        return <BetTypeWrapper>{ betLabel }</BetTypeWrapper>;
    }

    return null;
});
