import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { CarouselSelection } from './CarouselSelection';
import { CarouselEventNavigationState } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigationState';

interface PropsType {
    state: CarouselEventNavigationState;
    isActiveCompetition?: string | null;
}

export const CarouselEventNavigationEvent = observer('CarouselEventNavigationEvent', (props: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { isActiveCompetition, state } = props;

    if (isActiveCompetition !== null && isActiveCompetition !== undefined) {
        return (
            <CarouselSelection
                options={state.eventState.eventsData}
                placeholder={languagesState.getTranslation('events.navigation.event', 'Select event')}
                active={state.eventState.active}
                type='event'
            />
        );
    }

    return null;
});
