import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { importAmd } from 'src/utils/require_amd';
import { DarkLoadingSpinner } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';
import { PaysafeTabState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/paysafeProvider/PaysafeTab.state';
import { PaymentIframeData } from './PaysafeFrameOptions';
import { PaysafePaymentProviderType } from 'src/domains/layouts/config/features/types';

export interface PaysafeFramePropsType {
    paymentIframeData: PaymentIframeData;
    paysafeTabState: PaysafeTabState;
    paysafePaymentProvider: PaysafePaymentProviderType;
}

export const PaysafeFrame = observer(
    'PaysafeiFrame',
    ({ paymentIframeData, paysafeTabState, paysafePaymentProvider }: PaysafeFramePropsType): JSX.Element | null => {
        React.useEffect(() => {
            const initPayment = async (): Promise<void> => {
                await importAmd(`https://hosted.paysafe.com/checkout/v2/paysafe.checkout.min.js`);

                //@ts-expect-error
                window.paysafe.checkout.setup(
                    paysafePaymentProvider.apiKey,
                    paymentIframeData.data,
                    paysafeTabState.paysafeResultCallback(paymentIframeData),
                    paysafeTabState.paysafeStateCallback
                );
            };
            initPayment().catch((err) => {
                console.error('paysafe-iframe-init', err);
                paysafeTabState.stepsState.redirectToFailureView('serverIssue');
            });
        }, []);

        return (
            <div>
                <DarkLoadingSpinner />
            </div>
        );
    }
);
