import { action, computed, makeObservable } from 'mobx';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';

export class CheckboxState {
    public constructor(
        private readonly checkboxState: FormInputState<boolean,boolean>,
    ) {
        makeObservable(this);
    }

    @action public handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.currentTarget;

        this.checkboxState.setValue(checked);
        this.checkboxState.setAsVisited();
    };

    @computed public get value(): boolean {
        return this.checkboxState.value;
    }
};
