import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BetslipMessage } from './BetslipSpecialMessage.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';

export const BetslipSpecialMessage = observer('BetslipSpecialMessage', () => {
    const {
        appSportsBookState: { betSlipState },
        sdkCustomer,
    } = useAppStateContext();
    const { combinationWithoutCast2, basicBetSlipState, legsState } = betSlipState;
    const { related } = basicBetSlipState;
    const firstCombinationWithoutCast2 = combinationWithoutCast2[0];

    if (firstCombinationWithoutCast2 === undefined) {
        return null;
    }

    const combinationElem2 = firstCombinationWithoutCast2.getModel();
    if (combinationElem2 === null) {
        return null;
    }

    const isEw = combinationElem2.eachWay ?? false;

    const { isAnyLegSP } = legsState;

    const specialMessageStake = combinationElem2.specialMessageStake;

    const shouldShowMessage =
        !isAnyLegSP &&
        !related &&
        (!isEw ||
            (isEw &&
                combinationElem2.stakePerLine !== null &&
                !combinationElem2.stakePerLine.isEqualWith(new Amount('0'))));

    return shouldShowMessage ? (
        <BetslipMessage>
            <I18n
                langKey='betslip.combination.special-message'
                defaultText='{stake} returns {returns}'
                params={{
                    stake: sdkCustomer.money(new Amount('1')),
                    returns: sdkCustomer.money(specialMessageStake),
                }}
            />
        </BetslipMessage>
    ) : null;
});
