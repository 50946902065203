import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { getBetsTranslation } from 'src/domains/layouts/shared/translations/bets';
import {
    BetSelectionHeader,
    BetSelectionHeaderEventName,
    ArrowBetSelectionWrapper,
    BetType,
    BetElementList,
} from 'src/domains/sportsbook/betting/bettingTab/cashout/selection/Selection.style';
import { LegsInBet } from './LegsInBet';
import { BetSummary } from './betSummary/BetSummary';
import { BogMessageComponent } from './BogMessageComponent';
import { SettledCashOut } from './SettledCashout';
import { CashOutButtons } from 'src/domains/sportsbook/betting/bettingTab/cashout/selection/selectionNew/CashoutButtons';
import { SingleBetItemState } from 'src/domains/sportsbook/betting/betSlipState/betsState/SingleBetItemState';
import { computed, makeObservable } from 'mobx';
import { SelectionBoostedMessage } from './SelectionBoostedMessage';
import { BetInfoBetHistory } from 'src/domains/layouts/state/lifespanState/LifespanSocketState';
import { BetsFilter } from 'src/domains/sportsbook/betting/state/BetsState';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsTypesSelection {
    betId: number | null;
}

const renderBoostMessage = (
    betBoostedInfo: BetInfoBetHistory | null,
    betItem: SingleBetItemState,
    isWon: boolean,
    selectedFilter: string
): JSX.Element | null => {
    const { status, isAllTypeVoid, cashOut } = betItem;

    if (
        cashOut === false &&
        selectedFilter !== BetsFilter.CASH_OUT &&
        betBoostedInfo !== null &&
        betBoostedInfo.betInfo !== null &&
        isAllTypeVoid === false &&
        (isWon === true || status === 'open')
    ) {
        return (
            <SelectionBoostedMessage
                value={betBoostedInfo.betInfo.reward.value}
                potentialReturns={betItem.potentialReturns}
            />
        );
    }

    return null;
};

class SelectionState {
    private readonly bet: SingleBetItemState;

    public constructor(bet: SingleBetItemState) {
        makeObservable(this);
        this.bet = bet;
    }

    @computed public get checkStatusSettled(): boolean {
        return this.bet.status === 'settled';
    }

    @computed public get checkIfBetIsWon(): boolean {
        return this.bet.payout !== null && this.bet.payout.isGreaterThanZero();
    }
}

export const Selection = observer('Selection', ({ betId }: PropsTypesSelection) => {
    const appState = useAppStateContext();
    const { appLayoutsState, appSportsBookState } = appState;
    const { configComponents, lifeSpanState } = appLayoutsState;
    const { config } = configComponents;
    const { betsState } = appSportsBookState;

    if (betId === null) {
        return null;
    }

    const { getBetItemById, selectedFilter } = betsState;
    const betItem = getBetItemById(betId);
    const [state] = useState(() => new SelectionState(betItem));
    const { isSelectionOpen, toggleSelectionOpen, cashOutErrors, id, type } = betItem;

    if (id === null) {
        return null;
    }

    const betType = type === null ? null : getBetsTranslation(appState, type) ?? null;

    const betBoostedInfo = lifeSpanState.lifeSpanSocketState.myBetsBetHistory(betId.toString());

    return (
        <BetElementList
            hasStatus={state.checkStatusSettled}
            winingBet={state.checkIfBetIsWon}
            data-test='bet-element'
            data-test-bet-type={betType}
        >
            {betType?.toLowerCase() === 'single' ? null : (
                <BetSelectionHeader>
                    <ArrowBetSelectionWrapper
                        position={isSelectionOpen ? 'down' : 'right'}
                        onClick={toggleSelectionOpen}
                    />
                    <BetType data-test='bet-type'>{betType}</BetType>
                    {type === 'RAB' ? (
                        <BetSelectionHeaderEventName data-test='rab-event-name'>
                            {betItem.legs[0]?.event.name ?? config.rabHeaderTitle}
                        </BetSelectionHeaderEventName>
                    ) : null}
                </BetSelectionHeader>
            )}

            {isSelectionOpen ? (
                <>
                    <LegsInBet betItem={betItem} />
                    <BetSummary betItem={betItem} />
                    <BogMessageComponent betItem={betItem} />
                    <SettledCashOut betItem={betItem} />
                    <CashOutButtons betItem={betItem} />
                    {cashOutErrors.length > 0 ? (
                        <div className='bet__errors'>
                            {cashOutErrors.map((elem) => (
                                <Messages type='error' marginBottom='16px' key={elem} message={elem} />
                            ))}
                        </div>
                    ) : null}
                </>
            ) : null}

            {renderBoostMessage(betBoostedInfo, betItem, state.checkIfBetIsWon, selectedFilter)}
        </BetElementList>
    );
});
