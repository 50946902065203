import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { PrimaryLoaderContainer } from 'src/domains/layouts/shared/loaders';
import { fadeIn } from 'src/domains/layouts/shared/Animation.style';
import { withConfig } from 'src/withConfig';
import { NFLIcon } from 'src/domains/layouts/shared/assets/icons/NFLIcon';

interface SportHeaderPropsType {
    textColor?: string | null;
    bgColor?: string | null;
    hasImage?: boolean;
    backgroundImgDesktop?: string | null;
    backgroundImgMobile?: string | null;
    backgroundImgTablet?: string | null;
    isCustomImgDesktop?: boolean;
    isCustomImgTablet?: boolean;
    isCustomImgMobile?: boolean;
}

const renderBackground = (
    backgroundImg: string | null | undefined,
    isCustomImage: boolean | undefined,
    bgColor: string | null | undefined,
    theme: EmotionTheme
): SerializedStyles => {
    if (backgroundImg !== null && backgroundImg !== undefined && isCustomImage !== undefined) {
        return css`
            background-image: ${isCustomImage ? `url(${backgroundImg})` : `${theme.star.headings.sportBanner.bgColorTertiary}, url(${backgroundImg})`};
        `;
    }

    if (bgColor !== null && bgColor !== undefined) {
        return css`
            background-color: ${bgColor};
        `;
    }

    return css`
        background-color: ${theme.star.headings.sportBanner.bgColor};
    `;
};

const renderHeaderHasImage = (
    backgroundImgDesktop: string | null | undefined,
    backgroundImgTablet: string | null | undefined,
    bgColor: string | null | undefined,
    hasImage: boolean | undefined,
    isCustomImgDesktop: boolean | undefined,
    isCustomImgTablet: boolean | undefined,
    theme: EmotionTheme,
): SerializedStyles => {

    if (hasImage === true) {
        return css`
            background-position: right top;
            background-repeat: no-repeat;
            background-size: auto 100%;
            position: relative;
            @media ${theme.star.mediaQuery.tablet} {
                ${renderBackground(backgroundImgTablet, isCustomImgTablet, bgColor, theme)};
            }

            @media ${theme.star.mediaQuery.xLargeDesktop} {
                ${renderBackground(backgroundImgDesktop, isCustomImgDesktop, bgColor, theme)};
            }
        `;
    }

    return css`
        align-items: flex-start;
    `;
};

export const SportHeaderWrapper = withConfig(theme => styled('div', { label: 'SportHeaderWrapper' })<SportHeaderPropsType>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 64px;
    padding: 18px 0 18px 8px;
    ${({ backgroundImgMobile, isCustomImgMobile, bgColor }): SerializedStyles => renderBackground(backgroundImgMobile, isCustomImgMobile, bgColor, theme)};

    ${({
        hasImage,
        isCustomImgDesktop,
        isCustomImgTablet,
        bgColor,
        backgroundImgDesktop,
        backgroundImgTablet
    }): SerializedStyles => renderHeaderHasImage(
        backgroundImgDesktop,
        backgroundImgTablet,
        bgColor,
        hasImage,
        isCustomImgDesktop,
        isCustomImgTablet,
        theme,
    )};
    animation: ${fadeIn} .3s ease-in-out;
    white-space: nowrap;
    @media ${theme.star.mediaQuery.tablet} {
        height: 96px;
        padding: 28px 0 28px 16px;
    }
`);

const renderTextColor = (textColor: string | null | undefined, hasImage: boolean | undefined, theme: EmotionTheme): SerializedStyles => {
    if (textColor === null || textColor === undefined) {
        if (hasImage === true) {
            return css`
                color: ${theme.star.headings.sportBanner.txtColor};
            `;
        }

        return css`
            color: ${theme.star.headings.sportBanner.txtColorSecondary};
        `;
    }

    return css`
        color: ${textColor};
    `;
};

type SportHeaderRowType = Pick<SportHeaderPropsType, 'textColor' | 'hasImage'>;

export const SportHeaderRow = withConfig(theme => styled('div', { label: 'SportHeaderRow' })<SportHeaderRowType>`
    align-items: center;
    ${({ textColor, hasImage }): SerializedStyles => renderTextColor(textColor, hasImage, theme)};
    display: flex;
    position: relative;
    min-height: ${({ hasImage }): string => hasImage === false ? '32px' : '30px'};
    animation: ${fadeIn} .3s ease-in-out;
`);

type SportNameType = Pick<SportHeaderPropsType, 'hasImage'>;

export const SportName = withConfig(theme => styled('h2', { label: 'SportName' })<SportNameType>`
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.25;
    margin: 0;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.big};
        ${({ hasImage }): SerializedStyles => hasImage === true ? css`` : css`
            display: flex;
            align-items: center;
        `}
    }
`);

export const SportHeaderRowLogo = styled(NFLIcon, { label: 'SportHeaderRowLogo' })`
    display: block;
    margin-right: 6px;
    width: 32px;
`;

export const LoaderWrapper = withConfig(theme => styled('div', { label: 'LoaderWrapper' })`
    align-items: center;
    background-color: ${theme.star.headings.sportBanner.bgColor};
    display: flex;
    height: 64px;
    @media ${theme.star.mediaQuery.tablet} {
        height: 96px;
    }
`);

export const Loader = withConfig(theme => styled(PrimaryLoaderContainer, { label: 'Loader' })`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorNonary};
    height: 25px;
    margin-left: 8px;
    width: 150px;
    @media ${theme.star.mediaQuery.tablet} {
        margin-left: 16px;
        height: 30px;
    }
`);
