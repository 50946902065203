import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { MarketSeparator } from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroup.style';
import { withConfig } from 'src/withConfig';


export const LiveTrackerToggleWrapper = withConfig(theme => styled('header', { label: 'LiveTrackerToggleWrapper' })`
    align-items: center;
    background: ${theme.star.headings.market.bgColor};
    color: ${theme.star.headings.market.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 28px;
    line-height: 1.3334;
    padding: 8px;
    text-transform: capitalize;
`);

const renderPreMatchBgImage = (bgImage: string | null, theme: EmotionTheme): SerializedStyles => {
    if (bgImage === null ) {
        return css``;
    }

    return css`
        background-color: ${theme.star.preMatch.bgColor};
        &::before {
            ${positionElementFull};
            background-image: url(${bgImage}), ${theme.star.preMatch.bgColorSecondary};
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            position: absolute;
            z-index: ${theme.star.zIndexGlobal.below};
        }
    `;
};

interface LiveTrackerWrapperType {
    bgImage: string | null;
    isOpen: boolean;
}

/* Background container for Live tracker */
export const LiveTrackerWrapper = withConfig(theme => styled('div', { label: 'LiveTrackerWrapper' })<LiveTrackerWrapperType>`
    height: ${({ isOpen }): string => (isOpen ? '100%' : '0')};
    margin: auto;
    overflow: hidden;
    padding: ${({ isOpen }): string => (isOpen ? '16px 16px 6px' : '0 16px')};
    position: relative;
    text-align: center;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.base};
    ${({ bgImage }): SerializedStyles => renderPreMatchBgImage(bgImage, theme)};
`);

export const LiveTrackerChevron = styled(ChevronIcon, { label: 'LiveTrackerChevron' })`
    fill: currentcolor;
    width: 12px;
    margin-right: 8px;
`;

interface ReferencePropsType {
    eventId: number;
}

export const ScoreboardWrapper = styled('div', { label: 'ScoreboardWrapper' })<ReferencePropsType>``;
export const SportWidget = styled('div', { label: 'SportWidget' })``; 
export const Separator = styled(MarketSeparator, { label: 'Separator' })``;
