import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ErrorMessageWrapper, OptionsWrapper, SettingsList } from './OffersSettings.style';
import { GroupOption, RadioButtons } from 'src/domains/layouts/webview/components/form/radioButtons/RadioButtons';
import { OffersContactPreferencesState } from './OffersSettingsState';
import { observer } from 'src/utils/mobx-react';
import { ButtonsWrapper, ButtonTitle, SectionWrapper } from 'src/domains/players/webview/components/Account';
import { useAppStateContext } from 'src/appState/AppState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface PropsType {
    groupState: OffersContactPreferencesState;
    skin: 'light' | 'dark';
    onChange?: () => void;
    isProcessingRequest?: boolean;
}

export const radioButtonGroupValuesYesNo: Array<GroupOption<'yes' | 'no'>> = [
    {
        value: 'yes',
        label: <I18n langKey='signup.contact-preferences.yes.label' defaultText='Yes' />,
    },
    {
        value: 'no',
        label: <I18n langKey='account.contact-preferences.no.label' defaultText='No' />,
    },
];

export const OffersSettings = observer('OffersSettings', (props: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const {
        configComponents: { config },
    } = appLayoutsState;

    const isVisited = props.groupState.contactPreferencesGroup.isVisited();

    return (
        <SettingsList isProcessingRequest={props.isProcessingRequest}>
            {config.contactPreferences.includes('email') === true ? (
                <SectionWrapper data-test='section-wrapper'>
                    <ButtonsWrapper data-test='email-settings'>
                        <ButtonTitle>
                            <I18n langKey='account.contact-preferences.settings.email' defaultText='Email' />
                        </ButtonTitle>
                        <OptionsWrapper>
                            <RadioButtons
                                name='email'
                                gap='39'
                                group={radioButtonGroupValuesYesNo}
                                color={props.skin}
                                state={props.groupState.emailState}
                                onChangeCallback={props.onChange}
                            />
                        </OptionsWrapper>
                    </ButtonsWrapper>
                    {props.groupState.emailState.result.value.type === 'error' && isVisited === true ? (
                        <Messages
                            type='error'
                            marginBottom='16px'
                            message={props.groupState.emailState.result.errors()?.[0] ?? ''}
                        />
                    ) : null}
                </SectionWrapper>
            ) : null}
            {config.contactPreferences.includes('sms') === true ? (
                <SectionWrapper data-test='section-wrapper'>
                    <ButtonsWrapper data-test='sms-settings'>
                        <ButtonTitle>
                            <I18n langKey='account.contact-preferences.settings.sms' defaultText='SMS' />
                        </ButtonTitle>
                        <OptionsWrapper>
                            <RadioButtons
                                name='sms'
                                gap='39'
                                group={radioButtonGroupValuesYesNo}
                                color={props.skin}
                                state={props.groupState.smsState}
                                onChangeCallback={props.onChange}
                            />
                        </OptionsWrapper>
                    </ButtonsWrapper>
                    {props.groupState.smsState.result.value.type === 'error' && isVisited === true ? (
                        <Messages
                            type='error'
                            marginBottom='16px'
                            message={props.groupState.smsState.result.errors()?.[0] ?? ''}
                        />
                    ) : null}
                </SectionWrapper>
            ) : null}
            {config.contactPreferences.includes('phone') === true ? (
                <SectionWrapper data-test='section-wrapper'>
                    <ButtonsWrapper data-test='phone-settings'>
                        <ButtonTitle>
                            <I18n langKey='account.contact-preferences.settings.phone' defaultText='Phone' />
                        </ButtonTitle>
                        <OptionsWrapper>
                            <RadioButtons
                                name='phone'
                                gap='39'
                                group={radioButtonGroupValuesYesNo}
                                color={props.skin}
                                state={props.groupState.phoneState}
                                onChangeCallback={props.onChange}
                            />
                        </OptionsWrapper>
                    </ButtonsWrapper>
                    {props.groupState.phoneState.result.value.type === 'error' && isVisited === true ? (
                        <Messages
                            type='error'
                            marginBottom='16px'
                            message={props.groupState.phoneState.result.errors()?.[0] ?? ''}
                        />
                    ) : null}
                </SectionWrapper>
            ) : null}
            {config.contactPreferences.includes('whatsApp') === true ? (
                <SectionWrapper data-test='section-wrapper'>
                    <ButtonsWrapper data-test='whatsApp-settings'>
                        <ButtonTitle>
                            <I18n langKey='account.contact-preferences.settings.whatsApp' defaultText='WhatsApp' />
                        </ButtonTitle>
                        <OptionsWrapper>
                            <RadioButtons
                                name='whatsApp'
                                gap='39'
                                group={radioButtonGroupValuesYesNo}
                                color={props.skin}
                                state={props.groupState.whatsappState}
                                onChangeCallback={props.onChange}
                            />
                        </OptionsWrapper>
                    </ButtonsWrapper>
                    {props.groupState.whatsappState.result.value.type === 'error' && isVisited === true ? (
                        <ErrorMessageWrapper
                            type='error'
                            marginBottom='16px'
                            message={props.groupState.whatsappState.result.errors()?.[0] ?? ''}
                        />
                    ) : null}
                </SectionWrapper>
            ) : null}
        </SettingsList>
    );
});
