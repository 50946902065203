import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { ActiveHomePageCarouselType } from 'src/api/config/cms/getActiveHomePageCarousel';
import {
    HomePageCarouselSingleItem,
    CardBodyActionLink,
    CardFooterText,
    CardFooterWrapper,
    CardBodyActionWrapper,
    CardButtonWrapper,
    TcApplied,
    CardButton,
    LazyImageItem,
} from './HomePageCarouselPromos.style';
import { HomePageCarouselItemState } from './HomePageCarouselItem.state';
import { CardBodyComponent } from './CardBodyComponent';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import PlaceholderImage from 'src/domains/layouts/webview/components/homePageCarouselPromos/assets/default_hpc_image.webp';

interface PropsTypes {
    promo: ActiveHomePageCarouselType;
}

const ButtonComponent = ({ btn_title, btnColor }: { btn_title: string; btnColor?: string }): JSX.Element => {
    return (
        <CardButtonWrapper>
            <CardButton type='button' size='xs' btnColor={btnColor} dataTest='promo-carousel-button'>
                {btn_title}
            </CardButton>
        </CardButtonWrapper>
    );
};

export const ItemTypePromo = observer('ItemTypePromo', ({ promo }: PropsTypes) => {
    const { appLayoutsState, appCasinoState } = useAppStateContext();
    const { starRouter } = appLayoutsState;

    const [state] = useState(() => new HomePageCarouselItemState(promo, starRouter, appCasinoState));

    const { img_url, bg_color, tc_text, btn_title, btn_color } = promo;
    const { actionTemplate, btnLink, handleRedirect, isShowTC, onApplyTC, isTooLongTerms } = state;
    const tcText = tc_text ?? '';
    const btnColor = btn_color ?? '';
    const btnTitle = btn_title ?? '';

    const handleMatchUrl = (): void => {
        if (btnLink === null) return;
        starRouter.handleUrlRedirection(btnLink);
    };

    const imageUrl = img_url ?? null;

    return (
        <HomePageCarouselSingleItem bgColor={bg_color ?? undefined} data-test={`promo-carousel-item-${promo.title}`}>
            {imageUrl === null ? (
                <LazyImageItem imgBase={{ src: PlaceholderImage }} imgFit='contain' alt='' placeholder={true} />
            ) : (
                <LazyImageItem imgBase={{ src: imageUrl }} imgFit='contain' alt='' placeholder={true} />
            )}

            {actionTemplate === 'direct-link' ? (
                <CardBodyActionLink onClick={handleMatchUrl}>
                    <CardBodyComponent promo={promo} />
                    {btnTitle === '' ? null : (
                        <ButtonComponent btn_title={btnTitle} btnColor={btnColor === '' ? undefined : btnColor} />
                    )}
                </CardBodyActionLink>
            ) : (
                <CardBodyActionWrapper onClick={handleRedirect} isClickable={actionTemplate !== null}>
                    <CardBodyComponent promo={promo} />
                    {btnTitle !== '' && actionTemplate !== null ? (
                        <ButtonComponent btn_title={btnTitle} btnColor={btnColor === '' ? undefined : btnColor} />
                    ) : null}
                </CardBodyActionWrapper>
            )}

            {tcText === '' ? null : (
                <CardFooterWrapper>
                    {isShowTC === true || isTooLongTerms === false ? (
                        <CardFooterText dangerouslySetInnerHTML={{ __html: tcText }} data-test='promo-carousel-tc' />
                    ) : (
                        <TcApplied onClick={onApplyTC} data-test='promo-carousel-tc'>
                            <I18n
                                langKey='promos.terms-and-conditions-apply'
                                defaultText='Terms and Conditions Apply'
                            />
                        </TcApplied>
                    )}
                </CardFooterWrapper>
            )}
        </HomePageCarouselSingleItem>
    );
});
