import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { VerificationFailedPageState } from './VerificationFailedPage';
import { VerifyInfoGamStop, HomeButtonWrapper } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/ProfileVerification.style';
import {
    VerificationFailedWrapper,
    EmailLink,
    WarningTriangleGamStop,
    WarningTriangleIconWrapper
} from './VerificationFailed.style';
import { SignupTypes } from 'src/domains/players/webview/components/SignUp';


interface PropsType {
    signUpState: SignupTypes;
}

export const GamStopAccountFailedPage = observer('GamStopAccountFailedPage', ({ signUpState }: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents: { config }, starRouter } = appLayoutsState;
    const [state] = useState(() => new VerificationFailedPageState(signUpState, starRouter));
    const { signUpFooterMail } = config;

    return (
        <VerificationFailedWrapper>
            <WarningTriangleGamStop>
                <WarningTriangleIconWrapper />
            </WarningTriangleGamStop>
            <VerifyInfoGamStop>
                <I18n
                    langKey='signup-journey.gamstop-account-failed.info'
                    defaultText='Your account is currently suspended Please contact customer services on'
                />
                <EmailLink href={`mailto: ${signUpFooterMail}`}> {signUpFooterMail}</EmailLink>
            </VerifyInfoGamStop>
            <HomeButtonWrapper size='large' onClick={state.onHomeClick} dataTest='home-button'>
                <I18n
                    langKey='signup-journey.gamstop-account-failed.home-btn'
                    defaultText='Home'
                />
            </HomeButtonWrapper>
        </VerificationFailedWrapper>
    );
});
