import { LinkItemLinkType } from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

export class PopularSportState {
    private readonly config: ConfigComponents;
    private readonly translationsStore: TranslationsStore;

    public constructor(config: ConfigComponents, translationsStore: TranslationsStore) {
        this.translationsStore = translationsStore;
        this.config = config;
    }

    public generateNavigationList = (sportsArray: Array<string>): LinkItemLinkType[] => {
        const { hiddenSportsList } = this.config.config;
        const out: LinkItemLinkType[] = [];

        sportsArray.forEach((singlePopularSport) => {
            if (hiddenSportsList.includes(singlePopularSport) === true) {
                return;
            }

            if (singlePopularSport === 'horseracing' || singlePopularSport === 'greyhoundracing') {
                out.push({
                    key: singlePopularSport,
                    to: {
                        name: 'sport',
                        nameType: 'races',
                        id: singlePopularSport,
                        type: null,
                        event: null,
                    },
                    label: this.translationsStore.translateSport(singlePopularSport),
                });
            } else {
                out.push({
                    key: singlePopularSport,
                    to: {
                        name: 'sport',
                        nameType: 'regular',
                        id: singlePopularSport,
                    },
                    label: this.translationsStore.translateSport(singlePopularSport),
                });
            }
        });
        return out;
    };
}
