import React, { useState } from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ContainerConfigType } from 'src/domains/sportsbook/webview/components/scoreboards/liveTracker/ContainerConfig';
import {
    LiveTrackerChevron,
    LiveTrackerToggleWrapper,
    LiveTrackerWrapper,
    ScoreboardWrapper,
    SportWidget,
    Separator,
} from 'src/domains/sportsbook/webview/components/scoreboards/liveTracker/LiveTrackerIframe.style';
import { observer } from 'src/utils/mobx-react';
import { LiveTrackerIframeState } from './LiveTrackerIframe.state';
import { UniverseComponent } from 'src/domains/common/universes';

interface ReferencePropsType {
    eventId: number;
    providerName: string;
    containerConfig: ContainerConfigType;
}

export const LiveTrackerIframe = observer(
    'LiveTrackerIframe',
    ({ eventId, providerName, containerConfig }: ReferencePropsType): JSX.Element => {
        const [state] = useState(() => new LiveTrackerIframeState());

        setTimeout(() => {
            const widgetParams = document.createElement('script');
            widgetParams.type = 'text/javascript';
            widgetParams.innerHTML = `
            var widget_params = {
                container_id: '${containerConfig.containerId}',
                id: '${eventId}',
                provider: '${providerName}',
                onError: function(){console.log('error');} 
            };
        `;

            const widgetScript = document.createElement('script');
            widgetScript.src = containerConfig.scriptSrc;

            document.body.appendChild(widgetParams);
            document.body.appendChild(widgetScript);
        }, 0);

        return (
            <>
                <LiveTrackerToggleWrapper onClick={state.toggle}>
                    <LiveTrackerChevron position={state.isOpen ? 'down' : 'right'} />
                    <I18n langKey='scoreboard.live-tracker.title' defaultText='Live tracker' />
                </LiveTrackerToggleWrapper>

                {state.isOpen ? null : <Separator />}
                <UniverseComponent>
                    {(universeVersion): React.ReactElement => (
                        <LiveTrackerWrapper bgImage={universeVersion.liveTrackerBgUrl} isOpen={state.isOpen}>
                            <SportWidget id={containerConfig.containerId} />
                            <ScoreboardWrapper eventId={eventId} />
                        </LiveTrackerWrapper>
                    )}
                </UniverseComponent>
            </>
        );
    }
);
