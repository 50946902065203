import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { Header, NavigationSidebar } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import {
    ErrorHeadline,
    ErrorInfo,
    ErrorPageWrapper,
    ErrorPageContent,
    ButtonWrapper,
    Error404MainHeadline,
    SpinnerWrapper,
} from './PageNotFound.style';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import { UniverseComponent } from 'src/domains/common/universes';

interface PropsTypes {
    currentView: RouteViewType | null;
    accountView: RightHandSideViewType | null;
}

export const PageNotFoundFallBackLoader = observer('PageNotFoundFallBackLoader', () => {
    return (
        <SpinnerWrapper>
            <Loader size={60}/>
        </SpinnerWrapper>
    );
});

export const PageNotFound = observer('ErrorPage', (props: PropsTypes) => {
    const { appLayoutsState } = useAppStateContext();
    const { starRouter, configComponents } = appLayoutsState;
    const widthInner = getWindowInnerWidth();

    const { config } = configComponents;
    const isPage404Default = config.page404Default;
    const currentView = props.currentView;
    const hideSidebar = widthInner === null || widthInner >= 1200 || currentView === null;
    return (
        <>
            {props.currentView === null ? null : <Header accountView={props.accountView} currentView={props.currentView}/>}

            {hideSidebar ? null : <NavigationSidebar currentView={currentView} isSearchActive={false} navigationType='global' />}
            <ErrorPageWrapper>
                <ErrorPageContent>
                    <UniverseComponent>
                        {(universeVersion): React.ReactElement => (
                            <universeVersion.error404Image />
                        )}
                    </UniverseComponent>

                    {isPage404Default ? null : <Error404MainHeadline>404</Error404MainHeadline>}

                    <ErrorHeadline isDefault={isPage404Default}>
                        <I18n langKey='layouts.page-not-found.headline' defaultText='This page does not exist' />
                    </ErrorHeadline>

                    <ErrorInfo>
                        <I18n langKey='layouts.page-not-found.info' defaultText='Please check the link or alternatively return to homepage.' />
                    </ErrorInfo>

                    <ButtonWrapper size='large' onClick={starRouter.redirectToHomepage}>
                        <I18n langKey='layouts.page-not-found.go-back-home-btn' defaultText='Go Back Home' />
                    </ButtonWrapper>
                </ErrorPageContent>
            </ErrorPageWrapper>
        </>
    );
});
