import * as t from 'io-ts';
import { RabMarketIO } from 'src_common/common/websocket2/modelsApi/RabMarket';

export const SubscriptionResourceIdIO = t.union([
    t.interface({
        type: t.literal('ModelSport'),
        id: t.string,
    }),
    t.interface({
        type: t.literal('ModelCompetition'),
        id: t.number,
    }),

    t.interface({
        type: t.literal('ModelMarket'),
        eventId: t.number,
        id: t.number,
    }),

    t.interface({
        type: t.literal('ModelEvent'),
        id: t.number,
    }),

    t.interface({
        type: t.literal('ModelEventMarkets'),
        id: t.number,
    }),

    t.interface({
        type: t.literal('ModelEventMarketsAll'),
        id: t.number,
    }),

    t.interface({
        type: t.literal('QueryEvents'),
        query: t.string,
    }),

    t.interface({
        type: t.literal('ModelRabMarkets'),
        platformId: t.string,
    }),

    t.interface({
        type: t.literal('ModelTagEvent'),
        id: t.number,
    }),

    t.interface({
        type: t.literal('ModelTagMarket'),
        id: t.number,
    })
]);

export type SubscriptionResourceIdType = t.TypeOf<typeof SubscriptionResourceIdIO>;

export const SubscriptionResourceUpdateIO = t.union([
    t.interface({
        type: t.literal('ModelSport'),
        id: t.string,
        data: t.unknown,
    }),
    t.interface({
        type: t.literal('ModelCompetition'),
        id: t.number,
        data: t.unknown,
    }),
    t.interface({
        type: t.literal('ModelMarket'),
        eventId: t.union([t.undefined, t.null, t.number ]),
        id: t.number,
        data: t.unknown,
    }),
    t.interface({
        type: t.literal('ModelEvent'),
        id: t.number,
        event: t.unknown,
    }),
    t.interface({
        type: t.literal('ModelEventMarkets'),
        id: t.number,
        data: t.unknown,
    }),
    t.interface({
        type: t.literal('ModelEventMarketsAll'),
        id: t.number,
        data: t.unknown,
    }),
    t.interface({
        type: t.literal('QueryEvents'),
        query: t.string,
        data: t.interface({
            group: t.unknown,
            query: t.string,
        }),
    }),
    t.interface({
        type: t.literal('ModelRabMarkets'),
        platformId: t.string,
        rabMarkets: t.array(RabMarketIO)
    }),
    t.interface({
        type: t.literal('ModelTagEvent'),
        id: t.number,
        data: t.unknown,
    }),
    t.interface({
        type: t.literal('ModelTagMarket'),
        id: t.number,
        data: t.unknown,
    })
]);

export type SubscriptionResourceUpdateType = t.TypeOf<typeof SubscriptionResourceUpdateIO>;

