import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    SportHeaderRow,
    SportHeaderRowLogo,
    SportHeaderWrapper,
    SportName,
    Loader,
    LoaderWrapper,
} from 'src/domains/layouts/webview/components/sportHeader/SportHeader.style';
import { CustomizedSportHeaderState } from './CustomizedSportHeaderState';
import { SportHeaderContainer } from 'src/domains/layouts/shared/SportsAndScoreboards.style';
import bgPattern from 'src/domains/layouts/shared/assets/backgrounds/sports/bgPatternHexagon.webp';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { RenderEventFilters } from 'src/domains/layouts/webview/components/sportHeader/SportHeaderFilters';

export interface SportHeaderPropsType {
    sportId: string;
    competitionId: CompetitionId | null;
    options: {
        desktopImage: string | null | undefined;
        tabletImage: string | null | undefined;
        mobileImage: string | null | undefined;
    };
    name?: string;
    isSpecialEvent?: boolean;
    isCompetitionPage: boolean;
}

export const SportHeaderStar = observer('SportHeaderStar', (props: SportHeaderPropsType) => {
    const appState = useAppStateContext();
    const { sportId, isSpecialEvent, isCompetitionPage } = props;
    const specialSportState = appState.appSportsBookState.specialSportsMap.get(sportId);
    const specialExpandForSport = specialSportState.specialExpandForSport;
    const sportName =
        specialExpandForSport?.label ?? appState.appLayoutsState.translationsStore.translateSport(sportId);

    const isFootball = sportId === 'football';
    const isSpecialSport = props.isSpecialEvent;

    const sportState = new CustomizedSportHeaderState(appState.appSportsBookState.sportsConfigState, props);

    const sportHeaderDetails = sportState.getSportDetails;
    const renderFilters = isFootball || isSpecialSport === true;

    return (
        <>
            {isSpecialSport === true ? null : (
                <SportHeaderContainer bgImage={bgPattern} isActiveSportHeaderImage={sportState.isActiveHeaderImage}>
                    {sportState.isLoading === true ? (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    ) : (
                        <SportHeaderWrapper
                            hasImage={sportState.isActiveHeaderImage}
                            bgColor={sportHeaderDetails?.header_bg_color}
                            isCustomImgDesktop={sportState.isCustomImgDesktop}
                            isCustomImgTablet={sportState.isCustomImgTablet}
                            isCustomImgMobile={sportState.isCustomImgMobile}
                            backgroundImgDesktop={sportState.headerImgDesktop}
                            backgroundImgTablet={sportState.headerImgTablet}
                            backgroundImgMobile={sportState.headerImgMobile}
                            data-test='sport-header-wrapper'
                        >
                            <SportHeaderRow
                                hasImage={sportState.isActiveHeaderImage}
                                textColor={sportHeaderDetails?.sport_label_color}
                            >
                                {sportName === 'American Football' ? <SportHeaderRowLogo /> : null}
                                <SportName hasImage={sportState.isActiveHeaderImage}>
                                    {sportHeaderDetails?.sport_label ?? sportName}
                                </SportName>
                            </SportHeaderRow>
                        </SportHeaderWrapper>
                    )}
                </SportHeaderContainer>
            )}

            {renderFilters === true ? (
                <RenderEventFilters
                    competitionId={props.competitionId}
                    isCompetitionPage={isCompetitionPage}
                    isSpecialEvent={isSpecialEvent}
                    specialSportsState={specialSportState}
                    sportName={sportId}
                />
            ) : null}
        </>
    );
});
